import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'isB2BSpecialProduct',
})
export class IsB2BSpecialProductPipe implements PipeTransform {
	transform(product: any): 'hatam' | 'inKilograms' | 'regular' {
		if (!product?.IGC) return 'regular';

		if (+product.IGC === 286) return 'hatam';
		if (+product.IGC === 255) return 'inKilograms';

		return 'regular';
	}
}
