import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-product-info-dialog',
	templateUrl: 'product-info-dialog.component.html',
	styleUrls: ['./products.component.scss'],
})
export class ProductInfoDialogComponent {
	constructor(public dialogRef: MatDialogRef<ProductInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public _ss: StoreService) {
		setTimeout(() => {
			this.data.loadingComments = false;
		}, 2000);
	}

	alternativeProductCalculations = this._ss.CustomerInformation.CanChangePermPrice === '1';

	reverseProductExtraInfoOrder = this._ss.CustomerInformation.ProductExtraInfoOrder === '1';

	onNoClick(): void {
		this.dialogRef.close();
	}
}
