import { RALLYANCE_RECLAMATION_APP_PATH } from '../services/reclamation.service';

export type ReclamationReasonsResponse = {
	FieldId: 'BODY' | 'LOCATION' | 'TAKALA' | 'ANS';
	ResonCode: string;
	ResonBody: string;
	Reason: string;
};

export type AllReclamationsResponse = {
	RekID: string;
	Status: number;
	CarNum: string;
	BODY: string;
	LOCATION: string;
	TAKALA: string;
	ANS: string;
	RDR: string;
	RDM: string;
	RDL: string;
	DOT: string;
	SN: string;
	PIC1: string;
	PIC2: string;
	PIC3: string;
	PIC4: string;
	ProductId: string;
	ProductQty: number;
	ClientId: string;
	CreateDate: Date;
	AgentId: string;
};
export const RallyanceReclamationRekStatuses = ['0', '1', '2', '3'] as const;

export type RallyanceReclamationSQLOptionalProps = {
	RekStatus: typeof RallyanceReclamationRekStatuses[number];
	RekRemark: string;
	AhuzPizui: string;
	ConfirmReason: string;
	DOT: string;
	Serial: string;
	SugTakaleYazran: string;
	OmekSulia: string;
	PRIStatus: string;
	PIC1: string;
	PIC2: string;
	PIC3: string;
	PIC4: string;
	PIC5: string;
	PIC6: string;
	PIC7: string;
	PIC8: string;
	PIC9: string;
	PIC10: string;
	PIC11: string;
	PIC12: string;
	PIC13: string;
	PIC14: string;
	PIC15: string;
	Color: string;
};

type Pics = 'PIC1' | 'PIC2' | 'PIC3' | 'PIC4' | 'PIC5' | 'PIC6' | 'PIC7' | 'PIC8' | 'PIC9' | 'PIC10' | 'PIC11' | 'PIC12' | 'PIC13' | 'PIC14' | 'PIC15';
export type RallyanceReclamationPics = Partial<Record<Pics, string>>;

export type RallyanceReclamationSQLResponse = {
	TeudaNum: string;
	ClientId: string;
	TeudaDate: string;
	ProductId: string;
	Qty: 1;
	RekID: string;
	AgentRemark: string;
	Client_Name: string;
	Product_Name: string;
	SugTakala: string;
	EmailStatus: string;
	TeudaStatus: string;
} & Partial<RallyanceReclamationSQLOptionalProps>;

export type RallyanceReclamationReasons = {
	ID: string;
	Sug: string;
	Name: string;
};

export type RallyanceReclamationNewReclamationBody = {
	TeudaNum: string;
	ClientId: string;
	TeudaDate: string;
	ProductId: string;
	RekID: string;
	AgentRemark: string;
	SugTakala: string;
};

export type RallyanceReclamationNewReclamationFormValues = {
	RekID: string;
	SugTakala: string;
	AgentRemark: string;
};

export const RALLYANCE_RECLAMATION_ROUTES = ['tasks', 'finish'] as const;

export const RALLYANCE_RECLAMATOR_ADMIN = 'reclamator' as const;

export type RallyanceReclamationNavigation = {
	mode: typeof RALLYANCE_RECLAMATION_APP_PATH;
	route: typeof RALLYANCE_RECLAMATION_ROUTES[number];
};

export type RallyanceReclamationReclamatorPatchBody = Partial<
	{
		RekRemark: string;
		AhuzPizui: string;
		ConfirmReason: string;
		DOT: string;
		SERIAL: string;
		OmekSulia: string;
		SugTakalatYazran: string;
		RekStatus: typeof RallyanceReclamationRekStatuses[number];
	} & RallyanceReclamationPics
>;
