import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

export type Gvia = {
	Client_Name: string;
	Agentid: string;
	AgentName: string;
	OwnerAgentID: string;
	OwnerAgentName: string;
	DebtCustCode: string;
	DebtDocNum: string;
	DebtDocType: number;
	DebtDocCreateDate: string;
	DebtDocToPayDate: string;
	DebtDocTotal: number;
	DebtDocOpenToPay: number;
	DebtRemark: string;
	DebtColor: typeof colors[number]['value'];
	DebtColorIndex: string;
	Company: number;
	DocTypeName: string;
	PayTermName: string;
	mm1: number;
	yy1: number;
	PayTermThisDoc: string;
	CustOpenDebt: number;
};

export const colors = [
	{ text: 'חורג מתנאי התשלום', value: 'red' },
	{ text: 'גבייה החודש', value: 'purple' },
	{ text: 'עומד בתנאי התשלום', value: 'green' },
] as const;

@Injectable({
	providedIn: 'root',
})
export class AgentGviaService {
	AgentsSalesAll: Gvia[] = [];
	AgentsSalesAllResponse: Gvia[] = [];
	AgentsSalesAllSecond: Gvia[] = [];
	AgentsSalesAllSecondObject: Record<string, Gvia[]> = {};

	hasAgentGvia = false;

	searchControl = new FormControl(<string | null>'');
	AgentsControl = new FormControl(<string[]>[], { nonNullable: true });
	Agents: Set<string> = new Set();
	colorsControl = new FormControl(<typeof colors[number]['value'][]>[], { nonNullable: true });

	constructor() {}
}
