<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
	>
		<!-- global markup for header -->
		<header class="header header_app-shop-return" style="padding: 15px" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }" *ngIf="!isPartOfOtherPage">
			<div class="content">
				<div class="header__block row" style="direction: ltr">
					<div class="header__arrow col image" (click)="history.back()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
						<span style="color: white; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">איתור סניפים</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger">  -->
					</div>
				</div>

				<div class="report-table__row">
					<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px; direction: rtl">
						<input class="header__search-input" [formControl]="searchBranchControl" (keyup)="searchBranches()" type="text" placeholder=" חיפוש" style="text-align: center" />
						<button class="header__clear-icon" (click)="clearSearch()"></button>
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- start shop/return markup -->
		<section class="shop" *ngIf="!Loading; else loadBlock">
			<div class="content">
				<div class="shop__filters row flex-column" [ngClass]="{ 'main-content_absolute': !_ss.headerAbsolute }">
					<div class="header__block">
						<mat-form-field appearance="fill" color="primary" dir="rtl" style="text-align: right; max-height: 40px">
							<mat-label dir="rtl">סוג כלי רכב</mat-label>
							<mat-select dir="rtl" multiple [formControl]="carTypeControl" (selectionChange)="searchBranches()">
								<mat-option *ngFor="let car of carTypes" [value]="car.value" dir="rtl">
									{{ car.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<div class="flex justify-evenly checkboxes" style="flex: 1">
							<div class="flex checkboxes-wrapper justify-align-evenly" style="gap: 0.5rem" *ngFor="let check of checkboxes" [formGroup]="checkboxesFormGroup">
								<mat-checkbox color="primary" labelPosition="after" [value]="check.value" dir="rtl" [formControlName]="check.value" (change)="searchBranches()">{{ check.name }}</mat-checkbox>
							</div>
						</div>
					</div>
				</div>
				<div class="shop__cards row">
					<cdk-virtual-scroll-viewport itemSize="225" class="example-viewport" [ngClass]="{ 'override-height': isPartOfOtherPage }">
						<div
							class="shop__card"
							*cdkVirtualFor="let ac of AllClients$ | async; index as i"
							style="margin: 15px auto"
							(click)="handleCardClicked(ac.mikum)"
							[ngClass]="{ 'override-padding-and-width': isPartOfOtherPage, selected: mikumSelected === ac.mikum }"
						>
							<div class="flex rtl" style="height: 48px">
								<div class="shop__card-title" style="flex: 3">
									<div class="shop__card-title-text">{{ ac.mikumname }}</div>
								</div>
								<div style="flex: 5; text-align: center; font-size: 20px">
									{{ ac.address && ac.city ? ac.address + ',' + ac.city : ac.address || ac.city || 'ללא כתובת' }}
								</div>
							</div>
							<div class="flex gap info-box-wrapper rtl">
								<div class="info-box">
									<div class="flex info-box-line">
										<strong>טלפון</strong><span>{{ ac.phone }}</span>
									</div>
									<div class="flex info-box-line">
										<strong>נייד</strong><span>{{ ac.mobilephone }}</span>
									</div>
									<div class="flex info-box-line">
										<strong>פקס</strong><span>{{ ac.faxnum }}</span>
									</div>
									<div class="flex info-box-line">
										<strong>מייל</strong><a [href]="'mailto:'+ac.email">{{ ac.email }}</a>
									</div>
								</div>
								<div class="info-box">
									<div class="info-box-title">שעות פתיחה</div>
									<div class="flex info-box-line">
										<strong>א'-ה'</strong><span>{{ ac.week !== '00:00-00:00' ? ac.week : '' }}</span>
									</div>
									<div class="flex info-box-line">
										<strong>ו'</strong><span>{{ ac.friday !== '00:00-00:00' ? ac.friday : '' }}</span>
									</div>
									<div class="flex info-box-line">
										<strong>שבת</strong><span>{{ ac.shabat !== 'Y' ? 'סגור' : 'פתוח - בתיאום טלפוני' }}</span>
									</div>
								</div>
								<div class="info-box">
									<div class="cars-wrapper">
										<div class="flex cars-wrapper-images">
											<svg
												[ngClass]="{ active: ac.privet === 'Y' }"
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												viewBox="0 0 512 512"
												xml:space="preserve"
											>
												<g>
													<path
														d="M98.025,317.265c1.919,0,3.839-0.732,5.303-2.196l11.336-11.337c2.929-2.929,2.929-7.677,0-10.606
																c-2.929-2.929-7.678-2.929-10.606,0l-11.336,11.337c-2.929,2.929-2.929,7.677,0,10.606
																C94.187,316.533,96.106,317.265,98.025,317.265z"
													/>
													<path
														d="M416.688,315.068c1.464,1.464,3.384,2.196,5.303,2.196s3.839-0.732,5.303-2.196c2.929-2.929,2.929-7.678,0-10.606
																l-11.336-11.337c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.606L416.688,315.068z"
													/>
													<path
														class="chasis"
														d="M489.383,238.186c-42.36-19.672-106.078-21.554-118.944-21.731l-60.762-38.667c-17.955-11.426-38.693-17.465-59.974-17.465
																h-71.419c-18.49,0-37.005,4.371-53.543,12.64l-38.043,19.021c-11.461,5.731-23.72,9.442-36.436,11.032l-29.648,3.706
																C8.862,208.19,0,218.229,0,230.072v47.869c0,14.011,9.409,26.485,22.881,30.334l35.431,10.123
																c6.086,19.269,24.126,33.279,45.381,33.279c17.679,0,33.125-9.699,41.328-24.049h229.973c8.203,14.35,23.649,24.049,41.328,24.049
																c26.236,0,47.581-21.345,47.581-47.581s-21.345-47.581-47.581-47.581s-47.581,21.344-47.581,47.581
																c0,2.914,0.277,5.762,0.781,8.532h-219.03c0.504-2.771,0.781-5.619,0.781-8.532c0-26.236-21.345-47.581-47.581-47.581
																c-25.594,0-46.523,20.316-47.532,45.667l-29.159-8.331C19.936,291.833,15,285.291,15,277.941v-22.457h8.532
																c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H15v-10.412c0-4.294,3.213-7.934,7.474-8.466l29.648-3.706
																c14.408-1.801,28.297-6.007,41.284-12.5l3.421-1.71l13.873,13.809c8.987,8.987,20.936,13.937,33.645,13.937h15.462
																c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-15.462c-1.311,0-2.607-0.086-3.889-0.238l8.287-36.604
																c9.582-2.812,19.566-4.272,29.542-4.272h38.152v41.113h-24.564c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h176.353
																c0.711,0,71.566,0.258,114.84,20.354c2.539,1.179,4.801,2.794,6.734,4.726h-9.347c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5
																h16.412c0.081,0.812,0.136,1.628,0.136,2.456v26.612c0,7.43-3.27,12.045-8.532,12.045c-4.142,0-7.5,3.358-7.5,7.5
																s3.358,7.5,7.5,7.5c6.642,0,12.677-2.733,16.995-7.696c4.276-4.917,6.537-11.607,6.537-19.349v-26.612
																C512,258.613,503.122,244.566,489.383,238.186z M416.322,271.516c17.965,0,32.581,14.616,32.581,32.581
																s-14.616,32.581-32.581,32.581s-32.581-14.616-32.581-32.581S398.357,271.516,416.322,271.516z M103.693,271.516
																c17.965,0,32.581,14.616,32.581,32.581s-14.616,32.581-32.581,32.581s-32.581-14.616-32.581-32.581S85.729,271.516,103.693,271.516
																z M126.259,210.967c-1.761-1.18-3.42-2.541-4.953-4.074l-10.295-10.295l20.438-10.219c0.14-0.07,0.283-0.135,0.423-0.204
																L126.259,210.967z M231.436,175.322h18.267c18.423,0,36.377,5.229,51.921,15.12l40.847,25.994H231.436V175.322z"
													/>
													<path
														class="bumper"
														d="M175.839,280.564c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h168.338c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5
																H175.839z"
													/>
												</g>
											</svg>

											<svg
												[ngClass]="{ active: ac.suv === 'Y' }"
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												viewBox="0 0 512 512"
												xml:space="preserve"
											>
												<g>
													<path
														d="M104.058,301.141l-11.336,11.336c-2.929,2.929-2.929,7.678,0,10.606c1.464,1.464,3.384,2.197,5.303,2.197
																s3.839-0.732,5.303-2.197l11.336-11.336c2.929-2.929,2.929-7.678,0-10.606C111.736,298.212,106.987,298.212,104.058,301.141z"
													/>
													<path
														d="M416.686,323.084c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.606
																l-11.336-11.336c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.606L416.686,323.084z"
													/>
													<path
														d="M489.541,229.876c-37.774-18.552-88.082-21.066-101.789-21.405l-36.299-42.349c-7.526-8.781-18.475-13.816-30.04-13.816
																h-65.414c-12.976,0-23.532,10.557-23.532,23.532v128.774h-81.789c-3.608-22.684-23.3-40.081-46.984-40.081
																s-43.376,17.396-46.984,40.081H23.532c-4.705,0-8.532-3.828-8.532-8.532v-40.597h8.532c4.142,0,7.5-3.358,7.5-7.5
																s-3.358-7.5-7.5-7.5H15v-16.548c0-0.285,0.231-0.516,0.516-0.516h192.386c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H15.516
																C6.96,208.419,0,215.38,0,223.936v72.145c0,12.976,10.557,23.532,23.532,23.532h33.177c3.608,22.684,23.3,40.081,46.984,40.081
																s43.376-17.396,46.984-40.081h218.66c3.608,22.684,23.3,40.081,46.984,40.081c26.236,0,47.581-21.345,47.581-47.581
																s-21.345-47.581-47.581-47.581c-23.684,0-43.376,17.396-46.984,40.081h-121.87V175.839c0-4.705,3.828-8.532,8.532-8.532h8.532
																v32.586c0,12.973,10.557,23.526,23.532,23.526h96.191c0.582,0,58.651,0.264,98.674,19.921c2.719,1.335,5.115,3.09,7.125,5.16
																h-9.603c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16.452c0.059,0.692,0.098,1.388,0.098,2.092l-0.001,30.488
																c0,4.705-3.828,8.533-8.533,8.533c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5c12.975,0,23.532-10.556,23.533-23.532L512,265.592
																C512,250.366,503.395,236.68,489.541,229.876z M103.693,344.693c-17.965,0-32.581-14.616-32.581-32.581
																s14.616-32.581,32.581-32.581s32.581,14.616,32.581,32.581S121.658,344.693,103.693,344.693z M416.321,279.532
																c17.965,0,32.581,14.616,32.581,32.581s-14.616,32.581-32.581,32.581s-32.581-14.616-32.581-32.581
																S398.356,279.532,416.321,279.532z M279.531,199.893v-32.586h41.881c7.18,0,13.978,3.126,18.651,8.578l27.887,32.535h-79.887
																C283.359,208.419,279.531,204.595,279.531,199.893z"
													/>
												</g>
											</svg>

											<svg
												[ngClass]="{ active: ac.truck === 'Y' }"
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												viewBox="0 0 512 512"
												xml:space="preserve"
											>
												<g>
													<path
														d="M80.01,325.19l-11.336,11.337c-2.929,2.929-2.929,7.678,0,10.607c1.464,1.464,3.384,2.197,5.303,2.197
																s3.839-0.732,5.303-2.197l11.336-11.337c2.929-2.929,2.929-7.678,0-10.607C87.688,322.261,82.939,322.26,80.01,325.19z"
													/>
													<path
														d="M159.441,325.19c-2.929-2.929-7.678-2.928-10.606,0c-2.929,2.929-2.929,7.678,0,10.607l11.336,11.337
																c1.464,1.465,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.607L159.441,325.19z"
													/>
													<path
														d="M399.925,325.19c-2.929-2.929-7.678-2.928-10.606,0c-2.929,2.929-2.929,7.678,0,10.607l11.336,11.337
																c1.464,1.465,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.607L399.925,325.19z"
													/>
													<path
														d="M509.993,240.197l-18.93-63.104c-3.01-10.031-12.068-16.77-22.54-16.77h-60.216c-8.556,0-15.516,6.96-15.516,15.516v16.549
																h-17.065v-49.129h40.597c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-62.184c-8.427,0-16.35,3.282-22.308,9.24l-17.977,17.977
																c-3.126,3.126-7.282,4.847-11.702,4.847H15.516C6.96,160.323,0,167.283,0,175.839v112.226c0,8.556,6.96,15.516,15.516,15.516
																h29.501c-8.021,8.521-12.952,19.982-12.952,32.581c0,26.236,21.345,47.581,47.581,47.581c16.819,0,31.617-8.78,40.081-21.989
																c8.464,13.209,23.262,21.989,40.081,21.989c23.684,0,43.376-17.396,46.984-40.081h146.515c3.608,22.685,23.3,40.081,46.985,40.081
																c23.684,0,43.376-17.396,46.984-40.081h41.193c12.976,0,23.532-10.556,23.532-23.532v-66.26
																C512,249.234,511.325,244.634,509.993,240.197z M476.696,181.403l12.914,43.049h-41.222c-4.705,0-8.532-3.828-8.532-8.532v-40.597
																h28.668C472.32,175.323,475.604,177.766,476.696,181.403z M15,288.064V175.839c0-0.285,0.231-0.516,0.516-0.516h286.636
																c8.426,0,16.349-3.281,22.308-9.24l17.977-17.977c3.125-3.126,7.281-4.847,11.702-4.847h6.587v120.758
																c0,13.545-11.02,24.564-24.564,24.564H223.936c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h112.226
																c21.816,0,39.564-17.749,39.564-39.564v-56.629h17.065v8.532c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-40.081
																c0-0.285,0.231-0.516,0.516-0.516h16.548v40.597c0,12.976,10.557,23.532,23.532,23.532h45.722l1.517,5.056
																c0.912,3.039,1.374,6.188,1.374,9.361v10.664h-8.532c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5H497v17.064h-70.314
																c-5.582-3.736-11.993-6.322-18.896-7.42v-41.194c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v41.194
																c-20.24,3.219-36.265,19.244-39.485,39.484H206.791c-3.608-22.685-23.3-40.081-46.984-40.081H15.516
																C15.231,288.581,15,288.349,15,288.064z M125.178,303.581c-2.026,2.152-3.851,4.491-5.452,6.989
																c-1.601-2.499-3.427-4.837-5.452-6.989H125.178z M79.645,368.742c-17.965,0-32.581-14.615-32.581-32.581
																s14.616-32.581,32.581-32.581s32.581,14.616,32.581,32.581S97.61,368.742,79.645,368.742z M159.807,368.742
																c-17.965,0-32.581-14.615-32.581-32.581s14.616-32.581,32.581-32.581c17.965,0,32.581,14.616,32.581,32.581
																S177.771,368.742,159.807,368.742z M400.291,368.742c-17.965,0-32.581-14.615-32.581-32.581s14.616-32.581,32.581-32.581
																c17.965,0,32.581,14.616,32.581,32.581S418.255,368.742,400.291,368.742z M488.468,328.661h-41.193
																c-0.979-6.159-3.141-11.928-6.25-17.065H497v8.532C497,324.834,493.172,328.661,488.468,328.661z"
													/>
													<path
														d="M47.581,200.403c-4.142,0-7.5,3.358-7.5,7.5V256c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-48.097
																C55.081,203.761,51.723,200.403,47.581,200.403z"
													/>
													<path
														d="M87.661,263.5c4.142,0,7.5-3.358,7.5-7.5v-48.097c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V256
																C80.161,260.142,83.519,263.5,87.661,263.5z"
													/>
													<path
														d="M127.742,263.5c4.142,0,7.5-3.358,7.5-7.5v-48.097c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V256
																C120.242,260.142,123.6,263.5,127.742,263.5z"
													/>
													<path
														d="M167.823,263.5c4.142,0,7.5-3.358,7.5-7.5v-48.097c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V256
																C160.323,260.142,163.681,263.5,167.823,263.5z"
													/>
													<path
														d="M207.903,263.5c4.142,0,7.5-3.358,7.5-7.5v-48.097c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V256
																C200.403,260.142,203.761,263.5,207.903,263.5z"
													/>
													<path
														d="M247.984,263.5c4.142,0,7.5-3.358,7.5-7.5v-48.097c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V256
																C240.484,260.142,243.842,263.5,247.984,263.5z"
													/>
													<path
														d="M288.064,263.5c4.142,0,7.5-3.358,7.5-7.5v-48.097c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V256
																C280.564,260.142,283.922,263.5,288.064,263.5z"
													/>
													<path
														d="M328.145,263.5c4.142,0,7.5-3.358,7.5-7.5v-48.097c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V256
																C320.645,260.142,324.003,263.5,328.145,263.5z"
													/>
												</g>
											</svg>

											<svg
												[ngClass]="{ active: ac.traktor === 'Y' }"
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												viewBox="0 0 512 512"
												xml:space="preserve"
											>
												<g>
													<path
														d="M104.209,312.113c0,12.976,10.557,23.532,23.532,23.532c12.976,0,23.533-10.557,23.533-23.532
																c0-12.976-10.557-23.532-23.533-23.532C114.766,288.58,104.209,299.137,104.209,312.113z M136.274,312.113
																c0,4.705-3.828,8.532-8.533,8.532c-4.705,0-8.532-3.828-8.532-8.532c0-4.705,3.828-8.532,8.532-8.532
																C132.447,303.58,136.274,307.408,136.274,312.113z"
													/>
													<path
														d="M445.508,352.948c-4.011,1.032-6.427,5.121-5.394,9.133c0.513,1.993,0.773,4.061,0.773,6.145
																c0,13.545-11.02,24.564-24.564,24.564s-24.564-11.02-24.564-24.564s11.02-24.564,24.564-24.564c2.091,0,4.164,0.261,6.162,0.778
																c4.009,1.035,8.101-1.376,9.137-5.387c1.036-4.01-1.376-8.101-5.387-9.137c-3.223-0.832-6.557-1.254-9.912-1.254
																c-21.816,0-39.564,17.749-39.564,39.564s17.749,39.564,39.564,39.564s39.564-17.749,39.564-39.564c0-3.344-0.419-6.669-1.247-9.883
																C453.609,354.331,449.521,351.917,445.508,352.948z"
													/>
													<path
														d="M492.677,238.211l-132.984-24.178v-84.346c0-8.427-3.282-16.349-9.24-22.308l-8.989-8.989
																c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.607l8.989,8.988c3.125,3.126,4.847,7.281,4.847,11.702v81.618
																l-10.658-1.938L298.088,87.145h6.009c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H179.248c-14.778,0-27.752,10.488-30.848,24.938
																l-11.886,55.467c-2.905-0.158-5.828-0.244-8.772-0.244c-25.11,0-49.148,5.66-71.447,16.824c-3.704,1.854-5.203,6.36-3.349,10.064
																c1.854,3.704,6.359,5.203,10.064,3.349c20.196-10.11,41.975-15.237,64.732-15.237c79.847,0,144.807,64.96,144.807,144.806
																c0,2.479-0.116,4.921-0.257,7.627c-0.107,2.054,0.635,4.062,2.051,5.553c1.416,1.492,3.382,2.336,5.439,2.336h40.864v17.064
																h-69.378c2.747-10.406,4.217-21.324,4.217-32.581c0-70.437-57.305-127.742-127.742-127.742C57.305,184.371,0,241.675,0,312.113
																s57.305,127.742,127.742,127.742c53.628,0,99.637-33.221,118.545-80.161h98.932c-0.334,2.8-0.526,5.644-0.526,8.532
																c0,39.497,32.133,71.629,71.629,71.629c39.496,0,71.629-32.133,71.629-71.629c0-1.978-0.102-3.931-0.26-5.869l19.165-44.719
																c3.413-7.963,5.144-16.394,5.144-25.059v-31.215C512,249.984,503.874,240.247,492.677,238.211z M127.742,424.855
																C65.576,424.855,15,374.279,15,312.113c0-62.166,50.576-112.742,112.742-112.742s112.742,50.576,112.742,112.742
																C240.484,374.279,189.908,424.855,127.742,424.855z M163.066,100.226c1.624-7.58,8.429-13.081,16.181-13.081h21.156v82.656
																c-15.07-7.726-31.522-13.122-48.876-15.723L163.066,100.226z M287.547,312.629c0.001-0.172,0.001-0.344,0.001-0.516
																c0-55.77-28.723-104.949-72.145-133.548v-91.42h67.049l38.192,129.854v95.63H287.547z M416.323,424.855
																c-31.226,0-56.629-25.404-56.629-56.629s25.404-56.629,56.629-56.629c31.225,0,56.629,25.404,56.629,56.629
																S447.548,424.855,416.323,424.855z M497,292.579c0,6.621-1.322,13.064-3.931,19.15l-11.536,26.918
																c-11.283-24.775-36.259-42.05-65.21-42.05c-31.254,0-57.88,20.129-67.639,48.097h-13.039V224.906l154.349,28.063
																c4.06,0.738,7.006,4.268,7.006,8.395V292.579z"
													/>
													<path
														d="M373.529,258.713c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.607l12.024,12.024
																c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.607L373.529,258.713z"
													/>
													<path
														d="M413.61,258.713c-2.928-2.929-7.677-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.607l12.024,12.024
																c1.464,1.465,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.607L413.61,258.713z"
													/>
													<path
														d="M453.69,258.713c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.607l12.024,12.024
																c1.464,1.465,3.384,2.197,5.303,2.197c1.919,0,3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.607L453.69,258.713z"
													/>
													<path
														d="M63.489,305.226c1.562-14.831,7.974-28.256,18.542-38.825c10.569-10.569,23.994-16.981,38.825-18.542
																c14.406-1.517,29.062,1.876,41.26,9.557c3.505,2.207,8.136,1.154,10.343-2.35c2.207-3.505,1.155-8.136-2.351-10.343
																c-15.032-9.466-33.085-13.65-50.823-11.781c-18.024,1.898-35.022,10.014-47.861,22.854c-12.839,12.839-20.955,29.836-22.853,47.86
																c-1.868,17.741,2.315,35.791,11.781,50.823c1.426,2.264,3.862,3.505,6.354,3.505c1.366,0,2.748-0.373,3.989-1.154
																c3.505-2.207,4.558-6.838,2.351-10.343C65.365,334.289,61.971,319.636,63.489,305.226z"
													/>
													<path
														d="M184.789,267.396c-3.505,2.207-4.558,6.838-2.351,10.343c7.68,12.197,11.074,26.85,9.557,41.26
																c-1.562,14.831-7.974,28.256-18.542,38.825c-10.569,10.568-23.994,16.98-38.825,18.542c-14.411,1.517-29.063-1.877-41.26-9.557
																c-3.506-2.207-8.136-1.154-10.343,2.35c-2.207,3.505-1.155,8.136,2.351,10.343c12.689,7.99,27.527,12.216,42.516,12.216
																c2.768,0,5.542-0.144,8.308-0.436c18.024-1.898,35.021-10.014,47.86-22.853c12.839-12.839,20.955-29.836,22.853-47.861
																c1.868-17.741-2.315-35.791-11.781-50.823C192.924,266.242,188.293,265.189,184.789,267.396z"
													/>
												</g>
											</svg>
										</div>
										<mat-checkbox class="cars-wrapper-checkbox" style="margin-left: 8px" color="primary" dir="rtl" [checked]="ac.recue === 'Y'" (change)="preventInteraction($event)"
											>חילוץ</mat-checkbox
										>
										<mat-checkbox [checked]="ac.shinua === 'Y'" class="cars-wrapper-checkbox" style="margin-left: 3px" color="primary" dir="rtl" (change)="preventInteraction($event)"
											>שינוע</mat-checkbox
										>
										<mat-checkbox class="cars-wrapper-checkbox" style="margin-top: -16px" color="primary" [checked]="ac.tpms === 'Y'" dir="rtl" (change)="preventInteraction($event)"
											>TPMS</mat-checkbox
										>
										<mat-checkbox [checked]="ac.front === 'Y'" class="cars-wrapper-checkbox" style="margin-top: -16px" color="primary" dir="rtl" (change)="preventInteraction($event)"
											>פרונט</mat-checkbox
										>
									</div>
								</div>
							</div>
						</div>
					</cdk-virtual-scroll-viewport>
				</div>
			</div>
		</section>

		<ng-template #loadBlock>
			<h1 style="margin-top: 300px; text-align: center; direction: rtl">טוען אנא המתן....</h1>
		</ng-template>

		<!-- markup for product popup -->
		<div class="popup" *ngIf="LoadingClient === true" style="z-index: 333">
			<div class="overlay"></div>
			<div class="product-popup popup__content">
				<div class="order-product__item">
					<div class="order-process__row order-process__row_success">
						<div class="row">
							<h2 class="order-process__title" style="text-align: center">טוען אנא המתן</h2>
						</div>
						<div class="row"></div>
						<div class="row">
							<div class="order-process__image image">
								<img *ngIf="_ss.isConnectionFastEnough()" src="/assets/images/basket/process/success.gif" alt="photo" />
							</div>
						</div>
						<div class="row"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- end markup for product popup -->
		<!-- start if didn't heve filters result -->
		<section class="shop-filter-empty" *ngIf="!AllClients.length && !Loading">
			<div class="container">
				<div class="shop-filter-empty__row row">
					<div class="shop-filter-empty__photo image">
						<img src="/assets/images/shop-return/no-filters-result.svg" alt="photo" />
					</div>
					<h2 class="shop-filter-empty__title">אופס, לא מצאנו את מה שחיפשתם</h2>
					<h2 class="shop-filter-empty__title">כדאי לבדוק איות או לנסות לחפש לפי פרמטר אחר</h2>
				</div>
			</div>
		</section>
	</div>
	<!-- end markup for sort popup -->
</div>

<div *ngIf="!isPartOfOtherPage" class="back-top" (click)="ScrollUp()" style="z-index: 300"></div>

