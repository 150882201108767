<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span class="back-arrow-text">חזרה</span></div>
					<div class="header__title col">חובות</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start markup for client profile page -->
		<!-- client info for all client profile page -->
		<section class="client-info" style="margin-top: 100px">
			<div class="content">
				<div class="row">
					<div class="client-info__title col">
						{{ this.OneClientInfo.Client_Name }}
					</div>
				</div>
				<div class="client-info__block row">
					<div class="client-info__number col">
						{{ this.OneClientInfo.ClientId }}
					</div>
					<div class="client-info__date">
						<span class="client-info__date-text">תנאי אשראי</span>
						{{ this.OneClientInfo.PTN }}
					</div>
				</div>
				<div class="row">
					<div class="client-info__certificate col">
						<span dir="ltr" class="invalid-color"> {{ this.TotalSumDebtChecked | number : '1.0-2' }}</span>
						<span class="client-info__certificate-text"> :סך התעודות שסומנו</span>
					</div>
				</div>
			</div>
		</section>
		<!-- end of client info for all client profile page -->

		<div class="loading-div" *ngIf="LoadingHov === true">
			<h2 style="text-align: center; direction: rtl">טוען תעודה {{ this.ChosenTeuda }} אנא המתן !</h2>
			<mat-spinner diameter="50" color="primary" *ngIf="LoadingHov === true"></mat-spinner>
		</div>

		<section class="report-table" *ngIf="this.LoadingHov === false">
			<div class="content">
				<div class="report-table__row report-table__row_between align-start flex-column" style="gap: 1rem">
					<div class="flex flex-wrap s-gap">
						<div class="client-indebt__button client-indebt__button_active" (click)="AllDebt()" style="margin: 0 0.7rem">
							<span class="client-indebt__button-text client-indebt__button-text_message">כל התעודות</span>
						</div>
						<div
							class="client-indebt__button client-indebt__button_active"
							*ngIf="
								_ss.CustomerInformation.CanSendEmail &&
								((_ss.CustomerInformation.CanSendEmail.includes('1') && clientHasEmail) || _ss.CustomerInformation.CanSendEmail.includes('2')) &&
								OneClientInfoDebt &&
								OneClientInfoDebt.length
							"
							style="margin: 0 0.7rem"
							(click)="directToSendThroughEmail()"
						>
							<span class="client-indebt__button-text client-indebt__button-text_message">{{ shouldDisableSendButton ? 'שולח...' : 'שלח חובות' }}</span>
						</div>
						<div class="client-indebt__button client-indebt__button_active" (click)="checkAll()" style="margin: 0 0.7rem">
							<span class="client-indebt__button-text client-indebt__button-text_message">סמן הכל</span>
						</div>
						<div class="client-indebt__button client-indebt__button_active" (click)="_ss.prefersHovotFullTableDesign = !_ss.prefersHovotFullTableDesign" style="margin: 0 0.7rem">
							<span class="client-indebt__button-text client-indebt__button-text_message">החלף עיצוב</span>
						</div>
						<div class="client-indebt__button client-indebt__button_active highlight" *ngIf="_receipts.hasReceipts" style="margin: 0 0.7rem" (click)="handleReceiptPopupOpen()">
							<span class="client-indebt__button-text client-indebt__button-text_message">בצע קבלה</span>
						</div>
					</div>
					<div class="flex" style="gap: 1rem">
						<div class="flex" style="gap: 1rem">
							<label for="CreateMonth" class="client-info__number col justify-align-center" style="order: 0; margin-right: 0.7rem">יצירה:</label>
							<div class="client-indebt__button">
								<form [formGroup]="form" style="font-size: 20px">
									<select formControlName="CreateMonth" id="CreateMonth">
										<option *ngFor="let month of Months; let i = index" [value]="i">
											{{ month }}
										</option>
									</select>
								</form>
							</div>
						</div>
						<div class="flex" style="gap: 1rem">
							<label for="DueMonth" class="client-info__number col justify-align-center" style="order: 0; margin-right: 0.7rem">פרעון:</label>
							<div class="client-indebt__button">
								<form [formGroup]="form" style="font-size: 20px">
									<select formControlName="DueMonth" id="DueMonth">
										<option *ngFor="let month of Months; let i = index" [value]="i">
											{{ month }}
										</option>
									</select>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div class="report-table__row report-table__row_full-width" [ngClass]="{ 'justify-align-center': !_ss.prefersHovotFullTableDesign }" style="margin-right: 0px">
					<table class="report-table__table" cellspacing="0" [ngClass]="{ 'max-width': !_ss.prefersHovotFullTableDesign }">
						<thead class="report-table__table-head">
							<tr>
								<th class="report-table__table-head-name" [ngClass]="{ 'full-width': !_ss.prefersHovotFullTableDesign }" style="width: 50px">סמן</th>
								<th class="report-table__table-head-name" [ngStyle]="{ width: longestTeudaNum > 9 ? longestTeudaNum * 10 + 'px' : '90px' }" *ngIf="_ss.prefersHovotFullTableDesign">מספר</th>
								<th
									class="report-table__table-head-name"
									[ngClass]="{ 'full-width': !_ss.prefersHovotFullTableDesign }"
									[ngStyle]="{
										width: _ss.prefersHovotFullTableDesign ? '90px' : null,
										'min-width': _ss.prefersHovotFullTableDesign ? null : '90px'
									}"
								>
									חוב
								</th>
								<th
									class="report-table__table-head-name"
									[ngClass]="{ 'full-width': !_ss.prefersHovotFullTableDesign }"
									[ngStyle]="{
										width: _ss.prefersHovotFullTableDesign ? '100px' : null,
										'min-width': _ss.prefersHovotFullTableDesign ? null : '100px'
									}"
								>
									תאריך
								</th>
								<th
									class="report-table__table-head-name"
									[ngClass]="{ 'full-width': !_ss.prefersHovotFullTableDesign }"
									[ngStyle]="{
										width: _ss.prefersHovotFullTableDesign ? '100px' : null,
										'min-width': _ss.prefersHovotFullTableDesign ? null : '100px'
									}"
								>
									פרעון
								</th>
								<th class="report-table__table-head-name" *ngIf="_ss.prefersHovotFullTableDesign && _ss.CustomerInformation.CustChange3 !== '2'" style="width: 150px">הערה</th>
							</tr>
						</thead>
						<tbody class="report-table__table-body">
							<tr
								*ngFor="let ocid of OneClientInfoDebt; let i = index"
								[ngClass]="{ 'position-relative': !_ss.prefersHovotFullTableDesign }"
								[ngStyle]="{ 'height.em': _ss.prefersHovotFullTableDesign ? null : 8 }"
							>
								<td class="report-table__table-body-name">
									<input
										type="checkbox"
										(click)="DebtChecked(ocid.rownum, ocid.DebtDocOpenToPay, ocid)"
										id="check{{ ocid.rownum }}"
										style="width: 20px; height: 20px"
										[indeterminate]="true"
									/>
								</td>
								<td class="report-table__table-body-name" *ngIf="_ss.prefersHovotFullTableDesign" (click)="MoveToHovEach(ocid.DebtDocNum, i)">
									{{ ocid.DebtDocNum }}
								</td>
								<td class="report-table__table-body-name" [ngClass]="{ 'invalid-color': ocid.DebtDocOpenToPay <= 0 }" id="hov{{ ocid.rownum }}">
									{{ ocid.DebtDocOpenToPay | number : '1.0-2' }}
								</td>

								<td class="report-table__table-body-name" (click)="MoveToHovEach(ocid.DebtDocNum, i)">
									{{ ocid.DebtDocCreateDate | appDate : 'shortDate' }}
								</td>
								<td class="report-table__table-body-name">
									{{ ocid.DebtDocToPayDate | appDate : 'shortDate' }}
								</td>
								<td class="report-table__table-body-name" *ngIf="_ss.prefersHovotFullTableDesign && _ss.CustomerInformation.CustChange3 !== '2'">
									{{ ocid.DebtRemark }}
								</td>

								<div class="info info-top" *ngIf="!_ss.prefersHovotFullTableDesign">
									<b>{{ ocid.DocTypeName }} מספר: {{ ocid.DebtDocNum }}</b>
								</div>
								<div class="info info-bottom" *ngIf="!_ss.prefersHovotFullTableDesign">
									<b>הערה: {{ ocid.DebtRemark }}</b>
								</div>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</section>
		<!-- end second screen client indebtedness -->
	</div>
</div>

<div class="popup" *ngIf="isNewReceiptPopupOpen" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed wrapper">
		<div class="product-detail__item">
			<div class="popup__close" (click)="closeReceiptPopup()" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>

			<!-- <div *ngIf="true"> -->
			<div *ngIf="!_receipts.receiptSent && _receipts.sendingErroredWithTimeoutAndCredit; else receiptNormalBlock">
				<h2 class="product-detail-add__popup-title">קרתה תקלה בעת שידור עסקת אשראי</h2>

				<h3 style="text-align: center">הזמנתך נשמרה אך לא ניתנת לשליחה שוב</h3>
				<h3 style="text-align: center">נא לפנות למשרד לבדיקה</h3>
				<h4 style="text-align: center">מזהה עסקה: {{ _receipts.creditFailureIdentifiers.transactionID }}</h4>
				<h4 style="text-align: center">מספר הזמנה: {{ _receipts.creditFailureIdentifiers.teudaNum }}</h4>

				<div class="auth__help-image image">
					<img src="/assets/images/basket/process/error.gif" alt="photo" />
				</div>
				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="closeReceiptPopup()">קיבלתי</button>
				</div>
				<div class="product-detail-add__popup-row"></div>
			</div>

			<ng-template #receiptNormalBlock>
				<app-new-receipt *ngIf="!_receipts.receiptSent" [TotalSumDebtChecked]="TotalSumDebtChecked" [DebtArray]="DebtArray" (closePopup)="handleCloseReceiptPopupEvent($event)"></app-new-receipt>

				<div *ngIf="_receipts.receiptSent">
					<h2 class="product-detail-add__popup-title">קבלה נשלחה בהצלחה</h2>
					<h3 style="text-align: center">{{ this._ss.OneClientInfo.Client_Name }}</h3>
					<h4 style="text-align: center">מזהה עסקה: {{ _receipts.lastDocnumSent }}</h4>
					<ng-container *ngIf="_receipts.canPayWithCreditCard">
						<h4 style="text-align: center">מספר מנפיק: {{ _receipts.lastAuthManpikNo }}</h4>
						<h4 style="text-align: center">מספר שובר: {{ _receipts.lastShovarNum }}</h4>
						<h4 style="text-align: center">מספר מפעיל חיצוני: {{ _receipts.lastTransactionID }}</h4>
					</ng-container>
					<div class="auth__help-image image">
						<img src="/assets/images/auth/plane-icon.gif" alt="photo" />
					</div>
					<div class="product-detail-add__popup-row">
						<button class="product-detail-add__popup-button valid-background" (click)="closeReceiptPopup()">קיבלתי</button>
					</div>
					<div class="product-detail-add__popup-row"></div>
				</div>
			</ng-template>
		</div>
	</div>
</div>

<div class="popup" *ngIf="isEmailPickPopupOpen || (_receipts.shouldOpenEmailPopup$ | async)" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed wrapper">
		<div class="product-detail__item">
			<div class="popup__close" (click)="handleCloseEmailPopup()" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>

			<div>
				<h2 class="product-detail-add__popup-title">בחר כתובת דוא"ל לשליחה</h2>

				<div style="padding: 1rem">
					<form (ngSubmit)="handleSubmitEmail()">
						<mat-radio-group color="primary" [ngClass]="{ 'radio-group': windowWidth > 768 }" dir="rtl" [formControl]="emailRadios">
							<mat-radio-button value="none" *ngIf="_receipts.shouldOpenEmailPopup$ | async">ללא שליחה</mat-radio-button>
							<mat-radio-button value="client" [disabled]="!clientHasEmail">כתובת לקוח - {{ OneClientInfo.CustEmail || OneClientInfo.CustEMail || 'לא נמצאה כתובת לקוח' }}</mat-radio-button>
							<mat-radio-button value="user" *ngIf="_user.agentEmail$ | async as email">כתובת סוכן - {{ email }}</mat-radio-button>
							<mat-radio-button value="custom">
								<mat-form-field>
									<mat-label>כתובת דוא"ל</mat-label>
									<input matInput [formControl]="emailInput" (focus)="emailRadios.setValue('custom')" dir="rtl" placeholder='כתובת דוא"ל' />
								</mat-form-field>
							</mat-radio-button>
						</mat-radio-group>
						<div style="text-align: center">
							<button mat-raised-button color="primary" [disabled]="emailInput.invalid && emailRadios.value === 'custom'">
								{{ (_receipts.shouldOpenEmailPopup$ | async) ? 'אשר' : 'שלח' }}
							</button>
						</div>
					</form>
				</div>

				<!-- <h3 style="text-align: center">{{ this._ss.OneClientInfo.Client_Name }}</h3>
				<h4 style="text-align: center">מספר אישור: {{ _receipt.lastDocnumSent }}</h4> -->

				<div class="product-detail-add__popup-row"></div>
			</div>
		</div>
	</div>
</div>
