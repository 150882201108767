import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-admin-reports-company',
	templateUrl: './admin-reports-company.component.html',
	styleUrls: ['./admin-reports-company.component.scss'],
})
export class AdminReportsCompanyComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	public HasMultipleCompanys;
	public HasMultiplleCompanysPop;
	public online;
	public ShowCompanys;

	logo=MOBILITY_LOGO;

	hasAgentSalesProductsQuantitiesDates = false;

	hasBrandsTablesAndQuery = false;

	hasInvoiceQuery = false;

	hasUndeliveredEndpoint = false;

	windowWidth = innerWidth;

	async ngOnInit() {
		window.scrollTo(0, 0);

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		const res = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/reports/get-agent-sales`);
		if (res.status === 400) {
			this.hasAgentSalesProductsQuantitiesDates = true;
		}

		const res2 = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/reports/hasBrands`);
		if (res2.ok) {
			this.hasBrandsTablesAndQuery = true;
		}

		const res3 = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/reports/daily-invoices`);
		if (res3.status === 400) {
			this.hasInvoiceQuery = true;
		}

		const res4 = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/user/adminhistory-undelivered/1?checkIfExistsInIndex=true`);
		if (res4.ok) {
			this.hasUndeliveredEndpoint = true;
		}

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2' || this._ss.CustomerInformation.HasMultipleCompanies > 1) {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}
	}

	public MoveToClients() {
		this.router.navigate(['clients']);
	}

	public BackToHome() {
		this.router.navigate(['home']);
	}

	public BackToDohot() {
		history.back();
	}

	public MoveToadminbizoahevra() {
		this.router.navigate(['admin-bizoa-hevra']);
	}

	public MoveToadminbizoaazmanot() {
		this.router.navigate(['admin-bizoa-azmanot']);
	}

	public MoveToadmindohotproducts() {
		this.router.navigate(['admin-dohot-products']);
	}

	public MoveToAdminActiv() {
		this.router.navigate(['admin-activ']);
	}

	public CompanyChoosen(company) {
		this._ss.AdminCompanyChosen = company;

		setTimeout(() => {
			this.router.navigate([this.waitingToMoveTo], { queryParams: { company } });
		}, 0);
	}

	public NoChoose() {
		this.HasMultiplleCompanysPop = false;
	}

	waitingToMoveTo = '';

	checkIfHasMultipleCompanys(navPath: string) {
		this.online = window.navigator.onLine;

		if ((this.HasMultipleCompanys === '2' || this._ss.CustomerInformation.HasMultipleCompanies > 1) && this.online === true) {
			this.HasMultiplleCompanysPop = true;
			this.waitingToMoveTo = navPath;

			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		} else {
			this.router.navigate([navPath]);
		}
	}
}
