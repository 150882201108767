import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';
import { Subject, takeUntil } from 'rxjs';
@Component({
	selector: 'app-dohot-agent-categories-performance',
	templateUrl: './dohot-agent-categories-performance.component.html',
	styleUrls: ['./dohot-agent-categories-performance.component.scss'],
})
export class DohotAgentCategoriesPerformanceComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	public Bizoim = [];
	public BizoimSwitch;
	public BizoimSwitchMain;
	public DateToday = new Date();

	public toogleInputNew = false;

	public AllClientsSearch;

	public test;
	public test2;

	public FirstPage = true;
	public SecondPage = false;
	public ThirdPage = false;
	public FourthPage = false;

	public LastPage = false;

	public BizoimEach;
	public BizoimEach2;

	public ShowCompanys;

	public HasMultipleCompanys;

	public CompanyChoosen = 1;

	public OneTeudaInfo;

	public Loading = false;

	public LastPage2 = false;

	destroy$: Subject<void> = new Subject();

	currentClientNameAndID;

	performanceFilters = ['עומדים ביעדים', 'כמעט עומדים', 'אינם עומדים'];

	green = 'rgb(0, 209, 0)';
	orange = 'rgb(255 127 0)';
	red = 'red';
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Month: ['', Validators.required],
			ClientSearch: ['', Validators.required],
			Company: ['', Validators.required],
			performance: [],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this.Loading = true;
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			this._ss.getDohotAgentCategoriesPerformance().subscribe(res => {
				this.AllClientsSearch = res;
				this.AllClientsSearch = this.AllClientsSearch.recordset;
				this.BizoimSwitch = this.AllClientsSearch;

				// this.test = this.DateToday.toISOString().toString().slice(5, 7)
				// this.test2 = this.DateToday.toISOString().toString().slice(0, 4)

				// for (let x = 0; x < this.AllClientsSearch.length; x++) {
				//   this.AllClientsSearch[x].Yy1 = JSON.stringify(this.AllClientsSearch[x].Yy1)
				//   this.AllClientsSearch[x].Client_Name = this.AllClientsSearch[x].cn
				//   this.AllClientsSearch[x].ClientId = this.AllClientsSearch[x].cl

				//   this.Bizoim.push(this.AllClientsSearch[x])

				// }

				// this.BizoimSwitch = this.AllClientsSearch

				// this.AllClientsSearch = this.Bizoim

				// this.Bizoim.sort(function (a, b) {
				//   return b.ahuz - a.ahuz;
				// });

				this.form.controls['performance'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
					if (val === '0') {
						this.AllClientsSearch = this.BizoimSwitch.filter(biz => biz.ahuz >= 99);
						return;
					}
					if (val === '1') {
						this.AllClientsSearch = this.BizoimSwitch.filter(biz => biz.ahuz < 99 && biz.ahuz >= 50);
						return;
					}
					if (val === '2') {
						this.AllClientsSearch = this.BizoimSwitch.filter(biz => biz.ahuz < 50);
						return;
					}

					this.AllClientsSearch = this.BizoimSwitch;
				});

				this.Loading = false;

				// if (this._ss.ParamBitzoimClientCard !== null) {
				//   console.log(this._ss.ParamBitzoimClientCard);

				//   let data = this.Bizoim.find(ob => ob.cl === this._ss.ParamBitzoimClientCard);
				//   if (data !== undefined) {
				//     console.log(data);

				//     this.MoveToDohotBizoimLekohotEachYear({ClientId:data.cl, AgentId:data.ag, Company:data.cm, Year:data.Yy1,Client_Name:data.Client_Name})
				//     //{ClientId, AgentId, Company, Year,Client_Name}
				//   }
				// }
			});
		} else {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('AllProducts') !== null) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
			this.AllProductsSearch = this.AllProducts;
		} else {
			this.router.navigate(['clients']);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	changeFilter() {}

	changeMode(makeActiveElm: HTMLDivElement, disableActive: HTMLDivElement) {
		disableActive.classList.remove('active');
		makeActiveElm.classList.add('active');
	}

	public AH = false;
	public TYA = false;
	public lYA = false;

	public SortAhoz() {
		if (this.AH === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ahuz - a.Ahuz;
			});

			this.AH = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ahuz - b.Ahuz;
			});

			this.AH = false;
		}
	}

	public SortThisYear() {
		if (this.TYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Tot - a.Tot;
			});

			this.TYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Tot - b.Tot;
			});

			this.TYA = false;
		}
	}

	public SortLastYear() {
		if (this.lYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ltot - a.Ltot;
			});

			this.lYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ltot - b.Ltot;
			});

			this.lYA = false;
		}
	}

	public ChangeMonth() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].Mm1 === this.form.controls['Month'].value && this.BizoimSwitch[x].Yy1 === this.test2 && this.BizoimSwitch[x].cm === this.CompanyChoosen) {
				this.Bizoim.push(this.BizoimSwitch[x]);
			}
		}

		this.AllClientsSearch = this.Bizoim;
	}

	public ChangeCompany() {
		this.form.controls['Month'].reset();

		this.CompanyChoosen = JSON.parse(this.form.controls['Company'].value);

		this.AllClientsSearch = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].cm === JSON.parse(this.form.controls['Company'].value) && this.BizoimSwitch[x].Mm1 === this.test && this.BizoimSwitch[x].Yy1 === this.test2) {
				this.AllClientsSearch.push(this.BizoimSwitch[x]);
			}
		}

		this.Bizoim = this.AllClientsSearch;
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public BackToDohotBizoim() {
		if (this.FirstPage === true) {
			// this.router.navigate(['dohotbizoim'])
			window.history.back();
		}

		if (this.SecondPage === true) {
			this.SecondPage = false;
			this.FirstPage = true;
			this.BizoimEach = null;
		}

		if (this.ThirdPage === true) {
			this.ThirdPage = false;
			this.SecondPage = true;
			this.BizoimEach2 = null;
		}

		if (this.FourthPage === true) {
			this.FourthPage = false;
			this.ThirdPage = true;
			this.OneTeudaInfo = null;
		}
	}
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	public OneTeuda(number) {
		this.ThirdPage = false;
		this.FourthPage = true;

		this._ss.GetOneTeudaDohot(number).subscribe(res => {
			this.OneTeudaInfo = res;
			this.OneTeudaInfo = this.OneTeudaInfo.recordset;
		});
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	//THIS IS IN THE SAME PAGE NOT IN NEW PAGE
	public MoveToDohotBizoimLekohotEachYear({ ClientId, AgentId, Company, Year, Client_Name }) {
		//cl,b.ag,b.cm,b.Yy1

		this.currentClientNameAndID = `${ClientId}${Client_Name ? ' - ' + Client_Name : ''}`;

		this.Loading = true;
		this.FirstPage = false;
		this.SecondPage = true;
		this._ss.EachClientDohotYear(ClientId, Company, Year, AgentId).subscribe(res => {
			this.BizoimEach = res;
			this.BizoimEach = this.BizoimEach.recordset;

			this.Loading = false;
		});
	}

	public MoveToDohotBizoimLekohotEach(ClientId, Company, Month, Year) {
		this.Loading = true;
		this.SecondPage = false;
		this.ThirdPage = true;
		this._ss.EachClientDohot(ClientId, Company, Month, Year).subscribe(res => {
			this.BizoimEach2 = res;
			this.BizoimEach2 = this.BizoimEach2.recordset;

			this.Loading = false;
		});
	}

	//=====================================================================================================================================================================================================================================================================>
	public SearchClear() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			this.Bizoim.push(this.BizoimSwitch[x]);
		}

		this.form.reset();
	}
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public SearchClient() {
		this.Bizoim = [];

		if (this.form.controls['ClientSearch'].value === '' || this.form.controls['ClientSearch'].value.length === 0) {
			for (let x = 0; x < this.BizoimSwitch.length; x++) {
				this.Bizoim.push(this.BizoimSwitch[x]);
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 1) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1)
				) {
					this.Bizoim.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2)
				) {
					this.Bizoim.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length > 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				for (let z = 0; z < 50; z++) {
					if (
						this.AllClientsSearch[x].Client_Name.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length) ||
						this.AllClientsSearch[x].ClientId.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length) ||
						this.AllClientsSearch[x].CustCity.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length)
					) {
						let data = this.Bizoim.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
						if (data === undefined) {
							this.Bizoim.push(this.AllClientsSearch[x]);
						}
					}
				}
			}
		}
		//=====================================================================================================================================================================================================================================================================>
		//=====================================================================================================================================================================================================================================================================>
	}
}
