type Product = {
	IC: string;
	qt: number;
	discount: number;
	company: number;
	Stock: number;
	Product_Name: string;
	ProductId: string;
	BP: number;
	BD: number;
	BS: number;
	BM: number;
	ItemBarcode: string;
	IGC: string;
	IDGR: string;
	ItemPack: number;
	ItemPicName: string;
	MinPrice: number;
	Price: number;
	RE: string;
	BB: number;
	ItemExtra1: string;
	ItemExtra2?: number | string;
	ItemExtra3?: number | string;
	ItemExtra4?: number | string;
	ItemExtra5?: number | string;
	PKD: number;
	CPP: number;
	NetPrice: number;
	IsMainProduct?: 0 | 1;
	MGroup?: string;
};

export interface GetAllPricesAll {
	IC: string;
	qt: number;
	ClientId: string;
	NumOfTimes: number;
	DocPrice: number;
	DocDiscount: number;
	docquantity: number;
	discount: number;
	company: number;
	Stock: number;
	Product_Name: string;
	ProductId: string;
	BP: number;
	BD: number;
	BS: number;
	SpecByLevel: number;
	IDGR: string;
	BM: number;
	ItemBarcode: string;
	IGC: string;
	ItemPack: number;
	ItemPicName: string;
	MinPrice: number;
	Price: number;
	RE: string;
	BB: number;
	ItemExtra1: string;
	ItemExtra2: string;
	ItemExtra3: string;
	ItemExtra4: string;
	ItemExtra5: string;
	PKD: number;
	CPP: number;
	NetPrice: number;
	DocNetPrice: number;
	DocCreateDateDisplay: string;
}

export interface AllDocTypes {
	CheckMinPrice: number;
	NoObligoCheck: number;
	OpenBodd: number;
	MustCustRem1: number;
	MustCustRem2: number;
	MustCustRem3: number;
	MustCustRem4: number;
	AgentID: string;
	ClientID: string;
	DocType: number;
	DocName: string;
	IsShrion: number;
	Company: number;
	Show: number;
}

export type DocTypesNumToName = {
	DocTypeName: string;
	DocType: number;
};

export interface AllProducts extends Product {}

export interface AllProductsPricess {
	ClientId: string;
	dsc: number;
	cm: number;
	it: string;
	mn: number;
	prof: number;
	prc: number;
	Price: number;
}

export interface ShirionProducts extends Product {
	ClientId: string;
	NumOfTimes: number;
	DocPrice: number;
	DocDiscount: number;
	docquantity: number;
	DocCreateDateDisplay: string;
}

export interface AllDebts {
	AgentId: string;
	DebtCustCode: string;
	DebtDocNum: string;
	DebtDocType: number;
	DebtDocCreateDate: string;
	DebtDocToPayDate: string;
	DebtDocTotal: number;
	DebtDocOpenToPay: number;
	DebtRemark: string;
	DebtColor: string;
	Company: number;
	DocTypeName: string;
}

export interface AllProductsLikut {
	ProductId: string;
	ItemGroupCode: string;
	ItemDiscountGroupID: string;
	Product_Name: string;
	ItemBarcode: string;
	ItemPack: number;
	ItemVat: number;
	ItemRemark: string;
	ItemPicName: string;
	ItemOrderInList: string;
	ItemParams: number;
	ItemExtra1: string;
	ItemExtra2: string;
	ItemExtra3: string;
	ItemExtra4: string;
	ItemExtra5: string;
	ItemColor: string;
	Price: number;
	discount: number;
	Stock: number;
	ArizotPrice: number;
	ERPCreateDate: string;
	DefaultOrderBY: string;
	ItemCompany: number;
	RowNumber: number;
}
export interface StockOriginal {
	StockWareHouse: string;
	StockItemCode: string;
	Stock: number;
	StockOrder: number;
	StockBatch: string;
	StockOpen: number;
	Level: number;
}

export interface AllClients extends DocTypesNumToName {
	AM: number;
	AO: number;
	DSP: number;
	BCU: number;
	BCO: number;
	CPL: string;
	ClientId: string;
	Client_Name: string;
	Ldt?: any;
	CustCity: string;
	Address: string;
	CustDiscount: number;
	CMC: number;
	Cm: string;
	COC: number;
	COD: number;
	PTN: string;
	CustVat: number;
	HM: number;
	CE1: string;
	CE2: string;
	CE3: string;
	CE4: string;
	CE5: string;
	Hearot: string;
	Phone: string;
	company: number;
	CC: string;
	CustEMail?: string;
	CustEmail?: string;
	CustMobile?: string;
	AddObligo?: number;
	Knisa?: string;
	Coordinates?: string;
}

export interface ProductGroups {
	GroupId: string;
	GroupName: string;
	company: number;
}

export type AllMGroup = {
	ag: string; // סוכן
	cm: number; // חברה
	mg: string; // mainGroupID
	gr: string; // subGroupID
	mgn: string; // mainGroupName
	gn: string; // subGroupName
};

export type ConcentratedProducts = {
	AgentID: string;
	company: number;
	MainProductID: string;
	ProductID: string;
	ItemColor: string;
	ItemSize: string;
};

export type ItemPacks = {
	ProductID: string;
	Unit: string;
	UnitPerPack: number;
	PriceChange: number;
	company: number;
};

export type CustomerInformation = {
	CustNum: string;
	CustName: string;
	CustServer: string;
	CustERP: string;
	CustNotes: string;
	CustNotes1: string;
	CustDomain: string;
	CustImageDomain: string;
	CustDashbord: string;
	CustVersion: string;
	CustAhuzAnaha: string;
	CustSeeImages: string;
	CustSeeImageGeneral: string;
	CustShowExtraHarot: string;
	CustBlockParam3: string;
	CustSeeArizot: string;
	CustSeePrice: string;
	CustChangeCss: string;
	CustChangeCss1: string;
	CustChangeCss2: string;
	CustChangeCss3: string;
	CustChangeCss4: string;
	CustChangeCss5: string;
	CustExtra: string;
	CustExtra1: string;
	CustExtra2: string;
	CustExtra3: string;
	CustExtra4: string;
	CustExtra5: string;
	CustIP: string;
	CustChange2: string;
	CustChange3: string;
	CustChange4: string;
	CustChange5: string;
	CustChange6: string;
	CustChange7: string;
	CustChange8: string;
	CustChange9: string;
	CustChange1: string;
	SeeDeliveryOptions: number;
	EditProductName: string;
	EditTeuda: string;
	SeeProductName: string;
	SeeBarcode: string;
	SeeProductId: string;
	BlueOrRed: string;
	AnyDesk: string;
	HasMobilityService: number;
	EditDate: number;
	ShowProduct: string;
	InputBarcodeNumeric: '0' | '1' | '2' | '3';
	SkipRequest: string;
	OrderByInDashboard: string;
	CheckStockBeforeSend: string;
	DisplayWithVat: string;
	Submanagers: any;
	CopyRemark: string;
	SendPic: string;
	RekActive: string;
	Graph: any;
	PrintOrder: string;
	PDFDirectory: string;
	ItemExtraTitle: string;
	AgentSeeDashboard: string;
	IsPackDefault: string;
	HideMinPrice: string;
	ShowFileDownload: string;
	LoadDebtOnUpdate: string;
	BlockCopyCustPrice: string;
	ShowObligoInstead: string;
	PasswordFromMobility: string;
	CustomLogoutCode: string;
	AddToSupplyDate: string;
	HideStock: '0' | '1' | '2' | '3' | '4' | '5' | '6';
	EnforceDefaultOrderBy: string;
	RecvVproductsWhenEnter: string;
	AllProductsHistoryFirst: string;
	SpecificCompanyVersion: '0' | '1';
	CanProduceReceipt: string;
	RefreshClients: string;
	DisplayLogo: string;
	UpdateLogic: string;
	ProductExtraInfo: string;
	CanSendEmail: string;
	B2BOptions: string;
	OrderDepartments: string;
	PopularDefault: string;
	ReportsOptions: string;
	UnBlockQty: string;
	SortProductsBy: string;
	BonusDisplay: string;
	DiscountLogic: string;
	BlockChangingPriceBonusDiscountOrQuantities: string;
	DisplayRemark: any;
	HasMultipleCompanies: string;
	ItemPackDisplay: string;
	ShowInlineProduct: string;
	SearchLogic: string;
	HeaderDisplay: string;
	ItemExtrasFilter: string;
	OfflineLogic: string;
	MainColor: string;
	MinimumTotalOrderB2B: string;
	ClientsPageExtraInfo: string;
	SortByLastDateTeuda: string;
	AlertHearotWhenEnter: string;
	OnlyInClientPriceList: string;
	DisplayDohotTeudot: any;
	HasSpecials: string;
	SortWhenEnter: string;
	YaadAgents: string;
	YaadClients: string;
	ProductsFilterRow: string;
	TemporaryPassword: string;
	SendDiscount: string;
	TeudaItemRemark: string;
	CheckPassword: string;
	ColorClientName: string;
	AlternativeAdminReports: string;
	StockQuantity: string;
	ProductDescription: string;
	HideReport: string;
	HideQuantityInPack: string;
	BlockIncreasePrice: string;
	BikorMustIncludeReason: string;
	CanChangePermPrice: string;
	GetByMainCustID: string;
	ShowDebtTerm: '0' | '1' | '2' | '3';
	NeedsPasswordFromMobility: string;
	ProductExtraInfoOrder: string;
	WhatsappB2BL: string;
	SendCoordinates: string;
	MainColorCompany2: string;
	LikutType: string;
	B2BCanSeePrices: string;
	FilterMehironimOnBackFromTeuda: string;
	LikutReportsCanSee: string;
	BlockYehidotPlusIfBelowStockOne: string;
	SearchExtraTeudaTypeFromSave: string;
	SearchExtraProductFilter: string;
	LikutSearch: string;
	LikutRemarkExtra1: string;
	GetOriginalStock: string;
	ImageFilenameLookup: string;
	PackQuantityText: string;
	BlockClientTeudaSend: string;
	StockWithItemPack: string;
	MaxProductNameLength: string;
	MaslulDefaultClients: string;
	VisitsPlannedVsUnplanned: string;
	LoadingAndDistribution: string;
	LoadingAndDistribution_Quantities: string;
	ConcentratedProducts: string;
	ConcentratedProducts_Display: string;
	ShowVisitInClientsPage: string;
	VisitsTakePicture: string;
	VisitsHasNoSaleOption: string;
	SpecialOnlyIfRemarkContains: string;
	LikutPrice: string;
	LoadingAndDistribution_Manager_Loading: string;
	LoadingAndDistribution_TaskAssignmentExtra: string;
	LoadingAndDistribution_TaskAssignmentDisplay: string;
	LoadingAndDistribution_CanAttachPictures: string;
	LoadingAndDistribution_Manager_POD: string;
	ShowSpecificationsFileDownload: string;
	CalculatedDiscount: string;
	DashboardSeeTrucks: string;
	LookupDoctypeBeforeEntry: string;
	LoadingAndDistribution_PODExtra5: string;
	LoadingAndDistribution_ShowPDF: string;
	ProductName_AddItemExtra: string;
	TotalOrderOverride: string;
	AllDebtsReport: string;
	Suppliers: string;
	ProductWeightType: string;
	ProductBigViewOptions: string;
	LastQuantityClick: string;
	AgentPersonalAlert: string;
	SortCities: string;
	LoadingAndDistribution_GroupByDocNum: string;
	LikutRefreshStock: string;
	YaadGroup: string;
	ShowTranslatedQuantity: string;
	HasDocMinTeuda: string;
	VisitMultipleReasons: string;
	SortProductGroups: string;
	LoadingAndDistribution_PODPDFOptions: string;
	HasRallyanceReclamation: string;
	HideSendAll: string;
	SortByStock: string;
	GroupSum: string;
	LikutBarcode: string;
	HideQuantityWhenNoStock: string;
};

export type DocTypeDefinitions = {
	DocType: number;
	DocTypeName: string;
	DocParams: number;
	DocAllowB2B: number;
	DocMustWrtRemark: number;
	DocHidePrice: number;
	DocUpdateStock: number;
	DocCheckStock: number;
	DocCheckMinPrice: number;
	DocPs: number;
	DocVS: number;
	Typeof?: 0 | 1 | 2;
	Color?: string;
	DocOptions?: string;
};

export type MustIncludeProducts = {
	id: number;
	clientid: string;
	agent: string;
	doctype: number;
	JSON: string;
};

export type MustIncludeProductsPOSTBody = Omit<MustIncludeProducts, 'id'>;

export const mustIncludeOperators = ['gt', 'lt', 'gtTotal', 'ltTotal'] as const;
export const mustIncludeActions = ['stop', 'alert'] as const;
export type MustIncludeProductsActions = typeof mustIncludeActions[number];
export type MustIncludeProductsOperators = typeof mustIncludeOperators[number];
export type MustIncludeProductsJSONPart = {
	productsA: string[];
	productsB: string[];
	operator: MustIncludeProductsOperators;
	quantity: number;
	action: MustIncludeProductsActions;
};

export type MustIncludeProductsParsed = Omit<MustIncludeProducts, 'JSON'> & {
	JSON: MustIncludeProductsJSONPart;
};

export type V_Maslul = {
	id: number;
	ag: string;
	cl: string;
	dt: string;
	rem: string;
	cm: number;
};

export type Activities = {
	ActivityID: string;
	OwnerAgentID: string;
	ClientID: string;
	DocType: number;
	DocNum: string;
	ActivityTypeID: number;
	DateTime: string;
	Remark: string;
	ResultID: string;
	RegardingID: string;
	IsClosed: boolean;
	IsCanceled: boolean;
	FollowUpDetails: string;
	ArrivalTime: string;
	DepartureTime: string;
	Company: number;
};

export type HearotHeadForTeuda = {
	FName: string;
	NewHeadLine: string;
};
