import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { RallyanceReclamationSQLResponse } from 'src/app/Interfaces/reclamations';
import { AlertService } from 'src/app/services/alert.service';
import { AppStyleService } from 'src/app/services/app-style.service';
import { IndexedDBService } from 'src/app/services/indexed-db.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ReclamationService } from 'src/app/services/reclamation.service';
import { StoreService } from 'src/app/services/store.service';
import { TextService } from 'src/app/services/text.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import dummyResponse from './dummyResponse.json';

@Component({
	selector: 'app-reclamation-tasks',
	templateUrl: './reclamation-tasks.component.html',
	styleUrls: ['./reclamation-tasks.component.scss'],
})
export class ReclamationTasksComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, private _fb: FormBuilder, private router: Router, public _text: TextService, private _util: UtilService, private _reclamation: ReclamationService) {}

	form = this._fb.nonNullable.group({
		ClientSearch: [''],
	});
	dateString = Intl.DateTimeFormat('he-il', { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }).format(+new Date());
	windowWidth = innerWidth;

	tasks$ = this._reclamation.allTasks$;
	tasksDisplay$ = combineLatest([this.tasks$, this.form.controls.ClientSearch.valueChanges.pipe(startWith(''))]).pipe(
		map(([tasks, search]) => {
			if (!search) return tasks;
			return tasks.filter(task => task.ClientId.includes(search));
		}),
	);
	totalTasks$ = this.tasks$.pipe(map(tasks => tasks.length));
	totalTasksCompleted$ = this.tasks$.pipe(map(tasks => tasks.filter(task => !!task.RekStatus).length));

	destroy$ = new Subject<void>();

	zoomLevel = this.windowWidth > 767 && this.windowWidth < 1099 ? 1.2 : 1;

	itemSize = this.windowWidth > 768 ? (this.windowWidth > 1099 ? 146 : 106) : 82;

	isLocal = !environment.production;

	hasReports = false;

	Admin = '';

	ngOnInit(): void {
		this._text.store.mainTasks = 'משימות רקלמציה';

		this.getReclamationTasks();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	BackToHome() {
		this.router.navigate(['home']);
	}

	SearchClient() {}
	ClearClientSearch() {
		this.form.controls.ClientSearch.reset();
		this.SearchClient();
	}

	handleClicked(task: RallyanceReclamationSQLResponse) {
		this._reclamation.selectNewTask(task);
		this._reclamation.navigateToReclamatorTasks('finish');
	}

	getReclamationTasks() {
		// if (this._reclamation.allTasks$.value.length) {
		// 	return;
		// }

		this.handleUpdateTasks();
	}

	handleUpdateTasks() {
		this._ss.closeMenu();
		// if (environment.development) {
		// 	//@ts-ignore
		// 	this.tasks$.next(dummyResponse);
		// 	return;
		// }
		this.pipeADestroyer(this._reclamation.getReclamationTasks()).subscribe({
			next: res => {
				if (typeof res === 'string') {
					alert('ישנה תקלת תקשורת, נסה שנית מאוחר יותר');
					history.back();
					return;
				}
				this.tasks$.next(res.recordset);
			},
			error: err => {
				alert('ישנה תקלת תקשורת, נסה שנית מאוחר יותר');
				console.error(err);

				history.back();
				return;
			},
		});
	}

	pipeADestroyer<T>(method: Observable<T>) {
		return this._util.pipeASubjectDestoryer(method, this.destroy$);
	}
}
