import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { decompress } from 'lz-string';
import { BehaviorSubject, catchError, from, map, mergeMap, Observable, of, Subject, takeUntil, throwError } from 'rxjs';
import { ExcelObject, UtilService } from 'src/app/services/util.service';
import { utils, writeFile } from 'xlsx';
import { ReturnMonthHebrewPipe } from 'src/app/Pipes/return-month-hebrew.pipe';
import { BaseComponent } from '../base-component/base.component';

const SecondValues = ['ag', 'cm', 'tot', 'ltot', 'ahuz', 'AgentCode', 'total', 'currentYearGoal', 'growthPercentage', 'lastYearTotal', 'advancementPercentage', 'CompanyName'] as const;
const ThirdValues = ['mm', 'tot', 'ltot', 'ahuz', 'cm', 'MM', 'Tot', 'currentYearGoal', 'Ltot', 'Ahuz'] as const;
const FourthValues = ['Cl', 'cn', 'tot', 'ltot', 'ahuz', 'cm'] as const;
const FifthValues = ['dd', 'dn', 'tsum'] as const;
const SixthValues = ['pr', 'pn', 'prc', 'qu', 'ds'] as const;
// const dummyValue = [''] as const;

const valuesObject = {
	Second: SecondValues,
	Third: ThirdValues,
	Fourth: FourthValues,
	Fifth: FifthValues,
	Sixth: SixthValues,
} as const;

@Component({
	selector: 'app-admin-bizoa-hevra',
	templateUrl: './admin-bizoa-hevra.component.html',
	styleUrls: ['./admin-bizoa-hevra.component.scss'],
})
export class AdminBizoaHevraComponent extends BaseComponent implements OnInit, OnDestroy {
	constructor(public router: Router, public _fb: FormBuilder, public _util: UtilService, private _acr: ActivatedRoute) {
		super();
	}

	public form: FormGroup;
	public AllClientsSearch;
	public AgentsSalesAll;
	public AgentsSalesAllSecond;
	public AgentsSalesAllThird;
	public AgentsSalesAllFourth;
	public AgentsSalesAllFifth = [];
	public OneTeudaInfo = [];

	public HasMultipleCompanys;
	public ShowCompanys;

	public First = true;
	public Second = false;
	public Third = false;
	public Fourth = false;
	public Fifth = false;
	public Sixth = false;

	loading = true;

	AgentSalesAllSecondMonth;
	AgentsSalesAllSecondWithGoals$ = new BehaviorSubject<any>([]);

	agentResponseWithGoals = [];

	agentsToGet = [];

	agentsFromV_Users;

	destroy$ = new Subject<void>();

	displayTotal$ = new BehaviorSubject<number>(0);
	displayAverage$ = new BehaviorSubject<number>(0);

	hasAgentsGoals = false;

	isClosed = true;

	monthOriginalRes = [];
	isInMonthView = false;
	selectedMonth;

	windowWidth = innerWidth;

	excelFilename$: Observable<string>;
	currentPage$ = new BehaviorSubject<'First' | 'Second' | 'Third' | 'Fourth' | 'Fifth' | 'Sixth'>('First');
	title$: Observable<string>;
	showSearch$: Observable<boolean>;
	@ViewChild('secondTable') secondTable: ElementRef<HTMLTableElement>;

	async ngOnInit() {
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this._ss.CustomerInformation.HasMultipleCompanies !== '0') {
			this.HasMultipleCompanys = Number(this._ss.CustomerInformation.HasMultipleCompanies) + 1;
		}

		this.setupPipes();

		if (this.HasMultipleCompanys > 1) {
			await new Promise<void>(resolve => {
				this._ss.GetCompaniesWithoutAgent().subscribe({
					next: res => {
						if (typeof res !== 'string' && res.recordset && res.recordset.length) {
							this.ShowCompanys = res.recordset;
							localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
						} else {
							this.ShowCompanys = [{ Company: 1, CompanyName: this._ss.CustomerInformation.CustName }];
						}
						resolve();
					},
					error: err => {
						resolve();
					},
				});
			});

			if (!this.ShowCompanys) {
				if (localStorage.getItem('ShowCompanys') !== null) {
					this.ShowCompanys = localStorage.getItem('ShowCompanys');
					this.ShowCompanys = JSON.parse(this.ShowCompanys);
					this.ShowCompanys.sort((a, b) => a.Company - b.Company);
				} else {
					this._ss.GetComppanys().subscribe(res => {
						this.ShowCompanys = res;
						this.ShowCompanys = this.ShowCompanys.recordset;
						this.ShowCompanys.sort((a, b) => a.Company - b.Company);
						localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
					});
				}
			}
		} else {
			this.ShowCompanys = [{ Company: 1, CompanyName: this._ss.CustomerInformation.CustName }];
		}

		this.form = this._fb.group({
			ClientSearch: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (this._ss.CustomerInformation.YaadAgents === '1') {
			this.hasAgentsGoals = true;
		}

		if (this._ss.openDohotThirdOnAgent) {
			const { ag, agn } = this._ss.openDohotThirdOnAgent;
			this.First = false;
			this.ThirdPageAdminAgentsSales(ag, 1, agn);
			return;
		}

		if (this._ss.openDohotFourthOnAgent) {
			const { ag, agn, month } = this._ss.openDohotFourthOnAgent;
			this.First = false;
			this.selectedAgent = ag + ' ' + agn;
			this.FourthPageAdminAgentsSales(+month + 1, new Date().getFullYear(), ag, 1);
			return;
		}

		if (this._ss.CustomerInformation.Submanagers == '1') {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res == 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							return;
						}

						this.agentsToGet = res.recordset.map(obj => obj.Agent);
						this.agentsToGet.push(this._ss.AgentIdConnected);

						this._ss.agentsOfSubmanager = this.agentsToGet;

						this.getFirstPage();
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getFirstPage();
			}
		} else {
			this.getFirstPage();
		}
	}

	ngOnDestroy(): void {
		this._ss.openDohotThirdOnAgent = this._ss.openDohotFourthOnAgent = undefined;

		this.destroy$.next();
		this.destroy$.complete();
	}

	refreshSubjects(mode: 'agentsSalesAll' | 'agentsSalesAllSecond' | 'agentsSalesAllThird' | 'agentsSalesAllFourth' | 'agentsSalesAllFifth') {
		let t = this._ss.CustomerInformation.CustChange3 == '1' || this._ss.CustomerInformation.YaadAgents == '1';

		if (mode === 'agentsSalesAll') {
			const byMonth = this.openedCompanyIndex !== null;
			const arr = this.AgentsSalesAll.filter(sale => (byMonth ? sale.isMonth : !sale.isMonth));
			this.displayTotal$.next(arr.reduce((p, c) => p + (c.tot ?? c.Tot), 0));
			this.displayAverage$.next(arr.reduce((p, c) => p + (c.tot ?? c.Tot), 0) / (byMonth ? new Date().getMonth() + 1 : arr.length));
			this.currentPage$.next('First');
			return;
		}

		if (mode === 'agentsSalesAllSecond') {
			this.currentPage$.next('Second');
			const arr = this.AgentsSalesAllSecondWithGoals$.value.length ? this.AgentsSalesAllSecondWithGoals$.value : this.AgentsSalesAllSecond;
			if (!arr) return;
			this.displayTotal$.next(arr.reduce((p, c) => p + (c.total ?? c.Tot ?? c.tot), 0));
			this.displayAverage$.next(arr.reduce((p, c) => p + (c.total ?? c.Tot ?? c.tot), 0) / (arr.length || 1));
			return;
		}

		if (mode === 'agentsSalesAllThird') {
			this.currentPage$.next('Third');
			if (!this.AgentsSalesAllThird) return;

			this.displayTotal$.next(this.AgentsSalesAllThird.reduce((p, c) => p + (c.tot ?? c.Tot), 0));
			this.displayAverage$.next(this.AgentsSalesAllThird.reduce((p, c) => p + (c.tot ?? c.Tot), 0) / (this.AgentsSalesAllThird.length ? new Date().getMonth() + 1 : 1));

			return;
		}

		if (mode === 'agentsSalesAllFourth') {
			this.displayTotal$.next(this.AgentsSalesAllFourth.reduce((p, c) => p + (c.tot ?? c.Tot), 0));
			this.displayAverage$.next(this.AgentsSalesAllFourth.reduce((p, c) => p + (c.tot ?? c.Tot), 0) / (this.AgentsSalesAllFourth.length || 1));
			this.currentPage$.next('Fourth');
			return;
		}

		if (mode === 'agentsSalesAllFifth') {
			this.displayTotal$.next(this.AgentsSalesAllFifth.reduce((p, c) => p + c.tsum, 0));
			this.displayAverage$.next(this.AgentsSalesAllFifth.reduce((p, c) => p + c.tsum, 0) / (this.AgentsSalesAllFifth.length || 1));
			this.currentPage$.next('Fifth');
			return;
		}
	}

	public BackToAdmin() {
		if (this.First === true) {
			history.back();
		}

		if (this.Second === true) {
			this.First = true;
			this.Second = false;

			this.refreshSubjects('agentsSalesAll');
		}

		if (this.Third === true) {
			if (this._ss.openDohotThirdOnAgent) {
				history.back();
			}
			this.Second = true;
			this.Third = false;

			this.refreshSubjects('agentsSalesAllSecond');
		}

		if (this.Fourth === true) {
			if (this._ss.openDohotFourthOnAgent) {
				history.back();
			}

			if (this.isInMonthView) {
				this.Fourth = false;
				this.Second = true;
				this.refreshSubjects('agentsSalesAllSecond');
				return;
			}
			this.Third = true;
			this.Fourth = false;
			this.DayArray = [];

			this.refreshSubjects('agentsSalesAllThird');
		}

		if (this.Fifth === true) {
			this.Fourth = true;
			this.Fifth = false;

			this.refreshSubjects('agentsSalesAllFourth');
		}

		if (this.Sixth === true) {
			this.Fifth = true;
			this.Sixth = false;

			this.refreshSubjects('agentsSalesAllFifth');
		}
	}

	getFirstPage() {
		const currentYear = new Date().getFullYear();

		if (!this.agentsToGet.length) {
			this._ss.GetAllAgents().subscribe(res => {
				if (typeof res == 'string') {
					console.log("Couldn't get agents");
					return;
				}

				this.agentsFromV_Users = res.recordset.filter(ag => !ag.Admin.includes('b2b'));
			});
		}

		if (this._ss.CustomerInformation.CustChange3 === '1' || this._ss.CustomerInformation.YaadAgents == '1') {
			const cm = this.ShowCompanys && this.ShowCompanys.length ? this.ShowCompanys.length : 1;

			this._ss.getAgentsGoals(this.agentsToGet, cm).subscribe({
				next: (res: any) => {
					this.AgentsSalesAllSecond = res.recordset.reduce((prev, curr) => {
						const agent = prev.find(arr => arr?.AgentCode == curr.AgentCode && arr.cm === (curr.cm || 1));
						let cmName = this._ss.CustomerInformation.CustName;

						if (this.ShowCompanys && this.ShowCompanys.length && this.HasMultipleCompanys > 1) {
							const company = this.ShowCompanys[curr.cm - 1];
							if (company) {
								cmName = company.CompanyName;
							}
						}

						if (agent) {
							agent.currentYearGoal += curr.Year === currentYear ? curr.Yaad2 || curr.Yaad : null;
							// agent.previousYearGoal += curr.Year === currentYear ? null : curr.Yaad2 || curr.Yaad;
							agent.total += curr.Tot || 0;
							agent.lastYearTotal += curr.Ltot || 0;
						} else {
							prev.push({
								AgentCode: curr.AgentCode,
								AgentName: curr.AgentName,
								currentYearGoal: curr.Year === currentYear ? curr.Yaad2 || curr.Yaad : 0,
								// previousYearGoal: curr.Year === currentYear ? 0 : curr.Yaad2 || curr.Yaad,
								total: curr.Tot || 0,
								lastYearTotal: curr.Ltot || 0,
								cm: curr.cm || 1,
								CompanyName: cmName,
							});
						}
						return prev;
					}, []);

					this.AgentsSalesAllSecond = this.AgentsSalesAllSecond.map(gl => {
						if (gl.total && gl.currentYearGoal) {
							gl.advancementPercentage = (gl.total / gl.currentYearGoal) * 100;
						}
						if (gl.total && gl.lastYearTotal) {
							gl.growthPercentage = (gl.total / gl.lastYearTotal) * 100;
						}
						return gl;
					});

					this.AgentsSalesAll = res.recordset.reduce((prev, curr) => {
						const cm = curr.cm || 1;

						let cmName = this._ss.CustomerInformation.CustName;
						if (this.ShowCompanys && this.ShowCompanys.length && this.HasMultipleCompanys > 1) {
							const company = this.ShowCompanys[cm - 1];
							if (company) {
								cmName = company.CompanyName;
							}
						}

						if (prev[cm]) {
							prev[cm].tot += curr.Tot;
							prev[cm].ltot += curr.Ltot;

							// += false turns into 0
							if (currentYear === curr.Year) {
								prev[cm].currentYearGoal += curr.Yaad2 || curr.Yaad || 0;
							}
						} else {
							prev[cm] = {
								tot: curr.Tot || 0,
								ltot: curr.Ltot || 0,
								currentYearGoal: curr.Yaad2 || curr.Yaad || 0,
								cm,
								CompanyName: cmName,
							};
						}

						return prev;
					}, []);

					this.AgentsSalesAll = this.AgentsSalesAll.filter(obj => obj);

					this.agentResponseWithGoals = res.recordset;

					this.AgentsSalesAll = this.AgentsSalesAll.map(obj => ({
						...obj,
						ahuz: isFinite(obj.tot / obj.ltot) ? (obj.tot / obj.ltot) * 100 : 0,
						advancementPercentage: isFinite(obj.tot / obj.currentYearGoal) ? (obj.tot / obj.currentYearGoal) * 100 : 0,
					}));

					if (this.HasMultipleCompanys > 1) {
						for (let x = 0; x < this.AgentsSalesAll.length; x++) {
							for (let y = 0; y < this.ShowCompanys.length; y++) {
								if (this.AgentsSalesAll[x].cm === this.ShowCompanys[y].Company) {
									this.AgentsSalesAll[x].CompanyName = this.ShowCompanys[y].CompanyName;
								}
							}
						}
					}

					setTimeout(() => {
						this.refreshSubjects('agentsSalesAll');
					}, 0);

					this.loading = false;
				},
				error: err => {
					alert('שגיאת תקשורת, אנא נסה שנית מאוחר יותר.');
				},
			});
		} else {
			this._ss.AdminAgentsSales(this.agentsToGet).subscribe({
				next: res => {
					this.AgentsSalesAll = res;
					this.AgentsSalesAll = this.AgentsSalesAll.recordset;

					// if (this.HasMultipleCompanys > 1) {
					for (let x = 0; x < this.AgentsSalesAll.length; x++) {
						for (let y = 0; y < this.ShowCompanys.length; y++) {
							if (this.AgentsSalesAll[x].cm === this.ShowCompanys[y].Company) {
								this.AgentsSalesAll[x].CompanyName = this.ShowCompanys[y].CompanyName;
							}
						}
					}
					// }

					this.loading = false;

					this.refreshSubjects('agentsSalesAll');
				},
				error: err => {
					alert('ישנה תקלה בשרת');
					history.back();
					this.loading = false;
				},
			});
		}
	}

	public SecondPageAdminAgentsSales(company, month?) {
		this.First = false;
		this.Second = true;

		this.loading = true;
		if (typeof month === 'number') {
			if (this.hasAgentsGoals) {
				this.SecondPageAdminAgentsSalesMonthWithGoals(month);
				return;
			}
			this.SecondPageAdminAgentsSalesMonth(month);
			return;
		}

		this.isInMonthView = false;

		let cm;

		if (this.ShowCompanys && this.ShowCompanys.length > 1) {
			cm = this.ShowCompanys.find(c => c.Company === company);
		}

		if (this._ss.CustomerInformation.CustChange3 !== '1' && this._ss.CustomerInformation.YaadAgents !== '1') {
			this._ss.AdminAgentsSalesSecond(company, this.agentsToGet).subscribe(
				res => {
					if (typeof res === 'string') {
						alert('ישנה תקלה בשרת, לא ניתן לראות נתונים');
						return history.back();
					}
					this.AgentsSalesAllSecond = res.recordset;

					this.AgentsSalesAllSecond.sort((a, b) => (a.AgentCode ?? a.ag) - (b.AgentCode ?? b.ag));

					this._ss.GetAllAgents().subscribe({
						next: res => {
							if (typeof res == 'string') {
								console.log("Couldn't get agents");
								this.loading = false;
								return;
							}

							this.agentsFromV_Users = res.recordset;

							const usersHash = this.agentsFromV_Users.reduce((prev, curr) => {
								prev[curr.AgentID] = { Admin: curr.Admin, UserName: curr.UserName };
								return prev;
							}, {});

							if (this.agentsFromV_Users && this.agentsFromV_Users.length) {
								this.AgentsSalesAllSecond = this.AgentsSalesAllSecond.map(agent => ({
									...agent,
									...usersHash[agent.ag],
									agn: usersHash[agent.ag]?.UserName,
								}));
								console.log(this.AgentsSalesAllSecond);
							}

							this.AgentsSalesAllSecond = this.AgentsSalesAllSecond.filter(ag => ag.Admin !== 'b2b');

							// this.AgentsSalesAllSecond.sort((a,b)=> (a.AgentCode ?? a.ag) - (b.AgentCode ?? b.ag))

							console.log(this.AgentsSalesAllSecond);

							this.refreshSubjects('agentsSalesAllSecond');
							this.loading = false;
						},
						error: err => {
							console.log("Couldn't get agents");
							this.loading = false;

							this.refreshSubjects('agentsSalesAllSecond');
						},
					});
				},
				err => {
					alert('ישנה תקלה בשרת');
					history.back();
				},
			);
		} else {
			this.AgentsSalesAllSecondWithGoals$.next(this.AgentsSalesAllSecond.filter(obj => obj.cm === company));
			this.refreshSubjects('agentsSalesAllSecond');
			this.loading = false;
		}
	}

	SecondPageAdminAgentsSalesMonth(month) {
		const agentsArr = this.agentsToGet.length ? this.agentsToGet : this.agentsFromV_Users;

		console.log({ agentsArr });

		this.AgentsSalesAllSecond = agentsArr.map(agArr => ({ ...agArr, agn: agArr.UserName, ...this.monthOriginalRes[agArr.AgentID][month] }));

		this.loading = false;
		this.isInMonthView = true;
		this.selectedMonth = month;
		this.refreshSubjects('agentsSalesAllSecond');
	}

	SecondPageAdminAgentsSalesMonthWithGoals(month) {
		const agentsArr = this.agentsToGet.length ? this.agentsToGet.map(ag => ({ AgentID: ag })) : this.agentsFromV_Users;

		const goalsObj: Record<string, any>[] = this.generateGoalsArr(this.openedCompanyIndex + 1, 'object');

		console.log({ goalsObj, agentsArr, cm: this.ShowCompanys });

		this.AgentsSalesAllSecondWithGoals$.next(
			agentsArr
				.filter(ag => goalsObj[ag.AgentID])
				.map((agArr): any => {
					const goal = goalsObj[agArr.AgentID][month];
					console.log(goal);
					if (!goal) return;
					const { Tot, Ltot, Yaad, Yaad2, AgentCode, AgentName, cm } = goal;
					return {
						AgentCode,
						AgentName,
						CompanyName: this.ShowCompanys.find(c => c.Company === cm)?.CompanyName,
						total: Tot,
						lastYearTotal: Ltot,
						currentYearGoal: Yaad2 || Yaad || 0,
						growthPercentage: isFinite(Tot / Ltot) ? (Tot / Ltot) * 100 : 0,
						advancementPercentage: isFinite(Tot / (Yaad2 || Yaad)) ? (Tot / (Yaad2 || Yaad)) * 100 : 0,
					};
				}),
		);

		this.loading = false;
		this.isInMonthView = true;
		this.selectedMonth = month;
		this.refreshSubjects('agentsSalesAllSecond');
	}

	selectedAgent = null;
	agentName = '';

	public ThirdPageAdminAgentsSales(ag, company, name = '') {
		this.Second = false;
		this.loading = true;
		if (this.isInMonthView) {
			this.FourthPageAdminAgentsSales(this.selectedMonth + 1, new Date().getFullYear(), ag, this.openedCompanyIndex + 1);

			return;
		}

		console.log(company);
		this.AgentsSalesAllThird = [];
		this.Third = true;
		this.selectedAgent = ag;
		this.agentName = name;

		if (this._ss.CustomerInformation.CustChange3 !== '1' && this._ss.CustomerInformation.YaadAgents !== '1') {
			this._ss.AdminAgentsSalesThird(ag, company).subscribe(
				res => {
					if (typeof res === 'string') {
						alert('ישנה תקלה בשרת');
						return history.back();
					}
					this.AgentsSalesAllThird = res.recordset;

					this.refreshSubjects('agentsSalesAllThird');

					this.loading = false;
				},
				err => {
					alert('ישנה תקלה בשרת');
					history.back();
				},
			);
		} else {
			this._ss.getBizoimOfAgent(ag, company).subscribe({
				next: (res: any) => {
					this.AgentsSalesAllThird = res.recordset.map(goal => ({
						...goal,
						currentYearGoal: goal.Yaad2 || goal.Yaad || 0,
						growthPercentage: (goal.Yaad2 || goal.Yaad) && goal.Tot ? (goal.Tot / (goal.Yaad2 || goal.Yaad)) * 100 : 0,
					}));
					this.refreshSubjects('agentsSalesAllThird');
					this.loading = false;
				},
				error: err => {
					alert('ישנה תקלה בשרת');
					history.back();
				},
			});
		}
	}

	public DayArray = [];
	public FourthPageAdminAgentsSales(mm, YY, ag, cm) {
		this.AgentsSalesAllFourth = [];
		this.DayArray = [{ ag }, { mm }, { YY }, { cm }];

		console.log(this.DayArray);

		this.Third = false;
		this.Fourth = true;

		this.loading = true;

		this._ss.AdminAgentsSalesFourth(mm, YY, ag, cm).subscribe({
			next: res => {
				this.AgentsSalesAllFourth = res;
				this.AgentsSalesAllFourth = this.AgentsSalesAllFourth.recordset.filter(cl => cl.tot || cl.ltot);
				for (let x = 0; x < this.AgentsSalesAllFourth.length; x++) {
					const element = this.AgentsSalesAllFourth[x];
					element.Client_Name = element.cn;
					element.ClientId = element.Cl;
					if (this.ShowCompanys && this.ShowCompanys[element.cm - 1]) {
						element.CompanyName = this.ShowCompanys[element.cm - 1].CompanyName;
					}
				}
				this.AllClientsSearch = this.AgentsSalesAllFourth;

				this.refreshSubjects('agentsSalesAllFourth');

				this.loading = false;
			},
			error: err => {
				alert('ישנה תקלה בשרת');
				history.back();
			},
		});
	}

	teudaNum = '';

	public OneTeuda(number) {
		this.loading = true;

		this.teudaNum = number;

		this.Fifth = false;
		this.Sixth = true;

		this.currentPage$.next('Sixth');

		try {
			this._ss
				.GetOneTeudaDohot(number)
				.pipe(
					catchError(err => {
						this.currentPage$.next('Fifth');
						return throwError(() => new Error(err));
					}),
				)
				.subscribe({
					next: (res: any) => {
						if (!res.recordset) {
							throw new Error('No recordset');
						}

						this.OneTeudaInfo = res.recordset;

						this.loading = false;
					},
					error: err => {
						throw new Error(err);
					},
				});
		} catch (error) {
			alert('ישנה תקלה בשרת, אנא נסה שנית מאוחר יותר');
			this.Fifth = true;
			this.Sixth = false;
			this.loading = false;
		}
	}

	Loading = false;
	clientName = '';

	public MoveToDohotBizoimLekohotEach(ClientId, Company, name) {
		this.clientName = name;

		console.log(this.DayArray);

		const Month = this.DayArray.find(d => d.mm)
			.mm.toString()
			.padStart(2, '0');
		const Year = this.DayArray.find(d => d.YY).YY;

		const ag = this.DayArray.find(d => d.ag).ag;

		this.Loading = this.loading = true;
		this.Fourth = false;
		this.Fifth = true;
		this._ss.EachClientDohot(ClientId, Company, Month, Year, ag).subscribe({
			next: (res: any) => {
				this.AgentsSalesAllFifth = res.recordset;

				this.refreshSubjects('agentsSalesAllFifth');

				this.Loading = this.loading = false;
			},
			error: err => {
				alert('ישנה תקלה בשרת');
				history.back();
			},
		});
	}

	public SearchClear() {
		this.AgentsSalesAllFourth = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AgentsSalesAllFourth.length < 300) {
				this.AgentsSalesAllFourth.push(this.AllClientsSearch[x]);
			}
		}

		this.refreshSubjects('agentsSalesAllFourth');

		this.form.reset();
	}

	public SearchClient() {
		const pattern = new RegExp(this.form.controls['ClientSearch'].value.replace(/[\/*\+\-\?,]/g, ''), 'i');

		this.AgentsSalesAllFourth = this.AllClientsSearch.filter(client => client.Client_Name.toString().match(pattern)?.length);

		this.refreshSubjects('agentsSalesAllFourth');
	}

	openedCompanyIndex = null;

	async handleClickMonthsButton(e: Event, i: number, company: number) {
		this.Loading = true;
		e.stopPropagation();
		if (this.isClosed) {
			if (this.hasAgentsGoals) {
				this.populateFirstPageMonthsFromGoalsResponse(i, company);
				return;
			}

			this.monthOriginalRes = [];

			let arr = new Array(12).fill({ tot: 0, ltot: 0, isMonth: true }).map((m, i) => ({ ...m, month: i }));

			const agentArr = this.agentsToGet.length ? this.agentsToGet : this.agentsFromV_Users;

			const agentsArr$: Observable<Object | {}>[] = agentArr.map(ag =>
				this._ss.AdminAgentsSalesThird(ag.AgentID, company).pipe(
					takeUntil(this.destroy$),
					catchError(() => of('error')),
				),
			);

			const MAX_PARALLEL_QUERIES = 1;
			const observables = agentsArr$;
			from(observables)
				.pipe(
					mergeMap(observable => observable, MAX_PARALLEL_QUERIES),
					takeUntil(this.destroy$),
				)
				.subscribe({
					next: (partialResults: any) => {
						if (typeof partialResults === 'string') return;
						if (partialResults.recordset) {
							this.monthOriginalRes[partialResults.recordset[0].ag] = partialResults.recordset;
						}

						arr = arr.map((sales, i) => ({
							...sales,
							tot: (sales.tot || 0) + (partialResults.recordset ? partialResults.recordset[i]?.tot || 0 : 0),
							ltot: (sales.ltot || 0) + (partialResults.recordset ? partialResults.recordset[i]?.ltot || 0 : 0),
						}));
					},
					complete: () => {
						arr = arr.map(arr => ({ ...arr, ahuz: isFinite(arr.ltot / arr.tot) ? (arr.tot / arr.ltot) * 100 : 0 }));

						this.AgentsSalesAll = [...this.AgentsSalesAll.slice(0, i + 1), ...arr, ...this.AgentsSalesAll.slice(i + 1)];
						this.Loading = false;
						this.openedCompanyIndex = i;
						this.isClosed = !this.isClosed;

						this.refreshSubjects('agentsSalesAll');

						console.log(this.monthOriginalRes);
					},
				});
		} else {
			this.AgentsSalesAll.splice(i + 1, 12);
			this.Loading = false;
			this.isClosed = !this.isClosed;
			this.openedCompanyIndex = null;
		}
	}

	populateFirstPageMonthsFromGoalsResponse(i: number, company: number) {
		// const agentArr = this.agentsToGet.length ? this.agentsToGet : this.agentsFromV_Users

		const goalsArr = this.generateGoalsArr(company, 'values');

		let arr = new Array(12)
			.fill({ tot: 0, ltot: 0, isMonth: true })
			.map((m, i) => ({
				...m,
				month: i,
				tot: goalsArr.reduce((p, c: any) => p + c[i].Tot, 0),
				ltot: goalsArr.reduce((p, c: any) => p + c[i].Ltot, 0),
				currentYearGoal: goalsArr.reduce((p, c: any) => p + (c[i].Yaad2 || c[i].Yaad || 0), 0),
			}))
			.map(month => ({
				...month,
				ahuz: isFinite(month.tot / month.ltot) ? (month.tot / month.ltot) * 100 : 0,
				advancementPercentage: isFinite(month.tot / month.currentYearGoal) ? (month.tot / month.currentYearGoal) * 100 : 0,
			}));

		this.AgentsSalesAll = [...this.AgentsSalesAll.slice(0, i + 1), ...arr, ...this.AgentsSalesAll.slice(i + 1)];

		this.Loading = false;
		this.openedCompanyIndex = i;
		this.isClosed = !this.isClosed;
		this.refreshSubjects('agentsSalesAll');
	}

	generateGoalsArr(company, val: 'values' | 'object'): any[] {
		console.log(this.agentResponseWithGoals);
		const obj = this.agentResponseWithGoals.reduce((prev, curr) => {
			if (curr.cm != null && curr.cm !== company) return prev;

			if (!prev[curr.AgentCode]) {
				prev[curr.AgentCode] = [];
			}

			prev[curr.AgentCode].push(curr);

			return prev;
		}, {});
		if (val === 'values') {
			return Object.values(obj);
		}

		return obj;
	}

	translateForSort(value: typeof valuesObject[typeof screen][number], screen: keyof typeof valuesObject) {
		let i: number, arrayToSort: any[];

		if (screen === 'Second') {
			arrayToSort = this.hasAgentsGoals ? this.AgentsSalesAllSecondWithGoals$.value : this.AgentsSalesAllSecond;
		}

		if (screen === 'Third') {
			arrayToSort = this.AgentsSalesAllThird;
		}

		if (screen === 'Fourth') {
			arrayToSort = this.AgentsSalesAllFourth;
		}

		if (screen === 'Fifth') {
			arrayToSort = this.AgentsSalesAllFifth;
		}
		if (screen === 'Sixth') {
			arrayToSort = this.OneTeudaInfo;
		}

		i = valuesObject[screen].findIndex(arr => arr === value);

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}

	setupPipes() {
		this.title$ = this.currentPage$.pipe(
			map(page => {
				switch (page) {
					case 'First':
						return 'ביצועי חברה';
					case 'Second':
						return 'ביצועי סוכנים';
					case 'Third':
						return 'ביצועי סוכן לפי חודש';
					case 'Fourth':
						return 'ביצועי סוכן לקוח חודשי';
					case 'Fifth':
						return 'תעודות ללקוח בחודש';
					case 'Sixth':
						return 'פירוט תעודה';

					default:
						return 'ביצועי חברה';
				}
			}),
		);

		this.showSearch$ = this.currentPage$.pipe(map(page => page === 'Fourth'));

		this.excelFilename$ = this.currentPage$.pipe(
			map(page => {
				switch (page) {
					case 'Third':
						return 'ביצועי סוכן ' + this.selectedAgent + (this.agentName.length ? ', ' + this.agentName : '');
					case 'Fourth':
						return `ביצועים לקוחות - סוכן ${this.DayArray[0].ag} - ${new ReturnMonthHebrewPipe().transform(this.DayArray[1].mm - 1)} `;
					case 'Fifth':
						return 'תעודות לקוח  ' + this.clientName;
					case 'Sixth':
						return 'תעודה ' + this.teudaNum;

					default:
						return '';
				}
			}),
		);
	}
}
