import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { Subject, BehaviorSubject, distinctUntilChanged, takeUntil, Observable, map, startWith, combineLatest, tap } from 'rxjs';
import { Bizoim, BizoimClient, BizoimClientResponse, BizoimConcentrated } from 'src/app/Interfaces/agent-sales-quantities';
import { SQLError } from 'src/app/Interfaces/SQL-interfaces';
import { visistsPlannedVsUnplannedStatuses, VisitsPlannedVsUnplanned } from 'src/app/Interfaces/SQL-reports-responses';
import { Users } from 'src/app/Interfaces/users';
import { LoadingService } from 'src/app/services/loading.service';
import { StoreService } from 'src/app/services/store.service';
import { Time, UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { PlannedVsUnplannedStatistics, VisitsService } from 'src/app/services/visits.service';
import { translateDateForSQLServer } from 'src/app/Utility/functions';
import { DeviceInfoService } from 'src/app/services/device-info.service';

@Component({
	selector: 'app-planned-vs-unplanned',
	templateUrl: './planned-vs-unplanned.component.html',
	styleUrls: ['./planned-vs-unplanned.component.scss'],
})
export class PlannedVsUnplannedComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		private _date: DateAdapter<any, 'he-IL'>,
		private _util: UtilService,
		public _loading: LoadingService,
		public _visits: VisitsService,
		public _device: DeviceInfoService,
	) {}

	dates = {
		min: (() => {
			const d = new Date();
			d.setFullYear(d.getFullYear() - 3);
			return d;
		})(),
		max: new Date(),
	};

	mustPickDatePopup = !this._visits.scrollYPosition$.value && !this._visits.visitsPerAgentStatistics$.value.length;

	toogleInputNew = false;
	ShowCompanys = null;
	HasMultipleCompanys;
	reportSearch = null;
	history = history;

	destroy$ = new Subject<void>();

	bizoimTotalQuantity$ = new BehaviorSubject<number>(0);
	bizoimTotalSum$ = new BehaviorSubject<number>(0);
	bizoimAverageSum$ = new BehaviorSubject<number>(0);

	loadingMainQuery = false;

	windowWidth: number;

	isAdmin = location.pathname.includes('admin');

	AgentArray = [];

	loadingAgents = false;

	hasGetAgentSalesNextQuery = false;

	mode$ = new BehaviorSubject<'First'>('First');
	isFirstPage$ = this.mode$.pipe(map(page => page === 'First'));

	todayCutDate = Intl.DateTimeFormat('en-IL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).split('/').reverse().join('-');

	visitsDisplay$: Observable<PlannedVsUnplannedStatistics[]>;

	agentsToGet = [];

	totalSum$: Observable<number>;
	totalAverage$: Observable<number>;

	ngOnInit(): void {
		this._date.setLocale('he-IL');

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		this.windowWidth = window.innerWidth;

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		if (this.isAdmin) {
			this.loadingAgents = true;

			this.getAgents();
		}

		window.scrollTo(0, 0);

		if (!localStorage.getItem('UserName') && !localStorage.getItem('AgentId')) {
			this.router.navigate(['login']);
			return;
		}

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		this.setupPlannedVsUnplannedInteractivity();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		this._loading.changeLoadingStatuses({ isLoading: false });
	}

	handleClosePopup() {
		this.mustPickDatePopup = false;
	}

	async handleFormSubmit() {
		this.mustPickDatePopup = false;

		await this.getPlannedVsUnplannedVisits();

		const statistics = await this.buildPlannedVsUnplannedStatistics();

		if (statistics) {
			this._visits.visitsPerAgentStatistics$.next(statistics);
		}
	}

	handleError() {
		alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
		this.history.back();
		return;
	}

	async getPlannedVsUnplannedVisits() {
		return new Promise<void>(resolve => {
			this._ss
				.getPlannedVsUnplannedReport()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string' || !res.recordset?.length) {
							alert('ישנה תקלת תקשורת. נסו שנית מאוחר יותר');
							return history.back();
						}

						this._visits.allVisits = res.recordset;
						this._visits.visits$.next(res.recordset);

						resolve();
					},
					error: err => {
						alert('ישנה תקלת תקשורת. נסו שנית מאוחר יותר');
						history.back();
					},
				});
		});
	}

	changeDateValue(val: 'month' | 'year') {
		let { from, to } = this._visits.plannedVsUnplannedForm.value;

		if (val === 'month') {
			if (typeof from === 'string') from = new Date(from);
			from.setMonth(from.getMonth() - 1);
		} else {
			if (typeof from === 'string') from = new Date(from);
			from.setFullYear(from.getFullYear() - 1);
		}

		this._visits.plannedVsUnplannedForm.patchValue({ from, to });
	}

	SearchClear() {
		this._visits.plannedVsUnplannedForm.controls.agentSearch.reset();
	}

	public ChangeCompany() {}

	async getAgents(): Promise<void> {
		if (this._ss.CustomerInformation.Submanagers === '1') {
			this.agentsToGet = await this.getSubmanagerAgentsFirst();
		}

		this.getAgentUsers();
	}

	async getSubmanagerAgentsFirst(): Promise<string[]> {
		return await new Promise(resolve => {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res === 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							resolve([]);
							history.back();
							return;
						}

						const agents = res.recordset.map(obj => obj.Agent);
						agents.push(this._ss.AgentIdConnected);
						this._ss.agentsOfSubmanager = agents;

						return resolve(agents);
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				resolve(this._ss.agentsOfSubmanager);
			}
		});
	}

	getAgentUsers() {
		this._ss.GetAllAgents(this.agentsToGet).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('אירעה שגיאה בקבלת נתונים מהשרת, אנא נסה שנית');
					history.back();
					return;
				}

				this.AgentArray = res.recordset.sort((a, b) => +a.AgentID - +b.AgentID);
				this.loadingAgents = false;

				if (this._visits.visitsPerAgentStatistics$.value.length && this._visits.scrollYPosition$.value) {
					scrollBy({ top: this._visits.scrollYPosition$.value, behavior: this._visits.scrollYPosition$.value < 420 ? 'smooth' : 'auto' });
					this._visits.scrollYPosition$.next(0);
				}
			},
			error: err => {
				alert('אירעה שגיאה בקבלת נתונים מהשרת, אנא נסה שנית');
				history.back();
			},
		});
	}

	refreshSubjects(val: 'plannedVsUnplanned') {}

	sortArr = [false, false, false, false];

	sort(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		let arrOverride: Bizoim[] | BizoimClient[];

		// if (this._visits.plannedVsUnplannedForm.controls.agentSearch.value) {
		// if (this.First) arrOverride = this.Bizoim$.getValue();
		// if (this.Second) arrOverride = this.BizoimClients$.getValue();
		// }

		switch (valueToSortBy) {
			case 'ProductId':
			case 'ClientId':
				i = 0;
				break;
			case 'Product_Name':
			case 'Client_Name':
				i = 1;
				break;
			case 'totqty':
				i = 2;
				break;
			case 'totsum':
				i = 3;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		const finalArr = arrOverride || this[arrayToSort];

		if (bool) {
			finalArr.sort((a, b) => new Intl.Collator('he', opt).compare(b[String(valueToSortBy)], a[String(valueToSortBy)]));
		} else {
			finalArr.sort((a, b) => new Intl.Collator('he', opt).compare(a[String(valueToSortBy)], b[String(valueToSortBy)]));
		}

		this.sortArr[i] = !bool;
	}

	setupPlannedVsUnplannedInteractivity() {
		const searchValue$ = this._visits.plannedVsUnplannedForm.controls.agentSearch.valueChanges.pipe(startWith(''));

		this.visitsDisplay$ = combineLatest([this._visits.visitsPerAgentStatistics$, searchValue$]).pipe(
			map(([allVisits, searchValue]: [PlannedVsUnplannedStatistics[], string]) => {
				let filteredVisits: PlannedVsUnplannedStatistics[] = [...allVisits];
				const stringSearchArray: any[] = [];

				if (searchValue) {
					const splitValue = searchValue.toLowerCase().split(' ');

					for (let i = 0; i < filteredVisits.length; i++) {
						let counter = 0;

						const { agent } = filteredVisits[i];

						const agentWithoutSpaces = ((agent || '') + (this._visits.namesRecord[agent] || '')).replace(/\s/g, '').toLowerCase(),
							allStrings = typeof agentWithoutSpaces === 'string';

						// V8 optimizes this if statement, maybe
						if (allStrings) {
							for (let j = 0; j < splitValue.length; j++) {
								if (agentWithoutSpaces && agentWithoutSpaces.indexOf(splitValue[j]) > -1) {
									counter++;
								}
							}

							if (counter >= splitValue.length) {
								stringSearchArray.push(filteredVisits[i]);
							}
						}
					}

					filteredVisits = stringSearchArray;
				}

				return filteredVisits;
			}),
		);

		this.totalSum$ = this.visitsDisplay$.pipe(
			map(visits => visits.reduce((a, b) => a + b.totalSum, 0)),
			startWith(0),
		);
		this.totalAverage$ = combineLatest([this.visitsDisplay$.pipe(startWith<PlannedVsUnplannedStatistics[]>([])), this.totalSum$.pipe(startWith(0))]).pipe(
			map(([allVisits, totalSum]) => totalSum / (allVisits.length / visistsPlannedVsUnplannedStatuses.length) || 0),
		);
	}

	async buildPlannedVsUnplannedStatistics() {
		return new Promise<PlannedVsUnplannedStatistics[]>(resolve => {
			const { agents: agentsValue, from: dateFromValue, to: dateToValue } = this._visits.plannedVsUnplannedForm.value;

			const translatedDateFromValue: Date = translateDateForSQLServer(dateFromValue);
			const translatedDateToValue: Date = translateDateForSQLServer(dateToValue);

			const record: Record<string, Record<typeof visistsPlannedVsUnplannedStatuses[number], { totalOf: number; totalSum: number }>> = {};
			const namesRecord: Record<string, string> = {};
			for (const visit of this._visits.visits$.value) {
				if (!record[visit.OwnerAgentID]) {
					if (agentsValue.length && !agentsValue.includes(visit.OwnerAgentID)) {
						continue;
					}
					record[visit.OwnerAgentID] = {} as Record<any, any>;
					for (const status of visistsPlannedVsUnplannedStatuses) {
						record[visit.OwnerAgentID][status] = { totalOf: 0, totalSum: 0 };
					}
				}

				namesRecord[visit.OwnerAgentID] = visit.AgentName;

				const shouldAdd = this.doesDateFitsVisit([translatedDateFromValue, translatedDateToValue], visit);

				if (!shouldAdd) continue;

				record[visit.OwnerAgentID][visit.IfNotPlaned].totalOf++;
				record[visit.OwnerAgentID][visit.IfNotPlaned].totalSum += +visit.TeudaTotalSumBeforeVat;
			}

			const flattened: PlannedVsUnplannedStatistics[] = [];

			for (const [agent, innerRecords] of Object.entries(record)) {
				for (const status in innerRecords) {
					const { totalOf, totalSum } = innerRecords[status];
					flattened.push({
						agent,
						status: status as typeof visistsPlannedVsUnplannedStatuses[number],
						totalOf,
						totalSum,
					});
				}
			}

			this._visits.namesRecord = namesRecord;
			resolve(flattened);
		});
	}

	doesDateFitsVisit(dates: Date[], visit: VisitsPlannedVsUnplanned) {
		const [translatedDateFromValue, translatedDateToValue] = dates;

		const days = [translatedDateFromValue, translatedDateToValue].map(date => date.toISOString().slice(0, 10));

		if (days[0] === days[1] && visit.docdate.slice(0, 10) === days[0]) {
			return true;
		}

		const startOfFromDate = new Date(translatedDateFromValue);
		startOfFromDate.setHours(0);
		startOfFromDate.setMinutes(0);

		const endOfToDate = new Date(translatedDateToValue);
		endOfToDate.setHours(23);
		endOfToDate.setMinutes(59);

		if (startOfFromDate.getTime() > new Date(visit.docdate).getTime() || endOfToDate.getTime() < new Date(visit.docdate).getTime()) {
			return false;
		}

		return true;
	}

	handleTableRowClick(line: PlannedVsUnplannedStatistics) {
		const { agent, status } = line;
		const { from: dateFromValue, to: dateToValue } = this._visits.plannedVsUnplannedForm.value;

		const from: Date = translateDateForSQLServer(dateFromValue);
		const to: Date = translateDateForSQLServer(dateToValue);

		this._visits.chosenLineToView$.next({ agent, status, from, to });

		const filtered = this._visits.allVisits.filter(visit => visit.OwnerAgentID === agent && visit.IfNotPlaned === status && this.doesDateFitsVisit([from, to], visit));

		this._visits.visits$.next(filtered);

		this._visits.scrollYPosition$.next(scrollY);

		this.router.navigate(['admin-bizoa-azmanot', 'planned-vs-unplanned']);
	}
}
