<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohot()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ריכוז חובות לפי חודש</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חיפוש" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="report-table__row report-table__row_between" style="margin-top: 100px"></div>
		<div class="report-table__row" *ngIf="toogleInputNew === true">
			<span class="report-table__search-result"
				>מציג {{ this.AllClients.length }} תוצאות חיפוש <span class="report-table__search-result_bold">{{ this.form.controls['ClientSearch'].value }}</span></span
			>
		</div>

		<div *ngIf="HasMultipleCompanys === '2'">
			<form [formGroup]="form" (change)="ChangeCompany()">
				<select formControlName="Company" style="width: 150px; height: 35px">
					<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
				</select>
			</form>
		</div>

		<div class="report-table__row report-table__row_full-width" *ngIf="First === true">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th
							class="report-table__table-head-name sortable-clickable"
							[style.width.px]="longestName > 24 ? (windowWidth > 768 ? longestName * 11 : longestName * 8) : null"
							style="cursor: pointer"
							(click)="sortString('Client_Name', 'AllClients')"
						>
							שם לקוח
						</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="sortString('ClientId', 'AllClients')">קוד לקוח</th>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="sortNumber('COD', 'AllClients')">חוב</th>
						<th class="report-table__table-head-quantity" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ac of AllClients" (click)="GetHovotClient(ac.company, ac.ClientId, ac.Client_Name)" id="Client{{ ac.ClientId }}">
						<td class="report-table__table-body-name">{{ ac.Client_Name }}</td>
						<td class="report-table__table-body-code">{{ ac.ClientId }}</td>
						<td class="report-table__table-body-quantity" [style.fontWeight]="ac.COD < 0 ? '700' : null" [style.color]="ac.COD < 0 ? 'red' : null" dir="ltr">{{ ac.COD | number : '1.0-2' }}</td>
						<td class="report-table__table-body-code" *ngIf="HasMultipleCompanys > 1">{{ ac.company }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- <button class="download-excel" (click)="getExcelReport()"><img src="assets/images/excel-download.png" alt="download excel"></button> -->

		<h3 style="text-align: center; font-size: x-large; padding-bottom: 1rem">{{ clientName }}</h3>

		<div class="header__block" style="margin: 1em">
			<div class="report-table__button sortable-clickable active" (click)="changeMode($event.target)" *ngIf="Second === true" [ngClass]="{ 'report-table__button_active': searchActive }">
				לפי חודש פירעון
			</div>
			<div class="report-table__button sortable-clickable" (click)="changeMode($event.target)" *ngIf="Second === true" [ngClass]="{ 'report-table__button_active': searchActive }">
				לפי חודש יצירה
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" *ngIf="Second === true">
			<table class="report-table__table" cellspacing="0" id="one-client-hovot-table">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortNumber('yymm', displayByLastDateOrCreateDate ? 'OneClientHovot' : 'OneClientHovotLdt')">תקופה</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="sortNumber('tot', displayByLastDateOrCreateDate ? 'OneClientHovot' : 'OneClientHovotLdt')">סה"כ</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ac of displayByLastDateOrCreateDate ? OneClientHovot : OneClientHovotLdt">
						<td class="report-table__table-body-name">{{ ac.yymm }}</td>
						<td class="report-table__table-body-code" [style.fontWeight]="ac.tot < 0 ? '700' : null" [style.color]="ac.tot < 0 ? 'red' : null" dir="ltr">{{ ac.tot | number : '1.0-2' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
