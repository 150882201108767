<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ריכוז מלאי</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchProducts()">
				<input class="header__search-input" type="text" placeholder=" חיפוש" formControlName="ProductSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div style="width: 100px; margin: auto; margin-top: 100px" *ngIf="Loading === true">
			<mat-spinner style="width: 100px; margin: auto" color="primary"></mat-spinner>
		</div>
		<div class="report-table__row report-table__row_between center" style="margin-top: 100px">
			<!-- <div class="flex-row" *ngIf="Loading === false">
        <div class="box"><b>כספי:</b></div>
        <div class="box">
          <b>סה"כ</b>
          <span>
            {{(allProductsMoneyTotal$  | async ) | number : '1.0-2'}}
          </span>
        </div>
        <div class="box">
          <b>ממוצע</b>
          <span>
            {{allProductsMoneyAverage$ | async | number : '1.0-2'}}
          </span>
        </div>
      </div> -->
			<!-- <div class="flex-row" *ngIf="Loading === false">
				<div class="box"><b>מלאי:</b></div>
				<div class="box">
					<b>סה"כ</b>
					<span>
						{{ allProductsStockTotal$ | async | number : '1.0-2' }}
					</span>
				</div>
				<div class="box">
					<b>ממוצע</b>
					<span>
						{{ allProductsStockAverage$ | async | number : '1.0-2' }}
					</span>
				</div>
			</div> -->
		</div>

		<div class="report-table__row report-table__row_full-width" *ngIf="Loading === false">
			<table class="report-table__table">
				<thead class="report-table__table-head">
					<tr style="z-index: 1099">
						<th
							class="report-table__table-head-name sortable-clickable"
							(click)="sort('Product_Name', 'AllProducts')"
							[style.width.px]="windowWidth > 768 ? (nameLengthAverage > 24 ? nameLengthAverage * 12 : null) : 160"
						>
							שם פריט
						</th>
						<th class="report-table__table-head-quantity sortable-clickable" [style.width.px]="windowWidth > 768 ? 180 : 110" style="width: 180px" (click)="sort('Stock', 'AllProducts')">
							מלאי
						</th>
						<th class="report-table__table-head-quantity sortable-clickable" style="width: 130px" (click)="sort('Price', 'AllProducts')">מחיר</th>
						<th class="report-table__table-head-code sortable-clickable" style="width: 150px" (click)="sort('ProductId', 'AllProducts')">קוד פריט</th>
					</tr>
				</thead>
				<!-- <cdk-virtual-scroll-viewport itemSize="35" class="example-viewport" > -->
				<tbody class="report-table__table-body">
					<tr *ngFor="let ap of AllProducts; trackBy: trackByFnProduct" (click)="handlePopup(ap.ProductId)">
						<td class="report-table__table-body-name" *ngIf="_ss.CustomerInformation.CustExtra5 !== '1'">
							{{ ap.Product_Name }}
						</td>
						<td class="report-table__table-body-name" *ngIf="_ss.CustomerInformation.CustExtra5 === '1'">
							{{ ap.Product_NameD }}
						</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ ap.Stock | toNormalNumber : 0 : true }}</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ ap.Price | number : '1.0-2' }}</td>
						<td class="report-table__table-body-code">{{ ap.ProductId }}</td>
					</tr>
				</tbody>
				<!-- </cdk-virtual-scroll-viewport> -->
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start product popup -->

<div class="popup" *ngIf="productToShowInPopup.length" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content no-padding">
		<div class="product-detail__wrapper wrapper2">
			<div class="product-detail__item overflow" style="padding: 0; direction: rtl">
				<div class="popup__close" (click)="productToShowInPopup = []" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>
				<div class="content popup-header client-info">
					<div class="row">
						<div class="popup-title client-info__title col">{{ productToShowInPopup[0].product_name }}</div>
					</div>
					<div class="client-info__block row">
						<div class="client-info__number col">{{ productToShowInPopup[0].ProductID }}</div>
					</div>
				</div>

				<div class="client-profile__block row" *ngFor="let prod of productToShowInPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/comment-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 16px; font-weight: 600; line-height: 18px">
						{{ prod.HeadLineStr }}
					</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 16px; font-weight: 600; line-height: 18px">
						{{ prod.DataLineStr }}
					</div>
					<div class="client-profile__icon image col"></div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- end product popup -->
