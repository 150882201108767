import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getTeudaStatus',
})
export class GetTeudaStatusPipe implements PipeTransform {
	transform(status: number): string {
		switch (status) {
			case 0:
				return 'ממתין לקליטה';
			case 1:
				return 'נקלט';
			case 2:
				return 'ממתין לאישור';
			case 3:
				return 'אושר';
			default:
				return 'לא ידוע';
		}
	}
}
