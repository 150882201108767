import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { AdminTypes } from '../Interfaces/users';
import { StoreService } from './store.service';

export const B2B_USERS = ['b2b', 'b2bp', 'b2bl', 'b2blm', 'b2bei'];

@Injectable({
	providedIn: 'root',
})
//TODO Finish implementing this
export class B2bService {
	constructor(private _ss: StoreService) {
		this.initialInit();
	}

	Admin: AdminTypes | null = localStorage.getItem('Admin') as null | AdminTypes;
	admin$ = new BehaviorSubject(this.Admin);

	isB2BAgent$ = new BehaviorSubject(false);
	/**
	 * if user is b2bp or b2bm
	 */
	isB2BAgentNoPricesAndNoParameters$ = new BehaviorSubject(false);
	isB2BAgentAndNoDiscountsAndDates$ = new BehaviorSubject(false);
	canSeeDebtsAsB2B = new BehaviorSubject(false);

	isAdminUser$ = new BehaviorSubject(false);

	//template
	/**
	 * returns the opposite of isB2BAgent$
	 */
	isNotB2BAgent$ = this.isB2BAgent$.pipe(map(boolean => !boolean));

	shouldStopLoginIfAlreadyLoggedIn$ = new BehaviorSubject(false);

	readonly entryTeudaText = `מינימום לאספקה 5000 ש"ח`
	readonly confirmBeforeSendText = `אני מאשר הזמנה זו ומתחייב לתשלומה המלא ,גם אם לא אצרוך את
	ההזמנה מכל סיבה שהיא שאינה תלויה בספק.`

	private initialInit() {
		this.admin$.subscribe(newValue => {
			this.isB2BAgent$.next(newValue && newValue.toLowerCase().includes('b2b'));
			this.isB2BAgentNoPricesAndNoParameters$.next(newValue && (newValue.toLowerCase().includes('b2bp') || newValue.toLowerCase().includes('b2bm')));
			this.isB2BAgentAndNoDiscountsAndDates$.next(newValue && newValue.toLowerCase().includes('b2bei'));
			this.isAdminUser$.next(newValue === 'true');
			this.shouldStopLoginIfAlreadyLoggedIn$.next(!newValue || !newValue.includes('catalog'));
		});
	}

	refreshAdmin(newValue?: string) {
		this.admin$.next((newValue as AdminTypes) || this.Admin || (localStorage.getItem('Admin') as AdminTypes));
		this.Admin = (newValue as AdminTypes) || this.Admin;
		// this.canSeeDebtsAsB2B.next(this._ss.CustomerInformation)
	}

	checkIfUserIsB2B(admin: string) {
		if (!admin) return false;
		return B2B_USERS.includes(admin.toLowerCase());
	}
}
