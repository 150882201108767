import { Component, NgZone, PLATFORM_ID, Inject, OnDestroy, AfterViewInit, ElementRef, ViewChild, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { decompress } from 'lz-string';
import bb, { Chart, pie } from 'billboard.js';
import { catchError, from, mergeMap, of, Subject, takeUntil } from 'rxjs';
import { Users } from 'src/app/Interfaces/users';
import { UtilService } from 'src/app/services/util.service';
import { B2bService } from 'src/app/services/b2b.service';

@Component({
	selector: 'app-barcode-reader',
	templateUrl: './barcode-reader.component.html',
	// template: ` <div id="chartdiv" style="width: 300px; height: 100%"></div> `,
	styleUrls: ['./barcode-reader.component.scss'],
})
export class BarcodeReaderComponent implements OnDestroy, OnInit {
	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();

		if (this.timeoutID != null) {
			clearTimeout(this.timeoutID);
		}

		const charts = document.querySelectorAll('.bb');

		charts.forEach(el => {
			if (el) {
				el.remove();
			}
		});
	}

	public AdminSeeAgents;
	public AgentPerDate;
	public AgentPerDateOne;
	public AdminSeeAgentsMain;
	public form: FormGroup;
	public TodayDate = new Date();

	public Total = 0;

	shouldShowCompanies: number = 0;

	agentsToGet = [];

	loadedGraph = false;

	chart: Chart;
	chartMonthly: Chart;
	chartYearly: Chart;

	yearTotal = 0;

	windowDimensions = {
		width: innerWidth,
		height: innerHeight,
	};

	agentsToSuffocateServerWithQueries = new Set<string>();

	monthsResData = [];

	currentlyDisplayedMonthAllAgents = [];

	selectedMonth = '' + this.TodayDate.getMonth();

	dailyForm = new FormGroup({
		date: new FormControl(new Date()),
	});

	monthForm = new FormGroup({
		month: new FormControl(this.selectedMonth),
	});

	history = history;

	monthArr = new Array(12).fill('').map((v, i) => i);
	monthTotal = 0;

	agentsFromV_Users: any[];

	destroy$ = new Subject<void>();

	timeoutID;

	constructor(public _ss: StoreService, private router: Router, private _fb: FormBuilder, private _zone: NgZone, private _acr: ActivatedRoute, private _util: UtilService, public _b2b: B2bService) {}

	ngOnInit(): void {
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this.form = this._fb.group({
				Date: ['', Validators.required],
			});

			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			if (this._ss.CustomerInformation.Submanagers == '1') {
				if (!this._ss.agentsOfSubmanager.length) {
					this._ss.getAdminAgents().subscribe({
						next: res => {
							if (typeof res == 'string') {
								alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
								return;
							}

							this.agentsToGet = res.recordset.map(obj => obj.Agent);
							this.agentsToGet.push(this._ss.AgentIdConnected);

							this.getInitialStats();
						},
						error: err => {
							alert('שגיאת שרת');
						},
					});
				} else {
					this.agentsToGet = this._ss.agentsOfSubmanager;
					this.getInitialStats();
				}
			} else {
				this.getInitialStats();
			}
		} else {
			this.router.navigate(['login']);
		}
	}

	async getInitialStats() {
		this._ss
			.GetAgentsList(this.agentsToGet)
			.pipe(takeUntil(this.destroy$))
			.subscribe(async (res: any) => {
				this.AdminSeeAgents = [];
				this.AdminSeeAgentsMain = res.recordset;

				const data = { columns: [], type: pie() };

				const companiesSet: Set<number> = new Set();

				for (let x = 0; x < this.AdminSeeAgentsMain.length; x++) {
					const { cm, td, ag, agn, sum1 } = this.AdminSeeAgentsMain[x];

					companiesSet.add(cm);

					if (td.slice(0, 10) === this.TodayDate.toISOString().slice(0, 10)) {
						this.AdminSeeAgents.push(this.AdminSeeAgentsMain[x]);
						this.Total += sum1;
						data.columns.push([ag + ' - ' + agn + ' - ' + sum1, sum1]);
					}
				}

				this.shouldShowCompanies = [...companiesSet].length;

				this.agentsFromV_Users = [];

				try {
					this.agentsFromV_Users = await this.getAgentsList();
				} catch (error) {}

				console.log({ agentsFromV_Users: this.agentsFromV_Users });

				const agentsArr$ = this.agentsFromV_Users.map(ag => this._ss.AdminAgentsSalesThird(ag.AgentID, 1).pipe(catchError(err => of([]))));

				const MAX_PARALLEL_QUERIES = 1;
				const observables = agentsArr$;
				from(observables)
					.pipe(
						mergeMap(observable => observable, MAX_PARALLEL_QUERIES),
						takeUntil(this.destroy$),
					)
					.subscribe({
						next: partialResults => {
							this.monthsResData = this.monthsResData.concat(partialResults);
						},
						complete: () => {
							this.monthsResData = this.monthsResData.map((obj: { recordset: string[] }) => obj.recordset).filter(v => v);
							console.log({ resData: this.monthsResData });
							this.initiateCharts(data);
						},
					});
			});
	}

	async getAgentsList() {
		return await new Promise<Users[]>((resolve, rej) => {
			this._ss
				.GetAllAgents()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'object' && res.recordset) {
							return resolve(res.recordset.filter(user => !this._b2b.checkIfUserIsB2B(user.Admin)));
						}

						rej();
					},
					error: err => {
						console.log({ err });
						rej();
					},
				});
		});
	}
	async getYearlyStats() {
		return await new Promise<any[]>((resolve, rej) => {
			this._ss
				.AdminAgentsSalesSecond(1)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'object' && res.recordset) {
							if (this._ss.CustomerInformation.Graph === '1') {
								return resolve(res.recordset.filter(obj => obj.tot != 0));
							}
							return resolve(res.recordset);
						}

						rej();
					},
					error: err => {
						rej();
					},
				});
		});
	}

	columnsForLegend: { [something: string]: string[] } = {};

	async initiateCharts(firstChartData) {
		console.log({ firstChartData, agents: this.AdminSeeAgents });

		const secondChartData = { columns: [], type: pie() };

		const thirdCharData = { columns: [], type: pie() };

		for (const i in this.monthsResData) {
			const agent = this.agentsFromV_Users.find(resdata => this.monthsResData[i].length && resdata.AgentID == this.monthsResData[i]?.[0]?.ag);

			let agentName = 'ללא שם';

			if (agent) {
				agentName = (agent.AgentID || '') + ' - ' + (agent.UserName || '');
			}

			if (agent && this.monthsResData?.[i]?.[0]?.tot && this.monthsResData?.[i]?.[0]?.tot != 0) {
				const { tot } = this.monthsResData[i][this.selectedMonth];
				this.monthTotal += tot;

				secondChartData.columns.push([agentName + ' - ' + this._util.formatAsNormalNumber(tot), tot]);
			}
		}

		let yearly = [];

		try {
			yearly = await this.getYearlyStats();
		} catch (error) {
			console.log({ error });
		}

		for (const agent of yearly) {
			const agentUser = this.agentsFromV_Users.find(vuser => agent.ag === vuser.AgentID);
			if (agentUser) {
				thirdCharData.columns.push([agentUser.AgentID + ' - ' + agentUser.UserName + ' - ' + this._util.formatAsNormalNumber(agent.tot), agent.tot]);

				this.yearTotal += agent.tot;
			}
		}

		this.loadedGraph = true;

		const basicCurrencySetup = {
			tooltip: {
				format: {
					value: function (value, ratio, id) {
						return (
							+this._util.formatAsNormalNumber(ratio * 100) +
							'%' +
							' | ' +
							Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(value)
						);
					},
				},
			},
			legend: {
				item: {
					tile: {
						type: 'circle' as const,
						r: 7,
					},
				},
			},
			size: {
				height: 500,
				width: 400,
			},
		};

		if (this.windowDimensions.width < 600) {
			basicCurrencySetup.size.width = this.windowDimensions.width;
			basicCurrencySetup.size.height = Math.floor(this.windowDimensions.height / 2);
		}

		// this.columnsForLegend['first'] = firstChartData.columns;
		// this.columnsForLegend['second'] = secondChartData.columns;
		// this.columnsForLegend['third'] = thirdCharData.columns;

		firstChartData.columns.sort((a, b) => b[1] - a[1]);
		secondChartData.columns.sort((a, b) => b[1] - a[1]);
		thirdCharData.columns.sort((a, b) => b[1] - a[1]);

		this.timeoutID = setTimeout(() => {
			if (!location.pathname.includes('admin')) return;

			this.chart = bb.generate({
				title: {
					text: 'מכירות יומי',
				},

				data: {
					...firstChartData,
					onclick: (d, element, i) => {
						const { name } = d;

						// console.log({d:d.name.split(' - ')[0],element,i});

						console.log(this.chart.data());

						this._zone.run(() => {
							this.router.navigate(['admin-bizoa-azmanot'], {
								queryParams: {
									date: typeof this.dailyForm.controls.date.value === 'object' ? this.dailyForm.controls.date.value.toISOString().slice(0, 10) : this.dailyForm.controls.date.value,
									agent: name.split(' - ')[0],
								},
							});
						});
					},
				},
				...basicCurrencySetup,
				bindto: '#pieChart',
			});

			this.chartMonthly = bb.generate({
				title: {
					text: 'מכירות חודשי',
				},
				data: {
					...secondChartData,
					onclick: (d, element) => {
						const nameArr = d.name.split(' ');
						this._ss.openDohotFourthOnAgent = this.AdminSeeAgentsMain.find(see => see.ag == nameArr[0]);
						this._ss.openDohotFourthOnAgent.month = this.selectedMonth;
						console.log(this._ss.openDohotFourthOnAgent);
						this.router.navigate(['admin-bizoa-hevra']);
					},
				},
				...basicCurrencySetup,
				bindto: '#pieChart2',
				onrendered() {
					const monthlyChart = document.querySelector('#pieChart2');

					if (monthlyChart) {
						monthlyChart.scrollIntoView();
					}
				},
			});

			this.chartYearly = bb.generate({
				title: {
					text: 'מכירות שנתי - ' + this.TodayDate.getFullYear(),
				},
				data: {
					...thirdCharData,
					onclick: (d, element) => {
						const nameArr = d.name.split(' ');
						this._ss.openDohotThirdOnAgent = this.AdminSeeAgentsMain.find(see => see.ag == nameArr[0]);
						console.log({ openDohot: this._ss.openDohotThirdOnAgent, nameArr });
						this.router.navigate(['admin-bizoa-hevra']);
					},
				},
				...basicCurrencySetup,
				bindto: '#pieChart3',
			});

			setTimeout(() => {
				const svgs = [...(document.querySelectorAll('svg') as unknown as HTMLElement[])];

				for (const svg of svgs) {
					svg.style.overflow = 'initial';
				}
			}, 100);
		}, 100);
	}

	handleDayChange(e: Event) {
		if (e.target instanceof HTMLInputElement) {
			const columns = [];
			this.Total = 0;
			for (let x = 0; x < this.AdminSeeAgentsMain.length; x++) {
				if (this.AdminSeeAgentsMain[x].td.slice(0, 10) === e.target.value) {
					const { ag, agn, sum1 } = this.AdminSeeAgentsMain[x];
					this.AdminSeeAgents.push(this.AdminSeeAgentsMain[x]);
					this.Total += sum1;
					columns.push([`${ag} - ${agn} - ${sum1}`, sum1]);
				}
			}

			// this.columnsForLegend['first'] = columns;

			columns.sort((a, b) => b[1] - a[1]);

			this.chart.load({ columns, unload: true });

			setTimeout(() => {
				const svgs = [...(document.querySelectorAll('svg') as unknown as HTMLElement[])];

				for (const svg of svgs) {
					svg.style.overflow = 'initial';
				}
			}, 100);
		}
	}

	handleMonthChange(e: Event) {
		const columns = [];

		this.monthTotal = 0;

		if (e.target instanceof HTMLSelectElement) {
			this.selectedMonth = e.target.value;

			for (const i in this.monthsResData) {
				const agent = this.agentsFromV_Users.find(resdata => this.monthsResData[i].length && resdata.AgentID == this.monthsResData[i]?.[this.selectedMonth]?.ag);

				let agentName = 'ללא שם';

				if (agent) {
					agentName = (agent.AgentID || '') + ' - ' + (agent.UserName || '');
				}

				if (agent && this.monthsResData[i]?.[this.selectedMonth]?.tot && this.monthsResData[i]?.[this.selectedMonth]?.tot != 0) {
					const { tot } = this.monthsResData[i][this.selectedMonth];
					this.monthTotal += tot;

					columns.push([`${agentName} - ${tot}`, tot]);
				}
			}

			// this.columnsForLegend['second'] = columns;

			columns.sort((a, b) => b[1] - a[1]);

			this.chartMonthly.load({ columns, unload: true });

			setTimeout(() => {
				const svgs = [...(document.querySelectorAll('svg') as unknown as HTMLElement[])];

				for (const svg of svgs) {
					svg.style.overflow = 'initial';
				}
			}, 100);
		}
	}

	isRotated = false;

	rotate(e: Event, bool: boolean) {
		let method = '';

		if (bool) {
			e.stopPropagation();
		}

		method = bool ? 'add' : 'remove';

		document.querySelector('.expand-icon').classList[method]('expanded');

		this.isRotated = bool;
	}
}
