import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject, Subject } from 'rxjs';
import { BrandResponse } from 'src/app/Interfaces/brand-response';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-table-display',
	templateUrl: './brands-table.component.html',
	styleUrls: ['./brands-table.component.scss'],
})
export class BrandsTableComponent implements OnInit {
	constructor(private _ss: StoreService, private router: Router, private _fb: FormBuilder) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	Brands: BrandResponse[] = [];
	Brands$ = new BehaviorSubject<BrandResponse[]>(this.Brands);

	isAdmin = location.href.includes('admin');

	public BizoimCompany;

	public toogleInputNew = false;
	public ShowCompanys = null;
	public HasMultipleCompanys;
	reportSearch = null;

	loading = true;

	destroy$ = new Subject<void>();

	// total$: BehaviorSubject<number>;
	// average$: BehaviorSubject<number>;

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Company: ['', Validators.required],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			const method = this.isAdmin ? 'handleGetBrandAdminTable' : 'handleGetBrandTable';

			this._ss[method]().subscribe({
				next: res => {
					this.loading = false;

					if (res.recordset && res.recordset.length) {
						this.Brands = res.recordset;
						this.Brands$.next(this.Brands);
						// this.total$ = new BehaviorSubject<number>(this.Brands.reduce((acc, curr) => acc + curr.Brand_Total, 0));
						// this.average$ = new BehaviorSubject<number>(this.Brands.reduce((acc, curr) => acc + curr.Brand_Total, 0) / this.Brands.length);
						return;
					}

					alert('לא נמצאו נתונים לתצוגה');
					history.back();
				},
				error: err => {
					console.log(err);
					alert('ישנה תקלת תקשורת');
					history.back();
				},
			});
		} else {
			this.router.navigate(['login']);
		}

		// if (localStorage.getItem('AllProducts') !== null) {
		// 	this.AllProducts = decompress(localStorage.getItem('AllProducts'));
		// 	this.AllProducts = JSON.parse(this.AllProducts);
		// 	this.AllProductsSearch = this.AllProducts;
		// } else {
		// 	this.router.navigate(['clients']);
		// }
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	public AH = false;
	public TYA = false;
	public lYA = false;

	// public SortAhoz() {

	//   if (this.AH === false) {
	//     this.Bizoim.sort(function (a, b) {
	//       return b.Ahuz - a.Ahuz;
	//     });

	//     this.AH = true

	//   } else {

	//     this.Bizoim.sort(function (a, b) {
	//       return a.Ahuz - b.Ahuz;
	//     });

	//     this.AH = false

	//   }

	// }

	// public SortThisYear() {

	//   if (this.TYA === false) {
	//     this.Bizoim.sort(function (a, b) {
	//       return b.Tot - a.Tot;
	//     });

	//     this.TYA = true

	//   } else {

	//     this.Bizoim.sort(function (a, b) {
	//       return a.Tot - b.Tot;
	//     });

	//     this.TYA = false

	//   }

	// }

	// public SortLastYear() {

	//   if (this.lYA === false) {
	//     this.Bizoim.sort(function (a, b) {
	//       return b.Ltot - a.Ltot;
	//     });

	//     this.lYA = true

	//   } else {

	//     this.Bizoim.sort(function (a, b) {
	//       return a.Ltot - b.Ltot;
	//     });

	//     this.lYA = false

	//   }

	// }

	// public ChangeCompany() {

	//   this.Bizoim = []

	//   for (let x = 0; x < this.BizoimCompany.length; x++) {
	//     if (this.BizoimCompany[x].cm === JSON.parse(this.form.controls['Company'].value)) {
	//       this.Bizoim.push(this.BizoimCompany[x])
	//     }
	//   }
	//   // this.refreshSubjects('bizoim')

	// }

	First = true;
	Second = false;

	// monthPicked(mm, YY, cm) {

	//   this.First = false
	//   this.Second = true

	//   this.loading = true

	//   this._ss.AdminAgentsSalesFourth(mm, YY, this._ss.AgentIdConnected, cm).subscribe(
	//     (res:any) => {

	//       this.monthly = res.recordset

	//       for (let x = 0; x < this.monthly.length; x++) {
	//         this.monthly[x].Client_Name = this.monthly[x].cn
	//         this.monthly[x].ClientId = this.monthly[x].Cl
	//       }
	//       this.monthly$.next(this.monthly)

	//       this.refreshSubjects('month')

	//       this.loading = false

	//     }, err => {
	//       alert("ישנה תקלה בשרת")
	//       history.back()
	//     }
	//   )

	// }

	// refreshSubjects(val:'month'|'bizoim'){

	//   if (val === 'month'){
	//     this.total$.next(this.monthly.reduce((p, c) => p + c.tot, 0))
	//     this.average$.next(this.monthly.reduce((p, c) => p + c.tot, 0) / this.monthly.length)

	//   }

	//   if (val === 'bizoim'){
	//   const biz = this.Bizoim.slice(0, new Date().getMonth()+1)

	//   this.total$.next(biz.reduce((p, c) => p + c.Tot, 0))
	//   this.average$.next(biz.reduce((p, c) => p + c.Tot, 0) / biz.length)
	//   }
	// }

	handleBack() {
		if (this.First === false) {
			this.First = true;
			this.Second = false;
			// this.refreshSubjects('bizoim')
			return;
		}

		if (this.First === true) {
			history.back();
		}
	}
}
