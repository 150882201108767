import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { decompress } from 'lz-string';

@Component({
	selector: 'app-admindohotproducts',
	templateUrl: './admindohotproducts.component.html',
	styleUrls: ['./admindohotproducts.component.scss'],
})
export class AdmindohotproductsComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	public form: FormGroup;

	public AllProducts;
	public AllProductsSearch;

	public AllClients;
	public AllClientsSearch;

	public First = true;
	public Second = false;
	public Third = false;
	public Fourth = false;
	public ProductIdChoosen;
	public ProductNameChoosen;
	public ClientNameChoosen;

	public ProductTeuda;

	agentsToGet = [];

	longestName = 0;
	//==============================================================================================================================================>
	ngOnInit(): void {
		window.scrollTo(0, 0);

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.form = this._fb.group({
			ClientSearch: ['', Validators.required],
			ProductSearch: ['', Validators.required],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			if (this._ss.CustomerInformation.Submanagers == '1') {
				if (!this._ss.agentsOfSubmanager.length) {
					this._ss.getAdminAgents().subscribe({
						next: res => {
							if (typeof res == 'string') {
								alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
								return;
							}

							this.agentsToGet = res.recordset.map(obj => obj.Agent);
							this.agentsToGet.push(this._ss.AgentIdConnected);

							this.getAdminProducts();
						},
						error: err => {
							alert('שגיאת שרת');
						},
					});
				} else {
					this.agentsToGet = this._ss.agentsOfSubmanager;
					this.getAdminProducts();
				}
			} else {
				this.getAdminProducts();
			}
		} else {
			this.router.navigate(['login']);
			return;
		}
	}
	//==============================================================================================================================================>
	public BackToAdmin() {
		if (this.First === true) {
			history.back();
		}

		if (this.Second === true) {
			this.First = true;
			this.Second = false;
		}

		if (this.Third === true) {
			this.Second = true;
			this.Third = false;
		}
	}

	//==============================================================================================================================================>

	getAdminProducts() {
		this._ss.GetAdminProducts(this.agentsToGet).subscribe(res => {
			this.AllProducts = res;
			this.AllProducts = this.AllProducts.recordset;
			this.AllProductsSearch = this.AllProducts;

			this.longestName = Math.max(this.longestName, ...this.AllProducts.map(prd => prd.Product_Name.length));
			console.log(this.longestName);
		});
	}

	sortArr = [true, false, false];

	sortString(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'ProductId':
				i = 0;
				break;
			case 'Product_Name':
				i = 1;
				break;
			case 'ItemBarcode':
				i = 2;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	public GetNumPfTimeAllClientsPerProduct(ProductId, Product_Name) {
		this.ProductIdChoosen = ProductId;
		this.ProductNameChoosen = Product_Name;

		this.AllClients = [];

		this.First = false;
		this.Second = true;

		this._ss.GetNumPfTimeAllClientsPerProductService(ProductId, this.agentsToGet).subscribe(res => {
			this.AllClients = res;
			this.AllClients = this.AllClients.recordset;
			this.AllClientsSearch = this.AllClients;
		});
	}

	public GetProductTeuda(ClientId, Client_Name) {
		this.ClientNameChoosen = Client_Name;

		this.ProductTeuda = [];

		this.Second = false;
		this.Third = true;

		this._ss.GetProductTeudaService(this.ProductIdChoosen, ClientId).subscribe(res => {
			this.ProductTeuda = res;
			this.ProductTeuda = this.ProductTeuda.recordset;
		});
	}
	//==============================================================================================================================================>
	//==============================================================================================================================================>
	//==============================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	public ClearClientSearch() {
		window.scrollTo(0, 0);

		this.AllClients = [];

		this.form.reset();
		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			this.AllClients.push(this.AllClientsSearch[x]);
			this.longestName = Math.max(this.longestName, this.AllClientsSearch[x].Client_Name.length);
		}
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public SearchClient() {
		window.scrollTo(0, 0);

		this.AllClients = [];

		if (this.form.controls['ClientSearch'].value) {
			const pattern = new RegExp(this.form.controls['ClientSearch'].value.replace(/[\/*\+\-\?,]/g, ''), 'i');

			console.log(pattern);

			for (const client of this.AllClientsSearch) {
				if (String(client.Client_Name + client.ClienId).match(pattern)) {
					this.AllClients.push(client);
					this.longestName = Math.max(this.longestName, client.Client_Name.length);
				}
			}
		} else {
			this.AllClients = this.AllClientsSearch;
		}

		// if (this.form.controls['ClientSearch'].value === "" || this.form.controls['ClientSearch'].value.length === 0) {
		//   for (let x = 0; x < this.AllClientsSearch.length; x++) {
		//     if (this.AllClients.length < 100) {
		//       this.AllClients.push(this.AllClientsSearch[x])
		//     }
		//   }
		// }
		// if (this.form.controls['ClientSearch'].value.length === 1) {
		//   for (let x = 0; x < this.AllClientsSearch.length; x++) {
		//     if (this.AllClientsSearch[x].Client_Name.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1) ||
		//       this.AllClientsSearch[x].ClientId.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1)
		//     ) {
		//       this.AllClients.push(this.AllClientsSearch[x])
		//     }
		//   }
		// }
		// if (this.form.controls['ClientSearch'].value.length === 2) {
		//   for (let x = 0; x < this.AllClientsSearch.length; x++) {
		//     if (this.AllClientsSearch[x].Client_Name.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2) ||
		//       this.AllClientsSearch[x].ClientId.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2)
		//     ) {
		//       this.AllClients.push(this.AllClientsSearch[x])
		//     }
		//   }
		// }
		// if (this.form.controls['ClientSearch'].value.length > 2) {
		//   for (let x = 0; x < this.AllClientsSearch.length; x++) {
		//     for (let z = 0; z < 50; z++) {
		//       if (this.AllClientsSearch[x].Client_Name.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) === this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length) ||
		//         this.AllClientsSearch[x].ClientId.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) === this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length)
		//       ) {
		//         let data = this.AllClients.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
		//         if (data === undefined) {
		//           this.AllClients.push(this.AllClientsSearch[x])
		//         }

		//       }
		//     }
		//   }
		// }
	}
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	public ClearProductsSearch() {
		window.scrollTo(0, 0);
		this.AllProducts = [];

		this.form.reset();

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			this.AllProducts.push(this.AllProductsSearch[x]);
			if (this.AllProducts.length === 200) {
				break;
			}
		}
	}
	//==========================================================================================================================================================>
	public SearchProducts() {
		if (this.form.controls['ProductSearch'].value === null) {
		} else {
			window.scrollTo(0, 0);

			this.AllProducts = [];

			if (this.form.controls['ProductSearch'].value === '' || this.form.controls['ProductSearch'].value.length === 0) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					this.AllProducts.push(this.AllProductsSearch[x]);
					if (this.AllProducts.length === 100) {
						break;
					}
				}
			}
			if (this.form.controls['ProductSearch'].value.length === 1) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					if (
						this.AllProductsSearch[x].Product_Name.toString().slice(0, 1) === this.form.controls['ProductSearch'].value.toString().slice(0, 1) ||
						this.AllProductsSearch[x].ProductId.toString().slice(0, 1) === this.form.controls['ProductSearch'].value.toString().slice(0, 1)
					) {
						this.AllProducts.push(this.AllProductsSearch[x]);
						if (this.AllProducts.length === 20) {
							break;
						}
					}
				}
			}
			if (this.form.controls['ProductSearch'].value.length === 2) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					if (
						this.AllProductsSearch[x].Product_Name.toString().slice(0, 2) === this.form.controls['ProductSearch'].value.toString().slice(0, 2) ||
						this.AllProductsSearch[x].ProductId.toString().slice(0, 2) === this.form.controls['ProductSearch'].value.toString().slice(0, 2)
					) {
						this.AllProducts.push(this.AllProductsSearch[x]);
						if (this.AllProducts.length === 20) {
							break;
						}
					}
				}
			}
			if (this.form.controls['ProductSearch'].value.length > 2) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					for (let z = 0; z < 50; z++) {
						if (
							this.AllProductsSearch[x].Product_Name.toString().slice(z, this.form.controls['ProductSearch'].value.length + z) ===
								this.form.controls['ProductSearch'].value.toString().slice(0, this.form.controls['ProductSearch'].value.length) ||
							this.AllProductsSearch[x].ProductId.toString().slice(z, this.form.controls['ProductSearch'].value.length + z) ===
								this.form.controls['ProductSearch'].value.toString().slice(0, this.form.controls['ProductSearch'].value.length) ||
							this.AllProductsSearch[x].ItemBarcode.toString().slice(z, this.form.controls['ProductSearch'].value.length + z) ===
								this.form.controls['ProductSearch'].value.toString().slice(0, this.form.controls['ProductSearch'].value.length)
						) {
							let data = this.AllProducts.find(ob => ob.ProductId === this.AllProductsSearch[x].ProductId);
							if (data === undefined) {
								this.AllProducts.push(this.AllProductsSearch[x]);
							}
						}
					}
				}
			}
			if (this.AllProducts.length === 0) {
			}
		}
	}

	//-------------------->
	//==============================================================================================================================================>
	//==============================================================================================================================================>
	//==============================================================================================================================================>
}
