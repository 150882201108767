import { Pipe, PipeTransform } from '@angular/core';
import { AdminTypes } from '../Interfaces/users';
import { StoreService } from '../services/store.service';

type BelowStock = 0 | 1 | 2 | 3 | 4 | 5 | 6;

@Pipe({
	name: 'shouldStockShow',
})
export class ShouldStockShowPipe implements PipeTransform {
	constructor(private _ss: StoreService) {}

	transform(
		value: BelowStock, // BS in products table SQL
		place: 'productPopup' | 'productBrowser' | 'StockToggle1' | 'StockToggle2',
		showMlay?: boolean,
		admin?: AdminTypes,
	): boolean {
		const HideStock: '0' | '1' | '2' | '3' | '4' | '5' | '6' | undefined = this._ss.CustomerInformation.HideStock;
		const isB2BAgent = admin && admin.toLowerCase().includes('b2b');
		const isB2BPAgent = isB2BAgent && admin.toLowerCase().includes('b2bp');

		if (admin === 'b2bL' || admin === 'b2bLM') return false;

		if (place === 'StockToggle2' && (value === 3 || (HideStock && HideStock !== '0' && HideStock !== '1' && HideStock !== '2' && value === 0))) {
			if (HideStock === '5' || HideStock === '6') return false;

			return !!showMlay;
		}
		if (place === 'StockToggle1' && (value === 3 || (HideStock && HideStock !== '0' && HideStock !== '1' && HideStock !== '2' && value === 0))) {
			if ((HideStock === '5' && (!isB2BAgent || isB2BPAgent)) || HideStock === '6') return false;
			return !showMlay;
		}

		if (place === 'productPopup' && (value === 1 || value === 6 || (HideStock !== '1' && HideStock !== '3' && HideStock !== '4' && value === 0))) {
			if ((HideStock === '5' && isB2BAgent && !isB2BPAgent) || HideStock === '6') return false;

			return true;
		}

		if (place === 'productBrowser' && (value === 1 || value === 6 || (HideStock !== '1' && HideStock !== '2' && HideStock !== '3' && HideStock !== '4' && value === 0))) {
			if ((HideStock === '5' && isB2BAgent && !isB2BPAgent) || HideStock === '6') return false;

			return true;
		}

		return false;
	}
}
