import { Injectable } from '@angular/core';
import { AllDocTypes, DocTypeDefinitions, DocTypesNumToName } from '../Interfaces/SQL-morning-responses';
import { SPECIAL_REFUND_DOCTYPES } from '../Utility/constants-doctype';

export const doctypeOptions = {
	0: 'order',
	1: 'return',
	2: 'virtual',
} as const;

@Injectable({
	providedIn: 'root',
})
export class DoctypeService {
	private doctypeNamesRecord: Record<number, string> = {};
	private doctypesPerClient: Record<string, number[]> = {};

	doctypesSet: Set<number> = new Set();
	doctypesDefinitions: DocTypeDefinitions[] = [];
	hasExtraDoctypeDefinitions = false;
	typesOfDoctypes: { [P in typeof doctypeOptions[keyof typeof doctypeOptions]]: Set<number> } = {
		order: new Set(),
		return: new Set(),
		virtual: new Set(),
	};

	constructor() {}

	buildDoctypeNamesRecord(doctypes: AllDocTypes[]): void {
		if (!Object.keys(this.doctypesPerClient).length) {
			this.buildDoctypeNamesAndDoctypesPerClient(doctypes);
			return;
		}
		this.doctypeNamesRecord = doctypes.reduce((acc, doctype) => {
			this.doctypesSet.add(doctype.DocType);
			acc[doctype.DocType] = doctype.DocName;
			return acc;
		}, {});
	}

	buildDoctypesPerClient(doctypes: AllDocTypes[]): void {
		if (!Object.keys(this.doctypeNamesRecord).length) {
			this.buildDoctypeNamesAndDoctypesPerClient(doctypes);
			return;
		}
		this.doctypesPerClient = doctypes.reduce((acc, doctype) => {
			this.doctypesSet.add(doctype.DocType);
			acc[doctype.ClientID] ||= [];
			acc[doctype.ClientID].push(doctype.DocType);
			return acc;
		}, {});
	}

	buildDoctypeNamesAndDoctypesPerClient(doctypes: AllDocTypes[]): void {
		for (const doctype of doctypes) {
			this.doctypesSet.add(doctype.DocType);
			this.doctypeNamesRecord[doctype.DocType] = doctype.DocName;
			this.doctypesPerClient[doctype.ClientID] ||= [];
			this.doctypesPerClient[doctype.ClientID].push(doctype.DocType);
		}
	}

	buildDoctypesDefinitions(): void {
		this.hasExtraDoctypeDefinitions = true;
		if (!this.doctypesDefinitions.length || !('Typeof' in this.doctypesDefinitions[0])) {
			this.hasExtraDoctypeDefinitions = false;
			return;
		}
		for (const doctype of this.doctypesDefinitions) {
			this.typesOfDoctypes[doctypeOptions[doctype.Typeof]].add(doctype.DocType);
		}
	}

	getDoctypesPerClient(clientId: string): DocTypesNumToName[] {
		return this.doctypesPerClient[clientId].map(doctype => ({ DocType: doctype, DocTypeName: this.doctypeNamesRecord[doctype] }));
	}

	getDoctypeName(doctypeId: number): string | undefined {
		return this.doctypeNamesRecord[doctypeId];
	}

	setDoctypeName(doctypeId: number, doctypeName: string): void {
		this.doctypeNamesRecord[doctypeId] = doctypeName;
	}

	resetDoctypeNames(): void {
		this.doctypeNamesRecord = {};
	}

	resetDoctypesPerClient(): void {
		this.doctypesPerClient = {};
	}

	getAllDoctypeNames(doctypes: number[]): { doctype: number; name: string }[] {
		return doctypes.map(doctype => ({ doctype, name: this.getDoctypeName(doctype) }));
	}

	doesHaveExtraDoctypeDefinitions(): boolean {
		return this.hasExtraDoctypeDefinitions;
	}

	getAllDoctypes(): number[] {
		return [...this.doctypesSet];
	}

	isSpecialRefundDoctype(doctype: number | string) {
		return SPECIAL_REFUND_DOCTYPES.has(+doctype);
	}
}
