import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { alertActivity, MsgType } from 'src/app/Interfaces/alerts';
import { IsAlertActivePipe } from 'src/app/Pipes/is-alert-active.pipe';

@Component({
	selector: 'app-clientactiv2',
	templateUrl: './clientactiv2.component.html',
	styleUrls: ['./clientactiv2.component.scss'],
})
export class Clientactiv2Component implements OnInit {
	constructor(public _ss: StoreService, private router: Router, private _fb: FormBuilder, private _acr: ActivatedRoute, public _alert: AlertService) {}

	public form = this._fb.group({
		Report: [<string | null>'', Validators.required],
		Reason: [<string | null>'', Validators.required],
		reasonVisit: [<string | null>'', Validators.required],
		Status: [<string | null>'', Validators.required],
	});
	showActivityProducts = true;
	showLogoutPopup = false;

	public ResAgentActivity;

	public ConfirmSentReport = false;

	public Reasons;

	reasonsForVisit = [];

	public TodayDate;

	public ReasonRemark = '';

	public NewCartBikor;

	public Loading = false;

	public ShowExit = false;

	public BikorString;

	public KnisaTime;

	public PendingBikor;
	public FinishBikor;

	addToVisitAlertText = '';

	openOnExit = !!this._acr.snapshot.queryParams['exit'];

	canSelectMultipleReasons = this._ss.CustomerInformation?.VisitMultipleReasons === '1';

	ngOnInit(): void {
		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
			return;
		}

		if (this.openOnExit) {
			this.showActivityProducts = false;
		}

		if (localStorage.getItem('knisa' + this._ss.OneClientInfo.ClientId) !== null) {
			this.ShowExit = true;

			this.KnisaTime = localStorage.getItem('knisa' + this._ss.OneClientInfo.ClientId);

			this.KnisaTime = JSON.parse(this.KnisaTime);
		}

		this.PendingBikor = [];
		if (localStorage.getItem('PendingBikor') !== null) {
			this.PendingBikor = localStorage.getItem('PendingBikor');
			this.PendingBikor = JSON.parse(this.PendingBikor);
		}

		this.FinishBikor = [];
		if (localStorage.getItem('FinishBikor') !== null) {
			this.FinishBikor = localStorage.getItem('FinishBikor');
			this.FinishBikor = JSON.parse(this.FinishBikor);
		}

		this._ss.GetAllReportsAgentnew().subscribe(res => {
			this.ResAgentActivity = res;
			this.ResAgentActivity = this.ResAgentActivity.recordset;

			for (const resAct in this.ResAgentActivity) {
				if (this.ResAgentActivity[resAct].DATAString) {
					this.ResAgentActivity[resAct] = { ...this.ResAgentActivity[resAct], ...JSON.parse(this.ResAgentActivity[resAct].DATAString) };
				}
			}

			console.log(this.ResAgentActivity);

			this._ss.GetAllReportsAgentReasons().subscribe((res: any) => {
				this.Reasons = res;
				this.Reasons = this.Reasons.recordset;

				if (this.Reasons.some(r => [2, 3].includes(r.rem))) {
					this.Reasons = res.recordset.filter(r => [0, 1].includes(r.rem));
					this.reasonsForVisit = res.recordset.filter(r => [2, 3].includes(r.rem));

					for (const key in this.reasonsForVisit) {
						const element = this.reasonsForVisit[key];

						const visits = this.ResAgentActivity.filter(r => r.ResonVisitID);

						for (const visit of visits) {
							visit.ResonVisitIDText = element.rds;
						}
					}
				}
			});
		});
	}

	public Knisa() {
		this.TodayDate = new Date();
		localStorage.setItem('knisa' + this._ss.OneClientInfo.ClientId, JSON.stringify(this.TodayDate));

		alert(' כניסה ללקוח ' + this._ss.OneClientInfo.Client_Name + ' בשעה ' + this.TodayDate.toString().slice(15, 24));

		this.PendingBikor.push({ Knisa: this._ss.OneClientInfo.ClientId });

		localStorage.setItem('PendingBikor', JSON.stringify(this.PendingBikor));

		this.router.navigate(['clients'], { replaceUrl: true });
	}

	public BackToClientCard() {
		history.back();
	}

	public CloseSentReportPopOpen() {
		this.ConfirmSentReport = false;

		this.form.reset();

		this.NewCartBikor;

		this.router.navigate(['clients'], { replaceUrl: true });
	}

	public RemCheck;

	public StatusRemark = '';

	public ExitTime;
	public ConfirmBikor() {
		if (this._ss.CustomerInformation?.BikorMustIncludeReason === '1' && !this.form.controls.Reason.value?.length) {
			alert('חייב לבחור סיכום ביקור');
			return;
		}

		for (let x = 0; x < this.Reasons.length; x++) {
			if (this.form.controls['Reason'].value === this.Reasons[x].rid) {
				this.ReasonRemark = this.Reasons[x].rds;
				break;
			}
		}

		const needsRemarkArr = this.Reasons.filter(r => !isNaN(+r.rem) && +r.rem % 2).map(r => r.rem);

		if (needsRemarkArr.includes(+this.form.controls.Reason.value) && !this.form.controls.Report.value) {
			alert('חייב להכניס פירוט אי מכירה');
			return;
		}

		this.ExitTime = JSON.stringify(new Date());

		this.Loading = true;

		this.StatusRemark = this.form.controls['Report'].value;

		let intDate = +new Date();

		this.NewCartBikor = JSON.stringify(intDate);

		this.NewCartBikor = this.NewCartBikor + '000' + this._ss.AgentIdConnected;

		this.BikorString = {
			Enter: this.KnisaTime,
			Exit: this.ExitTime,
			VisitID: this.NewCartBikor,
			ClientID: this._ss.OneClientInfo.ClientId,
			ResonID: this.form.controls.Reason.value,
			ResonVisitID: this.form.controls.reasonVisit.value,
			Remark: this.StatusRemark,
			PicName: '0',
			company: this._ss.OneClientInfo.company,
			Latitude: '0',
			Longitude: '0',
			ResDesc: this.ReasonRemark,
			AgentID: this._ss.AgentIdConnected,
			ClientName: this._ss.OneClientInfo.Client_Name,
		};

		if (this.addToVisitAlertText) {
			this.BikorString.addToVisitAlertText = this.addToVisitAlertText;
		}

		setTimeout(() => {
			this._ss
				.BikorConfirmnew({
					VisitID: this.NewCartBikor,
					ClientID: this._ss.OneClientInfo.ClientId,
					ResonID: this.form.controls['Reason'].value,
					Remark: this.StatusRemark,
					PicName: '0',
					company: this._ss.OneClientInfo.company,
					Latitude: '0',
					Longitude: '0',
					ResDesc: this.ReasonRemark,
					AgentID: this._ss.AgentIdConnected,
					ClientName: this._ss.OneClientInfo.Client_Name,
					DATAString: JSON.stringify(this.BikorString),
				})
				.subscribe(
					res => {
						if (res !== 'RequestError') {
							this.Loading = false;
							this.ConfirmSentReport = true;
							localStorage.removeItem('knisa' + this._ss.OneClientInfo.ClientId);

							this.FinishBikor.push({ Exit: this._ss.OneClientInfo.ClientId });
							localStorage.setItem('FinishBikor', JSON.stringify(this.FinishBikor));

							this.PendingBikor.splice(this.PendingBikor.indexOf(this.PendingBikor.find(ob => ob.Knisa === this._ss.OneClientInfo.ClientId)), 1);
							localStorage.setItem('PendingBikor', JSON.stringify(this.PendingBikor));
						} else {
							alert('ישנה תקלה,אנא נסה שנית');
							this.Loading = false;
						}
					},
					err => {
						alert('ישנה תקלה,אנא נסה שנית');
						this.Loading = false;
					},
				);
		}, 200);
	}

	newExitReprot() {
		this.showActivityProducts = false;
		this._alert.handleAlertLogic(MsgType.newVisit.msgType);
		const anyAlerts = this._alert.handleFilterRelevantAlerts(MsgType.newVisit.msgType);

		if (!anyAlerts.length) return;

		this.addToVisitAlertText = anyAlerts.map(alert => alert.Msg).join(', ');
	}

	showHideLogoutPopup(value: boolean): void {
		this.showLogoutPopup = value;
	}

	navigateToAlerts(): void {
		this.router.navigate(['manage-alerts'], { queryParams: { newAlert: true, backTwice: this.openOnExit ? null : true }, replaceUrl: true });
	}
}
