import { Injectable } from '@angular/core';
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root',
})
export class TextService {
	constructor(private _ss: StoreService) {}

	packQuantityText = this._ss.CustomerInformation?.PackQuantityText === '1' ? 'כמות' : 'אריזות';

	store = {
		homeScreenAction: '',
		homeScreenAction2: '',
		mainTasks: '',
		teudaName: '',
		teudaNameGroupB: '',
		backToTasks: '',
		name: {
			'1': '',
			'2': '',
			'3': '',
		},
		collected: '',
		noClientsForMekabelError: 'לא ניתן לקבל למחסן ללא ספקים. אנא עדכנו נתונים',
		quantityOrdered: { '0': `יחידות שהוזמנו`, '0-2': 'אריזות שהוזמנו', '0-3': 'מחיר', '1': 'כמות שהוזמנה', '2': 'כמות שהוזמנה' },
		quantityCollected: { '0': ``, '0-2': '', '0-3': '', '0-4': '', '1': '', '2': '' },
		updatingTasks: `מעדכן משימות`,
		returnTask: '',
		returnTaskConfirmation: '',
		confirmReturn: 'החזר',
		confirmContinue: 'המשך',
		declineReturn: 'בטל',
		manageTaskReportTitle: '',
		manageCertainStatusReportTitle: '',
		manageNotFulfilled: 'לא לוקט',
		manageNotFulfilledTruckDistribution: 'תעודות שלא סופקו',
		manageSuppliedTruckDistribution: 'תעודות שסופקו',
		manageReportTableHeaders1: <{ text: string; element: 'th' | 'button'; action?: string }[]>[],
		changeStatusConfirmation: ['האם הנך בטוח שתרצה ', 'תעודות אלה?'],
		// activateStatusConfirmation: ['האם הנך בטוח שתרצה לאשר תעודות אלה?'],
		// activateStatusConfirmation: 'האם הנך בטוח שתרצה לאשר תעודות אלה?',
		// cancelStatusConfirmation: 'האם הנך בטוח שתרצה  תעודות אלה?',
		noStatusChecked: 'לא סומנו תעודות',
		taskUpdatedSuccess: '',
		taskAssignmentReport: 'שיוך ליקוטים',
		taskAssignmentReportLikut: 'ניהול משימות ליקוט',
		taskAssignmentReport2: 'ניהול משימות הפצה',
		taskAssignmentReport3: 'ניהול משימות העמסה',
		taskAssignmentReportMapDistribution: 'מפת הפצות',
		generatePassword: 'הפק סיסמה',
		passwordPrompt: 'חלק מהפריטים לא סומנו. אנא סמן את כולם, או לחילופין, הכנס סיסמה כדי להתקדם.',
		wrongPassword: 'סיסמה שגויה',
		dashboard: 'תמונת מצב',
		manageNoStatus: '',
		cantFinishAlert: 'לא כל הפריטים סומנו, אין אפשרות לסיים את המשימה',
		date: '',
		updatingGeneral: 'טוען אנא המתן...',
		search: 'חיפוש',
		barcodeNotFound: ['ברקוד מוצר ', ' לא נמצא.'],
		totalTeudot: 'תעודות',
		totalRow: 'שורות',
		notFound: ['אופס, לא מצאנו את מה שחיפשתם', 'כדאי לבדוק איות או לנסות לחפש לפי פרמטר אחר'],
		reports: 'דוחות',
		updateTasks: `עדכן משימות`,
		alreadyOpenedByMsg: `משימה זו כבר נפתחה ע"י סוכן `,
		updateNearFinish: 'כבר מסיימים...',
		taskConnectionError: `ישנה תקלה בשרת, לא ניתן לראות משימות`,
		taskConnectionErrorSpecific: `ישנה תקלה בשרת, לא ניתן להביא נתונים עבור משימה זו`,
		missingStoredData: 'לא נמצאו פריטים, יש צורך בעדכון נתונים',
		teudaNum: `אסמכתא`,
		ordered: `הוזמן`,
		quantityUpdateTitle: `עדכון כמות לפריט`,
		showingNumberOfProducts: ['מציג', 'מוצרים'],
		totalProducts: 'סה"כ פריטים',
		totalQuantity: 'סה"כ כמות',
		goBack: 'חזרה',
		barcodeError: {
			unsupported: 'קורא ברקוד אינו נתמך במכשיר זה',
			needsCameraAccess: 'יש לאפשר גישה למצלמה כדי להפעיל סורק ברקוד',
		},
		signature: 'חתימה',
		remarks: 'הערות',
		send: 'שלח',
		save: 'שמור',
		couldntPatch: `ישנה תקלה בשרת, לא ניתן לעדכן משימה`,
		backToLikut: `חזור למשימות`,
		processSuccessful: ['בוצע בהצלחה!', 'משימה עודכנה בהצלחה'],
		homepage: 'דף הבית',
		deliveryLine: 'קו חלוקה',
		mismatchedQuantityErrorMessage: 'הכמויות לא תואמות באחד מן הפריטים, האם אתם בטוחים שתרצו להמשיך?',
	};

	updateTextStore(input: Partial<typeof this.store>) {
		this.store = { ...this.store, ...input };
	}

	refreshCustomerParameters() {
		this.packQuantityText = this._ss.CustomerInformation?.PackQuantityText === '1' ? 'כמות' : 'אריזות';
	}
}
