import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { Subject, takeUntil, catchError, of, from, mergeMap } from 'rxjs';
import { SalesVsReturns } from 'src/app/Interfaces/sales-vs-returns';
import { Users } from 'src/app/Interfaces/users';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-sales-vs-returns',
	templateUrl: './sales-vs-returns.component.html',
	styleUrls: ['./sales-vs-returns.component.scss'],
})
export class SalesVsReturnsComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, private router: Router, private _fb: FormBuilder) {}

	form: FormGroup;
	AllProducts;
	AllProductsSearch;
	Bizoim: SalesVsReturns.Main[] = [];
	BizoimSwitch;
	BizoimSwitchMain;
	DateToday = new Date();

	toogleInputNew = false;

	AllClientsSearch;

	test;
	test2;

	First = true;
	Second = false;
	Third = false;
	Fourth = false;

	BizoimEach: SalesVsReturns.PerAgent[] = [];
	BizoimEach2: SalesVsReturns.PerClient[] = [];
	BizoimEach3: SalesVsReturns.PerMonth[] = [];

	ShowCompanys;

	HasMultipleCompanys;

	CompanyChoosen = 1;

	OneTeudaInfo;

	Loading = false;

	LastPage2 = false;

	currentClientNameAndID;

	year = new Date().getFullYear();

	agentsForAdmin = [];

	destroy$ = new Subject<void>();

	agents: any[] = [];
	agentsControl = new FormControl<string[]>(['']);

	selectedYear = 0;
	selectedAgent = '';

	async ngOnInit() {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Month: ['', Validators.required],
			ClientSearch: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (!localStorage.getItem('UserName') || !localStorage.getItem('AgentId')) {
			this.router.navigate(['login']);
			return;
		}

		this.Loading = true;

		this._ss.getSalesVsReturnsAdmin().subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת שרת, אנא נסה שנית מאוחר יותר');
					history.back();
					return;
				}

				this.Bizoim = res.recordset;

				this.Bizoim = Object.values(
					this.Bizoim.reduce((prev, curr): { [key: string]: SalesVsReturns.Main } => {
						if (!prev[curr.agentcode]) {
							prev[curr.agentcode] = {
								...curr,
								totreturn: +curr.yy === this.year ? curr.totreturn : 0,
								ltotreturn: +curr.yy === this.year ? 0 : curr.totreturn,
								totsale: +curr.yy === this.year ? curr.totsale : 0,
								ltotsale: +curr.yy === this.year ? 0 : curr.totsale,
								ahuz: curr.ahuz || 0,
							};

							return prev;
						}

						prev[curr.agentcode] = {
							...prev[curr.agentcode],
							totreturn: +curr.yy === this.year ? curr.totreturn : prev[curr.agentcode].totreturn,
							ltotreturn: +curr.yy === this.year ? prev[curr.agentcode].ltotreturn : curr.totreturn,
							totsale: +curr.yy === this.year ? curr.totsale : prev[curr.agentcode].totsale,
							ltotsale: +curr.yy === this.year ? prev[curr.agentcode].ltotsale : curr.totsale,
							ahuz: prev[curr.agentcode].ahuz || curr.ahuz || 0,
						};

						return prev;
					}, {}),
				);

				this.Loading = false;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
				history.back();
				return;
			},
		});
	}

	ngOnDestroy(): void {
		this._ss.ParamBitzoimClientCard = null;

		this.destroy$.next();
		this.destroy$.complete();
	}

	getSalesVsReturnsPerAgent(agentcode, yy) {
		this.Loading = true;
		this._ss.getSalesVsReturnsPerAgentAdmin(agentcode, yy).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת שרת, אנא נסו שנית מאוחר יותר.');
					history.back();
					return;
				}

				this.Loading = this.First = false;
				this.Second = true;

				this.selectedYear = yy;

				if (res.recordset.length) this.selectedAgent = res.recordset[0].agentname;

				this.BizoimEach = res.recordset;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר.');
				history.back();
				return;
			},
		});
	}

	getSalesVsReturnsPerAgentPerClient(agentcode, yy, clientid) {
		this.Loading = true;
		this._ss.getSalesVsReturnsPerAgentPerClientAdmin(agentcode, yy, clientid).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת שרת, אנא נסו שנית מאוחר יותר.');
					history.back();
					return;
				}

				const arr: SalesVsReturns.PerClient[] = new Array(12).fill({ totreturn: 0, totsale: 0, ahuz: 0 }).map((obj, i) => ({ ...obj, mm: +i + 1 }));

				for (const obj of res.recordset) {
					arr[obj.mm - 1] = obj;
				}

				console.log({ arr });

				this.BizoimEach2 = arr;

				this.Loading = this.Second = false;
				this.Third = true;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר.');
				history.back();
				return;
			},
		});
	}

	getSalesVsReturnsPerAgentPerClientPerMonth(agentcode, yy, clientid, mm) {
		if (!agentcode || !yy || !clientid) {
			this.BizoimEach3 = [];
			this.Loading = this.Third = false;
			this.Fourth = true;
			return;
		}

		this.Loading = true;
		this._ss.getSalesVsReturnsPerAgentPerClientMonthAdmin(agentcode, yy, clientid, mm).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת שרת, אנא נסו שנית מאוחר יותר.');
					history.back();
					return;
				}

				this.BizoimEach3 = res.recordset;

				this.Loading = this.Third = false;
				this.Fourth = true;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר.');
				history.back();
				return;
			},
		});
	}

	BackToDohotBizoim() {
		if (this.First === true) {
			// this.router.navigate(['dohotbizoim'])
			window.history.back();
		}

		if (this.Second === true) {
			this.Loading = false;
			this.Second = false;
			this.First = true;
			this.BizoimEach = null;
			this.selectedYear = 0;
			this.selectedAgent = '';
		}

		if (this.Third === true) {
			this.Loading = false;
			this.Third = false;
			this.Second = true;
		}

		if (this.Fourth === true) {
			this.Loading = false;
			this.Fourth = false;
			this.Third = true;
		}
	}

	// SearchClear() {
	// 	this.Bizoim = this.BizoimSwitch;

	// 	this.form.reset();
	// }

	// SearchClient() {
	// 	const searchInput = this.form.controls['ClientSearch'].value?.toLowerCase?.();
	// 	const searchInputLength = searchInput?.length;

	// 	this.Bizoim = [];

	// 	if (!searchInput) {
	// 		this.Bizoim = this.BizoimSwitch;
	// 		return;
	// 	}

	// 	if (searchInputLength <= 2) {
	// 		for (let x = 0; x < this.AllClientsSearch.length; x++) {
	// 			if (
	// 				this.AllClientsSearch[x].Client_Name.toString().slice(0, searchInputLength).toLowerCase() === searchInput ||
	// 				this.AllClientsSearch[x].ClientId.toString().slice(0, searchInputLength).toLowerCase() === searchInput
	// 			) {
	// 				this.Bizoim.push(this.AllClientsSearch[x]);
	// 			}
	// 		}
	// 		return;
	// 	}

	// 	for (let x = 0; x < this.AllClientsSearch.length; x++) {
	// 		for (let z = 0; z < 50; z++) {
	// 			const len = searchInputLength + z;

	// 			if (this.AllClientsSearch[x].Client_Name.toString().slice(z, len) === searchInput) {
	// 				this.Bizoim.push(this.AllClientsSearch[x]);
	// 				break;
	// 			}

	// 			if (this.AllClientsSearch[x].ClientId.toString().slice(z, len) === searchInput) {
	// 				this.Bizoim.push(this.AllClientsSearch[x]);
	// 				break;
	// 			}
	// 		}
	// 	}
	// }

	sortArr = [true];

	sort(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };
		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'agentcode':
			case 'clientname':
			case 'mm':
				i = 0;
				break;
			case 'totsale':
				i = 1;
				break;
			case 'ltotsale':
				i = 2;
				break;
			case 'totreturn':
				i = 3;
				break;
			case 'ltotreturn':
				i = 4;
				break;
			case 'ahuz':
				i = 5;
				break;

			default:
				console.log('I should never return');
				return;
		}

		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}
}
