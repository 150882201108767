import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base-component/base.component';
import { AgentGviaService } from 'src/app/services/agent-gvia.service';
import { DebtByDateService } from 'src/app/services/debt-by-date.service';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { ReportDashboardItemInput } from '../report-dashboard-item/report-dashboard-item.component';

@Component({
	selector: 'app-dohotlekohot',
	templateUrl: './dohotlekohot.component.html',
	styleUrls: ['./dohotlekohot.component.scss'],
})
export class DohotlekohotComponent extends BaseComponent implements OnInit {
	constructor(public router: Router, private _agentGvia: AgentGviaService, private _debtByDate: DebtByDateService) {
		super();
	}

	items: ReportDashboardItemInput[] = [{ path: 'dohot-lekohot-hovot', text: 'ריכוז חובות' }].map(item => ({ ...item, icon: 'customers' }));

	endpointArray = ['/mobil/debt/agent-gvia', '/mobil/reports/debt-by-dates'];

	logo = MOBILITY_LOGO;

	async ngOnInit() {
		window.scrollTo(0, 0);

		this._ss.OneClientInfo = null;
		this._ss.OneClientInfoDebt = null;
		this._ss.DocTypeName = null;
		this._ss.DocType = null;
		this._ss.AllOrdersDetailsClient = null;
		this._ss.SpecPriceSs = null;
		this._ss.VCust = null;
		this._ss.BackFromClientCard = false;
		this._ss.FromProductsToDohot = false;
		this._ss.MivzaA = null;
		this._ss.HearaNewOrder1 = '';
		this._ss.HearaNewOrder2 = '';
		this._ss.HearaNewOrder3 = '';
		this._ss.HearaNewOrder4 = '';
		this._ss.HearotHeadForTeuda = null;

		this._debtByDate.debtDateControl.reset();

		const fetchArray = this.endpointArray.map(endpoint => fetch(`${this._ss.CustomerInformation.CustServer}${endpoint}?checkIfExistsInIndex=true`));

		Promise.allSettled(fetchArray).then(([agentGvia, debtByDates]) => {
			let totalIndex = 0;
			if (debtByDates.status === 'fulfilled' && debtByDates.value.ok) {
				this.mutateItemsArray({ path: 'dohot-lekohot-hovot-by-date', text: 'ריכוז חובות לפי תקופה' }, 1 + totalIndex);
				totalIndex++;
			}
			if (agentGvia.status === 'fulfilled' && agentGvia.value.ok) {
				agentGvia.value.json().then(bod => {
					if (bod.recordset[0].res === 1) {
						this._agentGvia.hasAgentGvia = true;
						this.mutateItemsArray({ path: 'agent-gvia', text: 'לקוחות לגבייה' }, 2 + totalIndex);
					}
				});
			}
		});

		this._agentGvia.AgentsSalesAll = [];
		this._agentGvia.AgentsSalesAllResponse = [];
		this._agentGvia.AgentsSalesAllSecond = [];
		this._agentGvia.AgentsSalesAllSecondObject = {};
	}

	mutateItemsArray(newItem: { path: string | string[]; text: string }, index: number): void {
		this.items = [...this.items.slice(0, index), { ...newItem, icon: 'customers' }, ...this.items.slice(index)];
	}

	public MoveToClients() {
		this.router.navigate(['clients']);
	}

	public BackToHome() {
		this.router.navigate(['home']);
	}

	public BackToDohot() {
		history.back();
	}
}
