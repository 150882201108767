export const specialMock: any[] = [
	{
		id: 1,
		specialID: 1,
		name: 'מבצע דמה 1',
		startDate: new Date(),
		endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
		status: false,
		productID: '1031',
		stock: 50,

		company: 1,
		packs: 0,
		units: 10,
		price: 10,
	},
	{
		id: 2,
		specialID: 1,
		name: 'מבצע דמה 1',
		startDate: new Date(),
		endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
		status: false,
		productID: '3102',
		stock: 50,

		company: 1,
		packs: 0,
		units: 5,
		price: 20,
	},
	{
		id: 3,
		specialID: 2,
		name: 'מבצע דמה 2',
		startDate: new Date(),
		endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
		status: true,
		productID: '3102',
		stock: 50,

		company: 1,
		packs: 0,
		units: 5,
		price: 20,
	},
	{
		id: 4,
		specialID: 2,
		name: 'מבצע דמה 2',
		startDate: new Date(),
		endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
		status: true,
		productID: '9060',
		stock: 50,

		company: 1,
		packs: 0,
		units: 2,
		price: 20,
	},
	{
		id: 5,
		specialID: 3,
		name: 'מבצע דמה 3',
		startDate: new Date(),
		endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
		status: true,
		productID: '3102',
		stock: 50,
		company: 1,
		packs: 2,
		units: 0,
		price: 20,
	},
];
