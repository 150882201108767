import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { environment } from '../../../environments/environment';
import { Password, UtilService } from 'src/app/services/util.service';
import { LikutService } from 'src/app/services/likut.service';
import { AdminTypes, LoginUser } from 'src/app/Interfaces/users';
import { CookieService } from 'ngx-cookie';
import { AlertService } from 'src/app/services/alert.service';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { UserService } from 'src/app/services/user.service';
import { customerInformationMockTest, customerInformationMockIdan88, userResponseMock } from './login-mock';
import { StorageService } from 'src/app/services/storage.service';
import { B2bService } from 'src/app/services/b2b.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		private _util: UtilService,
		private _likut: LikutService,
		private _cookies: CookieService,
		private _alert: AlertService,
		private _user: UserService,
		private _storage: StorageService,
		private _b2b: B2bService,
	) {}

	public form = this._fb.nonNullable.group({
		AgentId: ['', Validators.required],
		Password: ['', Validators.required],
		CustomerNumber: ['', Validators.required],
	});

	public showLoginForm = true;
	public showHelpForm = false;
	public showSuccessBlock = false;
	public showCallBlock = false;

	public AgentIdNotFilled = false;
	public PasswordNotFilled = false;
	public CompanyNotFilled = false;
	public WrongPassOrName = false;
	public AlreadyLogged = false;

	public online;

	public Loading = false;
	public ServerProblem = false;

	public Privacy = false;

	isLocalhost;

	cookieState: Record<string, string>;
	get skipPasswordCheckAndLoginCheck() {
		return this.cookieState['skipPassword'];
	}

	logo = MOBILITY_LOGO;

	ngOnInit(): void {
		this.online = window.navigator.onLine;

		this.isLocalhost = !!location?.href?.includes('localhost');

		window.scrollTo(0, 0);

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			this.router.navigate(['home'], { replaceUrl: true });
		}

		this.refreshCookieState();

		console.log(this.cookieState);
	}

	public KeyUpAgentId() {
		this.AgentIdNotFilled = false;
		this.WrongPassOrName = false;
		this.AlreadyLogged = false;
		this.ServerProblem = false;

		document.getElementById('agentid').style.border = null;
	}

	public KeyUpPassword() {
		this.PasswordNotFilled = false;
		this.WrongPassOrName = false;
		this.AlreadyLogged = false;
		this.ServerProblem = false;

		document.getElementById('password').style.border = null;
	}

	public KeyUpCustomerNumber() {
		this.CompanyNotFilled = false;

		document.getElementById('customernumber').style.border = null;
	}

	loginLocalhost(e: MatButtonToggleChange) {
		if (e.value === 'user') {
			this.form.setValue({
				AgentId: this.form.controls['AgentId'].value || '10',
				Password: '0',
				CustomerNumber: '.30000',
			});
		}

		if (e.value === 'admin') {
			this.form.setValue({
				AgentId: '17',
				Password: '0',
				CustomerNumber: '.30000',
			});
		}

		if (e.value === 'mekabel') {
			this.form.setValue({
				AgentId: '500',
				Password: '0',
				CustomerNumber: '.30000',
			});
		}

		this.Login();
	}

	async Login(bool = false) {
		this.AlreadyLogged = false;

		if (this.isLocalhost && bool) {
			this.form.setValue({
				AgentId: this.form.controls['AgentId'].value || '10',
				Password: '0',
				CustomerNumber: '.30000',
			});
		}

		const [customerNumberInput, agentIdInput, passwordInput]: (string | null)[] = ['CustomerNumber', 'AgentId', 'Password'].map(key => this.form.controls[key].value);

		if (customerNumberInput === '') {
			this.CompanyNotFilled = true;
			document.getElementById('customernumber').style.border = ' 1px solid #f65944';
			return;
		}

		const input = document.getElementById('customernumber');

		if (input instanceof HTMLInputElement && input.value === 'skipPassword') {
			input.value = '';
			this.setPasswordSkipCookies();
			this.refreshCookieState();
			this._alert.customAlert(`ביטול סיסמה הופעל בהצלחה!`);
			input.focus();

			this.Loading = false;

			return;
		}

		if (agentIdInput === '' && passwordInput !== '') {
			this.AgentIdNotFilled = true;
			document.getElementById('agentid').style.border = ' 1px solid #f65944';
		}

		if (passwordInput === '' && agentIdInput !== '') {
			this.PasswordNotFilled = true;
			document.getElementById('password').style.border = ' 1px solid #f65944';
		}

		if (passwordInput === '' && agentIdInput === '') {
			this.PasswordNotFilled = true;
			this.AgentIdNotFilled = true;
			document.getElementById('agentid').style.border = ' 1px solid #f65944';
			document.getElementById('password').style.border = ' 1px solid #f65944';
		}

		if (!passwordInput && !agentIdInput) return;

		this.Loading = true;

		if (!this.online) {
			alert('אינך מחובר לאינטרנט, אנא בדוק את הגדרות מכשירך');
			this.Loading = false;
			return;
		}

		if (agentIdInput === 'test' && passwordInput === 'test' && customerNumberInput === 'test') {
			this.handleLoginTestUser('test');
			return;
		}
		if (agentIdInput === '' && passwordInput === 'test' && customerNumberInput === 'test') {
			this.handleLoginTestUser('test');
			return;
		}

		this._ss.GetCustInfo(customerNumberInput.trim()).subscribe(
			res => {
				let overrideRes;

				this._ss.CustomerInformation = overrideRes || res;
				this._ss.CustomerInformation = this._ss.CustomerInformation.recordset;

				if (this._ss.CustomerInformation.length === 0 || this._ss.CustomerInformation === null) {
					alert('מספר חברה לא תקין');
					this.Loading = false;
					return;
				}

				this._ss.CustomerInformation = this._ss.CustomerInformation[0];

				this._ss.loginService(agentIdInput.trim(), passwordInput.trim()).subscribe(
					async res => {
						if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset.length) {
							this.WrongPassOrName = true;
							this.Loading = false;
							return;
						}

						const verifiedUser = res.recordset[0];

						if (verifiedUser.Password !== passwordInput) {
							this.WrongPassOrName = true;
							this.Loading = false;
							return;
						}

						this._ss.UserNameConnected = verifiedUser.UserName;
						this._ss.AgentIdConnected = verifiedUser.AgentID;

						this._likut.Admin = verifiedUser.Admin as AdminTypes;

						this._likut.handleLikutInit();
						this._b2b.refreshAdmin(verifiedUser.Admin);

						if (
							verifiedUser.Logged === '1' &&
							!this._b2b.shouldStopLoginIfAlreadyLoggedIn$.value &&
							!this.isLocalhost &&
							!this.skipPasswordCheckAndLoginCheck &&
							(this._ss.CustomerInformation.CustExtra4 === '1' ||
								(this._ss.CustomerInformation.CustExtra4 === '2' && !this._b2b.isAdminUser$.value && !this._b2b.isB2BAgent$.value) ||
								this._likut.isLikutAppActive$.value)
						) {
							this.AlreadyLogged = true;
							this.Loading = false;
							return;
						}

						if (
							this._ss.CustomerInformation.NeedsPasswordFromMobility === '1' &&
							verifiedUser.Admin &&
							!this._b2b.isB2BAgent$.value &&
							!this._b2b.shouldStopLoginIfAlreadyLoggedIn$.value &&
							!this.skipPasswordCheckAndLoginCheck &&
							!this.isLocalhost
						) {
							const pass = prompt('יש צורך בסיסמה נוספת. אנא צור קשר עם חברת מוביליטי 050-8992950');
							const getPass = await this._util.getPasswordPIN({ AgentID: agentIdInput, CompanyNumber: customerNumberInput, company: 1, action: Password.generateLoginPassword, length: 6 });

							if (getPass !== pass) {
								this.Loading = false;

								alert('סיסמה שגויה');
								return;
							}
						}

						const hasWhatsappNumber = this._ss.CustomerInformation?.WhatsappB2BL && this._ss.CustomerInformation.WhatsappB2BL !== '0' && verifiedUser.Admin.includes('b2bL');

						this._ss.hasWhatsappIcon$.next(hasWhatsappNumber);
						if (hasWhatsappNumber) {
							this._ss.whatsappNumber$.next(this._ss.CustomerInformation.WhatsappB2BL);
						}

						this._storage.storeUserParameters({ verifiedUser, webclientInfo: this._ss.CustomerInformation });

						if (verifiedUser.Email) {
							this._user.updateEmail(verifiedUser.Email);
						}

						this._ss.UpdateUserLoggedConnect({ AgentID: this._ss.AgentIdConnected }).subscribe(res => {
							this.router.navigate(['home']);
						});
					},
					err => {
						this.ServerProblem = true;
						this.Loading = false;
					},
				);
			},
			err => {
				alert('תקלה בשרת');
				this.ServerProblem = true;
				this.Loading = false;
			},
		);
	}

	public HelpFormOpen(): void {
		this.showHelpForm = true;
		this.showLoginForm = false;
		this.showSuccessBlock = false;
		this.showCallBlock = false;
	}

	public HelpFormClose(): void {
		this.showHelpForm = false;
		this.showLoginForm = true;
		this.showSuccessBlock = false;
		this.showCallBlock = false;
	}

	public showsuccess() {
		this.showSuccessBlock = true;
	}

	handleLoginTestUser(mode: 'test' | 'idan88') {
		this._ss.CustomerInformation = customerInformationMockTest as any;

		const verifiedUser = userResponseMock as LoginUser;

		this._user.updateUserParamsAndInfo({ verifiedUser });

		this._storage.storeUserParameters({ verifiedUser, webclientInfo: this._ss.CustomerInformation });

		this._user.updateTestUserState(true);

		this.router.navigate(['home']);
	}

	loginSuccessful() {}

	showHideForm(): void {
		this.showHelpForm = !this.showHelpForm;
	}

	blockType = {
		LoginForm: 'login-form',
		HelpForm: 'help-form',
		SuccessBlock: 'success-block',
		CallBlock: 'call-block',
	};

	showHideBlock(value: string): void {
		this.showLoginForm = false;
		this.showHelpForm = false;
		this.showSuccessBlock = false;
		this.showCallBlock = false;

		switch (value) {
			case this.blockType.LoginForm:
				this.showLoginForm = true;
				break;

			case this.blockType.HelpForm:
				this.showHelpForm = true;
				break;

			case this.blockType.SuccessBlock:
				this.showSuccessBlock = true;
				break;

			case this.blockType.CallBlock:
				this.showCallBlock = true;
				break;
		}
	}

	public MoveToPrivacy() {
		this.router.navigate(['privacy']);
	}

	private setPasswordSkipCookies() {
		this._cookies.put('skipPassword', 'true', { domain: 'mobilityltd.net' });
		this.logCookies();
	}

	private refreshCookieState() {
		this.cookieState = this._cookies.getAll();
	}

	private logCookies() {
		console.log(this.getCookie());
	}

	private getCookie(key?: string) {
		if (!key) return this._cookies.getAll();
		return this._cookies.get(key);
	}
}
