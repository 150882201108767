import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { Location } from '@angular/common';
import { BehaviorSubject, reduce, share, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-dohotmotzarimreverse',
	templateUrl: './dohotmotzarimreverse.component.html',
	styleUrls: ['./dohotmotzarimreverse.component.scss'],
})
export class DohotmotzarimreverseComponent implements OnInit, AfterViewChecked, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, private location: Location) {}

	public form = this._fb.group({
		ClientSearch: ['', Validators.required],
		ProductSearch: ['', Validators.required],
	});

	public ProductsNumOfTimes;

	public AllProductsSearch;

	public AllProducts;

	public AllClientsSearch;

	public AllClients;

	public First = true;

	public Second = false;

	scrollY = 0;

	windowWidth = window.innerWidth;

	hasTDashKabalaQueries = false;

	history = history;

	destroy$ = new Subject<void>();

	AllClients$ = new BehaviorSubject<any[]>([]);
	AllProducts$ = new BehaviorSubject<any[]>([]);

	allClientsTotal$ = new BehaviorSubject<number>(0);
	allClientsAverage$ = new BehaviorSubject<number>(0);

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	ngOnInit(): void {
		window.scrollTo(0, 0);

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		}

		this._ss.getFirstPageOfDohotMotzarimReverse().subscribe({
			next: (res: any) => {
				if (res.recordset) {
					this.AllProducts = res.recordset;
					this.AllProducts$.next(this.AllProducts);
					this.hasTDashKabalaQueries = true;
					return;
				}

				this.oldMethodWithoutTDashDocSub();
			},
			error: () => {
				this.oldMethodWithoutTDashDocSub();
			},
		});
	}

	ngAfterViewChecked() {
		if (!this.Second && this.scrollY) scrollTo({ top: this.scrollY, behavior: 'auto' });
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	oldMethodWithoutTDashDocSub() {
		this.AllProductsSearch = decompress(localStorage.getItem('AllProducts'));
		this.AllProductsSearch = JSON.parse(this.AllProductsSearch);

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			this.AllProductsSearch[x].NumOfTimes = 0;
			this.AllProductsSearch[x].DocPrice = 0;
			this.AllProductsSearch[x].DocDiscount = 0;
			this.AllProductsSearch[x].docquantity = 0;
			this.AllProductsSearch[x].present = { icon: true, showContent: false };
		}

		this.AllProducts = this.AllProductsSearch;

		this.AllProducts$.next(this.AllProducts);

		this.AllClientsSearch = decompress(localStorage.getItem('AllClients'));
		this.AllClientsSearch = JSON.parse(this.AllClientsSearch);

		if (!this.AllClientsSearch.length) {
			this._ss.GetAllClients().subscribe({
				next: (res: any) => {
					if (res && res !== 'RequestError' && res !== 'ConnectionError') {
						this.AllClientsSearch = res.recordset;

						localStorage.setItem('AllClients', compress(JSON.stringify(this.AllClientsSearch)));
					} else {
						alert('לא התקבלו לקוחות חדשים, ישנה תקלה בשרת');
					}
				},
				error: err => {
					alert('לא התקבלו לקוחות חדשים, ישנה תקלה בשרת');
				},
			});
		}
	}

	public MoveToDohotMotzarim() {
		if (this.Second === true) {
			this.Second = false;
			this.First = true;
		} else {
			this.history.back();
		}
	}

	public ChoosenProduct = '';
	loading = false;
	hasUpdatedChart = false;

	public GetProductNums(id, Product_Name) {
		// this.location.go('dohotmotzarimreverse')
		this.loading = true;
		this.scrollY = window.scrollY;
		// window.scrollTo(0, 0);
		this.ChoosenProduct = Product_Name;
		this.AllClients = [];
		this.Second = true;
		this.First = false;

		// console.log(this.scrollY);
		this._ss.getCustItemSales(id).subscribe({
			next: res => {
				console.log({ res });

				if (typeof res !== 'string') {
					this.hasUpdatedChart = true;
					this.AllClients = res.recordset;
					this.AllClients$.next(this.AllClients);

					this.loading = false;

					return;
				}

				this.getFromProductNumOldMethod(id);
			},
			error: err => {
				console.log(err);
				this.getFromProductNumOldMethod(id);
			},
		});
	}

	getFromProductNumOldMethod(id) {
		this.hasUpdatedChart = false;
		this._ss.GetProductNumPerClients(id).subscribe({
			next: res => {
				console.log(res);

				this.AllClients = res;
				this.AllClients = this.AllClients.recordset;

				for (let x = 0; x < this.AllClients.length; x++) {
					for (let y = 0; y < this.AllClientsSearch.length; y++) {
						if (this.AllClients[x].ClientId === this.AllClientsSearch[y].ClientId) {
							this.AllClients[x].Client_Name = this.AllClientsSearch[y].Client_Name;
						}
					}
				}
				this.AllClients$.next(this.AllClients);

				this.loading = false;
			},
			error: err => {
				console.log(err);
				this.loading = false;
				alert('לא ניתן להביא נתונים');
			},
		});
	}

	public ChoosenClient = '';

	public GetClientsPricess(ClientId, company, Client_Name) {
		window.scrollTo(0, 0);
		this.ChoosenClient = Client_Name;
		this.Second = true;
		this.First = false;

		this._ss.GetAllPrices({ client: ClientId, company }).subscribe(res => {
			this.ProductsNumOfTimes = res;
			this.ProductsNumOfTimes = this.ProductsNumOfTimes.recordset;

			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				for (let y = 0; y < this.ProductsNumOfTimes.length; y++) {
					if (this.AllProductsSearch[x].ProductId === this.ProductsNumOfTimes[y].ProductId) {
						this.AllProductsSearch[x] = this.ProductsNumOfTimes[y];
					}
				}
			}

			this.AllProducts = this.AllProductsSearch;

			this.AllProducts$.next(this.AllProducts);
		});
	}

	public NumOfUp = true;
	public SortNumOfTimes() {
		// Better to do comparison one time instead of every time inside sort
		if (this.NumOfUp) {
			this.AllClients.sort(function (a, b) {
				return b.NumOfTimes - a.NumOfTimes;
			});
		} else {
			this.AllClients.sort(function (a, b) {
				return a.NumOfTimes - b.NumOfTimes;
			});
		}

		this.NumOfUp = !this.NumOfUp;
	}

	sortArr = [true, false, false, false];

	sortString(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };
		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'Client_Name':
				i = 0;
				break;
			case 'ClientId':
				i = 1;
				break;
			case 'ProductId':
				i = 2;
				break;
			case 'Product_Name':
				i = 3;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[i] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	sortArrNumber = [false, false];

	sortNumber(valueToSortBy: string, arrayToSort: string) {
		let bool;
		let i;

		switch (valueToSortBy) {
			case 'sumtotalrows':
				i = 0;
				break;
			case 'sumqty':
				i = 1;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArrNumber[i];
		this.sortArrNumber = this.sortArrNumber.map(_ => false);

		this.sortArrNumber[i] = bool;

		if (!bool) {
			this[arrayToSort].sort((a, b) => +String(b[valueToSortBy]).replace(/-/g, '') - +String(a[valueToSortBy]).replace(/-/g, ''));
		} else {
			this[arrayToSort].sort((a, b) => +String(a[valueToSortBy]).replace(/-/g, '') - +String(b[valueToSortBy]).replace(/-/g, ''));
		}

		this.sortArrNumber[i] = !bool;
	}

	trackByFnClient(ac) {
		return ac.ClientId;
	}

	trackByFnProduct(ap) {
		return ap.ProductId;
	}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;
	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}

	public ClearClientSearch() {
		window.scrollTo(0, 0);

		this.AllClients$.next(this.AllClients);

		this.form.reset();
	}

	public SearchClient() {
		window.scrollTo(0, 0);

		const allClients = [];
		this.AllClients$.next(allClients);

		const formVal: string | null = (this.form.controls['ClientSearch'].value || '').toLowerCase();

		if (!formVal) {
			this.AllClients$.next(this.AllClients);
			return;
		}

		const len = formVal.length;

		if (formVal.length <= 2) {
			for (let x = 0; x < this.AllClients.length; x++) {
				const { Client_Name, ClientId } = this.AllClients[x];

				if ((Client_Name || '').toLowerCase().slice(0, len) === formVal || (ClientId || '').toLowerCase().slice(0, len) === formVal) {
					allClients.push(this.AllClients[x]);
				}
			}

			return;
		}

		for (let x = 0; x < this.AllClients.length; x++) {
			const { Client_Name, ClientId } = this.AllClients[x];

			for (let z = 0; z < 50; z++) {
				if ((Client_Name || '').toLowerCase().slice(z, formVal.length + z) === formVal || (ClientId || '').toLowerCase().slice(z, formVal.length + z) === formVal) {
					allClients.push(this.AllClients[x]);
					break;
				}
			}
		}
	}

	public ClearProductsSearch() {
		window.scrollTo(0, 0);

		this.AllProducts = this.AllProductsSearch;

		this.form.reset();
	}

	public SearchProducts() {
		const formVal = (this.form.controls['ProductSearch'].value || '').toLowerCase();
		const len = formVal.length;

		window.scrollTo(0, 0);

		this.AllProducts = [];

		if (!formVal) {
			this.AllProducts = this.AllProductsSearch;
			return;
		}

		if (len <= 2) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				const { Product_Name, ProductId, ItemExtra1 } = this.AllProductsSearch[x];

				if (
					(Product_Name || '').toLowerCase().slice(0, len) === formVal ||
					(ProductId || '').toLowerCase().slice(0, len) === formVal ||
					(ItemExtra1 || '').toLowerCase().slice(0, len) === formVal
				) {
					this.AllProducts.push(this.AllProductsSearch[x]);
				}
			}
			return;
		}

		if (len > 2) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				const { Product_Name, ProductId, ItemExtra1, ItemBarcode } = this.AllProductsSearch[x];

				for (let z = 0; z < 50; z++) {
					if (
						(Product_Name || '').toLowerCase().slice(z, len + z) === formVal ||
						(ProductId || '').toLowerCase().slice(z, len + z) === formVal ||
						(ItemBarcode || '').toLowerCase().slice(z, len + z) === formVal ||
						(ItemExtra1 || '').toLowerCase().slice(z, len + z) === formVal
					) {
						this.AllProducts.push(this.AllProductsSearch[x]);
						break;
					}
				}
			}
		}
	}

	refreshSubjects(value: 'AllClients' | 'AllProducts') {}
}
