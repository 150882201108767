import { Injectable, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { CompaniesResponse } from '../Interfaces/companies';
import { ByClient, ByDate, ByDateDisplay } from '../Interfaces/reports';
import { StoreService } from './store.service';

export type DebtMode = 'by-create' | 'by-pay';

export type RefreshSubjectArguements = 'allDebtsSum' | 'allDebtsByPay' | 'allDebtsByCreate' | 'allClientsByCreate' | 'allClientsByPay';

@Injectable({
	providedIn: 'root',
})
export class DebtByDateService {
	constructor(private _router: Router, private _ss: StoreService) {}
	allDebtsByPay: ByDate[];
	allDebtsByCreate: ByDate[];
	allDebtsByDate$ = new BehaviorSubject<ByDateDisplay[]>([]);
	allClientsByDebt$ = new BehaviorSubject<ByClient[]>([]);

	debtDateControl = new FormControl<DebtMode>('by-pay', { nonNullable: true });
	companyControl = new FormControl<number>(1, { nonNullable: true });

	companies: CompaniesResponse[] = [];

	hasMoreThanOneCompany = false;


	allDebtsSum$ = new BehaviorSubject(0);

	resetDebts$: Subscription;

	selectedDate: { mm: number | null; yy: number | null } = {
		mm: null,
		yy: null,
	};

	refreshSubjectsIndexFirstPage: Record<string, RefreshSubjectArguements> = {
		'by-create': 'allDebtsByCreate',
		'by-pay': 'allDebtsByPay',
	};
	refreshSubjectsIndexSecondPage = {
		'by-create': 'allClientsByCreate',
		'by-pay': 'allClientsByPay',
	};

	listenToNavigationAndResetWhenLeaving() {
		if (this.resetDebts$) this.resetDebts$.unsubscribe();

		this.resetDebts$ = this._router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((event: NavigationStart) => {
			const shouldClearIfPathIsnt = ['/client-card', '/dohot-lekohot-hovot-by-date', '/client-hovot', '/client-hovot-each'].includes(event.url) === false;
			if (shouldClearIfPathIsnt) {
				this.clearAll();

				if (this.resetDebts$) {
					this.resetDebts$.unsubscribe();
				}
			}
		});
	}

	clearAll() {
		this.allDebtsByCreate = [];
		this.allDebtsByPay = [];
		this.allDebtsByDate$.next([]);
		this.allClientsByDebt$.next([]);
		this.allDebtsSum$.next(0);

		this._ss.OneClientInfo = null;
	}
}
