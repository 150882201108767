<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohot()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ריכוז חובות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חיפוש" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="report-table__row report-table__row_between" [ngClass]="{ admin: isAdmin }">
			<div class="fixed-header grid" [ngClass]="{ admin: isAdmin }" *ngIf="isAdmin; else nonAdminBlock">
				<!-- <div class="report-table__button" (click)="showFilterPopup = true">סינון</div> -->
				<div class="box form">
					<form [formGroup]="form">
						<ng-multiselect-dropdown
							id="dropdown"
							dir="rtl"
							[placeholder]="'סנן סוכנים'"
							[settings]="dropdownSettings"
							[data]="dropdownList"
							formControlName="agents"
							(onSelect)="handleAdvancedSearch('single')"
							(onDeSelect)="handleAdvancedSearch('single')"
							(onSelectAll)="handleAdvancedSearch('all')"
							(onDeSelectAll)="handleAdvancedSearch('clear')"
						>
						</ng-multiselect-dropdown>
					</form>
				</div>
				<div class="box number">
					<div class="report-table-popup__label-title">סה"כ</div>
					<strong>{{ this.allClientsSum$ | async | number : '1.0-2' : 'en-US' }}</strong>
				</div>
				<div class="box number">
					<div class="report-table-popup__label-title">סה"כ סומנו</div>
					<strong style="direction: ltr">{{ this.checkedArray$ | async | number : '1.0-2' : 'en-US' }}</strong>
				</div>
				<div class="box button">
					<button mat-raised-button color="primary" (click)="newStyle = !newStyle">החלף עיצוב</button>
				</div>
				<div class="box button" *ngIf="!newStyle">
					<app-excel-button></app-excel-button>
				</div>
			</div>
			<ng-template #nonAdminBlock>
				<div class="fixed-header">
					<div class="box" style="border: none">
						<div class="report-table-popup__label-title">סה"כ חובות</div>
						<strong>{{ this.allClientsSum$ | async | number : '1.0-2' : 'en-US' }}</strong>
					</div>
					<mat-divider vertical="true"></mat-divider>
					<div class="box" style="border: none">
						<button mat-raised-button color="primary" (click)="newStyle = !newStyle">החלף עיצוב</button>
					</div>
					<div class="box button" *ngIf="!newStyle">
						<app-excel-button></app-excel-button>
					</div>
				</div>
			</ng-template>
		</div>

		<div *ngIf="HasMultipleCompanys === '2'">
			<form [formGroup]="form" (change)="ChangeCompany()">
				<select formControlName="Company" style="width: 150px; height: 35px">
					<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
				</select>
			</form>
		</div>

		<h2 *ngIf="loading">טוען...</h2>

		<div class="report-table__row report-table__row_full-width" [ngStyle]="{ justifyContent: newStyle ? 'center' : null }" *ngIf="!loading">
			<table class="report-table__table" *ngIf="newStyle" [style.maxWidth.px]="longestName > 24 ? (windowWidth > 768 ? longestName * 15 : longestName * 8) : null" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" [style.maxWidth.px]="longestName > 24 ? (windowWidth > 768 ? longestName * 8 : longestName * 8) : null"></th>
						<th class="report-table__table-head-quantity" *ngIf="HasMultipleCompanys > 1">חברה</th>
						<th class="report-table__table-head-quantity" (click)="sortByChecked()" *ngIf="isAdmin"></th>
						<!-- <th class="report-table__table-head-quantity sortable-clickable" (click)="sortByChecked()" *ngIf="isAdmin">סמן</th> -->
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ac of AllClients; trackBy: trackBy" style="cursor: pointer" id="Client{{ ac.ClientId }}">
						<td class="report-table__table-body-name" style="text-align: center; vertical-align: baseline; padding: 1em" (click)="handleOpenPopup($event, ac)">
							{{ ac.Client_Name + ' - ' + ac.ClientId }}<span style="position: absolute; bottom: 0.5rem; right: 1rem">{{ ac | debtParameter }}</span>
							<div class="left" style="position: absolute; bottom: 0.5rem; left: 1rem" (click)="navigateToHovot($event, ac.ClientId, ac.company)">
								<span> חוב: </span>
								<span [style.fontWeight]="ac.COD < 0 ? '700' : null" [style.color]="ac.COD < 0 ? 'red' : null" dir="ltr">{{ ac.COD | number : '1.0-2' }}</span>
							</div>
						</td>
						<!-- <td class="report-table__table-body-code">{{ ac.ClientId }}</td>
						<td class="report-table__table-body-quantity" [style.fontWeight]="ac.COD < 0 ? '700' : null"
							[style.color]="ac.COD < 0 ? 'red' : null" dir="ltr">
							{{ ac.COD | number : '1.0-2' }}
						</td> -->
						<td class="report-table__table-body-code" *ngIf="HasMultipleCompanys > 1">{{ showCompaniesObject[ac.company].CompanyName }}</td>
						<td class="report-table__table-body-code" *ngIf="isAdmin">
							<mat-checkbox (change)="handleCheck(ac)" [checked]="ac.ClientId | shouldCheckboxBeMarked : checkedArray" color="primary"></mat-checkbox>
						</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="!newStyle">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-code sortable-clickable" style="width: 100px" (click)="sort('ClientId', 'AllClients')">קוד לקוח</th>
						<th
							class="report-table__table-head-name sortable-clickable"
							[style.maxWidth.px]="longestName > 24 ? (windowWidth > 768 ? longestName * 8 : longestName * 8) : null"
							(click)="sort('Client_Name', 'AllClients')"
						>
							שם לקוח
						</th>
						<th class="report-table__table-head-code sortable-clickable" *ngIf="withSingleAgentQuery" style="width: 100px" (click)="sort('CustAgentCode', 'AllClients')">סוכן</th>
						<th class="report-table__table-head-quantity sortable-clickable" style="width: 100px" (click)="sort('COD', 'AllClients')">חוב</th>
						<th class="report-table__table-head-quantity sortable-clickable" style="width: 100px" (click)="sort('CMC', 'AllClients')">אשראי</th>
						<th class="report-table__table-head-quantity sortable-clickable" style="width: 100px" (click)="sort('CMC-COC+COD', 'AllClients')">יתרה לניצול</th>
						<th class="report-table__table-head-quantity sortable-clickable" style="width: 100px" (click)="sort('COC+COD', 'AllClients')">אשראי מנוצל</th>
						<th class="report-table__table-head-quantity sortable-clickable" style="width: 100px" (click)="sort('COC', 'AllClients')">צ'קים דחויים</th>
						<th class="report-table__table-head-quantity" style="width: 100px" *ngIf="HasMultipleCompanys > 1">חברה</th>
						<th class="report-table__table-head-quantity" (click)="sortByChecked()" *ngIf="isAdmin">סמן</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ac of AllClients; trackBy: trackBy" style="cursor: pointer" id="Client{{ ac.ClientId }}">
						<td class="report-table__table-body-code">{{ ac.ClientId }}</td>
						<td class="report-table__table-body-name" (click)="navigateToHovot($event, ac.ClientId, ac.company)">
							{{ ac.Client_Name }}
						</td>
						<td class="report-table__table-body-quantity" *ngIf="withSingleAgentQuery" dir="rtl">{{ ac.CustAgentCode }} - {{ ac.AgentName }}</td>
						<td class="report-table__table-body-quantity" [style.fontWeight]="ac.COD < 0 ? '700' : null" [style.color]="ac.COD < 0 ? 'red' : null" dir="ltr">
							{{ ac.COD | number : '1.0-2' }}
						</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ ac.CMC | number : '1.0-2' }}</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ ac.CMC - (ac.COC + ac.COD + (+ac.AddObligo || 0)) | number : '1.0-2' }}</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ ac.COC + ac.COD | number : '1.0-2' }}</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ ac.COC | number : '1.0-2' }}</td>
						<td class="report-table__table-body-code" *ngIf="HasMultipleCompanys > 1">{{ showCompaniesObject[ac.company].CompanyName }}</td>
						<td class="report-table__table-body-code" *ngIf="isAdmin">
							<mat-checkbox (change)="handleCheck(ac)" [checked]="ac.ClientId | shouldCheckboxBeMarked : checkedArray" color="primary"></mat-checkbox>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="popup__close" (click)="showFilterPopup = false" style="top: 2em; z-index: 1002; box-shadow: inset 0px 0px 2px, 0 0 3px 2px" [style.left]="windowWidth > 768 ? '43%' : '2em'"></div>
	<div class="report-table-popup report-table-popup_sort popup__content" style="overflow-y: scroll">
		<div class="report-table-popup__header">
			<div class="report-table-popup__header-title">סינון מתקדם</div>
		</div>
		<div class="report-table-popup__wrapper">
			<div class="report-table-popup__content">
				<!-- <div
          class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding">
        </div> -->
				<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
					<div class="report-table-popup__box">
						<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
							<span class="report-table-popup__content-title"> סוכנים</span>
						</div>
						<div style="float: right">
							<form [formGroup]="form" style="width: 150px">
								<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px">
									<mat-select formControlName="agents" multiple>
										<mat-option *ngFor="let a of agentsForAdmin" [value]="a.AgentID">{{ a.AgentID + ' - ' + a.UserName }}</mat-option>
									</mat-select>
								</mat-form-field>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="popup" *ngIf="isPopupOpen" style="z-index: 555">
	<div class="overlay" (click)="handleClosePopup()"></div>
	<div class="product-detail__title" *ngIf="selectedClient?.Client_Name ?? selectedClient?.ClientName">
		{{ selectedClient?.Client_Name ?? selectedClient?.ClientName }}
	</div>
	<div class="product-detail__title remarks">
		<mat-button-toggle-group [formControl]="debtDateControl" aria-label="תאריך">
			<mat-button-toggle [value]="0" [ngClass]="{ active: debtDateControl.value === 0 }">פרעון</mat-button-toggle>
			<mat-button-toggle [value]="1" [ngClass]="{ active: debtDateControl.value === 1 }">יצירה</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black"></div>
		<div class="product-detail__wrapper" dir="rtl">
			<ng-container *ngIf="!isLoadingPopup; else loadingBlock">
				<div class="popup-row"><span class="bold">תקופה</span> <span class="bold">סה"כ</span></div>
				<div class="popup-row" *ngFor="let debt of OneClientHovotDisplay$ | async">
					<span>{{ debt.yymm ? debt.yymm.replace('-', ' - ') : 0 }}</span>
					<span [style.fontWeight]="debt.tot < 0 ? '700' : null" [style.color]="debt.tot < 0 ? 'red' : null" dir="ltr">{{ debt.tot | number : '1.0-2' }}</span>
				</div>
				<!-- <div class="popup-row">
					<span class="bold">סיכום ביקור:</span> <span>{{ selectedClient.ResDesc }}</span>
				</div>
				<div class="popup-row" *ngIf="isAdmin">
					<span class="bold">סוכן:</span> <span>{{ selectedClient.AgentID }}</span>
				</div>
				<div class="popup-row">
					<span class="bold">קוד לקוח:</span> <span>{{ selectedClient.ClientID }}</span>
				</div> -->
				<!-- <div class="popup-row" *ngIf="!hasString">
				<span class="bold">שעה:</span> <span>{{ selectedClient.CreateDate | date : 'HH:mm' : 'GMT' }}</span>
			</div>
			<div class="popup-row" *ngIf="hasString">
				<span class="bold">כניסה:</span> <span>{{ selectedClient.string.Enter | date : 'HH:mm' : 'Asia/Jerusalem' }}</span>
			</div>
			<div class="popup-row" *ngIf="hasString">
				<span class="bold">יציאה:</span> <span>{{ selectedClient.string.Exit | date : 'HH:mm' : 'Asia/Jerusalem' }}</span>
			</div> -->
				<!-- <div class="popup-row">
					<span class="bold">פירוט:</span> <span>{{ selectedClient.Remark }}</span>
				</div> -->
				<!-- <div class="popup-row" *ngIf="hasReasonVisitText">
				<span class="bold">סיבת ביקור:</span> <span>{{ selectedClient.ResonVisitText }}</span>
			</div> -->
			</ng-container>
			<ng-template #loadingBlock>
				<div class="popup-row">
					<span class="bold">טוען...</span>
				</div>
			</ng-template>
		</div>
	</div>
</div>
