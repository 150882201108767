import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubAppModes } from '../components/likut-clients/likut-clients.component';
import {
	TruckLoadingAssignmentTable,
	TruckLoadingAssignmentUpdateDate,
	TruckLoadingAssignmentUpdateLineNum,
	TruckLoadingDeliveryLinesResponse,
	TruckLoadingDisplay,
	TruckLoadingFinishBody,
	TruckLoadingMainSQL,
	TruckLoadingSubSQL,
	TruckLoadingTaskDisplayWithAssignment,
	TruckLoadingUsers,
} from '../Interfaces/truck-loading';
import { AdminTypes } from '../Interfaces/users';
import { StoreService } from './store.service';
import { TextService } from './text.service';
import { TruckDistributionService } from './truck-distribution.service';

// TODO move all distribution logic to own service
export const TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES = ['maamis', 'nahag', 'nahagm', 'nahagmaamis', 'loadingmanager', 'podmanager'] as const;

// TODO nahagM - can't open popup
const userAdminToValue = {
	isMaamisAgent: 'maamis',
	isNahagAgent: 'nahag',
	isNahagAndCantChangeQuantities: 'nahagm',
	isBothMaamisAndNahag: 'nahagmaamis',
	isTruckLoadingManager: 'loadingmanager',
	isTruckDistributionManager: 'podmanager',
} as const;

@Injectable({
	providedIn: 'root',
})
export class TruckLoadingService {
	constructor(private _ss: StoreService, private _text: TextService, private _truckDistribution: TruckDistributionService) {
		this.handleTruckLoadingInit();
		this.Admin &&= this.Admin.toLowerCase() as AdminTypes;
	}

	truckLoadOpened: { sub: TruckLoadingSubSQL[]; task: TruckLoadingDisplay } = {
		sub: [],
		task: { clientid: '', clientName: '', status: '0', teudot: {}, totalRow: 0, totalTeudot: 0, address: '' },
	};

	Admin: AdminTypes | null = localStorage.getItem('Admin') as AdminTypes | null;

	truckLoadingAdminTypes: Record<typeof TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES[number], boolean> = TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES.reduce((p, c) => {
		p[c] = false;
		return p;
	}, {} as Record<typeof TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES[number], boolean>);

	uniqueText = {
		homeScreenAction: 'התחל העמסה',
		homeScreenAction2: 'התחל הפצה',
		mainTasks: `משימות העמסה`,
		teudaName: `תעודת העמסה`,
		teudaNameGroupB: `תעודת החזרה`,
		name: {
			'0': 'שם מעמיס',
			'1': 'שם הנהג',
			'2': 'שם מנהל',
			'3': 'לא בשימוש',
		},
		backToTasks: `חזור למשימות`,
		taskAssignmentReport: 'שיוך העמסות',
		taskUpdatedSuccess: 'משימת העמסה שויכה בהצלחה',
	};

	isTruckLoadingOrTruckDistributionAppsActive$ = new BehaviorSubject(false);

	truckDeliveryLines$ = new BehaviorSubject<TruckLoadingDeliveryLinesResponse[]>([]);

	deliveryLineChosen: TruckLoadingDeliveryLinesResponse = { Extra1: null, Extra2: null, Name: '', UserID: '' };

	worksIn: 'singles' | 'packs' | 'both' = 'singles';

	hasTruckLoading = false;
	cantCheckLoadingTask = false;
	checkAfterScanner = false;
	howManyPicturesCanAttach = 0;
	cantStartDistributionIfLoadingNotDone = false;

	parametersRecord = {
		groupOnlyByDocNum: false,
	};

	reportsRecord = {
		truckLoadingAssignment: false,
		truckDistributionAssignment: false,
		truckLoadingPassword: false,
		truckLoadingExtra4AssignmentManagement: false,
		truckDistributionMap: false,
		mobilityDashboard: false,
		truckDistributionNotSupplied: false,
	};

	users$ = new BehaviorSubject<TruckLoadingUsers[]>([]);
	usersForAssignment$ = new BehaviorSubject<TruckLoadingUsers[]>([]);

	taskAssigmentPopupDateChange = new EventEmitter<{ task: TruckLoadingTaskDisplayWithAssignment; newDate: string }>();
	taskAssigmentPopupAgentChange = new EventEmitter<{ task: TruckLoadingTaskDisplayWithAssignment; newAgent: string }>();

	private readonly pathEntry = 'mobil/truck-loading';

	get isMaamisAgent() {
		return this.truckAdminState(userAdminToValue.isMaamisAgent);
	}
	get isNahagAgent() {
		return this.truckAdminState(userAdminToValue.isNahagAgent);
	}
	get isNahagAndCantChangeQuantities() {
		return this.truckAdminState(userAdminToValue.isNahagAndCantChangeQuantities);
	}
	get isBothMaamisAndNahag() {
		return this.truckAdminState(userAdminToValue.isBothMaamisAndNahag);
	}

	get isTruckLoadingManager() {
		return this.truckAdminState(userAdminToValue.isTruckLoadingManager);
	}

	get isTruckDistributionManager() {
		return this.truckAdminState(userAdminToValue.isTruckDistributionManager);
	}

	get canStartLoading() {
		return this.isBothMaamisAndNahag || this.isMaamisAgent;
	}
	get canStartDistribution() {
		return this.isBothMaamisAndNahag || this.isNahagAgent;
	}

	private updateAdminType() {
		this.Admin ??= localStorage.getItem('Admin') as AdminTypes;
		this.Admin &&= this.Admin.toLowerCase() as AdminTypes;

		if (!this.Admin) {
			return;
		}

		for (const admin of TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES) {
			const isAdmin = this.isAdminLowerCase(admin);
			this.truckLoadingAdminTypes[admin] = isAdmin;
		}

		this.isTruckLoadingOrTruckDistributionAppsActive$.next(Object.values(this.truckLoadingAdminTypes).some(admin => admin));
	}

	private isAdminLowerCase(value: typeof TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES[number]) {
		// 'melaketM' includes both melaket AND melaketm
		return this.Admin.toLowerCase().includes(value);
	}

	private truckAdminState(propertyToLookup: typeof TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES[number]) {
		const val = this.truckLoadingAdminTypes[propertyToLookup];
		if (!this.isTruckLoadingOrTruckDistributionAppsActive$.value) {
			this.isTruckLoadingOrTruckDistributionAppsActive$.next(val);
		}
		return val;
	}

	refreshAllAdminTypes() {
		this.updateAdminType();
	}

	private handleInitializeCustomerInformationParameters() {
		if (!this._ss.CustomerInformation) return;
		this.handleSetupQuantityTypes();

		if (!this._ss.CustomerInformation.LoadingAndDistribution) return;
		const splitParameters: string[] = this._ss.CustomerInformation.LoadingAndDistribution.split('_');
		this.cantCheckLoadingTask = splitParameters.includes('1');
		this.checkAfterScanner = splitParameters.includes('2');
		this.cantStartDistributionIfLoadingNotDone = splitParameters.includes('3');

		if (this._ss.CustomerInformation.LoadingAndDistribution_GroupByDocNum) {
			const split = this._ss.CustomerInformation.LoadingAndDistribution_GroupByDocNum.split('_');
			this.parametersRecord.groupOnlyByDocNum = split.includes('2');
		}

		if (!this._ss.CustomerInformation.LoadingAndDistribution_Manager_Loading) return;
		const splitReportsParameters: string[] = this._ss.CustomerInformation.LoadingAndDistribution_Manager_Loading.split('_');
		this.reportsRecord.truckLoadingPassword = splitReportsParameters.includes('1');
		this.reportsRecord.truckLoadingAssignment = splitReportsParameters.includes('2');
		this.reportsRecord.truckDistributionAssignment = splitReportsParameters.includes('3');
		this.reportsRecord.truckLoadingExtra4AssignmentManagement = splitReportsParameters.includes('4');
		this.reportsRecord.truckDistributionMap = splitReportsParameters.includes('5');
		this.reportsRecord.mobilityDashboard = splitReportsParameters.includes('6');
		this.reportsRecord.truckDistributionNotSupplied = splitReportsParameters.includes('7');

		if (!this._ss.CustomerInformation.LoadingAndDistribution_CanAttachPictures) return;
		this.howManyPicturesCanAttach = +this._ss.CustomerInformation?.LoadingAndDistribution_CanAttachPictures || 0;
	}

	handleTruckLoadingInit() {
		this.refreshAllAdminTypes();
		this.handleInitializeCustomerInformationParameters();
		if (this.isTruckLoadingOrTruckDistributionAppsActive$.value) {
			this.replaceTextForTruckLoading();
		}
	}

	private handleSetupQuantityTypes() {
		if (this._ss.CustomerInformation?.LoadingAndDistribution_Quantities) {
			const { LoadingAndDistribution_Quantities } = this._ss.CustomerInformation;
			let value: typeof this.worksIn;

			switch (LoadingAndDistribution_Quantities) {
				case '0':
					value = 'both';
					break;
				case '1':
					value = 'singles';
					break;
				case '2':
					value = 'packs';
					break;

				default:
					value = 'both';
					break;
			}

			this.worksIn = value;
		}
	}

	private replaceTextForTruckLoading() {
		this._text.updateTextStore(this.uniqueText);
	}

	switchTaskAndTeudaNames(mode: SubAppModes) {
		if (mode === 'truck-distribution') {
			this._truckDistribution.overrideText();
			return;
		}

		if (mode === 'truck-loading') {
			this.replaceTextForTruckLoading();
		}
	}

	resetTruckLoadingState() {
		this.truckLoadOpened = { sub: [], task: { clientid: '', clientName: '', status: '0', teudot: {}, totalRow: 0, totalTeudot: 0, address: '' } };
		this.deliveryLineChosen = { Extra1: null, Extra2: null, Name: '', UserID: '' };
	}

	getAllTruckLoadingUsers() {
		return this._ss.get<TruckLoadingUsers>(`${this.pathEntry}/get-truck-loading-users`);
	}

	updateLoadingUsers() {
		this.getAllTruckUsers('all').subscribe({
			next: res => {
				if (typeof res === 'string') {
					return;
				}

				this.users$.next(res.recordset);
			},
		});
	}

	updateLoadingUsersForAssignment() {
		this.getAllTruckUsers('assignment').subscribe({
			next: res => {
				if (typeof res === 'string') {
					return;
				}

				this.usersForAssignment$.next(res.recordset);
			},
		});
	}

	private getAllTruckUsers(mode: 'all' | 'assignment') {
		let path = '';
		if (mode === 'all') {
			return this.getAllTruckLoadingUsers();
		}

		if (mode === 'assignment') {
			path = 'get-all-users-for-assignment-kanban';
		}
		return this._ss.get<TruckLoadingUsers>(`${this.pathEntry}/${path}`);
	}

	getTruckAssignment(mode: 'main' = 'main') {
		let endpoint = '';

		if (mode === 'main') {
			endpoint = 'get-not-assigned';
		}
		return this._ss.get<TruckLoadingAssignmentTable>(`${this.pathEntry}/${endpoint}`);
	}

	finishTruckLoadingTask(body: TruckLoadingFinishBody[]) {
		return this._ss.patch(`${this.pathEntry}/finish-loading-task-as-driver-or-loader`, { lines: body });
	}

	getOneMainTruckLoadingTask(options: { docnums: string[] }) {
		let params = new HttpParams();

		params = params.appendAll({ DocNum: options.docnums });

		return this._ss.get<TruckLoadingMainSQL>(`${this.pathEntry}/get-one-main-as-driver-or-loader`, params);
	}

	getOneSubTruckLoadingTask(options: { docnums: string[] }) {
		let params = new HttpParams();

		params = params.appendAll({ DocNum: options.docnums });

		return this._ss.get<TruckLoadingSubSQL>(`${this.pathEntry}/get-sub-task-as-driver-or-loader`, params);
	}

	markTaskAsOpenedByAgent(body: { DocNum: string[]; OpenedBy: string | null }) {
		let base = `${this.pathEntry}/mark-sub-task-as`;

		switch (body.OpenedBy) {
			case null:
			case '':
				return this._ss.patch(`${base}-free-driver-or-loader`, body);

			default:
				return this._ss.patch(`${base}-opened-by-driver-or-loader`, body);
		}
	}

	getAllTruckLoadingTasksForMain(mode: 'assignment' | 'clientsTask' | 'password', onlyAssigned?: string) {
		let endpoint = 'get-all-main-as-driver-or-loader';

		if (mode === 'assignment') {
			if (!onlyAssigned) {
				endpoint = 'get-all-for-assignment-kanban';
			} else {
				endpoint = 'get-not-assigned/' + onlyAssigned;
			}
		}

		if (mode === 'clientsTask') {
			if (onlyAssigned) {
				endpoint += '/' + onlyAssigned;
			}
		}

		if (mode === 'password') {
			endpoint = 'get-all-main-as-manager-for-password';
		}

		return this._ss.get<TruckLoadingMainSQL>(`${this.pathEntry}/${endpoint}`);
	}

	assignKanbanTaskToUser(body: { Extra4: string; AssignTo: string }) {
		return this._ss.patch(`${this.pathEntry}/set-not-assigned`, body);
	}

	assignKanbanTaskToAgent(body: { DocNum: string[]; AssignTo: string }) {
		return this._ss.patch(`${this.pathEntry}/set-assignment-agent-kanban`, body);
	}

	assignKanbanTaskLineNum(lines: TruckLoadingAssignmentUpdateLineNum[]) {
		const body = { lines };
		return this._ss.patch(`${this.pathEntry}/set-assignment-linenum-kanban`, body);
	}

	assignKanbanTaskDate(lines: TruckLoadingAssignmentUpdateDate[]) {
		const body = { lines };
		return this._ss.patch(`${this.pathEntry}/set-assignment-date-kanban`, body);
	}

	assignTaskToUser(body: { Extra4: string; AssignTo: string }) {
		return this._ss.patch(`${this.pathEntry}/set-not-assigned`, body);
	}

	getDeliveryLinesList() {
		return this._ss.get<TruckLoadingDeliveryLinesResponse>(`${this.pathEntry}/get-truck-loading-delivery-lines`);
	}

	async handleFileUpload(file: File) {
		const fd = new FormData();

		fd.append(file.name, file);

		try {
			const res = await fetch(`${this._ss.CustomerInformation.CustServer}/${this.pathEntry}/upload-camera-picture`, {
				method: 'POST',
				body: fd,
			});

			if (!res.ok) {
				const data = await res.json();
				console.log(data);
				return false;
			}

			console.log('finished fetch');
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	}
}
