<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="handleBack()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביצועים מותגים</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<!-- <div class="report-table__row report-table__row_between" style="margin-top:50px;"> -->
		<div class="flex-row" style="margin-top: 50px">
			<!-- <div *ngIf="ShowCompanys !== null && !Second">
				<form [formGroup]="form" (change)="ChangeCompany()">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div> -->
			<!-- <div class="box" *ngIf="isAdmin">
				<b>סה"כ</b>
				<span>
					{{ total$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ average$ | async | number : '1.0-2' }}
				</span>
			</div> -->
		</div>

		<!-- </div> -->

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0" *ngIf="!loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px" *ngIf="isAdmin">סוכן</th>
						<th class="report-table__table-head-name" style="width: 120px">תקופה</th>
						<th class="report-table__table-head-name" style="width: 120px">DUNLOP_GY</th>
						<th class="report-table__table-head-name" style="width: 120px">FULDA</th>
						<th class="report-table__table-head-name" style="width: 120px">YOKOHAMA</th>
						<th class="report-table__table-head-name" style="width: 120px">DUNLOP_SRI</th>
						<th class="report-table__table-head-name" style="width: 120px">ALLIANCE</th>
						<th class="report-table__table-head-name" style="width: 120px">GALAXY</th>
						<th class="report-table__table-head-name" style="width: 120px">COOPER</th>
						<th class="report-table__table-head-name" style="width: 120px">סה"כ</th>
						<th class="report-table__table-head-name" style="width: 100px" *ngIf="ShowCompanys">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Brands$ | async">
						<td class="report-table__table-body-name" *ngIf="isAdmin">{{ b.AgentID }} - {{ b.AgentName }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.mm }} / {{ b.yy }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.DUNLOP_GY | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.FULDA | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.YOKOHAMA | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.DUNLOP_SRI | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.ALLIANCE | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.GALAXY | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.COOPER | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.Total | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr" *ngIf="ShowCompanys">{{ b.company }}</td>
					</tr>
				</tbody>
			</table>
			<h1 *ngIf="loading">טוען...</h1>
		</div>
	</div>
</section>
<!-- end report table markup -->
