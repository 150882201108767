import { CalOptions, HebrewCalendar, Location } from '@hebcal/core';
import { Time } from '../services/util.service';

export type HebrewHolidays = 'Rosh Hashana';

export function normalizeNumber(input: unknown, options: { max?: number; min?: number; lower?: boolean; upper?: boolean } = {}): number {
	let morphedInput = Number(input);

	const max = options.max ?? 100;
	const min = options.min ?? 0;
	const lower = options.lower || true;
	const upper = options.upper || true;

	if (lower) {
		morphedInput = morphNumberToHardLimit(morphedInput, { mode: 'lower', max, min });
	}

	if (upper) {
		morphedInput = morphNumberToHardLimit(morphedInput, { mode: 'upper', max, min });
	}

	return isNaN(morphedInput) ? 0 : morphedInput;
}

export function normalizeNumberToFormattedString(input: number, options: { maximumFractionDigits?: number; minimumFractionDigits?: number; useGrouping?: boolean } = {}) {
	const maximumFractionDigits = options.maximumFractionDigits ?? 2;
	const minimumFractionDigits = options.minimumFractionDigits ?? 0;
	const useGrouping = options.useGrouping ?? false;
	return Intl.NumberFormat('en-IL', { maximumFractionDigits, minimumFractionDigits, useGrouping }).format(+input);
}

export function normalizeNumberToMaxTwoDecimal(input: number | string): number {
	return +normalizeNumberToFormattedString(+input, { useGrouping: false }) || 0;
}
export function normalizeNumberToMaxThreeDecimal(input: number | string): number {
	return +normalizeNumberToFormattedString(+input, { useGrouping: false, maximumFractionDigits: 3 }) || 0;
}

function morphNumberToHardLimit(input: number, options: { mode: 'upper' | 'lower'; min: number; max: number }): number {
	const { mode, min, max } = options;
	if (mode === 'upper') {
		return Math.min(input, max);
	}
	if (mode === 'lower') {
		return Math.max(input, min);
	}

	// unreachable
	return 0;
}

export function shouldDisplayDifferentLogo(holiday: HebrewHolidays) {
	const currentMonth = new Date().getMonth();

	switch (holiday) {
		case 'Rosh Hashana':
			if (currentMonth < 7 || currentMonth > 9) return false;
			break;

		default:
			break;
	}

	const lastMonth = new Date();
	lastMonth.setMonth(new Date().getMonth() - 1);
	const nextMonth = new Date();
	nextMonth.setMonth(new Date().getMonth() + 1);

	const options: CalOptions = {
		year: new Date().getFullYear(),
		location: Location.lookup('Tel Aviv'),
		il: true,
		start: lastMonth,
		end: nextMonth,
	};

	return checkIfHoliday(options, holiday);
}

function checkIfHoliday(options: CalOptions, searchFor: HebrewHolidays) {
	const events = HebrewCalendar.calendar(options);

	let ignoreValues: string[] = [];

	if (searchFor === 'Rosh Hashana') {
		ignoreValues.push('Behemot');
	}

	for (const ev of events) {
		const hd = ev.getDate();
		const holidayDate = hd.greg();
		const holiday = ev.render('en');

		if (holiday.includes(searchFor) && !ignoreValues.filter(ignore => holiday.includes(ignore)).length) {
			const now = new Date().getTime();
			const { week } = Time;
			const holidayTime = holidayDate.getTime();
			if (holidayTime >= now - week && now <= holidayTime + week) {
				return true;
			}
		}
	}

	return false;
}

export function translateDateForSQLServer(date?: Date | string) {
	return translateDate(date || new Date(), 'toSQL');
}

export function translateDateForWebISO(date?: Date | string) {
	return translateDate(date || new Date(), 'fromSQL');
}

function translateDate(date: Date | string, mode: 'fromSQL' | 'toSQL'): Date {
	if (typeof date === 'string') {
		date = new Date(date);
	}

	if (mode === 'fromSQL') {
		return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * Time.minuteInMilliseconds);
	}

	if (mode === 'toSQL') {
		return new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * Time.minuteInMilliseconds);
	}

	return new Date(date);
}

export function arithmeticWithFloatingNumber(options: { number: number; action: 'increment' | 'decrement'; precision?: number }) {
	const precision = options?.precision || 3;
	const { action, number } = options;

	if (isNaN(number) || !isFinite(number)) {
		throw new Error('Input is not a valid number.');
	}

	const amount = Math.pow(10, precision);

	if (action === 'decrement') {
		return (number * amount - amount) / amount;
	}

	return (number * amount + amount) / amount;
}

export function formatNumberToMaxPrecision(input: number, maxPrecision = 3) {
	return Intl.NumberFormat('en-IL', { maximumFractionDigits: maxPrecision, minimumFractionDigits: 0, useGrouping: false }).format(+input);
}

/**
 * @template T
 * @param {string} input - The text to search.
 * @param {T[]} arrayToSearch - The array to search in.
 * @param {(keyof T)[]} valuesToSearch - The keys of the array to search in.
 * @returns {T[]} - The filtered array.
 * @description This function is used to filter an array of objects based on the input string. It checks if the input string matches (or is a substring of) any of the values of the specified keys in the objects. The input string can be in any order and it doesn't have to match the order of the object's values. The function is case-insensitive and it can handle any type `T` that extends `Record<any, {truthy value}>`.
 * @example
 * // The function will return an array of objects where the 'name' or 'description' contains 'apple' and 'red' in any order.
 * genericSearchingFunction('red apple',
 * [
 * {name: 'Red Apple', description:'A tasty fruit'},
 * {name: 'Green Apple', description: 'A sour fruit'},
 * {name: 'Red Shiny PrApple', description: 'A shiny red fruit'},
 * {name: 'Banana', description: 'A yellow fruit'}
 * ], ['name', 'description'])
 * // => [{name: 'Red Apple', description:'A tasty fruit'}, {name:'Red Shiny PrApple', description: 'A shiny red fruit'}]
 */
export function genericSearchingFunction<T extends Record<K, string | number | Date>, K extends keyof T>(input: string, arrayToSearch: T[], valuesToSearch: K[], sliceLength = 50): T[] {
	if (!arrayToSearch.length) return arrayToSearch;
	const inputLength = input.length || 0;

	const finalArray = [];

	if (!inputLength) {
		return arrayToSearch.slice(0, sliceLength);
	}

	try {
		valuesToSearch.map(valueToSearch => arrayToSearch[0][valueToSearch].toString());
	} catch (error) {
		console.error('values must have toString method or be a string');
		return arrayToSearch.slice(0, sliceLength);
	}

	const splitValue = input.split(' ');

	for (let i = 0; i < arrayToSearch.length; i++) {
		let counter = 0;

		const mappedValues = valuesToSearch.map(valueToSearch => (arrayToSearch[i][valueToSearch] || '').toString().replace(/\s/g, '').toLowerCase());
		const allStrings = mappedValues.every(value => typeof value === 'string');

		if (!allStrings) continue;

		for (let j = 0; j < splitValue.length; j++) {
			if (mappedValues.some(value => value.indexOf(splitValue[j]) > -1)) {
				counter++;
			}
		}

		if (counter >= splitValue.length) {
			finalArray.push(arrayToSearch[i]);
		}
	}

	return finalArray.slice(0, sliceLength);
}
/**
 * @template T
 * @param {string} input - The text to search.
 * @param {T[]} arrayToSearch - The array to search in.
 * @param {(keyof T)[]} valuesToSearch - The keys of the array to search in.
 * @returns {T[]} - The filtered array.
 * @description This function is used to filter an array of objects based on the input string. It checks if the input string matches (or is a substring of) any of the values of the specified keys in the objects. The input string can be in any order and it doesn't have to match the order of the object's values. The function is case-insensitive and it can handle any type `T` that extends `Record<any, {truthy value}>`.
 * @example
 * // The function will return an array of objects where the 'name' or 'description' contains 'apple' and 'red' in any order.
 * genericSearchingFunction('red apple',
 * [
 * {name: 'Red Apple', description:'A tasty fruit'},
 * {name: 'Green Apple', description: 'A sour fruit'},
 * {name: 'Red Shiny PrApple', description: 'A shiny red fruit'},
 * {name: 'Banana', description: 'A yellow fruit'}
 * ], ['name', 'description'])
 * // => [{name: 'Red Apple', description:'A tasty fruit'}, {name:'Red Shiny PrApple', description: 'A shiny red fruit'}]
 */
export function genericSearchingFunctionWithoutNullChecks<T extends Record<K, string | number | Date>, K extends keyof T>(
	input: string,
	arrayToSearch: T[],
	valuesToSearch: K[],
	sliceLength = 50,
): T[] {
	if (!arrayToSearch.length) return arrayToSearch;
	const inputLength = input.length || 0;

	const finalArray = [];

	if (!inputLength) {
		return arrayToSearch.slice(0, sliceLength);
	}

	try {
		valuesToSearch.map(valueToSearch => arrayToSearch[0][valueToSearch]?.toString() || '');
	} catch (error) {
		console.error('values must have toString method or be a string');
		return arrayToSearch.slice(0, sliceLength);
	}

	const splitValue = input.split(' ');

	for (let i = 0; i < arrayToSearch.length; i++) {
		let counter = 0;

		const mappedValues = valuesToSearch.map(valueToSearch => (arrayToSearch[i][valueToSearch] || '').toString().replace(/\s/g, '').toLowerCase());
		const allStrings = mappedValues.every(value => typeof value === 'string');

		if (!allStrings) continue;

		for (let j = 0; j < splitValue.length; j++) {
			if (mappedValues.some(value => value.indexOf(splitValue[j]) > -1)) {
				counter++;
			}
		}

		if (counter >= splitValue.length) {
			finalArray.push(arrayToSearch[i]);
		}
	}

	return finalArray.slice(0, sliceLength);
}
