import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { decompress } from 'lz-string';
import { StoreService } from 'src/app/services/store.service';
import { RallyanceReclamationNewReclamationFormValues, RallyanceReclamationReasons, ReclamationReasonsResponse } from 'src/app/Interfaces/reclamations';
import { ReclamationService } from 'src/app/services/reclamation.service';
import { map, take } from 'rxjs';

@Component({
	selector: 'app-reclamation-popup-content',
	templateUrl: './reclamation-popup-content.component.html',
	styleUrls: ['./reclamation-popup-content.component.scss'],
})
export class ReclamationPopupContentComponent implements OnInit {
	@Input() product: any;
	@Input() CustImageDomain: string;
	@Input() DoctypeName: string;
	@Output() submitted = new EventEmitter<RallyanceReclamationNewReclamationFormValues>();
	constructor(private _fb: FormBuilder, private _reclamation: ReclamationService) {}

	reclamationReasons: RallyanceReclamationReasons[] = [];

	reclamationReasons$ = this._reclamation.sugRecReasons$.pipe(map((reasons: RallyanceReclamationReasons[]) => reasons.filter(reason => reason.Sug === '1')));

	form = this._fb.nonNullable.group({
		RekID: ['', [Validators.required]],
		SugTakala: ['', [Validators.required]],
		AgentRemark: ['', [Validators.maxLength(200)]],
	});

	ngOnInit(): void {
		this._reclamation.init();

		if (!this._reclamation.sugRecReasons$.value.length) {
			alert('יש לעדכן נתונים בכדי לבצע רקלמציה');
			history.back();
			return;
		}

		this.reclamationReasons$.pipe(take(1)).subscribe((reasons: RallyanceReclamationReasons[]) => {
			this.reclamationReasons = reasons;
		});
	}

	submit() {
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}
		const values = { ...this.form.value, SugTakala: this.reclamationReasons.find(x => +x.ID === +this.form.value.SugTakala).Name } as RallyanceReclamationNewReclamationFormValues;
		this.submitted.emit(values);
	}
}
