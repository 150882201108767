import { Component, OnInit, Inject } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';

@Component({
	selector: 'app-dohotbizoimyaadim',
	templateUrl: './dohotbizoimyaadim.component.html',
	styleUrls: ['./dohotbizoimyaadim.component.scss'],
})
export class DohotbizoimyaadimComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	public Bizoim = [];
	public BizoimSwitch;
	public BizoimSwitchMain;
	public DateToday = new Date();

	public toogleInputNew = false;

	public AllClientsSearch;

	public test;
	public test2;

	public FirstPage = true;

	public LastPage = false;

	public BizoimEach;

	public ShowCompanys;

	public HasMultipleCompanys;

	public CompanyChoosen = 1;

	public OneTeudaInfo;

	public Loading = false;

	reportSearch = null;

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Month: ['', Validators.required],
			Search: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this.Loading = true;
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			this._ss.getBizoimYaadim(this.CompanyChoosen).subscribe(res => {
				this.AllClientsSearch = res;
				this.AllClientsSearch = this.AllClientsSearch.recordset;
				this.Bizoim = this.BizoimSwitch = this.AllClientsSearch;

				this.test = this.DateToday.toISOString().toString().slice(5, 7);
				this.test2 = this.DateToday.toISOString().toString().slice(0, 4);

				// for (let x = 0; x < this.AllClientsSearch.length; x++) {
				//   this.AllClientsSearch[x].Yy1 = JSON.stringify(this.AllClientsSearch[x].Yy1)
				//   this.AllClientsSearch[x].Client_Name = this.AllClientsSearch[x].Cn
				//   this.AllClientsSearch[x].ClientId = this.AllClientsSearch[x].Cl
				//   if (this.AllClientsSearch[x].Mm1 === this.test && this.AllClientsSearch[x].Yy1 === this.test2 && this.AllClientsSearch[x].cm === 1) {
				//     this.Bizoim.push(this.AllClientsSearch[x])
				//   }
				// }

				this.BizoimSwitch = this.AllClientsSearch;

				this.AllClientsSearch = this.Bizoim;

				// this.Bizoim.sort(function (a, b) {
				//   return b.Ahuz - a.Ahuz;
				// });

				this.Loading = false;
			});
		} else {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('AllProducts') !== null) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
			this.AllProductsSearch = this.AllProducts;
		} else {
			this.router.navigate(['clients']);
		}
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public AH = false;
	public TYA = false;
	public lYA = false;

	public SortAhoz() {
		if (this.AH === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ahuz - a.Ahuz;
			});

			this.AH = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ahuz - b.Ahuz;
			});

			this.AH = false;
		}
	}

	public SortThisYear() {
		if (this.TYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Tot - a.Tot;
			});

			this.TYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Tot - b.Tot;
			});

			this.TYA = false;
		}
	}

	public SortLastYear() {
		if (this.lYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ltot - a.Ltot;
			});

			this.lYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ltot - b.Ltot;
			});

			this.lYA = false;
		}
	}

	public ChangeMonth() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].Mm1 === this.form.controls['Month'].value && this.BizoimSwitch[x].Yy1 === this.test2 && this.BizoimSwitch[x].cm === this.CompanyChoosen) {
				this.Bizoim.push(this.BizoimSwitch[x]);
			}
		}

		this.AllClientsSearch = this.Bizoim;
	}

	public ChangeCompany() {
		// this.form.controls['Month'].reset()

		this.CompanyChoosen = JSON.parse(this.form.controls['Company'].value);

		this.AllClientsSearch = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].cm === JSON.parse(this.form.controls['Company'].value)) {
				this.AllClientsSearch.push(this.BizoimSwitch[x]);
			}
		}

		this.Bizoim = this.AllClientsSearch;
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public BackToDohotBizoim() {
		if (this.LastPage === true) {
			this.LastPage = false;
			this.OneTeudaInfo = null;
		} else {
			if (this.FirstPage === true) {
				// this.router.navigate(['dohot-bizoim'])
				history.back();
			} else {
				this.BizoimEach = null;
				this.FirstPage = true;
			}
		}
	}
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	public OneTeuda(number) {
		this.LastPage = true;

		this._ss.GetOneTeudaDohot(number).subscribe(res => {
			this.OneTeudaInfo = res;
			this.OneTeudaInfo = this.OneTeudaInfo.recordset;
		});
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	//THIS IS IN THE SAME PAGE NOT IN NEW PAGE
	public MoveToDohotBizoimLekohotEach(ClientId, Company, Month, Year) {
		this.Loading = true;
		this.FirstPage = false;
		this._ss.EachClientDohot(ClientId, Company, Month, Year).subscribe(res => {
			this.BizoimEach = res;
			this.BizoimEach = this.BizoimEach.recordset;

			this.Loading = false;
		});
	}

	//=====================================================================================================================================================================================================================================================================>
	public SearchClear() {
		this.Bizoim = this.BizoimSwitch;

		this.form.controls['Search'].reset();

		// for (let x = 0; x < this.BizoimSwitch.length; x++) {
		//   if (this.BizoimSwitch[x].Mm1 === this.test && this.BizoimSwitch[x].Yy1 === this.test2 && this.BizoimSwitch[x].cm === this.CompanyChoosen) {
		//     this.Bizoim.push(this.BizoimSwitch[x])
		//   }

		// }

		// this.form.reset()
	}
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public Search() {
		this.Bizoim = [];

		const searchInput = this.form.controls['Search'];

		if (searchInput.valid) {
			this.Bizoim = this.BizoimSwitch.filter(biz =>
				Object.values(biz)
					.map((str: string) => str.toLowerCase())
					.join()
					.match(searchInput.value.toLowerCase()),
			);
		} else {
			this.Bizoim = this.BizoimSwitch;
		}

		// if (this.form.controls['Search'].value === "" || this.form.controls['Search'].value.length === 0) {
		//   for (let x = 0; x < this.BizoimSwitch.length; x++) {
		//     if (this.BizoimSwitch[x].Mm1 === this.test && this.BizoimSwitch[x].Yy1 === this.test2 && this.BizoimSwitch[x].cm === this.CompanyChoosen) {
		//       this.Bizoim.push(this.BizoimSwitch[x])
		//     }

		//   }
		// }
		// if (this.form.controls['Search'].value.length === 1) {
		//   for (let x = 0; x < this.AllClientsSearch.length; x++) {
		//     if (this.AllClientsSearch[x].Client_Name.toString().slice(0, 1) === this.form.controls['Search'].value.toString().slice(0, 1) ||
		//       this.AllClientsSearch[x].ClientId.toString().slice(0, 1) === this.form.controls['Search'].value.toString().slice(0, 1)
		//     ) {
		//       this.Bizoim.push(this.AllClientsSearch[x])
		//     }
		//   }
		// }
		// if (this.form.controls['Search'].value.length === 2) {
		//   for (let x = 0; x < this.AllClientsSearch.length; x++) {
		//     if (this.AllClientsSearch[x].Client_Name.toString().slice(0, 2) === this.form.controls['Search'].value.toString().slice(0, 2) ||
		//       this.AllClientsSearch[x].ClientId.toString().slice(0, 2) === this.form.controls['Search'].value.toString().slice(0, 2)
		//     ) {
		//       this.Bizoim.push(this.AllClientsSearch[x])
		//     }
		//   }
		// }
		// if (this.form.controls['Search'].value.length > 2) {
		//   for (let x = 0; x < this.AllClientsSearch.length; x++) {
		//     for (let z = 0; z < 50; z++) {
		//       if (this.AllClientsSearch[x].Client_Name.toString().slice(z, this.form.controls['Search'].value.length + z) === this.form.controls['Search'].value.toString().slice(0, this.form.controls['Search'].value.length) ||
		//         this.AllClientsSearch[x].ClientId.toString().slice(z, this.form.controls['Search'].value.length + z) === this.form.controls['Search'].value.toString().slice(0, this.form.controls['Search'].value.length)
		//       ) {
		//         let data = this.Bizoim.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
		//         if (data === undefined) {
		//           this.Bizoim.push(this.AllClientsSearch[x])
		//         }

		//       }
		//     }
		//   }
		// }
	}
	//=====================================================================================================================================================================================================================================================================>
}
