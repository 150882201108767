import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { LikutTaskDisplayWithAssignment } from 'src/app/Interfaces/likut';
import { TruckDistributionTaskDisplayWithAssignment } from 'src/app/Interfaces/truck-distribution';
import { TruckLoadingTaskDisplayWithAssignment } from 'src/app/Interfaces/truck-loading';
import { TaskTextVisibilityPipe } from 'src/app/Pipes/task-text-visibility.pipe';
import { LikutService } from 'src/app/services/likut.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { TruckLoadingService } from 'src/app/services/truck-loading.service';
import { SubAppModes } from '../../likut-clients/likut-clients.component';

@Component({
	selector: 'app-kanban-assignment-popup',
	templateUrl: './kanban-assignment-popup.component.html',
	styleUrls: ['./kanban-assignment-popup.component.scss'],
})
export class KanbanAssignmentPopupComponent implements OnInit, OnDestroy {
	constructor(
		private dialogRef: MatDialogRef<KanbanAssignmentPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment,
		private _fb: FormBuilder,
		public _truckDistribution: TruckDistributionService,
		public _truckLoading: TruckLoadingService,
		public _likut: LikutService,
		public _loading: LoadingService,
	) {}

	form = this._fb.nonNullable.group({
		date: [''],
		agent: [this.data.AssignTo || ''],
	});

	shouldShowDate = this.determineDistributionTask(this.data) || this.determineLoadingTask(this.data);

	destroy$ = new Subject<void>();
	pageMode$ = new BehaviorSubject<SubAppModes>('truck-distribution');

	ngOnInit(): void {
		this.init();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	init() {
		this.resolvePageMode();

		if (this.determineLikutTask(this.data)) {
			this.form.controls.agent.setValue(this.data.AssignedTo || '');
		}

		if (this.shouldShowDate) {
			if (this.determineDistributionTask(this.data)) {
				this.form.controls.date.setValue(this.data.PODDate);
			}
			if (this.determineLoadingTask(this.data)) {
				this.form.controls.date.setValue(this.data.LoadDate);
			}
		}

		if (
			this.data.OpenedBy ||
			TaskTextVisibilityPipe.prototype.transform(this.data.status, this._truckLoading.Admin) ||
			TaskTextVisibilityPipe.prototype.transform(this.data.status, this._likut.Admin)
		) {
			this.form.disable();
		}

		this.form.controls.date.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			if (this.determineDistributionTask(this.data)) {
				this._truckDistribution.taskAssigmentPopupDateChange.emit({ newDate: value, task: this.data });
			}
			if (this.determineLoadingTask(this.data)) {
				this._truckLoading.taskAssigmentPopupDateChange.emit({ newDate: value, task: this.data });
			}
			// if (this.determineLikutTask(this.data)) {
			// 	this._likut.taskAssigmentPopupDateChange.emit({ newDate: value, task: this.data });
			// }
		});

		this.form.controls.agent.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			value ||= null;
			if (this.determineDistributionTask(this.data)) {
				this._truckDistribution.taskAssigmentPopupAgentChange.emit({ newAgent: value, task: this.data });
			}
			if (this.determineLoadingTask(this.data)) {
				this._truckLoading.taskAssigmentPopupAgentChange.emit({ newAgent: value, task: this.data });
			}
			if (this.determineLikutTask(this.data)) {
				this._likut.taskAssigmentPopupAgentChange.emit({ newAgent: value, task: this.data });
			}
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	resolvePageMode() {
		let path: SubAppModes;
		if (location.pathname.includes('truck-loading')) path = 'truck-loading';
		if (location.pathname.includes('truck-distribution')) path = 'truck-distribution';
		if (location.pathname.includes('likut')) path = 'likut';
		this.pageMode$.next(path);
	}

	determineDistributionTask(
		task: TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment | LikutTaskDisplayWithAssignment,
	): task is TruckDistributionTaskDisplayWithAssignment {
		return 'PODDate' in task;
	}

	determineLoadingTask(task: TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment | LikutTaskDisplayWithAssignment): task is TruckLoadingTaskDisplayWithAssignment {
		return 'LoadDate' in task;
	}

	determineLikutTask(task: TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment | LikutTaskDisplayWithAssignment): task is LikutTaskDisplayWithAssignment {
		return 'LikutDate' in task;
	}
}
