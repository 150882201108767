import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';
import { TextService } from 'src/app/services/text.service';

@Component({
	selector: 'app-dohmechira',
	templateUrl: './dohmechira.component.html',
	styleUrls: ['./dohmechira.component.scss'],
})
export class DohmechiraComponent implements OnInit {
	LoadingSeeTeuda = false;
	HelpAllClients: any[];
	TeudotEach: any;
	selectedTeudaToOpenPopup: any;
	custVAT = this._ss.valueAddedTax;
	SeeTeudaLoaded: boolean;
	errorLoadingTeuda: boolean;
	hasUpdatedQueryWithDocNumAndDocType = false;

	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _text: TextService) {}

	public form: FormGroup;

	public ProductsNumOfTimes;

	public AllProductsSearch;

	public AllProducts;

	public AllClientsSearch;

	public AllClients;

	public First = true;

	public Second = false;

	reportSearch = null;
	HasMultipleCompanys;
	ShowCompanys;

	sortArr = [true, false, false, false];

	longestClientId = 0;
	longestProductId = 0;

	hasTDashKabalaQueries = false;

	history = history;

	ngOnInit(): void {
		window.scrollTo(0, 0);

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.form = this._fb.group({
			ClientSearch: ['', Validators.required],
			ProductSearch: ['', Validators.required],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		}

		this.AllProductsSearch = decompress(localStorage.getItem('AllProducts'));
		this.AllProductsSearch = JSON.parse(this.AllProductsSearch);

		if (!this._ss.valueAddedTax) {
			this._ss.valueAddedTax = JSON.parse(decompress(localStorage.getItem('AllClients')))[0].CustVat;
		}

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			this.AllProductsSearch[x].NumOfTimes = 0;
			this.AllProductsSearch[x].DocPrice = 0;
			this.AllProductsSearch[x].DocDiscount = 0;
			this.AllProductsSearch[x].docquantity = 0;
			// this.AllProductsSearch[x].ProductIdNoChange = this.AllProductsSearch[x].ProductId
			let str = this.AllProductsSearch[x].Product_Name;
			let strid = this.AllProductsSearch[x].ProductId;
			this.AllProductsSearch[x].Product_Name = str.toString().toLowerCase();
			this.AllProductsSearch[x].ProductId = strid.toString().toLowerCase();
			this.AllProductsSearch[x].present = { icon: true, showContent: false };
		}

		this.AllClientsSearch = decompress(localStorage.getItem('AllClients'));
		this.AllClientsSearch = JSON.parse(this.AllClientsSearch);

		this.AllClients = this.AllClientsSearch;

		this.longestClientId = Math.max(this.longestClientId, ...this.AllClients.map(cl => cl.ClientId.length));

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		this._ss.getNumOfProductsPurchasedWithClient().subscribe({
			next: (res: any) => {
				if (typeof res === 'string') {
					console.log('no tdashkabala');
					return;
				}

				this.hasTDashKabalaQueries = true;

				const obj = res.recordset.reduce((prev, curr) => {
					const { ClientID, products } = curr;

					prev[ClientID] = {};
					prev[ClientID].products = products;

					// prev[ClientID].products += products

					return prev;
				}, {});

				this.AllClientsSearch = this.AllClients = this.AllClientsSearch.map(cls => ({
					...cls,
					numOfProductsPurchased: obj[cls.ClientId]?.products || 0,
				})).filter(cls => cls.numOfProductsPurchased);
			},
		});
	}

	sortString(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'Client_Name':
				i = 0;
				break;
			case 'ClientId':
				i = 1;
				break;
			case 'ProductId':
				i = 2;
				break;
			case 'Product_Name':
				i = 3;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[i] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	sortArrNumber = [false, false, false];

	sortNumber(valueToSortBy: string, arrayToSort: string) {
		let bool;
		let i;

		switch (valueToSortBy) {
			case 'numOfProductsPurchased':
				i = 0;
				break;
			case 'sumqty':
				i = 1;
				break;
			case 'sumtotalrows':
				i = 2;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArrNumber[i];
		this.sortArrNumber = this.sortArrNumber.map(_ => false);

		this.sortArrNumber[i] = bool;

		if (!bool) {
			this[arrayToSort].sort((a, b) => +b[valueToSortBy] - +a[valueToSortBy]);
		} else {
			this[arrayToSort].sort((a, b) => +a[valueToSortBy] - +b[valueToSortBy]);
		}

		this.sortArrNumber[i] = !bool;
	}

	public MoveToDohotMotzarim() {
		window.scrollTo(0, 0);
		if (this.Second === true) {
			this.Second = false;
			this.First = true;
			this.AllProducts = [];
			this.ChoosenClient = '';

			this.AllClients = this.AllClientsSearch;
		} else {
			this.history.back();
		}
	}

	public ChoosenClient = '';
	loading = false;
	hasUpdatedChart = false;
	scrollY = 0;

	public GetClientsPricess(ClientId, company, Client_Name) {
		window.scrollTo(0, 0);
		this.AllProducts = [];
		this.ChoosenClient = Client_Name;
		this.Second = true;
		this.First = false;
		this.loading = true;
		this.scrollY = window.scrollY;

		if (!this.hasTDashKabalaQueries) {
			this.getProductsOldMethod(ClientId, company);
		} else {
			this._ss.getSecondPageOfDohMechira(ClientId).subscribe({
				next: (res: any) => {
					this.loading = false;
					if (res.recordset.length) {
						this.AllProductsSearch = this.AllProducts = res.recordset;
						this.longestProductId = Math.max(this.longestProductId, ...this.AllProducts.map(prd => prd.ProductId.length));

						console.log({ longest: this.longestProductId });

						this.hasUpdatedQueryWithDocNumAndDocType = this.AllClientsSearch[0].docnum !== undefined;

						this.hasUpdatedChart = true;
						return;
					}
				},
			});
		}
	}

	getProductsOldMethod(ClientId, company) {
		this._ss.GetAllPrices({ client: ClientId, company }).subscribe({
			next: res => {
				this.ProductsNumOfTimes = res;
				this.ProductsNumOfTimes = this.ProductsNumOfTimes.recordset;

				this.AllProducts = [];

				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					this.longestProductId = Math.max(this.longestProductId, this.AllProductsSearch[x].ProductId.length);

					for (let y = 0; y < this.ProductsNumOfTimes.length; y++) {
						if (this.AllProductsSearch[x].ProductId === this.ProductsNumOfTimes[y].ProductId && this.ProductsNumOfTimes[y].NumOfTimes) {
							this.AllProducts.push(this.ProductsNumOfTimes[y]);
						}
					}
				}

				// this.AllProducts = this.AllProductsSearch
				// .filter(prod => prod.NumOfTimes)

				this.loading = false;
				this.hasUpdatedChart = false;
			},
			error: err => {
				alert('לא ניתן להביא נתונים');
				console.log(err);
				this.loading = false;
				this.hasUpdatedChart = false;
			},
		});
	}

	public NumOfUp = true;
	public SortNumOfTimes() {
		// Better to do comparison one time instead of every time inside sort
		if (this.NumOfUp) {
			this.AllProducts.sort(function (a, b) {
				return b.NumOfTimes - a.NumOfTimes;
			});
		} else {
			this.AllProducts.sort(function (a, b) {
				return a.NumOfTimes - b.NumOfTimes;
			});
		}

		this.NumOfUp = !this.NumOfUp;
	}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;
	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}

	public ClearClientSearch() {
		window.scrollTo(0, 0);

		this.AllClients = [];

		this.form.reset();
		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			this.AllClients.push(this.AllClientsSearch[x]);
		}
	}

	public SearchClient() {
		window.scrollTo(0, 0);
		this.AllClients = [];

		window.scrollTo(0, 0);

		if (this.form.controls['ClientSearch'].value === '' || this.form.controls['ClientSearch'].value.length === 0) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClients.length < 100) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 1) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1)
				) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2)
				) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length > 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				for (let z = 0; z < 50; z++) {
					if (
						this.AllClientsSearch[x].Client_Name.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length) ||
						this.AllClientsSearch[x].ClientId.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length)
					) {
						let data = this.AllClients.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
						if (data === undefined) {
							this.AllClients.push(this.AllClientsSearch[x]);
						}
					}
				}
			}
		}
	}
	public ClearProductsSearch() {
		window.scrollTo(0, 0);
		this.AllProducts = [];

		this.form.reset();

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			this.AllProducts.push(this.AllProductsSearch[x]);
			if (this.AllProducts.length === 200) {
				break;
			}
		}
	}

	trackByFnClient(ac) {
		return ac.ClientId;
	}

	trackByFnProduct(ap) {
		return ap.ProductId;
	}

	public SearchProducts() {
		if (this.form.controls['ProductSearch'].value === null) {
		} else {
			window.scrollTo(0, 0);

			this.AllProducts = [];

			if (this.form.controls['ProductSearch'].value === '' || this.form.controls['ProductSearch'].value.length === 0) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					this.AllProducts.push(this.AllProductsSearch[x]);
					if (this.AllProducts.length === 100) {
						break;
					}
				}
			}
			if (this.form.controls['ProductSearch'].value.length === 1) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					if (
						this.AllProductsSearch[x].Product_Name.toString().slice(0, 1) === this.form.controls['ProductSearch'].value.toString().slice(0, 1) ||
						this.AllProductsSearch[x].ProductId.toString().slice(0, 1) === this.form.controls['ProductSearch'].value.toString().slice(0, 1) ||
						this.AllProductsSearch[x].ItemExtra1.toString().slice(0, 1) === this.form.controls['ProductSearch'].value.toString().slice(0, 1)
					) {
						this.AllProducts.push(this.AllProductsSearch[x]);
						if (this.AllProducts.length === 100) {
							break;
						}
					}
				}
			}
			if (this.form.controls['ProductSearch'].value.length === 2) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					if (
						this.AllProductsSearch[x].Product_Name.toString().slice(0, 2) === this.form.controls['ProductSearch'].value.toString().slice(0, 2) ||
						this.AllProductsSearch[x].ProductId.toString().slice(0, 2) === this.form.controls['ProductSearch'].value.toString().slice(0, 2) ||
						this.AllProductsSearch[x].ItemExtra1.toString().slice(0, 2) === this.form.controls['ProductSearch'].value.toString().slice(0, 2)
					) {
						this.AllProducts.push(this.AllProductsSearch[x]);
						if (this.AllProducts.length === 100) {
							break;
						}
					}
				}
			}
			if (this.form.controls['ProductSearch'].value.length > 2) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					for (let z = 0; z < 50; z++) {
						if (
							this.AllProductsSearch[x].Product_Name.toString().slice(z, this.form.controls['ProductSearch'].value.length + z) ===
								this.form.controls['ProductSearch'].value.toString().slice(0, this.form.controls['ProductSearch'].value.length) ||
							this.AllProductsSearch[x].ProductId.toString().slice(z, this.form.controls['ProductSearch'].value.length + z) ===
								this.form.controls['ProductSearch'].value.toString().slice(0, this.form.controls['ProductSearch'].value.length) ||
							this.AllProductsSearch[x].ItemBarcode.toString().slice(z, this.form.controls['ProductSearch'].value.length + z) ===
								this.form.controls['ProductSearch'].value.toString().slice(0, this.form.controls['ProductSearch'].value.length) ||
							this.AllProductsSearch[x].ItemExtra1.toString().slice(z, this.form.controls['ProductSearch'].value.length + z) ===
								this.form.controls['ProductSearch'].value.toString().slice(0, this.form.controls['ProductSearch'].value.length)
						) {
							let data = this.AllProducts.find(ob => ob.ProductId === this.AllProductsSearch[x].ProductId);
							if (data === undefined) {
								this.AllProducts.push(this.AllProductsSearch[x]);
							}
						}
					}
				}
			}
			if (this.AllProducts.length === 0) {
			}
		}
	}

	@ViewChild('teudotTableNames') rightSideOfTeudaProducts: ElementRef<HTMLTableCellElement>;
	shouldColorRedAndBolden = false;

	TeudaClicked(DocDocNum, DocDocType, Company) {
		this.LoadingSeeTeuda = true;
		document.body.classList.add('no-scroll');

		if (DocDocNum.includes('-')) {
			DocDocNum = DocDocNum.split('-')[0];
		}

		this.HelpAllClients = [];
		this._ss.GetOneTeudaAgentNew({ DocDocNum, DocDocType }, Company).subscribe({
			next: (res: any) => {
				this.TeudotEach = res.recordset;
				// this._ss.TeudotDetailsEach = this._ss.TeudotDetailsEach.recordset

				if (!this.TeudotEach.length) {
					this.LoadingSeeTeuda = false;
					this.errorLoadingTeuda = true;
					return;
				}

				this.selectedTeudaToOpenPopup = this.TeudotEach[0];
				this.shouldColorRedAndBolden = this.TeudotEach[0]?.DocDocType == '3' || this.TeudotEach[0]?.DocDocType == '5';
				setTimeout(() => {
					this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
				}, 10);

				if (this._ss.CustomerInformation.DisplayWithVat === '1') {
					this.TeudotEach = this.TeudotEach.map(td => ({ ...td, DocPrice: td.DocPrice * (1 + this.custVAT / 100), DocTotalRow: td.DocTotalRow * (1 + this.custVAT / 100) }));
				}
				this.SeeTeudaLoaded = true;
				this.LoadingSeeTeuda = false;
				this.errorLoadingTeuda = false;
			},
			error: err => {
				// this.LoadingSeeTeuda = false;
				this.errorLoadingTeuda = true;
			},
		});
	}

	handleClosePopup() {
		this.errorLoadingTeuda = false;
		this.SeeTeudaLoaded = false;
		this.LoadingSeeTeuda = false;
		this.TeudotEach = [];
		document.body.classList.remove('no-scroll');
		this.selectedTeudaToOpenPopup = {};
		this.shouldColorRedAndBolden = false;
	}
}
