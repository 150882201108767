import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { icons } from 'src/app/Interfaces/assets';

export type ReportDashboardItemInput = {
	path: string | string[];
	text: string;
	icon: keyof typeof icons;
	ignoreClick?: boolean;
};

@Component({
	selector: 'app-report-dashboard-item',
	templateUrl: './report-dashboard-item.component.html',
	styleUrls: ['./report-dashboard-item.component.scss'],
})
export class ReportDashboardItemComponent implements OnInit {
	@Input() requiredProperties: Partial<ReportDashboardItemInput> = {};
	@Input() ignoreClick: boolean = false;
	// @Input() maxWidth: number = 0;

	path: string | string[] = '';
	text = '';
	icon = '';

	isBlocked: boolean = false;

	iconDictionary = icons;

	constructor(public _ss: StoreService, private router: Router) {}

	ngOnInit() {
		const { path, text, icon } = this.requiredProperties;
		this.path = path;
		this.text = text;
		this.icon = icon;
		if (Array.isArray(this.path)) {
			this.isBlocked = !!this._ss.blockPaths[this.path.join('/')];
			return;
		}
		this.isBlocked = !!this._ss.blockPaths[this.path];
	}

	navigateTo() {
		if (this.isBlocked || this.ignoreClick) return;
		if (Array.isArray(this.path)) {
			this.router.navigate(this.path);
			return;
		}
		this.router.navigate([this.path]);
	}

	getMainClass() {
		return { ['report-dashboard__item_' + this.icon]: true };
	}

	getStyle() {
		// if (this.maxWidth) return { 'max-width': `${this.maxWidth}px` };
		return null;
	}
}
