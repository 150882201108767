import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';
import { LoginResponse } from 'src/app/Interfaces/login-response';
import { Order } from 'src/app/Interfaces/order';
import { TextService } from 'src/app/services/text.service';

@Component({
	selector: 'app-clientteudoteach',
	templateUrl: './clientteudoteach.component.html',
	styleUrls: ['./clientteudoteach.component.scss'],
})
export class ClientteudoteachComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _text: TextService) {}

	public EachClientEachOrder = this._ss.EachClientEachOrder;
	addressToDisplay =
		this._ss.OneClientInfo?.Address && this._ss.OneClientInfo?.CustCity
			? `${this._ss.OneClientInfo.Address}, ${this._ss.OneClientInfo.CustCity}`
			: this._ss.OneClientInfo?.Address || this._ss.OneClientInfo?.CustCity || 'לא קיימת';
	// If both undefined for some reason

	isRemarkPopupOpen = false;
	remarksArr = [];

	canReplicateTeuda;

	public AllOrder = [];

	public PopDuplicate = false;

	public ConfirmPop = false;

	agentInfo: LoginResponse = {};

	clientAllDocTypes;

	DocTypePerClient = [];

	AllDocTypesSave;

	history = history;

	showItemInPack = true;

	ngOnInit(): void {
		window.scrollTo(0, 0);

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
		}

		if (!this._ss.EachClientEachOrder) {
			this.router.navigate(['clients']);
			return;
		}

		this.showItemInPack = this._ss.CustomerInformation.HideQuantityInPack !== '1';

		this.agentInfo.ordon = Number(localStorage.getItem('ordon'));
		this.agentInfo.hazaon = Number(localStorage.getItem('hazaon'));
		this.agentInfo.reton = Number(localStorage.getItem('reton'));

		if (localStorage.getItem('AllDocTypesSave') !== null) {
			this.DocTypePerClient = [];
			this.AllDocTypesSave = decompress(localStorage.getItem('AllDocTypesSave'));
			this.AllDocTypesSave = JSON.parse(this.AllDocTypesSave);

			const { Client_Id, TeudaCompany: company } = this.EachClientEachOrder[0];

			for (let x = 0; x < this.AllDocTypesSave.length; x++) {
				if (this.AllDocTypesSave[x].Company == company && this.AllDocTypesSave[x].ClientID == Client_Id) {
					this.DocTypePerClient.push(this.AllDocTypesSave[x]);
				}
			}
		}

		const [{ TeudaRemark, TeudaRemark1, TeudaRemark2, TeudaRemark3, TeudaRemark4, TeudaRemark5 }] = this.EachClientEachOrder;
		this.remarksArr = [TeudaRemark, TeudaRemark1, TeudaRemark2, TeudaRemark3, TeudaRemark4, TeudaRemark5];

		console.log('order:', this.EachClientEachOrder);

		this._ss.GetTeudotEach(this.EachClientEachOrder[0].TeudaRef).subscribe(
			(res: any) => {
				try {
					const { recordset } = res;

					if (recordset?.length) {
						this.canReplicateTeuda = JSON.parse(recordset[0].DATAString);
						console.log({ rep: this.canReplicateTeuda });
					}
				} catch (error) {}
			},
			err => {},
		);
	}

	ngOnDestroy(): void {
		if (this._ss.duplicatedTeudaAndCleanupForFilter) {
			const client = this._ss.OneClientInfo;
			this._ss.cleanupForProductsPage();
			this._ss.OneClientInfo = client;
		}
	}

	//can't have .filter in ngif in template
	areThereAnyRemarksAtAll() {
		return this.remarksArr.filter(rmrk => rmrk).length;
	}

	getRemarkHeader(i: number) {
		switch (i) {
			case 0:
				return 'הערה ראשית';
			case 1:
				return 'הערה 1';
			case 2:
				return 'הערה 2';
			case 3:
				return 'הערה 3';
			case 4:
				return 'הערה 4';

			default:
				return 'הערה 5';
		}
	}

	handleRemarksPopupChangeDisplay(val: boolean) {
		this.isRemarkPopupOpen = val;
	}

	DuplicateOrder(Type) {
		this.AllOrder = [];

		for (let x = 0; x < this.canReplicateTeuda.length; x++) {
			const original: Partial<Order> = this.canReplicateTeuda[x];
			const order: Partial<Order> = {
				Stock: original.Stock,
				ProductId: original.ProductId,
				Product_Name: original.Product_Name,
				Price: original.Price,
				PermPrice: original.PermPrice,
				ItemPack: original.ItemPack,
				TeudaQuantity: original.TeudaQuantity,
				TeudaPackQuantity: original.TeudaPackQuantity,
				TeudaBonusQuantity: original.TeudaBonusQuantity,
				YehidotPrice: original.YehidotPrice || 0,
				ArizotPrice: original.ArizotPrice || 0,
				TeudaTotalRow: original.TeudaTotalRow,
				NetPrice: original.NetPrice,
				ItemBarcode: original.ItemBarcode,
				TeudaItemRemark: original.TeudaItemRemark,
				TeudaRemark: original.TeudaRemark,
				ChangePrice: original.ChangePrice,
				ChangeDiscount: original.ChangeDiscount,
				ItemPicName: original.ItemPicName,
				BD: original.BD,
				BP: original.BP,
				BM: original.BM,
				BS: original.BS,
				MinPrice: original.MinPrice,
				qt: original.qt,
				PKD: original.PKD,
				CPP: original.CPP,
				Ptve: this._ss.VersionNumber,
				Duplicate: 'false',
				DuplicateOrder: 'true',
				IfChangePriceOrDisc: original.IfChangePriceOrDisc,
				PriceInInput: original.Price,
				TeudaNum: new Date().getTime() + '000' + this._ss.AgentIdConnected,
				TeudaCreateDate: new Date().toISOString(),
				basePrice: original.basePrice || original.ShowPrice || original.PermPrice,
			};

			if (this._ss.CustomerInformation.CopyRemark === '1') {
				order.TeudaRemark = original.TeudaRemark;
				order.TeudaRemark1 = original.TeudaRemark1;
				order.TeudaRemark2 = original.TeudaRemark2;
				order.TeudaRemark3 = original.TeudaRemark3;
				order.TeudaRemark4 = original.TeudaRemark4;
				order.TeudaRemark5 = '' + original.TeudaTotalSumBeforeVat;

				if (this._ss.CustomerInformation.CustNum == '61') {
					order.TeudaRemark5 = this._ss.roundNumberTo2Decimals(+order.TeudaRemark5 / 0.7);
				}
			}

			this.AllOrder.push(order);
		}

		console.log({ allorder: this.AllOrder });

		localStorage.setItem('AllOrder' + this.EachClientEachOrder[0].Client_Id + Type + this.EachClientEachOrder[0].TeudaCompany, JSON.stringify(this.AllOrder));

		this.ConfirmPop = true;
		this.PopDuplicate = false;

		this._ss.duplicatedTeudaAndCleanupForFilter = true;
	}
}
