import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getNewReceiptScreenTitle',
	pure: true
})
export class GetNewReceiptScreenTitlePipe implements PipeTransform {
	transform(mainScreen: boolean, clientName: string, isEdittingReceipt: boolean): 'המחאה חדשה' | 'עריכת המחאה' | string {
		if (mainScreen) {
			return 'בצע קבלה - ' + clientName;
		}

		if (!isEdittingReceipt) {
			return 'המחאה חדשה';
		}

		return 'עריכת המחאה';
	}
}
