export const SPECIAL_REFUND_DOCTYPES = new Set([174, 175, 176, 177, 178, 179]);

export const DEFAULT_RETURN_DOCTYPE = 74;
export const DEFAULT_ORDER_DOCTYPE = 11;
export const DEFAULT_VIRTUAL_DOCTYPE = 7;
export const DEFAULT_RESERVED_ORDER_DOCTYPE = 88;

export const ALL_CLIENTSIDE_ORDER_DOCTYPES = new Set([11, 170, 171, 172, 88]);

export const DOCTYPE_DEFAULT_NAME: Record<number, string> = {
	88: 'הזמנה משריון',
	11: 'הזמנה',
	170: 'הזמנה נוספת',
	171: 'הזמנה נוספת 1',
	172: 'הזמנה נוספת 2',
	74: 'ת. החזרה',
	7: 'הצעת מחיר',
};

export const RECLAMATION_DOCTYPE = 555;
export const RECLAMATION_RALLYANCE_DOCTYPE = 1110;

export const DEFAULT_DOCTYPE_BY_TYPE = {
	virtual: DEFAULT_VIRTUAL_DOCTYPE,
	order: DEFAULT_ORDER_DOCTYPE,
	return: DEFAULT_RETURN_DOCTYPE,
	reclamation: RECLAMATION_DOCTYPE,
	'reclamation-rallyance': RECLAMATION_RALLYANCE_DOCTYPE,
} as const;
