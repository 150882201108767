import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shouldCheckboxBeMarked',
})
export class ShouldCheckboxBeMarkedPipe implements PipeTransform {
	transform(clientid: any, arr: any[]): boolean {
		return arr.find((item: any) => clientid === item.ClientId) ? true : false;
	}
}
