<h1 mat-dialog-title dir="rtl">פרטים נוספים</h1>

<div class="product-detail__item-more-block">
	<ng-container *ngIf="reverseProductExtraInfoOrder">
		<div class="product-detail__item-more-block-row row" *ngFor="let cp of data.CommentsProduct">
			<div class="product-detail__item-more-block-title">{{ cp.HeadLineStr }}</div>
			<div class="product-detail__item-more-block-text">{{ cp.DataLineStr }}</div>
			<div class="product-detail__item-more-block-text">
				<a href="{{ cp.IfUrl }}" target="_blank" rel="noopener noreferrer">{{ cp.IfUrl }}</a>
			</div>
		</div>
	</ng-container>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ap.Unit">
		<div class="product-detail__item-more-block-title">{{ data.ap.ItemPack }} באריזה</div>
		<div class="product-detail__item-more-block-text">{{ data.ap.Unit }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ShowProfit === '1'">
		<div class="product-detail__item-more-block-title">רווחיות שורה</div>
		<div class="product-detail__item-more-block-text">{{ ((data.ap.NetPrice - data.ap.CPP) / data.ap.NetPrice) * 100 | number : '1.0-2' : 'en-US' }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ShowProfit === '2'">
		<div class="product-detail__item-more-block-title">רווחיות שורה</div>
		<div class="product-detail__item-more-block-text">{{ ((data.ap.NetPrice - data.ap.CPP) / data.ap.CPP) * 100 | number : '1.0-2' : 'en-US' }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ap.NumOfTimes">
		<div class="product-detail__item-more-block-title">תאריך אחרון שנקנה</div>
		<div class="product-detail__item-more-block-text">{{ data.ap.DocCreateDateDisplay }}</div>
	</div>
	<div class="product-detail__item-more-block-row row">
		<div class="product-detail__item-more-block-title">מספר פעמים שנקנה</div>
		<div class="product-detail__item-more-block-text">{{ data.ap.NumOfTimes }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ap.BM !== 3 && data.ap.BM !== 4 && _ss.CustomerInformation.HideMinPrice === '0'">
		<div class="product-detail__item-more-block-title">מחיר מינימום</div>
		<div class="product-detail__item-more-block-text">{{ data.ap.MinPrice | number : '1.0-2' : 'en-US' }}</div>
	</div>
	<div class="product-detail__item-more-block-row row">
		<div class="product-detail__item-more-block-title">מחלקה</div>
		<div class="product-detail__item-more-block-text">{{ data.ap.IGC  }}</div>
	</div>
	<div class="product-detail__item-more-block-row row">
		<div class="product-detail__item-more-block-title">שם סינון</div>
		<div class="product-detail__item-more-block-text">{{ data.ap.ItemExtra1 }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ap.ItemExtra2">
		<div class="product-detail__item-more-block-text">{{ data.ap.ItemExtra2 }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ap.ItemExtra3">
		<div class="product-detail__item-more-block-text">{{ data.ap.ItemExtra3 }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ap.ItemExtra4">
		<div class="product-detail__item-more-block-text">{{ data.ap.ItemExtra4 }}</div>
	</div>
	<div class="product-detail__item-more-block-row row" *ngIf="data.ap.ItemExtra5">
		<div class="product-detail__item-more-block-text">{{ data.ap.ItemExtra5 }}</div>
	</div>

	<ngx-skeleton-loader count="1" appearance="line" *ngIf="data.loadingComments"></ngx-skeleton-loader>

	<ng-container *ngIf="!reverseProductExtraInfoOrder">
		<div class="product-detail__item-more-block-row row" *ngFor="let cp of data.CommentsProduct">
			<div class="product-detail__item-more-block-title">{{ cp.HeadLineStr }}</div>
			<div class="product-detail__item-more-block-text">{{ cp.DataLineStr }}</div>
			<div class="product-detail__item-more-block-text">
				<a href="{{ cp.IfUrl }}" target="_blank" rel="noopener noreferrer">{{ cp.IfUrl }}</a>
			</div>
		</div>
	</ng-container>
	<div class="product-detail__item-more-block-row row" *ngIf="alternativeProductCalculations && data.ap.NetPrice < data.ap.ShowPrice">
		<div class="product-detail__item-more-block-title">הנחה ממחירון</div>
		<div class="product-detail__item-more-block-text">{{ (1 - data.ap.NetPrice / data.ap.ShowPrice) * 100 | number : '1.0-2' }}%</div>
	</div>
</div>
<div mat-dialog-actions dir="rtl">
	<button mat-raised-button (click)="onNoClick()" color="primary">סגור</button>
</div>
