<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="handleBack()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>

			<div class="header__title col">{{ title$ | async }}</div>

			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="showSearch$ | async">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<section class="report-table" style="margin-top: 50px">
	<div class="content">
		<div class="report-table__row report-table__row_between flex-column" style="margin-top: 50px">
			<h2 style="text-align: center" *ngIf="selectedYear$ | async as selectedYear">{{ selectedYear }}</h2>
			<h2 style="text-align: center" *ngIf="isAdmin">{{ currentMonth | returnMonthHebrew : true }}</h2>
			<ng-container *ngIf="agentIsSelected$ | async">
				<h2 style="text-align: center" *ngIf="selectedAgent$ | async as selectedAgent">{{ selectedAgent.name }}</h2>
			</ng-container>
			<h2 style="text-align: center" *ngIf="selectedMonthKabala$ | async as selectedMonthKabala">{{ selectedMonthKabala | returnMonthHebrew : true }}</h2>
			<h2 style="text-align: center" *ngIf="selectedKabalaNumber$ | async as selectedKabalaNumber">{{ selectedKabalaNumber }}</h2>

			<div class="flex-row" *ngIf="!loading">
				<div class="box">
					<app-excel-button [filename]="excelFilename$ | async"></app-excel-button>
				</div>
			</div>
		</div>

		<h1 *ngIf="loading">טוען...</h1>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<ng-container *ngIf="!loading">
				<ng-container *ngIf="yaadGviaDisplay$ | async as yaadGvia">
					<h1 *ngIf="!yaadGvia.length">לא נמצאו תוצאות</h1>
					<ng-container *ngIf="yaadGvia.length">
						<!-- <table class="report-table__table" cellspacing="0" *ngIf="firstPage$ | async">
							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name" style="width: 150px">חודש</th>
									<th class="report-table__table-head-name" style="width: 150px">יעד גביה</th>
									<th class="report-table__table-head-name" style="width: 150px">גביה בפועל</th>
									<th class="report-table__table-head-name" style="width: 150px">עמידה ביעד</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr class="clickable" (click)="moveToSecondPageAdmin(yaadGvia[0].yy)">
									<td class="report-table__table-body-name">
										<div class="flex gap align-center">
											<span>{{ yaadGvia[0].yy }}</span>
											<button
												class="order-product__item-info-count-button"
												(click)="handleYearlyViewToggle($event, yaadGvia[0].yy)"
												*ngIf="isAdmin"
												[ngClass]="{ 'order-product__item-info-count-button_add': (isExpandedYearlyViewOpen$ | async) === false }"
											></button>
										</div>
									</td>
									<td class="report-table__table-body-name">{{ yaadGvia[0].tothov | number : '1.0-2' }}</td>
									<td class="report-table__table-body-name">{{ yaadGvia[0].gviathismonth | number : '1.0-2' }}</td>
									<td class="report-table__table-body-name">{{ yaadGvia[0].ahuzgvia ? (yaadGvia[0].ahuzgvia | number : '1.0-2') + '%' : 0 }}</td>
								</tr>
								<ng-container *ngIf="isExpandedYearlyViewOpen$ | async">
									<ng-container *ngIf="(expandedYearlyViewSelectedYear$ | async) === yaadGvia[0].yy">
										<tr *ngFor="let month of expandedYearlyViewDisplay$ | async">
											<td class="report-table__table-body-name">{{ month.mm | returnMonthHebrew : true }}</td>
											<td class="report-table__table-body-name">{{ month.tothov | number : '1.0-2' }}</td>
											<td class="report-table__table-body-name">{{ month.gviathismonth | number : '1.0-2' }}</td>
											<td class="report-table__table-body-name">{{ month.ahuzgvia ? (month.ahuzgvia | number : '1.0-2') + '%' : 0 }}</td>
										</tr>
									</ng-container>
								</ng-container>
								<ng-container [ngTemplateOutlet]="totalsBlock"></ng-container>
							</tbody>
						</table> -->
						<table class="report-table__table" cellspacing="0" *ngIf="secondPage$ | async">
							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name" style="width: 150px">סוכן</th>
									<th class="report-table__table-head-name" style="width: 150px">יעד גביה</th>
									<th class="report-table__table-head-name" style="width: 150px">גביה בפועל</th>
									<th class="report-table__table-head-name" style="width: 150px">עמידה ביעד</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let yaad of yaadGvia" (click)="moveToThirdPageAdmin({ id: yaad.agentid, name: yaad.agentname })">
									<td class="report-table__table-body-name">{{ yaad.agentid }} - {{ yaad.agentname }}</td>
									<td class="report-table__table-body-name">{{ yaad.tothov | number : '1.0-2' }}</td>
									<td class="report-table__table-body-name">{{ yaad.gviathismonth | number : '1.0-2' }}</td>
									<td class="report-table__table-body-name">
										<span [ngClass]="ngClassColorForRow(yaad)" class="ahuz-yaad">{{ yaad.ahuzgvia ? (yaad.ahuzgvia | number : '1.0-2') + '%' : 0 }}</span>
									</td>
								</tr>

								<ng-container [ngTemplateOutlet]="totalsBlock"></ng-container>
							</tbody>
						</table>
						<table class="report-table__table" cellspacing="0" *ngIf="thirdPage$ | async">
							<thead class="report-table__table-head">
								<tr class="clickable">
									<th class="report-table__table-head-name" style="width: 100px">חודש</th>
									<th class="report-table__table-head-name" style="width: 150px">יעד גביה</th>
									<th class="report-table__table-head-name" style="width: 150px">גביה בפועל</th>
									<th class="report-table__table-head-name" style="width: 100px">עמידה ביעד</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr *ngFor="let yaad of yaadGvia; index as i" class="clickable" (click)="moveToFourthPage(yaad.mm)">
									<td class="report-table__table-body-name">{{ yaad.mm | returnMonthHebrew : true }}</td>
									<td class="report-table__table-body-name">{{ yaad.tothov | number : '1.0-2' }}</td>
									<td class="report-table__table-body-name">{{ yaad.gviathismonth | number : '1.0-2' }}</td>
									<td class="report-table__table-body-name">{{ yaad.ahuzgvia ? (yaad.ahuzgvia | number : '1.0-2') + '%' : 0 }}</td>
								</tr>

								<ng-container [ngTemplateOutlet]="totalsBlock" *ngIf="isAdmin"></ng-container>
							</tbody>
						</table>
						<table class="report-table__table" cellspacing="0" *ngIf="fourthPage$ | async">
							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name" style="width: 150px">לקוח</th>
									<th class="report-table__table-head-name" style="width: 150px">מספר</th>
									<th class="report-table__table-head-name" style="width: 150px">סה"כ</th>
									<th class="report-table__table-head-name" style="width: 150px">תאריך</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr
									*ngFor="let kabala of kabalaDisplay$ | async"
									(click)="moveToFifthPage(kabala.Docnumber)"
									class="clickable"
									[ngStyle]="{ color: kabala.ignorecalcbizua === '1' ? 'red' : null }"
								>
									<td class="report-table__table-body-name">{{ kabala.ClientID }}</td>
									<td class="report-table__table-body-name">{{ kabala.Docnumber }}</td>
									<td class="report-table__table-body-name">{{ kabala.tftal | number : '1.0-2' }}</td>
									<td class="report-table__table-body-name">{{ kabala.DocDate | date : 'dd/MM/yy' : 'GMT' }}</td>
								</tr>
							</tbody>
						</table>
						<table class="report-table__table" cellspacing="0" *ngIf="fifthPage$ | async">
							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name" style="width: 110px">מספר צ'ק</th>
									<th class="report-table__table-head-name" style="width: 70px">בנק</th>
									<th class="report-table__table-head-name" style="width: 80px">סניף</th>
									<th class="report-table__table-head-name" style="width: 110px">חשבון</th>
									<th class="report-table__table-head-name" style="width: 110px">תאריך צ'ק</th>
									<th class="report-table__table-head-name" style="width: 150px">סכום</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr *ngFor="let kabala of kabalaDisplay$ | async" [ngStyle]="{ color: kabala.ignorecalcbizua === '1' ? 'red' : null }">
									<ng-container *ngIf="kabala.Sug === 1; else kabalaDetailsChequeBlock">
										<td class="report-table__table-body-name" colspan="4" style="text-align: center; font-weight: 600;">מזומן</td>
									</ng-container>
									<ng-template #kabalaDetailsChequeBlock>
										<td class="report-table__table-body-name">{{ kabala.CheqNumber }}</td>
										<td class="report-table__table-body-name">{{ kabala.BankNumber }}</td>
										<td class="report-table__table-body-name">{{ kabala.Branch }}</td>
										<td class="report-table__table-body-name">{{ kabala.BankAccNum }}</td>
									</ng-template>
									<td class="report-table__table-body-name">{{ kabala.ValueDate | date : 'dd/MM/yy' : 'GMT' }}</td>
									<td class="report-table__table-body-name">{{ kabala.SuF | number : '1.0-2' }}</td>
								</tr>
							</tbody>
						</table>
						<ng-template #totalsBlock>
							<tr>
								<td class="report-table__table-body-name">סה"כ</td>
								<td class="report-table__table-body-name">{{ totals.tothov$ | async | number : '1.0-2' }}</td>
								<td class="report-table__table-body-name">{{ totals.gviathismonth$ | async | number : '1.0-2' }}</td>
								<td class="report-table__table-body-name">{{ totals.ahuzgvia$ | async | number : '1.0-2' }}%</td>
							</tr>
						</ng-template>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
</section>
