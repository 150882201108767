<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToAdmin()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">דו"ח מכירות מוצרים</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="Second === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
		</div>
	</div>

	<div class="report-table__row" *ngIf="First === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchProducts()">
				<input class="header__search-input" type="text" placeholder=" חפש מוצר" formControlName="ProductSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="ClearProductsSearch()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" style="margin-top: 100px">
	<h3 style="text-align: center" *ngIf="Second === true || Third === true">פריט: {{ this.ProductNameChoosen }}</h3>
	<h3 style="text-align: center" *ngIf="Third === true">לקוח: {{ this.ClientNameChoosen }}</h3>
	<div class="content">
		<div class="report-table__row report-table__row_between"></div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px" *ngIf="First === true">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('ProductId', 'AllProducts')" style="width: 150px">מס מוצר</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('Product_Name', 'AllProducts')" style="width: 250px">שם מוצר</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('ItemBarcode', 'AllProducts')" style="width: 150px">ברקוד</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ap of AllProducts" (click)="GetNumPfTimeAllClientsPerProduct(ap.ProductId, ap.Product_Name)">
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.ProductId }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.Product_Name }}</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ ap.ItemBarcode }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px" *ngIf="Second === true">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">קוד לקוח</th>
						<th class="report-table__table-head-name" style="width: 150px">שם לקוח</th>
						<th class="report-table__table-head-name" style="width: 150px">מספר פעמים שנקנה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ap of AllClients" (click)="GetProductTeuda(ap.ClientId, ap.Client_Name)">
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.ClientId }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.Client_Name }}</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ ap.numoftimes }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px" *ngIf="Third === true">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">תאריך</th>
						<th class="report-table__table-head-name" style="width: 150px">יח</th>
						<th class="report-table__table-head-name" style="width: 150px">אר</th>
						<th class="report-table__table-head-name" style="width: 150px">מחיר</th>
						<th class="report-table__table-head-name" style="width: 150px">הנחה</th>
						<th class="report-table__table-head-name" style="width: 150px">מס תעודה</th>
						<th class="report-table__table-head-name" style="width: 150px">סוג תעודה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ap of ProductTeuda">
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ ap.date1 }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.qty }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.pack }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.prc }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.dsc }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.tn }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ap.dn }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
