import { Injectable } from '@angular/core';
import { compress, decompress } from 'lz-string';
import { StoreService } from './store.service';

export type CompaniesNumber = 1 | 2 | 3 | 4;

export interface MinDocTeuda extends MinDocTeudaBase {
	company: CompaniesNumber;
	agentid: string;
	doctype: number;
}

export type MinDocTeudaBase = {
	minteuda: number;
	whataction: typeof whataction[keyof typeof whataction];
	clientid: string;
};

export type MinDocTeudaBaseWithAction = MinDocTeudaBase & { whataction: typeof whataction[keyof Omit<typeof whataction, 'noAction'>] };
export type MinDocTeudaBaseWithActionAndDoctype = MinDocTeudaBaseWithAction & { doctype: number };

export type MinDocTeudaRecord = Partial<Record<CompaniesNumber, Record<number, { clientid: string; whataction: typeof whataction[keyof typeof whataction]; minteuda: number }[]>>>;

export const whataction = {
	noAction: 0,
	block: 1,
	confirm: 2,
} as const;

@Injectable({
	providedIn: 'root',
})
export class DocMinTeudaService {
	constructor(private _ss: StoreService) {
		this.init();
	}

	minDocTeudaRecord: MinDocTeudaRecord = {};
	minDocTeudaArray: MinDocTeuda[] = [];

	hasMinDocTeuda = false;

	init() {
		if (!this._ss.CustomerInformation) return;
		this.hasMinDocTeuda = this.shouldBotherChecking();
		if (!this.hasMinDocTeuda) return;
		this.minDocTeudaRecord = this.buildMinDocTeudaRecord(this.getMinDocTeuadaFromStorage());
	}

	refresh() {
		this.hasMinDocTeuda = false;
		this.init();
	}

	private shouldBotherChecking() {
		return this._ss.CustomerInformation?.HasDocMinTeuda === '1';
	}

	getRelevantDocMinTeuda(company: CompaniesNumber, doctype: number, clientid: string): MinDocTeudaBaseWithAction[] {
		if (!Object.keys(this.minDocTeudaRecord).length) {
			this.init();
		}

		const relevantDocMinTeuda = this.minDocTeudaRecord[company]?.[doctype];

		if (!relevantDocMinTeuda?.length) return [];

		const withAction = relevantDocMinTeuda.filter((docMinTeuda): docMinTeuda is MinDocTeudaBaseWithAction => docMinTeuda.whataction !== whataction.noAction);

		if (!withAction.length) return [];

		const forClient = withAction.filter(docMinTeuda => docMinTeuda.clientid === clientid);

		if (forClient.length) return forClient;

		return withAction;
	}

	getRelevantDocMinTeudaWithoutDoctype(company: CompaniesNumber, clientid: string): MinDocTeudaBaseWithActionAndDoctype[] {
		if (!Object.keys(this.minDocTeudaRecord).length) {
			this.init();
		}

		const relevantDocMinTeuda = this.minDocTeudaRecord[company];

		if (!relevantDocMinTeuda) return [];

		const withAction = Object.entries(relevantDocMinTeuda)
			.filter(([_, docMinTeuda]) => docMinTeuda.some(doc => doc.whataction !== whataction.noAction))
			.reduce((p, [doctype, docMinTeuda]: [string, MinDocTeudaBaseWithAction[]]) => {
				p.push(...docMinTeuda.map(d => ({ ...d, doctype: Number(doctype) })));
				return p;
			}, [] as MinDocTeudaBaseWithActionAndDoctype[]);

		if (!withAction.length) return [];

		const forClient = withAction.filter(docMinTeuda => docMinTeuda.clientid === clientid);

		if (forClient.length) return forClient;

		return withAction;
	}

	getMinDocTeuadaFromStorage(): MinDocTeuda[] {
		if (!this.shouldBotherChecking()) return [];

		const minDocTeudaLocalStorage = this.getFromLocalStorage();

		if (!minDocTeudaLocalStorage) return [];

		return JSON.parse(decompress(minDocTeudaLocalStorage));
	}

	private buildMinDocTeudaRecord(minDocTeuda: MinDocTeuda[]) {
		return minDocTeuda.reduce((p, c) => {
			const { minteuda, whataction, clientid, doctype, company } = c;
			p[company] ||= {};
			p[company][doctype] ||= [];
			p[company][doctype].push({ clientid, whataction, minteuda });
			return p;
		}, {} as MinDocTeudaRecord);
	}

	handleDocMinTeudaActions(docArray: MinDocTeudaBaseWithAction[], totalorder: number): boolean {
		if (!this.hasMinDocTeuda) return true;
		const docmin = docArray.find(doc => doc.minteuda > totalorder);

		if (!docmin) return true;

		switch (docmin.whataction) {
			case whataction.block:
				alert('סכום התעודה נמוך מהמינימום הנדרש. יש להגיע לפחות לסכום ' + docmin.minteuda);
				return false;
			case whataction.confirm:
				const decision = confirm('סכום התעודה נמוך מהמינימום הנדרש. האם להמשיך בכל זאת?');
				if (!decision) {
					return false;
				}
				break;

			default:
				break;
		}

		return true;
	}

	storeMinDocTeuda(minDocTeuda: MinDocTeuda[]) {
		localStorage.setItem('MinDocTeuda', compress(JSON.stringify(minDocTeuda)));
	}

	getFromLocalStorage() {
		return localStorage.getItem('MinDocTeuda');
	}
}
