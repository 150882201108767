import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'unformatPhoneNumber',
	pure: true,
})
export class UnformatPhoneNumberPipe implements PipeTransform {
	transform(value: string | null | number): string | void {
		if (!value) return;
		let returnValue = String(value).replace(/\D/gm, '').split('');
		if (returnValue[0] === '0') returnValue.shift();
		returnValue.unshift('972');
		return returnValue.join('');
	}
}
