import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TruckDistributionService } from '../services/truck-distribution.service';

@Injectable({
	providedIn: 'root',
})
export class CanLeavePageCustomGuard implements CanDeactivate<unknown> {
	constructor(private _router: Router, private _truckDistribution: TruckDistributionService) {}
	canDeactivate(
		component: unknown,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this._truckDistribution.canLeaveCurrentPage) {
			this._router.navigate([currentState.url.slice(1)]);

			return false;
		}

		return true;
	}
}
