<div class="popup__signature-button" *ngIf="useSignature" (click)="toggleSignaturePopupAndPrepareSignatureCanvas('open')">
	<img src="/assets/images/icons8-signature-96.png" alt="signature" />
	<img src="/assets/images/icons8-approval-60.png" *ngIf="signatureFinalized" alt="signature" />
</div>
<div class="pdf-viewer-controls-line">
	<button mat-flat-button (click)="incrementZoom(0.1)">
		<mat-icon>zoom_in</mat-icon>
	</button>
	<button mat-flat-button (click)="incrementZoom(-0.1)">
		<mat-icon>zoom_out</mat-icon>
	</button>
</div>

<div class="popup__signature-fullscreen" *ngIf="isSignaturePopupOpen">
	<div class="popup__signature-fullscreen__wrapper">
		<canvas id="signature" (mousedown)="signatureOK = true" (pointerdown)="signatureOK = true"></canvas>
		<button class="order-step__block-button" (click)="finalizeSignature()" [ngClass]="{ 'order-step__block-button_green': signatureOK }">אשר חתימה</button>
		<button class="order-step__block-button order-step__block-button_red" (click)="toggleSignaturePopupAndPrepareSignatureCanvas('close')">סגור</button>
	</div>
</div>
<pdf-viewer
	*ngIf="(finalPDFsrc$ | async)?.length; else noPDFBlock"
	[src]="finalPDFsrc$ | async"
	[(page)]="page"
	[rotation]="rotation"
	[original-size]="originalSize"
	[show-all]="showAll"
	[fit-to-page]="fitToPage"
	(after-load-complete)="afterLoadComplete($event)"
	[zoom]="zoom"
	[zoom-scale]="zoomScale"
	[stick-to-page]="stickToPage"
	[render-text]="renderText"
	[external-link-target]="'blank'"
	[autoresize]="autoresize"
	(error)="onError($event)"
	(on-progress)="onProgress($event)"
	(page-rendered)="pageRendered($event)"
	(pages-initialized)="pageInitialized($event)"
	(pageChange)="pageChange($event)"
	[show-borders]="true"
	style="margin-top: 80px; height: calc(100vh - 80px)"
>
</pdf-viewer>

<ng-container *ngIf="!isLoaded && (finalPDFsrc$ | async)?.length">
	<div class="no-content">
		<!-- <h1 class="text-align-center rtl no-content-text" >טוען...</h1>
		-->
		<mat-spinner color="primary"></mat-spinner>
	</div>
</ng-container>

<ng-template #noPDFBlock>
	<div class="no-content">
		<h1 class="text-align-center no-content-text">לא קיים מסמך עבור תעודה זו</h1>
	</div>
</ng-template>
