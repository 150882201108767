import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReceiptsService } from 'src/app/services/receipts.service';

@Component({
	selector: 'app-error-message-with-truck-gif',
	// templateUrl: './error-message-with-truck-gif.component.html',
	template: `
		<div>
			<h2 class="product-detail-add__popup-title">קרתה תקלה בעת שידור עסקת אשראי</h2>

			<h3 class="title">הזמנתך נשמרה אך לא ניתנת לשליחה שוב</h3>
			<h3 class="title">נא לפנות למשרד לבדיקה</h3>
			<h4 class="title">מזהה עסקה: {{ _receipts.creditFailureIdentifiers.transactionID }}</h4>
			<h4 class="title">מספר הזמנה: {{ _receipts.creditFailureIdentifiers.teudaNum }}</h4>

			<div class="auth__help-image image">
				<img src="/assets/images/basket/process/error.gif" alt="photo" />
			</div>
			<div class="product-detail-add__popup-row">
				<button class="product-detail-add__popup-button" (click)="close()">קיבלתי</button>
			</div>
			<div class="product-detail-add__popup-row"></div>
		</div>
	`,
	// styleUrls: ['./error-message-with-truck-gif.component.scss'],
	styles: [
		`
			.product-detail-add__popup {
				&-title {
					width: 100%;
					padding: 20px 0;
					text-align: center;
					color: #212121;
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px;
					letter-spacing: 0;
				}

				&-button {
					display: block;
					width: 150px;
					height: 44px;
					margin: 20px auto;
					border: none;
					border-radius: 40px;
					box-shadow: 1px 4px 14px var(--main-color-alpha);
					color: #ffffff;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					letter-spacing: 0;
					background-color: var(--main-color);
					cursor: pointer;
				}

				&__row {
					&.row {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
					}
				}
			}

			.order-process {
				&__image {
					max-width: 360px;
					margin-bottom: 30px;
				}
			}

			.title {
				text-align: center;
			}
		`,
	],
})
export class ErrorMessageWithTruckGifComponent {
	constructor(public _receipts: ReceiptsService) {}

	@Output() closeEvent = new EventEmitter();

	close() {
		this.closeEvent.emit();
	}
}
