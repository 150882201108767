<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToHome()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">דו"ח יומי</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start markup for client profile page -->
		<!-- client info for all client profile page -->
		<section class="client-info" style="margin-top: 100px">
			<div class="content">
				<div class="row flex rtl">
					<div class="col">
						<div class="client-info__title">
							{{ this._ss.UserNameConnected }}
						</div>
						<div class="client-info__number">
							{{ this._ss.AgentIdConnected }}
						</div>
					</div>
					<div class="client-info__block col">
						<div class="client-info__number col client-info__title_margin">
							בחר תאריך
							<form [formGroup]="form" (change)="DateChange()" style="font-size: 20px">
								<input type="date" formControlName="Date" style="height: 20px; font-size: 18px" />
							</form>
						</div>
					</div>
				</div>
				<div class="row rtl flex">
					<div class="col flex justify-align-center" (click)="AskRefresh()">
						<div class="client-info__certificate">
							<h3 class="client-info__certificate-text"><u>עדכן דו"ח יומי</u></h3>
							<h4 class="client-info__certificate-text" *ngIf="canSeePrice">סה"כ : {{ this.TotalDay | number : '1.0-2' : 'en-US' }}</h4>
						</div>
					</div>
					<div class="client-info__certificate col">
						<div class="grid padding-1">
							<label for="doctype">לפי תעודה</label>
							<select name="doctype" id="doctype" (change)="filterByDocType($event)" [formControl]="docTypeControl">
								<option *ngFor="let doc of doctypeNamesShrinked" [value]="doc.DocType">{{ doc.DocName }}</option>
							</select>
						</div>
						<div class="grid padding-1" *ngIf="isMultiCompany">
							<label for="doctype">לפי חברה</label>
							<select name="doctype" id="doctype" (change)="filterByCompany($event)" [formControl]="companyControl">
								<option *ngFor="let doc of companies" [value]="doc.Company">{{ doc.CompanyName }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- start markup for client profile order page -->
		<section class="client-order" *ngIf="Loading === false">
			<div class="content">
				<div class="client-order__item row" *ngFor="let t of Teudot">
					<div class="client-order__item-status client-order__item-status_green" id="Status{{ t.TeudaNum }}" *ngIf="t.Status === 1">טופל</div>
					<div class="client-order__item-status client-order__item-status_blue" *ngIf="t.Status === 11">ממתין להעברה לERP</div>
					<div class="client-order__item-status client-order__item-status_blue" *ngIf="t.Status === 0">בטיפול</div>
					<!-- <div class="client-order__item-status client-order__item-status_green">בטיפול</div> -->
					<div class="client-order__item-row row">
						<span class="client-order__item-name client-order__item-name_bold">לקוח -</span>
						<span class="client-order__item-name client-order__item-name__max-width">{{ t.Client_Name }}</span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-name client-order__item-name_bold">אסמכתא</span>
						<span class="client-order__item-name">{{ t.TuedaErpDocNum }}#</span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-title">תאריך:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl"
							>{{ t.TeudaDate.toString().slice(8, 10) }}-{{ t.TeudaDate.toString().slice(5, 7) }}-{{ t.TeudaDate.toString().slice(0, 4) }}</span
						>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-title">שעה:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ t.TeudaDate | returnTimeFormatted }}</span>
					</div>
					<div class="client-order__item-row row" *ngIf="t.Docname">
						<span class="client-order__item-title">סוג תעודה:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl" [ngStyle]="{ color: t.TeudaType === 7 ? 'red' : null }">{{ t.Docname }}</span>
					</div>
					<div class="client-order__item-row row" *ngIf="isMultiCompany">
						<span class="client-order__item-title">חברה:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ t.CompanyName }}</span>
					</div>
					<div class="client-order__item-row row" *ngIf="t.TeudaRemark">
						<span class="client-order__item-title">הערה:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ t.TeudaRemark }}</span>
					</div>
					<div class="client-order__item-row client-order__item-row_summ row">
						<ng-container  *ngIf="canSeePrice">
							<div class="client-order__item-col client-order__item-col_border col">
								<span class="client-order__item-title">סכום:</span>
								<span class="client-order__item-text client-order__item-text_big client-order__item-text_price">{{ t.TeudaTotalSum | number : '1.0-2' : 'en-US' }}</span>
							</div>
						</ng-container>
						<div class="client-order__item-col col">
							<span class="client-order__item-title">מספר שורות:</span>
							<span class="client-order__item-text client-order__item-text_big">{{ t.TotalLines }}</span>
						</div>
						<div class="client-order__item-col col">
							<button class="client-order__item-button" (click)="MoveToTeudaEach(t.TeudaNum, t.Status, t.Company)" *ngIf="Loading === false">הצג תעודה</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- end markup for client profile order page -->

		<!-- end markup for client profile order page -->
		<div class="order-process__row order-process__row_success" *ngIf="Loading === true">
			<div class="row">
				<h2 class="order-process__title">מעדכן דו"ח יומי</h2>
			</div>
			<div class="row"></div>
			<div class="row">
				<div class="order-process__image image">
					<img src="/assets/images/basket/process/success.gif" alt="photo" />
				</div>
			</div>
			<div class="row"></div>
			<!-- <div class="row">
  <div class="order-process__text">הצג את {{this._ss.DocTypeName}}</div>
</div> -->
		</div>

		<!-- markup for popup -->
		<div class="popup" *ngIf="UpdateSystem === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="NoUpdate()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">לעדכן תעודות ?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoUpdate()">לא, חזור</button>
					<button class="order-cart__popup-button" (click)="Refresh()">כן, עדכן</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->
	</div>
</div>
