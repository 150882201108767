import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { compress, decompress } from 'lz-string';
import { Observable, Subscription, Subject, BehaviorSubject, fromEvent, takeUntil, map, startWith, combineLatest, delay } from 'rxjs';
import { LikutSubSQL, LikutKabalaSubSQL, LStatuses } from 'src/app/Interfaces/likut';
import { AllProducts } from 'src/app/Interfaces/SQL-morning-responses';
import { TruckDistributionSubOpened, TruckDistributionSubSQL } from 'src/app/Interfaces/truck-distribution';
import { TruckLoadingSubOpened, TruckLoadingSubSQL } from 'src/app/Interfaces/truck-loading';
import { AdminTypes } from 'src/app/Interfaces/users';
import { AlertService } from 'src/app/services/alert.service';
import { IndexedDBService } from 'src/app/services/indexed-db.service';
import { LikutService } from 'src/app/services/likut.service';
import { StoreService } from 'src/app/services/store.service';
import { TextService } from 'src/app/services/text.service';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { TruckLoadingService } from 'src/app/services/truck-loading.service';
import { UtilService } from 'src/app/services/util.service';
import { MAX_DISCOUNT, MAX_PRODUCT_PRICE } from 'src/app/Utility/constants-products';
import { formatNumberToMaxPrecision, genericSearchingFunction, normalizeNumber } from 'src/app/Utility/functions';
import { environment } from 'src/environments/environment';
import { SubAppModes } from '../likut-clients/likut-clients.component';
// import { MAX_DISCOUNT, MAX_PRODUCT_PRICE } from '../products/products.component';

type Opened = { opened?: boolean };

type ProductDisplayBase = { product?: AllProducts; matchesSearch?: boolean };
type ProductDisplayMode = { truckTask: (TruckLoadingSubOpened | TruckDistributionSubOpened) & Opened };

export type ProductDisplay = ProductDisplayBase & ProductDisplayMode;

export type ProductDisplayLikutOrTruck = ProductDisplay | LikutProductDisplayNewRoute;

export type LikutProductDisplayNewRoute = ProductDisplayBase & { likut: LikutSubSQL; opened?: boolean; mismatchedQuantitiesToShowChecker?: boolean };

type LikutMekabelDisplay = { likut: LikutKabalaSubSQL; opened?: boolean; matchesSearch?: boolean };

@Component({
	selector: 'app-likut-task',
	templateUrl: './likut-task.component.html',
	styleUrls: ['./likut-task.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LikutTaskComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		private _router: Router,
		private _fb: FormBuilder,
		public _likut: LikutService,
		private _alert: AlertService,
		private _snackbar: MatSnackBar,
		private _change: ChangeDetectorRef,
		public _text: TextService,
		public _truckLoading: TruckLoadingService,
		public _truckDistribution: TruckDistributionService,
		private _util: UtilService,
		private _idb: IndexedDBService,
		private _acr: ActivatedRoute,
	) {}

	productSearch = new FormControl('', { nonNullable: true });

	form = this._fb.group({
		collected: [<number>null],
		collectedPacks: [<number>null],
		price: [<number>null],
		discount: [<number>null],
		eitur: new FormControl('', { nonNullable: true }),
		remark: new FormControl('', { nonNullable: true }),
	});
	allProducts: Record<string, AllProducts> = {};
	AllOrder = [];
	Admin: AdminTypes | null = localStorage.getItem('Admin') as AdminTypes | null;

	checkboxState: { [productAndTeudaNum: string]: boolean } = {};
	validQuantitiesState: { [productAndTeudaNum: string]: boolean } = {};

	clientName: string;

	productDisplay$ = new BehaviorSubject<ProductDisplay[]>([]);
	isCheckerReturnPopupOpen$ = new BehaviorSubject(false);
	isMelaketReleasePopupOpen$ = new BehaviorSubject(false);

	pageMode$ = new BehaviorSubject<SubAppModes>(location.pathname.split('/')[1] as SubAppModes);
	likutProductDisplayNewRoutes$ = new BehaviorSubject<LikutProductDisplayNewRoute[]>([]);
	isTrucks$ = this.pageMode$.pipe(map(page => page.includes('truck')));
	isEditQuantityPopupShown$ = new BehaviorSubject(false);
	isNormalPopupShown$ = this.isEditQuantityPopupShown$.pipe(map(value => value && !this.isLikutMekabel));
	isLikutMekabelPopupShown$ = this.isEditQuantityPopupShown$.pipe(map(value => value && this.isLikutMekabel));

	totalHandled$ = combineLatest([this.likutProductDisplayNewRoutes$, this.isEditQuantityPopupShown$]).pipe(
		delay(0),
		map(([products]) => {
			if (this._likut.isCheckerAgent) {
				return Object.values(this.checkboxState).filter(value => value).length;
			}
			return products.filter(product => product.likut.LikutQty > 0 || product.likut.LikutPackQty > 0).length;
		}),
	);

	likutMekabelDisplay$ = new BehaviorSubject<LikutMekabelDisplay[]>([]);

	totalShown$ = new BehaviorSubject(0);

	windowVisibilityObservable$: Observable<Event>;
	windowVisibilitySubscription$: Subscription;

	private destroy$ = new Subject<void>();

	windowWidth = innerWidth;

	isBarcodePopupOpen$ = new BehaviorSubject(false);
	supportsVideo = true;
	@ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
	video: HTMLVideoElement;
	mediaStream: MediaStream;
	detectedCode = '';
	intervalID;

	currentlyEdittedTask: ProductDisplayLikutOrTruck;
	currentlyEdittedTaskLikutMekabel: LikutMekabelDisplay;

	isLocal = !environment.production;

	isLikut = location.pathname.includes('likut');
	isLikutMekabel = location.pathname.includes('mekabel');
	isTruckLoading = location.pathname.includes('truck-loading');
	isTruckDistribution = location.pathname.includes('truck-distribution');
	isNewTask = this._acr.snapshot.queryParams['new'] === 'true';

	isAddNewProductForMekabelPopupOpen$ = new BehaviorSubject(false);
	addProductFormControl = new FormControl('', { nonNullable: true });
	addProductSearch$ = new Observable<AllProducts[]>();
	allProductsForAddNewProductPopup: AllProducts[] = [];
	selectedProductForAddNewProductPopup$ = new BehaviorSubject<AllProducts>(null);
	productsAlreadyAddedSet = new Set<string>();

	productToBeDeleted$ = new BehaviorSubject<LikutMekabelDisplay | null>(null);
	melaketProductToBeDeleted$ = new BehaviorSubject<LikutProductDisplayNewRoute | null>(null);
	isDeleteProductPopupShown$ = combineLatest([this.productToBeDeleted$, this.melaketProductToBeDeleted$]).pipe(map(values => values.some(value => !!value)));

	// isDeleteMelaketProductPopupShown$ = this.melaketProductToBeDeleted$.pipe(map(value => !!value));

	taskID = new Date().getTime() + '000' + this._ss.AgentIdConnected;

	private canGoAboveOriginalQuantity = this._likut.canGoOverQuantities || this.isNewTask;

	// Template Variables
	inlineItemSize = this.windowWidth > 767 && this.windowWidth < 1099 ? 134 * 1.2 : 134; // is tablet zoom css on, or pc putting 2 inline in 1 row.
	isMobile = this.windowWidth < 768;
	worksInBothSinglesAndPackQuantity = true;
	worksInSinglesQuantity = false;
	worksInPackQuantity = false;
	showLikutExtra1InParenthesis = this._likut.showLikutExtra1InParenthesis;
	dontShowParenthesisAtAll = this._likut.dontShowParenthesisAtAll;
	canSeeTeudaPrice = this._likut.canSeeTeudaPrice;
	canAddNewItemsAsMelaket = this._likut.isMelaketAgentAndCanAddNewItems;
	teudaName = this._text.store.teudaName;
	refreshStockOnEntryAndDisplay = this._likut.refreshStockOnTaskEntry;
	showBarcodeFromLikut = this._likut.getBarcodeFromSubTable;
	showEitur: boolean | null = null;

	// StoreService Locals
	CustImageDomain: string = this._ss.CustomerInformation?.CustImageDomain;
	LikutType: string = this._ss.CustomerInformation?.LikutType;
	LStatuses = LStatuses;

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	@ViewChild('collected') collectedInput: ElementRef<HTMLInputElement>;
	@ViewChild('collectedPacks') collectedPacksInput: ElementRef<HTMLInputElement>;
	@ViewChild('price') priceInput: ElementRef<HTMLInputElement>;
	@ViewChild('discount') discountInput: ElementRef<HTMLInputElement>;
	async ngOnInit() {
		let products: any = localStorage.getItem('AllProducts') || '';

		products = JSON.parse(decompress(products));

		if (!products || !this.Admin) {
			try {
				if (!this.isTruckDistribution) {
					throw '';
				}

				products = await this._idb.getAllProductsDB();

				if (!products?.length) {
					throw '';
				}

				products = products[0];
			} catch (error) {
				localStorage.setItem('Updating', 'true');

				localStorage.removeItem('refresh');
				alert(this._text.store.missingStoredData);
				this._router.navigate(['home'], { replaceUrl: true });
				return;
			}
		}

		this.windowVisibilityObservable$ = fromEvent(window, 'visibilitychange');
		this.windowVisibilitySubscription$ = this.windowVisibilityObservable$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const ls = localStorage.getItem('lastupdate');
			if (ls === null) {
				this._router.navigate(['home']);
				return;
			}

			const Datetwo = JSON.parse(ls);
			const date = new Date().toISOString().slice(0, 10);

			if (Datetwo.toString().slice(0, 10) !== date) {
				this._router.navigate(['home']);
			}
		});

		window.scrollTo(0, 0);

		this._likut.refreshAllAdminTypes();
		this._truckLoading.refreshAllAdminTypes();

		this.resolveQuantityTypesParametersFromCustomerInformation();

		if (this.isLikut) {
			this.populateCheckboxState(this.isLikutMekabel ? 'likutMekabel' : 'likutNewRoutes');
		}

		// if (this.isTruckDistribution) {
		// 	this.getOrSetDisplayTask('get');
		// }

		this.populateValidQuantitiesState();

		this.handleSettingUpClientNameAndOtherText();

		this.allProducts = (products as AllProducts[]).reduce((p, c) => {
			delete c.Stock;
			p[c.ProductId] = c;
			return p;
		}, {});

		this.handleForgetOriginalStock();

		await this.handleBuildStockRecord(products);

		if (this.isNewTask || this.canAddNewItemsAsMelaket) {
			this.allProductsForAddNewProductPopup = products;
		}

		console.log(products);

		this.handleUsingRefresh();

		this.setupFormInteractivity();

		this.totalShown$.next(this.productDisplay$.value.length || this.likutProductDisplayNewRoutes$.value.length || this.likutMekabelDisplay$.value.length);

		if (this._likut.isMelaketAgentAndCanAddNewItems) {
			this.productsAlreadyAddedSet = new Set(this._likut.likutOpenedNewRoutesState.sub.map(sub => sub.ProductId));
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();

		if (this.mediaStream) {
			for (const track of this.mediaStream.getTracks()) {
				this.mediaStream.removeTrack(track);
			}
		}
		if (this.video) {
			this.video.srcObject = null;
			this.video.pause();
		}

		this.normalizeCurrentlyEdittedTaskNumbers();

		clearInterval(this.intervalID);
	}

	handleSettingUpClientNameAndOtherText() {
		switch (location.pathname.split('/')[1] as SubAppModes) {
			case 'likut':
				if (this.isLikutMekabel) {
					this.clientName = this._likut.likutMekabelOpenedState.main.clientName;
					break;
				}
				this.clientName = this._likut.likutOpenedNewRoutesState.task.clientName;
				break;
			case 'truck-loading':
				this.clientName = this._truckLoading.truckLoadOpened.task.clientName;
				if (this._truckLoading.truckLoadOpened.task.groupB) {
					this.teudaName = this._text.store.teudaNameGroupB;
				}
				break;
			case 'truck-distribution':
				this.clientName = this._truckDistribution.truckDistributionOpened.task.clientName;
				if (this._truckDistribution.truckDistributionOpened.task.groupB) {
					this.teudaName = this._text.store.teudaNameGroupB;
				}
				break;

			default:
				break;
		}
	}

	resolveQuantityTypesParametersFromCustomerInformation() {
		if (this.isLikut) {
			this.worksInBothSinglesAndPackQuantity = this._ss.CustomerInformation.LikutType === '0';
			this.worksInSinglesQuantity = this._ss.CustomerInformation.LikutType === '1';
			this.worksInPackQuantity = this._ss.CustomerInformation.LikutType === '2';
			if (this.isNewTask) {
				this.worksInBothSinglesAndPackQuantity = true;
				this.worksInSinglesQuantity = false;
				this.worksInPackQuantity = false;
				this.LikutType = '0';
			}
		}
		if (this.isTruckDistribution || this.isTruckLoading) {
			this.worksInBothSinglesAndPackQuantity = this._ss.CustomerInformation?.LoadingAndDistribution_Quantities === '0';
			this.worksInSinglesQuantity = this._ss.CustomerInformation?.LoadingAndDistribution_Quantities === '1';
			this.worksInPackQuantity = this._ss.CustomerInformation?.LoadingAndDistribution_Quantities === '2';
		}
	}

	async handleBuildStockRecord(products: AllProducts[]) {
		if (!this.refreshStockOnEntryAndDisplay || !this.isLikut) {
			return;
		}

		await new Promise<void>(resolve => {
			this._ss
				.RefreshMlayServiceDefault1()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string' || !res.recordset?.length) {
							return resolve();
						}

						const stockRecord = res.recordset.reduce((p, c) => {
							p[c.it] = c;
							return p;
						}, {} as Record<string, { it: string; mly: number }>);

						for (const product of products) {
							if (stockRecord[product.ProductId]) {
								product.Stock = stockRecord[product.ProductId].mly || 0;
							}
						}

						for (const item of this._likut.likutOpenedNewRoutesState.sub) {
							if (stockRecord[item.ProductId]) {
								item.Stock = stockRecord[item.ProductId].mly || 0;
							}
						}

						for (const item of this._likut.likutMekabelOpenedState.main.rows) {
							if (stockRecord[item.ProductId]) {
								item.Stock = stockRecord[item.ProductId].mly || 0;
							}
						}

						resolve();
					},
					error: () => {
						resolve();
					},
				});
		});
	}

	handleUsingRefresh() {
		if (this.isLikut) {
			if (this._likut.isMekabel) {
				this.refreshLikutMekabelSubjects('allProductsDisplay');
				return;
			}
			this.refreshLikutNewRouteSubjects('allProductsDisplay');

			return;
		}

		if (this.isTruckLoading) {
			this.refreshSubjects('allProductsDisplay', 'truck-loading');

			return;
		}
		this.refreshSubjects('allProductsDisplay', 'truck-distribution');
	}

	refreshLikutMekabelSubjects(type: 'allProductsDisplay') {
		const productSearchValue: string = this.productSearch.value.toLowerCase();
		const len = productSearchValue.length;

		let allProducts: LikutMekabelDisplay[] = this._likut.likutMekabelOpenedState.main.rows.map(likut => ({ likut }));

		if (len) {
			const splitValue = productSearchValue.split(' ');

			allProducts = allProducts.map(display => {
				let counter = 0;

				const { ProductName, ProductId, Barcode } = display.likut;

				const prodNameWithoutSpaces = (ProductName || '').replace(/\s/g, '').toLowerCase(),
					productIdWithoutSpaces = (ProductId || '').replace(/\s/g, '').toLowerCase(),
					itemBarcodeWithoutSpaces = (Barcode || '').replace(/\s/g, '').toLowerCase(),
					allStrings = typeof prodNameWithoutSpaces === 'string' && typeof itemBarcodeWithoutSpaces === 'string' && typeof productIdWithoutSpaces === 'string';

				// V8 optimizes this if statement, maybe
				if (allStrings) {
					for (let j = 0; j < splitValue.length; j++) {
						if (
							(prodNameWithoutSpaces && prodNameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(productIdWithoutSpaces && productIdWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(itemBarcodeWithoutSpaces && itemBarcodeWithoutSpaces.indexOf(splitValue[j]) > -1)
						) {
							counter++;
						}
					}

					if (counter >= splitValue.length) {
						return { ...display, matchesSearch: true };
					}
				}
				return display;
			});
		}

		if (type === 'allProductsDisplay') {
			// if (this._likut.isCheckerAgent) {
			// 	for (const display of allProducts) {
			// 		display.mismatchedQuantitiesToShowChecker = display.likut.TeudaQty !== display.likut.TeudaQtyKabala || display.likut.TeudaPackQty !== display.likut.TeudaPackQtyKabala;
			// 	}
			// }

			this.likutMekabelDisplay$.next(allProducts);
			const isAnyMatch = allProducts.filter(likut => likut.matchesSearch);
			if (isAnyMatch.length) {
				this.virtualScroll.scrollToIndex(allProducts.findIndex(display => display.likut.ProductId === isAnyMatch[0].likut.ProductId));
			}
		}
	}
	refreshLikutNewRouteSubjects(type: 'allProductsDisplay') {
		const productSearchValue: string = this.productSearch.value.toLowerCase();
		const len = productSearchValue.length;

		let allProducts: LikutProductDisplayNewRoute[] = this._likut.likutOpenedNewRoutesState.sub.map(likut => ({ product: this.allProducts[likut.ProductId], likut }));

		if (this.showEitur === null) {
			this.showEitur = allProducts.some(product => product.likut.Eitur);
		}

		if (len) {
			const splitValue = productSearchValue.split(' ');

			allProducts = allProducts.map(display => {
				let counter = 0;

				if (!display.product) return display;

				const { Product_Name, ProductId, ItemBarcode, ItemExtra1 } = display.product;

				const prodNameWithoutSpaces = (Product_Name || '').replace(/\s/g, '').toLowerCase(),
					productIdWithoutSpaces = (ProductId || '').replace(/\s/g, '').toLowerCase(),
					itemBarcodeWithoutSpaces = (ItemBarcode || '').replace(/\s/g, '').toLowerCase(),
					itemExtra1WithoutSpaces = (ItemExtra1 || '').replace(/\s/g, '').toLowerCase(),
					allStrings =
						typeof prodNameWithoutSpaces === 'string' &&
						typeof itemBarcodeWithoutSpaces === 'string' &&
						typeof itemExtra1WithoutSpaces === 'string' &&
						typeof productIdWithoutSpaces === 'string';

				// V8 optimizes this if statement, maybe
				if (allStrings) {
					for (let j = 0; j < splitValue.length; j++) {
						if (
							(prodNameWithoutSpaces && prodNameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(productIdWithoutSpaces && productIdWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(itemBarcodeWithoutSpaces && itemBarcodeWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(itemExtra1WithoutSpaces && itemExtra1WithoutSpaces.indexOf(splitValue[j]) > -1)
						) {
							counter++;
						}
					}

					if (counter >= splitValue.length) {
						return { ...display, matchesSearch: true };
					}
				}
				return display;
			});
		}

		if (type === 'allProductsDisplay') {
			if (this._likut.isCheckerAgent) {
				for (const display of allProducts) {
					display.mismatchedQuantitiesToShowChecker = display.likut.TeudaQuantity !== display.likut.LikutQty || display.likut.TeudaPackQuantity !== display.likut.LikutPackQty;
				}
			}

			this.likutProductDisplayNewRoutes$.next(allProducts);
			const isAnyMatch = allProducts.filter(likut => likut.matchesSearch);
			if (isAnyMatch.length) {
				this.virtualScroll.scrollToIndex(allProducts.findIndex(display => display.product.ProductId === isAnyMatch[0].product.ProductId));
			}
		}
	}

	refreshSubjects(type: 'allProductsDisplay', mode: SubAppModes) {
		const productSearchValue: string = this.productSearch.value.toLowerCase();
		const len = productSearchValue.length;

		let allProducts: ProductDisplay[] = this.productDisplay$.value;

		if (!allProducts.length) {
			if (mode === 'truck-loading') {
				//@ts-ignore
				allProducts = this._truckLoading.truckLoadOpened.sub.map(task => {
					task.collected = false;
					return { product: this.allProducts[task.ProductID], truckTask: task };
				});
			}
			if (mode === 'truck-distribution') {
				// allProducts = this._truckDistribution.truckDistributionOpened.sub.map(task => ({ product: this.allProducts[task.ProductID], truckTask: { ...task, collected: false } }));

				//@ts-ignore
				allProducts = this._truckDistribution.truckDistributionOpened.sub.map(task => {
					task.collected = false;
					return { product: this.allProducts[task.ProductID], truckTask: task };
				});
			}
		}

		if (len) {
			const splitValue = productSearchValue.split(' ');

			allProducts = allProducts.map(display => {
				// if (!display.product) return display;

				let counter = 0;

				const { Barcode } = display.truckTask || {};

				let barcodeWithoutSpaces = (Barcode || '').replace(/\s/g, '').toLowerCase(),
					allStrings = typeof barcodeWithoutSpaces === 'string';

				// V8 optimizes this if statement, maybe
				if (allStrings) {
					for (let j = 0; j < splitValue.length; j++) {
						if (barcodeWithoutSpaces && barcodeWithoutSpaces.indexOf(splitValue[j]) > -1) {
							counter++;
						}
					}

					if (counter >= splitValue.length) {
						console.log(display);
						display.matchesSearch = true;
						return display;
					}
				}
				display.matchesSearch = false;
				return display;
			});
		} else {
			for (const product of allProducts) {
				product.matchesSearch = false;
			}
		}

		if (type === 'allProductsDisplay') {
			if (this.isTruckLoading || this.isTruckDistribution) {
				const notOpened = allProducts.filter(prod => !prod.truckTask.opened);
				const opened = allProducts.filter(prod => prod.truckTask.opened);
				this.productDisplay$.next([...notOpened, ...opened]);

				const isAnyMatch = allProducts.filter(task => task.matchesSearch);
				if (isAnyMatch.length) {
					this.virtualScroll.scrollToIndex(allProducts.findIndex(display => this.isProductWithTruckLoading(isAnyMatch[0]) && display.truckTask.Barcode === isAnyMatch[0].truckTask.Barcode));
				}

				return;
			}
		}
	}

	handlePopupOpen(task: ProductDisplayLikutOrTruck | LikutMekabelDisplay) {
		if (this.isProductWithLikutMekabel(task)) {
			this.handleLikutMekabelPopupOpen(task);
			return;
		}
		if ((!this._likut.isMelaketAgent && !this._likut.isCheckerAgentAndCanEdit && !this.isTruckDistribution) || !task.product || this._truckLoading.isNahagAndCantChangeQuantities) {
			return;
		}

		this.currentlyEdittedTask = task;

		if (this.isProductWithLikut(task)) {
			task.opened = true;
		}
		if (this.isProductWithTruckLoading(task) && this.isTruckLoading) {
			task.truckTask.opened = true;
		}

		this.form.controls.collected.reset();
		this.form.controls.collectedPacks.reset();
		this.form.controls.price.reset();

		this.showHideProductDetailPopup(true);

		setTimeout(() => {
			if (this.collectedInput) {
				this.collectedInput.nativeElement.focus();
				return;
			}
			if (this.collectedPacksInput) {
				this.collectedPacksInput.nativeElement.focus();
			}
		}, 0);
	}

	handleLikutMekabelPopupOpen(task: LikutMekabelDisplay) {
		task.opened = true;
		this.currentlyEdittedTaskLikutMekabel = task;

		this.form.controls.collected.reset();
		this.form.controls.collectedPacks.reset();
		// this.form.controls.price.reset();

		this.showHideProductDetailPopup(true);

		setTimeout(() => {
			this.collectedInput.nativeElement.focus();
		}, 0);
	}

	handlePopupClose() {
		this.normalizeCurrentlyEdittedTaskNumbers();
		this.currentlyEdittedTask = this.currentlyEdittedTaskLikutMekabel = null;

		this.form.reset();

		this.showHideProductDetailPopup(false);
	}

	showHideProductDetailPopup(value: boolean): void {
		this.isEditQuantityPopupShown$.next(value);
	}

	handleQuantityButtonPress(action: 'increment' | 'decrement', quantity: 'single' | 'pack' = 'single') {
		// if (!('likut' in this.currentlyEdittedTask)) return;
		if (!this.worksInBothSinglesAndPackQuantity && !this.isNewTask) {
			quantity = this.worksInSinglesQuantity ? 'single' : 'pack';
		}
		this.handleQuantityIncrementOrDecrement(action, quantity);

		this.getOrSetDisplayTask('set', this.productDisplay$.value);

		const { collected, collectedPacks, docID, itemID, packs, singles } = this.getCollectedInCurrentlyEdittedProduct();

		const totalQuantity = this.getTotalQuantityOfCurrentlyEdittedTask();

		if (!this.handleChangeValidityColor({ collected, collectedPacks, docID, itemID, packs, singles, totalQuantity })) {
			return;
		}

		// TODO What about collectedPAcks?
		this.checkboxState[itemID + ' - ' + docID] = this.form.controls.collected.value === totalQuantity;

		// console.log(this.productDisplay$.value);
		// this._change.detectChanges();
		// return;
	}

	getTotalQuantityOfCurrentlyEdittedTask(): number {
		const { singles, packs } = this.getSinglesAndPacksInCurrentlyEdittedProduct();

		let totalQuantity = 0;

		if (this.worksInPackQuantity || this.worksInBothSinglesAndPackQuantity) {
			totalQuantity += +packs;
		}

		if (this.worksInSinglesQuantity || this.worksInBothSinglesAndPackQuantity) {
			totalQuantity += +singles;
		}

		return totalQuantity;
	}

	handleChangeValidityColor(quantities: { singles: number; packs: number; itemID: string; collected: number; collectedPacks: number; docID: string; totalQuantity: number }): boolean {
		const { singles, packs, itemID, collected, collectedPacks, docID, totalQuantity } = quantities;

		if (this.isLikutMekabel) {
			this.validQuantitiesState[itemID] = (+this.form.controls.collected.value || collected) === +singles && (+this.form.controls.collectedPacks.value || collectedPacks) === +packs;

			this.checkboxState[itemID] = (+this.form.controls.collected.value || collected) === +singles && (+this.form.controls.collectedPacks.value || collectedPacks) === +packs;
			return false;
		}

		if (this.worksInBothSinglesAndPackQuantity) {
			this.validQuantitiesState[itemID + ' - ' + docID] = (+this.form.controls.collected.value || collected) === +singles && (+this.form.controls.collectedPacks.value || collectedPacks) === +packs;
			if (this._likut.isCheckerAgent) {
				if ('mismatchedQuantitiesToShowChecker' in this.currentlyEdittedTask) {
					this.currentlyEdittedTask.mismatchedQuantitiesToShowChecker = !this.validQuantitiesState[itemID + ' - ' + docID];
					this.likutProductDisplayNewRoutes$.next([...this.likutProductDisplayNewRoutes$.value]);
				}
				return false;
			}
			this.checkboxState[itemID + ' - ' + docID] = (+this.form.controls.collected.value || collected) === +singles && (+this.form.controls.collectedPacks.value || collectedPacks) === +packs;
			return false;
		}

		this.validQuantitiesState[itemID + ' - ' + docID] = this.form.controls.collected.value === totalQuantity;
		if (this._likut.isCheckerAgent) {
			if ('mismatchedQuantitiesToShowChecker' in this.currentlyEdittedTask) {
				this.currentlyEdittedTask.mismatchedQuantitiesToShowChecker = !this.validQuantitiesState[itemID + ' - ' + docID];
			}
			return false;
		}

		return true;
	}

	handleQuantityIncrementOrDecrement(action: 'increment' | 'decrement', quantity: 'single' | 'pack') {
		const { taskObject, taskCollectedProperty, totalQuantityCollected, totalQuantityOrdered } = this.resolveTaskQuantityParameters({ quantity });

		const canIncrement = totalQuantityCollected < totalQuantityOrdered || this.canGoAboveOriginalQuantity;

		if (action === 'increment') {
			if (canIncrement) {
				taskObject[taskCollectedProperty]++;
			}
			this.form.controls[quantity === 'single' ? 'collected' : 'collectedPacks'].setValue(taskObject[taskCollectedProperty]);
		}

		if (action === 'decrement') {
			taskObject[taskCollectedProperty] = Math.max(totalQuantityCollected - 1, 0);
			this.form.controls[quantity === 'single' ? 'collected' : 'collectedPacks'].setValue(taskObject[taskCollectedProperty]);
		}

		if (!this.isProductWithTruckDistribution(this.currentlyEdittedTask)) {
			return;
		}

		const { ProductID, DocNum } = this.currentlyEdittedTask.truckTask;
		const sub = this._truckDistribution.truckDistributionOpened.sub.find(sub => sub.ProductID === ProductID && sub.DocNum === DocNum);

		sub[taskCollectedProperty] = taskObject[taskCollectedProperty];
	}

	resolveTaskQuantityParameters(options: { quantity: 'single' | 'pack' }) {
		let totalQuantityOrdered = 0,
			totalQuantityCollected = 0,
			taskObject = {},
			taskQuantityProperty = '',
			taskCollectedProperty = '';

		const { quantity } = options;

		if (this.isProductWithLikut(this.currentlyEdittedTask)) {
			taskObject = this.currentlyEdittedTask.likut;

			taskQuantityProperty = quantity === 'single' ? 'TeudaQuantity' : 'TeudaPackQuantity';
			taskCollectedProperty = quantity === 'single' ? 'LikutQty' : 'LikutPackQty';
		}
		if (this.isProductWithTruckDistribution(this.currentlyEdittedTask)) {
			taskObject = this.currentlyEdittedTask.truckTask;
			taskQuantityProperty = quantity === 'single' ? 'Quantity' : 'PackQTY';
			taskCollectedProperty = quantity === 'single' ? 'PODQuantity' : 'PODPackQTY';
		}

		if (this.isLikutMekabel) {
			taskObject = this.currentlyEdittedTaskLikutMekabel.likut;
			taskQuantityProperty = quantity === 'single' ? 'TeudaQty' : 'TeudaPackQty';
			taskCollectedProperty = quantity === 'single' ? 'TeudaQtyKabala' : 'TeudaPackQtyKabala';
		}

		totalQuantityOrdered = taskObject[taskQuantityProperty];
		totalQuantityCollected = taskObject[taskCollectedProperty];

		return { totalQuantityOrdered, totalQuantityCollected, taskObject, taskQuantityProperty, taskCollectedProperty };
	}

	handleIfBackspaceOnEmptyInput(e: Event) {
		if (!(e instanceof KeyboardEvent) || ['.', 'Backspace'].includes(e.key)) return;

		const inputs = [
			{ input: this.collectedInput, control: 'collected' },
			{ input: this.collectedPacksInput, control: 'collectedPacks' },
			{ input: this.priceInput, control: 'price' },
			{ input: this.discountInput, control: 'discount' },
		];

		inputs.forEach(({ input, control }) => {
			if (!input?.nativeElement) return;
			if (e.target === input.nativeElement && this.form.controls[control].value === null) {
				this.form.controls[control].setValue(0);
			}
		});
	}

	handleCheckboxPress(line: ProductDisplay | LikutProductDisplayNewRoute) {
		if (this.isTruckLoading && 'truckTask' in line) {
			if (this._truckLoading.cantCheckLoadingTask) return;
			// line.truckTask.opened = true;
			this.checkboxState[line.truckTask.Barcode] = !this.checkboxState[line.truckTask.Barcode];
			const notOpened = this.productDisplay$.value.filter(prod => !prod.truckTask.opened);
			const opened = this.productDisplay$.value.filter(prod => prod.truckTask.opened);
			this.productDisplay$.next([...notOpened, ...opened]);
			setTimeout(() => {
				this.virtualScroll.scrollToIndex(0, 'smooth');
				window.scrollTo({ top: 0 });
			}, 0);
			return;
		}

		if (this.isLikut && 'likut' in line) {
			line.opened = true;
			if (!line.product) return;

			const { TeudaNum, ProductId } = line.likut;

			this.likutMekabelDisplay$.next([...this.likutMekabelDisplay$.value]);
			this.likutProductDisplayNewRoutes$.next([...this.likutProductDisplayNewRoutes$.value]);

			const state = this.checkboxState[ProductId + ' - ' + TeudaNum];
			if (this._likut.isCheckerAgent) {
				this._change.detectChanges();
				return;
			}
			const likutProduct = this._likut.likutOpenedNewRoutesState.sub.find(likut => likut.ProductId === line.product.ProductId && likut.TeudaNum === line.likut.TeudaNum);

			if (likutProduct) {
				if (this.worksInBothSinglesAndPackQuantity || this.worksInSinglesQuantity) {
					likutProduct.LikutQty = state ? 0 : line.likut.TeudaQuantity;
				}

				if (this.worksInBothSinglesAndPackQuantity || this.worksInPackQuantity) {
					likutProduct.LikutPackQty = state ? 0 : line.likut.TeudaPackQuantity;
				}

				this._change.detectChanges();
			} else {
				console.log('i should never show');
			}
		}
	}

	handleCheckboxPressLikutMekabel(line: LikutMekabelDisplay, event: MatCheckboxChange) {
		line.opened = true;

		const { ProductId } = line.likut;

		const newCheckState = event.checked;

		if (this.worksInBothSinglesAndPackQuantity || this.worksInSinglesQuantity) {
			line.likut.TeudaQtyKabala = newCheckState ? line.likut.TeudaQty : 0;
		}

		if (this.worksInBothSinglesAndPackQuantity || this.worksInPackQuantity) {
			line.likut.TeudaPackQtyKabala = newCheckState ? line.likut.TeudaPackQty : 0;
		}

		// this.checkboxState[ProductId] = !this.checkboxState[ProductId];
		const { TeudaQty: singles, TeudaPackQty: packs, TeudaQtyKabala: collected, TeudaPackQtyKabala: collectedPacks } = line.likut;

		this.handleChangeValidityColor({ collected, collectedPacks, docID: '', itemID: ProductId, packs, singles, totalQuantity: 0 });

		this._change.detectChanges();
	}

	async moveToFinishTask() {
		let subPath = this.pageMode$.value;

		if (!this._likut.isCheckerAgent && !this.isTruckLoading) {
			if (this.isTruckDistribution) {
				// this._loading.changeLoadingStatuses({ isLoading: true });
			}
			if (this.isLikutMekabel) {
				if (!this.likutMekabelDisplay$.value.length) {
					alert('לא ניתן לסיים משימה ללא מוצרים');
					return;
				}
				if (this.isNewTask) {
					this._router.navigate(['likut', 'finish', 'mekabel'], { queryParams: { new: true } });
					return;
				}
				this._router.navigate(['likut', 'finish', 'mekabel']);
				return;
			}
			this._router.navigate([subPath, 'finish']);
			return;
		}

		let uncheckedItem = Object.entries(this.checkboxState).find(([productid, state]) => !state);

		let array: (LikutSubSQL | TruckLoadingSubSQL)[];

		if (this.isLikut) {
			array = this._likut.likutOpenedNewRoutesState.sub.filter(sub => this.allProducts[sub.ProductId]);
			uncheckedItem = Object.entries(this.checkboxState).find(([productid, state]) => !state && this.allProducts[productid.split(' - ')[0]]);
		}

		if (this.isTruckLoading) {
			array = this._truckLoading.truckLoadOpened.sub;
		}

		if (uncheckedItem || !Object.entries(this.checkboxState).length || Object.entries(this.checkboxState).length !== array.length) {
			if (this.isLikut && !this.isLocal) {
				alert(this._text.store.cantFinishAlert);
				if (uncheckedItem) {
					this.virtualScroll.scrollToIndex(this.likutProductDisplayNewRoutes$.value.findIndex(prod => prod.product.ProductId === uncheckedItem[0].split(' - ')[0]));
				}
				return;
			}

			if (this.isTruckLoading) {
				const passwordInput = prompt(this._text.store.passwordPrompt);
				let neededPassword = '';

				try {
					const { Address, City, ClientID } = Object.values(this._truckLoading.truckLoadOpened.task.teudot)[0];

					neededPassword = await this._util.getPasswordPIN({ AgentID: Address + City, CompanyNumber: ClientID });
				} catch (error) {
					neededPassword = '';
				}

				if (passwordInput !== neededPassword) {
					alert(this._text.store.wrongPassword);
					return;
				}
			}
		}

		this._router.navigate([subPath, 'finish']);
	}

	SearchClear() {
		this.productSearch.reset();
	}

	handleBarcodePopupOpen() {
		this.isBarcodePopupOpen$.next(true);

		setTimeout(() => {
			try {
				if (this.videoElement && this.videoElement.nativeElement) {
					this.video = this.videoElement.nativeElement;
				} else {
					alert('no video element');
					throw new Error();
				}
				if (!window.BarcodeDetector) {
					alert(this._text.store.barcodeError.unsupported);
					throw new Error();
				}

				if (!navigator.mediaDevices || !('getUserMedia' in navigator.mediaDevices)) {
					alert('אין גישה למצלמה');
					throw new Error();
				}
			} catch (error) {
				this.handleBarcodePopupClose();
				return;
			}

			const constraints: MediaStreamConstraints = {
				video: { facingMode: { exact: 'environment' } },
				audio: false,
			};

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(async stream => {
					this.video.srcObject = stream;
					this.video.play();

					this.mediaStream = stream;

					const formats: SupportedFormats[] = ['code_39', 'codabar', 'ean_13', 'code_128'];

					const barcodeFormatsDetect = new window.BarcodeDetector({ formats });

					if (!barcodeFormatsDetect) {
						alert(this._text.store.barcodeError.unsupported);
						return;
					}

					const barcodeDetector = new window.BarcodeDetector({ formats });

					const detectCode = async () => {
						if (!('detect' in barcodeDetector) || !this.video || this.video.paused) {
							return;
						}

						if (this.detectedCode?.length) {
							return;
						}

						try {
							const codes = await barcodeDetector.detect(this.video);
							if (!codes?.length) return;

							for (const barcode of codes) {
								this.productSearch.reset();
								this.addProductFormControl.reset();
								if (this.isAddNewProductForMekabelPopupOpen$.value && !this.detectedCode?.length) {
									const prod = this.allProductsForAddNewProductPopup.find(prod => prod.ItemBarcode === barcode.rawValue);
									if (prod) {
										this.addProductFormControl.reset();
										this.detectedCode = barcode.rawValue;

										if (this.isNewTask) {
											const display = this.handleAddNewProductToTaskForMekabel(prod);
											this.handleAddSelectedProductForMekabel(display);
											this.handleBarcodePopupClose();

											return;
										}

										const display = this.handleAddNewProductToTaskForMelaket(prod);
										this.handleAddSelectedProductForMelaket(display);
										this.handleBarcodePopupClose();

										return;
									}
									this.openSnackbarForNoBarcode(barcode.rawValue);
									return;
								}
								if (!this.detectedCode?.length) {
									this.handleScrollToProductIfExist(barcode.rawValue);
								}
								this.detectedCode = barcode.rawValue;
							}
						} catch (error) {
							console.error(error);
						}

						this.handleBarcodePopupClose();
					};

					this.intervalID = setInterval(detectCode, 200);
				})
				.catch(() => {
					this.supportsVideo = false;
					alert(this._text.store.barcodeError.needsCameraAccess);
					this.handleBarcodePopupClose();
				});
		}, 0);
	}

	handleBarcodePopupClose() {
		if (this.mediaStream) {
			for (const track of this.mediaStream.getTracks()) {
				this.mediaStream.removeTrack(track);
			}
		}
		if (this.video) {
			this.video.srcObject = null;
			this.video.pause();
		}

		clearInterval(this.intervalID);

		this.detectedCode = '';

		this.isBarcodePopupOpen$.next(false);
	}

	async handleSendLikutBackToMelaket() {
		try {
			this._alert.customAlert(this._text.store.processSuccessful[0]);
			this.isCheckerReturnPopupOpen$.next(false);
			this.handleMelaketGoBack();
		} catch (error) {
			alert(this._text.store.couldntPatch);
		}
	}

	async handleMelaketGoBack(destination: 'back' | 'home' = 'back') {
		try {
			try {
				await this.handleChangeOpenedBy();
			} catch (error) {
				if (!this.isTruckDistribution) throw error;
			}
			if (this.isTruckLoading) {
				this._truckLoading.resetTruckLoadingState();
			}

			if (this.isTruckDistribution) {
				this._truckDistribution.resetTruckDistributionState();
			}
			if (this.isLikut) {
				this._likut.resetLikutState();
			}
			if (destination === 'back') {
				history.back();
				return;
			}
			if (destination === 'home') {
				this._router.navigate(['home'], { replaceUrl: true });
				return;
			}
		} catch (error) {
			alert(this._text.store.taskConnectionError);
		}
	}

	handleDeveloperCheckAll() {
		if (this._likut.isCheckerAgent) {
			this.checkboxState = this._likut.likutOpenedNewRoutesState.sub.reduce((p, c) => {
				p[c.ProductId + ' - ' + c.TeudaNum] = true;

				return p;
			}, {});
			return;
		}
		for (const product in this.checkboxState) {
			this.checkboxState[product] = true;
		}
	}

	async handleChangeOpenedBy(): Promise<void> {
		if (this.isLikutMekabel) return;
		if (this.isLikut) {
			return await new Promise((resolve, reject) => {
				const teudaNums = [...new Set(this._likut.likutOpenedNewRoutesState.sub.map(likut => likut.TeudaNum))];
				this._ss
					.getLikutByTeudaNumMain(teudaNums, 'freeup')
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							if (typeof res === 'string') {
								return reject();
							}
							resolve();
						},
						error: () => {
							reject();
						},
					});
			});
		}
		if (this.isTruckDistribution) {
			return await new Promise((resolve, reject) => {
				const DocNum = [...new Set(this._truckDistribution.truckDistributionOpened.sub.map(sub => sub.DocNum))];
				this._truckDistribution
					.markTaskAsOpenedByAgent({ DocNum, OpenedBy: null })
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							if (typeof res === 'string') {
								return reject();
							}
							resolve();
						},
						error: () => {
							reject();
						},
					});
			});
		}
		return await new Promise((resolve, reject) => {
			const DocNum = [...new Set(this._truckLoading.truckLoadOpened.sub.map(sub => sub.DocNum))];
			this._truckLoading
				.markTaskAsOpenedByAgent({ DocNum, OpenedBy: null })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') {
							return reject();
						}
						resolve();
					},
					error: () => {
						reject();
					},
				});
		});
	}

	setupFormInteractivity() {
		this.form.controls.collected.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				if (value === null) return;

				if (+value < 0 || isNaN(+value)) {
					this.form.controls.collected.reset();
				}

				const { singles, packs } = this.getSinglesAndPacksInCurrentlyEdittedProduct();

				if (+value > singles && !this.canGoAboveOriginalQuantity) {
					this.form.controls.collected.reset(singles);
				}

				this.syncTaskQuantityWithForm({ quantity: this.worksInBothSinglesAndPackQuantity || this.worksInSinglesQuantity ? 'single' : 'pack' });

				const { itemID, collected, collectedPacks, docID } = this.getCollectedInCurrentlyEdittedProduct();
				const totalQuantity = this.getTotalQuantityOfCurrentlyEdittedTask();

				this.handleChangeValidityColor({ itemID, collected, collectedPacks, docID, packs, singles, totalQuantity });

				if (this.isTruckDistribution) {
					this.getOrSetDisplayTask('set', this.productDisplay$.value);
				}
			},
		});
		this.form.controls.collectedPacks.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				if (value === null) return;

				if (+value < 0 || isNaN(+value)) {
					this.form.controls.collectedPacks.reset();
				}

				const { singles, packs } = this.getSinglesAndPacksInCurrentlyEdittedProduct();

				if (+value > packs && !this.canGoAboveOriginalQuantity) {
					this.form.controls.collectedPacks.reset(packs);
				}

				this.syncTaskQuantityWithForm({ quantity: 'pack' });

				const { itemID, collected, collectedPacks, docID } = this.getCollectedInCurrentlyEdittedProduct();
				const totalQuantity = this.getTotalQuantityOfCurrentlyEdittedTask();

				this.handleChangeValidityColor({ itemID, collected, collectedPacks, docID, packs, singles, totalQuantity });

				if (this.isTruckDistribution) {
					this.getOrSetDisplayTask('set', this.productDisplay$.value);
				}
			},
		});
		this.form.controls.price.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				if (!this.isLikutMekabel && (value === null || !this._likut.isCheckerAgentAndCanEdit)) return;

				if (+value < 0 || isNaN(+value)) {
					this.form.controls.price.reset(0);
				}

				if (+value > MAX_PRODUCT_PRICE) {
					this.form.controls.price.reset(MAX_PRODUCT_PRICE);
				}

				if (this.isProductWithLikut(this.currentlyEdittedTask)) {
					this.currentlyEdittedTask.likut.LikutPrice = +this.form.controls.price.value || 0;
				}

				if (this.isProductWithLikutMekabel(this.currentlyEdittedTaskLikutMekabel)) {
					this.currentlyEdittedTaskLikutMekabel.likut.PriceOut = +this.form.controls.price.value || 0;
				}
			},
		});

		this.form.controls.discount.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				if (+value < 0 || isNaN(+value)) {
					this.form.controls.discount.reset();
				}

				if (+value > MAX_DISCOUNT) {
					this.form.controls.discount.reset(MAX_DISCOUNT);
				}

				if (this.isProductWithLikutMekabel(this.currentlyEdittedTaskLikutMekabel)) {
					this.currentlyEdittedTaskLikutMekabel.likut.DiscountOut = +this.form.controls.discount.value || 0;
				}
			},
		});

		this.form.controls.eitur.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				if (value === null) return;

				if (value.length > 100) {
					this.form.controls.eitur.reset(value.slice(0, 100));
				}

				if (this.isProductWithLikutMekabel(this.currentlyEdittedTaskLikutMekabel)) {
					this.currentlyEdittedTaskLikutMekabel.likut.Eitur = this.form.controls.eitur.value || '';
				}
			},
		});

		this.form.controls.remark.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				if (value === null) return;

				if (value.length > 100) {
					this.form.controls.remark.reset(value.slice(0, 100));
				}

				if (this.isProductWithLikutMekabel(this.currentlyEdittedTaskLikutMekabel)) {
					this.currentlyEdittedTaskLikutMekabel.likut.ItemRemark = this.form.controls.remark.value || '';
				}
			},
		});

		this.productSearch.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.handleUsingRefresh();
		});

		if (this.isTruckDistribution) {
			this.productDisplay$.pipe(takeUntil(this.destroy$)).subscribe(val => {
				this.getOrSetDisplayTask('set', val);
			});
		}

		if (this.isNewTask || this.canAddNewItemsAsMelaket) {
			this.addProductSearch$ = this.addProductFormControl.valueChanges.pipe(
				startWith(''),
				map(value => {
					const name = value;
					return name ? this._filterProduct(name as string) : this.allProductsForAddNewProductPopup.filter(prod => !this.productsAlreadyAddedSet.has(prod.ProductId)).slice(0, 50);
				}),
			);
		}
	}

	syncTaskQuantityWithForm(options: { quantity: 'single' | 'pack' }) {
		if (options.quantity === 'single') {
			if (this.isProductWithLikut(this.currentlyEdittedTask)) {
				this.currentlyEdittedTask.likut.LikutQty = +this.form.controls.collected.value;
				this._change.detectChanges();
				return;
			}
			if (this.isProductWithTruckDistribution(this.currentlyEdittedTask)) {
				this.currentlyEdittedTask.truckTask.PODQuantity = +this.form.controls.collected.value;
			}

			if (this.isLikutMekabel) {
				this.currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala = +this.form.controls.collected.value;
			}
		}

		if (options.quantity === 'pack') {
			if (this.isProductWithLikut(this.currentlyEdittedTask)) {
				this.currentlyEdittedTask.likut.LikutPackQty = +this.form.controls.collectedPacks.value;
				return;
			}
			if (this.isProductWithTruckDistribution(this.currentlyEdittedTask)) {
				this.currentlyEdittedTask.truckTask.PODPackQTY = +this.form.controls.collectedPacks.value;
			}

			if (this.isLikutMekabel) {
				this.currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala = +this.form.controls.collectedPacks.value;
			}
		}
	}

	handleScrollToProductIfExist(productBarcode: string) {
		let productIndex = this.productDisplay$.value.findIndex(task => task.truckTask.Barcode === productBarcode);

		if (this.isLikut) {
			productIndex = this.likutProductDisplayNewRoutes$.value.findIndex(product => product.product?.ItemBarcode === productBarcode);
		}

		if (this.isLikutMekabel) {
			productIndex = this.likutMekabelDisplay$.value.findIndex(product => product.likut.Barcode === productBarcode);
		}

		if (productIndex > -1) {
			this.virtualScroll.scrollToIndex(productIndex, 'smooth');

			if ((this.isTruckLoading || this.isTruckDistribution) && this._truckLoading.checkAfterScanner) {
				const line = this.productDisplay$.value[productIndex];
				if (this.isProductWithTruckLoading(line)) {
					this.checkboxState[line.truckTask.Barcode] = true;
				}
				return;
			}

			if (this.isLikut && this._ss.CustomerInformation.LikutSearch === '1') {
				if (this.isLikutMekabel) {
					const prod = this.likutMekabelDisplay$.value[productIndex];
					if (!prod) return;
					const likut = this._likut.likutMekabelOpenedState.main.rows.find(line => line.ProductId === prod.likut.ProductId);
					if (likut) {
						likut.TeudaQtyKabala = likut.TeudaQty;
						likut.TeudaPackQtyKabala = likut.TeudaPackQty;
						this.checkboxState[likut.ProductId] = true;
					}
					return;
				}
				const prod = this.likutProductDisplayNewRoutes$.value[productIndex];

				if (!prod) return;

				const likut = this._likut.likutOpenedNewRoutesState.sub.find(line => line.ProductId === prod.product.ProductId);

				if (likut) {
					likut.LikutQty = likut.TeudaQuantity;
					likut.LikutPackQty = likut.TeudaPackQuantity;
					this.checkboxState[likut.ProductId + ' - ' + likut.TeudaNum] = true;
				}
			}
			return;
		}

		this.openSnackbarForNoBarcode(productBarcode);
	}

	openSnackbarForNoBarcode(barcode: string) {
		this._snackbar.open(`${this._text.store.barcodeNotFound[0]} ${barcode} ${this._text.store.barcodeNotFound[1]}`, undefined, {
			duration: 3000,
			direction: 'rtl',
			panelClass: ['snack-bar'],
		});
	}

	populateCheckboxState(mode: 'truckLoading' | 'likutNewRoutes' | 'likutMekabel') {
		// this.likutMekabelDisplay$.next([...this.likutMekabelDisplay$.value]);
		// this.likutProductDisplayNewRoutes$.next([...this.likutProductDisplayNewRoutes$.value]);
		if (this._likut.isCheckerAgent) return;

		if (mode === 'likutNewRoutes') {
			this.checkboxState = this._likut.likutOpenedNewRoutesState.sub.reduce((p, c) => {
				// let isQuantityCollected = false;
				const singles = c.TeudaPackQuantity === c.LikutPackQty,
					packs = c.TeudaQuantity === c.LikutQty;

				if (this.worksInPackQuantity) {
					p[c.ProductId + ' - ' + c.TeudaNum] = packs;
				}

				if (this.worksInSinglesQuantity) {
					p[c.ProductId + ' - ' + c.TeudaNum] = singles;
				}

				if (this.worksInBothSinglesAndPackQuantity) {
					p[c.ProductId + ' - ' + c.TeudaNum] = singles && packs;
				}

				return p;
			}, {});
		}
		if (mode === 'likutMekabel') {
			this.checkboxState = this._likut.likutMekabelOpenedState.main.rows.reduce((p, c) => {
				const packs = c.TeudaPackQty === c.TeudaPackQtyKabala,
					singles = c.TeudaQtyKabala === c.TeudaQty;

				if (this.worksInPackQuantity) {
					p[c.ProductId] = packs;
				}

				if (this.worksInSinglesQuantity) {
					p[c.ProductId] = singles;
				}

				if (this.worksInBothSinglesAndPackQuantity) {
					p[c.ProductId] = singles && packs;
				}

				return p;
			}, {});
		}
	}

	populateValidQuantitiesState() {
		if (this.isTruckLoading) return;

		if (this.isTruckDistribution) {
			this.validQuantitiesState = this._truckDistribution.truckDistributionOpened.sub.reduce((p, c) => {
				let isQuantityCollected = false;
				if (this.worksInBothSinglesAndPackQuantity) {
					isQuantityCollected = c.Quantity === c.PODQuantity && c.PackQTY === c.PODPackQTY;
				}
				if (this.worksInPackQuantity) {
					isQuantityCollected = c.PackQTY === c.PODPackQTY;
				}
				if (this.worksInSinglesQuantity) {
					isQuantityCollected = c.Quantity === c.PODQuantity;
				}

				p[c.ProductID + ' - ' + c.DocNum] = isQuantityCollected;

				return p;
			}, {});

			return;
		}

		if (this.isLikutMekabel) {
			this.validQuantitiesState = this._likut.likutMekabelOpenedState.main.rows.reduce((p, c) => {
				const packs = c.TeudaPackQty === c.TeudaPackQtyKabala,
					singles = c.TeudaQtyKabala === c.TeudaQty;
				if (this.worksInBothSinglesAndPackQuantity) {
					p[c.ProductId] = packs && singles;
				}
				if (this.worksInPackQuantity) {
					p[c.ProductId] = packs;
				}
				if (this.worksInSinglesQuantity) {
					p[c.ProductId] = singles;
				}

				return p;
			}, {});

			return;
		}

		this.validQuantitiesState = this._likut.likutOpenedNewRoutesState.sub.reduce((p, c) => {
			let isQuantityCollected = false;
			if (this.worksInBothSinglesAndPackQuantity) {
				isQuantityCollected = c.LikutQty === c.TeudaQuantity && c.LikutPackQty === c.TeudaPackQuantity;
			}
			if (this.worksInPackQuantity) {
				isQuantityCollected = c.LikutPackQty === c.TeudaPackQuantity;
			}
			if (this.worksInSinglesQuantity) {
				isQuantityCollected = c.LikutQty === c.TeudaQuantity;
			}

			p[c.ProductId + ' - ' + c.TeudaNum] = isQuantityCollected;

			return p;
		}, {});
	}

	handlePressEnterOnSearch(e?: KeyboardEvent) {
		if (e.key !== 'Enter') return;

		let relevantProducts: (ProductDisplay | LikutProductDisplayNewRoute)[] = this.productDisplay$.value.filter(f => f.matchesSearch);

		if (this.isLikut && !relevantProducts.length) {
			relevantProducts = this.likutProductDisplayNewRoutes$.value.filter(f => f.matchesSearch);
			// handle likutmekabel
		}

		if (relevantProducts.length === 1) {
			const prod = relevantProducts[0];

			if (this.isLikut && 'likut' in prod) {
				const likut = this._likut.likutOpenedNewRoutesState.sub.find(opened => opened.ProductId === prod.product?.ProductId);
				if (likut && !this._likut.isCheckerAgent && this._ss.CustomerInformation.LikutSearch === '1') {
					this.checkboxState[likut.ProductId + ' - ' + likut.TeudaNum] = true;

					prod.opened = true;

					likut.LikutQty = likut.TeudaQuantity;
					likut.LikutPackQty = likut.TeudaPackQuantity;
				}
			}
			if (this.isProductWithTruckLoading(prod) && (this.isTruckLoading || this.isTruckDistribution)) {
				const arr: (TruckLoadingSubSQL | TruckDistributionSubSQL)[] = this.isTruckDistribution ? this._truckDistribution.truckDistributionOpened.sub : this._truckLoading.truckLoadOpened.sub;
				const truck = arr.find(opened => opened.Barcode === prod.truckTask.Barcode);
				if (truck && this._truckLoading.checkAfterScanner) {
					this.checkboxState[truck.Barcode] = true;
					prod.truckTask.opened = true;
					this.productSearch.reset();
				}
			}
		}
	}

	getSinglesAndPacksInCurrentlyEdittedProduct(): { singles: number; packs: number } {
		let singles = 0,
			packs = 0;

		if (this.isLikutMekabel) {
			return { singles: this.currentlyEdittedTaskLikutMekabel.likut.TeudaQty, packs: this.currentlyEdittedTaskLikutMekabel.likut.TeudaPackQty };
		}

		if (this.isProductWithLikut(this.currentlyEdittedTask)) {
			return { singles: this.currentlyEdittedTask.likut.TeudaQuantity, packs: this.currentlyEdittedTask.likut.TeudaPackQuantity };
		}

		if (this.isProductWithTruckDistribution(this.currentlyEdittedTask) || this.isProductWithTruckLoading(this.currentlyEdittedTask)) {
			return { singles: this.currentlyEdittedTask.truckTask.Quantity, packs: this.currentlyEdittedTask.truckTask.PackQTY };
		}
		return { singles, packs };
	}

	normalizeCurrentlyEdittedTaskNumbers() {
		if (!this.currentlyEdittedTask && !this.currentlyEdittedTaskLikutMekabel) return;
		if (this.isProductWithLikut(this.currentlyEdittedTask)) {
			this.currentlyEdittedTask.likut.LikutQty = +formatNumberToMaxPrecision(+this.currentlyEdittedTask.likut.LikutQty || 0);
			this.currentlyEdittedTask.likut.LikutPackQty = +formatNumberToMaxPrecision(+this.currentlyEdittedTask.likut.LikutPackQty || 0);
			return;
		}

		if (this.isProductWithLikutMekabel(this.currentlyEdittedTaskLikutMekabel)) {
			this.currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala = +formatNumberToMaxPrecision(+this.currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala || 0);
			this.currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala = +formatNumberToMaxPrecision(+this.currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala || 0);
			this.currentlyEdittedTaskLikutMekabel.likut.PriceOut = +formatNumberToMaxPrecision(+this.currentlyEdittedTaskLikutMekabel.likut.PriceOut || 0);
			this.currentlyEdittedTaskLikutMekabel.likut.DiscountOut = +formatNumberToMaxPrecision(+this.currentlyEdittedTaskLikutMekabel.likut.DiscountOut || 0);
			return;
		}
	}

	getCollectedInCurrentlyEdittedProduct() {
		const { singles, packs } = this.getSinglesAndPacksInCurrentlyEdittedProduct();
		let itemID = '',
			collected = 0,
			collectedPacks = 0,
			docID = '';

		if (this.isProductWithLikut(this.currentlyEdittedTask)) {
			itemID = this.currentlyEdittedTask.likut.ProductId;
			collected = this.currentlyEdittedTask.likut.LikutQty;
			collectedPacks = this.currentlyEdittedTask.likut.LikutPackQty;
			docID = this.currentlyEdittedTask.likut.TeudaNum;
			return { singles, packs, itemID, collected, collectedPacks, docID };
		}

		if (this.isProductWithTruckDistribution(this.currentlyEdittedTask)) {
			itemID = this.currentlyEdittedTask.truckTask.ProductID;
			collected = this.currentlyEdittedTask.truckTask.PODQuantity;
			collectedPacks = this.currentlyEdittedTask.truckTask.PODPackQTY;
			docID = this.currentlyEdittedTask.truckTask.DocNum;
		}

		if (this.isProductWithTruckLoading(this.currentlyEdittedTask)) {
			itemID = this.currentlyEdittedTask.truckTask.ProductID;
			collected = this.currentlyEdittedTask.truckTask.Quantity;
			collectedPacks = this.currentlyEdittedTask.truckTask.PackQTY;
			docID = this.currentlyEdittedTask.truckTask.DocNum;
		}

		if (this.isLikutMekabel) {
			itemID = this.currentlyEdittedTaskLikutMekabel.likut.ProductId;
			collected = this.currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala;
			collectedPacks = this.currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala;
			docID = this.currentlyEdittedTaskLikutMekabel.likut.TeudaNum;
		}

		return { singles, packs, itemID, collected, collectedPacks, docID };
	}

	enforceMaxTextLength(e: Event, mode: 'eitur' | 'remark') {
		if (e instanceof KeyboardEvent && e.target instanceof HTMLTextAreaElement) {
			this.form.controls[mode].reset(e.target.value.slice(0, 100));
		}
	}

	getOrSetDisplayTask(mode: 'get'): void;
	getOrSetDisplayTask(mode: 'set', newValue: ProductDisplay[]): void;
	getOrSetDisplayTask(mode: 'get' | 'set', newValue = this.productDisplay$.value): void {
		if (!this.isTruckDistribution) return;
		let key = '',
			clientid = '',
			address = '',
			status = '';
		if (this._truckDistribution.truckDistributionOpened.task) {
			({ clientid, address, status } = this._truckDistribution.truckDistributionOpened.task);
			key = `Task-${clientid},${status},${address}`;
		}
		// if (mode === 'get') {
		// 	if (!key || !clientid || !address || !status) return;
		// 	const stored = localStorage.getItem(key);
		// 	if (stored) {
		// 		const parsed: { sub: ProductDisplay[]; main: TruckDistributionTaskDisplayExtended; finishOptions?: { signature: string } } = JSON.parse(decompress(stored));
		// 		if (parsed?.sub?.length && this.pageMode$.value.includes('truck')) {
		// 			console.log(parsed);
		// 			this.productDisplay$.next(parsed.sub);
		// 			this.taskID = parsed.main.taskID;
		// if (this.isTruckDistribution) {
		// 	this._truckDistribution.truckDistributionOpened.sub = parsed.sub.map(task => task.truckTask as TruckDistributionSubOpened);
		// 	if (parsed.finishOptions?.signature) {
		// 		this._truckDistribution.truckDistributionOpened.finishOptions = parsed.finishOptions;
		// 	}
		// }
		// 		}
		// 	}
		// }
		if (mode === 'set') {
			this._truckDistribution.truckDistributionOpened.sub = newValue.map(task => task.truckTask as TruckDistributionSubOpened);
			// const main = this._truckDistribution.truckDistributionOpened.task;
			// const finishOptions = this._truckDistribution.truckDistributionOpened.finishOptions;
			// const stringified = compress(JSON.stringify({ sub, main, finishOptions }));
			// localStorage.setItem(key, stringified);
		}
	}

	openAddNewProductForMekabelPopup() {
		this.isAddNewProductForMekabelPopupOpen$.next(true);
	}

	handleRouteSelectNewProductForMekabelOrMelaket(e: MatAutocompleteSelectedEvent) {
		if (this.isNewTask) {
			this.handleSelectNewProductForMekabel(e);
			return;
		}

		this.handleSelectNewProductForMelaket(e);
	}

	handleSelectNewProductForMelaket(e: MatAutocompleteSelectedEvent) {
		const addedItem = this.handleAddNewProductToTaskForMelaket(e.option.value);
		this.handleAddSelectedProductForMelaket(addedItem);
	}

	handleSelectNewProductForMekabel(e: MatAutocompleteSelectedEvent) {
		const addedItem = this.handleAddNewProductToTaskForMekabel(e.option.value);
		this.handleAddSelectedProductForMekabel(addedItem);
	}

	handleAddSelectedProductForMekabel(product: LikutMekabelDisplay) {
		this.productsAlreadyAddedSet.add(product.likut.ProductId);
		this.isAddNewProductForMekabelPopupOpen$.next(false);
		this.addProductFormControl.reset();
		this.handleLikutMekabelPopupOpen(product);
	}
	handleAddSelectedProductForMelaket(product: LikutProductDisplayNewRoute) {
		this.productsAlreadyAddedSet.add(product.likut.ProductId);
		this.isAddNewProductForMekabelPopupOpen$.next(false);
		this.addProductFormControl.reset();
		this.handlePopupOpen(product);
	}

	handleAddNewProductToTaskForMekabel(product: AllProducts) {
		const likutDisplay = this.handleGenerateLikutMekabelDisplayFromAllProducts(product);
		const { likut } = likutDisplay;

		this._likut.likutMekabelOpenedState.main.rows = [...this._likut.likutMekabelOpenedState.main.rows, { ...likut }];

		this.likutMekabelDisplay$.next([...this.likutMekabelDisplay$.value, { likut }]);
		return likutDisplay;
	}

	handleAddNewProductToTaskForMelaket(product: AllProducts) {
		const productDisplay = this._likut.handleGenerateProductDisplayFromAllProducts({ product, isDuplicated: true });

		this._likut.likutOpenedNewRoutesState.sub.push(productDisplay.likut);

		this.likutProductDisplayNewRoutes$.next([...this.likutProductDisplayNewRoutes$.value, productDisplay]);

		return productDisplay;
	}

	handleForgetOriginalStock() {
		if (this.isLikutMekabel) {
			for (const product of this._likut.likutMekabelOpenedState.main.rows) {
				delete product.Stock;
			}
			return;
		}

		if (this.isLikut) {
			for (const product of this._likut.likutOpenedNewRoutesState.sub) {
				delete product.Stock;
			}
		}
	}

	handleGenerateLikutMekabelDisplayFromAllProducts(product: AllProducts): LikutMekabelDisplay {
		return {
			opened: true,
			likut: {
				Barcode: product.ItemBarcode,
				DiscountOut: 0,
				Eitur: '',
				ItemRemark: '',
				PriceOut: 0,
				ProductId: product.ProductId,
				TeudaNum: this._likut.likutMekabelOpenedState.main.refrenceNumber,
				TeudaPackQtyKabala: 0,
				TeudaQtyKabala: 0,
				Currency: '',
				Discount: 0,
				Extra1: '',
				Extra2: '',
				Extra3: '',
				Extra4: '',
				Extra5: '',
				ItemPack: product.ItemPack,
				ItemPicName: product.ItemPicName,
				ItemStatus: '0',
				Price: product.Price,
				ProductName: product.Product_Name,
				Rate: 0,
				Stock: product.Stock,
				TeudaLineNum: this._likut.likutMekabelOpenedState.main.rows.length + 1,
				TeudaPackQty: 0,
				TeudaQty: 0,
				TeudaType: this._likut.likutMekabelOpenedState.main.doctype.num,
				Warehouse: this._likut.likutMekabelOpenedState.main.warehouse.id,
			} as LikutKabalaSubSQL,
		};
	}

	handleDeleteLikutMekabelProduct(product: LikutMekabelDisplay) {
		const index = this._likut.likutMekabelOpenedState.main.rows.findIndex(row => row.TeudaLineNum === product.likut.TeudaLineNum);
		this._likut.likutMekabelOpenedState.main.rows.splice(index, 1);
		this._likut.likutMekabelOpenedState.main.rows = [...this._likut.likutMekabelOpenedState.main.rows.map((row, i) => ({ ...row, TeudaLineNum: +i + 1 }))];
		this.likutMekabelDisplay$.next(
			this.likutMekabelDisplay$.value.filter(prod => prod.likut.TeudaLineNum !== product.likut.TeudaLineNum).map((prod, i) => ({ ...prod, likut: { ...prod.likut, TeudaLineNum: +i + 1 } })),
		);
		this.productsAlreadyAddedSet.delete(product.likut.ProductId);
		this.productToBeDeleted$.next(null);
		this.handlePopupClose();
	}

	handleDeleteLikutMelaketProduct(product: LikutProductDisplayNewRoute) {
		this._likut.likutOpenedNewRoutesState.sub = this._likut.likutOpenedNewRoutesState.sub
			.filter(row => row.TeudaLineNum !== product.likut.TeudaLineNum)
			.map((row, i) => ({ ...row, TeudaLineNum: +i + 1 }));
		this.likutProductDisplayNewRoutes$.next(
			this.likutProductDisplayNewRoutes$.value
				.filter(prod => prod.likut.TeudaLineNum !== product.likut.TeudaLineNum)
				.map((prod, i) => ({ ...prod, likut: { ...prod.likut, TeudaLineNum: +i + 1 } })),
		);
		this.productsAlreadyAddedSet.delete(product.likut.ProductId);
		this.melaketProductToBeDeleted$.next(null);
		this.handlePopupClose();
	}

	openScannerForAddNewProductPopup(e: Event) {
		e.stopPropagation();
		this.handleBarcodePopupOpen();
	}

	displayFnProduct(input: AllProducts): string {
		return input?.Product_Name || '';
	}

	private _filterProduct(name: string | AllProducts): AllProducts[] {
		const filterValue = typeof name === 'string' ? name.toLowerCase() : '';

		return genericSearchingFunction(filterValue, this.allProductsForAddNewProductPopup, ['Product_Name', 'ProductId', 'ItemBarcode']).filter(
			prod => !this.productsAlreadyAddedSet.has(prod.ProductId),
		);
	}

	// type completion for template
	isProductWithLikut(product: ProductDisplay | LikutProductDisplayNewRoute | LikutMekabelDisplay): product is LikutProductDisplayNewRoute {
		if (!product) return false;
		return 'likut' in product && 'TeudaQuantity' in product.likut;
	}
	isProductWithLikutMekabel(product: ProductDisplay | LikutProductDisplayNewRoute | LikutMekabelDisplay): product is LikutMekabelDisplay {
		if (!product) return false;
		return 'likut' in product && 'TeudaQtyKabala' in product.likut;
	}
	isProductWithTruckLoading(product: ProductDisplay | LikutProductDisplayNewRoute | LikutMekabelDisplay): product is ProductDisplayBase & { truckTask: TruckLoadingSubOpened & Opened } {
		if (!product) return false;
		return 'truckTask' in product;
	}
	isProductWithTruckDistribution(product: ProductDisplay | LikutProductDisplayNewRoute | LikutMekabelDisplay): product is ProductDisplayBase & { truckTask: TruckDistributionSubOpened & Opened } {
		if (!product) return false;
		return 'truckTask' in product && 'PODQuantity' in product.truckTask;
	}
}
