import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { circleMarker, Icon, IconOptions, LatLngExpression, LatLngTuple, Map, marker as Mark, Marker, polyline, Polyline } from 'leaflet';
import { generateNewIcon } from '../components/map/map.component';
import { TruckDistributionExtraNumbers, TruckDistributionMainSQL } from '../Interfaces/truck-distribution';
import { TruckDistributionService } from './truck-distribution.service';
import 'leaflet-polylinedecorator';
import L from 'leaflet';

export type TeudaMarkerPopupData = { TeudaTotal: string; TeudaCreateDate: string };
export type MarkerInfo<T> = { longitude: string; latitude: string; data: T };

const DEGREES = 360;
const MAX_COLOR_DIVESITY = 12;
const HUE_FILTER_VALUES = new Array(MAX_COLOR_DIVESITY)
	.fill(DEGREES)
	.map((num, i) => num - (i * DEGREES) / MAX_COLOR_DIVESITY)
	.sort(() => (Math.random() > 0.5 ? 1 : -1));

interface PopupData {
	// Define the properties needed for the popup template
}

@Injectable({
	providedIn: 'root',
})
export class MarkerService {
	constructor(private _truckDistribution: TruckDistributionService) {}

	markers: Marker[] = [];
	paths: Polyline[] = [];
	agentColors: Record<string, number> = {};

	openPDF = new EventEmitter<string>();
	eventListenerCallback;

	addCoordinates<T>(map: Map, coords: MarkerInfo<T>[]) {
		for (const coord of coords) {
			const marker = Mark([+coord.latitude, +coord.longitude]);
			this.resolveMarkerModeAndAddPopupMarkup(marker, coord.data);

			marker.addTo(map);

			this.markers.push(marker);
		}
	}

	addCoordinatesTruckDistribution(map: Map, coords: MarkerInfo<TruckDistributionMainSQL>[]) {
		this.agentColors = {};
		for (const coord of coords) {
			this.agentColors[coord.data.AssignTo] ||= HUE_FILTER_VALUES[Object.keys(this.agentColors).length];
			const marker = Mark([+coord.latitude, +coord.longitude]);
			this.resolveMarkerModeAndAddPopupMarkup(marker, coord.data);

			marker.addTo(map);

			this.markers.push(marker);
		}
	}

	drawPathDistribution(map: Map, agentSpecificCoords: MarkerInfo<TruckDistributionMainSQL>[]) {
		const latLangTuplesRecord: Record<string, LatLngExpression[]> = agentSpecificCoords.reduce((prev, curr) => {
			prev[curr.data.AssignTo] ||= [];
			prev[curr.data.AssignTo].push([+curr.latitude, +curr.longitude]);
			return prev;
		}, {} as Record<string, LatLngExpression[]>);

		const tuples = Object.entries(latLangTuplesRecord);

		// TODO add only 1 arrow per marker
		for (const i in tuples) {
			const color = `hsl(${HUE_FILTER_VALUES[i]}, 46%, 41%)`;
			if (!tuples[i][1].length) continue;
			const paths = polyline(tuples[i][1], { color });
			paths.addTo(map);
			this.paths.push(paths);

			// Add arrows to the path
			//@ts-ignore
			const decorator = L.polylineDecorator(paths, {
				//@ts-ignore
				patterns: [{ offset: '10%', repeat: '10%', symbol: L.Symbol.arrowHead({ pixelSize: 20, pathOptions: { fillOpacity: 1, weight: 0, color } }) }],
			}).addTo(map);
			this.paths.push(decorator);
		}
	}

	clearMap() {
		for (const marker of this.markers) {
			marker.remove();
		}

		for (const path of this.paths) {
			path.remove();
		}

		this.markers = [];
		this.paths = [];
	}

	private resolveMarkerModeAndAddPopupMarkup(marker: Marker, data: TeudaMarkerPopupData | TruckDistributionMainSQL | PopupData) {
		if (this.isTeudaData(data)) {
			marker.bindPopup(this.generateTeudaPopupTemplate(data));
			return;
		}

		if (this.isTruckDistributionData(data)) {
			const icon: Icon<IconOptions> = generateNewIcon({ className: `filter-hue-${this.agentColors[data.AssignTo]}` });

			marker.bindPopup(this.generateTruckPopupTemplate(data));
			marker.on('popupopen', () => {
				const pdfButtons = document.querySelectorAll('.marker-pdf-button');
				if (pdfButtons) {
					for (const pdfButton of Array.from(pdfButtons)) {
						pdfButton.addEventListener('click', () => {
							this.openPDF.emit(data.PDFfile);
						});
					}
				}
			});
			marker.setIcon(icon);
			return;
		}

		marker.bindPopup(this.generatePopupTemplate(data));
	}

	private generatePopupTemplate(data: PopupData): string {
		return ``;
	}
	private generateTruckPopupTemplate(data: TruckDistributionMainSQL): string {
		const divOuterLeft = '<div style="text-align:right;direction:rtl;">';
		//prettier-ignore
		const details = [
			`שעה: ${Intl.DateTimeFormat('en-il',{timeZone:'gmt', timeStyle:'medium'}).format(new Date(data.PODDate))}`,
			`כתובת: ${data.Address}`,
			`מס' תעודה: ${data.DocNum}`,
			`סוכן: ${data.AssignTo}`,
		];

		if (data.Comment) {
			details.push(`הערה: ${data.Comment}`);
		}

		details.push(`לקוח: ${data.ClientName}`);

		if (this._truckDistribution.extras$.value.length) {
			const extraArray: TruckDistributionExtraNumbers[] = new Array(4).fill(0).map((_, i) => `Extra${i + 1}`) as TruckDistributionExtraNumbers[];

			for (const extraNum of extraArray) {
				if (data[extraNum]) {
					const extra = this._truckDistribution.extras$.value.find(extra => extra.EName === extraNum);
					if (!extra || extra.DisplayInMap === '0') continue;
					const name = extra.HebName;
					details.push(`${name}: ${data[extraNum]}`);
				}
			}
		}
		if (data.PDFfile) {
			details.push(`<button class="marker-pdf-button">קובץ PDF</button>`);
		}
		return details.map(detail => divOuterLeft + detail + '</div>').join('');
	}

	private generateTeudaPopupTemplate(data: TeudaMarkerPopupData) {
		return `<div style="text-align:right;">סה"כ: ${data.TeudaTotal}</div>
			<div style="text-align:right;">תאריך: ${data.TeudaCreateDate}</div>`;
	}

	isTeudaData(data: any): data is TeudaMarkerPopupData {
		return 'TeudaTotal' in data && 'TeudaCreateDate' in data;
	}

	isTruckDistributionData(data: any): data is TruckDistributionMainSQL {
		return 'PODDate' in data && 'DocNum' in data;
	}
}
