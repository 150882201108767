<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header" *ngIf="!isPartOfPopup">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="handleBackButton()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
						<span class="back-arrow-text">חזרה</span>
					</div>
					<div class="header__title col">כרטיס לקוח</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start markup for client profile page -->
		<!-- client info for all client profile page -->
		<section class="client-info" [ngStyle]="{ 'marginTop.px': !isPartOfPopup ? 100 : null }">
			<div class="content">
				<div class="row">
					<div class="client-info__title col">
						{{ this.OneClientInfo.Client_Name }}
					</div>
				</div>
				<div class="client-info__block row">
					<div class="client-info__number col">
						{{ this.OneClientInfo.ClientId }}
					</div>
				</div>
			</div>
		</section>
		<!-- end of client info for all client profile page -->

		<!-- first screen client profile-->
		<section *ngIf="!showDebtTable && !showReports" class="client-profile">
			<div class="contnet">
				<div class="client-profile__block row" *ngIf="!isPartOfPopup">
					<div
						class="client-profile__button col"
						[ngClass]="{ 'client-profile__button_active': isShopActive }"
						(click)="MoveToProductsAzmana()"
						*ngIf="this._ss.OneClientInfo.DocType === 11 || this._ss.OneClientInfo.AO === 1"
					>
						<div class="client-profile__button-image client-profile__button-image_big image">
							<img src="/assets/images/client-profile/shop.svg" alt="image" />
						</div>
						<span class="client-profile__button-text">הזמנה</span>
					</div>
					<div class="client-profile__button col" [ngClass]="{ 'client-profile__button_active': isShopActive }" (click)="MoveToProductsAzmana()" *ngIf="showNewOrderButton">
						<div class="client-profile__button-image client-profile__button-image_big image">
							<img src="/assets/images/client-profile/shop.svg" alt="image" />
						</div>
						<span class="client-profile__button-text">הזמנה</span>
					</div>
					<div
						class="client-profile__button col"
						[ngClass]="{ 'client-profile__button_active': isRevertActive }"
						(click)="MoveToProductsAhzara()"
						*ngIf="!isB2BAgent && this._ss.OneClientInfo.DocType !== 0 && this._ss.OneClientInfo.AM !== 1 && this._ss.OneClientInfo.AO !== 1"
					>
						<div class="client-profile__button-image image">
							<img src="/assets/images/client-profile/revert.svg" alt="image" />
						</div>
						<span class="client-profile__button-text">החזרה</span>
					</div>
					<div class="client-profile__button col" [ngClass]="{ 'client-profile__button_active': isRefreshActive }" (click)="MoveToClientActiv()" *ngIf="!isB2BAgent">
						<div class="client-profile__button-image image">
							<img src="/assets/images/client-profile/refresh.svg" alt="image" />
						</div>
						<span class="client-profile__button-text">ביקורים</span>
					</div>
					<div
						class="client-profile__button col"
						style="background: var(--visit-blue)"
						[ngClass]="{ 'client-profile__button_active': isShopActive }"
						(click)="toggleMaslulNewTaskForm('open')"
						*ngIf="canCreateNewMaslul"
					>
						<div class="client-profile__button-image client-profile__button-image_big image" style="max-width: unset; width: 14px">
							<img src="/assets/images/meeting_32.png" alt="image" />
						</div>
						<span class="client-profile__button-text">קבע פגישה</span>
					</div>
				</div>
				<div class="client-profile__block row clickable" (click)="ShowContacts = true" *ngIf="!isPartOfPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/people-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">איש קשר</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ this.OneClientInfo.CE3 }}
					</div>
					<div class="client-profile__icon image col"></div>
				</div>
				<div class="client-profile__block row" *ngIf="!isPartOfPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/phone-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">טלפון</div>
					<div class="client-profile__value client-profile__value_phone col justify-align-center">
						<a href="tel:{{ this.OneClientInfo.Phone }}" style="font-size: 19px; font-weight: 600">{{ this.OneClientInfo.Phone }}</a>
					</div>
					<div class="client-profile__icon client-profile__icon_big image col">
						<a href="https://wa.me/{{ this.OneClientInfo.Phone | unformatPhoneNumber }}" target="_blank" rel="noopener noreferrer">
							<img src="/assets/images/client-profile/whatsapp-icon.svg" alt="image" />
						</a>
					</div>
				</div>
				<div class="client-profile__block row" *ngIf="!isPartOfPopup && OneClientInfo.CustMobile">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/phone-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">נייד</div>
					<div class="client-profile__value client-profile__value_phone col justify-align-center">
						<a href="tel:{{ this.OneClientInfo.CustMobile }}" style="font-size: 19px; font-weight: 600">{{ this.OneClientInfo.CustMobile }}</a>
					</div>
					<div class="client-profile__icon client-profile__icon_big image col">
						<a href="https://wa.me/{{ this.OneClientInfo.CustMobile | unformatPhoneNumber }}" target="_blank" rel="noopener noreferrer">
							<img src="/assets/images/client-profile/whatsapp-icon.svg" alt="image" />
						</a>
					</div>
				</div>
				<div class="client-profile__block row" *ngIf="this.OneClientInfo.CustEMail || this.OneClientInfo.CustEmail">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/email.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">אימייל</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ this.OneClientInfo.CustEMail || this.OneClientInfo.CustEmail }}
					</div>
				</div>
				<div class="client-profile__block row" (click)="MoveToClientHovot()" *ngIf="LoadingHovot === true">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">חובות</div>
					<div
						class="client-profile__value client-profile__value_debt col justify-align-center"
						style="font-size: 19px; font-weight: 600"
						[style.fontWeight]="OneClientInfo.COD < 0 ? '700' : null"
						[style.color]="OneClientInfo.COD < 0 ? 'red' : null"
					>
						{{ (OneClientInfo.COD | number : '1.0-2') + (hovotStatus ? ' - ' + hovotStatus : '') }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">אובליגו מנוצל</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ this.OneClientInfo.COD + this.OneClientInfo.COC + (+OneClientInfo.AddObligo || 0) | number : '1.0-2' }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row" *ngIf="!isB2BAgent">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/credit-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">אשראי</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ this.OneClientInfo.CMC | number : '1.0-2' }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row" *ngIf="!isB2BAgent">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/credit-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">תנאי אשראי</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ this.OneClientInfo.PTN }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row clickable" (click)="MoveToClientTeudot()" *ngIf="!isB2BAgent && !isPartOfPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/documents-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">תעודות סוכן</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ this.TeudotPerClient.length }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row clickable" (click)="router.navigate(['mismachim-receipts'])" *ngIf="!isB2BAgent && !isPartOfPopup && receipts.length">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/documents-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">קבלות</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ receipts.length }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row clickable" (click)="MoveMismachim()" *ngIf="!isB2BAgent && !isPartOfPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/documents-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">מסמכי לקוח</div>
					<div class="client-profile__value col" style="font-size: 19px; font-weight: 600"></div>
					<div class="client-profile__icon client-profile__icon_arrow-left image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row clickable" (click)="handleNavigationToVisitsReport()" *ngIf="!isB2BAgent && !isPartOfPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/documents-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">ביקורים</div>
					<div class="client-profile__value col" style="font-size: 19px; font-weight: 600"></div>
					<div class="client-profile__icon client-profile__icon_arrow-left image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row clickable" (click)="MoveNewBizoim()" *ngIf="!isB2BAgent && !isPartOfPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/documents-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">ביצועים</div>
					<div class="client-profile__value col" style="font-size: 19px; font-weight: 600"></div>
					<div class="client-profile__icon client-profile__icon_arrow-left image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row" *ngIf="!isPartOfPopup">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/adress-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">כתובת</div>
					<div class="client-profile__value col justify-align-center">
						<a
							[href]="'https://waze.com/ul?' + (OneClientInfo.Coordinates ? 'll=' + OneClientInfo.Coordinates : 'q=' + addressToShow) + '&navigate=yes'"
							target="_blank"
							rel="noopener noreferrer"
							style="font-size: 19px; font-weight: 600"
							>{{ addressToShow }}</a
						>
					</div>
					<div class="client-profile__icon image col">
						<img src="/assets/images/client-profile/simple-icons_waze.svg" alt="image" />
					</div>
				</div>
				<div class="client-profile__block row" *ngFor="let ce of custExtraArray">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/comment-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">פרטים נוספים</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">{{ ce }}</div>
					<div class="client-profile__icon image col"></div>
				</div>
				<div class="client-profile__block row" *ngIf="!isB2BAgent">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/comment-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">הערות</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ this.OneClientInfo.Hearot }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
					</div>
				</div>
				<div>
					<div
						class="client-profile__block client-profile__block-rejected row"
						[ngClass]="{ 'client-profile__block-rejected_active': showRejectBlock }"
						(click)="showHideReject()"
						*ngIf="!isB2BAgent"
					>
						<div class="client-profile__image image col">
							<img src="/assets/images/client-profile/rejected-icon.svg" alt="image" />
						</div>
						<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">צ'קים דחויים</div>
						<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
							{{ this.OneClientInfo.COC | number : '1.0-2' }}
						</div>
						<div class="client-profile__icon client-profile__icon_arrow image col">
							<img src="/assets/images/client-profile/rejected-arrow.svg" alt="image" />
						</div>
					</div>
					<div class="client-profile__rejected-hide row" *ngIf="showRejectBlock">
						<div class="client-profile__rejected-table row">
							<div class="client-profile__rejected-table-title client-profile__rejected-table-date col" style="font-size: 16px; font-weight: 500">תאריך</div>
							<div class="client-profile__rejected-table-title client-profile__rejected-table-sum col" style="font-size: 16px; font-weight: 500; max-width: 80px">סכום</div>
							<div class="client-profile__rejected-table-title client-profile__rejected-table-number col" style="font-size: 16px; font-weight: 500; min-width: 110px">צ'ק</div>
							<div class="client-profile__rejected-table-title client-profile__rejected-table-number col" style="font-size: 16px; font-weight: 500">בנק</div>
						</div>
						<div class="client-profile__rejected-table client-profile__rejected-table_padding-top row" *ngFor="let cs of ChecksShow">
							<div class="client-profile__rejected-table-text client-profile__rejected-table-date col" style="font-size: 16px; font-weight: 500">{{ cs.PayDate }}</div>
							<div class="client-profile__rejected-table-text client-profile__rejected-table-sum col" style="font-size: 16px; font-weight: 500; max-width: 80px">{{ cs.Amount }}</div>
							<div class="client-profile__rejected-table-text client-profile__rejected-table-number col" style="font-size: 16px; font-weight: 500; min-width: 110px">{{ cs.CheckNum }}</div>
							<div class="client-profile__rejected-table-text client-profile__rejected-table-number col" style="font-size: 16px; font-weight: 500">{{ cs.BankNum }}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- end first screen client profile-->
	</div>
</div>

<!-- markup for popup -->
<div class="popup" *ngIf="showPopup">
	<div class="overlay"></div>
	<div class="client-profile__popup popup__content">
		<div class="popup__close" (click)="showHidePopup(false)"></div>
		<h2 class="client-profile__popup-title">שלח מסמכים מסומנים למייל הלקוח</h2>
		<div class="client-profile__popup-block">
			<input class="client-profile__popup-input" type="email" value="someone@mobility-ltd.com" />
		</div>
		<div class="client-profile__popup-block">
			<button class="client-profile__popup-button client-profile__popup-button_send" (click)="showHidePopup(false)">שלח</button>
			<button class="client-profile__popup-button" (click)="showHidePopup(false)">בטל</button>
		</div>
	</div>
</div>

<div class="popup" *ngIf="ShowContacts === true">
	<div class="overlay"></div>
	<div class="client-profile__popup popup__content">
		<div class="popup__close" (click)="ShowContacts = false"></div>
		<h2 class="client-profile__popup-title">פרטים נוספים לאיש קשר</h2>
		<h2 class="client-profile__popup-title">{{ this._ss.OneClientInfo.CE1 }}</h2>
		<h2 class="client-profile__popup-title">{{ this._ss.OneClientInfo.CE2 }}</h2>
		<h2 class="client-profile__popup-title">{{ this._ss.OneClientInfo.CE3 }}</h2>
		<h2 class="client-profile__popup-title">{{ this._ss.OneClientInfo.CE4 }}</h2>
		<h2 class="client-profile__popup-title">{{ this._ss.OneClientInfo.CE5 }}</h2>
		<div class="client-profile__popup-block">
			<button class="client-profile__popup-button" (click)="ShowContacts = false" style="margin: auto; background-color: red">סגור</button>
		</div>
	</div>
</div>

<div class="popup" *ngIf="receiptsPopupOpen" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content no-padding">
		<div class="product-detail__wrapper wrapper2">
			<div class="product-detail__item overflow" style="padding: 0; direction: rtl">
				<div class="popup__close" (click)="receiptsPopupOpen = false" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>
				<div class="content popup-header client-info" style="padding: 1em">
					<div class="row">
						<div class="popup-title client-info__title col">קבלות</div>
					</div>
					<div class="client-info__block row">
						<div class="client-info__number col">{{ OneClientInfo.Client_Name }}</div>
					</div>
					<div class="client-info__block row">
						<div class="client-info__number col">{{ OneClientInfo.ClientId }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="popup" *ngIf="isCreatingNewMaslul$ | async" style="z-index: 900; direction: rtl">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content" [ngSwitch]="newMaslulSubmitStatus$ | async">
		<div class="popup__close" (click)="toggleMaslulNewTaskForm('close')"></div>
		<form class="grid row gap" *ngSwitchCase="'idle'" style="place-items: center" [formGroup]="newMaslulForm" dir="rtl" (ngSubmit)="handleMaslulFormSubmit()">
			<ng-container *ngIf="_device.shouldntShowNativeControls$ | async; else nativeDateFormControlBlock">
				<mat-form-field color="primary" style="max-width: 180px">
					<mat-label>בחר תאריך</mat-label>
					<input [min]="today" readonly="true" formControlName="date" matInput [matDatepicker]="datePicker" (click)="datePicker.open()" />
					<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
					<mat-datepicker #datePicker></mat-datepicker>
					<mat-error> יש לבחור תאריך </mat-error>
				</mat-form-field>
				<div class="grid">
					<label for="time">בחר שעה</label>
					<input [ngxTimepicker]="timePicker" [format]="24" formControlName="time" name="time" readonly />
					<ngx-material-timepicker #timePicker [confirmBtnTmpl]="timePickerConfirmButton" [cancelBtnTmpl]="timePickerCancelButton"></ngx-material-timepicker>
					<ng-container *ngIf="newMaslulForm.get('time').touched">
						<span class="invalid-color" *ngIf="newMaslulForm.get('time').invalid">יש לבחור שעה</span>
					</ng-container>
					<ng-template #timePickerConfirmButton>
						<button mat-raised-button type="button" color="primary">אישור</button>
					</ng-template>
					<ng-template #timePickerCancelButton>
						<button mat-raised-button type="button">בטל</button>
					</ng-template>
					<span class="invalid-color" *ngIf="newMaslulForm.get('time').touched && newMaslulForm.getError('invalidTime')">יש לבחור שעה מאוחרת יותר</span>
				</div>
			</ng-container>
			<ng-template #nativeDateFormControlBlock>
				<label for="date">בחר תאריך</label>
				<input type="datetime-local" formControlName="date" name="date" />
				<ng-container *ngIf="newMaslulForm.get('date').touched">
					<span class="valid-color" *ngIf="newMaslulForm.get('date').valid">תאריך תקין</span>
					<span class="invalid-color" *ngIf="newMaslulForm.get('date').invalid">תאריך לא תקין</span>
				</ng-container>
			</ng-template>
			<div class="row gap justify-align-center">
				<button mat-raised-button type="submit" color="primary">אישור</button>
				<button mat-raised-button type="button" (click)="toggleMaslulNewTaskForm('close')">ביטול</button>
			</div>
		</form>
		<div class="grid justify-align-center row">
			<mat-spinner *ngSwitchCase="'loading'"></mat-spinner>
			<div *ngSwitchCase="'success'" class="row grid gap">
				<div class="popup-title client-info__title col" style="text-align: center; color: var(--valid-color)">פגישה נקבעה בהצלחה!</div>
				<div class="order-process__image image" style="width: min(300px, 75vw)">
					<img src="/assets/images/basket/process/save.gif" alt="photo" />
				</div>
				<button mat-raised-button color="primary" (click)="toggleMaslulNewTaskForm('close')">אישור</button>
			</div>
			<div *ngSwitchCase="'error'" class="row grid gap">
				<div class="invalid-color">אירעה שגיאה בעת יצירת המשימה, נסו שנית מאוחר יותר</div>
				<button mat-raised-button (click)="newMaslulSubmitStatus$.next('idle')">הבנתי</button>
			</div>
		</div>
	</div>
</div>
