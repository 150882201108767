export const specialsValues = ['סט', 'קנה קבל', 'קנה קבל מרכז'] as const;
export const typeOfSpecials = specialsValues.map((value, index) => ({ text: value, value: index }));
export const specialsTypesEnum = {
	NORMAL: 0,
	BUY_GET: 1, // need to add functionality
	BUY_GET_MAIN_PRODUCT: 2,
} as const;

export type Special = {
	ID: number;
	SpecialID: number;
	Name: string;
	StartDate: Date;
	EndDate: Date;
	SpecialStatus: 0 | 1 | 2;
	ProductID: string;
	Client_Id: string;
	Stock: number;
	Company: number;
	TeudaPackQuantity: number;
	TeudaQuantity: number;
	TeudaBonusQuantity: number;
	TeudaItemNetPrice: number;
	MaxInCart: number;
	SpecialType: typeof specialsTypesEnum[keyof typeof specialsTypesEnum];
	PriceCode: string;
	SpecialExtra1: string;
	SpecialExtra2: string;
	SpecialDescription: string;
	length?: number;
	Color?: string;
	BonusType?: SpecialBonusType;
};

export interface specialPostBody extends specialPatchBodyEdit {
	StartDate: Date;

	EndDate: Date;

	ProductID: string;

	Client_Id: string;

	Company: number;

	Stock: number;

	TeudaQuantity: number;

	TeudaPackQuantity: number;

	TeudaBonusQuantity: number;

	TeudaItemNetPrice: number;

	// PriceCode: number;
	MaxInCart: number;
	Name: string;
	BonusType?: SpecialBonusType;
}

export type SpecialBonusType = 'single' | 'packs';

export interface specialPatchBodyEdit {
	SpecialID: number;
	SpecialStatus: number;
}

export type SpecialObjectHash<T> = {
	[ID: number | string]: T;
};

export type SpecialInCartObj = {
	[key: string]: SpecialInCart;
};

export type SpecialInCart = {
	special: Special[];
	quantity: number;
};
