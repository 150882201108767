import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { decompress } from 'lz-string';
import { BaseComponent } from '../base-component/base.component';
import { ExcelObject, formatAsDateOptions, UtilService } from 'src/app/services/util.service';
import { utils, writeFile } from 'xlsx';
import { DatePipe } from '@angular/common';
import { LoadingService } from 'src/app/services/loading.service';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { translateDateForSQLServer } from 'src/app/Utility/functions';

type Active = {
	VisitID: string;
	ClientID: string;
	CreateDate: string;
	ResonID: string;
	Remark: string;
	ClientName: string;
	Client_Name: string;
	company: number;
	Latitude: string;
	Longitude: string;
	ResDesc: string;
	AgentID: string;
	VisitTimeZone: string;
	DATAString: string;
	string?: any;
	ResonVisitText?: string;
};

@Component({
	selector: 'app-adminactiv',
	templateUrl: './adminactiv.component.html',
	styleUrls: ['./adminactiv.component.scss'],
})
export class AdminactivComponent extends BaseComponent implements OnInit {
	constructor(public router: Router, public _fb: FormBuilder, public _util: UtilService, private _loading: LoadingService, public device: DeviceInfoService, private _acr: ActivatedRoute) {
		super();
	}

	public form = this._fb.group({
		Agent: ['all', Validators.required],
		Date: ['all', Validators.required],
		Name: ['all', Validators.required],
		ClientSearch: ['', Validators.required],
	});
	// showActivityProducts = true;
	burgerMenu = false;
	burgerMenuNoScroll = false;
	headerAbsolute = false;
	// showLogoutPopup = false;

	public Agents;
	public AllClients;
	public AllClientsName;
	public AllClientsSearch;

	agentsToGet = [];

	public Dates = [];
	public Names = [];

	isAdmin = location.pathname.includes('admin');
	hasString = false;
	hasReasonVisitText = false;

	isPopupOpen = false;
	selectedActive: Active | null = null;

	windowWidth = innerWidth;

	shouldFilterByClient = this._acr.snapshot.queryParams['clientid'];

	radioControl = new FormControl<string>('basic');

	hasVisitsPictures = false;

	ngOnInit(): void {
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		this._ss.GetAllReportsAdminnew().subscribe({
			next: async (res: any) => {
				if (typeof res === 'string') {
					alert('לא ניתן להביא ביקורים');
					history.back();
					return;
				}

				this.AllClients = res.recordset;

				const anyPictures = this.AllClients.some(visit => visit.PicName && visit.PicName !== '0');

				this.hasVisitsPictures = anyPictures;

				if (this._ss.CustomerInformation?.VisitsTakePicture !== '1') {
					this.hasVisitsPictures = false;
				}

				if (!this.isAdmin) {
					this.AllClients = this.AllClients.filter(ac => ac.AgentID === this._ss.AgentIdConnected);
				}

				await new Promise<void>(resolve => {
					this._ss.GetAllReportsAgentReasons(1).subscribe({
						next: (res: any) => {
							if (!res.recordset) return resolve();
							const reasons = res.recordset.filter(r => [2, 3].includes(r.rem));

							if (reasons.length) {
								for (const client of this.AllClients) {
									if (client.DATAString) {
										this.hasString = true;
										const string = JSON.parse(client.DATAString);
										const reason = reasons.find(rs => rs.rid === string.ResonVisitID);
										if (reason) {
											client.ResonVisitText = reason.rds;
											this.hasReasonVisitText = true;
										}
									}
								}
							}
							resolve();
						},
						error: err => {
							resolve();
						},
					});
				});

				if (localStorage.getItem('AllClients') !== null) {
					this.AllClientsName = decompress(localStorage.getItem('AllClients'));
					this.AllClientsName = JSON.parse(this.AllClientsName);

					for (let x = 0; x < this.AllClientsName.length; x++) {
						for (let y = 0; y < this.AllClients.length; y++) {
							this.AllClients[y].ClientId = this.AllClients[y].ClientID;
							if (this.AllClientsName[x].ClientId === this.AllClients[y].ClientID) {
								if (!this.AllClients[y].ClientName && this.AllClientsName[x].Client_Name) {
									this.AllClients[y].Client_Name = this.AllClients[y].ClientName = this.AllClientsName[x].Client_Name;
								} else {
									this.AllClients[y].Client_Name = this.AllClients[y].ClientName;
								}
							}
							try {
								this.AllClients[y].string = JSON.parse(this.AllClients[y].DATAString || '{}');
							} catch (err) {
								this.AllClients[y].string = '';
							}
							// replace "" if exists in string. might be unnecessarily complex
							this.AllClients[y].string.Exit &&= this.AllClients[y].string.Exit.replaceAll('"', '');
						}
					}

					this.AllClientsSearch = this.AllClients;

					for (let x = 0; x < this.AllClientsSearch.length; x++) {
						let data = this.Dates.find(ob => ob.CreateDate.toString().slice(0, 10) === this.AllClientsSearch[x].CreateDate.toString().slice(0, 10));
						if (data === undefined) {
							this.Dates.push(this.AllClientsSearch[x]);
						}
					}

					for (let x = 0; x < this.AllClientsSearch.length; x++) {
						let data = this.Names.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
						if (data === undefined) {
							this.Names.push(this.AllClientsSearch[x]);
						}
					}
				}

				if (this.isAdmin) {
					this.handleGetAgents();
				}

				if (this.shouldFilterByClient) {
					this.form.controls.Name.setValue(this.shouldFilterByClient);
					this.NameReports();
				}
			},
			error: () => {
				alert('לא ניתן ליצור קשר עם השרת, נסו שנית מאוחר יותר');
				history.back();
			},
		});
	}

	public BackToAdmin() {
		history.back();
	}
	public AgentReports() {
		this.AllClients = [];

		if (this.form.controls.Agent.value === 'all') {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		} else {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClientsSearch[x].AgentID === this.form.controls.Agent.value) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}

		this.FilterSearches();
	}
	public DateReports() {
		this.AllClients = [];

		if (this.form.controls.Date.value === 'all') {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		} else {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClientsSearch[x].CreateDate?.slice?.(0, 10) === translateDateForSQLServer(this.form.controls.Date.value).toISOString().slice(0, 10)) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}

		this.FilterSearches();
	}
	public NameReports() {
		this.AllClients = [];

		if (this.form.controls.Name.value === 'all') {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		} else {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClientsSearch[x].ClientID === this.form.controls.Name.value) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}

		this.FilterSearches();
	}
	public FilterSearches() {
		if (this.form.controls.Agent.value === 'all' && this.form.controls.Date.value === 'all' && this.form.controls.Name.value === 'all') {
			this.AllClients = [];

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		}

		if (this.form.controls.Agent.value !== 'all' && this.form.controls.Date.value !== 'all' && this.form.controls.Name.value !== 'all') {
			this.AllClients = [];

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].ClientID === this.form.controls.Name.value &&
					this.AllClientsSearch[x].CreateDate === this.form.controls.Date.value &&
					this.AllClientsSearch[x].AgentID === this.form.controls.Agent.value
				) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}

		if (this.form.controls.Name.value === 'all' && this.form.controls.Date.value !== 'all' && this.form.controls.Agent.value !== 'all') {
			this.AllClients = [];

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClientsSearch[x].CreateDate === this.form.controls.Date.value && this.AllClientsSearch[x].AgentID === this.form.controls.Agent.value) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}

		if (this.form.controls.Date.value === 'all' && this.form.controls.Agent.value !== 'all' && this.form.controls.Name.value !== 'all') {
			this.AllClients = [];

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClientsSearch[x].ClientID === this.form.controls.Name.value && this.AllClientsSearch[x].AgentID === this.form.controls.Agent.value) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}

		if (this.form.controls.Agent.value === 'all' && this.form.controls.Date.value !== 'all' && this.form.controls.Name.value !== 'all') {
			this.AllClients = [];

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClientsSearch[x].ClientID === this.form.controls.Name.value && this.AllClientsSearch[x].CreateDate === this.form.controls.Date.value) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}
	}

	handleGetAgents() {
		if (this._ss.CustomerInformation.Submanagers == '1') {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res == 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							return;
						}

						this.agentsToGet = res.recordset.map(obj => obj.Agent);
						this.agentsToGet.push(this._ss.AgentIdConnected);

						this.getAgentList();
					},
					error: err => {
						alert('שגיאת שרת');
						history.back();
					},
				});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getAgentList();
			}
		} else {
			this.getAgentList();
		}
	}

	getAgentList() {
		this._ss.GetAllAgents(this.agentsToGet).subscribe(res => {
			this.Agents = res;
			this.Agents = this.Agents.recordset;

			if (this._ss.CustomerInformation.Submanagers === '1') {
				const allowedAgents = new Set(this.Agents.map(ag => ag.AgentID));

				this.AllClientsSearch = this.AllClientsSearch.filter(client => allowedAgents.has(client.AgentID));
				this.AllClients = this.AllClients.filter(client => allowedAgents.has(client.AgentID));
			}

			this._loading.changeLoadingStatuses({ isLoading: false });
		});
	}

	openPopup(activ: Active) {
		this.selectedActive = activ;
		this.isPopupOpen = true;
	}

	handleClosePopup() {
		this.isPopupOpen = false;
		this.selectedActive = null;
	}

	valuesObject = {
		First: ['Client_Name', 'AgentID', 'ClientID', 'CreateDate', 'CreateDate', 'ResDesc', 'Remark', 'ResonVisitText'],
	} as const;

	translateForSort(value: typeof this.valuesObject[typeof screen][number], screen: keyof typeof this.valuesObject = Object.keys(this.valuesObject)[0] as keyof typeof this.valuesObject): void {
		let i: number, arrayToSort: any[];

		i = this.valuesObject[screen].findIndex(arr => arr === value);

		if (screen === 'First') {
			arrayToSort = this.AllClients;
		}

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}

	// showHideLogoutPopup(value: boolean): void {
	// 	this.showLogoutPopup = value;
	// }

	downloadExcel() {
		const dataObject: ExcelObject = { data: [], filename: '', extension: 'xlsx' };

		const getFormatOptions = (str: 'date' | 'hour'): formatAsDateOptions => ({ isTrueISO: true, onlyTime: str === 'hour' });

		console.log(this.AllClients);

		const longestStringInColumn: any = {};

		dataObject.data = this.AllClients.map(b => {
			const obj: { [key: string]: string | number } = {
				תאריך: this._util.formatAsDate(b.CreateDate, getFormatOptions('date')),
				לקוח: b.Client_Name ?? b.ClientName,
				'סיכום ביקור': b.ResDesc,
			};
			if (this.isAdmin) {
				obj['סוכן'] = b.AgentID;
			}

			if (this.radioControl.value === 'detailed') {
				obj['קוד לקוח'] = b.ClientID;
				obj['פירוט'] = b.Remark;
				if (!this.hasString) {
					obj['שעה'] = this._util.formatAsDate(b.CreateDate, getFormatOptions('hour'));
				} else {
					obj['כניסה'] = this._util.formatAsDate(b.string.Enter, getFormatOptions('hour'));
					obj['יציאה'] = this._util.formatAsDate(b.string.Exit, getFormatOptions('hour'));
				}

				if (this.hasReasonVisitText) {
					obj['סיבת ביקור'] = b.ResonVisitText;
				}
			}

			for (const entry of Object.entries(obj)) {
				longestStringInColumn[entry[0]] ??= 0;
				longestStringInColumn[entry[0]] = Math.max(String(entry[1]).length, longestStringInColumn[entry[0]], 3);
			}
			return obj;
		});

		dataObject.filename = 'ביקורים';

		console.log({ longestStringInColumn });

		const ws = utils.json_to_sheet(dataObject.data);

		ws['!cols'] = Object.values(longestStringInColumn).map((columnWidth: number) => ({ wch: columnWidth }));

		const wb = utils.book_new();
		if (!wb.Workbook) wb.Workbook = {};
		if (!wb.Workbook.Names) wb.Workbook.Views = [{}];
		wb.Workbook.Views[0].RTL = true;
		utils.book_append_sheet(wb, ws, dataObject.filename);
		writeFile(wb, `${dataObject.filename}.${dataObject.extension}`, { bookType: dataObject.extension, sheet: dataObject.filename, compression: true, cellStyles: true });
	}
}
