import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { CreditFormValues } from '../components/credit-card-payment/credit-card-payment.component';
import { CreditOrderDoctypes, CreditParameters, ReceiptBodyWithPayment } from '../Interfaces/credit-payment';
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root',
})
export class CreditCardService {
	constructor(private http: HttpClient, private _ss: StoreService) {}
	parameters$ = new BehaviorSubject<CreditParameters | {}>({});

	creditCardFormValues$ = new BehaviorSubject<CreditFormValues>({});

	refreshParameters$: Observable<CreditParameters> = defer(
		() =>
			new Observable<CreditParameters>(observer => {
				this.getCreditParameters().subscribe({
					next: parameters => {
						this.parameters$.next(parameters);
						observer.next(parameters);
						observer.complete();
					},
					error: err => observer.error(err),
				});
			}),
	);

	private readonly apiEntry = () => `${this._ss.CustomerInformation.CustServer}/mobil/payment`;

	resetFormValues() {
		this.creditCardFormValues$.next({});
	}

	hasAnyFormValues() {
		return Object.keys(this.creditCardFormValues$.value).length > 0;
	}

	getCurrentAmount() {
		return this.creditCardFormValues$.value.amount ?? 0;
	}

	getCreditParameters() {
		return this.http.get<CreditParameters>(`${this.apiEntry()}/parameters`);
	}

	refreshParameters() {
		this.getCreditParameters().subscribe({
			next: parameters => {
				this.parameters$.next(parameters);
			},
			error: error => {
				// console.error(error);
			},
		});
	}

	async handlePostNewReceiptWithCredit(body: ReceiptBodyWithPayment) {
		const response = await fetch(`${this.apiEntry()}/new/receipt`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'content-type': 'application/json',
			},
			mode: 'cors',
		});

		const data = await response.json();

		return data;
	}
	async handlePostNewOrderWithCredit(body: any) {
		const response = await fetch(`${this.apiEntry()}/new/order`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'content-type': 'application/json',
			},
			mode: 'cors',
		});

		const data = await response.json();

		return data;
	}

	hasParameters(parameters = this.parameters$.value): parameters is CreditParameters {
		return Object.keys(parameters).length > 0;
	}

	isDoctypeOfCreditTypeOrder(doctype: number) {
		return doctype >= CreditOrderDoctypes.min && doctype <= CreditOrderDoctypes.max;
	}
}
