<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="_util.navigateBack()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
					</div>
					<div class="header__title col">{{ _text.store.taskAssignmentReportMapDistribution }}</div>

					<div class="header__burger col image" (click)="_util.reloadPage()" style="text-decoration: underline">
						<b> איפוס </b>
					</div>
				</div>
			</div>
			<!-- <div class="report-table__row">
				<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
					<form [formGroup]="form">
						<input class="header__search-input" type="text" placeholder="חפש" formControlName="Search" id="search-Input" style="text-align: center; direction: rtl" />
					</form>
					<button class="header__clear-icon" (click)="SearchClear()"></button>
				</div>
			</div> -->

			<!-- <h2 style="text-align: center" *ngIf="LoadingBack === true">טוען אנא המתן</h2> -->
		</header>

		<div class="popup" *ngIf="shouldShowPDFPopup$ | async" style="z-index: 1999">
			<div class="overlay" style="z-index: 0" (click)="closePDFPopup()"></div>

			<div class="popup__close" (click)="closePDFPopup()" style="width: 30px; height: 30px; background-color: black; top: 1rem; left: 1rem"></div>

			<div class="pdf-wrapper">
				<app-canvas-popup [pdfSRCInput$]="pdfSRC$" [useSignature]="false"></app-canvas-popup>
			</div>
		</div>

		<div class="map-wrapper fullscreen">
			<div class="map-controls above-map flex gap justify-align-center row">
				<div class="block" class="gap justify-align-center" style="background: white; padding: 16px">
					<button mat-raised-button color="primary" (click)="openDialog()">שנה יום</button>
					<mat-form-field color="primary" appearance="fill" dir="rtl">
						<mat-select multiple [formControl]="agentsControl">
							<mat-option *ngFor="let agent of _truckDistribution.usersForAssignment$ | async" [value]="agent">{{ agent.AgentID }} - {{ agent.UserName }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<app-map *ngIf="shouldMapShow$ | async" [coordinatesDistribution]="relevantTasks$ | async" [shouldRecalculate]="recalculateMap$"></app-map>
		</div>
	</div>
</div>
