import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MismachimService } from 'src/app/services/mismachim.service';
import { TextService } from 'src/app/services/text.service';
import { EmailRadioValues } from '../teuda/teuda.component';
import { UserService } from 'src/app/services/user.service';
import { DeviceInfoService } from 'src/app/services/device-info.service';

@Component({
	selector: 'app-mismachimeach',
	templateUrl: './mismachimeach.component.html',
	styleUrls: ['./mismachimeach.component.scss'],
})
export class MismachimeachComponent implements OnInit {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		public _mismachim: MismachimService,
		public _text: TextService,
		public _user: UserService,
		public _device: DeviceInfoService,
	) {}

	public EachClientEachOrder: any = [];

	OneClientInfo = this._ss.OneClientInfo;

	addressToDisplay =
		this._ss.OneClientInfo?.Address && this._ss.OneClientInfo?.CustCity
			? `${this._ss.OneClientInfo.Address}, ${this._ss.OneClientInfo.CustCity}`
			: this._ss.OneClientInfo?.Address || this._ss.OneClientInfo?.CustCity || '';
	// If both undefined for some reason

	doesPDFExistInServer = false;
	pdfFolderLookup = this._ss.CustomerInformation.PDFDirectory;
	pdfFilepath;

	history = history;

	showItemInPack = true;

	isEmailPickPopupOpen = false;

	clientHasEmail = false;

	windowWidth = window.innerWidth;

	emailRadios = new FormControl<EmailRadioValues>('custom');
	emailInput = new FormControl('', [Validators.required, Validators.email]);

	ngOnInit(): void {
		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
			return;
		}

		this.showItemInPack = this._ss.CustomerInformation.HideQuantityInPack !== '1';

		this.clientHasEmail = !!this.OneClientInfo.CustEMail || !!this.OneClientInfo.CustEmail || false;

		if (this.clientHasEmail) {
			this.emailRadios.setValue('client');
		}

		this._ss.GetOneTeudaAgentNew(this._mismachim.chosenMismachParameters, this._ss.OneClientInfo.company).subscribe(res => {
			this.EachClientEachOrder = res;
			this.EachClientEachOrder = this.EachClientEachOrder.recordset;

			if (this._ss.CustomerInformation.CanSendEmail && this._ss.CustomerInformation.CanSendEmail.includes('4') && this.EachClientEachOrder?.[0]?.DocPicName) {
				this._ss.checkIfPDFExistInServer(this.pdfFolderLookup, this.EachClientEachOrder?.[0]?.DocPicName).subscribe({
					next: (value: any) => {
						this.doesPDFExistInServer = true;
						this.pdfFilepath = this.formatStringWithEncodedSlashes(value.pdf);
					},
				});
			}
		});
	}

	formatStringWithEncodedSlashes(str: string) {
		return str.replace(/\//g, '%2F').replace(/\\/g, '%5C');
	}

	handleClosePopup() {
		this.isEmailPickPopupOpen = this.emailSentSuccessfully = false;
	}

	loadingEmailSend = false;
	emailSentSuccessfully = false;

	handleSubmitEmail() {
		this.loadingEmailSend = true;

		let email = '';

		switch (this.emailRadios.value) {
			case 'client':
				email = this.OneClientInfo.CustEmail || this.OneClientInfo.CustEMail;
				break;
			case 'user':
				email = this._user.agentEmail$.value;
				break;
			case 'custom':
				email = this.emailInput.value;
				break;

			default:
				break;
		}

		this._ss.sendPDFThroughEmail(email, this.pdfFilepath, this.EachClientEachOrder[0].DocDocNum).subscribe({
			next: res => {
				this.loadingEmailSend = false;
				this.emailSentSuccessfully = true;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסה שנית או מאוחר יותר.');
				this.loadingEmailSend = false;
			},
		});
	}

	public BackToMismachim() {
		this.router.navigate(['mismachim']);
	}
}
