<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToAdmin()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">חשבוניות יומי</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" style="margin-top: 40px">
	<div class="content">
		<form [formGroup]="form" dir="rtl">
			<div class="row flex gap flex-wrap">
				<!-- <mat-form-field color="primary" style="max-width:30%">
			  <mat-label>לפי תאריך</mat-label>
			  <input readonly="true" formControlName="Date" (dateChange)="ChooseDate()" matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilterFunction" (click)="picker.open()" />
			  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			  <mat-datepicker #picker ></mat-datepicker>
			</mat-form-field> -->
				<mat-form-field color="primary">
					<mat-label>תקופה</mat-label>
					<mat-error *ngIf="form.controls.from.invalid">תאריך אינו תקין</mat-error>
					<mat-date-range-input [rangePicker]="picker" [min]="dates.min" [max]="dates.max" (click)="picker.open()" [disabled]="loading || Second || Third">
						<input matStartDate readonly="true" formControlName="from" />
						<input matEndDate readonly="true" formControlName="to" (dateChange)="changeDate()" />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>

				<button mat-raised-button [disabled]="loading || Second || Third" (click)="ResetDate()" style="height: 50%; align-self: center" color="primary">היום</button>
				<!-- <button mat-raised-button (click)="ResetDate()" style="height:50%;align-self: center;" color="primary">כל המכירות</button> -->
				<div style="margin-top: 10px; font-size: 15.5px; font-weight: 425" *ngIf="!loading">סה"כ: {{ this.Total | number : '1.0-2' : 'en-US' }}</div>
			</div>
		</form>

		<div class="report-table__row report-table__row_between"></div>
		<div class="report-table__row"></div>

		<div class="report-table__row report-table__row_full-width">
			<h1 *ngIf="loading">טוען...</h1>
			<table class="report-table__table" cellspacing="0" *ngIf="First && !loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-quantity">סוכן</th>
						<th class="report-table__table-head-quantity">סה"כ {{ customDate ? 'תקופה' : 'יומי' }}</th>
						<th class="report-table__table-head-quantity" *ngIf="!customDate">תאריך</th>
						<th class="report-table__table-head-quantity" *ngIf="shouldShowCompanies > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let inv of invoicesDisplay$ | async" (click)="showInvoices(inv.agent)">
						<td class="report-table__table-body-quantity">{{ inv.agent + ' - ' + inv.agentname }}</td>
						<td class="report-table__table-body-quantity">{{ inv.sum | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-quantity" *ngIf="!customDate">{{ inv.date }}</td>
						<!-- <td class="report-table__table-body-quantity" *ngIf="shouldShowCompanies>1">{{inv.cm}}</td> -->
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="Second === true">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-quantity" style="width: 150px">לקוח</th>
						<th class="report-table__table-head-quantity">סה"כ</th>
						<th class="report-table__table-head-quantity">מספר תעודה</th>
						<th class="report-table__table-head-quantity">סוג תעודה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let inv of secondPage$ | async" (click)="showOneInvoice(inv.invoiceID)">
						<td class="report-table__table-body-quantity">{{ inv.clientID + ' - ' + inv.clientName }}</td>
						<td class="report-table__table-body-quantity">{{ inv.totalSum | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-quantity">{{ inv.invoiceID }}</td>
						<td class="report-table__table-body-quantity">{{ inv.invoiceTypeName }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="Third === true">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-code" style="width: 150px">שם פריט</th>
						<th class="report-table__table-head-code" style="width: 150px">קוד פריט</th>
						<th class="report-table__table-head-code" style="width: 80px">יח</th>
						<th class="report-table__table-head-code" style="width: 80px">אר</th>
						<!-- <th class="report-table__table-head-code"> כ. אר</th> -->
						<th class="report-table__table-head-code" style="width: 90px">מחיר</th>
						<th class="report-table__table-head-code" style="width: 80px">הנחה</th>
						<th class="report-table__table-head-code">סה"כ</th>
						<th class="report-table__table-head-code">הערה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let inv of thirdPage$ | async">
						<td class="report-table__table-body-code">{{ inv.DocItemName }}</td>
						<td class="report-table__table-body-code">{{ inv.DocItemCode }}</td>
						<td class="report-table__table-body-code">{{ inv.DocQuantity }}</td>
						<td class="report-table__table-body-code">{{ inv.DocPackQuantity }}</td>
						<!-- <td class="report-table__table-body-code">{{inv.doc}}</td> -->
						<td class="report-table__table-body-code">{{ inv.DocPrice }}</td>
						<td class="report-table__table-body-code">{{ inv.DocDiscount }}</td>
						<td class="report-table__table-body-code">{{ inv.DocTotalRow }}</td>
						<td class="report-table__table-body-code">{{ inv.DocItemRemark }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
