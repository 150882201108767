import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { compress, decompress } from 'lz-string';
import { filter, fromEvent, merge } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppStyleService } from './services/app-style.service';
import { OnlineStatusService } from './services/online-status.service';
import { StoreService } from './services/store.service';

@Component({
	selector: 'app-root',
	styles: [''],
	template: `<router-outlet></router-outlet>
		<app-loading></app-loading>
		<a href="https://wa.me/{{ _ss.whatsappNumber$ | async | unformatPhoneNumber }}" *ngIf="_ss.hasWhatsappIcon$ | async" target="_blank" rel="noopener noreferrer">
			<img src="/assets/images/client-profile/whatsapp-icon.svg" class="whatsapp-icon" alt="image" [ngClass]="{ 'whatsapp-icon-moved': ['/branches-lookup'].includes(_router.url) }" />
		</a> `,
})
export class AppComponent implements OnInit {
	router$;
	historyChange$;

	constructor(private _title: Title, public _ss: StoreService, public _router: Router, private _style: AppStyleService, private _date: DateAdapter<any, 'he-IL'>) {}

	ngOnInit() {
		this._date.setLocale('he-IL');
		if (!this._ss.CustomerInformation) {
			const ls = localStorage.getItem('CustomerInformation');

			if (ls) {
				this._ss.CustomerInformation = JSON.parse(decompress(ls));
			}
		}

		// const root: HTMLElement = document.querySelector(':root');

		// if (root){
		// 	root.style.setProperty('--base-font-size', environment.baseFontSize);
		// }

		this._style.handleSetCSSVariablesBasedOnCustomerParameters();

		if (window.location.href.includes('test.')) {
			this._title.setTitle('Test Env');
		} else {
			this._title.setTitle(environment.production ? 'Mobility' : 'Localhost');
		}

		this.router$ = this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			document.body.classList.remove('no-scroll');

			window.scrollTo(0, 0);

			this._ss.headerRelative = this._ss.headerAbsolute = this._ss.burgerMenuNoScroll = this._ss.burgerMenu = false;
		});

		const minute = 1000 * 60;

		const hour = minute * 60;

		setInterval(() => {
			if (!this._ss.CustomerInformation) return;

			if (this._ss.CustomerInformation.UpdateLogic === '2' || this._ss.CustomerInformation.UpdateLogic === '3') {
				this._ss.GetAllClients().subscribe(res => {
					if (typeof res === 'string') return;

					localStorage.setItem('AllClients', compress(JSON.stringify(res.recordset)));
				});
			}
		}, hour);

		setInterval(() => {
			if (!this._ss.CustomerInformation) return;

			if (this._ss.CustomerInformation.UpdateLogic === '2' || this._ss.CustomerInformation.UpdateLogic === '3') {
				this._ss.GetAllProducts().subscribe({
					next: res => {
						if (typeof res === 'string') return;

						localStorage.setItem('AllProducts', compress(JSON.stringify(res.recordset)));
					},
				});
			}
		}, hour + minute * 5);
	}
}
