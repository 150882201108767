<div class="main-content">
	<header class="header" style="position: relative" #header>
		<div class="content">
			<div class="header__block row">
				<div class="header__arrow col image" (click)="history.back()">
					<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
				</div>
				<div class="header__title col" style="line-height: 18px">ניהול סוכנים - {{ this._ss.CustomerInformation.CustName }}</div>
				<div class="header__burger col image" (click)="Restart()" style="text-decoration: underline">
					<b> איפוס </b>
				</div>
			</div>
		</div>

		<div class="report-table__row">
			<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
				<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="search()">
					<img src="/assets/images/header-search.svg" alt="search" />
				</button>
				<input dir="rtl" class="header__search-input" (keyup)="search()" type="text" placeholder="חפש סוכן" [formControl]="searchControl" style="text-align: center" />
				<button class="header__clear-icon" (click)="searchClear()"></button>
			</div>
			<!-- <h3 class="report-table__row__totals" *ngIf="!Loading">סה"כ: <span>{{total$ | async | number : '1.0-2'}}</span></h3> -->
		</div>
	</header>

	<div class="flex-row" *ngIf="(device.isWebview$ | async) === false" #excelButtonBlock>
		<app-excel-button [downloadFunction]="downloadExcel" [agents$]="agents$"></app-excel-button>
	</div>

	<div class="dashboard-tables__row dashboard-tables__row_scroll">
		<table class="dashboard-tables__table-big table" cellspacing="0" cellpadding="0" style="max-width:98vw">
			<thead class="table__head" #thead>
				<tr>
					<th class="dashboard-tables__table-big-column_name table__head-column sortable-clickable" (click)="translateForSort('UserName', 'Agents')" #tableRight>שם סוכן</th>
					<th class="dashboard-tables__table-big-column_name table__head-column sortable-clickable" (click)="translateForSort('AgentID', 'Agents')">קוד סוכן</th>
					<th
						class="dashboard-tables__table-big-column_name table__head-column sortable-clickable"
						*ngIf="hasB2BAgentResponsibleColumnArrivingFromSQLTable"
						(click)="translateForSort('ClientAgent', 'Agents')"
					>
						סוכן B2B
					</th>
					<th class="dashboard-tables__table-big-column_name table__head-column sortable-clickable">סיסמה</th>
					<th class="dashboard-tables__table-big-column_name table__head-column sortable-clickable" (click)="translateForSort('Logged', 'Agents')">סטטוס</th>
					<th class="dashboard-tables__table-big-column_name table__head-column sortable-clickable" (click)="translateForSort('Admin', 'Agents')">מנהל</th>
					<th class="dashboard-tables__table-big-column_name table__head-column">נתק סוכן</th>
					<th class="dashboard-tables__table-big-column_name table__head-column" *ngIf="isPasswordChangeEndpointAvailable">שנה סיסמה</th>
				</tr>
			</thead>
			<tbody id="first-table" class="table__body">
				<tr *ngFor="let a of agents$ | async">
					<td class="dashboard-tables__table-big-column_name table__head-column">{{ a.UserName }}</td>
					<td class="dashboard-tables__table-big-column_name table__head-column">{{ a.AgentID }}</td>
					<td class="dashboard-tables__table-big-column_name table__head-column" *ngIf="hasB2BAgentResponsibleColumnArrivingFromSQLTable">{{ a.ClientAgent }}</td>
					<td class="dashboard-tables__table-big-column_name table__head-column">{{ a.Password }}</td>
					<td class="dashboard-tables__table-big-column_name table__head-column">{{ a.Logged }}</td>
					<td class="dashboard-tables__table-big-column_name table__head-column">{{ a.Admin }}</td>
					<td class="dashboard-tables__table-big-column_name table__head-column dashboard-tables__table-column_button" (click)="AdminAgentLoggout(a.AgentID)">
						<div class="dashboard-tables__table-button invalid-color">נתק</div>
					</td>
					<td class="dashboard-tables__table-big-column_name table__head-column" (click)="handlePasswordChangePopupOpen(a)" *ngIf="isPasswordChangeEndpointAvailable">
						<div class="dashboard-tables__table-button" style="color: rgb(50, 50, 255)">שנה</div>
					</td>
				</tr>
			</tbody>
		</table>
		<h2 *ngIf="Loading === true" style="text-align: right; direction: rtl">טוען...</h2>
	</div>
</div>

<div class="popup" *ngIf="passChangePopupOpen" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="popup__close" (click)="handlePasswordChangePopupClose()"></div>

		<h2 style="text-align: center">בחר סיסמה חדשה עבור סוכן {{ chosenAgentId }} - {{ chosenAgentName }}</h2>
		<h4 style="text-align: center">אורך מינימלי של 3 תווים</h4>

		<form [formGroup]="form" dir="rtl">
			<input
				type="text"
				formControlName="password"
				id="password"
				[placeholder]="'סיסמה ישנה: ' + originalPassword"
				style="width: 99%; margin: auto; border: 1px solid black; height: 40px; font-size: 20px"
				dir="rtl"
				maxlength="255"
			/>
		</form>

		<div style="display: flex" *ngIf="!changedPassword; else elseBlock">
			<button
				class="product-detail-add__popup-button"
				(click)="handlePasswordChange()"
				style="background-color: #38d88b; margin: auto; height: 30px; width: 60px"
				*ngIf="form.controls.password.valid"
			>
				אישור
			</button>

			<button class="product-detail-add__popup-button" (click)="handlePasswordChangePopupClose()" style="background-color: red; margin: auto; float: right; height: 30px; width: 60px">חזור</button>
		</div>

		<ng-template #elseBlock> <h2>סיסמה שונתה בהצלחה! ניתן לסגור את החלון כעת.</h2></ng-template>
	</div>
</div>
