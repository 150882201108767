import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';

export type SliderToggleChangeEvent = 'label' | 'prefix';

@Component({
	selector: 'app-slider-toggle',
	templateUrl: './slider-toggle.component.html',
	styleUrls: ['./slider-toggle.component.scss'],
})
export class SliderToggleComponent {
	@ViewChild('slider') slider: MatSlideToggle;

	@Input() label: string = '';
	@Input() prefixLabel: string = '';

	@Input() color: 'primary' | 'accent' | 'warn' = 'primary';

	@Output() checkedChange: EventEmitter<'label' | 'prefix'> = new EventEmitter();

	checked = false;

	constructor() {}

	handleChange() {
		this.emitChange();
	}

	toggle() {
		this.slider.toggle();
		this.emitChange();
	}

	emitChange() {
		this.checkedChange.emit(this.slider.checked ? 'label' : 'prefix');
	}
}
