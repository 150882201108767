import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { decompress } from 'lz-string';
// import jspdf from 'jspdf';
// import html2canvas from 'html2canvas';
import { debounceTime, distinctUntilChanged, fromEvent, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';

@Component({
	selector: 'app-dashboard-receipts',
	templateUrl: './dashboard-receipts.component.html',
	styleUrls: ['./dashboard-receipts.component.scss'],
})
export class DashboardReceiptsComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _receipts: ReceiptsService, private _acr: ActivatedRoute) {}

	minDate = new Date();

	public form = this._fb.group({
		Search: [''],
		FromDate: ['', Validators.required],
		ToDate: ['', [Validators.required, Validators.min(this.minDate.getTime())]],
		Agent: [null],
		Status: [null],
	});
	public Teudot;
	teudotOriginalResponse;
	public AllTeudotReset;
	public TeudotEach;
	public TeudotEachString;
	public Main = true;
	public Each = false;
	public Last = false;
	public Loading = true;
	public LoadingSeeTeuda = false;
	public SeeTeudaLoaded = false;
	public Agents;
	public TeudotTableEach;
	public LoaingConfirm = false;
	public TotalArrayChecked = 0;
	public TotalArray = 0;
	public ArrayChecked = [];
	public HasMultipleCompanys;
	public SeeTrucks = '0';
	public Admin;
	public LocalSaveTrucksSent = [];
	public LocalSaveTrucksSentHelp;

	public AgentsForAdminArray;
	public FilterTeudot = [];

	receipts: any[] = [];

	showLogoutPopup = false;

	today = new Date();
	windowWidth: number;
	movingOffset = { x: 0, y: 0 };
	document = document;

	agentsToGet = [];

	allTeudotAgents = [];

	LoadingBack = false;

	showFilterPopup = false;

	// Doc: Set<string> = new Set();
	// DocArr = [];

	AgentSet: Set<string> = new Set();
	AgentArray = [];

	statusSet: Set<string> = new Set();
	statusArray = [];

	destroy$: Subject<void> = new Subject();

	allClients: AllClients[] = [];

	scrollY = 0;

	history = history;

	isAdmin = !location.pathname.includes('agent');

	isReceiptPopupOpen = false;

	selectedReceipt: any;

	// @ViewChild('tableDivToScroll') tableDivToScroll: ElementRef<HTMLTableRowElement>

	ngOnInit(): void {
		this._ss.OneClientInfoDebt = null;

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		if (this.Admin !== 'true' && this.isAdmin && !this._receipts.hasReceipts && !this._receipts.adminCanDeleteAndEditReceipt) {
			this.router.navigate(['clients']);
			return;
		}

		this._receipts.refreshReceiptsParameters();

		if (localStorage.getItem('CustNum') !== null) {
			this._ss.CustNum = localStorage.getItem('CustNum');
		}

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		this.windowWidth = window.innerWidth;

		window.scrollTo(0, 0);

		if (this._ss.CustomerInformation.Submanagers == '1') {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res == 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							return;
						}

						this.agentsToGet = res.recordset.map(obj => obj.Agent);
						this.agentsToGet.push(this._ss.AgentIdConnected);

						this.getAllReceipts();
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getAllReceipts();
			}
		} else {
			this.getAllReceipts();
		}

		this.form.controls.Search.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(val => {
			this.TotalArray = 0;
			this.TotalArrayChecked = 0;
			this.ArrayChecked = [];
			this.receipts = [];

			this.form.controls.Status.reset();
			this.form.controls.Agent.reset();
			this.form.controls.FromDate.reset();
			this.form.controls.ToDate.reset();

			if (val) {
				const splitValue = val.split(' ');

				for (let i = 0; i < this._receipts.receipts.length; i++) {
					let counter = 0;

					const { Client_Name, DocNewNum, DocNum, ClientID } = this._receipts.receipts[i][0];

					const Client_NameWithoutSpaces = (Client_Name || '').replace(/\s/g, '').toLowerCase(),
						DocNewNumWithoutSpaces = (DocNewNum || '').replace(/\s/g, '').toLowerCase(),
						DocNumWithoutSpaces = (DocNum || '').replace(/\s/g, '').toLowerCase(),
						ClientIDWithoutSpaces = (ClientID || '').replace(/\s/g, '').toLowerCase(),
						allStrings =
							typeof Client_NameWithoutSpaces === 'string' && typeof DocNewNumWithoutSpaces === 'string' && typeof ClientIDWithoutSpaces === 'string' && typeof DocNumWithoutSpaces === 'string';

					// V8 optimizes this if statement, maybe
					if (allStrings) {
						for (let j = 0; j < splitValue.length; j++) {
							if (
								(Client_NameWithoutSpaces && Client_NameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
								(DocNewNumWithoutSpaces && DocNewNumWithoutSpaces.indexOf(splitValue[j]) > -1) ||
								(ClientIDWithoutSpaces && ClientIDWithoutSpaces.indexOf(splitValue[j]) > -1) ||
								(DocNumWithoutSpaces && DocNumWithoutSpaces.indexOf(splitValue[j]) > -1)
							) {
								counter++;
							}
						}
					}

					if (counter === splitValue.length) {
						this.receipts.push(this._receipts.receipts[i]);
					}
				}

				// this.receipts = this._receipts.receipts.filter(rcp =>
				// 	new RegExp(val.toLowerCase().replace(/[\/*\+\-\?,]/g, ''), 'g').test(('' + rcp[0].Client_Name + rcp[0].DocNum + rcp[0].DocNewNum).toLowerCase().replace(/[\/*\+\-\?,]/g, '')),
				// );

				for (let x = 0; x < this.receipts.length; x++) {
					this.TotalArray += this.receipts[x][0].DocTotal;
				}
				return;
			}

			this.receipts = this._receipts.receipts;

			for (let x = 0; x < this.receipts.length; x++) {
				this.TotalArray += this.receipts[x][0].DocTotal;
			}

			this.document.querySelectorAll('input[type="checkbox"]').forEach(chckbx => ((chckbx as HTMLInputElement).checked = false));
		});

		this.form.controls.FromDate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.form.controls.ToDate.reset();

			this.minDate = new Date(val);
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.document.body.classList.remove('no-scroll');
	}

	shouldClientCardPopupShow = false;

	openClientCardPopup(teuda) {
		this.scrollY = scrollY;

		const client = this.allClients.find(ac => ac.Client_Name == teuda.Client_Name);

		if (client) {
			document.body.classList.add('no-scroll');
			this.shouldClientCardPopupShow = true;
			this._ss.OneClientInfo = client;

			setTimeout(() => {
				window.scrollTo({ top: this.scrollY });
			}, 0);
		}
	}

	async getAllReceipts() {
		try {
			let response;
			await new Promise<void>((resolve, rej) => {
				const options = { isAdmin: this.isAdmin };
				this._receipts.getAllReceipts(options).subscribe({
					next: (res: any) => {
						console.log(typeof res);
						if (typeof res === 'string') {
							return rej();
						}

						response = res;

						resolve();
					},
					error: err => {
						return rej();
					},
				});
			});

			this.receipts = this._receipts.receipts = Object.values(
				response.recordset.reduce((prev, curr) => {
					if (prev[curr.DocNum]) {
						prev[curr.DocNum].push(curr);
					} else {
						prev[curr.DocNum] = [curr];
					}

					return prev;
				}, {}),
			);

			this.receipts.sort((a, b) => new Date(b[0].CreateDate).getTime() - new Date(a[0].CreateDate).getTime());

			for (let i = 0; i < this.receipts.length; i++) {
				const element = this.receipts[i];

				// cheque mark if at least one of the payments is cheque, otherwise cash
				element.cheque = element.find(el => el.Sug === 2);

				// Sets for search parameters
				// this.Doc.add(element[0].TeudaTypeName);
				this.AgentSet.add(element[0].AgentID);
				this.statusSet.add(element[0].TeudaStatus);

				this.TotalArray += element[0].DocTotal;
			}

			// this.DocArr = [...this.Doc];
			this.AgentArray = [...this.AgentSet];
			this.statusArray = [...this.statusSet];

			if (this._acr.snapshot.queryParamMap.has('date')) {
				const { Search, Agent, Status } = this.form.value;
				this.form.setValue({ Search: Search || '', Agent: Agent || [], Status: Status || [], FromDate: this.minDate.toISOString().slice(0, 10), ToDate: this.minDate.toISOString().slice(0, 10) });
				this.handleAdvancedSearch();
			}
		} catch (error) {
			alert('חלה תקלה בהבאת קבלות. נסו שנית מאוחר יותר ');
			console.log({ error });
			history.back();
		}
	}

	dateInvalid = false;

	handleDateChange(val) {
		if (val == 'from') {
			this.dateInvalid = this.form.controls['FromDate'].invalid || this.form.controls['ToDate'].invalid;
		} else {
			if (!this.form.controls['FromDate'].value || new Date(this.form.controls['FromDate'].value).getTime() > new Date(this.form.controls['ToDate'].value).getTime()) {
				const msg = !this.form.controls['FromDate'].value ? "ערך 'מתאריך' אינו תקין" : "ערך 'עד תאריך' אינו יכול להיות קטן מערך 'מתאריך'";
				alert(msg);
				this.dateInvalid = true;
				this.form.controls['ToDate'].reset();
			} else {
				this.dateInvalid = false;
			}
		}
	}

	handleAdvancedSearch() {
		// Make search input text disappear without triggering valueChanges()
		(this.document.querySelector('#search-Input') as HTMLInputElement).value = '';

		this.TotalArray = 0;

		const { FromDate, ToDate, Agent, Status } = this.form.value;

		console.log(this.form.value);

		this.receipts = this._receipts.receipts;

		const day = 1000 * 60 * 60 * 24;

		//Can't submit without both, but whatever
		if (FromDate && ToDate) {
			// Changed from TeudaSupplyDate
			const dateToSearch = 'CreateDate';

			this.receipts = this.receipts.filter(
				td => new Date(td[0][dateToSearch]).getTime() >= new Date(FromDate).getTime() && new Date(td[0][dateToSearch]).getTime() <= new Date(ToDate).getTime() + day,
			);
		}

		if (Agent && Agent.length) {
			this.receipts = this.receipts.filter(td => Agent.includes(td[0].AgentID));
		}

		if (Status && Status.length) {
			this.receipts = this.receipts.filter(td => Status.includes(td[0].TeudaStatus));
		}

		this.showFilterPopup = false;

		for (let x = 0; x < this.receipts.length; x++) {
			this.TotalArray += this.receipts[x][0].DocTotal;
		}
	}

	SearchClear() {
		if (this.form.controls['Search'].value) {
			this.form.controls['Search'].reset();
		} else {
			this.form.controls['Status'].reset();
			this.form.controls['DocType'].reset();
			this.form.controls['Agent'].reset();
			this.form.controls['FromDate'].reset();
			this.form.controls['ToDate'].reset();

			this.clearEverything();
		}
	}

	//TODO refactor into two different methods

	clearEverything() {
		this.TotalArray = 0;
		this.TotalArrayChecked = 0;
		this.ArrayChecked = [];
		this.receipts = [];

		this.receipts = this._receipts.receipts;

		for (let x = 0; x < this.receipts.length; x++) {
			this.TotalArray += this.receipts[x][0].PayTotal;
		}
	}

	@ViewChild('receiptTableNames') rightSideOfReceiptTable: ElementRef<HTMLTableCellElement>;

	public Restart() {
		window.location.reload();
	}

	public selectedTeudaToOpenPopup = null;
	public seeTeuda(index) {
		this.isReceiptPopupOpen = true;
		this.selectedReceipt = this.receipts[index];

		console.log({ object: this.selectedReceipt });

		setTimeout(() => {
			this.rightSideOfReceiptTable.nativeElement.scrollIntoView();
		}, 10);
	}

	public AdminConfirmOrder(num) {
		this.LoaingConfirm = true;

		this._receipts.changeStatusOfReceipt(num, 3).subscribe({
			next: res => {
				this.LoaingConfirm = false;

				if (typeof res === 'object') {
					this.receipts = this._receipts.receipts = this.receipts.map(receipt => {
						if (receipt[0].DocNum === num) {
							for (let i = 0; i < receipt.length; i++) {
								const element = receipt[i];
								element.TeudaStatus = 3;
							}
						}
						return receipt;
					});
					alert('אישור קבלה בוצע בהצלחה');

					return;
				}
				alert('שגיאת שרת');
			},
			error: err => {
				alert('שגיאת שרת');
				this.LoaingConfirm = false;
			},
		});
	}

	adminDeleteReceipt(DocNum) {
		const decision = confirm('מחיקת קבלה הינה בלתי הפיכה, האם להמשיך?');
		if (!decision) return;

		this.LoaingConfirm = true;

		this._receipts.deleteReceipt(DocNum).subscribe({
			next: res => {
				this.LoaingConfirm = false;

				if (typeof res === 'object') {
					this.receipts = this._receipts.receipts = this.receipts.filter(receipt => receipt[0].DocNum !== DocNum);
					alert('מחיקת קבלה בוצעה בהצלחה');

					return;
				}
				alert('שגיאת שרת');
			},
			error: err => {
				alert('שגיאת שרת');
				this.LoaingConfirm = false;
			},
		});
	}
}
