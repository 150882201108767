import { Component, ElementRef, inject, NgZone, OnDestroy, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { NgUpdateService } from 'src/app/services/ng-update.service';
import { OnlineStatusService } from 'src/app/services/online-status.service';
import { BaseComponent } from '../base-component/base.component';
import { environment } from 'src/environments/environment';
import { LikutService, LIKUT_ADMIN_TYPES } from 'src/app/services/likut.service';
import { MatSelectChange } from '@angular/material/select';
import { AdminTypes, Users } from 'src/app/Interfaces/users';
import { TruckLoadingService, TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES } from 'src/app/services/truck-loading.service';
import { TextService } from 'src/app/services/text.service';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, combineLatest, EMPTY, fromEvent, interval, map, mergeMap, Observable, of, race, startWith, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { Time, UtilService } from 'src/app/services/util.service';
import { TruckLoadingDeliveryLinesResponse, TruckLoadingNavigation } from 'src/app/Interfaces/truck-loading';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { DOCUMENT } from '@angular/common';
import { B2bService } from 'src/app/services/b2b.service';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { LikutNavigation } from 'src/app/Interfaces/likut';
import { TruckDistributionNavigation, TruckDistributionUsers } from 'src/app/Interfaces/truck-distribution';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { UserService } from 'src/app/services/user.service';
import { IndexedDBService } from 'src/app/services/indexed-db.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubAppModes } from '../likut-clients/likut-clients.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppStyleService } from 'src/app/services/app-style.service';
import { AlertService } from 'src/app/services/alert.service';
import { CustomerInformationOverrideModalComponent } from './customerInformationPopup.component';
import { MatDialog } from '@angular/material/dialog';
import { RallyanceReclamationNavigation } from 'src/app/Interfaces/reclamations';
import { RALLYANCE_RECLAMATION_APP_PATH } from 'src/app/services/reclamation.service';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { DateAdapter } from '@angular/material/core';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { translateDateForSQLServer } from 'src/app/Utility/functions';
import { LoadingStatuses } from 'src/app/Interfaces/util';
import { DeviceInfoService } from 'src/app/services/device-info.service';

type SubAppNavigation = LikutNavigation | TruckLoadingNavigation | TruckDistributionNavigation | RallyanceReclamationNavigation;
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
	constructor(
		private _fb: FormBuilder,
		protected _update: NgUpdateService,
		protected router: Router,
		protected _online: OnlineStatusService,
		protected _likut: LikutService,
		protected _truckLoading: TruckLoadingService,
		protected _truckDistribution: TruckDistributionService,
		protected _text: TextService,
		protected _cookies: CookieService,
		protected _util: UtilService,
		protected _receipts: ReceiptsService,
		public _b2b: B2bService,
		public _user: UserService,
		private _IDB: IndexedDBService,
		private _storage: StorageService,
		private _style: AppStyleService,
		public _alert: AlertService,
		private dialog: MatDialog,
		private _cc: CreditCardService,
		private _date: DateAdapter<any, 'he-IL'>,
		public _device: DeviceInfoService,
	) {
		super();
	}

	protected form = this._fb.nonNullable.group({
		disconnectInput: ['', Validators.required],
	});

	logo = MOBILITY_LOGO;

	online = navigator.onLine;
	DocsType;
	GroupName;
	AllClients = null;
	AllClientsMatch;
	AllClientsSerach;
	ShowOptions = false;

	Date;
	DateUpdate = new Date();

	adminTypes = [...TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES, ...LIKUT_ADMIN_TYPES];

	UpdateSystem = false;

	datedate = '';
	datetime = 'לא מעודכן';

	Loading = false;

	DisconnectPop = false;

	Status = '...שולח תעודות';
	StatusTwo = null;
	HasMultipleCompanys;
	Spec;

	LoggoutPop = false;
	Admin: AdminTypes | null = this._storage.getAndParseFromStorage('Admin', { parseJSON: false, decompressLZ: false }) as null | AdminTypes;
	isBIUser = false;

	isCatalogUser = false;

	Doh = false;

	ChangeCustNum = false;

	resa;
	erra;

	CustomerInformation;

	Privacy = false;

	shouldShowMessagesInsteadOfButtons = true;

	document = document;

	battery: any = 0;

	Updating = false;

	isProduction = environment.production;

	isNewTruckDistributionTaskPopupOpen$ = new BehaviorSubject(false);

	reportsEndpointsArray = new Array(11).fill(`${this._ss.CustomerInformation?.CustServer}/mobil/reports/dynamic-reports/report`).map((endpoint, i) => endpoint + i + '?checkIfExistsInIndex=true');

	private destroy$ = new Subject<void>();

	truckDistributionNewTaskForm = this._fb.nonNullable.group({
		client: [<string>'', [Validators.required, this.validateClient.bind(this)]],
		agent: [<string>'', [Validators.required, this.validateAgent.bind(this)]],
		date: [new Date(), Validators.required],
		typeofTask: ['', Validators.required],
		remarks: [''],
	});
	allClients$ = new BehaviorSubject<AllClients[]>([]);
	allAgents$ = this._truckDistribution.usersForAssignment$;

	truckDistributionNewTaskSubmitStatus$ = new BehaviorSubject<LoadingStatuses>('idle');

	allClientsDisplay$ = combineLatest([this.truckDistributionNewTaskForm.controls.client.valueChanges.pipe(startWith('')), this.allClients$]).pipe(
		map(([value, clients]) => this._filter<AllClients>(value || '', clients, ['ClientId', 'Client_Name'])),
	);
	allAgentsDisplay$ = combineLatest([this.truckDistributionNewTaskForm.controls.agent.valueChanges.pipe(startWith('')), this.allAgents$]).pipe(
		map(([value, agents]) => this._filter<TruckDistributionUsers>(value || '', agents, ['AgentID', 'UserName'])),
	);

	today = new Date();

	typeofTasks$ = new BehaviorSubject([
		{ type: '1', name: 'קבלה' },
		{ type: '2', name: 'החזרה' },
	]);

	showSuppliersButton = false;
	showClientsButton = true;

	willNeedToUpdate = false;

	noNewVersion = false;
	isLikutAppActive = false;
	isTruckModeActive = false;
	isB2BAgent = false;
	isB2BAgentNoPricesAndNoParameters = false;
	hasRallyanceReclamation = this._user.isRallyanceReclamator$.value;
	rallyanceReclamationPath = RALLYANCE_RECLAMATION_APP_PATH;

	async ngOnInit() {
		this._date.setLocale('he-IL');
		this.initiateSubscribers();
		this.setupClickTracker();
		this._b2b.refreshAdmin();

		if (this._ss.CustomerInformation?.HasForceDisconnect === '1' && this._ss.AgentIdConnected) {
			this._ss.checkCurrentLoggedStatusForUser(this._ss.AgentIdConnected).subscribe({
				next: res => {
					if (typeof res === 'string') {
						return;
					}

					const { Logged } = res.recordset[0];

					if (Logged === '2') {
						this.LoggoutPop = true;
						setTimeout(() => {
							this.deleteAndReload();
						}, 500);
					}
				},
				error: err => {
					return;
				},
			});
		}

		if (this.Admin && this.Admin.toLowerCase().includes('bi')) {
			this.isBIUser = true;
		}

		if (this._ss.CustomerInformation === null) {
			const ls = this._storage.getAndParseFromStorage('CustomerInformation', { onlyCheckIfExist: true });
			if (!ls) {
				this.router.navigate(['login']);
				return;
			}

			this.CustomerInformation = this._ss.CustomerInformation = this._storage.getAndParseFromStorage('CustomerInformation');
		}

		this._ss.CompanyName = null;
		this._ss.CompanyNum = null;

		this.refreshAllParameters();

		this._ss.cleanupReports();
		try {
			// samsung browser doesn't support getBattery, even if it is present in navigator. no error, just early return of ngOnInit
			if (!this._device.isSamsungBrowser$.value || !('getBattery' in window.navigator)) {
				throw new Error();
			}
			const navigatoryOverrideForBattery: any = window.navigator;
			const batteryLevel = await navigatoryOverrideForBattery.getBattery();

			if (batteryLevel) {
				this.battery = batteryLevel.level * 100;
			}
		} catch (error) {
			this.battery = 'לא ניתן לקבל נתונים';
		}

		if (this._likut.isCheckerAgent) {
			this._text.store.homeScreenAction = 'התחל בדיקה';
		}

		this._style.handleSetCSSVariablesBasedOnCustomerParameters();

		if (this._storage.getAndParseFromStorage('res', { onlyCheckIfExist: true })) {
			this.resa = this._storage.getAndParseFromStorage('res', { decompressLZ: false });
		}

		if (this._storage.getAndParseFromStorage('err', { onlyCheckIfExist: true })) {
			this.erra = this._storage.getAndParseFromStorage('err', { decompressLZ: false });
		}

		if (!this._user.isLoggedIn()) {
			this._storage.clearAllSavedData();
			this.router.navigate(['login']);
		}

		if (!this.online) {
			await new Promise<void>(resolve => {
				this._ss.checkConnection().subscribe({
					next: res => {
						resolve();
					},
					error: err => {
						console.log({ err: err.error });
						const errReason = err.error || err;
						const isExpress404ErrorButHasConnection = typeof errReason === 'string' && errReason.includes('Cannot GET');
						if (isExpress404ErrorButHasConnection) {
							console.log('online');
							this.online = true;
						}
						resolve();
					},
				});
			});
		}

		this._ss.UserNameConnected = this._storage.getAndParseFromStorage('UserName', { parseJSON: false, decompressLZ: false }) as string;
		this._ss.AgentIdConnected = this._storage.getAndParseFromStorage('AgentId', { parseJSON: false, decompressLZ: false }) as string;

		if (this._storage.getAndParseFromStorage('AllClients', { onlyCheckIfExist: true })) {
			this.AllClients = this._storage.getAndParseFromStorage('AllClients');
			this.determineWhetherShouldShowSuppliersButton();
		}

		this.HasMultipleCompanys = this._storage.getAndParseFromStorage('HasMultipleCompanys', { parseJSON: false, decompressLZ: false }) as string;

		if (this.HasMultipleCompanys === '2') {
			this._ss.AllClientsHold = null;
		}

		window.scrollTo(0, 0);

		if (!this._ss.CustomerInformation.CustNum) {
			this.router.navigate(['login']);
		}

		if (!this._ss.isLocalhost) {
			this._update.checkNewVersion();
			await this._update.isNewUpdateActivated();

			// if (this._update.isNewUpdateActivated()) {
			// 	return location.reload();
			// }
		}

		this.willNeedToUpdate = this.resolveWhetherWillNeedToUpdate();

		if (this.isBIUser && !this._ss.biUserAvailableReports.length) {
			for (const response of await Promise.allSettled(this.reportsEndpointsArray.map(endpoint => fetch(endpoint)))) {
				if (response.status === 'fulfilled' && response.value.ok) {
					const reportName = response.value.url.split('/')[response.value.url.split('/').length - 1];
					this._ss.biUserAvailableReports.push(reportName.split('?')[0]);
				}
			}

			const names = await fetch(`${this._ss.CustomerInformation?.CustServer}/mobil/reports/dynamic-reports/report-names`);

			if (names.ok) {
				const body = await names.json();
				this._ss.biUserAvailableReports = (this._ss.biUserAvailableReports as string[]).map(report => body.recordset.find(b => b.ID === report.split('report')[report.split('report').length - 1]));
			}

			console.log(this._ss.biUserAvailableReports);
		}

		if (this._storage.getAndParseFromStorage('lastupdate', { onlyCheckIfExist: true })) {
			this.Date = this._storage.getAndParseFromStorage('lastupdate', { decompressLZ: false });
			this.datedate = this.Date.toString().slice(0, 10);
			this.datetime = this.Date.toString().slice(11, 20);

			if (this._ss.CustomerInformation.CheckPassword === '1') {
				await this.getUserInfo();
			}

			if (this.Date.toString().slice(0, 10) !== this.DateUpdate.toISOString().toString().slice(0, 10)) {
				// this.ShowOptions = false;

				this._ss.GetGroups().subscribe(
					res => {
						this.GroupName = res;
						this.GroupName = this.GroupName.recordset;

						if (!this.GroupName || res === 'RequestError' || res === 'ConnectionError') {
							this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('departments');
							return;
						}

						this._storage.storeLocalDBUpdate('GroupName', this.GroupName, { compressLZ: false });

						let method = this._ss.GetAllClients();

						if (this._likut.isMekabel) {
							method = this._ss.getLikutMekabelSuppliers();
						}

						method.subscribe(
							res => {
								this.AllClients = res;
								this.AllClients = this.AllClients.recordset;

								this.determineWhetherShouldShowSuppliersButton();

								if (!this.AllClients || res === 'RequestError' || res === 'ConnectionError') {
									this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('clients');
									return;
								}

								this._storage.storeLocalDBUpdate('Updating', true, { compressLZ: false });
								this.Updating = true;
								this.willNeedToUpdate = true;

								this.deleteLocalstorageAfterFetchingAllClients();
								this._storage.storeLocalDBUpdate('AllClients', this.AllClients);
								this.refreshLastUpdate();
								setTimeout(() => {
									this.ShowOptions = true;
								}, 1000);
							},
							err => {
								this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('clients');
							},
						);
					},
					err => {
						this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('departments');
					},
				);
			} else {
				this.ShowOptions = true;
				this.willNeedToUpdate = false;
			}
		} else {
			// this.ShowOptions = false;

			console.log(performance.now(), 'start');

			// await this.updateCustomerInformation();

			console.log(performance.now(), 'end');

			this._ss.GetGroups().subscribe(
				res => {
					this.GroupName = res;
					this.GroupName = this.GroupName.recordset;

					if (!this.GroupName || res === 'RequestError' || res === 'ConnectionError') {
						this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('departments');
						return;
					}

					this._storage.storeLocalDBUpdate('GroupName', this.GroupName, { compressLZ: false });

					let method = this._ss.GetAllClients();

					if (this._likut.isMekabel && !this._ss.isLocalhost) {
						method = this._ss.getLikutMekabelSuppliers();
					}

					method.subscribe({
						next: (res: any) => {
							this.AllClients = res.recordset;

							this.determineWhetherShouldShowSuppliersButton();

							if (!this.AllClients || res === 'RequestError' || res === 'ConnectionError') {
								this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('clients');
								return;
							}

							this._storage.storeLocalDBUpdate('Updating', true, { compressLZ: false });
							this.willNeedToUpdate = true;
							this.deleteLocalstorageAfterFetchingAllClients();

							this._storage.storeLocalDBUpdate('AllClients', this.AllClients);
							this.refreshLastUpdate();

							setTimeout(() => {
								this.ShowOptions = true;
							}, 1000);
						},
						error: err => {
							this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('clients');
						},
					});
				},
				err => {
					this.refreshLastUpdateLocalstorageAndAlertUserAfterFail('departments');
				},
			);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	changeAdminStatus(string: AdminTypes) {
		this._storage.storeLocalDBUpdate('Admin', string, { compressLZ: false, stringify: false });
		this.Admin = string;
	}

	async deleteAndReload() {
		this._storage.clearAllSavedData();
		location.reload();
	}

	changeAdminAndReload(event: MatSelectChange) {
		const { value } = event;

		this.changeAdminStatus(value);
		location.reload();
	}

	handleSubAppNavigation(options: SubAppNavigation) {
		const { mode, route } = options;
		if (route.includes('/')) {
			this.router.navigate([mode, ...route.split('/')]);
			return;
		}
		this.router.navigate([mode, route]);
	}

	deleteLocalstorageAfterFetchingAllClients() {
		localStorage.removeItem('refresh');
		localStorage.removeItem('SaveMainGroups');
		localStorage.removeItem('AllSavedMahlakot');
	}

	refreshLastUpdateLocalstorageAndAlertUserAfterFail(mode: 'clients' | 'departments') {
		this.refreshLastUpdate();
		if (mode === 'clients') {
			alert('לא התקבלו לקוחות חדשים, ישנה תקלה בשרת');
			setTimeout(() => {
				this.ShowOptions = true;
			}, 1000);
			return;
		}
		alert('לא התקבלו מחלקות חדשות, ישנה תקלה בשרת');
		this.ShowOptions = true;
	}

	refreshLastUpdate() {
		this._storage.storeLocalDBUpdate('lastupdate', this.DateUpdate.toISOString(), { compressLZ: false });
	}

	async updateCustomerInformation() {
		return await new Promise<void>(resolve => {
			this._ss.GetCustInfo(this._ss.CustomerInformation.CustNum).subscribe({
				next: (res: any) => {
					console.log('got custinfo');
					const custinfo = res.recordset;

					if (!custinfo?.length) {
						alert('לא נמצאו פרטי לקוח, אנא התחבר מחדש');
						this.deleteAndReload();
						return;
					}

					this._ss.CustomerInformation = custinfo[0];
					this._storage.storeLocalDBUpdate('CustomerInformation', this._ss.CustomerInformation);
					resolve();
				},
				error: err => {
					resolve();
				},
			});
		});
	}

	async getUserInfo() {
		return await new Promise<void>(resolve => {
			//TODO adjust for storage service
			const username = localStorage.getItem('AgentId');
			const password = localStorage.getItem('Password');

			if (!username || !password) {
				return resolve();
			}

			this._ss.loginService(username, password).subscribe({
				next: (res: any) => {
					console.log(performance.now(), 'user info');

					if (res.recordset && !res.recordset.length) {
						this.deleteAndReload();
						return;
					}

					resolve();
				},
				error: () => {
					resolve();
				},
			});
		});
	}

	async handleGetAllClients() {
		return await new Promise<void>(resolve => {
			this._ss.GetAllClients().subscribe({
				next: (res: any) => {
					if (res && res !== 'RequestError' && res !== 'ConnectionError') {
						if (!res.recordset?.length) {
							alert('לא התקבלו לקוחות');
							this.ShowOptions = true;
							return resolve();
						}
						this.AllClients = res.recordset;

						this.determineWhetherShouldShowSuppliersButton();

						this._storage.storeLocalDBUpdate('AllClients', this.AllClients);

						setTimeout(() => {
							this.ShowOptions = true;
							resolve();
						}, 500);
					} else {
						alert('לא התקבלו לקוחות חדשים, ישנה תקלה בשרת');
						setTimeout(() => {
							this.ShowOptions = true;
							resolve();
						}, 500);
					}
				},
				error: err => {
					alert('לא התקבלו לקוחות חדשים, ישנה תקלה בשרת');

					setTimeout(() => {
						this.ShowOptions = true;
						resolve();
					}, 500);
				},
			});
		});
	}

	DisconnectAndMoveToLogin() {
		this.DisconnectPop = true;
	}

	HelpDisconnect;

	// YesDisconnect() {
	// 	this.Loading = true;

	// 	if (this.online === true) {
	// 		this.LoggoutPop = true;

	// 		this._ss.UpdateUserLogged({ AgentID: this._ss.AgentIdConnected }).subscribe({
	// 			next: res => {
	// 				this._ss.loginServiceDisconnected(this._ss.AgentIdConnected).subscribe((res: any) => {
	// 					this.HelpDisconnect = res.recordset;
	// 					// this.HelpDisconnect = this.HelpDisconnect.recordset

	// 					if (
	// 						this.HelpDisconnect.length === 0 ||
	// 						this.HelpDisconnect === null ||
	// 						this.HelpDisconnect === undefined ||
	// 						this.HelpDisconnect[0].Logged === '1' ||
	// 						res === 'RequestError' ||
	// 						res === 'ConnectionError'
	// 					) {
	// 						alert('אין אפשרות להתנתק, ישנה תקלה בשרת');
	// 						this.Loading = false;
	// 						this.LoggoutPop = false;
	// 						this.DisconnectPop = false;
	// 					} else {
	// 						this._storage.clearAllSavedData();
	// 						setTimeout(() => {
	// 							window.location.reload();
	// 						}, 2500);
	// 					}
	// 				});
	// 			},
	// 			error: err => {
	// 				alert('אין אפשרות להתנתק, ישנה תקלה בשרת');
	// 				this.Loading = false;
	// 				this.LoggoutPop = false;
	// 				this.DisconnectPop = false;
	// 			},
	// 		});
	// 	} else {
	// 		this.Loading = false;
	// 		this.LoggoutPop = false;
	// 		alert('אין חיבור לרשת בכדי להתנתק');
	// 		this.DisconnectPop = false;
	// 	}
	// }

	NoDisconnect() {
		this.DisconnectPop = false;
	}

	MoveAdmin() {
		this.router.navigate(['admin']);
	}

	isDisconnectionInputPopupOpen = false;

	handleDisconnectionPopup(action: 'open' | 'close') {
		if (!environment.production && action === 'open') {
			this.handleDisconnection();
			return;
		}

		this.isDisconnectionInputPopupOpen = action === 'open';
	}

	handleDisconnection() {
		const disconectInputValue = this.form.controls.disconnectInput.value;
		const customCode = this._ss.CustomerInformation.CustomLogoutCode;
		const shouldCheckAndCompareDisconnectionCode = environment.production;

		if (shouldCheckAndCompareDisconnectionCode) {
			if (!disconectInputValue || disconectInputValue === '0') return;

			if (customCode !== '0' && disconectInputValue === '8491' && customCode !== '8491') return;

			if (disconectInputValue.toLowerCase() !== customCode && disconectInputValue.toLowerCase() !== '8491') {
				return;
			}
		}

		this.Loading = true;
		this.isDisconnectionInputPopupOpen = false;

		if (this.online === true) {
			this.LoggoutPop = true;

			this._ss.UpdateUserLogged({ AgentID: this._ss.AgentIdConnected }).subscribe({
				next: res => {
					this._ss.loginServiceDisconnected(this._ss.AgentIdConnected).subscribe((res: any) => {
						this.HelpDisconnect = res.recordset;

						if (
							this.HelpDisconnect.length === 0 ||
							this.HelpDisconnect === null ||
							this.HelpDisconnect === undefined ||
							this.HelpDisconnect[0].Logged === '1' ||
							res === 'RequestError' ||
							res === 'ConnectionError'
						) {
							alert('אין אפשרות להתנתק, ישנה תקלה בשרת');
							this.Loading = false;
							this.LoggoutPop = false;
							this.DisconnectPop = false;
						} else {
							this._storage.clearAllSavedData();
							setTimeout(() => {
								window.location.reload();
							}, 2500);
						}
					});
				},
				error: err => {
					alert('אין אפשרות להתנתק, ישנה תקלה בשרת');
					this.Loading = false;
					this.LoggoutPop = false;
					this.DisconnectPop = false;
				},
			});
		} else {
			this.Loading = false;
			this.LoggoutPop = false;
			alert('אין חיבור לרשת בכדי להתנתק');
			this.DisconnectPop = false;
		}
	}

	refreshCustomerInformation() {
		this._ss.closeMenu();
		this.Loading = true;

		this._ss.GetCustInfo(this._ss.CustomerInformation.CustNum).subscribe(
			res => {
				this.Loading = false;

				// TODO Bring back this logic after testing
				// if (typeof res === 'string') {
				// 	this.Loading = false;
				// 	alert('ישנה תקלה בשרת');
				// 	return;
				// }

				// this._ss.CustomerInformation = res.recordset;

				this._ss.CustomerInformation = res;
				this._ss.CustomerInformation = this._ss.CustomerInformation.recordset;

				if (this._ss.CustomerInformation.length) {
					this._ss.CustomerInformation = this._ss.CustomerInformation[0];
					this._storage.storeLocalDBUpdate('CustomerInformation', this._ss.CustomerInformation);

					this.refreshAllParameters();
					// console.log(res.recordset[0]);
					console.log(this._ss.CustomerInformation);
				}
			},
			err => {
				this.Loading = false;
				alert('ישנה תקלה בשרת');
			},
		);
	}

	async togglePopupTruckDistributionNewTask(mode: 'open' | 'close') {
		if (mode === 'close') {
			this.isNewTruckDistributionTaskPopupOpen$.next(false);
			this.truckDistributionNewTaskForm.reset();
			return;
		}

		if (!this.AllClients?.length) {
			await this.handleGetAllClients();
			if (!this.AllClients?.length) {
				this.AllClients = [
					{
						AM: 0,
						AO: 0,
						DSP: 0,
						BCU: 0,
						BCO: 1,
						CPL: '1',
						DocTypeName: 'הזמנה',
						DocType: 11,
						ClientId: '5677',
						Client_Name: "RISOTTO - ריזוטו - ש'ק מזומן",
						Ldt: '31-01-2024',
						CustCity: 'עכו',
						CustEMail: null,
						Address: 'שפירא 26/9',
						CustDiscount: null,
						CMC: 1000000,
						Cm: '0',
						COC: 0,
						COD: -21.7,
						PTN: '0',
						CustVat: 17,
						HM: 1,
						CE1: 'עכו',
						CE2: null,
						CE3: 'פעיל                ',
						CE4: null,
						CE5: null,
						Hearot: '',
						Phone: '0545514445',
						company: 1,
						CC: 'Green',
						Azmana: false,
						Ahzara: false,
						Azaha: false,
						Knisa: 'false',
						Exit: 'false',
					},
				];
			}
			this.allClients$.next(this.AllClients);
		}
		if (!this._truckDistribution.usersForAssignment$.value.length) {
			this._truckDistribution.updateDistributionUsersForAssignment();
		}
		this.isNewTruckDistributionTaskPopupOpen$.next(true);
	}

	validateClient(control: FormControl) {
		if (!this.allClients$?.value) return null;
		if (!this.allClients$.value.find(client => client.ClientId === control.value)) {
			return { noClient: true };
		}
		return null;
	}
	validateAgent(control: FormControl) {
		if (!this.allAgents$?.value) return null;
		if (!this.allAgents$.value.find(agent => agent.AgentID === control.value)) {
			return { noAgent: true };
		}
		return null;
	}

	handleErrorText(control: 'client' | 'agent') {
		if (control === 'agent') {
			if (this.truckDistributionNewTaskForm.controls.agent.hasError('noAgent')) return 'יש לבחור נהג מרשימת הנהגים';
		}

		if (control === 'client') {
			if (this.truckDistributionNewTaskForm.controls.client.hasError('noClient')) return 'יש לבחור לקוח מרשימת הלקוחות';
		}

		return '';
	}

	handleTruckDistributionNewTaskFormSubmit() {
		if (this.truckDistributionNewTaskForm.invalid) {
			this.truckDistributionNewTaskForm.markAllAsTouched();
			return;
		}

		this.truckDistributionNewTaskSubmitStatus$.next('loading');

		const client = this.allClients$.value.find(client => client.ClientId === this.truckDistributionNewTaskForm.value.client);
		const agent = this.allAgents$.value.find(agent => agent.AgentID === this.truckDistributionNewTaskForm.value.agent);
		const date = translateDateForSQLServer(this.truckDistributionNewTaskForm.value.date).toISOString();
		const { remarks, typeofTask } = this.truckDistributionNewTaskForm.value;

		this._truckDistribution.handleCreateNewTask({ typeofTask: typeofTask || '0', client, agent, date, remarks }).subscribe({
			next: res => {
				if (typeof res === 'string') {
					this.truckDistributionNewTaskSubmitStatus$.next('error');
					return;
				}
				this.truckDistributionNewTaskSubmitStatus$.next('success');
				this.togglePopupTruckDistributionNewTask('close');
			},
			error: err => {
				this.truckDistributionNewTaskSubmitStatus$.next('error');
			},
		});

		setTimeout(() => {
			this.truckDistributionNewTaskSubmitStatus$.next(Math.random() > 0.5 ? 'success' : 'error');
		}, 3000);

		// const { client, agent, date, typeofTask, remarks } = this.truckDistributionNewTaskForm.value;

		// const formattedDate = this._util.formatDateToSQL(date);

		// const task = {
		// 	ClientId: client,
		// 	AgentID: agent,
		// 	Date: formattedDate,
		// 	TaskType: typeofTask,
		// 	Remarks: remarks,
		// };

		// this._truckDistribution.addNewTask(task).subscribe({
		// 	next: res => {
		// 		this._alert.success('המשימה נוספה בהצלחה');
		// 		this.togglePopupTruckDistributionNewTask('close');
		// 	},
		// 	error: err => {
		// 		this._alert.error('אירעה שגיאה בהוספת המשימה');
		// 	},
		// });
	}

	handleTruckDistributionNewTaskFormSubmitStatus(status: 'success' | 'error') {}

	HasMultiplleCompanysPop = false;
	ShowCompanys;
	isMovingToSuppliers = false;

	async MoveToClients(moveTo: 'clients' | 'suppliers' = 'clients') {
		// is null || 'true'
		const updating = this._storage.getAndParseFromStorage('Updating', { parseJSON: false, decompressLZ: false }) !== 'false';

		if (this.battery && this.battery < 16 && updating) {
			const userChoice = confirm('הסוללה נמוכה, האם ברצונך להמשיך עם העדכון?');
			if (!userChoice) return;
		}

		this.ShowOptions = false;

		setTimeout(async () => {
			if (!this.online) {
				this.online = window.navigator.onLine;
			}

			this.HasMultipleCompanys = this._storage.getAndParseFromStorage('HasMultipleCompanys', { parseJSON: false, decompressLZ: false }) as string;

			this.isMovingToSuppliers = moveTo === 'suppliers';

			if (this.HasMultipleCompanys === '2' && this.online === true) {
				this.HasMultiplleCompanysPop = true;

				if (this._storage.getAndParseFromStorage('ShowCompanys', { onlyCheckIfExist: true })) {
					this.ShowCompanys = this._storage.getAndParseFromStorage('ShowCompanys', { decompressLZ: false });
				} else {
					this._ss.GetComppanys().subscribe(res => {
						if (res === 'RequestError' || res === 'ConnectionError') {
							this.ShowCompanys = undefined;
							return;
						}
						this.ShowCompanys = res.recordset;
						this._storage.storeLocalDBUpdate('ShowCompanys', this.ShowCompanys, { compressLZ: false });
					});
				}
			} else {
				if (this.HasMultipleCompanys === '2') {
					if (this._storage.getAndParseFromStorage('ShowCompanys', { onlyCheckIfExist: true })) {
						this.ShowCompanys = this._storage.getAndParseFromStorage('ShowCompanys', { decompressLZ: false });

						this.HasMultiplleCompanysPop = true;
					}
				} else {
					this.Loading = true;
					if (this._ss.CustomerInformation.RefreshClients === '1') {
						await this.handleGetAllClients();
					}
					this.handleNavigateToClientsPage(moveTo);
				}
			}
		}, 50);
	}

	handleNavigateToClientsPage(path: 'clients' | 'suppliers' = 'clients') {
		const route = ['clients'];
		if (path === 'suppliers' || this.isMovingToSuppliers) {
			route.push('suppliers');
		}
		this.router.navigate(route);
	}

	NoChoose() {
		this.HasMultiplleCompanysPop = false;

		this.isMovingToSuppliers = false;

		this.ShowOptions = true;
	}

	async CompanyChoosen(Company, Name) {
		this._ss.CompanyName = Name;

		this._ss.CompanyNum = Company;

		this.ShowOptions = true;

		this._ss.AllClientsTwoCompanys = [];

		this.Loading = true;
		if (this._ss.CustomerInformation.RefreshClients === '1') {
			await this.handleGetAllClients();
		}

		for (let x = 0; x < this.AllClients.length; x++) {
			if (this.AllClients[x].company === Company) {
				this._ss.AllClientsTwoCompanys.push(this.AllClients[x]);
			}
		}

		setTimeout(() => {
			this.handleNavigateToClientsPage();
		}, 100);
	}

	MoveToDohot(str = '') {
		if (!this._storage.getAndParseFromStorage('Updating', { onlyCheckIfExist: true })) {
			this.Loading = true;
			if (str) {
				this.router.navigate(['dohot-b2b']);
				return;
			}
			this.router.navigate(['dohot']);
		} else {
			this._ss.isMovingToDohot = true;
			this.MoveToClients();
		}
	}

	MoveToDailyReport() {
		if (!this._storage.getAndParseFromStorage('Updating', { onlyCheckIfExist: true })) {
			this.Loading = true;
			this.router.navigate(['daily-report']);
		} else {
			this.MoveToClients();
		}
	}

	MoveToDailyReportReceipts() {
		if (!this._storage.getAndParseFromStorage('Updating', { onlyCheckIfExist: true })) {
			this.Loading = true;
			this.router.navigate(['dashboard-receipts-agent'], { queryParams: { date: 'today' } });
		} else {
			this.MoveToClients();
		}
	}

	UpdateDb() {
		if (this._ss.burgerMenu) this.showHideMenu();
		localStorage.removeItem('lastupdate');
		this._storage.storeLocalDBUpdate('Updating', true, { compressLZ: false });
		const likut = this._likut.isLikutAppActiveWithAlternateRoutes$.value;
		const trucks = this._truckLoading.isTruckLoadingOrTruckDistributionAppsActive$.value;
		if (likut || trucks) {
			let path: SubAppModes;
			if (likut) path = 'likut';
			if (this._truckLoading.canStartDistribution) path = 'truck-distribution';
			if (this._truckLoading.canStartLoading) path = 'truck-loading';
			this.router.navigate([path, 'clients']);
			return;
		}
		this.handleNavigateToClientsPage();
	}

	setCookies() {
		this._cookies.put('skipPassword', 'true', { domain: 'mobilityltd.net' });
		this.logCookies();
	}

	logCookies() {
		console.log(this.getCookie());
	}

	getCookie(key?: string) {
		if (!key) return this._cookies.getAll();
		return this._cookies.get(key);
	}

	handleNewRouteLikutNavigation(mode: 'not-fulfilled') {
		if (this._likut.isLikutAppActiveWithAlternateRoutes$.value) {
			this.router.navigate(['likut', 'manage-not-fulfilled']);
			return;
		}
	}

	refreshAllParameters() {
		this.refreshCustomerParameters();
		this.refreshServicesParameters();
		this._user.refreshUserParams();
		this._alert.refreshAlertsParams();
		this._cc.refreshParameters();
	}

	refreshCustomerParameters() {
		this.populateHideReportBlockedPaths();

		this.determineWhetherShouldShowSuppliersButton();
	}

	refreshServicesParameters() {
		this._truckDistribution.init();
		this._likut.handleLikutInit();

		this._truckLoading.resetTruckLoadingState();
		this._truckLoading.handleTruckLoadingInit();

		this._receipts.refreshReceiptsParameters();
	}

	determineWhetherShouldShowSuppliersButton() {
		if (!this.AllClients?.length || !this._ss.CustomerInformation.Suppliers) return;

		const { Suppliers } = this._ss.CustomerInformation;

		if (Suppliers === '1' || Suppliers === '2') {
			this.showSuppliersButton = !!this.AllClients.filter(ac => ac.CE4 && ac.CE4.toLowerCase().includes('supplier')).length;

			this.showClientsButton = Suppliers !== '2' || !!this.AllClients.filter(ac => !ac.CE4 || !ac.CE4.toLowerCase().includes('supplier')).length;
		}
	}

	// downloadImage(src,filename){
	//   const image = new Image();
	//   image.crossOrigin = 'Anonymous';

	//   image.onload = function(){

	//     const canvas = document.createElement('canvas'),
	//     context = canvas.getContext('2d');

	//     canvas.height = this['naturalHeight'];
	//     canvas.width = this['naturalWidth'];
	//     context.drawImage((this as HTMLImageElement), 0, 0);
	//     const dataURL = canvas.toDataURL('image/jpeg'),
	//           a = document.createElement('a')
	//     a.href = dataURL
	//     a.download = filename+'.jpg'
	//     document.body.appendChild(a)
	//     a.click()
	//     document.body.removeChild(a)
	//  };

	//  image.src = src;
	// }

	@ViewChild('clickToLogoutElement', { static: true }) clickToLogoutElement: ElementRef;

	private setupClickTracker() {
		let clickCount = 0;
		const CLICK_THRESHOLD = 12;
		const TIME_INTERVAL = 400;

		const click$ = fromEvent(this.clickToLogoutElement.nativeElement, 'click');

		click$
			.pipe(
				mergeMap((e: MouseEvent) => {
					clickCount++;
					if (clickCount >= CLICK_THRESHOLD) {
						this.LoggoutPop = true;

						this._storage.clearAllSavedData().then(() => {
							setTimeout(() => {
								window.location.reload();
							}, 500);
						});
						return EMPTY;
					}
					return interval(TIME_INTERVAL).pipe(takeUntil(click$));
				}),
			)
			.subscribe(_ => {
				clickCount = 0;
			});
	}

	async replaceSavedCustomerInformation() {
		try {
			const res = await fetch(`${this._ss.CustomerInformation.CustServer}/api/customer-information`);
			const data = await res.json();

			if (res.ok && data) {
				this._ss.CustomerInformation = data;
				console.log(data);
				this._storage.storeLocalDBUpdate('CustomerInformation', this._ss.CustomerInformation);
			}
		} catch (err) {
			console.log(err);
		}
	}

	resolveWhetherWillNeedToUpdate(): boolean {
		const lastUpdate = this._storage.getAndParseFromStorage('lastupdate', { onlyCheckIfExist: true });

		if (!lastUpdate) return true;

		const updatingExists = this._storage.getAndParseFromStorage('Updating', { onlyCheckIfExist: true });

		return updatingExists && this._storage.getAndParseFromStorage('Updating', { parseJSON: false, decompressLZ: false }) !== 'false';
	}

	showHideMenu(): void {
		this._ss.openMenuAndAnimateButton();
	}

	showLogoutPopup = false;

	showHideLogoutPopup(value: boolean): void {
		this.showLogoutPopup = value;
	}

	async clearCache() {
		caches
			.keys()
			.then(async function (names) {
				let bool = false;
				for (let name of names) {
					if (name.includes('images:cache') || name.includes('images:age')) {
						const cache = await caches.open(name);
						const allImagesCaches = await cache.keys();
						for (const image of allImagesCaches) {
							await cache.delete(image);
						}
						bool = true;
					}
				}
				if (!bool) {
					throw 'no images found';
				}
			})
			.then(() => {
				alert('images cache cleared!');
			})
			.catch(err => {
				alert('error occured');
				console.log(err);
			});
	}

	initiateSubscribers() {
		this.pipeADestroyer(this._update.noNewVersion$).subscribe(value => {
			this.noNewVersion = value;
		});
		this.pipeADestroyer(this._likut.isLikutAppActive$).subscribe(value => {
			this.isLikutAppActive = value;
		});
		this.pipeADestroyer(this._truckLoading.isTruckLoadingOrTruckDistributionAppsActive$).subscribe(value => {
			this.isTruckModeActive = value;
		});
		this.pipeADestroyer(this._b2b.isB2BAgent$).subscribe(value => {
			this.isB2BAgent = value;
		});
		this.pipeADestroyer(this._b2b.isB2BAgentNoPricesAndNoParameters$).subscribe(value => {
			this.isB2BAgentNoPricesAndNoParameters = value;
		});
		this.pipeADestroyer(this._user.isRallyanceReclamator$).subscribe(value => {
			this.hasRallyanceReclamation = value;
		});
	}

	private _filter<T>(value: string | number, array: T[], keys: (keyof T)[]): T[] {
		const filterValue = String(value).toLowerCase();

		return array.filter(item => {
			for (const key of keys) {
				if (String(item[key]).toLowerCase().includes(filterValue)) {
					return true;
				}
			}
			return false;
		});
	}

	pipeADestroyer<T>(storeServiceMethod: Observable<T>) {
		return this._util.pipeASubjectDestoryer(storeServiceMethod, this.destroy$);
	}

	openCustomerInformationOverrideModal() {
		const customerInformation = JSON.parse(decompress(localStorage.getItem('CustomerInformation')));

		this.dialog.open(CustomerInformationOverrideModalComponent, {
			// width: '250px',
			data: customerInformation,
		});
	}
}
