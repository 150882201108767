<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="history.back()">
				<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
				<span style="color: white; font-size: 18px">חזרה</span>
			</div>
			<div class="header__title col">הפקת סיסמה זמנית</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="search()">
				<img src="/assets/images/header-search.svg" alt="search" />
			</button>
			<input dir="rtl" class="header__search-input" (keyup)="search()" type="text" [placeholder]="'חפש לקוח'" [formControl]="searchControl" style="text-align: center" />
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
		<!-- <h3 class="report-table__row__totals" style="position: absolute;bottom:-1em;	left: 50%; transform: translateX(-50%); background: #fff;">חברה שנבחרה: <span>{{_ss.AdminCompanyChosen}}</span></h3> -->
	</div>

	<h2 style="text-align: center" *ngIf="Loading">טוען אנא המתן</h2>
</header>

<!-- start report table markup -->
<section class="report-table margin-top-me" *ngIf="!Loading">
	<div class="content" style="display: flex; justify-content: center; padding: 20px">
		<div class="report-table__row">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head" style="border: unset">
					<tr>
						<th class="report-table__table-head-name" style="width: 50%; text-align: center">לקוח</th>
						<!-- <th class="report-table__table-head-name" style="width: auto;">עריכה</th> -->
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<cdk-virtual-scroll-viewport itemSize="36" class="virtual-viewport">
						<tr *cdkVirtualFor="let ac of allClients$ | async" style="vertical-align: middle" (click)="handleEditButton(ac)">
							<td class="report-table__table-body-name adjust-width">{{ ac.Client_Name }} - {{ ac.ClientId }}</td>
							<!-- <td class="report-table__table-body-name" style="min-width: min(50vw, 600px)">{{ ac.Client_Name }} - {{ ac.ClientId }}</td> -->
							<td class="report-table__table-body-name" style="width: auto">
								<button (click)="handleEditButton(ac)">{{ windowWidth > 500 ? 'הצג' : '' }}</button>
							</td>
						</tr>
					</cdk-virtual-scroll-viewport>
				</tbody>
			</table>
		</div>
	</div>
</section>

<div class="popup" *ngIf="shouldShowAgentSelectPopup" style="z-index: 555">
	<div class="overlay"></div>
	<div class="client-name">
		<h3>{{ selectedToEdit.Client_Name }}</h3>
		<h5>{{ selectedToEdit.ClientId }}</h5>
	</div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="closeEditPopup()" style="width: 30px; height: 30px; background-color: black"></div>
		<div class="product-detail__wrapper">
			<form [formGroup]="form" class="popup-form">
				<table>
					<thead>
						<tr>
							<th #TableStart>סוכן</th>
							<th>בחר פעולה</th>
							<th>סיסמה</th>
						</tr>
					</thead>

					<tbody>
						<tr *ngFor="let agent of agents">
							<td>{{ agent.AgentID + ' - ' + agent.UserName }}</td>
							<td>
								<mat-select [formControl]="form.controls[agent.AgentID]">
									<mat-option *ngFor="let action of actions" [value]="action.value">{{ action.text }}</mat-option>
								</mat-select>
							</td>
							<td>
								<span
									class="clickable"
									[cdkCopyToClipboard]="passwordObject[agent.AgentID]"
									#tooltip="matTooltip"
									(mouseenter)="$event.stopImmediatePropagation()"
									(mouseleave)="$event.stopImmediatePropagation()"
									(click)="tooltip.show()"
									*ngIf="passwordObject[agent.AgentID]"
									dir="rtl"
									matTooltip="סיסמה הועתקה!"
									>{{ passwordObject[agent.AgentID] }}</span
								>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
	</div>
</div>
<!-- end report table markup -->
