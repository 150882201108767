import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { MismachimService } from 'src/app/services/mismachim.service';
import { AlertService } from 'src/app/services/alert.service';
import { MsgType } from 'src/app/Interfaces/alerts';
import { Activities, AllClients, AllDocTypes } from 'src/app/Interfaces/SQL-morning-responses';
import { DEFAULT_RETURN_DOCTYPE } from 'src/app/Utility/constants-doctype';
import { validateMinDateForDateControl } from 'src/app/Utility/FormValidators';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { translateDateForSQLServer } from 'src/app/Utility/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingStatuses } from 'src/app/Interfaces/util';

@Component({
	selector: 'app-clientcard',
	templateUrl: './clientcard.component.html',
	styleUrls: ['./clientcard.component.scss'],
})
export class ClientcardComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		public _receipts: ReceiptsService,
		public _mismachim: MismachimService,
		private _alert: AlertService,
		public _device: DeviceInfoService,
		private snackbar: MatSnackBar,
	) {}

	public form: FormGroup;
	public OneClientInfo: AllClients = this._ss.OneClientInfo;
	public ForLenghtError;
	public LoadingHovot = true;
	public TeudotPerClient = [];
	public ChecksShow;
	public Admin;
	public AllHovotHeaders;
	public online;
	public ShowContacts = false;
	addressToShow;
	custExtraArray = [];
	isPartOfPopup = !location.pathname.includes('client-card');

	hovot$: Subscription;
	timeoutID: number;
	hovotStatus;

	receipts = [];
	receiptsPopupOpen = false;

	isB2BAgent = false;

	destroy$ = new Subject<void>();

	AllProducts;
	DocTypePerClient: AllDocTypes[];
	shopFilterPop;
	LoadingClient;

	showNewOrderButton = false;

	today = new Date();

	canCreateNewMaslul = this._ss.CustomerInformation?.MaslulCanCreateNew === '1';
	isCreatingNewMaslul$ = new BehaviorSubject(false);
	newMaslulForm = this._fb.nonNullable.group({
		date: ['', [Validators.required, validateMinDateForDateControl(this.today)]],
		time: ['', Validators.required],
	});
	newMaslulSubmitStatus$ = new BehaviorSubject<LoadingStatuses>('idle');

	ngOnInit(): void {
		this._mismachim.docTypes = [];
		this._mismachim.allMismachimClient = [];
		this._mismachim.scrollY = 0;

		this._mismachim.docTypeControl.reset();

		this.online = window.navigator.onLine;

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		if (this.Admin && this.Admin.includes('b2b')) {
			this.isB2BAgent = true;
		}

		this.handleSetupMaslulForm();

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Client_Name: ['', Validators.required],
			Product_Name: ['', Validators.required],
			Amount: ['', Validators.required],
			Arizot: ['', Validators.required],
			OrderBy: ['', Validators.required],
			OrderByProducts: ['', Validators.required],
			HearaForOrder: ['', Validators.required],
			Anaha: ['', Validators.required],
			Ahozim: ['', Validators.required],
			TotalPrecentAllOrderSum: ['', Validators.required],
			OrderType: ['', Validators.required],
			OrderTypeBack: ['', Validators.required],
			HearaForOrderProduct: ['', Validators.required],
			AspakaDateForOrder: ['', Validators.required],
			bonus: ['', Validators.required],
			MahlakotType: ['', Validators.required],
		});

		console.log(this.OneClientInfo);

		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
			return;
		}

		this._receipts.refreshReceiptsParameters();
		this._receipts.resetAllState();

		this.addressToShow = this.OneClientInfo.CE5 == 'AA' ? this.OneClientInfo.CE1 : `${this.OneClientInfo.Address}, ${this.OneClientInfo.CustCity}`;

		this.custExtraArray = [this.OneClientInfo.CE1, this.OneClientInfo.CE2, this.OneClientInfo.CE3, this.OneClientInfo.CE4, this.OneClientInfo.CE5].filter(ce => ce);

		if (this.isPartOfPopup) {
			this.custExtraArray = [];
		}

		if (!this.isPartOfPopup) {
			this._alert.handleAlertLogic(MsgType.clientCard.msgType);

			if (this._ss.CustomerInformation.StartOrderFromClientCard && this.OneClientInfo.AO !== 1 && this.OneClientInfo.AM !== 1) {
				const doctypesave = localStorage.getItem('AllDocTypesSave');
				if (doctypesave) {
					const allDoctypes = JSON.parse(decompress(doctypesave)) as AllDocTypes[];
					// console.log(allDoctypes);
					this.DocTypePerClient = allDoctypes.filter(doc => doc.ClientID === this.OneClientInfo.ClientId);
					this.showNewOrderButton = true;
					console.log(this.DocTypePerClient);
				}
			}
		}

		if (this._ss.OneClientInfoDebt === null) {
			this.timeoutID = window.setTimeout(() => {
				this.hovot$.unsubscribe();
				if (localStorage.getItem('AllHovotHeaders') !== null) {
					this.AllHovotHeaders = decompress(localStorage.getItem('AllHovotHeaders'));
					this.AllHovotHeaders = JSON.parse(this.AllHovotHeaders);

					this._ss.OneClientInfoDebt = [];

					for (let x = 0; x < this.AllHovotHeaders.length; x++) {
						if (this.AllHovotHeaders[x].DebtCustCode === this.OneClientInfo.ClientId && this.AllHovotHeaders[x].Company === this.OneClientInfo.company) {
							this._ss.OneClientInfoDebt.push(this.AllHovotHeaders[x]);
						}
					}

					this.hovotStatus = 'לא ניתן להביא חובות חדשים בשל תקלת תקשורת';

					// this.LoadingHovot = true

					this.handleAutoMovesAfterLoad('after-hovot');
				} else {
					this.hovotStatus = 'ישנה תקלת תקשורת, לא ניתן לראות חובות';
				}
				this.LoadingHovot = true;
			}, 15000);

			if (localStorage.getItem('AllHovotHeaders') !== null && this.online === false) {
				clearTimeout(this.timeoutID);
				this.AllHovotHeaders = decompress(localStorage.getItem('AllHovotHeaders'));
				this.AllHovotHeaders = JSON.parse(this.AllHovotHeaders);

				this._ss.OneClientInfoDebt = [];

				for (let x = 0; x < this.AllHovotHeaders.length; x++) {
					if (this.AllHovotHeaders[x].DebtCustCode === this.OneClientInfo.ClientId && this.AllHovotHeaders[x].Company === this.OneClientInfo.company) {
						this._ss.OneClientInfoDebt.push(this.AllHovotHeaders[x]);
					}
				}

				this.hovotStatus = 'לא ניתן להביא חובות חדשים בשל תקלת תקשורת';

				this.handleAutoMovesAfterLoad('after-hovot');
			} else {
				this.LoadingHovot = false;

				this.hovot$ = this._ss
					.GetOneClientInfoDebt(this.OneClientInfo.ClientId)
					.pipe(takeUntil(this.destroy$))
					.subscribe(
						res => {
							clearTimeout(this.timeoutID);
							this._ss.OneClientInfoDebt = res;
							this._ss.OneClientInfoDebt = this._ss.OneClientInfoDebt.recordset;
							this.LoadingHovot = true;

							this.handleAutoMovesAfterLoad('after-hovot');
						},
						err => {
							clearTimeout(this.timeoutID);
							if (localStorage.getItem('AllHovotHeaders') !== null) {
								clearTimeout(this.timeoutID);
								this.AllHovotHeaders = decompress(localStorage.getItem('AllHovotHeaders'));
								this.AllHovotHeaders = JSON.parse(this.AllHovotHeaders);

								this._ss.OneClientInfoDebt = [];

								for (let x = 0; x < this.AllHovotHeaders.length; x++) {
									if (this.AllHovotHeaders[x].DebtCustCode === this.OneClientInfo.ClientId && this.AllHovotHeaders[x].Company === this.OneClientInfo.company) {
										this._ss.OneClientInfoDebt.push(this.AllHovotHeaders[x]);
									}
								}

								this.hovotStatus = 'לא ניתן להביא חובות חדשים בשל תקלת תקשורת';

								this.handleAutoMovesAfterLoad('after-hovot');
							} else {
								this.hovotStatus = 'ישנה תקלת תקשורת, לא ניתן לראות חובות';
							}
							this.LoadingHovot = true;
							// this.router.navigate(['clients'])
						},
					);
			}
		}

		if (this._ss.CustomerInformation.CustGetInfoWhenEnter !== '1' && !this.isPartOfPopup) {
			this._ss
				.getVKabalaReceipts(this.OneClientInfo.ClientId, this._ss.AgentIdConnected, this.OneClientInfo.company)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (res: any) => {
						if (typeof res === 'string') {
							console.log('receipts error');
							console.log({ res });
							return;
						}

						this.receipts = this._receipts.receipts = res.recordset;
					},
				});

			if (this._ss.AllOrdersDetailsClient === null) {
				this._ss
					.GetAllTeudot()
					.pipe(takeUntil(this.destroy$))
					.subscribe(
						res => {
							this.ForLenghtError = res;
							this._ss.AllOrdersDetailsClient = this.ForLenghtError.recordset;
							if (this._ss.AllOrdersDetailsClient !== null && this._ss.AllOrdersDetailsClient !== undefined && this._ss.AllOrdersDetailsClient.length !== 0) {
								for (let x = 0; x < this._ss.AllOrdersDetailsClient.length; x++) {
									if (this._ss.AllOrdersDetailsClient[x].Client_Id === this._ss.OneClientInfo.ClientId) {
										let data = this.TeudotPerClient.find(ob => ob.TeudaNum === this._ss.AllOrdersDetailsClient[x].TeudaNum);
										if (data === undefined) {
											this.TeudotPerClient.push(this._ss.AllOrdersDetailsClient[x]);
										}
									}
								}
							}
							this._ss.TeudotPerClient = this.TeudotPerClient;
							this.TeudotPerClient.sort((a, b) => new Date(b.TeudaCreateDate).getTime() - new Date(a.TeudaCreateDate).getTime());
							this.handleAutoMovesAfterLoad('after-teudot');
						},
						err => {
							// alert( "ישנה תקלה בשרת, לא התקבלו תעודות לקוח");
							// this.router.navigate(['clients'])
						},
					);
			} else {
				if (this._ss.AllOrdersDetailsClient !== null && this._ss.AllOrdersDetailsClient !== undefined && this._ss.AllOrdersDetailsClient.length !== 0) {
					for (let x = 0; x < this._ss.AllOrdersDetailsClient.length; x++) {
						if (this._ss.AllOrdersDetailsClient[x].Client_Id === this._ss.OneClientInfo.ClientId) {
							let data = this.TeudotPerClient.find(ob => ob.TeudaNum === this._ss.AllOrdersDetailsClient[x].TeudaNum);
							if (data === undefined) {
								this.TeudotPerClient.push(this._ss.AllOrdersDetailsClient[x]);
							}
						}
					}
				}
				this._ss.TeudotPerClient = this.TeudotPerClient;
				this.TeudotPerClient.sort((a, b) => new Date(b.TeudaCreateDate).getTime() - new Date(a.TeudaCreateDate).getTime());

				this.handleAutoMovesAfterLoad('after-teudot');
			}
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		if (typeof this.timeoutID == 'number') {
			clearTimeout(this.timeoutID);
		}
	}

	//NAVIGATIONS NAVIGATIONS NAVIGATIONS NAVIGATIONS NAVIGATIONS

	public handleBackButton() {
		this._ss.OneClientInfoDebt = null;

		history.back();
	}

	handleAutoMovesAfterLoad(from: 'after-hovot' | 'after-teudot') {
		if (from === 'after-hovot' && this._ss.AutoMoveHovot) {
			this.router.navigate(['client-hovot'], { replaceUrl: true });
		}

		if (from === 'after-teudot' && this._ss.AutoMoveAgentOrders) {
			this.router.navigate(['client-teudot'], { replaceUrl: true });
		}
	}

	public MoveMismachim() {
		this.router.navigate(['mismachim']);
	}

	public MoveNewBizoim() {
		this.router.navigate(['bizoim-lekohot-year']);
		this._ss.ParamBitzoimClientCard = this._ss.OneClientInfo.ClientId;
	}

	public MoveToClientActiv() {
		if (this._ss.CustomerInformation.CustChange1 === '1') {
			this.router.navigate(['client-activ2']);
		} else if (this._ss.CustomerInformation.CustChange1 === '2') {
			this.router.navigate(['client-activ3']);
		} else {
			this.router.navigate(['client-activ']);
		}
	}

	public MoveToReports() {
		this.router.navigate(['client-reports']);
	}

	public MoveToClientTeudot() {
		this.router.navigate(['client-teudot']);
	}

	public MoveToClientHovot() {
		this.router.navigate(['client-hovot']);
	}

	public MoveToProductsAzmana() {
		this._ss.SpecPriceSs = null;
		this._ss.MivzaA = null;

		this._ss.DocTypeName = 'הזמנה';
		this._ss.DocType = 11;
		this._ss.DocTypeShrion = 0;

		this.router.navigate(['products']);
	}

	public MoveToProductsAhzara() {
		this._ss.SpecPriceSs = null;
		this._ss.MivzaA = null;

		this._ss.DocTypeName = 'ת.החזרה';
		this._ss.DocType = DEFAULT_RETURN_DOCTYPE;
		this._ss.DocTypeShrion = 0;

		this.router.navigate(['products']);
	}

	public HelpChecksShow = 0;
	public GetOpenChecks() {
		if (this.HelpChecksShow === 0) {
			this._ss.GetAllOpenChecksPerClient().subscribe(
				res => {
					this.ChecksShow = res;
					this.ChecksShow = this.ChecksShow.recordset;
					this.HelpChecksShow = 1;

					let splitStringDateDDMMYY = (date: string) => date.split('/').reverse().join();

					if (this.ChecksShow.length && this.ChecksShow[0].PayDate.includes('-')) {
						splitStringDateDDMMYY = (date: string) => date.split('-').reverse().join();
					}

					this.ChecksShow.sort((a, b) => new Date(splitStringDateDDMMYY(a.PayDate)).getTime() - new Date(splitStringDateDDMMYY(b.PayDate)).getTime());
				},
				err => {
					alert('ישנה תקלה בשרת');
					this.router.navigate(['clients']);
				},
			);
		}
	}

	profileButtonTypes = {
		Refresh: 'refresh',
		Revert: 'revert',
		Shop: 'shop',
	};
	isRefreshActive = false;
	isRevertActive = false;
	isShopActive = false;
	showRejectBlock = false;
	showDebtTable = false;
	showPopup = false;
	showReports = false;
	showAttentionPopup = false;

	activeProfileButton(type: string): void {
		this.isRefreshActive = false;
		this.isRevertActive = false;
		this.isShopActive = false;

		switch (type) {
			case this.profileButtonTypes.Refresh:
				this.isRefreshActive = true;
				this.showReports = true;
				break;

			case this.profileButtonTypes.Revert:
				this.isRevertActive = true;
				break;

			case this.profileButtonTypes.Shop:
				this.isShopActive = true;
				break;
		}
	}

	showHideDebtTable(): void {
		this.showDebtTable = true;
	}

	showHideReject(): void {
		if (this.showRejectBlock === true) {
			this.showRejectBlock = false;
		} else {
			this.showRejectBlock = true;
			this.GetOpenChecks();
		}
	}

	showHidePopup(value: boolean): void {
		this.showPopup = value;
	}

	showHideAttentionPopup(value: boolean): void {
		this.showAttentionPopup = value;
	}

	OpenTeudaType(ClientId: string) {
		this.shopFilterPop = true;

		// הזמנה תמיד ראשונה ברשימת התעודות
		this.DocTypePerClient.sort((a, b) => (a.DocType === 11 ? -1 : 1));

		this.handleSettingUpItemExtraArrays();

		this._ss.ClientForRETURN = ClientId;

		if (this.DocTypePerClient.length === 1) {
			this.MoveToProductsOrderType(this.DocTypePerClient[0]);
		}
	}

	handleSettingUpItemExtraArrays() {
		if (!this.AllProducts || !this.AllProducts.length) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
		}

		const itemExtraObject: { [key: string]: Set<string> } = {};
		const numArr = ['1', '2', '3', '4', '5'];

		// ItemExtra1, ItemExtra2...
		for (const num of numArr) {
			itemExtraObject[`itemExtra${num}Set`] = new Set();
		}

		for (let x = 0; x < this.AllProducts.length; x++) {
			for (const num of numArr) {
				const itemExtra = this.AllProducts[x][`ItemExtra${num}`];

				if (itemExtra && itemExtra.replaceAll(' ', '')) {
					itemExtraObject[`itemExtra${num}Set`].add(this.AllProducts[x][`ItemExtra${num}`]);
				}
			}
		}

		for (const num of numArr) {
			this._ss.itemExtraObject[`itemExtra${num}`] = [...itemExtraObject[`itemExtra${num}Set`]];

			const currentItemExtra = this._ss.itemExtraObject[`itemExtra${num}`];

			if (!currentItemExtra.length || !currentItemExtra[0]) {
				delete this._ss.itemExtraObject[`itemExtra${num}`];
			}
		}

		this._ss.ItemExtra = [...itemExtraObject['itemExtra1Set']];
		console.log(this._ss.itemExtraObject);
	}

	MoveToProductsOrderType(doctype: AllDocTypes) {
		this.shopFilterPop = false;
		this.LoadingClient = true;

		this._ss.Heara1 = doctype.MustCustRem1;
		this._ss.Heara2 = doctype.MustCustRem2;
		this._ss.Heara3 = doctype.MustCustRem3;
		this._ss.Heara4 = doctype.MustCustRem4;
		this._ss.NoObligoCheck = doctype.NoObligoCheck;
		this._ss.DocTypeName = doctype.DocName;
		this._ss.DocType = doctype.DocType;
		this._ss.DocTypeShrion = doctype.IsShrion;
		this._ss.CheckMinPrice = doctype.CheckMinPrice;

		// this._ss.AllClientsHold = this.AllClients;

		this._ss.performance.push({ name: 'MoveToProductsOrderType', time: performance.now() });

		setTimeout(() => {
			this.handleNavigateToProductsPage();
		}, 100);
	}

	handleNavigateToProductsPage() {
		this._alert.handleAlertLogic(MsgType.enterProductsFromHour.msgType);
		this.router.navigate(['products']);
	}

	handleNavigationToVisitsReport() {
		this.router.navigate(['activ'], { queryParams: { clientid: this.OneClientInfo.ClientId } });
	}

	handleSetupMaslulForm() {
		if (this._device.shouldShowNativeControls$.value) {
			this.newMaslulForm.controls.time.disable();
			return;
		}

		this.newMaslulForm.controls.time.setValidators([Validators.required]);
		this.newMaslulForm.controls.date.setValidators([Validators.required]);

		this.newMaslulForm.setValidators([
			(group: FormGroup) => {
				const date = group.get('date').value;
				const time = group.get('time').value;

				console.log('time', time);

				if (!date || !time || !isFinite(+date)) return { required: true };

				const [hours, minutes] = time.split(':');

				const timeDate = new Date(date);
				timeDate.setHours(+hours, +minutes);

				const today = new Date().toISOString().slice(0, 10);
				const chosenDate = translateDateForSQLServer(date).toISOString().slice(0, 10);

				if (chosenDate === today && timeDate < new Date()) {
					return { invalidTime: true };
				}

				return null;
			},
		]);

		this.newMaslulForm.updateValueAndValidity();

		console.log(this.newMaslulForm);
	}

	toggleMaslulNewTaskForm(mode: 'open' | 'close') {
		if (mode === 'open') {
			this.newMaslulForm.reset();

			this.newMaslulSubmitStatus$.next('idle');
			this.isCreatingNewMaslul$.next(true);
		}

		if (mode === 'close') {
			this.isCreatingNewMaslul$.next(false);
		}
	}

	handleMaslulFormSubmit() {
		if (this.newMaslulForm.invalid) {
			this.newMaslulForm.markAllAsTouched();
			return;
		}

		this.newMaslulSubmitStatus$.next('loading');

		const { date, time } = this.newMaslulForm.value;
		const dateObj = new Date(date);

		if (this.newMaslulForm.controls.time.enabled) {
			const [hours, minutes] = time.split(':');
			dateObj.setHours(+hours, +minutes);
		}

		const formattedDate = translateDateForSQLServer(dateObj);
		const agent = this._ss.AgentIdConnected;
		const client = this.OneClientInfo.ClientId;

		const newMaslul: Partial<Activities> = {
			ActivityID: `M${agent}${client}${formattedDate.toISOString().slice(0, 10)}`,
			ClientID: client,
			Company: this.OneClientInfo.company,
			DateTime: formattedDate.toISOString(),
			ActivityTypeID: 1,
			OwnerAgentID: agent,
		};

		this._ss
			.insertNewMaslol(newMaslul)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this._ss.GetMaslol().subscribe({
						next: (res: any) => {
							if (typeof res === 'string') return;

							localStorage.setItem('Maslul', compress(JSON.stringify(res.recordset)));
						},
						error: () => {
							this.snackbar.open('שגיאה בטעינת המשימות', 'סגור', { duration: 5000 });
							this.newMaslulSubmitStatus$.next('error');
						},
						complete: () => {
							this.newMaslulSubmitStatus$.next('success');
						},
					});
				},
				error: () => {
					this.newMaslulSubmitStatus$.next('error');
				},
			});
	}

	handleErrorText(mode: 'client' | 'date' | 'time') {
		if (mode === 'date') {
			return this.newMaslulForm.get('date').hasError('required') ? 'שדה חובה' : '';
		}
		if (mode === 'time') {
			return this.newMaslulForm.get('time').hasError('required') ? 'שדה חובה' : '';
		}
		return '';
	}
}
