import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, map } from 'rxjs';

export type ServiceWorkerVersionStates = 'noNewVersion' | 'updating' | 'updateSuccessful' | 'updateFailed';

@Injectable({
	providedIn: 'root',
})
export class NgUpdateService {
	swVersionState$ = new BehaviorSubject<ServiceWorkerVersionStates>('noNewVersion');
	noNewVersion$ = this.swVersionState$.pipe(map(state => state === 'noNewVersion'));
	checkingNewVersion$ = this.swVersionState$.pipe(map(state => state !== 'noNewVersion'));

	constructor(private updates: SwUpdate) {
		updates.versionUpdates.subscribe(evt => {
			switch (evt.type) {
				case 'VERSION_DETECTED':
					console.log(`Downloading new app version: ${evt.version.hash}`);

					this.swVersionState$.next('updating');
					break;
				case 'VERSION_READY':
					console.log(`Current app version: ${evt.currentVersion.hash}`);
					console.log(`New app version ready for use: ${evt.latestVersion.hash}`);

					this.swVersionState$.next('updateSuccessful');
					// this.updates.activateUpdate();
					setTimeout(() => {
						if (location.pathname.includes('home') || location.pathname.includes('login')) {
							window.location.reload();
						}
					}, 1700);
					break;
				case 'VERSION_INSTALLATION_FAILED':
					console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
					console.log(evt.version.appData);

					this.swVersionState$.next('updateFailed');
					break;
			}
		});
	}

	async checkNewVersion() {
		try {
			await this.updates.checkForUpdate();
		} catch (err) {
			console.log(err.stack);
		}
	}

	async isNewUpdateActivated() {
		try {
			const isNewVersionAvailableToActivate = await this.updates.checkForUpdate();
			if (isNewVersionAvailableToActivate) {
				const isActivatedSuccessfully = await this.updates.activateUpdate();
				if (isActivatedSuccessfully && (location.pathname.includes('home') || location.pathname.includes('login'))) {
					location.reload();
					return true;
				}
			}
			console.log('no new version');
			return false;
		} catch (err) {
			return false;
		}
	}
}
