<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToDohot()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">דוחות - מנהל</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger-red.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start content of report page -->
		<section class="report-dashboard">
			<div class="container" [ngClass]="{ 'container__no-background': _ss.CustomerInformation.AlternativeAdminReports === '1' }">
				<ng-container *ngIf="_ss.CustomerInformation.AlternativeAdminReports === '1'; else alternativeAdminReportsBlock">
					<app-barcode-reader></app-barcode-reader>
					<div class="report-dashboard__row row report-dashboard__row_alternative">
						<app-report-dashboard-item *ngFor="let item of alternativeAdminLayoutItems" class="report-dashboard__item__alternative" [requiredProperties]="item"></app-report-dashboard-item>
					</div>
				</ng-container>
				<ng-template #alternativeAdminReportsBlock>
					<!-- <app-barcode-reader></app-barcode-reader> -->
					<div class="report-dashboard__row row" style="position: relative; bottom: 55px">
						<div class="report-dashboard__item report-dashboard__item_products col" (click)="MoveToadminbizoahevra()">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים חברה</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_products col" *ngIf="hasInvoiceQuery" (click)="router.navigate(['daily-invoices-admin'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים חשבוניות</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_products col"
							*ngIf="_ss.CustomerInformation.ReportsOptions === '2' || _ss.CustomerInformation.ReportsOptions === '3'"
							(click)="router.navigate(['reports', 'sales-vs-returns'])"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">מכירות מול חזרות</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['graph'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">גרף</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_products col" (click)="checkIfHasMultipleCompanys('admin-bizoa-teudot')">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים תעודות</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_products col" *ngIf="hasUndeliveredEndpoint" (click)="checkIfHasMultipleCompanys('admin-bizoa-teudot-undelivered')">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">דוח לא סופק</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="MoveToadminbizoaazmanot()">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים הזמנות</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['bizoim-lekohot-year-admin'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים לקוחות</span>
						</div>
						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['dohot-bizoim-gvia-admin'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים גבייה</span>
						</div>
						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['dohot-lekohot-hovot-admin'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ריכוז חובות</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="MoveToadmindohotproducts()">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">התפלגות מכירות פריט-לקוח</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="MoveToDashbord()">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">תמונת מצב</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_performence col"
							*ngIf="_ss.CustomerInformation.B2BOptions === '1' || _ss.CustomerInformation.B2BOptions === '2'"
							(click)="router.navigate(['mobilitydashbord-B2B'])"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">תמונת מצב - B2B</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['admin-activ'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביקורים</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" *ngIf="hasBikurimPlannedVsUnplanned" (click)="router.navigate(['planned-vs-unplanned-admin'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביקורים - תיכנון מול ביצוע</span>
						</div>
						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['admin-users'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול סוכנים</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" *ngIf="_ss.CustomerInformation.YaadAgents === '1'" (click)="navigateAdminGoals('agents')">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול יעדי סוכנים</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_performence col"
							*ngIf="(_ss.CustomerInformation.YaadClients === '1' || _ss.CustomerInformation.YaadClients === '2') && windowWidth > 700"
							(click)="navigateAdminGoals('clients')"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול יעדי לקוחות</span>
						</div>
						<div class="report-dashboard__item report-dashboard__item_performence col" *ngIf="_ss.CustomerInformation.YaadGroup === '1'" (click)="navigateAdminGoals('groups')">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול יעדי מחלקות</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_products col" *ngIf="hasBrandsTablesAndQuery" (click)="router.navigate(['reports/brands-admin'])">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים מותגים</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_products col"
							*ngIf="_ss.CustomerInformation.ReportsOptions === '1' || _ss.CustomerInformation.ReportsOptions === '3'"
							(click)="router.navigate(['reports/group-sales-admin'])"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים מחלקות</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['dashboard-receipts'])" *ngIf="_receipts.hasReceipts">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול קבלות</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_performence col"
							(click)="router.navigate(['reports/agent-sales-products-quantities-admin'])"
							*ngIf="hasAgentSalesProductsQuantitiesDates"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים מכירות לפי פריטים</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_performence col"
							(click)="router.navigate(['reports/agent-sales-orders-quantities-admin'])"
							*ngIf="hasAgentSalesOrdersQuantitiesDates"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ביצועים הזמנות לפי פריטים</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_performence col"
							(click)="checkIfHasMultipleCompanys('temporary-password')"
							*ngIf="_ss.CustomerInformation.TemporaryPassword === '1'"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">סיסמה זמנית</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['specials'])" *ngIf="hasSpecials">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול מבצעים</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['admin', 'allocation-per-agent'])" *ngIf="hasAllocations">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">הקצאות סוכנים</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['agent-gvia'])" *ngIf="hasAgentGvia">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">לקוחות לגבייה</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['reports', 'yaad-gvia-admin'])" *ngIf="hasYaadGvia">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">יעדי גבייה</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['admin', 'manage-alerts'])" *ngIf="hasAlerts">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול התראות</span>
						</div>

						<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['admin', 'manage-must-include-products'])" *ngIf="hasMustIncludeProducts">
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ניהול מוצרי חובה</span>
						</div>

						<div
							class="report-dashboard__item report-dashboard__item_performence col"
							(click)="router.navigate(['reports', 'concentrated-agent-sales-products-quantities-admin'])"
							*ngIf="hasConcentratedSales"
						>
							<div class="report-dashboard__item-photo image">
								<img src="/assets/images/report/performence-icon.svg" alt="photo" />
							</div>
							<span class="report-dashboard__item-text">ריכוז מכירות לפריט</span>
						</div>
					</div>
				</ng-template>
			</div>
		</section>
		<!-- end content of report page -->
	</div>

	<!-- markup for popup -->
	<div class="popup" *ngIf="HasMultiplleCompanysPop === true">
		<div class="overlay"></div>
		<div class="order-cart__popup popup__content">
			<div class="popup__close" (click)="NoChoose()"></div>
			<div class="order-cart__popup-image image">
				<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
			</div>
			<h2 class="order-cart__popup-title">בחר חברה</h2>
			<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoChoose()">חזור</button>
			<br />
			<div class="order-cart__popup-block row">
				<button class="order-cart__popup-button" (click)="CompanyChoosen(sc.Company)" *ngFor="let sc of ShowCompanys">
					{{ sc.CompanyName }}
				</button>
			</div>
		</div>
	</div>
	<!-- end markup for popup -->

	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img
						width="100%"
						height="auto"
						src="{{ this._ss.CustomerInformation.CustImageDomain }}/companylogo.jpg"
						crossorigin="anonymous"
						alt="mobility"
						class="company-logo__image"
						onerror="this.onerror=null; this.src=MOBILITY_LOGO"
					/>
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ this._ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ this._ss.AgentIdConnected }}</div>
				</div>
			</div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="MoveToClients()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">לקוחות</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="BackToHome()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דף הבית</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-menu-button></app-menu-button>
