import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Mismachim } from '../Interfaces/mismachim';
import { AllReclamationsResponse, RallyanceReclamationNewReclamationBody, RallyanceReclamationReasons, ReclamationReasonsResponse } from '../Interfaces/reclamations';
import { BrandResponse } from '../Interfaces/brand-response';
import { CompaniesResponse } from '../Interfaces/companies';
import { Invoices } from '../Interfaces/invoices';
import { SalesVsReturns } from '../Interfaces/sales-vs-returns';
import { specialPostBody, specialPatchBodyEdit, Special } from './../Interfaces/special';
import { GroupSales } from '../components/group-sales-all/GroupSales';
import { ClientGoals, ClientGoalsYearly, GroupGoals } from '../Interfaces/goals';
import { BizoimClientResponse } from '../Interfaces/agent-sales-quantities';
import { AdminTypes, LoginUser, Submanagers, Users } from '../Interfaces/users';
import { Alert, AlertInDatabase, AlertPostBody } from '../Interfaces/alerts';
import {
	AllProducts,
	AllDocTypes,
	AllProductsPricess,
	ShirionProducts,
	AllDebts,
	AllProductsLikut,
	StockOriginal,
	ProductGroups,
	GetAllPricesAll,
	AllClients,
	ConcentratedProducts,
	CustomerInformation,
	ItemPacks,
	AllMGroup,
	DocTypesNumToName,
	DocTypeDefinitions,
	MustIncludeProducts,
	Activities,
	HearotHeadForTeuda,
} from '../Interfaces/SQL-morning-responses';
import { MinDocTeuda } from '../services/doc-min-teuda.service';
import { ResponseFromExpress, SQLError, SQLResponse } from '../Interfaces/SQL-interfaces';
import { CustItemSales } from '../Interfaces/cust-item-sales';
import { MlayRes } from '../Interfaces/mlay-res';
import { AllGroupDiscounts } from '../Interfaces/allgroupdiscounts';
import { BizoimGviaResponse, ByDate, DebtSummedPerAgentAndClient } from '../Interfaces/reports';
import {
	LikutFinishBodyNewRoutes,
	LikutKabalaMainSQL,
	LikutKabalaSubSQL,
	LikutMainSQL,
	LikutMekabelFinishBody,
	LikutMekabelFinishBodyForUnexistingTask,
	LikutStatus,
	LikutSubSQL,
	LikutUsers,
	LStatuses,
	MekabelWarehouse,
} from '../Interfaces/likut';
import {
	AdminAgentsSalesSecond,
	AdminAgentsSalesThird,
	AgentGoals,
	BIReports,
	GetAllPrices,
	ProductCommentSelect,
	Reasons,
	SeeOneHov,
	TDataOneStringTeuda,
	TeudaFromAllTeudot,
	TotalAgentPerDay,
	VisitsPlannedVsUnplanned,
	YaadGvia,
} from '../Interfaces/SQL-reports-responses';
import { DeviceInfoService } from './device-info.service';
import { SubAppModes } from '../components/likut-clients/likut-clients.component';
import { SecondGroup } from '../Interfaces/groups';
import { StorageService } from './storage.service';
import { Receipt, ReceiptForGvia } from '../Interfaces/receipt';

@Injectable({
	providedIn: 'root',
})
export class StoreService {
	constructor(private http: HttpClient, private _storage: StorageService) {
		this.updateCustomerState();
	}

	UserNameConnected;
	UserIdConnected;
	AgentIdConnected;

	OneClientInfo = null;
	DocTypeName = null;
	DocType: number | null = null;
	DocTypeShrion = null;

	allDoctypeNames;

	OneClientInfoDebt;
	AllOrdersDetailsClient = null;
	TeudaNumForSeeTeudaInner;
	TeudotPerClient: TeudaFromAllTeudot[];
	EachClientEachOrder;
	EachClientEachHov;

	ProductInProductPage = null;
	ProductInProductSectionToGoBack = null;
	SpecPriceSs = null;
	VCust = null;

	ItemExtra = [];
	itemExtraObject: { [key: string]: string[] } = {};

	BackFromClientCard = false;

	TeudotDetails = null;

	TeudotDetailsEach;
	Teudot = null;
	TeudotAll = null;
	Betipul = 0;
	MivzaA = null;

	ClientFromDohot = 0;

	SearchStyle = 1;

	AllClientsTwoCompanys = [];

	SeeTotalPrice: string;

	ShowAshraiOnHovot;

	AllTeudotAgent;

	FromProductsToDohot = false;

	CompanyNumForDuplicate;

	OrderInProcess = false;

	VersionNumber = 'V650.9';

	SaveTeudotDohot = null;

	AdminCompanyChosen = 1;

	NoObligoCheck = 0;

	CheckMinPrice = 0;

	HearotHeadForTeuda;

	// hearotForTeuda = []
	selectHearotForTeuda = [];

	Heara1 = 0;
	Heara2 = 0;
	Heara3 = 0;
	Heara4 = 0;
	HearaNewOrder1 = '';
	HearaNewOrder2 = '';
	HearaNewOrder3 = '';
	HearaNewOrder4 = '';
	HearaNewOrder5 = '';

	AllSavedMahlakot = null;
	SaveMainGroups = null;

	AllClientsHold = null;

	AllClientsHoldSearch = 'חיפוש';
	ClientForRETURN = 'null';

	BlockMlayParam3 = true;
	AllMismachimClient;
	SeeArizot = true;

	AutoMoveHovot: 0 | 1 = 0;
	AutoMoveAgentOrders: 0 | 1 = 0;

	// V_AllProducts2Comp
	// go to only if offline
	AllProductsInfo = null;

	AllProductsPricess = null;
	ProductCompany = null;

	BackToProductsFromTeuda = null;
	BackToProductsOnlineStatus = null;
	BackToProductsFromTeudaSectionName = null;

	//CustNum From Users table, not CustomerInformation
	CustNum;

	//CustomerInformation From Mobility
	CustomerInformation = this._storage.getAndParseCustomerInformation() as any;
	// as Partial<CustomerInformation> | null;

	// TODO turn on typescript strict mode, and change type false -> null. type check ignores null if not strict.
	// TODO Replace CustomerInformation with this subject
	CustomerInformation$ = new BehaviorSubject<Partial<CustomerInformation> | false>(this.CustomerInformation || false);
	custServer$ = new BehaviorSubject('');

	FilterMigvan = null;

	Mehironim = null;

	CompanyName = null;
	CompanyNum = null;

	ParamBitzoimClientCard = null;
	allGroupDiscounts = [];

	valueAddedTax = 0;

	adminBizoaSearchArrays: { DocArr?: string[]; AgentArray?: string[] } = {};

	agentsOfSubmanager = [];

	isMovingToDohot = false;

	shouldGetToAgentBizoa;

	isLocalhost = location.href.includes('localhost');

	environmentApiUrl = window.location.href.split(':')[0] == 'http' && !this.isLocalhost ? environment.HTTP.api_url : environment.HTTPS.api_url;

	isOnline = true;
	onlineObs$: Observable<Event>;
	// onlineSub = new Subscription()

	offlineObs$: Observable<Event>;
	connectionChangeSub$ = new Subscription();

	currentlyChosenDebt;

	allProductsSearchForTeudaCheck;
	allProductsSearchByDateForSchichim;

	openDohotThirdOnAgent;
	openDohotFourthOnAgent;

	prefersHovotFullTableDesign = false;

	adminAllClientsWithDebt = [];

	performance = [];

	allSpecials: Special[] = [];

	blockPaths = {};

	duplicatedTeudaAndCleanupForFilter = false;

	Admin: AdminTypes | null = localStorage.getItem('Admin') as null | AdminTypes;

	hasWhatsappIcon$ = new BehaviorSubject<boolean>(!!this.CustomerInformation?.WhatsappB2BL && this.CustomerInformation.WhatsappB2BL !== '0' && this.Admin && this.Admin.includes('b2bL'));
	whatsappNumber$ = new BehaviorSubject<string | undefined>(this.CustomerInformation?.WhatsappB2BL);

	biUserAvailableReports = [];

	allConcentratedProducts: ConcentratedProducts[] = [];

	// use instead of sending query on back from client-hovot
	copyOfAllClientsFromDohotLekohotHovotAdmin;
	allClientsFromDohotLekohotHovotAdminSet = new Set<string>();

	get = <TResponse = any>(path: string, params = new HttpParams()) => this.http.get<ResponseFromExpress<TResponse>>(`${this.CustomerInformation.CustServer}/${path}`, { params });

	delete = <TResponse = any>(path: string, params = new HttpParams()) => this.http.delete<ResponseFromExpress<TResponse>>(`${this.CustomerInformation.CustServer}/${path}`, { params });

	post = <TResponse = any>(path: string, body: Record<string, any>) => this.http.post<ResponseFromExpress<TResponse>>(`${this.CustomerInformation.CustServer}/${path}`, body);

	put = <TResponse = any>(path: string, body: Record<string, any>) => this.http.put<ResponseFromExpress<TResponse>>(`${this.CustomerInformation.CustServer}/${path}`, body);

	patch = <TResponse = any>(path: string, body: Record<string, any>) => this.http.patch<ResponseFromExpress<TResponse>>(`${this.CustomerInformation.CustServer}/${path}`, body);

	//===========================================================================================================================================>
	//Email
	Email(body) {
		return this.http.post(this.environmentApiUrl + '/Email', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}
	//===========================================================================================================================================>

	isConnectionFastEnough() {
		return (window && window?.navigator && window.navigator['connection'] && window.navigator['connection']['downlink'] && window.navigator['connection']['downlink'] > 0.5) ?? true;
	}

	roundNumberTo2Decimals(num: number | string) {
		return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+num);
	}

	sendEmail(body) {
		return this.http.post(this.environmentApiUrl + '/mobil/send-email', body);
	}

	checkConnection() {
		return this.get('');
	}

	updateCustomerState() {
		this.CustomerInformation$.pipe(filter(value => value !== false)).subscribe((res: Partial<CustomerInformation>) => {
			this.custServer$.next(res.CustServer);
			this.hasWhatsappIcon$.next(!!res?.WhatsappB2BL && res.WhatsappB2BL !== '0' && this.Admin && this.Admin.includes('b2bL'));
			this.whatsappNumber$.next(res.WhatsappB2BL || '');
		});
	}

	////////////////////////////////
	//check CustNum User (0) ---> (בדיקת מספר חברה בשרת מוביליטי)

	GetCustInfo(CustomerNumber) {
		return this.http.get<ResponseFromExpress<CustomerInformation>>(this.environmentApiUrl + '/mobility/custinfo/' + CustomerNumber);

		// return this.http.get("https://webclient.mobilityltd.net:3000"+'/mobility/custinfo/'+CustomerNumber)
	}

	//Login User (1) ---> (התחברות למערכת)

	loginService(UserName, Password) {
		return this.get<LoginUser>(`mobil/user/login/${UserName}/${Password}`);
	}

	loginServiceDisconnected(UserName) {
		return this.get(`mobil/user/logindisconnected/${UserName}`);
	}

	//changed logged CONNECT (2) ---> ( שינוי התחברות ל 1 )

	UpdateUserLoggedConnect(body: { AgentID: string }) {
		return this.post('mobil/changeconnlog', body);
	}

	//changed logged DISCONNECT (3) ---> ( שינוי התחברות ל 0 )

	UpdateUserLogged(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/changelogged', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	forceDisconnectUserNextUpdate(AgentID: string) {
		return this.patch('mobil/user/force-disconnect', { AgentID });
	}

	checkCurrentLoggedStatusForUser(AgentID: string) {
		return this.get<{ Logged: '0' | '1' | '2' }>(`mobil/user/logged-status/${AgentID}`);
	}

	// change password

	changeAgentPassword(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/users/change-password', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	//GetAllClients (4) ---> (כל הלקוחות)

	GetAllClients(agent = this.AgentIdConnected) {
		return this.get<AllClients>(`mobil/user/clients/${agent}`);
	}

	//GetAllProducts (5) ---> (מוצרים לפתיחה על הדף)

	GetAllProducts() {
		return this.get<AllProducts>(`mobil/user/allproducts/${this.AgentIdConnected}`);
	}

	getAllProductsWithoutAgent(mode: SubAppModes) {
		return this.get<AllProductsLikut>(`mobil/${mode}/all-products`);
	}

	GetAllProductsandpricesnew() {
		return this.get<AllProductsPricess>(`mobil/user/allproductspricessnew/${this.AgentIdConnected}`);
	}

	GetAllDoctypesDefinitions() {
		return this.get<DocTypeDefinitions>('mobil/user/docstype');
	}
	//GetAllClientChoosenDebt (11) ---> (טבלת מספר תעודות חובות)

	GetOneClientInfoDebt(ClientId) {
		ClientId = encodeURIComponent(ClientId);

		return this.get<AllDebts>(`mobil/user/clients/one/debt/${ClientId}/${this.AgentIdConnected}/${this.OneClientInfo.company}`);
	}

	GetAllClientsInfoDebt() {
		return this.get<AllDebts>('mobil/user/clients/all/debt/' + this.AgentIdConnected);
	}

	//GetAllClientChoosenDebtEachDebt (12) ---> (טבלת חוב לכל תעודת חוב לפי מספר תעודה)

	SeeOneHovTeudaService(ClientId, DocDocNum, agent = this.AgentIdConnected, doctype?, company = this.OneClientInfo?.company || 1) {
		ClientId = encodeURIComponent(ClientId);

		let params = new HttpParams();

		if (doctype) {
			params = params.set('DocDocType', doctype);
		}

		return this.get<SeeOneHov>(`mobil/user/clients/one/debt/teuda/${ClientId}/${DocDocNum}/${agent}/${company}`, params);
	}

	//GetAllTeudotForOneClient (13) ---> (כל התעדות)

	GetAllTeudot() {
		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.get<TeudaFromAllTeudot>(`mobil/user/teudot/${this.AgentIdConnected}/${client}`);
	}

	//InsertItemsToTeuda (16) ---> (הכנסת ההזמנה לתעודה)

	AllOrderString(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/allorder', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	AllOrderStringExpended(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/allorderexpended', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	//get groups for products(17) ---> (קבלת מחלקות)

	GetGroups() {
		return this.get<ProductGroups>(`mobil/user/productsgroups/${this.AgentIdConnected}`);
	}

	//confirmed order(18) ---> ( אישור הזמנה)

	CheckConfirmedOrder(number) {
		return this.get<TDataOneStringTeuda>(`mobil/client/confirmedorder/${number}`);
	}

	//get spec prices(19) ---> (מחירים מיוחדים)

	GetAllPrices(options: { client?: string; agent?: string; company?: number } = {}) {
		options.client = encodeURIComponent(options.client || this.OneClientInfo.ClientId);
		options.agent ??= this.AgentIdConnected;
		options.company ??= this.OneClientInfo.company;

		const { agent, client, company } = options;

		return this.get<GetAllPrices>(`mobil/user/specprice/${client}/${agent}/${company}`);
		// .pipe(timeout(10),catchError(e=>of(new HttpErrorResponse({error:'not found',status:404}))))
	}

	// מוצרי לקוחות
	GetAllPricesAll() {
		return this.get<GetAllPricesAll>(`mobil/user/specpriceall/${this.AgentIdConnected}`);
	}

	getCustItemSales(ProductId, company = 1) {
		ProductId = encodeURIComponent(ProductId);

		return this.get<CustItemSales>(`mobil/vcustitemsales-customers/${ProductId}/${this.AgentIdConnected}/${company}`);
	}

	//comments(20) ---> (הערות)

	GetHearotPerProduct(id: string) {
		id = encodeURIComponent(id);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/productcomments/' + id + '/' + this.AgentIdConnected + '/' + this.OneClientInfo.company);
	}

	//mivza  per client(23) ---> (    מבצעים  )

	Mivza() {
		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/mivza/' + client + '/' + this.AgentIdConnected + '/' + this.OneClientInfo.company);
	}
	//get shirion(24) ---> (שריון)

	GetAllPricesShirion(client = this.OneClientInfo.ClientId) {
		client = encodeURIComponent(client);

		return this.get<ShirionProducts>(`mobil/user/shirion/${client}/${this.AgentIdConnected}/${this.OneClientInfo.company}`);
	}

	GetAllPricesShirionAll() {
		return this.get<ShirionProducts>(`mobil/user/shirionall/${this.AgentIdConnected}`);
	}
	//get companys(25) ---> (טבלת חברות)

	GetComppanys() {
		return this.get<CompaniesResponse>('mobil/user/companys/' + this.AgentIdConnected);
	}

	GetCompaniesWithoutAgent() {
		return this.get<CompaniesResponse>('mobil/admin/companies/all');
	}
	//get doctypes per client(26) ---> ( סוגי תעודות ללקוח)

	GetDoTypePerClient(ClientId, company) {
		ClientId = encodeURIComponent(ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/doctypesperclient/' + ClientId + '/' + this.AgentIdConnected + '/' + company);
	}

	GetDocTypePerClientAll() {
		return this.get<AllDocTypes>('mobil/user/doctypesperclientall/' + this.AgentIdConnected);
	}
	//get dOPen Checks per client(27) ---> ( צקים פתוחים ללקוח)

	GetAllOpenChecksPerClient() {
		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/checks/' + client + '/' + this.AgentIdConnected + '/' + this.OneClientInfo.company);
	}
	//mlay refresh  (28) ---> ( רענון מלאי  )

	RefreshMlayService() {
		return this.http.get<ResponseFromExpress<MlayRes>>(this.CustomerInformation.CustServer + '/mobil/user/mlay/' + this.OneClientInfo.company);
	}

	RefreshMlayServiceDefault1() {
		return this.http.get<ResponseFromExpress<MlayRes>>(this.CustomerInformation.CustServer + '/mobil/user/mlay/' + 1);
	}
	getStockFromStockTable() {
		return this.get<StockOriginal>('mobil/user/mlay-original');
	}

	getConcentrateProducts(agent = this.AgentIdConnected) {
		agent = encodeURIComponent(agent);
		return this.get(`mobil/product-concentrate/get-all-main-products/${agent}`);
	}

	getItemPacks() {
		return this.get<ItemPacks>(`mobil/user/itempacks/`);
	}

	getRallyanceReclamationReasons() {
		return this.get<RallyanceReclamationReasons>(`mobil/reclamation-rallyance/reasons`);
	}

	submitRallyanceReclamationAgent(body: RallyanceReclamationNewReclamationBody) {
		return this.post(`mobil/reclamation-rallyance`, body);
	}
	//Get Historya Parit(29) ---> ( היסטוריה פריט)

	GetAllHistoryaParitClientService(ProductId) {
		ProductId = encodeURIComponent(ProductId);

		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/historyaparit/' + client + '/' + this.AgentIdConnected + '/' + this.OneClientInfo.company + '/' + ProductId);
	}
	//get bizoim(30) ---> (ביצועים סוכן)

	GetBizoim() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimmonths/' + this.AgentIdConnected);
	}

	getBizoimOfAgent(ag, cm = 1) {
		const params = new HttpParams().set('cm', cm.toString());

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimmonths/' + ag, { params });
	}
	//get bizoim lekohot(31) ---> (  ביצועים לקוחות)

	GetBizoimLekohot() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimlekohot/' + this.AgentIdConnected);
	}

	GetBizoimLekohotYear() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimlekohotyear/' + this.AgentIdConnected);
	}

	GetBizoimLekohotYearWithYaad() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimlekohotyear/with-yaad/' + this.AgentIdConnected);
	}

	GetBizoimLekohotYearWithYaadAdmin() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimlekohotyear/with-yaad/admin');
	}

	GetBizoimLekohotYearCustomAgent(agent = 0) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimlekohotyear/' + agent);
	}

	getBizoimYaadim(cm = 1) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/goals-agent-yaadim/' + this.AgentIdConnected + '/' + cm);
	}

	getGoalsClients(cm = 1) {
		return this.http.get<ResponseFromExpress<ClientGoals>>(this.CustomerInformation.CustServer + '/mobil/admin/get-goals-clients/' + cm);
	}

	getGoalsGroups(agent = '', cm = 1) {
		let path = 'mobil/goals/groups';
		if (agent) {
			path += `/${agent}`;
		}
		return this.get<ResponseFromExpress<GroupGoals>>(path);
	}

	// not implemented
	getGoalsGroupsCustomAgents(agents, cm = 1) {
		return this.post<ResponseFromExpress<GroupGoals>>(`mobil/goals/groups`, { agents });
	}

	updateGroupGoals({ changesArray, GroupId, AgentId }: { changesArray: any[]; GroupId: string; AgentId: string }) {
		return this.patch<ResponseFromExpress<GroupGoals>>(`mobil/goals/groups/${AgentId}/${GroupId}`, { changesArray });
	}

	//get bizoim lekohot each(32) ---> ( כל אחד ביצועים לקוחות)

	EachClientDohot(ClientId, Company, Month, Year, AgentId = this.AgentIdConnected) {
		ClientId = encodeURIComponent(ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimeachclient/' + ClientId + '/' + AgentId + '/' + Company + '/' + Month + '/' + Year);
	}

	EachClientDohotYear(ClientId, Company, Year, AgentId = this.AgentIdConnected) {
		ClientId = encodeURIComponent(ClientId);

		return this.get(`mobil/user/bizoimeachclientyear/${ClientId}/${AgentId}/${Company}/${Year}`);
	}

	EachClientDohotYearWithYaad(ClientId, Company, Year, AgentId = this.AgentIdConnected) {
		ClientId = encodeURIComponent(ClientId);

		return this.get<ClientGoalsYearly>(`mobil/user/bizoimeachclientyear/with-yaad/${ClientId}/${AgentId}/${Company}/${Year}`);
	}

	//get one teudat(33) ---> (תעודה אחת)

	GetOneTeudaDohot(number) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/oneteuda/' + number);
	}

	//Historya Agent (34) ---> (היסטוריה סוכן)

	GetAllTeudotAgentNew() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/agenthistory/' + this.AgentIdConnected, {
			reportProgress: true,
		});
	}

	GetAllTeudotAgentNewUndelivered() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/agenthistory-undelivered/' + this.AgentIdConnected);
	}

	GetAllTeudotAgentNewClient(ClientId) {
		ClientId = encodeURIComponent(ClientId);

		let params = new HttpParams();

		if (this.CompanyNum) {
			params = params.set('cm', this.CompanyNum);
		}

		return this.get<Mismachim>(`mobil/user/agenthistoryclient/${this.AgentIdConnected}/${ClientId}`, params);
	}

	//Historya Agent teuda(35) ---> (היסטוריה סוכן תעודה)

	GetOneTeudaAgentNew(
		docParams: {
			DocDocType: string;
			DocDocNum: string;
		},
		Company,
		AgentId = this.AgentIdConnected,
	) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/teudahistory/' + docParams.DocDocNum.replace(/\s/g, '') + '/' + Company + '/' + AgentId, {
			params: {
				DocDocType: docParams.DocDocType,
			},
		});
	}

	//changed admin true (36) ---> (הוספת מנהל דשבורד)

	UpdateAdminOn(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/adminon', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}
	//changed admin false (37) ---> ( הורדת מנהל דשבורד)

	UpdateAdminOff(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/adminoff', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}
	//changed order status admin (38) ---> ( שינוי סטטוס הזמנה ל 1 )

	ConfirmOrderAdmin(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/orderstatus', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}
	//bizoim gvia etc (38) ---> ( ביצועים גבייה )
	GetBizoimGvia() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimgvia/' + this.AgentIdConnected);
	}
	GetBizoimGviaCustomAgent(agent = '0') {
		return this.get<BizoimGviaResponse>('mobil/user/bizoimgvia/' + agent);
	}

	GetBizoimGviaYearSelected(Year) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimyear/' + this.AgentIdConnected + '/' + Year);
	}
	GetBizoimGviaYearSelectedCustomAgent(Year, agent = '0') {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimyear/' + agent + '/' + Year);
	}

	GetBizoimGviaYearMonthSelected(Year, Month) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimmonth/' + this.AgentIdConnected + '/' + Year + '/' + Month);
	}

	GetBizoimGviaYearMonthSelectedCustomAgent(Year, Month, agent = '0') {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/bizoimmonth/' + agent + '/' + Year + '/' + Month);
	}

	//admin get sales all agents(40) ---> ( מנהל מכירות כל הסוכנים)

	AdminAgentsSales(agents = []) {
		const params = new HttpParams({ fromObject: { agents } });

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/adminagentssales', { params });
	}

	//admin get sales all agents Second(41) ---> ( מנהל מכירות כל הסוכנים)

	AdminAgentsSalesSecond(company, agents: string[] = []) {
		const params = new HttpParams({ fromObject: { agents } });

		return this.get<AdminAgentsSalesSecond>(`mobil/user/adminagentssalessecond/${company}`, params);
	}

	//admin get sales all agents Third(42) ---> ( מנהל מכירות כל הסוכנים)

	AdminAgentsSalesThird(ag, company) {
		return this.get<AdminAgentsSalesThird>(`mobil/user/adminagentssalesthird/${ag}/${company}`);
	}

	//admin get sales all agents Third(42) ---> ( מנהל מכירות כל הסוכנים)

	AdminAgentsSalesFourth(mm, YY, ag, cm) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/adminagentssalesfourth/' + mm + '/' + YY + '/' + ag + '/' + cm);
	}

	// get sales of agent per month - clients

	getAgentsSalesWithClientsPerMonth(mm, YY, ag, cm, checkIfExistsInIndex?: boolean) {
		let params = new HttpParams();

		if (checkIfExistsInIndex) {
			params = params.set('checkIfExistsInIndex', true);
		}

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/user/agent-bizoim-client-month/${mm}/${YY}/${ag}/${cm}`, { params });
	}

	//Historya Agent (43) ---> (היסטוריה סוכן)

	GetAllTeudotAdmin(Company, agents = []) {
		let params = new HttpParams({ fromObject: { agents } });

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/adminhistory/' + Company, {
			...params,
			reportProgress: true,
		});
	}

	getAllTeudotUndeliveredAdmin(poll: boolean, Company: number, agents = []) {
		let params = new HttpParams({ fromObject: { agents } });

		if (poll) {
			params = params.set('checkIfExistsInIndex', true);
		}

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/adminhistory-undelivered/' + Company, { params });
	}

	//itempack (44) ---> ( אריזות )
	GetPackOptionsService(ProductId) {
		ProductId = encodeURIComponent(ProductId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/itempack/' + ProductId + '/' + this.OneClientInfo.company);
	}
	//Main Groups (45) ---> (ראשי מחלקות )

	GetMainGroups() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/user/maingroups/' + this.AgentIdConnected + '/' + this.OneClientInfo.company);
	}
	//Second Groups (46) ---> (תת מחלקות )

	SecondGroup(mg) {
		return this.get<SecondGroup>(`mobil/user/secondgroups/${this.AgentIdConnected}/${this.OneClientInfo.company}/${mg}`);
	}

	SecondGroupSecond(cm = this.OneClientInfo.company) {
		return this.get<SecondGroup>(`mobil/user/secondgroupssecond/${this.AgentIdConnected}/${cm}`);
	}

	getAllMGroups(cm = 1) {
		return this.get<AllMGroup>(`mobil/user/get-all-mgroup/${this.AgentIdConnected}/${cm}`);
	}
	//Second Groups (47) ---> (הזמנות סוכן )

	GetAllOrdersOneAgent() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/client/getbitzoimorders/' + this.AgentIdConnected);
	}
	//ADMIN see agents per days  (48) ---> (הזמנות סוכן מנהל)

	GetAgentsList(agents = []) {
		const params = new HttpParams({ fromObject: { agents } });

		return this.get<TotalAgentPerDay>('mobil/client/adminseetotalagentsperday', params);
	}
	//ADMIN see agents per days one agent one date  (49) ---> (שלב 2 הזמנות סוכן מנהל)

	GetSpecificAgentAndDayService(ag, td, cm) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/client/secondstepadminseetotalagentsperday/' + ag + '/' + td + '/' + cm);
	}
	//ADMIN see agents per days one agent one date  (50) ---> (שלב 3 הזמנות סוכן מנהל)

	GetOneAzmanaAgentAdmonDateService(tn) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/client/oneazamana/' + tn);
	}
	//ADMIN get products (51) ---> (מנהל - מוצרים)

	GetAdminProducts(agents = []) {
		const params = new HttpParams({ fromObject: { agents } });

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/admingetproducts', { params });
	}

	//ADMIN get numoftimes product - clients (52) ---> ( מנהל - מוצרים - לקוחות)

	GetNumPfTimeAllClientsPerProductService(ProductId, agents = []) {
		const params = new HttpParams({ fromObject: { agents } });

		ProductId = encodeURIComponent(ProductId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/productnumoftimeclients/' + ProductId, { params });
	}
	//ADMIN get products teuda (53) ---> (מוצר - תעודה)

	GetProductTeudaService(ProductId, ClientId) {
		ClientId = encodeURIComponent(ClientId);

		ProductId = encodeURIComponent(ProductId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/productteudaadmin/' + ProductId + '/' + ClientId);
	}
	//ADMIN get products teuda (54) ---> (ביקורים מנהל)

	GetAllReportsAdmin() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/bikoradmin');
	}

	GetAllReportsAdminnew() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/abikoradminnew');
	}

	//ADMIN get products teuda (55) ---> (ביקורים סוכן)

	GetAllReportsAgent() {
		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/bikoragent/' + this.AgentIdConnected + '/' + client + '/' + this.OneClientInfo.company);
	}

	GetAllReportsAgentnew() {
		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/bikoragentnew/' + this.AgentIdConnected + '/' + client + '/' + this.OneClientInfo.company);
	}

	//reasons no sale (56) ---> (סיבות אי מכירה סוכן)

	GetAllReportsAgentReasons(company = this.OneClientInfo.company) {
		return this.get<Reasons>(`mobil/reasons/${this.AgentIdConnected}/${company}`);
	}

	//InsertItemsToTeuda (57) ---> (הכנסת ההזמנה לתעודה)

	BikorConfirm(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/bikorinsert', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	BikorConfirmnew(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/abikorinsertnew', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}
	//get Hearot teuda (58) ---> (הערות תעודה)
	GetHearotForTeuda() {
		return this.get<HearotHeadForTeuda>('mobil/hearotforteuda');
	}
	//get hovot by month and year  (59) ---> ( חובות שנה חודש)

	HovotByMonth(company, clientid) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/yearmonthhovot/' + this.AgentIdConnected + '/' + company + '/' + clientid);
	}

	getAllDebtByDates(mode: 'by-create' | 'by-pay', agent = this.AgentIdConnected) {
		const obj = new HttpParams();

		if (!mode) {
			obj.set('checkIfExistsInIndex', true);
		}
		return this.get<ByDate>(`mobil/reports/debt-by-dates/${mode}/${agent}`, obj);
	}

	getClientDebtsSummedAsAdmin(agent: string = this.AgentIdConnected) {
		return this.get<DebtSummedPerAgentAndClient>('mobil/admin-debt-hovot-summed-per-client/' + agent);
	}

	//get Hearot parit (60) ---> (הערות לפריט)

	GetHearotPerProductClick(ProductId) {
		ProductId = encodeURIComponent(ProductId);

		return this.get<ProductCommentSelect>(`mobil/hearotforparit/${this.OneClientInfo.company}/${this.AgentIdConnected}/${ProductId}`);
	}
	//headers type dohot for agent (61) ---> (כותרות דוחות לסוכן)

	DinamicDohotFirst() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/dohotdinamicagentheaders/' + this.AgentIdConnected);
	}
	//dohot for agent (62) ---> ( דוחות לסוכן)

	DinamicDohotSecond(repn) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/dohotdinamicagent/' + this.AgentIdConnected + '/' + repn);
	}

	//heara select for teuda (63) ---> ( סלקט בערה לתעודה)

	SelectHearot(company = this.OneClientInfo.company) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/SelectHearot/' + this.AgentIdConnected + '/' + company);
	}

	SelectHearotsupply() {
		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/SelectHearotsupply/' + this.AgentIdConnected + '/' + this.OneClientInfo.company + '/' + client);
	}
	//migvan(64) ---> (מגוון)
	GetMigvanFilterNew() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/migvan/new/' + this.AgentIdConnected);
	}
	//prices(65) ---> (מחירונים)
	GetPricesNew() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/prices/new/' + this.AgentIdConnected);
	}
	//prices(66) ---> (מסלולים)
	GetMaslol() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/maslol/' + this.AgentIdConnected);
	}

	insertNewMaslol(body: Partial<Activities>) {
		return this.post(`mobil/maslol/${body.OwnerAgentID}`, body);
	}
	// =====> הנחות קבוצתיות לפי חברה

	GetGroupDiscounts() {
		return this.http.get<SQLResponse<AllGroupDiscounts> | SQLError>(this.CustomerInformation.CustServer + '/mobil/user/allGroupDiscountsWithDiscount/' + this.AgentIdConnected);
	}

	getDiscountedItemsOnEntryToProducts() {
		const client = encodeURIComponent(this.OneClientInfo.ClientId);

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/user/allProductstoDiscounts/${client}/${this.AgentIdConnected}/${this.OneClientInfo.company}`);
	}

	//Truck To Teuda(67) ---> (שיוך משאית לתעודה)
	UpdateTDataOneStringTruck(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/TruckToTeuda', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	//Truck To Teuda(68) ---> (שיוך משאית לתעודות)
	UpdateTDataOneStringTruckAll(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/TruckToTeudaAll', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}
	//Dohot Motzar Lekohot(69) ---> (דוחות מוצר לקוחות)

	GetProductNumPerClients(id) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/dohotmotzarlekohot/' + id + '/' + this.AgentIdConnected);
	}

	//Dohot Motzar Lekohot(69) ---> (דוחות מוצר לקוחות)

	AgentsForAdmin() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/agentsforadmin/' + this.AgentIdConnected);
	}

	getYaadGvia({ onlyAgent }: { onlyAgent?: boolean } = {}) {
		const endpoint = `mobil/reports/get-yaad-gvia`;
		return this.get<YaadGvia>(`${endpoint}/${onlyAgent ? this.AgentIdConnected : 'admin'}`);
	}

	getB2BOnlyOrders() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/b2b-orders/' + this.AgentIdConnected);
	}

	getItemExtraProducts() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/get-product-extra-data/${this.AgentIdConnected}/${this.CompanyNum || 1}`);
	}

	getNumOfProductsPurchasedWithClient() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/get-count-per-client-products/' + this.AgentIdConnected);
	}

	getSecondPageOfDohMechira(ClientId) {
		ClientId = encodeURIComponent(ClientId);

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/get-sum-quantity-per-client-products-client/${this.AgentIdConnected}/${ClientId}`);
	}

	getFirstPageOfDohotMotzarimReverse() {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/get-sum-quantity-per-product/' + this.AgentIdConnected);

		// return this.http.get(this.CustomerInformation.CustServer + '/mobil/get-products-clients-report', {params:agents});
	}

	getDohotAgentCategoriesPerformance() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/dohot-agent-categories-performance/${this.AgentIdConnected}/${this.CompanyNum || 1}`);
	}

	getDohotAgentDivisionSales() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/dohot-agent-division-sales/${this.AgentIdConnected}/${this.CompanyNum || 1}`);
	}

	getAgentsGoals(agents = [], cm: number | number[] = 0) {
		const params = new HttpParams({ fromObject: { agents, cm } });

		return this.get<AgentGoals>(`mobil/admin-goals`, params);
	}

	createPDFInIndex(body, str = '') {
		const endpoint = str === 'hovot' ? 'pdf-debt-email' : 'pdf-template-test';

		const params = {};

		if (body.replyTo) {
			params['replyTo'] = body.replyTo;
		}

		return this.http.post(`${this.CustomerInformation.CustServer}/mobil/${endpoint}`, body, {
			params,
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	getPDFToDownload(body) {
		return this.http.post<Blob>(`${this.CustomerInformation.CustServer}/send-download-pdf-test`, body);
	}

	checkIfPDFExistInServer(directory, filename) {
		return this.http.post(`${this.CustomerInformation.CustServer}/mobil/does-pdf-exist`, { directory, filename });
	}

	sendPDFThroughEmail(to, path, filename) {
		return this.http.post(`${this.CustomerInformation.CustServer}/mobil/send-pdf-email`, { to, path, filename });
	}

	changeSupplyDateTeudaTable(body) {
		return this.http.patch(`${this.CustomerInformation.CustServer}/mobil/changeTeudaSupplyDateTeudaTable`, body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	changeSupplyDateTDataOneString(body) {
		return this.http.patch(`${this.CustomerInformation.CustServer}/mobil/changeTeudaSupplyDateTDataOneStringTable`, body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	checkIfImageExistInServer(file) {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/get-image-download/${file}`);
	}
	getAllDocMinTeuda(agentid = this.AgentIdConnected) {
		return this.get<MinDocTeuda>(`mobil/user/min-teuda/${agentid}`);
	}

	getAgentSalesProductsQuantitiesDates(from?: Date | string, to?: Date | string, agents?: string | string[], groupAgents?: boolean) {
		const params: Partial<getAgentSalesProductsQuantitiesDatesBody> = {};

		if (from) {
			if (from instanceof Date) {
				from = from.toISOString();
			}
			params['from'] = from;
		}

		if (to) {
			if (to instanceof Date) {
				to = to.toISOString();
			}
			params['to'] = to;
		}

		if (agents) {
			params['agents'] = agents;
		}

		if (groupAgents) {
			params['groupAgents'] = true;
		}

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/get-agent-sales`, { params });
	}

	getAgentSalesOrderssQuantitiesDates(from?: Date | string, to?: Date | string, agents?: string | string[], groupAgents?: boolean) {
		const params: Partial<getAgentSalesProductsQuantitiesDatesBody> = {};

		if (from) {
			if (from instanceof Date) {
				from = from.toISOString();
			}
			params['from'] = from;
		}

		if (to) {
			if (to instanceof Date) {
				to = to.toISOString();
			}
			params['to'] = to;
		}

		if (agents) {
			params['agents'] = agents;
		}

		if (groupAgents) {
			params['groupAgents'] = true;
		}

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/get-agent-orders`, { params });
	}

	getAgentSalesProductsQuantitiesDatesSpecificProduct(productid: string, from?: Date | string, to?: Date | string, agents?: string | string[]) {
		const params: Partial<getAgentSalesProductsQuantitiesDatesBody> = {};

		if (from) {
			if (from instanceof Date) {
				from = from.toISOString();
			}
			params['from'] = from;
		}

		if (to) {
			if (to instanceof Date) {
				to = to.toISOString();
			}
			params['to'] = to;
		}

		if (agents) {
			params['agents'] = agents;
		}

		return this.http.get<ResponseFromExpress<BizoimClientResponse>>(`${this.CustomerInformation.CustServer}/mobil/reports/get-agent-sales/${productid}`, { params });
	}

	getAgentSalesProductsQuantitiesDatesConcentrated(from?: Date | string, to?: Date | string, agents?: string | string[], groupAgents?: boolean) {
		const params: Partial<getAgentSalesProductsQuantitiesDatesBody> = {};

		if (from) {
			if (from instanceof Date) {
				from = from.toISOString();
			}
			params['from'] = from;
		}

		if (to) {
			if (to instanceof Date) {
				to = to.toISOString();
			}
			params['to'] = to;
		}

		if (agents) {
			params['agents'] = agents;
		}

		if (groupAgents) {
			params['groupAgents'] = true;
		}

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/get-concentrated-sales-per-agents`, { params });
	}

	handleGetReasonsOfSelectForReclamation() {
		return this.http.get<SQLResponse<ReclamationReasonsResponse>>(`${this.CustomerInformation.CustServer}/mobil/reclamation/get-reasons`);
	}

	handleGetAllReclamationOrders() {
		return this.http.get<SQLResponse<AllReclamationsResponse>>(`${this.CustomerInformation.CustServer}/mobil/reclamation/get-reclamations`);
	}

	handleGetLatestReclamationID() {
		let params = new HttpParams({ fromObject: { getOnlyLastRekID: true } });
		return this.http.get<SQLResponse<AllReclamationsResponse>>(`${this.CustomerInformation.CustServer}/mobil/reclamation/get-reclamations`, { params });
	}

	handleReclamationOrder(body) {
		return this.http.post(`${this.CustomerInformation.CustServer}/mobil/new-order/reclamation`, body);
	}

	handleGetBrandTable() {
		return this.http.get<SQLResponse<BrandResponse>>(`${this.CustomerInformation.CustServer}/mobil/reports/brands/${this.AgentIdConnected}/${this.CompanyNum || 1}`);
	}

	handleGetBrandAdminTable() {
		return this.http.get<SQLResponse<BrandResponse>>(`${this.CustomerInformation.CustServer}/mobil/reports/brands/admin/${this.AgentIdConnected}/${this.CompanyNum || 1}`);
	}

	handleGetAllGroupSalesAdmin() {
		return this.http.get<SQLResponse<GroupSales>>(`${this.CustomerInformation.CustServer}/mobil/reports/quantities-by-departments-admin`);
	}

	handleGetAllGroupSalesPerOneAgent() {
		return this.http.get<SQLResponse<GroupSales>>(`${this.CustomerInformation.CustServer}/mobil/reports/quantities-by-departments/${this.AgentIdConnected}`);
	}

	handleGetYearlyGroupSalesAdmin(groupID: string) {
		groupID = encodeURIComponent(groupID);

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/quantities-by-departments-admin/${groupID} `);
	}

	handleGetYearlyGroupSalesPerOneAgent(groupID: string) {
		groupID = encodeURIComponent(groupID);

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/quantities-by-departments/${this.AgentIdConnected}/${groupID}`);
	}

	handleGetAllGroupSalesWithProducts(groupID: string, month: string, year: string) {
		groupID = encodeURIComponent(groupID);

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/quantities-by-departments/${this.AgentIdConnected}/${groupID}/products`, { params: { month, year } });
	}

	handleGetAllGroupSalesWithProductsAdmin(groupID: string, month: string, year: string) {
		groupID = encodeURIComponent(groupID);

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/quantities-by-departments-admin/${groupID}/products`, { params: { month, year } });
	}

	getDailyInvoices(agents = [], Company = 0) {
		const params = { Company };

		if (agents.length) {
			params['agents'] = agents.join(',');
		}

		return this.http.get<ResponseFromExpress<Invoices>>(`${this.CustomerInformation.CustServer}/mobil/reports/daily-invoices`, { params });
	}

	getSalesVsReturnsAdmin() {
		const params = { company: this.CompanyNum || 1 };

		return this.http.get<ResponseFromExpress<SalesVsReturns.Main>>(`${this.CustomerInformation.CustServer}/mobil/reports/admin/sales-vs-returns`, { params });
	}

	getSalesVsReturnsPerAgentAdmin(agentcode, yy) {
		const params = { company: this.CompanyNum || 1, agentcode, yy };

		return this.http.get<ResponseFromExpress<SalesVsReturns.PerAgent>>(`${this.CustomerInformation.CustServer}/mobil/reports/admin/sales-vs-returns/agent`, { params });
	}

	getSalesVsReturnsPerAgentPerClientAdmin(agentcode, yy, clientid) {
		const params = { company: this.CompanyNum || 1, agentcode, yy, clientid };

		return this.http.get<ResponseFromExpress<SalesVsReturns.PerClient>>(`${this.CustomerInformation.CustServer}/mobil/reports/admin/sales-vs-returns/agent/clients`, { params });
	}

	getSalesVsReturnsPerAgentPerClientMonthAdmin(agentcode, yy, clientid, mm) {
		const params = { company: this.CompanyNum || 1, agentcode, yy, clientid, mm };

		return this.http.get<ResponseFromExpress<SalesVsReturns.PerMonth>>(`${this.CustomerInformation.CustServer}/mobil/reports/admin/sales-vs-returns/agent/clients/month`, { params });
	}

	getAllSpecials() {
		return this.http.get<ResponseFromExpress<Special>>(`${this.CustomerInformation.CustServer}/mobil/get-specials/all`);
	}

	postNewSpecial(body: specialPostBody[]) {
		return this.http.post<ResponseFromExpress<Special>>(`${this.CustomerInformation.CustServer}/mobil/update-specials`, body);
	}

	patchEditSpecial(body: specialPatchBodyEdit) {
		return this.http.patch<ResponseFromExpress<Special>>(`${this.CustomerInformation.CustServer}/mobil/update-specials`, body);
	}

	getLastSpecial() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/get-specials/all/last-id`);
	}

	deactivateSpecial(SpecialID: number) {
		return this.http.delete(`${this.CustomerInformation.CustServer}/specials/deactivate-special/${SpecialID}`);
	}

	//Autolux vehicles branches איתור סניף
	getBranches() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/branches/all`);
	}

	getB2BAutoluxDashboard(agent: string) {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/alt-dashboard/${agent}`);
	}

	getDocSpecialProducts(agent = this.AgentIdConnected, company = this.CompanyNum) {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/get-products/doc-special/${agent}/${company}`);
	}

	// Tzur agencies (צור סוכנויות) allocations הקצאות סוכן
	getAllotcationReports() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/reports/get-allocations/admin`);
	}

	getAlerts() {
		return this.get<AlertInDatabase>(`mobil/alerts`);
	}

	deleteAlert(id: number) {
		return this.delete<AlertInDatabase>(`mobil/alerts/${id}`);
	}

	postNewAlert(body: AlertPostBody) {
		return this.post<AlertInDatabase>(`mobil/alerts`, body);
	}

	patchAlert(id: number, body: Partial<AlertPostBody>) {
		return this.patch<AlertInDatabase>(`mobil/alerts/${id}`, body);
	}

	getAgentGvia<T = void>(AgentId = this.AgentIdConnected, Company = 1) {
		const params = new HttpParams({ fromObject: { Company, AgentId } });

		return this.get<T>(`mobil/debt/agent-gvia`, params);
	}

	getAllTeudotForLikutNewRoute(args: { role: 'melaket' | 'checker'; assigned: boolean }) {
		let endpoint = 'mobil/likut';

		if (args.role === 'melaket') {
			endpoint += '/get-all-main-for-melaket';
		}

		if (args.role === 'checker') {
			endpoint += '/get-all-main-for-checker';
		}

		if (args.assigned) {
			endpoint += '-only-assigned/' + this.AgentIdConnected;
		}

		return this.get<LikutMainSQL>(endpoint);
	}

	getAllTeudotForLikutMekabel(args: { role: 'mekabel' }) {
		let endpoint = 'mobil/likut';

		if (args.role === 'mekabel') {
			endpoint += '/get-all-for-mekabel';
		}

		return this.get<LikutKabalaMainSQL>(endpoint);
	}

	getLikutMekabelDocTypes() {
		return this.get<DocTypesNumToName>(`mobil/likut/get-kabala-doc-types`);
	}

	getLikutMekabelWarehouses() {
		return this.get<MekabelWarehouse>(`mobil/likut/get-kabala-warehouses`);
	}

	getLikutMekabelSuppliers() {
		return this.get<AllClients>(`mobil/likut/get-kabala-suppliers`);
	}

	getLikutByTeudaNumMain(TeudaNum: string[] | string, mode: 'get' | 'mark' | 'freeup') {
		let endpoint = 'mobil/likut/';
		const body: { TeudaNum: string[] | string; OpenedBy?: string } = { TeudaNum };

		if (mode === 'get') {
			endpoint += 'get-one-main';
		}
		if (mode === 'mark' || mode === 'freeup') {
			endpoint += 'mark-one-main-as-openedby';
			body.OpenedBy = mode === 'freeup' ? null : this.AgentIdConnected;
		}

		return this.post<LikutMainSQL>(endpoint, body);
	}

	getLikutMekabelByTeudaNum(TeudaNum: string, mode: 'get') {
		let endpoint = 'mobil/likut/';
		const body: { TeudaNum: string[] | string } = { TeudaNum };

		if (mode === 'get') {
			endpoint += 'get-one-teuda-for-mekabel';
		}

		return this.post<LikutKabalaMainSQL>(endpoint, body);
	}

	finishAndSendLikutNewRoute(body: LikutFinishBodyNewRoutes[]) {
		if (body.filter(row => row.table === 'sub' && row.LStatus === LStatuses.isDuplicated).length) {
			return this.patch(`mobil/likut/finish-task-as-melaket-with-added-products`, body);
		}
		return this.patch(`mobil/likut/finish-task-as-melaket-or-checker`, body);
	}

	finishAndSendLikutMekabel(body: LikutMekabelFinishBody[]) {
		return this.patch(`mobil/likut/finish-task-as-mekabel`, body);
	}

	finishAndSendLikutMekabelNewTask(body: LikutMekabelFinishBodyForUnexistingTask[]) {
		return this.post(`mobil/likut/finish-task-as-mekabel-new-task`, body);
	}

	getLikutMekabelByTeudaNumSub(input: { TeudaNum: string; TeudaType: number }) {
		const { TeudaNum, TeudaType } = input;
		return this.post<LikutKabalaSubSQL>(`mobil/likut/get-all-sub-for-mekabel`, { TeudaNum, TeudaType });
	}
	getLikutByTeudaNumSub(TeudaNum: string[]) {
		return this.post<LikutSubSQL>(`mobil/likut/get-all-sub`, { TeudaNum });
	}
	getLikutByTeudaNumSubHistory(TeudaNum: string[]) {
		return this.post<LikutSubSQL>(`mobil/likut/get-all-sub-history`, { TeudaNum });
	}

	getAllForLikutReport(mode: 'all' | 'all-history' | 'all-after-checker' | 'unassigned' | 'not-fulfilled' | 'no-status') {
		let base = 'mobil/likut/get-main';
		switch (mode) {
			case 'all':
				return this.get<LikutMainSQL>(`${base.slice(0, -5)}-all-main`);
			case 'all-after-checker':
				return this.get<LikutMainSQL>(`${base.slice(0, -5)}-task-with-checker-checked-status`);
			case 'all-history':
				return this.get<LikutMainSQL>(`${base.slice(0, -5)}-all-main-history`);
			case 'unassigned':
				return this.get<LikutMainSQL>(`${base}-for-assignment`);
			case 'not-fulfilled':
				return this.get<LikutMainSQL>(`${base}-for-not-fulfilled`);
			case 'no-status':
				return this.get<LikutMainSQL>(`${base}-no-status-for-activate-likut`);

			default:
				return this.get<LikutMainSQL>('i should  never be reached');
		}
	}

	patchMainAndTaskForLikut(mode: 'assign-status-from-null-to-activate' | 'confirm-checker' | 'assign-status-from-null-to-cancelled', teudanum: string | string[]) {
		let base = 'mobil/likut/set-main-and-sub';
		switch (mode) {
			case 'assign-status-from-null-to-activate':
				return this.patch<LikutMainSQL>(`${base}-status-for-activate-likut`, { TeudaNum: teudanum });
			case 'assign-status-from-null-to-cancelled':
				return this.patch<LikutMainSQL>(`${base}-status-for-cancel-likut`, { TeudaNum: teudanum });
			case 'confirm-checker':
				return this.patch<LikutMainSQL>(`mobil/likut/set-task-with-checker-checked-status`, { TeudaNum: teudanum });

			default:
				return this.get<LikutMainSQL>('i should  never be reached');
		}
	}

	assignLikutToUserNewRoute(teudanum: string, user: string) {
		return this.patch(`mobil/likut/assign-task-in-main-and-sub/${user}/${teudanum}`, {});
	}

	// getAllLikutUnassigned() {
	// 	return this.get<LikutFromSql>(`mobil/likut/get-for-assignment`);
	// }

	getAllLikutUsers() {
		return this.get<LikutUsers>(`mobil/likut/get-likut-users`);
	}

	getBIReport(report: string) {
		return this.get<BIReports>(`mobil/reports/dynamic-reports/${report}`);
	}

	getPlannedVsUnplannedReport() {
		return this.get<VisitsPlannedVsUnplanned>(`mobil/reports/maslul/planned-vs-unplanned`);
	}

	getVKabalaReceipts(ClientId: string, AgentID?: string, company?: number) {
		ClientId = encodeURIComponent(ClientId);

		let params = new HttpParams();

		if (AgentID) {
			params = params.set('AgentID', AgentID);
		}
		if (company) {
			params = params.set('company', company);
		}

		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/get-all-receipts/${ClientId}`, { params });
	}

	getAllVKabalaReceiptsGviaReport() {
		return this.get<ReceiptForGvia>(`mobil/get-all-receipts/`);
	}

	//=====================================================================================================================================================================================================================================================================>
	//DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD DASHBORD
	GetTeudotDashbord(agents = []) {
		const params = new HttpParams({ fromObject: { agents } });

		return this.http.get(this.CustomerInformation.CustServer + '/mobil/client/dashbord', { params });
	}

	getB2BDashboard() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/b2b-dashboard`);
	}

	getB2BOrders() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/client/getbitzoimorders-b2b/${this.AgentIdConnected}`);
	}

	getAdminAgents() {
		const params = new HttpParams({ fromObject: { admin: this.AgentIdConnected } });
		return this.get<Submanagers>('mobil/get-TAdminAgents', params);
	}

	GetTeudotEach(TeudaNum) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/' + TeudaNum);
	}

	GetTeudotFirst(TeudaNum) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/dash/' + TeudaNum);
	}

	GetAllAgents(agents = []) {
		const params = new HttpParams({ fromObject: { agents } });

		return this.get<Users>('mobil/user/all', params);
	}

	GetProductsToEditTeuda(value) {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/editteuda/${value}`);
	}

	postTeudaProductEdits(body) {
		return this.http.post(this.CustomerInformation.CustServer + '/mobil/Editme', body, {
			headers: { 'Content-type': 'application/json' },
			responseType: 'text',
		});
	}

	postTeudaStatusChange(value) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/editteudastatus/' + value);
	}

	postTeudaStatusChangeOnestring(value) {
		return this.http.get(this.CustomerInformation.CustServer + '/mobil/edit-teuda-status-onestring/' + value);
	}

	deleteTeudaFromTeudaTable(teudaNum) {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/delete-teuda-from-teuda-table/${teudaNum}`);
	}

	deleteTeudaFromOnestring(teudaNum) {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/delete-teuda-from-onestring-table/${teudaNum}`);
	}

	getObligoTeudaDashboard() {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/get-teudot-obligo`);
	}

	sendPostRequestToChangeTeudaPrintStatus(TeudaNum) {
		return this.http.get(`${this.CustomerInformation.CustServer}/mobil/dashboard/edit-teuda-print-status-onestring/${TeudaNum}`);
	}

	permanentlyDeleteTeudaFromBothOneStringAndTeuda(TeudaNum = '', TeudaNewNum = '') {
		return this.http.delete(`${this.CustomerInformation.CustServer}/mobil/delete-teuda-from-database`, { params: { TeudaNum, TeudaNewNum, checkIfImageExistInServer: true } });
	}

	burgerMenu = false;
	headerRelative = false;
	headerAbsolute = false;
	burgerMenuNoScroll = false;

	buttonWidth = Math.min(window.innerWidth / 15, 30);

	//TODO might be shorter to list the secondary color pages

	openMenuAndAnimateButton(btn?: HTMLInputElement) {
		btn ??= document.querySelector('label.toggle input') as HTMLInputElement;

		const currentState = btn ? btn.getAttribute('data-state') : null;
		const isClosed = (currentState ?? 'closed') === 'closed';

		this.burgerMenu = isClosed;

		if (isClosed) {
			btn.setAttribute('data-state', 'opened');
			btn.setAttribute('aria-expanded', 'true');
			this.headerRelative = this.headerAbsolute = this.burgerMenuNoScroll = isClosed;
		} else {
			btn.setAttribute('data-state', 'closed');
			btn.setAttribute('aria-expanded', 'false');
			setTimeout(() => {
				this.headerRelative = this.headerAbsolute = this.burgerMenuNoScroll = isClosed;
			}, 1000);
		}
	}

	closeMenu() {
		if (this.burgerMenu) {
			const btn = document.querySelector('label.toggle') as HTMLLabelElement;
			btn.click();
		}
	}

	cleanupForProductsPage() {
		this.AllMismachimClient =
			this.OneClientInfo =
			this.OneClientInfoDebt =
			this.DocTypeName =
			this.DocType =
			this.AllOrdersDetailsClient =
			this.SpecPriceSs =
			this.VCust =
			this.MivzaA =
			this.HearotHeadForTeuda =
			this.BackToProductsFromTeuda =
			this.BackToProductsOnlineStatus =
			this.BackToProductsFromTeudaSectionName =
			this.allProductsSearchForTeudaCheck =
			this.allProductsSearchByDateForSchichim =
			this.ParamBitzoimClientCard =
			this.DocTypeShrion =
				null;

		this.BackFromClientCard = this.duplicatedTeudaAndCleanupForFilter = this.FromProductsToDohot = false;

		this.AutoMoveHovot = this.NoObligoCheck = this.CheckMinPrice = this.AutoMoveAgentOrders = 0;

		this.HearaNewOrder1 = this.HearaNewOrder2 = this.HearaNewOrder3 = this.HearaNewOrder4 = '';
	}

	cleanupForDoctype() {
		this.Heara1 = this.Heara2 = this.Heara3 = this.Heara4 = 0;
		this.NoObligoCheck = this.CheckMinPrice = 0;
		this.DocType = this.DocTypeName = this.DocTypeShrion = null;
		this.AllClientsHold = null;
	}

	cleanupReports() {
		this.SaveTeudotDohot = null;
	}
}

type getAgentSalesProductsQuantitiesDatesBody = {
	agents: string | string[];
	from: string;
	to: string;
	groupAgents: boolean;
};
