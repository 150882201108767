import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { StoreService } from '../services/store.service';
import { decompress } from 'lz-string';
import { MOBILITY_LOGO } from '../Utility/constants';

@Directive({
	selector: '[appFallbackSrc]',
})
export class FallbacksrcDirective {
	fallbackSrc = MOBILITY_LOGO;

	constructor(private _ss: StoreService, private renderer: Renderer2, private el: ElementRef<HTMLImageElement>) {
		if (!_ss.CustomerInformation) {
			const ls = localStorage.getItem('CustomerInformation');
			console.log({ ls });
			if (ls) {
				_ss.CustomerInformation = JSON.parse(decompress(ls));
			}
		}

		if (this._ss.CustomerInformation.DisplayLogo === '1') {
			this.fallbackSrc = `${this._ss.CustomerInformation.CustImageDomain}/companylogo.jpg`;
		}
	}

	@HostListener('error') onError() {
		// if (window.navigator.onLine) {
			if (!this.el.nativeElement.src.includes(this.fallbackSrc)) {
				// if companylogo doesn't exist or network error
				if (this.el.nativeElement.src.includes('companylogo')) {
					this.fallbackSrc = MOBILITY_LOGO;
				}
				this.renderer.setAttribute(this.el.nativeElement, 'src', this.fallbackSrc);
			}
		// }
	}
}
