<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="header header_app-shop-return" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToHome()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /> <span style="color: var(--secondary-color); font-size: 14px">חזרה</span>
					</div>

					<!-- option search start keyup 0-->

					<div class="header__search" *ngIf="this._ss.SearchStyle === 1">
						<form [formGroup]="form" (keyup)="SearchClient()">
							<input
								class="header__search-input"
								type="text"
								placeholder="{{ this._ss.AllClientsHoldSearch }}"
								formControlName="ClientSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="clientsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
					</div>

					<!-- option search ends keyup -->

					<!-- option search ends click 1-->

					<div class="header__search" *ngIf="this._ss.SearchStyle === 0">
						<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="SearchClient()">
							<img src="/assets/images/header-search.svg" alt="search" />
						</button>
						<form [formGroup]="form" (keyup.enter)="SearchClient()">
							<input
								class="header__search-input"
								type="text"
								placeholder="{{ this._ss.AllClientsHoldSearch }}"
								formControlName="ClientSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="clientsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
					</div>
					<!-- option search ends click -->
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- start shop/return markup -->
		<section class="shop" *ngIf="Loading === false">
			<div class="content">
				<div
					class="shop__filters row flex-column"
					[ngClass]="{
						'main-content_absolute': !_ss.headerAbsolute
					}"
				>
					<h3 class="performance-line clickable" *ngIf="bizoimOfMonth" (click)="router.navigate(['dohot-bizoim-agent'])">
						ביצועים החודש:
						<span style="position: relative">
							<img *ngIf="bizoimReflect" [src]="bizoimReflect === 'down' ? '../../../assets/images/arrow-down-red.svg' : '../../../assets/images/arrow-up-green.svg'" class="arrow" />
							{{ bizoimOfMonth | number : '1.0-2' }}
						</span>
					</h3>
					<div class="header__block">
						<div class="filter-sort__row" [ngClass]="{ 'filter-sort__row-active': showingOnlyMaslul }" (click)="MaslulPickPop = true">
							<span class="filter-sort__row-name">תכנון יומי</span>
							<div class="filter-sort__row-img image">
								<img [src]="'/assets/images/filters/pointer' + (showingOnlyMaslul ? '-white' : '') + '.svg'" alt="photo" />
							</div>
						</div>
						<!-- <div
						class="filter-sort__row"
						(click)="showHidePopup(true, popupType.range)"
						>
						<span class="filter-sort__row-name">מרחק</span>
						<div class="filter-sort__row-img image">
							<img src="/assets/images/filters/cursor.svg" alt="photo">
						</div>
            </div> -->
						<div class="filter-sort__row" *ngIf="this._ss.CompanyName !== null">
							<span class="filter-sort__row-name">{{ this._ss.CompanyName }}</span>
							<!-- <div class="filter-sort__row-img image"></div> -->
						</div>

						<div class="filter-sort__row filter-sort__row_double">
							<!-- <div
							class="filter-sort__row filter-sort__row_double"
							[matBadgeHidden]="!CityCheckedArray?.length"
							[matBadge]="CityCheckedArray?.length"
							matBadgePosition="before"
							matBadgeColor="primary"
						> -->

							<div class="filter-sort__row-block" (click)="Sanen()">
								<span class="filter-sort__row-name">סנן</span>
								<div class="filter-sort__row-img image">
									<img src="/assets/images/filters/filter.svg" alt="photo" />
								</div>
							</div>
							<div class="filter-sort__row-block" (click)="showHideSortPopup(true)">
								<span class="filter-sort__row-name">מיין</span>
								<div class="filter-sort__row-img image">
									<img src="/assets/images/filters/arrows.svg" alt="photo" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <ng-container *ngIf="_loading.isLoaded$ | async"> -->
				<div class="shop__cards row" *ngIf="_b2b.isNotB2BAgent$ | async; else b2bBlock">
					<cdk-virtual-scroll-viewport [itemSize]="itemSize" [minBufferPx]="itemSize * 5" [maxBufferPx]="itemSize * 5 * 2" class="example-viewport" [ngClass]="{ bizoim: bizoimOfMonth }">
						<div
							class="shop__card"
							*cdkVirtualFor="let ac of AllClients; index as i"
							[ngClass]="{ 'shop__card__with-color': _ss.CustomerInformation.ColorClientName === '1' && ac.CC, 'shop__card-remark-open': show[i] }"
							[ngStyle]="{ '--color': ac.CC }"
						>
							<hr *ngIf="ac.Azmana || ac.Ahzara || ac.Azaha" appGetBackground [Azmana]="ac.Azmana" [Ahzara]="ac.Ahzara" [Azaha]="ac.Azaha" />
							<div class="shop__card-marker" appGetBackground [CC]="ac.CC"></div>
							<div class="shop__card-block shop__card-block_center">
								<p
									style="position: absolute; top: -21px"
									*ngIf="(ac.Azmana || ac.Ahzara || ac.Azaha) && _ss.CustomerInformation.CustSeePrice !== '1' && (ac.DSP !== 2 || TotalOrderOverride === '1')"
								>
									<span style="color: var(--valid-color); margin: 0 5px" *ngIf="ac.Azmana">{{ ac.Azmana }}</span>
									<span style="color: var(--invalid-color); margin: 0 5px" *ngIf="!ac.Azmana && ac.Ahzara">{{ ac.Ahzara }}</span>
									<span style="color: blue; margin: 0 5px" *ngIf="!ac.Azmana && !ac.Ahzara && ac.Azaha">{{ ac.Azaha }}</span>
								</p>
								<div
									class="shop__card-title"
									(click)="MoveToClientCard(ac.ClientId)"
									[ngClass]="{ 'shop__card-title-pad-right': +ac.Client_Name?.length > 38 && ac.Hearot && ac.Hearot.trim().length }"
								>
									<div class="shop__card-title-text" appGetTextColor [Knisa]="ac.Knisa" [Exit]="ac.Exit">{{ ac.Client_Name }}</div>
								</div>
								<div class="shop__card-number">
									<strong>{{ ac.ClientId }}</strong> - {{ ac.Address && ac.CustCity ? ac.Address + ',' + ac.CustCity : ac.Address || ac.CustCity || '' }}
								</div>
							</div>
							<div class="shop__card-chat" (click)="openSelect(i)" *ngIf="ac.Hearot && ac.Hearot.trim().length"></div>
							<div class="shop__card-block">
								<div
									class="shop__card-basket shop__card-basket_bikur"
									(click)="handleBikurShortcutNavigate(ac, ac.Knisa === 'true')"
									[ngClass]="{ 'shop__card-basket_bikur-in': ac.Knisa === 'false', 'shop__card-basket_bikur-out': true }"
									*ngIf="hasBikurIcon"
								></div>
								<div class="shop__card-basket shop__card-basket_refresh" *ngIf="ac.DocType !== 0 && ac.AO !== 1 && ac.AM !== 1" (click)="MoveToProductsAhzara(ac)"></div>
								<div class="shop__card-info">
									<div class="shop__card-info-block" *ngIf="_ss.CustomerInformation.ShowObligoInstead !== '1'; else elseBlock" (click)="MoveToDailyReport({ source: 'card', client: ac })">
										<div class="shop__card-info-text shop__card-info-text_title">הזמנה אחרונה:</div>
										<div class="shop__card-info-text">{{ ac.Ldt }}</div>
									</div>
									<ng-template #elseBlock>
										<div
											class="shop__card-info-block"
											(click)="MoveToClientHovot(ac.ClientId)"
											[ngStyle]="{ border: (ac.CMC || 0) - (+(ac.COD || 0) + +(ac.COC || 0)) - +(ac.AddObligo || 0) >= 0 ? 'initial' : '3px solid red' }"
										>
											<div class="shop__card-info-text shop__card-info-text_title">אובליגו זמין:</div>
											<div
												class="shop__card-info-text"
												[ngStyle]="{ color: (ac.CMC || 0) - (+(ac.COD || 0) + +(ac.COC || 0)) - +(ac.AddObligo || 0) >= 0 ? '#38D88B' : 'black' }"
												style="direction: ltr"
											>
												{{ (ac.CMC || 0) - (+(ac.COD || 0) + +(ac.COC || 0)) - +(ac.AddObligo || 0) }}
											</div>
										</div>
									</ng-template>
									<div class="shop__card-info-block" (click)="MoveToClientHovot(ac.ClientId)" *ngIf="canSeePrices">
										<div class="shop__card-info-text shop__card-info-text_title">חוב פתוח:</div>
										<div class="shop__card-info-text shop__card-info-text_title" *ngIf="ac.COD <= 0" dir="ltr">{{ ac.COD | number : '1.0-2' : 'en-US' }}</div>
										<div class="shop__card-info-text shop__card-info-text_title" style="color: red" *ngIf="ac.COD > 0" dir="ltr">{{ ac.COD | number : '1.0-2' : 'en-US' }}</div>
									</div>
								</div>
								<div class="shop__card-basket" (click)="MoveToProductsAzmana(ac.ClientId, ac.company, ac.DocType, ac.DocTypeName)"></div>
							</div>
							<div class="shop__card-block shop__card-block_comment" *ngIf="show[i]">
								<div class="shop__card-comment-row">
									<div class="shop__card-comment-text">הערות</div>
								</div>
								<div class="shop__card-comment-row shop__card-comment-row_left row" (click)="MoveToClientCard(ac.ClientId)">
									<div class="shop__card-comment-button">
										{{ ac.Hearot }}
									</div>
								</div>
								<div class="shop__card-comment-row row">
									<div class="shop__card-comment-button shop__card-comment-button_close" (click)="openSelectClose(i)">סגור</div>
								</div>
							</div>
						</div>
					</cdk-virtual-scroll-viewport>
				</div>

				<ng-template #b2bBlock>
					<div class="shop__cards row">
						<div class="shop__card" [ngClass]="{ 'main-background': _ss.CustomerInformation.SpecificCompanyVersion === '1' }" *ngFor="let ac of AllClients; index as i">
							<hr style="height: 5px; bottom: 22px" appGetBackground [Azmana]="ac.Azmana" [Ahzara]="ac.Ahzara" [Azaha]="ac.Azaha" *ngIf="ac.Azmana || ac.Ahzara || ac.Azaha" />
							<div class="shop__card-marker" appGetBackground [CC]="ac.CC"></div>
							<div class="shop__card-block shop__card-block_center">
								<div class="shop__card-title">
									<div class="shop__card-title-text" (click)="MoveToClientCard(ac.ClientId)">{{ ac.Client_Name }}</div>
								</div>
								<div class="shop__card-number">{{ ac.ClientId }}</div>
							</div>
							<div class="shop__card-block">
								<div class="shop__card-info">
									<div class="shop__card-info-block" (click)="MoveToClientHovot(ac.ClientId)" *ngIf="canSeePrices">
										<div class="shop__card-info-text shop__card-info-text_title">חוב פתוח:</div>
										<div class="shop__card-info-text shop__card-info-text_title" *ngIf="ac.COD <= 0" dir="ltr">{{ ac.COD | number : '1.0-2' : 'en-US' }}</div>
										<div class="shop__card-info-text shop__card-info-text_title" style="color: red" *ngIf="ac.COD > 0" dir="ltr">{{ ac.COD | number : '1.0-2' : 'en-US' }}</div>
									</div>
								</div>
								<div class="shop__card-basket" (click)="MoveToProductsAzmana(ac.ClientId, ac.company, ac.DocType, ac.DocTypeName)"></div>
							</div>
							<div class="shop__card-block shop__card-block_comment" *ngIf="show[i]">
								<div class="shop__card-comment-row">
									<div class="shop__card-comment-text">הערות</div>
								</div>
								<div class="shop__card-comment-row shop__card-comment-row_left row" (click)="MoveToClientCard(ac.ClientId)">
									<div class="shop__card-comment-button">
										{{ ac.Hearot }}
									</div>
								</div>
								<div class="shop__card-comment-row row">
									<div class="shop__card-comment-button shop__card-comment-button_close" (click)="openSelectClose(i)">סגור</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
				<!-- </ng-container> -->
			</div>
		</section>
		<!-- end shop/return markup -->

		<section class="shop-delivery" *ngIf="Loading === true">
			<div class="container">
				<div class="shop-delivery__row row">
					<div class="shop-delivery__photo image">
						<img src="/assets/images/shop-return/delivery-icon.gif" alt="photo" />
					</div>
					<h2 class="shop-delivery__title shop-delivery__title_margin">{{ this.Status }}</h2>
					<h2 class="shop-delivery__title">{{ this.Date }}</h2>
					<h2 class="shop-delivery__title"><span class="shop-delivery__title-bold">נא לא לסגור את הדפדפן</span> סגירת הדפדפן תצריך סנכרון חדש</h2>
				</div>
			</div>
		</section>

		<!-- markup for product popup -->
		<div class="popup" *ngIf="LoadingClient === true" style="z-index: 333">
			<div class="overlay"></div>
			<div class="product-popup popup__content">
				<div class="order-product__item">
					<div class="order-process__row order-process__row_success">
						<div class="row">
							<h2 class="order-process__title" style="text-align: center">טוען אנא המתן</h2>
						</div>
						<div class="row"></div>
						<div class="row">
							<div class="order-process__image image">
								<img *ngIf="_ss.isConnectionFastEnough()" src="/assets/images/basket/process/success.gif" alt="photo" />
							</div>
						</div>
						<div class="row"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- end markup for product popup -->
		<!-- start if didn't heve filters result -->
		<section class="shop-filter-empty" *ngIf="SearchErrorPop === true">
			<div class="container">
				<div class="shop-filter-empty__row row">
					<div class="shop-filter-empty__photo image">
						<img src="/assets/images/shop-return/no-filters-result.svg" alt="photo" />
					</div>
					<h2 class="shop-filter-empty__title">אופס, לא מצאנו את מה שחיפשתם</h2>
					<h2 class="shop-filter-empty__title">כדאי לבדוק איות או לנסות לחפש לפי פרמטר אחר</h2>
				</div>
			</div>
		</section>
		<!-- end if didn't heve filters result -->

		<!-- markup for sort popup -->
		<div class="popup" *ngIf="showSortPopup">
			<div class="overlay"></div>
			<div class="order-popup order-popup_sort popup__content">
				<div class="popup__close" (click)="showHideSortPopup(false)"></div>
				<div class="order-popup__header" style="flex-direction: row-reverse">
					<div class="order-popup__header-title">בחר</div>
				</div>
				<div class="order-popup__content">
					<div class="order-popup__row" style="margin-top: 25px">
						<!-- <div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px"> -->

						<!-- don't show arrow and don't react to click if no open teudot -->
						<div class="order-popup__row-first" (click)="isOpenTeudotMenuOpen = !this.totalOpenTeudot.count.all ? false : !isOpenTeudotMenuOpen">
							<!-- <div class="order-popup__row-first" (click)="OpenOrdersClients()"> -->
							<div class="order-popup__content-title">תעודות פתוחות ({{ this.totalOpenTeudot.count.all }})</div>
							<!-- <div class="order-popup__content-title" *ngIf="this.totalOpenTeudot.sum"> - {{this.totalOpenTeudot.sum}}</div> -->
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow" *ngIf="this.totalOpenTeudot.count.all" [ngClass]="{ open: isOpenTeudotMenuOpen }"></div>
						</div>
					</div>
					<div class="order-popup__row-drawer" [ngClass]="{ expanded: isOpenTeudotMenuOpen }">
						<div>
							<h3 (click)="OpenOrdersClients()">סה"כ: {{ this.totalOpenTeudot.sum.all | number : '1.0-2' }}</h3>
							<h3 (click)="OpenOrdersClients('orders')">הזמנות: {{ this.totalOpenTeudot.sum.orders | number : '1.0-2' }}</h3>
							<h3 (click)="OpenOrdersClients('returns')">החזרות: {{ this.totalOpenTeudot.sum.returns | number : '1.0-2' }}</h3>
						</div>
					</div>

					<div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px">
						<div class="order-popup__row-first" (click)="HovotClients()">
							<div class="order-popup__content-title">חובות פתוחים</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px">
						<div class="order-popup__row-first" (click)="ClearClientSearch()">
							<div class="order-popup__content-title">כל הלקוחות - {{ this.AllClientsSearch.length }}</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px" *ngIf="ShowSapakim === true">
						<div class="order-popup__row-first" (click)="Sapakim()">
							<div class="order-popup__content-title">ספקים</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px" *ngIf="ShowKnisa === true">
						<div class="order-popup__row-first" (click)="KnisaToday()">
							<div class="order-popup__content-title">בוצע ביקור כניסה או יציאה שנכשלה</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px" *ngIf="ShowExit === true">
						<div class="order-popup__row-first" (click)="ExitToday()">
							<div class="order-popup__content-title">בוצע דיווח חדש</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px">
						<div class="order-popup__row-first" (click)="SentToday()">
							<div class="order-popup__content-title">נשלחו היום</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" *ngIf="_ss.CustomerInformation.SortByLastDateTeuda === '1'" (click)="showHideSortPopup(false)" style="margin-top: 25px">
						<div class="order-popup__row-first" (click)="filterByLastDateTeuda(30)">
							<div class="order-popup__content-title">לא קנו 30 יום</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" *ngIf="_ss.CustomerInformation.SortByLastDateTeuda === '1'" (click)="showHideSortPopup(false)" style="margin-top: 25px">
						<div class="order-popup__row-first" (click)="filterByLastDateTeuda(60)">
							<div class="order-popup__content-title">לא קנו 60 יום</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div
						class="order-popup__row"
						(click)="showHideSortPopup(false)"
						style="margin-top: 25px"
						*ngIf="
							!hasMinDocTeuda &&
							((_b2b.isNotB2BAgent$ | async) || (!_ss.CustomerInformation.MinimumTotalOrderB2B && (_b2b.isB2BAgentNoPricesAndNoParameters$ | async) === false)) &&
							hasSendAllTeudot
						"
					>
						<div class="order-popup__row-first" (click)="SendOpenTeudotOne()">
							<div class="order-popup__content-title">שלח תעודות פתוחות</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div class="order-popup__row" (click)="showHideSortPopup(false)" style="margin-top: 25px" *ngIf="_receipts.hasReceipts">
						<div class="order-popup__row-first" (click)="shouldReceiptPopupShow = true">
							<div
								class="order-popup__content-title"
								matBadgeSize="small"
								[matBadgeHidden]="!_receipts.receiptsWaitingToBeSent.length"
								[matBadge]="_receipts.receiptsWaitingToBeSent.length"
								matBadgePosition="before"
								matBadgecolor="primary"
								matBadgeOverlap="false"
							>
								שלח קבלות
							</div>
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>

					<div
						class="order-popup__row"
						(click)="showHideSortPopup(false)"
						style="margin-top: 25px"
						*ngIf="
							!hasMinDocTeuda &&
							((_b2b.isNotB2BAgent$ | async) || (!_ss.CustomerInformation.MinimumTotalOrderB2B && (_b2b.isB2BAgentNoPricesAndNoParameters$ | async) === false)) &&
							hasSendAllTeudot
						"
					>
						<div class="order-popup__row-first" (click)="SendOpenTeudot()">
							<div class="order-popup__content-title">שלח תעודות פתוחות (לפי סוג תעודה)</div>
							<!-- <div class="order-popup__content-number"></div> -->
							<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end markup for sort popup -->

		<!-- markup for sort popup -->
		<div class="popup" *ngIf="showSortPopupSanen === true">
			<div class="overlay"></div>
			<div class="order-popup order-popup_sort popup__content">
				<div class="popup__close" (click)="CloseSanen()"></div>
				<div class="order-popup__header">
					<div class="order-popup__header-search">
						<form [formGroup]="form" class="city-search-input">
							<img src="/assets/images/header/clear-icon.svg" alt="search" class="city-search-clear-button" *ngIf="shouldClearCitySortButtonAppear" (click)="ClearClientSearch()" />
							<input class="header__search-input" type="text" placeholder="חפש עיר" formControlName="CitySearch" style="text-align: center" dir="rtl" />
						</form>
					</div>
					<div class="order-popup__header-title">בחר</div>
					<!-- <div class="order-popup__header-description">נקה הכל</div> -->
				</div>
				<div class="order-popup__content">
					<div class="order-popup__row" *ngFor="let city of CustCity" style="margin-top: 25px">
						<div class="order-popup__row-first" (click)="CityChecked(city)">
							<div class="city-check" id="{{ city }}"></div>
							<div class="order-popup__content-title">{{ city }}</div>
							<!-- <mat-checkbox color="primary">{{city}}</mat-checkbox> -->

							<!-- <div class="order-popup__content-number"></div> -->
							<!-- <div class="order-popup__content-arrow order-popup__content-arrow-hide"></div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end markup for sort popup -->
	</div>

	<!-- main menu markup -->
	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img src="{{ this._ss.CustomerInformation?.CustImageDomain }}/companylogo.jpg" crossorigin="anonymous" alt="mobility" onerror="this.onerror=null; this.src=MOBILITY_LOGO" />
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ this._ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ this._ss.AgentIdConnected }}</div>
				</div>
			</div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="Sapakim()" *ngIf="(_b2b.isNotB2BAgent$ | async) && ShowSapakim === true">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">ספקים</div>
				</div>
			</div>
			<div class="main-menu__border" *ngIf="(_b2b.isNotB2BAgent$ | async) && Admin !== 'simple' && !isCatalogAgent"></div>
			<div class="main-menu__row" *ngIf="(_b2b.isNotB2BAgent$ | async) && Admin !== 'simple' && !isCatalogAgent">
				<div class="main-menu__col" (click)="MoveToDohot()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-statistic.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דוחות</div>
				</div>
			</div>
			<div class="main-menu__border" *ngIf="!isCatalogAgent"></div>
			<div class="main-menu__row" *ngIf="!isCatalogAgent">
				<div class="main-menu__col" (click)="MoveToDailyReport({ source: 'menu' })">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-calendar.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דו"ח יומי</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="BackToHome()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דף הבית</div>
				</div>
			</div>

			<!-- <div class="main-menu__border" *ngIf="this._ss.CustomerInformation.CustChangeCss4 === '1'"></div> -->

			<!-- <div class="main-menu__row"
        *ngIf="WorkOnLineShow === true && this._ss.CustomerInformation.CustChangeCss4 === '1'">
        <div class="main-menu__col" (click)="AskOfflineWork()">
          <div class="main-menu__col-icon image">
            <img src="/assets/images/burger/icon-settings.svg" alt="icon">
          </div>
          <div class="main-menu__col-title"> כרגע במצב <span style="color: green;">ONLINE</span> לחץ לשינוי </div>
        </div>
      </div> -->

			<!-- <div class="main-menu__row"
        *ngIf="WorkOfflineShow === true && this._ss.CustomerInformation.CustChangeCss4 === '1'">
        <div class="main-menu__col" (click)="WorkOnLine()">
          <div class="main-menu__col-icon image">
            <img src="/assets/images/burger/icon-settings.svg" alt="icon">
          </div>
          <div class="main-menu__col-title">כרגע במצב <span style="color: red;">OFFLINE</span> לחץ לשינוי </div>
        </div>
      </div> -->

			<div class="main-menu__border"></div>
			<div class="main-menu__col">
				<div class="main-menu__col-title">{{ _ss.VersionNumber }}-{{ (_b2b.isB2BAgent$ | async) ? 'b2b-' : isCatalogAgent ? 'catalog-' : '' }}{{ _ss.CustomerInformation?.CustName }}</div>
			</div>
		</div>
	</div>
	<!-- end main menu markup -->
</div>

<!-- if didn't heve filters result -->
<!-- <section class="shop-filter-empty" *ngIf="emptyResult">
	<div class="container">
		<div class="shop-filter-empty__row row">
			<div class="shop-filter-empty__photo image">
				<img src="/assets/images/shop-return/no-filters-result.gif" alt="photo" />
			</div>
			<h2 class="shop-filter-empty__title">אופס, לא מצאנו את מה שחיפשתם</h2>
			<h2 class="shop-filter-empty__title">כדאי לבדוק איות או לנסות לחפש לפי פרמטר אחר</h2>
		</div>
	</div>
</section> -->
<!-- end if didn't heve filters result -->

<!-- markup for popup -->
<!-- <div class="popup"  *ngIf="shopRange">
	<div class="overlay"></div>
	<div class="shop-range__popup popup__content">
		<div class="popup__close" (click)="showHidePopup(false)"></div>
		<h2 class="shop-range__popup-title">חפש לקוחות קרובים</h2>
		<div class="shop-range__block">
			<div class="shop-range__box">
				<input class="shop-range__input" type="range" min="0" max="50" value="0" step="10" orient="vertical" />
				<ul class="shop-range__list">
					<li class="shop-range__list-item">0 ק"מ</li>
					<li class="shop-range__list-item shop-range__list-item_active">10 ק"מ</li>
					<li class="shop-range__list-item">20 ק"מ</li>
					<li class="shop-range__list-item">30 ק"מ</li>
					<li class="shop-range__list-item">40 ק"מ</li>
					<li class="shop-range__list-item">50 ק"מ</li>
				</ul>
			</div>
		</div>
		<div class="shop-range__block">
			<button class="shop-range__button" (click)="showHidePopup(false)">צא לדרך</button>
		</div>
	</div>
</div> -->
<!-- end markup for popup -->

<!-- button back to top -->
<!-- <div class="back-top" (click)="ScrollUp()" style="z-index: 300"></div> -->
<!-- end button back to top -->

<!-- start markup for product detail add popup -->
<div class="popup" *ngIf="shopFilterPop === true" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="CloseTeudaType()"></div>
				<h3 style="text-align: center">{{ this._ss.OneClientInfo.Client_Name }}</h3>
				<div
					class="shop-filter__row"
					style="position: relative"
					*ngFor="let dtpc of DocTypePerClient"
					(click)="
						MoveToProductsOrderType(dtpc.DocType, dtpc.DocName, dtpc.IsShrion, dtpc.NoObligoCheck, dtpc.CheckMinPrice, dtpc.MustCustRem1, dtpc.MustCustRem2, dtpc.MustCustRem3, dtpc.MustCustRem4)
					"
				>
					<!-- <button *ngIf="dtpc.DocType === 11" style="    position: absolute; right: -9%; top: 50%; translate: 0 -50%; scale: 0.7;" mat-raised-button color="warn">שכפל</button> -->
					<label class="shop-filter__radio custom-radio" style="font-weight: bold" id="doc{{ dtpc.DocType }}"
						>{{ dtpc.DocName }} <span id="numofitems{{ dtpc.DocType }}"></span
						><span *ngIf="OpenOrder[dtpc.DocType] && _ss.CustomerInformation.CustSeePrice !== '1' && (_ss.OneClientInfo.DSP !== 2 || TotalOrderOverride === '1')">
							- {{ OpenOrder[dtpc.DocType].total | number : '1.0-2' }}</span
						>
						<input type="radio" name="shop-filter" />
						<span class="custom-radio__checkmark"></span>
					</label>
				</div>
				<div class="shop-filter__row" style="position: relative" *ngIf="hasVisitsNoSale" (click)="createNoSaleReportAndNavigateToClientActiv()">
					<label class="shop-filter__radio custom-radio" style="font-weight: bold">
						אי מכירה
						<input type="radio" name="shop-filter" />
						<span class="custom-radio__checkmark"></span>
					</label>
				</div>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="CloseTeudaType()">חזור</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="popup" *ngIf="isDuplicateOrderPopupShowing" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="CancelSendOpenTeudotOne()"></div>
				<h3 style="text-align: center">הזמנה זו כבר שודרה, יש לבדוק מול המשרד</h3>
				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="closeDuplicateOrderPopupAndOpenDoctypeSelect()">הזמנה חדשה</button>
					<button class="product-detail-add__popup-button" (click)="CancelSendOpenTeudotOne()">אוקי</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end markup for product detail add popup -->

<!-- start markup for product detail add popup -->
<div class="popup" *ngIf="SentTodayPop === true" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<!-- <div class="popup__close" style="position: absolute; top: 0px; background-color: black"
					(click)="CancelSendOpenTeudotOne()"></div> -->
				<div class="product-detail-add__popup-row">
					<h2 class="product-detail-add__popup-title">נשלחו היום</h2>
				</div>

				<table style="text-align: center; width: 99%; margin: auto">
					<tr>
						<th>שם לקוח</th>
						<th>סכום</th>
					</tr>
					<tr *ngFor="let so of SentTodayVar" (click)="SentTodayOne(so.Order[0].TeudaNum)">
						<td>{{ so.Order[0].Client_Name }}</td>
						<td>{{ so.Order[0].TeudaTotalSum | number : '1.0-2' : 'en-US' }}</td>
					</tr>
				</table>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="CloseSentTodayPop()">חזור</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end markup for product detail add popup -->

<!-- start markup for product detail add popup -->
<div class="popup" *ngIf="SentTodayPopSecond === true" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="CancelSendOpenTeudotOne()"></div>
				<div class="product-detail-add__popup-row">
					<h2 class="product-detail-add__popup-title">נשלחו היום</h2>
				</div>

				<table style="text-align: center; width: 99%; margin: auto">
					<tr>
						<th>שם מוצר</th>
						<th>אר</th>
						<th>יח</th>
						<th>מחיר</th>
						<th>הנחה</th>
						<th>סה"כ</th>
					</tr>
					<tr *ngFor="let so of SecondOrderSentTodayTwo">
						<td>{{ so.Product_Name }}</td>
						<td>{{ so.TeudaPackQuantity }}</td>
						<td>{{ so.TeudaQuantity }}</td>
						<td>{{ so.Price | number : '1.0-2' : 'en-US' }}</td>
						<td>{{ so.TeudaItemDiscount | number : '1.0-2' : 'en-US' }}</td>
						<td>{{ so.TeudaTotalRow | number : '1.0-2' : 'en-US' }}</td>
					</tr>
				</table>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="CloseSentTodayPopSecondPop()">חזור</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end markup for product detail add popup -->

<!-- start markup for product detail add popup -->
<div class="popup" *ngIf="SendAllTeudotPop === true" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<!-- <div class="popup__close" style="position: absolute; top: 0px; background-color: black"
					(click)="CancelSendOpenTeudotOne()"></div> -->
				<div class="product-detail-add__popup-row" *ngIf="ButtonsShowSentAllTeudot === true && AllOpenTeudaArray.length !== 0">
					<h2 class="product-detail-add__popup-title">בחר סוג תעודה לשליחה</h2>
				</div>

				<div class="product-detail-add__popup-row" *ngIf="AllOpenTeudaArray.length === 0 && Finish === false">
					<h2 class="product-detail-add__popup-title">אין תעודות פתוחות</h2>
				</div>

				<div class="product-detail-add__popup-row" *ngIf="ButtonsShowSentAllTeudot === false && Finish === false">
					<h2 class="product-detail-add__popup-title">שולח תעודות אנא המתן...</h2>
				</div>

				<div class="product-detail-add__popup-row" *ngIf="Finish === true && this.SentArray.length !== 0">
					<h2 class="product-detail-add__popup-title">השליחה הסתיימה</h2>
				</div>

				<div class="product-detail-add__popup-row" *ngIf="this.SentArray.length === 0 && Finish === true">
					<h2 class="product-detail-add__popup-title">אין תעודות פתוחות</h2>
				</div>

				<table *ngIf="ButtonsShowSentAllTeudot === false && this.SentArray.length !== 0" style="text-align: center">
					<tr>
						<th>לקוח</th>
						<th>סוג תעודה</th>
						<th>סטטוס</th>
					</tr>
					<tr *ngFor="let sa of SentArray">
						<td>{{ sa.Client_Name }}</td>
						<td>{{ sa.Type }}</td>
						<td id="{{ sa.id }}">{{ sa.Status }}</td>
					</tr>
				</table>

				<div class="product-detail-add__popup-row" *ngIf="Finish === true">
					<button class="product-detail-add__popup-button" (click)="CancelSendOpenTeudot()" style="background-color: #38d88b">קיבלתי</button>
					<div class="auth__help-image image" *ngIf="this.SentArray.length !== 0">
						<img src="/assets/images/auth/plane-icon.gif" alt="photo" />
					</div>
				</div>

				<div class="product-detail-add__popup-row" *ngIf="ButtonsShowSentAllTeudot === true">
					<button class="product-detail-add__popup-button" (click)="CancelSendOpenTeudot()">חזור</button>
				</div>
				<div class="product-detail-add__popup-row" *ngIf="ButtonsShowSentAllTeudot === true">
					<button class="product-detail-add__popup-button" (click)="SendOpenTeudotConfirm(0)" style="background-color: var(--valid-color); box-shadow: 1px 4px 14px var(--valid-color-rgba)">
						שלח הכל
					</button>
				</div>
				<!-- <div class="product-detail-add__popup-row" *ngIf="ButtonsShowSentAllTeudot === true && HasMultipleCompanys > 1">
					<button class="product-detail-add__popup-button" (click)="SendOpenTeudotConfirm(0,true)"
						style="background-color: var(--valid-color); box-shadow: 1px 4px 14px var(--valid-color-rgba)">
						שלח הכל (כל החברות)
					</button>
				</div> -->
				<div class="product-detail-add__popup-row" *ngIf="ButtonsShowSentAllTeudot === true">
					<button class="product-detail-add__popup-button" (click)="resolveSendOpenTeudotConfirm('order')" style="background-color: rgb(142, 141, 141); box-shadow: unset">הזמנות</button>
					<button class="product-detail-add__popup-button" (click)="resolveSendOpenTeudotConfirm('return')" style="background-color: rgb(142, 141, 141); box-shadow: unset">החזרות</button>
					<button class="product-detail-add__popup-button" (click)="resolveSendOpenTeudotConfirm('virtual')" style="background-color: rgb(142, 141, 141); box-shadow: unset">הצעות מחיר</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end markup for product detail add popup -->

<!-- start markup for product detail add popup -->
<div class="popup" *ngIf="SendOpenTeudotOnePop === true" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<!-- <div class="popup__close" style="position: absolute; top: 0px; background-color: black"
					(click)="CancelSendOpenTeudotOne()"></div> -->

				<div class="product-detail-add__popup-row" *ngIf="ButtonsShowSentAllTeudotOne === true">
					<h2 class="product-detail-add__popup-title">שולח תעודה ללקוח {{ this._ss.OneClientInfo.Client_Name }}...</h2>
					<h2 class="product-detail-add__popup-title">אנא המתן לאישור...</h2>
				</div>

				<div class="product-detail-add__popup-row" *ngIf="AllOpenTeudaArray.length === 0">
					<h2 class="product-detail-add__popup-title">אין תעודות פתוחות</h2>
				</div>

				<table *ngIf="ButtonsShowSentAllTeudotOne === false && AllOpenTeudaArray.length !== 0">
					<tr>
						<th>לקוח</th>
						<th>סוג תעודה</th>
						<th>שלח</th>
					</tr>
					<tr *ngFor="let sa of AllOpenTeudaArray">
						<td style="padding-bottom: 10px; padding-top: 10px">{{ sa.Client_Name }}</td>
						<td style="padding-bottom: 10px; padding-top: 10px">{{ sa.Type }}</td>
						<td style="padding-bottom: 10px; padding-top: 10px"><u (click)="SendOpenTeudotOneConfirm(sa.ClientId, sa.TypeNum, sa.company, sa.Type)">שלח</u></td>
					</tr>
				</table>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="CancelSendOpenTeudotOne()">חזור</button>
				</div>
				<div class="product-detail-add__popup-row"></div>
			</div>
		</div>
	</div>
</div>
<!-- end markup for product detail add popup -->

<!-- start markup for product detail add popup -->
<div class="popup" *ngIf="SendOpenTeudotOnePopConfirm === true" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="popup__close" (click)="CancelSendOpenTeudotOnePopConfirm()"></div>

		<h2 class="product-detail-add__popup-title">תעודה נשלחה בהצלחה</h2>

		<h3 style="text-align: center">{{ this._ss.OneClientInfo.Client_Name }}</h3>
		<h4 style="text-align: center">מספר אישור:{{ this.NewCart }}</h4>

		<div class="auth__help-image image">
			<img src="/assets/images/auth/plane-icon.gif" alt="photo" />
		</div>
		<div class="product-detail-add__popup-row">
			<button class="product-detail-add__popup-button" (click)="CancelSendOpenTeudotOnePopConfirm()" style="background-color: #38d88b">קיבלתי</button>
		</div>
		<div class="product-detail-add__popup-row"></div>
	</div>
</div>
<!-- end markup for product detail add popup -->

<div class="popup" *ngIf="ConfirmOffline === true" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="popup__close" (click)="CancelSendOpenTeudotOnePopConfirm()"></div>

		<h2 class="product-detail-add__popup-title">מעבר OFFLINE ?</h2>

		<h3 style="text-align: center">מעבר לאופליין נועד למקום שאין בו קליטה</h3>
		<h4 style="text-align: center">ניתן לחזור למצב אונליין בלחיצה פשוטה על אותו הכפתור</h4>

		<div class="auth__help-image image">
			<img src="/assets/images/burger/icon-settings.svg" alt="photo" />
		</div>
		<div class="product-detail-add__popup-row">
			<button class="product-detail-add__popup-button" (click)="WorkOffline()" style="background-color: #38d88b">אישור</button>

			<button class="product-detail-add__popup-button" (click)="CancelPopWorkOffline()" style="background-color: red">ביטול</button>
		</div>
		<div class="product-detail-add__popup-row"></div>
	</div>
</div>

<!-- ========================= -->
<div class="popup" *ngIf="MaslulPickPop === true" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="popup__close" (click)="MaslulPickPop = false"></div>

		<h2 class="product-detail-add__popup-title">בחר תאריך</h2>
		<h4 style="text-align: center">
			<form [formGroup]="form">
				<input type="date" formControlName="MaslulDate" />
			</form>
		</h4>
		<div class="product-detail-add__popup-row">
			<button class="product-detail-add__popup-button" (click)="maslol()" style="background-color: var(--valid-color)">המשך</button>
		</div>
		<div class="product-detail-add__popup-row"></div>
	</div>
</div>

<div class="popup" *ngIf="shouldReceiptPopupShow" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="shouldReceiptPopupShow = false"></div>

				<div class="product-detail-add__popup-row" *ngIf="!_receipts.receiptsWaitingToBeSent.length">
					<h2 class="product-detail-add__popup-title" dir="rtl">
						{{
							allreceiptsSent
								? 'כל הקבלות נשלחו!'
								: 'אין
						קבלות לשלוח '
						}}
					</h2>
				</div>

				<table *ngIf="_receipts.receiptsWaitingToBeSent.length">
					<tr>
						<th>לקוח</th>
						<th>סכום קבלה</th>
						<th>סטטוס</th>
					</tr>
					<tr *ngFor="let sa of _receipts.receiptsWaitingToBeSent; index as i">
						<td style="padding-bottom: 10px; padding-top: 10px">{{ sa.Client_Name }}</td>
						<td style="padding-bottom: 10px; padding-top: 10px; text-align: center">{{ sa.DocTotal | number : '1.0-2' }}</td>
						<td style="padding-bottom: 10px; padding-top: 10px; color: var(--valid-color)" dir="rtl" *ngIf="sendingReceipts && !i">שולח</td>
						<td style="padding-bottom: 10px; padding-top: 10px; color: var(--invalid-color)" dir="rtl" *ngIf="sa.failed">נכשלה</td>
					</tr>
				</table>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button valid-background" *ngIf="_receipts.receiptsWaitingToBeSent.length" (click)="sendReceipts()">שלח הכל</button>
					<button class="product-detail-add__popup-button" (click)="shouldReceiptPopupShow = false; sendingReceipts = false; allreceiptsSent = false">חזור</button>
				</div>
				<div class="product-detail-add__popup-row"></div>
			</div>
		</div>
	</div>
</div>
<!-- ========================= -->

<app-menu-button [shouldChangeToPositionInitial]="showSortPopupSanen || showSortPopup || LoadingClient"></app-menu-button>
