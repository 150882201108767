<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">הקצאות לסוכנים</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<section class="report-table" style="margin-top: 70px">
			<div class="content">
				<div class="report-table__row report-table__row_between"></div>

				<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
					<table class="report-table__table" cellspacing="0" *ngIf="!loading">
						<thead class="report-table__table-head">
							<tr>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('agent')" style="width: 100px">קוד סוכן</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('agentname')" style="width: 150px">שם סוכן</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('productid')" style="width: 150px">קוד פריט</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('productname')" style="width: 150px">שם פריט</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('fromdate')" style="width: 150px">מתאריך</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('todate')" style="width: 150px">עד תאריך</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('muktze')" style="width: 150px">מוקצה</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('nutzal')" style="width: 150px">נוצל</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('yetra')" style="width: 150px">יתרה</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('status')" style="width: 150px">סטטוס</th>
							</tr>
						</thead>
						<tbody class="report-table__table-body">
							<tr *ngFor="let a of allocations">
								<td class="report-table__table-body-name" style="width: 150px">{{ a.agent }}</td>
								<td class="report-table__table-body-name" style="width: 150px">{{ a.agentname }}</td>
								<td class="report-table__table-body-name" style="width: 150px">{{ a.productid }}</td>
								<td class="report-table__table-body-name" style="width: 150px">{{ a.productname }}</td>
								<td class="report-table__table-body-name" style="width: 150px">
									{{ a.fromdate | date : 'dd/MM/yy' : 'GMT' }}
								</td>
								<td class="report-table__table-body-name" style="width: 150px">
									{{ a.todate | date : 'dd/MM/yy' : 'GMT' }}
								</td>
								<td class="report-table__table-body-name" style="width: 150px">
									{{ a.muktze }}
								</td>
								<td class="report-table__table-body-name" style="width: 150px">
									{{ a.nutzal }}
								</td>
								<td class="report-table__table-body-name" style="width: 150px">
									{{ a.yetra }}
								</td>
								<td class="report-table__table-body-name" style="width: 150px">
									{{ a.status }}
								</td>
							</tr>
						</tbody>
					</table>
					<h2 *ngIf="loading">טוען...</h2>
				</div>
			</div>
		</section>

		<!-- markup for popup -->
		<!-- <div class="popup" *ngIf="PopDuplicate === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="NoDuplicate()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">לשכפל את התעודה ?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoDuplicate()">לא, חזור</button>
					<button class="order-cart__popup-button" (click)="DuplicateOrder()">כן, שכפל</button>
				</div>
			</div>
		</div> -->
		<!-- end markup for popup -->

		<!-- markup for popup -->
		<!-- <div class="popup" *ngIf="ConfirmPop === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="Exit()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">תעודה שוכפלה בהצלחה</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="Exit()">חזור</button>

					<button class="order-cart__popup-button" (click)="WatchOrderDuplicate()">צפה בתעודה</button>
				</div>
			</div>
		</div> -->
		<!-- end markup for popup -->
	</div>
</div>
