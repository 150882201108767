<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="MoveToDohotMotzarim()">
				<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
			</div>
			<div class="header__title col">דו"ח מכירות מוצר - לקוחות</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="First === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchProducts()">
				<input class="header__search-input" type="text" placeholder=" חפש מוצר" formControlName="ProductSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="ClearProductsSearch()"></button>
		</div>
	</div>

	<div class="report-table__row" *ngIf="Second === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" *ngIf="First === true" style="margin-top: 100px">
	<div class="content">
		<div class="report-table__row report-table__row_between"></div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('Product_Name', 'AllProducts')" style="width: 220px">שם מוצר</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('ProductId', 'AllProducts')" style="width: 150px">קוד מוצר</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortNumber('sumqty', 'AllProducts')" style="width: 120px" *ngIf="hasTDashKabalaQueries">כמות</th>
						<th class="report-table__table-head-name sortable-clickable" *ngIf="hasTDashKabalaQueries" (click)="sortNumber('sumtotalrows', 'AllProducts')" style="width: 150px">סה"כ</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ap of AllProducts; trackBy: trackByFnProduct" (click)="GetProductNums(ap.ProductId, ap.Product_Name)">
						<td class="report-table__table-body-name">{{ ap.Product_Name }}</td>
						<td class="report-table__table-body-name">{{ ap.ProductId }}</td>
						<td class="report-table__table-body-name" *ngIf="hasTDashKabalaQueries" dir="ltr">{{ ap.sumqty }}</td>
						<td class="report-table__table-body-name" *ngIf="hasTDashKabalaQueries" dir="ltr">{{ ap.sumtotalrows | number : '1.0-2' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table" style="margin-top: 100px" *ngIf="Second === true">
	<div class="content">
		<!-- <div class="flex-row" *ngIf="AllClients.length">
      <div class="box">
        <b>סה"כ</b>
        <span>
          {{(allClientsTotal$  | async ) | number:'1.0-2'}}
        </span>
      </div>
      <div class="box">
        <b>ממוצע</b>
        <span>
          {{allClientsAverage$ | async | number:'1.0-2'}}
        </span>
      </div>
    </div> -->
		<div class="report-table__row report-table__row_between" style="justify-content: center">
			<h2 style="text-align: center; font-size: x-large">{{ this.ChoosenProduct }}</h2>
		</div>
		<h3 *ngIf="!AllClients.length" style="font-size: large; text-align: center">{{ loading ? 'טוען...' : 'אין נתונים להציג' }}</h3>
		<div class="report-table__row report-table__row_full-width" *ngIf="AllClients.length">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('Client_Name', 'AllClients')" style="width: 250px">שם לקוח</th>
						<th class="report-table__table-head-name" *ngIf="hasUpdatedChart" style="width: 150px">עלות מצטברת</th>
						<th class="report-table__table-head-name" *ngIf="hasUpdatedChart" style="width: 150px">סה"כ כמויות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="SortNumOfTimes()" style="width: 150px">מס פעמים שנקנה</th>

						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('ClientId', 'AllClients')" style="width: 150px">קוד לקוח</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ac of AllClients$ | async; trackBy: trackByFnClient">
						<td class="report-table__table-body-name" style="width: 150px">{{ ac.Client_Name }}</td>
						<td class="report-table__table-body-name" *ngIf="hasUpdatedChart" style="width: 150px; direction: ltr">
							{{ ac.sumtotalrows | number : '1.0-2' }}
						</td>
						<td class="report-table__table-body-name" *ngIf="hasUpdatedChart" style="width: 150px; direction: ltr">
							{{ ac.sumqty | number }}
						</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ac.NumOfTimes }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ ac.ClientId }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
