import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import { BrandResponse } from 'src/app/Interfaces/brand-response';
import { ReturnMonthHebrewPipe } from 'src/app/Pipes/return-month-hebrew.pipe';
import { StoreService } from 'src/app/services/store.service';
import { ExcelObject } from 'src/app/services/util.service';
import { utils, writeFile } from 'xlsx';

@Component({
	selector: 'app-group-sales-all',
	templateUrl: './group-sales-all.component.html',
	styleUrls: ['./group-sales-all.component.scss'],
})
export class GroupSalesAllComponent implements OnInit, OnDestroy {
	constructor(private _ss: StoreService, private router: Router, private _fb: FormBuilder) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	form = this._fb.group({
		search: '',
		qtyOrMoney: <'qty' | 'money'>'money',
	});

	public AllProducts;
	public AllProductsSearch;
	groupsQuantities: any[] = [];
	groupsQuantities$ = new BehaviorSubject<any[]>(this.groupsQuantities);

	groupYearly: any[] = [];
	groupYearly$ = new BehaviorSubject<any[]>(this.groupYearly);

	productsOfGroup: any[] = [];
	productsOfGroup$ = new BehaviorSubject<any[]>(this.productsOfGroup);

	isAdmin = location.href.includes('admin');

	public BizoimCompany;

	public toogleInputNew = false;
	public ShowCompanys = null;
	public HasMultipleCompanys;
	reportSearch = null;

	loading = true;

	destroy$ = new Subject<void>();

	thisYear = new Date().getFullYear();
	chosenMonth = null;
	chosenYear = null;

	groupIDChosen = null;

	First = true;
	Second = false;
	Third = false;

	windowWidth = innerWidth;

	differentLayout = this.windowWidth < 520;

	currentPage$ = new BehaviorSubject<'First' | 'Second' | 'Third'>('First');
	excelFilename$: Observable<string>;

	ngOnInit(): void {
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		if (!localStorage.getItem('UserName') || !localStorage.getItem('AgentId')) {
			this.router.navigate(['login']);
			return;
		}

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		if (this.isAdmin) {
			this._ss
				.handleGetAllGroupSalesAdmin()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						this.handleGetInitialInfo(res);
					},
					error: err => {
						console.log(err);
						alert('ישנה תקלת תקשורת');
						history.back();
					},
				});
		} else {
			this._ss
				.handleGetAllGroupSalesPerOneAgent()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						this.handleGetInitialInfo(res);
					},
					error: err => {
						console.log(err);
						alert('ישנה תקלת תקשורת');
						history.back();
					},
				});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleGetSecondPage(groupID) {
		this.loading = true;
		this.form.controls.search.setValue('');
		this.groupIDChosen = groupID;
		const method = this.isAdmin ? 'handleGetYearlyGroupSalesAdmin' : 'handleGetYearlyGroupSalesPerOneAgent';
		this._ss[method](groupID).subscribe({
			next: (res: any) => {
				this.handlePopulatingSecondPage(res);
			},
			error: err => {
				console.log(err);
				alert('ישנה תקלת תקשורת');
				history.back();
			},
		});
	}

	handlePopulatingSecondPage(res) {
		if (res.recordset && res.recordset.length) {
			this.groupYearly = Object.values(
				res.recordset.reduce((prev, curr) => {
					const year = curr.yy == new Date().getFullYear();
					if (!prev[curr.mm - 1]) {
						if (year) {
							prev[curr.mm - 1] = {
								ItmsGrpCod: curr.ItmsGrpCod,
								ItmsGrpNam: curr.ItmsGrpNam,
								yy: curr.yy,
								yy1TotalQty: curr.TotalQty,
								yy2TotalQty: 0,
								yy1TotalMoney: curr.TotalMoney,
								yy2TotalMoney: 0,
							};
						} else {
							prev[curr.mm - 1] = {
								ItmsGrpCod: curr.ItmsGrpCod,
								ItmsGrpNam: curr.ItmsGrpNam,
								yy: curr.yy,
								yy1TotalQty: 0,
								yy2TotalQty: curr.TotalQty,
								yy1TotalMoney: 0,
								yy2TotalMoney: curr.TotalMoney,
							};
						}
					} else {
						if (year) {
							prev[curr.mm - 1].yy1TotalQty += curr.TotalQty;
							prev[curr.mm - 1].yy1TotalMoney += curr.TotalMoney;
						} else {
							prev[curr.mm - 1].yy2TotalQty += curr.TotalQty;
							prev[curr.mm - 1].yy2TotaMoney += curr.TotaMoney;
						}
					}
					return prev;
				}, new Array(12)),
			);

			this.Second = true;
			this.First = this.loading = false;

			this.currentPage$.next('Second');

			this.handleFilter(this.form.controls.qtyOrMoney.value);
			return;
		}

		this.groupIDChosen = null;
		this.loading = false;
		alert('לא נמצאו נתונים לתצוגה');
	}

	handleGetThirdPage(month, year) {
		this.form.controls.search.setValue('');
		const method = this.isAdmin ? 'handleGetAllGroupSalesWithProductsAdmin' : 'handleGetAllGroupSalesWithProducts';

		this.loading = true;

		this._ss[method](this.groupIDChosen, month, year).subscribe({
			next: (res: any) => {
				if (res.recordset && res.recordset.length) {
					this.chosenMonth = month - 1 + '';
					this.chosenYear = year;
					this.productsOfGroup = res.recordset;
					this.productsOfGroup$.next(this.productsOfGroup);
					this.Third = true;
					this.Second = this.loading = false;
					this.currentPage$.next('Third');
					return;
				}

				alert('לא נמצאו נתונים לתצוגה');
				this.loading = false;
			},
			error: err => {
				console.log(err);
				alert('ישנה תקלת תקשורת');
				history.back();
			},
		});
	}

	handleGetInitialInfo(res) {
		this.loading = false;

		if (!res.recordset || !res.recordset.length) {
			alert('לא נמצאו נתונים לתצוגה');
			return history.back();
		}

		this.groupsQuantities = Object.values(
			res.recordset.reduce((prev, curr) => {
				const year = curr.yy == new Date().getFullYear();
				if (!prev[curr.ItmsGrpCod]) {
					if (year) {
						prev[curr.ItmsGrpCod] = {
							ItmsGrpCod: curr.ItmsGrpCod,
							ItmsGrpNam: curr.ItmsGrpNam,
							yy1TotalQty: curr.TotalQty,
							yy2TotalQty: 0,
							yy1TotalMoney: curr.TotalMoney,
							yy2TotalMoney: 0,
						};
					} else {
						prev[curr.ItmsGrpCod] = {
							ItmsGrpCod: curr.ItmsGrpCod,
							ItmsGrpNam: curr.ItmsGrpNam,
							yy1TotalQty: 0,
							yy2TotalQty: curr.TotalQty,
							yy1TotalMoney: 0,
							yy2TotalMoney: curr.TotalMoney,
						};
					}
				} else {
					if (year) {
						prev[curr.ItmsGrpCod].yy1TotalQty += curr.TotalQty;
						prev[curr.ItmsGrpCod].yy1TotalMoney += curr.TotalMoney;
					} else {
						prev[curr.ItmsGrpCod].yy2TotalQty += curr.TotalQty;
						prev[curr.ItmsGrpCod].yy2TotalMoney += curr.TotalMoney;
					}
				}
				return prev;
			}, {}),
		);

		this.handleFilter('money');
		this.setUpFormListeners();
	}

	search() {
		if (this.First) {
			this.handleActualSearch('groupsQuantities', 'ItmsGrpNam');
		}

		if (this.Third) {
			this.handleActualSearch('productsOfGroup', 'ProductName', 'ProductID');
		}
	}

	handleActualSearch(arr: 'productsOfGroup' | 'groupsQuantities', val: string, val2?: string) {
		const inputValue = this.form.controls.search.value;

		if (inputValue) {
			const splitValue = inputValue.split(' ');
			const arrayToSearch = this[arr];
			const filteredArray = [];
			for (let i = 0; i < arrayToSearch.length; i++) {
				let counter = 0,
					otherVal = '';

				const prodWithoutSpaces = (arrayToSearch[i][val] || '').replace(/\s/g, '').toLowerCase();

				if (val2) {
					otherVal = (arrayToSearch[i][val2] || '').replace(/\s/g, '').toLowerCase();
				}

				// V8 optimizes this if statement
				if (typeof otherVal === 'string' && typeof prodWithoutSpaces === 'string') {
					for (let j = 0; j < splitValue.length; j++) {
						if ((prodWithoutSpaces && prodWithoutSpaces.indexOf(splitValue[j]) > -1) || (otherVal && otherVal.indexOf(splitValue[j]) > -1)) {
							counter++;
						}
					}
				}

				if (counter === splitValue.length) {
					filteredArray.push(arrayToSearch[i]);
				}
			}

			this[arr + '$'].next(filteredArray);
			this.handleFilter(this.form.controls.qtyOrMoney.value, true);
			return;
		}
		this[arr + '$'].next(this[arr]);
		this.handleFilter(this.form.controls.qtyOrMoney.value);
	}

	searchClear() {
		this.form.controls.search.setValue('');

		this.handleFilter(this.form.controls.qtyOrMoney.value);
	}

	setUpFormListeners() {
		this.form.controls.qtyOrMoney.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.handleFilter(val);
		});
	}

	handleFilter(val: typeof this.form.controls.qtyOrMoney.value, considerSearch = false) {
		const property = val === 'money' ? 'Money' : 'Qty';

		let firstArray = this.groupsQuantities;

		if (considerSearch) {
			firstArray = this.groupsQuantities$.getValue();
		}

		this.groupsQuantities$.next(
			firstArray.map(grpQtys => ({
				ItmsGrpCod: grpQtys.ItmsGrpCod,
				ItmsGrpNam: grpQtys.ItmsGrpNam,
				yy1: grpQtys['yy1Total' + property],
				yy2: grpQtys['yy2Total' + property],
			})),
		);

		if (this.Second) {
			this.groupYearly$.next(
				this.groupYearly.map(grpQtys => ({
					ItmsGrpCod: grpQtys.ItmsGrpCod,
					ItmsGrpNam: grpQtys.ItmsGrpNam,
					yy1: grpQtys['yy1Total' + property],
					yy2: grpQtys['yy2Total' + property],
				})),
			);
		}
	}

	handleBack() {
		if (this.Second === true) {
			this.First = true;
			this.Second = false;
			// this.refreshSubjects('bizoim')

			this.currentPage$.next('First');
			return;
		}

		if (this.Third === true) {
			this.Second = true;
			this.Third = false;
			this.chosenMonth = null;
			this.form.controls.search.setValue('');
			this.currentPage$.next('Second');
			return;
		}

		if (this.First === true) {
			history.back();
		}
	}

	setupPipes() {
		this.excelFilename$ = this.currentPage$.pipe(
			map(page => {
				let title = 'ביצועים מחלקות';
				switch (page) {
					case 'Second':
						title += ' - ' + this.groupYearly[0].ItmsGrpNam;
						break;

					case 'Third':
						return title + ' - ' + this.groupYearly[0].ItmsGrpNam + ' - ' + this.chosenYear + ' - ' + new ReturnMonthHebrewPipe().transform(+this.chosenMonth);

					default:
						break;
				}
				return title;
			}),
		);
	}
}
