import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { decompress } from 'lz-string';
import { BaseComponent } from '../base-component/base.component';
import { environment } from 'src/environments/environment';
import { AgentGviaService } from 'src/app/services/agent-gvia.service';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { VisitsService } from 'src/app/services/visits.service';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { icons } from 'src/app/Interfaces/assets';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
})
export class AdminComponent extends BaseComponent implements OnInit {
	constructor(public router: Router, public _fb: FormBuilder, private _agentGvia: AgentGviaService, public _receipts: ReceiptsService, private _visits: VisitsService) {
		super();
	}

	public HasMultipleCompanys;
	public HasMultiplleCompanysPop;
	public online;
	public ShowCompanys;

	logo = MOBILITY_LOGO;

	windowWidth = innerWidth;

	hasAgentSalesProductsQuantitiesDates = false;

	hasAgentSalesOrdersQuantitiesDates = false;

	hasBrandsTablesAndQuery = false;

	hasInvoiceQuery = false;

	hasUndeliveredEndpoint = false;

	hasAllocations = false;

	hasAgentGvia = this._agentGvia.hasAgentGvia;

	hasAlerts = false;

	hasConcentratedSales = false;

	hasYaadGvia = false;

	hasSpecials = false;

	hasBikurimPlannedVsUnplanned = this._ss.CustomerInformation.VisitsPlannedVsUnplanned === '1';

	hasMustIncludeProducts = this._ss.CustomerInformation.MustIncludeProducts === '1';

	alternativeAdminLayoutItems: { path: string; text: string; icon: keyof typeof icons }[] = [
		{ path: 'admin-reports-company', text: 'דוחות חברה' },
		{ path: 'mobilitydashbord', text: 'ניהול הזמנות' },
		{ path: 'dohot-lekohot-hovot-admin', text: 'ריכוז חובות' },
		{ path: 'admin-manage-company', text: 'ניהול חברה' },
	].map(item => ({ ...item, icon: 'performence' }));

	endpointArray = [
		'/mobil/reports/get-agent-sales',
		'/mobil/reports/get-agent-orders',
		'/mobil/reports/hasBrands',
		'/mobil/reports/daily-invoices',
		'/mobil/reports/get-allocations/admin',
		'/mobil/user/adminhistory-undelivered/1',
		'/mobil/alerts',
		'/mobil/reports/get-concentrated-sales-per-agents',
		'/mobil/reports/get-yaad-gvia/admin',
	];

	async ngOnInit() {
		window.scrollTo(0, 0);

		if (!this.hasAgentGvia) {
			this.endpointArray.push('/mobil/debt/agent-gvia');
		} else {
			this._agentGvia.AgentsSalesAll = [];
			this._agentGvia.AgentsSalesAllResponse = [];
			this._agentGvia.AgentsSalesAllSecond = [];
			this._agentGvia.AgentsSalesAllSecondObject = {};
		}

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (!this._ss.CustomerInformation) {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
			return;
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this._receipts.refreshReceiptsParameters();
		this._visits.resetAll();

		// if (environment.development) {
		// 	this.hasYaadGvia = true;
		// 	return;
		// }

		const fetchArray = this.endpointArray.map(endpoint => fetch(`${this._ss.CustomerInformation.CustServer}${endpoint}?checkIfExistsInIndex=true`));

		// if agentGvia false, doesn't reach last part of array
		Promise.allSettled(fetchArray).then(([getAgentSales, getAgentSalesOrders, hasBrands, dailyInvoices, allocations, undelivered, alerts, concentrated, yaadGvia, agentGvia]) => {
			if (getAgentSales.status === 'fulfilled' && getAgentSales.value.status === 400) this.hasAgentSalesProductsQuantitiesDates = true;
			if (getAgentSalesOrders.status === 'fulfilled' && getAgentSalesOrders.value.status === 400) this.hasAgentSalesOrdersQuantitiesDates = true;
			if (hasBrands.status === 'fulfilled' && hasBrands.value.ok) this.hasBrandsTablesAndQuery = true;
			if (dailyInvoices.status === 'fulfilled' && dailyInvoices.value.status === 400) this.hasInvoiceQuery = true;
			if (undelivered.status === 'fulfilled' && undelivered.value.ok) this.hasUndeliveredEndpoint = true;
			if (concentrated.status === 'fulfilled' && concentrated.value.ok) this.hasConcentratedSales = true;

			if (allocations.status === 'fulfilled' && allocations.value.ok) {
				allocations.value.json().then(bod => {
					if (bod.recordset?.[0]?.res === 1) {
						this.hasAllocations = true;
					}
				});
			}

			if (agentGvia && agentGvia.status === 'fulfilled' && agentGvia.value.ok) {
				agentGvia.value.json().then(bod => {
					if (bod.recordset?.[0]?.res === 1) {
						this.hasAgentGvia = this._agentGvia.hasAgentGvia = true;
					}
				});
			}
			if (!environment.production) {
				this.hasAgentGvia = this._agentGvia.hasAgentGvia = true;
			}

			if (alerts.status === 'fulfilled' && alerts.value.ok) {
				alerts.value.json().then(bod => {
					if (bod.recordset?.[0]?.res === 1) {
						this.hasAlerts = true;
					}
				});
			}

			if (yaadGvia.status === 'fulfilled' && yaadGvia.value.ok) {
				yaadGvia.value.json().then(bod => {
					if (bod.recordset?.[0]?.res === 1) {
						this.hasYaadGvia = true;
					}
				});
			}
		});

		this.hasSpecials = ['1', '2', '3'].includes(this._ss.CustomerInformation.HasSpecials);

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		// if (this._ss.CustomerInformation)

		if (this.HasMultipleCompanys === '2' || this._ss.CustomerInformation.HasMultipleCompanies > 1) {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}
	}

	public MoveToClients() {
		this.router.navigate(['clients']);
	}

	public BackToHome() {
		this.router.navigate(['home']);
	}

	public BackToDohot() {
		history.back();
	}

	public MoveToDashbord() {
		this.router.navigate(['mobilitydashbord']);
	}

	public MoveToadminbizoahevra() {
		this.router.navigate(['admin-bizoa-hevra']);
	}

	public MoveToadminbizoaazmanot() {
		this.router.navigate(['admin-bizoa-azmanot']);
	}

	public MoveToadmindohotproducts() {
		this.router.navigate(['admin-dohot-products']);
	}

	public CompanyChoosen(company) {
		this._ss.AdminCompanyChosen = company;

		setTimeout(() => {
			this.router.navigate([this.waitingToMoveTo], { queryParams: { company } });
		}, 0);
	}

	public NoChoose() {
		this.HasMultiplleCompanysPop = false;
	}

	navigateAdminGoals(to: 'agents' | 'clients' | 'groups') {
		this.router.navigate(['admin-goals'], { queryParams: { type: to } });
	}

	waitingToMoveTo = '';

	checkIfHasMultipleCompanys(navPath: string) {
		this.online = window.navigator.onLine;

		if ((this.HasMultipleCompanys === '2' || this._ss.CustomerInformation.HasMultipleCompanies > 1) && this.online === true) {
			this.HasMultiplleCompanysPop = true;
			this.waitingToMoveTo = navPath;

			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		} else {
			this.router.navigate([navPath]);
		}
	}
}
