import { LikutMainSQL, LikutKabalaMainSQL, LikutPath } from 'src/app/Interfaces/likut';
import { TruckDistributionMainSQL, TruckDistributionPath } from 'src/app/Interfaces/truck-distribution';
import { TruckLoadingMainSQL, TruckLoadingPath } from 'src/app/Interfaces/truck-loading';
import { translateDateForWebISO } from 'src/app/Utility/functions';

export const TEUDA_GROUP_A = [1, 4];
export const TEUDA_GROUP_B = [3, 5];
export const COLOR_FOR_GROUP_B = `#fbffb1`;
export const COLOR_FOR_COMPANY_2 = `#b0dbff`;
export const COLOR_FOR_COMPANY_3 = `#ffdaad`;

type TaskIdentifierInput = (
	| { mode: TruckDistributionPath; task: TruckDistributionMainSQL }
	| { mode: TruckLoadingPath; task: TruckLoadingMainSQL }
	| { mode: LikutPath; task: LikutMainSQL }
	| { mode: 'likut-mekabel'; task: LikutKabalaMainSQL }
) & { onlyDocnum?: boolean };

function generateTaskIdentifier(input: TaskIdentifierInput): string {
	if (input.mode === 'truck-distribution') {
		const { task } = input;
		if (input.onlyDocnum) {
			return `${task.DocNum}`;
		}
		return `${task.ClientID},${task.PODStatus}${task.Address || ''}${task.City || ''}${(task.PODDate || '').slice(0, 10)}${task.AssignTo}${task.TypeofDoc || ''}`;
	}
	if (input.mode === 'truck-loading') {
		const { task } = input;
		return `${task.ClientID},${task.LoadingStatus}${task.Address || ''}${task.City || ''}${(task.LoadDate || '').slice(0, 10)}${task.AssignTo}`;
	}
	if (input.mode === 'likut') {
		const { task } = input;
		return `${task.Client_Id},${task.LikutStatus}${task.Address || ''}${task.City || ''}`;
	}
	if (input.mode === 'likut-mekabel') {
		const { task } = input;
		return `${task.TeudaNum},${task.TeudaType}`;
	}
	return '';
}

export function getTaskIdentifier(options: TaskIdentifierInput): string {
	const { mode, task } = options;
	let identifier = '';
	let usingTeudaTypeSeparator = '';
	if (task.TeudaType && mode !== 'likut' && mode !== 'likut-mekabel' && TEUDA_GROUP_B.includes(task.TeudaType)) {
		usingTeudaTypeSeparator = 'groupB';
	}

	switch (mode) {
		case 'truck-distribution':
			identifier = `${generateTaskIdentifier({ ...options })}${usingTeudaTypeSeparator}`;
			break;
		case 'truck-loading':
			identifier = `${generateTaskIdentifier({ ...options })}${usingTeudaTypeSeparator}`;
			break;
		case 'likut':
			identifier = `${generateTaskIdentifier({ ...options })}${usingTeudaTypeSeparator}`;
			break;
		case 'likut-mekabel':
			identifier = `${generateTaskIdentifier({ ...options })}${usingTeudaTypeSeparator}`;
			break;

		default:
			break;
	}

	return identifier;
}

export function getTaskColor(options: TaskIdentifierInput): string | null {
	const { mode, task } = options;
	if (mode === 'truck-distribution' || mode === 'truck-loading') {
		if (task.TeudaType && TEUDA_GROUP_B.includes(task.TeudaType)) {
			return COLOR_FOR_GROUP_B;
		}
		if (task.Company === 2) {
			return COLOR_FOR_COMPANY_2;
		}
	}
	return null;
}
