<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="handleBack()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביצועים מחלקות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
	<div class="report-table__row" *ngIf="First || Third">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="search()">
				<input class="header__search-input" type="text" [placeholder]="First ? 'חפש מחלקה' : 'חפש מוצר'" formControlName="search" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="searchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<!-- <div class="report-table__row report-table__row_between" style="margin-top:50px;"> -->
		<div style="margin-top: 96px">
			<!-- <div *ngIf="ShowCompanys !== null && !Second">
				<form [formGroup]="form" (change)="ChangeCompany()">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div> -->
			<!-- <div class="box" *ngIf="isAdmin">
				<b>סה"כ</b>
				<span>
					{{ total$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ average$ | async | number : '1.0-2' }}
				</span>
			</div> -->
			<div class="flex-row" style="justify-content: space-between; padding-inline: 0">
				<form [formGroup]="form" *ngIf="!Third">
					<mat-button-toggle-group name="filterBy" formControlName="qtyOrMoney" aria-label="Filter By">
						<mat-button-toggle value="money" [ngClass]="{ active: form.controls.qtyOrMoney.value === 'money' }">סכום</mat-button-toggle>
						<mat-button-toggle value="qty" [ngClass]="{ active: form.controls.qtyOrMoney.value === 'qty' }">כמות</mat-button-toggle>
					</mat-button-toggle-group>
				</form>
				<button *ngIf="Third" mat-raised-button color="primary" (click)="differentLayout = !differentLayout">החלף עיצוב</button>
				<div class="flex gap" *ngIf="windowWidth > 768">
					<app-excel-button [ngStyle]="{ opacity: differentLayout && Third ? 0 : 1 }" [filename]="excelFilename$ | async"></app-excel-button>
					<!-- <mat-radio-group color="primary" [formControl]="radioControl" class="justify-align-center gap" dir="rtl">
						<mat-radio-button value="basic">בסיסי</mat-radio-button>
						<mat-radio-button value="detailed">מפורט</mat-radio-button>
					</mat-radio-group> -->
				</div>
			</div>
			<h2 *ngIf="(Second || Third) && groupYearly && groupYearly.length && groupYearly[0].ItmsGrpNam">{{ groupYearly[0].ItmsGrpNam }}</h2>
			<h2 *ngIf="Third && chosenMonth && chosenYear">{{ chosenYear + ' - ' + (+chosenMonth | returnMonthHebrew) }}</h2>
		</div>

		<!-- </div> -->

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0" *ngIf="First && !loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 120px">מחלקה</th>
						<th class="report-table__table-head-name" style="width: 120px">החודש, השנה</th>
						<!-- <th class="report-table__table-head-name" style="width: 120px">כמות החודש, שנה שעברה</th> -->
						<!-- <th class="report-table__table-head-name" style="width: 120px">סכום החודש, השנה</th> -->
						<th class="report-table__table-head-name" style="width: 120px">החודש, שנה שעברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let g of groupsQuantities$ | async" (click)="handleGetSecondPage(g.ItmsGrpCod)">
						<td class="report-table__table-body-name">{{ g.ItmsGrpNam }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ g.yy1 | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ g.yy2 | number : '1.0-2' }}</td>
						<!-- <td class="report-table__table-body-name" dir="ltr">{{ g.yy1TotalMoney | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ g.yy2TotalMoney | number : '1.0-2' }}</td> -->
					</tr>
				</tbody>
			</table>
			<table class="report-table__table" cellspacing="0" *ngIf="Second && !loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 120px">חודש</th>
						<th class="report-table__table-head-name" style="width: 120px">השנה</th>
						<th class="report-table__table-head-name" style="width: 120px">אשתקד</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let g of groupYearly$ | async; index as i">
						<td class="report-table__table-body-name">
							{{ i | returnMonthHebrew }}
						</td>
						<td class="report-table__table-body-name" (click)="handleGetThirdPage(+i + 1, thisYear)" dir="ltr">
							{{ g.yy1 | number : '1.0-2' }}
						</td>
						<td class="report-table__table-body-name" (click)="handleGetThirdPage(+i + 1, thisYear - 1)" dir="ltr">
							{{ g.yy2 | number : '1.0-2' }}
						</td>
					</tr>
				</tbody>
			</table>
			<table class="report-table__table" cellspacing="0" *ngIf="Third && !loading">
				<thead class="report-table__table-head">
					<tr *ngIf="!differentLayout; else mobileViewBlockHead">
						<th class="report-table__table-head-name" style="width: 220px">שם מוצר</th>
						<th class="report-table__table-head-name" style="width: 120px">קוד</th>
						<th class="report-table__table-head-name" style="width: 90px">כמות שנמכרה</th>
						<th class="report-table__table-head-name" style="width: 100px">סכום</th>
					</tr>
					<ng-template #mobileViewBlockHead>
						<tr>
							<th class="report-table__table-head-name" style="width: 100%"></th>
						</tr>
					</ng-template>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let g of productsOfGroup$ | async">
						<ng-container *ngIf="!differentLayout; else mobileViewBlockBody">
							<td class="report-table__table-body-name">{{ g.ProductName }}</td>
							<td class="report-table__table-body-name">{{ g.ProductID }}</td>
							<td class="report-table__table-body-name" dir="ltr">{{ g.TotalQty | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" dir="ltr">{{ g.TotalMoney | number : '1.0-2' }}</td>
						</ng-container>
						<ng-template #mobileViewBlockBody>
							<td class="report-table__table-body-name mobile-view-row">
								<div class="top">
									<span>{{ g.ProductID }}</span> - <span>{{ g.ProductName }}</span>
								</div>
								<div class="bottom bottom-left">
									<span>סכום: </span><span dir="ltr">{{ g.TotalMoney }}</span>
								</div>
								<div class="bottom bottom-right">
									<span>כמות: </span><span dir="ltr">{{ g.TotalQty }}</span>
								</div>
							</td>
						</ng-template>
					</tr>
				</tbody>
			</table>
			<h1 *ngIf="loading">טוען...</h1>
		</div>
	</div>
</section>
<!-- end report table markup -->
