import { Injectable } from '@angular/core';
import { compress, decompress } from 'lz-string';
import { CustomerInformation } from '../Interfaces/SQL-morning-responses';
import { LoginUser } from '../Interfaces/users';
import { IndexedDBService } from './indexed-db.service';

export const DB_KEYS = [
	'AllClients',
	'SpecPriceSs',
	'AllProductsPricess',
	'_ss.FilterMigvan',
	'_ss.Mehironim',
	'AllProducts',
	'GroupName',
	'allSpecials',
	'AllDocTypesSave',
	'MGroups',
	'ShirionProducts',
	'AllHovotHeaders',
	'Maslul',
	'allGroupDiscounts',
	'SelectHearot',
	'DocSpecialProducts',
	'Alerts',
	'StockOriginal',
	'Concentrate',
	'ItemPacks',
	'receiptsToSend',
	'Banks',
	'LikutMekabelWarehouses',
	'LikutMekabelDoctypes',
] as const;
export const LS_KEYS = [
	'CustomerInformation',
	'lastupdate',
	'Admin',
	'UserName',
	'Password',
	'AgentId',
	'Updating',
	'work',
	'res',
	'err',
	'HasMultipleCompanys',
	'refresh',
	'SaveMainGroups',
	'AllSavedMahlakot',
	'ShowCompanys',
	'ManualUpdate',
	'HasMainGroup',
	'SeeTotalPrice',
	'ordon',
	'OrderStatus',
	'IsHistFirst',
	'hazaon',
	'reton',
	'ShowProfit',
	'AddProduct',
	'CustNum',
	'IgnorMinPrice',
	'AgentEmail',
] as const;

type DB_KEYS = typeof DB_KEYS[number];
type LS_KEYS = typeof LS_KEYS[number];
type ALL_KEYS = DB_KEYS | LS_KEYS;

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor(private _IDB: IndexedDBService) {}

	private _storageKeys: Set<ALL_KEYS> = new Set(Object.keys(localStorage) as ALL_KEYS[]);
	private lastKeysMapTimestamp: number = performance.now();

	private get storageKeys() {
		if (performance.now() - this.lastKeysMapTimestamp < 1000) return this._storageKeys;
		this.storageKeys = new Set(Object.keys(localStorage) as ALL_KEYS[]);
		return this._storageKeys;
	}

	private set storageKeys(value: Set<ALL_KEYS>) {
		// if (performance.now() - this.lastKeysMapTimestamp < 1000) return;
		this._storageKeys = value;
		this.lastKeysMapTimestamp = performance.now();
	}

	doesKeyExistInStorage(key: ALL_KEYS) {
		// if (this.isDBKey(key)) {
		// 	if (this.indexDBTablesWithValues.has(key) || this.indexDBTablesWithoutValues.has(key)) {
		// 		return this.indexDBTablesWithValues.has(key);
		// 	}
		// }
		return this.storageKeys.has(key);
	}

	async clearAllSavedData() {
		this.clearAllLocalstorage();
		await this.clearAllTruckDistributionFromIndexDB();
	}

	clearLocalstorageByKey(key: LS_KEYS) {
		localStorage.removeItem(key);
		this.storageKeys.delete(key);
	}

	private clearAllLocalstorage() {
		localStorage.clear();
		this.storageKeys.clear();
	}

	private async clearAllTruckDistributionFromIndexDB() {
		try {
			await this._IDB.deleteAllImages();
			await this._IDB.deleteAllProductsDB();
			await this._IDB.deleteTruckDistributionDB();
			await this._IDB.deleteAllTruckDistributionTasks();
		} catch (error) {
			console.log(error);
		}
	}

	async storeUserParameters(data: { verifiedUser: LoginUser; webclientInfo: Partial<CustomerInformation> }) {
		const { verifiedUser, webclientInfo } = data;
		localStorage.setItem('CustomerInformation', compress(JSON.stringify(webclientInfo)));
		localStorage.setItem('HasMultipleCompanys', verifiedUser.AgentExtra2);
		localStorage.setItem('UserName', verifiedUser.UserName);
		localStorage.setItem('AgentId', verifiedUser.AgentID);
		localStorage.setItem('SeeTotalPrice', verifiedUser.AgentExtra1);
		localStorage.setItem('OrderStatus', verifiedUser.AgentPhoneMobile);
		localStorage.setItem('Admin', verifiedUser.Admin);
		localStorage.setItem('HasMainGroup', String(verifiedUser.HasMainGroup));
		localStorage.setItem('IsHistFirst', String(verifiedUser.IsHistFirst));
		localStorage.setItem('hazaon', String(verifiedUser.hazaon));
		localStorage.setItem('ordon', String(verifiedUser.ordon));
		localStorage.setItem('reton', String(verifiedUser.reton));
		localStorage.setItem('ShowProfit', String(verifiedUser.ShowProfit));
		localStorage.setItem('AddProduct', String(verifiedUser.AddProduct));
		localStorage.setItem('CustNum', String(verifiedUser.CustNum));
		localStorage.setItem('IgnorMinPrice', String(verifiedUser.IgnorMinPrice));
		localStorage.setItem('Password', verifiedUser.Password);

		if (verifiedUser.Email) {
			localStorage.setItem('AgentEmail', verifiedUser.Email);
		}
	}

	/**
	 * @default
	 * ```ts
	 * options = {
	 * compressLZ: true
	 * useLocalStorage: true
	 * stringify: true
	 * }
	 * ```
	 */
	storeLocalDBUpdate(key: ALL_KEYS, data: any, options: storeLocalDBUpdateOptions = {}) {
		const useLocalStorage = options?.useLocalStorage ?? true;
		const compressLZ = options?.compressLZ ?? true;
		const stringify = options?.stringify ?? true;
		if (!useLocalStorage) {
			throw new Error('not implemented yet');
		}
		this.handleStoreLocalStorageByKey({ key, data, compressLZ, stringify });
	}

	/**
	 *
	 * @default
	 * ```ts
	 * options = {
	 * 	decompressLZ: true
	 * 	onlyCheckIfExist: false
	 * 	useLocalStorage: true
	 * 	parseJSON: true
	 * }
	 * ```
	 * @returns {string | null} if `onlyCheckIfExist = true` -> `boolean`; else -> `null | JSON.parse(decompress(data)) | string`
	 */
	getAndParseFromStorage(key: ALL_KEYS, options?: getAndParseFromStorageOptions) {
		const decompressLZ = options?.decompressLZ ?? true;
		const onlyCheckIfExist = options?.onlyCheckIfExist ?? false;
		const useLocalStorage = options?.useLocalStorage ?? true;
		const parseJSON = options?.parseJSON ?? true;

		if (onlyCheckIfExist) return this.doesKeyExistInStorage(key);
		if (!useLocalStorage) {
			throw new Error('not implemented yet');
		}
		const data = localStorage.getItem(key);
		if (!data) return data;
		const decompressedData = decompressLZ ? decompress(data) : data;

		if (!parseJSON) return decompressedData;
		return JSON.parse(decompressedData);
	}

	getAndParseCustomerInformation() {
		if (!this.doesKeyExistInStorage('CustomerInformation')) {
			return null;
		}
		return JSON.parse(decompress(localStorage.getItem('CustomerInformation')));
	}

	private handleStoreLocalStorageByKey({ key, data, compressLZ, stringify }: { key: ALL_KEYS; data: any; compressLZ: boolean; stringify: boolean }) {
		if (stringify) {
			data = JSON.stringify(data);
		}

		if (compressLZ) {
			data = compress(data);
		}

		localStorage.setItem(key, data);
		this.storageKeys.add(key);
	}

	// doesKeyExistInStorage(key: ALL_KEYS) {
	// 	return this.getAndParseFromStorage(key, { onlyCheckIfExist: true });
	// }
}

type storeLocalDBUpdateOptions = { compressLZ?: boolean; useLocalStorage?: boolean; stringify?: boolean };

type getAndParseFromStorageOptions = { decompressLZ?: boolean; onlyCheckIfExist?: boolean; useLocalStorage?: boolean; parseJSON?: boolean };
