<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToAdmin()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>

					<div class="header__title col">ביקורים</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>

			<div class="report-table__row">
				<div style="width: 99%; margin: auto">
					<table style="width: 99%; margin: auto">
						<tr>
							<!-- <th>
                  <div (click)="FilterSearches()" >אישור</div>
                  <button (click)="FilterSearches()" style="height: 30px;font-size: 20px;" >אישור </button>
                </th> -->
							<th>
								<form [formGroup]="form" (change)="AgentReports()" *ngIf="isAdmin">
									<div style="margin-bottom: 5px">בחר סוכן</div>
									<select formControlName="Agent" style="height: 30px">
										<option value="all">הכל</option>
										<option *ngFor="let a of Agents" value="{{ a.AgentID }}">{{ a.AgentID }} - {{ a.UserName }}</option>
									</select>
								</form>
							</th>
							<th>
								<form [formGroup]="form" (change)="NameReports()">
									<div style="margin-bottom: 5px">בחר לקוח</div>
									<select formControlName="Name" style="height: 30px">
										<option value="all">הכל</option>
										<option *ngFor="let raa of Names" value="{{ raa.ClientID }}">{{ raa.Client_Name }}</option>
									</select>
								</form>
							</th>
							<th>
								<form [formGroup]="form" (change)="DateReports()">
									<div style="margin-bottom: 5px">בחר תאריך</div>
									<select formControlName="Date" style="height: 30px">
										<option value="all">הכל</option>
										<option *ngFor="let raa of Dates" value="{{ raa.CreateDate }}">
											{{ raa.CreateDate | date : 'dd.MM.yyyy' }}
										</option>
									</select>
								</form>
							</th>
						</tr>
					</table>
				</div>
				<!-- <div class="header__search header__search_small" style="width: 99%;margin: auto;margin-top: 10px;">
            <form [formGroup]="form" (keyup)="SearchClient()"  >
            <input class="header__search-input" type="text" placeholder="חפש לקוח" formControlName="ClientSearch" style="text-align: center;">
          </form>
            <button class="header__clear-icon" (click)="SearchClear()"></button>
          </div> -->
			</div>

			<!-- <hr style="margin-top: 10px;"> -->
		</header>

		<!-- shop return activity markup -->
		<section class="shop-activity" style="margin-top: 140px">
			<div class="flex-row" style="justify-content: flex-end">
				<div class="flex gap" *ngIf="(device.isWebview$ | async) === false">
					<app-excel-button [downloadFunction]="downloadExcel" [AllClients]="AllClients" [radioControl]="radioControl"></app-excel-button>
					<mat-radio-group color="primary" [formControl]="radioControl" class="justify-align-center gap" dir="rtl">
						<mat-radio-button value="basic">בסיסי</mat-radio-button>
						<mat-radio-button value="detailed">מפורט</mat-radio-button>
					</mat-radio-group>
				</div>
			</div>
			<!-- <div class="report-table__row report-table__row_full-width" > -->
			<div class="report-table__row" style="overflow-x: auto">
				<table class="report-table__table" cellspacing="0" style="margin: 0 auto; width: min(100%, 1000px)">
					<thead class="report-table__table-head">
						<tr>
							<th class="report-table__table-head-name sortable-clickable" style="width: 110px" (click)="translateForSort('CreateDate', 'First')">תאריך</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('Client_Name', 'First')">לקוח</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 120px" (click)="translateForSort('ResDesc', 'First')">סיכום ביקור</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 60px" *ngIf="isAdmin" (click)="translateForSort('AgentID', 'First')">סוכן</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 60px" *ngIf="hasVisitsPictures">תמונה</th>
						</tr>
					</thead>
					<tbody class="report-table__table-body">
						<tr *ngFor="let b of AllClients" (click)="openPopup(b)" class="border-bottom-black">
							<td class="report-table__table-body-name">{{ b.CreateDate | date : 'dd.MM.yyyy' }}</td>
							<td class="report-table__table-body-name">{{ b.Client_Name ?? b.ClientName }}</td>
							<td class="report-table__table-body-name">{{ b.ResDesc }}</td>
							<td class="report-table__table-body-name" *ngIf="isAdmin">{{ b.AgentID }}</td>
							<td class="report-table__table-body-name" *ngIf="hasVisitsPictures">
								<ng-container *ngIf="b.PicName && b.PicName !== '0'">
									<div class="report-table__button" (click)="_util.stopEventPropogation($event)">
										<a
											*ngIf="(device.isWebview$ | async) || (device.isNotDesktopResolution$ | async); else openInNewTabBlock"
											[href]="_ss.CustomerInformation.CustServer + '/mobil/download-visits-picture/' + b.PicName"
											target="_blank"
											>הורד</a
										>
										<ng-template #openInNewTabBlock><a [href]="_ss.CustomerInformation.CustServer + '/' + b.PicName" target="_blank">פתח</a></ng-template>
									</div>
								</ng-container>
							</td>
						</tr>
					</tbody>
				</table>

				<!-- <h1 *ngIf="Second && loading">טוען...</h1> -->
			</div>
		</section>
		<!-- end shop return activity markup -->
	</div>
</div>

<div class="popup" *ngIf="isPopupOpen" style="z-index: 555">
	<div class="overlay" (click)="handleClosePopup()"></div>
	<div class="product-detail__title" *ngIf="selectedActive?.Client_Name ?? selectedActive?.ClientName">
		{{ selectedActive?.Client_Name ?? selectedActive?.ClientName }}
	</div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black"></div>
		<div class="product-detail__wrapper">
			<div class="popup-row">
				<span class="bold">תאריך:</span> <span>{{ selectedActive.CreateDate | date : 'dd.MM.yyyy' }}</span>
			</div>
			<div class="popup-row">
				<span class="bold">סיכום ביקור:</span> <span>{{ selectedActive.ResDesc }}</span>
			</div>
			<div class="popup-row" *ngIf="isAdmin">
				<span class="bold">סוכן:</span> <span>{{ selectedActive.AgentID }}</span>
			</div>
			<div class="popup-row">
				<span class="bold">קוד לקוח:</span> <span>{{ selectedActive.ClientID }}</span>
			</div>
			<div class="popup-row" *ngIf="!hasString">
				<span class="bold">שעה:</span> <span>{{ selectedActive.CreateDate | date : 'HH:mm' : 'GMT' }}</span>
			</div>
			<div class="popup-row" *ngIf="hasString">
				<span class="bold">כניסה:</span> <span>{{ selectedActive.string.Enter | date : 'HH:mm' : 'Asia/Jerusalem' }}</span>
			</div>
			<div class="popup-row" *ngIf="hasString">
				<span class="bold">יציאה:</span> <span>{{ selectedActive.string.Exit | date : 'HH:mm' : 'Asia/Jerusalem' }}</span>
			</div>
			<div class="popup-row">
				<span class="bold">פירוט:</span> <span>{{ selectedActive.Remark }}</span>
			</div>
			<div class="popup-row" *ngIf="hasReasonVisitText">
				<span class="bold">סיבת ביקור:</span> <span>{{ selectedActive.ResonVisitText }}</span>
			</div>
		</div>
	</div>
</div>
