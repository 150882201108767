<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">
						{{ title }}
					</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start markup for client profile page -->

		<!-- client info for all client profile page -->
		<section class="client-info" style="margin-top: 100px">
			<div class="content">
				<div class="row">
					<div class="client-info__title col">
						{{ this._ss.OneClientInfo.Client_Name }}
					</div>
				</div>
				<div class="client-info__block row">
					<div class="client-info__number col">
						{{ this._ss.OneClientInfo.ClientId }}
					</div>
				</div>
			</div>
			<div class="row" dir="rtl">
				<mat-form-field appearance="fill" dir="rtl">
					<mat-label>תעודות</mat-label>
					<mat-select [formControl]="_mismachim.docTypeControl" multiple (selectionChange)="handleSelectedDocTypesChange($event)">
						<mat-option *ngFor="let doc of _mismachim.docTypes" [value]="doc.DocDocType">{{ doc.DocTypeName }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</section>
		<!-- end of client info for all client profile page -->

		<!-- start markup for client profile order page -->
		<section class="client-order">
			<div class="content" *ngIf="!shouldShowReceiptsInstead">
				<div class="client-order__item row" *ngFor="let aodc of _mismachim.allMismachimClient$ | async">
					<div class="client-order__item-status client-order__item-status_green">טופל</div>
					<!-- <div class="client-order__item-status client-order__item-status_green">בטיפול</div> -->
					<div class="client-order__item-row row">
						<span class="client-order__item-name client-order__item-name_bold">אסמכתא</span>
						<span class="client-order__item-name">{{ aodc.DocDocNum }}#</span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-title">תאריך:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ aodc.dtorder | date : 'dd-MM-yy' : 'GMT' }}</span>
						<span class="client-order__item-text"></span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-title">הערה:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ aodc.DocRemark }}</span>
						<span class="client-order__item-text"></span>
					</div>

					<div class="client-order__item-row client-order__item-row_summ row">
						<div class="client-order__item-col client-order__item-col_border col">
							<span class="client-order__item-title">סכום:</span>
							<span class="client-order__item-text client-order__item-text_big client-order__item-text_price">{{ aodc.DocTotalSum | number : '1.0-2' : 'en-US' }}</span>
						</div>
						<div class="client-order__item-col col">
							<span class="client-order__item-title">סוג תעודה:</span>
							<span class="client-order__item-text client-order__item-text_big">{{ aodc.DocTypeName }}</span>
						</div>
						<div class="client-order__item-col col" style="align-items: center; display: flex">
							<button class="client-order__item-button" (click)="MoveToMismachimEach(aodc.DocDocNum, aodc.DocDocType)">הצג תעודה</button>
						</div>
					</div>
				</div>
			</div>

			<div class="content" *ngIf="shouldShowReceiptsInstead">
				<div class="client-order__item row" *ngFor="let rec of _receipts.receipts">
					<div class="client-order__item-row row">
						<span class="client-order__item-name client-order__item-name_bold">מס קבלה</span>
						<span class="client-order__item-name">{{ rec.Docnumber }}#</span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-title">תאריך פרעון:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ rec.ValueDate | date : 'dd/MM/YY' }}</span>
						<span class="client-order__item-text"></span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-title">הערה:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ rec.DocRemark }}</span>
						<span class="client-order__item-text"></span>
					</div>

					<div class="client-order__item-row client-order__item-row_summ row">
						<div class="client-order__item-col client-order__item-col_border col">
							<span class="client-order__item-title">סכום:</span>
							<span class="client-order__item-text client-order__item-text_big client-order__item-text_price">{{ rec.SuF | number : '1.0-2' }}</span>
						</div>
						<div class="client-order__item-col col">
							<span class="client-order__item-title">סוג תשלום:</span>
							<span class="client-order__item-text client-order__item-text_big">{{ rec.Sug === 1 ? 'מזומן' : "צ'ק" }}</span>
						</div>
						<div class="client-order__item-col col" style="align-items: center; display: flex">
							<button class="client-order__item-button" (click)="handleReceiptPopupOpen(rec)">הצג פרטי צ'ק</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>

<div class="popup" *ngIf="isReceiptPopupOpen" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content no-padding">
		<div class="product-detail__wrapper wrapper2">
			<div class="product-detail__item overflow" style="padding: 0; direction: rtl">
				<div class="popup__close" (click)="isReceiptPopupOpen = false" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>
				<div class="content popup-header client-info">
					<div class="row">
						<div class="popup-title client-info__title col">קבלה מס' {{ selectedReceipt.Docnumber }}</div>
					</div>
					<div class="client-info__block row">
						<div class="client-info__number col">הערה: {{ selectedReceipt.Remark }}</div>
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">מספר צ'ק</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ selectedReceipt.CheqNumber }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">בנק/סניף</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600; direction: ltr">
						{{ selectedReceipt.BankNumber }} / {{ selectedReceipt.Branch }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>

				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">חשבון</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ selectedReceipt.BankAccNum }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">תאריך יצירה</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ selectedReceipt.DocDate | date : 'dd/MM/yy' }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">תאריך פרעון</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ selectedReceipt.ValueDate | date : 'dd/MM/yy' }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">סוג תשלום</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ selectedReceipt.Sug === 1 ? 'מזומן' : "צ'ק" }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">סכום</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ selectedReceipt.SuF | number : '1.0-2' }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>
				<div class="client-profile__block row">
					<div class="client-profile__image image col">
						<img src="/assets/images/client-profile/indebtedness-icon.svg" alt="image" />
					</div>
					<div class="client-profile__title col" style="font-size: 19px; font-weight: 600">סכום הקבלה</div>
					<div class="client-profile__value col justify-align-center" style="font-size: 19px; font-weight: 600">
						{{ selectedReceipt.tftal | number : '1.0-2' }}
					</div>
					<div class="client-profile__icon client-profile__icon_arrow-left client-profile__icon_arrow-gray image col">
						<!-- <img src="/assets/images/client-profile/rejected-arrow.svg" alt="image"> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
