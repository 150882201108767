import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, delay, map, merge, Observable, of, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';

export type SearchPreferenceChoice = 'default' | 'rememberLast' | 'startFrom';
export type SearchOptions = '' | 'Name' | 'Code' | 'Barcode' | 'ItemExtra1';
type PreferencesList = { name: string; value: SearchPreferenceChoice };

@Component({
	selector: 'app-search-preference',
	templateUrl: './search-preference.component.html',
	styleUrls: ['./search-preference.component.scss'],
})
export class SearchPreferenceComponent implements OnInit, OnDestroy {
	@Output() closeEvent = new EventEmitter();
	@Output() preferenceSelectChanged = new EventEmitter<SearchOptions>();

	preferences: PreferencesList[] = [
		{ name: 'ללא העדפה', value: 'default' },
		{ name: 'זכור בחירה אחרונה', value: 'rememberLast' },
		{ name: 'לפי: ', value: 'startFrom' },
	];

	preferedChoice: SearchPreferenceChoice = 'default';

	preferenceRadioControl = new FormControl<SearchPreferenceChoice>('default');
	preferenceSelectControl = new FormControl<SearchOptions>('');

	savedValue$: Observable<string>;

	searchOptions: { value: SearchOptions; text: string }[] = [
		{ value: '', text: 'הכל' },
		{ value: 'Name', text: 'שם' },
		{ value: 'Code', text: 'קוד' },
		{ value: 'Barcode', text: 'ברקוד' },
	];

	destory$ = new Subject<void>();

	constructor(private _ss: StoreService, private _snackbar: MatSnackBar) {}

	ngOnInit(): void {
		this.handleExtraInitialSearchOptions();
		this.populatePreference();
		this.handleInteractivity();
	}

	ngOnDestroy(): void {
		this.destory$.next();
		this.destory$.complete();

		this._snackbar.dismiss();
	}

	populatePreference() {
		const stored = localStorage.getItem('searchPreference');
		if (stored) {
			if (stored.includes('-') && stored.split('-')[0] === 'startFrom') {
				const [startFrom, option] = stored.split('-');

				this.preferenceRadioControl.setValue('startFrom');
				this.preferenceSelectControl.setValue(option as SearchOptions);
				return;
			}

			this.preferenceRadioControl.setValue(stored as SearchPreferenceChoice);
		}
	}

	handleExtraInitialSearchOptions() {
		if (this._ss.CustomerInformation.SearchExtraProductFilter === '1') {
			this.searchOptions.push({
				value: 'ItemExtra1',
				text: 'שם סינון',
			});
		}
	}

	handleInteractivity() {
		this.preferenceRadioControl.valueChanges.pipe(takeUntil(this.destory$)).subscribe(newValue => {
			if (newValue === 'startFrom') {
				this.preferenceSelectControl.setValue(this.preferenceSelectControl.value);
				return;
			}

			this.saveLocally(newValue, 'searchPreference');
		});
		this.preferenceSelectControl.valueChanges.pipe(takeUntil(this.destory$)).subscribe(newValue => {
			this.saveLocally(newValue, 'searchOption');
			this.preferenceSelectChanged.emit(newValue);
		});
	}

	saveLocally(newValue: string, key: 'searchPreference' | 'searchOption') {
		if (key === 'searchPreference') {
			localStorage.setItem(key, newValue);
		}

		if (key === 'searchOption') {
			localStorage.setItem('searchPreference', `startFrom-${newValue}`);
		}
		this._snackbar.open('הגדרת חיפוש עודכנה בהצלחה', 'אוקי', { direction: 'rtl' });
		return of(newValue).pipe(delay(200));
	}
}
