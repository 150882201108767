import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { decompress } from 'lz-string';
import { BehaviorSubject, debounceTime, map, Observable, Subject, takeUntil } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { TextService } from 'src/app/services/text.service';
import { translateDateForSQLServer, translateDateForWebISO } from 'src/app/Utility/functions';

type DocType = {
	DocType: string;
	DocName: string;
};

const pathsToPage = {
	'b2b-to-agent': 'B2B',
	'b2b-only': 'B2BOnly',
	reclamation: 'reclamation',
	'brands-admin': 'brandsAdmin',
	brands: 'brands',
	'dohot-bizoim-azmanot': 'bizoimAzmanot',
} as const;

type PossiblePaths = keyof typeof pathsToPage;
type PageMode = typeof pathsToPage[PossiblePaths];

@Component({
	selector: 'app-dohotbizoimazmanot',
	templateUrl: './dohotbizoimazmanot.component.html',
	styleUrls: ['./dohotbizoimazmanot.component.scss'],
})
export class DohotbizoimazmanotComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, private _date: DateAdapter<any, 'he-IL'>, public _text: TextService) {}

	public form = this._fb.group({
		Search: [''],
		from: [<Date>null],
		to: [<Date>null],
	});
	public AllOrdersBitzoimPerAgent;
	public AllOrdersBitzoimPerAgentSave;
	public Each = false;
	public Main = true;
	public TotalArrayChecked = 0;
	public TotalArray = 0;
	public ArrayChecked = [];

	LoadingSeeTeuda = false;
	SeeTeudaLoaded = false;

	selectedTeudaToOpenPopup = null;

	destroy$: Subject<void> = new Subject();

	monthArrWithExistingMonths = new Array(12);

	showFilterPopup = false;

	remarks = [];

	pageMode$ = new BehaviorSubject<PageMode>(this.getInitialPageMode());

	isNotReclamation$ = this.pageMode$.pipe(map(page => page !== 'reclamation'));
	isRemarkPopupOpen = false;

	title$ = this.pageMode$.pipe(map(page => this.getPageTitle(page)));

	docTypes;
	docTypesControl = new FormControl<Array<DocType>>([], { nonNullable: true });
	docTypeHash: { [key: number]: string } = {};

	checkedObject: { [key: string]: boolean } = {};

	datesFilter = [];

	canSeePrices = true;

	ngOnInit(): void {
		this._date.setLocale('he-IL');
		console.clear();

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		if (this.pageMode$.value !== 'reclamation') {
			const docTypes = localStorage.getItem('AllDocTypesSave');

			if (docTypes) {
				this.docTypes = JSON.parse(decompress(docTypes));
			}
		}

		this.resolveInitialDatabaseCall();

		window.scrollTo(0, 0);

		this.initiateSearchObservable();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		document.body.classList.remove('no-scroll');
	}

	public BackToDohotBizoim() {
		window.scrollTo(0, 0);
		if (this.Each === true || this.Last === true) {
			this.Main = true;
			this.Each = false;
			this.Last = false;
		} else {
			history.back();
		}
	}

	dateFilterFunction = (date: Date): boolean => {
		const dates = this.datesFilter;

		const actualDate = translateDateForSQLServer(date);

		return date ? dates.includes(actualDate.toISOString().slice(0, 10)) : false;
	};

	public ChooseDate() {
		if (!this.form.controls.from.value || !this.form.controls.to.value) return;

		this.ArrayChecked = [];
		this.TotalArrayChecked = 0;

		// const threeHours = 1000 * 60 * 60 * 3;

		this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave.filter(biz => {
			const teudaDate = new Date(biz.TeudaDate.slice(0, 10)).getTime();

			if (this.form.controls.from.value.toISOString().slice(0, 10) === this.form.controls.to.value.toISOString().slice(0, 10)) {
				return new Date(teudaDate).toISOString().slice(0, 10) === translateDateForSQLServer(this.form.controls.from.value).toISOString().slice(0, 10);
			}

			return (
				teudaDate >= new Date(translateDateForSQLServer(this.form.controls.from.value).toISOString().slice(0, 10)).getTime() &&
				teudaDate <= new Date(translateDateForSQLServer(this.form.controls.to.value).toISOString().slice(0, 10)).getTime()
			);
		});

		if (this.docTypesControl.value.length) {
			this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgent.filter(order => {
				return this.docTypesControl.value.find(docType => docType.DocType === order.TeudaType);
			});
		}

		this.TotalArray = this.AllOrdersBitzoimPerAgent.reduce((prev, curr) => prev + +curr.TeudaTotalSum, 0);

		this.showFilterPopup = !this.showFilterPopup;
	}

	handleChangeDoctype(val) {
		if (val && val.length) {
			this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave.filter(order => {
				return val.find(docType => docType.DocType === order.TeudaType);
			});
		} else {
			this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave;
		}

		if (this.form.controls.from.value || this.form.controls.to.value) {
			const threeHours = 1000 * 60 * 60 * 3;

			this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgent.filter(biz => {
				const teudaDate = new Date(biz.TeudaDate.slice(0, 10)).getTime();

				if (this.form.controls.from.value.toISOString().slice(0, 10) === this.form.controls.to.value.toISOString().slice(0, 10)) {
					return new Date(teudaDate).toISOString().slice(0, 10) === new Date(this.form.controls.from.value.getTime() + threeHours).toISOString().slice(0, 10);
				}

				return (
					teudaDate >= new Date(new Date(this.form.controls.from.value.getTime() + threeHours).toISOString().slice(0, 10)).getTime() &&
					teudaDate <= new Date(new Date(this.form.controls.to.value.getTime() + threeHours).toISOString().slice(0, 10)).getTime()
				);
			});
		}

		this.TotalArray = this.AllOrdersBitzoimPerAgent.reduce((prev, curr) => prev + +curr.TeudaTotalSum, 0);

		this.showFilterPopup = !this.showFilterPopup;
	}

	public OrderChecked(TeudaNum, TeudaTotalSum) {
		this.checkedObject[TeudaNum] = !this.checkedObject[TeudaNum];

		let data = this.ArrayChecked.find(ob => ob.TeudaNum === TeudaNum);

		if (data !== undefined) {
			this.ArrayChecked.splice(this.ArrayChecked.indexOf(this.ArrayChecked.find(ob => ob.TeudaNum === TeudaNum)), 1);

			this.TotalArrayChecked = 0;
			for (let x = 0; x < this.ArrayChecked.length; x++) {
				this.TotalArrayChecked += this.ArrayChecked[x].TeudaTotalSum;
			}
		} else {
			this.TotalArrayChecked = 0;

			this.ArrayChecked.push({ TeudaNum: TeudaNum, TeudaTotalSum: TeudaTotalSum });
			for (let x = 0; x < this.ArrayChecked.length; x++) {
				this.TotalArrayChecked += this.ArrayChecked[x].TeudaTotalSum;
			}
		}
	}

	SearchClear() {
		this.form.controls.Search.reset();
	}
	public Loading = false;
	public TeudotTableEach;
	public TeudotEach;
	public TeudotEachString;
	public Last = false;
	@ViewChild('teudotTableNames') rightSideOfTeudaProducts: ElementRef<HTMLTableCellElement>;

	public seeTeuda(TeudaNum, index) {
		document.body.classList.add('no-scroll');
		this.selectedTeudaToOpenPopup = this.AllOrdersBitzoimPerAgent[index];
		console.log(this.selectedTeudaToOpenPopup);
		this.LoadingSeeTeuda = true;
		this._ss.GetTeudotFirst(TeudaNum).subscribe(
			(res: any) => {
				this.TeudotTableEach = res.recordset;

				setTimeout(() => {
					if (!this.TeudotTableEach.length) {
						this._ss.GetTeudotEach(TeudaNum).subscribe(
							(res: any) => {
								this.TeudotEach = res.recordset;
								this.TeudotEachString = this.TeudotEach[0].DATAString;
								this.TeudotEach = JSON.parse(this.TeudotEach[0].DATAString);

								this.remarks = [
									this.TeudotEach[0].TeudaRemark1,
									this.TeudotEach[0].TeudaRemark2,
									this.TeudotEach[0].TeudaRemark3,
									this.TeudotEach[0].TeudaRemark4,
									this.TeudotEach[0].TeudaRemark5,
								].filter(r => r.length);

								console.log({ remarks: this.remarks, teuda: this.TeudotEach });

								if (this.TeudotEach[0].TeudaRemark) this.selectedTeudaToOpenPopup.remark = this.TeudotEach[0].TeudaRemark;

								this.SeeTeudaLoaded = true;
								this.LoadingSeeTeuda = false;
								setTimeout(() => {
									this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
								}, 10);
							},
							err => {
								alert('שגיאת שרת, אנא נסו שנית');
								this.LoadingSeeTeuda = false;
								document.body.classList.remove('no-scroll');
							},
						);
					} else {
						this.SeeTeudaLoaded = true;
						this.LoadingSeeTeuda = false;

						if (this.TeudotTableEach[0].TeudaRemark) this.selectedTeudaToOpenPopup.remark = this.TeudotTableEach[0].TeudaRemark;

						console.log(this.remarks);

						setTimeout(() => {
							this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
						}, 10);
					}
				}, 200);
			},
			err => {
				alert('שגיאת שרת, אנא נסו שנית');
				this.LoadingSeeTeuda = false;
				document.body.classList.remove('no-scroll');
			},
		);
	}

	handleClosePopup() {
		this.SeeTeudaLoaded = false;
		this.LoadingSeeTeuda = false;
		this.TeudotTableEach = [];
		this.selectedTeudaToOpenPopup = null;
		this.TeudotEach = [];
		this.remarks = [];
		document.body.classList.remove('no-scroll');
	}

	resetSort() {
		this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave;
		this.form.controls.from.reset();
		this.form.controls.to.reset();
		this.docTypesControl.setValue([]);
		this.showHideFilterPopup(false);

		this.SearchClear();
	}

	showHideFilterPopup(val: boolean): void {
		this.showFilterPopup = val;
	}

	initiateSearchObservable() {
		if (this.pageMode$.value === 'reclamation') {
			this.initiateReclamationSearchObservable();
			return;
		}
		this.form.controls.Search.valueChanges.pipe(debounceTime(150), takeUntil(this.destroy$)).subscribe(val => {
			this.ArrayChecked = [];
			this.TotalArrayChecked = 0;
			this.TotalArray = 0;

			this.form.controls.from.reset();
			this.form.controls.to.reset();
			this.docTypesControl.reset();

			if (val) {
				this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave.filter(td =>
					new RegExp(val.toLowerCase().replace(/[\/*\+\-\?,]/g, ''), 'g').test(
						('' + td.Client_Name + td.TeudaNum + td.TeudaNewNum + (td.TuedaErpDocNum || '')).toLowerCase().replace(/[\/*\+\-\?,]/g, ''),
					),
				);

				document.querySelectorAll('input[type="checkbox"]').forEach(chckbx => ((chckbx as HTMLInputElement).checked = false));

				this.checkedObject = {};

				for (let x = 0; x < this.AllOrdersBitzoimPerAgent.length; x++) {
					this.TotalArray += this.AllOrdersBitzoimPerAgent[x].TeudaTotalSum;
				}
				return;
			}

			this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave;

			for (let x = 0; x < this.AllOrdersBitzoimPerAgent.length; x++) {
				this.TotalArray += this.AllOrdersBitzoimPerAgent[x].TeudaTotalSum;
			}

			document.querySelectorAll('.mat-checkbox').forEach(chckbx => ((chckbx as any as MatCheckbox).checked ? (chckbx as any as MatCheckbox).toggle() : null));

			this.checkedObject = {};
		});
	}

	initiateReclamationSearchObservable() {
		this.form.controls.Search.valueChanges.pipe(debounceTime(150), takeUntil(this.destroy$)).subscribe(val => {
			if (val) {
				this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave.filter(td =>
					new RegExp(val.toLowerCase().replace(/[\/*\+\-\?,]/g, ''), 'g').test(('' + td.Client_Name + td.RekID).toLowerCase().replace(/[\/*\+\-\?,]/g, '')),
				);

				return;
			}

			this.AllOrdersBitzoimPerAgent = this.AllOrdersBitzoimPerAgentSave;
		});
	}

	handleMatchDoctypes() {
		const doctypeSet = new Set();

		for (let x = 0; x < this.AllOrdersBitzoimPerAgent.length; x++) {
			this.TotalArray += this.AllOrdersBitzoimPerAgent[x].TeudaTotalSum;

			doctypeSet.add(this.AllOrdersBitzoimPerAgent[x].TeudaType);

			if (this.AllOrdersBitzoimPerAgent[x].TeudaDate) {
				const monthOfOrder = new Date(this.AllOrdersBitzoimPerAgent[x].TeudaDate).getMonth();
				this.monthArrWithExistingMonths[monthOfOrder] = true;
			}
		}

		this.AllOrdersBitzoimPerAgentSave.sort((a, b) => new Date(b.TeudaDate).getTime() - new Date(a.TeudaDate).getTime());

		this.docTypes = Array.from(doctypeSet).map(type => {
			const doc = this.docTypes.find(docType => docType.DocType === type);
			if (doc) {
				return { DocName: doc.DocName, DocType: type };
			}
			return { DocName: type, DocType: type };
		});

		this.docTypeHash = this.docTypes.reduce((acc, docType) => {
			acc[docType.DocType] = docType.DocName;
			return acc;
		}, this.docTypeHash);
	}

	resolveInitialDatabaseCall() {
		const callbackAfterSubscribing = (HTTPRequest: Observable<any>) =>
			HTTPRequest.pipe(takeUntil(this.destroy$)).subscribe({
				next: (res: any) => {
					if (!res.recordset) {
						alert('ישנה תקלת תקשורת, נסה שנית מאוחר יותר');
						return;
					}

					this.AllOrdersBitzoimPerAgentSave = this.AllOrdersBitzoimPerAgent = res.recordset;

					const datesSet: Set<string> = new Set(this.AllOrdersBitzoimPerAgent.map(perAgent => perAgent.TeudaDate.slice(0, 10)));

					this.datesFilter = [...datesSet];

					if (!['reclamation', 'brands', 'brandsAdmin'].includes(this.pageMode$.value)) {
						this.handleMatchDoctypes();
					}
				},
				error: () => {
					alert('ישנה תקלת תקשורת, נסה שנית מאוחר יותר');
					history.back();
					return;
				},
			});

		switch (this.pageMode$.value) {
			case 'B2B':
				this.canSeePrices = this._ss.CustomerInformation.B2BCanSeePrices !== '1';
				callbackAfterSubscribing(this._ss.getB2BOrders());
				break;
			case 'B2BOnly':
				callbackAfterSubscribing(this._ss.getB2BOnlyOrders());
				break;
			case 'brands':
				callbackAfterSubscribing(this._ss.handleGetBrandTable());
				break;
			case 'brandsAdmin':
				callbackAfterSubscribing(this._ss.handleGetBrandAdminTable());
				break;
			case 'reclamation':
				callbackAfterSubscribing(this._ss.handleGetAllReclamationOrders());
				break;

			default:
				//bizoimAzmanot
				callbackAfterSubscribing(this._ss.GetAllOrdersOneAgent());
				break;
		}
	}

	getInitialPageMode(): PageMode {
		const includes = (str: PossiblePaths) => location.pathname.includes(str);

		switch (true) {
			case includes('b2b-to-agent'):
				return 'B2B';
			case includes('b2b-only'):
				return 'B2BOnly';
			case includes('reclamation'):
				return 'reclamation';
			case includes('brands-admin'):
				return 'brandsAdmin';
			case includes('brands'):
				return 'brands';

			default:
				return 'bizoimAzmanot';
		}
	}

	getPageTitle(page: PageMode) {
		switch (page) {
			case 'brands':
			case 'brandsAdmin':
				return 'ביצועים מותגים';
			case 'B2B':
			case 'B2BOnly':
				return 'ביצועים תעודות B2B';
			case 'reclamation':
				return 'תעודות רקלמציה';

			default:
				return 'ביצועים תעודות';
		}
	}
}
