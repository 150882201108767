import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { Subject, BehaviorSubject, distinctUntilChanged, takeUntil } from 'rxjs';
import { Bizoim, BizoimClient, BizoimClientResponse, BizoimConcentrated } from 'src/app/Interfaces/agent-sales-quantities';
import { translateDateForSQLServer } from 'src/app/Utility/functions';

import { StoreService } from 'src/app/services/store.service';
import { UtilService } from 'src/app/services/util.service';
import { ResponseFromExpress } from 'src/app/Interfaces/SQL-interfaces';

@Component({
	selector: 'app-agent-sales-products-quantities-dates',
	templateUrl: './agent-sales-products-quantities-dates.component.html',
	styleUrls: ['./agent-sales-products-quantities-dates.component.scss'],
})
export class AgentSalesProductsQuantitiesDatesComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, private _date: DateAdapter<any, 'he-IL'>, private _util: UtilService) {}

	dates = {
		min: (() => {
			const d = new Date();
			d.setFullYear(d.getFullYear() - 3);
			return d;
		})(),
		max: new Date(),
	};

	form = this._fb.group({
		// Company: ['', Validators.required],
		Search: [''],
		from: [new Date(), Validators.required],
		to: [new Date(), Validators.required],
		Agent: [null],
	});

	mustPickDatePopup = true;
	hasPickedAtLeastOnce = false;

	AllProducts;
	AllProductsSearch;
	Bizoim: Bizoim[] = [];
	Bizoim$ = new BehaviorSubject<Bizoim[]>(this.Bizoim);
	BizoimClients: BizoimClientResponse[] = [];
	BizoimClients$ = new BehaviorSubject<BizoimClient[]>([]);

	BizoimConcentrated: BizoimConcentrated[] = [];
	BizoimConcentrated$ = new BehaviorSubject<BizoimConcentrated[]>(this.BizoimConcentrated);

	BizoimCompany;

	toogleInputNew = false;
	ShowCompanys = null;
	HasMultipleCompanys;
	reportSearch = null;
	history = history;

	destroy$ = new Subject<void>();

	bizoimTotalQuantity$ = new BehaviorSubject<number>(0);
	bizoimTotalSum$ = new BehaviorSubject<number>(0);
	bizoimAverageSum$ = new BehaviorSubject<number>(0);

	loadingMainQuery = false;

	windowWidth: number;

	isAdmin = location.pathname.includes('admin');

	AgentArray = [];

	loadingAgents = false;

	hasGetAgentSalesNextQuery = false;

	First = true;
	FirstScrollY = 0;
	Second = false;
	// Third = false;

	isConcentrated = location.pathname.includes('concentrated');

	isOrders = location.pathname.includes('orders');

	agentsToGet = [];

	ngOnInit(): void {
		this._date.setLocale('he-IL');

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		this.windowWidth = window.innerWidth;

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		fetch(`${this._ss.CustomerInformation.CustServer}/mobil/reports/get-agent-sales/0`)
			.then(res => {
				if (res.ok) {
					return res.json();
				}
				// query not available, return nothing
				return Promise;
			})
			.then(res => {
				if (res.msg) {
					this.hasGetAgentSalesNextQuery = true;
				}
			})
			.catch(err => {
				console.log(err);
			});

		if (this.isAdmin) {
			this.loadingAgents = true;

			this.getAgents();
		}

		window.scrollTo(0, 0);

		if (!localStorage.getItem('UserName') && !localStorage.getItem('AgentId')) {
			this.router.navigate(['login']);
		}

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		this.form.controls['Search'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			if (val) {
				const splitValue = val.split(' ').map(v => v.toLowerCase());
				const arr = [];

				for (let i = 0; i < this.Bizoim.length; i++) {
					let counter = 0;

					const { ProductId, Product_Name } = this.Bizoim[i];

					const ProductIdWithoutSpaces = (ProductId || '').replace(/\s/g, '').toLowerCase(),
						Product_NameWithoutSpaces = (Product_Name || '').replace(/\s/g, '').toLowerCase(),
						allStrings = typeof ProductIdWithoutSpaces === 'string' && typeof Product_NameWithoutSpaces === 'string';

					// V8 optimizes this if statement, maybe
					if (allStrings) {
						for (let j = 0; j < splitValue.length; j++) {
							if ((ProductIdWithoutSpaces && ProductIdWithoutSpaces.indexOf(splitValue[j]) > -1) || (Product_NameWithoutSpaces && Product_NameWithoutSpaces.indexOf(splitValue[j]) > -1)) {
								counter++;
							}
						}
					}

					if (counter === splitValue.length) {
						arr.push(this.Bizoim[i]);
					}
				}

				// const

				this.Bizoim$.next(arr);
				this.refreshSubjects('Bizoim');
				return;
			}

			this.Bizoim$.next(this.Bizoim);
			this.refreshSubjects('Bizoim');
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleClosePopup() {
		this.mustPickDatePopup = false;
		if (!this.hasPickedAtLeastOnce) {
			history.back();
			return;
		}
	}

	handleFormSubmit() {
		this.loadingMainQuery = true;

		let { from, to, Agent } = this.form.value;

		let toAdd = [];

		if (!this.isAdmin) {
			toAdd.push(this._ss.AgentIdConnected);
		}

		if (this.isAdmin && Agent && Agent.length) {
			toAdd = [Agent, true];
		}

		from = new Date(Intl.DateTimeFormat('en-il', {}).format(new Date(+from)).split('/').reverse().join('/'));
		to = new Date(Intl.DateTimeFormat('en-il', {}).format(new Date(+to)).split('/').reverse().join('/'));

		from = translateDateForSQLServer(from);
		to = translateDateForSQLServer(to);

		// if (from.getTime() === to.getTime()) {
		to.setHours(24 - new Date().getTimezoneOffset() / 60);
		// }

		if (this.isOrders) {
			this._ss.getAgentSalesOrderssQuantitiesDates(from, to, ...toAdd).subscribe({
				next: (res: ResponseFromExpress<Bizoim>) => {
					if (res === 'ConnectionError' || res === 'RequestError') {
						this.handleError();
						return;
					}

					this.Bizoim = res.recordset;

					this.Bizoim$.next(this.Bizoim);

					this.mustPickDatePopup = false;
					this.hasPickedAtLeastOnce = true;

					this.refreshSubjects('Bizoim');

					this.loadingMainQuery = false;

					this.form.controls.Search.setValue(this.form.controls.Search.value);
				},
				error: err => {
					this.handleError();
				},
			});
			return;
		}

		if (this.isConcentrated) {
			this._ss
				.getAgentSalesProductsQuantitiesDatesConcentrated(from, to, ...toAdd)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (res: ResponseFromExpress<BizoimConcentrated>) => {
						if (res === 'ConnectionError' || res === 'RequestError') {
							this.handleError();
							return;
						}

						this.BizoimConcentrated = res.recordset;

						this.BizoimConcentrated$.next(this.BizoimConcentrated);

						this.mustPickDatePopup = false;
						this.hasPickedAtLeastOnce = true;

						this.refreshSubjects('BizoimConcentrated');

						this.loadingMainQuery = false;

						this.form.controls.Search.setValue(this.form.controls.Search.value);
					},
					error: err => {
						this.handleError();
					},
				});
			return;
		}

		this._ss.getAgentSalesProductsQuantitiesDates(from, to, ...toAdd).subscribe({
			next: (res: ResponseFromExpress<Bizoim>) => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					this.handleError();
					return;
				}

				this.Bizoim = res.recordset;

				this.Bizoim$.next(this.Bizoim);

				this.mustPickDatePopup = false;
				this.hasPickedAtLeastOnce = true;

				this.refreshSubjects('Bizoim');

				this.loadingMainQuery = false;

				this.form.controls.Search.setValue(this.form.controls.Search.value);
			},
			error: err => {
				this.handleError();
			},
		});
	}

	handleError() {
		alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
		this.history.back();
		return;
	}

	changeDateValue(val: 'month' | 'year') {
		const { from, to } = this.form.value;

		if (val === 'month') {
			from.setMonth(from.getMonth() - 1);
			// to.setMonth(to.getMonth() - 1);
		} else {
			from.setFullYear(from.getFullYear() - 1);
			// to.setFullYear(to.getFullYear() - 1);
		}

		this.form.patchValue({ from, to });

		// this.handleFormSubmit();
	}

	SearchClear() {
		if (this.form.controls['Search'].value) {
			this.form.controls['Search'].reset();
		}

		this.clearEverything();
	}

	//TODO refactor into two different methods

	clearEverything() {
		this.Bizoim$.next(this.Bizoim);

		this.refreshSubjects('Bizoim');
	}

	public ChangeCompany() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimCompany.length; x++) {
			if (this.BizoimCompany[x].COMPANY === JSON.parse(this.form.controls['Company'].value)) {
				this.Bizoim.push(this.BizoimCompany[x]);
			}
		}
	}

	compareProductWithClients(productid: string) {
		if (!this.hasGetAgentSalesNextQuery) return;

		this.loadingMainQuery = true;

		this.FirstScrollY = window.scrollY;

		const { from, to, Agent } = this.form.value;

		let toAdd = [];

		if (!this.isAdmin) {
			toAdd.push(this._ss.AgentIdConnected);
		}

		if (this.isAdmin && Agent && Agent.length) {
			toAdd = [Agent, true];
		}

		this._ss.getAgentSalesProductsQuantitiesDatesSpecificProduct(productid, from, to, ...toAdd).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					console.log('Error:', res);
					return;
				}

				this.BizoimClients = res.recordset;

				console.log(this.BizoimClients);

				this.BizoimClients$.next(res.recordset.map(rcd => ({ Client_Name: rcd.Client_Name, ClientId: rcd.ClientId, totqty: rcd.DocQuantity || rcd.totqty, totsum: rcd.DocPrice || rcd.totsum })));
				this.Second = true;
				this.First = false;
				window.scrollTo(0, 0);

				this.refreshSubjects('BizoimClients');

				this.loadingMainQuery = false;
			},
			error: err => {},
		});
	}

	handleNavigation() {
		if (this.First) {
			history.back();
			return;
		}

		if (this.Second) {
			this.Second = false;
			this.First = true;
			this.BizoimClients$.next([]);
			this.refreshSubjects('Bizoim');
			setTimeout(() => {
				window.scrollTo(0, this.FirstScrollY);
			}, 0);
			return;
		}
	}

	getTeudotOfClient(ClientId: string) {}

	async getAgents(): Promise<void> {
		if (this._ss.CustomerInformation.Submanagers === '1') {
			this.agentsToGet = await this.getSubmanagerAgentsFirst();
		}

		this.getAgentUsers();
	}

	async getSubmanagerAgentsFirst(): Promise<string[]> {
		return await new Promise(resolve => {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res === 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							resolve([]);
							history.back();
							return;
						}

						const agents = res.recordset.map(obj => obj.Agent);
						agents.push(this._ss.AgentIdConnected);
						this._ss.agentsOfSubmanager = agents;

						return resolve(agents);
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				resolve(this._ss.agentsOfSubmanager);
			}
		});
	}

	getAgentUsers() {
		this._ss.GetAllAgents(this.agentsToGet).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('אירעה שגיאה בקבלת נתונים מהשרת, אנא נסה שנית');
					history.back();
					return;
				}

				this.AgentArray = res.recordset;
				this.loadingAgents = false;
			},
			error: err => {
				alert('אירעה שגיאה בקבלת נתונים מהשרת, אנא נסה שנית');
				history.back();
			},
		});
	}

	refreshSubjects(val: 'Bizoim' | 'BizoimClients' | 'BizoimConcentrated') {
		if (this.isConcentrated) {
			val = 'BizoimConcentrated';
		}
		if (val === 'Bizoim') {
			this.bizoimTotalQuantity$.next(this.Bizoim$.value.length ? this.Bizoim$.value.reduce((p, c) => p + c.totqty, 0) : 0);
			this.bizoimTotalSum$.next(this.Bizoim$.value.length ? this.Bizoim$.value.reduce((p, c) => p + c.totsum, 0) : 0);
			this.bizoimAverageSum$.next(this.Bizoim$.value.length ? this.Bizoim$.value.reduce((p, c) => p + c.totsum, 0) / this.Bizoim$.value.length : 0);
		}

		if (val === 'BizoimClients') {
			this.bizoimTotalQuantity$.next(this.BizoimClients$.value.length ? this.BizoimClients$.value.reduce((p, c) => p + c.totqty, 0) : 0);
			this.bizoimTotalSum$.next(this.BizoimClients$.value.length ? this.BizoimClients$.value.reduce((p, c) => p + c.totsum, 0) : 0);
			this.bizoimAverageSum$.next(this.BizoimClients$.value.length ? this.BizoimClients$.value.reduce((p, c) => p + c.totsum, 0) / this.BizoimClients$.value.length : 0);
		}

		if (val === 'BizoimConcentrated') {
			this.bizoimTotalSum$.next(this.BizoimConcentrated$.getValue().length ? this.BizoimConcentrated$.value.reduce((p, c) => p + (+c.totavg || 0), 0) : 0);
			this.bizoimAverageSum$.next(this.BizoimConcentrated$.value.length ? this.BizoimConcentrated$.value.reduce((p, c) => p + (+c.AvgPrice || 0), 0) / this.BizoimConcentrated$.value.length : 0);
		}
	}

	sortArr = [false, false, false, false];

	sort(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		let arrOverride: Bizoim[] | BizoimClient[];

		if (this.form.controls.Search.value) {
			if (this.First) arrOverride = this.Bizoim$.getValue();
			if (this.Second) arrOverride = this.BizoimClients$.getValue();
		}

		switch (valueToSortBy) {
			case 'ProductId':
			case 'ClientId':
				i = 0;
				break;
			case 'Product_Name':
			case 'Client_Name':
				i = 1;
				break;
			case 'totqty':
				i = 2;
				break;
			case 'totsum':
				i = 3;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		const finalArr = arrOverride || this[arrayToSort];

		if (bool) {
			finalArr.sort((a, b) => new Intl.Collator('he', opt).compare(b[String(valueToSortBy)], a[String(valueToSortBy)]));
		} else {
			finalArr.sort((a, b) => new Intl.Collator('he', opt).compare(a[String(valueToSortBy)], b[String(valueToSortBy)]));
		}

		this.sortArr[i] = !bool;
	}
}
