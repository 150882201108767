import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, NgModel, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject, Observable, Subject, startWith, map, takeUntil, filter, merge, tap } from 'rxjs';
import { Alert, alertActivity, AlertInDatabase, AlertPostBody, MsgType, MsgTypeOptions } from 'src/app/Interfaces/alerts';
import { Users } from 'src/app/Interfaces/users';
import { AlertService } from 'src/app/services/alert.service';
import { StoreService } from 'src/app/services/store.service';
import { AtLeast } from 'src/app/Interfaces/util';
import { LoadingService } from 'src/app/services/loading.service';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { ValidateArrayLength } from 'src/app/Utility/FormValidators';
import { ResponseFromExpress } from 'src/app/Interfaces/SQL-interfaces';

// type MsgText = typeof text[number];
const activeArray = ['0', '1', '2'] as const;

const YEAR = 31_104_000_000;
const minDate = new Date();
const maxDate = new Date(new Date().getTime() * YEAR * 10); // 10 years max

const formDefaultValue = {
	Name: '',
	Msg: '',
	Active: <typeof activeArray[number]>alertActivity.active,
	time: '',
	clients: [],
	clientsSearch: '',
	agents: [],
	agentsSearch: '',
	shouldAffectAllClients: true,
	shouldAffectAllAgents: true,
	shouldBlockAgent: false,
	StartDate: minDate,
	EndDate: minDate,
};

type ArrayValues = 'agents' | 'clients';

@Component({
	selector: 'app-alert-manager',
	templateUrl: './alert-manager.component.html',
	styleUrls: ['./alert-manager.component.scss'],
})
export class AlertManagerComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		private router: Router,
		private _fb: FormBuilder,
		public _date: DateAdapter<any, 'he-il'>,
		private _snackBar: MatSnackBar,
		public _alert: AlertService,
		public _loading: LoadingService,
		private _acr: ActivatedRoute,
	) {}

	allAlerts: Alert[] = this._alert.Alerts;
	allAlerts$ = new BehaviorSubject<Alert[]>(this.allAlerts);

	inputControls = new Array(this.allAlerts.length).fill(null);

	loading = false;

	alertSuccess = false;

	MsgTypeText = Object.values(MsgType).reduce((p, c) => {
		p[c.msgType] = c.msgText;
		return p;
	}, {});
	MsgTypeOptions = MsgTypeOptions;

	year = 31_104_000_000;

	dates = { min: minDate, max: maxDate };

	destroy$: Subject<void> = new Subject();

	msgTypes: typeof MsgType[keyof typeof MsgType][] = Object.values(MsgType).sort((a, b) => +a.msgType - +b.msgType);
	allClients: AllClients[] = [];
	allClients$ = new Observable<AllClients[]>();
	allClientsRecord: Record<string, AllClients> = {};

	allAgents: Users[] = [];
	allAgents$ = new Observable<Users[]>();
	allAgentsRecord: Record<string, Users> = {};

	agentsToGet = [];

	// selectedProducts = new FormControl<Product[]>([], { nonNullable: true, validators: ValidateArrayLength });
	alertStatusControl = new FormControl<typeof activeArray[number]>(alertActivity.active, { nonNullable: true, validators: Validators.required });

	isEdittingAlert = false;
	selectedAlertID = null;

	isProductsPopupOpen = false;

	allowedCompanies = [1, 2];

	companyNum: number = 1;

	multiCompany = false;

	form = this._fb.nonNullable.group({
		Name: ['', Validators.required],
		MsgType: [<typeof MsgType[keyof typeof MsgType]['msgType']>Object.values(MsgType)[0].msgType, Validators.required],
		Msg: ['', Validators.required],
		Active: this.alertStatusControl,
		time: [''],
		clients: [<AllClients[]>[]],
		clientsSearch: [''],
		agents: [<Users[]>[]],
		agentsSearch: [''],
		shouldAffectAllClients: [true],
		shouldAffectAllAgents: [true],
		shouldBlockAgent: [false],
		StartDate: [this.dates.min],
		EndDate: [this.dates.min],
	});

	shouldShowClientAutocomplete$ = new BehaviorSubject(false);
	shouldShowAgentAutocomplete$ = new BehaviorSubject(false);
	shouldShowDateSelection$ = new BehaviorSubject(false);

	showClientAutocomplete$ = this.form.controls.shouldAffectAllClients.valueChanges.pipe(map(value => value));
	showAgentAutocomplete$ = this.form.controls.shouldAffectAllAgents.valueChanges.pipe(map(value => value));

	shouldAllowClientSelection$ = new BehaviorSubject(false);
	shouldAllowAgentSelection$ = new BehaviorSubject(false);
	shouldAllowTimeSelection$ = new BehaviorSubject(false);

	addProductPressed = false;

	shouldShowNoProductsError = false;

	isLoadingProgressBarShowing = false;

	isAlertDrawerOpen = false;

	isOnlyAgentAlerts = !location.pathname.includes('admin');

	shouldShowStatusSelection = !this.isOnlyAgentAlerts;

	openNewAlertOnInit = this._acr.snapshot.queryParams['newAlert'];

	historyBackTwice = this._acr.snapshot.queryParams['backTwice'];

	finishedSettingUpAllInformation$ = new BehaviorSubject<boolean>(false);

	// isAllocationPage = location.pathname.includes('allocation');
	// allocationPopupOpen = false;
	// selectedAllocationProduct = new FormControl<Product | string>('');

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			const CustomerInformation = localStorage.getItem('CustomerInformation');
			if (!CustomerInformation) {
				this.router.navigate(['login']);
				return;
			}

			this._ss.CustomerInformation = JSON.parse(decompress(CustomerInformation));
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (this._ss.CustomerInformation.HasMultipleCompanies === '1') {
			this.multiCompany = true;
			this.companyNum = 0;
		}

		if (this.isOnlyAgentAlerts) {
			this.msgTypes = this.msgTypes.filter(msg => msg.msgType === '1000');
		}

		const clients = localStorage.getItem('AllClients');
		//const 	products = localStorage.getItem('AllProducts');

		if (
			!clients
			// || !products
		) {
			alert('אנא עדכן נתונים');
			this.router.navigate(['home']);
		}

		this.allClients = JSON.parse(decompress(clients));
		this.allClientsRecord = this.allClients.reduce((p, c) => {
			p[c.ClientId] = c;
			return p;
		}, {});

		this.setFormInteractivity();

		this.handleGetSubmanagersFirst();

		this._ss.CompanyNumForDuplicate = null;

		window.scrollTo(0, 0);

		this._ss.Betipul = 0;
		this._ss.TeudotDetailsEach = [];
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		this.refreshAlerts();

		if (this.openNewAlertOnInit) {
			this.finishedSettingUpAllInformation$.pipe(takeUntil(this.destroy$)).subscribe({
				next: () => {
					this.openNewAlertDrawer();
				},
			});
		}
	}

	private _filterClients(name: string): AllClients[] {
		const filterValue = name.toLowerCase();

		return this.allClients.filter(client => client.Client_Name.toLowerCase().includes(filterValue) || client.ClientId.toLowerCase().includes(filterValue)).slice(0, 50);
	}

	private _filterAgents(name: string): Users[] {
		console.log(name);
		const filterValue = name.toLowerCase();

		return this.allAgents.filter(ag => ag.UserName.toLowerCase().includes(filterValue) || ag.AgentID.toLowerCase().includes(filterValue)).slice(0, 50);
	}

	displayFnClients(client: AllClients): string {
		return client && client.Client_Name && client.ClientId ? `${client.ClientId} - ${client.Client_Name}` : '';
	}

	displayFnAgents(agent: Users): string {
		return agent?.UserId && agent?.UserName ? `${agent.UserId} - ${agent.UserName}` : '';
	}

	chipClicked(i, mode: ArrayValues) {
		this.form.controls[mode].value.splice(i, 1);
		this.form.controls[mode + 'Search'].reset();
		this.form.controls[mode].updateValueAndValidity();
		// this.form.control[mode].value('');
	}

	handleAutocompleteSelect(event: MatAutocompleteSelectedEvent, mode: ArrayValues): void {
		this.form.controls[mode].setValue([...this.form.controls[mode].value, event.option.value]);
		this.form.controls[mode + 'Search'].reset();
	}

	// getAllAlerts() {
	// 	this.loading = true;

	// 	this._ss.getAlerts().subscribe({
	// 		next: res => {
	// 			if (res === 'ConnectionError' || res === 'RequestError') {
	// 				alert('ישנה תקלת תקשורת');
	// 				return;
	// 			}

	// 			this.allAlerts = res.recordset;
	// 			console.log(res);
	// 			this.refreshAlerts();
	// 		},
	// 	});
	// }

	handleGetSubmanagersFirst() {
		if (this._ss.CustomerInformation.Submanagers !== '1') {
			this.getAllUsers();
			return;
		}
		if (!this._ss.agentsOfSubmanager.length) {
			this._ss.getAdminAgents().subscribe({
				next: res => {
					if (typeof res == 'string') {
						alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
						return;
					}

					this._ss.agentsOfSubmanager = this.agentsToGet = res.recordset.map(obj => obj.Agent);
					this.agentsToGet.push(this._ss.AgentIdConnected);
				},
				error: err => {
					alert('שגיאת שרת');
				},
			});
		} else {
			this.agentsToGet = this._ss.agentsOfSubmanager;
			this.getAllUsers();
		}
	}

	getAllUsers() {
		this._ss
			.GetAllAgents(this.agentsToGet)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					if (typeof res === 'string') {
						alert('ישנה תקלה בהבאת סוכנים, אנא נסו שנית מאוחר יותר');
						history.back();
						return;
					}
					this.allAgents = res.recordset;

					this.allAgentsRecord = this.allAgents.reduce((p, c) => {
						p[c.AgentID] = c;
						return p;
					}, {});
					this.finishedSettingUpAllInformation$.next(true);
					this.finishedSettingUpAllInformation$.complete();
				},
			});
	}

	// handleProductSubmit() {
	// 	this.isProductsPopupOpen = false;
	// }

	closeDrawer() {
		this.isEdittingAlert = false;
		this.form.reset();
		this.alertSuccess = false;
		this.form.enable();
		this.showHideFilterPopup(false);
	}

	activateSpecial(id: number) {
		// this.allSpecials = specialMock.map(item => {
		// 	if (item.SpecialID == id) {
		// 		item.SpecialStatus = !item.SpecialStatus;
		// 	}
		// 	return item;
		// });
	}

	refreshAlerts() {
		this._loading.changeLoadingStatuses({ isLoading: true });

		// this.allAlerts = this.allAlerts.map(alert => ({ ...alert, JSON: JSON.parse(alert.JSON) }));

		let alerts = this.allAlerts;
		if (this.isOnlyAgentAlerts) {
			alerts = alerts.filter(al => al.MsgType === '1000' && al.JSON.agents?.includes(this._ss.AgentIdConnected));
		}
		this.allAlerts$.next(alerts);

		console.log(this.allAlerts);
		this._loading.changeLoadingStatuses({ isLoading: false });
	}

	// handleCloseProductsPopup() {
	// 	console.log(this.selectedProducts.value);
	// 	if (this.selectedProducts.value?.length) {
	// 		const allZero = this.selectedProducts.value.filter(prd => prd.TeudaBonusQuantity < 1 && prd.TeudaPackQuantity < 1 && prd.TeudaQuantity < 1);
	// 		let msg = 'אחד או יותר מהפריטים  ';
	// 		let originMsg = msg;

	// 		if (allZero.length) {
	// 			msg += 'בכמות 0. ';
	// 		}
	// 		const illegalQuantity = this.selectedProducts.value.filter(prd => prd.TeudaBonusQuantity < 0 || prd.TeudaPackQuantity < 0 || prd.TeudaQuantity < 0);

	// 		if (illegalQuantity.length) {
	// 			msg += 'בכמות שלילית. ';
	// 		}

	// 		const illegalNumber = this.selectedProducts.value.filter(prd => isNaN(+prd.TeudaBonusQuantity) || isNaN(+prd.TeudaItemNetPrice) || isNaN(+prd.TeudaPackQuantity) || isNaN(+prd.TeudaQuantity));

	// 		if (illegalNumber.length) {
	// 			msg += 'בעייתי';
	// 			console.log(illegalNumber);
	// 		}

	// 		if (msg !== originMsg) {
	// 			alert(msg + 'אנא הכניסו כמות או הורידו את הפריט');
	// 			return;
	// 		}
	// 	}
	// 	this.isProductsPopupOpen = false;
	// }

	// handleProductPopupOpen() {
	// 	this.shouldShowNoProductsError = false;
	// 	if (this.form.controls.Company.invalid) {
	// 		this.form.controls.Company.setErrors({ needsValueForProductPopup: true });
	// 		this.form.controls.Company.markAsTouched();
	// 		return;
	// 	}
	// 	this.isProductsPopupOpen = true;
	// }

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		if (this.historyBackTwice) {
			history.go(-1);
		}
	}

	showHideFilterPopup(value): void {
		this.isAlertDrawerOpen = value;
	}

	getDate(val: 'min' | 'max') {
		if (val === 'min') {
			return new Date(this.dates.min);
		}
		return new Date(this.dates.max);
	}

	setFormInteractivity() {
		this._date.setLocale('he-il');

		const { MsgType: MsgTypeControl, clients, agents, time, shouldAffectAllClients, shouldAffectAllAgents, Active, StartDate, EndDate } = this.form.controls;

		MsgTypeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(type => {
			if (MsgTypeOptions[type].clients && !shouldAffectAllClients.value) {
				clients.setValidators([Validators.required, ValidateArrayLength]);
				this.form.updateValueAndValidity();
			} else {
				clients.clearValidators();
				clients.setErrors(null);
			}
			if (MsgTypeOptions[type].agents && !shouldAffectAllAgents.value) {
				agents.setValidators([Validators.required, ValidateArrayLength]);
			} else {
				agents.clearValidators();
				agents.setErrors(null);
			}

			if (MsgTypeOptions[type].timeSensitive) {
				time.setValidators(Validators.required);
				if (!time.value) {
					time.setErrors({ required: true });
				}
			} else {
				time.clearValidators();
				time.setErrors(null);
			}

			this.shouldAllowClientSelection$.next(MsgTypeOptions[type].clients);
			this.shouldAllowAgentSelection$.next(MsgTypeOptions[type].agents);
			this.shouldAllowTimeSelection$.next(MsgTypeOptions[type].timeSensitive);
		});

		shouldAffectAllClients.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(boolean => {
			if (boolean) {
				clients.clearValidators();
				clients.reset();
			} else {
				clients.setValidators([Validators.required, ValidateArrayLength]);
				clients.updateValueAndValidity();
			}
			this.shouldShowClientAutocomplete$.next(!boolean);
		});

		shouldAffectAllAgents.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(boolean => {
			if (boolean) {
				agents.clearValidators();
				agents.reset();
			} else {
				agents.setValidators([Validators.required, ValidateArrayLength]);
				agents.updateValueAndValidity();
			}
			this.shouldShowAgentAutocomplete$.next(!boolean);
		});

		Active.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			const boolean = value === '2';
			if (boolean) {
				StartDate.setValidators(Validators.required);
				EndDate.setValidators(Validators.required);
			} else {
				StartDate.clearValidators();
				EndDate.clearValidators();
			}
			this.shouldShowDateSelection$.next(boolean);
		});

		// merge(StartDate.valueChanges, EndDate.valueChanges)
		// 	.pipe(takeUntil(this.destroy$))
		// 	.subscribe(value => {
		// 		console.log(value);
		// 	});

		this.allClients$ = this.form.controls.clientsSearch.valueChanges.pipe(
			startWith(''),
			map(value => (value && typeof value === 'string' ? this._filterClients(value) : this.allClients.slice(0, 50))),
			map(value => value.filter(v => !clients.value.includes(v))),
		);

		this.allAgents$ = this.form.controls.agentsSearch.valueChanges.pipe(
			startWith(''),
			map(value => (value && typeof value === 'string' ? this._filterAgents(value) : this.allAgents)),
			map(value => value.filter(v => !agents.value.includes(v))),
		);
	}

	refreshForm() {
		// const obj: typeof this.form.value = {};
		// for (const v of Object.values(this.form.value)) {
		// }
		// this.form.setValue({
		// 	Active
		// })
	}

	// refreshFormObservables() {
	// 	this.showClientAutocomplete$ = this.form.controls.shouldAffectAllClients.valueChanges.pipe(map(value => value));
	// 	this.showAgentAutocomplete$ = this.form.controls.shouldAffectAllAgents.valueChanges.pipe(map(value => value));
	// 	this.shouldShowDateSelection$ = this.form.controls.Active.valueChanges.pipe(map(value => value === '2'));

	// 	this.shouldAllowClientSelection$ = this.form.controls.MsgType.valueChanges.pipe(map(value => MsgTypeOptions[value].clients));
	// 	this.shouldAllowAgentSelection$ = this.form.controls.MsgType.valueChanges.pipe(map(value => MsgTypeOptions[value].agents));
	// 	this.shouldAllowTimeSelection$ = this.form.controls.MsgType.valueChanges.pipe(map(value => MsgTypeOptions[value].timeSensitive));
	// }

	async handleFormSubmit() {
		console.log(this.form);
		this.form.markAllAsTouched();
		if (this.form.invalid) return;

		// this._loading.changeLoadingStatuses({ isLoading: true });

		const { Active, Msg, MsgType, time, Name, agents, clients, shouldAffectAllAgents, shouldAffectAllClients, shouldBlockAgent, StartDate, EndDate } = this.form.value;

		const alertBody: AtLeast<Alert, 'Msg'> = {
			Name,
			Msg,
			Active,
			MsgType,
			CreateDate: new Date().toISOString(),
			JSON: {},
		};

		// const addDay = (date: Date): Date => {
		// 	date.setDate(new Date(date).getDate() + 1);
		// 	return date;
		// };

		const setTimeZero = (date: Date): Date => {
			date.setHours(0);
			date.setMinutes(0);
			date.setSeconds(0);
			return date;
		};

		if (Active === '2') {
			const start = typeof StartDate === 'object' ? setTimeZero(StartDate).toISOString() : StartDate;
			const end = typeof EndDate === 'object' ? EndDate.toISOString() : EndDate;
			alertBody.JSON = { date: { start, end } };
		}

		if (MsgTypeOptions[MsgType].timeSensitive) {
			alertBody.JSON = { ...alertBody.JSON, time };
		}

		if (MsgTypeOptions[MsgType].clients && !shouldAffectAllClients) {
			// alertBody.JSON = { ...alertBody.JSON, clients };
			alertBody.JSON = { ...alertBody.JSON, clients: clients.map(client => client.ClientId) };
		}

		if (MsgTypeOptions[MsgType].agents && !shouldAffectAllAgents) {
			alertBody.JSON = { ...alertBody.JSON, agents: agents.map(agent => agent.AgentID) };
		}

		if (MsgTypeOptions[MsgType].block && shouldBlockAgent) {
			alertBody.JSON = { ...alertBody.JSON, block: true };
		}

		const body: AlertPostBody = { ...alertBody, JSON: JSON.stringify(alertBody.JSON) };

		console.log(body);
		// this._loading.changeLoadingStatuses({ isLoading: false });

		// return;

		if (this.isEdittingAlert) {
			await this.handlePatchAlert(this.selectedAlertID, body);
			return;
		}

		this._ss.postNewAlert(body).subscribe({
			next: res => {
				this._loading.changeLoadingStatuses({ isLoading: false });

				if (res === 'ConnectionError' || res === 'RequestError') {
					console.log(res);
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					this.alertSuccess = false;
					return;
				}

				this.alertSuccess = true;

				this.allAlerts = this._alert.Alerts = res.recordset.map(al => ({ ...al, JSON: JSON.parse(al.JSON) }));

				this.form.reset();
				this.refreshAlerts();
				localStorage.setItem('Alerts', compress(JSON.stringify(this.allAlerts)));
			},
			error: err => {
				console.log(err);
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
				this._loading.changeLoadingStatuses({ isLoading: false });
				this.alertSuccess = false;
			},
		});
	}

	async handlePatchAlert(alertID: number, postBody: Partial<AlertPostBody>) {
		return new Promise<void>((resolve, reject) => {
			this._ss.patchAlert(alertID, postBody).subscribe({
				next: res => {
					this.handleAfterHttpRequest(res, resolve, reject);
				},
				error: err => {
					console.log(err);
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					this.alertSuccess = false;
					this._loading.changeLoadingStatuses({ isLoading: false });

					reject();
				},
			});
		});
	}

	handleDeleteAlert(alertID: number) {
		return new Promise<void>((resolve, reject) => {
			this._ss.deleteAlert(alertID).subscribe({
				next: async res => {
					await this.handleAfterHttpRequest(res, resolve, reject);
				},
				error: err => {
					console.log(err);
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					// this._loading.changeLoadingStatuses({ isLoading: false });
					this.alertSuccess = false;
					reject();
				},
			});
		});
	}

	handleDateChange(val: 'month' | 'year') {
		const start = new Date(this.form.controls.StartDate.value);

		if (val === 'month') {
			start.setMonth(start.getMonth() + 1);
		}

		const date = val === 'month' ? start : new Date(new Date(this.form.controls.StartDate.value).getTime() + 1000 * 60 * 60 * 24 * 365);
		this.form.controls.EndDate.setValue(date);
	}

	openNewAlertDrawer(formData: Required<typeof this.form.value> = { ...formDefaultValue, MsgType: Object.values(MsgType)[0]['msgType'] }) {
		this.showHideFilterPopup(true);
		if (this.isOnlyAgentAlerts) {
			formData.Active = alertActivity.byDate;
			formData.shouldAffectAllAgents = false;
			formData.agents = [this.allAgentsRecord[this._ss.AgentIdConnected]];
		}
		this.form.setValue(formData);
		setTimeout(() => {
			this.form.updateValueAndValidity();
		}, 0);
	}

	handleEditAlert(alert: Alert) {
		this.isEdittingAlert = true;
		this.selectedAlertID = alert.ID;

		const values = structuredClone(formDefaultValue);
		const alertObject = structuredClone(alert);

		values.Name = alertObject.Name;
		values.Active = alertObject.Active;
		values.Msg = alertObject.Msg;

		const { agents, clients, date, time, block } = alert.JSON;

		if (agents?.length) {
			values.agents = agents.map(agent => this.allAgentsRecord[agent]);
			values.shouldAffectAllAgents = false;
			this.shouldShowAgentAutocomplete$.next(true);
		}

		if (clients?.length) {
			values.clients = clients.map(client => this.allClientsRecord[client]);
			values.shouldAffectAllClients = false;
			this.shouldShowClientAutocomplete$.next(true);
		}

		if (date) {
			values.StartDate = new Date(date.start);
			values.EndDate = new Date(date.end);
			this.shouldShowDateSelection$.next(true);
		}

		if (time) {
			values.time = time;
		}

		if (block) {
			values.shouldBlockAgent = block;
		}

		this.openNewAlertDrawer({ ...values, MsgType: alert.MsgType });
	}

	async handleAfterHttpRequest(res: ResponseFromExpress<AlertInDatabase>, resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) {
		this._loading.changeLoadingStatuses({ isLoading: false });

		if (res === 'ConnectionError' || res === 'RequestError') {
			console.log(res);
			alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
			return reject();
		}

		this.alertSuccess = true;

		this.selectedAlertID = null;
		// this.isEdittingAlert = false;

		this.allAlerts = this._alert.Alerts = res.recordset.map(al => ({ ...al, JSON: JSON.parse(al.JSON) }));
		this.refreshAlerts();
		localStorage.setItem('Alerts', compress(JSON.stringify(this.allAlerts)));
		resolve();
	}

	async handleChangeAlert(
		e: MatButtonToggleChange | MouseEvent | PointerEvent | Event,
		alertID: number,
		action: 'patch' | 'delete' | 'patch-msg',
		options: { status?: string; input?: NgModel; i?: number } = {},
	) {
		if (this.isLoadingProgressBarShowing) return;

		const { status, i, input } = options;

		this.isLoadingProgressBarShowing = true;

		let msg = 'קרתה תקלה בתקשורת נתונים, אנא נסו שנית';

		if (action === 'patch' && e instanceof MatButtonToggleChange) {
			const newStatus = e.value;
			e.source.buttonToggleGroup.value = status;
			try {
				// await this.handlePatchAlert(alertID, { Active: newStatus });
				e.source.buttonToggleGroup.value = newStatus;
				msg = 'סטטוס התראה הוחלף בהצלחה';
				this.alertSuccess = false;
			} catch (error) {}
		}

		if (action === 'delete' && !(e instanceof MatButtonToggleChange)) {
			try {
				await this.handleDeleteAlert(alertID);
				msg = 'התראה נמחקה בהצלחה';
				this.alertSuccess = false;
			} catch (error) {}
		}

		if (action === 'patch-msg' && !(e instanceof MatButtonToggleChange)) {
			const newMsg = input.value;
			const alert = this.allAlerts$.value[i];
			console.log(alert);
			if (!newMsg || newMsg === alert.Msg) {
				return;
			}

			try {
				await this.handlePatchAlert(alert.ID, { Msg: newMsg });

				input.reset();

				this.alertSuccess = false;

				msg = 'מלל התראה הוחלף בהצלחה';
			} catch (error) {}
		}

		this._snackBar.open(msg, undefined, {
			duration: 3000,
			direction: 'rtl',
			panelClass: ['snack-bar'],
		});

		this.isLoadingProgressBarShowing = false;
	}

	BackToDohot() {
		history.back();
	}
}
