import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MismachimDocTypes } from '../components/mismachim/Mismachim-DocTypes';

import { Mismachim } from '../Interfaces/mismachim';

@Injectable({
	providedIn: 'root',
})
export class MismachimService {
	constructor() {}

	allMismachimClient: Mismachim[] = [];
	allMismachimClient$ = new BehaviorSubject<Mismachim[]>(this.allMismachimClient);

	docTypes: MismachimDocTypes[] = [];

	docTypeControl = new FormControl<MismachimDocTypes[]>([]);

	chosenMismachParameters = {
		DocDocType: '',
		DocDocNum: '',
	};

	scrollY = 0;
}
