<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="handleArrowPress()">
				<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
				<span style="color: white; font-size: 18px">חזרה</span>
			</div>
			<div class="header__title col">{{ isAdmin ? 'ניהול' : '' }} יעדי {{ isAgents ? 'סוכנים' : isGroups ? 'מחלקות' : 'לקוחות' }}</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="search()">
				<img src="/assets/images/header-search.svg" alt="search" />
			</button>
			<input
				dir="rtl"
				class="header__search-input"
				(keyup)="search()"
				type="text"
				[placeholder]="'חפש ' + (isAgents ? 'סוכנים' : isGroups ? 'מחלקות' : 'לקוחות')"
				[formControl]="searchControl"
				style="text-align: center"
			/>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
		<!-- <h3 class="report-table__row__totals" *ngIf="!Loading">סה"כ: <span>{{total$ | async | number : '1.0-2'}}</span></h3> -->
	</div>

	<h2 style="text-align: center" *ngIf="Loading">טוען אנא המתן</h2>
</header>

<!-- start report table markup -->
<section class="report-table" *ngIf="!Loading">
	<div class="content">
		<form [formGroup]="form" dir="rtl" *ngIf="hasMultipleCompanies">
			<div class="row flex gap justify-align-center">
				<div style="margin-top: 10px; font-size: 15.5px; font-weight: 425">
					<select formControlName="company">
						<option *ngFor="let company of companies" [value]="company.Company">{{ company.CompanyName }}</option>
					</select>
				</div>
			</div>
		</form>

		<div class="report-table__row report-table__row_between"></div>
		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px" *ngIf="main; else firstDrillBlock">
			<table class="report-table__table" cellspacing="0" *ngIf="isAgents">
				<thead class="report-table__table-head" style="border: unset">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortAgent('AgentCode')" style="width: 4rem">מס'</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortAgent('AgentName')" style="width: 7rem">שם</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortAgent('currentYearGoal')" style="width: 7rem">יעד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortAgent('total')" style="width: 7rem">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortAgent('advancementPercentage')" style="width: 5rem">אחוז התקדמות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortAgent('lastYearTotal')" style="width: 7rem">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortAgent('growthPercentage')" style="width: 4rem">גידול שנתי</th>
						<th class="report-table__table-head-name" style="width: 5rem" *ngIf="isAdmin">עריכה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let g of goalsAgentDisplay$ | async">
						<td class="report-table__table-body-name">{{ g.AgentCode }}</td>
						<td class="report-table__table-body-name">{{ g.AgentName }}</td>
						<td class="report-table__table-body-name">{{ g.currentYearGoal ? (g.currentYearGoal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.total ? (g.total | number : '1.0-2') : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.advancementPercentage ? (g.advancementPercentage | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.lastYearTotal ? (g.lastYearTotal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.growthPercentage ? (g.growthPercentage | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name" *ngIf="isAdmin"><button (click)="handleEditButton(g.AgentCode)">ערוך</button></td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" style="width: 98%" cellspacing="0" *ngIf="isClients">
				<thead class="report-table__table-head" style="border: unset">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortClient('Cl')" style="width: 400px">לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortClient('currentYearGoal')" style="width: auto">יעד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortClient('total')" style="width: auto">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortClient('advancementPercentage')" style="width: auto">אחוז התקדמות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortClient('lastYearTotal')" style="width: auto">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortClient('growthPercentage')" style="width: auto">גידול שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: auto" *ngIf="isAdmin">עריכה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<cdk-virtual-scroll-viewport itemSize="36" class="virtual-viewport">
						<tr *cdkVirtualFor="let g of goalsClient$" style="vertical-align: middle">
							<td class="report-table__table-body-name" style="min-width: 400px">{{ g.Cl }} - {{ g.Cn }}</td>
							<td class="report-table__table-body-name">{{ g.currentYearGoal ? (g.currentYearGoal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ g.total ? (g.total | number : '1.0-2') : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ g.advancementPercentage ? (g.advancementPercentage | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ g.lastYearTotal ? (g.lastYearTotal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ g.growthPercentage ? (g.growthPercentage | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name" *ngIf="isAdmin"><button (click)="handleEditButton(g.Cl)">ערוך</button></td>
						</tr>
					</cdk-virtual-scroll-viewport>
				</tbody>
			</table>
			<table class="report-table__table" style="width: 98%" cellspacing="0" *ngIf="isGroups">
				<thead class="report-table__table-head" style="border: unset">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" *ngIf="isAdmin" (click)="translateForSortGroup('AgentId')" style="width: 12rem">סוכן</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortGroup('GroupId')" style="width: 12rem">מחלקה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortGroup('currentYearGoal')" style="width: 7rem">יעד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortGroup('total')" style="width: 7rem">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortGroup('advancementPercentage')" style="width: 5rem">אחוז התקדמות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortGroup('lastYearTotal')" style="width: 7rem">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSortGroup('growthPercentage')" style="width: 5rem">גידול שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 6rem" *ngIf="isAdmin">עריכה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let g of goalsGroup$ | async" style="vertical-align: middle" (click)="handleGroupClick(g)">
						<td class="report-table__table-body-name" *ngIf="isAdmin" style="min-width: 200px">{{ g.AgentId }} - {{ g.AgentName }}</td>
						<td class="report-table__table-body-name" style="min-width: 200px">{{ g.GroupId }} - {{ g.GroupName }}</td>
						<td class="report-table__table-body-name">{{ g.currentYearGoal ? (g.currentYearGoal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.total ? (g.total | number : '1.0-2') : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.advancementPercentage ? (g.advancementPercentage | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.lastYearTotal ? (g.lastYearTotal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ g.growthPercentage ? (g.growthPercentage | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name" *ngIf="isAdmin"><button (click)="handleEditButtonGroup($event, g.AgentId, g.GroupId)">ערוך</button></td>
					</tr>
				</tbody>
			</table>
		</div>
		<ng-template #firstDrillBlock>
			<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
				<table class="report-table__table" style="width: 98%" cellspacing="0">
					<h1 *ngIf="selectedDrill$ | async as goal"></h1>
					<thead class="report-table__table-head" style="border: unset">
						<tr>
							<th class="report-table__table-head-name" style="width: 5rem">חודש</th>
							<th class="report-table__table-head-name" style="width: 7rem">יעד</th>
							<th class="report-table__table-head-name" style="width: 7rem">מכירות השנה</th>
							<th class="report-table__table-head-name" style="width: 5rem">אחוז התקדמות</th>
							<th class="report-table__table-head-name" style="width: 7rem">מכירות אשתקד</th>
							<th class="report-table__table-head-name" style="width: 5rem">גידול שנתי</th>
						</tr>
					</thead>
					<tbody class="report-table__table-body">
						<tr *ngFor="let g of goalsDrilled$ | async" style="vertical-align: middle">
							<td class="report-table__table-body-name">{{ g.Mm - 1 | returnMonthHebrew }}</td>
							<td class="report-table__table-body-name">{{ g.GYaad2 || g.GYaad ? (g.GYaad2 || g.GYaad | number : '1.0-2') : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ g.Tot || 0 }}</td>
							<td class="report-table__table-body-name">{{ g.Tot && (g.GYaad2 || g.GYaad) ? (g.Tot / (g.GYaad2 || g.GYaad) | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ g.Ltot || 0 }}</td>
							<td class="report-table__table-body-name">{{ g.Tot && g.Ltot ? ((g.Tot / g.Ltot) * 100 | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</ng-template>
	</div>
</section>

<div class="popup" *ngIf="shouldShowEditPopup" style="z-index: 555">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="closeEditPopup()" style="width: 30px; height: 30px; background-color: black"></div>
		<div class="product-detail__wrapper">
			<form [formGroup]="goalsForm" class="popup-form" (submit)="handleGoalsSubmit()">
				<div class="product-detail__wrapper__send-changes-block">
					<span *ngIf="goalsForm.invalid && goalsForm.touched" class="span-error">אחד הערכים אינו תקין או חסר</span>
					<span *ngIf="isSendingInfo" class="span-sending">{{ infoSendingMessage }}</span>
					<button class="product-detail__wrapper__send-changes-block__button" [disabled]="goalsForm.invalid">אשר יעדים</button>
					<button class="product-detail__wrapper__send-changes-block__button cancel" (click)="closeEditPopup()">בטל</button>
				</div>
				<div class="grid rtl gap">
					<div class="flex gap justify-align-center">
						<input
							[formControl]="percentageInputControl"
							max="99"
							min="1"
							(input)="affectAllInputs('percentage')"
							type="number"
							name="percentage"
							id="percentage"
							placeholder="לפי אחוז למכירות אשתקד"
						/>
					</div>
					<div class="flex gap justify-align-center">
						<input [formControl]="yearlyInputControl" min="12" (input)="affectAllInputs('yearly')" type="number" name="yearly" id="yearly" placeholder="לפי יעד שנתי" />
					</div>
				</div>

				<table>
					<thead>
						<tr>
							<th #TableStart>חודשים</th>
							<th>יעד</th>
							<th>מכירות אשתקד</th>
							<th *ngIf="!isFirstTime">יעד מעודכן</th>
						</tr>
					</thead>
					<ng-container [ngSwitch]="pageMode$ | async">
						<tbody *ngSwitchCase="'agents'">
							<tr *ngFor="let month of Months; let i = index">
								<td>{{ month }}</td>
								<td *ngIf="selectedToEdit[i].Yaad">{{ selectedToEdit[i].Yaad | number : '1.0-2' }}</td>
								<td *ngIf="!selectedToEdit[i].Yaad"><input type="number" [formControlName]="selectedToEdit[i].Month" /></td>
								<td>{{ selectedToEdit[i].Ltot | number : '1.0-2' }}</td>
								<td *ngIf="!isFirstTime"><input type="number" [placeholder]="selectedToEdit[i].Yaad2 ?? selectedToEdit[i].Yaad" [formControlName]="selectedToEdit[i].Month" /></td>
							</tr>

							<tr>
								<td>סה"כ</td>
								<td class="bold">{{ totalYaad | number : '1.0-2' }}</td>
								<td class="bold">{{ totalLastYearSales | number : '1.0-2' }}</td>
								<td class="bold" *ngIf="!isFirstTime">{{ totalYaad2 | number : '1.0-2' }}</td>
							</tr>
							<tr></tr>
						</tbody>

						<tbody *ngSwitchCase="'clients'">
							<tr *ngFor="let month of Months; let i = index">
								<td>{{ month }}</td>
								<td *ngIf="selectedToEdit[i].Yaad1">{{ selectedToEdit[i].Yaad1 | number : '1.0-2' }}</td>
								<td *ngIf="!selectedToEdit[i].Yaad1"><input placeholder="0" type="number" [formControlName]="selectedToEdit[i].Mm1" /></td>
								<td>{{ selectedToEdit[i].Ltot | number : '1.0-2' }}</td>
								<td *ngIf="!isFirstTime"><input type="number" [placeholder]="selectedToEdit[i].Yaad2 || 0" [formControlName]="selectedToEdit[i].Mm1" /></td>
							</tr>

							<tr>
								<td>סה"כ</td>
								<td class="bold">{{ totalYaad | number : '1.0-2' }}</td>
								<td class="bold">{{ totalLastYearSales | number : '1.0-2' }}</td>
								<td class="bold" *ngIf="!isFirstTime">{{ totalYaad2 | number : '1.0-2' }}</td>
							</tr>
							<tr></tr>
						</tbody>
						<tbody *ngSwitchCase="'groups'">
							<tr *ngFor="let month of Months; let i = index">
								<td>{{ month }}</td>
								<td *ngIf="selectedToEdit[i].GYaad">{{ selectedToEdit[i].GYaad | number : '1.0-2' }}</td>
								<td *ngIf="!selectedToEdit[i].GYaad"><input placeholder="0" type="number" [formControlName]="selectedToEdit[i].Mm" /></td>
								<td>{{ selectedToEdit[i].Ltot | number : '1.0-2' }}</td>
								<td *ngIf="!isFirstTime"><input type="number" [placeholder]="selectedToEdit[i].GYaad2 || 0" [formControlName]="selectedToEdit[i].Mm" /></td>
							</tr>

							<tr>
								<td>סה"כ</td>
								<td class="bold">{{ totalYaad | number : '1.0-2' }}</td>
								<td class="bold">{{ totalLastYearSales | number : '1.0-2' }}</td>
								<td class="bold" *ngIf="!isFirstTime">{{ totalYaad2 | number : '1.0-2' }}</td>
							</tr>
							<tr></tr>
						</tbody>
					</ng-container>
				</table>
			</form>
		</div>
	</div>
</div>
<!-- end report table markup -->
