import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'receiptType',
})
export class ReceiptTypePipe implements PipeTransform {
	transform(value: 1 | 2 | 3): string {
		switch (value) {
			case 1:
				return 'מזומן';
			case 2:
				return "צ'ק";
			case 3:
				return 'חוב';

			default:
				return 'לא ידוע';
		}
	}
}
