import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NestedTreeControl } from '@angular/cdk/tree';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject, combineLatest, fromEvent, map, merge, Observable, of, startWith, Subject, Subscription, takeUntil } from 'rxjs';
import { ClientGoals, ClientGoalsDisplay, GroupGoals, GroupGoalsDisplay } from 'src/app/Interfaces/goals';
import { ToNormalNumberPipe } from 'src/app/Pipes/to-normal-number.pipe';
import { StoreService } from 'src/app/services/store.service';
import { UtilService } from 'src/app/services/util.service';
import { monthsHeb } from 'src/app/Utility/constants';
import { normalizeNumber } from 'src/app/Utility/functions';
import { environment } from 'src/environments/environment';
import { goals as dummyGoals } from './dummy-group-goals';

const toNormalNumber = ToNormalNumberPipe.prototype.transform;

const goalsValues = ['currentYearGoal', 'total', 'advancementPercentage', 'lastYearTotal', 'growthPercentage'] as const;
const agentsValues = ['AgentCode', 'AgentName', ...goalsValues] as const;
const clientsValues = ['Cl', 'currentYearGoal', ...goalsValues] as const;
const groupsValues = ['GroupId', 'GroupName', 'AgentId', 'AgentName', ...goalsValues] as const;

const pageMode = ['agents', 'clients', 'groups'] as const;

@Component({
	selector: 'app-admin-goals',
	templateUrl: './admin-goals.component.html',
	styleUrls: ['./admin-goals.component.scss'],
})
export class AdminGoalsComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _acr: ActivatedRoute, private _util: UtilService) {}

	goalsArrayDisplay;

	goals = [];
	goals$ = new BehaviorSubject(this.goals);
	goalsClientFormatted: ClientGoalsDisplay[] = [];
	goalsAgent$ = new BehaviorSubject(this.goals);
	goalsClient$ = new BehaviorSubject<ClientGoalsDisplay[]>(this.goals);
	goalsGroup$ = new BehaviorSubject<GroupGoalsDisplay[]>(this.goals);
	year = new Date().getFullYear();
	shouldShowEditPopup = false;
	goalsForm: FormGroup;
	windowWidth;
	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription = new Subscription();

	selectedDrill$ = new BehaviorSubject<{ type: typeof pageMode[number]; field1: string; field2?: string }>(null);
	goalsDrilled$ = combineLatest([this.goals$, this.selectedDrill$]).pipe(
		map(([goals, drill]) =>
			goals.filter(goal => goal[drill.type === 'groups' ? 'AgentId' : 'AgentCode'] === drill.field1 && (!drill.field2 || goal[drill.type === 'groups' ? 'GroupId' : 'Cl'] === drill.field2)),
		),
	);

	selectedToEdit;
	totalYaad = 0;
	totalLastYearSales = 0;
	totalYaad2 = 0;

	isFirstTime = true;

	isSendingInfo = false;
	infoSendingMessage = 'מעדכן';

	agentsToGet = [];

	isAgents = location.pathname.includes('agents');
	isClients = location.pathname.includes('clients');
	isGroups = location.pathname.includes('groups');
	isAdmin = location.pathname.includes('admin');

	Months = monthsHeb;

	Loading = true;

	searchControl = new FormControl<string>('', { nonNullable: true });

	destroy$ = new Subject<void>();

	percentageInputControl = new FormControl<number>(null, [Validators.min(1), Validators.max(99)]);
	yearlyInputControl = new FormControl<number>(null, [Validators.min(12)]);

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	pageMode$ = new BehaviorSubject<typeof pageMode[number]>('agents');

	main = true;

	hasMultipleCompanies = false;

	form = this._fb.group({
		company: [1],
	});

	companies: { CompanyName: string; Company: number }[] = [];
	goalsPerCompany$ = new BehaviorSubject<{ Company: number; Goals: any[] }[]>([]);
	goalsAgentDisplay$ = combineLatest([this.form.controls.company.valueChanges.pipe(startWith(1)), this.goalsAgent$]).pipe(
		map(([cm, goals]) => {
			console.log(goals);
			return this.hasMultipleCompanies ? goals.filter(arr => +arr.cm === +cm) : goals;
		}),
	);

	ngOnInit(): void {
		this.isAgents = this._acr.snapshot.queryParamMap.get('type') === 'agents';
		this.isClients = this._acr.snapshot.queryParamMap.get('type') === 'clients';
		this.isGroups = this._acr.snapshot.queryParamMap.get('type') === 'groups' || location.pathname.includes('group-goals');

		this.pageMode$.next(this._acr.snapshot.queryParamMap.get('type') as typeof pageMode[number]);

		if (this.isGroups) {
			this.pageMode$.next('groups');
		}

		if (this._ss.CustomerInformation === null) {
			const CI = localStorage.getItem('CustomerInformation');
			if (CI === null) {
				alert('קרתה שגיאה בקריאת נתונים');
				history.back();
				return;
			}

			this._ss.CustomerInformation = JSON.parse(decompress(localStorage.getItem('CustomerInformation')));
		}

		if ((this._ss.CustomerInformation.HasMultipleCompanies > 0 && this.isAgents) || environment.development) {
			this.hasMultipleCompanies = true;
		}

		const [username, agentid] = ['UserName', 'AgentId'].map(key => localStorage.getItem(key));

		if (username === null || agentid === null) {
			this.router.navigate(['login']);
			return;
		}

		this._ss.UserNameConnected = username;
		this._ss.AgentIdConnected = agentid;

		this.windowWidth = window.innerWidth;
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.pipe(takeUntil(this.destroy$)).subscribe((evt: UIEvent) => {
			if (evt.currentTarget instanceof Window) {
				this.windowWidth = evt.currentTarget.innerWidth;
			}
		});

		if (!this.isAdmin || this._ss.CustomerInformation.Submanagers !== '1') {
			this.getGoalsAndUpdate();
			return;
		}

		if (this._ss.agentsOfSubmanager.length) {
			this.agentsToGet = this._ss.agentsOfSubmanager;
			this.getGoalsAndUpdate();
			return;
		}

		this._ss.getAdminAgents().subscribe({
			next: res => {
				if (typeof res == 'string') {
					alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
					return;
				}

				this.agentsToGet = res.recordset.map(obj => obj.Agent);
				this.agentsToGet.push(this._ss.AgentIdConnected);

				this.getGoalsAndUpdate();
			},
			error: err => {
				alert('שגיאת שרת');
			},
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleArrowPress() {
		if (!this.main) {
			this.main = !this.main;
			this.selectedDrill$.next(null);
			return;
		}

		history.back();
	}

	handleGroupClick(row: GroupGoalsDisplay) {
		// if (this.isAdmin) return;
		this.selectedDrill$.next({ type: 'groups', field1: row.AgentId, field2: row.GroupId });
		this.main = !this.main;
	}

	handleEditButton(identifier: string) {
		document.body.classList.add('no-scroll');

		let column = '';
		if (this.isAgents) {
			column = 'AgentCode';
		}
		if (this.isClients) {
			column = 'Cl';
		}
		if (this.isGroups) {
			column = 'AgentId';
		}

		this.selectedToEdit = this.goals.filter(gl => gl[column] == identifier);

		if (this.hasMultipleCompanies) {
			this.selectedToEdit = this.selectedToEdit.filter(arr => arr.cm === +this.form.controls.company.value);
		}

		this.totalLastYearSales = this.selectedToEdit.reduce((a, b) => a + b.Ltot, 0);
		this.totalYaad2 = this.selectedToEdit.reduce((a, b) => a + b.Yaad2, 0);
		this.shouldShowEditPopup = true;

		if (this.isAgents) {
			this.handleEditAgent();
			return;
		}

		if (this.isGroups) {
			this.handleEditAgent();
			return;
		}

		this.handleClientEdit();
	}

	handleEditButtonGroup(e: Event, agent: string, group: string) {
		e.stopPropagation();
		document.body.classList.add('no-scroll');

		this.selectedToEdit = this.goals.filter(gl => gl['AgentId'] == agent && gl['GroupId'] == group);

		this.totalLastYearSales = this.selectedToEdit.reduce((a, b) => a + b.Ltot, 0);
		this.totalYaad2 = this.selectedToEdit.reduce((a, b) => a + b.Yaad2, 0);
		this.shouldShowEditPopup = true;

		this.handleEditGroup();
	}

	handleEditAgent() {
		this.isFirstTime = this.selectedToEdit.filter(selected => selected.Year == this.year && selected.Yaad).length !== 12;

		// column name different so its inside a function
		this.totalYaad = this.selectedToEdit.reduce((a, b) => a + b.Yaad, 0);

		const controlsObject: Record<string, FormControl> = {};

		console.log(this.selectedToEdit[0].Month);

		for (const agent of this.selectedToEdit) {
			if (!agent.Yaad) {
				controlsObject[agent.Month] = new FormControl(['', [Validators.required, Validators.min(0)]]);
			} else {
				if (!this.isFirstTime) {
					controlsObject[agent.Month] = new FormControl([null, [Validators.min(0)]]);
				}
			}
			this.goalsForm = this._fb.group(controlsObject);
		}

		this.setupListeners();
	}

	handleEditGroup() {
		console.log(this.selectedToEdit);
		const selectedToEdit = this.selectedToEdit as GroupGoals[];
		this.isFirstTime = selectedToEdit.filter(selected => selected.Yy == this.year && selected.GYaad).length !== 12;

		// column name different so inside function
		this.totalYaad = selectedToEdit.reduce((a, b) => a + b.GYaad, 0);

		const controlsObject: Record<string, FormControl> = {};

		console.log(selectedToEdit);

		for (const client of selectedToEdit) {
			if (!client.GYaad) {
				controlsObject[client.Mm] = new FormControl([null, [Validators.required, Validators.min(0)]]);
			} else {
				if (!this.isFirstTime) {
					controlsObject[client.Mm] = new FormControl([null, [Validators.min(0)]]);
				}
			}
			this.goalsForm = this._fb.group(controlsObject);
		}

		this.goalsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: null | number) => {
			if (this.isFirstTime) {
				this.totalYaad = Object.values(val).reduce((prev: number, curr: null | number) => prev + +curr, 0);
				return;
			}
			this.totalYaad2 = Object.values(val).reduce(
				(prev: number, curr: null | number, i: number) => prev + +(curr || (this.selectedToEdit as GroupGoals[])[i].GYaad2 || (this.selectedToEdit as GroupGoals[])[i].GYaad),
				0,
			);

			return;
		});
	}

	handleClientEdit() {
		console.log(this.selectedToEdit);
		this.isFirstTime = (this.selectedToEdit as ClientGoals[]).filter(selected => selected.Yy1 == this.year && selected.Yaad1).length !== 12;

		// column name different so inside function
		this.totalYaad = this.selectedToEdit.reduce((a, b) => a + b.Yaad1, 0);

		const controlsObject: Record<string, FormControl> = {};

		console.log(this.selectedToEdit);

		for (const client of this.selectedToEdit) {
			if (!client.Yaad1) {
				controlsObject[client.Mm1] = new FormControl([null, [Validators.required, Validators.min(0)]]);
			} else {
				if (!this.isFirstTime) {
					controlsObject[client.Mm1] = new FormControl([null, [Validators.min(0)]]);
				}
			}
			this.goalsForm = this._fb.group(controlsObject);
		}

		this.goalsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: null | number) => {
			if (this.isFirstTime) {
				this.totalYaad = Object.values(val).reduce((prev: number, curr: null | number) => prev + +curr, 0);
				return;
			}
			this.totalYaad2 = Object.values(val).reduce((prev: number, curr: null | number, i: number) => prev + +(curr || this.selectedToEdit[i].Yaad2 || this.selectedToEdit[i].Yaad1), 0);

			return;
		});
	}

	async handleGoalsSubmit() {
		if (this.pageMode$.value === 'groups') {
			this.handleGroupGoalsSubmit();
			return;
		}
		this.isSendingInfo = true;

		const value = Object.entries(this.goalsForm.value);

		for (const val of value) {
			if (isFinite(+val[1]) && val[1] !== null) {
				try {
					let custServer = this._ss.CustomerInformation.CustServer;
					let url = this.isFirstTime ? '/mobil/admin-goals' : '/mobil/admin-goals-update';
					let identifier = 'AgentCode';
					if (this.isClients) {
						url += `-clients/${this.year}`;
						identifier = 'Cl';
					}
					custServer = custServer + url;
					let company = '';

					if (this.hasMultipleCompanies) {
						company = `/${this.form.controls.company.value}`;
					}

					const res = await fetch(`${custServer}/${val[0].trim()}/${val[1]}/${this.selectedToEdit[0][identifier]}${company}`);
					const data = await res.json();
					if (!data?.rowsAffected[0]) throw Error();
					this.infoSendingMessage = this.infoSendingMessage.length < 9 ? this.infoSendingMessage + '.' : 'מעדכן';
				} catch (error) {
					this.isSendingInfo = false;
					alert('אירעה שגיאה בעת שליחת נתונים, אנא נסו שנית מאוחר יותר.');
					return;
				}
			}
		}

		this.infoSendingMessage = 'נתונים עודכנו בהצלחה! ניתן לסגור את החלון כעת.';
		this.getGoalsAndUpdate();
	}

	async handleGroupGoalsSubmit() {
		if (this.goalsForm.invalid) {
			alert('אחד או יותר מהשדות אינו תקין, אנא נסו שנית.');
			this.goalsForm.markAllAsTouched();
			return;
		}
		this.isSendingInfo = true;
		this.infoSendingMessage = 'מעדכן...';
		const year = this.year;

		const changesArray = Object.entries(this.goalsForm.value).map(([key, value]) => {
			const obj = this.selectedToEdit.find(obj => obj.Mm == key);
			const field = this.isFirstTime ? 'GYaad' : 'GYaad2';
			return { Mm: obj.Mm, Yy: year, value, field };
		});

		this._ss.updateGroupGoals({ changesArray, AgentId: this.selectedToEdit[0].AgentId, GroupId: this.selectedToEdit[0].GroupId }).subscribe({
			next: res => {
				if (typeof res === 'string') {
					alert('חלה תקלה בעדכון נתונים, נסו שנית');
					return;
				}

				this.infoSendingMessage = 'נתונים עודכנו בהצלחה! ניתן לסגור את החלון כעת.';
				this.getGoalsAndUpdate();
			},
			error: err => {
				this.isSendingInfo = false;
				alert('אירעה שגיאה בעת שליחת נתונים, אנא נסו שנית מאוחר יותר.');
			},
		});
	}

	async getGoalsAndUpdate() {
		if (this.isAgents) {
			await this.handleGetCompanies();
			if (this.hasMultipleCompanies) {
				this.getAgentGoals(this.companies.length);
				return;
			}
			this.getAgentGoals();
			return;
		}

		if (this.isGroups) {
			this.getGroupsGoals();
			return;
		}

		this.getClientsGoals();
	}

	async handleGetCompanies() {
		if (!this.hasMultipleCompanies) return;
		return await new Promise<void>((resolve, reject) => {
			this._ss.GetCompaniesWithoutAgent().subscribe({
				next: res => {
					if (typeof res === 'string') {
						alert('חלה תקלה בהבאת חברות. נסו שנית');
						return;
					}

					this.companies = res.recordset;

					this.form.controls.company.setValue(res.recordset[0].Company);

					resolve();
				},
				error: err => {
					if (err.status !== 404) {
						alert('שגיאת שרת');
						return;
					}
					console.log('couldnt get all companies, trying to get companies of agent');
					this._ss.GetComppanys().subscribe({
						next: res => {
							if (typeof res === 'string') {
								alert('חלה תקלה בהבאת חברות. נסו שנית');
								return;
							}

							this.companies = res.recordset;

							this.form.controls.company.setValue(res.recordset[0].Company);

							resolve();
						},
						error: err => {
							alert('שגיאת שרת');
						},
					});
				},
			});
		});
	}

	getAgentGoals(cm?: number) {
		this._ss.getAgentsGoals(this.agentsToGet, cm).subscribe({
			next: res => {
				if (typeof res === 'string') {
					alert('לא ניתן להביא נתונים. נסו שנית מאוחר יותר.');
					return history.back();
				}
				this.goals = res.recordset;
				this.goals$.next(this.goals);
				const arr = this.getAgentsGoalsFormatted();

				this.Loading = false;
				this.goalsAgent$.next(arr);
			},
			error: err => {
				alert('שגיאת תקשורת, אנא נסה שנית מאוחר יותר.');
				history.back();
			},
		});
	}

	getGroupsGoals() {
		if (this._ss.isLocalhost) {
			this.goals = dummyGoals;
			this.goals$.next(this.goals);
			const arr = this.getGroupsGoalsFormatted();

			this.Loading = false;
			this.goalsGroup$.next(arr);
			return;
		}
		const callbacks = {
			next: res => {
				if (typeof res === 'string') {
					alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
					return history.back();
				}
				this.goals = res.recordset as GroupGoals[];
				this.goals$.next(this.goals);
				const arr = this.getGroupsGoalsFormatted();

				this.Loading = false;
				this.goalsGroup$.next(arr);
			},
			error: err => {
				alert('שגיאת תקשורת, אנא נסה שנית מאוחר יותר.');
				history.back();
			},
		};
		if (!this.isAdmin) {
			this._ss.getGoalsGroups(this._ss.AgentIdConnected).subscribe(callbacks);
			return;
		}
		if (!this.agentsToGet.length) {
			this._ss.getGoalsGroups().subscribe(callbacks);
			return;
		}

		this._ss.getGoalsGroupsCustomAgents(this.agentsToGet).subscribe(callbacks);
	}

	getAgentsGoalsFormatted() {
		const goals = this.goals
			.reduce((prev, curr) => {
				const agent = prev.find(arr => arr?.AgentCode == curr.AgentCode && arr?.GroupId == curr.GroupId && arr.cm === curr.cm);
				if (agent) {
					agent.currentYearGoal += curr.Year == this.year ? curr.Yaad2 || curr.Yaad : null;
					agent.previousYearGoal += curr.Year == this.year ? null : curr.Yaad2 || curr.Yaad;
					agent.total += curr.Tot || 0;
					agent.lastYearTotal += curr.Ltot || 0;
				} else {
					prev.push({
						AgentCode: curr.AgentCode,
						AgentName: curr.AgentName,
						currentYearGoal: curr.Year == this.year ? curr.Yaad2 || curr.Yaad : 0,
						previousYearGoal: curr.Year == this.year ? 0 : curr.Yaad2 || curr.Yaad,
						total: curr.Tot || 0,
						lastYearTotal: curr.Ltot || 0,
						cm: curr.cm,
					});
				}
				return prev;
			}, [])
			.map(gl => {
				if (gl.total && gl.currentYearGoal) {
					gl.advancementPercentage = (gl.total / gl.currentYearGoal) * 100;
				}
				if (gl.total && gl.lastYearTotal) {
					gl.growthPercentage = (gl.total / gl.lastYearTotal) * 100;
				}
				return gl;
			});

		return goals;
	}

	getGroupsGoalsFormatted() {
		return (this.goals as GroupGoals[])
			.reduce((prev, curr) => {
				const agent = prev.find(arr => arr?.AgentId == curr.AgentId && arr?.GroupId == curr.GroupId);
				if (agent) {
					agent.currentYearGoal += curr.GYaad2 || curr.GYaad;
					agent.previousYearGoal += curr.GYaad2 || curr.GYaad;
					agent.total += curr.Tot || 0;
					agent.lastYearTotal += curr.Ltot || 0;
				} else {
					prev.push({
						AgentId: curr.AgentId,
						AgentName: curr.AgentName,
						GroupId: curr.GroupId,
						GroupName: curr.GroupName,
						currentYearGoal: curr.GYaad2 || curr.GYaad,
						previousYearGoal: curr.GYaad2 || curr.GYaad,
						total: curr.Tot || 0,
						lastYearTotal: curr.Ltot || 0,
					});
				}
				return prev;
			}, [])
			.map(gl => {
				if (gl.total && gl.currentYearGoal) {
					gl.advancementPercentage = (gl.total / gl.currentYearGoal) * 100;
				}
				if (gl.total && gl.lastYearTotal) {
					gl.growthPercentage = (gl.total / gl.lastYearTotal) * 100;
				}
				return gl;
			});
	}

	getClientsGoals() {
		this._ss.getGoalsClients().subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					return history.back();
				}

				this.goals = res.recordset;
				this.goals$.next(this.goals);

				this.goalsClientFormatted = Object.values(
					res.recordset.reduce((prev: { [key: string]: ClientGoalsDisplay }, curr) => {
						const { Cl, Yy1, Cn, Tot, Ltot, Yaad1, Yaad2 } = curr;

						const client = prev[Cl];
						if (client) {
							client.currentYearGoal += Yy1 == this.year ? +Yaad2 || +Yaad1 : 0;
							client.previousYearGoal += Yy1 == this.year ? 0 : +Yaad2 || +Yaad1;
							client.total += Tot || 0;
							client.lastYearTotal += Ltot || 0;
							return prev;
						}

						prev[Cl] = {
							Cl,
							Cn,
							total: Tot,
							lastYearTotal: Ltot,
							currentYearGoal: Yy1 == this.year ? +Yaad2 || +Yaad1 : 0,
							previousYearGoal: Yy1 == this.year ? 0 : +Yaad2 || +Yaad1,
							advancementPercentage: 0,
							growthPercentage: 0,
						};

						return prev;
					}, {}),
				).map((gl: ClientGoalsDisplay) => {
					const { total, currentYearGoal, lastYearTotal } = gl;
					if (total && currentYearGoal) {
						gl.advancementPercentage = (total / currentYearGoal) * 100;
					}

					if (total && lastYearTotal) {
						gl.growthPercentage = (total / lastYearTotal) * 100;
					}

					return gl;
				});

				console.log(this.goalsClientFormatted);
				this.Loading = false;
				this.goalsClient$.next(this.goalsClientFormatted);
			},
		});
	}

	search() {
		const page = this.pageMode$.value;
		// if (['clients', 'groups'].includes(page)) this.virtualScroll.scrollToIndex(0);
		if (this.isClients) this.virtualScroll.scrollToIndex(0);

		const value = this.searchControl.value?.toLowerCase?.();

		if (!value) {
			if (page === 'clients') {
				this.goalsClient$.next(this.goalsClientFormatted);
				return;
			}
			if (page === 'groups') {
				this.goalsGroup$.next(this.getGroupsGoalsFormatted());
				return;
			}

			this.goalsAgent$.next(this.getAgentsGoalsFormatted());
		} else {
			const pattern = () => new RegExp(value.replace(/[\/*\+\-\?,]/g, ''), 'i');

			if (page === 'clients') {
				this.goalsClient$.next(this.goalsClientFormatted.filter(client => pattern().test(client.Cn || '') || pattern().test(client.Cl || '')));
				return;
			}

			if (page === 'groups') {
				this.goalsGroup$.next(this.getGroupsGoalsFormatted().filter(group => pattern().test(group.GroupName || '') || pattern().test(group.AgentName || '')));
				return;
			}

			this.goalsAgent$.next(this.getAgentsGoalsFormatted().filter(agent => pattern().test(agent.AgentName || '')));
		}
	}

	SearchClear() {
		this.searchControl.reset();
		this.search();
	}

	affectAllInputs(by: 'percentage' | 'yearly') {
		const propertyName = this.isAgents ? 'Month' : this.isGroups ? 'Mm' : 'Mm1';
		if (by === 'percentage') {
			this.yearlyInputControl.reset();

			const val = this.percentageInputControl.value;

			if (this.percentageInputControl.invalid) return;

			for (const selected of this.selectedToEdit) {
				this.goalsForm.controls[selected[propertyName]].setValue(this._util.formatAsNormalNumber(selected.Ltot * (1 + val / 100), false));
			}
		}

		if (by === 'yearly') {
			this.percentageInputControl.reset();

			const val = this.yearlyInputControl.value;

			if (isNaN(+val) || val < 12) return;

			for (const selected of this.selectedToEdit) {
				this.goalsForm.controls[selected[propertyName]].setValue(this._util.formatAsNormalNumber(val / 12, false));
			}
		}
	}

	closeEditPopup() {
		this.shouldShowEditPopup = this.isSendingInfo = false;

		this.selectedToEdit = null;

		document.body.classList.remove('no-scroll');

		this.yearlyInputControl.reset();
		this.percentageInputControl.reset();
	}

	setupListeners() {
		this._util.pipeASubjectDestoryer(this.goalsForm.valueChanges, this.destroy$).subscribe(() => {
			const inputs = document.querySelectorAll('input[type="number"]');
			if (!inputs.length) return;
			for (const input of [...inputs]) {
				const inp = input as HTMLInputElement;
				inp.value &&= toNormalNumber(inp.value);
			}
		});
	}

	translateForSortAgent(value: typeof agentsValues[number]) {
		this.translateForSort({ value, screen: 'isAgents' });
	}

	translateForSortClient(value: typeof clientsValues[number]) {
		this.translateForSort({ value, screen: 'isClients' });
	}

	translateForSortGroup(value: typeof groupsValues[number]) {
		this.translateForSort({ value, screen: 'isGroups' });
	}

	translateForSort(
		payload: { value: typeof agentsValues[number]; screen: 'isAgents' } | { value: typeof clientsValues[number]; screen: 'isClients' } | { value: typeof groupsValues[number]; screen: 'isGroups' },
	) {
		let i: number, arrayToSort: any[];
		const { value, screen } = payload;

		switch (screen) {
			case 'isAgents':
				i = agentsValues.findIndex(arr => arr === value);
				arrayToSort = this.goalsAgent$.value;
				break;
			case 'isClients':
				i = clientsValues.findIndex(arr => arr === value);
				arrayToSort = this.goalsClient$.value;
				break;
			case 'isGroups':
				i = groupsValues.findIndex(arr => arr === value);
				arrayToSort = this.goalsGroup$.value;
				break;

			default:
				break;
		}

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);

		switch (screen) {
			case 'isAgents':
				this.goalsAgent$.next([...arrayToSort]);
				break;
			case 'isClients':
				this.goalsClient$.next([...arrayToSort]);
				break;
			case 'isGroups':
				this.goalsGroup$.next([...arrayToSort]);
				break;

			default:
				break;
		}
	}
}
