import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, ViewChildren } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { decompress } from 'lz-string';
// import jspdf from 'jspdf';
// import html2canvas from 'html2canvas';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, fromEvent, map, Observable, startWith, Subject, Subscription, takeUntil } from 'rxjs';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { environment } from 'src/environments/environment';
import { TextService } from 'src/app/services/text.service';
import { MarkerInfo, TeudaMarkerPopupData } from 'src/app/services/marker.service';
import { UtilService } from 'src/app/services/util.service';
import { DeviceInfoService, TABLET_RESOLUTION_CUTOFF } from 'src/app/services/device-info.service';
import { Users } from 'src/app/Interfaces/users';

const TEUDA_TYPES = {
	'11': 'הזמנה',
	'170': 'הזמנה',
	'171': 'הזמנה',
	'172': 'הזמנה',
	'74': 'ת. החזרה',
	'7': 'הצעת מחיר',
	'88': 'הזמנה משריון',
	'17': 'הזמנת רכש',
} as const;

@Component({
	selector: 'app-mobilitydashbord',
	templateUrl: './mobilitydashbord.component.html',
	styleUrls: ['./mobilitydashbord.component.scss'],
})
export class MobilitydashbordComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, private router: Router, private _fb: FormBuilder, public _text: TextService, private _util: UtilService, public _device: DeviceInfoService) {}

	form: FormGroup;
	Teudot;
	teudotOriginalResponse;
	AllTeudotReset;
	TeudotEach;
	TeudotEachString;
	Main = true;
	Each = false;
	Last = false;
	Loading = true;
	LoadingSeeTeuda = false;
	SeeTeudaLoaded = false;
	Agents;
	TeudotTableEach;
	LoaingConfirm = false;
	TotalArrayChecked = 0;
	TotalArray = 0;
	ArrayChecked = [];
	HasMultipleCompanys;
	SeeTrucks = '0';
	Admin;
	LocalSaveTrucksSent = [];
	LocalSaveTrucksSentHelp;

	AgentsForAdminArray;
	FilterTeudot = [];

	showLogoutPopup = false;

	today = new Date();
	windowWidth = innerWidth;
	movingOffset = { x: 0, y: 0 };
	document = document;
	history = history;

	agentsToGet = [];

	allTeudotAgents = [];

	LoadingBack = false;

	showFilterPopup = false;

	minDate = new Date();

	doctypes: string[] = [];
	agents: string[] = [];
	statuses: string[] = [];

	destroy$: Subject<void> = new Subject();

	allClients: AllClients[] = [];

	scrollY = 0;

	isB2BPage = location.pathname.includes('B2B');
	isAutoluxb2bDashboard = location.pathname.includes('mobilitydashbord-B2B-autolux');
	isLikutManager = location.pathname.includes('likut');
	isLoadingDistributionManager = location.pathname.includes('truck-distribution-or-loading');
	isRegularDashboard = !this.isB2BPage && !this.isLikutManager && !this.isAutoluxb2bDashboard;
	isActualAdmin = false;

	isNonProduction = !environment.production;

	hasTeudaProfitColumn = false;

	hasTeudaSupplyDateColumn = false;
	hasTeudaDeliveryCityColumn = false;

	autoluxDisplayArray: any[] = [];
	autoluxDisplayArray$ = new BehaviorSubject(this.autoluxDisplayArray);

	tableHeight: string | number = '';

	canDeleteTeudaInOneQueryAndPermanently = false;

	hasClientIdColumn = false;
	clientIdKey = '';

	coordinates = [];
	shouldMapShow = false;

	isFullscreen = false;

	isDesktop = this.windowWidth > 1099;

	headerSearchForm = this._fb.nonNullable.group({
		TeudaNewNum: [''],
		Client_Name: [''],
		clientID: [''],
		AgentID: [''],
		TeudaDate: [''],
		// supplyDate: [''],
		TeudaTypeName: [''],
		Status: [''],
		TeudaDeliveryCity: [''],
		CompanyName: [''],
		TruckNum: [''],
		TuedaErpDocNum: [''],
	});

	filters = [
		{ title: 'סוכנים', formControlName: 'Agent', controlValues: [] },
		{ title: 'סטטוס', formControlName: 'Status', controlValues: [] },
		{ title: 'סוג תעודה', formControlName: 'DocType', controlValues: [] },
	];

	allAgentsRecord: Record<string, Users> = {};
	isTabletAndUpResolution = this._device.windowWidth$.value >= TABLET_RESOLUTION_CUTOFF;

	ngOnInit(): void {
		this._ss.OneClientInfoDebt = null;

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			} else {
				this.router.navigate(['home']);
				return;
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
			return;
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');

			if (this.Admin && (this.Admin.includes('manager') || this.Admin === 'true')) {
				this.isActualAdmin = true;
			}
		}

		//TODO refactor to one if statement
		if (
			this.isActualAdmin ||
			this._ss.CustomerInformation.AgentSeeDashboard === '1' ||
			(this._ss.CustomerInformation.AgentSeeDashboard === '2' && this.Admin && this.Admin.includes('dashboard')) ||
			(['b2bL', 'b2bLM'].includes(this.Admin) && this.isAutoluxb2bDashboard)
		) {
		} else {
			this.router.navigate(['clients']);
			return;
		}

		if (localStorage.getItem('CustNum') !== null) {
			this._ss.CustNum = localStorage.getItem('CustNum');
		}

		if (this._ss.CustomerInformation.CustNum === '11111' || this._ss.CustNum === '11111' || this._ss.CustomerInformation.DashboardSeeTrucks === '1') {
			this.SeeTrucks = '1';
		}

		const allClientsFromLS = localStorage.getItem('AllClients');

		if (allClientsFromLS) {
			this.allClients = JSON.parse(decompress(allClientsFromLS));
		}

		if (localStorage.getItem('LocalSaveTrucksSent') !== null) {
			this.LocalSaveTrucksSentHelp = localStorage.getItem('LocalSaveTrucksSent');
			this.LocalSaveTrucksSentHelp = JSON.parse(this.LocalSaveTrucksSentHelp);
			this.LocalSaveTrucksSent = this.LocalSaveTrucksSentHelp;
		}

		this.form = this._fb.group({
			Month: ['', Validators.required],
			Day: ['', Validators.required],
			AgentChosen: ['', Validators.required],
			Truck: ['', [Validators.required, Validators.minLength(6)]],
			TruckAll: ['', [Validators.required, Validators.minLength(6)]],
			TeudaQuantity: [0, [Validators.required, Validators.min(0)]],
			TeudaPackQuantity: [0, [Validators.required, Validators.min(0)]],
			TeudaBonusQuantity: [0, [Validators.required, Validators.min(0)]],
			TeudaItemDiscount: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
			TeudaPrice: [0, [Validators.required, Validators.min(0)]],
			Search: [''],
			FromDate: ['', Validators.required],
			ToDate: ['', [Validators.required, Validators.min(this.minDate.getTime())]],
			DocType: [null],
			Agent: [null],
			Status: [null],
		});

		// TODO - check if this is needed, window width is changed only in development
		fromEvent(window, 'resize')
			.pipe(takeUntil(this.destroy$))
			.subscribe((evt: any) => {
				this.windowWidth = evt.currentTarget.innerWidth;
			});

		window.scrollTo(0, 0);

		// check if has updated delete query
		this._ss.permanentlyDeleteTeudaFromBothOneStringAndTeuda().subscribe({
			next: res => {
				this.canDeleteTeudaInOneQueryAndPermanently = true;
			},
			error: () => {},
		});

		this._ss.GetAllAgents().subscribe({
			next: res => {
				if (typeof res === 'string') {
					return;
				}

				this.allAgentsRecord = res.recordset.reduce((acc, curr) => {
					acc[curr.AgentID] = curr;
					return acc;
				}, {});
			},
			error: err => {
				console.error(err);
			},
		});

		if (this._ss.CustomerInformation.Submanagers == '1' && !this.isLoadingDistributionManager) {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res == 'string') {
							alert('ישנה תקלה בשרת, לא ניתן להביא סוכנים עבור מנהל זה.');
							return;
						}

						this.agentsToGet = res.recordset.map(obj => obj.Agent);
						this.agentsToGet.push(this._ss.AgentIdConnected);

						this.getAllTeudot();
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getAllTeudot();
			}
		} else {
			this.getAllTeudot();
		}

		this.setupFormInteractivity();

		setTimeout(() => {
			const [header, filterRow] = document.querySelectorAll('header, .filter-row');

			let height = header.getBoundingClientRect().height;

			if (filterRow) {
				height += filterRow.getBoundingClientRect().height;
			}

			// zoom: 1.2
			if (this.windowWidth > 767 && this.windowWidth < 1099) {
				height *= 2.2;
			}
			this.tableHeight = height + 'px';
		}, 0);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.document.body.classList.remove('no-scroll');
	}

	shouldClientCardPopupShow = false;

	openClientCardPopup(teuda) {
		this.scrollY = scrollY;

		const client = this.allClients.find(ac => ac.Client_Name == teuda.Client_Name);

		if (client) {
			document.body.classList.add('no-scroll');
			this.shouldClientCardPopupShow = true;
			this._ss.OneClientInfo = client;

			setTimeout(() => {
				window.scrollTo({ top: this.scrollY });
			}, 0);
		}
	}

	companies = [];

	getAllTeudot() {
		if (this.isAutoluxb2bDashboard) {
			this.getAutoluxB2BDashboardInstead();
			return;
		}
		if (this.isB2BPage) {
			this.getB2BInstead();
			return;
		}

		this._ss.GetTeudotDashbord(this.agentsToGet).subscribe(
			async (res: any) => {
				if (typeof res === 'string') return;

				this.hasTeudaProfitColumn = res.recordset.some(teuda => teuda.TeudaProfit != undefined);
				this.hasTeudaDeliveryCityColumn = res.recordset.some(teuda => teuda.TeudaDeliveryCity);
				this.hasTeudaSupplyDateColumn = res.recordset.some(teuda => teuda.TeudaSupplyDate || teuda.SupplyDate);

				//Dont respect query order in index.js
				if (this._ss.CustomerInformation?.OrderByInDashboard !== '1') {
					res.recordset.sort((a, b) => {
						if (a.TeudaDate > b.TeudaDate) {
							return -1;
						}
						if (a.TeudaDate < b.TeudaDate) {
							return 1;
						}
						return 0;
					});
				}

				this.Teudot = this.teudotOriginalResponse = res.recordset;

				if (this.Teudot.length) {
					const clientid = Object.keys(this.Teudot[0]).find(t => t.toLowerCase() === 'clientid' || t.toLowerCase() === 'client_id');

					if (clientid) {
						this.hasClientIdColumn = true;
						this.clientIdKey = clientid;
					}
				}

				const coordinatesArray: MarkerInfo<TeudaMarkerPopupData>[] = [];
				const companiesSet = new Set();
				const doctypesSet = new Set<string>();
				const agentsSet = new Set<string>();
				const statusesSet = new Set<string>();

				for (let x = 0; x < this.Teudot.length; x++) {
					const teuda = this.Teudot[x];

					teuda.TeudaTypeName ||= TEUDA_TYPES[teuda.TeudaType];

					if (this.SeeTrucks === '1') {
						for (let y = 0; y < this.LocalSaveTrucksSent.length; y++) {
							if (teuda.TeudaNum === this.LocalSaveTrucksSent[y].TeudaNum) {
								if (teuda.TruckNum === '') {
									teuda.TruckNum = this.LocalSaveTrucksSent[y].TruckNum;
								}
							}
						}
					}

					companiesSet.add(teuda.Company);
					doctypesSet.add(teuda.TeudaTypeName);
					agentsSet.add(teuda.AgentID);
					statusesSet.add(teuda.Status);

					if (teuda.Longitude && teuda.Latitude && teuda.Longitude !== '0' && teuda.Latitude !== '0') {
						coordinatesArray.push({
							longitude: teuda.Longitude,
							latitude: teuda.Latitude,
							data: {
								TeudaCreateDate: this._util.formatAsDate(new Date(teuda.TeudaDate || teuda.TeudaCreateDate), { isTrueISO: true, onlyTime: false }),
								TeudaTotal: this._util.formatAsNormalNumber(teuda.TeudaTotalSum),
							},
						});
					}
				}

				if (coordinatesArray.length) {
					this.coordinates = coordinatesArray;
					this.shouldMapShow = true;
				}

				this.HasMultipleCompanys = companiesSet.size > 1;

				await new Promise<void>(resolve => {
					if (!this.HasMultipleCompanys) {
						resolve();
					}
					if (localStorage.getItem('ShowCompanys') !== null) {
						this.companies = JSON.parse(localStorage.getItem('ShowCompanys'));
						resolve();
					} else {
						this._ss.GetComppanys().subscribe((res: any) => {
							if (res.recordset && res.recordset.length > 0) {
								localStorage.setItem('ShowCompanys', JSON.stringify(res.recordset));
								resolve();
							}
						});
					}
				});

				console.log(this.companies);

				this.doctypes = [...doctypesSet];
				this.agents = [...agentsSet];
				this.statuses = [...statusesSet];

				this.updateFilterReference();

				this.AllTeudotReset = this.Teudot;

				const hasCompanies = this.companies.length > 0;

				for (let x = 0; x < this.Teudot.length; x++) {
					if (hasCompanies) {
						this.Teudot[x].CompanyName = this.companies.find(c => c.Company === this.Teudot[x].Company)?.CompanyName;
					}
					this.TotalArray += this.Teudot[x].TeudaTotalSum;
				}
			},
			err => {
				alert('שגיאת שרת');
			},
		);
	}

	getB2BInstead() {
		this._ss.getB2BDashboard().subscribe({
			next: (res: any) => {
				//Dont respect query order in index.js
				if (this._ss.CustomerInformation?.OrderByInDashboard !== '1') {
					res.recordset.sort((a, b) => {
						if (a.TeudaDate > b.TeudaDate) {
							return -1;
						}
						if (a.TeudaDate < b.TeudaDate) {
							return 1;
						}
						return 0;
					});
				}

				this.Teudot = this.teudotOriginalResponse = res.recordset;

				const companiesSet = new Set();
				const doctypesSet = new Set<string>();
				const agentsSet = new Set<string>();
				const statusesSet = new Set<string>();

				if (this.Teudot.length) {
					const clientid = Object.keys(this.Teudot[0]).find(t => t.toLowerCase() === 'clientid' || t.toLowerCase() === 'client_id');

					if (clientid) {
						this.hasClientIdColumn = true;
						this.clientIdKey = clientid;
					}
				}

				for (let x = 0; x < this.Teudot.length; x++) {
					const teuda = this.Teudot[x];
					companiesSet.add(teuda.Company);

					teuda.TeudaTypeName ||= TEUDA_TYPES[teuda.TeudaType];

					if (this.SeeTrucks === '1') {
						for (let y = 0; y < this.LocalSaveTrucksSent.length; y++) {
							if (this.Teudot[x].TeudaNum === this.LocalSaveTrucksSent[y].TeudaNum) {
								if (this.Teudot[x].TruckNum === '') {
									this.Teudot[x].TruckNum = this.LocalSaveTrucksSent[y].TruckNum;
								}
							}
						}
					}

					doctypesSet.add(this.Teudot[x].TeudaTypeName);
					agentsSet.add(this.Teudot[x].AgentID);
					statusesSet.add(this.Teudot[x].Status);
				}

				this.HasMultipleCompanys = companiesSet.size > 1;

				this.doctypes = [...doctypesSet];
				this.agents = [...agentsSet];
				this.statuses = [...statusesSet];

				this.updateFilterReference();

				this.AllTeudotReset = this.Teudot;

				for (let x = 0; x < this.Teudot.length; x++) {
					this.TotalArray += this.Teudot[x].TeudaTotalSum;
				}
			},
			error: err => alert('שגיאת שרת'),
		});
	}

	getAutoluxB2BDashboardInstead() {
		this._ss.getB2BAutoluxDashboard(this._ss.AgentIdConnected.replaceAll('M', '')).subscribe({
			next: (res: any) => {
				this.autoluxDisplayArray = res.recordset;
				this.autoluxDisplayArray$.next(this.autoluxDisplayArray);

				if (this.autoluxDisplayArray.length) {
					const clientid = Object.keys(this.autoluxDisplayArray[0]).find(t => t.toLowerCase() === 'clientid' || t.toLowerCase() === 'client_id');

					if (clientid) {
						this.hasClientIdColumn = true;
						this.clientIdKey = clientid;
					}
				}
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
				this.history.back();
			},
		});
	}

	dateInvalid = false;

	handleDateChange(val) {
		if (val == 'from') {
			this.dateInvalid = this.form.controls['FromDate'].invalid || this.form.controls['ToDate'].invalid;
		} else {
			if (!this.form.controls['FromDate'].value || new Date(this.form.controls['FromDate'].value).getTime() > new Date(this.form.controls['ToDate'].value).getTime()) {
				const msg = !this.form.controls['FromDate'].value ? "ערך 'מתאריך' אינו תקין" : "ערך 'עד תאריך' אינו יכול להיות קטן מערך 'מתאריך'";
				alert(msg);
				this.dateInvalid = true;
				this.form.controls['ToDate'].reset();
			} else {
				this.dateInvalid = false;
			}
		}
	}

	handleAdvancedSearch() {
		// Make search input text disappear without triggering valueChanges()
		(this.document.querySelector('#search-Input') as HTMLInputElement).value = '';

		this.TotalArray = 0;
		this.TotalArrayChecked = 0;
		this.ArrayChecked = [];

		const { FromDate, ToDate, DocType, Agent, Status } = this.form.value;

		this.Teudot = this.teudotOriginalResponse;

		const day = 1000 * 60 * 60 * 24;

		//Can't submit without both, but whatever
		if (FromDate && ToDate) {
			// Changed from TeudaSupplyDate
			const dateToSearch = this._ss.CustomerInformation.CustChange2 === '1' ? 'SupplyDate' : 'TeudaDate';

			this.Teudot = this.Teudot.filter(td => new Date(td[dateToSearch]).getTime() >= new Date(FromDate).getTime() && new Date(td[dateToSearch]).getTime() <= new Date(ToDate).getTime() + day);
		}

		if (DocType?.length) {
			this.Teudot = this.Teudot.filter(td => DocType.includes(td.TeudaTypeName));
		}

		if (Agent?.length) {
			this.Teudot = this.Teudot.filter(td => Agent.includes(td.AgentID));
		}

		if (Status?.length) {
			this.Teudot = this.Teudot.filter(td => Status.includes(td.Status));
		}

		this.showFilterPopup = false;

		for (let x = 0; x < this.Teudot.length; x++) {
			this.TotalArray += this.Teudot[x].TeudaTotalSum;
		}

		this.document.querySelectorAll('input[type="checkbox"]').forEach(chckbx => ((chckbx as HTMLInputElement).checked = false));
	}

	SearchClear() {
		if (this.form.controls['Search'].value) {
			this.form.controls['Search'].reset();
		} else {
			this.form.controls['Status'].reset();
			this.form.controls['DocType'].reset();
			this.form.controls['Agent'].reset();
			this.form.controls['FromDate'].reset();
			this.form.controls['ToDate'].reset();

			this.headerSearchForm.reset();

			this.clearEverything();
		}
	}

	//TODO refactor into two different methods

	clearEverything() {
		this.TotalArray = 0;
		this.TotalArrayChecked = 0;
		this.ArrayChecked = [];
		this.Teudot = [];

		this.Teudot = this.teudotOriginalResponse;

		for (let x = 0; x < this.Teudot.length; x++) {
			this.TotalArray += this.Teudot[x].TeudaTotalSum;
		}

		this.document.querySelectorAll('input[type="checkbox"]').forEach(chckbx => ((chckbx as HTMLInputElement).checked = false));
	}

	ChooseTruckPopAll = false;
	TeudaNumChoosenAll;
	ConfirmTruckPopAll = false;
	ErrorTruckPopAll = false;
	ShowConformButtonTruckAll = true;

	SendTrucks = [];
	SendTrucksSendOnly = [];

	ChooseTruckCloseAll() {
		this.ChooseTruckPopAll = false;
		this.ErrorTruckPopAll = false;
		this.ConfirmTruckPopAll = false;
		this.ShowConformButtonTruckAll = true;
	}

	SubmitTruckAll() {
		if (this.ArrayChecked.length < 51) {
			this.SendTrucks = [];
			this.SendTrucksSendOnly = [];

			if (this.form.controls['TruckAll'].value !== null) {
				if (this.ArrayChecked.length > 0) {
					if (this.form.controls['TruckAll'].value.length > 0) {
						this.ErrorTruckPopAll = false;
						this.ConfirmTruckPopAll = false;
						this.ShowConformButtonTruckAll = false;

						for (let x = 0; x < this.ArrayChecked.length; x++) {
							this.SendTrucks.push({ TruckNum: this.form.controls['TruckAll'].value, TeudaNum: this.ArrayChecked[x].TeudaNum });
							this.SendTrucksSendOnly.push({ TeudaNum: this.ArrayChecked[x].TeudaNum });
						}

						setTimeout(() => {
							this._ss
								.UpdateTDataOneStringTruckAll({ DataString: JSON.stringify(this.SendTrucksSendOnly), TruckNum: this.form.controls['TruckAll'].value, UserID: this._ss.UserIdConnected })
								.subscribe(
									res => {
										if (res === 'RequestError' || res === 'ConnectionError') {
											this.ErrorTruckPopAll = true;
											this.ShowConformButtonTruckAll = true;
										} else {
											this.form.controls['TruckAll'].reset();
											this.ConfirmTruckPopAll = true;
											for (let x = 0; x < this.SendTrucks.length; x++) {
												this.LocalSaveTrucksSent.push({ TruckNum: this.SendTrucks[x].TruckNum, TeudaNum: this.SendTrucks[x].TeudaNum });
												for (let y = 0; y < this.Teudot.length; y++) {
													if (this.Teudot[y].TeudaNum === this.SendTrucks[x].TeudaNum) {
														this.Teudot[y].TruckNum = this.SendTrucks[x].TruckNum;
													}
												}
											}

											localStorage.setItem('LocalSaveTrucksSent', JSON.stringify(this.LocalSaveTrucksSent));

											console.log(this.LocalSaveTrucksSent);

											this.ArrayChecked = [];
										}
									},
									err => {
										this.ErrorTruckPopAll = true;
										this.ShowConformButtonTruckAll = true;
									},
								);
						}, 1000);
					} else {
						alert('אנא הזן פרטים');
					}
				} else {
					alert('חייב לסמן תעודות לבחירת משאית');
				}
			}
		} else {
			alert('ניתן לשייך עד 50 תעודות בבת אחת');
		}
	}

	ClosePopsAll() {
		this.ErrorTruckPopAll = false;
		this.ConfirmTruckPopAll = false;
	}

	ProductsToEdit;
	isTeudaEditPopupOpen = false;
	teudaProductsAfterEdit;
	TeudaNumToEdit;
	TDataOneStringChangeStatusTeudaNum;
	indexOfCurrentlyOpenedTeuda;
	shouldShowTeudaAlreadyChangedPopup;
	openedTeudaClientName = '';
	EdittedTeudaRemark = '';

	@ViewChild('teudotTableNames') rightSideOfTeudaProducts: ElementRef<HTMLTableCellElement>;

	EditTeuda(value, valueOriginalNum, TeudaIndex) {
		this.indexOfCurrentlyOpenedTeuda = TeudaIndex;
		this.TDataOneStringChangeStatusTeudaNum = valueOriginalNum;
		this.document.body.classList.add('no-scroll');

		this.TeudaNumToEdit = value;

		this.EdittedTeudaRemark = this.Teudot[TeudaIndex].TeudaRemark;
		this.selectedTeudaToOpenPopupRemarks = [];

		this._ss.GetProductsToEditTeuda(value).subscribe(
			(res: any) => {
				this.ProductsToEdit = res.recordset;

				this.openedTeudaClientName = this.getClientNameAndIDFromTeuda(this.ProductsToEdit[0]);

				this.toggleTeudaPopup('open');
				setTimeout(() => {
					this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
				}, 10);

				const arrayOfKeysToAdd = ['TeudaPackQuantity', 'TeudaQuantity', 'TeudaPrice', 'TeudaItemDiscount', 'TeudaItemNetPrice', 'TeudaTotalRow', 'TeudaBonusQuantity', 'TeudaSupplyDate'];

				for (const product of this.ProductsToEdit) {
					for (const key of arrayOfKeysToAdd) {
						product[key + 'Original'] = product[key];
					}
				}

				console.log(res);
			},
			err => {
				console.error(err);
				alert('אירעה שגיאה בהבאת תעודה, אנא נסה שנית מאוחר יותר.');
			},
		);
	}

	MyProductId = null;
	productIndex = null;
	openProductofTeudaToEdit(ProductId) {
		this.productIndex = this.ProductsToEdit.findIndex(prd => prd.ProductId == ProductId);
		this.selectedProductToEdit = this.ProductsToEdit[this.productIndex];

		this.MyProductId = ProductId;

		this.form.setValue({
			...this.form.value,
			TeudaPackQuantity: this.selectedProductToEdit.TeudaPackQuantity,
			TeudaQuantity: this.selectedProductToEdit.TeudaQuantity,
			TeudaPrice: this.selectedProductToEdit.TeudaPrice,
			TeudaItemDiscount: this.selectedProductToEdit.TeudaItemDiscount,
			TeudaBonusQuantity: this.selectedProductToEdit.TeudaBonusQuantity,
		});

		this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			if (
				val.TeudaPackQuantity != this.selectedProductToEdit.TeudaPackQuantity ||
				val.TeudaQuantity != this.selectedProductToEdit.TeudaQuantity ||
				val.TeudaPrice != this.selectedProductToEdit.TeudaPrice ||
				val.TeudaItemDiscount != this.selectedProductToEdit.TeudaItemDiscount ||
				val.TeudaBonusQuantity != this.selectedProductToEdit.TeudaBonusQuantity
			) {
				this.shouldShowButtonRow = true;
			} else {
				this.shouldShowButtonRow = false;
			}
			this.sendEditedProductStatus = null;
		});
	}

	selectedProductToEdit;
	shouldShowButtonRow;

	sendEditedProductStatus;

	changesToTeudaMade = false;

	teudaStatusChangeMadeBoolean = false;

	toggleTeudaPopup(mode: 'open' | 'close') {
		if (mode === 'close') {
			this.isTeudaEditPopupOpen = false;
			this.shouldShowTeudaAlreadyChangedPopup = false;
			this.changesToTeudaMade = false;
			this.openedTeudaClientName = this.EdittedTeudaRemark = '';
			document.body.classList.remove('no-scroll');
			return;
		}

		if (mode === 'open') {
			this.isTeudaEditPopupOpen =
				// this.LoadingSeeTeuda =
				true;
		}
	}

	//TODO Might be irrelevant, leftover from editting both onestring and teuda
	messageToShowAfterEdit = 'תעודה זו כבר נערכה ואושרה';

	SendEditedProduct() {
		this._ss
			.postTeudaProductEdits({
				ProductId: this.MyProductId,
				TeudaNum: this.TeudaNumToEdit,
				TeudaQuantity: this.form.controls['TeudaQuantity'].value,
				TeudaPackQuantity: this.form.controls['TeudaPackQuantity'].value,
				TeudaBonusQuantity: this.form.controls['TeudaBonusQuantity'].value,
				TeudaItemDiscount: this.form.controls['TeudaItemDiscount'].value,
				TeudaPrice: this.form.controls['TeudaPrice'].value,
				TeudaItemNetPrice: Number(this.form.controls['TeudaPrice'].value * (1 - this.form.controls['TeudaItemDiscount'].value / 100)).toFixed(2),
				TeudaTotalRow: Number(
					(this.form.controls['TeudaQuantity'].value + this.form.controls['TeudaPackQuantity'].value * this.selectedProductToEdit.TeudaUnitPerPack) *
						(this.form.controls['TeudaPrice'].value * (1 - this.form.controls['TeudaItemDiscount'].value / 100)),
				).toFixed(2),
			})
			.subscribe(
				res => {
					this.ProductsToEdit[this.productIndex].TeudaQuantity = this.form.controls['TeudaQuantity'].value;
					this.ProductsToEdit[this.productIndex].TeudaPackQuantity = this.form.controls['TeudaPackQuantity'].value;
					this.ProductsToEdit[this.productIndex].TeudaBonusQuantity = this.form.controls['TeudaBonusQuantity'].value;
					this.ProductsToEdit[this.productIndex].TeudaPrice = this.form.controls['TeudaPrice'].value;
					this.ProductsToEdit[this.productIndex].TeudaItemDiscount = this.form.controls['TeudaItemDiscount'].value;
					this.ProductsToEdit[this.productIndex].TeudaItemNetPrice = Number(this.form.controls['TeudaPrice'].value * (1 - this.form.controls['TeudaItemDiscount'].value / 100)).toFixed(2);
					this.shouldShowButtonRow = null;
					this.ProductsToEdit[this.productIndex].TeudaTotalRow = Number(
						(this.form.controls['TeudaQuantity'].value + this.form.controls['TeudaPackQuantity'].value * this.selectedProductToEdit.TeudaUnitPerPack) *
							Math.round(this.form.controls['TeudaPrice'].value * (1 - this.form.controls['TeudaItemDiscount'].value / 100)),
					).toFixed(2);

					this.sendEditedProductStatus = 'שינויים נקלטו בהצלחה!';
					this.changesToTeudaMade = true;
				},
				err => {
					console.error(err);
					alert('ארעה שגיאה,אנא נסה שנית');
				},
			);
	}

	shouldSupplyDateEditPopupShow = false;

	supplyDate: Date;
	supplyDateOriginal: Date;

	supplyDateForm: FormGroup;

	teudaCreateDate: Date;

	dateEdittedSuccessfullyMsg = null;

	handleSupplyDateEditPopupOpen() {
		this.shouldSupplyDateEditPopupShow = true;
		this.supplyDate = this.ProductsToEdit[0].TeudaSupplyDate;
		this.supplyDateOriginal = this.ProductsToEdit[0].TeudaSupplyDateOriginal;
		const createDate = (this.teudaCreateDate = new Date(new Date(this.ProductsToEdit[0].TeudaCreateDate).setHours(0)));

		function dateValidator(c: AbstractControl): { [key: string]: boolean } | null {
			const twoYearsFromToday = new Date(new Date().valueOf() + 1000 * 60 * 60 * 24 * 730);
			if (c.pristine) {
				return null;
			}
			const value = new Date(c.value);
			if (value < new Date(createDate) || value > twoYearsFromToday) {
				return { illegal: true };
			}
			return null;
		}
		this.supplyDateForm = this._fb.group({
			TeudaSupplyDate: [this.supplyDate, [Validators.required, dateValidator]],
		});

		this.supplyDateForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			if (val.TeudaSupplyDate != this.supplyDateOriginal) this.shouldShowButtonRow = true;
			this.dateEdittedSuccessfullyMsg = null;
		});
	}

	handleSupplyDateEditSubmit() {
		const TeudaSupplyDate = new Date(this.supplyDateForm.value.TeudaSupplyDate).toISOString();

		const body = { TeudaSupplyDate, TeudaNum: this.TeudaNumToEdit };

		this._ss.changeSupplyDateTeudaTable(body).subscribe(
			(res: any) => {
				if (res == 'RequestError') {
					alert('ישנה תקלה בשרת, אנא נסו שנית מאוחר יותר');
				} else {
					const jsond = JSON.parse(res);
					if (!jsond?.rowsAffected[0]) {
						alert('ישנה תקלה בשרת, אנא נסו שנית מאוחר יותר');
					} else {
						this.shouldShowButtonRow = false;
						this.dateEdittedSuccessfullyMsg = 'נתונים עודכנו בהצלחה!';
					}
				}
			},
			err => {
				alert('תקלה בעת העברת נתונים. אנא וודאו שהנכם מחוברים לאינטרנט');
			},
		);
	}

	ChangeTeudaStatusAfterFinishEditting() {
		this._ss.postTeudaStatusChange(this.TeudaNumToEdit).subscribe(
			res => {
				this._ss.postTeudaStatusChangeOnestring(this.TDataOneStringChangeStatusTeudaNum).subscribe(
					res => {
						this.messageToShowAfterEdit = 'תעודה אושרה בהצלחה. ניתן לסגור את החלון.';
						this.shouldShowTeudaAlreadyChangedPopup = true;
						const teuda = this.Teudot.find(td => td.TeudaNewNum == this.TeudaNumToEdit);
						teuda.TeudaStatus = 3;
						teuda.Status = 3;
					},
					err => {},
				);
			},
			err => {
				console.error(err);
				alert('ארעה שגיאה,אנא נסה שנית');
			},
		);
	}

	teudaDeletePopupConfirmation = false;
	messageToShowAfterDelete = 'מחיקת תעודה הינה בלתי הפיכה. להמשיך?';

	deleteTeudaFromTeudaTable() {
		if (this.canDeleteTeudaInOneQueryAndPermanently) {
			this.permanentlyDeleteTeuda();
			return;
		}

		this._ss.deleteTeudaFromTeudaTable(this.TeudaNumToEdit).subscribe(
			res => {
				this._ss.deleteTeudaFromOnestring(this.TDataOneStringChangeStatusTeudaNum).subscribe(
					res => {
						this.isTeudaEditPopupOpen = this.shouldShowTeudaAlreadyChangedPopup = false;
						const teuda = this.Teudot.find(td => td.TeudaNewNum != this.TeudaNumToEdit);
						teuda.TeudaStatus = 0;
						teuda.Status = 0;
						this.messageToShowAfterDelete = 'תעודה נמחקה, ניתן לסגור את החלון.';
					},
					err => {
						this._ss.deleteTeudaFromOnestring(this.TDataOneStringChangeStatusTeudaNum).subscribe(
							res => {},
							err => {
								console.log(err);
								alert('ארעה שגיאה,אנא נסה שנית');
							},
						);
					},
				);
			},
			err => {
				console.log(err);
				alert('ארעה שגיאה,אנא נסה שנית');
			},
		);
	}

	permanentlyDeleteTeuda() {
		this._ss.permanentlyDeleteTeudaFromBothOneStringAndTeuda(this.TDataOneStringChangeStatusTeudaNum, this.TeudaNumToEdit).subscribe({
			next: res => {
				this.isTeudaEditPopupOpen = this.shouldShowTeudaAlreadyChangedPopup = false;
				this.Teudot = this.Teudot.filter(td => td.TeudaNewNum != this.TeudaNumToEdit);
				this.messageToShowAfterDelete = 'תעודה נמחקה, ניתן לסגור את החלון.';
			},
			error: err => {},
		});
	}

	deleteProductFromTeuda(e: Event, ProductId) {
		e.stopPropagation();
		const decision = confirm('פעולה זו תנטרל את הפריט. האם להמשיך?');
		if (decision) {
			const productToEdit = this.ProductsToEdit.find(prd => prd.ProductId == ProductId);
			this._ss
				.postTeudaProductEdits({
					ProductId,
					TeudaNum: this.TeudaNumToEdit,
					TeudaQuantity: 0,
					TeudaPackQuantity: 0,
					TeudaBonusQuantity: 0,
					TeudaItemDiscount: productToEdit.TeudaItemDiscount,
					TeudaPrice: productToEdit.TeudaPrice,
					TeudaItemNetPrice: Number(productToEdit.TeudaPrice * (1 - productToEdit.TeudaItemDiscount / 100)).toFixed(2),
					TeudaTotalRow: 0,
				})
				.subscribe(
					res => {
						alert('נא לאשר תעודה בסיום העריכה');
						// this.TotalArray -= Number(Number(productToEdit.TeudaTotalRow).toFixed(2))
						this.changesToTeudaMade = true;
						productToEdit.TeudaTotalRow = productToEdit.TeudaQuantity = productToEdit.TeudaPackQuantity = productToEdit.TeudaBonusQuantity = 0;
						productToEdit.TeudaItemNetPrice = Number(productToEdit.TeudaPrice * (1 - productToEdit.TeudaItemDiscount / 100)).toFixed(2);
						// for (let x = 0; x < this.Teudot.length; x++) {
						//   this.TotalArray += this.Teudot[x].TeudaTotalSum
						// }
					},
					err => {
						alert('שגיאת שרת, אנא נסו שנית.');
					},
				);
		}
	}

	handleProductEditPopupClose() {
		this.selectedProductToEdit = this.MyProductId = this.sendEditedProductStatus = this.productIndex = null;
	}

	ChooseTruckPop = false;
	TeudaNumChoosen;
	ConfirmTruckPop = false;
	ErrorTruckPop = false;
	ShowConformButtonTruck = true;

	ChooseTruck(TeudaNum) {
		this.form.controls['Truck'].reset();

		this.ShowConformButtonTruck = true;
		this.ChooseTruckPop = true;

		this.TeudaNumChoosen = TeudaNum;

		setTimeout(() => {
			document.getElementById('TruckInput').focus();
		}, 50);
	}

	ChooseTruckClose() {
		this.ChooseTruckPop = false;
		this.ErrorTruckPop = false;
		this.ConfirmTruckPop = false;
		this.ShowConformButtonTruck = true;
	}

	SubmitTruck() {
		if (this.form.controls['Truck'].value.length > 0) {
			this.ErrorTruckPop = false;
			this.ConfirmTruckPop = false;
			this.ShowConformButtonTruck = false;

			this._ss.UpdateTDataOneStringTruck({ TeudaNum: this.TeudaNumChoosen, Truck: this.form.controls['Truck'].value }).subscribe(
				res => {
					if (res === 'RequestError' || res === 'ConnectionError') {
						this.ErrorTruckPop = true;
						this.ShowConformButtonTruck = true;
					} else {
						this.ConfirmTruckPop = true;
						this.LocalSaveTrucksSent.push({ TruckNum: this.form.controls['Truck'].value, TeudaNum: this.TeudaNumChoosen });
						localStorage.setItem('LocalSaveTrucksSent', JSON.stringify(this.LocalSaveTrucksSent));
						this.Teudot[this.Teudot.indexOf(this.Teudot.find(ob => ob.TeudaNum === this.TeudaNumChoosen))].TruckNum = this.form.controls['Truck'].value;
					}
				},
				err => {
					this.ErrorTruckPop = true;
					this.ShowConformButtonTruck = true;
				},
			);
		} else {
			alert('אנא הזן פרטים');
		}
	}

	ClosePops() {
		this.ErrorTruckPop = false;
		this.ConfirmTruckPop = false;
	}

	Restart() {
		window.location.reload();
	}

	selectedTeudaToOpenPopup = null;
	selectedTeudaToOpenPopupRemarks = [];
	seeTeuda(TeudaNum, index) {
		const teudaRemarkProperties = ['TeudaRemark1', 'TeudaRemark2', 'TeudaRemark3', 'TeudaRemark4', 'TeudaRemark5'];
		this.selectedTeudaToOpenPopup = (this.isAutoluxb2bDashboard ? this.autoluxDisplayArray$.value : this.Teudot)[index];
		this.LoadingSeeTeuda = true;
		this._ss.GetTeudotFirst(TeudaNum).subscribe(
			(res: any) => {
				this.TeudotTableEach = res.recordset;

				setTimeout(() => {
					if (this.TeudotTableEach.length) {
						this.openedTeudaClientName = this.getClientNameAndIDFromTeuda(this.TeudotTableEach[0]);

						for (const key of Object.keys(this.TeudotTableEach[0])) {
							if (teudaRemarkProperties.includes(key) && this.TeudotTableEach[0][key]) {
								this.selectedTeudaToOpenPopupRemarks.push(this.TeudotTableEach[0][key]);
							}
						}
						this.SeeTeudaLoaded = true;
						this.LoadingSeeTeuda = false;

						setTimeout(() => {
							this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
						}, 10);

						return;
					}

					this._ss.GetTeudotEach(TeudaNum).subscribe(
						(res: any) => {
							this.TeudotEach = res.recordset;
							this.TeudotEachString = this.TeudotEach[0].DATAString;
							this.TeudotEach = JSON.parse(this.TeudotEach[0].DATAString);

							this.openedTeudaClientName = this.getClientNameAndIDFromTeuda(this.TeudotEach[0]);

							for (const key of Object.keys(this.TeudotEach[0])) {
								if (teudaRemarkProperties.includes(key) && this.TeudotEach[0][key]) {
									this.selectedTeudaToOpenPopupRemarks.push(this.TeudotEach[0][key]);
								}
							}

							this.SeeTeudaLoaded = true;
							this.LoadingSeeTeuda = false;

							this.openedTeudaClientName ||= this.TeudotEach[0].Client_Name;
							setTimeout(() => {
								this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
							}, 10);
						},
						err => {
							alert('שגיאת שרת, אנא נסו שנית');
							this.LoadingSeeTeuda = false;
							this.document.body.classList.remove('no-scroll');
						},
					);
				}, 200);
			},
			err => {
				alert('שגיאת שרת, אנא נסו שנית');
				this.LoadingSeeTeuda = false;
				this.document.body.classList.remove('no-scroll');
			},
		);
	}

	handleCloseTeudaPopup() {
		this.SeeTeudaLoaded = false;
		this.LoadingSeeTeuda = false;
		this.TeudotTableEach = [];
		this.TeudotEach = [];
		this.openedTeudaClientName = '';
		document.body.classList.remove('no-scroll');
		this.selectedTeudaToOpenPopupRemarks = [];
	}

	exitdashbord() {
		this.router.navigate(['admin']);
	}

	closepopup() {
		this.showLogoutPopup = false;
	}

	AdminConfirmOrder(num) {
		this.LoaingConfirm = true;
		this._ss.ConfirmOrderAdmin({ num: num }).subscribe(
			res => {
				this.LoaingConfirm = false;

				if (res !== 'RequestError') {
					let data = this.Teudot.find(ob => ob.TeudaNum === num);

					if (data !== undefined) {
						this.Teudot[this.Teudot.indexOf(this.Teudot.find(ob => ob.TeudaNum === num))].Status = '0';
					}
					alert('אישור הזמנה בוצע בהצלחה');
				} else {
					alert('ישנה בעיה באישור ההזמנה');
				}
			},
			err => {
				alert('ישנה בעיה באישור ההזמנה');
				this.LoaingConfirm = false;
			},
		);
	}

	changeTeudaPrintStatus(TeudaNewNum: string) {
		this.LoaingConfirm = true;
		this._ss.sendPostRequestToChangeTeudaPrintStatus(TeudaNewNum).subscribe({
			next: res => {
				if (res !== 'RequestError' && res !== 'ConnectionError') {
					let data = this.Teudot.find(ob => ob.TeudaNewNum === TeudaNewNum);
					if (data !== undefined) {
						this.Teudot[this.Teudot.indexOf(this.Teudot.find(ob => ob.TeudaNewNum === TeudaNewNum))].PrintStatus = 0;
					}
					alert('תעודה נשלחה להדפסה');
				} else {
					alert('ישנה בעיה בהדפסת תעודה');
				}
				this.LoaingConfirm = false;
			},
			error: err => {
				alert('ישנה בעיית תקשורת עם השרת');
				this.LoaingConfirm = false;
			},
		});
	}

	OrderChecked(TeudaNum, TeudaTotalSum) {
		let data = this.ArrayChecked.find(ob => ob.TeudaNum === TeudaNum);

		if (data !== undefined) {
			this.ArrayChecked.splice(this.ArrayChecked.indexOf(this.ArrayChecked.find(ob => ob.TeudaNum === TeudaNum)), 1);

			this.TotalArrayChecked = 0;
			for (let x = 0; x < this.ArrayChecked.length; x++) {
				this.TotalArrayChecked += this.ArrayChecked[x].TeudaTotalSum;
			}
		} else {
			this.TotalArrayChecked = 0;

			this.ArrayChecked.push({ TeudaNum: TeudaNum, TeudaTotalSum: TeudaTotalSum });
			for (let x = 0; x < this.ArrayChecked.length; x++) {
				this.TotalArrayChecked += this.ArrayChecked[x].TeudaTotalSum;
			}
		}
	}

	checkAllOrders() {
		const checkboxes = [...document.querySelectorAll('input[type=checkbox]')] as HTMLInputElement[];

		for (const chkbx of checkboxes) {
			chkbx.click();
		}
	}

	changeFullscreen(newValue: boolean) {
		this.isFullscreen = newValue;
	}

	translateTeudaToCoordinates(teuda: any, coordinates: MarkerInfo<TeudaMarkerPopupData>[]) {
		if (teuda.Longitude && teuda.Latitude && teuda.Longitude !== '0' && teuda.Latitude !== '0') {
			coordinates.push({
				longitude: teuda.Longitude,
				latitude: teuda.Latitude,
				data: {
					TeudaCreateDate: this._util.formatAsDate(new Date(teuda.TeudaDate || teuda.TeudaCreateDate), { isTrueISO: true, onlyTime: false }),
					TeudaTotal: this._util.formatAsNormalNumber(teuda.TeudaTotalSum),
				},
			});
		}
	}

	updateFilterReference() {
		this.filters = [
			{ title: 'סוכנים', formControlName: 'Agent', controlValues: this.agents },
			{ title: 'סטטוס', formControlName: 'Status', controlValues: this.statuses },
			{ title: 'סוג תעודה', formControlName: 'DocType', controlValues: this.doctypes },
		];
	}

	setupFormInteractivity() {
		const searchControl = this.form.controls['Search'];
		const mainSearch$ = searchControl.valueChanges.pipe(startWith(''));
		const headerFormSearch$ = this.headerSearchForm.valueChanges.pipe(startWith({}));

		// isHeaderSearchFormInUse$ = this.headerSearchForm.valueChanges.pipe(
		// 	startWith({}),
		// 	map(v => !!Object.values(v).filter(v => v).length),
		// );

		headerFormSearch$.pipe(takeUntil(this.destroy$)).subscribe(formValue => {
			if (Object.values(formValue).filter(v => v).length) {
				searchControl.disable();
				return;
			}

			searchControl.enable();
		});

		// mainSearch$.pipe(takeUntil(this.destroy$)).subscribe(searchValue => {
		// 	if (searchValue?.length) {
		// 		this.headerSearchForm.disable();
		// 		return;
		// 	}

		// 	this.headerSearchForm.enable();
		// });

		const headerForm = this.headerSearchForm;

		combineLatest([mainSearch$, headerFormSearch$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([mainSearch, headerFormSearch]) => {
				if (this.isAutoluxb2bDashboard) {
					this.handleAutoluxMainSearch(mainSearch);
					return;
				}
				if (!this.teudotOriginalResponse) return;

				this.TotalArray = 0;
				this.TotalArrayChecked = 0;
				this.ArrayChecked = [];
				this.Teudot = [];

				this.form.controls['Status'].reset();
				this.form.controls['DocType'].reset();
				this.form.controls['Agent'].reset();
				this.form.controls['FromDate'].reset();
				this.form.controls['ToDate'].reset();

				if (Object.values(headerFormSearch).filter(v => v).length) {
					let arr = this.teudotOriginalResponse;

					for (const key in headerFormSearch) {
						const searchValue = headerFormSearch[key];
						if (!searchValue) continue;

						arr = this.handleSearchOneColumn(searchValue, key as keyof typeof headerForm.controls, arr);
					}

					this.Teudot = arr;
					for (let x = 0; x < this.Teudot.length; x++) {
						this.TotalArray += this.Teudot[x].TeudaTotalSum;
					}
					return;
				}

				if (mainSearch) {
					this.handleMainSearch(mainSearch);
					return;
				}

				this.Teudot = this.teudotOriginalResponse;

				for (let x = 0; x < this.Teudot.length; x++) {
					this.TotalArray += this.Teudot[x].TeudaTotalSum;
				}

				this.document.querySelectorAll('input[type="checkbox"]').forEach(chckbx => ((chckbx as HTMLInputElement).checked = false));
			});

		// this.headerSearchForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
		// 	this.form;
		// });

		this.form.controls['FromDate'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.form.controls['ToDate'].reset();

			this.minDate = new Date(val);
		});
	}

	handleAutoluxMainSearch(searchValue: string) {
		if (!searchValue) {
			this.autoluxDisplayArray$.next(this.autoluxDisplayArray);
			return;
		}

		const splitValue = searchValue.split(' ');
		const arr = [];

		for (let i = 0; i < this.autoluxDisplayArray.length; i++) {
			let counter = 0;

			const { CARNUM, LEASINGORDER, CONFIRMNUM, MIKUM } = this.autoluxDisplayArray[i];

			const CARNUMWithoutSpaces = (CARNUM || '').replace(/\s/g, '').toLowerCase(),
				LEASINGORDERWithoutSpaces = (LEASINGORDER || '').replace(/\s/g, '').toLowerCase(),
				CONFIRMNUMWithoutSpaces = (CONFIRMNUM || '').replace(/\s/g, '').toLowerCase(),
				MIKUMWithoutSpaces = (MIKUM || '').replace(/\s/g, '').toLowerCase(),
				allStrings = typeof CARNUMWithoutSpaces === 'string' && typeof LEASINGORDERWithoutSpaces === 'string' && typeof MIKUMWithoutSpaces === 'string';

			// V8 optimizes this if statement, maybe
			if (allStrings) {
				for (let j = 0; j < splitValue.length; j++) {
					if (
						(CARNUMWithoutSpaces && CARNUMWithoutSpaces.indexOf(splitValue[j]) > -1) ||
						(LEASINGORDERWithoutSpaces && LEASINGORDERWithoutSpaces.indexOf(splitValue[j]) > -1) ||
						(CONFIRMNUMWithoutSpaces && CONFIRMNUMWithoutSpaces.indexOf(splitValue[j]) > -1) ||
						(MIKUMWithoutSpaces && MIKUMWithoutSpaces.indexOf(splitValue[j]) > -1)
					) {
						counter++;
					}
				}
			}

			if (counter === splitValue.length) {
				arr.push(this.autoluxDisplayArray[i]);
			}
		}
		this.autoluxDisplayArray$.next(arr);
		return;
	}

	handleMainSearch(searchValue: string) {
		const splitValue = searchValue.split(' ');

		for (let i = 0; i < this.teudotOriginalResponse.length; i++) {
			let counter = 0;

			const { Client_Name, TeudaNum, TeudaNewNum, TuedaErpDocNum } = this.teudotOriginalResponse[i];
			const clientid = this.teudotOriginalResponse[i][this.clientIdKey];

			const Client_NameWithoutSpaces = (Client_Name || '').replace(/\s/g, '').toLowerCase(),
				TeudaNumWithoutSpaces = (TeudaNum || '').replace(/\s/g, '').toLowerCase(),
				TeudaNewNumWithoutSpaces = (TeudaNewNum || '').replace(/\s/g, '').toLowerCase(),
				TuedaErpDocNumWithoutSpaces = (TuedaErpDocNum || '').replace(/\s/g, '').toLowerCase(),
				clientidWithoutSpaces = (clientid || '').replace(/\s/g, '').toLowerCase(),
				allStrings =
					typeof Client_NameWithoutSpaces === 'string' &&
					typeof TeudaNumWithoutSpaces === 'string' &&
					typeof TeudaNewNumWithoutSpaces === 'string' &&
					typeof clientidWithoutSpaces === 'string' &&
					typeof TuedaErpDocNumWithoutSpaces === 'string';

			// V8 optimizes this if statement, maybe
			if (allStrings) {
				for (let j = 0; j < splitValue.length; j++) {
					if (
						(Client_NameWithoutSpaces && Client_NameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
						(TeudaNumWithoutSpaces && TeudaNumWithoutSpaces.indexOf(splitValue[j]) > -1) ||
						(TeudaNewNumWithoutSpaces && TeudaNewNumWithoutSpaces.indexOf(splitValue[j]) > -1) ||
						(clientidWithoutSpaces && clientidWithoutSpaces.indexOf(splitValue[j]) > -1) ||
						(TuedaErpDocNumWithoutSpaces && TuedaErpDocNumWithoutSpaces.indexOf(splitValue[j]) > -1)
					) {
						counter++;
					}
				}
			}

			if (counter === splitValue.length) {
				this.Teudot.push(this.teudotOriginalResponse[i]);
			}
		}

		for (let x = 0; x < this.Teudot.length; x++) {
			this.TotalArray += this.Teudot[x].TeudaTotalSum;
		}
	}

	handleSearchOneColumn(searchValue: string, columnName: keyof typeof this.headerSearchForm.controls, arrayToFilter: any[]) {
		const splitValue = searchValue.split(' ');
		const resultArr = [];

		for (let i = 0; i < arrayToFilter.length; i++) {
			let counter = 0;

			let teudaValue = arrayToFilter[i][columnName];

			if (columnName === 'clientID') {
				teudaValue = arrayToFilter[i][this.clientIdKey];
			}

			if (teudaValue == null || teudaValue === '') {
				if (columnName !== 'TeudaNewNum') continue;

				teudaValue = teudaValue.length < 1 ? arrayToFilter[i]['TeudaNum'] : teudaValue;
				if (teudaValue == null || teudaValue === '') continue;
			}

			if (columnName === 'TeudaDate') {
				teudaValue = teudaValue.slice(0, 10);
			}

			const teudaValueWithoutSpaces = String(teudaValue).replace(/\s/g, '').toLowerCase(),
				allStrings = typeof teudaValueWithoutSpaces === 'string';

			// V8 optimizes this if statement, maybe
			if (allStrings) {
				for (let j = 0; j < splitValue.length; j++) {
					if (teudaValueWithoutSpaces && teudaValueWithoutSpaces.indexOf(splitValue[j]) > -1) {
						counter++;
					}
				}
			}

			if (counter === splitValue.length) {
				resultArr.push(arrayToFilter[i]);
			}
		}

		// for (let x = 0; x < this.Teudot.length; x++) {
		// 	this.TotalArray += this.Teudot[x].TeudaTotalSum;
		// }

		return resultArr;
	}

	getClientNameAndIDFromTeuda(teuda: any) {
		if (!teuda) return '';
		let returnValue = '';
		const clientname = Object.keys(teuda).find(t => t.toLowerCase() === 'client_name' || t.toLowerCase() === 'clientname');
		if (clientname) {
			returnValue = teuda[clientname];
			const clientid = Object.keys(teuda).find(t => t.toLowerCase() === 'clientid' || t.toLowerCase() === 'client_id');
			if (clientid) {
				returnValue += ` (${teuda[clientid]})`;
			}
		}
		return returnValue;
	}
}
