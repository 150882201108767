import { AtLeast } from './util';

export const alertActivity = {
	active: '0',
	inactive: '1',
	byDate: '2',
} as const;

export type Alert = {
	ID: number;
	Msg: string;
	MsgType: typeof MsgType[keyof typeof MsgType]['msgType'];
	Active: typeof alertActivity[keyof typeof alertActivity];
	Extra1: string;
	Extra2: string;
	CreateDate: string;
	Company: number;
	Name: string;
	JSON: { clients?: string[]; agents?: string[]; time?: string; date?: { start: string; end: string }; block?: boolean };
};

// before JSON.parse()
export type AlertInDatabase = {
	[K in keyof Alert]: K extends 'JSON' ? string : Alert[K];
};

// export interface AlertInDatabase extends Omit<Alert, 'JSON'> {
// 	JSON: string;
// }

export interface AlertPostBody extends AtLeast<Alert, 'Msg'> {
	JSON: string;
}
// clients - choose clients
// agents - choose agents
// timeSensitive - only certain time
// onlyOnce - alert only once per day/update
// block - not implemented yet
const baseMessageOptions = { clients: true, agents: true, timeSensitive: false, onlyOnce: false, block: false } as const;

export const MsgType = {
	afterUpdateLocal: { msgText: 'התראה לסוכן', msgType: '1000', options: { ...baseMessageOptions, clients: false, onlyOnce: true } },
	clientCard: { msgText: 'כניסה לכרטיס לקוח', msgType: '2000', options: { ...baseMessageOptions } },
	enterProducts: { msgText: 'התראה בהזמנת לקוח', msgType: '3000', options: { ...baseMessageOptions } },
	enterProductsFromHour: { msgText: 'התראה בהזמנת לקוח - מתוזמנת', msgType: '3010', options: { ...baseMessageOptions, timeSensitive: true } },
	moveToTeudaScreen: { msgText: 'לפני סיום הזמנה', msgType: '4000', options: { ...baseMessageOptions } },
	moveToTeudaScreenFromHour: { msgText: 'לפני סיום הזמנה - מתוזמנת', msgType: '4010', options: { ...baseMessageOptions, timeSensitive: true } },
	newVisit: { msgText: 'ביקור חדש', msgType: '5000', options: { ...baseMessageOptions } },
} as const;

type MsgTypeOptions = {
	[K in keyof typeof MsgType as typeof MsgType[K]['msgType']]: typeof MsgType[K]['options'];
};

type MsgTypeText = {
	[K in keyof typeof MsgType as typeof MsgType[K]['msgType']]: typeof MsgType[K]['msgText'];
};

export const MsgTypeOptions: MsgTypeOptions = Object.fromEntries(Object.values(MsgType).map(({ msgType, options }) => [msgType, options])) as MsgTypeOptions;

export const MsgTypeText: MsgTypeText = Object.fromEntries(Object.values(MsgType).map(({ msgType, msgText }) => [msgType, msgText])) as MsgTypeText;
