import { Pipe, PipeTransform } from '@angular/core';
import { SubAppModes } from '../components/likut-clients/likut-clients.component';
import { LikutKabalaStatus, LikutStatus } from '../Interfaces/likut';
import { LikutService } from '../services/likut.service';

@Pipe({
	name: 'taskText',
})
export class TaskTextPipe implements PipeTransform {
	constructor(private _likut: LikutService) {}
	transform(value: LikutStatus | LikutKabalaStatus, type: SubAppModes, isLockedOrderForDistribution?: boolean): string {
		if (type === 'truck-distribution') {
			if (isLockedOrderForDistribution) {
				return 'שמור';
			}
			switch (value) {
				case '0':
					return 'ממתין לשיוך';
				case '1':
					return 'בוצע';
				case '2':
					return 'אושר';
				case '3':
					return 'בוטל';
				default:
					break;
			}
		}
		if (type === 'truck-loading') {
			switch (value) {
				case '0':
					return 'ממתין לשיוך';
				case '1':
					return 'הועמס';
				case '2':
					return 'אושר';
				case '3':
					return 'בוטל';

				default:
					break;
			}
		}

		if (this._likut.isMekabel) {
			switch (value as LikutKabalaStatus) {
				case '0':
					return 'ממתין לקבלה';
				case '1':
				case '2':
					return 'התקבל';
				case '5':
					return 'בוטל';

				default:
					return 'ממתין לשיוך';
			}
		}

		switch (value) {
			case '0':
				return 'ממתין לשיוך';
			case '1':
				return 'ממתין לליקוט';
			case '2':
				return 'לוקט';
			case '3':
				return 'אושר';
			case '4':
				return 'טופל';
			case '6':
				return 'בוטל';

			default:
				return '';
		}
	}
}
