import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class MapDistributionService {
	constructor() {}

	showDateControl = new FormControl(new Date());
}
