<div class="product-detail-popup popup__content">
	<!-- <div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black"></div> -->
	<div class="product-detail__wrapper" dir="rtl">
		<h3 style="text-align: center">אנא בחר יום להציג</h3>

		<div class="flex flex-column gap" dir="rtl">
			<mat-form-field color="primary" dir="rtl">
				<mat-label>תאריך</mat-label>
				<input readonly="true" [formControl]="_mapDistribution.showDateControl" matInput [matDatepicker]="picker" (click)="picker.open()" />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</div>

		<button mat-raised-button color="primary" matDialogClose>בחר</button>
	</div>
	<ng-template #elseBlock>
		<div class="product-detail__wrapper flex flex-column justify-align-center rtl">
			<h2>טוען, אנא המתינו...</h2>
			<mat-spinner diameter="50" color="primary"></mat-spinner>
		</div>
	</ng-template>
</div>
