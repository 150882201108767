import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, BehaviorSubject, takeUntil, map, catchError, of, from, mergeMap, take, tap, Subscription } from 'rxjs';
import { ByClient, ByDate, ByDateDisplay } from 'src/app/Interfaces/reports';
import { Users } from 'src/app/Interfaces/users';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/loading.service';
import { DebtByDateService, DebtMode, RefreshSubjectArguements } from 'src/app/services/debt-by-date.service';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { CompaniesResponse } from 'src/app/Interfaces/companies';

@Component({
	selector: 'app-dohot-lekohot-hovot-by-date',
	templateUrl: './dohot-lekohot-hovot-by-date.component.html',
	styleUrls: ['./dohot-lekohot-hovot-by-date.component.scss'],
})
export class DohotLekohotHovotByDateComponent implements OnInit, OnDestroy {
	constructor(private _ss: StoreService, private router: Router, public _loading: LoadingService, private _router: Router, public _debtByDate: DebtByDateService) {}

	navigate$: Subscription;

	clientsFromStorage: AllClients[] = null;

	First = true;
	Second = false;

	// windowWidth = outerWidth;

	isAdmin = location.pathname.includes('admin');

	destroy$ = new Subject<void>();

	@ViewChildren('tableRow') row: QueryList<ElementRef<HTMLTableRowElement>>;

	isLocal = false;
	// isLocal = !environment.production;

	async ngOnInit() {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this._ss.ClientFromDohot = 0;

		this._ss.OneClientInfoDebt = null;
		this._ss.AllMismachimClient = null;

		window.scrollTo(0, 0);

		const [
			UserName,
			AgentId,
			AllClients,
			// , allHovotLS
		] = [
			localStorage.getItem('UserName'),
			localStorage.getItem('AgentId'),
			localStorage.getItem('AllClients'),
			// localStorage.getItem('AllHovotHeaders'),
		];

		if (UserName === null || AgentId === null || AllClients === null) {
			this.router.navigate(['login']);
		}

		this._ss.UserNameConnected = UserName;
		this._ss.AgentIdConnected = AgentId;

		if (!AllClients) {
			this.router.navigate(['login']);
			return;
		}

		this.clientsFromStorage = JSON.parse(decompress(AllClients));

		this.setupFormFunctionality();

		if (this._debtByDate.selectedDate.yy) {
			this.First = false;
			this.Second = true;

			this.refreshSubjects(this._debtByDate.debtDateControl.value === 'by-create' ? 'allClientsByCreate' : 'allClientsByPay');
			return;
		}

		this.getByDate('by-pay');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleBackArrow() {
		if (this.First) {
			history.back();
			return;
		}

		if (this.Second) {
			const mode = this._debtByDate.debtDateControl.value;

			this.refreshSubjects(this._debtByDate.refreshSubjectsIndexFirstPage[mode]);
			this.Second = false;
			this.First = true;

			this.refreshSubjects('allDebtsSum');

			const { mm, yy } = this._debtByDate.selectedDate;

			setTimeout(() => {
				this.handleScrollToRelevantDate(mm, yy);
			}, 0);

			this._debtByDate.selectedDate = { mm: null, yy: null };
		}
	}

	sortArr = [true, false, false];

	sort(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'COD':
				i = 0;
				break;
			case 'Client_Name':
				i = 1;
				break;
			case 'ClientId':
				i = 2;
				break;
			case 'CMC':
				i = 3;
				break;
			case 'CMC-COC+COD':
				i = 4;
				break;
			case 'COC+COD':
				i = 5;
				break;
			case 'COC':
				i = 6;
				break;

			default:
				console.log('I should never return');
				return;
		}

		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	trackBy(index: number, client: ByDateDisplay): string {
		return client.DebtCustCode;
	}

	refreshSubjects(mode: RefreshSubjectArguements) {
		const arraysMode: Record<typeof mode, any[]> = {
			allDebtsByPay: this._debtByDate.allDebtsByPay,
			allDebtsByCreate: this._debtByDate.allDebtsByCreate,
			allClientsByCreate: this._debtByDate.allDebtsByCreate,
			allClientsByPay: this._debtByDate.allDebtsByPay,
			allDebtsSum: this.First ? this._debtByDate.allDebtsByDate$.value : this._debtByDate.allClientsByDebt$.value,
		};
		const arrayToReduce = arraysMode[mode];
		if (mode === 'allDebtsByPay' || mode === 'allDebtsByCreate') {
			this._debtByDate.allDebtsByDate$.next(
				Object.values(
					arrayToReduce.reduce((prev: Record<string, ByDateDisplay>, curr) => {
						if (!curr.tothov || this._debtByDate.companyControl.value !== curr.company) return prev;
						prev[curr.mm + '-' + curr.yy] ??= { date: curr.mm + '-' + curr.yy, tothov: 0, yy: curr.yy, mm: curr.mm };
						prev[curr.mm + '-' + curr.yy].tothov += curr.tothov;

						return prev;
					}, {}),
				),
			);

			this._debtByDate.allDebtsByDate$.getValue().sort((a, b) => {
				return Intl.Collator('en-IL').compare(a.yy + String(a.mm).padStart(2, '0'), b.yy + String(b.mm).padStart(2, '0'));
			});

			return;
		}

		if (mode === 'allClientsByCreate' || mode === 'allClientsByPay') {
			const { mm, yy } = this._debtByDate.selectedDate;

			this._debtByDate.allClientsByDebt$.next(
				Object.values(
					arrayToReduce.reduce((prev: Record<string, any>, curr: ByDate) => {
						if (curr.mm !== mm || curr.yy !== yy || !curr.tothov || this._debtByDate.companyControl.value !== curr.company) return prev;

						prev[curr.DebtCustCode] ??= { id: curr.DebtCustCode, name: curr.Client_Name, tothov: 0 };
						prev[curr.DebtCustCode].tothov += curr.tothov;

						return prev;
					}, {}),
				),
			);
		}

		if (mode === 'allDebtsSum') {
			this._debtByDate.allDebtsSum$.next(arraysMode[mode].reduce((acc, curr) => acc + curr.tothov, 0));
			return;
		}
	}

	handleClickOnDate(date: ByDateDisplay) {
		const { mm, yy } = date;

		if (!date.mm || !date.yy) return;

		this._debtByDate.selectedDate = { mm, yy };

		const mode = this._debtByDate.debtDateControl.value === 'by-create' ? 'allClientsByCreate' : 'allClientsByPay';

		this.refreshSubjects(mode);

		this.First = false;
		this.Second = true;
		this.refreshSubjects('allDebtsSum');

		window.scroll(0, 0);
	}
	handleClickOnClient(client: ByClient) {
		const result = this.clientsFromStorage.find(c => c.ClientId === client.id);
		if (result) {
			this._ss.OneClientInfo = result;
			this._ss.AutoMoveHovot = 1;

			this._debtByDate.listenToNavigationAndResetWhenLeaving();

			this._router.navigate(['client-card']);
		}
	}

	getByDate(mode: DebtMode) {
		this._loading.changeLoadingStatuses({ isLoading: true });

		this.handleGetNewDebts(mode);
	}

	handleGetNewDebts(mode: DebtMode) {
		this._ss
			.getAllDebtByDates(mode)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: async res => {
					if (typeof res === 'string') return alert('ישנה תקלה בשרת');

					this._debtByDate.hasMoreThanOneCompany ||= res.recordset.some(debt => debt.company > 1);

					if (this._debtByDate.hasMoreThanOneCompany) {
						await new Promise<void>(resolve => {
							this._ss.GetComppanys().subscribe({
								next: res => {
									if (typeof res === 'string') {
										return resolve();
									}

									this._debtByDate.companies = res.recordset;
									return resolve();
								},
								error: () => {},
							});
						});
					}

					if (mode === 'by-create') {
						this._debtByDate.allDebtsByCreate = res.recordset;
						if (this.First) this.refreshSubjects('allDebtsByCreate');
						else this.refreshSubjects('allClientsByCreate');
					}

					if (mode === 'by-pay') {
						this._debtByDate.allDebtsByPay = res.recordset;
						if (this.First) this.refreshSubjects('allDebtsByPay');
						else this.refreshSubjects('allClientsByPay');
					}

					this.refreshSubjects('allDebtsSum');
					this._loading.changeLoadingStatuses({ isLoading: false });

					setTimeout(() => {
						this.handleScrollToRelevantDate();
					}, 0);
				},
				error: err => {
					alert('ישנה תקלת שרת');
					history.back();
				},
			});
	}

	handleScrollToRelevantDate(month = new Date().getMonth() + 1, year = new Date().getFullYear()) {
		const nodes = this.row.toArray();

		const relevantRow = nodes.find(node => node.nativeElement.children[0].textContent === `${month}-${year}`);

		if (relevantRow) {
			relevantRow.nativeElement.scrollIntoView({ block: 'center' });
		}
	}

	setupFormFunctionality() {
		this._debtByDate.debtDateControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: val => {
				if (this.First) {
					if (val === 'by-create') {
						if (!this._debtByDate.allDebtsByCreate?.length) {
							this.getByDate('by-create');
						} else {
							this.refreshSubjects('allDebtsByCreate');
						}
					} else {
						this.refreshSubjects('allDebtsByPay');
					}
					this.refreshSubjects('allDebtsSum');
				}

				if (this.Second) {
					if (val === 'by-create') {
						if (!this._debtByDate.allDebtsByCreate?.length) {
							this.getByDate('by-create');
						} else {
							this.refreshSubjects('allClientsByCreate');
						}
					} else {
						this.refreshSubjects('allClientsByPay');
					}
					this.refreshSubjects('allDebtsSum');
				}
			},
		});
		this._debtByDate.companyControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this._debtByDate.debtDateControl.setValue(this._debtByDate.debtDateControl.value);
			},
		});
	}
}
