import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject } from 'rxjs';
import {
	RallyanceReclamationNavigation,
	RallyanceReclamationReasons,
	RallyanceReclamationReclamatorPatchBody,
	RallyanceReclamationRekStatuses,
	RallyanceReclamationSQLResponse,
	RALLYANCE_RECLAMATION_ROUTES,
} from '../Interfaces/reclamations';
import { StoreService } from './store.service';

export const RALLYANCE_RECLAMATION_APP_PATH = 'reclamation-rallyance' as const;

@Injectable({
	providedIn: 'root',
})
export class ReclamationService {
	constructor(private _ss: StoreService, private router: Router) {}

	private readonly appPath = RALLYANCE_RECLAMATION_APP_PATH;

	public readonly path = 'mobil/reclamation-rallyance';
	public readonly reasonsStorageName = 'RecReason';

	allTasks$ = new BehaviorSubject<RallyanceReclamationSQLResponse[]>([]);
	selectedTask$ = new BehaviorSubject<RallyanceReclamationSQLResponse | null>(null);
	sugRecReasons$ = new BehaviorSubject<RallyanceReclamationReasons[]>([]);

	readonly rallyanceReclamationRekStatusesDictionary = {
		noStatus: '0',
		notApproved: '1',
		approved: '2',
		sentForClaim: '3',
	} as const;

	getReclamationTasks() {
		return this._ss.get<RallyanceReclamationSQLResponse>(`${this.path}/for-reclamator`);
	}

	getReclamationReasons({ force }: { force?: boolean } = {}) {
		if (this.sugRecReasons$.value.length && !force) {
			return;
		}
		const reasons = localStorage.getItem('RecReason');

		if (!reasons) {
			console.log('no reasons');
			return;
		}

		const parsed: RallyanceReclamationReasons[] = JSON.parse(decompress(reasons));

		if (!parsed.length) {
			return;
		}

		this.sugRecReasons$.next(parsed);
	}

	init() {
		this.getReclamationReasons({ force: true });
	}

	navigateToReclamatorTasks = (whereTo: typeof RALLYANCE_RECLAMATION_ROUTES[number]) => this.router.navigate([this.appPath, whereTo]);

	resetTask = () => this.selectedTask$.next(null);
	selectNewTask = (task: RallyanceReclamationSQLResponse) => this.selectedTask$.next(task);

	getAllReclamationReasons() {
		return this._ss.get<RallyanceReclamationReasons>(`${this.path}/reasons`);
	}

	sendReclamationTaskAsReclamator(task: RallyanceReclamationReclamatorPatchBody, TeudaNum: string) {
		return this._ss.patch(`${this.path}/${TeudaNum}`, task);
	}

	resetState() {
		this.selectedTask$.next(null);
		this.allTasks$.next([]);
	}
}
