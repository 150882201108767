import { Pipe, PipeTransform } from '@angular/core';
import { LikutKabalaStatus, LikutStatus } from '../Interfaces/likut';
import { TruckModeStatuses } from '../Interfaces/truck-loading';
import { AdminTypes } from '../Interfaces/users';
import { LIKUT_ADMIN_TYPES } from '../services/likut.service';
import { TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES } from '../services/truck-loading.service';

@Pipe({
	name: 'taskTextVisibility',
})
export class TaskTextVisibilityPipe implements PipeTransform {
	transform(
		taskStatus: TruckModeStatuses | LikutStatus | LikutKabalaStatus | null | undefined,
		agentAdminType: typeof LIKUT_ADMIN_TYPES[number] | typeof TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES[number] | AdminTypes | null,
	): boolean {
		switch (agentAdminType) {
			case 'mekabel':
				return taskStatus === '1' || taskStatus === '2' || taskStatus === '5';
			case 'melaket':
			case 'melaketm':
			case 'melaketa':
				return taskStatus === '2' || taskStatus === '6';
			case 'checker':
			case 'checkerm':
				return taskStatus === '3' || taskStatus === '4' || taskStatus === '6';
			case 'loadingmanager':
			case 'maamis':
			case 'nahag':
			case 'nahagmaamis':
			case 'podmanager':
				return taskStatus === '1' || taskStatus === '3';
			default:
				return false;
		}
	}
}
