import { Pipe, PipeTransform } from '@angular/core';
import { TruckDistributionTaskDisplayWithAssignment } from '../Interfaces/truck-distribution';
import { TruckDistributionService } from '../services/truck-distribution.service';

@Pipe({
	name: 'taskAssignmentExtraDisplay',
})
export class TaskAssignmentExtraDisplayPipe implements PipeTransform {
	constructor(private _truckDistribution: TruckDistributionService) {}

	transform(value: TruckDistributionTaskDisplayWithAssignment): string {
		let output = '';

		for (const number of this._truckDistribution.reportShowExtras) {
			const text = value['Extra'+number];

			output += text ? ' |   '+text : '';
		}

		return output;
	}
}
