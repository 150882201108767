import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { decompress } from 'lz-string';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, delay, map, Observable, shareReplay, startWith, Subject, takeUntil } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
// import { Users } from 'src/app/Interfaces/users';
import { specialMock } from './specialMock';
// import { allocationMock } from './allocationMock';
import { specialPostBody, specialPatchBodyEdit, Special, SpecialObjectHash, typeOfSpecials, SpecialBonusType, specialsTypesEnum } from './../../Interfaces/special';
import {
	AllClients,
	AllProducts,
	DocTypesNumToName,
	mustIncludeActions,
	mustIncludeOperators,
	MustIncludeProducts,
	MustIncludeProductsActions,
	MustIncludeProductsJSONPart,
	MustIncludeProductsOperators,
	MustIncludeProductsParsed,
	MustIncludeProductsPOSTBody,
} from './../../Interfaces/SQL-morning-responses';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TextService } from 'src/app/services/text.service';
import { Users } from 'src/app/Interfaces/users';
import { MustIncludeProductsForm, MustIncludeProductsService } from 'src/app/services/must-include-products.service';
import { ValidateArrayLength, validateThatIsNumber } from 'src/app/Utility/FormValidators';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment';
import { SliderToggleChangeEvent } from '../slider-toggle/slider-toggle.component';

type ArrayValues = 'agents' | 'clients' | 'productsA' | 'productsB';
interface Product extends AllProducts {
	TeudaQuantity: number;
	TeudaPackQuantity: number;
	TeudaBonusQuantity: number;
	TeudaItemNetPrice: number;
}

// export type Allocation = {
// 	id: number;
// 	company: number;
// 	agent: string;
// 	productID: string;
// 	units: number;
// 	total: number;
// 	startDate: Date;
// 	endDate: Date;
// 	status: '0' | '1' | '2';
// };

// type numberOrNull = number | null;

@Component({
	selector: 'app-specials',
	templateUrl: './specials.component.html',
	styleUrls: ['./specials.component.scss'],
})
export class SpecialsComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		private router: Router,
		private _fb: FormBuilder,
		public _date: DateAdapter<any, 'he-il'>,
		private _snackBar: MatSnackBar,
		public _text: TextService,
		private _mustIncludeProducts: MustIncludeProductsService,
		private _alert: AlertService,
	) {}

	allSpecials: Special[] = [];
	allSpecials$ = new BehaviorSubject<Special[]>([]);

	allProducts: Product[] = [];
	allProductsSearch$ = new Observable<Product[]>();

	allMustIncludeProducts$ = new BehaviorSubject<MustIncludeProductsParsed[]>([]);
	allMustIncludeProductsDisplay$ = this.allMustIncludeProducts$.pipe(
		// map(products => {
		// 	return products;
		// }),
		shareReplay(1),
	);

	isSpecialsMode = location.pathname.includes('specials');
	isMustIncludeProductsMode = location.pathname.includes('must-include');
	pageMode$ = new BehaviorSubject<'specials' | 'must-include'>('specials');
	title$ = this.pageMode$.pipe(
		map(mode => {
			switch (mode) {
				case 'specials':
					return 'ניהול מבצעים';
				case 'must-include':
					return 'ניהול מוצרי חובה';
				default:
					return '';
			}
		}),
	);
	openDrawerTitle$ = this.pageMode$.pipe(
		map(mode => {
			switch (mode) {
				case 'specials':
					return 'מבצע חדש';
				case 'must-include':
					return 'מוצרי חובה';
				default:
					return '';
			}
		}),
	);

	loading = true;

	specialSuccess = false;

	year = 31_104_000_000;

	dates = { min: new Date(), max: new Date(new Date().getTime() + this.year * 10) }; // 10 years max

	// agents: Users[] = [];

	destroy$: Subject<void> = new Subject();

	// clients = new FormControl<string[]>([],Validators.required);
	products = new FormControl<Product | string>('');

	selectedProducts = new FormControl<(Product & { bonusType: SpecialBonusType })[]>([], { nonNullable: true, validators: ValidateArrayLength });
	specialStatusControl = new FormControl<0 | 1 | 2>(0, { nonNullable: true, validators: Validators.required });

	isEdittingSpecial = false;

	isProductsPopupOpen = false;

	allowedCompanies = [1, 2];

	companyNum: number = 1;

	multiCompany = false;

	canPickSpecialsType = this._ss.CustomerInformation?.HasSpecialsType === '1';
	canPickBonusType = this._ss.CustomerInformation?.HasSpecialsTypeBonusPacks === '1' || environment.development;
	typeOfSpecials = typeOfSpecials.filter(item => item.value !== specialsTypesEnum.BUY_GET); // TODO implement buy get

	canSeeAddNewProductButton = true;

	form = this._fb.nonNullable.group({
		Name: ['', Validators.required],
		SpecialDescription: ['', Validators.required],
		StartDate: [this.dates.min, Validators.required],
		EndDate: [this.dates.min, Validators.required],
		Status: this.specialStatusControl,
		Company: [this.companyNum, [Validators.required, isCompanyValid(this.allowedCompanies)]],
		MaxInCart: [0, Validators.min(0)],
		selectedProducts: this.selectedProducts,
		SpecialType: [0],
	});

	mustIncludeForm = this._fb.nonNullable.group({
		clients: [<string[]>[]],
		agents: [<string[]>[]],
		doctypes: [<number[]>[]],
		operator: new FormControl<MustIncludeProductsOperators>('gt', { nonNullable: true, validators: Validators.required }),
		productsA: new FormControl<string[]>([], { nonNullable: true, validators: [ValidateArrayLength, Validators.required] }),
		productsB: new FormControl<string[]>([], { nonNullable: true, validators: [ValidateArrayLength, Validators.required] }),
		quantity: [<number>null, [Validators.required, Validators.min(1), validateThatIsNumber]],
		action: [<MustIncludeProductsActions>'', Validators.required],
		shouldAffectAllClients: [true],
		shouldAffectAllAgents: [true],
		shouldAffectAllDocTypes: [true],
		clientsSearch: [''],
		agentsSearch: [''],
		doctypesSearch: [''],
		productsASearch: [''],
		productsBSearch: [''],
	});
	mustIncludeOperators = mustIncludeOperators;
	mustIncludeActions = mustIncludeActions;
	shouldShowClientAutocomplete$ = this.mustIncludeForm.controls.shouldAffectAllClients.valueChanges.pipe(
		delay(0),
		startWith(this.mustIncludeForm.controls.shouldAffectAllClients.value),
		map(val => !val),
	);
	shouldShowAgentAutocomplete$ = this.mustIncludeForm.controls.shouldAffectAllAgents.valueChanges.pipe(
		startWith(this.mustIncludeForm.controls.shouldAffectAllAgents.value),
		map(val => !val),
	);

	edittedIDMustIncludeProduct: number | null = null;

	allClients$ = new BehaviorSubject<AllClients[]>([]);
	allAgents$ = new BehaviorSubject<Users[]>([]);
	allDoctypes$ = new BehaviorSubject<DocTypesNumToName[]>([]);
	allProductsA$ = new BehaviorSubject<AllProducts[]>([]);
	allProductsB$ = new BehaviorSubject<AllProducts[]>([]);

	allClientsDisplay$ = combineLatest([this.mustIncludeForm.controls.clientsSearch.valueChanges.pipe(startWith('')), this.allClients$]).pipe(
		map(([value, clients]) => {
			return (value ? clients.filter(client => client.Client_Name.toLowerCase().includes(value.toLowerCase()) || client.ClientId.toLowerCase().includes(value.toLowerCase())) : clients).slice(
				0,
				50,
			);
		}),
		map(value => value.filter(v => !this.mustIncludeForm.controls.clients.value.includes(v.ClientId))),
	);

	allAgentsDisplay$ = combineLatest([this.mustIncludeForm.controls.agentsSearch.valueChanges.pipe(startWith('')), this.allAgents$]).pipe(
		map(([value, agents]) => {
			return (value ? agents.filter(agent => agent.UserName.toLowerCase().includes(value.toLowerCase()) || agent.AgentID.toLowerCase().includes(value.toLowerCase())) : agents).slice(0, 50);
		}),
		map(value => value.filter(v => !this.mustIncludeForm.controls.agents.value.includes(v.AgentID))),
	);

	productsADisplay$ = combineLatest([
		this.mustIncludeForm.controls.productsASearch.valueChanges.pipe(startWith('')),
		this.allProductsA$,
		this.mustIncludeForm.controls.productsBSearch.valueChanges.pipe(startWith('')),
	]).pipe(
		map(([value, products]) => {
			return (
				value ? products.filter(product => product.Product_Name.toLowerCase().includes(value.toLowerCase()) || product.ProductId.toLowerCase().includes(value.toLowerCase())) : products
			).slice(0, 50);
		}),
		map(value => value.filter(v => !this.mustIncludeForm.controls.productsA.value.includes(v.ProductId) && !this.mustIncludeForm.controls.productsB.value.includes(v.ProductId))),
	);

	productsBDisplay$ = combineLatest([
		this.mustIncludeForm.controls.productsBSearch.valueChanges.pipe(startWith('')),
		this.allProductsB$,
		this.mustIncludeForm.controls.productsASearch.valueChanges.pipe(startWith('')),
	]).pipe(
		map(([value, products]) => {
			return (
				value ? products.filter(product => product.Product_Name.toLowerCase().includes(value.toLowerCase()) || product.ProductId.toLowerCase().includes(value.toLowerCase())) : products
			).slice(0, 50);
		}),
		map(value => value.filter(v => !this.mustIncludeForm.controls.productsB.value.includes(v.ProductId) && !this.mustIncludeForm.controls.productsA.value.includes(v.ProductId))),
	);

	// TODO implement doctypes
	// allDoctypesDisplay$ = '';

	shouldAllowAgentSelection = this._mustIncludeProducts.showAgentSelectionInManagement;
	shouldAllowDoctypeSelection = this._mustIncludeProducts.showDoctypeSelectionInManagement;

	addProductPressed = false;

	shouldShowNoProductsError = false;

	currentlyEdittedSpecial: Special[] | null = null;

	isLoadingProgressBarShowing = false;

	ngOnInit(): void {
		this.pageMode$.next(this.isMustIncludeProductsMode ? 'must-include' : 'specials');
		this._date.setLocale('he-il');
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation')) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			} else {
				this.router.navigate(['login']);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (this._ss.CustomerInformation.HasMultipleCompanies === '1') {
			this.multiCompany = true;
			this.companyNum = 0;
		}

		this.populateData();

		this.handleFormInteractivity();

		if (this.isSpecialsMode) {
			this.getAllSpecials();
		}

		if (this.isMustIncludeProductsMode) {
			this.getMustIncludeProducts();
		}

		// if (this.isAllocationPage) {
		// 	this.allAllocations$.next(this.allAllocations);
		// }

		this._ss.CompanyNumForDuplicate = null;

		window.scrollTo(0, 0);

		this._ss.Betipul = 0;
		this._ss.TeudotDetailsEach = [];
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		if (this.isSpecialsMode) {
			this.refreshSpecials();
		}
	}

	private _filter(name: string): Product[] {
		const filterValue = name.toLowerCase();

		const returnArray = [];
		const cutoff = 100;

		for (let i = 0; i < this.allProducts.length; i++) {
			if (returnArray.length > cutoff) break;
			const product = this.allProducts[i];
			if (product.Product_Name.toLowerCase().includes(filterValue) || product.ProductId.toLowerCase().includes(filterValue)) {
				returnArray.push(product);
			}
		}

		return returnArray;
	}

	populateData() {
		const clients = localStorage.getItem('AllClients'),
			products = localStorage.getItem('AllProducts');

		if (!clients || !products) {
			alert('אנא עדכן נתונים');
			this.router.navigate(['home']);
			return;
		}

		this.allClients$.next(JSON.parse(decompress(clients)));

		this.allProducts = JSON.parse(decompress(products));
		const filteredProducts = this.allProducts.filter(product => product.company === this.companyNum);

		this.allProducts = filteredProducts;

		if (this.isSpecialsMode) return;
		this.allProductsA$.next([...filteredProducts]);
		this.allProductsB$.next([...filteredProducts]);
	}

	handleFormInteractivity() {
		if (this.isMustIncludeProductsMode) {
			combineLatest([
				this.mustIncludeForm.controls.shouldAffectAllAgents.valueChanges.pipe(startWith(true)),
				this.mustIncludeForm.controls.shouldAffectAllDocTypes.valueChanges.pipe(startWith(true)),
				this.mustIncludeForm.controls.shouldAffectAllClients.valueChanges.pipe(startWith(true)),
			])
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: newValuesArray => {
						const arrayToValues = ['agents', 'doctypes', 'clients'];
						for (const index in arrayToValues) {
							const value = newValuesArray[index];
							const control = this.mustIncludeForm.controls[arrayToValues[index]];
							if (value) {
								control.disable();
								control.setValue([]);
								continue;
							}
							control.enable();
							control.setValidators([Validators.required, ValidateArrayLength]);
						}
					},
				});
			return;
		}
		this.allProductsSearch$ = this.products.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.Product_Name;
				if (this.form.controls.SpecialType.value === specialsTypesEnum.BUY_GET_MAIN_PRODUCT) {
					return name ? this._filter(name as string).filter(product => product.IsMainProduct === 1) : this.allProducts.filter(product => product.IsMainProduct).slice(0, 50);
				}

				if (this.form.controls.SpecialType.value === specialsTypesEnum.BUY_GET) {
					return name ? this._filter(name as string).filter(product => !product.IsMainProduct) : this.allProducts.filter(product => !product.IsMainProduct).slice(0, 50);
				}
				return name ? this._filter(name as string) : this.allProducts.filter(product => product.company === this.form.controls.Company.value).slice(0, 50);
			}),
		);

		this.form.controls.Status.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: val => {
				if (val === 2) {
					this.form.controls.StartDate.reset();
					this.form.controls.StartDate.disable();
					this.form.controls.EndDate.reset();
					this.form.controls.EndDate.disable();
					return;
				}

				this.form.controls.StartDate.enable();
				this.form.controls.EndDate.enable();
			},
		});

		this.form.controls.Company.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.selectedProducts.setValue([]);
		});

		this.form.controls.SpecialType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: val => {
				this.selectedProducts.reset();
			},
		});
	}

	displayFn(product: any): string {
		return product && product.Product_Name && product.ProductId ? `${product.ProductId} - ${product.Product_Name}` : '';
	}

	handleProductSelect(event: MatAutocompleteSelectedEvent): void {
		this.selectedProducts.setValue([
			...this.selectedProducts.value,
			{ ...event.option.value, TeudaBonusQuantity: 0, TeudaPackQuantity: 0, TeudaQuantity: 0, TeudaItemNetPrice: event.option.value.Price, bonusType: 'single' },
		]);
		this.products.reset();
		this.addProductPressed = false;
	}

	// productsForm = new FormGroup({})

	productInputsChanged(origin: 'TeudaQuantity' | 'TeudaPackQuantity' | 'TeudaBonusQuantity' | 'TeudaItemNetPrice', event: KeyboardEvent, i) {
		const isNum = /\d/.test(event.key);
		// console.log(isNum);
		if (!isNum && event.key !== '.') {
			if (event.target instanceof HTMLInputElement) {
				event.target.value = null;
				this.selectedProducts.updateValueAndValidity({ onlySelf: true, emitEvent: false });
			}
			return;
		}

		this.selectedProducts.value[i][origin] = +(event.target as HTMLInputElement).value;

		this.selectedProducts.updateValueAndValidity({ onlySelf: true, emitEvent: false });
	}

	getAllSpecials() {
		this.loading = true;

		this._ss.getAllSpecials().subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת תקשורת');
					return;
				}

				this.allSpecials = res.recordset;
				console.log(res);
				this.refreshSpecials();
			},
		});
	}

	getMustIncludeProducts() {
		this.loading = true;
		this._mustIncludeProducts.getMustIncludeProducts().subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
					history.back();
					return;
				}

				const parseOrEmpty = (str: string) => {
					try {
						const object = JSON.parse(str);
						return object;
					} catch (error) {
						return {};
					}
				};

				this._mustIncludeProducts.replaceStoredMustIncludeProducts(res.recordset);

				this.allMustIncludeProducts$.next(res.recordset.map(item => ({ ...item, JSON: parseOrEmpty(item.JSON) })));

				this.loading = false;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
				history.back();
			},
		});
	}

	handleOpenDrawer() {
		if (this.isMustIncludeProductsMode) {
			this.handleOpenEditMustIncludeProduct();
			return;
		}

		this.showHideFilterPopup(true);
	}

	handleOpenEditMustIncludeProduct(product?: MustIncludeProductsParsed) {
		setTimeout(() => {
			this.isEdittingSpecial = !!product;
			if (product) {
				this.edittedIDMustIncludeProduct = product.id;
				this.mustIncludeForm.controls.agents.disable();
				this.mustIncludeForm.controls.shouldAffectAllAgents.disable();
				this.mustIncludeForm.controls.agentsSearch.disable();
				this.mustIncludeForm.controls.clients.disable();
				this.mustIncludeForm.controls.clientsSearch.disable();
				this.mustIncludeForm.controls.shouldAffectAllClients.disable();
				this.mustIncludeForm.controls.doctypes.disable();
				this.mustIncludeForm.controls.doctypesSearch.disable();
				this.mustIncludeForm.controls.shouldAffectAllDocTypes.disable();
				if (product.agent) {
					this.mustIncludeForm.controls.shouldAffectAllAgents.setValue(false);
					this.mustIncludeForm.controls.agents.setValue([product.agent]);
				}

				if (product.clientid) {
					this.mustIncludeForm.controls.shouldAffectAllClients.setValue(false);
					this.mustIncludeForm.controls.clients.setValue([product.clientid]);
				}

				if (product.doctype) {
					this.mustIncludeForm.controls.shouldAffectAllDocTypes.setValue(false);
					this.mustIncludeForm.controls.doctypes.setValue([product.doctype]);
				}

				this.mustIncludeForm.controls.operator.setValue(product.JSON.operator);
				this.mustIncludeForm.controls.productsA.setValue(product.JSON.productsA);
				this.mustIncludeForm.controls.productsB.setValue(product.JSON.productsB);
				this.mustIncludeForm.controls.quantity.setValue(product.JSON.quantity);
				this.mustIncludeForm.controls.action.setValue(product.JSON.action);
			}
		}, 0);

		this.form.disable();
		this.showHideFilterPopup(true);
	}

	async handleConfirmDeleteMustIncludeProduct(product: MustIncludeProductsParsed) {
		const result = await this._alert.customConfirmAlert('האם למחוק שורה זו? שים לב, פעולה זו בלתי הפיכה');

		if (!result) return;

		this.loading = true;

		this._mustIncludeProducts.handleDELETEMustIncludeProducts(product.id).subscribe({
			next: res => {
				this.loading = false;
				if (typeof res === 'string') {
					alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
					return;
				}

				this._snackBar.open('שורה נמחקה בהצלחה', 'סגור', { duration: 3000 });

				this.getMustIncludeProducts();
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
				this.loading = false;
			},
		});
	}

	async handleConfirmEditMustIncludeProduct() {
		const result = await this._alert.customConfirmAlert('האם לעדכן שורה זו?');

		if (!result) return;

		this.loading = true;

		const body: Partial<MustIncludeProductsPOSTBody> = {
			JSON: JSON.stringify({
				productsA: this.mustIncludeForm.controls.productsA.value,
				productsB: this.mustIncludeForm.controls.productsB.value,
				operator: this.mustIncludeForm.controls.operator.value,
				quantity: this.mustIncludeForm.controls.quantity.value,
				action: this.mustIncludeForm.controls.action.value,
			}),
		};

		this._mustIncludeProducts.handlePATCHMustIncludeProducts(this.edittedIDMustIncludeProduct, body).subscribe({
			next: res => {
				this.loading = false;
				if (typeof res === 'string') {
					alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
					return;
				}

				this._snackBar.open('שורה עודכנה בהצלחה', 'סגור', { duration: 3000 });

				this.closeDrawer();

				this.getMustIncludeProducts();
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
				this.loading = false;
			},
		});
	}

	// handleEditExistingSpecial(id: Special['SpecialID']) {
	// 	const special = this.allSpecials.filter(item => item.SpecialID === id);

	// 	this.currentlyEdittedSpecial = special;

	// 	this.form.controls.Name.setValue(special[0].Name);
	// 	this.form.controls.StartDate.setValue(new Date(special[0].StartDate));
	// 	this.form.controls.StartDate.disable();
	// 	this.form.controls.EndDate.setValue(new Date(special[0].EndDate));
	// 	this.form.controls.EndDate.disable();
	// 	this.form.controls.Company.setValue(special[0].Company);
	// 	this.form.controls.Status.setValue(special[0].SpecialStatus);
	// 	this.form.controls.selectedProducts.setValue(
	// 		special.map(item => ({
	// 			...this.allProducts.find(product => product.ProductId === item.ProductID),
	// 			TeudaItemNetPrice: item.TeudaItemNetPrice,
	// 			TeudaQuantity: item.TeudaQuantity,
	// 			TeudaPackQuantity: item.TeudaPackQuantity,
	// 			TeudaBonusQuantity: item.TeudaBonusQuantity,
	// 			ID: item.ID,
	// 		})),
	// 	);
	// 	this.isEdittingSpecial = true;
	// 	this.showHideFilterPopup(true);
	// }

	handleDateChange(val: 'month' | 'year') {
		const start = new Date(this.form.controls.StartDate.value);
		const newDate = new Date(this.form.controls.StartDate.value);

		if (val === 'month') {
			newDate.setMonth(start.getMonth() + 1);
		}

		if (val === 'year') {
			newDate.setFullYear(start.getFullYear() + 1);
		}

		this.form.controls.EndDate.setValue(newDate);
	}

	removeItem(i: number) {
		this.selectedProducts.value.splice(i, 1);
		this.selectedProducts.setValue(this.selectedProducts.value);

		if (!this.selectedProducts.value.length) {
			this.addProductPressed = true;
		}
	}

	// removeItemFromDB(product:any){
	// 	this.loading = true;

	// 	this._ss.deleteItemFromSpecial(product.ID).subscribe({next:res=>{},error:err=>{}})
	// }

	handleAutocompleteSelect(event: MatAutocompleteSelectedEvent, mode: ArrayValues): void {
		this.mustIncludeForm.controls[mode].setValue([...this.mustIncludeForm.controls[mode].value, event.option.value]);
		this.mustIncludeForm.controls[mode + 'Search'].reset();
	}
	chipClicked(i, mode: ArrayValues) {
		if (this.isEdittingSpecial && ['agents', 'clients', 'doctypes'].includes(mode)) return;
		this.mustIncludeForm.controls[mode].value.splice(i, 1);
		this.mustIncludeForm.controls[mode + 'Search'].reset();
		this.mustIncludeForm.controls[mode].updateValueAndValidity();
	}
	handleProductSubmit() {
		this.isProductsPopupOpen = false;
	}

	closeDrawer() {
		this.isEdittingSpecial = false;
		this.form.reset();
		this.mustIncludeForm.reset();
		this.selectedProducts.reset();
		this.specialSuccess = false;
		this.form.enable();
		this.edittedIDMustIncludeProduct = null;
		this.showHideFilterPopup(false);
	}

	activateSpecial(id: number) {
		this.allSpecials = specialMock.map(item => {
			if (item.SpecialID == id) {
				item.SpecialStatus = !item.SpecialStatus;
			}
			return item;
		});
	}

	refreshSpecials() {
		this.loading = true;
		const hash: SpecialObjectHash<Special> = this.allSpecials.reduce((acc, item) => {
			acc[item.SpecialID] = item;
			return acc;
		}, {});

		this.allSpecials$.next(Object.values(hash));

		this.loading = false;
	}

	handleCloseProductsPopup() {
		if (!this.selectedProducts.value?.length) {
			this.isProductsPopupOpen = false;
			return;
		}

		const allZero = this.selectedProducts.value.filter(prd => prd.TeudaBonusQuantity <= 0 && prd.TeudaPackQuantity <= 0 && prd.TeudaQuantity <= 0);
		let msg = 'אחד או יותר מהפריטים  ';
		const originMsg = msg;

		if (allZero.length) {
			msg += 'בכמות 0. ';
		}

		if (this.form.controls.SpecialType.value !== 0) {
			const allZeroBonus = this.selectedProducts.value.some(prd => prd.TeudaBonusQuantity <= 0);
			if (allZeroBonus) {
				msg += 'בכמות בונוס 0. ';
			}
		}

		const illegalQuantity = this.selectedProducts.value.filter(prd => prd.TeudaBonusQuantity < 0 || prd.TeudaPackQuantity < 0 || prd.TeudaQuantity < 0);

		if (illegalQuantity.length) {
			msg += 'בכמות שלילית. ';
		}

		const illegalNumber = this.selectedProducts.value.filter(
			prd => !isFinite(+prd.TeudaBonusQuantity) || !isFinite(+prd.TeudaItemNetPrice) || !isFinite(+prd.TeudaPackQuantity) || !isFinite(+prd.TeudaQuantity),
		);

		if (illegalNumber.length) {
			msg += 'בעייתי';
		}

		if (msg !== originMsg) {
			alert(msg + 'אנא הכניסו כמות או הורידו את הפריט');
			return;
		}

		this.isProductsPopupOpen = false;
	}

	handleProductPopupOpen() {
		this.shouldShowNoProductsError = false;
		if (this.form.controls.Company.invalid) {
			this.form.controls.Company.setErrors({ needsValueForProductPopup: true });
			this.form.controls.Company.markAsTouched();
			return;
		}
		this.isProductsPopupOpen = true;

		this.canSeeAddNewProductButton = this.form.controls.SpecialType.value === 0;
		if (this.form.controls.SpecialType.value !== 0 && !this.selectedProducts.value.length) {
			this.addProductPressed = true;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	showFilterPopup = false;

	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	getDate(val: 'min' | 'max') {
		if (val === 'min') {
			return new Date(this.dates.min);
		}
		return new Date(this.dates.max);
	}

	async handleFormSubmit() {
		this.form.markAllAsTouched();
		if (!this.selectedProducts.value.length) {
			this.shouldShowNoProductsError = true;
			return;
		}
		if (this.form.invalid) return;

		this.loading = true;

		// if (this.isEdittingSpecial) {
		// 	await this.handlePostEditSpecial();
		// 	return;
		// }

		let lastID;

		if (!this.isEdittingSpecial) {
			lastID = await this.getLastID();
		}

		const { Name, Company, EndDate, StartDate, Status, selectedProducts, MaxInCart, SpecialDescription, SpecialType } = this.form.value;

		const body: specialPostBody[] = selectedProducts.map(prd => ({
			Name,
			Company,
			SpecialDescription,
			EndDate: EndDate || new Date(),
			StartDate: StartDate || new Date(),
			MaxInCart: isNaN(+MaxInCart) ? 0 : MaxInCart,
			SpecialID: (+lastID || 0) + 1,
			SpecialStatus: +Status,
			ProductID: prd.ProductId,
			TeudaItemNetPrice: prd.TeudaItemNetPrice,
			TeudaQuantity: prd.TeudaQuantity,
			TeudaBonusQuantity: prd.TeudaBonusQuantity,
			TeudaPackQuantity: prd.TeudaPackQuantity,
			Stock: 0,
			Client_Id: '0',
			SpecialType,
			BonusType: prd.bonusType || 'single',
		}));
		// const body = {Name,Company} = this.form.value

		this._ss.postNewSpecial(body).subscribe({
			next: res => {
				this.loading = false;

				if (res === 'ConnectionError' || res === 'RequestError') {
					console.log(res);
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					this.specialSuccess = false;
					return;
				}

				this.specialSuccess = true;

				this.allSpecials = res.recordset;
				this.form.reset();
				this.refreshSpecials();
			},
			error: err => {
				console.log(err);
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
				this.specialSuccess = this.loading = false;
			},
		});
	}
	async handleMustIncludeFormSubmit() {
		if (this.form.invalid) {
			this.mustIncludeForm.markAllAsTouched();
			return;
		}

		if (this.isEdittingSpecial) {
			await this.handleConfirmEditMustIncludeProduct();
			return;
		}

		this.loading = true;

		const { clients, agents, doctypes, operator, productsA, productsB, quantity, action, shouldAffectAllClients, shouldAffectAllAgents, shouldAffectAllDocTypes } = this.mustIncludeForm.value;

		const jsonBody: MustIncludeProductsJSONPart = {
			action,
			operator,
			productsA,
			productsB,
			quantity,
		};

		const clientsLines = shouldAffectAllClients ? [''] : clients;
		const agentsLines = shouldAffectAllAgents ? [''] : agents;
		const doctypesLines = shouldAffectAllDocTypes ? [0] : doctypes;

		const requestBody: MustIncludeProductsPOSTBody[] = new Array(clientsLines.length * agentsLines.length * doctypesLines.length).fill('').map((_, i) => ({
			clientid: clientsLines[i % clientsLines.length],
			agent: agentsLines[i % agentsLines.length],
			doctype: doctypesLines[i % doctypesLines.length],
			JSON: JSON.stringify(jsonBody),
		}));

		this._mustIncludeProducts.handlePOSTnewMustIncludeProducts({ lines: requestBody }).subscribe({
			next: res => {
				this.loading = false;

				if (res === 'ConnectionError' || res === 'RequestError') {
					console.log(res);
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					this.specialSuccess = false;
					return;
				}

				this.specialSuccess = true;
				this.mustIncludeForm.reset();

				this.getMustIncludeProducts();
			},
			error: err => {
				console.log(err);
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
				this.specialSuccess = this.loading = false;
			},
		});
	}
	// handleAllocationFormSubmit() {
	// 	alert('Form submitted successfully');
	// 	console.log(this.allocationForm.value);
	// }

	// handleAdvancedSearch() {}
	// private _filterClients(name: string): AllClients[] {
	// 	const filterValue = name.toLowerCase();

	// 	return this.allClients.filter(client => client.Client_Name.toLowerCase().includes(filterValue) || client.ClientId.toLowerCase().includes(filterValue)).slice(0, 50);
	// }

	// private _filterAgents(name: string): Users[] {
	// 	console.log(name);
	// 	const filterValue = name.toLowerCase();

	// 	return this.allAgents.filter(ag => ag.UserName.toLowerCase().includes(filterValue) || ag.AgentID.toLowerCase().includes(filterValue)).slice(0, 50);
	// }

	displayFnClients(client: AllClients): string {
		return client && client.Client_Name && client.ClientId ? `${client.ClientId} - ${client.Client_Name}` : '';
	}

	displayFnAgents(agent: Users): string {
		return agent?.UserId && agent?.UserName ? `${agent.UserId} - ${agent.UserName}` : '';
	}

	handlePostEditSpecial(specialID: number, newStatus: number) {
		return new Promise<void>((resolve, reject) => {
			const body: specialPatchBodyEdit = {
				SpecialID: specialID,
				SpecialStatus: newStatus,
			};
			this._ss.patchEditSpecial(body).subscribe({
				next: res => {
					this.loading = false;

					if (res === 'ConnectionError' || res === 'RequestError') {
						console.log(res);
						alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
						return reject();
					}

					this.specialSuccess = true;

					this.allSpecials = res.recordset;
					this.refreshSpecials();
					resolve();
				},
				error: err => {
					console.log(err);
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					// this.specialSuccess = this.loading = false;
					reject();
				},
			});
		});
	}

	async handleChangeSpecialStatus(e: MatButtonToggleChange, specialID: number, status: number) {
		const newStatus = e.value;
		// e.source.checked = false;
		e.source.buttonToggleGroup.value = status;

		if (this.isLoadingProgressBarShowing) return;

		this.isLoadingProgressBarShowing = true;

		try {
			await this.handlePostEditSpecial(specialID, newStatus);
			this.isLoadingProgressBarShowing = false;
			e.source.buttonToggleGroup.value = newStatus;

			this._snackBar.open('סטטוס מבצע הוחלף בהצלחה', undefined, {
				duration: 3000,
				direction: 'rtl',
				panelClass: ['snack-bar'],
			});
		} catch (error) {
			return;
		}
	}

	operatorToText(operator: MustIncludeProductsOperators) {
		switch (operator) {
			case 'gt':
				return 'גדול מ';
			case 'lt':
				return 'קטן מ';
			case 'gtTotal':
				return 'גדול מ סך הכל';
			case 'ltTotal':
				return 'קטן מ סך הכל';
			default:
				return '';
		}
	}

	actionToText(action: MustIncludeProductsActions) {
		switch (action) {
			case 'alert':
				return 'התראה';
			case 'stop':
				return 'עצירה';
			default:
				return '';
		}
	}

	submitButtonText() {
		switch (this.pageMode$.value) {
			case 'specials':
				return this.isEdittingSpecial ? 'ערוך' : 'הוסף';
			case 'must-include':
				return this.isEdittingSpecial ? 'ערוך' : 'הוסף';
			default:
				return '';
		}
	}

	openedDrawerTitleText() {
		switch (this.pageMode$.value) {
			case 'specials':
				return this.isEdittingSpecial ? 'עריכת מבצע' : 'הוספת מבצע חדש';
			case 'must-include':
				return this.isEdittingSpecial ? 'עריכת כללי כלול' : 'הוספת כללי כלול חדש';
			default:
				return '';
		}
	}

	BackToDohot() {
		history.back();
	}

	async getLastID() {
		return await new Promise<void | number>(resolve => {
			this._ss.getLastSpecial().subscribe({
				next: (res: any) => {
					console.log(res);
					return resolve(res.recordset[0].SpecialID);
				},
				error: err => {
					console.log(err);
					return resolve();
				},
			});
		});
	}

	log(content) {
		console.log(content);
	}

	toggleProductBonusType(i: number, newValue: SliderToggleChangeEvent) {
		const newBonusType = newValue === 'label' ? 'packs' : 'single';
		this.selectedProducts.value[i].bonusType = newBonusType;
		this.selectedProducts.setValue(this.selectedProducts.value);
	}
}

function isCompanyValid(allowedCompanies: number[]) {
	return (control: AbstractControl<number | null>) => {
		// No 0 company should exist
		if (!control.value) {
			return null;
		}

		if (!allowedCompanies.includes(control.value)) {
			return { companyNotInAllowedValues: true };
		}

		return null;
	};
}
