import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'special-dialog',
	templateUrl: 'special-dialog.component.html',
	styleUrls: ['../products/products.component.scss'],
})
export class SpecialInfoDialog {
	constructor(public dialogRef: MatDialogRef<SpecialInfoDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public _ss: StoreService) {
		if (data) {
			this.total = data.special.reduce((prev, curr) => prev + curr.TeudaItemNetPrice * (curr.TeudaQuantity + curr.TeudaPackQuantity * curr.ItemPack), 0);
		}
	}

	total = 0;

	onNoClick(): void {
		this.dialogRef.close();
	}
}
