<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">פירוט תעודה</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- client info for all client profile page -->
		<section class="client-info" style="margin-top: 100px">
			<div class="content">
				<div class="row-title">
					<div class="row-title-right">
						<div class="client-info__title client-info__title_margin col">
							{{ this._ss.OneClientInfo.Client_Name }}
						</div>
						<div class="client-info__number col">
							{{ this._ss.OneClientInfo.ClientId }}
						</div>
					</div>
					<div class="row-title-left justify-align-center" *ngIf="doesPDFExistInServer">
						<div>
							<button style="margin-left: 1em" color="primary" (click)="isEmailPickPopupOpen = true" mat-mini-fab><mat-icon>picture_as_pdf</mat-icon></button>
							שליחה כדוא"ל
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- end of client info for all client profile page -->

		<!-- start markup for client profile order page -->

		<section class="client-details">
			<div class="content">
				<div class="row">
					<div class="client-details__item row">
						<div class="client-details__item-status client-details__item-status_green">סופק</div>
						<div class="client-details__item-row client-details__item-row_margin row">
							<span class="client-details__item-name client-details__item-name_bold">אסמכתא</span>
							<span class="client-details__item-name" dir="ltr">{{ this.EachClientEachOrder[0]?.DocDocNum }} #</span>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title">תאריך אספקה:</span>
							<span class="client-details__item-text client-details__item-text_bold"></span>
							<span class="client-details__item-text">{{ this.EachClientEachOrder[0]?.DocSupplyDate | date : 'dd-MM-yyyy' }}</span>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title">תאריך הזמנה:</span>
							<span class="client-details__item-text client-details__item-text_bold" dir="rtl">{{ this.EachClientEachOrder[0]?.DocCreateDate | date : 'dd-MM-yyyy' }}</span>
						</div>
						<div class="client-details__item-row client-details__item-row_margin row">
							<span class="client-details__item-title">כתובת:</span>
							<span class="client-details__item-text client-details__item-text_bold">{{ addressToDisplay }}</span>
						</div>
						<div class="client-details__item-row client-details__item-row_summ row" style="gap: 0.4em">
							<div class="client-details__item-col col">
								<span class="client-details__item-title">סכום:</span>
								<span class="client-details__item-text client-details__item-text_big client-details__item-text_price">{{
									this.EachClientEachOrder[0]?.DocTotalSum | number : '1.0-2' : 'en-US'
								}}</span>
							</div>
							<div class="client-details__item-col col">
								<span class="client-details__item-title">סכום כולל מע"מ:</span>
								<span class="client-details__item-text client-details__item-text_big client-details__item-text_price">{{
									this.EachClientEachOrder[0]?.DocTotalSumVat | number : '1.0-2' : 'en-US'
								}}</span>
							</div>
							<div class="client-details__item-col col">
								<span class="client-details__item-title">מספר שורות:</span>
								<span class="client-details__item-text client-details__item-text_big">{{ this.EachClientEachOrder.length }}</span>
							</div>
						</div>
					</div>
					<div class="client-details__block" *ngFor="let eceo of EachClientEachOrder">
						<div class="row">
							<h2 class="client__product-title">
								{{ eceo.DocItemName }}
								<span class="client__product-title-brackets" *ngIf="eceo.ItemPack && showItemInPack">{{ eceo.ItemPack }} באריזה</span>
							</h2>
						</div>
						<div class="client__product-block-row client__product-block-row_border row">
							<div class="client__product-block-col client__product-block-col_image image">
								<img src="assets/{{ eceo.DocItemCode }}.jpg" class="ItemPic" onerror="this.onerror=null; this.src='/assets/images/no-product-icon.svg'" alt="photo" />
							</div>
							<div class="client__product-block-col client__product-block-col_text">
								<div class="row">
									<div class="client__product-block-text client__product-block-text_gray">ברקוד:</div>
									<div class="client__product-block-text">לא קיים</div>
								</div>
								<div class="row">
									<div class="client__product-block-text client__product-block-text_gray">קוד פריט:</div>
									<div class="client__product-block-text">{{ eceo.DocItemCode }}</div>
								</div>
							</div>
							<div class="client__product-block-col client__product-block-col_number client__product-block-col_border">
								<div class="row">
									<h3 class="client__product-block-text client__product-block-text_gray">יחידות</h3>
									<div class="client__product-block-text">{{ eceo.DocQuantity }}</div>
								</div>
							</div>
							<div class="client__product-block-col client__product-block-col_number">
								<div class="row">
									<h3 class="client__product-block-text client__product-block-text_gray">{{ _text.packQuantityText }}</h3>
									<div class="client__product-block-text">{{ eceo.DocPackQuantity }}</div>
								</div>
							</div>
						</div>
						<div class="client__product-block-row row">
							<div class="client__product-block-box">
								<div class="client__product-block-box-title">מחיר יחידה</div>
								<div class="client__product-block-box-price client__product-block-box-price_small">{{ eceo.DocPrice | number : '1.0-2' : 'en-US' }}</div>
							</div>
							<div class="client__product-block-box">
								<div class="client__product-block-box-title">מחיר מעודכן</div>
								<div class="client__product-block-box-price client__product-block-box-price_green client__product-block-box-price_small">
									{{ eceo.DocPrice | number : '1.0-2' : 'en-US' }}
								</div>
							</div>
							<div class="client__product-block-box discount">
								<div class="client__product-block-box-title">הנחה</div>
								<div class="client__product-block-box-text">{{ (eceo.DocDiscount | number : '1.0-2' : 'en-US') + (eceo.DocDiscount > 0 ? ' %' : '') }}</div>
							</div>
							<div class="client__product-block-box total">
								<div class="client__product-block-box-title">סה"כ</div>
								<div class="client__product-block-box-price client__product-block-box-price_green">{{ eceo.DocTotalRow | number : '1.0-2' : 'en-US' }}</div>
							</div>
						</div>
						<div class="client__product-block-row">
							<div class="client__product-block-icon image">
								<img src="/assets/images/basket/chat-icon.svg" alt="photo" />
							</div>
							<div class="client__product-block-title">{{ eceo.DocItemRemark }}</div>
						</div>
					</div>

					<!-- <div class="client-details__action row">
        <button class="client-details__block-button">שכפל הזמנה</button>
      </div> -->
				</div>
			</div>
		</section>
		<!-- end markup for client profile order page -->
	</div>
</div>

<div class="popup" *ngIf="isEmailPickPopupOpen" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed wrapper">
		<div class="product-detail__item">
			<div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>

			<div *ngIf="!loadingEmailSend; else loadingBlock">
				<ng-container *ngIf="!emailSentSuccessfully; else sent">
					<h2 class="product-detail-add__popup-title" style="text-align: center">בחר כתובת דוא"ל לשליחה</h2>

					<div style="padding: 1rem">
						<form (ngSubmit)="handleSubmitEmail()" style="display: grid">
							<mat-radio-group color="primary" [ngClass]="{ 'radio-group': _device.isTabletAndUpResolution$ | async }" dir="rtl" [formControl]="emailRadios">
								<mat-radio-button value="client" [disabled]="!clientHasEmail">כתובת לקוח - {{ OneClientInfo.CustEmail || OneClientInfo.CustEMail || 'לא נמצאה כתובת לקוח' }}</mat-radio-button>
								<mat-radio-button value="user" *ngIf="_user.agentEmail$ | async as email">כתובת סוכן - {{ email }}</mat-radio-button>
								<mat-radio-button value="custom">
									<mat-form-field>
										<mat-label>כתובת דוא"ל</mat-label>
										<input matInput (focus)="emailRadios.setValue('custom')" [formControl]="emailInput" dir="rtl" placeholder='כתובת דוא"ל' />
									</mat-form-field>
								</mat-radio-button>
							</mat-radio-group>
							<div style="text-align: center">
								<button mat-raised-button color="primary" [disabled]="emailInput.invalid && emailRadios.value === 'custom'">שלח</button>
							</div>
						</form>
					</div>
				</ng-container>
				<ng-template #sent>
					<h2 style="text-align: center; direction: rtl">דוא"ל נשלח בהצלחה!</h2>
				</ng-template>

				<div class="product-detail-add__popup-row"></div>
			</div>
			<ng-template #loadingBlock>
				<div class="flex justify-align-center">
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
		</div>
	</div>
</div>
