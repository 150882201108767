<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToDohot()" *ngIf="LoadingBack === false">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">{{ isUndelivered ? 'תעודות שלא סופקו' : 'כל התעודות' }}</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>

			<div class="report-table__row">
				<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
					<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="SearchClient()">
						<img src="/assets/images/header-search.svg" alt="search" />
					</button>
					<form [formGroup]="form" (keyup.enter)="SearchClient()">
						<input class="header__search-input" type="text" placeholder="חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
					</form>
					<button class="header__clear-icon" (click)="SearchClear()"></button>
				</div>
				<h3 class="report-table__row__totals" *ngIf="!Loading">
					סה"כ: <span>{{ total$ | async | number : '1.0-2' }}</span>
				</h3>
			</div>

			<h2 style="text-align: center" *ngIf="LoadingBack === true">טוען אנא המתן</h2>
		</header>

		<!-- start markup for client profile page -->
		<!-- client info for all client profile page -->
		<section class="client-info">
			<div class="content">
				<div class="row">
					<div class="client-info__title col">
						{{ this._ss.UserNameConnected }}
					</div>
				</div>
				<div class="client-info__block row">
					<div class="client-info__number col">
						{{ this._ss.AgentIdConnected }}
					</div>
				</div>
			</div>
		</section>

		<section class="report-table" *ngIf="Loading === false && LoadingBack === false" style="margin-top: 40px">
			<div class="content">
				<div style="display: flex; justify-content: space-between; align-items: center">
					<div class="report-table__button" [ngClass]="{ 'report-table__button_active': searchActive }" (click)="showHideFilterPopup(true)">סינון מתקדם</div>
					<div class="pages-div">
						<span class="left-arrow" (click)="changePage('+')" *ngIf="currentPage < Math.ceil(AllClients.length / (+numOfItemsPerPage || 50))"
							><img src="assets/images/dashboard/scroll-arrow.svg" alt="" /></span
						>עמוד {{ (!AllClients.length && '0') || currentPage }} מתוך {{ Math.ceil(AllClients.length / (+numOfItemsPerPage || 50))
						}}<span class="right-arrow" (click)="changePage('-')" *ngIf="currentPage > 1"><img src="assets/images/dashboard/scroll-arrow.svg" alt="" /></span>
					</div>
					<div class="pagination-title">
						<form [formGroup]="form">
							<label for="pagination">מספר שורות לדף</label>
							<select name="pagination" id="pagination" formControlName="Page">
								<option [value]="50">50</option>
								<option [value]="100">100</option>
								<option [value]="200">200</option>
								<option [value]="300">300</option>
								<option [value]="500">500</option>
							</select>
						</form>
					</div>
				</div>

				<div *ngIf="FilterLoading === true">טוען {{ this.form.controls['DocType'].value }} אנא המתן</div>

				<div class="report-table__row report-table__row_between"></div>

				<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
					<table class="report-table__table" cellspacing="0" *ngIf="FilterLoading === false && AllClients.length">
						<thead class="report-table__table-head">
							<tr>
								<th class="report-table__table-head-name" style="width: 150px">קוד סוכן</th>
								<th class="report-table__table-head-name" style="width: 150px">קוד לקוח</th>
								<th class="report-table__table-head-name" style="width: 250px">שם לקוח</th>
								<th class="report-table__table-head-name" style="width: 150px">תאריך</th>
								<th class="report-table__table-head-name" style="width: 150px">מס תעודה</th>
								<th class="report-table__table-head-name" *ngIf="!isUndelivered" style="width: 150px">סוג תעודה</th>
								<th class="report-table__table-head-name" style="width: 150px">סכום</th>
								<th class="report-table__table-head-name" style="width: 150px">הערה</th>
							</tr>
						</thead>
						<tbody class="report-table__table-body">
							<tr
								*ngFor="let ata of AllClients.slice(numOfItemsPerPage * currentPage - numOfItemsPerPage, numOfItemsPerPage * currentPage)"
								(click)="TeudaClicked(ata.DocDocNum, ata.DocDocType, ata.Company, ata.AgentId)"
							>
								<td class="report-table__table-body-name" style="width: 150px">{{ ata.AgentId }}</td>
								<td class="report-table__table-body-name" style="width: 150px">{{ ata.DocCustCode }}</td>
								<td class="report-table__table-body-name" style="width: 250px">{{ ata.Client_Name }}</td>
								<td class="report-table__table-body-name" style="width: 150px">{{ ata.DocCreateDate }}</td>
								<td class="report-table__table-body-name" style="width: 150px">{{ ata.DocDocNum }}</td>
								<td class="report-table__table-body-name" *ngIf="!isUndelivered" style="width: 150px">{{ ata.DocTypeName }}</td>
								<td
									class="report-table__table-body-name"
									[ngStyle]="{
										color: ata.DocTypeName === 'חשבונית מס זיכוי' || ata.DocTypeName === 'החזרה' ? 'red' : null,
										'font-weight': ata.DocTypeName === 'חשבונית מס זיכוי' || ata.DocTypeName === 'החזרה' ? '600' : null
									}"
									style="width: 150px"
									dir="ltr"
								>
									{{ ata.DocTotalSum | number : '1.0-2' }}
								</td>
								<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ ata.DocRemark }}</td>
							</tr>
						</tbody>
					</table>

					<h1 *ngIf="FilterLoading === false && !AllClients.length">אין נתונים להציג</h1>
				</div>
			</div>
		</section>
		<!-- end report table markup -->
		<!-- end markup for client profile order page -->
		<div class="order-process__row order-process__row_success" *ngIf="Loading === true" style="margin-top: 70px">
			<div class="row">
				<h2 class="order-process__title">מעדכן</h2>
			</div>
			<div class="row"></div>
			<div class="row">
				<div class="order-process__image image">
					<img src="/assets/images/basket/process/success.gif" alt="photo" />
				</div>
			</div>
			<div class="row"></div>
		</div>

		<!-- markup for popup -->
		<div class="popup" *ngIf="UpdateSystem === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">לעדכן תעודות ?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send">לא, חזור</button>
					<button class="order-cart__popup-button">כן, עדכן</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->
	</div>
</div>

<!-- markup for sort popup -->
<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="report-table-popup report-table-popup_sort popup__content">
		<div class="popup__close" (click)="showHideFilterPopup(false)"></div>
		<div class="report-table-popup__header" [ngClass]="{ 'report-table-popup__header_active': searchActive }">
			<div class="report-table-popup__header-search">
				<!-- <input
          type="text"
          class="report-table-popup__header-input"
          placeholder="חפש שם או קוד..."

        > -->
				<!-- <button
          class="report-table-popup__header-search-clear"

        ></button> -->
				<button class="report-table-popup__header-search-button" *ngIf="searchActive"></button>
			</div>
			<div class="report-table-popup__header-title" *ngIf="!searchActive">סינון מתקדם</div>
		</div>
		<div class="report-table-popup__wrapper" *ngIf="!searchActive">
			<div class="report-table-popup__content">
				<!-- <label class="report-table-popup__label_all custom-checkbox">
          <input type="checkbox">
          <span class="custom-checkbox__checkmark"></span>
          <div class="report-table-popup__label-title">הצג הכל</div>
        </label> -->
				<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding">
					<span class="report-table-popup__content-title">בחירת תקופה</span>
				</div>
				<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
					<div class="report-table-popup__box widen">
						<div class="report-table-popup__row">
							<label class="report-table-popup__label custom-checkbox" (click)="changeDateSort(0)">
								<!-- <input type="checkbox">
                <span class="custom-checkbox__checkmark"></span> -->
								<div class="report-table-popup__label-title">הצג הכל</div>
							</label>
							<span class="active-sort" *ngIf="dateSortArr[0].active"><img src="assets/images/radio-button-active.svg" alt="" /></span>
						</div>
						<div class="report-table-popup__row" (click)="changeDateSort(1)">
							<label class="report-table-popup__label custom-checkbox">
								<!-- <input type="checkbox">
                <span class="custom-checkbox__checkmark"></span> -->
								<div class="report-table-popup__label-title">20 אחרונים</div>
							</label>
							<span class="active-sort" *ngIf="dateSortArr[1].active"><img src="assets/images/radio-button-active.svg" alt="" /></span>
						</div>
						<div class="report-table-popup__row" (click)="changeDateSort(2)">
							<label class="report-table-popup__label custom-checkbox">
								<!-- <input type="checkbox">
                <span class="custom-checkbox__checkmark"></span> -->
								<div class="report-table-popup__label-title">מתחילת החודש</div>
							</label>
							<span class="active-sort" *ngIf="dateSortArr[2].active"><img src="assets/images/radio-button-active.svg" alt="" /></span>
						</div>

						<div class="report-table-popup__row" (click)="changeDateSort(3)">
							<label class="report-table-popup__label custom-checkbox">
								<!-- <input type="checkbox">
                <span class="custom-checkbox__checkmark"></span> -->
								<div class="report-table-popup__label-title">חודש קודם</div>
							</label>
							<span class="active-sort" *ngIf="dateSortArr[3].active"><img src="assets/images/radio-button-active.svg" alt="" /></span>
						</div>
						<div class="report-table-popup__row" (click)="changeDateSort(4)">
							<label class="report-table-popup__label custom-checkbox">
								<!-- <input type="checkbox">
                <span class="custom-checkbox__checkmark"></span> -->
								<div class="report-table-popup__label-title">30 יום</div>
							</label>
							<span class="active-sort" *ngIf="dateSortArr[4].active"><img src="assets/images/radio-button-active.svg" alt="" /></span>
						</div>
					</div>
					<div class="report-table-popup__box">
						<!-- <div class="report-table-popup__row">
              <label class="report-table-popup__label custom-checkbox">
                <input type="checkbox" (change)="toogleInputDate = !toogleInputDate" checked="checked">
                <span class="custom-checkbox__checkmark"></span>
                <div class="report-table-popup__label-title">בין תאריכים</div>
              </label>
              <div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding">
                <span class="report-table-popup__content-title">בין תאריכים</span>
              </div>
            </div> -->
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label" [ngClass]="{ 'report-table-popup__label_disabled': toogleInputDate }">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">מתאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="FromDate" (change)="handleDateChange('from')" />
								</form>
							</label>
						</div>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label" [ngClass]="{ 'report-table-popup__label_disabled': toogleInputDate }">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">עד תאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="ToDate" (change)="handleDateChange('to')" />
								</form>
							</label>
						</div>

						<ng-container *ngIf="!isUndelivered">
							<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
								<span class="report-table-popup__content-title">סוג תעודה</span>
							</div>
							<div style="float: right">
								<form [formGroup]="form" style="width: 150px; margin-bottom: 30px">
									<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: scale(0.8)">
										<mat-select formControlName="DocType" multiple>
											<mat-option *ngFor="let d of DocArr" value="{{ d }}">{{ d }}</mat-option>
										</mat-select>
									</mat-form-field>
								</form>
							</div>
						</ng-container>
					</div>
				</div>

				<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
					<span class="report-table-popup__content-title">בחר סוכנים</span>
				</div>
				<div style="float: right">
					<form [formGroup]="form" style="width: 150px; margin-bottom: 30px">
						<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(-20%) scale(0.7)">
							<mat-select formControlName="Agent" multiple>
								<mat-option *ngFor="let a of AgentArray" [value]="a">{{ a }}</mat-option>
							</mat-select>
						</mat-form-field>
					</form>
				</div>
			</div>
			<div class="report-table-popup__button-wrapper">
				<button class="report-table-popup__button" [attr.disabled]="dateInvalid ? true : null" [ngClass]="{ darken: dateInvalid }" (click)="handleAdvancedSearch()">הצג תוצאות</button>
			</div>
		</div>
	</div>
</div>
<!-- end markup for sort popup -->

<div class="popup" *ngIf="LoadingSeeTeuda || SeeTeudaLoaded" style="z-index: 555">
	<div class="overlay"></div>

	<div class="product-detail__title" *ngIf="selectedTeudaToOpenPopup.Client_Name">
		{{ selectedTeudaToOpenPopup.Client_Name }}
	</div>
	<div class="product-detail__title remarks" *ngIf="selectedTeudaToOpenPopup.DocRemark"><span style="font-weight: 700">הערת תעודה:</span> {{ selectedTeudaToOpenPopup.DocRemark }}</div>
	<div class="product-detail-popup popup__content">
		<div
			class="popup__close"
			(click)="SeeTeudaLoaded = false; LoadingSeeTeuda = false; TeudotEach = []; document.body.classList.remove('no-scroll'); selectedTeudaToOpenPopup = {}; shouldColorRedAndBolden = false"
			style="width: 30px; height: 30px; background-color: black"
			*ngIf="!LoadingSeeTeuda"
		></div>
		<div class="product-detail__wrapper">
			<table *ngIf="SeeTeudaLoaded">
				<tr>
					<th #teudotTableNames>שם פריט</th>
					<th>קוד פריט</th>
					<th>יחידות</th>
					<th>{{ _text.packQuantityText }}</th>
					<th>מחיר יחידה</th>
					<!-- <th> הנחה</th> -->
					<!-- <th> מחיר נטו</th> -->
					<th>סכום</th>
					<!-- <th> הערה</th> -->
				</tr>
				<tr *ngFor="let pte of TeudotEach">
					<td>{{ pte.DocItemName }}</td>
					<td>{{ pte.DocItemCode }}</td>
					<td>{{ pte.DocQuantity }}</td>
					<td>{{ pte.DocPackQuantity }}</td>
					<td [style.fontWeight]="shouldColorRedAndBolden ? '600' : null" [style.color]="shouldColorRedAndBolden ? 'red' : null">
						{{ pte.DocPrice | number : '1.0-2' }}
					</td>
					<!-- <td>{{pte.DocDiscount}}</td> -->
					<!-- <td>{{pte.TeudaItemNetPrice | number: '1.0-2'}}</td> -->
					<td [style.fontWeight]="shouldColorRedAndBolden ? '600' : null" [style.color]="shouldColorRedAndBolden ? 'red' : null">
						{{ pte.DocTotalRow | number : '1.0-2' }}
					</td>
					<!-- <td>{{pte.DocItemRemark}}</td> -->
				</tr>
			</table>
			<div class="product-detail__wrapper__already-sent" *ngIf="LoadingSeeTeuda">
				טוען תעודה, אנא המתינו...
				<mat-spinner diameter="50" color="primary"></mat-spinner>
			</div>
		</div>
	</div>
</div>
