import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { Users } from 'src/app/Interfaces/users';
import { BehaviorSubject, catchError, from, map, mergeMap, of, Subject, takeUntil } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HovotHeaders } from 'src/app/Interfaces/SQL-reports-responses';
import { ExcelObject } from 'src/app/services/util.service';
import { utils, writeFile } from 'xlsx';

@Component({
	selector: 'app-dohotlekohothovot',
	templateUrl: './dohotlekohothovot.component.html',
	styleUrls: ['./dohotlekohothovot.component.scss'],
})
export class DohotlekohothovotComponent implements OnInit, OnDestroy {
	constructor(private _ss: StoreService, private router: Router, private _fb: FormBuilder) {}

	AllClients;
	AllClientsSearch;
	AllProducts;
	ItemExtraOne = [];

	agents = new FormControl<{ AgentID: string; UserName: string }[]>([]);

	form = this._fb.group({
		ClientSearch: ['', Validators.required],
		Company: ['', Validators.required],
		agents: this.agents,
	});

	ShowCompanys;
	showCompaniesObject: { [company: number]: { CompanyName: string; Company: number } };

	HasMultipleCompanys;

	MainAc;

	longestName = 0;

	windowWidth = document.body.clientWidth;

	isAdmin = this.router.url.includes('admin');

	destroy$ = new Subject<void>();

	agentsForAdmin: Users[] = [];

	allClientsSum$ = new BehaviorSubject(0);

	showFilterPopup = false;

	loading = true;

	TotalArray = 0;

	newStyle = true;

	checkedArray = [];
	checkedArray$ = new BehaviorSubject(this.checkedArray.reduce((prev, curr) => prev + curr.COD, 0));

	dropdownList: Users[] = [];
	dropdownSettings: IDropdownSettings = {};

	isPopupOpen = false;

	selectedClient;
	isLoadingPopup = false;

	hovotMappedByClientID: { [key: string]: HovotHeaders[] } = {};

	OneClientHovot: any[] = [];
	OneClientHovotLdt: any[] = [];
	OneClientHovotDisplay$ = new BehaviorSubject<any[]>([]);

	debtDateValue = 0;
	debtDateControl = new FormControl<0 | 1>(0);

	// byDate = location.pathname.includes('-by-date');

	agentsToGet = [];

	withSingleAgentQuery = this._ss.CustomerInformation.AllDebtsReport === '1' && this.isAdmin;

	async ngOnInit() {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
				this.showCompaniesObject = this.ShowCompanys.reduce((prev, curr) => {
					prev[curr.Company] = curr;
					return prev;
				}, {});
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
					this.showCompaniesObject = this.ShowCompanys.reduce((prev, curr) => {
						prev[curr.Company] = curr;
						return prev;
					}, {});
				});
			}
		}

		this._ss.ClientFromDohot = 0;

		this._ss.OneClientInfoDebt = null;
		this._ss.AllMismachimClient = null;

		window.scrollTo(0, 0);

		// if (this.byDate) {
		// 	this.getByDate();
		// 	return;
		// }

		const [UserName, AgentId, allHovotLS] = [localStorage.getItem('UserName'), localStorage.getItem('AgentId'), localStorage.getItem('AllHovotHeaders')];

		if (UserName === null || AgentId === null) {
			this.router.navigate(['login']);
		}

		this._ss.UserNameConnected = UserName;
		this._ss.AgentIdConnected = AgentId;

		await this.handleGetAllClients();

		if (allHovotLS) {
			const hovot: HovotHeaders[] = JSON.parse(decompress(allHovotLS));
			for (const i in hovot) {
				const clientHov = this.hovotMappedByClientID[hovot[i].DebtCustCode];

				if (!clientHov) {
					this.hovotMappedByClientID[hovot[i].DebtCustCode] = [];
				}

				this.hovotMappedByClientID[hovot[i].DebtCustCode].push(hovot[i]);
			}

			console.log({ hovotMapped: this.hovotMappedByClientID });
		}

		this.dropdownSettings = {
			idField: 'AgentID',
			textField: 'UserName',
			selectAllText: 'סמן הכל',
			unSelectAllText: 'הוצא סימון',
			itemsShowLimit: 1,
		};

		this.debtDateControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: val => {
				// if 0
				if (!val) {
					this.OneClientHovotDisplay$.next(this.OneClientHovot);
					return;
				}

				this.OneClientHovotDisplay$.next(this.OneClientHovotLdt);
			},
		});

		if (this.isAdmin) {
			if (!this.withSingleAgentQuery) {
				this.AllClients = this.AllClients.map(client => ({ ...client, agent: [] }));
			}
			await this.handleAdminGetAllClients();
		}

		this.handleSetLongestName();

		this.allClientsSum$.next(this.AllClients.reduce((prev, curr) => prev + curr.COD, 0));

		this.loading = false;

		setTimeout(() => {
			if (
				this._ss.OneClientInfo !== null &&
				document.getElementById('Client' + this._ss.OneClientInfo.ClientId) !== null &&
				document.getElementById('Client' + this._ss.OneClientInfo.ClientId) !== undefined
			) {
				var elmnt = document.getElementById('Client' + this._ss.OneClientInfo.ClientId);
				elmnt.scrollIntoView({ block: 'center' });

				this._ss.OneClientInfo = null;
			}
		}, 100);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleSetLongestName() {
		if (this._ss.copyOfAllClientsFromDohotLekohotHovotAdmin?.length) {
			this.longestName = this.AllClientsSearch.reduce((prev, curr) => Math.max(prev, curr.Client_Name.length), 0);
			return;
		}

		this.AllClientsSearch =
			this.MainAc =
			this.AllClients =
				this.AllClients.filter(client => {
					if (client.COD != 0) {
						// Hijack loop to get longest name
						this.longestName = Math.max(this.longestName, client.Client_Name.length);
						return true;
					}
					return false;
				});
	}

	handleCheck(client) {
		const included = this.checkedArray.findIndex(checked => checked.ClientId === client.ClientId);
		if (included > -1) {
			this.checkedArray.splice(included, 1);
		} else {
			this.checkedArray.push(client);
		}
		this.checkedArray$.next(this.checkedArray.reduce((prev, curr) => prev + curr.COD, 0));
	}

	async handleAdminGetAllClients() {
		if (this._ss.CustomerInformation.Submanagers === '1') {
			this.agentsToGet = await this.getSubmanagerAgentsFirst();
		}

		await this.handleGetAgents();

		if (this.withSingleAgentQuery) {
			const agentsSet = new Set((this.AllClients as any).map(c => c.CustAgentCode));
			this.dropdownList = this.dropdownList.filter(ag => agentsSet.has(ag.AgentID));
			return;
		}
		if (this._ss.copyOfAllClientsFromDohotLekohotHovotAdmin) {
			this.AllClientsSearch = this.MainAc = this._ss.copyOfAllClientsFromDohotLekohotHovotAdmin;
			this.AllClients = this.AllClientsSearch.filter(client => this._ss.allClientsFromDohotLekohotHovotAdminSet.has(client.ClientId));
			return;
		}
		try {
			await this.handleGetAdminStats();
		} catch (error) {
			alert('אירעה שגיאה בטעינת הנתונים');
		}
	}

	async handleGetAgents() {
		try {
			this.dropdownList = await this.getAgentsList();
		} catch (error) {}
	}

	async getAgentsList() {
		return await new Promise<Users[]>((resolve, rej) => {
			this._ss
				.GetAllAgents(this.agentsToGet)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'object' && res.recordset) {
							res.recordset.sort((a, b) => +a.AgentID - +b.AgentID);
							return resolve(res.recordset);
						}

						rej();
					},
					error: err => {
						console.log({ err });
						rej();
					},
				});
		});
	}

	async getSubmanagerAgentsFirst(): Promise<string[]> {
		return await new Promise(resolve => {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res === 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							resolve([]);
							history.back();
							return;
						}

						const agents = res.recordset.map(obj => obj.Agent);
						agents.push(this._ss.AgentIdConnected);
						this._ss.agentsOfSubmanager = agents;

						return resolve(agents);
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				resolve(this._ss.agentsOfSubmanager);
			}
		});
	}

	async handleGetAdminStats() {
		return new Promise<void>((resolve, rej) => {
			const agentsArr$ = this.dropdownList.map(ag =>
				this._ss.GetAllClients(ag.AgentID).pipe(
					map(res => (res ? res : {})),
					map(res => ({ ...res, agent: ag.AgentID })),
					catchError(err => of({ recordset: [] })),
				),
			);

			const MAX_PARALLEL_QUERIES = 1;
			const observables = agentsArr$;
			from(observables)
				.pipe(
					mergeMap(observable => observable, MAX_PARALLEL_QUERIES),
					takeUntil(this.destroy$),
				)
				.subscribe({
					next: (partialResults: any) => {
						this.AllClients = this.AllClients.concat(partialResults.recordset.map((clt: any) => ({ ...clt, agent: [partialResults.agent] })));
					},
					complete: () => {
						this.AllClients = Object.values(
							this.AllClients.reduce((p, c) => {
								if (!p[c.ClientId]) {
									p[c.ClientId] = c;
								} else {
									p[c.ClientId].agent = [...p[c.ClientId].agent, ...c.agent];
								}

								return p;
							}, {}),
						);

						resolve();
					},
				});
		});
	}

	ChangeCompany() {
		window.scrollTo(0, 0);
		this.AllClientsSearch = [];

		for (let x = 0; x < this.MainAc.length; x++) {
			if (this.MainAc[x].company === JSON.parse(this.form.controls['Company'].value)) {
				// this.AllClients.push(this.MainAc[x])
				this.AllClientsSearch.push(this.MainAc[x]);
			}
		}
		this.form.controls.ClientSearch.setValue(this.form.controls.ClientSearch.value || '');
		this.SearchClient();
	}

	BackToDohot() {
		history.back();
	}

	navigateToHovot(e: Event, ClientId: string, company: number) {
		e.stopPropagation();

		this._ss.OneClientInfo = this.AllClients.find(ac => ac.ClientId === ClientId && ac.company === company);

		this._ss.GetOneClientInfoDebt(ClientId).subscribe((res: any) => {
			this._ss.OneClientInfoDebt = res.recordset;

			if (this._ss.OneClientInfoDebt) {
				this._ss.ClientFromDohot = 1;

				this._ss.copyOfAllClientsFromDohotLekohotHovotAdmin = this.MainAc;
				this._ss.allClientsFromDohotLekohotHovotAdminSet = new Set(this.AllClients.map(c => c.ClientId));

				this.router.navigate(['client-hovot']);
			}
		});
	}

	handleOpenPopup(e: Event, client) {
		if (e.target instanceof HTMLTableCellElement) {
			document.body.classList.add('no-scroll');

			this.isLoadingPopup = this.isPopupOpen = true;
			this.selectedClient = client;
			const { ClientId } = client;
			e.stopImmediatePropagation();

			this._ss.HovotByMonth(client.company || 1, ClientId).subscribe((res: any) => {
				this.isLoadingPopup = false;

				this.OneClientHovot = res.recordset;
				this.debtDateControl.setValue(0);
			});

			const relevantClient = this.hovotMappedByClientID[ClientId] ?? [];

			const obj = relevantClient.reduce((prev, curr) => {
				const year = new Date(curr.DebtDocCreateDate).getFullYear();
				const month = new Date(curr.DebtDocCreateDate).getMonth() + 1;

				if (!prev[year + '-' + month]) {
					prev[year + '-' + month] = { tot: 0 };
				}

				prev[year + '-' + month].tot += curr.DebtDocOpenToPay;

				return prev;
			}, {});

			const ob2 = Object.entries(obj).map((entry: any) => ({ yymm: entry[0], tot: entry[1].tot }));

			this.OneClientHovotLdt = ob2;

			this.OneClientHovotDisplay$.next(this.OneClientHovotLdt);
		}
	}

	handleClosePopup() {
		this.isPopupOpen = false;
		document.body.classList.remove('no-scroll');
	}

	sortArr = [true, false, false];

	sort(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'COD':
				i = 0;
				break;
			case 'Client_Name':
				i = 1;
				break;
			case 'ClientId':
				i = 2;
				break;
			case 'CMC':
				i = 3;
				break;
			case 'CMC-COC+COD':
				i = 4;
				break;
			case 'COC+COD':
				i = 5;
				break;
			case 'COC':
				i = 6;
				break;

			default:
				console.log('I should never return');
				return;
		}

		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	trackBy(index: number, client: any): string {
		return client.ClientId;
	}

	refreshSubjects(mode: 'allClientsSum') {
		if (mode === 'allClientsSum') {
			this.allClientsSum$.next(this.AllClients.reduce((acc, curr) => acc + curr.COD, 0));
			return;
		}
	}

	SearchClear() {
		if (this.loading) return;
		this.AllClients = this.AllClientsSearch = this.MainAc;
		this.form.controls.ClientSearch.reset();
		this.form.controls.Company.reset();
		this.agents.setValue([]);

		this.refreshSubjects('allClientsSum');
	}

	SearchClient() {
		if (this.loading) return;

		if (this.form.controls['ClientSearch'].value === null) return;

		this.agents.setValue([]);

		window.scrollTo(0, 0);
		this.AllClients = [];

		const clientSearchValue = this.form.controls['ClientSearch'].value.toLowerCase();
		const clientSearchValueLength = clientSearchValue.length;

		if (!clientSearchValue) {
			this.AllClients = this.AllClientsSearch;
			this.refreshSubjects('allClientsSum');
			return;
		}

		const len = clientSearchValueLength;

		if (len <= 2) {
			for (let i = 0; i < this.AllClientsSearch.length; i++) {
				if (
					(this.AllClientsSearch[i].Client_Name || '').toString().slice(0, len).toLowerCase() === clientSearchValue ||
					(this.AllClientsSearch[i].ClientId || '').toString().slice(0, len).toLowerCase() === clientSearchValue
				) {
					this.AllClients.push(this.AllClientsSearch[i]);
				}
			}
		}

		if (len > 2) {
			const splitValue = clientSearchValue.split(' ');

			for (let i = 0; i < this.AllClientsSearch.length; i++) {
				let counter = 0;

				const clientNameWithoutSpaces = (this.AllClientsSearch[i].Client_Name || '').replace(/\s/g, '').toLowerCase(),
					clientIdWithoutSpaces = (this.AllClientsSearch[i].ClientId || '').replace(/\s/g, '').toLowerCase();

				// V8 optimizes this if statement, maybe
				if (typeof clientIdWithoutSpaces === 'string' && typeof clientNameWithoutSpaces === 'string') {
					for (let j = 0; j < splitValue.length; j++) {
						if ((clientNameWithoutSpaces && clientNameWithoutSpaces.indexOf(splitValue[j]) > -1) || (clientIdWithoutSpaces && clientIdWithoutSpaces.indexOf(splitValue[j]) > -1)) {
							counter++;
						}
					}
				}

				if (counter === splitValue.length) {
					this.AllClients.push(this.AllClientsSearch[i]);
				}
			}
		}

		this.refreshSubjects('allClientsSum');
	}

	handleAdvancedSearch(action: 'clear' | 'all' | 'single') {
		this.form.controls['ClientSearch'].setValue('');

		this.figureOutNewClientsValue(action);

		this.refreshSubjects('allClientsSum');
	}

	figureOutNewClientsValue(action: 'clear' | 'all' | 'single') {
		if (action !== 'single' || !this.agents.value?.length) {
			this.AllClients = this.AllClientsSearch;
			return;
		}

		this.AllClients = this.AllClientsSearch.filter(client => {
			if (this.withSingleAgentQuery) {
				return this.agents.value.some(ag => ag.AgentID === client.CustAgentCode);
			} else {
				for (const a of client.agent) {
					if (this.agents.value.find(ag => ag.AgentID === a)) {
						return true;
					}
				}
			}
			return false;
		});
	}

	sortByChecked() {
		const checkedSet = new Set(this.checkedArray);
		this.AllClients.sort(a => {
			if (checkedSet.has(a)) {
				return -1;
			}

			return 0;
		});
	}

	// getByDate() {
	// 	this._ss
	// 		.getAllDebtByDatesCreate('by-pay')
	// 		.pipe(takeUntil(this.destroy$))
	// 		.subscribe({
	// 			next: res => {
	// 				if (typeof res === 'string') return alert('ישנה תקלה בשרת');

	// 				this.AllClients = res.recordset;
	// 				this.loading = false;
	// 			},
	// 		});
	// }

	async handleGetAllClients() {
		const getAllClientsFromStorage = () => {
			if (this._ss.copyOfAllClientsFromDohotLekohotHovotAdmin?.length) {
				this.AllClientsSearch = this.MainAc = this._ss.copyOfAllClientsFromDohotLekohotHovotAdmin;
				this.AllClients = this.AllClientsSearch.filter(client => this._ss.allClientsFromDohotLekohotHovotAdminSet.has(client.ClientId));
				return;
			}
			const allClients = localStorage.getItem('AllClients');
			if (!allClients) {
				this.router.navigate(['login']);
				alert('לא ניתן להביא נתונים');
				return;
			}

			this.AllClients = JSON.parse(decompress(allClients));
		};

		if (this.withSingleAgentQuery && this.isAdmin) {
			try {
				await this.getFromEndpointInstead();
				return;
			} catch (error) {
				getAllClientsFromStorage();
				return;
			}
		}

		getAllClientsFromStorage();
	}

	async getFromEndpointInstead() {
		return await new Promise<void>((resolve, reject) => {
			this._ss
				.getClientDebtsSummedAsAdmin()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') {
							console.log(res);
							return resolve();
						}

						this.AllClients = res.recordset.filter(client => client.COD);
						return resolve();
					},
					error: err => {
						reject();
					},
				});
		});
	}
}
