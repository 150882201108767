import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { compress } from 'lz-string';
import { BehaviorSubject, startWith, map } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-customer-information-override-modal',
	template: `
		<h1 mat-dialog-title>Customer Information Override</h1>
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<div mat-dialog-content>
				<input [formControl]="searchControl" placeholder="Search Property" />
				<table>
					<tr *ngFor="let row of displayedValues$ | async; let i = index">
						<th>{{ row.header }}</th>
						<td><input [placeholder]="row.value" [formControlName]="row.header" /></td>
					</tr>
				</table>
			</div>
			<div mat-dialog-actions>
				<button mat-button (click)="onNoClick()">Cancel</button>
				<button mat-button cdkFocusInitial>Ok</button>
				<!-- <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button> -->
			</div>
		</form>
	`,
	standalone: true,
	imports: [MatDialogModule, MatButtonModule, CommonModule, ReactiveFormsModule, MatSnackBarModule, FormsModule],
})
export class CustomerInformationOverrideModalComponent {
	constructor(
		public dialogRef: MatDialogRef<CustomerInformationOverrideModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _ss: StoreService,
		private _fb: FormBuilder,
		private _snack: MatSnackBar,
	) {}

	headers = Object.keys(this.data);
	values = Object.values(this.data);

	searchControl = new FormControl('', { nonNullable: true });

	headersAndValues$ = new BehaviorSubject(this.headers.map((header, i) => ({ header, value: this.values[i] })));

	displayedValues$ = this.searchControl.valueChanges.pipe(
		startWith(''),
		map(search => {
			if (!search) return this.headersAndValues$.value;

			return this.headersAndValues$.value.filter(({ header, value }) => {
				const searchRegex = new RegExp(search, 'i');

				return searchRegex.test(header) || searchRegex.test(value as string);
			});
		}),
	);

	form = this._fb.nonNullable.group(this.headers.reduce((acc, curr, i) => ({ ...acc, [curr]: null }), {}));

	onNoClick(): void {
		this.dialogRef.close();
	}

	onSubmit() {
		const newValues = this.form.value;
		for (const key in newValues) {
			if (!newValues[key]) {
				newValues[key] = this.data[key];
			}
		}
		this._ss.CustomerInformation = this.form.value;
		const compressed = compress(JSON.stringify(this.form.value));
		localStorage.setItem('CustomerInformation', compressed);

		console.log(this.form.value);

		this._snack.open('Customer Information Saved', 'Ok', { duration: 2000 });
		this.dialogRef.close();
	}
}
