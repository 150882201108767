import { LIKUT_ADMIN_TYPES } from '../services/likut.service';
import { AllClients } from './SQL-morning-responses';

export type LikutStatus = '0' | '1' | '2' | '3' | '4' | '6';
export type LikutPath = 'likut';

export type LikutFinishBodyNewRoutes = {
	TeudaNum: string;
	TeudaType: number;
} & (LikutFinishBodyNewRoutesMainTable | LikutFinishBodyNewRoutesSubTable);

type LikutFinishBodyNewRoutesSubTable = {
	table: 'sub';
	ProductId: string;
	LStatus: string;
	LikutQty: number;
	LikutPackQty: number;
	LikutPrice?: number;
	TeudaLineNum: number;
};

type LikutFinishBodyNewRoutesMainTable = {
	table: 'main';
	LikutStatus: string;
	MelaketName: string;
	CheckerName: string;
	LikutSignature: string;
	CheckerSignature: string;
	LikutCheckDate: string;
	LikutDate: string;
	MelaketRemark: string;
	CheckerRemark: string;
	QuantitiesMatch: boolean;
};

export type LikutUsers = {
	Admin: typeof LIKUT_ADMIN_TYPES[number];
	melaketid: string;
	melaketname: string;
	pass: string;
};

export type LikutMainSQL = {
	TeudaNum: string;
	TeudaType: number;
	Client_Id: string;
	TeudaCreateDate: string;
	TeudaSupplyDate: string;
	TeudaAgent: string;
	Client_Name: string;
	User_Name: string;
	TuedaErpDocNum: string;
	Address: string;
	City: string;
	TeudaCompany: number;
	LikutStatus: LikutStatus | null;
	MelaketName: string | null;
	CheckerName: string | null;
	LikutExtra1: string | null;
	LikutExtra2: string | null;
	LikutDate: string | null;
	LikutSignature: string | null;
	CheckerSignature: string | null;
	LikutCheckDate: string | null;
	OpenedBy: string | null;
	CheckerRemark: string | null;
	MelaketRemark: string | null;
	AssignedTo: string | null;
	TotalLines: number;
	QuantitiesMatch: number | null;
	AreaId?: string | null;
	AreaName?: string;
	LineNum: number;
};

export type LikutTaskDisplayWithAssignment = LikutDisplayMain & {
	AssignedTo: string | null;
	OpenedBy: string;
	LikutDate: string;
	LineNum: number;
	colorForBackground?: string;
};

export type LikutSubSQL = {
	TeudaNum: string;
	TeudaType: number;
	ProductId: string;
	TeudaLineNum: number;
	ProductName: string;
	TeudaQuantity: number;
	TeudaPackQuantity: number;
	TeudaBonusQuantity: number;
	TeudaBonusPackQuantity: number;
	TeudaPrice: number | null;
	TeudaItemRemark: string;
	Amount: number;
	Arizot: number;
	TeudaUnitPerPack: number;
	PicName: string;
	Stock: number;
	ItemPack: number;
	LStatus: typeof LStatuses[keyof typeof LStatuses];
	LikutQty: number | null;
	LikutExtra1: string | null;
	LikutExtra2: string | null;
	LikutPackQty: number | null;
	LikutPrice?: number | null;
	Eitur?: string;
};

export const LStatuses = {
	regular: '0',
	isDuplicated: '5',
} as const;

type LikutDisplayBase = { clientName: string; clientid: string; totalRow: number; totalTeudot: number; status: LikutStatus };
type LikutFromLikutMain = { teudot: Record<string, LikutMainSQL>; address: string; city: string; quantitiesMatch: boolean };

export type LikutDisplayMain = LikutDisplayBase & LikutFromLikutMain;

export const LIKUT_ROUTES = ['clients', 'tasks', 'finish', 'manage', 'manage-part', 'manage-assignment', 'manage-not-fulfilled', 'manage-no-status', 'assign-users-tasks', 'clients/mekabel'] as const;

export type LikutNavigation = {
	mode: LikutPath;
	route: typeof LIKUT_ROUTES[number];
};

export type LikutKabalaStatus = '0' | '1' | '2' | '5';

export type LikutKabalaMainSQL = {
	TeudaNum: string | null;
	TeudaType: number | null;
	Client_Id: string | null;
	TeudaDate: Date | null;
	TeudaKabalaDate: Date | null;
	TeudaAgent: string | null;
	Client_Name: string | null;
	TeudaErpNum: string | null;
	TeudaCompany: number | null;
	MekabelId: string | null;
	MekabelName: string | null;
	Extra1: string | null;
	Extra2: string | null;
	TotalLines: number | null;
	Remark: string | null;
	TeudaStatus: LikutKabalaStatus | null;
	DATAString: string | null;
	PrintStatus: number | null;
	TeudaErpErr: string | null;
	Extra3: string | null;
	Extra4: string | null;
	Extra5: string | null;
	Warehouse: string | null;
	Warehouse2: string | null;
	Currency: string | null;
	Rate: number | null;
	StringNum: string | null;
	SignatureString: string | null;
	ImageFileName: string | null;
	TeudaReference: string | null;
};

export type LikutKabalaSubSQL = {
	TeudaNum: string | null;
	TeudaType: number | null;
	Extra1: string | null;
	Extra2: string | null;
	ProductId: string | null;
	Barcode: string | null;
	TeudaLineNum: number | null;
	ProductName: string | null;
	TeudaQty: number | null;
	TeudaQtyKabala: number | null;
	TeudaPackQty: number | null;
	TeudaPackQtyKabala: number | null;
	Price: number | null;
	Discount: number | null;
	ItemPicName: string | null;
	ItemPack: number | null;
	Eitur: string | null;
	Extra3: string | null;
	Extra4: string | null;
	Extra5: string | null;
	Warehouse: string | null;
	Warehouse2: string | null;
	Currency: string | null;
	Rate: number | null;
	ItemStatus: string | null;
	Stock: number | null;
	PriceOut: number | null;
	DiscountOut: number | null;
	ItemRemark: string | null;
};

export type LikutMekabelDisplay = {
	clientid: string;
	clientName: string;
	status: LikutKabalaStatus;
	rows: LikutKabalaSubSQL[];
	totalRow: number;
	supplier: {
		name: string;
		id: string;
	};
	warehouse: {
		name: string;
		id: string;
	};
	doctype: { num: number; name: string };
	refrenceNumber: string;
};

export type LikutMekabelTaskExtra = { signature: string; image: string; name: string; remark: string };

export type LikutMekabelFinishBody = {
	TeudaNum: string;
	TeudaType: number;
} & (LikutMekabelFinishBodySubTable | LikutMekabelFinishBodyMainTable);

export type LikutMekabelFinishBodyForUnexistingTask = LikutMekabelFinishBodyMainTableForNewTask | LikutMekabelFinishBodySubTableForNewTask;

interface LikutMekabelFinishBodySubTable {
	table: 'sub';
	ItemStatus: string;
	TeudaQtyKabala: number;
	TeudaPackQtyKabala: number;
	PriceOut: number;
	DiscountOut: number;
	ItemRemark: string;
	Eitur: string;
	ProductId: string;
}
type LikutMekabelFinishBodySubTableForNewTask = LikutKabalaSubSQL & {
	table: 'sub';
};
type LikutMekabelFinishBodyMainTableForNewTask = Omit<LikutKabalaMainSQL, 'TeudaKabalaDate' | 'TeudaDate'> & {
	table: 'main';
	TeudaKabalaDate: string;
	TeudaDate: string;
};

type LikutMekabelFinishBodyMainTable = {
	table: 'main';
	MekabelId: string;
	TeudaKabalaDate: string;
	MekabelName: string;
	Remark: string;
	TeudaStatus: string;
	DATAString: string;
	SignatureString: string;
	ImageFileName: string;
};

export type MekabelWarehouse = {
	WarehouseId: string;
	WarehouseName: string;
};

export type NewLikutMekabelWarehouseFormResult = {
	warehouse: MekabelWarehouse;
	supplier: AllClients;
};

export type LikutKanbanUsers = {
	AgentID: string;
	Admin: string;
	UserName: string;
};

type LikutAssignmentUpdateBodyBase = { TeudaNum: string };

export type LikutAssignmentUpdateLineNum = LikutAssignmentUpdateBodyBase & { LineNum: number };
// export type LikutAssignmentUpdateDate = LikutAssignmentUpdateBodyBase & { LoadDate: string };
export type LikutAssignmentUpdateAgent = { TeudaNum: string[]; AssignTo: string };
