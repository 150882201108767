<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohotBizoim()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביצועים גבייה</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="Third === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="report-table__row report-table__row_between mt-m" [ngClass]="{ 'mt-m-custom': Third }">
			<div class="flex-row" style="height: 48px" *ngIf="!loading">
				<div class="box">
					<app-excel-button *ngIf="!First"></app-excel-button>
				</div>

				<div *ngIf="ShowCompanys !== null && Third === true">
					<form [formGroup]="form" (change)="ChangeCompany()">
						<select formControlName="Company" style="width: 150px; height: 35px">
							<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
						</select>
					</form>
				</div>
			</div>
		</div>

		<h2 *ngIf="Second === true && !isInAdminPage">סה"כ: {{ totalSumMonths | number : '1.0-2' }}</h2>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<h1 *ngIf="loading">טוען...</h1>
			<h1 *ngIf="!loading && ((First && !Bizoim.length) || (Second && !SelectedYear.length))">אין נתונים להציג</h1>

			<table class="report-table__table" cellspacing="0" *ngIf="First === true && !loading && Bizoim.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('yy1', 'Bizoim')" style="width: 100px">שנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('tsum', 'Bizoim')" style="width: 100px">סה"כ קבלות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cash', 'Bizoim')" style="width: 100px">סה"כ מזומן</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Bizoim; index as i" (click)="SeeOneYear(b.yy1, b.isAgent, b.agentid)">
						<td class="report-table__table-body-name" style="width: 100px">
							<div class="flex gap align-center">
								<span>{{ b.isAgent ? b.agentid : b.yy1 }}</span>

								<button
									class="order-product__item-info-count-button"
									(click)="handleClickAgentsButton($event, i, b.cm)"
									*ngIf="admin && !b.isAgent && (openedCompanyIndex === null || openedCompanyIndex === i)"
									[ngClass]="{ 'order-product__item-info-count-button_add': isClosed }"
								></button>
							</div>
						</td>
						<td class="report-table__table-body-name" style="width: 100px">{{ b.tsum | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name" style="width: 100px" dir="ltr">{{ b.cash | number : '1.0-2' : 'en-US' }}</td>
					</tr>
				</tbody>
			</table>

			<!------------------------------------------------------------------------------------------------------------------------------------>

			<table class="report-table__table" cellspacing="0" *ngIf="Second === true && !loading && SelectedYear.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Mm1', 'SelectedYear')" style="width: 100px">חודש</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('tsum', 'SelectedYear')" style="width: 100px">סה"כ קבלות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cash', 'SelectedYear')" style="width: 150px">מזומן</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of SelectedYear" (click)="GetByMonth(b.yy1, b.Mm1)">
						<td class="report-table__table-body-name" style="width: 100px">{{ b.Mm1 }}</td>
						<td class="report-table__table-body-name" style="width: 100px">{{ b.tsum | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name" style="width: 100px" dir="ltr">{{ b.cash | number : '1.0-2' : 'en-US' }}</td>
					</tr>
				</tbody>
			</table>

			<!------------------------------------------------------------------------------------------------------------------------------------>

			<table class="report-table__table" cellspacing="0" *ngIf="Third === true && !loading" style="margin-top: 20px">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('yy1', 'SelectedMonth')" style="width: 100px">שנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Mm1', 'SelectedMonth')" style="width: 100px">חודש</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cn', 'SelectedMonth')" style="width: 200px">שם לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cl', 'SelectedMonth')" style="width: 200px">מספר לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('dn', 'SelectedMonth')" style="width: 200px">מספר קבלה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('dd', 'SelectedMonth')" style="width: 200px">תאריך קבלה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('tsum', 'SelectedMonth')" style="width: 100px">סה"כ קבלה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cash', 'SelectedMonth')" style="width: 150px">מזומן</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of SelectedMonth">
						<td class="report-table__table-body-name" style="width: 100px">{{ b.yy1 }}</td>
						<td class="report-table__table-body-name" style="width: 100px">{{ b.Mm1 }}</td>
						<td class="report-table__table-body-name" style="width: 200px">{{ b.cn }}</td>
						<td class="report-table__table-body-name" style="width: 200px">{{ b.cl }}</td>
						<td class="report-table__table-body-name" style="width: 200px">{{ b.dn }}</td>
						<td class="report-table__table-body-name" style="width: 200px">{{ b.dd }}</td>
						<td class="report-table__table-body-name" style="width: 100px">{{ b.tsum | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name" style="width: 100px" dir="ltr">{{ b.cash | number : '1.0-2' : 'en-US' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
