import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toMatrix'
})
export class ToMatrixPipe implements PipeTransform {
	transform<T>(arr: T[], n: number): T[][] {
		if (!arr.length) return [];

		const rows = Array.from({ length: Math.ceil(arr.length / n) }, (_, i) => i);
		return rows.map(idx => arr.slice(idx * n, idx * n + n));
	}
}
