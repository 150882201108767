import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';
import { Subject, takeUntil, BehaviorSubject, Observable, map, combineLatest } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { normalizeNumber, normalizeNumberToFormattedString } from 'src/app/Utility/functions';

@Component({
	selector: 'app-dohot-bizoim-agent',
	templateUrl: './dohot-bizoim-agent.component.html',
	styleUrls: ['./dohot-bizoim-agent.component.scss'],
})
export class DohotBizoimAgentComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, private _util: UtilService) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	public Bizoim = [];
	Bizoim$ = new BehaviorSubject<any[]>(this.Bizoim);

	public BizoimCompany;

	public toogleInputNew = false;
	public ShowCompanys = null;
	public HasMultipleCompanys;
	reportSearch = null;

	monthly = [];
	monthly$ = new BehaviorSubject<any[]>(this.monthly);

	loading = true;

	month = new Date().getMonth();

	destroy$ = new Subject<void>();

	//TODO turn these into pipes
	total$ = new BehaviorSubject(0);
	average$ = new BehaviorSubject(0);
	totalLastYear$ = new BehaviorSubject(0);
	totalYaad$ = new BehaviorSubject(0);
	totalYaadPercentage$ = new BehaviorSubject(0);
	averageYaad$ = new BehaviorSubject(0);
	totalAhuz$ = new BehaviorSubject(0);

	totalMonth$ = this.monthly$.pipe(map(monthly => monthly.reduce((p, c) => p + c.tot, 0)));
	totalLastYearMonth$ = this.monthly$.pipe(map(monthly => monthly.reduce((p, c) => p + c.ltot, 0)));
	totalYaadMonth$ = this.monthly$.pipe(map(monthly => monthly.reduce((p, c) => p + c.yaad, 0)));
	totalYaadPercentageMonth$ = combineLatest([this.totalMonth$, this.totalYaadMonth$]).pipe(map(([total, yaad]) => (total / yaad) * 100));
	totalAhuzMonth$ = this.monthly$.pipe(map(monthly => monthly.reduce((p, c) => p + c.ahuz, 0)));
	averageYaadMonth$ = this.monthly$.pipe(map(monthly => monthly.reduce((p, c) => p + c.yaad, 0) / monthly.length));

	hasYaad = false;

	hasMoreAccurateQuery = false;

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (this._ss.CustomerInformation.CustChange3 == '1' || this._ss.CustomerInformation.YaadAgents == '1') {
			this.hasYaad = true;
		}

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Company: [1, Validators.required],
		});

		this._ss
			.getAgentsSalesWithClientsPerMonth(0, 0, 0, 0, true)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					this.hasMoreAccurateQuery = true;
				},
			});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			this._ss.GetBizoim().subscribe({
				next: res => {
					this.BizoimCompany = res;
					this.BizoimCompany = this.BizoimCompany.recordset;

					for (let x = 0; x < this.BizoimCompany.length; x++) {
						const element = this.BizoimCompany[x];
						if (element.Yaad2 || element.Yaad) {
							element.currentYearGoal = element.Yaad2 || element.Yaad;
						}
						if (element.Yaad2 || element.Yaad) {
							element.advancementPercentage = isFinite(element.Tot / (element.Yaad2 || element.Yaad)) ? (element.Tot / element.currentYearGoal) * 100 : 0;
						}
						if (this.BizoimCompany[x].cm === 1) {
							this.Bizoim.push(this.BizoimCompany[x]);
						}
					}

					const biz = this.Bizoim;
					this.total$.next(biz.reduce((p, c) => p + c.Tot, 0));
					this.average$.next(this.total$.value / (new Date().getMonth() + 1));
					this.totalLastYear$.next(biz.reduce((p, c) => p + c.Ltot, 0));
					this.totalYaad$.next(biz.reduce((p, c) => p + (c.Yaad2 || c.Yaad || 0), 0));
					this.averageYaad$.next(this.totalYaad$.value / this.Bizoim.length);
					this.totalYaadPercentage$.next((this.total$.value / this.totalYaad$.value) * 100);
					this.totalAhuz$.next((this.total$.value / this.totalLastYear$.value) * 100);

					this.loading = false;
				},
				error: () => {
					alert('ישנה תקלה בשרת');
					history.back();
				},
			});
		} else {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('AllProducts') !== null) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
			this.AllProductsSearch = this.AllProducts;
		} else {
			this.router.navigate(['clients']);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public ChangeCompany() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimCompany.length; x++) {
			if (this.BizoimCompany[x].cm === JSON.parse(this.form.controls['Company'].value)) {
				this.Bizoim.push(this.BizoimCompany[x]);
			}
		}
		this.refreshSubjects('bizoim');
	}

	First = true;
	Second = false;

	monthPicked(mm, YY, cm) {
		this.First = false;
		this.Second = true;

		this.loading = true;

		const method = (mm, YY, ag, cm) => (this.hasMoreAccurateQuery ? this._ss.getAgentsSalesWithClientsPerMonth(mm, YY, ag, cm) : this._ss.AdminAgentsSalesFourth(mm, YY, ag, cm));

		method(mm, YY, this._ss.AgentIdConnected, cm).subscribe(
			(res: any) => {
				this.monthly = res.recordset.filter(m => m.tot || m.ltot);

				for (let x = 0; x < this.monthly.length; x++) {
					this.monthly[x].Client_Name = this.monthly[x].cn;
					this.monthly[x].ClientId = this.monthly[x].Cl;
				}
				this.monthly$.next(this.monthly);

				this.refreshSubjects('month');

				this.loading = false;
			},
			err => {
				alert('ישנה תקלה בשרת');
				history.back();
			},
		);
	}

	refreshSubjects(val: 'month' | 'bizoim') {
		if (val === 'month') {
			this.total$.next(this.monthly.reduce((p, c) => p + c.tot, 0));
			this.average$.next(this.monthly.reduce((p, c) => p + c.tot, 0) / this.monthly.length);
		}

		if (val === 'bizoim') {
			const biz = this.Bizoim.slice(0, new Date().getMonth() + 1);

			this.total$.next(biz.reduce((p, c) => p + c.Tot, 0));
			this.totalLastYear$.next(biz.reduce((p, c) => p + c.Ltot, 0));
			this.totalAhuz$.next((this.total$.value / this.totalLastYear$.value) * 100);
		}
	}

	handleBack() {
		if (this.First === false) {
			this.First = true;
			this.Second = false;
			this.refreshSubjects('bizoim');
			return;
		}

		if (this.First === true) {
			history.back();
		}
	}

	valuesObject = {
		First: ['MM', 'Tot', 'currentYearGoal', 'advancementPercentage', 'Ltot', 'Ahuz', 'cm'],
		Second: ['Cl', 'cn', 'tot', 'ltot', 'ahuz', 'cm'],
	} as const;

	translateForSort(value: typeof this.valuesObject[typeof screen][number], screen: keyof typeof this.valuesObject = Object.keys(this.valuesObject)[0] as keyof typeof this.valuesObject): void {
		let i: number, arrayToSort: any[];

		i = this.valuesObject[screen].findIndex(arr => arr === value);

		if (screen === 'First') {
			arrayToSort = this.Bizoim;
		}

		if (screen === 'Second') {
			arrayToSort = this.monthly$.value;
		}

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}
}
