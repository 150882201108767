import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getRemarkHeader',
})
export class GetRemarkHeaderPipe implements PipeTransform {
	transform(i: number): 'הערה ראשית' | 'הערה 1' | 'הערה 2' | 'הערה 3' | 'הערה 4' | 'הערה 5' {
		switch (i) {
			case 0:
				return 'הערה ראשית';
			case 1:
				return 'הערה 1';
			case 2:
				return 'הערה 2';
			case 3:
				return 'הערה 3';
			case 4:
				return 'הערה 4';

			default:
				return 'הערה 5';
		}
	}
}
