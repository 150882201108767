import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { decompress } from 'lz-string';
import { Users } from 'src/app/Interfaces/users';
import { Subject, takeUntil, catchError, of, from, mergeMap } from 'rxjs';
import { ExcelObject, UtilService } from 'src/app/services/util.service';
import { BizoimGviaResponse } from 'src/app/Interfaces/reports';
import { SQLResponse } from '../../Interfaces/SQL-interfaces';
import { utils, writeFile } from 'xlsx';

@Component({
	selector: 'app-dohotbizoimgvia',
	templateUrl: './dohotbizoimgvia.component.html',
	styleUrls: ['./dohotbizoimgvia.component.scss'],
})
export class DohotbizoimgviaComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _location: Location, private _util: UtilService) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	public Bizoim = [];

	public BizoimCompany;

	public toogleInputNew = false;
	public ShowCompanys = null;
	public HasMultipleCompanys;
	public First = true;
	public Second = false;
	public Third = false;

	reportSearch = null;

	admin = localStorage.getItem('Admin');

	isInAdminPage = location.href.includes('admin');

	destroy$ = new Subject<void>();

	agentsForAdmin = [];

	allClients = [];

	loading = true;

	agentsToGet = [];

	isClosed = true;

	monthOriginalRes = [];
	isInMonthView = false;
	selectedMonth;

	openedCompanyIndex = null;

	gviaWithAgent = [];

	windowWidth = innerWidth;

	async ngOnInit(): Promise<void> {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			ClientSearch: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (this.admin) this.admin = JSON.parse(this.admin);

		if (this.admin && this.isInAdminPage) {
			await this.handleGetAgents();
			await this.handleGetAdminStats();
		} else {
			if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
				this._ss.UserNameConnected = localStorage.getItem('UserName');
				this._ss.AgentIdConnected = localStorage.getItem('AgentId');

				this.SeeOneYear(new Date().getFullYear().toString());

				this.loading = false;

				// this._ss.GetBizoimGvia().subscribe(res => {
				// 	this.BizoimCompany = res;
				// 	this.BizoimCompany = this.BizoimCompany.recordset;

				// 	for (let x = 0; x < this.BizoimCompany.length; x++) {
				// 		if (this.BizoimCompany[x].cm === 1 && new Date().getFullYear().toString() === this.BizoimCompany[x].yy1) {
				// 			this.Bizoim.push(this.BizoimCompany[x]);
				// 		}
				// 	}

				// 	this.SeeOneYear(new Date().getFullYear().toString());

				// });
			} else {
				this.router.navigate(['login']);
			}
		}

		if (localStorage.getItem('AllProducts') !== null) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
			this.AllProductsSearch = this.AllProducts;
		} else {
			this.router.navigate(['clients']);
		}

		if (localStorage.getItem('AllClients')) {
			this.allClients = JSON.parse(decompress(localStorage.getItem('AllClients')));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	async handleGetAgents() {
		try {
			if (this._ss.CustomerInformation.Submanagers === '1') {
				this.agentsToGet = await this.getSubmanagerAgentsFirst();
			}
			this.agentsForAdmin = await this.getAgentsList();
		} catch (error) {}
	}

	gviaAdminRes: SQLResponse<BizoimGviaResponse>[] = [];
	totalSumMonths = 0;

	anyGvia = false;

	async handleGetAdminStats() {
		const agentsArr$ = this.agentsForAdmin.map(ag =>
			this._ss.GetBizoimGviaCustomAgent(ag.AgentID).pipe(
				takeUntil(this.destroy$),
				catchError(() => of('error')),
			),
		);
		const arrayWithSuccessfulRequestsAsIndex = [];

		const MAX_PARALLEL_QUERIES = 1;
		const observables = agentsArr$;
		from(observables)
			.pipe(mergeMap(observable => observable, MAX_PARALLEL_QUERIES))
			.subscribe({
				next: partialResults => {
					if (typeof partialResults === 'string') {
						arrayWithSuccessfulRequestsAsIndex.push(false);
						return;
					}
					if (partialResults.recordset.length) {
						this.gviaAdminRes = this.gviaAdminRes.concat(partialResults);
						arrayWithSuccessfulRequestsAsIndex.push(true);
					} else {
						arrayWithSuccessfulRequestsAsIndex.push(false);
					}

					this.anyGvia ||= partialResults.recordset && !!partialResults.recordset.length;
				},
				complete: () => {
					if (this.anyGvia) {
						// too much work to replace the old endpoint in all servers, instead we sum it up here //

						const year = new Date().getFullYear() + '';

						this.gviaWithAgent = [];

						this.Bizoim = Object.values(
							this.gviaAdminRes.reduce(
								(p: Record<string, BizoimGviaResponse>, c) => {
									// filter only current year
									const relevant = c.recordset.filter(r => r.yy1 === year);
									if (relevant.length) {
										// reduce sums and build relevant data
										const toAdd = relevant.reduce((p, c) => ({ tsum: p.tsum + c.tsum, cash: p.cash + c.cash }), { tsum: 0, cash: 0 });
										p[year].cash += toAdd.cash;
										p[year].tsum += toAdd.tsum;
									}
									return p;
								},
								{ [year]: { cm: 1, tsum: 0, cash: 0, yy1: year } },
							),
						);
					}

					const finishedArray = arrayWithSuccessfulRequestsAsIndex.map((bool, i) => (bool ? this.agentsForAdmin[i].AgentID : false)).filter(agentid => agentid);

					this.gviaWithAgent = this.gviaAdminRes.map((gvia, i) => ({ ...gvia.recordset[0], agentid: finishedArray[i], isAgent: true }));

					this.loading = false;
				},
			});
	}

	async handleGetAdminStatsMonthly(year) {
		const agentsArr$ = this.agentsForAdmin.map(ag => this._ss.GetBizoimGviaYearSelectedCustomAgent(year, ag.AgentID).pipe(catchError(err => of([]))));

		const initialArr = new Array(12).fill({ cm: 1, yy1: year, Mm1: 0, tsum: 0, cash: 0 }).map((y, i) => ({ ...y, Mm1: (1 + +i > 9 ? 1 + +i : '0' + (1 + +i)) + '' }));

		const MAX_PARALLEL_QUERIES = 1;
		const observables = agentsArr$;
		from(observables)
			.pipe(
				mergeMap(observable => observable, MAX_PARALLEL_QUERIES),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: partialResults => {
					this.YearCompany = this.YearCompany.concat(partialResults);
				},
				complete: () => {
					this.SelectedYear = this.YearCompany = this.YearCompany.reduce((p, c) => {
						const y1 = c.recordset;

						for (const i in y1) {
							const month = y1[i].Mm1;
							let monthToUpdate = p.findIndex(pM => +pM.Mm1 === +month);
							if (monthToUpdate > -1) {
								p[monthToUpdate] = { ...p[monthToUpdate], tsum: p[monthToUpdate].tsum + y1[i].tsum, cash: p[monthToUpdate].cash + y1[i].cash };
							}
						}

						return p;
					}, initialArr);
					console.log({ result: this.YearCompany });
					this.loading = false;
				},
			});
	}

	async getSubmanagerAgentsFirst(): Promise<string[]> {
		return await new Promise(resolve => {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res === 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							resolve([]);
							history.back();
							return;
						}

						const agents = res.recordset.map(obj => obj.Agent);
						agents.push(this._ss.AgentIdConnected);
						this._ss.agentsOfSubmanager = agents;

						return resolve(agents);
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				resolve(this._ss.agentsOfSubmanager);
			}
		});
	}

	async getAgentsList() {
		return await new Promise<Users[]>((resolve, rej) => {
			this._ss
				.GetAllAgents(this.agentsToGet)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'object' && res.recordset) {
							return resolve(res.recordset.filter(user => !['b2b', 'b2bL', 'b2bLM'].includes(user.Admin)));
						}

						rej();
					},
					error: err => {
						console.log({ err });
						rej();
					},
				});
		});
	}

	public YearCompany = [];
	public SelectedYear = [];
	selectedAgent = null;
	async SeeOneYear(Year, isAgent = false, agent?: string) {
		this.First = false;
		this.Second = true;

		this.loading = true;

		if (this.admin && this.isInAdminPage && !isAgent) {
			this.selectedAgent = null;
			this.handleGetAdminStatsMonthly(Year);
			return;
		}

		if (isAgent && agent) {
			this._ss.GetBizoimGviaYearSelectedCustomAgent(Year, agent).subscribe((res: any) => {
				this.selectedAgent = agent;
				this.YearCompany = res.recordset;
				this.SelectedYear = this.YearCompany;
				this.totalSumMonths = this.YearCompany.reduce((p, c) => p + +c.tsum, 0);

				this.loading = false;
			});
			return;
		}

		this._ss.GetBizoimGviaYearSelected(Year).subscribe((res: any) => {
			this.YearCompany = res.recordset;
			this.SelectedYear = this.YearCompany;
			this.totalSumMonths = this.YearCompany.reduce((p, c) => p + +c.tsum, 0);

			this.loading = false;
		});
	}

	public MonthCompany = [];
	public SelectedMonth;

	public GetByMonth(Year, Month) {
		this.loading = true;

		if (this.admin && this.isInAdminPage && !this.selectedAgent) {
			this.handleGetAdminStatsYearMonthSelected(Year, Month);
			this.Second = false;
			this.Third = true;

			return;
		}

		if (this.selectedAgent) {
			this.Second = false;
			this.Third = true;
			this._ss.GetBizoimGviaYearMonthSelectedCustomAgent(Year, Month, this.selectedAgent).subscribe((res: any) => {
				this.MonthCompany = res.recordset;
				// this.MonthCompany = this.MonthCompany

				for (let x = 0; x < this.MonthCompany.length; x++) {
					this.MonthCompany[x].Client_Name = this.MonthCompany[x].cn;
					this.MonthCompany[x].ClientId = this.MonthCompany[x].cl;
				}
				this.SelectedMonth = this.MonthCompany;
				this.AllClientsSearch = this.MonthCompany;
				this.loading = false;
			});
			return;
		}

		this.Second = false;
		this.Third = true;
		this._ss.GetBizoimGviaYearMonthSelected(Year, Month).subscribe((res: any) => {
			this.MonthCompany = res.recordset;
			// this.MonthCompany = this.MonthCompany

			for (let x = 0; x < this.MonthCompany.length; x++) {
				this.MonthCompany[x].Client_Name = this.MonthCompany[x].cn;
				this.MonthCompany[x].ClientId = this.MonthCompany[x].cl;
			}
			this.SelectedMonth = this.MonthCompany;
			this.AllClientsSearch = this.MonthCompany;
			this.loading = false;
		});
	}

	async handleGetAdminStatsYearMonthSelected(year, month) {
		const agentsArr$ = this.agentsForAdmin.map(ag => this._ss.GetBizoimGviaYearMonthSelectedCustomAgent(year, month, ag.AgentID).pipe(catchError(err => of([]))));

		this.MonthCompany = [];

		const MAX_PARALLEL_QUERIES = 1;
		const observables = agentsArr$;
		from(observables)
			.pipe(
				mergeMap(observable => observable, MAX_PARALLEL_QUERIES),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: partialResults => {
					this.MonthCompany = this.MonthCompany.concat(partialResults);
				},
				complete: () => {
					console.log({ result: this.MonthCompany });

					this.MonthCompany =
						this.SelectedMonth =
						this.AllClientsSearch =
							this.MonthCompany.reduce((p, c) => {
								//TODO Could probably be refactored, but the bottleneck is in making too many requests

								p = p.concat(c.recordset || c);

								for (const entry of p) {
									entry.Client_Name = entry.Client_Name ?? entry.cn;
									entry.ClientId = entry.Client_Name ?? entry.cl;
								}

								return p;
							}, []);
					console.log({ result: this.MonthCompany });
					this.loading = false;
				},
			});
	}

	public BackToDohotBizoim() {
		if (this.First === true) {
			history.back();
		}

		if (this.Second === true) {
			if (!this.isInAdminPage) {
				history.back();
			}
			this.Second = false;
			this.First = true;
			this.SelectedYear = [];
		}

		if (this.Third === true) {
			this.Third = false;
			this.Second = true;
			this.SelectedMonth = [];
		}
	}

	public ChangeCompany() {
		this.SelectedMonth = [];

		for (let x = 0; x < this.MonthCompany.length; x++) {
			if (this.MonthCompany[x].cm === JSON.parse(this.form.controls['Company'].value)) {
				this.SelectedMonth.push(this.MonthCompany[x]);
			}
		}

		this.AllClientsSearch = this.SelectedMonth;
	}

	public SearchClear() {
		this.SelectedMonth = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			this.SelectedMonth.push(this.AllClientsSearch[x]);
		}

		this.form.reset();
	}
	public AllClientsSearch;

	public SearchClient() {
		this.SelectedMonth = [];

		if (this.form.controls['ClientSearch'].value === '' || this.form.controls['ClientSearch'].value.length === 0) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				this.SelectedMonth.push(this.AllClientsSearch[x]);
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 1) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1)
				) {
					this.SelectedMonth.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2)
				) {
					this.SelectedMonth.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length > 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				for (let z = 0; z < 50; z++) {
					if (
						this.AllClientsSearch[x].Client_Name.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length) ||
						this.AllClientsSearch[x].ClientId.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length)
					) {
						let data = this.SelectedMonth.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
						if (data === undefined) {
							this.SelectedMonth.push(this.AllClientsSearch[x]);
						}
					}
				}
			}
		}
	}

	screens = ['Bizoim', 'SelectedYear', 'SelectedMonth'] as const;

	BizoimValues = ['yy1', 'tsum', 'cash'] as const;
	SelectedYearValues = ['Mm1', 'tsum', 'cash'] as const;
	SelectedMonthValues = ['yy1', 'Mm1', 'cn', 'cl', 'dn', 'dd', 'tsum', 'cash'] as const;

	valuesObject = {
		Bizoim: this.BizoimValues,
		SelectedYear: this.SelectedYearValues,
		SelectedMonth: this.SelectedMonthValues,
	} as const;

	translateForSort(value: typeof this.valuesObject[typeof screen][number], screen: typeof this.screens[number]): void {
		let i: number,
			arrayToSort: any[] = [];

		if (screen === 'Bizoim') {
			arrayToSort = this.Bizoim;
		}

		if (screen === 'SelectedYear') {
			arrayToSort = this.SelectedYear;
		}

		if (screen === 'SelectedMonth') {
			arrayToSort = this.SelectedMonth;
		}

		i = this.valuesObject[screen].findIndex(arr => arr === value);

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}

	handleClickAgentsButton(e: Event, i: number, company: number) {
		e.stopPropagation();
		if (this.isClosed) {
			this.Bizoim = [...this.Bizoim.slice(0, +i + 1), ...this.gviaWithAgent, ...this.Bizoim.slice(+i + 1)];
		} else {
			this.Bizoim = [...this.Bizoim.slice(0, +i + 1), ...this.Bizoim.slice(this.gviaWithAgent.length + 1)];
		}
		this.isClosed = !this.isClosed;
	}
}
