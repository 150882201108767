<div class="page">
	<div class="page-background">
		<img [src]="logo" alt="mobility" class="logo" />
		<form class="auth__form-help" *ngIf="main; else elseBlock" [formGroup]="form" (ngSubmit)="handleSubmit()">
			<fieldset class="auth__form">
				<div class="auth__form-close" (click)="router.navigate(['home'], { replaceUrl: true })"></div>
				<h2 class="auth__form-title">
					<span class="auth__form-title-block">שאלה, בעיה, בקשה..? אנחנו</span>
					<span class="auth__form-title-block">!תמיד לשירותכם</span>
				</h2>
				<h3 class="auth__form-sub-title">(: תשאירו פרטים ואנחנו נשמח לעזור</h3>
				<div class="auth__form-controls">
					<div class="auth__form-block">
						<label class="form-control__label form-control__label_big" for="help-field1">*שם:</label>
						<div class="form-control__input form-control__input--error">
							<input id="help-field1" type="text" class="form-control" autocomplete="off" formControlName="name" />
							<div class="form-control__input--error-info" *ngIf="form.controls.name.invalid && (form.controls.name.touched || isBottomReached)"></div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="form.controls.name.invalid && (form.controls.name.touched || isBottomReached)">
								<span>יש להזין שם </span>
							</div>
						</div>
					</div>
					<div class="auth__form-block">
						<label class="form-control__label form-control__label_big" for="help-field2">*טלפון:</label>
						<div class="form-control__input">
							<input id="help-field2" type="text" class="form-control" autocomplete="off" formControlName="phone" />
							<div class="form-control__input--error-info" *ngIf="form.controls.phone.invalid && (form.controls.name.touched || isBottomReached)"></div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="form.controls.phone.invalid && (form.controls.name.touched || isBottomReached)">
								<span>יש להזין מספר טלפון לחזרה </span>
							</div>
						</div>
					</div>
					<div class="auth__form-block">
						<label class="form-control__label form-control__label_big" for="help-field3">אימייל:</label>
						<div class="form-control__input">
							<input id="help-field3" type="text" class="form-control" autocomplete="off" formControlName="email" />
						</div>
					</div>
					<div class="auth__form-block">
						<label class="form-control__label form-control__label_big" for="help-field4">שם החברה:</label>
						<div class="form-control__input">
							<input id="help-field4" type="text" class="form-control" autocomplete="off" formControlName="company" />
						</div>
					</div>
					<div class="auth__form-block">
						<label class="form-control__label form-control__label_big" for="help-field5">כיצד נוכל לעזור:</label>
						<div class="form-control__input">
							<select id="help-field5" class="form-control form-control_select" formControlName="subject">
								<option [value]="0">בעיית תקשורת</option>
								<option [value]="1">נתונים לא נכונים</option>
								<option [value]="2">חסר לי משהו</option>
							</select>
						</div>
					</div>
					<div class="auth__form-block">
						<div class="form-control__input">
							<textarea id="help-field6" class="form-control form-control__textarea" formControlName="text" placeholder="ספרו לנו עוד"></textarea>
							<div class="form-control__input--error-info" *ngIf="form.controls.text.invalid && (form.controls.name.touched || isBottomReached)"></div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="form.controls.text.invalid && (form.controls.name.touched || isBottomReached)">
								<span>אנא ציינו את תוכן הפנייה</span>
							</div>
						</div>
					</div>
				</div>
				<div class="auth__actions">
					<button type="submit" class="submit-button" [attr.disabled]="form.invalid || sending || null" [ngClass]="{ 'gray-background': form.invalid }">
						<span class="submit-button__text submit-button__text_without-img" style="direction: rtl">{{ sending ? 'שולח...' : 'שלח' }}</span>
					</button>
				</div>
				<div class="auth__actions">
					<div class="auth__form-text-cancel" (click)="router.navigate(['home'], { replaceUrl: true })">ביטול</div>
				</div>
			</fieldset>
		</form>
		<ng-template #elseBlock>
			<div class="auth__help">
				<h2 class="auth__help-title">תודה שפנית אלינו, נחזור אליך בהקדם!</h2>
				<div class="auth__help-image image">
					<img src="/assets/images/auth/plane-icon.gif" alt="photo" />
				</div>
				<div class="auth__help-cancel" (click)="router.navigate(['home'], { replaceUrl: true })">סגור</div>
			</div></ng-template
		>
	</div>
	<span id="intersection"></span>
</div>
