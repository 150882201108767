<div class="order-product__item-count" *ngIf="product.BS | shouldStockShow : 'StockToggle1' : ShowMlay : Admin">
	<div class="order-product__item-count-info" appGetBackground [Stock]="Stock">!</div>
</div>

<div class="order-product__item-count" *ngIf="product.BS | shouldStockShow : 'StockToggle2' : ShowMlay : Admin">
	<div class="order-product__item-count-info" appGetBackground [Stock]="Stock">!</div>
	<div class="order-product__item-count-text">{{ isMoveStockInline ? 'מלאי' : '' }}</div>
	<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': Stock <= 0 }">
		{{ Stock | number : '1.0-2' : 'en-US' }}
	</div>
</div>

<div class="order-product__item-count" *ngIf="product.BS | shouldStockShow : 'productBrowser' : false : Admin">
	<div class="order-product__item-count-info" appGetBackground [Stock]="Stock">!</div>
	<div class="order-product__item-count-text" *ngIf="(_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88) || isMoveStockInline">
		{{ isMoveStockInline ? 'מלאי' : 'משוריין' }}
	</div>
	<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': Stock <= 0 }">
		{{ Stock | number : '1.0-2' : 'en-US' }}
	</div>
</div>

<div class="order-product__item-count" *ngIf="(product.BS | shouldStockShow : 'productBrowser' : false : Admin) && _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88">
	<div class="order-product__item-count-info" appGetBackground [Stock]="product.ItemExtra5">!</div>
	<div class="order-product__item-count-text">מלאי</div>
	<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': Stock <= 0 }">
		{{ product.ItemExtra5 | number : '1.0-2' : 'en-US' }}
	</div>
</div>

<div class="order-product__item-count date" *ngIf="_ss.CustomerInformation.ProductExtraInfo === '1' && showArrivalDate && product.ItemExtra5 && !isB2BLeasing">
	<div class="order-product__item-count-text">תאריך הגעה</div>
	<div class="order-product__item-count-number" dir="ltr">{{ product.ItemExtra5 }}</div>
</div>
