<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="MoveToDohotMotzarim()">
				<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
			</div>
			<div class="header__title col">דו"ח בקרה רשת</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" style="margin-top: 100px">
	<div class="content">
		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">חודש</th>
						<th class="report-table__table-head-name" style="width: 150px">קוד לקוח</th>
						<th class="report-table__table-head-name" style="width: 250px">שם לקוח</th>
						<th class="report-table__table-head-name" style="width: 150px">מכירות השנה</th>
						<th class="report-table__table-head-name" style="width: 150px">מכירות אשתקד</th>
						<th class="report-table__table-head-name" style="width: 150px">קצב התקדמות באחוזים</th>
						<th class="report-table__table-head-name" style="width: 150px">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr>
						<td class="report-table__table-body-name" style="width: 150px"></td>
						<td class="report-table__table-body-name" style="width: 150px"></td>
						<td class="report-table__table-body-name" style="width: 250px"></td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr"></td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr"></td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr"></td>
						<td class="report-table__table-body-name" style="width: 150px"></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="report-table__row report-table__row_between" style="margin-top: 100px"></div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">תאריך</th>
						<th class="report-table__table-head-name" style="width: 150px">מס תעודה</th>
						<th class="report-table__table-head-name" style="width: 150px">סכום</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr>
						<td class="report-table__table-body-name" style="width: 150px"></td>
						<td class="report-table__table-body-name" style="width: 150px"></td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr"></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="report-table__row report-table__row_between" style="margin-top: 100px"></div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">קוד פריט</th>
						<th class="report-table__table-head-name" style="width: 250px">שם פריט</th>
						<th class="report-table__table-head-name" style="width: 150px">מחיר</th>
						<th class="report-table__table-head-name" style="width: 150px">כמות</th>
						<th class="report-table__table-head-name" style="width: 150px">הנחה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr>
						<td class="report-table__table-body-name" style="width: 150px"></td>
						<td class="report-table__table-body-name" style="width: 250px"></td>
						<td class="report-table__table-body-name" style="width: 150px"></td>
						<td class="report-table__table-body-name" style="width: 150px"></td>
						<td class="report-table__table-body-name" style="width: 150px"></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- markup for sort popup -->
<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="report-table-popup report-table-popup_sort popup__content">
		<div class="popup__close" (click)="showHideFilterPopup(false)"></div>
		<div class="report-table-popup__header" [ngClass]="{ 'report-table-popup__header_active': searchActive }">
			<div class="report-table-popup__header-title" *ngIf="!searchActive">חיפוש מתקדם</div>
		</div>
		<div class="report-table-popup__wrapper" *ngIf="!searchActive">
			<div class="report-table-popup__content">
				<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
					<span class="report-table-popup__content-title">הצג את העמודות הבאות</span>
					<label class="report-table-popup__label_all custom-checkbox">
						<input type="checkbox" />
						<span class="custom-checkbox__checkmark"></span>
						<div class="report-table-popup__label-title">הצג הכל</div>
					</label>
				</div>
				<div class="report-table-popup__row">
					<label class="report-table-popup__label custom-checkbox">
						<input type="checkbox" checked="checked" />
						<span class="custom-checkbox__checkmark"></span>
						<div class="report-table-popup__label-title">מחלקות</div>
					</label>
				</div>
				<div class="report-table-popup__row">
					<label class="report-table-popup__label custom-checkbox">
						<input type="checkbox" />
						<span class="custom-checkbox__checkmark"></span>
						<div class="report-table-popup__label-title">מחלקות</div>
					</label>
				</div>
				<div class="report-table-popup__row">
					<label class="report-table-popup__label custom-checkbox">
						<input type="checkbox" />
						<span class="custom-checkbox__checkmark"></span>
						<div class="report-table-popup__label-title">מחלקות</div>
					</label>
				</div>
			</div>
			<button class="report-table-popup__button">הצג תוצאות</button>
		</div>
		<div class="report-table-popup__wrapper" *ngIf="searchActive">
			<div class="report-table-popup__search-content">
				<div class="report-table-popup__row">
					<div class="report-table-popup__search-button" (click)="searchFocus(false)">סנן</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end markup for sort popup -->
