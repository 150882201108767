<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohotBizoim()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביצועים לקוחות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" *ngIf="FirstPage === true">
	<div class="content">
		<h1 *ngIf="Loading === true" style="text-align: right; margin-top: 100px">טוען אנא המתן...</h1>

		<div class="report-table__row report-table__row_between" style="margin-top: 100px">
			<div *ngIf="HasMultipleCompanys === '2'" style="margin-left: 25px">
				<form [formGroup]="form" (change)="ChangeCompany()">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div>
		</div>

		<div class="flex-row" style="position: relative">
			<div class="box" >
				<app-excel-button *ngIf="windowWidth <= 768 || (isInAdminPage && windowWidth > 768)" [downloadFunction]="downloadExcel"></app-excel-button>
			</div>
			<div class="box">
				<b>סה"כ</b>
				<span>
					{{ bizoimTotal$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ bizoimAverage$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<mat-form-field color="primary" appearance="fill" dir="rtl" style="text-align: right" *ngIf="isInAdminPage">
					<mat-label dir="rtl">סוכנים</mat-label>
					<mat-select dir="rtl" [formControl]="agentsControl" multiple (valueChange)="handleMatSelectChange($event)">
						<mat-option *ngFor="let a of agents" value="{{ a.id }}">{{ a.id + ' - ' + a.name }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0" *ngIf="!hasYaad">
				<thead class="report-table__table-head">
					<tr>
						<!-- <th class="report-table__table-head-name" style="width: 70px;">שנה</th> -->
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cl', 'Bizoim')" style="width: 120px">קוד לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cn', 'Bizoim')" style="width: 250px">שם לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Tot', 'Bizoim')" style="width: 150px" (click)="SortThisYear()">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Ltot', 'Bizoim')" style="width: 150px" (click)="SortLastYear()">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('ahuz', 'Bizoim')" style="width: 180px" (click)="SortAhoz()">גידול שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cm', 'Bizoim')" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Bizoim" (click)="MoveToDohotBizoimLekohotEachYear({ ClientId: b.cl, AgentId: b.ag, Company: b.cm, Year: b.Yy1, Client_Name: b.cn })">
						<!-- ClientId, AgentId, Company, Year,Client_Name -->

						<!-- <td class="report-table__table-body-name">{{b.Yy1}}</td> -->
						<td class="report-table__table-body-name">{{ b.cl }}</td>
						<td class="report-table__table-body-name">{{ b.cn }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.Tot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.Ltot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.ahuz && b.Ltot > 0 ? (b.ahuz | number : '1.0-2' : 'en-US') + '%' : 0 }}</td>
						<td class="report-table__table-body-name" *ngIf="HasMultipleCompanys > 1">{{ b.cm }}</td>
					</tr>
				</tbody>
			</table>
			<table class="report-table__table" cellspacing="0" *ngIf="hasYaad">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Cl', 'Bizoim')" style="width: 120px">קוד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Cn', 'Bizoim')" style="width: 250px">שם</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Tot', 'Bizoim')" style="width: 150px">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Yaad1', 'Bizoim')" style="width: 150px">יעד שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('advancementPercentage', 'Bizoim')" style="width: 150px">אחוז התקדמות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Ltot', 'Bizoim')" style="width: 150px">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Ahuz', 'Bizoim')" style="width: 180px">גידול שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cm', 'Bizoim')" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Bizoim" (click)="MoveToDohotBizoimLekohotEachYear({ ClientId: b.Cl, AgentId: b.ag, Company: b.cm, Year: b.Yy1, Client_Name: b.Cn })">
						<!-- ClientId, AgentId, Company, Year,Client_Name -->
						<!-- <td class="report-table__table-body-name">{{b.Yy1}}</td> -->
						<td class="report-table__table-body-name">{{ b.Cl }}</td>
						<td class="report-table__table-body-name">{{ b.Cn }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.Tot ? (b.Tot | number : '1.0-2') : 'אין נתון' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ (b.Yaad1 && b.Yaad1 | number : '1.0-2') || 'אין נתון' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ (b.advancementPercentage && b.advancementPercentage | number : '1.0-2') || 'אין נתון' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.Ltot ? (b.Ltot | number : '1.0-2') : 'אין נתון' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.Ahuz ? (b.Ahuz | number : '1.0-2') : 'אין נתון' }}</td>
						<td class="report-table__table-body-name" *ngIf="HasMultipleCompanys > 1">{{ b.cm }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table" *ngIf="SecondPage === true">
	<div class="content">
		<div class="report-table__row report-table__row_between" style="margin-top: 100px; text-align: center">
			{{ currentClientNameAndID }}
		</div>

		<div class="flex-row" style="position: relative">
			<div class="box">
				<app-excel-button *ngIf="windowWidth <= 768 || (isInAdminPage && windowWidth > 768)" [downloadFunction]="downloadExcel"></app-excel-button>
			</div>
			<div class="box">
				<b>סה"כ</b>
				<span>
					{{ bizoimEachTotal$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ bizoimEachAverage$ | async | number : '1.0-2' }}
				</span>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0" *ngIf="!hasYaad">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Mm1', 'BizoimEach')" style="width: 150px">חודש</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Tot', 'BizoimEach')" style="width: 150px">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Ltot', 'BizoimEach')" style="width: 150px">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('ahuz', 'BizoimEach')" style="width: 150px">גידול שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cm', 'BizoimEach')" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of BizoimEach" (click)="MoveToDohotBizoimLekohotEach(b.cl, b.cm, b.Mm1, b.Yy1)">
						<td class="report-table__table-body-name" style="width: 150px">{{ b.Mm1 }}</td>
						<td class="report-table__table-body-name" style="width: 150px; position: relative" dir="ltr">
							{{ b.Tot | number : '1.0-2' }}
							<img *ngIf="b.ahuz !== 0 && b.Mm1 <= month + 1" [src]="b.ahuz < 100 ? '../../../assets/images/arrow-down-red.svg' : '../../../assets/images/arrow-up-green.svg'" class="arrow" />
						</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ b.Ltot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ b.ahuz && b.Ltot > 0 ? (b.ahuz | number : '1.0-2' : 'en-US') + '%' : 0 }}</td>
						<td class="report-table__table-body-name" style="width: 150px" *ngIf="HasMultipleCompanys > 1">{{ b.cm }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="hasYaad">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Mm1', 'BizoimEach')" style="width: 150px">חודש</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Tot', 'BizoimEach')" style="width: 150px">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Yaad1', 'BizoimEach')" style="width: 150px">יעד חודשי</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('advancementPercentage', 'BizoimEach')" style="width: 150px">אחוז התקדמות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Ltot', 'BizoimEach')" style="width: 150px">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('ahuz', 'BizoimEach')" style="width: 180px">גידול שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cm', 'BizoimEach')" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of BizoimEach" (click)="MoveToDohotBizoimLekohotEach(b.cl, b.cm, b.Mm1, b.Yy1)">
						<!-- ClientId, AgentId, Company, Year,Client_Name -->

						<td class="report-table__table-body-name" style="width: 150px">{{ b.Mm1 - 1 | returnMonthHebrew }}</td>

						<td class="report-table__table-body-name" style="width: 150px; position: relative" dir="ltr">
							{{ b.Tot ? (b.Tot | number : '1.0-2') : 'אין נתון' }}

							<img *ngIf="b.ahuz !== 0 && b.Mm1 <= month + 1" [src]="b.ahuz < 100 ? '../../../assets/images/arrow-down-red.svg' : '../../../assets/images/arrow-up-green.svg'" class="arrow" />
						</td>
						<td class="report-table__table-body-name" dir="ltr">{{ (b.Yaad1 && b.Yaad1 | number : '1.0-2') || 'אין נתון' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ (b.advancementPercentage && b.advancementPercentage | number : '1.0-2') || 'אין נתון' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.Ltot ? (b.Ltot | number : '1.0-2') : 'אין נתון' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.ahuz ? (b.ahuz | number : '1.0-2') : 'אין נתון' }}</td>
						<td class="report-table__table-body-name" *ngIf="HasMultipleCompanys > 1">{{ b.cm }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table" *ngIf="ThirdPage === true">
	<div class="content">
		<div class="flex-row m-top" style="position: relative">
			<div class="box">
				<app-excel-button *ngIf="windowWidth <= 768 || (isInAdminPage && windowWidth > 768)" [downloadFunction]="downloadExcel"></app-excel-button>
			</div>
			<div class="box">
				<b>סה"כ</b>
				<span>
					{{ bizoimEach2Total$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ bizoimEach2Average$ | async | number : '1.0-2' }}
				</span>
			</div>
			<!-- <div class="box">
        <mat-form-field color="primary" appearance="fill" dir="rtl">
          <mat-label>סוכנים</mat-label>
            <mat-select [formControl]="agentsControl" multiple>
              <mat-option *ngFor="let a of agents" value="{{ a }}">{{ a }}</mat-option>
            </mat-select>

        </mat-form-field>
      </div> -->
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('dd', 'BizoimEach2')" style="width: 150px">תאריך</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('dn', 'BizoimEach2')" style="width: 150px">מס תעודה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('tsum', 'BizoimEach2')" style="width: 150px">סכום</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of BizoimEach2" (click)="OneTeuda(b.dn)">
						<td class="report-table__table-body-name">{{ b.dd }}</td>
						<td class="report-table__table-body-name">{{ b.dn }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.tsum | number : '1.0-2' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table" *ngIf="FourthPage === true">
	<div class="content">
		<div class="flex-row m-top" style="position: relative">
			<div class="box">
				<app-excel-button *ngIf="windowWidth <= 768 || (isInAdminPage && windowWidth > 768)" [downloadFunction]="downloadExcel"></app-excel-button>
			</div>
		</div>
		<!-- <div class="flex-row m-top">
      <div class="box">
        <b>סה"כ</b>
        <span>
          {{(oneTeudaInfoTotal$ | async ) | number : '1.0-2'}}
        </span>
      </div>
      <div class="box">
        <b>ממוצע</b>
        <span>
          {{oneTeudaInfoAverage$ | async | number : '1.0-2'}}
        </span>
      </div>
    </div> -->

		<div class="report-table__row report-table__row_full-width" style="margin-top: 98px; margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('pr', 'OneTeudaInfo')" style="width: 150px">קוד פריט</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('pn', 'OneTeudaInfo')" style="width: 250px">שם פריט</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('prc', 'OneTeudaInfo')" style="width: 150px">מחיר</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('qu', 'OneTeudaInfo')" style="width: 150px">כמות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('ds', 'OneTeudaInfo')" style="width: 150px">הנחה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of OneTeudaInfo">
						<td class="report-table__table-body-name">{{ b.pr }}</td>
						<td class="report-table__table-body-name">{{ b.pn }}</td>
						<td class="report-table__table-body-name">{{ b.prc | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.qu }}</td>
						<td class="report-table__table-body-name">{{ b.ds ? (b.ds | number : '1.0-2' : 'en-US') + '%' : 0 }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
