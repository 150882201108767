import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { ExcelObject, UtilService } from 'src/app/services/util.service';
import { utils, writeFile } from 'xlsx';
// import { ReturnMonthHebrewPipe } from 'src/app/Pipes/return-month-hebrew.pipe';

@Component({
	selector: 'app-excel-button',
	templateUrl: './excel-button.component.html',
	styleUrls: ['./excel-button.component.scss'],
})
export class ExcelButtonComponent implements OnInit {
	@Input() downloadFunction?: () => void;
	@Input() filename?: string;
	@Input() hideText?: boolean;
	@Input() tableQuerySelector?: string;
	@Input() AllClients?: any[];
	@Input() agents$?: BehaviorSubject<any[]>;
	@Input() radioControl?: FormControl<string>;

	constructor(public device: DeviceInfoService, private _util: UtilService) {}

	ngOnInit(): void {
		const emptyString = '';
	}

	handleWhichFunctionToUse() {
		if (this.downloadFunction) {
			this.downloadFunction();
			return;
		}

		this.downloadExcel();
	}

	downloadExcel() {
		const dataObject: ExcelObject = { data: [], filename: '', extension: 'xlsx' };

		const table = document.querySelector(this.tableQuerySelector || 'table');
		const title = document.querySelector('.header__title');

		console.log(table);

		if (table) {
			if (title) {
				dataObject.filename = this.filename || title.textContent;
			}

			const wb = utils.table_to_book(table, { sheet: dataObject.filename.slice(0, 31), cellStyles: true });

			if (!wb.Workbook) wb.Workbook = {};
			if (!wb.Workbook.Views) wb.Workbook.Views = [{}];
			wb.Workbook.Views[0].RTL = true;

			writeFile(wb, `${dataObject.filename}.${dataObject.extension}`, { bookType: dataObject.extension, compression: true, cellStyles: true });
			return;
		}
	}
}
