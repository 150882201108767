<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="header header_white">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()">
						<img src="/assets/images/header/back-arrow-black.svg" alt="arrow" /><span style="color: black; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">ביצועים</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger-red.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start content of report page -->
		<section class="report-dashboard">
			<div class="container">
				<div class="report-dashboard__row row">
					<app-report-dashboard-item *ngFor="let item of items" [requiredProperties]="item"></app-report-dashboard-item>
				</div>
			</div>
		</section>
		<!-- end content of report page -->
	</div>

	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img
						width="100%"
						height="auto"
						src="{{ this._ss.CustomerInformation.CustImageDomain }}/companylogo.jpg"
						crossorigin="anonymous"
						alt="mobility"
						class="company-logo__image"
						onerror="this.onerror=null; this.src=MOBILITY_LOGO"
					/>
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ this._ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ this._ss.AgentIdConnected }}</div>
				</div>
			</div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="MoveToClients()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">לקוחות</div>
				</div>
				<!-- <div class="main-menu__col">
        <div class="main-menu__col-icon image">
          <img src="/assets/images/burger/icon-statistic.svg" alt="icon">
        </div>
        <div class="main-menu__col-title">דוחות</div>
      </div> -->
			</div>
			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="BackToHome()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דף הבית</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-menu-button></app-menu-button>
