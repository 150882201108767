<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohot()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">דוחות דינמים</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" *ngIf="First === true">
	<div class="content">
		<div class="report-table__row report-table__row_between" style="margin-top: 50px"></div>
		<div class="report-table__row report-table__row_full-width">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name">שם הדו"ח</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let hfdd of HeaderForDinamicDohot">
						<td class="report-table__table-body-name" (click)="SecondStep(hfdd.repn)">{{ hfdd.repn }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>

<section class="report-table" *ngIf="Second === true">
	<div class="content">
		<div class="report-table__row report-table__row_between" style="margin-top: 50px"></div>
		<div class="report-table__row report-table__row_full-width">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name">תאור</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col1 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col2 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col3 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col4 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col5 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col6 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col7 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col8 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col9 }}</td>
					</tr>
					<tr *ngFor="let hfdd of HeaderForDinamicDohotSecond">
						<td class="report-table__table-body-name">{{ hfdd.Col10 }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
