import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { AdminactivComponent } from './components/adminactiv/adminactiv.component';
import { AdminbizoaazmanotComponent } from './components/adminbizoaazmanot/adminbizoaazmanot.component';
import { AdminBizoaHevraComponent } from './components/admin-bizoa-hevra/admin-bizoa-hevra.component';
import { AdminbizoateudotComponent } from './components/adminbizoateudot/adminbizoateudot.component';
import { AdmincakeComponent } from './components/admincake/admincake.component';
import { AdmindohotproductsComponent } from './components/admindohotproducts/admindohotproducts.component';
import { BizoimlekohotyearComponent } from './components/bizoimlekohotyear/bizoimlekohotyear.component';
import { ClientactivComponent } from './components/clientactiv/clientactiv.component';
import { Clientactiv2Component } from './components/clientactiv2/clientactiv2.component';
import { Clientactiv3Component } from './components/clientactiv3/clientactiv3.component';
import { ClientcardComponent } from './components/clientcard/clientcard.component';
import { ClienthovotComponent } from './components/clienthovot/clienthovot.component';
import { ClienthovoteachComponent } from './components/clienthovoteach/clienthovoteach.component';
import { ClientsComponent } from './components/clients/clients.component';
import { ClientteudotComponent } from './components/clientteudot/clientteudot.component';
import { ClientteudoteachComponent } from './components/clientteudoteach/clientteudoteach.component';
import { DailyreportComponent } from './components/dailyreport/dailyreport.component';
import { DailyReportEachComponent } from './components/daily-report-each/daily-report-each.component';
import { DohbakaraComponent } from './components/dohbakara/dohbakara.component';
import { DohmechiraComponent } from './components/dohmechira/dohmechira.component';
import { DohotComponent } from './components/dohot/dohot.component';
import { DohotbizoimComponent } from './components/dohotbizoim/dohotbizoim.component';
import { DohotBizoimAgentComponent } from './components/dohot-bizoim-agent/dohot-bizoim-agent.component';
import { DohotbizoimazmanotComponent } from './components/dohotbizoimazmanot/dohotbizoimazmanot.component';
import { DohotbizoimgviaComponent } from './components/dohotbizoimgvia/dohotbizoimgvia.component';
import { DohotbizoimlekohotComponent } from './components/dohotbizoimlekohot/dohotbizoimlekohot.component';
import { DohotdinamicagentComponent } from './components/dohotdinamicagent/dohotdinamicagent.component';
import { DohotlekohotComponent } from './components/dohotlekohot/dohotlekohot.component';
import { DohotlekohotbydateComponent } from './components/dohotlekohotbydate/dohotlekohotbydate.component';
import { DohotlekohothovotComponent } from './components/dohotlekohothovot/dohotlekohothovot.component';
import { DohotmotzarimComponent } from './components/dohotmotzarim/dohotmotzarim.component';
import { DohotmotzarimmlayComponent } from './components/dohotmotzarimmlay/dohotmotzarimmlay.component';
import { DohotmotzarimreverseComponent } from './components/dohotmotzarimreverse/dohotmotzarimreverse.component';
import { DohotteudotComponent } from './components/dohotteudot/dohotteudot.component';
import { DohotteudoteachComponent } from './components/dohotteudoteach/dohotteudoteach.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MismachimComponent } from './components/mismachim/mismachim.component';
import { MismachimeachComponent } from './components/mismachimeach/mismachimeach.component';
import { MobilitydashbordComponent } from './components/mobilitydashbord/mobilitydashbord.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ProductsComponent } from './components/products/products.component';
import { TeudaComponent } from './components/teuda/teuda.component';
import { AdminGoalsComponent } from './components/admin-goals/admin-goals.component';
import { BarcodeReaderComponent } from './components/barcode-reader/barcode-reader.component';
import { DohotbizoimyaadimComponent } from './components/dohotbizoimyaadim/dohotbizoimyaadim.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { DohotAgentCategoriesPerformanceComponent } from './components/dohot-agent-categories-performance/dohot-agent-categories-performance.component';
import { NewReceiptComponent } from './components/new-receipt/new-receipt.component';
import { HelpFormComponent } from './components/help-form/help-form.component';
import { SpecialsComponent } from './components/specials/specials.component';
import { DashboardReceiptsComponent } from './components/dashboard-receipts/dashboard-receipts.component';
import { DohotBizoimAgentDivisionComponent } from './components/dohot-bizoim-agent-division/dohot-bizoim-agent-division.component';
import { AgentSalesProductsQuantitiesDatesComponent } from './components/agent-sales-products-quantities-dates/agent-sales-products-quantities-dates.component';
import { BrandsTableComponent } from './components/brands-table/brands-table.component';
import { GroupSalesAllComponent } from './components/group-sales-all/group-sales-all.component';
import { DailyInvoicesComponent } from './components/daily-invoices/daily-invoices.component';
import { SalesVsReturnsComponent } from './components/sales-vs-returns/sales-vs-returns.component';
import { BranchesLookupComponent } from './components/branches-lookup/branches-lookup.component';
import { PasswordForBypassComponent } from './components/password-for-bypass/password-for-bypass.component';
import { AdminManageCompanyComponent } from './components/admin-manage-company/admin-manage-company.component';
import { AdminReportsCompanyComponent } from './components/admin-reports-company/admin-reports-company.component';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { CanEnterPageGuard } from './Guards/can-enter-page.guard';
import { environment } from 'src/environments/environment';
import { AlertManagerComponent } from './components/alert-manager/alert-manager.component';
import { AgentGviaComponent } from './components/agent-gvia/agent-gvia.component';
import { DohotLekohotHovotByDateComponent } from './components/dohot-lekohot-hovot-by-date/dohot-lekohot-hovot-by-date.component';
import { LikutClientsComponent } from './components/likut-clients/likut-clients.component';
import { LikutTaskComponent } from './components/likut-task/likut-task.component';
import { EmptyOpenLikutGuard } from './Guards/empty-open-likut.guard';
import { LikutFinishComponent } from './components/likut-finish/likut-finish.component';
import { LikutReportsComponent } from './components/likut-reports/likut-reports.component';
import { CantExitTruckOrLikutGuard } from './Guards/cant-exit-truck-or-likut.guard';
import { DynamicReportComponent } from './components/dynamic-report/dynamic-report.component';
import { PlannedVsUnplannedComponent } from './components/planned-vs-unplanned/planned-vs-unplanned.component';
import { KanbanAssignmentComponent } from './components/kanban-assignment/kanban-assignment.component';
import { MapDistributionComponent } from './components/map-distribution/map-distribution.component';
import { CanLeavePageCustomGuard } from './Guards/can-leave-page-custom.guard';
import { ReclamationTasksComponent } from './components/reclamation-tasks/reclamation-tasks.component';
import { ReclamationFinishComponent } from './components/reclamation-finish/reclamation-finish.component';
import { RALLYANCE_RECLAMATION_APP_PATH } from './services/reclamation.service';
import { YaadGviaReportComponent } from './components/yaad-gvia-report/yaad-gvia-report.component';

const HOST = environment.production ? 'Mobility' : 'Localhost';

const routes: Routes = [
	{ path: '', component: LoginComponent },
	// a
	{
		path: 'admin',
		children: [
			{ path: '', component: AdminComponent },
			{ path: 'allocation-per-agent', component: ReportTableComponent },
			{ path: 'manage-alerts', component: AlertManagerComponent },
			{ path: 'manage-must-include-products', component: SpecialsComponent },
		],
		title: `${HOST} - Admin Reports`,
	},
	{ path: 'admin-goals', component: AdminGoalsComponent },
	{ path: 'admin-users', component: AdminUsersComponent },
	{ path: 'admin-activ', component: AdminactivComponent },
	{ path: 'activ', component: AdminactivComponent },
	{
		path: 'admin-bizoa-azmanot',
		children: [
			{ path: '', component: AdminbizoaazmanotComponent },
			{ path: ':day', component: AdminbizoaazmanotComponent },
			{ path: 'planned-vs-unplanned', children: [{ path: '', component: AdminbizoaazmanotComponent }] },
		],
	},
	{
		path: 'planned-vs-unplanned-admin',
		children: [
			{ path: '', component: PlannedVsUnplannedComponent },
			{ path: ':day', component: PlannedVsUnplannedComponent },
		],
	},
	{ path: 'admin-bizoa-hevra', component: AdminBizoaHevraComponent },
	{ path: 'admin-bizoa-teudot', component: AdminbizoateudotComponent },
	{ path: 'admin-bizoa-teudot-undelivered', component: AdminbizoateudotComponent },
	{ path: 'admin-cake', component: AdmincakeComponent },
	{ path: 'admin-dohot-products', component: AdmindohotproductsComponent },
	{ path: 'admin-manage-company', component: AdminManageCompanyComponent },
	{ path: 'admin-reports-company', component: AdminReportsCompanyComponent },
	{ path: 'agent-gvia', component: AgentGviaComponent },

	//b
	{ path: 'barcode-reader', component: BarcodeReaderComponent },
	{ path: 'bizoim-lekohot-year', component: BizoimlekohotyearComponent },
	{ path: 'bizoim-lekohot-year-admin', component: BizoimlekohotyearComponent },
	{ path: 'branches-lookup', component: BranchesLookupComponent },

	//c
	{ path: 'client-activ', component: ClientactivComponent },
	{ path: 'client-activ2', component: Clientactiv2Component },
	{ path: 'client-activ3', component: Clientactiv3Component },
	{ path: 'client-card', component: ClientcardComponent },
	{ path: 'client-hovot', component: ClienthovotComponent },
	{ path: 'client-hovot-each', component: ClienthovoteachComponent },
	{
		path: 'clients',
		title: `${HOST} - Clients`,
		children: [
			{ path: '', component: ClientsComponent },
			{ path: 'suppliers', title: `${HOST} - Supplier Clients`, component: ClientsComponent },
		],
	},
	{ path: 'client-teudot', component: ClientteudotComponent },
	{ path: 'client-teudot-each', component: ClientteudoteachComponent },

	//d
	{ path: 'daily-invoices', component: DailyInvoicesComponent },
	{ path: 'daily-invoices-admin', component: DailyInvoicesComponent },
	{ path: 'daily-report', component: DailyreportComponent },
	{ path: 'daily-report-each', component: DailyReportEachComponent },
	{ path: 'dashboard-receipts', component: DashboardReceiptsComponent },
	{ path: 'dashboard-receipts-agent', component: DashboardReceiptsComponent },
	{ path: 'developer-info', loadChildren: () => import('./modules/developer/developer.module').then(m => m.DeveloperInfoModule) },
	{ path: 'dohot-agent-categories-performance', component: DohotAgentCategoriesPerformanceComponent },
	{ path: 'doh-bakara', component: DohbakaraComponent },
	{ path: 'doh-mechira', component: DohmechiraComponent },
	//dohot
	{ path: 'dohot', component: DohotComponent, title: `${HOST} - Reports` },
	{ path: 'dohot-b2b', component: DohotComponent },
	{ path: 'dohot-bizoim', component: DohotbizoimComponent },
	{ path: 'dohot-bizoim-agent', component: DohotBizoimAgentComponent },
	{ path: 'dohot-bizoim-agent-division', component: DohotBizoimAgentDivisionComponent },
	{ path: 'dohot-bizoim-azmanot', component: DohotbizoimazmanotComponent },
	{ path: 'dohot-bizoim-azmanot-b2b-only', component: DohotbizoimazmanotComponent },
	{ path: 'dohot-bizoim-azmanot-b2b-to-agent', component: DohotbizoimazmanotComponent },
	{ path: 'dohot-bizoim-azmanot-brands', component: DohotbizoimazmanotComponent },
	{ path: 'dohot-bizoim-azmanot-reclamations', component: DohotbizoimazmanotComponent },
	{ path: 'dohot-bizoim-gvia-admin', component: DohotbizoimgviaComponent },
	{ path: 'dohot-bizoim-gvia', component: DohotbizoimgviaComponent },
	{ path: 'dohot-bizoim-lekohot', component: DohotbizoimlekohotComponent },
	{ path: 'dohot-bizoim-yaadim', component: DohotbizoimyaadimComponent },
	{ path: 'dohot-dinamic-agent', component: DohotdinamicagentComponent },
	{ path: 'dohot-lekohot', component: DohotlekohotComponent },
	{ path: 'dohot-lekohot-by-date', component: DohotlekohotbydateComponent },
	{ path: 'dohot-lekohot-hovot', component: DohotlekohothovotComponent },
	{ path: 'dohot-lekohot-hovot-admin', component: DohotlekohothovotComponent },
	{ path: 'dohot-lekohot-hovot-by-date', component: DohotLekohotHovotByDateComponent },
	{ path: 'dohot-lekohot-hovot-by-date-admin', component: DohotLekohotHovotByDateComponent },
	{ path: 'dohot-motzarim', component: DohotmotzarimComponent },
	{ path: 'dohot-motzarim-mlay', component: DohotmotzarimmlayComponent },
	{ path: 'dohot-motzarim-reverse', component: DohotmotzarimreverseComponent },
	{ path: 'dohot-teudot', component: DohotteudotComponent },
	{ path: 'dohot-teudot-each', component: DohotteudoteachComponent },
	{ path: 'dohot-teudot-undelivered', component: DohotteudotComponent },
	{ path: 'dynamic-report/:report', component: DynamicReportComponent },

	//g
	{ path: 'graph', loadChildren: () => import('./modules/graph/graph.module').then(m => m.GraphModule) },
	{ path: 'group-goals', component: AdminGoalsComponent },

	//h
	{ path: 'help', component: HelpFormComponent, title: `${HOST} - Help` },
	{ path: 'home', component: HomeComponent, title: `${HOST} - Home` },

	//l
	{ path: 'login', component: LoginComponent },
	{
		path: 'likut',
		title: `${HOST} - Likut`,
		children: [
			{
				path: 'clients',
				children: [
					{ path: '', component: LikutClientsComponent },
					{ path: 'mekabel', component: LikutClientsComponent },
				],
			},
			{
				path: 'tasks',
				children: [
					{ path: '', component: LikutTaskComponent },
					{ path: 'mekabel', component: LikutTaskComponent },
				],
				canDeactivate: [CantExitTruckOrLikutGuard],
				canActivate: [EmptyOpenLikutGuard],
			},
			{
				path: 'finish',
				canActivate: [EmptyOpenLikutGuard],
				children: [
					{ path: '', component: LikutFinishComponent },
					{ path: 'mekabel', component: LikutFinishComponent },
				],
			},
			{
				path: 'manage',
				component: LikutReportsComponent,
			},
			{
				path: 'manage-part',
				component: LikutReportsComponent,
			},
			{
				path: 'assign-users-tasks',
				component: KanbanAssignmentComponent,
			},
			{
				path: 'manage-assignment',
				component: LikutReportsComponent,
			},
			{
				path: 'manage-not-fulfilled',
				component: LikutReportsComponent,
			},
			{
				path: 'manage-no-status',
				component: LikutReportsComponent,
			},
		],
	},

	//m
	{ path: 'manage-alerts', component: AlertManagerComponent, title: `${HOST} - Alerts` },
	{ path: 'mobilitydashbord', component: MobilitydashbordComponent, title: `${HOST} - Dashboard` },
	{ path: 'mobilitydashbord-B2B', component: MobilitydashbordComponent },
	{ path: 'mobilitydashbord-B2B-autolux', component: MobilitydashbordComponent },
	{ path: 'mobilitydashbord-likut', component: MobilitydashbordComponent, title: `${HOST} - Dashboard` },
	{ path: 'mobilitydashbord-truck-distribution-or-loading', component: MobilitydashbordComponent, title: `${HOST} - Dashboard` },
	{ path: 'mismachim', component: MismachimComponent },
	{ path: 'mismachim-each', component: MismachimeachComponent },
	{ path: 'mismachim-receipts', component: MismachimComponent },

	//n
	{ path: 'new-receipt', component: NewReceiptComponent },

	//p
	{ path: 'privacy', component: PrivacyComponent },
	{ path: 'products', component: ProductsComponent, canActivate: [CanEnterPageGuard], title: `${HOST} - Products` },

	//r
	{
		path: RALLYANCE_RECLAMATION_APP_PATH,
		title: `${HOST} - Rallyance-Reclamation`,
		children: [
			{ path: 'tasks', component: ReclamationTasksComponent },
			{
				path: 'finish',
				component: ReclamationFinishComponent,
			},
		],
	},
	{
		path: 'reports',
		children: [
			{ path: 'agent-sales-products-quantities', component: AgentSalesProductsQuantitiesDatesComponent },
			{ path: 'agent-sales-products-quantities-admin', component: AgentSalesProductsQuantitiesDatesComponent },
			{ path: 'agent-sales-orders-quantities-admin', component: AgentSalesProductsQuantitiesDatesComponent },
			{ path: 'agent-sales-orders-quantities-admin-admin', component: AgentSalesProductsQuantitiesDatesComponent },
			{ path: 'concentrated-agent-sales-products-quantities-admin', component: AgentSalesProductsQuantitiesDatesComponent },
			{ path: 'brands', component: BrandsTableComponent },
			{ path: 'brands-admin', component: BrandsTableComponent },
			{ path: 'group-sales', component: GroupSalesAllComponent },
			{ path: 'group-sales-admin', component: GroupSalesAllComponent },
			{ path: 'sales-vs-returns', component: SalesVsReturnsComponent },
			{ path: 'yaad-gvia-agent', component: YaadGviaReportComponent },
			{ path: 'yaad-gvia-admin', component: YaadGviaReportComponent },
		],
	},

	//s
	{ path: 'specials', component: SpecialsComponent },

	//t
	{ path: 'temporary-password', component: PasswordForBypassComponent },
	{ path: 'tests', component: BarcodeReaderComponent },
	{ path: 'teuda', component: TeudaComponent, canActivate: [CanEnterPageGuard], title: `${HOST} - Teuda` },
	{
		path: 'truck-distribution',
		title: `${HOST} - Truck Distribution`,
		children: [
			{ path: 'clients', component: LikutClientsComponent },
			{ path: 'password', component: LikutClientsComponent },
			{
				path: 'tasks',
				component: LikutTaskComponent,
				canDeactivate: [CantExitTruckOrLikutGuard],
				canActivate: [EmptyOpenLikutGuard],
			},
			{
				path: 'finish',
				component: LikutFinishComponent,
				canActivate: [EmptyOpenLikutGuard],
				canDeactivate: [CanLeavePageCustomGuard],
			},
			{
				path: 'manage-assignment',
				component: LikutReportsComponent,
			},
			{
				path: 'manage-not-fulfilled',
				component: LikutReportsComponent,
			},
			{
				path: 'manage-not-fulfilled-only-agent',
				component: LikutReportsComponent,
			},
			{
				path: 'manage-supplied',
				component: LikutReportsComponent,
			},
			{
				path: 'assign-users-tasks',
				component: KanbanAssignmentComponent,
			},
			{
				path: 'distribution-map',
				component: MapDistributionComponent,
			},
		],
	},
	{
		path: 'truck-loading',
		title: `${HOST} - Truck Loading`,
		children: [
			{ path: 'clients', component: LikutClientsComponent },
			{ path: 'password', component: LikutClientsComponent },
			{
				path: 'tasks',
				component: LikutTaskComponent,
				canDeactivate: [CantExitTruckOrLikutGuard],
				canActivate: [EmptyOpenLikutGuard],
			},
			{
				path: 'finish',
				component: LikutFinishComponent,
				canActivate: [EmptyOpenLikutGuard],
			},
			{
				path: 'manage-assignment',
				component: LikutReportsComponent,
			},
			{
				path: 'assign-users-tasks',
				component: KanbanAssignmentComponent,
			},
		],
	},

	//*
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
