import { Injectable } from '@angular/core';
import { B2bService } from './b2b.service';
import { StoreService } from './store.service';

type ColorProperties = {
	mainColor: string;
	mainColorAlpha: string;
	textColor: 'black' | 'white';
};

@Injectable({
	providedIn: 'root',
})
export class AppStyleService {
	constructor(private _ss: StoreService, private _b2b: B2bService) {}

	handleSetCSSVariablesBasedOnCustomerParameters() {
		const root: HTMLElement = document.querySelector(':root');

		if (!root || !this._ss.CustomerInformation) return;

		const { MainColorCompany2, MainColor } = this._ss.CustomerInformation;
		const useAlternativeColor = this._ss.CompanyNum > 1 && MainColorCompany2 && MainColorCompany2 !== '0';
		const mainColor = useAlternativeColor ? MainColorCompany2 : MainColor || '#f65944';

		const colorProperties: ColorProperties = {
			mainColor,
			mainColorAlpha: mainColor + '70',
			textColor: useAlternativeColor ? 'black' : 'white',
		};

		if (this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._b2b.isB2BAgent$.value) {
			colorProperties.mainColor = '#4040fb';
			colorProperties.mainColorAlpha = colorProperties.mainColor + '70';
			colorProperties.textColor = 'white';
		}

		root.style.setProperty('--main-color', colorProperties.mainColor);
		root.style.setProperty('--main-color-alpha', colorProperties.mainColorAlpha);
		root.style.setProperty('--secondary-color', colorProperties.textColor);
	}

	updateCSSVariable(options: { variable: string; newValue: string | number }) {
		const { newValue, variable } = options;
		const root: HTMLElement = document.querySelector(':root');
		if (!root) return;

		root.style.setProperty(variable, String(newValue));
	}
}
