export const goals = [
	{
		AgentId: 'A1',
		Yy: 2024,
		Mm: '01',
		GroupId: 'G1',
		GYaad: 100,
		GYaad2: 200,
		Tot: 300,
		Ltot: 400,
		GroupName: 'Group 1',
		AgentName: 'Agent 1',
	},
	{
		AgentId: 'A1',
		Yy: 2024,
		Mm: '02',
		GroupId: 'G1',
		GYaad: 50,
		GYaad2: 700,
		Tot: 100,
		Ltot: 300,
		GroupName: 'Group 1',
		AgentName: 'Agent 1',
	},
	{
		AgentId: 'A1',
		Yy: 2024,
		Mm: '03',
		GroupId: 'G1',
		GYaad: 100,
		GYaad2: 200,
		Tot: 300,
		Ltot: 400,
		GroupName: 'Group 1',
		AgentName: 'Agent 1',
	},
	{
		AgentId: 'A2',
		Yy: 2024,
		Mm: '02',
		GroupId: 'G2',
		GYaad: 150,
		GYaad2: 250,
		Tot: 350,
		Ltot: 450,
		GroupName: 'Group 2',
		AgentName: 'Agent 2',
	},
	{
		AgentId: 'A3',
		Yy: 2024,
		Mm: '03',
		GroupId: 'G3',
		GYaad: 200,
		GYaad2: 300,
		Tot: 400,
		Ltot: 500,
		GroupName: 'Group 3',
		AgentName: 'Agent 3',
	},
];
