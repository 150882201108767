import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { environment } from 'src/environments/environment';
import { ReportDashboardItemInput } from '../report-dashboard-item/report-dashboard-item.component';

@Component({
	selector: 'app-dohotbizoim',
	templateUrl: './dohotbizoim.component.html',
	styleUrls: ['./dohotbizoim.component.scss'],
})
export class DohotbizoimComponent extends BaseComponent implements OnInit {
	constructor(public router: Router, public _fb: FormBuilder, public _receipts: ReceiptsService) {
		super();
	}
	canProduceReclamationOrders = this._ss.AgentIdConnected === '8011' && this._ss.CustomerInformation.CustNum === '10093' ? true : false;

	items: ReportDashboardItemInput[] = [
		{ path: 'dohot-bizoim-agent', text: 'ביצועים סוכן' },
		{ path: 'dohot-bizoim-lekohot', text: 'ביצועים לקוחות חודש' },
		{ path: 'bizoim-lekohot-year', text: 'ביצועים לקוחות שנה' },
		{ path: 'dohot-bizoim-gvia', text: 'קבלות' },
		{ path: 'dohot-bizoim-azmanot', text: 'ביצועים הזמנות' },
		{ path: 'activ', text: 'ביקורים' },
	].map(item => ({ ...item, icon: 'customers' }));

	endpointArray = ['/mobil/reports/get-agent-sales', '/mobil/reports/get-agent-orders', '/mobil/reports/hasBrands', '/mobil/user/agenthistory-undelivered/1', '/mobil/reports/get-yaad-gvia/admin'];

	logo = MOBILITY_LOGO;

	async ngOnInit() {
		window.scrollTo(0, 0);

		this._receipts.refreshReceiptsParameters();

		if (this._receipts.hasReceipts) {
			this.mutateItemsArray({ path: 'dashboard-receipts-agent', text: 'ביצועים קבלות' }, 5);
		}

		const fetchArray = this.endpointArray.map(endpoint => fetch(`${this._ss.CustomerInformation.CustServer}${endpoint}?checkIfExistsInIndex=true`));

		Promise.allSettled(fetchArray).then(([getAgentSales, getAgentSalesOrders, hasBrands, undelivered, yaadGvia]) => {
			let totalIndex = 0;

			if (undelivered.status === 'fulfilled' && undelivered.value.ok) {
				this.mutateItemsArray({ path: 'dohot-teudot-undelivered', text: 'תעודות שלא סופקו' }, 3 + totalIndex);
				totalIndex++;
			}
			if (hasBrands.status === 'fulfilled' && hasBrands.value.ok) {
				this.mutateItemsArray({ path: ['reports', 'brands'], text: 'ביצועים מותגים' }, 4 + totalIndex);
				totalIndex++;
			}
			if (this.canProduceReclamationOrders) {
				this.mutateItemsArray({ path: 'dohot-bizoim-azmanot-reclamations', text: 'ביצועים תעודות רקלמציה' }, 5 + totalIndex);
				totalIndex++;
			}
			if (['1', '2'].includes(this._ss.CustomerInformation.B2BOptions)) {
				this.mutateItemsArray({ path: 'dohot-bizoim-azmanot-b2b-only', text: 'ביצועים הזמנות B2B' }, 6 + totalIndex);
				totalIndex++;
			}
			if (this._ss.CustomerInformation.CustChange3 === '1') {
				this.mutateItemsArray({ path: 'dohot-agent-categories-performance', text: 'ביצועים מחלקות' }, 7 + totalIndex);
				totalIndex++;
			}
			if (this._ss.CustomerInformation.CustChange7 === '1') {
				this.mutateItemsArray({ path: 'dohot-bizoim-yaadim', text: 'הקצאות לסוכן' }, 8 + totalIndex);
				totalIndex++;
			}
			if (this._ss.CustomerInformation.YaadGroup === '1') {
				this.mutateItemsArray({ path: 'group-goals', text: 'יעדי מחלקות' }, 9 + totalIndex);
				totalIndex++;
			}
			if (['1', '3'].includes(this._ss.CustomerInformation.ReportsOptions)) {
				this.mutateItemsArray({ path: ['reports', 'group-sales'], text: 'ביצועים מחלקות' }, 10 + totalIndex);
				totalIndex++;
			}
			if (getAgentSales.status === 'fulfilled' && getAgentSales.value.status === 400) {
				this.mutateItemsArray({ path: ['reports', 'agent-sales-products-quantities'], text: 'ביצועים מכירות לפי פריטים' }, 11 + totalIndex);
				totalIndex++;
			}
			if (getAgentSalesOrders.status === 'fulfilled' && getAgentSalesOrders.value.status === 400) {
				this.mutateItemsArray({ path: ['reports', 'agent-sales-orders-quantities'], text: 'ביצועים הזמנות לפי פריטים' }, 12 + totalIndex);
				totalIndex++;
			}

			if (yaadGvia.status === 'fulfilled' && yaadGvia.value.ok) {
				yaadGvia.value.json().then(bod => {
					if (bod.recordset?.[0]?.res === 1) {
						this.mutateItemsArray({ path: ['reports', 'yaad-gvia-agent'], text: 'יעדי גביה' }, 13 + totalIndex);
						totalIndex++;
					}
				});
			}
		});
	}

	mutateItemsArray(newItem: { path: string | string[]; text: string }, index: number): void {
		this.items = [...this.items.slice(0, index), { ...newItem, icon: 'customers' }, ...this.items.slice(index)];
	}

	public MoveToClients() {
		this.router.navigate(['clients']);
	}

	public BackToHome() {
		this.router.navigate(['home']);
	}

	history = history;

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;
	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}
}
