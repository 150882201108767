import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, of, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OnlineStatusService implements OnDestroy {
	networkStatusSub$: Subscription;
	networkStatus$ = new BehaviorSubject<boolean>(navigator.onLine);

	constructor() {
		this.checkNetworkStatus();
		console.log('network status service up');
	}

	ngOnDestroy(): void {
		if (this.networkStatusSub$) this.networkStatusSub$.unsubscribe();
	}

	checkNetworkStatus() {
		if (!this.networkStatusSub$) {
			this.networkStatusSub$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
				.pipe(map(() => navigator.onLine))
				.subscribe(status => {
					this.networkStatus$.next(status);
					console.log('new online status:', status);
				});
		}
	}
}
