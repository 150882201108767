import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BooleanStrings } from '../Interfaces/util';

@Directive({
	selector: '[appGetTextColor]',
})
export class GetTextColorDirective implements OnChanges {
	@Input() Knisa?: BooleanStrings;
	@Input() Exit?: BooleanStrings;

	constructor(private element: ElementRef) {}

	ngOnChanges() {
		const el: HTMLDivElement = this.element.nativeElement;
		let color: 'orange' | 'var(--invalid-color)' | 'var(--valid-color)';

		if (!this.Knisa || !this.Exit || (this.Knisa === 'false' && this.Exit === 'false')) {
			return;
		}

		if (this.Knisa === 'true' && this.Exit === 'false') {
			color = 'orange';
		}

		if (this.Knisa === 'false' && this.Exit === 'true') {
			color = 'var(--valid-color)';
		}

		if (this.Knisa === 'true' && this.Exit === 'true') {
			color = 'var(--invalid-color)';
		}

		el.style.color = color;
	}
}
