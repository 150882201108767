import { Pipe, PipeTransform } from '@angular/core';
import { AllClients } from '../Interfaces/SQL-morning-responses';
import { StoreService } from '../services/store.service';

@Pipe({
	name: 'debtParameter',
})
export class DebtParameterPipe implements PipeTransform {
	constructor(private _ss: StoreService) {}

	transform(client: Partial<AllClients>): string {
		const { ShowDebtTerm }: { ShowDebtTerm: '0' | '1' | '2' | '3' | undefined } = this._ss.CustomerInformation;

		client.CMC ||= 0;
		client.COC ||= 0;
		client.COD ||= 0;

		if (!ShowDebtTerm || ShowDebtTerm === '0') {
			return 'אשראי: ' + client.CMC;
		}

		if (ShowDebtTerm === '1') {
			return 'יתרה לניצול: ' + (client.CMC - (client.COC + client.COD + (+client.AddObligo || 0)));
		}

		if (ShowDebtTerm === '2') {
			return 'אשראי מנוצל: ' + (client.COC + client.COD);
		}

		if (ShowDebtTerm === '3') {
			return "צ'קים דחויים: " + client.COC;
		}

		return null;
	}
}
