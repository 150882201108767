<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="header header_white">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()">
						<img src="/assets/images/header/back-arrow-black.svg" alt="arrow" /><span style="color: black; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">דוחות</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger-red.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start content of report page -->
		<section class="report-dashboard" *ngIf="Loading === false">
			<div class="container">
				<div class="report-dashboard__row row" style="position: relative; bottom: 55px" *ngIf="!isB2B; else b2bBlock">
					<app-report-dashboard-item *ngFor="let item of normalDohotItems" [requiredProperties]="item"></app-report-dashboard-item>
					<app-report-dashboard-item [requiredProperties]="adminItem" *ngIf="Admin === 'true'"></app-report-dashboard-item>
					<app-report-dashboard-item
						[requiredProperties]="agentSeeDashboardItem"
						*ngIf="(Admin === 'false' && _ss.CustomerInformation.AgentSeeDashboard === '1') || (Admin && Admin.includes('dashboard') && _ss.CustomerInformation.AgentSeeDashboard === '2')"
					></app-report-dashboard-item>
				</div>
				<ng-template #b2bBlock>
					<div class="report-dashboard__row row" style="position: relative; bottom: 55px">
						<app-report-dashboard-item
							[requiredProperties]="{ text: 'חובות', icon: 'products' }"
							(click)="handleNavigation('debts')"
							*ngIf="!isB2BL"
							[ignoreClick]="true"
						></app-report-dashboard-item>

						<app-report-dashboard-item
							[requiredProperties]="{ text: 'מעקב הזמנות', icon: 'products' }"
							(click)="handleNavigation('dashboard')"
							*ngIf="isB2BL || isB2BLM"
							[ignoreClick]="true"
						></app-report-dashboard-item>

						<app-report-dashboard-item
							[requiredProperties]="{ text: 'איתור סניפים', icon: 'products' }"
							(click)="handleNavigation('branches')"
							*ngIf="isB2BL || isB2BLM"
							[ignoreClick]="true"
						></app-report-dashboard-item>

						<app-report-dashboard-item [requiredProperties]="{ text: 'דוח הזמנות', icon: 'products', path: 'dohot-bizoim-azmanot-b2b-to-agent' }"></app-report-dashboard-item>
					</div>
				</ng-template>
			</div>
		</section>
		<!-- end content of report page -->

		<section class="shop-delivery" *ngIf="Loading === true">
			<div class="container">
				<div class="shop-delivery__row row">
					<div class="shop-delivery__photo image">
						<img src="/assets/images/shop-return/delivery-icon.gif" alt="photo" />
					</div>
					<h2 class="shop-delivery__title shop-delivery__title_margin">{{ this.Status }}</h2>
					<h2 class="shop-delivery__title">{{ this.Date }}</h2>
					<h2 class="shop-delivery__title"><span class="shop-delivery__title-bold">נא לא לסגור את הדפדפן</span> סגירת הדפדפן תצריך סנכרון חדש</h2>
				</div>
			</div>
		</section>
	</div>

	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img
						width="100%"
						height="auto"
						src="{{ this._ss.CustomerInformation.CustImageDomain }}/companylogo.jpg"
						crossorigin="anonymous"
						alt="mobility"
						class="company-logo__image"
						onerror="this.onerror=null; this.src=MOBILITY_LOGO"
					/>
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ this._ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ this._ss.AgentIdConnected }}</div>
				</div>
			</div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="MoveToClients()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">לקוחות</div>
				</div>
			</div>
			<div class="main-menu__border"></div>
		</div>
	</div>
</div>

<app-menu-button></app-menu-button>
