<!-- global markup for header -->
<header class="header" #header>
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="goBack()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>

			<div class="header__title col">{{ reportName }}</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<!-- <div class="report-table__row" *ngIf="Fourth === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div> -->
</header>

<!-- start report table markup -->
<section class="report-table" style="margin-top: 50px">
	<div class="flex-row" *ngIf="!loading">
		<div class="box" style="position: absolute; left: 0">
			<app-excel-button></app-excel-button>
		</div>
	</div>
	<div class="content">
		<!-- <h3 style="text-align: center; font-size: x-large; font-weight: 600" *ngIf="Third || Fourth">סוכן מס' {{ selectedAgent + (agentName.length ? ', ' + agentName : '') }}</h3>
		<h2 style="text-align: center" *ngIf="Fifth || Sixth">{{ clientName }}</h2>
		<h2 style="text-align: center" *ngIf="Sixth">תעודה מספר {{ teudaNum }}</h2> -->

		<div class="report-table__row report-table__row_full-width custom-scrollbar" style="margin-right: 0px; width: 100%">
			<h1 *ngIf="loading">טוען...</h1>
			<h1 *ngIf="!loading && !displayValues.length">לא נמצאו תוצאות</h1>

			<table class="report-table__table" cellspacing="0" *ngIf="!loading && displayValues.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px" *ngFor="let header of headers">{{ header }}</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let a of displayValues; index as i">
						<td class="report-table__table-body-name" *ngFor="let property of headers">{{ a[property] }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<div class="popup" *ngIf="Loading" style="z-index: 333">
	<div class="overlay"></div>
	<div class="product-popup popup__content">
		<div class="order-product__item">
			<div class="order-process__row order-process__row_success">
				<div class="row">
					<h2 class="order-process__title" style="text-align: center">טוען אנא המתן</h2>
				</div>
				<div class="row"></div>
				<div class="row">
					<div class="order-process__image image">
						<img src="/assets/images/basket/process/success.gif" alt="photo" />
					</div>
				</div>
				<div class="row"></div>
			</div>
		</div>
	</div>
</div>
