<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">תעודות ללקוח</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start markup for client profile page -->
		<!-- client info for all client profile page -->
		<section class="client-info" style="margin-top: 100px">
			<div class="content">
				<div class="row">
					<div class="client-info__title col">
						{{ this._ss.OneClientInfo.Client_Name }}
					</div>
				</div>
				<div class="client-info__block row">
					<div class="client-info__number col">
						{{ this._ss.OneClientInfo.ClientId }}
					</div>
				</div>
			</div>
		</section>
		<!-- end of client info for all client profile page -->
		<!-- start markup for client profile order page -->
		<section class="client-order">
			<h1 style="direction: rtl" *ngIf="isLoading">טוען...</h1>
			<div class="content" *ngIf="!isLoading">
				<div class="client-order__item row" *ngFor="let aodc of TeudotPerClient">
					<div class="client-order__item-status client-order__item-status_green">טופל</div>
					<!-- <div class="client-order__item-status client-order__item-status_green">בטיפול</div> -->
					<div class="client-order__item-row row">
						<span class="client-order__item-name client-order__item-name_bold">אסמכתא</span>
						<span class="client-order__item-name">{{ aodc.TeudaNum }}#</span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-name client-order__item-name_bold">סטטוס/מספר ERP</span>
						<span class="client-order__item-name">{{ aodc.TuedaErpDocNum }}#</span>
					</div>
					<div class="client-order__item-row row">
						<span class="client-order__item-title">תאריך:</span>
						<span class="client-order__item-text client-order__item-text_bold" dir="rtl">{{ aodc.TeudaCreateDate | date : 'dd-MM-yyyy' }}</span>
						<span class="client-order__item-text"></span>
					</div>
					<div class="client-order__item-row client-order__item-row_summ row">
						<div class="client-order__item-col client-order__item-col_border col">
							<span class="client-order__item-title">סכום:</span>
							<span class="client-order__item-text client-order__item-text_big client-order__item-text_price">{{ aodc.TeudaTotalSum | number : '1.0-2' : 'en-US' }}</span>
						</div>
						<div class="client-order__item-col col">
							<span class="client-order__item-title">מספר שורות:</span>
							<span class="client-order__item-text client-order__item-text_big">{{ aodc.TotalLines }}</span>
						</div>
						<div class="client-order__item-col col">
							<button class="client-order__item-button" (click)="MoveToTeudaEach(aodc.TeudaNum)">הצג הזמנה</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- end markup for client profile order page -->
	</div>
</div>
