<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToDohot()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">{{ title$ | async }}</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<section class="report-table" style="margin-top: 40px">
			<div class="content">
				<div style="display: flex; justify-content: space-between; align-items: center" *ngIf="!loading">
					<div class="product-detail__button flex justify-align-center" (click)="handleOpenDrawer()">{{ openDrawerTitle$ | async }}</div>
				</div>

				<div class="report-table__row report-table__row_between"></div>

				<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
					<div class="loading" *ngIf="loading">
						<mat-spinner color="primary"></mat-spinner>
					</div>
					<mat-progress-bar *ngIf="isLoadingProgressBarShowing" style="position: absolute" mode="indeterminate"></mat-progress-bar>
					<ng-container *ngIf="allMustIncludeProductsDisplay$ | async as allMustIncludeProducts">
						<ng-container *ngIf="!loading">
							<table class="report-table__table" cellspacing="0" *ngIf="isSpecialsMode && allSpecials.length">
								<!-- <table class="report-table__table" cellspacing="0" *ngIf="FilterLoading === false && allSpecials.length"> -->
								<thead class="report-table__table-head">
									<tr>
										<th class="report-table__table-head-name" style="width: 40px">קוד</th>
										<th class="report-table__table-head-name" style="width: 200px">שם</th>
										<th class="report-table__table-head-name" style="width: 100px">התחלה</th>
										<th class="report-table__table-head-name" style="width: 100px">סיום</th>
										<th class="report-table__table-head-name" style="width: 100px">חברה</th>
										<!-- <th class="report-table__table-head-name" style="width: 100px">עריכה</th> -->
										<th class="report-table__table-head-name" style="width: 300px">סטטוס</th>
									</tr>
								</thead>
								<tbody class="report-table__table-body">
									<tr *ngFor="let ata of allSpecials$ | async; index as i">
										<td class="report-table__table-body-name">{{ ata.SpecialID }}</td>
										<td class="report-table__table-body-name">{{ ata.Name }}</td>
										<td class="report-table__table-body-name">{{ ata.StartDate | date : 'dd/MM/yy' }}</td>
										<td class="report-table__table-body-name">{{ ata.EndDate | date : 'dd/MM/yy' }}</td>
										<td class="report-table__table-body-name">{{ ata.Company }}</td>
										<td class="report-table__table-body-name bold">
											<mat-button-toggle-group name="specialStatus" (change)="handleChangeSpecialStatus($event, ata.SpecialID, ata.SpecialStatus)" [value]="ata.SpecialStatus">
												<mat-button-toggle #button0 [value]="0">פעיל</mat-button-toggle>
												<mat-button-toggle #button1 [value]="1">לא פעיל</mat-button-toggle>
												<mat-button-toggle #button2 [value]="2">פעיל ללא הגבלה</mat-button-toggle>
											</mat-button-toggle-group>
										</td>
									</tr>
								</tbody>
							</table>
							<table class="report-table__table" cellspacing="0" *ngIf="isMustIncludeProductsMode">
								<thead class="report-table__table-head">
									<tr>
										<th class="report-table__table-head-name" style="width: 100px">סוכן</th>
										<th class="report-table__table-head-name" style="width: 100px">לקוח</th>
										<th class="report-table__table-head-name" style="width: 100px">סוג תעודה</th>
										<th class="report-table__table-head-name" style="width: 100px">פעולות</th>
									</tr>
								</thead>
								<tbody class="report-table__table-body">
									<tr *ngFor="let mustIncludeLine of allMustIncludeProducts; index as i">
										<td class="report-table__table-body-name">{{ mustIncludeLine.agent || 'כל הסוכנים' }}</td>
										<td class="report-table__table-body-name">{{ mustIncludeLine.clientid || 'כל הלקוחות' }}</td>
										<td class="report-table__table-body-name">{{ mustIncludeLine.doctype || 'כל התעודות' }}</td>
										<td class="report-table__table-body-name">
											<button mat-icon-button (click)="handleOpenEditMustIncludeProduct(mustIncludeLine)">
												<mat-icon style="color: #3375cb">edit</mat-icon>
											</button>
											<button mat-icon-button (click)="handleConfirmDeleteMustIncludeProduct(mustIncludeLine)">
												<mat-icon color="primary">close</mat-icon>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</ng-container>

						<h1 *ngIf="!allSpecials.length && !allMustIncludeProducts.length && !loading">אין נתונים להציג</h1>
					</ng-container>
					<!-- <h1 *ngIf="FilterLoading === false && !allSpecials.length">אין נתונים להציג</h1> -->
				</div>
				<!-- <ng-template #allocationTable>
					<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
						<table class="report-table__table" cellspacing="0" *ngIf="allAllocations.length">

							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name" style="width: 40px">קוד</th>
									<th class="report-table__table-head-name" style="width: 200px">סוכן</th>
									<th class="report-table__table-head-name" style="width: 100px">מוצר</th>
									<th class="report-table__table-head-name" style="width: 100px">כמות</th>
									<th class="report-table__table-head-name" style="width: 100px">מתוך</th>
									<th class="report-table__table-head-name" style="width: 100px">התחלה</th>
									<th class="report-table__table-head-name" style="width: 100px">סיום</th>

									<th class="report-table__table-head-name" style="width: 100px">סטטוס</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr *ngFor="let ata of allAllocations$ | async; index as i">
									<td class="report-table__table-body-name">{{ ata.id }}</td>
									<td class="report-table__table-body-name">{{ ata.agent }}</td>
									<td class="report-table__table-body-name">{{ ata.productID }}</td>
									<td class="report-table__table-body-name">{{ ata.units }}</td>
									<td class="report-table__table-body-name">{{ ata.total }}</td>
									<td class="report-table__table-body-name">{{ ata.startDate | date : 'dd/MM/yy' }}</td>
									<td class="report-table__table-body-name">{{ ata.endDate | date : 'dd/MM/yy' }}</td>
									<td class="report-table__table-body-name bold" [ngStyle]="{ color: ata.status === '0' ? 'var(--valid-color)' : 'var(--invalid-color)' }">
										{{ ata.status === '0' ? 'פעיל' : 'לא פעיל' }}
									</td>
								</tr>
							</tbody>
						</table>
						<h1 *ngIf="!allAllocations.length">אין נתונים להציג</h1>

					</div>
				</ng-template> -->
			</div>
		</section>
	</div>
</div>

<!-- markup for sort popup -->
<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="report-table-popup report-table-popup_sort popup__content">
		<div class="popup__close" (click)="closeDrawer()"></div>
		<div class="report-table-popup__header">
			<div class="report-table-popup__header-search"></div>
			<div class="report-table-popup__header-title">{{ openedDrawerTitleText() }}</div>
		</div>
		<div class="report-table-popup__wrapper">
			<div class="report-table-popup__content">
				<div class="loading" *ngIf="loading; else formBlock">
					<mat-spinner color="primary"></mat-spinner>
					<h3 style="text-align: center; direction: rtl">טוען...</h3>
				</div>
				<ng-template #formBlock>
					<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
						<div *ngIf="specialSuccess; else specialChangeBlock" style="width: 100%; display: grid; place-items: center">
							<h3 style="text-align: center; color: var(--valid-color)">מבצע {{ isEdittingSpecial ? 'עודכן' : 'נוסף' }} בהצלחה!</h3>
							<button mat-raised-button color="primary" (click)="closeDrawer()">סגור</button>
						</div>

						<ng-template #specialChangeBlock [ngSwitch]="pageMode$ | async">
							<form *ngSwitchCase="'specials'" [formGroup]="form" dir="rtl" autocomplete="off" class="flex flex-column gap" (ngSubmit)="handleFormSubmit()">
								<mat-form-field color="primary">
									<mat-label>שם</mat-label>
									<input matInput placeholder="שם" type="text" formControlName="Name" required />
									<mat-error *ngIf="form.controls.Name.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>תיאור</mat-label>
									<input matInput placeholder="תיאור" type="text" formControlName="SpecialDescription" required />
									<mat-error *ngIf="form.controls.SpecialDescription.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>משך המבצע</mat-label>
									<mat-error *ngIf="form.controls.StartDate.invalid">תאריך אינו תקין</mat-error>
									<mat-date-range-input [rangePicker]="picker" [min]="dates.min" [max]="dates.max" (click)="picker.open()">
										<input matStartDate readonly="true" formControlName="StartDate" />
										<input matEndDate readonly="true" formControlName="EndDate" />
									</mat-date-range-input>
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-date-range-picker #picker></mat-date-range-picker>
								</mat-form-field>
								<div class="flex justify-evenly">
									<button mat-stroked-button color="primary" type="button" [disabled]="isEdittingSpecial" (click)="handleDateChange('month')">חודש</button>
									<button mat-stroked-button color="primary" type="button" [disabled]="isEdittingSpecial" (click)="handleDateChange('year')">שנה</button>
								</div>
								<mat-form-field color="primary" *ngIf="multiCompany">
									<mat-label>חברה</mat-label>
									<input matInput placeholder="חברה" type="number" formControlName="Company" required [readonly]="isEdittingSpecial" />
									<mat-error *ngIf="form.controls.Company.hasError('required')">שדה חסר או אינו תקין</mat-error>
									<mat-error *ngIf="form.controls.Company.hasError('companyNotInAllowedValues')">חברה לא תקינה</mat-error>
									<mat-error *ngIf="form.controls.Company.hasError('needsValueForProductPopup')">יש לבחור חברה לפני בחירת מוצרים</mat-error>
								</mat-form-field>
								<mat-form-field color="primary" *ngIf="canPickSpecialsType">
									<mat-label>סוג מבצע</mat-label>
									<mat-select formControlName="SpecialType">
										<mat-option *ngFor="let type of typeOfSpecials" [value]="type.value" style="direction: rtl">
											{{ type.text }}
										</mat-option>
									</mat-select>
									<mat-error>יש לבחור אחד</mat-error>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>הגבלת מבצע לתעודה</mat-label>
									<input matInput placeholder="מקסימום" type="number" formControlName="MaxInCart" min="0" />
									<mat-error>מספר לא יכול להיות שלילי</mat-error>
									<mat-hint>0 או ריק - ללא הגבלה</mat-hint>
								</mat-form-field>
								<div class="flex radio-row">
									<label>סטטוס: </label>
									<mat-radio-group formControlName="Status" color="primary">
										<mat-radio-button [value]="0">פעיל</mat-radio-button>
										<mat-radio-button [value]="1">לא פעיל</mat-radio-button>
										<mat-radio-button [value]="2">פעיל ללא תאריך</mat-radio-button>
									</mat-radio-group>
								</div>
								<button mat-raised-button color="primary" type="button" (click)="handleProductPopupOpen()">
									{{ isEdittingSpecial ? '' : 'הוסף' }} מוצרים <span *ngIf="selectedProducts.value.length">({{ selectedProducts.value.length }})</span>
								</button>
								<div class="report-table-popup__button-wrapper" style="position: relative">
									<span style="position: absolute; bottom: -20px; color: var(--invalid-color)" *ngIf="!selectedProducts.value.length && shouldShowNoProductsError">
										חייב לבחור מוצרים לפני אישור מבצע
									</span>
									<button class="report-table-popup__button" [ngClass]="{ darken: form.invalid }">{{ isEdittingSpecial ? 'ערוך' : 'אשר' }}</button>
								</div>
							</form>
							<form
								*ngSwitchCase="'must-include'"
								[formGroup]="mustIncludeForm"
								dir="rtl"
								autocomplete="off"
								class="flex flex-column gap row must-include-form"
								(ngSubmit)="handleMustIncludeFormSubmit()"
							>
								<mat-accordion multi>
									<mat-expansion-panel expanded="true">
										<mat-expansion-panel-header>
											<mat-panel-title> סינון </mat-panel-title>
										</mat-expansion-panel-header>

										<div class="flex gap row flex-column">
											<mat-checkbox formControlName="shouldAffectAllClients" color="primary">כל הלקוחות</mat-checkbox>
											<ng-container *ngIf="shouldShowClientAutocomplete$ | async">
												<mat-form-field color="primary">
													<mat-label>בחר לקוח</mat-label>
													<input type="text" matInput [matAutocomplete]="auto" formControlName="clientsSearch" #clientSearch />
													<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionSelected)="handleAutocompleteSelect($event, 'clients')">
														<mat-option *ngFor="let client of allClientsDisplay$ | async" [value]="client.ClientId" style="direction: rtl">
															<span style="unicode-bidi: isolate">{{ client.ClientId }}</span> -
															<span style="unicode-bidi: isolate">{{ client.Client_Name }}</span>
														</mat-option>
													</mat-autocomplete>
												</mat-form-field>
												<mat-chip-list dir="rtl">
													<mat-chip *ngFor="let client of mustIncludeForm.controls.clients.value; let i = index" style="padding: 0 12px">
														{{ client }}
														<button matChipRemove (click)="chipClicked(i, 'clients')">
															<mat-icon>cancel</mat-icon>
														</button>
													</mat-chip>
													<mat-error *ngIf="mustIncludeForm.controls.clients.getError('invalidArray') && mustIncludeForm.controls.clients.touched" (click)="clientSearch.focus()">
														יש לבחור לפחות לקוח אחד, או לסמן מחדש 'כל הלקוחות'
													</mat-error>
												</mat-chip-list>
											</ng-container>
											<ng-container *ngIf="shouldAllowAgentSelection">
												<mat-checkbox formControlName="shouldAffectAllAgents" color="primary">כל הסוכנים</mat-checkbox>
												<ng-container *ngIf="shouldShowAgentAutocomplete$ | async">
													<mat-form-field color="primary">
														<mat-label>בחר סוכן</mat-label>
														<input type="text" matInput [matAutocomplete]="auto" formControlName="agentsSearch" />
														<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionSelected)="handleAutocompleteSelect($event, 'clients')">
															<mat-option *ngFor="let agent of allAgentsDisplay$ | async" [value]="agent.AgentID" style="direction: rtl">
																<span style="unicode-bidi: isolate">{{ agent.AgentID }}</span> -
																<span style="unicode-bidi: isolate">{{ agent.UserName }}</span>
															</mat-option>
														</mat-autocomplete>
														<mat-error *ngIf="mustIncludeForm.controls.agents.getError('invalidArray') && mustIncludeForm.controls.agents.touched">
															יש לבחור לפחות סוכן אחד, או לסמן מחדש 'כל הסוכנים'
														</mat-error>
													</mat-form-field>
													<mat-chip-list dir="rtl">
														<mat-chip *ngFor="let agent of mustIncludeForm.controls.agents.value; let i = index" style="padding: 0 12px">
															{{ agent }}
															<button matChipRemove (click)="chipClicked(i, 'agents')">
																<mat-icon>cancel</mat-icon>
															</button>
														</mat-chip>
													</mat-chip-list>
												</ng-container>
											</ng-container>
										</div>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title> פריטי בסיס </mat-panel-title>
										</mat-expansion-panel-header>
										<div class="flex gap row flex-column">
											<ng-container>
												<mat-form-field color="primary">
													<mat-label>פריטי בסיס</mat-label>
													<input type="text" matInput [matAutocomplete]="autoA" formControlName="productsASearch" />
													<mat-autocomplete #autoA="matAutocomplete" [displayWith]="displayFn" (optionSelected)="handleAutocompleteSelect($event, 'productsA')">
														<mat-option *ngFor="let productA of productsADisplay$ | async" [value]="productA.ProductId" style="direction: rtl">
															<span style="unicode-bidi: isolate">{{ productA.ProductId }}</span> -
															<span style="unicode-bidi: isolate">{{ productA.Product_Name }}</span>
														</mat-option>
													</mat-autocomplete>
												</mat-form-field>

												<mat-chip-list dir="rtl" formControlName="productsA">
													<mat-chip *ngFor="let productA of mustIncludeForm.controls.productsA.value; let i = index" style="padding: 0 12px">
														{{ productA }}
														<button matChipRemove (click)="chipClicked(i, 'productsA')">
															<mat-icon>cancel</mat-icon>
														</button>
													</mat-chip>
													<mat-error *ngIf="mustIncludeForm.controls.productsA.getError('invalidArray') && mustIncludeForm.controls.productsA.touched">
														יש לבחור לפחות פריט אחד
													</mat-error>
												</mat-chip-list>
											</ng-container>
											<mat-form-field color="primary">
												<mat-label>חישוב</mat-label>
												<mat-select formControlName="operator">
													<mat-option *ngFor="let operator of mustIncludeOperators" [value]="operator" style="direction: rtl">
														{{ operatorToText(operator) }}
													</mat-option>
												</mat-select>
												<mat-error>יש לבחור אחד</mat-error>
											</mat-form-field>
											<mat-form-field color="primary">
												<mat-label>כמות</mat-label>
												<input matInput placeholder="כמות" type="text" inputmode="numeric" formControlName="quantity" />
												<mat-error>שדה חסר או אינו תקין</mat-error>
											</mat-form-field>
										</div>
									</mat-expansion-panel>
									<mat-expansion-panel class="gap">
										<mat-expansion-panel-header>
											<mat-panel-title> פריטי חובה </mat-panel-title>
										</mat-expansion-panel-header>
										<div class="flex gap row flex-column">
											<ng-container>
												<mat-form-field color="primary">
													<mat-label>פריטי חובה</mat-label>
													<input type="text" matInput [matAutocomplete]="autoB" formControlName="productsBSearch" />
													<mat-autocomplete #autoB="matAutocomplete" [displayWith]="displayFn" (optionSelected)="handleAutocompleteSelect($event, 'productsB')">
														<mat-option *ngFor="let productB of productsBDisplay$ | async" [value]="productB.ProductId" style="direction: rtl">
															<span style="unicode-bidi: isolate">{{ productB.ProductId }}</span> -
															<span style="unicode-bidi: isolate">{{ productB.Product_Name }}</span>
														</mat-option>
													</mat-autocomplete>
												</mat-form-field>
												<mat-chip-list dir="rtl">
													<mat-chip *ngFor="let productB of mustIncludeForm.controls.productsB.value; let i = index" style="padding: 0 12px">
														{{ productB }}
														<button matChipRemove (click)="chipClicked(i, 'productsB')">
															<mat-icon>cancel</mat-icon>
														</button>
													</mat-chip>
													<mat-error *ngIf="mustIncludeForm.controls.productsB.getError('invalidArray') && mustIncludeForm.controls.productsB.touched">
														יש לבחור לפחות פריט אחד
													</mat-error>
												</mat-chip-list>
											</ng-container>
											<ng-container>
												<mat-form-field color="primary">
													<mat-label>פעולה</mat-label>
													<mat-select formControlName="action">
														<mat-option *ngFor="let action of mustIncludeActions" [value]="action" style="direction: rtl">
															{{ actionToText(action) }}
														</mat-option>
													</mat-select>
													<mat-error>שדה חסר או אינו תקין</mat-error>
												</mat-form-field>
											</ng-container>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
								<div class="justify-align-center">
									<button (click)="mustIncludeForm.markAllAsTouched()" [disabled]="mustIncludeForm.invalid" mat-raised-button color="primary">{{ submitButtonText() }}</button>
								</div>
							</form>
						</ng-template>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
<!-- end markup for sort popup -->

<!-- markup for  products popup popup -->
<div class="popup" *ngIf="isProductsPopupOpen">
	<div class="overlay"></div>
	<div class="order-cart__popup popup__content products">
		<div class="order-cart__popup-block row">
			<button class="order-cart__popup-button" *ngIf="!isEdittingSpecial" (click)="handleCloseProductsPopup()">אשר</button>
			<button class="order-cart__popup-button order-cart__popup-button_send" (click)="handleCloseProductsPopup()">סגור</button>
		</div>
		<div class="order-cart__popup-block row" *ngIf="!isEdittingSpecial && canSeeAddNewProductButton">
			<div class="product-detail__button flex justify-align-center" (click)="addProductPressed = true">הוסף פריט</div>
		</div>

		<form [formGroup]="form" style="width: 100%" dir="rtl" *ngIf="addProductPressed">
			<mat-form-field class="example-full-width override-bidi" appearance="fill" style="width: 100%">
				<mat-label>לחץ לחיפוש מוצר</mat-label>
				<input type="text" matInput [formControl]="products" [matAutocomplete]="auto" />
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="handleProductSelect($event)">
					<mat-option *ngFor="let product of allProductsSearch$ | async" [value]="product" style="direction: rtl">
						<span style="unicode-bidi: isolate">{{ product.ProductId }}</span> - <span style="unicode-bidi: isolate">{{ product.Product_Name }}</span>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</form>
		<div class="table-wrapper">
			<table *ngIf="selectedProducts.value.length" class="product-table" dir="rtl">
				<thead>
					<tr>
						<th style="min-width: 200px" #TableStart>מוצר</th>
						<th style="min-width: 50px">{{ _text.packQuantityText }}</th>
						<th style="min-width: 50px">יחידות</th>
						<th style="min-width: 50px">מחיר</th>
						<th style="min-width: 50px">בונוס</th>
						<th style="min-width: 50px" *ngIf="!isEdittingSpecial">מחק</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let product of selectedProducts.value; let i = index">
						<td>{{ product.ProductId + ' - ' + product.Product_Name }}</td>
						<td>
							<mat-form-field appearance="outline">
								<input
									matInput
									type="number"
									[disabled]="isEdittingSpecial"
									[placeholder]="'' + product.TeudaPackQuantity"
									min="0"
									(keyup)="productInputsChanged('TeudaPackQuantity', $event, i)"
								/>
							</mat-form-field>
							<span>({{ product.ItemPack }})</span>
						</td>
						<td>
							<mat-form-field appearance="outline">
								<input matInput type="number" [disabled]="isEdittingSpecial" [placeholder]="'' + product.TeudaQuantity" min="0" (keyup)="productInputsChanged('TeudaQuantity', $event, i)" />
							</mat-form-field>
						</td>
						<td>
							<mat-form-field appearance="outline">
								<input
									matInput
									type="number"
									[disabled]="isEdittingSpecial"
									[placeholder]="'' + product.TeudaItemNetPrice"
									(keyup)="productInputsChanged('TeudaItemNetPrice', $event, i)"
									min="0"
								/>
							</mat-form-field>
							<span>({{ product.Price }})</span>
						</td>
						<td>
							<mat-form-field appearance="outline">
								<input
									matInput
									type="number"
									[disabled]="isEdittingSpecial"
									[placeholder]="'' + product.TeudaBonusQuantity"
									min="0"
									(keyup)="productInputsChanged('TeudaBonusQuantity', $event, i)"
								/>
							</mat-form-field>

							<app-slider-toggle (checkedChange)="toggleProductBonusType(i, $event)" *ngIf="canPickBonusType" label="אר'" prefixLabel="יח'"></app-slider-toggle>
						</td>
						<td *ngIf="!isEdittingSpecial">
							<button mat-icon-button (click)="removeItem(i)">
								<mat-icon color="primary">delete</mat-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<!-- end markup for products popup  popup -->
