import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-custom-svg',
	templateUrl: './custom-svg.component.html',
	styleUrls: ['./custom-svg.component.scss'],
})
export class CustomSvgComponent {
	@Input() svg: 'delete' | '' = '';

	constructor() {}
}
