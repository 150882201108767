import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-admin-manage-company',
	templateUrl: './admin-manage-company.component.html',
	styleUrls: ['./admin-manage-company.component.scss'],
})
export class AdminManageCompanyComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _receipts: ReceiptsService) {}

	public HasMultipleCompanys;
	public HasMultiplleCompanysPop;
	public online;
	public ShowCompanys;

	windowWidth = innerWidth;

	hasSpecials = false;

	logo=MOBILITY_LOGO

	async ngOnInit() {
		window.scrollTo(0, 0);

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this._receipts.refreshReceiptsParameters();

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');
		this.hasSpecials = ['1', '2', '3'].includes(this._ss.CustomerInformation.HasSpecials);

		if (this.HasMultipleCompanys === '2' || this._ss.CustomerInformation.HasMultipleCompanies > 1) {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}
	}

	public MoveToClients() {
		this.router.navigate(['clients']);
	}

	public BackToHome() {
		this.router.navigate(['home']);
	}

	public BackToDohot() {
		history.back();
	}

	public CompanyChoosen(company) {
		this._ss.AdminCompanyChosen = company;

		setTimeout(() => {
			this.router.navigate([this.waitingToMoveTo], { queryParams: { company } });
		}, 0);
	}

	public NoChoose() {
		this.HasMultiplleCompanysPop = false;
	}

	navigateAdminGoals(to: 'agents' | 'clients') {
		this.router.navigate(['admin-goals'], { queryParams: { type: to } });
	}

	waitingToMoveTo = '';

	checkIfHasMultipleCompanys(navPath: string) {
		this.online = window.navigator.onLine;

		if ((this.HasMultipleCompanys === '2' || this._ss.CustomerInformation.HasMultipleCompanies > 1) && this.online === true) {
			this.HasMultiplleCompanysPop = true;
			this.waitingToMoveTo = navPath;

			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		} else {
			this.router.navigate([navPath]);
		}
	}
}
