import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { decompress } from 'lz-string';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { BaseComponent } from '../base-component/base.component';
import { VisitsPlannedVsUnplanned } from 'src/app/Interfaces/SQL-reports-responses';
import { Time } from 'src/app/services/util.service';
import { TextService } from 'src/app/services/text.service';

@Component({
	selector: 'app-adminbizoateudot',
	templateUrl: './adminbizoateudot.component.html',
	styleUrls: ['./adminbizoateudot.component.scss'],
})
export class AdminbizoateudotComponent extends BaseComponent implements OnInit, OnDestroy {
	constructor(public router: Router, public _fb: FormBuilder, public _text: TextService) {
		super();
	}

	public AllClients = [];

	public AllClientsSearch;

	public OneTeudaAgent;

	public UpdateSystem = false;

	public Loading = true;

	//TODO Change these to Set()
	public Doc: Map<string, string> = new Map();
	DocArr = [];

	public AgentMap = new Map();
	AgentArray = [];
	public FilterLoading = false;

	public LoadingBack = false;

	public LoadingSeeTeuda = false;
	public SeeTeudaLoaded = false;
	public selectedTeudaToOpenPopup: { Client_Name?: string; DocRemark?: string; DocCustCode?: string; DocDocNum?: string } = {};
	TeudotEach = [];
	document = document;
	shouldColorRedAndBolden = false;
	custVAT = this._ss.valueAddedTax;

	Math = window.Math;

	agents: FormControl;

	numOfItemsPerPage = 50;
	currentPage = 1;

	monthInMS = 1000 * 60 * 60 * 24 * 30;

	dateSortArr: { name: string; active: boolean }[] = [
		{ active: true, name: 'all' },
		{ active: false, name: 'last20' },
		{ active: false, name: 'monthStart' },
		{ active: false, name: 'prevMonth' },
		{ active: false, name: '30Days' },
	];

	minDate = new Date();

	agentsToGet = [];

	destroy$: Subject<void> = new Subject();

	total$: BehaviorSubject<number> = new BehaviorSubject(0);

	agentControl = new FormControl<string[]>([]);
	docTypeControl = new FormControl<string[]>([]);

	isUndelivered = location.pathname.includes('undelivered');

	title$: Observable<string>;
	showSearch$: Observable<boolean>;
	totalSum$: Observable<number>;

	maxVisitsLength = 0;

	form = this._fb.group({
		ClientSearch: ['', Validators.required],
		FromDate: ['', Validators.required],
		ToDate: ['', [Validators.required, Validators.min(this.minDate.getTime())]],
		DocType: this.docTypeControl,
		Agent: this.agentControl,
		Page: this.numOfItemsPerPage,
	});

	ngOnInit(): void {
		this._ss.CompanyNumForDuplicate = null;

		window.scrollTo(0, 0);

		this._ss.Betipul = 0;
		this._ss.TeudotDetailsEach = [];
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		if (this._ss.CustomerInformation.Submanagers == '1') {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss
					.getAdminAgents()
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							if (typeof res == 'string') {
								alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
								return;
							}

							this.agentsToGet = res.recordset.map(obj => obj.Agent);
							this.agentsToGet.push(this._ss.AgentIdConnected);

							this.getAllTeudot();
						},
						error: err => {
							alert('שגיאת שרת');
						},
					});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getAllTeudot();
			}
		} else {
			this.getAllTeudot();
		}

		if (this._ss.CustomerInformation.DisplayWithVat === '1') {
			// is 0
			if (!this._ss.valueAddedTax) {
				this._ss.valueAddedTax = this.custVAT = JSON.parse(decompress(localStorage.getItem('AllClients')))[0].CustVat;
			}
		}

		this.form.controls.Page.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.numOfItemsPerPage = Number(val);
			this.currentPage = Math.ceil(this.AllClients.length / val) < this.currentPage ? Math.ceil(this.AllClients.length / val) : this.currentPage;
		});
		this.form.controls.FromDate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.form.controls.ToDate.reset();

			this.minDate = new Date(val);
		});
	}

	async getAllTeudot() {
		if (this.isUndelivered) {
			await this.getOnlyUndeliveredTeudot();
			return;
		}

		// if (this._ss.SaveTeudotDohot === null) {
		this._ss
			.GetAllTeudotAdmin(this._ss.AdminCompanyChosen, this.agentsToGet)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: any) => {
					if (!res.recordset || !res.recordset.length) {
						this.Loading = false;
						alert('ישנה תקלה בהבאת נתונים. נסו שנית מאוחר יותר');
						history.back();
						return;
					}

					this.Loading = false;

					this.AllClientsSearch = res;
					this.AllClientsSearch = this.AllClientsSearch.recordset;
					this._ss.SaveTeudotDohot = this.AllClientsSearch;

					let total = 0;

					for (let x = 0; x < this.AllClientsSearch.length; x++) {
						this.AllClientsSearch[x].ClientId = this.AllClientsSearch[x].DocCustCode;
						// if (this.Doc instanceof Map)
						this.Doc.set(this.AllClientsSearch[x].DocTypeName, this.AllClientsSearch[x].DocTypeName);

						this.AgentMap.set(this.AllClientsSearch[x].AgentId, this.AllClientsSearch[x].AgentId);

						total += +this.AllClientsSearch[x].DocTotalSum || 0;
					}

					this.total$.next(total);

					for (const val of this.Doc.values()) {
						this.DocArr.push(val);
					}
					for (const val of this.AgentMap.values()) {
						this.AgentArray.push(val);
					}

					this.AgentArray.sort((a, b) => a - b);

					// this._ss.adminBizoaSearchArrays = {
					// 	DocArr: this.DocArr,
					// 	AgentArray: this.AgentArray,
					// };

					this.AllClients = this.AllClientsSearch;

					console.log(this.AllClients);
				},
				error: err => {
					this.Loading = false;
					alert('ישנה תקלה בהבאת נתונים. נסו שנית מאוחר יותר');
					history.back();
				},
			});
		// } else {
		// 	this.Loading = false;
		// 	this.LoadingBack = true;

		// 	this.AllClients = this.AllClientsSearch = this._ss.SaveTeudotDohot;

		// 	this.total$.next(this.AllClients.reduce((p, c) => p + (+c.DocTotalSum || 0), 0));
		// 	if (this._ss.adminBizoaSearchArrays?.DocArr?.length && this._ss.adminBizoaSearchArrays?.AgentArray?.length) {
		// 		this.AgentArray = [...this._ss.adminBizoaSearchArrays.AgentArray];
		// 		this.DocArr = [...this._ss.adminBizoaSearchArrays.DocArr];
		// 	} else {
		// 		for (const client of this.AllClients) {
		// 			this.AgentMap.set(client.AgentId, client.AgentId);
		// 			this.Doc.set(client.DocTypeName, client.DocTypeName);
		// 		}
		// 		for (const val of this.Doc.values()) {
		// 			this.DocArr.push(val);
		// 		}
		// 		for (const val of this.AgentMap.values()) {
		// 			this.AgentArray.push(val);
		// 		}
		// 	}

		// 	this.LoadingBack = false;
		// }
	}

	async getOnlyUndeliveredTeudot() {
		return await new Promise<void>(resolve => {
			// if (this._ss.SaveTeudotDohot === null) {
			this._ss
				.getAllTeudotUndeliveredAdmin(false, this._ss.AdminCompanyChosen, this.agentsToGet)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (res: any) => {
						if (typeof res === 'string' || !res.recordset) {
							this.Loading = false;
							alert('ישנה תקלה בהבאת נתונים. נסו שנית מאוחר יותר');
							history.back();
							return resolve();
						}

						this.Loading = false;

						this.AllClientsSearch = res;
						this.AllClientsSearch = this.AllClientsSearch.recordset;
						this._ss.SaveTeudotDohot = this.AllClientsSearch;

						let total = 0;

						for (let x = 0; x < this.AllClientsSearch.length; x++) {
							this.AllClientsSearch[x].ClientId = this.AllClientsSearch[x].DocCustCode;
							this.Doc.set(this.AllClientsSearch[x].DocTypeName, this.AllClientsSearch[x].DocTypeName);

							this.AgentMap.set(this.AllClientsSearch[x].AgentId, this.AllClientsSearch[x].AgentId);
							total += +this.AllClientsSearch[x].DocTotalSum || 0;
						}

						this.total$.next(total);

						for (const val of this.Doc.values()) {
							this.DocArr.push(val);
						}
						for (const val of this.AgentMap.values()) {
							this.AgentArray.push(val);
						}

						this.AgentArray.sort((a, b) => a - b);

						// this._ss.adminBizoaSearchArrays = {
						// 	DocArr: this.DocArr,
						// 	AgentArray: this.AgentArray,
						// };

						this.AllClients = this.AllClientsSearch;

						resolve();
					},
					error: err => {
						console.log(err);
						this.Loading = false;
						alert('ישנה תקלה בהבאת נתונים. נסו שנית מאוחר יותר');
						history.back();
						resolve();
					},
				});
			// } else {
			// 	this.Loading = false;
			// 	this.LoadingBack = true;

			// 	this.AllClients = this.AllClientsSearch = this._ss.SaveTeudotDohot;

			// 	let total = 0;

			// 	if (this._ss.adminBizoaSearchArrays?.DocArr.length && this._ss.adminBizoaSearchArrays?.AgentArray.length) {
			// 		this.AgentArray = this._ss.adminBizoaSearchArrays.AgentArray;
			// 		this.DocArr = this._ss.adminBizoaSearchArrays.DocArr;
			// 	} else {
			// 		for (const client of this.AllClients) {
			// 			this.AgentMap.set(client.AgentId, client.AgentId);
			// 			this.Doc.set(client.DocTypeName, client.DocTypeName);
			// 			total += +client.DocTotalSum || 0;
			// 		}

			// 		this.total$.next(total);

			// 		for (const val of this.Doc.values()) {
			// 			this.DocArr.push(val);
			// 		}
			// 		for (const val of this.AgentMap.values()) {
			// 			this.AgentArray.push(val);
			// 		}
			// 	}

			// 	this.LoadingBack = false;
			// 	resolve();
			// }
		});
	}

	dateInvalid = false;

	handleDateChange(val) {
		this.dateSortArr = this.dateSortArr.map(date => ({ ...date, active: false }));

		console.log(val);
		if (val == 'from') {
			this.dateInvalid = this.form.controls.FromDate.invalid || this.form.controls.ToDate.invalid;
			console.log({ dateInvalid: this.dateInvalid, from: this.form.controls.FromDate.invalid, to: this.form.controls.ToDate.invalid });
		} else {
			if (!this.form.controls.FromDate.value || new Date(this.form.controls.FromDate.value).getTime() > new Date(this.form.controls.ToDate.value).getTime()) {
				const msg = !this.form.controls.FromDate.value ? "ערך 'מתאריך' אינו תקין" : "ערך 'עד תאריך' אינו יכול להיות קטן מערך 'מתאריך'";
				alert(msg);
				this.dateInvalid = true;
				this.form.controls.ToDate.reset();
			} else {
				this.dateInvalid = false;
			}
		}
	}

	changeDateSort(index: number) {
		this.dateSortArr = this.dateSortArr.map((date, i) => ({ ...date, active: index === i }));
		this.dateInvalid = false;

		this.form.controls.FromDate.reset();
		this.form.controls.ToDate.reset();
	}

	changePage(val: '-' | '+') {
		if (val === '-') {
			this.currentPage = Math.max(this.currentPage - 1, 1);
			return;
		}

		if (val === '+') {
			const max = Math.ceil(this.AllClients.length / this.numOfItemsPerPage);

			this.currentPage = Math.min(this.currentPage + 1, max);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		document.body.classList.remove('no-scroll');
	}

	toogleInput = false;
	toogleInputDate = false;
	showFilterPopup = false;
	searchActive = false;
	headerAbsolute = false;
	showLogoutPopup = false;

	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}

	handleAdvancedSearch() {
		this.AllClients = [];
		this.FilterLoading = true;
		this.currentPage = 1;

		const agentArr = this.form.controls.Agent.value;
		const docArr = this.form.controls.DocType.value;
		const dateFilter = this.dateSortArr.findIndex(date => date.active);

		console.log({ agentArr, docArr, dateFilter });

		let searcher;

		const startOfMonth = new Date();
		startOfMonth.setDate(0);
		const pastMonth = new Date();
		pastMonth.setDate(1);
		pastMonth.setMonth(new Date().getMonth() - 1 > -1 ? new Date().getMonth() - 1 : 11);

		const today = new Date();

		switch (dateFilter) {
			case 0:
				searcher = this.AllClientsSearch;
				break;
			case 1:
				searcher = this.AllClientsSearch.slice(0, 20);
				break;
			case 2:
				searcher = this.AllClientsSearch.filter(client => new Date(client.DocCreateDate.split('-').reverse().join('-')).getTime() >= startOfMonth.getTime());
				break;
			case 3:
				searcher = this.AllClientsSearch.filter(
					client =>
						new Date(client.DocCreateDate.split('-').reverse().join('-')).getTime() >= pastMonth.getTime() &&
						new Date(client.DocCreateDate.split('-').reverse().join('-')).getTime() < startOfMonth.getTime(),
				);
				break;
			case 4:
				searcher = this.AllClientsSearch.filter(client => new Date(client.dtorder).getTime() > today.getTime() - this.monthInMS && new Date(client.dtorder).getTime() < today.getTime());
				break;

			case -1:
				searcher = this.AllClientsSearch.filter(
					client =>
						new Date(client.dtorder).getTime() >= new Date(this.form.controls.FromDate.value).getTime() &&
						new Date(client.dtorder).getTime() <= new Date(this.form.controls.ToDate.value).getTime(),
				);
				break;

			default:
				break;
		}

		let total = 0;

		if (agentArr.length && docArr.length) {
			for (let i = 0; i < searcher.length; i++) {
				if (agentArr.includes(searcher[i].AgentId) && docArr.includes(searcher[i].DocTypeName)) {
					this.AllClients.push(searcher[i]);
					total += +searcher[i].DocTotalSum || 0;
				}
			}

			console.log({ searcher, clients: this.AllClients });
		} else {
			for (let i = 0; i < searcher.length; i++) {
				if (!agentArr.length && !docArr.length) {
					this.AllClients.push(searcher[i]);
					total += +searcher[i].DocTotalSum || 0;
				} else {
					if (agentArr.includes(searcher[i].AgentId) || docArr.includes(searcher[i].DocTypeName)) {
						this.AllClients.push(searcher[i]);
						total += +searcher[i].DocTotalSum || 0;
					}
				}
			}
			// this.AllClients = this.AllClientsSearch
		}

		this.total$.next(total);

		this.FilterLoading = false;

		this.showFilterPopup = false;
	}

	public HelpAllClients = [];
	public AgentFilter = [];

	public FromDateVarMonth = 0;
	public FromDateVarDay = 0;

	public ToDateVarMonth = 0;
	public ToDateVarDay = 0;

	public PickMonth = 0;
	public PickDay = 0;

	// public TeudaClicked(DocDocNum, DocDocType, Company, AgentId) {
	// 	this.Loading = true;

	// 	this.HelpAllClients = [];
	// 	this._ss
	// 		.GetOneTeudaAgentNew({ DocDocNum, DocDocType }, Company, AgentId)
	// 		.pipe(takeUntil(this.destroy$))
	// 		.subscribe(res => {
	// 			this._ss.TeudotDetailsEach = res;
	// 			this._ss.TeudotDetailsEach = this._ss.TeudotDetailsEach.recordset;

	// 			this.router.navigate(['dohot-teudot-each']);
	// 		});
	// }
	public BackToDohot() {
		history.back();
	}

	public SearchClear() {
		window.scrollTo(0, 0);

		this.form.reset();
		this.form.controls.Page.setValue(this.form.controls.Page.value);
		this.form.controls.Agent.setValue([]);
		this.form.controls.DocType.setValue([]);
		this.currentPage = 1;

		this.HelpAllClients = [];

		this.resetDateSort();

		this.AllClients = [...this.AllClientsSearch];
		this.total$.next(this.AllClients.reduce((p, c) => p + (+c.DocTotalSum || 0), 0));
	}
	public SearchClient() {
		window.scrollTo(0, 0);

		this.HelpAllClients = this.AllClients = [];

		this.currentPage = 1;

		const value = this.form.controls.ClientSearch.value?.toLowerCase?.();

		if (!value) {
			this.AllClients = this.AllClientsSearch;
		} else {
			const pattern = new RegExp(value.replace(/[\/*\+\-\?,]/g, ''), 'i');

			this.AllClients = this.AllClientsSearch.filter(client => pattern.test(client.Client_Name || '') || pattern.test(client.ClientId || ''));
		}

		this.total$.next(this.AllClients.reduce((p, c) => p + (+c.DocTotalSum || 0), 0));

		this.currentPage =
			Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage
				? Math.ceil(this.AllClients.length / this.numOfItemsPerPage)
				: this.AllClients.length && !this.currentPage
				? 1
				: this.currentPage;
	}

	resetDateSort() {
		this.dateSortArr = [
			{ active: true, name: 'all' },
			{ active: false, name: 'last20' },
			{ active: false, name: 'monthStart' },
			{ active: false, name: 'prevMonth' },
			{ active: false, name: '30Days' },
		];
	}

	@ViewChild('teudotTableNames') rightSideOfTeudaProducts: ElementRef<HTMLTableCellElement>;

	public TeudaClicked(DocDocNum, DocDocType, Company, AgentId) {
		this.LoadingSeeTeuda = true;

		document.body.classList.add('no-scroll');

		this.HelpAllClients = [];
		this._ss.GetOneTeudaAgentNew({ DocDocNum, DocDocType }, Company, AgentId).subscribe(
			(res: any) => {
				this.TeudotEach = res.recordset;
				// this._ss.TeudotDetailsEach = this._ss.TeudotDetailsEach.recordset

				this.selectedTeudaToOpenPopup = this.TeudotEach[0];
				this.shouldColorRedAndBolden = this.TeudotEach[0]?.DocDocType == '3' || this.TeudotEach[0].DocDocType == '5';
				setTimeout(() => {
					this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
				}, 10);

				if (this._ss.CustomerInformation.DisplayWithVat === '1') {
					this.TeudotEach = this.TeudotEach.map(td => ({ ...td, DocPrice: td.DocPrice * (1 + this.custVAT / 100), DocTotalRow: td.DocTotalRow * (1 + this.custVAT / 100) }));
				}
				this.SeeTeudaLoaded = true;
				this.LoadingSeeTeuda = false;

				// this.router.navigate(['dohotteudoteach'])
			},

			//TODO remove unnecesary dependancies and variables from dohotteudoteach
		);
	}
}
