<!-- <h1 mat-dialog-title>{{ title }}</h1> -->
<div mat-dialog-content style="font-size: 16px; font-weight: 600">
	<p *ngFor="let msg of messages">{{ msg }}</p>
</div>
<div mat-dialog-actions>
	<ng-container *ngIf="data.confirmAction">
		<button mat-button (click)="onYesClick()" cdkFocusInitial>אישור</button>
		<button mat-button (click)="onNoClick()" cdkFocusInitial>בטל</button>
	</ng-container>
	<ng-container *ngIf="data.confirmActionWithoutCancel">
		<button mat-button (click)="onNoClick()" cdkFocusInitial>הבנתי</button>
	</ng-container>
	<ng-template #normalCloseBlock>
		<button mat-button mat-dialog-close cdkFocusInitial>סגור</button>
	</ng-template>
</div>
