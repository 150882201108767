<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohotBizoim()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביצועים מחלקות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" *ngIf="FirstPage === true">
	<div class="content">
		<h1 *ngIf="Loading === true" style="text-align: right; margin-top: 100px">טוען אנא המתן...</h1>

		<div class="report-table__row report-table__row_between" style="margin-top: 50px">
			<div style="margin-left: 25px">
				<form [formGroup]="form" style="display: flex; gap: 1em">
					<select formControlName="performance" style="width: 150px; height: 35px">
						<option *ngFor="let c of performanceFilters; let i = index" [style.color]="i ? (i > 1 ? red : orange) : green" value="{{ i }}">{{ c }}</option>
					</select>
					<button (click)="form.controls['performance'].reset()">איפוס</button>
				</form>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px; word-break: break-all">
			<h1 *ngIf="Loading === false && !AllClientsSearch?.length" style="text-align: right; margin-top: 100px">אין נתונים להציג</h1>
			<table class="report-table__table" cellspacing="0" *ngIf="AllClientsSearch?.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 130px">קוד קבוצה</th>
						<th class="report-table__table-head-name" style="width: 160px">שם קבוצה</th>
						<th class="report-table__table-head-name" style="width: 70px">שנה</th>
						<th class="report-table__table-head-name" style="width: 70px">חודש</th>
						<th class="report-table__table-head-name" style="width: 150px">כמות</th>
						<th class="report-table__table-head-name" style="width: 170px">סה"כ</th>
						<th class="report-table__table-head-name" style="width: 170px">יעד</th>
						<th class="report-table__table-head-name" style="width: 170px">עמידה ביעד</th>
						<th class="report-table__table-head-name" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of AllClientsSearch">
						<!-- <tr *ngFor="let b of Bizoim"
              (click)="MoveToDohotBizoimLekohotEachYear({ClientId:b.cl,AgentId:b.ag,Company:b.cm,Year:b.Yy1,Client_Name:b.cn})"
              > -->

						<td class="report-table__table-body-name">{{ b.groupid }}</td>
						<td class="report-table__table-body-name">{{ b.groupname }}</td>
						<td class="report-table__table-body-name">{{ b.yy1 }}</td>
						<td class="report-table__table-body-name">{{ b.mm1 }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.totqty | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.totsum | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" dir="ltr">{{ b.targetsum | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" [style.color]="b.ahuz > 50 ? (b.ahuz >= 99 ? green : orange) : red" dir="ltr" style="font-weight: 600">
							{{ b.ahuz ? (b.ahuz | number : '1.0-2' : 'en-US') + '%' : b.ahuz }}
						</td>
						<td class="report-table__table-body-name" *ngIf="HasMultipleCompanys > 1">{{ b.cm }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
