<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">מכירות סוכן לפי מחלקות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="flex-row" style="margin-top: 50px">
			<div *ngIf="ShowCompanys !== null">
				<form [formGroup]="form" (change)="ChangeCompany()">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div>

			<div class="box">
				<b>סה"כ</b>
				<span>
					{{ bizoimTotal$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ bizoimAverage$ | async | number : '1.0-2' }}
				</span>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 85px">מספר</th>
						<th class="report-table__table-head-name" style="width: 85px">שם</th>
						<th class="report-table__table-head-name" style="width: 150px">כמות</th>
						<th class="report-table__table-head-name" style="width: 150px">מכירות</th>
						<th class="report-table__table-head-name" style="width: 85px" *ngIf="ShowCompanys">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Bizoim">
						<td class="report-table__table-body-name">{{ b.GroupID }}</td>
						<td class="report-table__table-body-name">{{ b.GroupName }}</td>
						<td class="report-table__table-body-name">{{ b.TotQty }}</td>
						<td class="report-table__table-body-name">{{ b.TotMoney }}</td>
						<td class="report-table__table-body-name" dir="ltr" *ngIf="ShowCompanys">{{ b.cm }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
