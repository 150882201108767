import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { Subject, takeUntil } from 'rxjs';
// import mockRes from './mockRes';
import { TextService } from 'src/app/services/text.service';

@Component({
	selector: 'app-dohotteudot',
	templateUrl: './dohotteudot.component.html',
	styleUrls: ['./dohotteudot.component.scss'],
})
export class DohotteudotComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _text: TextService) {}

	public AllClients = [];

	public AllClientsSearch;

	public OneTeudaAgent;

	public Loading = true;

	public Doc = [];

	public FilterLoading = false;

	public LoadingBack = false;

	reportSearch = null;

	longestClientName;

	widthForClientName;

	custVAT = this._ss.valueAddedTax;

	MONTH_IN_MS = 1000 * 60 * 60 * 24 * 31; //  2629800000

	month = new Date().getTime() - this.MONTH_IN_MS;

	public LoadingSeeTeuda = false;
	public SeeTeudaLoaded = false;
	public selectedTeudaToOpenPopup: { Client_Name?: string; DocRemark?: string; DocCustCode?: string; DocDocNum?: string } = {};
	public TeudotEach;
	public TeudotTableEach;
	document = document;

	Math = window.Math;

	// agents: FormControl
	numOfItemsPerPage = 50;
	currentPage = 1;

	destroy$ = new Subject<void>();

	form = this._fb.group({
		Date: ['', Validators.required],
		ClientSearch: ['', Validators.required],
		FromDate: ['', Validators.required],
		ToDate: ['', Validators.required],
		DocType: ['', Validators.required],
		Page: [this.numOfItemsPerPage],
	});

	isLocal = location.hostname.includes('localhost');

	isAlternativeView = false;

	isUndelivered = location.pathname.includes('undelivered');

	ngOnInit(): void {
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		this._ss.CompanyNumForDuplicate = null;

		if (this._ss.CustomerInformation.DisplayDohotTeudot === '1') {
			this.isAlternativeView = true;
		}
		window.scrollTo(0, 0);

		this._ss.Betipul = 0;
		this._ss.TeudotDetailsEach = [];
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		if (this._ss.CustomerInformation.DisplayWithVat === '1') {
			// is 0
			if (!this._ss.valueAddedTax) {
				this._ss.valueAddedTax = this.custVAT = JSON.parse(decompress(localStorage.getItem('AllClients')))[0].CustVat;
			}
		}

		// if (this._ss.SaveTeudotDohot === null || !this._ss.SaveTeudotDohot.length) {
			this.handleGetAllTeudot();
		// } else {
		// 	this.Loading = false;
		// 	this.LoadingBack = true;

		// 	this.AllClients = this.AllClientsSearch = this._ss.SaveTeudotDohot;

		// 	this.setupLongestClientName();

		// 	this.LoadingBack = false;
		// }

		this.form.controls['Page'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.numOfItemsPerPage = Number(val);
			this.currentPage = Math.ceil(this.AllClients.length / val) < this.currentPage ? Math.ceil(this.AllClients.length / val) : this.currentPage;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	changePage(val: string) {
		//TODO can revert Number(numofitems)||50 to just numofitems
		if ((this.currentPage > Math.floor(this.AllClients.length / this.numOfItemsPerPage) && val == '+') || (this.currentPage < 2 && val == '-')) {
			return;
		} else {
			this.currentPage = val == '+' ? this.currentPage + 1 : this.currentPage - 1;
		}
	}

	public AllTeudot() {
		this.LoadingBack = true;

		this.AllClients = this.AllClientsSearch;

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		this.LoadingBack = false;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

		this.considerClientSearch();
	}

	public TopTwenty() {
		this.AllClients = this.AllClientsSearch.slice(0, 20);

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

		this.considerClientSearch();
	}

	public DateUpdate = new Date();

	public ThisMonth() {
		this.DateUpdate = new Date();

		this.LoadingBack = true;

		this.AllClients = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].DocCreateDate.toString().slice(3, 5) === this.DateUpdate.toISOString().toString().slice(5, 7)) {
				this.AllClients.push(this.AllClientsSearch[x]);
			} else {
				break;
			}
		}

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

		this.LoadingBack = false;

		this.considerClientSearch();
	}

	public LastMonth() {
		this.DateUpdate.setMonth(this.DateUpdate.getMonth() - 1);

		this.LoadingBack = true;

		this.AllClients = [];

		const relevantClientSearch = (this.AllClientsSearch as any[]).findIndex(cs => cs.DocCreateDate.toString().slice(3, 5) === this.DateUpdate.toISOString().toString().slice(5, 7));

		for (let x = relevantClientSearch; x < this.AllClientsSearch.slice(relevantClientSearch).length; x++) {
			if (this.AllClientsSearch[x].DocCreateDate.toString().slice(3, 5) === this.DateUpdate.toISOString().toString().slice(5, 7)) {
				this.AllClients.push(this.AllClientsSearch[x]);
			} else {
				break;
			}
		}

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

		this.LoadingBack = false;

		this.considerClientSearch();
	}

	public ThirtyDays() {
		this.AllClients = this.AllClientsSearch.filter(cls => new Date(cls.dtorder).getTime() > this.month);

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

		this.considerClientSearch();
	}

	trackByFnClient(ac) {
		return ac.ClientId;
	}

	toogleInput = false;
	toogleInputDate = false;
	showFilterPopup = false;
	searchActive = false;
	showLogoutPopup = false;

	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	public HelpAllClients = [];
	public DocTypeSelect() {
		this.FilterLoading = true;

		this.AllClients = [];

		if (this.HelpAllClients.length !== 0) {
			for (let x = 0; x < this.HelpAllClients.length; x++) {
				if (this.HelpAllClients[x].DocTypeName === this.form.controls['DocType'].value) {
					this.AllClients.push(this.HelpAllClients[x]);
				}
			}

			this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

			// if 0
			this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

			this.FilterLoading = false;
		} else {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClientsSearch[x].DocTypeName === this.form.controls['DocType'].value) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}

			this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

			// if 0
			this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

			this.FilterLoading = false;
		}

		this.considerClientSearch();
	}

	public FromDateVarMonth = 0;
	public FromDateVarDay = 0;
	public FromDateFunction() {
		this.AllClients = [];

		this.form.controls['ToDate'].reset();

		if (JSON.parse(this.form.controls['FromDate'].value.toString().slice(5, 6)) === 0) {
			this.FromDateVarMonth = JSON.parse(this.form.controls['FromDate'].value.toString().slice(6, 7));
		} else {
			this.FromDateVarMonth = JSON.parse(this.form.controls['FromDate'].value.toString().slice(5, 7));
		}

		if (JSON.parse(this.form.controls['FromDate'].value.toString().slice(8, 9)) === 0) {
			this.FromDateVarDay = JSON.parse(this.form.controls['FromDate'].value.toString().slice(9, 10));
		} else {
			this.FromDateVarDay = JSON.parse(this.form.controls['FromDate'].value.toString().slice(8, 10));
		}

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (
				this.AllClientsSearch[x].DocCreateDate.toString().slice(0, 2) === this.form.controls['FromDate'].value.toString().slice(8, 10) &&
				this.AllClientsSearch[x].DocCreateDate.toString().slice(3, 5) === this.form.controls['FromDate'].value.toString().slice(5, 7)
			)
				this.AllClients.push(this.AllClientsSearch[x]);
		}

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;

		this.considerClientSearch();
	}

	public ToDateVarMonth = 0;
	public ToDateVarDay = 0;

	public PickMonth = 0;
	public PickDay = 0;

	public ToDateFunction() {
		this.PickMonth = 0;
		this.PickDay = 0;

		this.AllClients = [];

		if (this.FromDateVarMonth === 0) {
			alert('חייב לבחור מתאריך');
			this.form.controls['ToDate'].reset();
		} else {
			if (JSON.parse(this.form.controls['ToDate'].value.toString().slice(5, 6)) === 0) {
				this.ToDateVarMonth = JSON.parse(this.form.controls['ToDate'].value.toString().slice(6, 7));
			} else {
				this.ToDateVarMonth = JSON.parse(this.form.controls['ToDate'].value.toString().slice(5, 7));
			}

			if (JSON.parse(this.form.controls['ToDate'].value.toString().slice(8, 9)) === 0) {
				this.ToDateVarDay = JSON.parse(this.form.controls['ToDate'].value.toString().slice(9, 10));
			} else {
				this.ToDateVarDay = JSON.parse(this.form.controls['ToDate'].value.toString().slice(8, 10));
			}

			if (this.FromDateVarDay < this.ToDateVarDay) {
				for (let y = this.FromDateVarMonth; y < this.FromDateVarMonth + 1; y++) {
					for (let z = this.FromDateVarDay; z < this.ToDateVarDay + 1; z++) {
						for (let x = 0; x < this.AllClientsSearch.length; x++) {
							if (this.AllClientsSearch[x].DocCreateDate.toString().slice(0, 1) === '0') {
								this.PickDay = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(1, 2));
							} else {
								this.PickDay = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(0, 2));
							}

							if (this.AllClientsSearch[x].DocCreateDate.toString().slice(3, 4) === '0') {
								this.PickMonth = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(4, 5));
							} else {
								this.PickMonth = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(3, 5));
							}

							if (this.PickDay === z && this.PickMonth === y) {
								this.AllClients.push(this.AllClientsSearch[x]);
							}

							this.HelpAllClients = this.AllClients;
						}
					}
				}
			} else {
				if (this.FromDateVarMonth === this.ToDateVarMonth) {
					alert('תאריך יעד חייב להיות מאוחר מתאריך התחלה');
					this.form.controls['ToDate'].reset();
				} else {
					for (let y = this.FromDateVarMonth; y < this.ToDateVarMonth; y++) {
						for (let z = this.FromDateVarDay; z < 32; z++) {
							for (let x = 0; x < this.AllClientsSearch.length; x++) {
								if (this.AllClientsSearch[x].DocCreateDate.toString().slice(0, 1) === '0') {
									this.PickDay = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(1, 2));
								} else {
									this.PickDay = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(0, 2));
								}

								if (this.AllClientsSearch[x].DocCreateDate.toString().slice(3, 4) === '0') {
									this.PickMonth = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(4, 5));
								} else {
									this.PickMonth = JSON.parse(this.AllClientsSearch[x].DocCreateDate.toString().slice(3, 5));
								}

								if (this.PickDay === z && this.PickMonth === y) {
									this.AllClients.push(this.AllClientsSearch[x]);
								}
							}
						}
					}
				}
			}

			this.considerClientSearch();
		}
	}

	@ViewChild('teudotTableNames') rightSideOfTeudaProducts: ElementRef<HTMLTableCellElement>;
	shouldColorRedAndBolden = false;

	public TeudaClicked(DocDocNum, DocDocType, Company) {
		this.LoadingSeeTeuda = true;

		this.HelpAllClients = [];
		this._ss.GetOneTeudaAgentNew({ DocDocNum, DocDocType }, Company).subscribe(
			(res: any) => {
				this.TeudotEach = res.recordset;
				// this._ss.TeudotDetailsEach = this._ss.TeudotDetailsEach.recordset

				this.selectedTeudaToOpenPopup = this.TeudotEach[0];
				this.shouldColorRedAndBolden = this.TeudotEach[0]?.DocDocType == '3' || this.TeudotEach[0].DocDocType == '5';
				setTimeout(() => {
					this.rightSideOfTeudaProducts.nativeElement.scrollIntoView();
				}, 10);

				if (this._ss.CustomerInformation.DisplayWithVat === '1') {
					this.TeudotEach = this.TeudotEach.map(td => ({ ...td, DocPrice: td.DocPrice * (1 + this.custVAT / 100), DocTotalRow: td.DocTotalRow * (1 + this.custVAT / 100) }));
				}
				this.SeeTeudaLoaded = true;
				this.LoadingSeeTeuda = false;

				// this.router.navigate(['dohotteudoteach'])
			},

			//TODO remove unnecesary dependancies and variables from dohotteudoteach
		);
	}

	public BackToDohot() {
		history.back();
	}

	public SearchClear() {
		window.scrollTo(0, 0);

		this.form.controls['ToDate'].reset();
		this.form.controls['FromDate'].reset();

		this.HelpAllClients = [];
		this.AllClients = [];

		this.AllClients = this.AllClientsSearch;

		const numOfItems = this.form.controls['Page'].value;

		this.form.reset();
		this.form.controls['Page'].setValue(numOfItems);
		this.currentPage = 1;
	}

	public SearchClient() {
		window.scrollTo(0, 0);

		this.HelpAllClients = this.AllClients = [];

		const value = this.form.controls['ClientSearch'].value?.toLowerCase?.();

		if (!value) {
			this.AllClients = this.AllClientsSearch;
		} else {
			const pattern = new RegExp(value.replace(/[\/*\+\-\?,]/g, ''), 'i');

			this.AllClients = this.AllClientsSearch.filter(client => pattern.test(client.Client_Name || '') || pattern.test(client.ClientId || ''));
		}

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;
	}

	show: { [key: number]: boolean } = {};

	public indexClose = null;

	openSelect(index: number) {
		if (this.indexClose !== null && this.indexClose !== index) {
			this.show[this.indexClose] = false;
		}

		if (this.show[index] === false) {
			this.show[index] = true;
		} else {
			this.show[index] = false;
		}

		setTimeout(() => {
			this.indexClose = index;
		}, 100);
	}

	considerClientSearch() {
		window.scrollTo(0, 0);

		const value = this.form.controls['ClientSearch'].value?.toLowerCase?.();

		if (!value) {
			return;
		}

		const pattern = new RegExp(value.replace(/[\/*\+\-\?,]/g, ''), 'i');

		this.AllClients = this.AllClients.filter(client => pattern.test(client.Client_Name || '') || pattern.test(client.ClientId || ''));

		this.currentPage = Math.ceil(this.AllClients.length / this.numOfItemsPerPage) < this.currentPage ? Math.ceil(this.AllClients.length / this.numOfItemsPerPage) : this.currentPage;

		// if 0
		this.currentPage = this.AllClients.length && !this.currentPage ? 1 : this.currentPage;
	}

	handlePressRefresh() {
		this.Loading = true;
		this.handleGetAllTeudot();
	}

	handleGetAllTeudot() {
		const callbacksObject = {
			next: (res: any) => {
				this.Loading = false;
				if (!res.recordset) {
					alert('ישנה תקלה בהבאת נתונים. נסו שנית מאוחר יותר');
					history.back();
					return;
				}

				this._ss.SaveTeudotDohot = this.AllClientsSearch = res.recordset;

				const teudot = [];

				for (let x = 0; x < this.AllClientsSearch.length; x++) {
					this.AllClientsSearch[x].ClientId = this.AllClientsSearch[x].DocCustCode;

					let data = this.Doc.find(ob => ob.DocTypeName === this.AllClientsSearch[x].DocTypeName);

					if (data === undefined) {
						this.Doc.push({ DocTypeName: this.AllClientsSearch[x].DocTypeName });
					}

					teudot.push(this.AllClientsSearch[x]);
				}

				this.setupLongestClientName();

				this.AllClients = teudot;

				this.considerClientSearch();
			},
			error: err => {
				this.Loading = false;
				alert('ישנה תקלה בהבאת נתונים. נסו שנית מאוחר יותר');
				history.back();
			},
		};

		if (this.isUndelivered) {
			this._ss.GetAllTeudotAgentNewUndelivered().pipe(takeUntil(this.destroy$)).subscribe(callbacksObject);
			return;
		}

		this._ss.GetAllTeudotAgentNew().pipe(takeUntil(this.destroy$)).subscribe(callbacksObject);
	}

	setupLongestClientName() {
		this.longestClientName = Math.max(...this.AllClients.map(debt => debt.Client_Name.length));
		this.widthForClientName = Math.round(window.innerWidth > 767 ? this.longestClientName * 7 : this.longestClientName * 5.2);
		this.widthForClientName ||= 100;
	}
}
