<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="handleBack()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביצועים סוכן</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<!-- <div class="report-table__row report-table__row_between" style="margin-top:50px;"> -->
		<div class="flex-row" style="margin-top: 50px">
			<div *ngIf="ShowCompanys !== null && !Second">
				<form [formGroup]="form" (change)="ChangeCompany()">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div>
			<!-- <div class="box">
				<b>סה"כ</b>
				<span>
					{{ total$ | async | number : '1.0-2' }}
				</span>
			</div> -->
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ average$ | async | number : '1.0-2' }}
				</span>
			</div>
		</div>

		<!-- </div> -->

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<ng-container *ngIf="!loading; else loadingBlock">
				<table class="report-table__table" cellspacing="0" *ngIf="First">
					<thead class="report-table__table-head">
						<tr>
							<th class="report-table__table-head-name sortable-clickable" style="width: 85px" (click)="translateForSort('MM', 'First')">חודש</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('Tot', 'First')">מכירות השנה</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('currentYearGoal', 'First')" *ngIf="hasYaad">יעד שנתי</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('advancementPercentage', 'First')" *ngIf="hasYaad">אחוז התקדמות</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('Ltot', 'First')">מכירות אשתקד</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('Ahuz', 'First')">גידול שנתי</th>
							<th class="report-table__table-head-name sortable-clickable" style="width: 100px" (click)="translateForSort('cm', 'First')" *ngIf="ShowCompanys">חברה</th>
						</tr>
					</thead>
					<tbody class="report-table__table-body">
						<tr *ngFor="let b of Bizoim" (click)="monthPicked(b.MM, b.YY, b.cm)">
							<td class="report-table__table-body-name" style="width: 100px">{{ b.MM }}-{{ b.YY }}</td>
							<td
								class="report-table__table-body-name"
								[style.fontWeight]="b.Tot < 0 ? '700' : null"
								[style.color]="b.Tot < 0 ? 'red' : null"
								style="width: 100px; position: relative"
								dir="ltr"
							>
								<img
									*ngIf="+b.Ahuz !== 0 && b.MM <= month + 1"
									[src]="b.Ahuz < 100 ? '../../../assets/images/arrow-down-red.svg' : '../../../assets/images/arrow-up-green.svg'"
									class="arrow"
								/>
								{{ b.Tot | number : '1.0-2' }}
							</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr" *ngIf="hasYaad">
								{{ b.currentYearGoal ? (b.currentYearGoal | number : '1.0-2') : 'אין נתונים עדיין' }}
							</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr" *ngIf="hasYaad">
								{{ b.advancementPercentage ? (b.advancementPercentage | number : '1.0-2' : 'en-US') + '%' : 'אין נתונים עדיין' }}
							</td>
							<td class="report-table__table-body-name" [style.fontWeight]="b.Ltot < 0 ? '700' : null" [style.color]="b.Ltot < 0 ? 'red' : null" dir="ltr" style="width: 100px">
								{{ b.Ltot | number : '1.0-2' }}
							</td>
							<td class="report-table__table-body-name" [style.fontWeight]="b.Ahuz < 0 ? '700' : null" [style.color]="b.Ahuz < 0 ? 'red' : null" style="width: 100px" dir="ltr">
								{{ (b.Ahuz | number : '1.0-2' : 'en-US') + '%' }}
							</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr" *ngIf="ShowCompanys">{{ b.cm }}</td>
						</tr>
						<tr>
							<td class="report-table__table-body-name" style="width: 100px">סה"כ</td>
							<td class="report-table__table-body-name" style="width: 100px">{{ total$ | async | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 100px" *ngIf="hasYaad">{{ totalYaad$ | async | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 100px" *ngIf="hasYaad">{{ totalYaadPercentage$ | async | number : '1.0-2' : 'en-US' }}%</td>
							<td class="report-table__table-body-name" style="width: 100px">{{ totalLastYear$ | async | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 100px">{{ totalAhuz$ | async | number : '1.0-2' : 'en-US' }}%</td>
							<td class="report-table__table-body-name" style="width: 100px" *ngIf="ShowCompanys"></td>
						</tr>
					</tbody>
				</table>
				<table class="report-table__table" cellspacing="0" *ngIf="Second && !loading">
					<thead class="report-table__table-head">
						<tr>
							<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('Cl', 'Second')" style="width: 150px">קוד לקוח</th>
							<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cn', 'Second')" style="width: 150px">שם לקוח</th>
							<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('tot', 'Second')" style="width: 150px">מכירות השנה</th>
							<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('ltot', 'Second')" style="width: 150px">מכירות אשתקד</th>
							<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('ahuz', 'Second')" style="width: 150px">קצב התקדמות באחוזים</th>
							<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('cm', 'Second')" style="width: 150px" *ngIf="HasMultipleCompanys === 2">חברה</th>
						</tr>
					</thead>
					<tbody class="report-table__table-body">
						<tr *ngFor="let a of monthly$ | async">
							<td class="report-table__table-body-name" colspan="2" style="width: 300px">{{ a.Cl }} - {{ a.cn }}</td>
							<!-- <td class="report-table__table-body-name" style="width: 150px">{{ a.cn }}</td> -->
							<td class="report-table__table-body-name" style="width: 150px">{{ a.tot | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 150px">{{ a.ltot | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 150px">{{ (a.ahuz | number : '1.0-2' : 'en-US') + '%' }}</td>
							<td class="report-table__table-body-name" style="width: 150px" *ngIf="HasMultipleCompanys === 2">{{ a.cm }}</td>
						</tr>
						<tr>
							<td class="report-table__table-body-name" style="width: 150px">סה"כ</td>
							<td class="report-table__table-body-name" style="width: 150px"></td>
							<td class="report-table__table-body-name" style="width: 150px">{{ totalMonth$ | async | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 150px">{{ totalLastYearMonth$ | async | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 150px">{{ totalAhuzMonth$ | async | number : '1.0-2' : 'en-US' }}%</td>
							<td class="report-table__table-body-name" style="width: 150px" *ngIf="ShowCompanys"></td>
						</tr>
					</tbody>
				</table>
			</ng-container>
			<ng-template #loadingBlock>
				<h1>טוען...</h1>
			</ng-template>
		</div>
	</div>
</section>
<!-- end report table markup -->
