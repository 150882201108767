import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { LoginResponse } from 'src/app/Interfaces/login-response';
import { Order } from 'src/app/Interfaces/order';
import { TextService } from 'src/app/services/text.service';

@Component({
	selector: 'app-daily-report-each',
	templateUrl: './daily-report-each.component.html',
	styleUrls: ['./daily-report-each.component.scss'],
})
export class DailyReportEachComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _text: TextService) {}

	public TeudotDetailsEach = this._ss.TeudotDetailsEach || [{ Client_Name: '' }];

	public AllOrder = [];

	public PopDuplicate = false;

	public ConfirmPop = false;

	shouldHearotPopupShow = false;

	agentInfo: LoginResponse = {};

	anyRemarksAtAll = false;

	clientAllDocTypes;

	DocTypePerClient = [];

	AllDocTypesSave;

	document = document;

	canSeePrice = true;

	Admin = localStorage.getItem('Admin');

	showItemInPack = true;

	// addressToDisplay = this._ss.OneClientInfo?.Address && this._ss.OneClientInfo?.CustCity
	// ? `${this._ss.OneClientInfo.Address}, ${this._ss.OneClientInfo.CustCity}`
	// : this._ss.OneClientInfo?.Address || this._ss.OneClientInfo?.CustCity || 'לא קיימת'

	ngOnInit(): void {
		if (!this._ss.valueAddedTax) {
			this._ss.valueAddedTax = JSON.parse(decompress(localStorage.getItem('AllClients')))[0].CustVat;
		}

		window.scrollTo(0, 0);

		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		this.showItemInPack = this._ss.CustomerInformation.HideQuantityInPack !== '1';

		if (!this._ss.TeudotDetailsEach) {
			this.router.navigate(['daily-report']);
			return;
		}

		if (this.Admin && this.Admin.includes('b2b') && this._ss.CustomerInformation.B2BCanSeePrices === '1') {
			this.canSeePrice = false;
		}

		this.agentInfo.ordon = Number(localStorage.getItem('ordon'));
		this.agentInfo.hazaon = Number(localStorage.getItem('hazaon'));
		this.agentInfo.reton = Number(localStorage.getItem('reton'));

		if (localStorage.getItem('AllDocTypesSave') !== null) {
			this.DocTypePerClient = [];
			this.AllDocTypesSave = decompress(localStorage.getItem('AllDocTypesSave'));
			this.AllDocTypesSave = JSON.parse(this.AllDocTypesSave);

			const { Client_Id, TeudaCompany: company } = this.TeudotDetailsEach[0];

			for (let x = 0; x < this.AllDocTypesSave.length; x++) {
				if (this.AllDocTypesSave[x].Company == company && this.AllDocTypesSave[x].ClientID == Client_Id) {
					this.DocTypePerClient.push(this.AllDocTypesSave[x]);
				}
			}
		}

		console.log({ TeudotDetailsEach: this.TeudotDetailsEach, DocTypePerClient: this.DocTypePerClient });

		const [{ TeudaRemark, TeudaRemark1, TeudaRemark2, TeudaRemark3, TeudaRemark4, TeudaRemark5 }] = this.TeudotDetailsEach;
		this.remarksArr = [TeudaRemark, TeudaRemark1, TeudaRemark2, TeudaRemark3, TeudaRemark4, TeudaRemark5];
		this.anyRemarksAtAll = this.remarksArr.filter(rmrk => rmrk).length > 0;
	}

	public BackToClientTeudot() {
		this.router.navigate(['daily-report']);
	}

	isRemarkPopupOpen = false;
	remarksArr = [];
	handleRemarksPopupChangeDisplay(val: boolean) {
		this.isRemarkPopupOpen = val;
	}

	createPdfInServer() {
		const returnWithCurrency = number => Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);

		const {
			TeudaSupplyDate,
			TeudaNum,
			TeudaTotalSum,
			TeudaType: DocType,
			TeudaVat,
			TeudaTotalSumBeforeVat,
			TeudaTotalSumVat,
			Client_Name,
			Client_Id,
			TeudaAgent,
			User_Name,
			TuedaErpDocNum,
			TeudaRemark,
			TeudaRemark1,
			TeudaRemark2,
			TeudaRemark3,
			TeudaRemark4,
			TeudaRemark5,
		} = this.TeudotDetailsEach[0];
		const body = {
			TeudaSupplyDate,
			TeudaNum,
			DocType,
			TeudaVat,
			Client_Name,
			Client_Id,
			TeudaAgent,
			User_Name,
			TuedaErpDocNum,
			TeudaRemark,
			TeudaRemark1,
			TeudaRemark2,
			TeudaRemark3,
			TeudaRemark4,
			TeudaRemark5,
			TeudaTotalSum: returnWithCurrency(TeudaTotalSum),
			TeudaTotalSumBeforeVat: returnWithCurrency(TeudaTotalSumBeforeVat),
			TeudaTotalSumVat: returnWithCurrency(TeudaTotalSumVat),
			itemsArray: this.TeudotDetailsEach.map(teuda => ({
				ProductId: teuda.ProductId,
				ProductName: teuda.ProductName,
				TeudaQuantity: teuda.TeudaQuantity,
				TeudaPackQuantity: teuda.TeudaPackQuantity,
				TeudaItemNetPrice: returnWithCurrency(teuda.TeudaItemNetPrice),
				TeudaItemDiscount: teuda.TeudaItemDiscount || 0,
				TeudaTotalRow: returnWithCurrency(teuda.TeudaTotalRow),
				TeudaItemRemark: teuda.TeudaItemRemark,
			})),
		};
		this._ss.createPDFInIndex(body).subscribe(
			(res: any) => {
				if (!res.err) {
					alert('קובץ PDF נוצר בהצלחה!');
				} else {
					alert('קרתה תקלה, אנא נסה שנית מאוחר יותר...');
				}
			},
			err => {
				alert('קרתה תקלה, אנא נסה שנית מאוחר יותר...');
			},
		);
	}

	openDuplicatePopup(bool: boolean) {
		this.PopDuplicate = bool;
	}

	public AskDuplicate() {
		this.PopDuplicate = true;
	}

	public NoDuplicate() {
		this.PopDuplicate = false;
	}

	public DuplicateOrder(Type) {
		this.AllOrder = [];

		const newDate = new Date();

		for (let x = 0; x < this._ss.TeudotDetailsEach.length; x++) {
			const original: Partial<Order> = this._ss.TeudotDetailsEach[x];
			const order: Partial<Order> = {
				Stock: original.Stock,
				ProductId: original.ProductId,
				Product_Name: original.Product_Name,
				Price: original.Price,
				PermPrice: original.PermPrice,
				ItemPack: original.ItemPack,
				TeudaQuantity: original.TeudaQuantity,
				TeudaPackQuantity: original.TeudaPackQuantity,
				TeudaBonusQuantity: original.TeudaBonusQuantity,
				YehidotPrice: original.YehidotPrice || 0,
				ArizotPrice: original.ArizotPrice || 0,
				NetPrice: original.NetPrice || original.Price,
				ItemBarcode: original.ItemBarcode,
				TeudaItemRemark: original.TeudaItemRemark,
				TeudaRemark: this.TeudotDetailsEach[x].TeudaRemark,
				ChangePrice: original.ChangePrice,
				ChangeDiscount: original.ChangeDiscount,
				ItemPicName: original.ItemPicName,
				BD: original.BD,
				BP: original.BP,
				BM: original.BM,
				BS: original.BS,
				MinPrice: original.MinPrice,
				qt: original.qt,
				PKD: original.PKD,
				CPP: original.CPP,
				Ptve: this._ss.VersionNumber,
				TeudaTotalRow: original.TeudaTotalRow,
				Duplicate: 'false',
				DuplicateOrder: 'true',
				IfChangePriceOrDisc: original.IfChangePriceOrDisc,
				PriceInInput: original.Price,
				TeudaNum: newDate.getTime() + '000' + this._ss.AgentIdConnected,
				TeudaCreateDate: newDate.toISOString(),
				basePrice: original.basePrice || original.ShowPrice || original.PermPrice,
			};

			if (this._ss.CustomerInformation.CopyRemark === '1') {
				order.TeudaRemark = original.TeudaRemark;
				order.TeudaRemark1 = original.TeudaRemark1;
				order.TeudaRemark2 = original.TeudaRemark2;
				order.TeudaRemark3 = original.TeudaRemark3;
				order.TeudaRemark4 = original.TeudaRemark4;
				order.TeudaRemark5 = '' + original.TeudaTotalSumBeforeVat;

				if (this._ss.CustomerInformation.CustNum == '61') {
					order.TeudaRemark5 = this._ss.roundNumberTo2Decimals(+order.TeudaRemark5 / 0.7);
				}
			}

			this.AllOrder.push(order);
		}

		localStorage.setItem('AllOrder' + this.TeudotDetailsEach[0].Client_Id + Type + this._ss.CompanyNumForDuplicate, JSON.stringify(this.AllOrder));

		this.ConfirmPop = true;
		this.PopDuplicate = false;
	}

	public Exit() {
		this.ConfirmPop = false;
	}

	public WatchOrderDuplicate() {
		this.router.navigate(['clients']);
	}

	getRemarkHeader(i: number) {
		if (i === 0) {
			return 'הערה ראשית';
		}
		return `הערה ${i}`;
	}
}
