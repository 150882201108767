import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { decompress } from 'lz-string';
import { BehaviorSubject, Subject } from 'rxjs';
import { NewReceiptBody, NewReceiptBodyWithClientName } from '../Interfaces/new-receipt-body';
import { BanksList } from '../Interfaces/SQL-reports-responses';
import { CreditCardService } from './credit-card.service';
import { StoreService } from './store.service';

export type Cheque = {
	bank: number;
	branch: number;
	accountNumber: number;
	chequeNumber: number;
	dueDate: Date;
	amount: number;
	remark: string;
};

@Injectable({
	providedIn: 'root',
})
export class ReceiptsService {
	receipts = [];

	isWritingNewReceipt = false;

	chequesArray: Cheque[] = [];

	receiptSent = false;

	sendingError = false;
	sendingErroredWithTimeoutAndCredit = false;

	creditFailureIdentifiers = {
		teudaNum: '',
		transactionID: '',
	};

	lastDocnumSent;

	lastTransactionID = '';
	lastAuthManpikNo = '';
	lastShovarNum = '';

	receiptsWaitingToBeSent: NewReceiptBodyWithClientName[] = [];

	//TODO move parameters to store
	// parameters = {
	// 	hasReceipts: false,
	// ...etc
	//}

	hasReceipts = false;
	adminCanDeleteAndEditReceipt = false;
	needsAtLeastOneHovCheckedToProduceReceipt = false;
	canProvideEmail = false;
	sendAllOpenReceiptsOnMorningDBUpdate = false;
	useBanksList = false;
	alertIfChequeIsFartherThanClosestDebt = false;
	blockIfChequeIsFartherThanClosestDebt = false;
	canPayWithCreditCard = false;
	totalDebtMustBePaid = false;

	emailForReceipt: null | string = null;

	readonly shouldOpenEmailPopup$ = new BehaviorSubject<boolean>(false);
	readonly emailOkToContinue$ = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient, private _ss: StoreService, public _cc: CreditCardService) {
		this.refreshReceiptsParameters();
	}

	refreshReceiptsParameters() {
		if (!this._ss.CustomerInformation?.CanProduceReceipt) return;

		const produceReceiptParameter = this._ss.CustomerInformation.CanProduceReceipt.split('_');

		this.hasReceipts = produceReceiptParameter.includes('1');
		if (!this.hasReceipts) return;
		this.adminCanDeleteAndEditReceipt = produceReceiptParameter.includes('2');
		this.needsAtLeastOneHovCheckedToProduceReceipt = produceReceiptParameter.includes('3');
		this.canProvideEmail = produceReceiptParameter.includes('4');
		this.sendAllOpenReceiptsOnMorningDBUpdate = produceReceiptParameter.includes('5');
		this.useBanksList = produceReceiptParameter.includes('6');
		this.alertIfChequeIsFartherThanClosestDebt = produceReceiptParameter.includes('7');
		this.canPayWithCreditCard = produceReceiptParameter.includes('8');
		this.totalDebtMustBePaid = produceReceiptParameter.includes('9');
		this.blockIfChequeIsFartherThanClosestDebt = produceReceiptParameter.includes('10');

		this.alertIfChequeIsFartherThanClosestDebt ||= this.blockIfChequeIsFartherThanClosestDebt;
	}

	getAllReceipts(options) {
		const params = {};

		if (!this._ss.CustomerInformation && localStorage.getItem('CustomerInformation')) {
			this._ss.CustomerInformation = JSON.parse(decompress(localStorage.getItem('CustomerInformation')));
		}
		if (!options.isAdmin) {
			params['AgentID'] = this._ss.AgentIdConnected;
		}

		return this.http.get(`${this._ss.CustomerInformation.CustServer}/mobil/get-all-receipts-trecipts/`, { params });
	}

	changeStatusOfReceipt(DocNum: string, TeudaStatus: number) {
		return this.http.get(`${this._ss.CustomerInformation.CustServer}/mobil/trecipts-status/${DocNum}/${TeudaStatus}`);
	}

	deleteReceipt(DocNum: string) {
		return this.http.get(`${this._ss.CustomerInformation.CustServer}/mobil/delete-from-trecipts/${DocNum}`);
	}

	getReceiptsFiltered(ClientId: string, AgentID?: string, company?: number) {
		ClientId = encodeURIComponent(ClientId);

		let queries = '';

		if (AgentID || company) {
			queries = '?';

			queries += AgentID ? `AgentID=${AgentID}` : ``;

			const cm = `company=${company}`;

			queries += company ? (AgentID ? `&${cm}` : cm) : '';
		}

		return this.http.get(`${this._ss.CustomerInformation.CustServer}/mobil/get-all-receipts/${ClientId}${queries}`);
	}

	postNewReceipt(body: NewReceiptBody) {
		return this.http.post(`${this._ss.CustomerInformation.CustServer}/mobil/post-new-receipt`, body);
	}

	handleEmailCheckAndEmitPopupOpenIfNeedBe(): boolean {
		const shouldStopAndOpenPopup = this.canProvideEmail && typeof this.emailForReceipt !== 'string';
		this.shouldOpenEmailPopup$.next(shouldStopAndOpenPopup);
		return !shouldStopAndOpenPopup;
	}

	getBanksList() {
		return this._ss.get<BanksList>(`mobil/get-bank-list`);
	}

	resetAllState() {
		this.lastDocnumSent = this.emailForReceipt = null;
		this.lastTransactionID = this.lastAuthManpikNo = this.lastShovarNum = '';
		this.receiptSent = this.sendingErroredWithTimeoutAndCredit = false;
		this.chequesArray = [];
		document.body.classList.remove('no-scroll');
		this.shouldOpenEmailPopup$.next(false);
		this.emailOkToContinue$.next(false);
	}

	handleFailedCreditCardPaymentAfterTimeout(payload: { TeudaNum: string; TransactionID: string }) {
		this.creditFailureIdentifiers.teudaNum = payload.TeudaNum;
		this.creditFailureIdentifiers.transactionID = payload.TransactionID;
		this.sendingErroredWithTimeoutAndCredit = true;
		this.chequesArray = [];
		this._cc.resetFormValues();
	}
}
