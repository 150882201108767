import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { Invoices, InvoicesDisplay, InvoicesDisplay2 } from 'src/app/Interfaces/invoices';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-daily-invoices',
	templateUrl: './daily-invoices.component.html',
	styleUrls: ['./daily-invoices.component.scss'],
})
export class DailyInvoicesComponent implements OnInit {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		private _date: DateAdapter<any, 'he-IL'>, //  private _acr:ActivatedRoute,
	) {}

	public AdminSeeAgents;
	public AgentPerDate;
	public AgentPerDateOne;
	public AdminSeeAgentsMain;
	public form = this._fb.group({
		from: [Intl.DateTimeFormat('en-IL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).split('/').reverse().join('-')],
		to: [Intl.DateTimeFormat('en-IL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).split('/').reverse().join('-')],
	});
	public TodayDate = new Date();
	public Total = 0;

	Total$: Subscription;

	invoices: Invoices[] = [];

	invoicesMapped: { [DocDocNum: string]: Invoices[] } = {};

	invoicesDisplay: InvoicesDisplay[] = [];
	invoicesDisplay$ = new BehaviorSubject<InvoicesDisplay[]>(this.invoicesDisplay);

	secondPage: InvoicesDisplay2[] = [];
	secondPage$ = new BehaviorSubject<InvoicesDisplay2[]>(this.secondPage);

	thirdPage: Invoices[] = [];
	thirdPage$ = new BehaviorSubject<Invoices[]>(this.thirdPage);

	shouldShowCompanies = 0;

	agentsToGet = [];

	dates = {
		max: new Date().toISOString().slice(0, 10),
		min: (() => {
			const date = new Date();
			date.setDate(-60);
			return date.toISOString().slice(0, 10);
		})(),
	};

	datesFilter = [new Date().toISOString().slice(0, 10)];

	dateFilterFunction = (date: Date): boolean => {
		const dates = this.datesFilter;

		return date ? dates.includes(date.toISOString().slice(0, 10)) : false;
	};

	destroy$ = new Subject<void>();

	goToDateOfAgent: { agent?: string; date?: string } = {};

	isAdmin = location.pathname.includes('admin');

	agents = [];

	loading = true;

	customDate = false;

	//==============================================================================================================================================>

	ngOnInit(): void {
		this._date.setLocale('he-IL');

		//  this._acr.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
		// 		for (const key in params) {
		// 			if (Object.prototype.hasOwnProperty.call(params, key)) {
		// 				this.goToDateOfAgent[key] = params[key];
		// 			}
		// 		}

		// 		this.form.controls.Date.setValue(this.goToDateOfAgent.date);

		// 		// console.log(this.goToDateOfAgent);
		// 	})

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			if (this._ss.CustomerInformation.Submanagers == '1') {
				if (!this._ss.agentsOfSubmanager.length) {
					this._ss.getAdminAgents().subscribe({
						next: res => {
							if (typeof res == 'string') {
								alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
								return;
							}

							this.agentsToGet = res.recordset.map(obj => obj.Agent);
							this.agentsToGet.push(this._ss.AgentIdConnected);

							this.getInvoices();
						},
						error: err => {
							alert('שגיאת שרת');
						},
					});
				} else {
					this.agentsToGet = this._ss.agentsOfSubmanager;
					this.getInvoices();
				}
			} else {
				this.getInvoices();
			}
		} else {
			this.router.navigate(['login']);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	//==============================================================================================================================================>

	public ResetDate() {
		this.loading = true;
		const date = new Date().toISOString().slice(0, 10);

		const next: InvoicesDisplay[] = Object.values(
			this.invoicesDisplay
				.filter(invoice => invoice.date.toString().slice(0, 10) === date)
				.reduce((acc, invoice) => {
					if (!acc[invoice.agent]) {
						acc[invoice.agent] = {};
						acc[invoice.agent].sum = 0 + invoice.sum;
						acc[invoice.agent].date = invoice.date.toString().slice(0, 10).split('-').reverse().join('/');
						acc[invoice.agent].agent = invoice.agent;
						acc[invoice.agent].agentname = invoice.agentname;
					} else {
						acc[invoice.agent].sum += invoice.sum;
					}
					return acc;
				}, {}),
		);

		this.Total = next.reduce((a, b) => a + b.sum, 0);

		this.invoicesDisplay$.next(next);

		this.form.controls.from.setValue(this.dates.max);
		this.form.controls.to.setValue(this.dates.max);

		this.loading = false;
		this.customDate = false;
	}

	public First = true;
	public Second = false;
	public Third = false;
	public Fourth = false;

	public BackToAdmin() {
		if (this.First === true) {
			// this.router.navigate(['admin'])
			history.back();
		}

		if (this.Second === true) {
			this.First = true;
			this.Second = false;

			this.Total$ = this.invoicesDisplay$.pipe(takeUntil(this.destroy$)).subscribe(res => {
				this.Total = res.reduce((a, b) => a + b.sum, 0);
				if (this.Total$) this.Total$.unsubscribe();
			});
		}

		if (this.Third === true) {
			this.Second = true;
			this.Third = false;

			this.Total$ = this.secondPage$.pipe(takeUntil(this.destroy$)).subscribe(res => {
				this.Total = res.reduce((a, b) => a + b.sum, 0);
				if (this.Total$) this.Total$.unsubscribe();
			});
		}

		if (this.Fourth === true) {
			this.Third = true;
			this.Fourth = false;
		}
	}

	getInvoices() {
		this._ss.getDailyInvoices(this.agentsToGet).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת תקשורת. נסו שנית מאוחר יותר');
					history.back();
					return;
				}

				this.invoices = res.recordset;

				const agentsSet: Set<string> = new Set();
				const datesSet: Set<string> = new Set();

				this.invoices.reduce((acc, invoice) => {
					agentsSet.add(invoice.AgentID);

					const fixedDate = new Date(invoice.DocCreateDate);
					// fixedDate.setHours(-2);

					const cutDate = fixedDate.toISOString().slice(0, 10);

					datesSet.add(fixedDate.toISOString().slice(0, 10));

					if (agentsSet.size > this.agents.length) {
						this.agents.push({ agentid: invoice.AgentID, agentname: invoice.AgentName });
					}

					const key = invoice.DocDocNum + '-' + invoice.DocDocType + '-' + invoice.AgentID;
					acc[key] ||= [];
					acc[key].push(invoice);
					return acc;
				}, this.invoicesMapped);

				this.datesFilter = [...datesSet];

				console.log(this.agents);

				this.invoicesDisplay = Object.entries(this.invoicesMapped).map(([key, entry]) => ({
					ID: key,
					Invoice: entry,
					date: entry[0].DocCreateDate,
					agent: entry[0].AgentID,
					agentname: entry[0].AgentName,
					sum: entry[0].DocTotalSum - entry[0].DocTotalSumVat,
				}));

				console.log(this.invoicesDisplay);

				const date = new Date().toISOString().slice(0, 10);

				const next: InvoicesDisplay[] = Object.values(
					this.invoicesDisplay
						.filter(invoice => invoice.date.toString().slice(0, 10) === date)
						.reduce((acc, invoice) => {
							if (!acc[invoice.agent]) {
								acc[invoice.agent] = {};
								acc[invoice.agent].sum = 0 + invoice.sum;
								acc[invoice.agent].date = invoice.date.toString().slice(0, 10).split('-').reverse().join('/');
								acc[invoice.agent].agent = invoice.agent;
								acc[invoice.agent].agentname = invoice.agentname;
							} else {
								acc[invoice.agent].sum += invoice.sum;
							}
							return acc;
						}, {}),
				);

				this.Total = next.reduce((a, b) => a + b.sum, 0);

				console.log({ next });

				this.invoicesDisplay$.next(next);

				this.loading = false;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, נסו שנית מאוחר יותר');
				console.log(err);
				history.back();
			},
		});
	}

	showInvoices(agent: string) {
		const date = new Date().toISOString().slice(0, 10);

		this.Total = 0;

		console.log({ agent });

		const twoHours = 1000 * 60 * 60 * 2;

		this.secondPage = this.invoicesDisplay
			.filter(
				invoice =>
					invoice.agent === agent &&
					(this.customDate
						? new Date(invoice.date).getTime() >= new Date(this.form.controls.from.value).getTime() - twoHours &&
						  new Date(invoice.date).getTime() <= new Date(this.form.controls.to.value).getTime() + twoHours
						: invoice.date.toString().slice(0, 10) === date),
			)
			.map(invoice => ({
				...invoice,
				clientID: invoice.Invoice[0].ClientId,
				clientName: invoice.Invoice[0].Client_Name,
				invoiceID: invoice.Invoice[0].DocDocNum,
				invoiceType: invoice.Invoice[0].DocDocType,
				invoiceTypeName: invoice.Invoice[0].DocName,
				totalSum: invoice.Invoice.reduce((a, b) => a + b.DocTotalRow, 0),
			}));

		this.Total = this.secondPage.reduce((a, b) => a + b.totalSum, 0);

		this.First = false;
		this.Second = true;

		console.log(this.secondPage);

		this.secondPage$.next(this.secondPage);
	}

	showOneInvoice(invoiceID: string) {
		const arr = this.secondPage.find(invoice => invoice.invoiceID === invoiceID);

		if (!arr) {
			alert('ישנה תקלה, לא ניתן להציג פרטים');
			return;
		}

		this.thirdPage = arr.Invoice;

		this.Total = arr.totalSum;

		this.Second = false;
		this.Third = true;

		console.log(this.thirdPage);

		this.thirdPage$.next(this.thirdPage);
	}

	changeDate() {
		if (!this.form.controls.to.value) return;

		this.customDate = new Date(this.form.controls.from.value).getTime() !== new Date(this.form.controls.to.value).getTime();

		this.loading = true;
		// const date = new Date().toISOString().slice(0, 10);

		const twoHours = 1000 * 60 * 60 * 2;

		const next: InvoicesDisplay[] = Object.values(
			this.invoicesDisplay
				.filter(
					invoice =>
						new Date(invoice.date).getTime() >= new Date(this.form.controls.from.value).getTime() - twoHours &&
						new Date(invoice.date).getTime() <= new Date(this.form.controls.to.value).getTime() + twoHours,
				)
				.reduce((acc, invoice) => {
					if (!acc[invoice.agent]) {
						acc[invoice.agent] = {};
						acc[invoice.agent].sum = 0 + invoice.sum;
						acc[invoice.agent].date = invoice.date.toString().slice(0, 10).split('-').reverse().join('/');
						acc[invoice.agent].agent = invoice.agent;
						acc[invoice.agent].agentname = invoice.agentname;
					} else {
						acc[invoice.agent].sum += invoice.sum;
					}
					return acc;
				}, {}),
		);

		this.Total = next.reduce((a, b) => a + b.sum, 0);

		this.invoicesDisplay$.next(next);

		this.loading = false;

		console.log({ next, date: [new Date(this.form.controls.from.value).toISOString(), new Date(this.form.controls.to.value).toISOString()] });
	}
}
