import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { DateFilterFn } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, delay, map, merge, startWith, Subject, takeUntil, tap } from 'rxjs';
import {
	TruckDistributionAgentSpread,
	TruckDistributionMainSQL,
	TruckDistributionPath,
	TruckDistributionAssignmentUpdateLineNum,
	TruckDistributionAssignmentUpdateDate,
	TruckDistributionTaskDisplayWithAssignment,
	TruckDistributionAssignmentUpdateAgent,
	TruckDistributionUsers,
} from 'src/app/Interfaces/truck-distribution';
import {
	TruckLoadingAssignmentUpdateAgent,
	TruckLoadingAssignmentUpdateDate,
	TruckLoadingAssignmentUpdateLineNum,
	TruckLoadingMainSQL,
	TruckLoadingPath,
	TruckLoadingTaskDisplayWithAssignment,
	TruckLoadingUsers,
} from 'src/app/Interfaces/truck-loading';
import { AlertService } from 'src/app/services/alert.service';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { StoreService } from 'src/app/services/store.service';
import { TextService } from 'src/app/services/text.service';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { TruckLoadingService } from 'src/app/services/truck-loading.service';
import { Time, UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { COLOR_FOR_GROUP_B, getTaskColor, getTaskIdentifier, TEUDA_GROUP_B } from '../likut-clients/methods';
import { KanbanAssignmentPopupComponent } from './kanban-assignment-popup/kanban-assignment-popup.component';
import ScrollBooster from './scrollbooster';
import { translateDateForSQLServer, translateDateForWebISO } from 'src/app/Utility/functions';
import { LikutAssignmentUpdateAgent, LikutAssignmentUpdateLineNum, LikutKanbanUsers, LikutMainSQL, LikutTaskDisplayWithAssignment, LikutUsers } from 'src/app/Interfaces/likut';
import { LikutService } from 'src/app/services/likut.service';
import { SubAppModes } from '../likut-clients/likut-clients.component';
import { Users } from 'src/app/Interfaces/users';

type AgentWithTasks<T> = { name: string; agentID: string; tasks: T[] };

type TruckMode = TruckLoadingPath | TruckDistributionPath;

type clientIDWithStatusAndAddressAndCity = string;
type DocNum = string;
type TeudaNum = string;

type clientId = string;

@Component({
	selector: 'app-kanban-assignment',
	templateUrl: './kanban-assignment.component.html',
	styleUrls: ['./kanban-assignment.component.scss'],
})
export class KanbanAssignmentComponent implements OnInit, OnDestroy, AfterViewInit {
	constructor(
		public _ss: StoreService,
		private router: Router,
		private _fb: FormBuilder,
		public _text: TextService,
		private _date: DateAdapter<any, 'he-IL'>,
		public _util: UtilService,
		private _snackbar: MatSnackBar,
		private _alerts: AlertService,
		private _dialog: MatDialog,
		public _truckDistribution: TruckDistributionService,
		public _truckLoading: TruckLoadingService,
		public _likut: LikutService,
	) {}

	windowWidth = innerWidth;

	today = new Date();

	allTruckDistributionTeudot: TruckDistributionMainSQL[] = [];
	allTruckDistributionTeudotObject$: BehaviorSubject<Record<clientIDWithStatusAndAddressAndCity, Record<DocNum, TruckDistributionMainSQL>>> = new BehaviorSubject({});
	allTruckDistributionTeudotDisplay$: BehaviorSubject<TruckDistributionTaskDisplayWithAssignment[]> = new BehaviorSubject([]);

	allTruckLoadingTeudot: TruckLoadingMainSQL[] = [];
	allTruckLoadingTeudotObject$: BehaviorSubject<Record<clientIDWithStatusAndAddressAndCity, Record<DocNum, TruckLoadingMainSQL>>> = new BehaviorSubject({});
	allTruckLoadingTeudotDisplay$: BehaviorSubject<TruckLoadingTaskDisplayWithAssignment[]> = new BehaviorSubject([]);

	allLikutTeudot: LikutMainSQL[] = [];
	allLikutTeudotObject$: BehaviorSubject<Record<clientId, Record<TeudaNum, LikutMainSQL>>> = new BehaviorSubject({});
	allLikutTeudotDisplay$: BehaviorSubject<LikutTaskDisplayWithAssignment[]> = new BehaviorSubject([]);

	destroy$ = new Subject<void>();

	// TODO write a generic function to handle these 3 similar functions
	agentsDistribution: AgentWithTasks<TruckDistributionTaskDisplayWithAssignment>[] = this._truckDistribution.usersForAssignment$.value.map(driver => ({
		name: driver.UserName,
		agentID: driver.AgentID,
		tasks: [],
	}));

	agentsLoading: AgentWithTasks<TruckLoadingTaskDisplayWithAssignment>[] = this._truckLoading.usersForAssignment$.value.map(driver => ({
		name: driver.UserName,
		agentID: driver.AgentID,
		tasks: [],
	}));

	agentsLikut: AgentWithTasks<LikutTaskDisplayWithAssignment>[] = this._likut.usersForAssignment$.value.map(melaket => ({
		name: melaket.UserName,
		agentID: melaket.AgentID,
		tasks: [],
	}));

	form: FormGroup<{
		date: FormControl<Date>;
		agentsArray: FormControl<string[]>;
		agent?: FormControl<boolean>;
	}> = this._fb.nonNullable.group({
		date: [new Date()],
		agentsArray: [<string[]>[]],
	});

	notAssignedDistribution$ = combineLatest([this.allTruckDistributionTeudotDisplay$, this.form.controls.date.valueChanges.pipe(startWith(new Date()))]).pipe(
		map(([tasks, selectedDate]) => tasks.filter(task => task.AssignTo === null && Intl.DateTimeFormat('he-IL').format(new Date(task.PODDate)) === Intl.DateTimeFormat('he-IL').format(selectedDate))),
	);

	agentsDistribution$ = combineLatest([this.allTruckDistributionTeudotDisplay$, this.form.controls.date.valueChanges.pipe(startWith(new Date()))]).pipe(
		map(([tasks, selectedDate]) => {
			tasks.sort((a, b) => a.LineNum - b.LineNum);
			return this.agentsDistribution.map(agent => {
				return {
					...agent,
					tasks: tasks.filter(task => task.AssignTo === agent.agentID && Intl.DateTimeFormat('he-IL').format(new Date(task.PODDate)) === Intl.DateTimeFormat('he-IL').format(selectedDate)),
				};
			});
		}),
	);

	notAssignedLoading$ = combineLatest([this.allTruckLoadingTeudotDisplay$, this.form.controls.date.valueChanges.pipe(startWith(new Date()))]).pipe(
		map(([tasks, selectedDate]) =>
			tasks.filter(task => task.AssignTo === null && Intl.DateTimeFormat('he-IL').format(new Date(task.LoadDate)) === Intl.DateTimeFormat('he-IL').format(selectedDate)),
		),
	);

	agentsLoading$ = combineLatest([this.allTruckLoadingTeudotDisplay$, this.form.controls.date.valueChanges.pipe(startWith(new Date()))]).pipe(
		map(([tasks, selectedDate]) => {
			tasks.sort((a, b) => a.LineNum - b.LineNum);
			return this.agentsLoading.map(agent => {
				return {
					...agent,
					tasks: tasks.filter(task => task.AssignTo === agent.agentID && Intl.DateTimeFormat('he-IL').format(new Date(task.LoadDate)) === Intl.DateTimeFormat('he-IL').format(selectedDate)),
				};
			});
		}),
	);

	notAssignedLikut$ = this.allLikutTeudotDisplay$.pipe(map(tasks => tasks.filter(task => task.AssignedTo === null)));

	agentsLikut$ = this.allLikutTeudotDisplay$.pipe(
		map(tasks => {
			tasks.sort((a, b) => a.LineNum - b.LineNum);
			return this.agentsLikut.map(agent => ({
				...agent,
				tasks: tasks.filter(task => task.AssignedTo === agent.agentID),
			}));
		}),
	);

	agentsCheckboxContainerMode$ = new BehaviorSubject<TruckDistributionAgentSpread>(this._truckDistribution.reportAgentDisplay);

	pageMode$ = new BehaviorSubject<SubAppModes>('truck-distribution');
	reportTitle$ = this.pageMode$.pipe(
		map(page => {
			switch (page) {
				case 'truck-distribution':
					return this._text.store.taskAssignmentReport2;
				case 'truck-loading':
					return this._text.store.taskAssignmentReport3;
				case 'likut':
					return this._text.store.taskAssignmentReportLikut;

				default:
					return '';
			}
		}),
	);

	ngOnInit(): void {
		this._date.setLocale('he-IL');
		this.resolvePageMode();

		this.prepareData();

		this.handleUpdateUsers();

		this.fillAgentsArrayFormControl();
	}

	ngAfterViewInit(): void {
		this.setupScrollDrag();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	scrollBooster: typeof ScrollBooster.prototype;
	@ViewChild('scrollable') scrollableContainer: ElementRef<HTMLDivElement>;

	setupScrollDrag() {
		this.scrollBooster = new ScrollBooster({
			viewport: document.querySelector('.scroll-me'),
			content: this.scrollableContainer.nativeElement,
			scrollMode: 'transform',
			direction: 'horizontal',
			emulateScroll: true,
			padEnd: 25,
			shouldScroll: (state, event) => {
				const isDrag = event.target instanceof HTMLElement && event.target.getAttribute('data-draggable');
				return !isDrag;
			},
		});
	}

	recalculateScrollEdges() {
		if (!this.scrollBooster) return;

		this.scrollBooster.updateMetrics();
	}

	navigateBack() {
		history.back();
	}

	async dropDistribution(event: CdkDragDrop<TruckDistributionTaskDisplayWithAssignment[]>, agentID: string | null) {
		if (event.previousContainer === event.container && event.currentIndex === event.previousIndex) return;
		try {
			let body: TruckDistributionAssignmentUpdateLineNum[] = [];
			if (event.previousContainer === event.container) {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			} else {
				const result = await this.changeTaskAgent({ DocNum: Object.keys(event.previousContainer.data[event.previousIndex].teudot), AssignTo: agentID });
				if (!result) throw new Error('fetch failed');
				transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
				body = this.reorderTasksAccordingToContainerAndPrepareBody(event.previousContainer);
			}
			body = body.concat(this.reorderTasksAccordingToContainerAndPrepareBody(event.container));

			const result = await this.changeTasksLineNum(body);

			if (!result) {
				throw new Error('couldnt patch');
			}

			this.updateAllTasks();

			this.taskUpdateSuccessfullyAlert();
		} catch (error) {
			console.log(error);
			alert('תקלת תקשורת');
		}
	}
	async dropLoading(event: CdkDragDrop<TruckLoadingTaskDisplayWithAssignment[]>, agentID: string | null) {
		if (event.previousContainer === event.container && event.currentIndex === event.previousIndex) return;
		try {
			let body: TruckLoadingAssignmentUpdateLineNum[] = [];
			if (event.previousContainer === event.container) {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			} else {
				const result = await this.changeTaskAgent({ DocNum: Object.keys(event.previousContainer.data[event.previousIndex].teudot), AssignTo: agentID });
				if (!result) throw new Error('fetch failed');
				transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
				body = this.reorderTasksAccordingToContainerAndPrepareBody(event.previousContainer);
			}
			body = body.concat(this.reorderTasksAccordingToContainerAndPrepareBody(event.container));

			const result = await this.changeTasksLineNum(body);

			if (!result) {
				throw new Error('couldnt patch');
			}

			this.updateAllTasks();

			this.taskUpdateSuccessfullyAlert();
		} catch (error) {
			console.log(error);
			alert('תקלת תקשורת');
		}
	}
	async dropLikut(event: CdkDragDrop<LikutTaskDisplayWithAssignment[]>, agentID: string | null) {
		if (event.previousContainer === event.container && event.currentIndex === event.previousIndex) return;
		try {
			let body: LikutAssignmentUpdateLineNum[] = [];
			if (event.previousContainer === event.container) {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			} else {
				const result = await this.changeTaskAgentLikut({ TeudaNum: Object.keys(event.previousContainer.data[event.previousIndex].teudot), AssignTo: agentID });
				if (!result) throw new Error('fetch failed');
				transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
				body = this.reorderTasksAccordingToContainerAndPrepareBodyLikut(event.previousContainer);
			}
			body = body.concat(this.reorderTasksAccordingToContainerAndPrepareBodyLikut(event.container));

			const result = await this.changeTasksLineNumLikut(body);

			if (!result) {
				throw new Error('couldnt patch');
			}

			this.updateAllTasks();

			this.taskUpdateSuccessfullyAlert();
		} catch (error) {
			console.log(error);
			alert('תקלת תקשורת');
		}
	}

	async changeTaskAgent(body: TruckDistributionAssignmentUpdateAgent | TruckLoadingAssignmentUpdateAgent) {
		const { AssignTo, DocNum } = body;

		return await new Promise<boolean>((resolve, reject) => {
			const callbacks = updateTaskCallbacks(resolve, reject);
			if (this.pageMode$.value === 'truck-distribution') {
				this._truckDistribution.assignKanbanTaskToAgent({ DocNum, AssignTo }).subscribe(callbacks);
			}
			if (this.pageMode$.value === 'truck-loading') {
				this._truckLoading.assignKanbanTaskToAgent({ DocNum, AssignTo }).subscribe(callbacks);
			}
		});
	}
	async changeTaskAgentLikut(body: LikutAssignmentUpdateAgent) {
		const { AssignTo, TeudaNum } = body;

		return await new Promise<boolean>((resolve, reject) => {
			const callbacks = updateTaskCallbacks(resolve, reject);

			this._likut.assignKanbanTaskToAgent({ TeudaNum, AssignTo }).subscribe(callbacks);
		});
	}

	async changeTasksLineNum(body: (TruckDistributionAssignmentUpdateLineNum | TruckLoadingAssignmentUpdateLineNum)[]) {
		return await new Promise<boolean>((resolve, reject) => {
			const callbacks = updateTaskCallbacks(resolve, reject);
			if (this.pageMode$.value === 'truck-distribution') {
				this._truckDistribution.assignKanbanTaskLineNum(body).subscribe(callbacks);
			}
			if (this.pageMode$.value === 'truck-loading') {
				this._truckLoading.assignKanbanTaskLineNum(body).subscribe(callbacks);
			}
		});
	}
	async changeTasksLineNumLikut(body: LikutAssignmentUpdateLineNum[]) {
		return await new Promise<boolean>((resolve, reject) => {
			const callbacks = updateTaskCallbacks(resolve, reject);

			this._likut.assignKanbanTaskLineNum(body).subscribe(callbacks);
		});
	}

	async handlePopupDateChange(data: { task: TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment; newDate: string }) {
		const body: (TruckDistributionAssignmentUpdateDate | TruckLoadingAssignmentUpdateDate)[] = [];
		switch (this.pageMode$.value) {
			case 'truck-distribution':
				for (const entry of Object.entries(data.task.teudot)) {
					body.push({ DocNum: entry[0], PODDate: translateDateForSQLServer(data.newDate).toISOString() });
				}
				break;
			case 'truck-loading':
				for (const entry of Object.entries(data.task.teudot)) {
					body.push({ DocNum: entry[0], LoadDate: translateDateForSQLServer(data.newDate).toISOString() });
				}
				break;

			default:
				break;
		}

		try {
			const result = await this.changeTaskDate(body);

			if (!result) {
				throw new Error('couldnt update date');
			}

			this.updateAllTasks();
			this.taskUpdateSuccessfullyAlert();
		} catch (error) {
			console.log(error);
			alert('תקלת תקשורת');
		}
	}

	async handlePopupAgentChange(data: { task: TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment; newAgent: string | null }) {
		const body: TruckDistributionAssignmentUpdateAgent | TruckLoadingAssignmentUpdateAgent = {
			AssignTo: data.newAgent,
			DocNum: Object.keys(data.task.teudot),
		};

		try {
			const result = await this.changeTaskAgent(body);

			if (!result) {
				throw new Error('couldnt update date');
			}

			this.updateAllTasks();
			this.taskUpdateSuccessfullyAlert();
		} catch (error) {
			console.log(error);
			alert('תקלת תקשורת');
		}
	}

	async handlePopupAgentChangeLikut(data: { task: LikutTaskDisplayWithAssignment; newAgent: string | null }) {
		const body: LikutAssignmentUpdateAgent = {
			AssignTo: data.newAgent,
			TeudaNum: Object.keys(data.task.teudot),
		};

		try {
			const result = await this.changeTaskAgentLikut(body);

			if (!result) {
				throw new Error('couldnt update date');
			}

			this.updateAllTasks();
			this.taskUpdateSuccessfullyAlert();
		} catch (error) {
			console.log(error);
			alert('תקלת תקשורת');
		}
	}

	async changeTaskDate(body: (TruckDistributionAssignmentUpdateDate | TruckLoadingAssignmentUpdateDate)[]) {
		return await new Promise<boolean>((resolve, reject) => {
			const callbacks = updateTaskCallbacks(resolve, reject);
			const truckDistributionBody: TruckDistributionAssignmentUpdateDate[] = body.filter((line): line is TruckDistributionAssignmentUpdateDate => 'PODDate' in line);
			const truckLoadingBody: TruckLoadingAssignmentUpdateDate[] = body.filter((line): line is TruckLoadingAssignmentUpdateDate => 'LoadDate' in line);
			if (this.pageMode$.value === 'truck-distribution') {
				this._truckDistribution.assignKanbanTaskDate(truckDistributionBody).subscribe(callbacks);
			}
			if (this.pageMode$.value === 'truck-loading') {
				this._truckLoading.assignKanbanTaskDate(truckLoadingBody).subscribe(callbacks);
			}
		});
	}

	taskUpdateSuccessfullyAlert() {
		this._snackbar.open('משימות עודכנו', 'הבנתי', { direction: 'rtl', duration: 3000 });
	}

	reorderTasksAccordingToContainerAndPrepareBody(
		container: CdkDropList<(TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment)[]>,
	): (TruckDistributionAssignmentUpdateLineNum | TruckLoadingAssignmentUpdateLineNum)[] {
		const body: TruckDistributionAssignmentUpdateLineNum[] = [];
		for (const i in container.data) {
			const task = container.data[i];
			task.LineNum = +i + 1;
			const teudot = Object.entries(task.teudot);

			for (const teuda of teudot) {
				body.push({ LineNum: task.LineNum, DocNum: teuda[0] });
			}
		}

		return body;
	}
	reorderTasksAccordingToContainerAndPrepareBodyLikut(container: CdkDropList<LikutTaskDisplayWithAssignment[]>): LikutAssignmentUpdateLineNum[] {
		const body: LikutAssignmentUpdateLineNum[] = [];
		for (const i in container.data) {
			const task = container.data[i];
			task.LineNum = +i + 1;
			const teudot = Object.entries(task.teudot);

			for (const teuda of teudot) {
				body.push({ LineNum: task.LineNum, TeudaNum: teuda[0] });
			}
		}

		return body;
	}

	toggleOpenTaskPopup(task: TruckDistributionTaskDisplayWithAssignment | TruckLoadingTaskDisplayWithAssignment | LikutTaskDisplayWithAssignment) {
		this._dialog.open(KanbanAssignmentPopupComponent, { data: task });
	}

	prepareData() {
		if (this.pageMode$.value === 'truck-loading') {
			this.form = this._fb.nonNullable.group(
				this.agentsLoading.reduce(
					(prev, curr) => {
						if (curr.agentID) {
							prev[curr.agentID] = new FormControl<boolean>(true);
						}
						return prev;
					},
					{
						date: [new Date()],
						agentsArray: [<string[]>[]],
					},
				),
			);
			this.notAssignedLoading$ = combineLatest([this.allTruckLoadingTeudotDisplay$, this.form.controls.date.valueChanges.pipe(startWith(new Date()))]).pipe(
				map(([tasks, selectedDate]) =>
					tasks.filter(task => task.AssignTo === null && Intl.DateTimeFormat('he-IL').format(new Date(task.LoadDate)) === Intl.DateTimeFormat('he-IL').format(selectedDate)),
				),
			);

			this.agentsLoading$ = combineLatest([this.allTruckLoadingTeudotDisplay$, this.form.controls.date.valueChanges.pipe(startWith(new Date()))]).pipe(
				map(([tasks, selectedDate]) => {
					tasks.sort((a, b) => a.LineNum - b.LineNum);
					return this.agentsLoading.map(agent => {
						return {
							...agent,
							tasks: tasks.filter(task => task.AssignTo === agent.agentID && Intl.DateTimeFormat('he-IL').format(new Date(task.LoadDate)) === Intl.DateTimeFormat('he-IL').format(selectedDate)),
						};
					});
				}),
			);
		}
		if (this.pageMode$.value === 'likut') {
			this.form = this._fb.nonNullable.group(
				this.agentsLikut.reduce(
					(prev, curr) => {
						if (curr.agentID) {
							prev[curr.agentID] = new FormControl<boolean>(true);
						}
						return prev;
					},
					{
						date: [new Date()],
						agentsArray: [<string[]>[]],
					},
				),
			);
			this.notAssignedLikut$ = this.allLikutTeudotDisplay$.pipe(map(tasks => tasks.filter(task => task.AssignedTo === null)));

			this.agentsLikut$ = this.allLikutTeudotDisplay$.pipe(
				map(tasks => {
					tasks.sort((a, b) => a.LineNum - b.LineNum);
					return this.agentsLikut.map(agent => {
						return {
							...agent,
							tasks: tasks.filter(task => task.AssignedTo === agent.agentID),
						};
					});
				}),
			);
		}

		const formValueChanges$ = this.form.valueChanges.pipe(startWith({})),
			distributionUsers$ = this._truckDistribution.usersForAssignment$.pipe(startWith([] as TruckDistributionUsers[])),
			loadingUsers$ = this._truckLoading.usersForAssignment$.pipe(startWith([] as TruckLoadingUsers[])),
			likutUsers$ = this._likut.usersForAssignment$.pipe(startWith([] as LikutKanbanUsers[]));

		combineLatest([formValueChanges$, distributionUsers$, loadingUsers$, likutUsers$])
			.pipe(delay(0), takeUntil(this.destroy$))
			.subscribe(() => {
				this.recalculateScrollEdges();
			});

		merge(distributionUsers$, loadingUsers$, likutUsers$)
			.pipe(takeUntil(this.destroy$))
			.subscribe((agents: (TruckDistributionUsers | TruckLoadingUsers | LikutUsers)[]) => {
				if (this.pageMode$.value === 'truck-distribution') {
					this.agentsDistribution = (agents as TruckDistributionUsers[]).map(driver => ({ name: driver.UserName, agentID: driver.AgentID, tasks: [] }));
				}
				if (this.pageMode$.value === 'truck-loading') {
					this.agentsLoading = (agents as TruckLoadingUsers[]).map(driver => ({ name: driver.UserName, agentID: driver.AgentID, tasks: [] }));
				}
				if (this.pageMode$.value === 'likut') {
					const agentsLikut = agents as LikutKanbanUsers[];
					this.agentsLikut = agentsLikut.map(driver => ({ name: driver.UserName, agentID: driver.AgentID, tasks: [] }));
					for (const agent of agentsLikut) {
						if (agent.AgentID && !this.form.controls[agent.AgentID]) {
							//@ts-ignore
							this.form.addControl(agent.AgentID, new FormControl(true));
						}
					}
				}
				if (['truck-distribution', 'truck-loading'].includes(this.pageMode$.value)) {
					for (const agent of agents as TruckLoadingUsers[]) {
						if (agent.AgentID && !this.form.controls[agent.AgentID]) {
							//@ts-ignore
							this.form.addControl(agent.AgentID, new FormControl(true));
						}
					}
				}

				this.fillAgentsArrayFormControl();

				switch (this.pageMode$.value) {
					case 'truck-distribution':
						this.handleRefreshTruckDistributionSubjects();
						break;
					case 'truck-loading':
						this.handleRefreshTruckLoadingSubjects();
						break;
					case 'likut':
						this.handleRefreshLikutSubjects();
						break;

					default:
						break;
				}
			});

		this.updateAllTasks();
		this.setupPopupChangesSubscribers();
	}

	setupPopupChangesSubscribers() {
		merge(this._truckDistribution.taskAssigmentPopupDateChange, this._truckLoading.taskAssigmentPopupDateChange)
			.pipe(takeUntil(this.destroy$))
			.subscribe(async newValue => {
				try {
					await this.handlePopupDateChange(newValue);
				} catch (error) {
					console.error(error);
					alert('ישנה תקלת תקשורת, נסה שנית מאוחר יותר');
				}
			});

		merge(this._truckDistribution.taskAssigmentPopupAgentChange, this._truckLoading.taskAssigmentPopupAgentChange)
			.pipe(takeUntil(this.destroy$))
			.subscribe(async newValue => {
				try {
					await this.handlePopupAgentChange(newValue);
				} catch (error) {
					alert('ישנה תקלת תקשורת, נסה שנית מאוחר יותר');
					console.error(error);
				}
			});
		merge(this._likut.taskAssigmentPopupAgentChange, this._likut.taskAssigmentPopupAgentChange)
			.pipe(takeUntil(this.destroy$))
			.subscribe(async newValue => {
				try {
					await this.handlePopupAgentChangeLikut(newValue);
				} catch (error) {
					alert('ישנה תקלת תקשורת, נסה שנית מאוחר יותר');
					console.error(error);
				}
			});
	}

	updateAllTasks() {
		if (this.pageMode$.value === 'truck-distribution') {
			this._truckDistribution
				.getAllTruckDistributionTasksForMain('assignment')
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return;

						this.allTruckDistributionTeudot = res.recordset;

						this.buildData();
					},
					error: err => {
						console.error(err);
					},
				});
		}
		if (this.pageMode$.value === 'truck-loading') {
			this._truckLoading
				.getAllTruckLoadingTasksForMain('assignment')
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return;

						this.allTruckLoadingTeudot = res.recordset;

						this.buildData();
					},
					error: err => {
						console.error(err);
					},
				});
		}

		if (this.pageMode$.value === 'likut') {
			this._likut
				.getAllKanbanTasks()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return;

						this.allLikutTeudot = res.recordset;

						this.buildData();
					},
					error: err => {
						console.error(err);
					},
				});
		}
	}

	buildData() {
		this.buildAllTasksObject();

		switch (this.pageMode$.value) {
			case 'truck-distribution':
				this.handleRefreshTruckDistributionSubjects();
				break;
			case 'truck-loading':
				this.handleRefreshTruckLoadingSubjects();
				break;
			case 'likut':
				this.handleRefreshLikutSubjects();
				break;

			default:
				break;
		}
	}

	dateFilterFunction(date): boolean {
		return true;
	}

	buildAllTasksObject() {
		const onlyDocnumTruckDistribution = this._truckDistribution.parametersRecord.groupOnlyByDocNum;
		const onlyDocnumTruckLoading = this._truckLoading.parametersRecord.groupOnlyByDocNum;
		switch (this.pageMode$.value) {
			case 'truck-distribution':
				this.allTruckDistributionTeudotObject$.next(
					this.allTruckDistributionTeudot.reduce((p, c) => {
						const identifier = getTaskIdentifier({ mode: 'truck-distribution', task: c, onlyDocnum: onlyDocnumTruckDistribution });

						p[identifier] ||= {};
						p[identifier][c.DocNum] = c;

						return p;
					}, {}),
				);
				break;
			case 'truck-loading':
				this.allTruckLoadingTeudotObject$.next(
					this.allTruckLoadingTeudot.reduce((p, c) => {
						const identifier = getTaskIdentifier({ mode: 'truck-loading', task: c, onlyDocnum: onlyDocnumTruckLoading });

						p[identifier] ||= {};
						p[identifier][c.DocNum] = c;

						return p;
					}, {}),
				);
				break;

			case 'likut':
				this.allLikutTeudotObject$.next(
					this.allLikutTeudot.reduce((p, c) => {
						const identifier = getTaskIdentifier({ mode: 'likut', task: c });

						p[identifier] ||= {};
						p[identifier][c.TeudaNum] = c;

						return p;
					}, {}),
				);
				break;

			default:
				break;
		}
	}

	handleRefreshTruckDistributionSubjects() {
		const values = Object.entries(this.allTruckDistributionTeudotObject$.value).map(([clientidWithComma, teudotObject]) => {
			const innerValues = Object.values(teudotObject),
				clientid = clientidWithComma.split(',')[0],
				clientName = innerValues[0].ClientName,
				status = innerValues[0].PODStatus,
				address = (innerValues[0].Address && innerValues[0].City && innerValues[0].Address + ', ' + innerValues[0].City) || innerValues[0].Address || innerValues[0].City || '',
				totalTeudot = innerValues.length,
				totalLines = innerValues.reduce((p, c) => p + +c.RowsNum, 0),
				taskID = getTaskIdentifier({ mode: 'truck-distribution', task: innerValues[0] }),
				PODDate = translateDateForWebISO(innerValues[0].PODDate).toISOString(),
				groupB = TEUDA_GROUP_B.includes(innerValues[0].TeudaType),
				colorForBackground = innerValues[0].Color;

			const { LineNum, OpenedBy, AssignTo, Extra5 } = innerValues[0];

			const finalValues: TruckDistributionTaskDisplayWithAssignment = {
				clientid,
				teudot: teudotObject,
				clientName,
				status,
				address,
				taskID,
				groupB,
				colorForBackground,
				AssignTo,
				PODDate,
				LineNum,
				totalTeudot,
				OpenedBy,
				totalLines,
				Extra5: Extra5 || '',
			};

			return finalValues;
		});

		values.sort((a, b) => a.LineNum - b.LineNum);
		if (!values.length) return;
		this.allTruckDistributionTeudotDisplay$.next(values);
		console.log(this.allTruckDistributionTeudotDisplay$.value);
		return;
	}
	handleRefreshTruckLoadingSubjects() {
		const values = Object.entries(this.allTruckLoadingTeudotObject$.value).map(([clientidWithComma, teudotObject]) => {
			const innerValues = Object.values(teudotObject),
				clientid = clientidWithComma.split(',')[0],
				clientName = innerValues[0].ClientName,
				status = innerValues[0].LoadingStatus,
				address = (innerValues[0].Address && innerValues[0].City && innerValues[0].Address + ', ' + innerValues[0].City) || innerValues[0].Address || innerValues[0].City || '',
				totalTeudot = innerValues.length,
				totalRow = innerValues.reduce((p, c) => p + +c.RowsNum, 0),
				LoadDate = translateDateForWebISO(innerValues[0].LoadDate).toISOString(),
				groupB = TEUDA_GROUP_B.includes(innerValues[0].TeudaType),
				colorForBackground = innerValues[0].Color;

			const { LineNum, OpenedBy, AssignTo } = innerValues[0];

			const finalValues: TruckLoadingTaskDisplayWithAssignment = {
				clientid,
				teudot: teudotObject,
				clientName,
				status,
				address,
				groupB,
				colorForBackground,
				AssignTo,
				LoadDate,
				LineNum,
				totalTeudot,
				OpenedBy,
				totalRow,
			};

			return finalValues;
		});

		values.sort((a, b) => a.LineNum - b.LineNum);
		this.allTruckLoadingTeudotDisplay$.next(values);
		console.log(this.allTruckLoadingTeudotDisplay$.value);
		return;
	}
	handleRefreshLikutSubjects() {
		const values = Object.entries(this.allLikutTeudotObject$.value).map(([clientidWithComma, teudotObject]) => {
			const innerValues = Object.values(teudotObject),
				clientid = clientidWithComma.split(',')[0],
				clientName = innerValues[0].Client_Name,
				status = innerValues[0].LikutStatus,
				address = (innerValues[0].Address && innerValues[0].City && innerValues[0].Address + ', ' + innerValues[0].City) || innerValues[0].Address || innerValues[0].City || '',
				totalTeudot = innerValues.length,
				quantitiesMatch = !!innerValues[0].QuantitiesMatch,
				totalRow = innerValues.reduce((p, c) => p + +c.TotalLines, 0),
				LikutDate = translateDateForWebISO(innerValues[0].LikutDate).toISOString();
			const { LineNum, OpenedBy, AssignedTo } = innerValues[0];
			const finalValues: LikutTaskDisplayWithAssignment = {
				clientid,
				teudot: teudotObject,
				clientName,
				status,
				address,
				city: address,
				AssignedTo,
				LikutDate,
				LineNum,
				totalTeudot,
				OpenedBy,
				quantitiesMatch,
				totalRow,
			};
			return finalValues;
		});
		values.sort((a, b) => a.LineNum - b.LineNum);
		this.allLikutTeudotDisplay$.next(values);
		return;
	}

	handleToggleAllAgents() {
		switch (this.agentsCheckboxContainerMode$.value) {
			case 'multiselect':
				const enableOrDisable = this.form.controls.agentsArray.value.length === 0;
				if (enableOrDisable) {
					this.form.controls.agentsArray.setValue(this.resolveAgentsArr().map(agent => agent.agentID));
				} else {
					this.form.controls.agentsArray.setValue([]);
				}
				break;
			case 'wrap':
				const agentsFormControls = Object.entries(this.form.controls).filter((control): control is [string, FormControl<boolean>] => control[0] !== 'date' && control[0] !== 'agentsArray');
				const enableOrDisableWrap = agentsFormControls.some(([key, value]) => value.value === false);

				for (const [key, control] of agentsFormControls) {
					control.setValue(enableOrDisableWrap);
				}

				break;

			default:
				break;
		}
	}

	fillAgentsArrayFormControl() {
		if (this.agentsCheckboxContainerMode$.value === 'multiselect') {
			this.form.controls.agentsArray.setValue(this.resolveAgentsArr().map(agent => agent.agentID));
		}
	}

	resolveAgentsArr() {
		switch (this.pageMode$.value) {
			case 'truck-distribution':
				return this.agentsDistribution;

			case 'truck-loading':
				return this.agentsLoading;
			case 'likut':
				return this.agentsLikut;

			default:
				return [];
		}
	}

	resolvePageMode() {
		let path: SubAppModes;
		if (location.pathname.includes('truck-loading')) path = 'truck-loading';
		if (location.pathname.includes('truck-distribution')) path = 'truck-distribution';
		if (location.pathname.includes('likut')) path = 'likut';
		this.pageMode$.next(path);
	}

	handleUpdateUsers() {
		switch (this.pageMode$.value) {
			case 'truck-distribution':
				this._truckDistribution.updateDistributionUsers();
				this._truckDistribution.updateDistributionUsersForAssignment();
				break;
			case 'truck-loading':
				this._truckLoading.updateLoadingUsers();
				this._truckLoading.updateLoadingUsersForAssignment();
				break;
			case 'likut':
				this._likut.updateLikutUsersForAssignment();
				break;

			default:
				break;
		}
	}

	isLikutMainSQL(task: TruckDistributionMainSQL | TruckLoadingMainSQL | LikutMainSQL): task is LikutMainSQL {
		return 'Client_Name' in task;
	}
}

function updateTaskCallbacks(resolve: (value: boolean | PromiseLike<boolean>) => void, reject: () => void) {
	return {
		next: res => {
			if (typeof res === 'string') {
				console.log(res);
				return reject();
			}
			resolve(true);
		},
		error: err => {
			console.log(err);
			reject();
		},
	};
}
