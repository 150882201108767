import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { NavigationBehaviorOptions, Router } from '@angular/router';
import { TeudaFromAllTeudot } from 'src/app/Interfaces/SQL-reports-responses';

@Component({
	selector: 'app-clientteudot',
	templateUrl: './clientteudot.component.html',
	styleUrls: ['./clientteudot.component.scss'],
})
export class ClientteudotComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router) {}

	public AllOrdersDetailsClient = this._ss.AllOrdersDetailsClient;
	public TeudotPerClient: TeudaFromAllTeudot[] = this._ss.TeudotPerClient;
	public EachClientEachOrder = [];
	ForLenghtError;
	isLoading = this._ss.CustomerInformation?.CustGetInfoWhenEnter === '1';
	history = history;
	skipLocationChange = false;

	ngOnInit(): void {
		window.scrollTo(0, 0);

		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
		}

		if (this._ss.CustomerInformation?.CustGetInfoWhenEnter === '1') {
			if (this._ss.AllOrdersDetailsClient === null) {
				this._ss.GetAllTeudot().subscribe(
					res => {
						this.ForLenghtError = res;

						this._ss.AllOrdersDetailsClient = this.ForLenghtError.recordset;

						if (this._ss.AllOrdersDetailsClient !== null && this._ss.AllOrdersDetailsClient !== undefined && this._ss.AllOrdersDetailsClient.length !== 0) {
							for (let x = 0; x < this._ss.AllOrdersDetailsClient.length; x++) {
								const { Client_Id, TeudaNum } = this._ss.AllOrdersDetailsClient[x];

								if (Client_Id === this._ss.OneClientInfo.ClientId) {
									let data = this.TeudotPerClient.find(ob => ob.TeudaNum === TeudaNum);
									if (data === undefined) {
										this.TeudotPerClient.push(this._ss.AllOrdersDetailsClient[x]);
									}
								}
							}
						}
						this.isLoading = false;
						this._ss.TeudotPerClient = this.TeudotPerClient;

						// Currently SQL orders by teudanum
						this.TeudotPerClient.sort((a, b) => new Date(b.TeudaCreateDate).getTime() - new Date(a.TeudaCreateDate).getTime());

						this.handleCheckIfOnlyOneTeudaInArray();
						return;
					},
					err => {},
				);
			} else {
				if (this._ss.AllOrdersDetailsClient !== null && this._ss.AllOrdersDetailsClient !== undefined && this._ss.AllOrdersDetailsClient.length !== 0) {
					for (let x = 0; x < this._ss.AllOrdersDetailsClient.length; x++) {
						if (this._ss.AllOrdersDetailsClient[x].Client_Id === this._ss.OneClientInfo.ClientId) {
							let data = this.TeudotPerClient.find(ob => ob.TeudaNum === this._ss.AllOrdersDetailsClient[x].TeudaNum);
							if (data === undefined) {
								this.TeudotPerClient.push(this._ss.AllOrdersDetailsClient[x]);
							}
						}
					}
				}
				this._ss.TeudotPerClient = this.TeudotPerClient;
				this.TeudotPerClient.sort((a, b) => new Date(b.TeudaCreateDate).getTime() - new Date(a.TeudaCreateDate).getTime());

				this.handleCheckIfOnlyOneTeudaInArray();
				return;
			}
		}

		this.handleCheckIfOnlyOneTeudaInArray();
	}

	handleCheckIfOnlyOneTeudaInArray() {
		if (this.TeudotPerClient?.length === 1) {
			this.skipLocationChange = true;
			this.MoveToTeudaEach(this.TeudotPerClient[0].TeudaNum);
		}
	}

	public MoveToTeudaEach(TeudaNum) {
		this.EachClientEachOrder = [];
		for (let x = 0; x < this.AllOrdersDetailsClient.length; x++) {
			if (this.AllOrdersDetailsClient[x].TeudaNum === TeudaNum && this.AllOrdersDetailsClient[x].Client_Id === this._ss.OneClientInfo.ClientId)
				this.EachClientEachOrder.push(this.AllOrdersDetailsClient[x]);
		}

		this._ss.EachClientEachOrder = this.EachClientEachOrder;

		const navigateOptions: NavigationBehaviorOptions = {};

		if (this.skipLocationChange) {
			navigateOptions.replaceUrl = true;
		}

		this.router.navigate(['client-teudot-each'], navigateOptions);
	}
}
