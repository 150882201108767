<div class="report-table-popup__box">
	<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
		<span class="report-table-popup__content-title"> {{ title }}</span>
	</div>
	<div style="float: right; height: 75px">
		<form [formGroup]="form" style="width: 150px">
			<mat-form-field
				color="primary"
				appearance="fill"
				dir="rtl"
				style="transform: translateX(10%) scale(0.6); font-size: 22px"
				*ngIf="device.shouldntShowNativeControls$ | async; else mobileBlock"
			>
				<mat-select [formControlName]="formControlNameFilter" multiple>
					<mat-option *ngFor="let item of controlValues" [value]="item">{{ item }}</mat-option>
				</mat-select>
			</mat-form-field>
			<ng-template #mobileBlock>
				<mat-form-field color="primary" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px">
					<select matNativeControl [name]="formControlNameFilter" multiple [formControlName]="formControlNameFilter">
						<option *ngFor="let item of controlValues" [value]="item">{{ item }}</option>
					</select>
				</mat-form-field>
			</ng-template>
		</form>
	</div>
</div>
