<div class="shop-activity__product-summ">
	<div class="shop-activity__item">
		<form [formGroup]="form" dir="rtl" autocomplete="off" class="flex flex-column main-form" (submit)="handleSubmit()">
			<mat-form-field color="primary">
				<mat-label>כרטיס</mat-label>
				<mat-select formControlName="cardType" #cardTypeControl>
					<mat-option *ngFor="let card of cards" [value]="card.type">
						{{ card.hebrewName }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field color="primary" *ngIf="!isEdittingExistingCard; else creditCardNumberReadonly">
				<mat-label>מספר כרטיס</mat-label>
				<input matInput placeholder="מספר" type="text" formControlName="number" maxlength="16" (blur)="trimWhitespaces('number')" />
				<mat-error>{{ getErrorMessage('number') }}</mat-error>
			</mat-form-field>
			<ng-template #creditCardNumberReadonly>
				<mat-form-field color="primary">
					<mat-label>{{ maskedCardNumber }}</mat-label>
					<input matInput type="text" formControlName="number" readonly maxlength="16" />
					<mat-error>{{ getErrorMessage('number') }}</mat-error>
				</mat-form-field>
			</ng-template>
			<form [formGroup]="form.controls.expiry" class="flex justify-between">
				<h3 class="input-group-title">תוקף</h3>

				<div class="gap justify-align-center">
					<mat-form-field color="primary" class="small-input">
						<mat-label>חודש</mat-label>
						<mat-select formControlName="month">
							<mat-option *ngFor="let month of months$ | async" [value]="month">
								{{ month }}
							</mat-option>
						</mat-select>
						<mat-error>שדה חסר או לא תקין</mat-error>
					</mat-form-field>
					<mat-form-field color="primary" class="small-input">
						<mat-label>שנה</mat-label>
						<mat-select formControlName="year">
							<mat-option *ngFor="let year of years" [value]="year">
								{{ year }}
							</mat-option>
						</mat-select>
						<mat-error>שדה חסר או לא תקין</mat-error>
					</mat-form-field>
				</div>
			</form>
			<ng-container *ngIf="needsToProvideCVVAndIsraeliID">
				<mat-form-field color="primary">
					<mat-label>CVV</mat-label>
					<input matInput placeholder="ספרות בטחון בגב הכרטיס" type="text" formControlName="CVV" maxlength="4" />
					<mat-error>שדה חסר או לא תקין</mat-error>
				</mat-form-field>
				<mat-form-field color="primary">
					<mat-label>תעודת זהות</mat-label>
					<input matInput placeholder="תעודת זהות" type="text" formControlName="creditCardID" maxlength="9" />
					<mat-error>שדה חסר או לא תקין</mat-error>
				</mat-form-field>
			</ng-container>
			<ng-container *ngIf="hasPayments && cardTypeControl.value !== 'isracardDirect'">
				<ng-container *ngIf="availablePayments$ | async as paymentLevels">
					<mat-form-field color="primary">
						<mat-label>סוג תשלום</mat-label>
						<mat-select formControlName="payType" #payTypeControl>
							<mat-option value="credit">רגיל</mat-option>
							<mat-option value="payments" *ngIf="paymentLevels.length">תשלומים</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field color="primary" *ngIf="payTypeControl.value === 'payments' && paymentLevels.length">
						<mat-label>תשלומים</mat-label>
						<mat-select formControlName="payments">
							<mat-option *ngFor="let paymentLevel of paymentLevels" [value]="paymentLevel.payments">
								{{ paymentLevel.payments }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="needsToSpecifyAmount">
				<mat-form-field color="primary" *ngIf="!isEdittingExistingCard; else amountEdittingBlock">
					<mat-label>סכום</mat-label>
					<input matInput placeholder="סכום" type="text" inputmode="numeric" formControlName="amount" />
					<mat-error>{{ getErrorMessage('amount') }}</mat-error>
					<button type="button" matSuffix mat-icon-button [disabled]="isEdittingExistingCard" (click)="fillAmount()">
						<mat-icon>keyboard_double_arrow_right</mat-icon>
					</button>
					<mat-hint
						>נשאר לתשלום:
						{{ maxAmount | displayAmountLeftToPay : +form.controls.amount.value : isEdittingExistingCard : 0 | number : '1.0-2' }}
					</mat-hint>
				</mat-form-field>
				<ng-template #amountEdittingBlock>
					<mat-form-field color="primary">
						<mat-label>סכום</mat-label>
						<input matInput placeholder="סכום" type="text" inputmode="numeric" formControlName="amount" />
						<!-- <mat-hint
							>נשאר לתשלום:
							{{ maxAmount | displayAmountLeftToPay : +form.controls.amount.value : isEdittingExistingCard : 0 | number : '1.0-2' }}
						</mat-hint> -->
					</mat-form-field>
				</ng-template>
			</ng-container>

			<div class="shop-activity__action" *ngIf="!isEdittingExistingCard; else elseBlock">
				<button class="shop-activity__action-button shop-activity__action-button_gray" [ngClass]="{ 'valid-background': form.valid }">{{ submitText }}</button>
				<div class="shop-activity__action-text-button" (click)="handleCancel()">בטל</div>
			</div>
			<ng-template #elseBlock>
				<div class="shop-activity__action">
					<button class="shop-activity__action-button shop-activity__action-button_gray" type="button" style="width: 33%" (click)="handleCancel()">אשר</button>
					<button type="button" class="shop-activity__action-button" style="width: 33%" (click)="resetForm()">ערוך</button>
				</div>
			</ng-template>
		</form>
	</div>
</div>
