import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DeviceInfoService } from 'src/app/services/device-info.service';

@Component({
	selector: 'app-mobilitydashbord-table-header',
	templateUrl: './mobilitydashbord-table-header.component.html',
	styleUrls: ['./mobilitydashbord-table-header.component.scss'],
})
export class MobilitydashbordTableHeaderComponent {
	@Input() isDesktop: boolean;
	@Input() title: string;
	@Input() controlName: string;
	@Input() headerForm: FormGroup;

	formControlNames = {
		תעודה: 'TeudaNewNum',
		'שם לקוח': 'Client_Name',
		'קוד לקוח': 'clientID',
		סוכן: 'AgentID',
		תאריך: 'TeudaDate',
		// 'ת.אספקה': 'supplyDate',
		'סוג תעודה': 'TeudaTypeName',
		סטטוס: 'Status',
		עיר: 'TeudaDeliveryCity',
		חברה: 'CompanyName',
		משאית: 'TruckNum',
		'ERP סטטוס': 'TuedaErpDocNum',
	};

	constructor(public device: DeviceInfoService) {}
}
