import { Pipe, PipeTransform } from '@angular/core';
import { ProductDisplayLikutOrTruck } from '../components/likut-task/likut-task.component';
import { StoreService } from '../services/store.service';

@Pipe({
	name: 'areQuantitiesDifferent',
	pure: false,
})
export class AreQuantitiesDifferentPipe implements PipeTransform {
	constructor(private _ss: StoreService) {}

	private worksInBothSinglesAndPackQuantity = this._ss.CustomerInformation.LikutType === '0';
	private worksInSinglesQuantity = this.worksInBothSinglesAndPackQuantity || this._ss.CustomerInformation.LikutType === '1';
	private worksInPackQuantity = this.worksInBothSinglesAndPackQuantity || this._ss.CustomerInformation.LikutType === '2';

	// private isLikut = location.pathname.includes('likut');
	private isTruckLoading = location.pathname.includes('truck-loading');
	// private isTruckDistribution = location.pathname.includes('truck-distribution');

	transform(mainValue: ProductDisplayLikutOrTruck, mode: 'truck' | 'likut', quantityType?: 'single' | 'pack' | 'both'): boolean {
		//truck path
		if (mode.includes('truck') && 'truckTask' in mainValue && 'PODQuantity' in mainValue.truckTask) {
			if (this.isTruckLoading) return false;

			let considerSingles = quantityType === 'single';
			let considerPacks = quantityType === 'pack';

			if (quantityType === 'both') {
				considerSingles = considerPacks = true;
			}

			const { truckTask } = mainValue;

			if (considerSingles) {
				if (this.worksInSinglesQuantity) {
					return truckTask.Quantity !== truckTask.PODQuantity;
				}
			}

			if (considerPacks) {
				if (this.worksInPackQuantity) {
					return truckTask.PackQTY !== truckTask.PODPackQTY;
				}
			}
		}

		//likut path
		//TODO Implement this
		if (mode === 'likut' && 'likut' in mainValue) {
		}

		return false;
	}
}
