<form [formGroup]="form" (ngSubmit)="submit()">
	<div class="row grid">
		<h2>מספר זיהוי רקלמציה</h2>
		<input type="text" class="form-control" formControlName="RekID" />
		<span *ngIf="form.controls.RekID.invalid && form.controls.RekID.touched" class="error">יש להזין מספר זיהוי רקלמציה</span>
	</div>
	<hr />
	<div class="row grid">
		<h2>סוג תקלה</h2>
		<select class="form-control select" formControlName="SugTakala">
			<option value="" disabled selected>בחר סוג תקלה</option>
			<option *ngFor="let reason of reclamationReasons$ | async" [value]="reason.ID">{{ reason.Name }}</option>
		</select>
		<span *ngIf="form.controls.SugTakala.invalid && form.controls.SugTakala.touched" class="error">יש לבחור סוג תקלה</span>
	</div>
	<hr />
	<div class="row grid">
		<h2>הערה</h2>
		<textarea class="form-control" formControlName="AgentRemark"></textarea>
	</div>
	<div class="row grid">
		<div class="product-detail__item-image image" id="poo" style="height: 250px; max-width: 100%; margin: auto; display: flex; justify-content: center">
			<img
				src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg"
				class="ItemPic"
				appFallbackSrc
				alt="photo"
				crossorigin
				style="height: 100%; object-fit: contain; margin: auto"
			/>
		</div>
	</div>
	<div class="row grid">
		<button class="order-step__block-button order-step__block-button_green">שלח {{ DoctypeName }}</button>
	</div>
</form>
