import { Component, OnInit, AfterViewInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Receipt } from 'src/app/Interfaces/receipt';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { MismachimDocTypes } from './Mismachim-DocTypes';
import { BehaviorSubject } from 'rxjs';
import { Mismachim } from 'src/app/Interfaces/mismachim';
import { MatSelectChange } from '@angular/material/select';
import { MismachimService } from 'src/app/services/mismachim.service';

@Component({
	selector: 'app-mismachim',
	templateUrl: './mismachim.component.html',
	styleUrls: ['./mismachim.component.scss'],
})
export class MismachimComponent implements OnInit, AfterViewInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, private _location: Location, public _receipts: ReceiptsService, public _mismachim: MismachimService) {}

	history = history;

	shouldShowReceiptsInstead = this._location.path().includes('receipts');

	title = this.shouldShowReceiptsInstead ? 'קבלות' : 'תעודות ללקוח';

	ngOnInit(): void {
		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
			return;
		}

		if (this.shouldShowReceiptsInstead) {
			console.log(this._receipts.receipts);
			return;
		}

		if (this._mismachim.allMismachimClient && this._mismachim.allMismachimClient.length) {
			return;
		}

		this._ss.GetAllTeudotAgentNewClient(this._ss.OneClientInfo.ClientId).subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('אירעה שגיאה בקבלת תעודות ללקוח');
					return;
				}
				//TODO Most indexes already sort by dtorder desc. Check if some others don't
				this._mismachim.allMismachimClient = res.recordset.sort((a, b) => new Date(b.dtorder).getTime() - new Date(a.dtorder).getTime());

				this._mismachim.allMismachimClient$.next(this._mismachim.allMismachimClient);

				this.getDoctypes();
			},
		});
	}

	ngAfterViewInit(): void {
		scrollTo({ behavior: 'auto', top: this._mismachim.scrollY + document.body.clientHeight });
	}

	getDoctypes() {
		const set = new Set();

		for (let i = 0; i < this._mismachim.allMismachimClient.length; i++) {
			const element = this._mismachim.allMismachimClient[i];

			if (!set.has(element.DocDocType)) {
				set.add(element.DocDocType);
				this._mismachim.docTypes.push({ DocDocType: element.DocDocType, DocTypeName: element.DocTypeName });
			}
		}
	}

	public BackToClientCard() {
		this.router.navigate(['client-card']);
	}

	public MoveToMismachimEach(DocDocNum, DocDocType) {
		this._mismachim.scrollY = window.scrollY;
		this._mismachim.chosenMismachParameters = { DocDocNum, DocDocType };

		this.router.navigate(['mismachim-each']);
	}

	selectedReceipt: Receipt;
	isReceiptPopupOpen = false;

	handleReceiptPopupOpen(receipt: Receipt) {
		this.selectedReceipt = receipt;
		this.isReceiptPopupOpen = !this.isReceiptPopupOpen;
	}

	handleSelectedDocTypesChange(ev: MatSelectChange) {
		const nextValue = ev.value.length === 0 ? this._mismachim.allMismachimClient : this._mismachim.allMismachimClient.filter(doc => ev.value.includes(doc.DocDocType));
		this._mismachim.allMismachimClient$.next(nextValue);
	}
}
