import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const modules = [
	MatSlideToggleModule,
	MatTabsModule,
	MatProgressSpinnerModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatSelectModule,
	MatIconModule,
	MatButtonModule,
	MatInputModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatChipsModule,
	MatBadgeModule,
	MatRadioModule,
	MatSnackBarModule,
	MatCheckboxModule,
	MatAutocompleteModule,
	MatListModule,
	MatSidenavModule,
	MatBottomSheetModule,
	MatDialogModule,
	MatButtonToggleModule,
	MatProgressBarModule,
	MatTooltipModule,
	ClipboardModule,
	DragDropModule,
	MatTreeModule,
];

@NgModule({
	declarations: [],
	imports: [...modules],
	exports: [...modules],
})
export class MaterialModule {}
