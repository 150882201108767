import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { StoreService } from 'src/app/services/store.service';
import { ExcelObject, UtilService } from 'src/app/services/util.service';
import { utils, writeFile } from 'xlsx';

type AgentParams = { AgentID: string; UserName: string; Password: string };

@Component({
	selector: 'app-admin-users',
	templateUrl: './admin-users.component.html',
	styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, private router: Router, private _fb: FormBuilder, private _util: UtilService, public device: DeviceInfoService) {}

	agents = [];
	agents$ = new BehaviorSubject([]);
	Loading = true;
	history = history;

	Teudot;
	AllTeudotReset;

	TeudotTableEach;
	LoaingConfirm = false;
	TotalArrayChecked = 0;
	TotalArray = 0;
	HasMultipleCompanys;
	SeeTrucks = '0';
	Admin;

	AgentsForAdminArray;
	FilterTeudot = [];

	showLogoutPopup = false;
	burgerMenu = false;
	burgerMenuNoScroll = false;
	headerAbsolute = false;
	windowWidth: number;
	movingOffset = { x: 0, y: 0 };
	document = document;

	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription = new Subscription();

	agentsToGet = [];

	form = this._fb.group({
		password: ['', [Validators.required, Validators.minLength(3)]],
	});

	hasB2BAgentResponsibleColumnArrivingFromSQLTable = false;

	searchControl = new FormControl<string>('', { nonNullable: true });

	@ViewChild('tableRight') rightSideOfTable: ElementRef<HTMLTableCellElement>;
	@ViewChild('header') header: ElementRef<HTMLHeadElement>;
	@ViewChild('thead') thead: ElementRef<HTMLTableRowElement>;
	@ViewChild('excelButtonBlock') excelButtonBlock: ElementRef<HTMLDivElement>;

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		if (this.Admin !== 'true') {
			this.router.navigate(['clients']);
			return;
		}

		this.canChangePassword();

		if (localStorage.getItem('CustNum') !== null) {
			this._ss.CustNum = localStorage.getItem('CustNum');
		}

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		this.windowWidth = window.innerWidth;
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe((evt: any) => {
			this.windowWidth = evt.currentTarget.innerWidth;
		});

		window.scrollTo(0, 0);

		// if (this._ss.CustomerInformation.CustNum !== '10052') {

		if (this._ss.CustomerInformation.Submanagers == '1') {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res == 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							return;
						}

						this.agentsToGet = res.recordset.map(obj => obj.Agent);
						this.agentsToGet.push(this._ss.AgentIdConnected);

						this.getAllAgents();
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getAllAgents();
			}
		} else {
			this.getAllAgents();
		}
	}

	ngOnDestroy(): void {
		this.resizeSubscription$.unsubscribe();
	}

	public Restart() {
		window.location.reload();
	}

	isPasswordChangeEndpointAvailable = false;
	passChangePopupOpen = false;

	async canChangePassword() {
		const res = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/users/change-password`, { method: 'POST' });

		if (res.status === 400) {
			this.isPasswordChangeEndpointAvailable = true;
		}
	}

	chosenAgentName = '';
	chosenAgentId = '';
	originalPassword = '';
	changedPassword = false;

	handlePasswordChangePopupOpen({ AgentID, UserName, Password }: AgentParams) {
		this.chosenAgentName = UserName;
		this.chosenAgentId = AgentID;
		this.originalPassword = Password;

		this.passChangePopupOpen = true;
	}

	handlePasswordChange() {
		const pass = this.form.controls.password.value;

		if (!pass || !this.chosenAgentId) return;

		const [AgentID, Password] = [this.chosenAgentId, pass];

		this._ss.changeAgentPassword({ AgentID, Password }).subscribe({
			next: (res: any) => {
				const parsedRes = JSON.parse(res);

				if (parsedRes.recordsets && parsedRes.rowsAffected[0] > 0) {
					console.log('okay');
					this.changedPassword = true;
				}
			},
			error: err => {
				console.log(err);
				alert('ישנה תקלת שרת, אנא נסו שנית מאוחר יותר');
			},
		});
	}

	handlePasswordChangePopupClose() {
		this.chosenAgentName = '';
		this.chosenAgentId = '';
		this.changedPassword = false;
		this.passChangePopupOpen = false;
	}

	sortArr = [false, false, false, false];

	sort(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };
		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'UserName':
				i = 0;
				break;
			case 'AgentID':
				i = 1;
				break;
			case 'Logged':
				i = 2;
				break;
			case 'Admin':
				i = 3;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	forceDisconnectNextUpdate(agentID: string) {
		this.Loading = true;
		this._ss.forceDisconnectUserNextUpdate(agentID).subscribe({
			next: res => {
				this.Loading = false;
				if (typeof res === 'string') {
					alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
					return;
				}

				this.getAllAgents();
			},
			error: err => {
				alert('שגיאת שרת');
				this.Loading = false;
			},
		});
	}

	AdminAgentLoggout(AgentID) {
		if (this._ss.CustomerInformation.HasForceDisconnect === '1') {
			this.forceDisconnectNextUpdate(AgentID);
			return;
		}
		this.Loading = true;
		this._ss.UpdateUserLogged({ AgentID: AgentID }).subscribe(
			res => {
				this.Loading = false;
			},
			err => {
				alert('שגיאת שרת');
			},
		);
	}

	AdminAgent(AgentID) {
		this.Loading = true;
		this._ss.UpdateAdminOn({ AgentID: AgentID }).subscribe(
			res => {
				this.Loading = false;
			},
			err => {
				alert('שגיאת שרת');
			},
		);
	}

	AdminAgentFalse(AgentID) {
		this.Loading = true;
		this._ss.UpdateAdminOff({ AgentID: AgentID }).subscribe(
			res => {
				this.Loading = false;
			},
			err => {
				alert('שגיאת שרת');
			},
		);
	}

	getAllAgents() {
		this.Loading = true;
		this._ss.GetAllAgents(this.agentsToGet).subscribe((res: any) => {
			this.agents = res.recordset;
			this.agents$.next(this.agents);

			this.hasB2BAgentResponsibleColumnArrivingFromSQLTable = this.agents.some(agent => agent.ClientAgent);

			this.agents.sort((a, b) => a.AgentID - b.AgentID);
			setTimeout(() => {
				this.rightSideOfTable.nativeElement.scrollIntoView();
				const heights = [this.header.nativeElement.offsetHeight, this.thead.nativeElement.offsetHeight];

				if (this.excelButtonBlock.nativeElement) {
					heights.push(this.excelButtonBlock.nativeElement.offsetHeight);
				}

				const tbody = document.getElementById('first-table');

				if (tbody) {
					tbody.style.height = `calc(100vh - ${heights.reduce((acc, curr) => acc + curr, 0)}px)`;
				}
			}, 10);
			this.Loading = false;
		});
	}

	search() {
		// if (this.isClients) this.virtualScroll.scrollToIndex(0);

		window.scroll(0, 0);

		const value = this.searchControl.value?.toLowerCase?.();

		if (!value) {
			this.agents$.next(this.agents);
		} else {
			const pattern = new RegExp(value.replace(/[\/*\+\-\?,]/g, ''), 'i');

			this.agents$.next(this.agents.filter(agent => pattern.test(agent.UserName || '') || pattern.test(agent.AgentID || '')));
		}
	}

	searchClear() {
		this.searchControl.reset();
		this.search();
	}

	valuesObject = {
		Agents: ['UserName', 'AgentID', 'ClientAgent', 'Logged', 'Admin'],
	} as const;

	translateForSort(value: typeof this.valuesObject[typeof screen][number], screen: keyof typeof this.valuesObject = Object.keys(this.valuesObject)[0] as keyof typeof this.valuesObject): void {
		let i: number, arrayToSort: any[];

		i = this.valuesObject[screen].findIndex(arr => arr === value);

		arrayToSort = this.agents$.value;

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);

		this.agents$.next([...this.agents$.value]);
	}

	downloadExcel() {
		const dataObject: ExcelObject = { data: [], filename: '', extension: 'xlsx' };

		// const longestStringInColumn: any = {};

		dataObject.data = this.agents$.value.map(agent => {
			const obj: { [key: string]: string | number } = {
				'שם סוכן': agent.UserName,
				'קוד סוכן': agent.AgentID,
				סיסמה: agent.Password,
				סטטוס: agent.Logged === '1' ? 'מחובר' : 'לא מחובר',
				מנהל: agent.Admin,
			};
			if (this.hasB2BAgentResponsibleColumnArrivingFromSQLTable) {
				obj['סוכן B2B'] = agent.ClientAgent;
			}

			// for (const entry of Object.entries(obj)) {
			// 	longestStringInColumn[entry[0]] ??= 0;
			// 	longestStringInColumn[entry[0]] = Math.max(String(entry[1]).length, longestStringInColumn[entry[0]], 3);
			// }
			return obj;
		});

		dataObject.filename = 'סוכנים';

		// console.log({ longestStringInColumn });

		const ws = utils.json_to_sheet(dataObject.data);

		// ws['!cols'] = Object.values(longestStringInColumn).map((columnWidth: number) => ({ wch: columnWidth }));

		const wb = utils.book_new();
		if (!wb.Workbook) wb.Workbook = {};
		if (!wb.Workbook.Names) wb.Workbook.Views = [{}];
		wb.Workbook.Views[0].RTL = true;
		utils.book_append_sheet(wb, ws, dataObject.filename);
		writeFile(wb, `${dataObject.filename}.${dataObject.extension}`, { bookType: dataObject.extension, sheet: dataObject.filename, compression: true, cellStyles: true });
	}
}
