<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
					</div>
					<div class="header__title col" *ngIf="!isAutoluxb2bDashboard; else altTitleBlock">תמונת מצב - {{ _ss.CustomerInformation.CustName }}</div>
					<!-- <div class="header__title col" *ngIf="!isAutoluxb2bDashboard; else altTitleBlock">ניהול הזמנות - {{ _ss.CustomerInformation.CustName }}</div> -->
					<ng-template #altTitleBlock>
						<div class="header__title col">מעקב הזמנות</div>
					</ng-template>
					<div class="header__burger col image" (click)="Restart()" style="text-decoration: underline">
						<b> איפוס </b>
					</div>
				</div>
			</div>
			<div class="report-table__row">
				<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
					<form [formGroup]="form">
						<input class="header__search-input" type="text" placeholder="חפש" formControlName="Search" id="search-Input" style="text-align: center; direction: rtl" />
					</form>
					<button class="header__clear-icon" (click)="SearchClear()"></button>
					<span class="totals"> מציג סה"כ ({{ isAutoluxb2bDashboard ? (autoluxDisplayArray$ | async).length : Teudot?.length || 0 }}) תעודות</span>
				</div>
			</div>

			<h2 style="text-align: center" *ngIf="LoadingBack === true">טוען אנא המתן</h2>
		</header>

		<!-- markup for product detail popup -->
		<div class="popup" *ngIf="isTeudaEditPopupOpen" style="z-index: 1002">
			<div class="overlay"></div>
			<div class="product-detail__title remarks" *ngIf="EdittedTeudaRemark">
				<span style="font-weight: 700">הערת תעודה:</span> {{ EdittedTeudaRemark }}.
				<span *ngIf="selectedTeudaToOpenPopupRemarks.length">{{ (selectedTeudaToOpenPopupRemarks | filterArray).join() }}.</span>
			</div>
			<div class="product-detail__title" *ngIf="openedTeudaClientName">
				{{ openedTeudaClientName }}
			</div>
			<div class="product-detail-popup popup__content">
				<div class="popup__close" (click)="toggleTeudaPopup('close')" style="width: 30px; height: 30px; background-color: black"></div>

				<div class="product-detail__wrapper">
					<table *ngIf="!shouldShowTeudaAlreadyChangedPopup">
						<tr class="fixed-table-headers">
							<th #teudotTableNames>שם פריט</th>
							<th>קוד פריט</th>
							<th>יחידות</th>
							<th>{{ _text.packQuantityText }}</th>
							<th>בונוס</th>
							<th>מחיר</th>
							<th>הנחה</th>
							<th>מחיר נטו</th>
							<th>מחיר סופי</th>
							<th>מחיקת פריט</th>
						</tr>
						<tr *ngFor="let pte of ProductsToEdit" (click)="openProductofTeudaToEdit(pte.ProductId)" class="product-detail__clickable-row">
							<td>{{ pte.ProductName }}</td>
							<td>{{ pte.ProductId }}</td>
							<td [ngClass]="{ 'value-changed': +pte.TeudaQuantity !== +pte.TeudaQuantityOriginal }">{{ pte.TeudaQuantity }}</td>
							<td [ngClass]="{ 'value-changed': +pte.TeudaPackQuantity !== +pte.TeudaPackQuantityOriginal }">
								{{ pte.TeudaPackQuantity }}
							</td>
							<td
								[ngClass]="{
									'value-changed': +pte.TeudaBonusQuantity !== +pte.TeudaBonusQuantityOriginal,
									'invalid-color': pte.TeudaBonusQuantity > 0 && +pte.TeudaBonusQuantity === +pte.TeudaBonusQuantityOriginal
								}"
							>
								{{ pte.TeudaBonusQuantity }}
							</td>
							<td [ngClass]="{ 'value-changed': +pte.TeudaPrice !== +pte.TeudaPriceOriginal }">{{ pte.TeudaPrice }}</td>
							<td [ngClass]="{ 'value-changed': +pte.TeudaItemDiscount !== +pte.TeudaItemDiscountOriginal }">
								{{ pte.TeudaItemDiscount | number : '1.0-2' }}
							</td>
							<td [ngClass]="{ 'value-changed': +pte.TeudaItemNetPrice !== +pte.TeudaItemNetPriceOriginal }">
								{{ pte.TeudaItemNetPrice | number : '1.0-2' }}
							</td>
							<td [ngClass]="{ 'value-changed': +pte.TeudaTotalRow !== +pte.TeudaTotalRowOriginal }">{{ pte.TeudaTotalRow | number : '1.0-2' }}</td>
							<td>
								<div class="product-detail__delete-button">
									<app-custom-svg [svg]="'delete'" (click)="deleteProductFromTeuda($event, pte.ProductId)"></app-custom-svg>
								</div>
							</td>
						</tr>
					</table>
					<div class="product-detail__wrapper__already-sent" *ngIf="shouldShowTeudaAlreadyChangedPopup">
						{{ messageToShowAfterEdit }}
					</div>
				</div>
				<div class="product-detail__wrapper__send-changes-block">
					<button
						class="product-detail__wrapper__send-changes-block__buttons product-detail__wrapper__send-changes-block__buttons__send"
						*ngIf="!shouldShowTeudaAlreadyChangedPopup"
						(click)="ChangeTeudaStatusAfterFinishEditting()"
					>
						אשר תעודה
					</button>
					<button
						class="product-detail__wrapper__send-changes-block__buttons product-detail__wrapper__send-changes-block__buttons__date"
						*ngIf="!shouldShowTeudaAlreadyChangedPopup && _ss.CustomerInformation?.EditDate === 1"
						(click)="handleSupplyDateEditPopupOpen()"
					>
						תאריך אספקה
					</button>
					<button
						class="product-detail__wrapper__send-changes-block__buttons product-detail__wrapper__send-changes-block__buttons__delete"
						*ngIf="!shouldShowTeudaAlreadyChangedPopup"
						(click)="teudaDeletePopupConfirmation = true"
					>
						מחק תעודה
					</button>
					<button
						class="product-detail__wrapper__send-changes-block__buttons product-detail__wrapper__send-changes-block__buttons__send"
						*ngIf="shouldShowTeudaAlreadyChangedPopup"
						(click)="shouldShowTeudaAlreadyChangedPopup = false; isTeudaEditPopupOpen = false; document.body.classList.remove('no-scroll')"
					>
						סגור
					</button>
				</div>
			</div>
		</div>
		<!-- end markup for product detail popup -->

		<!-- start markup edit supply date popup -->

		<div class="popup" *ngIf="shouldSupplyDateEditPopupShow" style="z-index: 1004">
			<div class="overlay"></div>
			<div class="product-detail-popup popup__content">
				<div class="product-detail__wrapper wrapper2">
					<div class="product-detail__item">
						<div
							class="popup__close"
							(click)="shouldSupplyDateEditPopupShow = false; shouldShowButtonRow = false; dateEdittedSuccessfullyMsg = null"
							style="width: 30px; height: 30px; background-color: black; position: fixed"
						></div>
						<form [formGroup]="supplyDateForm">
							<h2 class="product-detail__item-title" style="margin: 0">עריכה של תאריך אספקת הזמנה</h2>

							<div class="teuda-edit__section" style="margin: 0">
								<h3 class="teuda-edit__title" *ngIf="!isTabletAndUpResolution" style="margin: 0">תאריך</h3>
								<div class="teuda-edit__input-block">
									<div class="teuda-edit__input-block__title" *ngIf="isTabletAndUpResolution">תאריך</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaSupplyDate">שנה ל:</label>
										<input type="date" [min]="supplyDateOriginal" formControlName="TeudaSupplyDate" name="TeudaSupplyDate" style="font-size: 15px" />
									</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaSupplyDate">ערך מקור</label>
										<span>{{ supplyDateOriginal | date : 'dd/MM/yy' }}</span>
									</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaSupplyDate">יצירת הזמנה</label>
										<span>{{ teudaCreateDate | date : 'dd/MM/yy' }}</span>
									</div>
								</div>
							</div>

							<div class="shop-filter__row__button_row" style="padding: 2rem">
								<button
									class="order-cart__popup-button"
									[ngStyle]="{ visibility: shouldShowButtonRow ? 'visible' : 'hidden' }"
									type="button"
									(click)="handleSupplyDateEditSubmit()"
									[disabled]="supplyDateForm.invalid"
								>
									שמור
								</button>
								<span [ngStyle]="{ visibility: shouldShowButtonRow && supplyDateForm.invalid ? 'visible' : 'hidden' }" style="color: red; position: absolute; bottom: 0"
									>תאריך אינו תקין או מוקדם מתאריך יצירת תעודה</span
								>
								<button
									class="product-detail-add__popup-button"
									[ngStyle]="{ visibility: shouldShowButtonRow ? 'visible' : 'hidden' }"
									type="button"
									(click)="shouldSupplyDateEditPopupShow = false; shouldShowButtonRow = false"
								>
									שכח
								</button>
								<div class="shop-filter__request-status" style="top: 1rem" *ngIf="dateEdittedSuccessfullyMsg">
									{{ dateEdittedSuccessfullyMsg }}
								</div>
								<div class="shop-filter__request-status" style="color: red" *ngIf="dateEdittedSuccessfullyMsg">נא לאשר תעודה בסיום העריכה</div>
								<span
									class="shop-filter__request-status__button"
									(click)="shouldSupplyDateEditPopupShow = false; shouldShowButtonRow = false; dateEdittedSuccessfullyMsg = null"
									*ngIf="dateEdittedSuccessfullyMsg"
									>סגור</span
								>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<!-- end markup edit supply date popup -->

		<!-- start markup for delete teuda confirmation popup -->

		<div class="popup" *ngIf="teudaDeletePopupConfirmation" style="z-index: 1003">
			<div class="overlay"></div>
			<div class="product-detail-popup popup__content">
				<div
					class="popup__close"
					(click)="teudaDeletePopupConfirmation = false; messageToShowAfterDelete = 'מחיקת תעודה הינה בלתי הפיכה. להמשיך?'; document.body.classList.remove('no-scroll')"
					style="width: 30px; height: 30px; background-color: black"
				></div>
				<div class="product-detail__wrapper">
					<div class="product-detail__wrapper__delete-confirmation">
						{{ messageToShowAfterDelete }}
					</div>
				</div>
				<div class="product-detail__wrapper__send-changes-block" *ngIf="messageToShowAfterDelete === 'מחיקת תעודה הינה בלתי הפיכה. להמשיך?'">
					<button class="product-detail__wrapper__send-changes-block__buttons" (click)="teudaDeletePopupConfirmation = false">בטל</button>
					<button class="product-detail__wrapper__send-changes-block__buttons product-detail__wrapper__send-changes-block__buttons__delete" (click)="deleteTeudaFromTeudaTable()">כן, תמחק</button>
				</div>
			</div>
		</div>

		<!-- end markup for delete teuda confirmation popup -->

		<!-- start markup for edit teuda product popup -->

		<div class="popup" *ngIf="selectedProductToEdit" style="z-index: 1003">
			<div class="overlay"></div>
			<div class="product-detail-popup popup__content">
				<div class="product-detail__wrapper wrapper2">
					<div class="product-detail__item">
						<div class="popup__close" (click)="handleProductEditPopupClose()" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>
						<form [formGroup]="form">
							<h2 class="product-detail__item-title" style="margin: 0">עריכה של {{ selectedProductToEdit.ProductName }}</h2>

							<div class="teuda-edit__section" style="margin: 0">
								<h3 class="teuda-edit__title" *ngIf="!isTabletAndUpResolution" style="margin: 0">יחידות</h3>
								<div class="teuda-edit__input-block">
									<div class="teuda-edit__input-block__title" *ngIf="isTabletAndUpResolution">יחידות</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaQuantity">שנה ל:</label>
										<input type="number" formControlName="TeudaQuantity" name="TeudaQuantity" style="font-size: 15px" />
									</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaQuantity">ערך מקור</label>
										<span>{{ selectedProductToEdit.TeudaQuantity }}</span>
									</div>
								</div>
							</div>
							<div class="teuda-edit__section" style="margin: 0">
								<h3 class="teuda-edit__title" *ngIf="!isTabletAndUpResolution" style="margin: 0">{{ _text.packQuantityText }}</h3>
								<div class="teuda-edit__input-block">
									<div class="teuda-edit__input-block__title" *ngIf="isTabletAndUpResolution">{{ _text.packQuantityText }}</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaPackQuantity">שנה ל:</label>
										<input type="number" formControlName="TeudaPackQuantity" name="TeudaPackQuantity" style="font-size: 15px" />
									</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaPackQuantity">ערך מקור</label>
										<span>{{ selectedProductToEdit.TeudaPackQuantity }}</span>
									</div>
								</div>
							</div>
							<div class="teuda-edit__section" style="margin: 0">
								<h3 class="teuda-edit__title" *ngIf="!isTabletAndUpResolution">בונוס</h3>
								<div class="teuda-edit__input-block">
									<div class="teuda-edit__input-block__title" *ngIf="isTabletAndUpResolution">בונוס</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaBonusQuantity">שנה ל:</label>
										<input type="number" formControlName="TeudaBonusQuantity" name="TeudaBonusQuantity" style="font-size: 15px" />
									</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaBonusQuantity">ערך מקור</label>
										<span>{{ selectedProductToEdit.TeudaBonusQuantity }}</span>
									</div>
								</div>
							</div>
							<div class="teuda-edit__section" style="margin: 0">
								<h3 class="teuda-edit__title" *ngIf="!isTabletAndUpResolution">מחיר</h3>
								<div class="teuda-edit__input-block">
									<div class="teuda-edit__input-block__title" *ngIf="isTabletAndUpResolution">מחיר</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaPrice">שנה ל:</label>
										<input type="number" formControlName="TeudaPrice" name="TeudaPrice" style="font-size: 15px" />
									</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaPrice">ערך מקור</label>
										<span>{{ selectedProductToEdit.TeudaPrice }}</span>
									</div>
								</div>
							</div>

							<div class="teuda-edit__section" style="margin: 0">
								<h3 class="teuda-edit__title" *ngIf="!isTabletAndUpResolution">הנחה למוצר</h3>
								<div class="teuda-edit__input-block">
									<div class="teuda-edit__input-block__title" *ngIf="isTabletAndUpResolution">הנחה למוצר</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaItemDiscount">שנה ל:</label>
										<div style="position: relative">
											<span class="teuda-edit__input-block__section__input-prefix">%</span>
											<input type="number" formControlName="TeudaItemDiscount" name="TeudaItemDiscount" style="font-size: 15px" />
										</div>
									</div>
									<div class="teuda-edit__input-block__section">
										<label for="TeudaItemDiscount">ערך מקור</label>
										<span>{{ selectedProductToEdit.TeudaItemDiscount }}%</span>
									</div>
								</div>
							</div>
							<div class="shop-filter__row__button_row" style="padding: 2rem">
								<button
									class="order-cart__popup-button"
									[ngStyle]="{ visibility: shouldShowButtonRow ? 'visible' : 'hidden' }"
									type="button"
									(click)="SendEditedProduct()"
									[disabled]="
										form.get('TeudaPackQuantity').invalid ||
										form.get('TeudaItemDiscount').invalid ||
										form.get('TeudaPrice').invalid ||
										form.get('TeudaQuantity').invalid ||
										form.get('TeudaBonusQuantity').invalid
									"
								>
									שמור
								</button>
								<span
									[ngStyle]="{
										visibility:
											(shouldShowButtonRow && form.get('TeudaPackQuantity').invalid) ||
											form.get('TeudaItemDiscount').invalid ||
											form.get('TeudaPrice').invalid ||
											form.get('TeudaQuantity').invalid ||
											form.get('TeudaBonusQuantity').invalid
												? 'visible'
												: 'hidden'
									}"
									style="color: red; position: absolute; bottom: 0"
									>אחד הערכים אינו תקין</span
								>
								<button class="product-detail-add__popup-button" [ngStyle]="{ visibility: shouldShowButtonRow ? 'visible' : 'hidden' }" type="button" (click)="handleProductEditPopupClose()">
									שכח
								</button>
								<div class="shop-filter__request-status" style="top: 1rem" *ngIf="sendEditedProductStatus">
									{{ sendEditedProductStatus }}
								</div>
								<div class="shop-filter__request-status" style="color: red" *ngIf="sendEditedProductStatus">נא לאשר תעודה בסיום העריכה</div>
								<span class="shop-filter__request-status__button" (click)="handleProductEditPopupClose()" *ngIf="sendEditedProductStatus">סגור</span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<!-- end markup for edit teuda product popup -->

		<!-- markup for see teuda after teuda num press-->
		<div class="popup" *ngIf="LoadingSeeTeuda || SeeTeudaLoaded" style="z-index: 9990">
			<div class="overlay"></div>
			<div class="product-detail__title" *ngIf="openedTeudaClientName">
				{{ openedTeudaClientName }}
			</div>
			<div class="product-detail__title remarks" *ngIf="selectedTeudaToOpenPopup?.TeudaRemark">
				<span style="font-weight: 700">הערת תעודה:</span> {{ selectedTeudaToOpenPopup?.TeudaRemark }}.
				<span *ngIf="selectedTeudaToOpenPopupRemarks.length">{{ (selectedTeudaToOpenPopupRemarks | filterArray).join() }}.</span>
			</div>
			<div class="product-detail-popup popup__content">
				<div class="popup__close" (click)="handleCloseTeudaPopup()" style="width: 30px; height: 30px; background-color: black" *ngIf="!LoadingSeeTeuda"></div>
				<div class="product-detail__wrapper">
					<table *ngIf="SeeTeudaLoaded">
						<tr class="fixed-table-headers">
							<th #teudotTableNames>שם פריט</th>
							<th>קוד פריט</th>
							<th>יחידות</th>
							<th>{{ _text.packQuantityText }}</th>
							<th>בונוס</th>
							<th>מחיר</th>
							<th>הנחה</th>
							<th>מחיר נטו</th>
							<th>מחיר סופי</th>
							<th>רווחיות שורה</th>
							<th>הערה</th>
						</tr>
						<tr *ngFor="let pte of TeudotTableEach.length ? TeudotTableEach : TeudotEach">
							<td>{{ pte.ProductName }}</td>
							<td>{{ pte.ProductId }}</td>
							<td>{{ pte.TeudaQuantity }}</td>
							<td>{{ pte.TeudaPackQuantity }}</td>
							<td [ngClass]="{ 'invalid-color': pte.TeudaBonusQuantity > 0 }">{{ pte.TeudaBonusQuantity }}</td>
							<td>{{ pte.TeudaPrice }}</td>
							<td>{{ pte.TeudaItemDiscount }}</td>
							<td>{{ pte.TeudaItemNetPrice | number : '1.0-2' }}</td>
							<td>{{ pte.TeudaTotalRow | number : '1.0-2' }}</td>
							<td>{{ pte.TeudaItemProfit }}</td>
							<td>{{ pte.TeudaItemRemark }}</td>
						</tr>
					</table>
					<div class="product-detail__wrapper__already-sent" *ngIf="LoadingSeeTeuda">
						טוען תעודה, אנא המתינו...
						<mat-spinner diameter="50" color="primary"></mat-spinner>
					</div>
				</div>
			</div>
		</div>

		<!--  dashboard-tables markup  -->
		<section class="dashboard-tables" style="margin-top: 30px">
			<div class="content">
				<!-- markup for logout popup -->
				<div class="popup" *ngIf="showLogoutPopup">
					<div class="overlay"></div>
					<div class="popup__content popup__content_logout">
						<div class="popup__close" (click)="closepopup()"></div>
						<div class="popup__content-image image">
							<img src="/assets/images/logout.gif" alt="photo" />
						</div>
						<h2 class="popup__content-title">לחזור לדף דוחות?</h2>

						<div class="popup__content-block popup__content-block_center">
							<button class="popup__content-button" (click)="exitdashbord()">כן, חזור</button>
							<button class="popup__content-button popup__content-button_red" (click)="closepopup()">לא, ביטול</button>
						</div>
					</div>
				</div>

				<!-- end markup for logout popup -->

				<div class="dashboard-tables__wrapper">
					<table
						*ngIf="!isAutoluxb2bDashboard"
						style="font-size: 17px; position: sticky; top: 130px; z-index: 999"
						class="filter-row"
						[ngStyle]="{ 'z-index': isTeudaEditPopupOpen || LoadingSeeTeuda || SeeTeudaLoaded || showLogoutPopup || teudaDeletePopupConfirmation ? '99' : '999' }"
					>
						<tr>
							<th style="background-color: white; width: 150px"><b>סומנו</b></th>
							<th style="background-color: white; width: 150px"><b>שורות</b></th>
							<th style="background-color: white; width: 150px"><b>סינון מתקדם</b></th>
							<th style="background-color: white; width: 150px" *ngIf="(_device.isWebview$ | async) === false"><b>הורד דוח</b></th>
						</tr>

						<tr>
							<td>
								<b>{{ TotalArrayChecked | number : '1.0-2' : 'en-US' }}</b>
							</td>
							<td>
								<b>{{ ArrayChecked.length }}</b>
							</td>
							<td>
								<div class="justify-align-center">
									<div class="report-table__button" (click)="showFilterPopup = true"></div>
								</div>
							</td>
							<td *ngIf="(_device.isWebview$ | async) === false">
								<app-excel-button [hideText]="true" tableQuerySelector=".report-table__table"></app-excel-button>
							</td>
						</tr>
					</table>

					<div class="dashboard-tables__row dashboard-tables__row_scroll margin-top">
						<b>
							<section class="sticky-headers" [ngClass]="{ 'flex with-map': windowWidth > 1000 && isRegularDashboard && isNonProduction }">
								<div
									class="map-wrapper"
									[ngStyle]="{ height: 'calc(99vh - ' + tableHeight + ')' }"
									*ngIf="windowWidth > 1000 && isRegularDashboard && isNonProduction && shouldMapShow"
									[ngClass]="{ fullscreen: isFullscreen }"
								>
									<app-map [coordinates]="coordinates" (isFullscreenEvent)="changeFullscreen($event)"></app-map>
								</div>
								<table
									class="report-table__table"
									[ngStyle]="{ height: 'calc(99vh - ' + tableHeight + ')' }"
									cellspacing="0"
									#regularTable
									*ngIf="!isAutoluxb2bDashboard; else autoluxTableBlock"
								>
									<thead class="report-table__table-head" #thead>
										<tr>
											<th class="report-table__table-head-name">
												<app-mobilitydashbord-table-header title="תעודה" [headerForm]="headerSearchForm"> </app-mobilitydashbord-table-header>
											</th>
											<th
												class="report-table__table-head-quantity"
												*ngIf="((_ss.CustomerInformation.EditTeuda === '1' && !isB2BPage) || (_ss.CustomerInformation.EditTeuda === '2' && isB2BPage)) && isActualAdmin"
											>
												ערוך תעודה
											</th>
											<th class="report-table__table-head-code">
												<app-mobilitydashbord-table-header title="שם לקוח" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-code" *ngIf="hasClientIdColumn">
												<app-mobilitydashbord-table-header title="קוד לקוח" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity">
												<app-mobilitydashbord-table-header title="סוכן" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity">סכום</th>
											<th class="report-table__table-head-quantity" *ngIf="hasTeudaProfitColumn">רווחיות תעודה</th>
											<th class="report-table__table-head-quantity">
												<app-mobilitydashbord-table-header title="תאריך" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity">
												<!-- <app-mobilitydashbord-table-header title=""  [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header> -->
												שעה
											</th>
											<th class="report-table__table-head-quantity" *ngIf="hasTeudaSupplyDateColumn">
												ת.אספקה
												<!-- <app-mobilitydashbord-table-header title="ת.אספקה" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header> -->
											</th>
											<th class="report-table__table-head-quantity">שורות</th>
											<th class="report-table__table-head-quantity">
												<app-mobilitydashbord-table-header title="סוג תעודה" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity">
												<app-mobilitydashbord-table-header title="סטטוס" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity" *ngIf="hasTeudaDeliveryCityColumn">
												<app-mobilitydashbord-table-header title="עיר" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity" *ngIf="HasMultipleCompanys">
												<app-mobilitydashbord-table-header title="חברה" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity" *ngIf="SeeTrucks === '1'">
												<app-mobilitydashbord-table-header title="משאית" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity ERP">
												<app-mobilitydashbord-table-header title="ERP סטטוס" [headerForm]="headerSearchForm"></app-mobilitydashbord-table-header>
											</th>
											<th class="report-table__table-head-quantity" *ngIf="SeeTrucks === '1' && isActualAdmin"><button (click)="ChooseTruckPopAll = true">בחר משאית</button></th>
											<th class="report-table__table-head-quantity" *ngIf="SeeTrucks !== '1'"><button (click)="checkAllOrders()">סמן</button></th>
											<th class="report-table__table-head-quantity" *ngIf="isActualAdmin">אשר הזמנה</th>
											<th class="report-table__table-head-quantity" *ngIf="_ss.CustomerInformation.PrintOrder === '1'">הדפס</th>
										</tr>
									</thead>
									<tbody class="report-table__table-body" [ngStyle]="{ height: 'calc(99vh - ' + thead.offsetHeight + 'px - ' + tableHeight + ')' }">
										<tr
											*ngFor="let t of Teudot; let index = index"
											[ngStyle]="{
												'box-shadow': t.TeudaStatus === 2 || t.TeudaStatus === 4 || t.TeudaStatus === 3 ? 'rgb(233 115 115) 0px 0px 9px 3px inset' : '',
												'background-color': t.Color || null
											}"
										>
											<td
												class="report-table__table-body-name-clickable"
												*ngIf="t.TeudaNewNum.length < 1"
												style="color: red"
												(click)="document.body.classList.add('no-scroll'); seeTeuda(t.TeudaNum, index)"
											>
												{{ t.TeudaNum.toString().slice(7, 13) }}
											</td>
											<td
												class="report-table__table-body-name-clickable"
												*ngIf="t.TeudaNewNum.length > 1"
												style="color: #38d88b"
												(click)="seeTeuda(t.TeudaNum, index); document.body.classList.add('no-scroll')"
											>
												{{ t.TeudaNewNum }}
											</td>
											<td
												class="report-table__table-body-name"
												*ngIf="((_ss.CustomerInformation.EditTeuda === '1' && !isB2BPage) || (_ss.CustomerInformation.EditTeuda === '2' && isB2BPage)) && isActualAdmin"
											>
												<button (click)="EditTeuda(t.TeudaNewNum, t.TeudaNum, index)" *ngIf="t.TeudaStatus && t.TeudaStatus !== 1 && t.TeudaStatus !== 0 && t.TeudaStatus !== 3">
													<span>ערוך</span>
												</button>
											</td>
											<td class="report-table__table-body-name clickable client-name" (click)="openClientCardPopup(t)">{{ t.Client_Name }}</td>
											<td class="report-table__table-body-name clickable client-name" *ngIf="hasClientIdColumn" (click)="openClientCardPopup(t)">{{ t[clientIdKey] }}</td>
											<td class="report-table__table-body-quantity">
												{{ allAgentsRecord[t.AgentID] ? allAgentsRecord[t.AgentID].AgentID + ' - ' + allAgentsRecord[t.AgentID].UserName : t.AgentID }}
											</td>
											<!-- ! Do not change number to currency. Yaron wants to keep it as number. 14/11/22 -->
											<td class="report-table__table-body-quantity" dir="ltr">{{ t.TeudaTotalSum | number : '1.0-2' }}</td>
											<td class="report-table__table-body-quantity" *ngIf="hasTeudaProfitColumn">{{ t.TeudaProfit }}</td>
											<td class="report-table__table-body-quantity">
												{{ t.TeudaShowDate.toString().slice(0, 2) }}.{{ t.TeudaShowDate.toString().slice(3, 5) }}.{{ t.TeudaShowDate.toString().slice(6, 10) }}
											</td>
											<td class="report-table__table-body-quantity">{{ t.TeudaShowTime }}</td>
											<td class="report-table__table-body-quantity" *ngIf="hasTeudaSupplyDateColumn">
												{{ t.SupplyDate ? (t.SupplyDate | date : 'dd.MM.yyyy') : t.TeudaSupplyDate }}
											</td>
											<td class="report-table__table-body-quantity">{{ t.TotalLines }}</td>
											<td class="report-table__table-body-quantity">{{ t.TeudaTypeName }}</td>
											<td class="report-table__table-body-quantity">{{ t.TeudaStatus || t.Status }}</td>
											<td class="report-table__table-body-quantity" *ngIf="hasTeudaDeliveryCityColumn">
												{{ t.TeudaDeliveryCity }}
											</td>
											<td class="report-table__table-body-quantity" *ngIf="HasMultipleCompanys">{{ t.Company + (t.CompanyName ? ' - ' + t.CompanyName : '') }}</td>
											<td class="report-table__table-body-quantity" (click)="ChooseTruck(t.TeudaNum)" *ngIf="SeeTrucks === '1' && t.TruckNum === '' && isActualAdmin" style="color: red">
												בחר משאית
											</td>
											<td class="report-table__table-body-quantity" *ngIf="SeeTrucks === '1' && t.TruckNum !== ''" style="color: green">{{ t.TruckNum }}</td>
											<td class="report-table__table-body-quantity ERP" [ngStyle]="{ color: t.TuedaErpDocNum && t.TeudaStatus === 1 ? '#36c836' : 'initial' }">
												{{ t.TuedaErpDocNum }}
											</td>
											<td class="report-table__table-body-quantity" *ngIf="SeeTrucks !== '1' || (SeeTrucks === '1' && t.TruckNum === '')">
												<input type="checkbox" style="height: 20px; width: 20px" (change)="OrderChecked(t.TeudaNum, t.TeudaTotalSum)" />
											</td>
											<td class="report-table__table-body-quantity" *ngIf="isActualAdmin">
												<div class="dashboard-tables__table-button" *ngIf="t.Status === 2 && LoaingConfirm === false" (click)="AdminConfirmOrder(t.TeudaNum)" style="color: #38d88b">
													הגדר
												</div>
											</td>
											<td class="report-table__table-body-quantity" *ngIf="_ss.CustomerInformation.PrintOrder === '1'">
												<button
													mat-raised-button
													color="primary"
													(click)="changeTeudaPrintStatus(t.TeudaNewNum)"
													*ngIf="t.PrintStatus === 1 && LoaingConfirm === false && isActualAdmin && t.TeudaNewNum"
												>
													<mat-icon>print</mat-icon>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</section>

							<ng-template #autoluxTableBlock>
								<table class="report-table__table report-table__table-alt" #autoluxCarTable cellspacing="0" [ngStyle]="{ height: 'calc(98vh - ' + tableHeight + ')' }">
									<thead class="report-table__table-head">
										<tr>
											<th class="report-table__table-head-name">מס רכב</th>
											<th class="report-table__table-head-code">הזמנת לקוח</th>
											<th class="report-table__table-head-code">מס אישור</th>
											<th class="report-table__table-head-quantity">מיקום הרכבה</th>
											<th class="report-table__table-head-quantity">תאריך</th>
											<th class="report-table__table-head-quantity">סטטוס</th>
										</tr>
									</thead>
									<tbody class="report-table__table-body" [ngStyle]="{ height: 'calc(94vh - ' + tableHeight + ')' }">
										<tr *ngFor="let t of autoluxDisplayArray$ | async; let index = index">
											<td class="report-table__table-body-name">{{ t.CARNUM }}</td>
											<td class="report-table__table-body-name">{{ t.LEASINGORDER }}</td>
											<td class="report-table__table-body-name">{{ t.CONFIRMNUM }}</td>
											<td class="report-table__table-body-name">{{ t.MIKUM }}</td>
											<td class="report-table__table-body-name">{{ t.CREATEDATE.split('T')[0].split('-').reverse().join('-') }}</td>
											<td class="report-table__table-body-quantity">{{ t.ORDSTATUSDES }}</td>
										</tr>
									</tbody>
								</table>
							</ng-template>
						</b>
					</div>
				</div>
			</div>
		</section>

		<div *ngIf="TeudotEachString" style="margin-top: 100px; margin-right: 20px" dir="rtl">
			<div class="dashboard-tables__row dashboard-tables__row_scroll">
				<table class="dashboard-tables__table-big table" cellspacing="0">
					<thead class="table__head">
						<tr>
							<th class="dashboard-tables__table-big-column_name table__head-column">סטרינג</th>
						</tr>
					</thead>
					<tbody id="second-table" class="table__body">
						<tr class="dashboard-tables__table-big-row dashboard-tables__table-big-row_active">
							<td class="dashboard-tables__table-big-column_name table__head-column" style="height: 100%">
								{{ TeudotEachString }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<!-- start markup for product detail add popup -->
	<div class="popup" *ngIf="ChooseTruckPop === true" style="z-index: 1999">
		<div class="overlay"></div>
		<div class="product-detail-add__popup popup__content">
			<div class="popup__close" (click)="ChooseTruckClose()"></div>

			<h2 style="text-align: center">הקלד משאית</h2>

			<form [formGroup]="form" (keyup)="ClosePops()" dir="rtl">
				<input
					type="text"
					formControlName="Truck"
					id="TruckInput"
					placeholder="אורך מינימלי של 6 ספרות"
					style="width: 99%; margin: auto; border: 1px solid black; height: 40px; font-size: 20px"
					dir="rtl"
					maxlength="45"
				/>
			</form>

			<h4 style="text-align: center; color: green" *ngIf="ConfirmTruckPop === true">בוצע שיוך משאית לתעודה {{ TeudaNumChoosen }}</h4>
			<h4 style="text-align: center; color: red" *ngIf="ErrorTruckPop === true">ישנה תקלה,אנא נסה שנית</h4>

			<div>
				<button
					class="product-detail-add__popup-button"
					(click)="SubmitTruck()"
					(keyup.enter)="SubmitTruck()"
					style="background-color: #38d88b; margin: auto; height: 30px; width: 60px"
					*ngIf="ShowConformButtonTruck === true && form.controls['Truck'].valid"
				>
					אישור
				</button>

				<button class="product-detail-add__popup-button" (click)="ChooseTruckClose()" style="background-color: red; margin: auto; float: right; height: 30px; width: 60px">חזור</button>
			</div>
		</div>
	</div>
	<!-- end markup for product detail add popup -->

	<!-- start markup for product detail add popup -->
	<div class="popup" *ngIf="ChooseTruckPopAll === true" style="z-index: 1999">
		<div class="overlay"></div>
		<div class="product-detail-add__popup popup__content">
			<div class="popup__close" (click)="ChooseTruckCloseAll()"></div>

			<h2 style="text-align: center">הקלד משאית</h2>

			<form [formGroup]="form" (keyup)="ClosePopsAll()" dir="rtl">
				<input
					type="text"
					formControlName="TruckAll"
					id="TruckInputAll"
					placeholder="אורך מינימלי של 6 ספרות"
					style="width: 99%; margin: auto; border: 1px solid black; height: 40px; font-size: 20px"
					dir="rtl"
					maxlength="45"
				/>
			</form>

			<h4 style="text-align: center; color: green" *ngIf="ConfirmTruckPopAll === true">בוצע שיוך משאית לתעודות</h4>
			<h4 style="text-align: center; color: red" *ngIf="ErrorTruckPopAll === true">ישנה תקלה,אנא נסה שנית</h4>

			<div>
				<button
					class="product-detail-add__popup-button"
					(click)="SubmitTruckAll()"
					(keyup.enter)="SubmitTruckAll()"
					style="background-color: #38d88b; margin: auto; height: 30px; width: 60px"
					*ngIf="ShowConformButtonTruckAll === true && form.controls['TruckAll'].valid"
				>
					אישור
				</button>

				<button class="product-detail-add__popup-button" (click)="ChooseTruckCloseAll()" style="background-color: red; margin: auto; float: right; height: 30px; width: 60px">חזור</button>
			</div>
		</div>
	</div>
	<!-- end markup for product detail add popup -->

	<!-- end main menu markup -->
</div>

<!-- start sort popup -->
<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="popup__close" (click)="showFilterPopup = false" style="top: 2em; z-index: 1002; box-shadow: inset 0px 0px 2px, 0 0 3px 2px" [style.left]="isTabletAndUpResolution ? '43%' : '2em'"></div>
	<div class="report-table-popup report-table-popup_sort popup__content" style="overflow-y: scroll">
		<div class="report-table-popup__header">
			<div class="report-table-popup__header-title">סינון מתקדם</div>
		</div>
		<div class="report-table-popup__wrapper">
			<div class="report-table-popup__content">
				<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding"></div>
				<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
					<div class="report-table-popup__box">
						<span class="report-table-popup__content-title">בחירת תקופה</span>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">מתאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="FromDate" (change)="handleDateChange('from')" />
								</form>
							</label>
						</div>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">עד תאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="ToDate" (change)="handleDateChange('to')" />
								</form>
							</label>
						</div>
					</div>
					<app-mobilitydashbord-filter-box
						*ngFor="let filter of filters"
						[form]="form"
						[title]="filter.title"
						[controlValues]="filter.controlValues"
						[formControlNameFilter]="filter.formControlName"
					></app-mobilitydashbord-filter-box>
				</div>
				<div class="report-table-popup__button-wrapper">
					<button class="report-table-popup__button" [attr.disabled]="dateInvalid ? true : null" [ngClass]="{ darken: dateInvalid }" (click)="handleAdvancedSearch()">הצג תוצאות</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end sort popup -->

<!-- start clientcard popup -->

<div class="popup" *ngIf="shouldClientCardPopupShow" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed">
		<div class="product-detail__wrapper wrapper2">
			<div class="product-detail__item overflow" style="padding: 0">
				<div
					class="popup__close"
					(click)="_ss.OneClientInfoDebt = null; shouldClientCardPopupShow = false; document.body.classList.remove('no-scroll')"
					style="width: 30px; height: 30px; background-color: black; position: fixed"
				></div>
				<app-clientcard></app-clientcard>
			</div>
		</div>
	</div>
</div>

<!-- end clientcard popup -->
