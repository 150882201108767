import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LikutService } from '../services/likut.service';
import { TruckDistributionService } from '../services/truck-distribution.service';
import { TruckLoadingService } from '../services/truck-loading.service';

@Injectable({
	providedIn: 'root',
})
export class CantExitTruckOrLikutGuard implements CanDeactivate<unknown> {
	constructor(private _likut: LikutService, private _router: Router, private _truckLoading: TruckLoadingService, private _truckDistribution: TruckDistributionService) {}

	canDeactivate(
		component: unknown,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (
			(this._likut.likutOpenedNewRoutesState.sub.length || this._truckLoading.truckLoadOpened.sub.length || this._truckDistribution.truckDistributionOpened.sub.length) &&
			!nextState.url.includes('finish')
		) {
			this._router.navigate([currentState.url.slice(1)]);

			return false;
		}

		return true;
	}
}
