import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { decompress } from 'lz-string';
import { DateAdapter } from '@angular/material/core';
import { BehaviorSubject, combineLatest, filter, map, Observable, startWith, Subject, take, takeUntil } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Users } from 'src/app/Interfaces/users';
import { Time, UtilService } from 'src/app/services/util.service';
import { VisitsPlannedVsUnplanned } from 'src/app/Interfaces/SQL-reports-responses';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/loading.service';
import { VisitsService } from 'src/app/services/visits.service';

@Component({
	selector: 'app-adminbizoaazmanot',
	templateUrl: './adminbizoaazmanot.component.html',
	styleUrls: ['./adminbizoaazmanot.component.scss'],
})
export class AdminbizoaazmanotComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		private _date: DateAdapter<any, 'he-IL'>,
		private _acr: ActivatedRoute,
		public _util: UtilService,
		private _loading: LoadingService,
		public _visits: VisitsService,
	) {}

	AdminSeeAgents;
	AgentPerDate;
	AgentPerDateOne;
	AdminSeeAgentsMain;

	agents = new FormControl<{ AgentID: string; UserName: string }[]>([]);
	companyControl = new FormControl<number>(0);

	form = this._fb.nonNullable.group({
		Date: [Intl.DateTimeFormat('en-IL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).split('/').reverse().join('-')],
		ClientSearch: ['', Validators.required],
		agents: this.agents,
	});

	TodayDate = new Date();

	shouldShowCompanies = 0;

	agentsToGet = [];

	datesFilter = [new Date().toISOString().slice(0, 10)];

	dateFilterFunction = (date: Date): boolean => {
		const dates = this.datesFilter;

		return date ? dates.includes(date.toISOString().slice(0, 10)) : false;
	};

	destroy$ = new Subject<void>();

	goToDateOfAgent: { agent?: string; date?: string } = {};

	pageMode$ = new BehaviorSubject<'daily-sales' | 'planned-vs-unplanned' | ''>('');

	title$: Observable<string>;
	showSearch$: Observable<boolean>;
	totalSum$: Observable<number>;

	currentlyViewedAgent = '';
	currentlyViewedClientTeuda = '';

	checkedArray = [];
	checkedArray$ = new BehaviorSubject(this.checkedArray.reduce((prev, curr) => prev + curr.COD, 0));

	todayCutDate = Intl.DateTimeFormat('en-IL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).split('/').reverse().join('-');

	isVisitsPlannedVsUnplanned = location.pathname.includes('planned-vs-unplanned');
	visits: VisitsPlannedVsUnplanned[] = [];
	visits$ = new BehaviorSubject<VisitsPlannedVsUnplanned[]>([]);
	visitsDisplay$ = this.visits$.pipe(map(visits => visits.filter(visit => new Date(visit.docdate).toISOString().slice(0, 10) === this.todayCutDate)));

	maxVisitsLength = environment.production ? 0 : 0;

	ngOnInit(): void {
		this._date.setLocale('he-IL');

		this._acr.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
			for (const key in params) {
				if (Object.prototype.hasOwnProperty.call(params, key)) {
					this.goToDateOfAgent[key] = params[key];
				}
			}

			this.form.controls.Date.setValue(this.goToDateOfAgent.date);
		});

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		const username = localStorage.getItem('UserName');
		const agentid = localStorage.getItem('AgentId');

		if (!username || !agentid) {
			this.router.navigate(['login']);
			return;
		}

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		this.setupPipes();
		this.setupPlannedVsUnplannedInteractivity();

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		// this.dropdownSettings = {
		// 	idField: 'AgentID',
		// 	textField: 'UserName',
		// 	selectAllText: 'סמן הכל',
		// 	unSelectAllText: 'הוצא סימון',
		// 	itemsShowLimit: 1,
		// };

		if (this.isVisitsPlannedVsUnplanned) {
			if (this._visits.chosenLineToView$.value) {
				return;
			}
			this.getPlannedVsUnplannedVisits();
			return;
		}

		if (this._ss.CustomerInformation.Submanagers == '1') {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res == 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							return;
						}

						this.agentsToGet = res.recordset.map(obj => obj.Agent);
						this.agentsToGet.push(this._ss.AgentIdConnected);

						this.getAgentList();
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getAgentList();
			}
		} else {
			this.getAgentList();
		}
	}

	ngOnDestroy(): void {
		this._loading.changeLoadingStatuses({ isLoading: false });
		this._visits.resetChosenLineState();
		this.destroy$.next();
		this.destroy$.complete();
	}

	public Total = 0;

	public ChooseDate() {
		if (this.pageMode$.value === 'planned-vs-unplanned') return;

		this.Total = 0;

		this.AdminSeeAgents = [];

		let fixedDate = new Date(new Date(this.form.controls.Date.value).getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

		this.First = true;
		this.Second = false;
		this.Third = false;
		this.Fourth = false;

		if (this.goToDateOfAgent.date) {
			fixedDate = new Date(this.goToDateOfAgent.date);
		}

		for (let x = 0; x < this.AdminSeeAgentsMain.length; x++) {
			if (this.AdminSeeAgentsMain[x].td.toString().slice(0, 10) === fixedDate.toISOString().slice(0, 10)) {
				this.AdminSeeAgents.push(this.AdminSeeAgentsMain[x]);
				this.Total += this.AdminSeeAgentsMain[x].sum1;
			}
		}

		this.totalSum$ = new BehaviorSubject(this.Total);
	}

	public ResetDate() {
		this.AdminSeeAgents = this.AdminSeeAgentsMain;
		this.form.controls['Date'].reset();
		this.Total = this.AdminSeeAgents.reduce((a, b) => a + b.sum1, 0);
		this.totalSum$ = new BehaviorSubject(this.Total);
	}

	public First = true;
	public Second = false;
	public Third = false;
	public Fourth = false;

	public BackToAdmin() {
		if (this.First === true) {
			// this.router.navigate(['admin'])
			history.back();
		}

		if (this.Second === true) {
			this.First = true;
			this.Second = false;
		}

		if (this.Third === true) {
			this.Second = true;
			this.Third = false;
		}

		if (this.Fourth === true) {
			this.Third = true;
			this.Fourth = false;
		}
	}

	public GetSpecificAgentAndDay(ag, agn, td, cm) {
		this.First = false;
		this.Second = true;
		this.AgentPerDate = [];

		this.currentlyViewedAgent = `${ag} - ${agn}`;

		this._ss.GetSpecificAgentAndDayService(ag, td, cm).subscribe({
			next: (res: any) => {
				if (!res.recordset || !res.recordset.length) {
					alert('לא נמצאו נתונים לתאריך זה');
					this.First = true;
					this.Second = false;
					return;
				}

				this.AgentPerDate = res;
				this.AgentPerDate = this.AgentPerDate.recordset;
			},
			error: err => {
				alert('שגיאת שרת');
				this.First = true;
				this.Second = false;
				return;
			},
		});
	}
	public GetOneAzmanaAgentAdmonDate(tn, cn) {
		this.currentlyViewedClientTeuda = cn;
		this.Second = false;
		this.Third = true;
		this.AgentPerDateOne = [];

		this._ss.GetOneAzmanaAgentAdmonDateService(tn).subscribe(res => {
			this.AgentPerDateOne = res;
			this.AgentPerDateOne = this.AgentPerDateOne.recordset.map(one => ({ ...one, net: one.prc * (1 - one.dsc / 100) }));
		});
	}

	async getAgentList() {
		// await this.handleGetAgentsList();
		this._ss
			.GetAgentsList(this.agentsToGet)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					if (typeof res === 'string' || !res.recordset || !res.recordset.length) {
						alert('ישנה תקלת תקשורת. נסו שנית מאוחר יותר');
						return history.back();
					}

					this.AdminSeeAgents = [];
					this.AdminSeeAgentsMain = res.recordset;
					// this.AdminSeeAgentsMain = this.AdminSeeAgents;

					const companiesSet: Set<number> = new Set();
					const datesSet: Set<string> = new Set();

					const todayCutDate = Intl.DateTimeFormat('en-IL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).split('/').reverse().join('-');

					let agentName;

					for (let x = 0; x < this.AdminSeeAgentsMain.length; x++) {
						// Boy do I love Set. Much faster than .filter
						companiesSet.add(this.AdminSeeAgentsMain[x].cm);

						const fixedDate = new Date(new Date(this.AdminSeeAgentsMain[x].td).getTime() + new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

						datesSet.add(fixedDate.toISOString().slice(0, 10));

						const unchangedDate = new Date(this.AdminSeeAgentsMain[x].td).toISOString().slice(0, 10);

						if (unchangedDate === todayCutDate) {
							this.AdminSeeAgents.push(this.AdminSeeAgentsMain[x]);
						}
					}
					this.Total = this.AdminSeeAgents.reduce((a, b) => a + b.sum1, 0);

					this.totalSum$ = new BehaviorSubject(this.Total);

					this.datesFilter = [...datesSet];

					this.shouldShowCompanies = [...companiesSet].length;

					if (this.goToDateOfAgent.agent && this.goToDateOfAgent.date) {
						const ag = this.AdminSeeAgentsMain.find(am => am.ag === this.goToDateOfAgent.agent);
						this.ChooseDate();
						this.GetSpecificAgentAndDay(this.goToDateOfAgent.agent, ag.agn, new Date(this.goToDateOfAgent.date).toISOString(), 1);
					}
				},
				error: err => {
					alert('ישנה תקלת תקשורת. נסו שנית מאוחר יותר');
					history.back();
				},
			});
	}

	SearchClear() {
		this.form.controls.ClientSearch.reset();
	}

	async getAgentsList() {
		return await new Promise<Users[]>((resolve, rej) => {
			this._ss
				.GetAllAgents()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'object' && res.recordset) {
							res.recordset.sort((a, b) => +a.AgentID - +b.AgentID);
							return resolve(res.recordset);
						}

						rej();
					},
					error: err => {
						console.log({ err });
						rej();
					},
				});
		});
	}

	getPlannedVsUnplannedVisits() {
		this._ss
			.getPlannedVsUnplannedReport()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					if (typeof res === 'string' || !res.recordset?.length) {
						alert('ישנה תקלת תקשורת. נסו שנית מאוחר יותר');
						return history.back();
					}

					this.AdminSeeAgents = [];
					this.AdminSeeAgentsMain = [];
					// this.AdminSeeAgentsMain = this.AdminSeeAgents;

					const companiesSet: Set<number> = new Set();
					const datesSet: Set<string> = new Set();

					for (let x = 0; x < res.recordset.length; x++) {
						const visit = res.recordset[x];
						companiesSet.add(visit.company);

						const fixedDate = new Date(new Date(visit.docdate).getTime() + new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

						datesSet.add(fixedDate.toISOString().slice(0, 10));
					}

					this.visits = res.recordset;
					this.visits$.next(this.visits);

					this.datesFilter = [...datesSet];

					this.shouldShowCompanies = [...companiesSet].length;

					// if (this.goToDateOfAgent.agent && this.goToDateOfAgent.date) {
					// 	const ag = this.AdminSeeAgentsMain.find(am => am.ag === this.goToDateOfAgent.agent);
					// 	this.ChooseDate();
					// 	this.GetSpecificAgentAndDay(this.goToDateOfAgent.agent, ag.agn, new Date(this.goToDateOfAgent.date).toISOString(), 1);
					// }
				},
				error: err => {
					alert('ישנה תקלת תקשורת. נסו שנית מאוחר יותר');
					history.back();
				},
			});
	}

	handleAdvancedSearch(action: 'clear' | 'all' | 'single') {
		this.form.controls['ClientSearch'].setValue('');

		// if (action !== 'single') {
		// 	this.AllClients = this.AllClientsSearch;
		// } else {
		// 	if (this.agents.value && this.agents.value.length) {
		// 		this.AllClients = this.AllClientsSearch.filter(client => {
		// 			for (const a of client.agent) {
		// 				if (this.agents.value.find(ag => ag.AgentID === a)) {
		// 					return true;
		// 				}
		// 			}
		// 			return false;
		// 		});
		// 	}
		// }

		// this.refreshSubjects('allClientsSum');
	}

	handleCheck(client) {
		const included = this.checkedArray.findIndex(checked => checked.ClientId === client.ClientId);
		if (included > -1) {
			this.checkedArray.splice(included, 1);
		} else {
			this.checkedArray.push(client);
		}
		this.checkedArray$.next(this.checkedArray.reduce((prev, curr) => prev + curr.COD, 0));
	}

	valuesObject = {
		First: ['agn', 'ag', 'sum1', 'td', 'cm'],
		Second: ['cn', 'cl', 'sum1', 'tn', 'dn'],
		Third: ['itn', 'it', 'qt', 'pk', 'unt', 'prc', 'dsc', 'net', 'bns', 'rem'],
	} as const;

	translateForSort(value: typeof this.valuesObject[typeof screen][number], screen: keyof typeof this.valuesObject = Object.keys(this.valuesObject)[0] as keyof typeof this.valuesObject): void {
		let i: number, arrayToSort: any[];

		i = this.valuesObject[screen].findIndex(arr => arr === value);

		if (screen === 'First') {
			arrayToSort = this.AdminSeeAgents;
		}

		if (screen === 'Second') {
			arrayToSort = this.AgentPerDate;
		}

		if (screen === 'Third') {
			arrayToSort = this.AgentPerDateOne;
		}

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}

	setupPipes() {
		this.pageMode$.next(this.isVisitsPlannedVsUnplanned ? 'planned-vs-unplanned' : 'daily-sales');

		this.title$ = this.pageMode$.pipe(
			map(page => {
				switch (page) {
					case 'daily-sales':
						return 'מכירות יומי';
					case 'planned-vs-unplanned':
						return 'ביקורים - תיכנון מול ביצוע';

					default:
						return '';
				}
			}),
		);

		this.showSearch$ = this.pageMode$.pipe(
			map(page => {
				switch (page) {
					case 'daily-sales':
						return false;
					case 'planned-vs-unplanned':
						return true;

					default:
						return false;
				}
			}),
		);
	}

	setupPlannedVsUnplannedInteractivity() {
		if (this.pageMode$.value !== 'planned-vs-unplanned') return;

		const searchValue$ = this.form.controls.ClientSearch.valueChanges.pipe(startWith(''));
		const dateValue$ = this.form.controls.Date.valueChanges.pipe(
			startWith(Intl.DateTimeFormat('en-IL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).split('/').reverse().join('-')),
		);

		const allVisits$ = this._visits.chosenLineToView$.value ? this._visits.visits$.asObservable() : this.visits$;

		this.visitsDisplay$ = combineLatest([allVisits$, searchValue$, dateValue$]).pipe(
			map(([allVisits, searchValue, dateValue]: [VisitsPlannedVsUnplanned[], string, string | Date]) => {
				let filteredVisits: VisitsPlannedVsUnplanned[] = [...allVisits.slice(0, this.maxVisitsLength || allVisits.length)];
				const stringSearchArray: any[] = [];

				if (searchValue) {
					const splitValue = searchValue.split(' ');

					for (let i = 0; i < filteredVisits.length; i++) {
						let counter = 0;

						const { ClientID, clientname } = filteredVisits[i];

						const clientIDWithoutSpaces = (clientname || '').replace(/\s/g, '').toLowerCase(),
							clientNameWithoutSpaces = (ClientID || '').replace(/\s/g, '').toLowerCase(),
							allStrings = typeof clientIDWithoutSpaces === 'string' && typeof clientNameWithoutSpaces === 'string';

						// V8 optimizes this if statement, maybe
						if (allStrings) {
							for (let j = 0; j < splitValue.length; j++) {
								if ((clientIDWithoutSpaces && clientIDWithoutSpaces.indexOf(splitValue[j]) > -1) || (clientNameWithoutSpaces && clientNameWithoutSpaces.indexOf(splitValue[j]) > -1)) {
									counter++;
								}
							}

							if (counter >= splitValue.length) {
								stringSearchArray.push(filteredVisits[i]);
							}
						}
					}

					filteredVisits = stringSearchArray;
				}

				if (dateValue && !this._visits.chosenLineToView$.value) {
					if (typeof dateValue === 'string') {
						return (filteredVisits = filteredVisits.filter(visit => new Date(visit.docdate).toISOString().slice(0, 10) === this.todayCutDate));
					}
					let fixedDate = new Date(dateValue.getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

					filteredVisits = filteredVisits.filter(visit => new Date(visit.docdate).toISOString().slice(0, 10) === fixedDate.toISOString().slice(0, 10));
				}

				return filteredVisits;
			}),
		);

		this.totalSum$ = this.visitsDisplay$.pipe(map(visits => visits.reduce((a, b) => a + b.TeudaTotalSumBeforeVat, 0)));
	}
}
