import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-dohotmotzarimmlay',
	templateUrl: './dohotmotzarimmlay.component.html',
	styleUrls: ['./dohotmotzarimmlay.component.scss'],
})
export class DohotmotzarimmlayComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;
	history = history;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;

	public MlayOne = true;
	public IdOne = false;
	public NameOne = false;
	public PriceOne = false;

	public toogleInputNew = false;

	public Mlayres;

	public Loading = false;

	reportSearch = null;

	nameLengthAverage = 0;

	windowWidth = window.innerWidth;

	shouldProductPopupShow;
	document = document;

	ItemExtraDetails = [];

	destroy$ = new Subject<void>();

	// allProductsMoneyTotal$ = new BehaviorSubject<number>(0)
	// allProductsMoneyAverage$ = new BehaviorSubject<number>(0)

	// allProductsStockTotal$ = new BehaviorSubject<number>(0)
	// allProductsStockAverage$ = new BehaviorSubject<number>(0)

	ngOnInit(): void {
		window.scrollTo(0, 0);

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.form = this._fb.group({
			ProductSearch: ['', Validators.required],
			SectionSearch: ['', Validators.required],
			Yehidot: ['', Validators.required],
			Arizot: ['', Validators.required],
			ChangePrice: ['', Validators.required],
			ChangeDiscount: ['', Validators.required],
			HearaForProduct: ['', Validators.required],
			Bonus: ['', Validators.required],
			BonusArizot: ['', Validators.required],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('AllProducts') !== null) {
			this.Loading = true;

			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);

			console.log(this.AllProducts);

			for (let z = 0; z < this.AllProducts.length; z++) {
				this.nameLengthAverage += this.AllProducts[z].Product_Name.length;
			}

			this.nameLengthAverage = Math.ceil(this.nameLengthAverage / this.AllProducts.length);

			// Take Of / And r For Tires Up //
			if (this._ss.CustomerInformation.CustExtra5 === '1') {
				for (let x = 0; x < this.AllProducts.length; x++) {
					this.AllProducts[x].Product_NameD = this.AllProducts[x].Product_Name;
					this.AllProducts[x].Product_Name = this.AllProducts[x].Product_Name.replace(/[/xr\-\+]/gi, '');
				}
			}
			///// Take Of / And r For Tires Down/////

			this.getItemExtras();

			this._ss.RefreshMlayServiceDefault1().subscribe(
				res => {
					this.Mlayres = res;
					this.Mlayres = this.Mlayres.recordset;
					for (let x = 0; x < this.AllProducts.length; x++) {
						for (let y = 0; y < this.Mlayres.length; y++) {
							if (this.Mlayres[y].it === this.AllProducts[x].ProductId) {
								this.AllProducts[x].Stock = this.Mlayres[y].mly;
							}
						}
					}

					this.AllProductsSearch = this.AllProducts;
					// this.refreshSubjects()

					setTimeout(() => {
						this.sort('Stock', 'AllProducts');
						this.Loading = false;
					}, 1000);
				},
				err => {
					this.AllProductsSearch = this.AllProducts;

					// this.refreshSubjects()

					setTimeout(() => {
						this.sort('Stock', 'AllProducts');
						this.Loading = false;
					}, 1000);
				},
			);
		} else {
			this.router.navigate(['clients']);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	trackByFnProduct(ap) {
		return ap.ProductId;
	}

	productToShowInPopup = [];

	handlePopup(ProductId: string) {
		// ProductId = ProductId.toLowerCase()

		const comparer = ProductId.toLowerCase();

		this.productToShowInPopup = this.ItemExtraDetails.filter(search => search.ProductID.toLowerCase() === comparer).map(pop => ({ ...pop, ProductID: ProductId }));
	}

	getItemExtras() {
		this._ss.getItemExtraProducts().subscribe({
			next: (res: any) => {
				if (res?.recordset?.length) {
					this.ItemExtraDetails = res.recordset;
				}
			},
			error: err => {},
		});
	}

	// refreshSubjects() {
	//   this.allProductsMoneyTotal$.next(this.AllProducts.reduce((c, p) => c + +p.Price, 0))
	//   this.allProductsMoneyAverage$.next(this.AllProducts.reduce((c, p) => c + +p.Price, 0) / this.AllProducts.length)

	//   this.allProductsStockTotal$.next(this.AllProducts.reduce((c, p) => c + +p.Stock, 0))
	//   this.allProductsStockAverage$.next(this.AllProducts.reduce((c, p) => c + +p.Stock, 0) / this.AllProducts.length)
	// }

	public BackToDohot() {
		this.router.navigate(['dohot-motzarim']);
	}

	sortArr = [true, true, false, false];

	sort(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'Product_Name':
				i = 0;
				break;
			case 'Stock':
				i = 1;
				break;
			case 'Price':
				i = 2;
				break;
			case 'ProductId':
				i = 3;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}

	public SearchClear() {
		this.AllProducts = this.AllProductsSearch;

		this.toogleInputNew = false;
		this.form.reset();

		// this.refreshSubjects()
	}

	public toogleInputFunction() {
		this.toogleInputNew = true;
	}

	public SearchProducts() {
		this.toogleInputNew = true;
		window.scrollTo(0, 0);

		this.AllProducts = [];

		const prodSearch = this.form.controls['ProductSearch'].value?.toLowerCase?.();

		if (!prodSearch) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (this.AllProducts.length < 100) {
					this.AllProducts.push(this.AllProductsSearch[x]);
				}
			}
			this.toogleInputNew = false;
		}

		if (prodSearch?.length === 1) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (
					this.AllProductsSearch[x].Product_Name.toString().slice(0, 1).toLowerCase() === prodSearch.slice(0, 1) ||
					this.AllProductsSearch[x].ProductId.toString().slice(0, 1).toLowerCase() === prodSearch.slice(0, 1)
				) {
					if (this.AllProducts.length < 100) {
						this.AllProducts.push(this.AllProductsSearch[x]);
					}
				}
			}
		}
		if (prodSearch?.length === 2) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (
					this.AllProductsSearch[x].Product_Name.toString().slice(0, 2).toLowerCase() === prodSearch.slice(0, 2) ||
					this.AllProductsSearch[x].ProductId.toString().slice(0, 2).toLowerCase() === prodSearch.slice(0, 2)
				) {
					if (this.AllProducts.length < 100) {
						this.AllProducts.push(this.AllProductsSearch[x]);
					}
				}
			}
		}
		if (prodSearch?.length > 2) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				const { Product_Name, ProductId, ItemBarcode } = this.AllProductsSearch[x];
				if (this.AllProducts.length > 100) return;
				const len = Math.min(Product_Name.length, 50);
				for (let z = 0; z < len; z++) {
					if (
						(Product_Name || '').slice(z, prodSearch.length + z).toLowerCase() === prodSearch ||
						(ProductId || '').slice(z, prodSearch.length + z).toLowerCase() === prodSearch ||
						(ItemBarcode || '').slice(z, prodSearch.length + z).toLowerCase() === prodSearch
					) {
						this.AllProducts.push(this.AllProductsSearch[x]);
						break;
					}
				}
			}
		}

		// this.refreshSubjects()
	}
}
