import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { all } from 'cypress/types/bluebird';
import { BehaviorSubject, Subject, Observable, catchError, throwError, takeUntil, of, from, mergeMap, map, forkJoin, switchMap, combineLatest, shareReplay, delay } from 'rxjs';
import { Receipt, ReceiptForGvia } from 'src/app/Interfaces/receipt';
import { YaadGvia } from 'src/app/Interfaces/SQL-reports-responses';
import { ReturnMonthHebrewPipe } from 'src/app/Pipes/return-month-hebrew.pipe';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';
import { translateDateForWebISO } from 'src/app/Utility/functions';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base-component/base.component';
import yaadmock from './yaadMock.json';

const SecondValues = ['ag', 'cm', 'tot', 'ltot', 'ahuz', 'AgentCode', 'total', 'currentYearGoal', 'growthPercentage', 'lastYearTotal', 'advancementPercentage', 'CompanyName'] as const;
const ThirdValues = ['mm', 'tot', 'ltot', 'ahuz', 'cm', 'MM', 'Tot', 'currentYearGoal', 'Ltot', 'Ahuz'] as const;
const FourthValues = ['Cl', 'cn', 'tot', 'ltot', 'ahuz', 'cm'] as const;
const FifthValues = ['dd', 'dn', 'tsum'] as const;
const SixthValues = ['pr', 'pn', 'prc', 'qu', 'ds'] as const;
const dummyValue = [''] as const;

// const valuesObject = {
// 	Second: SecondValues,
// 	Third: ThirdValues,
// 	Fourth: FourthValues,
// 	Fifth: FifthValues,
// 	Sixth: SixthValues,
// } as const;

const possiblePages = ['First', 'Second', 'Third', 'Fourth', 'Fifth'] as const;

@Component({
	selector: 'app-yaad-gvia-report',
	templateUrl: './yaad-gvia-report.component.html',
	styleUrls: ['./yaad-gvia-report.component.scss'],
})
export class YaadGviaReportComponent extends BaseComponent implements OnInit, OnDestroy {
	constructor(public router: Router, public _fb: FormBuilder, public _util: UtilService, private _acr: ActivatedRoute, private _loading: LoadingService) {
		super();
	}

	public form: FormGroup;
	public AllClientsSearch;
	public AgentsSalesAll;
	public AgentsSalesAllSecond;
	public AgentsSalesAllThird;
	public AgentsSalesAllFourth;
	public AgentsSalesAllFifth = [];
	public OneTeudaInfo = [];

	public HasMultipleCompanys;
	public ShowCompanys;

	loading = true;

	AgentSalesAllSecondMonth;
	AgentsSalesAllSecondWithGoals$ = new BehaviorSubject<any>([]);

	agentResponseWithGoals = [];

	agentsToGet = [];

	agentsFromV_Users;

	destroy$ = new Subject<void>();

	displayTotal$ = new BehaviorSubject<number>(0);
	displayAverage$ = new BehaviorSubject<number>(0);

	hasAgentsGoals = false;

	isClosed = true;

	monthOriginalRes = [];
	isInMonthView = false;
	selectedMonth;

	windowWidth = innerWidth;

	isAdmin = location.href.includes('admin');
	isAgent = location.href.includes('agent');

	private defaultPageTitle = 'יעדי גבייה';

	currentPage$ = new BehaviorSubject<'First' | 'Second' | 'Third' | 'Fourth' | 'Fifth'>('First');
	firstPage$ = this.currentPage$.pipe(map(page => page === 'First'));
	secondPage$ = this.currentPage$.pipe(map(page => page === 'Second'));
	thirdPage$ = this.currentPage$.pipe(map(page => page === 'Third'));
	fourthPage$ = this.currentPage$.pipe(map(page => page === 'Fourth'));
	fifthPage$ = this.currentPage$.pipe(map(page => page === 'Fifth'));

	excelFilename$: Observable<string> = this.currentPage$.pipe(
		delay(0),
		map(page => {
			let title = this.defaultPageTitle;

			const index = possiblePages.indexOf(page);

			if (index < 1) return title;

			if (index > 0){
				title += ' - ' + this.selectedYear$.value;
			}

			if (index > 1){
				title += ' - ' + this.selectedAgent$.value.name;
			}

			if (index > 2){
				title += ' - ' + new ReturnMonthHebrewPipe().transform(this.selectedMonthKabala$.value, true);
			}

			if (index > 3){
				title += ' - ' + this.selectedKabalaNumber$.value;
			}

			return title;
		}),
	);

	title$: Observable<string> = of(this.defaultPageTitle);
	showSearch$: Observable<boolean>;

	readonly currentYear = new Date().getFullYear();
	readonly currentMonth = new Date().getMonth() + 1;
	selectedYear$ = new BehaviorSubject<number>(0);

	allYaadGvia$ = new BehaviorSubject<YaadGvia[]>([]);
	selectedAgent$ = new BehaviorSubject({ name: '', id: '' });
	agentIsSelected$ = this.selectedAgent$.pipe(map(agent => !!agent.id));
	selectedMonthKabala$ = new BehaviorSubject<number>(0);
	selectedKabalaNumber$ = new BehaviorSubject<string>('');
	expandedYearlyViewSelectedYear$ = new BehaviorSubject<number>(0);
	isExpandedYearlyViewOpen$ = this.expandedYearlyViewSelectedYear$.pipe(map(year => !!year));

	expandedYearlyViewDisplay$ = combineLatest([this.allYaadGvia$, this.expandedYearlyViewSelectedYear$]).pipe(
		map(([allYaad, selectedYear]) => {
			allYaad = allYaad.filter(gvia => gvia.yy !== this.currentYear || gvia.mm <= this.currentMonth);
			const arrayLength = this.currentYear === selectedYear ? this.currentMonth : 12;
			return new Array(arrayLength).fill(0).map((_, i) => {
				const month = i + 1;
				const monthData = allYaad.filter(gvia => gvia.yy === selectedYear && gvia.mm === month);
				return {
					yy: selectedYear,
					mm: month,
					tothov: monthData.reduce((p, c) => p + +c.tothov, 0),
					gviathismonth: monthData.reduce((p, c) => p + c.gviathismonth, 0),
					ahuzgvia: isFinite(monthData.reduce((p, c) => p + c.ahuzgvia, 0) / monthData.length) ? monthData.reduce((p, c) => p + c.ahuzgvia, 0) / monthData.length : 0,
					cm: '1',
					agentname: '',
					agentid: '',
				};
			});
		}),
	);

	allAgentKabala$ = new BehaviorSubject<ReceiptForGvia[]>([]);
	kabalaDisplay$ = combineLatest([this.allAgentKabala$, this.selectedMonthKabala$, this.selectedAgent$, this.selectedKabalaNumber$]).pipe(
		map(([allKabala, selectedMonth, selectedAgent, selectedKabalaNumber]) => {
			if (!selectedMonth || !selectedAgent?.id) return [];
			this._loading.changeLoadingStatuses({ isLoading: true });

			if (selectedKabalaNumber) {
				const newKabala = allKabala.filter(kabala => kabala.Docnumber === selectedKabalaNumber && kabala.orgagent === selectedAgent.id);
				this._loading.changeLoadingStatuses({ isLoading: false });
				return newKabala;
			}

			const newKabala = Object.values(
				allKabala.reduce((prev, curr) => {
					prev[curr.Docnumber + curr.ClientID + curr.tftal] = curr;
					return prev;
				}, {} as Record<string, ReceiptForGvia>),
			).filter(
				kabala =>
					new Date(translateDateForWebISO(kabala.DocDate)).getMonth() + 1 === selectedMonth &&
					new Date(translateDateForWebISO(kabala.DocDate)).getFullYear() === this.currentYear &&
					kabala.orgagent === selectedAgent.id,
			);
			this._loading.changeLoadingStatuses({ isLoading: false });
			return newKabala;
		}),
		shareReplay(1),
	);

	yaadGviaDisplay$: Observable<YaadGvia[]> = combineLatest([this.currentPage$, this.allYaadGvia$, this.selectedAgent$, this.selectedYear$]).pipe(
		switchMap(([currentPage, allYaad, selectedAgent, selectedYear]) => {
			return of(allYaad.filter(gvia => gvia.yy === this.currentYear)).pipe(
				map(arr => {
					if (!arr.length) return arr;
					switch (currentPage) {
						case 'First':
							// return new Array(2).fill(0).map((_, i) => {
							return new Array(1).fill(0).map((_, i) => {
								const year = this.currentYear - i;
								const tothov = arr.filter(gvia => gvia.yy === year && gvia.mm <= this.currentMonth).reduce((p, c) => p + +c.tothov, 0);
								const gviathismonth = arr.filter(gvia => gvia.yy === year && gvia.mm <= this.currentMonth).reduce((p, c) => p + c.gviathismonth, 0);
								return {
									yy: year,
									mm: 0,
									tothov,
									gviathismonth,
									ahuzgvia: isFinite(gviathismonth / tothov) ? (gviathismonth / tothov) * 100 : 0,
									cm: '1',
									agentname: '',
									agentid: '',
								};
							});
						case 'Second':
							const currentYearData = arr.filter(gvia => gvia.yy === selectedYear && gvia.mm === this.currentMonth);
							const groupedByAgent = currentYearData.reduce((grouped, gvia) => {
								(grouped[gvia.agentid] = grouped[gvia.agentid] || []).push(gvia);
								return grouped;
							}, {} as Record<string, YaadGvia[]>);

							return Object.entries(groupedByAgent).map(([agentId, agentData]) => {
								const tothov = agentData.reduce((p, c) => p + +c.tothov, 0);
								const gviathismonth = agentData.reduce((p, c) => p + c.gviathismonth, 0);
								return {
									yy: this.currentYear,
									mm: 0,
									tothov,
									gviathismonth,
									ahuzgvia: isFinite(gviathismonth / tothov) ? (gviathismonth / tothov) * 100 : 0,
									cm: '1',
									agentname: agentData[0].agentname,
									agentid: agentId,
								};
							});

						case 'Third':
							const relevantYearAndAgentData = arr.filter(gvia => gvia.yy === selectedYear && gvia.agentid === selectedAgent.id);
							return relevantYearAndAgentData.map(gvia => {
								const tothov = +gvia.tothov;
								const gviathismonth = gvia.gviathismonth;
								return {
									yy: 0,
									mm: gvia.mm,
									tothov,
									gviathismonth,
									ahuzgvia: isFinite(gviathismonth / tothov) ? (gviathismonth / tothov) * 100 : 0,
									cm: '1',
									agentname: selectedAgent.name,
									agentid: selectedAgent.id,
								};
							});
						case 'Fourth':
						case 'Fifth':
							// have .length > 0 for ngIf with dummy data
							return [
								{
									yy: 0,
									mm: 0,
									tothov: 0,
									gviathismonth: 0,
									ahuzgvia: 0,
									cm: '1',
									agentname: '',
									agentid: '',
								},
							];
						default:
							return [];
					}
				}),
			);
		}),
		shareReplay(1),
	);
	totals = {
		tothov$: this.yaadGviaDisplay$.pipe(map(arr => arr.reduce((p, c) => p + +c.tothov, 0))),
		gviathismonth$: this.yaadGviaDisplay$.pipe(map(arr => arr.reduce((p, c) => p + +c.gviathismonth, 0))),
		ahuzgvia$: this.yaadGviaDisplay$.pipe(map(arr => arr.reduce((p, c) => p + +c.ahuzgvia, 0) / arr.length)),
	};

	@ViewChild('secondTable') secondTable: ElementRef<HTMLTableElement>;

	async ngOnInit() {
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
			return;
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this._ss.CustomerInformation.HasMultipleCompanies !== '0') {
			this.HasMultipleCompanys = Number(this._ss.CustomerInformation.HasMultipleCompanies) + 1;
		}

		this.form = this._fb.group({
			ClientSearch: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (this._ss.CustomerInformation.Submanagers !== '1' || this.isAgent) {
			this.getFirstPage();
			return;
		}

		if (!this._ss.agentsOfSubmanager.length) {
			this._ss.getAdminAgents().subscribe({
				next: res => {
					if (typeof res == 'string') {
						alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
						return;
					}

					this.agentsToGet = res.recordset.map(obj => obj.Agent);
					this.agentsToGet.push(this._ss.AgentIdConnected);

					this._ss.agentsOfSubmanager = this.agentsToGet;

					this.getFirstPage();
				},
				error: err => {
					alert('שגיאת שרת');
				},
			});
		} else {
			this.agentsToGet = this._ss.agentsOfSubmanager;
			this.getFirstPage();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getFirstPage() {
		// if (environment.development) {
		// 	let allYaadGvia = yaadmock.recordset;
		// 	if (this.agentsToGet?.length) {
		// 		allYaadGvia = allYaadGvia.filter(gvia => this.agentsToGet.includes(gvia.agentid));
		// 	}

		// 	if (this.isAgent) {
		// 		allYaadGvia = allYaadGvia.filter(gvia => gvia.agentid === this._ss.AgentIdConnected);
		// 	}
		// 	this.allYaadGvia$.next(allYaadGvia);
		// 	this.loading = false;
		// 	return;
		// }

		this._ss.getYaadGvia({ onlyAgent: this.isAgent }).subscribe({
			next: res => {
				if (typeof res === 'string') {
					alert('ישנה תקלת תקשורת, נסו שנית מאוחר יותר');
					history.back();
					return;
				}

				let allYaadGvia = res.recordset;
				if (this.agentsToGet?.length) {
					allYaadGvia = allYaadGvia.filter(gvia => this.agentsToGet.includes(gvia.agentid));
				}
				if (this.isAgent) {
					this.selectedAgent$.next({ id: this._ss.AgentIdConnected, name: this._ss.UserNameConnected });
					this.selectedYear$.next(this.currentYear);
					this.currentPage$.next('Third');
				}
				if (this.isAdmin) {
					this.selectedYear$.next(this.currentYear);
					this.currentPage$.next('Second');
				}
				this.allYaadGvia$.next(allYaadGvia);
				this.loading = false;
			},
			error: err => {
				this.loading = false;
				alert('ישנה תקלת תקשורת, נסו שנית מאוחר יותר');
				history.back();
			},
		});
	}

	moveToSecondPageAdmin(year: number) {
		this.selectedYear$.next(year);
		if (this.isAgent) {
			this.selectedAgent$.next({ id: this._ss.AgentIdConnected, name: this._ss.UserNameConnected });
			this.currentPage$.next('Third');
			return;
		}
		this.currentPage$.next('Second');
	}

	moveToThirdPageAdmin({ id, name }: { id: string; name: string }) {
		return;
		this.currentPage$.next('Third');
		this.selectedAgent$.next({ id, name });
	}

	moveToFifthPage(kabalaDocNum: string) {
		this._loading.changeLoadingStatuses({ isLoading: true });
		this.selectedKabalaNumber$.next(kabalaDocNum);
		this.currentPage$.next('Fifth');
	}

	async moveToFourthPage(month: number) {
		this._loading.changeLoadingStatuses({ isLoading: true });
		if (!this.allAgentKabala$.value.length) {
			try {
				await new Promise<void>((resolve, reject) => {
					this._ss.getAllVKabalaReceiptsGviaReport().subscribe({
						next: res => {
							if (typeof res === 'string') {
								reject();
								return;
							}
							this.allAgentKabala$.next(res.recordset);
							resolve();
						},
						error: err => {
							reject();
						},
					});
				});
			} catch (error) {
				alert('ישנה תקלת תקשורת, נסו שנית מאוחר יותר');
				return;
			}
		}
		this.selectedMonthKabala$.next(month);
		this.currentPage$.next('Fourth');
	}

	handleYearlyViewToggle(e: Event, year: number) {
		e.stopPropagation();
		if (this.expandedYearlyViewSelectedYear$.value) {
			this.expandedYearlyViewSelectedYear$.next(0);
			return;
		}
		this.expandedYearlyViewSelectedYear$.next(year);
	}

	handleBack() {
		switch (this.currentPage$.value) {
			case 'First':
				history.back();
				break;
			case 'Second':
				this.currentPage$.next('First');
				this.selectedYear$.next(0);
				this.selectedAgent$.next({ id: '', name: '' });
				this.allAgentKabala$.next([]);
				if (this.isAdmin) {
					history.back();
				}
				break;
			case 'Third':
				if (this.isAgent) {
					history.back();
					return;
				}
				this.currentPage$.next('Second');
				this.selectedAgent$.next({ id: '', name: '' });
				this.selectedMonthKabala$.next(0);
				this.allAgentKabala$.next([]);
				break;
			case 'Fourth':
				this.currentPage$.next('Third');
				this.selectedMonthKabala$.next(0);
				break;
			case 'Fifth':
				this.currentPage$.next('Fourth');
				this.selectedKabalaNumber$.next('');
				break;

			default:
				break;
		}
	}

	ngClassColorForRow(row: YaadGvia) {
		const ahuzgvia = row.ahuzgvia;
		let className = null;
		if (!ahuzgvia || ahuzgvia < 50) className = 'invalid-background';
		if (ahuzgvia >= 50 && ahuzgvia <= 100) className = 'warning-background';
		if (ahuzgvia > 100) className = 'valid-background';
		return { [className]: true };
	}

	public SearchClear() {
		// this.AgentsSalesAllFourth = [];
		// for (let x = 0; x < this.AllClientsSearch.length; x++) {
		// 	if (this.AgentsSalesAllFourth.length < 300) {
		// 		this.AgentsSalesAllFourth.push(this.AllClientsSearch[x]);
		// 	}
		// }
		// this.form.reset();
	}

	public SearchClient() {
		// const pattern = new RegExp(this.form.controls['ClientSearch'].value.replace(/[\/*\+\-\?,]/g, ''), 'i');
		// this.AgentsSalesAllFourth = this.AllClientsSearch.filter(client => client.Client_Name.toString().match(pattern)?.length);
	}

	// translateForSort(value: typeof valuesObject[typeof screen][number], screen: keyof typeof valuesObject) {
	// 	let i: number, arrayToSort: any[];

	// 	if (screen === 'Second') {
	// 		arrayToSort = this.hasAgentsGoals ? this.AgentsSalesAllSecondWithGoals$.value : this.AgentsSalesAllSecond;
	// 	}

	// 	if (screen === 'Third') {
	// 		arrayToSort = this.AgentsSalesAllThird;
	// 	}

	// 	if (screen === 'Fourth') {
	// 		arrayToSort = this.AgentsSalesAllFourth;
	// 	}

	// 	if (screen === 'Fifth') {
	// 		arrayToSort = this.AgentsSalesAllFifth;
	// 	}
	// 	if (screen === 'Sixth') {
	// 		arrayToSort = this.OneTeudaInfo;
	// 	}

	// 	i = valuesObject[screen].findIndex(arr => arr === value);

	// 	if (i === undefined || i === -1) {
	// 		console.log('i should never show');
	// 		return;
	// 	}

	// 	this._util.sortWithIndex(value, i, arrayToSort);
	// }
}
