import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject, map } from 'rxjs';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { Alert, MsgType, MsgTypeOptions } from '../Interfaces/alerts';
import { IsAlertActivePipe } from '../Pipes/is-alert-active.pipe';
import { StoreService } from './store.service';
import { UserService } from './user.service';
import { UtilService } from './util.service';

//if localstorage is empty
const emptyArrayCompressed = compress(JSON.stringify(new Array()));

const dummyAlert: Alert = { Active: '0', Company: 1, CreateDate: '', Extra1: '', Extra2: '', ID: 1, Msg: '', MsgType: '1000', Name: '', JSON: {} };

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	__Alerts: Alert[] = [];
	alerts$ = new BehaviorSubject<Alert[]>([]);

	hasAgentsAlerts = false;

	hasAlreadyAlertedOnceToday = (localStorage['alerted'] && JSON.parse(localStorage['alerted'])) || false;

	isAlertActive = IsAlertActivePipe.prototype.transform;

	get Alerts() {
		return this.__Alerts;
	}

	set Alerts(value: Alert[]) {
		this.__Alerts = value;
		this.alerts$.next(value);
	}

	agentAlerts$ = this.alerts$.pipe(
		map(
			alerts =>
				alerts.filter(
					alert =>
						alert.MsgType === '1000' && alert.JSON.agents?.includes(this._ss.AgentIdConnected) && this.isAlertActive(alert.Active, alert.JSON.date?.start || '0', alert.JSON.date?.end || '0'),
				).length,
		),
	);

	constructor(private _ss: StoreService, private dialog: MatDialog, private _user: UserService, private zone: NgZone) {
		this.Alerts = JSON.parse(decompress(localStorage.getItem('Alerts') || emptyArrayCompressed));
		this.refreshAlertsParams();
	}

	handleAlertLogic(type: typeof MsgType[keyof typeof MsgType]['msgType']) {
		if (!this.Alerts?.length) return;

		const alerts = this.handleFilterRelevantAlerts(type);

		if (!alerts.length) return;
		this.handleOpenAlertDialog(alerts, type);
	}

	handleFilterRelevantAlerts(filterBy: typeof MsgType[keyof typeof MsgType]['msgType']) {
		if (this.hasAlreadyAlertedOnceToday && MsgTypeOptions[filterBy].onlyOnce) return [];

		let filtered = this.Alerts.filter(al => al.MsgType === filterBy && this.isAlertActive(al.Active, al.JSON.date?.start, al.JSON.date?.end));

		if (MsgTypeOptions[filterBy].clients && this._ss.OneClientInfo?.ClientId) {
			const { ClientId } = this._ss.OneClientInfo;

			filtered = filtered.filter(al => {
				if (!al.JSON.clients?.length) return true;

				return al.JSON.clients.includes(ClientId);
			});
		}

		if (MsgTypeOptions[filterBy].agents && this._ss.AgentIdConnected) {
			const agentid = this._ss.AgentIdConnected;

			filtered = filtered.filter(al => {
				if (!al.JSON.agents?.length) return true;

				return al.JSON.agents.includes(agentid);
			});
		}

		if (MsgTypeOptions[filterBy].timeSensitive) {
			const onlyFromThisHourAndUp = filtered.filter(
				alert =>
					alert.JSON.time &&
					(+alert.JSON.time.slice(0, 2) < new Date().getHours() || (+alert.JSON.time.slice(3, 5) <= new Date().getMinutes() && +alert.JSON.time.slice(0, 2) === new Date().getHours())),
			);
			if (!onlyFromThisHourAndUp.length) return [];
			filtered = onlyFromThisHourAndUp;
		}

		return filtered;
	}

	handleOpenAlertDialog(alerts: Alert[], filterBy: typeof MsgType[keyof typeof MsgType]['msgType']) {
		this.dialog.open(AlertDialogComponent, {
			width: '250px',
			data: { alerts },
		});

		if (MsgTypeOptions[filterBy].onlyOnce) {
			localStorage.setItem('alerted', 'true');
			this.hasAlreadyAlertedOnceToday = true;
			localStorage.setItem('Alerts', compress(JSON.stringify(this.Alerts)));
		}
	}

	refreshAlerts() {
		this._ss.getAlerts().subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') return null;

				this.Alerts = res.recordset.map(alert => ({ ...alert, JSON: JSON.parse(alert.JSON) }));
			},
		});
	}

	customAlert(alert: Alert[] | string | string[]) {
		this.handleCustomAlert(alert);
	}

	customAlertConfirmWithoutCancel(alert: Alert[] | string | string[]) {
		this.handleCustomAlert(alert, { confirmActionWithoutCancel: true });
	}

	customConfirmAlert(alert: Alert[] | string | string[]) {
		return this.handleCustomAlert(alert, { confirmAction: true });
	}

	private handleCustomAlert(alert: Alert[] | string | string[], options?: { confirmAction?: boolean; confirmActionWithoutCancel?: boolean }) {
		if (typeof alert === 'string') {
			alert = [{ ...dummyAlert, Msg: alert }] as Alert[];
		}

		if (Array.isArray(alert) && typeof alert[0] === 'string') {
			alert = alert.map(msg => ({ ...dummyAlert, Msg: msg }));
		}

		const dialogRef = this.dialog.open(AlertDialogComponent, {
			width: '250px',
			data: { alerts: alert, ...options },
		});

		if (!options?.confirmAction) return null;

		return new Promise<boolean>(resolve => {
			dialogRef.afterClosed().subscribe(result => {
				resolve(result);
			});
		});
	}

	refreshAlertsParams() {
		this.hasAgentsAlerts = this._ss.CustomerInformation?.AgentPersonalAlert === '1';
		this.hasAgentsAlerts &&= !this._user.isAdmin$.value;
	}
}
