import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'displayAmountLeftToPay',
	pure: true,
})
export class DisplayAmountLeftToPayPipe implements PipeTransform {
	transform(totalLeftToPay: number, amountFormValue: number, isEdittingReceipt: boolean, originalAmount: number): number {
		const toAdd = isEdittingReceipt ? originalAmount : 0;

		return totalLeftToPay + toAdd - amountFormValue;
	}
}
