<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
					</div>
					<div class="header__title col">{{ isAdmin ? 'ניהול קבלות - ' + _ss.CustomerInformation.CustName : 'ביצועים קבלות' }}</div>
					<div class="header__burger col image" (click)="Restart()" style="text-decoration: underline">
						<b> איפוס </b>
					</div>
				</div>
			</div>
			<div class="report-table__row">
				<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
					<form [formGroup]="form">
						<input
							class="header__search-input"
							type="text"
							[placeholder]="windowWidth > 768 ? 'חפש לפי לקוח/תעודה/ERP' : 'חפש'"
							formControlName="Search"
							id="search-Input"
							style="text-align: center; direction: rtl"
						/>
					</form>
					<button class="header__clear-icon" (click)="SearchClear()"></button>
				</div>
			</div>

			<h2 style="text-align: center" *ngIf="LoadingBack === true">טוען אנא המתן</h2>
		</header>

		<!-- markup for see teuda after teuda num press-->
		<div class="popup" *ngIf="isReceiptPopupOpen" style="z-index: 555">
			<div class="overlay"></div>
			<div class="product-detail__title flex" style="flex-wrap: wrap; justify-content: space-around">
				<p *ngIf="selectedReceipt[0].DocNewNum">קבלה מספר: {{ selectedReceipt[0].DocNewNum }}</p>
				<p>סה"כ: {{ selectedReceipt[0].DocTotal }}</p>
			</div>
			<div class="product-detail__title remarks" *ngIf="selectedReceipt[0].DocRemark"><span style="font-weight: 700">הערת קבלה:</span> {{ selectedReceipt[0].DocRemark }}</div>
			<div class="product-detail-popup popup__content">
				<div class="popup__close" (click)="isReceiptPopupOpen = false; document.body.classList.remove('no-scroll')" style="width: 30px; height: 30px; background-color: black"></div>
				<div class="product-detail__wrapper">
					<table>
						<tr>
							<th #receiptTableNames>סוג תשלום</th>
							<th>סכום</th>
							<th>פרעון</th>
							<th>מס צ'ק</th>
							<th>חשבון</th>
							<th>סניף</th>
							<th>בנק</th>
							<th>הערה</th>
						</tr>
						<tr *ngFor="let pte of selectedReceipt">
							<td>{{ pte.Sug | receiptType }}</td>
							<td>{{ pte.PayTotal }}</td>
							<td>{{ pte.PayDate | date : 'dd/MM/yy' }}</td>
							<td>{{ pte.CheckNum}}</td>
							<td>{{ pte.AccountNum }}</td>
							<td>{{ pte.Snif }}</td>
							<td>{{ pte.Bank }}</td>
							<td>{{ pte.PayRemark }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<!--  dashboard-tables markup  -->
		<section class="dashboard-tables" style="margin-top: 30px">
			<div class="content">
				<div class="dashboard-tables__wrapper">
					<table style="font-size: 17px; position: sticky; top: 130px; z-index: 999" [ngStyle]="{ 'z-index': isReceiptPopupOpen ? '99' : '999' }">
						<tr>
							<th style="background-color: white; width: 150px"><b>סה"כ</b></th>
							<!-- <th style="background-color: white; width: 150px"><b>סומנו</b></th> -->
							<th style="background-color: white; width: 150px"><b>שורות</b></th>
							<th style="background-color: white; width: 150px"><b>סינון מתקדם</b></th>
						</tr>

						<tr>
							<td>
								<b>{{ TotalArray | number : '1.0-2' : 'en-US' }}</b>
							</td>
							<td>
								<b>{{ receipts.length }}</b>
							</td>
							<td class="justify-align-center">
								<div class="report-table__button" (click)="showFilterPopup = true"></div>
							</td>
						</tr>
					</table>

					<br />
					<br />
					<br />
					<br />
					<div class="dashboard-tables__row dashboard-tables__row_scroll">
						<b>
							<table class="report-table__table" cellspacing="0">
								<thead class="report-table__table-head">
									<tr>
										<th class="report-table__table-head-name">תעודה</th>
										<!-- <th class="report-table__table-head-quantity" *ngIf="_ss.CustomerInformation.EditTeuda === '1' && Admin === 'true'">ערוך קבלה</th> -->
										<th class="report-table__table-head-code">שם לקוח</th>
										<th class="report-table__table-head-code">מפתח</th>
										<th class="report-table__table-head-quantity" *ngIf="isAdmin">סוכן</th>
										<th class="report-table__table-head-quantity">סכום</th>
										<th class="report-table__table-head-quantity">תאריך</th>
										<th class="report-table__table-head-quantity">תשלום</th>
										<th class="report-table__table-head-quantity">סטטוס</th>
										<th class="report-table__table-head-quantity" *ngIf="HasMultipleCompanys === '2'">חברה</th>
										<th class="report-table__table-head-quantity">מספר קבלה</th>

										<ng-container *ngIf="isAdmin && _receipts.adminCanDeleteAndEditReceipt">
											<th class="report-table__table-head-quantity">אשר לביצוע</th>
											<th class="report-table__table-head-quantity">מחק</th>
										</ng-container>
									</tr>
								</thead>
								<tbody class="report-table__table-body">
									<tr
										*ngFor="let r of receipts; let index = index"
										[ngStyle]="{ 'box-shadow': r[0].TeudaStatus === 2 || r[0].TeudaStatus === 4 || r[0].TeudaStatus === 3 ? 'rgb(233 115 115) 0px 0px 9px 3px inset' : '' }"
									>
										<td
											class="report-table__table-body-name-clickable"
											[ngStyle]="{ color: r[0].DocNewNum ? 'var(--valid-color)' : 'red' }"
											(click)="document.body.classList.add('no-scroll'); seeTeuda(index)"
										>
											{{ r[0].DocNewNum ? r[0].DocNewNum : r[0].DocNum.toString().slice(7, 13) }}
										</td>

										<td class="report-table__table-body-name clickable" (click)="openClientCardPopup(r)">{{ r[0].Client_Name }}</td>
										<td class="report-table__table-body-name">{{ r[0].ClientID }}</td>
										<td class="report-table__table-body-quantity" *ngIf="isAdmin">{{ r[0].AgentID }}</td>

										<!-- ! Do not change number to currency. Yaron wants to keep it as number. 14/11/22 -->
										<td class="report-table__table-body-quantity" dir="ltr">{{ r[0].DocTotal | number : '1.0-2' }}</td>

										<td class="report-table__table-body-quantity">
											{{ r[0].CreateDate | date : 'dd/MM/yyyy' }}
										</td>
										<td class="report-table__table-body-quantity">
											{{ r.cheque ? "צ'ק" : 'מזומן' }}
										</td>

										<td class="report-table__table-body-quantity">{{ r[0].TeudaStatus | getTeudaStatus }}</td>

										<td class="report-table__table-body-quantity" *ngIf="HasMultipleCompanys === '2'">{{ r[0].Company_Name }}</td>

										<td class="report-table__table-body-quantity" [ngStyle]="{ color: r[0].TeudaStatus === 1 ? '#36c836' : 'initial' }">
											{{ r[0].ErpDocNum  || r[0].DocNewNum }}
										</td>

										<!-- <td class="report-table__table-body-quantity" *ngIf="SeeTrucks !== '1'">
											<input type="checkbox" style="height: 20px; width: 20px" (change)="OrderChecked(r[0].TeudaNum, r[0].TeudaTotalSum)" />
										</td> -->

										<ng-container *ngIf="isAdmin && _receipts.adminCanDeleteAndEditReceipt">
											<td class="report-table__table-body-quantity">
												<div class="dashboard-tables__table-button" style="color: #38d88b" *ngIf="!LoaingConfirm && r[0].TeudaStatus === 2" (click)="AdminConfirmOrder(r[0].DocNum)">
													אישור
												</div>
											</td>
											<td class="report-table__table-body-quantity">
												<div class="dashboard-tables__table-button" style="color: red" *ngIf="!LoaingConfirm && r[0].TeudaStatus === 2" (click)="adminDeleteReceipt(r[0].DocNum)">מחק</div>
											</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
						</b>
					</div>
				</div>
			</div>
		</section>
	</div>

	<!-- end main menu markup -->
</div>

<!-- start sort popup -->
<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="popup__close" (click)="showFilterPopup = false" style="top: 2em; z-index: 1002; box-shadow: inset 0px 0px 2px, 0 0 3px 2px" [style.left]="windowWidth > 768 ? '43%' : '2em'"></div>
	<div class="report-table-popup report-table-popup_sort popup__content" style="overflow-y: scroll">
		<div class="report-table-popup__header">
			<div class="report-table-popup__header-title">סינון מתקדם</div>
		</div>
		<div class="report-table-popup__wrapper">
			<div class="report-table-popup__content">
				<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding"></div>
				<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
					<div class="report-table-popup__box">
						<span class="report-table-popup__content-title">בחירת תקופה</span>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">מתאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="FromDate" (change)="handleDateChange('from')" />
								</form>
							</label>
						</div>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">עד תאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="ToDate" (change)="handleDateChange('to')" />
								</form>
							</label>
						</div>
					</div>

					<div class="report-table-popup__box">
						<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
							<span class="report-table-popup__content-title"> סוכנים</span>
						</div>
						<div style="float: right">
							<form [formGroup]="form" style="width: 150px">
								<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px">
									<mat-select formControlName="Agent" multiple>
										<mat-option *ngFor="let a of AgentArray" [value]="a">{{ a }}</mat-option>
									</mat-select>
								</mat-form-field>
							</form>
						</div>
					</div>
					<div class="report-table-popup__box">
						<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
							<span class="report-table-popup__content-title">סטטוס</span>
						</div>
						<div style="float: right">
							<form [formGroup]="form" style="width: 150px">
								<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px">
									<mat-select formControlName="Status" multiple>
										<mat-option *ngFor="let s of statusArray" [value]="s">{{ s | getTeudaStatus }}</mat-option>
									</mat-select>
								</mat-form-field>
							</form>
						</div>
					</div>
				</div>
				<div class="report-table-popup__button-wrapper">
					<button class="report-table-popup__button" [attr.disabled]="dateInvalid ? true : null" [ngClass]="{ darken: dateInvalid }" (click)="handleAdvancedSearch()">הצג תוצאות</button>
				</div>
			</div>
		</div>
	</div>
</div>
