import { Component, inject, OnInit } from '@angular/core';
import { decompress } from 'lz-string';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-base',
	template: '',
	// templateUrl: './base.component.html',
	// styleUrls: ['./base.component.scss']
})
export class BaseComponent {
	constructor() {
		this.checkAndFillCustomerInformation();
		this.populateHideReportBlockedPaths();
	}

	_ss = inject(StoreService);

	checkAndFillCustomerInformation() {
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
	}

	populateHideReportBlockedPaths() {
		this._ss.blockPaths = {};
		if (!this._ss.CustomerInformation) return;
		const { HideReport } = this._ss.CustomerInformation;

		if (HideReport) {
			const paths = HideReport.split(',');

			if (paths.length) {
				for (const path of paths) {
					if (this._ss.CustomerInformation.Admin !== 'true') {
						this._ss.blockPaths[path] = true;
					}
				}
			}
		}
	}
}
