import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../Interfaces/product';

const defaultSingle = 'יחידות';
const alternateSingle = 'משקל';
const defaultPack = 'אריזות';

@Pipe({
	name: 'weightTypeText',
})
export class WeightTypeTextPipe implements PipeTransform {
	transform(product: Product, quantity: 'single' | 'pack'): string {
		if (!product) return '';
		if (quantity === 'single') {
			if (!('Type' in product)) return defaultSingle;
			if ('weightMode' in product) {
				if (product.weightMode === 'singles') return defaultSingle;
				if (product.weightMode === 'weight') return alternateSingle;
			}
			const type = product.Type;
			if (type === 2) {
				return defaultSingle;
			}

			if (!product.Type) {
				return alternateSingle;
			}

			//fallback
			return defaultSingle;
		}

		if (quantity === 'pack') {
			if (!('Type' in product)) return defaultPack;

			const type = product.Type;

			if (type === 3) {
				return 'קופסאות';
			}

			if (!product.Type || type === 4) {
				return defaultPack;
			}

			//fallback
			return defaultPack;
		}

		return '';
	}
}
