import { Pipe, PipeTransform } from '@angular/core';

// const LOCALE = navigator.languages ? navigator.languages[0] : navigator.language || (navigator as any).userLanguage;
const LOCALE = 'he-IL';

type Formats = 'short' | 'medium' | 'shortDate' | 'mediumTime' | 'mediumTimeFixed';

const predefinedFormats: Record<Formats, Intl.DateTimeFormatOptions> = {
	short: {
		timeStyle: 'short',
		dateStyle: 'short',
	},
	medium: {
		timeStyle: 'medium',
		dateStyle: 'medium',
	},
	shortDate: {
		dateStyle: 'short',
	},
	mediumTime: {
		timeStyle: 'medium',
	},
	mediumTimeFixed: {
		timeStyle: 'medium',
		timeZone: 'Asia/Jerusalem',
	},
};

@Pipe({
	name: 'appDate',
})
export class DatePipe implements PipeTransform {
	transform(value: Parameters<Intl.DateTimeFormat['format']>[0], format: Formats = 'short') {
		if (!value) return '';
		value = new Date(value);
		return new Intl.DateTimeFormat(LOCALE, { timeZone: 'UTC', ...predefinedFormats[format] }).format(value);
	}
}
