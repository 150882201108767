<label class="toggle" tabindex="1" role="button" [ngClass]="{ 'position-initial': shouldChangeToPositionInitial }">
	<input type="checkbox" #btn (click)="_ss.openMenuAndAnimateButton(btn)" aria-expanded="false" />
	<div>
		<div>
			<span></span>
			<span></span>
		</div>
		<svg>
			<use xlink:href="#path" />
		</svg>
		<svg>
			<use xlink:href="#path" />
		</svg>
	</div>
</label>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
	<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="path">
		<path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22"></path>
	</symbol>
</svg>
