import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { icon, IconOptions, LatLngTuple, Map, map, Marker, tileLayer } from 'leaflet';
import { BehaviorSubject, Subject, delay, takeUntil, combineLatest } from 'rxjs';
import { TruckDistributionMainSQL } from 'src/app/Interfaces/truck-distribution';
import { MarkerInfo, MarkerService, TeudaMarkerPopupData } from 'src/app/services/marker.service';
import { UtilService } from 'src/app/services/util.service';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const defaultIconOptions: IconOptions = {
	iconRetinaUrl,
	iconUrl,
	shadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41],
	className: '',
};
export const iconDefault = icon(defaultIconOptions);
Marker.prototype.options.icon = iconDefault;

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {
	constructor(private _marker: MarkerService, private _util: UtilService) {}

	@Input() coordinates: MarkerInfo<TeudaMarkerPopupData>[] = [];
	@Input() coordinatesDistribution: MarkerInfo<TruckDistributionMainSQL>[];
	@Input() shouldRecalculate: BehaviorSubject<boolean>;
	@Output() isFullscreenEvent = new EventEmitter<boolean>();

	private map: Map;
	private isFullscreen = new BehaviorSubject(false);

	private destroy$ = new Subject<void>();

	private readonly CENTER: LatLngTuple = [32.0069513, 34.7630199];

	private initMap(): void {
		this.map = map('map');

		// this.handleFitBounds();

		const tiles = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 18,
			minZoom: 3,
		});

		tiles.addTo(this.map);

		// this.isFullscreen.pipe(delay(50), takeUntil(this.destroy$)).subscribe(() => {
		// 	this.map.invalidateSize();
		// });

		this.shouldRecalculate.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this._marker.clearMap();

			setTimeout(() => {
				this.handleSetMarkers();
				this.handleDrawPaths();
				this.handleFitBounds();
			}, 0);
		});
	}

	ngAfterViewInit(): void {
		this.initMap();
		// this.handleSetMarkers();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleSetMarkers() {
		if (!this.coordinates.length && !this.coordinatesDistribution.length) {
			return;
		}
		if (this.coordinates.length) {
			this._marker.addCoordinates(this.map, this.coordinates);
		}
		if (this.coordinatesDistribution.length) {
			this._marker.addCoordinatesTruckDistribution(this.map, this.coordinatesDistribution);
		}
	}

	handleDrawPaths() {
		if (!this.coordinates.length && !this.coordinatesDistribution.length) {
			return;
		}
		// if (this.coordinates.length) {
		// 	this._marker.drawPath(this.map, this.coordinates);
		// }
		if (this.coordinatesDistribution.length) {
			this._marker.drawPathDistribution(this.map, this.coordinatesDistribution);
		}
	}

	handleFitBounds() {
		if (this.coordinates.length) {
			this.map.fitBounds(this.coordinates.map(coord => [+coord.latitude, +coord.longitude] as LatLngTuple));
		}
		if (this.coordinatesDistribution.length) {
			this.map.fitBounds(this.coordinatesDistribution.map(coord => [+coord.latitude, +coord.longitude] as LatLngTuple));
		}

		if (!this._marker.markers.length) {
			this.map.setView(this.CENTER, 9);
		}
	}

	handleFullscreenButtonPress() {
		this.isFullscreen.next(!this.isFullscreen.value);
		this.isFullscreenEvent.emit(this.isFullscreen.value);
	}
}

export function generateNewIcon(options: Partial<IconOptions> = {}) {
	return icon({ ...defaultIconOptions, ...options });
}
