import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Input, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { compress, decompress } from 'lz-string';
import { Subscription, Observable, fromEvent, catchError, throwError, takeUntil, BehaviorSubject, Subject, map, startWith, combineLatest, shareReplay, merge, take } from 'rxjs';
import { AllGroupDiscounts } from 'src/app/Interfaces/allgroupdiscounts';
import { MatRadioButton } from '@angular/material/radio';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Platform, } from '@angular/cdk/platform';
import { AllOrder, AllOrderOverwrites, OrderState, Product, Product2 } from 'src/app/Interfaces/all-products';
import { AllMGroup, AllProducts, ConcentratedProducts, ItemPacks, MustIncludeProducts, MustIncludeProductsParsed, StockOriginal } from 'src/app/Interfaces/SQL-morning-responses';
import { Special, SpecialInCart, SpecialInCartObj, SpecialObjectHash, specialsTypesEnum } from 'src/app/Interfaces/special';
import ChangedDate from 'src/app/Helpers/changedDate';
import { SpecialInfoDialog } from '../specials/special-dialog.component';
import { CustomMap, Password, UtilService, EmptyPromise } from 'src/app/services/util.service';
import { AdminTypes } from 'src/app/Interfaces/users';
import { ToNormalNumberPipe } from 'src/app/Pipes/to-normal-number.pipe';
import { MsgType } from 'src/app/Interfaces/alerts';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment';
import { ProductInfoDialogComponent } from './product-info-dialog.component';
import { TextService } from 'src/app/services/text.service';
import { ProductCommentSelect } from 'src/app/Interfaces/SQL-reports-responses';
import { SearchOptions, SearchPreferenceChoice } from '../search-preference/search-preference.component';
import { CookieDict, CookieService } from 'ngx-cookie';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import {
	arithmeticWithFloatingNumber,
	normalizeNumber,
	normalizeNumberToFormattedString,
	normalizeNumberToMaxThreeDecimal,
	normalizeNumberToMaxTwoDecimal,
	translateDateForSQLServer,
} from 'src/app/Utility/functions';
import { TABLET_RESOLUTION_CUTOFF } from 'src/app/services/device-info.service';
import { StorageService } from 'src/app/services/storage.service';
import { B2bService } from 'src/app/services/b2b.service';
import { DoctypeService } from 'src/app/services/doctype.service';
import { RECLAMATION_DOCTYPE, RECLAMATION_RALLYANCE_DOCTYPE, SPECIAL_REFUND_DOCTYPES } from 'src/app/Utility/constants-doctype';
import { MAXIMUM_PACK_QUANTITY, MAXIMUM_QUANTITY, MAX_PRODUCT_PRICE, MAX_DISCOUNT, MAX_TOTAL_PRICE } from 'src/app/Utility/constants-products';
import { RallyanceReclamationNewReclamationBody, RallyanceReclamationNewReclamationFormValues } from 'src/app/Interfaces/reclamations';
import { MustIncludeProductsService } from 'src/app/services/must-include-products.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
// import { IndexedDBService } from 'src/app/services/indexed-db.service';

type ConcentratedProductsDisplay = { product: Product2; concentrated: ConcentratedProducts };

type GroupSumDisplay = { GroupSum: string; Total: number };

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		private router: Router,
		private _fb: FormBuilder,
		private dialog: MatDialog,
		private _util: UtilService, //  public _idb:IndexedDBService
		private _alert: AlertService,
		public _text: TextService,
		private _cookies: CookieService,
		private _storage: StorageService,
		public _b2b: B2bService,
		private _change: ChangeDetectorRef,
		private _doctypes: DoctypeService,
		private _mustIncludeProducts: MustIncludeProductsService,
	) {}

	form = this._fb.group({
		ProductSearch: ['', Validators.required],
		SectionSearch: ['', Validators.required],
		Yehidot: [<string | null>null, Validators.required],
		Arizot: [<string | null>null, Validators.required],
		ChangePrice: [<string | number | null>'', Validators.required],
		ChangeBasePrice: [<string | number | null>'', Validators.required],
		ChangeDiscount: [<string | number | null>'', Validators.required],
		HearaForProduct: ['', Validators.required],
		Bonus: [<string | number | null>'', Validators.required],
		BonusArizot: ['', Validators.required],
		ExtraSearch: ['', Validators.required],
		NewProductAdd: [<string | number | null>'', Validators.required],
		NewProductAddKamot: [<string | number | null>'', Validators.required],
		NewProductAddArizot: [<string | number | null>'', Validators.required],
		NewProductAddPrice: [<string | number | null>'', Validators.required],
		NewProductAddHeara: ['', Validators.required],
		SearchOptions: [<ReturnType<typeof this.returnSearchOptionInitialSelected> | 'ItemExtra1'>this.returnSearchOptionInitialSelected(), Validators.required],
		NewProductAddPriceAhuz: [<string | number | null>'', Validators.required],
		ItemPackInput: [<string | number | null>'', Validators.required],
	});

	concentratedProductsForm = this._fb.nonNullable.group({
		concentratedProductsSearch: [''],
		concentratedProductsItemSize: [<string[]>[]],
		concentratedProductsItemColor: [<string[]>[]],
		concentratedSingles: [<string>''],
		concentratedPacks: [<string>''],
	});
	AllProductsSearch = [];
	AllProducts = [];
	SearchErrorPop;
	Groups;
	AllOrder = [];
	TotalSumOAllOrder = 0;
	ChoosenSection = 'מוצרים';
	SearchProductInAllProducts = false;
	SaveSectionSearch = [];
	SaveSectionSearchOnlySpecPricessOrderByDate = [];
	GroupsSearch;
	online;
	showMoreDetails = false;
	isLoadingPopupOpen = false;
	ItemExtraOne = this._ss.ItemExtra;
	ItemExtraOneSearch = this._ss.ItemExtra;
	CommentsProduct;
	Mode;
	testpop = [];
	stockSortDescending = true;
	LastBoughtOn = false;
	OnMivza = false;
	showSortPopupSanen = false;
	ItemRxtraSanenArray = [];
	OnlyRespondVAllProducts;
	HasMainGroup;
	HasMahlakotMain = 0;
	shouldDisplayHistoryProductsFirst = false;
	Admin: AdminTypes | null = localStorage.getItem('Admin') as AdminTypes | null;
	isB2BAgent = false;
	isCatalogAgent = false;
	isB2BLeasing = false;
	isB2BAgentNoPricesAndNoParameters = false;
	isB2BAgentAndNoDiscountsAndDates = false;
	ShowProfit;
	AddProductTrueOrFalse;
	SeeImages = 0;
	SeeImagesGeneral = 0;
	DoctypeName;

	logo = MOBILITY_LOGO;

	ShowExtraHearot = false;
	SeeDateOrNumOfTimes = null;
	ShowMainTeudaIcon = true;

	warehouseStock: StockOriginal[] = [];

	dis = 0;

	AllProductsPricess;

	AllProductsInfo;

	FilterMigvanFilter;

	FilterMehironim = [];

	IgnorMinPrice;

	updatingProducts = false;
	updatingSpecials = false;

	allGroupDiscounts: AllGroupDiscounts[] = [];

	groupNamesRecord: Record<string, { GroupName: string; GroupdId: string }> = {};

	itempackRecord: Record<string, ItemPacks[]> = {};
	originalItempackRecord: Record<string, number> = {};

	windowVisibilityObservable$: Observable<Event>;
	windowVisibilitySubscription$: Subscription;

	destroy$ = new Subject<void>();

	AllOrder$;

	groupSort = new FormControl('1');

	performance = this._ss.performance;

	profitNumber = 0;

	timeoutID: number;
	prices$: Subscription;

	departmentsWrapperScrollY = 0;
	@ViewChild('departmentsWrapperScroller1') departmentsWrapperScroller1: ElementRef<HTMLDivElement>;
	@ViewChild('departmentsWrapperScroller2') departmentsWrapperScroller2: ElementRef<HTMLDivElement>;
	@ViewChild('departmentsWrapperScroller3') departmentsWrapperScroller3: ElementRef<HTMLDivElement>;
	@ViewChild('departmentsWrapperScroller4') departmentsWrapperScroller4: ElementRef<HTMLDivElement>;

	// logic comes from CustomerInformation.UnBlockQty
	// this.handleUnBlockQty()
	shouldOverrideAgentProductQuantities = false;

	// logic comes from CustomerInformation.BlockChangingPriceBonusDiscountOrQuantities
	// this.handleBlockInputs()
	shouldDisablePriceAndDiscountInput = false;
	shouldDisableChangingInputs = false;
	shouldDisableChangingClickingFunctions = false;

	isDuplicateOrder = false;
	duplicateOrderTeudaRemark = '';

	allSpecials: Special[] = [];
	allSpecials$ = new BehaviorSubject<Special[]>(this.allSpecials);

	isSpecialsPopupOpen = false;

	productsHash: { [key: string]: any } = {};

	specialsInCart: SpecialInCartObj = {};
	showOnlyTheseSpecials: number[] | null = null;

	totalQuantities = {
		quantity: 0,
		packQuantity: 0,
	};

	month = new Date().getMonth();

	toNormalNumber = new ToNormalNumberPipe();

	yaadObject = { avg: 0, tot: 0, yaad: 0 };

	alternativeProductCalculations = this._ss.CustomerInformation.CanChangePermPrice === '1';
	reverseProductExtraInfoOrder = this._ss.CustomerInformation.ProductExtraInfoOrder === '1';

	hasGroupSum = ['1', '2'].includes(this._ss.CustomerInformation.GroupSum);
	isGroupSumPopupOpen = false;
	groupSumStore: Record<string, GroupSumDisplay> = {};
	groupSumDisplay: GroupSumDisplay[] = [];

	windowWidth = innerWidth;

	canSeePrices = true;
	isB2BAndShouldSeeHistoryButton = this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	canSeeDates = !this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	canSeeStock = !this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	shouldSeeBadatzLogo = this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	showItemExtra4InsteadOfBarcode = this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;

	allProductsMap: CustomMap<string, AllProducts> = new CustomMap();

	isSearchOptionsPopupOpen$ = new BehaviorSubject(false);
	saveLastSearchOptionSelected: boolean;

	concentratedProducts: Record<string, ConcentratedProducts[]> = {};
	concentratedProductsFilters: { ItemColor: Record<string, string[]>; allItemColors: string[]; ItemSize: Record<string, string[]>; allItemSizes: string[] } = {
		ItemColor: {},
		ItemSize: {},
		allItemColors: [],
		allItemSizes: [],
	};
	isConcentratedProductPopupShowing$ = new BehaviorSubject(false);
	concentratedProductPopupView$ = new BehaviorSubject<'inline' | 'medium'>('inline');
	concentratedProductsUnderMain$ = new BehaviorSubject<ConcentratedProductsDisplay[]>([]);
	concentratedProductsShown$: Observable<ConcentratedProductsDisplay[]>;
	concentratedPoductsTotalQuantity$: Observable<number>;
	concentratedPoductsTotalQuantityPlaceholder$ = new BehaviorSubject({ singles: 0, packs: 0 });

	hasConcentratedSpecials = this._ss.CustomerInformation?.HasSpecialsType === '1';
	concentratedSpecialsCart$ = new BehaviorSubject<Record<string, SpecialInCart & { order: AllOrder[] }>>({});
	anyConcentratedSpecialsInCart$ = this.concentratedSpecialsCart$.pipe(map(cart => Object.keys(cart).length > 0));
	concentratedSpecialCurrentlyEditting$ = new BehaviorSubject<(SpecialInCart & { products: Product2[] }) | null>(null);
	concentratedSpecialIntermediateCurrentlyEdittingQuantities$ = new BehaviorSubject<Record<string, SpecialInCart & { order: AllOrder[] }>>({});
	concentratedSpecialsCartWithIntermediateQuantities$ = combineLatest([this.concentratedSpecialsCart$, this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$]).pipe(
		map(([cart, intermediate]) => {
			const newCart = { ...cart };
			for (const key in intermediate) {
				newCart[key] = intermediate[key];
			}
			return newCart;
		}),
	);
	concentratedSpecialTabsSelectedIndex$ = new BehaviorSubject<number>(0);
	concentratedSpecialTabsText$ = this.concentratedSpecialTabsSelectedIndex$.pipe(map(index => (index === 0 ? 'קנה' : 'קבל')));

	concentratedSpecialCurrentlyEdittingQuantities$ = combineLatest([
		this.concentratedSpecialCurrentlyEditting$,
		this.concentratedProductsUnderMain$,
		this.concentratedSpecialsCartWithIntermediateQuantities$,
		this.concentratedSpecialTabsSelectedIndex$,
	]).pipe(
		map(([special, productsUnderMain, specialAllOrder, selectedIndex]) => {
			let mustPurchaseNeeded = 0,
				mustPurchaseCurrent = 0,
				receiveBonusNeeded = 0,
				receiveBonusCurrent = 0,
				canFinish = false;
			if (!special || !productsUnderMain?.length) {
				return { mustPurchaseNeeded, mustPurchaseCurrent, canFinish };
			}

			const relevantSpecial = special.special[0];

			const mainProduct = this.productsHash[relevantSpecial.ProductID] as Product2;

			const specialAllOrderIntermediate = specialAllOrder[relevantSpecial.SpecialID];

			if (!specialAllOrderIntermediate) {
				return { mustPurchaseNeeded, mustPurchaseCurrent, canFinish };
			}

			mustPurchaseNeeded = (relevantSpecial.TeudaQuantity + relevantSpecial.TeudaPackQuantity * (mainProduct?.ItemPack || 1)) * special.quantity;

			mustPurchaseCurrent = specialAllOrderIntermediate.order.reduce((quantity, order) => {
				const product = this.productsHash[order.ProductId] as Product2;
				return quantity + (order?.TeudaQuantity || 0) + (order?.TeudaPackQuantity || 0) * (product?.ItemPack || 0);
			}, 0);

			if (!selectedIndex) {
				return {
					current: mustPurchaseCurrent,
					needed: mustPurchaseNeeded,
					mustPurchaseNeeded,
					mustPurchaseCurrent,
					mustPurchaseQuantitiesLeft: mustPurchaseCurrent - mustPurchaseNeeded,
					canFinish,
				};
			}

			receiveBonusNeeded = relevantSpecial.TeudaBonusQuantity * special.quantity;

			const multiplyBonusByItemPack = specialAllOrderIntermediate.special[0].BonusType === 'packs';

			if (multiplyBonusByItemPack) {
				receiveBonusNeeded *= mainProduct?.ItemPack || 1;
			}

			receiveBonusCurrent = specialAllOrderIntermediate.order.reduce((quantity, order) => {
				const product = this.productsHash[order.ProductId] as Product2;

				return quantity + (order?.TeudaBonusQuantity || 0) * (multiplyBonusByItemPack ? product?.ItemPack || 0 : 1);
			}, 0);

			const mustPurchaseQuantitiesLeft = mustPurchaseCurrent - mustPurchaseNeeded;
			const receiveBonusQuantitiesLeft = receiveBonusCurrent - receiveBonusNeeded;

			return {
				mustPurchaseNeeded,
				mustPurchaseCurrent,
				receiveBonusNeeded,
				receiveBonusCurrent,
				current: receiveBonusCurrent,
				needed: receiveBonusNeeded,
				mustPurchaseQuantitiesLeft,
				receiveBonusQuantitiesLeft,
				canFinish: mustPurchaseQuantitiesLeft < 1 && mustPurchaseQuantitiesLeft > -1 && receiveBonusQuantitiesLeft < 1 && receiveBonusQuantitiesLeft > -1,
			};
		}),
	);
	isConcentratedSpecialViewOpen$ = this.concentratedSpecialCurrentlyEditting$.pipe(map(special => !!special));
	@ViewChild('concentratedSingles') concentratedSinglesInput: ElementRef<HTMLInputElement>;
	@ViewChild('concentratedPacks') concentratedPacksInput: ElementRef<HTMLInputElement>;

	searchOptions = [
		{ value: '', text: 'הכל' },
		{ value: 'Name', text: 'שם' },
		{ value: 'Code', text: 'קוד' },
		{ value: 'Barcode', text: 'ברקוד' },
	];

	isBarcodePopupOpen = false;
	supportsVideo = true;
	@ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
	video: HTMLVideoElement;
	mediaStream: MediaStream;
	detectedCode = '';
	intervalID;

	cookiesState: CookieDict = {};

	MGroups: AllMGroup[] = [];
	MGroupsRecord: Record<string, { main: AllMGroup; sub: AllMGroup[] }> = {};
	MGroupsMain$ = new BehaviorSubject<AllMGroup[]>([]);
	MGroupsMainDisplay$ = combineLatest([this.MGroupsMain$, this.form.controls.SectionSearch.valueChanges.pipe(startWith(this.form.controls.SectionSearch.value), shareReplay(1))]).pipe(
		map(([mgroups, search]) => (search ? mgroups.filter(mg => mg.mgn.includes(search)) : mgroups)),
	);
	MGroupsSub = [];
	hasMGroups = false;

	hasSpecials = false;
	openSpecialsPopupOnEntry = true;
	useSpecialsIcons = false;

	// Only Reference Once
	rankExactSearchResult = this._ss.CustomerInformation.SearchLogic === '1';
	prioritizeFirstLetters = this._ss.CustomerInformation.SearchLogic === '2';
	blockBeforeSendTeudaIfObligoWarning = this._ss.CustomerInformation.BlockClientTeudaSend === '1';
	canPressLastQuantityAndFillQuantity = this._ss.CustomerInformation.LastQuantityClick === '1';
	isRallyanceReclamation = this._ss.DocType === RECLAMATION_RALLYANCE_DOCTYPE;

	// Template Variables
	alternativeInlineView = this._ss.CustomerInformation.ShowInlineProduct === '1' || this._ss.CustomerInformation.ShowInlineProduct === '2';
	isMoveStockInline = this.windowWidth > 500 && this._ss.CustomerInformation.ShowInlineProduct === '3';
	isAlternativeColors = false;
	isAlternativeShowProduct = this._ss.CustomerInformation.ShowProduct && ['7', '8', '9', '10'].includes(this._ss.CustomerInformation.ShowProduct);
	smallViewItemSize = this.isAlternativeShowProduct ? 200 : 330;
	inlineItemSize = this.windowWidth > 767 && this.windowWidth < 1099 ? 251.99 * 1.2 : 254 / 2; // is tablet zoom css on, or pc putting 2 inline in 1 row.
	inlineUnder768Size = this.alternativeInlineView ? 150 : 288;
	inlineConcentratedPopupSize = 124;
	mediumConcentratedPopupSize = 350;
	mediumItemSize = this.windowWidth > 767 && this.windowWidth < 1099 ? 490 * 1.2 : 490; // is tablet zoom css on, or pc putting 2 inline in 1 row.
	hideSingles = false;
	hidePacks = false;
	canShowClientYaad = this._ss.CustomerInformation.YaadClients === '2';
	packQuantityText = this._ss.CustomerInformation.PackQuantityText === '1' ? 'כמות' : 'אריזות';
	showTranslatedStock = this._ss.CustomerInformation.StockWithItemPack === '1';
	maxProductName = this._ss.CustomerInformation.MaxProductNameLength && +this._ss.CustomerInformation.MaxProductNameLength > 0 ? this._ss.CustomerInformation.MaxProductNameLength : 60;
	maxProductNameMobile = +this.maxProductName - 21;
	showConcentratedStock = this._ss.CustomerInformation.ConcentratedProducts_Display && this._ss.CustomerInformation.ConcentratedProducts_Display.split('_').includes('1');
	showConcentratedPrice = this._ss.CustomerInformation.ConcentratedProducts_Display && this._ss.CustomerInformation.ConcentratedProducts_Display.split('_').includes('2');
	dontShowCalculatedDiscount = this._ss.CustomerInformation.CalculatedDiscount === '1' || this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	hasWeightTypeUnitsBehaviors = this._ss.CustomerInformation.ProductWeightType === '1';
	viewtypeBigItemSize = 400;
	ignoreBelowMin = false;
	showLastDatePriceAndQuantityInsteadOfBarcodes = this._ss.CustomerInformation.SeeBarcode === '8';
	dontLimitBarcodeTo5DigitsInPopup = ['3', '4', '8'].includes(this._ss.CustomerInformation.SeeBarcode);
	calculatePackagesTotalQuantity = this._ss.CustomerInformation.ShowTranslatedQuantity === '1';
	readonly itemRemarkPlaceholder = 'הוסף הערה...';

	isProduction = environment.production;
	isTestEnvForPictureSnapScroll = location.href.includes('test.mobilityltd') || !this.isProduction;

	// StoreService Locals
	HideStock: string = this._ss.CustomerInformation.HideStock;
	SeeBarcode: string = this._ss.CustomerInformation.SeeBarcode;
	SeeProductId: string = this._ss.CustomerInformation.SeeProductId;
	CustImageDomain: string = this._ss.CustomerInformation.CustImageDomain;
	ShowProduct: string = this._ss.CustomerInformation.ShowProduct;
	BlockYehidotPlusIfBelowStockOne: string = this._ss.CustomerInformation.BlockYehidotPlusIfBelowStockOne;
	ProductName_AddItemExtra: string = this._ss.CustomerInformation.ProductName_AddItemExtra;
	TotalOrderOverride: string = this._ss.CustomerInformation.TotalOrderOverride;
	NoMinimum: boolean = this._ss.OneClientInfo && !!this._ss.OneClientInfo.NoMinimum;
	ItemExtraTitle: string = this._ss.CustomerInformation.ItemExtraTitle;

	readonly badatzLogo = `${this.CustImageDomain}/badaz.jpg`;
	readonly hatamLogo = `${this.CustImageDomain}/hatam.png`;
	readonly hatamText = `ללא חשש קיטניות`;
	readonly hatamTextPNG = 'assets/images/pngs/hatamText.png';

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	async ngOnInit() {
		this._ss.BackFromClientCard = false;

		console.log(this._ss.OneClientInfo);

		const isCheckOk = this.performObligoOrBlockCheckOnChosenClient();

		if (!isCheckOk) {
			return;
		}

		this.windowVisibilityObservable$ = fromEvent(window, 'visibilitychange');
		this.windowVisibilitySubscription$ = this.windowVisibilityObservable$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const ls = localStorage.getItem('lastupdate');
			if (ls === null) {
				this.router.navigate(['home']);
				return;
			}

			const Datetwo = JSON.parse(ls);
			const date = new Date().toISOString().slice(0, 10);

			if (Datetwo.toString().slice(0, 10) !== date) {
				this.router.navigate(['home']);
			}
		});

		// Don't know why this is here, but it's here ¯\_(ツ)_/¯
		if (this._ss.DocType === null) {
			this.router.navigate(['clients']);
			return;
		}

		if (this._ss.CustomerInformation.MustIncludeProducts === '1') {
			this._mustIncludeProducts.fetchRelevantMustIncludeProductsForCurrentTeuda();
		}

		this.online = window.navigator.onLine;

		if (this.windowWidth > TABLET_RESOLUTION_CUTOFF) {
			// if user is on PC. Currently only relevant for foie-gras

			let setProductsPerRowInMediumViewIfWindowWidthLargerThan768 = this.productsPerRowMedium;

			const num = this.windowWidth - 1100;

			if (num > -1) {
				setProductsPerRowInMediumViewIfWindowWidthLargerThan768 = 3;
			}
			// if monitor is ultra-wide, for some reason?
			if (num > 250) {
				setProductsPerRowInMediumViewIfWindowWidthLargerThan768 += Math.floor(num / 250);
			}
			this.productsPerRowMedium = setProductsPerRowInMediumViewIfWindowWidthLargerThan768;
		}

		this._ss.FromProductsToDohot = false;

		window.scrollTo(0, 0);

		const { Cm, company } = this._ss.OneClientInfo;

		if (this.isRallyanceReclamation) {
			this.handleRallyanceReclamation();
			return;
		}

		this.FilterMigvanFilter = this._ss.FilterMigvan.filter(migvan => migvan.mig === Cm && migvan.cm === company);

		this.handleUnBlockQty();
		this.handleBlockInputs();
		this.resolveQuantitiesLimitations();

		if (this._ss.CustomerInformation.CustSeeImages === '1') {
			this.SeeImages = 1;
		}

		if (this._ss.CustomerInformation.CustSeeImageGeneral === '1') {
			this.SeeImagesGeneral = 1;
		}

		if (this._ss.CustomerInformation.CustChangeCss2 === '1' || this._ss.CustomerInformation.CustChangeCss2 === '2') {
			this.ShowExtraHearot = true;
		}

		if (this._ss.CustNum === '10259' || this._ss.CustomerInformation.CustBlockParam3 === '1') {
			this._ss.BlockMlayParam3 = false;
		}

		if (this._ss.CustNum === '10152.1' || this._ss.CustomerInformation.CustSeeArizot === '1') {
			this._ss.SeeArizot = false;
		}

		if (this._ss.CustomerInformation.SearchExtraProductFilter === '1') {
			this.searchOptions.push({
				value: 'ItemExtra1',
				text: 'שם סינון',
			});
		}

		console.log(this._ss.CustomerInformation);

		this.hasSpecials = ['1', '2', '3'].includes(this._ss.CustomerInformation.HasSpecials);
		this.openSpecialsPopupOnEntry = this._ss.CustomerInformation.HasSpecials === '1';
		this.useSpecialsIcons = this._ss.CustomerInformation.HasSpecials === '3';

		const ignoreMinPrice = localStorage.getItem('IgnorMinPrice');

		if (ignoreMinPrice !== null) {
			this.IgnorMinPrice = ignoreMinPrice;
			if (this.IgnorMinPrice == 1) {
				this._ss.CheckMinPrice = 1;
			}
		}

		this.AddProductTrueOrFalse = localStorage.getItem('AddProduct') ?? this.AddProductTrueOrFalse;

		if (this._ss.CustomerInformation.CustNum === '10033') {
			this.AddProductTrueOrFalse = '1';
		}

		this.Admin ??= 'false';

		if (this.Admin) {
			if (this.Admin.toLowerCase().includes('b2b')) {
				this.isB2BAgent = true;
				this.isB2BLeasing = this.Admin === 'b2bL' || this.Admin === 'b2bLM';
				this._ss.AutoMoveHovot = 1;

				this.canSeePrices = this._ss.CustomerInformation.B2BCanSeePrices !== '1';
				if (this.Admin.toLowerCase().includes('b2bp')) {
					this.isB2BAgentNoPricesAndNoParameters = true;
					this.canSeePrices = false;
				}
			}

			if (this.Admin.toLowerCase().includes('catalog')) {
				this.isCatalogAgent = true;
			}
		}

		this.isAlternativeColors = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this.isB2BAgent;

		this.ShowProfit = localStorage.getItem('ShowProfit') ?? this.ShowProfit;

		const history = localStorage.getItem('IsHistFirst') ?? this.shouldDisplayHistoryProductsFirst;

		if (this._ss.CustomerInformation.CustExtra === '1' || history == '1') {
			this.shouldDisplayHistoryProductsFirst = true;
		}

		if ([89, 90, 91, 92].includes(this._ss.DocType)) {
			this.shouldDisplayHistoryProductsFirst = false;
		}

		this._ss.SeeTotalPrice = localStorage.getItem('SeeTotalPrice') ?? this._ss.SeeTotalPrice;

		this.productType = localStorage.getItem('view') ?? this.productType;

		if (this.isCatalogAgent) {
			this.productType = 'small';
		}

		if (this._ss.CustomerInformation.GetOriginalStock === '1') {
			try {
				this.ExtraMlayClient = true;
				this.warehouseStock = await this.updateOriginalStock();
			} catch (error) {}
			this.ExtraMlayClient = false;
		}

		this.SeeDateOrNumOfTimes = localStorage.getItem('SeeDateOrNumOfTimes') ?? this.SeeDateOrNumOfTimes;

		const isAnOrder = localStorage.getItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);

		if (isAnOrder) {
			this.AllOrder = JSON.parse(isAnOrder);
			const dup = this.AllOrder.find(ao => ao.DuplicateOrder === 'true');

			if (dup) {
				this.isDuplicateOrder = true;
				this.duplicateOrderTeudaRemark = dup.TeudaRemark;
			}
		}

		if (this.canShowClientYaad) {
			this._ss.EachClientDohotYearWithYaad(this._ss.OneClientInfo.ClientId, this._ss.OneClientInfo.company, new Date().getFullYear()).subscribe({
				next: res => {
					if (typeof res !== 'string') {
						const month = new Date().getMonth() + 1;
						const yaad = res.recordset.find(r => r.Mm1 === String(month).padStart(2, '0'));
						if (yaad) {
							this.yaadObject.tot = yaad.Tot;
							this.yaadObject.yaad = yaad.Yaad2 || yaad.Yaad1 || 0;
							this.yaadObject.avg = this.yaadObject.yaad ? (yaad.Tot / this.yaadObject.yaad) * 100 : 0;
						}
					}
				},
			});
		}

		this._alert.handleAlertLogic(MsgType.enterProducts.msgType);

		if (!this._ss.allConcentratedProducts.length && this._ss.CustomerInformation.ConcentratedProducts === '1') {
			const concentrated = localStorage.getItem('Concentrate');

			if (concentrated) {
				const parsed = JSON.parse(decompress(concentrated));
				this._ss.allConcentratedProducts = parsed;
			}
		}

		if (!this._ss.allGroupDiscounts.length) {
			const fromLS = localStorage.getItem('allGroupDiscounts');

			if (fromLS) {
				this._ss.allGroupDiscounts = this.allGroupDiscounts = JSON.parse(decompress(fromLS));
				console.log({ groupDiscounts: this.allGroupDiscounts });

				this.handleGroupDiscountsClientAndCompanyFilter();
			} else {
				if (this._ss.CustomerInformation.CustChange8 == '1') {
					this._ss.GetGroupDiscounts().subscribe({
						next: res => {
							if (typeof res == 'string' || !res.recordset.length) {
								console.log({ res, msg: 'getgroupdiscounts failed' });
							} else {
								this._ss.allGroupDiscounts = this.allGroupDiscounts = res.recordset;
								localStorage.setItem('allGroupDiscounts', compress(JSON.stringify(this.allGroupDiscounts)));

								this.handleGroupDiscountsClientAndCompanyFilter();
							}
						},
						error: err => {
							console.log({ msg: 'groupdiscounts error', err });
						},
					});
				}
			}
		} else {
			this.allGroupDiscounts = this._ss.allGroupDiscounts;
			this.handleGroupDiscountsClientAndCompanyFilter();
		}

		if (localStorage.getItem('HasMainGroup') !== null) {
			this.HasMainGroup = localStorage.getItem('HasMainGroup');
			if (this.HasMainGroup === '1') {
				this.HasMahlakotMain = 1;

				if (this._ss.SaveMainGroups === null) {
					if (localStorage.getItem('SaveMainGroups') !== null) {
						this._ss.SaveMainGroups = localStorage.getItem('SaveMainGroups');
						this._ss.SaveMainGroups = JSON.parse(this._ss.SaveMainGroups);
						this.GroupsSearch = this._ss.SaveMainGroups;
						this.Groups = this._ss.SaveMainGroups;
						console.log(this.Groups);
					} else {
						this._ss.GetMainGroups().subscribe(res => {
							this.Groups = res;
							this.Groups = this.Groups.recordset;
							for (let x = 0; x < this.Groups.length; x++) {
								this.Groups[x].GroupName = this.Groups[x].mgn;
								this.Groups[x].GroupId = this.Groups[x].mg;
							}

							this.GroupsSearch = this.Groups;
							this._ss.SaveMainGroups = this.Groups;
							console.log(this.Groups);

							this.groupNamesRecord = (this.Groups as any[]).reduce((p, c) => {
								p[c.GroupId] = c;
								return p;
							});

							localStorage.setItem('SaveMainGroups', JSON.stringify(this._ss.SaveMainGroups));

							this._ss.SecondGroupSecond().subscribe(res => {
								this._ss.AllSavedMahlakot = res;
								this._ss.AllSavedMahlakot = this._ss.AllSavedMahlakot.recordset;
								console.log(this._ss.AllSavedMahlakot);
								localStorage.setItem('AllSavedMahlakot', JSON.stringify(this._ss.AllSavedMahlakot));
							});
						});
					}
				} else {
					this.Groups = this._ss.SaveMainGroups;
					this.GroupsSearch = this._ss.SaveMainGroups;
					console.log(this.Groups);
				}
			} else {
				const groupCompany = localStorage.getItem('GroupName');
				if (groupCompany) {
					this.GroupsSearch = this.Groups = JSON.parse(groupCompany).filter(gc => gc.company === this._ss.OneClientInfo.company);
					this.groupNamesRecord = (this.Groups as any[]).reduce((p, c) => {
						p[c.GroupId] = c;
						return p;
					});
					console.log(this.Groups);
				}
			}
		} else {
			const groupCompany = localStorage.getItem('GroupName');
			if (groupCompany) {
				this.GroupsSearch = this.Groups = JSON.parse(groupCompany).filter(gc => gc.company === this._ss.OneClientInfo.company);
				this.groupNamesRecord = (this.Groups as any[]).reduce((p, c) => {
					p[c.GroupId] = c;
					return p;
				});
				console.log(this.Groups);
			}
		}

		const mgroups = localStorage.getItem('MGroups');

		if (mgroups) {
			this.hasMGroups = true;
			this.MGroups = JSON.parse(decompress(mgroups));

			this.MGroupsRecord = this.MGroups.reduce((prev, curr) => {
				prev[curr.mg] ||= { main: curr, sub: [] };
				prev[curr.mg].sub.push(curr);

				return prev;
			}, this.MGroupsRecord);

			console.log(this.MGroupsRecord);

			this.MGroupsMain$.next(Object.values(this.MGroupsRecord).map(record => record.main));
		}

		this.Mode = this.online;

		if (localStorage.getItem('work') !== null) {
			this.Mode = false;
			this.online = false;
		}

		if (this._ss.CustomerInformation.AlertHearotWhenEnter === '1' && this._ss.OneClientInfo.Hearot) {
			alert(this._ss.OneClientInfo.Hearot);
		}

		// if navigate back to page
		if (this._ss.SpecPriceSs !== null) {
			this.AllProductsSearch = this._ss.SpecPriceSs;

			this.AllProducts = this._ss.BackToProductsFromTeuda;

			this.Mode = this._ss.BackToProductsOnlineStatus ?? this.Mode;

			this.ChoosenSection = this._ss.BackToProductsFromTeudaSectionName;

			this.SaveSectionSearchOnlySpecPricessOrderByDate = this._ss.allProductsSearchByDateForSchichim;

			for (const product of this.AllProducts) {
				product.TeudaTotalRow = product.TeudaQuantity = product.TeudaPackQuantity = product.TeudaBonusQuantity = 0;
				product.ChangePrice = product.Price;
				product.ChangeDiscount = product.discount;
			}

			if (this._ss.CustomerInformation.FilterMehironimOnBackFromTeuda !== '1') {
				this.handlePopulateAndFilterMehironim();
			}

			if (this.HasMahlakotMain !== 1) {
				this.filterOnlyRelevantGroups();
			}

			this.isLoadingPopupOpen = false;
			setTimeout(() => {
				this.postOnInitFunctions();
			}, 200);
		} else {
			if (this._ss.CustomerInformation.RecvVproductsWhenEnter === '1') {
				this.updatingProducts = true;

				try {
					await new Promise<void>((resolve, reject) => {
						this._ss
							.GetAllProducts()
							.pipe(catchError(() => throwError(() => reject())))
							.subscribe({
								next: res => {
									if (typeof res === 'string') {
										return reject();
									}

									localStorage.setItem('AllProducts', compress(JSON.stringify(res.recordset)));

									this._ss.ProductCompany = res.recordset;

									this.updatingProducts = false;

									resolve();
								},
							});
					});
				} catch (error) {
					alert('לא היה ניתן לעדכן את המוצרים, אנא נסה שנית מאוחר יותר');
					// location.reload();
					// this._ss.reloadWhenEnterClientsPage = true;

					this.router.navigate(['clients'], { replaceUrl: true });
					return;
				}
			}

			this.AllProductsSearch = [];
			for (let x = 0; x < this._ss.ProductCompany.length; x++) {
				if (this._ss.ProductCompany[x].company === this._ss.OneClientInfo.company) {
					this._ss.ProductCompany[x].NumOfTimes = 0;
					this._ss.ProductCompany[x].DocNetPrice = 0;
					this._ss.ProductCompany[x].DocPrice = 0;
					this._ss.ProductCompany[x].DocDiscount = 0;
					this._ss.ProductCompany[x].docquantity = 0;
					let strid = this._ss.ProductCompany[x].ProductId;
					// this._ss.ProductCompany[x].Product_Name = str.toString().toLowerCase()
					this._ss.ProductCompany[x].ProductId = strid.toString().toLowerCase();
					this._ss.ProductCompany[x].present = { icon: true, showContent: false };
					this._ss.ProductCompany[x].TeudaQuantity = 0;
					this._ss.ProductCompany[x].TeudaPackQuantity = 0;
					this._ss.ProductCompany[x].TeudaBonusQuantity = 0;
					this._ss.ProductCompany[x].ChangePrice = this._ss.ProductCompany[x].NetPrice;
					this._ss.ProductCompany[x].ChangeDiscount = this._ss.ProductCompany[x].discount;
					this._ss.ProductCompany[x].TeudaTotalRow = 0;
					this._ss.ProductCompany[x].Heara = '';
					this._ss.ProductCompany[x].ShowPrice = this._ss.ProductCompany[x].Price;
					this.AllProductsSearch.push(this._ss.ProductCompany[x]);
				}
			}

			if (this._ss.CustomerInformation.CustChange8 == '1' && !this._ss.allGroupDiscounts.length) {
				const response = confirm('חלה תקלה בעת חישוב הנחות קבוצתיות, האם לנסות שוב?');

				if (response) {
					this._ss.GetGroupDiscounts().subscribe({
						next: res => {
							if (typeof res == 'string' || !res.recordset.length) {
								console.log({ res, msg: 'getgroupdiscounts failed' });
								alert('לא ניתן לקבל הנחות קבוצתיות בגלל שגיאת שרת');
							} else {
								this._ss.allGroupDiscounts = this.allGroupDiscounts = res.recordset;
								localStorage.setItem('allGroupDiscounts', compress(JSON.stringify(this.allGroupDiscounts)));

								document.location.reload();
							}
						},
						error: err => {
							console.log({ msg: 'groupdiscounts error', err });

							alert('לא ניתן לקבל הנחות קבוצתיות');
						},
					});
				}
			}

			this.handleGroupDiscountsClientAndCompanyFilter();

			this.handlePopulateAndFilterMehironim();

			if (this.online === true && this._ss.CustomerInformation.SkipRequest !== '1') {
				this.isLoadingPopupOpen = true;

				if ([88, 888].includes(this._ss.DocType)) {
					this.handleShirionDoctypeOnInitWhenOnline();
					return;
				}

				if (this._ss.CustomerInformation.SkipRequest === '2') {
					this.timeoutID = window.setTimeout(() => {
						this.prices$.unsubscribe();
						this.getAllPricesError();
					}, 10000);
				}

				this.prices$ = this._ss
					.GetAllPrices()
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: async (res: any) => {
							if (this._ss.CustomerInformation.SkipRequest === '2') {
								clearTimeout(this.timeoutID);
							}

							console.log(res);

							this.Mode = true;

							this._ss.SpecPriceSs = res.recordset;

							if (this._ss.SpecPriceSs.length && this._ss.SpecPriceSs[0].ClientId !== this._ss.OneClientInfo.ClientId) {
								alert('ארעה שגיאה ,הנך מועבר לדף לקוחות , אנא נסה שנית');
								this.router.navigate(['clients']);
								return;
							}

							// this.AllProductsSearch = res
							// this.AllProductsSearch = this.AllProductsSearch.recordset
							for (let z = 0; z < this._ss.SpecPriceSs.length; z++) {
								this._ss.SpecPriceSs[z].present = { icon: true, showContent: false };
								let strid = this._ss.SpecPriceSs[z].ProductId;
								// this._ss.SpecPriceSs[z].Product_Name = str.toString().toLowerCase()
								this._ss.SpecPriceSs[z].ProductId = strid.toString().toLowerCase();
								this._ss.SpecPriceSs[z].TeudaQuantity = 0;
								this._ss.SpecPriceSs[z].TeudaPackQuantity = 0;
								this._ss.SpecPriceSs[z].TeudaBonusQuantity = 0;
								this._ss.SpecPriceSs[z].ChangePrice = this._ss.SpecPriceSs[z].NetPrice;
								this._ss.SpecPriceSs[z].ChangeDiscount = this._ss.SpecPriceSs[z].discount;
								this._ss.SpecPriceSs[z].TeudaTotalRow = 0;
								this._ss.SpecPriceSs[z].Heara = '';
								this._ss.SpecPriceSs[z].ShowPrice = this._ss.SpecPriceSs[z].Price;
							}

							this.SaveSectionSearchOnlySpecPricessOrderByDate = this._ss.SpecPriceSs;

							console.log({ _ssSpecPriceSs: this._ss.SpecPriceSs, AllProductsSearch: this.AllProductsSearch });

							// Order of products is important
							const map = new Map();

							const prodMapObj: Map<string, any> = this.AllProductsSearch.reduce((prev, curr) => {
								prev.set(curr.ProductId, curr);
								return prev;
							}, map);

							for (let i = 0; i < this._ss.SpecPriceSs.length; i++) {
								const product = this._ss.SpecPriceSs[i];

								if (prodMapObj.has(product.ProductId)) {
									const prod = prodMapObj.get(product.ProductId);

									// product.NetPrice = product.Price * ((100 - mehiron.dsc) / 100)
									// product.MinPrice = mehiron.mn
									// product.discount =
									// product.CPP = mehiron.prof

									// let obj:any = {}
									if (prod.passedCustGroup) {
										// obj.ChangeDiscount = obj.discount = prod.discount
										// obj.ShowPrice = obj.Price =  prod.Price
										// obj.NetPrice = obj.ChangePrice = prod.NetPrice
										// obj.MinPrice = prod.MinPrice
										// obj.CPP = prod.CPP
										product.passedCustGroup = true;
									}
									prodMapObj.set(product.ProductId, product);
								}
							}

							this.AllProductsSearch = Array.from(prodMapObj.values());

							if (this.AllOrder.length > 0) {
								// for (let x = 0; x < this.AllOrder.length; x++) {
								// 	for (let y = 0; y < this._ss.SpecPriceSs.length; y++) {
								// 		if (this.AllOrder[x].ProductId === this._ss.SpecPriceSs[y].ProductId && this.AllOrder[x].Price === this.AllOrder[x].PermPrice) {
								// 			this.AllOrder[x].Price = this._ss.SpecPriceSs[y].NetPrice;
								// 			this.AllOrder[x].ChangeDiscount = this._ss.SpecPriceSs[y].discount;
								// 		}
								// 	}
								// }
							}

							if (this._ss.OneClientInfo.Cm !== '0') {
								for (let m = 0; m < this.FilterMigvanFilter.length; m++) {
									let data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);
									let data2 = this.AllProductsSearch.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);

									if (data === undefined && data2 !== undefined) {
										this._ss.SpecPriceSs.push(data2);
									}
								}

								this.AllProductsSearch = this._ss.SpecPriceSs;
								this.OnlyRespondVAllProducts = this._ss.SpecPriceSs;

								const orderMigvan = [];
								// for (let x = 0; x < this.AllOrder.length; x++) {
								// 	const data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.AllOrder[x].ProductId);
								// 	if (data) {
								// 		orderMigvan.push(this.AllOrder[x]);
								// 	}
								// }
								if (orderMigvan.length) {
									this.AllOrder = orderMigvan;
								}

								if (this.HasMahlakotMain !== 1) {
									this.filterOnlyRelevantGroups();
								}
							} else {
								//--בדיקה לפתיחת מחיר לפי תעודה
								this.handleDocTypeShrionIsShrion();

								//--בדיקה לפתיחת מחיר לפי תעודה

								this._ss.SpecPriceSs = this.AllProductsSearch;
								this.OnlyRespondVAllProducts = this.AllProductsSearch;
							}

							//--טבלת מבצע
							if (this._ss.OneClientInfo.HM === 1 && this.online === true) {
								this.OnMivza = true;
								this._ss.Mivza().subscribe({
									next: res => {
										this.OnMivza = false;

										this.isLoadingPopupOpen = false;

										if (this._ss.CustomerInformation.CustExtra1 === '1') {
											this.RefreshMlay();
										}

										this._ss.MivzaA = res;
										this._ss.MivzaA = this._ss.MivzaA.recordset;
										for (let z = 0; z < this._ss.MivzaA.length; z++) {
											let str = this._ss.MivzaA[z].ItemCode;
											this._ss.MivzaA[z].ItemCode = str.toString().toLowerCase();
										}

										if (this._ss.MivzaA.length === 0 && this.OnlyRespondVAllProducts.length !== 0) {
											for (let x = 0; x < this.OnlyRespondVAllProducts.length; x++) {
												if (this.OnlyRespondVAllProducts[x].discount !== 0) {
													this._ss.MivzaA.push({
														ItemCode: this.OnlyRespondVAllProducts[x].ProductId,
														FromQty: 1,
														ToQty: 99999999,
														PriceGet: this.OnlyRespondVAllProducts[x].Price,
														NetPriceGet: this.OnlyRespondVAllProducts[x].NetPrice,
														DiscountGet: this.OnlyRespondVAllProducts[x].discount,
														BonusGet: 0,
														Level: 1,
														company: this._ss.OneClientInfo.company,
													});
												}
											}
										}
									},
									error: () => {
										this.OnMivza = false;
										this.isLoadingPopupOpen = false;

										alert('תקלה בקבלת מבצעים למוצרים');
									},
								});
							} else {
								this.isLoadingPopupOpen = false;

								if (this._ss.CustomerInformation.CustExtra1 === '1') {
									this.RefreshMlay();
								}

								if (this.OnlyRespondVAllProducts.length !== 0) {
									this._ss.MivzaA = [];

									for (let x = 0; x < this.OnlyRespondVAllProducts.length; x++) {
										if (this.OnlyRespondVAllProducts[x].discount !== 0) {
											this._ss.MivzaA.push({
												ItemCode: this.OnlyRespondVAllProducts[x].ProductId,
												FromQty: 1,
												ToQty: 99999999,
												PriceGet: this.OnlyRespondVAllProducts[x].Price,
												NetPriceGet: this.OnlyRespondVAllProducts[x].NetPrice,
												DiscountGet: this.OnlyRespondVAllProducts[x].discount,
												BonusGet: 0,
												Level: 1,
												company: this._ss.OneClientInfo.company,
											});
										}
									}
								}
							}
							//--טבלת מבצע

							//--נקנו לאחרונה

							if (this.shouldDisplayHistoryProductsFirst && !this.isCatalogAgent) {
								const isReturnDoctype = this.resolveIfDocIsOfReturnsType();
								for (let x = 0; x < this.AllProductsSearch.length; x++) {
									if (isReturnDoctype) {
										this.AllProductsSearch[x].qt = 0;
									}
									if (this.AllProductsSearch[x].NumOfTimes > 0) {
										this.SaveSectionSearch.push(this.AllProductsSearch[x]);
									}
								}

								if (this.SaveSectionSearch.length !== 0) {
									if (this.SeeDateOrNumOfTimes === 'true') {
										this.SaveSectionSearch = this.SaveSectionSearchOnlySpecPricessOrderByDate;
									} else {
										if (!this._ss.CustomerInformation.SortProductsBy || this._ss.CustomerInformation.SortProductsBy === '0') {
											this.SaveSectionSearch.sort(function (a, b) {
												return b.NumOfTimes - a.NumOfTimes;
											});
										}
									}

									this.SearchProductInAllProducts = true;
									this.ChoosenSection = 'נקנו לאחרונה';
									this.LastBoughtOn = true;

									this.handleSortProducts();

									this.AllProducts = this.SaveSectionSearch;

									if (this.HasMainGroup !== '1') {
										const saveSectionSet = new Set(this.SaveSectionSearch.map(product => product.IGC));

										this.Groups = this.GroupsSearch.filter(group => saveSectionSet.has(group.GroupId));
									}
								} else {
									this.handleSortProducts();
									this.AllProducts = this.AllProductsSearch;

									if (this.HasMahlakotMain !== 1) {
										this.filterOnlyRelevantGroups();
									}
								}
							} else {
								if (this.resolveIfDocIsOfReturnsType()) {
									for (let x = 0; x < this.AllProductsSearch.length; x++) {
										this.AllProductsSearch[x].qt = 0;
									}
								}
								this.handleSortProducts();
								this.AllProducts = this.AllProductsSearch;

								if (this.HasMahlakotMain !== 1) {
									this.filterOnlyRelevantGroups();
								}
							}

							//--נקנו לאחרונה

							this.isLoadingPopupOpen = false;

							for (let x = 0; x < this.AllProducts.length; x++) {
								this.AllProducts[x].TeudaTotalRow = 0;
								this.AllProducts[x].TeudaQuantity = 0;
								this.AllProducts[x].TeudaPackQuantity = 0;
								this.AllProducts[x].TeudaBonusQuantity = 0;
								this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
								this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
								this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
							}

							if (this._ss.CustomerInformation.GetByMainCustID === '1' && this._ss.OneClientInfo.MainCustID && this._ss.OneClientInfo.MainCustID !== '0') {
								this.isLoadingPopupOpen = true;

								console.log('before prom', performance.now());
								await new Promise<void>(resolve => {
									const isOnlyVProductsLength = !!this.OnlyRespondVAllProducts.length;
									const isSaveSectionLength = !!this.SaveSectionSearchOnlySpecPricessOrderByDate.length;
									this._ss.GetAllPrices(this._ss.OneClientInfo.MainCustID).subscribe({
										next: res => {
											try {
												if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset?.length) return resolve();
												const productsMap: Map<string, any> = this.AllProductsSearch.reduce((prev, curr) => {
													prev.set(curr.ProductId, curr);

													return prev;
												}, new Map());

												const arr = [];

												for (const product of res.recordset) {
													let prodInMap = productsMap.has((product as any).ProductId);

													if (prodInMap) {
														prodInMap = productsMap.get((product as any).ProductId);
														for (const entry of Object.entries(product as any)) {
															prodInMap[entry[0]] = entry[1];
														}
													} else {
														arr.push(product);
														if (isOnlyVProductsLength) {
															this.OnlyRespondVAllProducts.push(product);
														}

														if (isSaveSectionLength) {
															this.SaveSectionSearchOnlySpecPricessOrderByDate.push(product);
														}
														this.AllProducts.push(product);
													}
												}

												this.AllProductsSearch = [...productsMap.values(), ...arr];
											} catch (error) {
												console.log(error);
											}
											resolve();
										},
										error: e => {
											console.log(e);
											resolve();
										},
									});
								});
								console.log('after prom', performance.now());
								this.isLoadingPopupOpen = false;
							}
							setTimeout(() => {
								console.log('before init', performance.now());
								map.clear();
								// map2.clear();
								this.postOnInitFunctions();
							}, 200);
						},
						error: () => {
							this.Mode = false;
							if (this._ss.AllProductsInfo !== null && this._ss.AllProductsPricess !== null) {
								this._ss.SpecPriceSs = [];

								// for (const price of this._ss.AllProductsPricess) {
								//   price.it = price.toString().toLowerCase()
								// }

								// * hijacked prices filter to lower the loop counts. morph productid to be lowercase
								const relevantPrices = this._ss.AllProductsPricess.filter(prdprice => {
									prdprice.it = prdprice.it.toString().toLowerCase();
									return prdprice.ClientId == this._ss.OneClientInfo.ClientId;
								});

								// TODO - refactor to use a map instead of a loop
								for (let x = 0; x < this.AllProductsSearch.length; x++) {
									for (let y = 0; y < relevantPrices.length; y++) {
										// let strid = relevantPrices[y].it
										// relevantPrices[y].it = strid.toString().toLowerCase()
										if (relevantPrices[y].ClientId === this._ss.OneClientInfo.ClientId && relevantPrices[y].it === this.AllProductsSearch[x].ProductId) {
											this.AllProductsSearch[x].Price = relevantPrices[y].prc;

											if (this._ss.CustomerInformation.OfflineLogic === '1') {
												this.AllProductsSearch[x].NetPrice = relevantPrices[y].prc;
												this.AllProductsSearch[x].Price = relevantPrices[y].prc / ((100 - relevantPrices[y].dsc) / 100);
											}

											this.AllProductsSearch[x].discount = relevantPrices[y].dsc;
											this.AllProductsSearch[x].MinPrice = relevantPrices[y].mn;
											this.AllProductsSearch[x].CPP = relevantPrices[y].prof;
											this._ss.SpecPriceSs.push(this.AllProductsSearch[x]);
										}
									}
								}

								for (let x = 0; x < this._ss.AllProductsInfo.length; x++) {
									if (this._ss.AllProductsInfo[x].ClientId === this._ss.OneClientInfo.ClientId && this._ss.AllProductsInfo[x].company === this._ss.OneClientInfo.company) {
										this._ss.SpecPriceSs.push(this._ss.AllProductsInfo[x]);
									}
								}

								console.log(this.AllProductsSearch);
								console.log(this._ss.SpecPriceSs);

								for (let x = 0; x < this.AllProductsSearch.length; x++) {
									for (let y = 0; y < this._ss.SpecPriceSs.length; y++) {
										if (this.AllProductsSearch[x].ProductId === this._ss.SpecPriceSs[y].ProductId && this._ss.SpecPriceSs[y].NumOfTimes > 0) {
											this.AllProductsSearch[x] = this._ss.SpecPriceSs[y];
										}
									}
								}

								for (let z = 0; z < this._ss.SpecPriceSs.length; z++) {
									this._ss.SpecPriceSs[z].present = { icon: true, showContent: false };
									let strid = this._ss.SpecPriceSs[z].ProductId;
									// this._ss.SpecPriceSs[z].Product_Name = str.toString().toLowerCase()
									this._ss.SpecPriceSs[z].ProductId = strid.toString().toLowerCase();
									this._ss.SpecPriceSs[z].TeudaQuantity = 0;
									this._ss.SpecPriceSs[z].TeudaPackQuantity = 0;
									this._ss.SpecPriceSs[z].TeudaBonusQuantity = 0;
									this._ss.SpecPriceSs[z].ChangePrice = this._ss.SpecPriceSs[z].NetPrice;
									this._ss.SpecPriceSs[z].ChangeDiscount = this._ss.SpecPriceSs[z].discount;
									this._ss.SpecPriceSs[z].TeudaTotalRow = 0;
									this._ss.SpecPriceSs[z].Heara = '';
									this._ss.SpecPriceSs[z].ShowPrice = this._ss.SpecPriceSs[z].Price;
								}

								this.SaveSectionSearchOnlySpecPricessOrderByDate = this._ss.SpecPriceSs;

								for (let x = 0; x < this._ss.SpecPriceSs.length; x++) {
									for (let y = 0; y < this.AllProductsSearch.length; y++) {
										if (this._ss.SpecPriceSs[x].ProductId === this.AllProductsSearch[y].ProductId) {
											this.AllProductsSearch[y] = this._ss.SpecPriceSs[x];
										}
									}
								}

								// if (this.AllOrder.length > 0) {
								// 	for (let x = 0; x < this.AllOrder.length; x++) {
								// 		for (let y = 0; y < this._ss.SpecPriceSs.length; y++) {
								// 			if (this.AllOrder[x].ProductId === this._ss.SpecPriceSs[y].ProductId && this.AllOrder[x].Price === this.AllOrder[x].PermPrice) {
								// 				this.AllOrder[x].Price = this._ss.SpecPriceSs[y].NetPrice;
								// 				this.AllOrder[x].ChangeDiscount = this._ss.SpecPriceSs[y].discount;
								// 			}
								// 		}
								// 	}
								// }

								if (this._ss.OneClientInfo.Cm !== '0') {
									for (let m = 0; m < this.FilterMigvanFilter.length; m++) {
										let data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);
										let data2 = this.AllProductsSearch.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);

										if (data === undefined && data2 !== undefined) {
											this._ss.SpecPriceSs.push(data2);
										}
									}

									this.AllProductsSearch = this._ss.SpecPriceSs;
									this.OnlyRespondVAllProducts = this._ss.SpecPriceSs;

									const orderMigvan = [];
									// for (let x = 0; x < this.AllOrder.length; x++) {
									// 	let data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.AllOrder[x].ProductId);
									// 	if (data === undefined) {
									// 	} else {
									// 		orderMigvan.push(this.AllOrder[x]);
									// 	}
									// }

									if (orderMigvan.length) {
										this.AllOrder = orderMigvan;
									}

									if (this.HasMahlakotMain !== 1) {
										this.filterOnlyRelevantGroups();
									}
								} else {
									//--בדיקה לפתיחת מחיר לפי תעודה
									this.handleDocTypeShrionIsShrion();

									this._ss.SpecPriceSs = this.AllProductsSearch;
									this.OnlyRespondVAllProducts = this.AllProductsSearch;
								}
								//--טבלת מבצע

								this._ss.MivzaA = [];

								for (let x = 0; x < this.OnlyRespondVAllProducts.length; x++) {
									if (this.OnlyRespondVAllProducts[x].discount !== 0) {
										this._ss.MivzaA.push({
											ItemCode: this.OnlyRespondVAllProducts[x].ProductId,
											FromQty: 1,
											ToQty: 99999999,
											PriceGet: this.OnlyRespondVAllProducts[x].Price,
											NetPriceGet: this.OnlyRespondVAllProducts[x].NetPrice,
											DiscountGet: this.OnlyRespondVAllProducts[x].discount,
											BonusGet: 0,
											Level: 1,
											company: this._ss.OneClientInfo.company,
										});
									}
								}

								//--טבלת מבצע

								//--נקנו לאחרונה

								if (this.shouldDisplayHistoryProductsFirst && !this.isCatalogAgent) {
									const isReturnDoctype = this.resolveIfDocIsOfReturnsType();
									for (let x = 0; x < this.AllProductsSearch.length; x++) {
										if (isReturnDoctype) {
											this.AllProductsSearch[x].qt = 0;
										}
										if (this.AllProductsSearch[x].NumOfTimes > 0) {
											this.SaveSectionSearch.push(this.AllProductsSearch[x]);
										}
									}
									if (this.SaveSectionSearch.length !== 0) {
										if (this.SeeDateOrNumOfTimes === 'true') {
											this.SaveSectionSearch = this.SaveSectionSearchOnlySpecPricessOrderByDate;
										} else {
											if (!this._ss.CustomerInformation.SortProductsBy || this._ss.CustomerInformation.SortProductsBy === '0') {
												this.SaveSectionSearch.sort(function (a, b) {
													return b.NumOfTimes - a.NumOfTimes;
												});
											}
										}

										this.SearchProductInAllProducts = true;
										this.ChoosenSection = 'נקנו לאחרונה';
										this.LastBoughtOn = true;

										this.handleSortProducts();

										this.AllProducts = this.SaveSectionSearch;

										if (this.HasMainGroup !== '1') {
											const saveSectionSet = new Set(this.SaveSectionSearch.map(product => product.IGC));

											this.Groups = this.GroupsSearch.filter(group => saveSectionSet.has(group.GroupId));
										}
									} else {
										this.handleSortProducts();
										this.AllProducts = this.AllProductsSearch;

										if (this.HasMahlakotMain !== 1) {
											this.filterOnlyRelevantGroups();
										}
									}
								} else {
									if (this.resolveIfDocIsOfReturnsType()) {
										for (let x = 0; x < this.AllProductsSearch.length; x++) {
											this.AllProductsSearch[x].qt = 0;
										}
									}
									this.handleSortProducts();
									this.AllProducts = this.AllProductsSearch;

									if (this.HasMahlakotMain !== 1) {
										this.filterOnlyRelevantGroups();
									}
								}

								//--נקנו לאחרונה

								this.isLoadingPopupOpen = false;

								for (let x = 0; x < this.AllProducts.length; x++) {
									this.AllProducts[x].TeudaTotalRow = 0;
									this.AllProducts[x].TeudaQuantity = 0;
									this.AllProducts[x].TeudaPackQuantity = 0;
									this.AllProducts[x].TeudaBonusQuantity = 0;
									this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
									this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
									this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
								}
								setTimeout(() => {
									this.postOnInitFunctions();

									// Unsure whether maps get garbage collected
									// map.clear();
									// map2.clear();
								}, 200);
							} else {
								this.handleSortProducts();
								this.AllProducts = this.AllProductsSearch;

								this.isLoadingPopupOpen = false;
								setTimeout(() => {
									this.postOnInitFunctions();
								}, 200);
							}
						},
					});
			} else {
				this.Mode = this.online;

				if ([88, 888].includes(this._ss.DocType)) {
					const shirionProducts = localStorage.getItem('ShirionProducts');
					if (shirionProducts) {
						this._ss.SpecPriceSs = [];

						const shirionProductsArray = JSON.parse(decompress(shirionProducts));

						// doctype 888 for autolux
						const overrideClientForDoctype888 = this._ss.DocType === 888 ? 99999999 : undefined;

						for (let x = 0; x < shirionProductsArray.length; x++) {
							const product = shirionProductsArray[x];

							if (product.ClientId === (overrideClientForDoctype888 || this._ss.OneClientInfo.ClientId) && product.company === this._ss.OneClientInfo.company) {
								this._ss.SpecPriceSs.push(product);
							}
						}

						this.AllProductsSearch = this._ss.SpecPriceSs;
						for (let z = 0; z < this.AllProductsSearch.length; z++) {
							this.AllProductsSearch[z].present = { icon: true, showContent: false };
							// this.AllProductsSearch[z].ProductIdNoChange = this.AllProductsSearch[z].ProductId
							let strid = this.AllProductsSearch[z].ProductId;
							// this.AllProductsSearch[z].Product_Name = str.toString().toLowerCase()
							this.AllProductsSearch[z].ProductId = strid.toString().toLowerCase();
							this.AllProductsSearch[z].TeudaQuantity = 0;
							this.AllProductsSearch[z].TeudaPackQuantity = 0;
							this.AllProductsSearch[z].TeudaBonusQuantity = 0;
							this.AllProductsSearch[z].ChangePrice = this.AllProductsSearch[z].NetPrice;
							this.AllProductsSearch[z].ChangeDiscount = this.AllProductsSearch[z].discount;
							this.AllProductsSearch[z].TeudaTotalRow = 0;
							this.AllProductsSearch[z].Heara = '';
							this.AllProductsSearch[z].ShowPrice = this.AllProductsSearch[z].Price;
						}

						this.AllProducts = this.AllProductsSearch;

						this.filterOnlyRelevantGroups();

						this.isLoadingPopupOpen = false;

						for (let x = 0; x < this.AllProducts.length; x++) {
							this.AllProducts[x].TeudaTotalRow = 0;
							this.AllProducts[x].TeudaQuantity = 0;
							this.AllProducts[x].TeudaPackQuantity = 0;
							this.AllProducts[x].TeudaBonusQuantity = 0;
							this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
							this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
							this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
						}
						setTimeout(() => {
							this.postOnInitFunctions();
						}, 200);
					} else {
						alert('תקלה בקבלת שריון ');
						this.router.navigate(['clients']);
					}
				} else {
					////
					if (this._ss.AllProductsInfo !== null && this._ss.AllProductsPricess !== null) {
						this._ss.SpecPriceSs = [];

						const relevantPrices = this._ss.AllProductsPricess.filter(prdprice => {
							prdprice.it = prdprice.it.toString().toLowerCase();
							return prdprice.ClientId == this._ss.OneClientInfo.ClientId;
						});

						for (let x = 0; x < this.AllProductsSearch.length; x++) {
							for (let y = 0; y < relevantPrices.length; y++) {
								// let strid = relevantPrices[y].it
								// relevantPrices[y].it = strid.toString().toLowerCase()
								if (relevantPrices[y].it === this.AllProductsSearch[x].ProductId) {
									this.AllProductsSearch[x].Price = relevantPrices[y].prc;

									if (this._ss.CustomerInformation.OfflineLogic === '1') {
										this.AllProductsSearch[x].NetPrice = relevantPrices[y].prc;
										this.AllProductsSearch[x].Price = relevantPrices[y].prc / ((100 - relevantPrices[y].dsc) / 100);
									}
									this.AllProductsSearch[x].discount = relevantPrices[y].dsc;
									this.AllProductsSearch[x].MinPrice = relevantPrices[y].mn;
									this.AllProductsSearch[x].CPP = relevantPrices[y].prof;
									this._ss.SpecPriceSs.push(this.AllProductsSearch[x]);
								}
							}
						}

						for (let x = 0; x < this._ss.AllProductsInfo.length; x++) {
							if (this._ss.AllProductsInfo[x].ClientId === this._ss.OneClientInfo.ClientId && this._ss.AllProductsInfo[x].company === this._ss.OneClientInfo.company) {
								this._ss.SpecPriceSs.push(this._ss.AllProductsInfo[x]);
							}
						}

						const specPriceSsWithNumOfTimes = this._ss.SpecPriceSs.filter(spec => spec.NumOfTimes > 0);

						for (let x = 0; x < this.AllProductsSearch.length; x++) {
							for (let y = 0; y < specPriceSsWithNumOfTimes.length; y++) {
								if (this.AllProductsSearch[x].ProductId === specPriceSsWithNumOfTimes[y].ProductId) {
									this.AllProductsSearch[x] = specPriceSsWithNumOfTimes[y];
								}
							}
						}

						for (let z = 0; z < this._ss.SpecPriceSs.length; z++) {
							this._ss.SpecPriceSs[z].present = { icon: true, showContent: false };
							// this._ss.SpecPriceSs[z].ProductIdNoChange = this._ss.SpecPriceSs[z].ProductId
							let strid = this._ss.SpecPriceSs[z].ProductId;
							// this._ss.SpecPriceSs[z].Product_Name = str.toString().toLowerCase()
							this._ss.SpecPriceSs[z].ProductId = strid.toString().toLowerCase();
							this._ss.SpecPriceSs[z].TeudaQuantity = 0;
							this._ss.SpecPriceSs[z].TeudaPackQuantity = 0;
							this._ss.SpecPriceSs[z].TeudaBonusQuantity = 0;
							this._ss.SpecPriceSs[z].ChangePrice = this._ss.SpecPriceSs[z].NetPrice;
							this._ss.SpecPriceSs[z].ChangeDiscount = this._ss.SpecPriceSs[z].discount;
							this._ss.SpecPriceSs[z].TeudaTotalRow = 0;
							this._ss.SpecPriceSs[z].Heara = '';
							this._ss.SpecPriceSs[z].ShowPrice = this._ss.SpecPriceSs[z].Price;
						}

						this.SaveSectionSearchOnlySpecPricessOrderByDate = this._ss.SpecPriceSs;

						for (let x = 0; x < this._ss.SpecPriceSs.length; x++) {
							for (let y = 0; y < this.AllProductsSearch.length; y++) {
								if (this._ss.SpecPriceSs[x].ProductId === this.AllProductsSearch[y].ProductId) {
									this.AllProductsSearch[y] = this._ss.SpecPriceSs[x];
								}
							}
						}

						// if (this.AllOrder.length > 0) {
						// 	for (let x = 0; x < this.AllOrder.length; x++) {
						// 		for (let y = 0; y < this._ss.SpecPriceSs.length; y++) {
						// 			if (this.AllOrder[x].ProductId === this._ss.SpecPriceSs[y].ProductId && this.AllOrder[x].Price === this.AllOrder[x].PermPrice) {
						// 				this.AllOrder[x].Price = this._ss.SpecPriceSs[y].NetPrice;
						// 				this.AllOrder[x].ChangeDiscount = this._ss.SpecPriceSs[y].discount;
						// 			}
						// 		}
						// 	}
						// }

						if (this._ss.OneClientInfo.Cm !== '0') {
							for (let m = 0; m < this.FilterMigvanFilter.length; m++) {
								let data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);
								let data2 = this.AllProductsSearch.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);

								if (data === undefined && data2 !== undefined) {
									this._ss.SpecPriceSs.push(data2);
								}
							}

							this.AllProductsSearch = this._ss.SpecPriceSs;
							this.OnlyRespondVAllProducts = this._ss.SpecPriceSs;

							const orderMigvan = [];
							// for (let x = 0; x < this.AllOrder.length; x++) {
							// 	let data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.AllOrder[x].ProductId);
							// 	if (data === undefined) {
							// 	} else {
							// 		orderMigvan.push(this.AllOrder[x]);
							// 	}
							// }
							if (orderMigvan.length) {
								this.AllOrder = orderMigvan;
							}

							if (this.HasMahlakotMain !== 1) {
								this.filterOnlyRelevantGroups();
							}
						} else {
							//--בדיקה לפתיחת מחיר לפי תעודה
							this.handleDocTypeShrionIsShrion();

							this._ss.SpecPriceSs = this.AllProductsSearch;
							this.OnlyRespondVAllProducts = this.AllProductsSearch;
						}
						//--טבלת מבצע

						this._ss.MivzaA = [];

						for (let x = 0; x < this.OnlyRespondVAllProducts.length; x++) {
							if (this.OnlyRespondVAllProducts[x].discount !== 0) {
								this._ss.MivzaA.push({
									ItemCode: this.OnlyRespondVAllProducts[x].ProductId,
									FromQty: 1,
									ToQty: 99999999,
									PriceGet: this.OnlyRespondVAllProducts[x].Price,
									NetPriceGet: this.OnlyRespondVAllProducts[x].NetPrice,
									DiscountGet: this.OnlyRespondVAllProducts[x].discount,
									BonusGet: 0,
									Level: 1,
									company: this._ss.OneClientInfo.company,
								});
							}
						}

						//--טבלת מבצע

						//--נקנו לאחרונה

						if (this.shouldDisplayHistoryProductsFirst && !this.isCatalogAgent) {
							const isReturnDoctype = this.resolveIfDocIsOfReturnsType();
							for (let x = 0; x < this.AllProductsSearch.length; x++) {
								if (isReturnDoctype) {
									this.AllProductsSearch[x].qt = 0;
								}
								if (this.AllProductsSearch[x].NumOfTimes > 0) {
									this.SaveSectionSearch.push(this.AllProductsSearch[x]);
								}
							}
							if (this.SaveSectionSearch.length !== 0) {
								if (this.SeeDateOrNumOfTimes === 'true') {
									this.SaveSectionSearch = this.SaveSectionSearchOnlySpecPricessOrderByDate;
								} else {
									if (!this._ss.CustomerInformation.SortProductsBy || this._ss.CustomerInformation.SortProductsBy === '0') {
										this.SaveSectionSearch.sort(function (a, b) {
											return b.NumOfTimes - a.NumOfTimes;
										});
									}
								}

								this.SearchProductInAllProducts = true;
								this.ChoosenSection = 'נקנו לאחרונה';
								this.LastBoughtOn = true;

								this.AllProducts = this.SaveSectionSearch;

								if (this.HasMainGroup !== '1') {
									const saveSectionSet = new Set(this.SaveSectionSearch.map(product => product.IGC));

									this.Groups = this.GroupsSearch.filter(group => saveSectionSet.has(group.GroupId));
								}
							} else {
								this.AllProducts = this.AllProductsSearch;

								if (this.HasMahlakotMain !== 1) {
									this.filterOnlyRelevantGroups();
								}
							}
						} else {
							if (this.resolveIfDocIsOfReturnsType()) {
								for (let x = 0; x < this.AllProductsSearch.length; x++) {
									this.AllProductsSearch[x].qt = 0;
								}
							}
							this.AllProducts = this.AllProductsSearch;

							if (this.HasMahlakotMain !== 1) {
								this.filterOnlyRelevantGroups();
							}
						}

						//--נקנו לאחרונה

						this.isLoadingPopupOpen = false;

						for (let x = 0; x < this.AllProducts.length; x++) {
							this.AllProducts[x].TeudaTotalRow = 0;
							this.AllProducts[x].TeudaQuantity = 0;
							this.AllProducts[x].TeudaPackQuantity = 0;
							this.AllProducts[x].TeudaBonusQuantity = 0;
							this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
							this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
							this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
						}
						setTimeout(() => {
							this.postOnInitFunctions();
						}, 200);
					} else {
						this.AllProducts = this.AllProductsSearch;

						this.isLoadingPopupOpen = false;

						setTimeout(() => {
							this.postOnInitFunctions();
						}, 200);
					}
				}
			}
		}
	}

	ngOnDestroy() {
		this._ss.performance = [];

		this.destroy$.next();
		this.destroy$.complete();

		this.cleanupOrFilterOrder();

		if (this.mediaStream) {
			for (const track of this.mediaStream.getTracks()) {
				this.mediaStream.removeTrack(track);
			}
		}
		if (this.video) {
			this.video.srcObject = null;
			this.video.pause();
		}

		clearInterval(this.intervalID);
	}

	cleanupOrFilterOrder() {
		// TODO add CustomerInformation parameter whether to filter/delete order
		if (this._ss.OneClientInfo) {
			const { ClientId, company } = this._ss.OneClientInfo;

			const shouldKeepOrder = this.AllOrder.filter(order => order.TeudaPackQuantity > 0 || order.TeudaQuantity > 0 || order.TeudaBonusQuantity > 0 || typeof order.SpecialID === 'number');

			const localstorageIdentifier: string = ClientId + this._ss.DocType + company;

			if (!shouldKeepOrder.length) {
				this.clearAllOrderLocalStorage(localstorageIdentifier);
				return;
			}

			if (shouldKeepOrder.length !== this.AllOrder.length) {
				localStorage.setItem('AllOrder' + localstorageIdentifier, JSON.stringify(shouldKeepOrder));
			}
		}
	}

	getAllPricesError() {
		this.Mode = false;

		if (this._ss.AllProductsInfo !== null && this._ss.AllProductsPricess !== null) {
			this._ss.SpecPriceSs = [];

			// * hijacked filter to lower the loop counts
			const relevantPrices = this._ss.AllProductsPricess.filter(prdprice => {
				prdprice.it = prdprice.it.toString().toLowerCase();
				return prdprice.ClientId == this._ss.OneClientInfo.ClientId;
			});

			// TODO - refactor to use a map instead of a loop
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				for (let y = 0; y < relevantPrices.length; y++) {
					// let strid = relevantPrices[y].it
					// relevantPrices[y].it = strid.toString().toLowerCase()
					if (relevantPrices[y].ClientId === this._ss.OneClientInfo.ClientId && relevantPrices[y].it === this.AllProductsSearch[x].ProductId) {
						this.AllProductsSearch[x].Price = relevantPrices[y].prc;

						if (this._ss.CustomerInformation.OfflineLogic === '1') {
							this.AllProductsSearch[x].NetPrice = relevantPrices[y].prc;
							this.AllProductsSearch[x].Price = relevantPrices[y].prc / ((100 - relevantPrices[y].dsc) / 100);
						}
						this.AllProductsSearch[x].discount = relevantPrices[y].dsc;
						this.AllProductsSearch[x].MinPrice = relevantPrices[y].mn;
						this.AllProductsSearch[x].CPP = relevantPrices[y].prof;
						this._ss.SpecPriceSs.push(this.AllProductsSearch[x]);
					}
				}
			}

			for (let x = 0; x < this._ss.AllProductsInfo.length; x++) {
				if (this._ss.AllProductsInfo[x].ClientId === this._ss.OneClientInfo.ClientId && this._ss.AllProductsInfo[x].company === this._ss.OneClientInfo.company) {
					this._ss.SpecPriceSs.push(this._ss.AllProductsInfo[x]);
				}
			}

			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				for (let y = 0; y < this._ss.SpecPriceSs.length; y++) {
					if (this.AllProductsSearch[x].ProductId === this._ss.SpecPriceSs[y].ProductId && this._ss.SpecPriceSs[y].NumOfTimes > 0) {
						this.AllProductsSearch[x] = this._ss.SpecPriceSs[y];
					}
				}
			}

			for (let z = 0; z < this._ss.SpecPriceSs.length; z++) {
				this._ss.SpecPriceSs[z].present = { icon: true, showContent: false };
				let strid = this._ss.SpecPriceSs[z].ProductId;
				// this._ss.SpecPriceSs[z].Product_Name = str.toString().toLowerCase()
				this._ss.SpecPriceSs[z].ProductId = strid.toString().toLowerCase();
				this._ss.SpecPriceSs[z].TeudaQuantity = 0;
				this._ss.SpecPriceSs[z].TeudaPackQuantity = 0;
				this._ss.SpecPriceSs[z].TeudaBonusQuantity = 0;
				this._ss.SpecPriceSs[z].ChangePrice = this._ss.SpecPriceSs[z].NetPrice;
				this._ss.SpecPriceSs[z].ChangeDiscount = this._ss.SpecPriceSs[z].discount;
				this._ss.SpecPriceSs[z].TeudaTotalRow = 0;
				this._ss.SpecPriceSs[z].Heara = '';
				this._ss.SpecPriceSs[z].ShowPrice = this._ss.SpecPriceSs[z].Price;
			}

			this.SaveSectionSearchOnlySpecPricessOrderByDate = this._ss.SpecPriceSs;

			for (let x = 0; x < this._ss.SpecPriceSs.length; x++) {
				for (let y = 0; y < this.AllProductsSearch.length; y++) {
					if (this._ss.SpecPriceSs[x].ProductId === this.AllProductsSearch[y].ProductId) {
						this.AllProductsSearch[y] = this._ss.SpecPriceSs[x];
					}
				}
			}

			// if (this.AllOrder.length > 0) {
			// 	for (let x = 0; x < this.AllOrder.length; x++) {
			// 		for (let y = 0; y < this._ss.SpecPriceSs.length; y++) {
			// 			if (this.AllOrder[x].ProductId === this._ss.SpecPriceSs[y].ProductId && this.AllOrder[x].Price === this.AllOrder[x].PermPrice) {
			// 				this.AllOrder[x].Price = this._ss.SpecPriceSs[y].NetPrice;
			// 				this.AllOrder[x].ChangeDiscount = this._ss.SpecPriceSs[y].discount;
			// 			}
			// 		}
			// 	}
			// }

			if (this._ss.OneClientInfo.Cm !== '0') {
				for (let m = 0; m < this.FilterMigvanFilter.length; m++) {
					let data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);
					let data2 = this.AllProductsSearch.find(ob => ob.ProductId === this.FilterMigvanFilter[m].it);

					if (data === undefined && data2 !== undefined) {
						this._ss.SpecPriceSs.push(data2);
					}
				}

				this.AllProductsSearch = this._ss.SpecPriceSs;
				this.OnlyRespondVAllProducts = this._ss.SpecPriceSs;

				const orderMigvan = [];
				// for (let x = 0; x < this.AllOrder.length; x++) {
				// 	let data = this._ss.SpecPriceSs.find(ob => ob.ProductId === this.AllOrder[x].ProductId);
				// 	if (data === undefined) {
				// 	} else {
				// 		orderMigvan.push(this.AllOrder[x]);
				// 	}
				// }
				if (orderMigvan.length) {
					this.AllOrder = orderMigvan;
				}

				if (this.HasMahlakotMain !== 1) {
					this.filterOnlyRelevantGroups();
				}
			} else {
				//--בדיקה לפתיחת מחיר לפי תעודה
				this.handleDocTypeShrionIsShrion();

				this._ss.SpecPriceSs = this.AllProductsSearch;
				this.OnlyRespondVAllProducts = this.AllProductsSearch;
			}
			//--טבלת מבצע

			this._ss.MivzaA = [];

			for (let x = 0; x < this.OnlyRespondVAllProducts.length; x++) {
				if (this.OnlyRespondVAllProducts[x].discount !== 0) {
					this._ss.MivzaA.push({
						ItemCode: this.OnlyRespondVAllProducts[x].ProductId,
						FromQty: 1,
						ToQty: 99999999,
						PriceGet: this.OnlyRespondVAllProducts[x].Price,
						NetPriceGet: this.OnlyRespondVAllProducts[x].NetPrice,
						DiscountGet: this.OnlyRespondVAllProducts[x].discount,
						BonusGet: 0,
						Level: 1,
						company: this._ss.OneClientInfo.company,
					});
				}
			}

			//--טבלת מבצע

			//--נקנו לאחרונה

			if (this.shouldDisplayHistoryProductsFirst && !this.isCatalogAgent) {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					if (this.resolveIfDocIsOfReturnsType()) {
						this.AllProductsSearch[x].qt = 0;
					}
					if (this.AllProductsSearch[x].NumOfTimes > 0) {
						this.SaveSectionSearch.push(this.AllProductsSearch[x]);
					}
				}
				if (this.SaveSectionSearch.length !== 0) {
					if (this.SeeDateOrNumOfTimes === 'true') {
						this.SaveSectionSearch = this.SaveSectionSearchOnlySpecPricessOrderByDate;
					} else {
						if (!this._ss.CustomerInformation.SortProductsBy || this._ss.CustomerInformation.SortProductsBy === '0') {
							this.SaveSectionSearch.sort(function (a, b) {
								return b.NumOfTimes - a.NumOfTimes;
							});
						}
					}

					this.SearchProductInAllProducts = true;
					this.ChoosenSection = 'נקנו לאחרונה';
					this.LastBoughtOn = true;

					this.AllProducts = this.SaveSectionSearch;

					if (this.HasMainGroup !== '1') {
						const saveSectionSet = new Set(this.SaveSectionSearch.map(product => product.IGC));

						this.Groups = this.GroupsSearch.filter(group => saveSectionSet.has(group.GroupId));
					}
				} else {
					this.AllProducts = this.AllProductsSearch;

					if (this.HasMahlakotMain !== 1) {
						this.filterOnlyRelevantGroups();
					}
				}
			} else {
				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					if (this.resolveIfDocIsOfReturnsType()) {
						this.AllProductsSearch[x].qt = 0;
					}
					this.AllProducts = this.AllProductsSearch;
				}

				if (this.HasMahlakotMain !== 1) {
					this.filterOnlyRelevantGroups();
				}
			}

			//--נקנו לאחרונה

			this.isLoadingPopupOpen = false;

			for (let x = 0; x < this.AllProducts.length; x++) {
				this.AllProducts[x].TeudaTotalRow = 0;
				this.AllProducts[x].TeudaQuantity = 0;
				this.AllProducts[x].TeudaPackQuantity = 0;
				this.AllProducts[x].TeudaBonusQuantity = 0;
				this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
				this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
				this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
			}
			setTimeout(() => {
				this.postOnInitFunctions();
			}, 200);
		} else {
			this.AllProducts = this.AllProductsSearch;

			this.isLoadingPopupOpen = false;
			setTimeout(() => {
				this.postOnInitFunctions();
			}, 200);
		}
	}

	async getAllProductsFirst() {
		await new Promise<void>((resolve, reject) => {
			this._ss.GetAllProducts().subscribe({
				next: res => {
					if (typeof res === 'string') {
						return reject();
					}

					this.AllProducts = res.recordset;

					localStorage.setItem('AllProducts', compress(JSON.stringify(this.AllProducts)));

					return resolve();
				},
				error: () => {
					return reject();
				},
			});
		});
	}

	isConnectionFastEnough() {
		return (window && window?.navigator && window.navigator['connection'] && window.navigator['connection']['downlink'] && window.navigator['connection']['downlink'] > 0.5) ?? true;
	}

	returnSearchOptionInitialSelected() {
		const savedPreference = localStorage.getItem('searchPreference') as SearchPreferenceChoice | null;

		if (savedPreference && savedPreference !== 'default') {
			if (savedPreference === 'rememberLast') {
				this.saveLastSearchOptionSelected = true;
				const lastSearchPreference = localStorage.getItem('lastSearch');
				return (lastSearchPreference || '') as SearchOptions;
			}

			if (savedPreference.includes('startFrom')) {
				const option = savedPreference.split('-')[1] ? savedPreference.split('-')[1] : '';
				return option as SearchOptions;
			}
		}

		switch (this._ss.CustomerInformation?.CustChange5) {
			case '0':
			case undefined:
				return '';

			case '1':
				return 'Name';

			case '2':
				return 'Code';

			case '3':
				return 'Barcode';

			default:
				return '';
		}
	}

	FilterProductName() {
		// TODO Check if can be replaced
		// for (let i = 0; i < this.AllProductsSearch.length; i++) {
		//   product.Product_NameFilterd = product.Product_Name.replace(/[\/r]/gi,"")
		// }

		if (this._ss.BackToProductsFromTeuda) return;

		const shouldSkipCharacter = (str: string): boolean => ['/', 'r', 'x', '+', '-', '*', '.'].includes(str);

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			const product = this.AllProductsSearch[x];

			if (product.Product_Name) {
				product.Product_Name = String(product.Product_Name).normalize('NFKD');
				product.Product_NameFilterd = (product.Product_Name as string).toLowerCase();
			} else {
				console.log({ productWithoutName: product });
				product.Product_Name = product.ProductId || '';
			}
			if (product.ProductId) {
				product.ProductId = String(product.ProductId).normalize('NFKD');
				product.ProductIdFilterd = product.ProductId.toLowerCase();
			} else {
				console.log({ productWithoutID: product });
				product.ProductId = product.Product_Name || '';
			}

			for (let y = 0; y < product.Product_NameFilterd?.length; y++) {
				if (shouldSkipCharacter(product.Product_NameFilterd.slice(y, y + 1))) {
					product.Product_NameFilterd = product.Product_NameFilterd.slice(0, y) + product.Product_NameFilterd.slice(y + 1);
				}
			}

			for (let y = 0; y < product.ProductIdFilterd?.length; y++) {
				if (shouldSkipCharacter(product.ProductIdFilterd.slice(y, y + 1))) {
					product.ProductIdFilterd = product.ProductIdFilterd.slice(0, y) + product.ProductIdFilterd.slice(y + 1);
				}
			}
		}
	}

	resolveQuantitiesLimitations() {
		const isReturnDoctype = this.resolveIfDocIsOfReturnsType();
		this.hidePacks =
			this._ss.CustomerInformation.CustSeeArizot === '1' ||
			(this._ss.CustomerInformation.CustSeeArizot === '4' && !isReturnDoctype) ||
			(this._ss.CustomerInformation.CustSeeArizot === '6' && isReturnDoctype);
		this.hideSingles =
			this._ss.CustomerInformation.CustSeeArizot === '2' ||
			(this._ss.CustomerInformation.CustSeeArizot === '3' && !isReturnDoctype) ||
			(this._ss.CustomerInformation.CustSeeArizot === '5' && isReturnDoctype);
	}

	ShowTotal() {
		this._ss.SeeTotalPrice = this._ss.SeeTotalPrice === '1' ? '0' : '1';
	}

	Heshbonit;
	HeshbonitClickPop = false;
	loadingHeshbonit = false;

	HeshbonitClick(DocDocNum, DocDocType, Company) {
		this.HeshbonitClickPop = true;
		this.loadingHeshbonit = true;
		this.Heshbonit = [];

		this._ss.GetOneTeudaAgentNew({ DocDocNum, DocDocType }, Company).subscribe({
			next: (res: any) => {
				this.loadingHeshbonit = false;
				if (typeof res === 'string') {
					return;
				}

				this.Heshbonit = res.recordset;
				setTimeout(() => {
					this.rightSideOfTableHistoriyaOrDohotPopupTable.nativeElement.scrollIntoView();
				}, 10);
			},
			error: () => {
				this.loadingHeshbonit = false;
			},
		});
	}

	CloseHeshbonitPop() {
		this.HeshbonitClickPop = false;
	}

	CloseKeybord(i) {
		this.showPresentContent(i);

		setTimeout(() => {
			this.GeneralFunction();
		}, 200);
	}

	StockAlert = false;

	CloseStockAlert() {
		this.StockAlert = false;
	}

	ProductPackOptions;
	ProductPackOptionsPop = false;
	Kamot;

	GetItemPackOptions(product: Product2) {
		const { ProductId } = product;
		if (this.hasWeightTypeUnitsBehaviors && !product.Unit) {
			return;
		}
		this.Kamot = ProductId;

		this.ProductPackOptions = [];

		this._ss.GetPackOptionsService(ProductId).subscribe({
			next: res => {
				this.ProductPackOptions = res;
				this.ProductPackOptions = this.ProductPackOptions.recordset;

				if (this.ProductPackOptions.length > 1) {
					this.ProductPackOptionsPop = true;
				}
			},
			error: err => {
				if (!Object.values(this.itempackRecord).length) return;

				const productid = ProductId.toLowerCase();

				const itempacks = this.itempackRecord[productid];

				if (!itempacks) return;

				this.ProductPackOptions = itempacks;
				this.ProductPackOptionsPop = this.ProductPackOptions.length > 1;
			},
		});
	}

	handlePopulateAndFilterMehironim() {
		let relevantProductIDGR = [];

		if (this.allGroupDiscounts.length) {
			relevantProductIDGR = [...new Set([...this.AllProductsSearch.map(prd => prd.IDGR), ...this.allGroupDiscounts.map(grp => grp.gr)])];
		}
		const relevantDiscounts = this.allGroupDiscounts.filter(grp => relevantProductIDGR.includes(grp.gr));

		const relevantPriceLists = [...new Set(relevantDiscounts.map(grp => grp.pl))];

		for (let x = 0; x < this._ss.Mehironim.length; x++) {
			const shouldGetPriceListAndDiscountFromGroupDiscounts = relevantPriceLists.includes(this._ss.Mehironim[x].pl);

			if (shouldGetPriceListAndDiscountFromGroupDiscounts) {
				this._ss.Mehironim[x].it = this._ss.Mehironim[x].it.toLowerCase();
				this.FilterMehironim.push({ ...this._ss.Mehironim[x], groupDiscount: relevantDiscounts });

				continue;
			}

			if (this._ss.OneClientInfo.CPL === this._ss.Mehironim[x].pl && this._ss.OneClientInfo.company === this._ss.Mehironim[x].cm) {
				this._ss.Mehironim[x].it = this._ss.Mehironim[x].it.toLowerCase();
				this.FilterMehironim.push(this._ss.Mehironim[x]);
			}
		}

		const productHashMap = this.AllProductsSearch.reduce((acc, product) => {
			acc[product.ProductId] = product;
			return acc;
		}, {});

		for (const mehiron of this.FilterMehironim) {
			const product = productHashMap[mehiron.it];

			if (!product) continue;

			if (product.passedCustGroup) continue;

			if (this._ss.CustomerInformation.BlockCopyCustPrice !== '1') {
				product.ShowPrice = product.Price = mehiron.prc;
			}

			product.NetPrice = product.Price * ((100 - mehiron.dsc) / 100);
			product.MinPrice = mehiron.mn;
			product.discount = +Intl.NumberFormat('he-IL', {
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
			}).format(+mehiron.dsc || 0);
			product.CPP = mehiron.prof;

			if (!mehiron.groupDiscount) continue;

			const isSameIDGRGroupDiscount = mehiron.groupDiscount.find(group => group.gr == product.IDGR && mehiron.pl === group.pl);

			if (!isSameIDGRGroupDiscount) continue;

			//If discount is 0.0001, hack by Yossi to lock pricelist
			product.ChangeDiscount = product.discount = +Intl.NumberFormat('he-IL', {
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
			}).format(+isSameIDGRGroupDiscount.dsc || 0);

			if (product.ChangeDiscount === 0) {
				product.ChangeDiscount = product.discount = mehiron.dsc;
			}

			product.NetPrice = product.ChangePrice = product.Price * ((100 - product.ChangeDiscount) / 100);
			product.passedCustGroup = true;
		}
	}

	CloseProductKmotPop() {
		this.ProductPackOptionsPop = false;
	}

	ChangeKamotProduct(ProductID, UnitPerPack, PriceChange, Unit) {
		if (PriceChange === 0) {
			PriceChange = 1;
		}

		for (let x = 0; x < this.AllProducts.length; x++) {
			if (this.AllProducts[x].ProductId === ProductID) {
				this.AllProducts[x].ItemPack = UnitPerPack;
				this.AllProducts[x].Unit = Unit;
				this.AllProducts[x].Price *= PriceChange;
				this.AllProducts[x].NetPrice *= PriceChange;
			}
		}

		for (let x = 0; x < this.AllOrder.length; x++) {
			if (this.AllOrder[x].ProductId === ProductID) {
				this.AllOrder[x].ItemPack = UnitPerPack;
				this.AllOrder[x].Price *= PriceChange;
				this.AllOrder[x].Unit = Unit;
			}
		}

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			if (this.AllProductsSearch[x].ProductId === ProductID) {
				this.AllProductsSearch[x].ItemPack = UnitPerPack;
				this.AllProductsSearch[x].Unit = Unit;
				this.AllProductsSearch[x].Price *= PriceChange;
				this.AllProductsSearch[x].NetPrice *= PriceChange;
			}
		}

		for (let x = 0; x < this.SaveSectionSearch.length; x++) {
			if (this.SaveSectionSearch[x].ProductId === ProductID) {
				this.SaveSectionSearch[x].ItemPack = UnitPerPack;
				this.SaveSectionSearch[x].Unit = Unit;
				this.SaveSectionSearch[x].Price *= PriceChange;
				this.SaveSectionSearch[x].NetPrice *= PriceChange;
			}
		}

		const order = this.AllOrder.find(ob => ob.ProductId === ProductID);

		if (order) {
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * UnitPerPack * order.NetPrice);
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}

		this.GeneralFunction();

		this.ProductPackOptionsPop = false;
	}

	ChangeKamotProductInput(ProductID) {
		const itemPackValue = +this.form.controls.ItemPackInput.value || 1;

		for (let x = 0; x < this.AllProducts.length; x++) {
			if (this.AllProducts[x].ProductId === ProductID) {
				this.AllProducts[x].ItemPack = itemPackValue;
			}
		}

		for (let x = 0; x < this.AllOrder.length; x++) {
			if (this.AllOrder[x].ProductId === ProductID) {
				this.AllOrder[x].ItemPack = itemPackValue;
			}
		}

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			if (this.AllProductsSearch[x].ProductId === ProductID) {
				this.AllProductsSearch[x].ItemPack = itemPackValue;
			}
		}

		for (let x = 0; x < this.SaveSectionSearch.length; x++) {
			if (this.SaveSectionSearch[x].ProductId === ProductID) {
				this.SaveSectionSearch[x].ItemPack = itemPackValue;
			}
		}

		const order = this.AllOrder.find(ob => ob.ProductId === ProductID);

		if (order) {
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * itemPackValue * order.NetPrice);
		}

		this.GeneralFunction();
	}

	FilterGroup = [];

	FilterGroups(mg) {
		this.Groups = [];
		this.GroupsSearch = [];

		if (this._ss.AllSavedMahlakot === null) {
			if (localStorage.getItem('AllSavedMahlakot') !== null) {
				this._ss.AllSavedMahlakot = localStorage.getItem('AllSavedMahlakot');
				this._ss.AllSavedMahlakot = JSON.parse(this._ss.AllSavedMahlakot);

				for (let x = 0; x < this._ss.AllSavedMahlakot.length; x++) {
					if (this._ss.AllSavedMahlakot[x].mg === mg) {
						this.Groups.push(this._ss.AllSavedMahlakot[x]);
					}
				}
				this.GroupsSearch = this.Groups;
			} else {
				this._ss.SecondGroupSecond().subscribe(res => {
					this._ss.AllSavedMahlakot = res;
					this._ss.AllSavedMahlakot = this._ss.AllSavedMahlakot.recordset;
					localStorage.setItem('AllSavedMahlakot', JSON.stringify(this._ss.AllSavedMahlakot));
					for (let x = 0; x < this._ss.AllSavedMahlakot.length; x++) {
						if (this._ss.AllSavedMahlakot[x].mg === mg) {
							this.Groups.push(this._ss.AllSavedMahlakot[x]);
						}
					}
					this.GroupsSearch = this.Groups;
				});
			}
		} else {
			for (let x = 0; x < this._ss.AllSavedMahlakot.length; x++) {
				if (this._ss.AllSavedMahlakot[x].mg === mg) {
					this.Groups.push(this._ss.AllSavedMahlakot[x]);
				}
			}
			this.GroupsSearch = this.Groups;
		}
	}

	OpenSanen() {
		this.showSortPopupSanen = true;

		// TODO shouldnt be manipulated directly, needs to be separated into display array and logic array
		// kept together, ex: [string, string, {object:string}, {object,string}]
		// refactor
		setTimeout(() => {
			for (let x = 0; x < this.ItemRxtraSanenArray.length; x++) {
				const element = document.getElementById(this.ItemRxtraSanenArray[x].ItemExtra1);
				if (element) element.classList.add('sanen-search-checkbox-active');
			}
		}, 150);
	}

	itemExtraObject = {
		itemExtra1: [],
		itemExtra2: [],
		itemExtra3: [],
		itemExtra4: [],
		itemExtra5: [],
	};

	itemExtraChosen = 0;

	SanenChecked(ItemExtra1) {
		window.scrollTo(0, 0);
		this.SearchErrorPop = false;
		this.SearchProductInAllProducts = true;
		this.ChoosenSection = 'מוצרים מסינון';
		this.AllProducts = [];
		this.SaveSectionSearch = [];
		this.FormReset();

		// TODO shouldnt be manipulated directly, needs to be separated into display array and logic array
		// kept together, ex: [string, string, {object:string}, {object,string}]
		// refactor to a Set<string>

		let data = this.ItemRxtraSanenArray.find(ob => ob.ItemExtra1 === ItemExtra1);

		const element = document.getElementById(ItemExtra1);

		if (data === undefined) {
			this.ItemRxtraSanenArray.push({ ItemExtra1: ItemExtra1 });

			if (element) element.classList.add('sanen-search-checkbox-active');
		} else {
			this.ItemRxtraSanenArray.splice(this.ItemRxtraSanenArray.indexOf(this.ItemRxtraSanenArray.find(ob => ob.ItemExtra1 === ItemExtra1)), 1);

			if (element) element.classList.remove('sanen-search-checkbox-active');
		}

		if (!this.ItemRxtraSanenArray.some(i => i.ItemExtra1)) {
			this.AllProducts = this.AllProductsSearch;
			this.ChoosenSection = 'מוצרים';
		} else {
			for (let x = 0; x < this.ItemRxtraSanenArray.length; x++) {
				for (let y = 0; y < this.AllProductsSearch.length; y++) {
					if (this.ItemRxtraSanenArray[x].ItemExtra1 === this.AllProductsSearch[y].ItemExtra1) {
						this.AllProducts.push(this.AllProductsSearch[y]);

						this.SaveSectionSearch.push(this.AllProductsSearch[y]);
					}
				}
			}
		}

		this.SearchErrorPop = !this.AllProducts.length;

		setTimeout(() => {
			this.GeneralFunction();
		}, 100);
	}

	clearSanen() {
		for (const object of this.ItemRxtraSanenArray) {
			if (!object.ItemExtra1) continue;
			const element = document.getElementById(object.ItemExtra1);

			if (element) element.classList.remove('sanen-search-checkbox-active');
		}

		this.form.controls.ExtraSearch.reset();

		// this.SaveSectionSearch = [];
		this.ItemExtraOne = this.ItemExtraOneSearch;
		this.ItemRxtraSanenArray = this.ItemRxtraSanenArray.filter(i => !i.ItemExtra1);
		this.AllProducts = this.AllProductsSearch;
		this.ChoosenSection = 'מוצרים';
	}

	// TODO refactor all search functions into one modular function. No need for 300 lines of the same thing

	SearchItemExtra() {
		this.ItemExtraOne = [];

		let searchInputValue = this.form.controls.ExtraSearch.value;

		if (searchInputValue) {
			searchInputValue = searchInputValue.toLowerCase();
		}

		const arrayToSearch = this.ItemExtraOneSearch;

		const arrayForDisplay = this.ItemExtraOne;

		if (!searchInputValue) {
			this.ItemExtraOne = arrayToSearch;
		}

		if (searchInputValue && searchInputValue.length <= 2) {
			for (let i = 0; i < arrayToSearch.length; i++) {
				if (arrayToSearch[i].slice(0, searchInputValue.length).toLowerCase() === searchInputValue) {
					arrayForDisplay.push(arrayToSearch[i]);
				}
			}
		}

		if (searchInputValue && searchInputValue.length > 2) {
			const splitValue = searchInputValue.split(' ');

			for (let i = 0; i < arrayToSearch.length; i++) {
				let counter = 0;

				const prodWithoutSpaces = arrayToSearch[i].replace(/\s/g, '').toLowerCase();

				// V8 optimizes this if statement
				if (typeof prodWithoutSpaces === 'string') {
					for (let j = 0; j < splitValue.length; j++) {
						if (prodWithoutSpaces && prodWithoutSpaces.indexOf(splitValue[j]) > -1) {
							counter++;
						}
					}
				}

				if (counter === splitValue.length) {
					arrayForDisplay.push(arrayToSearch[i]);
				}
			}
		}

		// TODO shouldnt be manipulated directly, needs to be separated into display array and logic array
		// kept together, ex: [string, string, {object:string}, {object,string}]
		// refactor
		setTimeout(() => {
			for (let x = 0; x < this.ItemRxtraSanenArray.length; x++) {
				if (document.getElementById(this.ItemRxtraSanenArray[x].ItemExtra1) !== null) {
					document.getElementById(this.ItemRxtraSanenArray[x].ItemExtra1).style.backgroundColor = '#38D88B';
				}
			}
		}, 150);
	}

	CloseSanen() {
		this.form.controls.ExtraSearch.reset('');
		this.ItemExtraOne = this.ItemExtraOneSearch;
		this.showSortPopupSanen = false;
	}

	MoveToDohot() {
		this.saveStateWhenNavigating('partial');

		this._ss.FromProductsToDohot = true;
		this.router.navigate(['dohot']);
	}

	MoveToDailyReport() {
		this.saveStateWhenNavigating('partial');
		this.router.navigate(['daily-report']);
	}

	BackToHome() {
		this.router.navigate(['home'], { replaceUrl: true });
	}

	NoAmount = [];

	async MoveToTeuda() {
		if (this.isRallyanceReclamation) return;

		const isCheckOk = this.performObligoOrBlockCheckOnChosenClient(false, true);

		if (!isCheckOk) {
			return;
		}

		if (this.isB2BAgent && this._ss.CustomerInformation.MinimumTotalOrderB2B && !this.isB2BAgentNoPricesAndNoParameters) {
			const shouldPrompt = /\?/.test(this._ss.CustomerInformation.MinimumTotalOrderB2B);

			const shouldCalculateCustDiscount = /%/.test(this._ss.CustomerInformation.MinimumTotalOrderB2B);

			const calc: string | number = shouldCalculateCustDiscount ? this._ss.OneClientInfo.CustDiscount : 0;

			const checkAgainst: string = this._ss.CustomerInformation.MinimumTotalOrderB2B.replace(/%\?/g, '');

			if (this.TotalSumOAllOrder * (1 - +calc / 100) < +checkAgainst) {
				if (shouldPrompt) {
					const q = prompt('סכום התעודה קטן מסכום מינימום הנדרש, האם להמשיך?');
					if (!q) return;
				} else {
					alert('סכום התעודה קטן מהסכום המינימלי: ' + checkAgainst + '. אנא הוסף מוצרים כדי להשלים את התעודה.');
					return;
				}
			}
		}

		this.NoAmount = this.AllOrder.filter(order => order.TeudaPackQuantity > 0 || order.TeudaQuantity > 0 || order.TeudaBonusQuantity > 0 || order.SpecialID);

		if (!this.NoAmount.length) {
			alert('חייב להזין כמות או בונוס');
			return;
		}

		if (this._ss.DocType === RECLAMATION_DOCTYPE && this.AllOrder.length > 1) {
			this.InOrderProducts();
			alert('הזמנת רקלמציה יכולה להכיל רק מוצר אחד, אנא בחר איזה מוצר להוריד');
			return;
		}

		if (this.hasGroupSum && !this.isGroupSumPopupOpen) {
			this.isGroupSumPopupOpen = true;
			this.prepareGroupSumDisplay();
			return;
		}

		// check must-include-products
		const mustIncludeCheck = this._mustIncludeProducts.handleCheckMustIncludeProducts(this.AllOrder);

		switch (mustIncludeCheck.result) {
			case 'ok':
				break;
			case 'stop':
				this._alert.customAlert([`לא ניתן לסיים ללא מוצרי חובה.`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);
				return;
			case 'alert':
				const promptUser = await this._alert.customConfirmAlert([`שים לב, ישנם מוצרי חובה לתעודה זו. האם להמשיך?`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);

				if (!promptUser) return;
				break;
			default:
				break;
		}

		this.ShowMainTeudaIcon = false;
		this.saveStateWhenNavigating('full');

		if (this._ss.OrderInProcess !== false) {
			this.ShowMainTeudaIcon = true;
			alert('תעודה בתהליך אנא המתן');
			return;
		}

		this.AllOrder = this.NoAmount;
		for (let x = 0; x < this.AllOrder.length; x++) {
			for (let y = 0; y < this.AllProductsSearch.length; y++) {
				if (this.AllOrder[x].ProductId === this.AllProductsSearch[y].ProductId) {
					this.AllOrder[x].PermPrice = this.AllProductsSearch[y].ShowPrice;
				}
			}
		}
		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		this._alert.handleAlertLogic(MsgType.moveToTeudaScreen.msgType);
		this._alert.handleAlertLogic(MsgType.moveToTeudaScreenFromHour.msgType);

		this.router.navigate(['teuda']);
	}

	BackToClients() {
		this.router.navigate(['clients']);
	}

	performObligoOrBlockCheckOnChosenClient(checkPassword = true, useParameterToBlockFromSending = false): boolean {
		if (this._ss.OneClientInfo.BCU === 1 && checkPassword) {
			if (this._ss.CustomerInformation.TemporaryPassword === '1') {
				const input = prompt('לקוח זה חסום. אם ידועה לך סיסמה אנא הקש אותה כעת.');

				(async () => {
					if (
						input !=
						(await this._util.getPasswordPIN({
							AgentID: this._ss.AgentIdConnected,
							CompanyNumber: this._ss.OneClientInfo.ClientId,
							company: this._ss.CompanyNum,
							action: Password.bypassBlockedClient,
						}))
					) {
						alert('סיסמה שגויה');
						this.router.navigate(['clients']);
						return false;
					}

					return true;
				})();
			} else {
				alert('לקוח חסום');
				this.router.navigate(['clients']);
				return false;
			}
		}

		if (this._ss.OneClientInfo.COD + this._ss.OneClientInfo.COC + (+this._ss.OneClientInfo.AddObligo || 0) < this._ss.OneClientInfo.CMC || this._ss.OneClientInfo.BCO === 0) {
			return true;
		}
		if (this._ss.OneClientInfo.BCO === 1 && this._ss.NoObligoCheck !== 1) {
			if (useParameterToBlockFromSending && this.blockBeforeSendTeudaIfObligoWarning) {
				alert('חסימה עקב חריגת אובליגו');
				// history.back();
				return false;
			}
			alert('התראה: חריגת אובליגו ');
		}

		if (this._ss.OneClientInfo.BCO === 2 && this._ss.NoObligoCheck !== 1) {
			alert(' חסימה עקב חריגת אובליגו ');
			this.router.navigate(['clients']);
			return false;
		}

		return true;
	}

	MoveToHovot() {
		this.saveStateWhenNavigating('full');

		this._ss.BackFromClientCard = true;

		this._ss.GetOneClientInfoDebt(this._ss.OneClientInfo.ClientId).subscribe((res: any) => {
			this._ss.OneClientInfoDebt = res.recordset;

			this.router.navigate(['client-hovot']);
		});
	}

	MoveToClientCard() {
		this.saveStateWhenNavigating('full');

		this._ss.BackFromClientCard = true;
		if (this.isB2BAgent) {
			this._ss.AutoMoveHovot = 1;
		}
		this.router.navigate(['client-card']);
	}

	SearchClear() {
		this.form.controls.HearaForProduct.reset();
		this.form.controls.ChangeDiscount.reset();
		this.form.controls.ChangePrice.reset();
		this.form.controls.Yehidot.reset();
		this.form.controls.Arizot.reset();
		this.form.controls.ProductSearch.reset();
		this.form.controls.SectionSearch.reset();
		this.form.controls.Bonus.reset();
		this.form.controls.ExtraSearch.reset();
		this.form.controls.NewProductAdd.reset();
		this.form.controls.NewProductAddKamot.reset();
		this.form.controls.NewProductAddPrice.reset();
		this.form.controls.NewProductAddHeara.reset();

		this.AllProducts = [];

		this.AllProducts = this.SearchProductInAllProducts ? this.SaveSectionSearch : this.AllProductsSearch;

		if (this.selectedGroup.GroupId) {
			this.AllProducts = this.AllProducts.filter(product => product.IGC === this.selectedGroup.GroupId);
		}
		this.SearchErrorPop = !this.AllProducts.length;

		setTimeout(() => {
			document.getElementById('productsearch').focus();
			this.GeneralFunction();
		}, 100);
	}

	ScrollUp() {
		window.scrollTo(0, 0);
	}

	prevent(e) {
		e.preventDefault();
	}

	FormReset() {
		this.form.controls.ItemPackInput.reset();
		this.form.controls.Yehidot.reset();
		this.form.controls.Arizot.reset();
		this.form.controls.Bonus.reset();
		this.form.controls.BonusArizot.reset();
		this.form.controls.ChangePrice.reset();
		this.form.controls.ChangeBasePrice.reset();
		this.form.controls.ChangeDiscount.reset();
		this.form.controls.HearaForProduct.reset();
		this.form.controls.SectionSearch.reset();
		this.form.controls.Yehidot.reset();
		this.form.controls.Arizot.reset();
	}

	ExtraMlayClient = false;
	RefreshMlay() {
		this.virtualScroll.scrollToIndex(0);
		this.ExtraMlayClient = true;
		if (this._ss.burgerMenu) this.showHideMenu(false);

		this.showHideSortBlock(false);

		this.FormReset();
		this._ss.RefreshMlayService().subscribe({
			next: res => {
				this.stockSortDescending = true;

				this.ExtraMlayClient = false;
				if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset.length) {
					return;
				}
				const mlayResponseHash = res.recordset.reduce((prev, curr) => {
					prev[curr.it] = curr;
					return prev;
				}, {});

				for (let x = 0; x < this.AllProductsSearch.length; x++) {
					if (mlayResponseHash[this.AllProductsSearch[x].ProductId]) {
						this.AllProductsSearch[x].Stock = mlayResponseHash[this.AllProductsSearch[x].ProductId].mly;
					}
				}

				// TODO Might be redundant to loop here, since the objects are probably coming in from the same place.

				for (let x = 0; x < this.AllProducts.length; x++) {
					if (mlayResponseHash[this.AllProducts[x].ProductId]) {
						this.AllProducts[x].Stock = mlayResponseHash[this.AllProducts[x].ProductId].mly;
					}
				}

				this.stockSortDescending = true;
			},
			error: () => {
				this.ExtraMlayClient = false;
			},
		});
	}

	MlayMinusPlus() {
		this.virtualScroll.scrollToIndex(0);
		this.isLoadingPopupOpen = true;

		this.SearchErrorPop = false;
		this.showHideSortBlock(false);
		this.AllProducts = [];
		this.SearchProductInAllProducts = false;
		this.ChoosenSection = 'מוצרים';

		this.FormReset();

		const bool = this.stockSortDescending;

		// Don't mutate original Array with .sort()
		this.AllProducts = [...this.AllProductsSearch].sort(function (a, b) {
			return bool ? a.Stock - b.Stock : b.Stock - a.Stock;
		});

		this.stockSortDescending = !this.stockSortDescending;

		setTimeout(() => {
			// this.AllProducts = this.AllProductsSearch
			this.isLoadingPopupOpen = false;
			this.GeneralFunction();
		}, 500);

		this.showHideSortPopup(false, 'remember');
	}

	InOrderProducts() {
		if (this.isRallyanceReclamation) return;
		this.virtualScroll.scrollToIndex(0);
		window.scrollTo(0, 0);
		this.SearchErrorPop = false;
		this.SearchProductInAllProducts = true;
		this.ChoosenSection = 'מוצרים בסל';
		this.AllProducts = [];
		this.SaveSectionSearch = [];
		this.FormReset();

		this.LastBoughtOn = false;

		for (let x = 0; x < this.AllOrder.length; x++) {
			for (let y = 0; y < this.AllProductsSearch.length; y++) {
				if (this.AllProductsSearch[y].ProductId === this.AllOrder[x].ProductId) {
					this.SaveSectionSearch.push(this.AllProductsSearch[y]);
					break;
				}
			}
		}

		for (let x = 0; x < this.SaveSectionSearch.length; x++) {
			this.AllProducts.push(this.SaveSectionSearch[x]);
		}

		const allProductsSet = new Set(this.AllProducts.map(product => product.IGC));

		this.Groups = this.GroupsSearch.filter(group => allProductsSet.has(group.GroupId));

		for (let x = 0; x < this.AllProducts.length; x++) {
			this.AllProducts[x].present.showContent = false;
		}
		this.GeneralFunction();

		setTimeout(() => {
			this.SearchErrorPop = !this.AllProducts.length;
		}, 200);
	}

	preventPropagation(e: Event) {
		e.stopPropagation();
	}

	InMivzaProducts() {
		this.virtualScroll.scrollToIndex(0);
		window.scrollTo(0, 0);
		this.SearchErrorPop = false;
		this.SearchProductInAllProducts = true;
		this.ChoosenSection = 'מוצרים במבצע';
		// this.AllProducts = [];
		// this.SaveSectionSearch = [];
		this.FormReset();

		this.LastBoughtOn = false;

		const items = this.filterOutItems(this._ss.CustomerInformation.SpecialOnlyIfRemarkContains === '1');

		this.AllProducts = [...items];
		this.SaveSectionSearch = [...items];

		this.GeneralFunction();

		setTimeout(() => {
			this.SearchErrorPop = !this.AllProducts.length;
		}, 200);
	}

	filterOutItems(onlyIfHasTextIn: boolean): any[] {
		const array = [];
		if (onlyIfHasTextIn) {
			for (let y = 0; y < this.AllProductsSearch.length; y++) {
				if ((this.AllProductsSearch[y].RE && this.AllProductsSearch[y].RE.includes('מבצע')) || this.AllProductsSearch[y].wizmivza === '1' || this.AllProductsSearch[y].mobmivza === '1') {
					this.AllProductsSearch[y].present.showContent = false;
					array.push(this.AllProductsSearch[y]);
				}
			}
			return array;
		}

		for (let y = 0; y < this.AllProductsSearch.length; y++) {
			if (this.AllProductsSearch[y].RE?.length || this.AllProductsSearch[y].wizmivza === '1' || this.AllProductsSearch[y].mobmivza === '1') {
				this.AllProductsSearch[y].present.showContent = false;
				array.push(this.AllProductsSearch[y]);
			}
		}
		return array;
	}

	ItemExtraClicked(ItemExtra1) {
		window.scrollTo(0, 0);

		this.SearchErrorPop = false;
		this.SearchProductInAllProducts = true;
		this.ChoosenSection = ItemExtra1;
		this.AllProducts = [];
		this.SaveSectionSearch = [];

		this.form.reset();

		this.form.controls.SearchOptions.reset('');

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			if (this.AllProductsSearch[x].ItemExtra1 === ItemExtra1) {
				this.AllProducts.push(this.AllProductsSearch[x]);
			}
		}

		for (let x = 0; x < this.AllProductsSearch.length; x++) {
			if (this.AllProductsSearch[x].ItemExtra1 === ItemExtra1) {
				this.SaveSectionSearch.push(this.AllProductsSearch[x]);
			}
		}

		for (let x = 0; x < this.AllProducts.length; x++) {
			this.AllProducts[x].present.showContent = false;
		}

		this.GeneralFunction();

		setTimeout(() => {
			this.SearchErrorPop = !this.SaveSectionSearch.length;
		}, 500);
	}

	selectedGroup = { GroupId: '', GroupName: '' };
	selectedMainGroup = { GroupId: '', GroupName: '' };

	ShowChosenMahlaka(GroupId, GroupName, mGroup = false) {
		if (mGroup) {
			this.handleShowChosenMahlakaMGroup({ GroupId, GroupName });
			return;
		}

		this.virtualScroll.scrollToIndex(0);
		if (GroupId === 'ALL_DEPARTMENTS') {
			if (this.hasMGroups && this.showDepartmentsList) {
				this.AllProducts = this.AllProductsSearch.filter(prd => prd.MGroup === this.selectedMainGroup.GroupId);
				this.showHideDepartmentsPopup(false);
				return;
			}
			this.KolHamozarim();
			return;
		}
		this.selectedGroup = { GroupId, GroupName };
		const sectionSearchValue = this.form.controls.SectionSearch.value;

		const grp = this.GroupsSearch.find(gs => gs.GroupId === GroupId);
		this.departmentsWrapperScrollY = this.getDepartmentsWrapperScrollerCurrentlyInDOM()?.nativeElement?.scrollTop || 0;

		if (grp) {
			grp.visitedThisSession = true;
		}

		if (this.HasMahlakotMain === 1) {
			this.FilterGroups(GroupId);
			this.HasMahlakotMain = 0;
			this.form.controls.SectionSearch.reset();
			return;
		}

		window.scrollTo(0, 0);
		this.SearchErrorPop = false;
		this.SearchProductInAllProducts = true;
		this.ChoosenSection = GroupName;
		this.AllProducts = [];
		this.SaveSectionSearch = [];
		const SaveMahlakotShichim = [];

		this.form.reset();

		this.form.controls.SearchOptions.reset('');

		this.form.controls.SectionSearch.setValue(sectionSearchValue);

		if (this.LastBoughtOn === false) {
			this.SaveSectionSearch = [];

			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (this.AllProductsSearch[x].IGC === GroupId) {
					this.AllProducts.push(this.AllProductsSearch[x]);
					this.SaveSectionSearch.push(this.AllProductsSearch[x]);
				}
			}
		} else {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (this.AllProductsSearch[x].NumOfTimes > 0) {
					this.SaveSectionSearch.push(this.AllProductsSearch[x]);
				}
			}

			for (let x = 0; x < this.SaveSectionSearch.length; x++) {
				if (this.SaveSectionSearch[x].IGC === GroupId) {
					SaveMahlakotShichim.push(this.SaveSectionSearch[x]);

					this.AllProducts.push(this.SaveSectionSearch[x]);
				}
			}

			this.SaveSectionSearch = SaveMahlakotShichim;
		}

		for (let x = 0; x < this.AllProducts.length; x++) {
			this.AllProducts[x].present.showContent = false;
		}
		this.GeneralFunction();

		setTimeout(() => {
			if (this.GroupsSearch.length === 0) {
				this.SearchErrorPop = true;
			}
		}, 500);

		setTimeout(() => {
			if (this.SaveSectionSearch.length === 0) {
				this.SearchErrorPop = true;
			}
		}, 500);
	}

	handleShowChosenMahlakaMGroup(input: { GroupId: string; GroupName: string }) {
		if (!this.showDepartmentsList) {
			this.Groups = this.MGroupsRecord[input.GroupId].sub;
			this.selectedMainGroup = input;
			this.showDepartmentsList = !this.showDepartmentsList;
		} else {
			this.handleShowChosenMahlakaMGroupSubGroup(input);
			this.showHideDepartmentsPopup(false);
		}
	}

	handleShowChosenMahlakaMGroupSubGroup(input: { GroupId: string; GroupName: string }) {
		this.AllProducts = this.AllProductsSearch.filter(product => product.IGC === input.GroupId && product.MGroup === this.selectedMainGroup.GroupId);

		this.ChoosenSection = input.GroupName;
	}

	handleFilterButton(mode: 'popularOrAll' | 'basket') {
		if (mode === 'basket') {
			this.InOrderProducts();
			return;
		}

		if (this.LastBoughtOn) {
			this.KolHamozarim();
		} else {
			let num = 0;

			if (this._ss.CustomerInformation.PopularDefault) {
				num = +this._ss.CustomerInformation.PopularDefault;
			}

			this.Shchichim(num);
		}

		if (this.selectedGroup.GroupName) {
			const { GroupName, GroupId } = this.selectedGroup;
			this.AllProducts = this.AllProducts.filter(prod => prod.IGC === GroupId);
			this.ChoosenSection = GroupName;

			this.showHideSortBlock(true);
		}
	}

	toggleSearchOptionsPopup(action: 'open' | 'close') {
		this.isSearchOptionsPopupOpen$.next(action === 'open');
	}

	closePopupOnlyIfTargetIsOutside(e: MouseEvent | PointerEvent) {
		const target = e.target;

		if (target instanceof HTMLElement && (target.classList.contains('popup') || target.classList.contains('overlay'))) {
			this.toggleSearchOptionsPopup('close');
		}
	}

	acknowledgeSearchPreferenceChanges(newValue: SearchOptions) {
		this.form.controls.SearchOptions.setValue(newValue);
	}

	Shchichim(num: number) {
		if (this.isRallyanceReclamation) return;

		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		this.virtualScroll.scrollToIndex(0);
		window.scrollTo(0, 0);
		this.SearchErrorPop = false;
		this.SearchProductInAllProducts = true;
		this.AllProducts = [];

		this.FormReset();

		this.LastBoughtOn = true;

		if (num === 0) {
			const hasDefaultOrderBy = this.AllProductsSearch[0]?.DefaultOrderBY || this.AllProductsSearch[0]?.DefaultOrderBy;

			this.SaveSectionSearch = [];

			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (this.AllProductsSearch[x].NumOfTimes > 0) {
					this.SaveSectionSearch.push(this.AllProductsSearch[x]);
				}
			}

			if (hasDefaultOrderBy && this._ss.CustomerInformation.SortByStock !== '1') {
				this.SaveSectionSearch.sort((a, b) => {
					return Intl.Collator('he', opt).compare(a.DefaultOrderBY || a.DefaultOrderBy, b.DefaultOrderBY || b.DefaultOrderBy);
				});
			} else {
				this.SaveSectionSearch.sort(function (a, b) {
					return b.NumOfTimes - a.NumOfTimes;
				});
			}

			this.AllProducts = this.SaveSectionSearch;

			this.ChoosenSection = 'פופולרי';

			localStorage.setItem('SeeDateOrNumOfTimes', 'false');

			this.SearchProducts();
		}

		if (num === 1) {
			this.SaveSectionSearch = this.SaveSectionSearchOnlySpecPricessOrderByDate;
			this.AllProducts = this.SaveSectionSearch;

			localStorage.setItem('SeeDateOrNumOfTimes', 'true');

			this.ChoosenSection = 'תאריך';

			this.AllProducts = this.SaveSectionSearch = this.SaveSectionSearch.filter(prod => prod.docquantity > 0);

			this.SearchProducts();
		}

		if (num === 2) {
			this.AllProducts = this.SaveSectionSearch = [...this.SaveSectionSearchOnlySpecPricessOrderByDate].sort((a, b) => new Intl.Collator('he', opt).compare(a.Product_Name, b.Product_Name));

			this.AllProducts = this.SaveSectionSearch = this.SaveSectionSearch.filter(prod => prod.docquantity > 0);

			this.ChoosenSection = "א'-ב'";
			this.SearchProducts();
		}

		if (num === 3) {
			this.AllProducts = this.SaveSectionSearch = [...this.SaveSectionSearchOnlySpecPricessOrderByDate].sort((a, b) => new Intl.Collator('he', opt).compare(a.ProductId, b.ProductId));

			this.AllProducts = this.SaveSectionSearch = this.SaveSectionSearch.filter(prod => prod.docquantity > 0);
			this.ChoosenSection = 'מק"ט';

			this.SearchProducts();
		}

		if (this.HasMainGroup !== '1') {
			const saveSectionSet = new Set(this.SaveSectionSearch.map(product => product.IGC));

			this.Groups = this.GroupsSearch.filter(group => saveSectionSet.has(group.GroupId));
		}

		for (let x = 0; x < this.AllProducts.length; x++) {
			this.AllProducts[x].present.showContent = false;
		}
		this.GeneralFunction();

		setTimeout(() => {
			if (this.AllProducts.length === 0) {
				this.SearchErrorPop = true;
			}
		}, 200);
	}

	KolHamozarim(options?: { sortAlphabetically: boolean }) {
		this.virtualScroll.scrollToIndex(0);
		this.SearchErrorPop = false;

		this.showHideSortBlock(false);

		this.SearchProductInAllProducts = false;
		this.ChoosenSection = 'מוצרים';
		this.showDepartmentsList = false;
		this.AllProducts = [];
		this.FormReset();

		this.LastBoughtOn = false;

		this.AllProducts = this.AllProductsSearch;

		if (this._b2b.isB2BAgentAndNoDiscountsAndDates$.value) {
			this._change.detach();
			this.handleSortByNumOfTimes('visible');
			this._change.detectChanges();
			this._change.reattach();
		}

		if (options?.sortAlphabetically) {
			this._change.detach();
			this.AllProductsSearch.sort((a, b) => (a.Product_Name || '').trim().localeCompare(b.Product_Name || ''));
			this.AllProducts = [...this.AllProductsSearch];
			this._change.detectChanges();
			this._change.reattach();
		}

		if (!this.isRallyanceReclamation) {
			for (let x = 0; x < this.AllProducts.length; x++) {
				this.AllProducts[x].present.showContent = false;
			}
		}

		this.SearchProducts();

		if (!this.isRallyanceReclamation) {
			this.Groups = [...this.GroupsSearch];
		}

		setTimeout(() => {
			this.GeneralFunction();
		}, 100);
	}

	handleLastBoughtOnFilter(range: 30 | 60) {
		const dateRange = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * range);
		this.AllProducts = this.AllProductsSearch.filter(
			ap => new Date((ap.DocCreateDateDisplay && ap.DocCreateDateDisplay.split('-').reverse().join('-')) || 0).getTime() < dateRange.getTime() && ap.docquantity > 0,
		).sort((a, b) => b.NumOfTimes - a.NumOfTimes);

		// this.AllClients.sort((a,b) => new Date(b.Ldt || 0).getTime() - new Date(a.Ldt || 0).getTime())

		// this._ss.AllClientsHoldSearch = range+' יום';
	}

	// product.present.showContent  - refactor into products state. reset into empty object instead of looping through products
	// TODO Implement this
	show: { [key: string]: boolean } = {};

	indexClose = null;

	CloseopenSelect(index: number) {
		this.form.controls.ChangeDiscount.reset();
		this.form.controls.ChangePrice.reset();
		this.form.controls.Bonus.reset();
		this.form.controls.HearaForProduct.reset();
	}

	ShowMlay: { [key: number]: boolean } = {};

	openSelectMlay(index: number) {
		if (this._ss.CustomerInformation.HideStock === '4' || (this._ss.CustomerInformation.HideStock === '5' && this.isB2BAgent)) return;

		this.ShowMlay[index] = !this.ShowMlay[index];
	}

	GeneralFunction() {
		if (this._ss.DocType === null || this._ss.DocTypeName === null || this._ss.DocType === undefined || this._ss.DocTypeName === undefined) {
			alert('ארעה שגיאה בסוג התעודה,אנא צא למסך הלקוחות ונסה שנית');
			this.router.navigate(['clients']);
			return;
		}

		for (let x = 0; x < this.AllOrder.length; x++) {
			if (this._ss.MivzaA !== null && !this.AllOrder[x].passedCustGroup && typeof this.AllOrder[x].SpecialID !== 'number' && !this.AllOrder[x].dontTouchPriceOrDiscount) {
				for (let y = 0; y < this._ss.MivzaA.length; y++) {
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 1 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}

							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this.AllOrder[x].PermPrice;
							this.AllOrder[x].ChangePrice = this.AllOrder[x].PermPrice;
							this.AllOrder[x].ChangeDiscount = 0;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 2 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 3 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 4 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 5 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 6 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 7 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 8 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 9 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
					if (
						this._ss.MivzaA[y].ItemCode.toLowerCase() === this.AllOrder[x].ProductId &&
						this._ss.MivzaA[y].Level === 10 &&
						this.AllOrder[x].IfChangePriceOrDisc === OrderState.noChangeToProduct
					) {
						if (this.AllOrder[x].TeudaQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						} else if (this.AllOrder[x].TeudaPackQuantity >= this._ss.MivzaA[y].FromQty && this.AllOrder[x].TeudaPackQuantity < this._ss.MivzaA[y].ToQty) {
							if (this._ss.OneClientInfo.HM === 1) {
								this.AllOrder[x].TeudaBonusQuantity = this._ss.MivzaA[y].BonusGet;
								// this.AllOrder[x].HasMivza = true;
							}
							this.AllOrder[x].Price = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangePrice = this._ss.MivzaA[y].NetPriceGet;
							this.AllOrder[x].ChangeDiscount = this._ss.MivzaA[y].DiscountGet;

							localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
						}
					}
				}
			}
		}

		//MIVZA

		setTimeout(() => {
			this.TotalSumOAllOrder = 0;
			if (this.AllOrder.length !== 0) {
				this.totalQuantities.quantity = 0;
				this.totalQuantities.packQuantity = 0;

				for (let x = 0; x < this.AllOrder.length; x++) {
					const order = this.AllOrder[x];

					order.TeudaQuantity = Math.min(order.TeudaQuantity, MAXIMUM_QUANTITY);
					order.TeudaPackQuantity = Math.min(order.TeudaPackQuantity, MAXIMUM_PACK_QUANTITY);

					if (typeof order.SpecialID === 'number') {
						if (this.hasConcentratedSpecials && (order.SpecialType === specialsTypesEnum.BUY_GET || order.SpecialType === specialsTypesEnum.BUY_GET_MAIN_PRODUCT)) {
							this.TotalSumOAllOrder += order.TeudaTotalRow = order.TeudaItemNetPrice;

							this.totalQuantities.packQuantity += order.special[0].TeudaPackQuantity;
							this.totalQuantities.quantity += order.special[0].TeudaQuantity + order.special[0].TeudaBonusQuantity;

							continue;
						}
						this.TotalSumOAllOrder += order.TeudaTotalRow = normalizeNumberToMaxThreeDecimal(order.TeudaItemNetPrice * order.quantity);
						if (!this.specialsInCart[order.SpecialID]) {
							this.specialsInCart[order.SpecialID] = { special: this.allSpecials.filter(sp => sp.SpecialID === order.SpecialID), quantity: 0 };
						}

						const inCart = this.specialsInCart[order.SpecialID];

						inCart.quantity = order.quantity;

						this.handlePopulateProductHash();

						const teudaQuantities = inCart.special.map(spc => ({
							ItemPack: this.productsHash[spc.ProductID.toLowerCase()].ItemPack,
							TeudaQuantity: spc.TeudaQuantity,
							TeudaPackQuantity: spc.TeudaPackQuantity,
						}));

						this.totalQuantities.packQuantity += teudaQuantities.reduce((p, c) => p + +c.TeudaPackQuantity, 0) * inCart.quantity;

						this.totalQuantities.quantity += teudaQuantities.reduce((p, c) => p + +c.TeudaQuantity + c.TeudaPackQuantity * c.ItemPack, 0) * inCart.quantity;

						continue;
					}

					if (this.alternativeProductCalculations) {
						const price = order.NetPrice || order.Price || order.DocNetPrice || order.PermPrice;

						order.ArizotPrice = normalizeNumberToMaxTwoDecimal(price * (order.TeudaPackQuantity * order.ItemPack));
						order.YehidotPrice = normalizeNumberToMaxTwoDecimal(price * order.TeudaQuantity);
					} else {
						order.ArizotPrice = normalizeNumberToMaxTwoDecimal((order.NetPrice || order.Price) * (order.TeudaPackQuantity * order.ItemPack));
						order.YehidotPrice = normalizeNumberToMaxTwoDecimal((order.NetPrice || order.Price) * order.TeudaQuantity);
						if (order.weightMode === 'singles') {
							order.YehidotPrice = normalizeNumberToMaxTwoDecimal((order.NetPrice || order.Price) * (order.TeudaQuantity * (order.Weight || 1)));
						}
					}
					order.TeudaTotalRow = order.ArizotPrice + order.YehidotPrice;
					this.TotalSumOAllOrder += order.TeudaTotalRow;

					if (order.TeudaQuantity || (order.TeudaPackQuantity && order.ItemPack)) {
						this.totalQuantities.quantity += order.TeudaQuantity + order.TeudaPackQuantity * order.ItemPack;
					}
					this.totalQuantities.packQuantity += order.TeudaPackQuantity || 0;
				}

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

				if (this.hasGroupSum) {
					this.refreshGroupSumStore();
					this.handleUpdateGroupSumStore();
				}

				setTimeout(() => {
					for (let x = 0; x < this.AllOrder.length; x++) {
						const order = this.AllOrder[x];

						if (typeof order.SpecialID === 'number') continue;

						let productInAllProducts: any = null;

						if (this._ss.allConcentratedProducts.length) {
							productInAllProducts = this.AllProductsSearch.find(ob => ob.ProductId === order.ProductId);
						} else {
							productInAllProducts = this.AllProducts.find(ob => ob.ProductId === order.ProductId);
						}

						let price = 0;

						if (this.alternativeProductCalculations) {
							price = order.NetPrice || order.Price || order.DocNetPrice || order.PermPrice || 0;
						}

						if (productInAllProducts) {
							productInAllProducts.TeudaTotalRow = order.TeudaTotalRow;
							productInAllProducts.TeudaQuantity = order.TeudaQuantity;
							productInAllProducts.TeudaPackQuantity = order.TeudaPackQuantity;
							productInAllProducts.TeudaBonusQuantity = order.TeudaBonusQuantity;
							productInAllProducts.ChangePrice = order.ChangePrice || price;
							productInAllProducts.ChangeDiscount = order.ChangeDiscount;
							productInAllProducts.NetPrice = order.NetPrice || order.Price || price;
							//  * (1 - ((order.ChangeDiscount || 0)/100))
							productInAllProducts.Heara = order.TeudaItemRemark;
							if (+order.PriceInInput) {
								productInAllProducts.Price = order.PriceInInput;
							}

							if (order.Unit) {
								productInAllProducts.Unit = order.Unit;
							}

							if (order.basePriceInputted) {
								productInAllProducts.basePriceInputted = order.basePriceInputted;
							}
						}

						this.concentratedProductsUnderMain$.next(this.concentratedProductsUnderMain$.value);

						if (!this.testpop) continue;

						const productInPopup = this.testpop.find(ob => ob.ProductId === order.ProductId);

						if (productInPopup) {
							productInPopup.TeudaTotalRow = order.TeudaTotalRow;
							productInPopup.TeudaQuantity = order.TeudaQuantity;
							productInPopup.TeudaPackQuantity = order.TeudaPackQuantity;
							productInPopup.TeudaBonusQuantity = order.TeudaBonusQuantity;
							productInPopup.ChangePrice = order.ChangePrice || price;
							productInPopup.ChangeDiscount = order.ChangeDiscount;
							productInPopup.NetPrice = order.NetPrice || order.Price || price;
							//  * (1 - ((order.ChangeDiscount || 0)/100))
							productInPopup.Heara = order.TeudaItemRemark;
							if (+order.PriceInInput) {
								productInPopup.Price = order.PriceInInput;
							}
							if (order.Unit) {
								productInPopup.Unit = order.Unit;
							}
							if (order.basePriceInputted) {
								productInPopup.basePriceInputted = order.basePriceInputted;
							}
						}
					}

					this.checkFaultyProduct();

					// localStorage.setItem("AllProducts", compress(JSON.stringify(this.AllProducts)))
				}, 100);
			} else {
				const lsIdentifier = '' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company;

				const existing = localStorage.getItem('AllOrder' + lsIdentifier);

				this.concentratedProductsUnderMain$.next(this.concentratedProductsUnderMain$.value);

				if (existing) {
					this.clearAllOrderLocalStorage(lsIdentifier);

					this.FormReset();
				}
			}

			if (this.hasSpecials) {
				this.refreshSpecials();
			}
		}, 100);
	}

	DeleteTeudaProducts() {
		this.AreYouSureDeletePop = false;

		this.AllOrder = [];
		this.specialsInCart = {};
		for (let x = 0; x < this.AllProducts.length; x++) {
			this.AllProducts[x].TeudaTotalRow = 0;
			this.AllProducts[x].TeudaQuantity = 0;
			this.AllProducts[x].TeudaPackQuantity = 0;
			this.AllProducts[x].TeudaBonusQuantity = 0;
			this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
			this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
			this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
			this.AllProducts[x].Heara = '';
		}

		if (this._ss.allConcentratedProducts.length) {
			for (const product of this.AllProductsSearch) {
				product.TeudaTotalRow = 0;
				product.TeudaQuantity = 0;
				product.TeudaPackQuantity = 0;
				product.TeudaBonusQuantity = 0;
				product.Heara = '';
			}
		}

		if (this.GroupsSearch?.length) {
			for (const group of this.GroupsSearch) {
				group.visitedThisSession = false;
				delete group.inOrder;
			}
		}

		this.isDuplicateOrder = false;
		this.duplicateOrderTeudaRemark = '';

		setTimeout(() => {
			this.KolHamozarim();
		}, 100);
	}

	Mehiron(product: Product2) {
		const { Stock, ProductId, Price, ItemPack, MinPrice, ShowPrice, BD, BP, BS } = product;

		this.FormReset();

		if (
			(BD === 1 && BP === 1) ||
			this._ss.CustomerInformation.BlockCopyCustPrice === '1' ||
			(MinPrice > ShowPrice && this.isBelowMinActive(product, 'any') && this._ss.CheckMinPrice !== 1) ||
			this.shouldDisablePriceAndDiscountInput ||
			this.shouldDisableChangingInputs ||
			this.shouldDisableChangingClickingFunctions
		)
			return;

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		if (!order) {
			if ((BS === 1 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			} else {
				const overwrite = {
					IfChangePriceOrDisc: OrderState.changedPrice,
					ChangePrice: ShowPrice,
					Price: ShowPrice,
					PriceInInput: ShowPrice,
					NetPrice: ShowPrice,
				};

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);

				product.Price = product.NetPrice = ShowPrice;
				order.ChangeDiscount = 0;
				order.PriceInInput = ShowPrice;

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		} else {
			if (ShowPrice !== null) {
				//@ts-ignore
				if (ShowPrice === 0 || ShowPrice === '' || ShowPrice === undefined) {
					order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
					order.ChangePrice = Price;
					order.Price = Price;

					order.Price = product.Price = order.PriceInInput = product.ShowPrice;
				} else {
					order.IfChangePriceOrDisc = OrderState.changedPrice;
					order.ChangeDiscount = 0;

					order.ChangePrice = order.Price = order.NetPrice = product.Price = product.NetPrice = order.PriceInInput = ShowPrice;
				}
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		setTimeout(() => {
			if (ShowPrice === null || ShowPrice === undefined) {
				order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
				order.ChangePrice = 0;
				order.Price = Price;

				order.Price = this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].ShowPrice;
				order.PriceInInput = this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].ShowPrice;
				this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].Price =
					this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].ShowPrice;

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}, 200);

		setTimeout(() => {
			order.ArizotPrice = order.TeudaPackQuantity * ItemPack;
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.ArizotPrice * order.NetPrice);

			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.NetPrice);
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

			this.GeneralFunction();
		}, 250);
	}

	AddedSuccess = false;
	CloseAddedSuccess() {
		this.AddedSuccess = false;
		this.form.controls.NewProductAdd.reset();
		this.form.controls.NewProductAddPrice.reset();
		this.form.controls.NewProductAddKamot.reset();
		this.form.controls.NewProductAddHeara.reset();
	}

	AddAhuz = 0;
	AddPrice = 0;
	AddArizot = 0;
	AddKamot = 0;
	PriceAhozTotal;
	HelpCantChangeAdd = 0;

	AddNewProduct(product: Product2) {
		const { ProductId, Product_Name, ItemPack, Price } = product;

		const range = { min: 1, max: 1000000 };
		const delta = range.max - range.min;

		const randomNumber = Math.round(range.min + Math.random() * delta);

		this.AddAhuz = +this.form.controls.NewProductAddPriceAhuz.value || 0;

		this.AddArizot = +this.form.controls.NewProductAddArizot.value || 0;

		this.AddPrice = +this.form.controls.NewProductAddPrice.value || Price || 0;

		this.AddKamot = +this.form.controls.NewProductAddKamot.value || 0;

		setTimeout(() => {
			this.PriceAhozTotal = this.AddPrice * this.AddAhuz;
			this.PriceAhozTotal /= 100;
			this.HelpCantChangeAdd = this.AddPrice - this.PriceAhozTotal;

			const overwrite = {
				ProductId: JSON.stringify(randomNumber) + '12345',
				Product_Name: String(this.form.controls.NewProductAdd.value || Product_Name),
				Price: this.HelpCantChangeAdd,
				TeudaQuantity: this.AddKamot,
				TeudaPackQuantity: this.AddArizot,
				YehidotPrice: normalizeNumberToMaxTwoDecimal(this.HelpCantChangeAdd * this.AddKamot),
				ArizotPrice: normalizeNumberToMaxTwoDecimal(this.AddArizot * (ItemPack * this.HelpCantChangeAdd)),
				TeudaItemRemark: this.form.controls.NewProductAddHeara.value,
				ChangePrice: this.HelpCantChangeAdd,
				ChangeDiscount: this.AddAhuz,
				Duplicate: ProductId,
				PriceInInput: +this.form.controls.NewProductAddPrice.value,
			};

			const order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);

			this.handleTeudaNum();
			this.handleTeudaCreateDate();

			setTimeout(() => {
				order.PriceInInput = this.HelpCantChangeAdd;
			}, 500);

			this.AddedSuccess = true;

			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

			this.GeneralFunction();
			this.showHideAddPopup(false);
		}, 200);
	}

	ChangePriceClickLastPriceFunctionMinPrice(product) {
		const { Stock, ProductId, Price, ItemPack, MinPrice, BD, BP, BS, ShowPrice } = product;

		if ((BD === 1 && BP === 1) || this.shouldDisablePriceAndDiscountInput || this.shouldDisableChangingInputs || this.shouldDisableChangingClickingFunctions) return;

		this.FormReset();

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		if (!order) {
			if ((BS === 1 || BS === 6 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			} else {
				const overwrite = {
					Price: MinPrice,
					PermPrice: Price,
					ChangePrice: MinPrice,
					IfChangePriceOrDisc: OrderState.changedPrice,
					PriceInInput: MinPrice,
				};

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);

				product.Price = MinPrice;
			}
		} else {
			if (MinPrice !== null) {
				if (MinPrice === 0 || MinPrice === '' || MinPrice === undefined) {
					order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
					order.ChangePrice = Price;
					order.ChangeDiscount = 0;
					order.Price = Price;

					order.Price = ShowPrice;
					order.PriceInInput = ShowPrice;
					product.Price = product.ShowPrice;
				} else {
					order.IfChangePriceOrDisc = OrderState.changedPrice;
					order.ChangePrice = MinPrice;
					order.Price = MinPrice;
					order.ChangeDiscount = 0;

					product.Price = MinPrice;
					order.ChangeDiscount = 0;
					order.PriceInInput = MinPrice;
				}

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		setTimeout(() => {
			if (MinPrice === null || MinPrice === undefined) {
				const order = this.AllOrder.find(ob => ob.ProductId === ProductId);
				const product = this.AllProducts.find(ob => ob.ProductId === ProductId);

				order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
				order.ChangePrice = 0;
				order.Price = Price;
				order.ChangeDiscount = 0;

				order.Price = product.ShowPrice;
				order.PriceInInput = product.ShowPrice;
				product.Price = product.ShowPrice;

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}, 200);

		setTimeout(() => {
			const order = this.AllOrder.find(ob => ob.ProductId === ProductId);

			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * ItemPack * order.NetPrice);

			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.NetPrice);

			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

			this.GeneralFunction();
		}, 250);
	}

	LastAmount(product) {
		if (!this.canPressLastQuantityAndFillQuantity) return;
		const { Stock, ProductId, Price, docquantity, qt, BS, dontTouchPriceOrDiscount } = product;

		if ((qt !== 0 && qt !== 3) || this.shouldDisablePriceAndDiscountInput || this.shouldDisableChangingInputs || this.shouldDisableChangingClickingFunctions) return;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		if (!order) {
			if ((BS === 1 || BS === 6 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) && (Stock < docquantity || (isRallyanceWithDoctype88 && +product.ItemExtra5 < docquantity))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock < docquantity || (isRallyanceWithDoctype88 && +product.ItemExtra5 < docquantity))) {
				this.StockAlert = true;
			} else {
				const overwrite: AllOrderOverwrites = {
					TeudaQuantity: docquantity,
					YehidotPrice: normalizeNumberToMaxTwoDecimal(Price * docquantity),
				};

				if (dontTouchPriceOrDiscount) {
					overwrite.dontTouchPriceOrDiscount = true;
				}

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			if (BS === 1 && (Stock < docquantity || (isRallyanceWithDoctype88 && +product.ItemExtra5 < docquantity))) {
				this.StockAlert = true;
			} else {
				order.TeudaQuantity = docquantity;
				order.YehidotPrice = normalizeNumberToMaxTwoDecimal(docquantity * order.Price);
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		const orderIndex = this.AllOrder.findIndex(ob => ob.ProductId === ProductId);

		if (docquantity === null) {
			order.TeudaQuantity = 0;
			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.Price);
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}

		if (!order.TeudaQuantity && !order.TeudaPackQuantity && !order.TeudaBonusQuantity) {
			this.AllOrder.splice(orderIndex, 1);
			this.toggleMarkGroupWithProductInOrder({ product, isInOrder: false });
		}

		this.GeneralFunction();
	}

	MinAlertChangePrice = false;
	ShowDisAhuz = 0;

	ChangePriceFunction(product: Product2) {
		if (this.alternativeProductCalculations) {
			this.ChangePriceFunctionAlternative(product);
			return;
		}
		this.form.controls.ChangeDiscount.reset();

		const { ShowPrice, Stock, ProductId, Price, ItemPack, MinPrice, BS } = product;

		const changePriceInput = this.form.controls.ChangePrice;
		const changePriceInputValueAsNumber = +changePriceInput.value;

		if (changePriceInputValueAsNumber < 0) {
			changePriceInput.setValue(0);
		}

		if (this._ss.CustomerInformation.BlockIncreasePrice === '1' && product.ShowPrice && product.ShowPrice < changePriceInputValueAsNumber) {
			changePriceInput.setValue(product.ShowPrice);
		}

		if (Price !== 0) {
			this.dis = 1 - changePriceInputValueAsNumber / ShowPrice;
			this.ShowDisAhuz = this.dis * 100;
			this.dis = this.dis * 100;
		} else {
			this.dis = 0;
		}

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		if (MinPrice > +changePriceInput.value && this.isBelowMinActive(product, 'block') && changePriceInput.value && this._ss.CheckMinPrice !== 1 && !this.shouldOverrideAgentProductQuantities) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangePrice' + ProductId) !== null) {
				document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
			}
			return;
		}

		this.MinAlertChangePrice = this.MinAlertChangeDiscount = false;

		if (document.getElementById('ChangePrice' + ProductId) !== null) {
			document.getElementById('ChangePrice' + ProductId).style.borderColor = '#38D88B';
		}
		if (document.getElementById('ChangeDiscount' + ProductId) !== null) {
			document.getElementById('ChangeDiscount' + ProductId).style.borderColor = '#38D88B';
		}

		if (MinPrice > +changePriceInput.value && this.isBelowMinActive(product, 'warn')) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangePrice' + ProductId) !== null) {
				document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
			}
		}
		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		if (!order) {
			if ((BS === 1 || BS === 6 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
			} else {
				const overwrite = {
					Price: changePriceInputValueAsNumber,
					ChangePrice: changePriceInputValueAsNumber,
					ChangeDiscount: this.dis,
					IfChangePriceOrDisc: OrderState.changedPrice,
					PriceInInput: changePriceInputValueAsNumber,
				};

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);

				product.Price = changePriceInputValueAsNumber;
				if (this._ss.CustomerInformation.CustChange1 === '1' || this._ss.CustomerInformation.DiscountLogic === '2' || this._ss.CustomerInformation.DiscountLogic === '3') {
					order.ChangeDiscount = this.dis;
				} else {
					order.ChangeDiscount = 0;
				}
				order.PriceInInput = changePriceInput.value;

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		} else {
			if (changePriceInput.value !== null) {
				if (changePriceInput.value === 0 || changePriceInput.value === '' || changePriceInput.value === undefined || changePriceInput.value === '') {
					order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
					order.ChangePrice = Price;
					order.ChangeDiscount = 0;

					order.Price = order.PriceInInput = product.Price = ShowPrice;
				} else {
					order.IfChangePriceOrDisc = OrderState.changedPrice;
					order.ChangePrice = changePriceInput.value;

					if (this._ss.CustomerInformation.CustChange1 === '1' || this._ss.CustomerInformation.DiscountLogic === '2' || this._ss.CustomerInformation.DiscountLogic === '3') {
						order.ChangeDiscount = this.dis;
					} else {
						order.ChangeDiscount = 0;
					}

					product.Price = order.Price = order.PriceInInput = order.NetPrice = product.NetPrice = order.PriceInInput = changePriceInputValueAsNumber;
				}

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		setTimeout(() => {
			if (changePriceInput.value === null) {
				order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
				order.ChangePrice = 0;
				order.ChangeDiscount = 0;

				order.Price = order.PriceInInput = product.Price = ShowPrice;

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}, 100);

		setTimeout(() => {
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * ItemPack * order.NetPrice);

			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.NetPrice);
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			this.GeneralFunction();
		}, 200);
	}

	ChangePriceFunctionAlternative(product: Product2) {
		this.form.controls.ChangeDiscount.reset();
		this.form.controls.ChangeBasePrice.reset();

		const { ShowPrice, Stock, ProductId, Price, ItemPack, MinPrice, BS, basePrice, basePriceInputted } = product;

		const changePriceInput = this.form.controls.ChangePrice;
		const changePriceInputValueAsNumber = +changePriceInput.value;

		if (+changePriceInput.value < 0) {
			changePriceInput.setValue(0);
		}

		// if (this._ss.CustomerInformation.BlockIncreasePrice === '1' && product.ShowPrice && product.ShowPrice < changePriceInput.value) {
		// 	changePriceInput.setValue(product.ShowPrice);
		// }

		// if (Price !== 0) {
		// 	this.dis = 1 - changePriceInput.value / ShowPrice;
		// 	this.ShowDisAhuz = this.dis * 100;
		// 	this.dis = this.dis * 100;
		// } else {
		// 	this.dis = 0;
		// }

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		if (MinPrice > +changePriceInput.value && this.isBelowMinActive(product, 'block') && changePriceInput.value && this._ss.CheckMinPrice !== 1 && !this.shouldOverrideAgentProductQuantities) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangePrice' + ProductId) !== null) {
				document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
			}
			return;
		}

		this.MinAlertChangePrice = this.MinAlertChangeDiscount = false;

		if (document.getElementById('ChangePrice' + ProductId) !== null) {
			document.getElementById('ChangePrice' + ProductId).style.borderColor = '#38D88B';
		}
		if (document.getElementById('ChangeDiscount' + ProductId) !== null) {
			document.getElementById('ChangeDiscount' + ProductId).style.borderColor = '#38D88B';
		}

		if (MinPrice > +changePriceInput.value && this.isBelowMinActive(product, 'warn')) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangePrice' + ProductId) !== null) {
				document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
			}
		}
		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		if (!order) {
			if ((BS === 1 || BS === 6 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
			} else {
				const overwrite = {
					Price: changePriceInputValueAsNumber,
					ChangePrice: changePriceInputValueAsNumber,
					ChangeDiscount: this.dis,
					IfChangePriceOrDisc: OrderState.changedPrice,
					PriceInInput: changePriceInputValueAsNumber,
				};

				product.Price = changePriceInputValueAsNumber;

				if (this._ss.CustomerInformation.CustChange1 === '1' || this._ss.CustomerInformation.DiscountLogic === '2' || this._ss.CustomerInformation.DiscountLogic === '3') {
					overwrite.ChangeDiscount = this.dis;
				} else {
					overwrite.ChangeDiscount = 0;
				}

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			if (!changePriceInput.value) {
				order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
				// order.NetPrice = product.NetPrice = product.ShowPrice;

				// if (order.ChangeDiscount) {
				// 	order.IfChangePriceOrDisc = OrderState.changedDiscount;
				// 	// order.NetPrice = product.NetPrice = product
				// }

				order.ChangePrice = 0;

				const price = basePriceInputted || basePrice || ShowPrice;

				order.ChangeDiscount = 0;

				order.Price = order.PriceInInput = product.Price = order.NetPrice = product.NetPrice = price;
			} else {
				order.IfChangePriceOrDisc = OrderState.changedPrice;

				order.PriceInInput = order.NetPrice = product.NetPrice = order.ChangePrice = +changePriceInput.value;

				const base = basePriceInputted || basePrice || ShowPrice;

				order.ChangeDiscount = (1 - +changePriceInput.value / base) * 100;

				order.ChangeDiscount = Math.max(order.ChangeDiscount, 0);

				if (order.ChangeDiscount) {
					order.IfChangePriceOrDisc = OrderState.changedBothDiscountAndPrice;
				}

				// order.Price = changePriceInput.value;

				// product.Price = changePriceInput.value;
			}
		}

		this.GeneralFunction();
	}

	changeBasePrice(product: Product2) {
		this.form.controls.ChangePrice.reset();
		this.form.controls.ChangeDiscount.reset();

		const { ShowPrice, Stock, ProductId, ChangeDiscount, ItemPack, ItemBarcode, MinPrice, ItemPicName, BD, BP, qt, BS, PKD, CPP, discount } = product;

		const changeBasePriceInput = this.form.controls.ChangeBasePrice;

		if (+changeBasePriceInput.value < 0) {
			changeBasePriceInput.setValue(0);
		}

		if (this._ss.CustomerInformation.BlockIncreasePrice === '1' && product.ShowPrice && product.ShowPrice < +changeBasePriceInput.value) {
			changeBasePriceInput.setValue(product.ShowPrice);
		}

		if (
			MinPrice > +changeBasePriceInput.value &&
			this.isBelowMinActive(product, 'block') &&
			changeBasePriceInput.value &&
			this._ss.CheckMinPrice !== 1 &&
			!this.shouldOverrideAgentProductQuantities
		) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangeBasePrice' + ProductId) !== null) {
				document.getElementById('ChangeBasePrice' + ProductId).style.borderColor = 'red';
			}
			return;
		}

		this.MinAlertChangePrice = this.MinAlertChangeDiscount = false;

		if (document.getElementById('ChangeBasePrice' + ProductId) !== null) {
			document.getElementById('ChangeBasePrice' + ProductId).style.borderColor = '#38D88B';
		}
		if (document.getElementById('ChangeDiscount' + ProductId) !== null) {
			document.getElementById('ChangeDiscount' + ProductId).style.borderColor = '#38D88B';
		}

		if (MinPrice > +changeBasePriceInput.value && this.isBelowMinActive(product, 'warn')) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangeBasePrice' + ProductId) !== null) {
				document.getElementById('ChangeBasePrice' + ProductId).style.borderColor = 'red';
			}
		}

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		// if (Price !== 0) {
		// 	this.dis = 1 - changeBasePriceInput.value / ShowPrice;
		// 	this.ShowDisAhuz = this.dis * 100;
		// 	this.dis = this.dis * 100;
		// } else {
		// 	this.dis = 0;
		// }

		if (!order) {
			if ((BS === 1 || BS === 6 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
			} else {
				const overwrite: AllOrderOverwrites = {
					basePrice: ShowPrice,
					basePriceInputted: +changeBasePriceInput.value,
					IfChangePriceOrDisc: OrderState.changedPrice,
				};

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);

				// if (this._ss.CustomerInformation.CustChange1 === '1' || this._ss.CustomerInformation.DiscountLogic === '2' || this._ss.CustomerInformation.DiscountLogic === '3') {
				// 	order.ChangeDiscount = this.dis;
				// } else {
				// 	order.ChangeDiscount = 0;
				// }
				// order.PriceInInput = changeBasePriceInput.value;
			}
		} else {
			if (changeBasePriceInput.value) {
				order.IfChangePriceOrDisc = OrderState.changedPrice;
				order.basePrice = ShowPrice;
				product.basePriceInputted = order.basePriceInputted = +changeBasePriceInput.value;

				order.NetPrice = product.NetPrice = product.basePriceInputted;

				if (order.ChangeDiscount) {
					order.NetPrice = product.NetPrice = product.basePriceInputted - (product.basePriceInputted * order.ChangeDiscount) / 100;
				}
			} else {
				order.IfChangePriceOrDisc = OrderState.noChangeToProduct;

				if (ChangeDiscount !== discount) {
					order.IfChangePriceOrDisc = OrderState.changedDiscount;
				}

				delete order.basePrice;
				delete order.basePriceInputted;

				if (!order.TeudaQuantity && !order.TeudaPackQuantity && !order.TeudaBonusQuantity) {
					const index = this.AllOrder.findIndex(ob => ob.ProductId === ProductId);
					this.AllOrder.splice(index, 1);
					this.toggleMarkGroupWithProductInOrder({ product, isInOrder: false });
				}
			}
		}

		this.GeneralFunction();
	}

	MinAlertChangeDiscount = false;
	ChangeDiscountFunction(product: Product2) {
		if (this.alternativeProductCalculations) {
			this.ChangeDiscountFunctionAlternative(product);
			return;
		}
		const { Stock, ProductId, Price, ItemPack, MinPrice, BS, ItemExtra5 } = product;

		const changeDiscountInput = this.form.controls.ChangeDiscount;
		const changeDiscountInputValueAsNumber = +changeDiscountInput.value;

		this.form.controls.ChangePrice.reset();
		if (changeDiscountInputValueAsNumber > 100) {
			changeDiscountInput.setValue(100);
		}
		let PriceAhoz = Price * changeDiscountInputValueAsNumber;
		PriceAhoz /= 100;
		let HelpCantChange = 0;
		this.dis = HelpCantChange = Price - PriceAhoz;

		if (MinPrice > HelpCantChange && this._ss.CheckMinPrice !== 1 && !this.shouldOverrideAgentProductQuantities) {
			if (this.isBelowMinActive(product, 'block-discount')) {
				this.MinAlertChangeDiscount = true;
				if (document.getElementById('ChangeDiscount' + ProductId) !== null) {
					document.getElementById('ChangeDiscount' + ProductId).style.borderColor = 'red';
				}
				return;
			}

			if (this.isBelowMinActive(product, 'block-price')) {
				this.MinAlertChangePrice = true;
				if (document.getElementById('ChangePrice' + ProductId) !== null) {
					document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
				}
				return;
			}
		}

		this.MinAlertChangeDiscount = false;
		this.MinAlertChangePrice = false;

		if (document.getElementById('ChangeDiscount' + ProductId) !== null) {
			document.getElementById('ChangeDiscount' + ProductId).style.borderColor = '#38D88B';
		}
		if (document.getElementById('ChangePrice' + ProductId) !== null) {
			document.getElementById('ChangePrice' + ProductId).style.borderColor = '#38D88B';
		}

		if (MinPrice > HelpCantChange && this.isBelowMinActive(product, 'warn')) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangePrice' + ProductId) !== null) {
				document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
			}
		}

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.DocType === 88 && this._ss.CustomerInformation.SpecificCompanyVersion === '1';

		if (!order) {
			if ((BS === 1 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) && (Stock <= 0 || (isRallyanceWithDoctype88 && +ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +ItemExtra5 <= 0)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
			} else {
				// const product = this.AllProducts.find(ob => ob.ProductId === ProductId)

				// if (product) {
				//   product.ChangeDiscount = product.discount = this.form.controls.ChangeDiscount.value
				//   product.NetPrice = Price - this.PriceAhoz

				//   product.Price = Price - this.PriceAhoz
				// }

				const overwrite = {
					Price: Price - PriceAhoz,
					ChangePrice: Price - PriceAhoz,
					ChangeDiscount: changeDiscountInputValueAsNumber,
					IfChangePriceOrDisc: OrderState.changedDiscount,
				};

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			if (!this.form.controls.ChangeDiscount.value || !+String(this.form.controls.ChangeDiscount.value).trim()) {
				order.IfChangePriceOrDisc = order.IfChangePriceOrDisc === OrderState.changedBothDiscountAndPrice ? OrderState.changedPrice : OrderState.noChangeToProduct;

				order.ChangePrice = Price;
				order.ChangeDiscount = 0;
				order.Price = Price;
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			} else {
				if (order.IfChangePriceOrDisc === OrderState.changedPrice) {
					order.IfChangePriceOrDisc = OrderState.changedBothDiscountAndPrice;
				}

				if (order.IfChangePriceOrDisc === OrderState.noChangeToProduct) {
					order.IfChangePriceOrDisc = OrderState.changedDiscount;
				}

				order.NetPrice = order.ChangePrice = order.Price = Price - PriceAhoz;
				order.ChangeDiscount = this.form.controls.ChangeDiscount.value;
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		setTimeout(() => {
			if (this.form.controls.ChangeDiscount.value === null || this.form.controls.ChangeDiscount.value === 0) {
				order.IfChangePriceOrDisc = order.IfChangePriceOrDisc === OrderState.changedBothDiscountAndPrice ? OrderState.changedPrice : OrderState.noChangeToProduct;

				order.ChangePrice = 0;
				order.ChangeDiscount = 0;
				order.Price = Price;
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}, 100);

		setTimeout(() => {
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * ItemPack * order.NetPrice);

			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.NetPrice);

			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

			this.GeneralFunction();
		}, 200);
	}

	ChangeDiscountFunctionAlternative(product: Product2) {
		const { Stock, ProductId, Price, ItemPack, MinPrice, BS, ItemExtra5, ShowPrice, basePrice, basePriceInputted } = product;

		this.form.controls.ChangePrice.reset();
		this.form.controls.ChangeBasePrice.reset();

		const changeDiscountInput = this.form.controls.ChangeDiscount;
		const changeDiscountInputValueAsNumber = +changeDiscountInput.value;

		if (+changeDiscountInput.value > 100) {
			changeDiscountInput.setValue(100);
		}
		let PriceAhoz = Price * changeDiscountInputValueAsNumber;
		PriceAhoz /= 100;
		let HelpCantChange = 0;
		this.dis = HelpCantChange = Price - PriceAhoz;

		if (MinPrice > HelpCantChange && this._ss.CheckMinPrice !== 1 && !this.shouldOverrideAgentProductQuantities) {
			if (this.isBelowMinActive(product, 'block-discount')) {
				this.MinAlertChangeDiscount = true;
				if (document.getElementById('ChangeDiscount' + ProductId) !== null) {
					document.getElementById('ChangeDiscount' + ProductId).style.borderColor = 'red';
				}
				return;
			}

			if (this.isBelowMinActive(product, 'block-price')) {
				this.MinAlertChangePrice = true;
				if (document.getElementById('ChangePrice' + ProductId) !== null) {
					document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
				}
				return;
			}
		}

		this.MinAlertChangeDiscount = false;
		this.MinAlertChangePrice = false;

		if (document.getElementById('ChangeDiscount' + ProductId) !== null) {
			document.getElementById('ChangeDiscount' + ProductId).style.borderColor = '#38D88B';
		}
		if (document.getElementById('ChangePrice' + ProductId) !== null) {
			document.getElementById('ChangePrice' + ProductId).style.borderColor = '#38D88B';
		}

		if (MinPrice > HelpCantChange && this.isBelowMinActive(product, 'warn')) {
			this.MinAlertChangePrice = true;
			if (document.getElementById('ChangePrice' + ProductId) !== null) {
				document.getElementById('ChangePrice' + ProductId).style.borderColor = 'red';
			}
		}

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.DocType === 88 && this._ss.CustomerInformation.SpecificCompanyVersion === '1';

		if (!order) {
			if ((BS === 1 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) && (Stock <= 0 || (isRallyanceWithDoctype88 && +ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +ItemExtra5 <= 0)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
			} else {
				// const product = this.AllProducts.find(ob => ob.ProductId === ProductId)

				// if (product) {
				//   product.ChangeDiscount = product.discount = changeDiscountInput.value
				//   product.NetPrice = Price - this.PriceAhoz

				//   product.Price = Price - this.PriceAhoz
				// }

				const overwrite = {
					Price: Price - PriceAhoz,
					ChangePrice: Price - PriceAhoz,
					ChangeDiscount: changeDiscountInputValueAsNumber,
					IfChangePriceOrDisc: OrderState.changedDiscount,
				};

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			const base = basePriceInputted || basePrice || ShowPrice;

			if (!changeDiscountInput.value || !String(changeDiscountInput.value).trim()) {
				order.IfChangePriceOrDisc = order.IfChangePriceOrDisc === OrderState.changedBothDiscountAndPrice ? OrderState.changedPrice : OrderState.noChangeToProduct;

				order.ChangePrice = base;
				order.ChangeDiscount = 0;
				order.Price = order.NetPrice = product.Price = base;
			} else {
				if (order.IfChangePriceOrDisc === OrderState.changedPrice) {
					order.IfChangePriceOrDisc = OrderState.changedBothDiscountAndPrice;
				}

				if (order.IfChangePriceOrDisc === OrderState.noChangeToProduct) {
					order.IfChangePriceOrDisc = OrderState.changedDiscount;
				}
				order.ChangeDiscount = +changeDiscountInput.value;

				order.NetPrice = base - (base * order.ChangeDiscount) / 100;
				// order.Price = Price - PriceAhoz;
			}
		}

		setTimeout(() => {
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * ItemPack * (order.NetPrice || order.Price));

			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * (order.NetPrice || order.Price));

			this.GeneralFunction();
		}, 200);
	}

	ChangePriceClickLastPriceFunction(product: Product2, val: 'price' | 'discount') {
		const isDiscount = val === 'discount';

		const { Stock, ProductId, Price, ItemPack, ShowPrice, MinPrice, DocNetPrice, BD, BP, BS, DocDiscount } = product;

		this.FormReset();

		if (
			(BD === 1 && BP === 1) ||
			(MinPrice > +DocNetPrice && this.isBelowMinActive(product, 'any') && this._ss.CheckMinPrice !== 1) ||
			this._ss.CustomerInformation.BlockCopyCustPrice === '2' ||
			(this._ss.CustomerInformation.ProductExtraInfo === '3' && this.Admin === 'true' && isDiscount) ||
			this.shouldDisablePriceAndDiscountInput ||
			this.shouldDisableChangingInputs ||
			this.shouldDisableChangingClickingFunctions
		)
			return;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.DocType === 88 && this._ss.CustomerInformation.SpecificCompanyVersion === '1';

		if (!order) {
			if ((BS === 1 || BS === 6 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			} else {
				const overwrite = {
					// Price: +DocNetPrice,
					// ChangePrice: +DocNetPrice,
					ChangeDiscount: (+DocNetPrice / Price - 1) * -100,
					NetPrice: +DocNetPrice,
					IfChangePriceOrDisc: OrderState.changedPrice,
				};

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);

				// product.Price = +DocNetPrice;
				// order.PriceInInput = DocNetPrice;

				if (isDiscount && (this._ss.CustomerInformation.DiscountLogic === '1' || this._ss.CustomerInformation.DiscountLogic === '3')) {
					order.Price = order.ChangePrice = Price - (Price * DocDiscount) / 100;
					order.ChangeDiscount = DocDiscount;
					order.IfChangePriceOrDisc = OrderState.changedDiscount;

					order.PriceInInput = product.Price = Price;
				}

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		} else {
			if (DocNetPrice !== null) {
				if (isDiscount && (this._ss.CustomerInformation.DiscountLogic === '1' || this._ss.CustomerInformation.DiscountLogic === '3')) {
					order.Price = order.ChangePrice = order.PriceInInput - (order.PriceInInput * DocDiscount) / 100;
					order.ChangeDiscount = DocDiscount;
					order.IfChangePriceOrDisc = OrderState.changedDiscount;
				} else {
					// const product = this.AllProducts.find(ob => ob.ProductId === ProductId);

					if (DocNetPrice === 0 || DocNetPrice === '' || DocNetPrice === undefined) {
						order.IfChangePriceOrDisc = OrderState.noChangeToProduct;

						order.ChangePrice = order.Price = Price;

						order.Price = order.PriceInInput = product.Price = ShowPrice;
					} else {
						order.IfChangePriceOrDisc = OrderState.changedPrice;

						// order.ChangeDiscount = 0;
						order.ChangeDiscount = (+DocNetPrice / Price - 1) * -100;

						order.NetPrice = +DocNetPrice;
					}
				}
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		setTimeout(() => {
			if (DocNetPrice === null || DocNetPrice === undefined) {
				order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
				order.ChangePrice = 0;

				order.Price = order.PriceInInput = product.Price = product.ShowPrice;

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}, 200);

		setTimeout(() => {
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * ItemPack * order.NetPrice);

			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.NetPrice);

			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

			this.GeneralFunction();
		}, 250);
	}

	HearaProduct(product) {
		const { Stock, ProductId, BS, dontTouchPriceOrDiscount } = product;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.DocType === 88 && this._ss.CustomerInformation.SpecificCompanyVersion === '1';

		if (!order) {
			if ((BS === 1 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			} else {
				const overwrite: AllOrderOverwrites = {
					TeudaItemRemark: this.form.controls.HearaForProduct.value || '',
				};
				if (dontTouchPriceOrDiscount) {
					overwrite.dontTouchPriceOrDiscount = true;
				}

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			order.TeudaItemRemark = this.form.controls.HearaForProduct.value || '';
		}

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		setTimeout(() => {
			this.GeneralFunction();
		}, 200);
	}

	Bonus(product: Product2) {
		const { Stock, ProductId, BS, dontTouchPriceOrDiscount } = product;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.DocType === 88 && this._ss.CustomerInformation.SpecificCompanyVersion === '1';

		const normalizedValue = normalizeNumber(this.form.controls.Bonus.value, { max: MAXIMUM_PACK_QUANTITY });

		if (!order) {
			if ((BS === 1 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
			} else {
				const overwrite: AllOrderOverwrites = {
					TeudaBonusQuantity: normalizedValue,
				};
				if (dontTouchPriceOrDiscount) {
					overwrite.dontTouchPriceOrDiscount = true;
				}

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			order.TeudaBonusQuantity = normalizedValue;
		}

		setTimeout(() => {
			this.GeneralFunction();
		}, 100);
	}

	YehidotPlus(product: Product2) {
		const { Stock, ProductId, Price, ItemPack, qt, BS, ChangeDiscount, ChangePrice, SpecialID, dontTouchPriceOrDiscount, PermPrice } = product;

		if (((qt === 1 || qt === 2) && !this.shouldOverrideAgentProductQuantities) || SpecialID) return;

		const arizotInput = this.form.controls.Arizot;
		const yehidotInput = this.form.controls.Yehidot;

		yehidotInput.reset();
		arizotInput.reset();

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		if (!order) {
			// TODO does this logic need to be separated from other one?
			if ((BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0))) {
				this.StockAlert = true;
			}

			if ((BS === 1 || BS === 2 || BS === 4) && (Stock <= 0 || (isRallyanceWithDoctype88 && +product.ItemExtra5 <= 0)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
			} else {
				// BS === 1 added in commit #7729370
				const overwrite: AllOrderOverwrites = {
					ChangePrice: ChangePrice || 0,
					TeudaQuantity: BS === 1 && this.BlockYehidotPlusIfBelowStockOne !== '1' ? 0 : 1,
					ChangeDiscount: ChangeDiscount || 0,
					YehidotPrice: Price,
					PermPrice: PermPrice || Price,
				};

				if (dontTouchPriceOrDiscount) {
					overwrite.dontTouchPriceOrDiscount = true;
				}

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			const check = order.TeudaPackQuantity * ItemPack + order.TeudaQuantity;

			if (BS === 1 && (Stock === check || (isRallyanceWithDoctype88 && +product.ItemExtra5 === check))) {
				this.StockAlert = true;
				yehidotInput.reset();
				arizotInput.reset();
			} else {
				order.TeudaQuantity = arithmeticWithFloatingNumber({ number: order.TeudaQuantity, action: 'increment' });
				order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.Price);

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		this.GeneralFunction();
	}

	YehidotMinus(product: Product) {
		const { ProductId, qt } = product;

		if ((qt === 1 || qt === 2) && !this.shouldOverrideAgentProductQuantities) {
		} else {
			this.form.controls.Yehidot.reset();
			this.form.controls.Arizot.reset();

			let productInExistingOrder = this.AllOrder.find(ob => ob.ProductId === ProductId);

			if (productInExistingOrder?.TeudaQuantity > 0) {
				productInExistingOrder.TeudaQuantity = arithmeticWithFloatingNumber({ number: productInExistingOrder.TeudaQuantity, action: 'decrement' });
				productInExistingOrder.TeudaQuantity = Math.max(productInExistingOrder.TeudaQuantity, 0);
				productInExistingOrder.YehidotPrice = normalizeNumberToMaxTwoDecimal(productInExistingOrder.TeudaQuantity * productInExistingOrder.Price);

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

				if (productInExistingOrder.TeudaQuantity === 0 && productInExistingOrder.TeudaPackQuantity === 0) {
					if (this._ss.CustomerInformation.CustChange6 == '1' && productInExistingOrder.TeudaBonusQuantity > 0) {
					} else {
						let productInAllProducts: any = null;
						if (this._ss.allConcentratedProducts.length) {
							productInAllProducts = this.AllProductsSearch.find(ob => ob.ProductId === ProductId);
						} else {
							productInAllProducts = this.AllProducts.find(ob => ob.ProductId === ProductId);
						}

						if (productInAllProducts) {
							const product = productInAllProducts;

							product.TeudaTotalRow = 0;
							product.TeudaQuantity = 0;
							product.TeudaPackQuantity = 0;
							product.TeudaBonusQuantity = 0;
							product.ChangePrice = product.NetPrice;
							product.ChangeDiscount = product.discount;
							// product.NetPrice = product.Price
						}

						const productInPopup = this.testpop.find(ob => ob.ProductId === ProductId);

						if (productInPopup) {
							const product = productInPopup;

							product.TeudaTotalRow = 0;
							product.TeudaQuantity = 0;
							product.TeudaPackQuantity = 0;
							product.TeudaBonusQuantity = 0;
							product.ChangePrice = product.NetPrice;
							product.ChangeDiscount = product.discount;
							// product.NetPrice = product.Price
						}

						this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
						this.toggleMarkGroupWithProductInOrder({ product, isInOrder: false });
					}
				}

				setTimeout(() => {
					this.GeneralFunction();
				}, 100);
			}
		}
	}

	ArizotPlus(product: Product2) {
		const { Stock, ProductId, Price, ItemPack, qt, BS, SpecialID, dontTouchPriceOrDiscount, NetPrice } = product;

		const arizotInput = this.form.controls.Arizot;
		const yehidotInput = this.form.controls.Yehidot;

		if (((qt === 1 || qt === 3) && !this.shouldOverrideAgentProductQuantities) || SpecialID) {
			return;
		}

		yehidotInput.reset();
		arizotInput.reset();

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		if (!order) {
			if (
				(BS === 2 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) &&
				(Stock < ItemPack || (isRallyanceWithDoctype88 && +product.ItemExtra5 < ItemPack)) &&
				!this.shouldOverrideAgentProductQuantities
			) {
				this.StockAlert = true;
				yehidotInput.reset();
				arizotInput.reset();
			}

			if ((BS === 1 || BS === 4) && (Stock < ItemPack || (isRallyanceWithDoctype88 && +product.ItemExtra5 < ItemPack)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
				yehidotInput.reset();
				arizotInput.reset();
			} else {
				const overwrite: AllOrderOverwrites = {
					TeudaPackQuantity: 1,
					ArizotPrice: normalizeNumberToMaxTwoDecimal((NetPrice || Price) * ItemPack),
					Price: NetPrice || Price,
				};

				if (dontTouchPriceOrDiscount) {
					overwrite.dontTouchPriceOrDiscount = true;
				}
				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
				product.NetPrice ||= Price;
			}
		} else {
			const check = order.TeudaPackQuantity * ItemPack + order.TeudaQuantity + ItemPack;

			if (BS === 1 && (Stock < check || (isRallyanceWithDoctype88 && +product.ItemExtra5 < check)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
				yehidotInput.reset();
				arizotInput.reset();
			} else {
				product.NetPrice ||= Price;
				order.NetPrice ||= product.NetPrice;
				order.TeudaPackQuantity = arithmeticWithFloatingNumber({ number: order.TeudaPackQuantity, action: 'increment' });
				order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * ItemPack * (order.NetPrice || order.Price));

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		this.GeneralFunction();
	}

	ArizotMinus(product: Product) {
		const { ProductId, ItemPack, qt } = product;

		const order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		if (((qt === 1 || qt === 3) && !this.shouldOverrideAgentProductQuantities) || !order) return;

		this.form.controls.Yehidot.reset();
		this.form.controls.Arizot.reset();

		if (order.TeudaPackQuantity <= 0) {
			order.TeudaPackQuantity = 0;
			return;
		}

		order.TeudaPackQuantity = arithmeticWithFloatingNumber({ number: order.TeudaPackQuantity, action: 'decrement' });
		order.TeudaPackQuantity = Math.max(order.TeudaPackQuantity, 0);
		order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaPackQuantity * ItemPack * order.NetPrice);

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		if (order.TeudaQuantity === 0 && order.TeudaPackQuantity === 0) {
			let product: any = null;
			if (this._ss.allConcentratedProducts.length) {
				product = this.AllProductsSearch.find(ob => ob.ProductId === ProductId);
			} else {
				product = this.AllProducts.find(ob => ob.ProductId === ProductId);
			}
			if (product) {
				product.TeudaTotalRow = 0;
				product.TeudaQuantity = 0;
				product.TeudaPackQuantity = 0;

				product.TeudaBonusQuantity = 0;
				product.ChangePrice = product.NetPrice;
				product.ChangeDiscount = product.discount;
				// product.NetPrice = product.Price
			}

			const productInPopup = this.testpop.find(ob => ob.ProductId === ProductId);

			if (productInPopup) {
				const product = productInPopup;
				product.TeudaTotalRow = 0;
				product.TeudaQuantity = 0;
				product.TeudaPackQuantity = 0;

				product.TeudaBonusQuantity = 0;
				product.ChangePrice = product.NetPrice;
				product.ChangeDiscount = product.discount;
				// product.NetPrice = product.Price
			}

			this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
			this.toggleMarkGroupWithProductInOrder({ product, isInOrder: false });

			const lsIdentifier = '' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company;

			localStorage.setItem('AllOrder' + lsIdentifier, JSON.stringify(this.AllOrder));

			if (this.AllOrder.length === 0) {
				this.clearAllOrderLocalStorage(lsIdentifier);

				this.TotalSumOAllOrder = 0;
			}
		}

		setTimeout(() => {
			this.GeneralFunction();
		}, 100);
	}

	stopKeyupEventIfKey(e: Event) {
		if (e instanceof InputEvent && e.data === '.') {
			// prevent YehidotInput/ArizotInput from going off, to allow decimal quantities
			this.preventPropagation(e);
		}
	}

	logThisEvent(e: Event) {
		console.log(e);
	}

	shouldStopInput = false;

	YehidotInput(e: Event, product: Product2, useConcentrated = false) {
		const { Stock, ProductId, Price, ItemPack, qt, BS, ChangeDiscount, ChangePrice, dontTouchPriceOrDiscount } = product;

		if ((qt === 1 || qt === 2) && !this.shouldOverrideAgentProductQuantities) return;

		const yehidotInput = useConcentrated ? this.concentratedProductsForm.controls.concentratedSingles : this.form.controls.Yehidot;
		const arizotInput = this.form.controls.Arizot;

		if (yehidotInput.value && String(yehidotInput.value)[String(yehidotInput.value).length - 1] === '.') {
			return;
		}

		if (e instanceof KeyboardEvent) {
			if (e.key === '.') {
				return;
			}

			if (e.key === 'Backspace' && !yehidotInput.value) {
				yehidotInput.setValue('0');
			}
		}

		if (isNaN(+yehidotInput.value) || +yehidotInput.value < 0 || yehidotInput.value === null) {
			yehidotInput.reset('0');
			return;
		}

		const yehidotInputAsNumber = +yehidotInput.value;

		const str = yehidotInput.value;

		if (str && str.split('.')[1] !== undefined && str.split('.')[1].length > 3) {
			yehidotInput.reset(str.split('.')[0] + '.' + str.split('.')[1].slice(0, 3));
			return;
		}

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		if (!order) {
			if (
				(BS === 1 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) &&
				(Stock < yehidotInputAsNumber || (isRallyanceWithDoctype88 && +product.ItemExtra5 < yehidotInputAsNumber)) &&
				!this.shouldOverrideAgentProductQuantities
			) {
				this.StockAlert = true;
				yehidotInput.reset();
				arizotInput.reset();
			}

			if ((BS === 2 || BS === 4) && (Stock < yehidotInputAsNumber || (isRallyanceWithDoctype88 && +product.ItemExtra5 < yehidotInputAsNumber)) && !this.shouldOverrideAgentProductQuantities) {
				console.log({ BS, Stock });

				this.StockAlert = true;
				yehidotInput.reset();
				arizotInput.reset();
			} else {
				const overwrite: AllOrderOverwrites = {
					ChangePrice: ChangePrice || 0,
					ChangeDiscount: ChangeDiscount || 0,
					TeudaQuantity: yehidotInputAsNumber,
					YehidotPrice: normalizeNumberToMaxTwoDecimal(Price * yehidotInputAsNumber),
				};
				if (dontTouchPriceOrDiscount) {
					overwrite.dontTouchPriceOrDiscount = true;
				}

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);
			}
		} else {
			const quantitiesCheck = yehidotInputAsNumber + order.TeudaPackQuantity * ItemPack;

			if (BS === 1 && (Stock < quantitiesCheck || (isRallyanceWithDoctype88 && +product.ItemExtra5 < quantitiesCheck)) && !this.shouldOverrideAgentProductQuantities) {
				this.StockAlert = true;
				yehidotInput.reset();
				arizotInput.reset();
			} else {
				order.TeudaQuantity = yehidotInputAsNumber;
				order.YehidotPrice = normalizeNumberToMaxTwoDecimal(yehidotInputAsNumber * order.Price);
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		if (yehidotInput.value === null) {
			order.TeudaQuantity = 0;
			order.YehidotPrice = normalizeNumberToMaxTwoDecimal(order.TeudaQuantity * order.Price);
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}

		if (order.TeudaQuantity === 0 && order.TeudaPackQuantity === 0) {
			// this.FormReset()

			setTimeout(() => {
				if (order && order.TeudaQuantity === 0 && order.TeudaPackQuantity === 0) {
					this.FormReset();
				}
			}, 2500);

			//
			let product: any = {};
			if (this._ss.allConcentratedProducts.length) {
				product = this.AllProductsSearch.find(ob => ob.ProductId === ProductId);
			} else {
				product = this.AllProducts.find(ob => ob.ProductId === ProductId);
			}
			const productInPopup = this.testpop.find(ob => ob.ProductId === ProductId);
			if (product) {
				product.TeudaTotalRow = 0;
				product.TeudaQuantity = 0;
				product.TeudaPackQuantity = 0;
				product.TeudaBonusQuantity = 0;
				product.ChangePrice = product.NetPrice;
				product.ChangeDiscount = product.discount;
				// if (BD === 0) {
				//   this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].NetPrice = this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].Price
				// }
			}
			if (productInPopup) {
				productInPopup.TeudaTotalRow = 0;
				productInPopup.TeudaQuantity = 0;
				productInPopup.TeudaPackQuantity = 0;
				productInPopup.TeudaBonusQuantity = 0;
				productInPopup.ChangePrice = productInPopup.NetPrice;
				productInPopup.ChangeDiscount = productInPopup.discount;
				// if (BD === 0) {
				//   this.testpop[this.testpop.indexOf(this.testpop.find(ob => ob.ProductId === ProductId))].NetPrice = this.testpop[this.testpop.indexOf(this.testpop.find(ob => ob.ProductId === ProductId))].Price
				// }
			}
			//

			this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
			this.toggleMarkGroupWithProductInOrder({ product, isInOrder: false });
		}

		this.GeneralFunction();
	}

	ArizotInput(e: Event, product: Product2, useConcentrated = false) {
		const { Stock, ProductId, ItemPack, BS, dontTouchPriceOrDiscount } = product;

		const HelpPriceArizot = ItemPack * (product.NetPrice || product.Price);

		let order = this.AllOrder.find(ob => ob.ProductId === ProductId);

		const ArizotInput = useConcentrated ? this.concentratedProductsForm.controls.concentratedPacks : this.form.controls.Arizot;
		const YehidotInput = this.form.controls.Yehidot;

		if (ArizotInput.value && ArizotInput.value[ArizotInput.value.length - 1] === '.') {
			return;
		}

		if (e instanceof KeyboardEvent) {
			if (e.key === '.') return;

			if (e.key === 'Backspace' && !ArizotInput.value) {
				ArizotInput.setValue('0');
			}
		}

		if (isNaN(+ArizotInput.value) || +ArizotInput.value < 0 || ArizotInput.value === null) {
			ArizotInput.reset('0');
			return;
		}

		const arizotInputValueAsNumber = +ArizotInput.value;

		const str = ArizotInput.value;

		if (str && str.split('.')[1] !== undefined && str.split('.')[1].length > 3) {
			ArizotInput.reset(str.split('.')[0] + '.' + str.split('.')[1].slice(0, 3));
			return;
		}

		const isRallyanceWithDoctype88 = this._ss.CustomerInformation.SpecificCompanyVersion === '1' && this._ss.DocType === 88;

		if (!order) {
			if (
				(BS === 2 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) &&
				(Stock < arizotInputValueAsNumber * ItemPack || (isRallyanceWithDoctype88 && +product.ItemExtra5 < arizotInputValueAsNumber * ItemPack))
			) {
				this.StockAlert = true;
				YehidotInput.reset();
				ArizotInput.reset();
			}

			if ((BS === 1 || BS === 4) && (Stock < arizotInputValueAsNumber * ItemPack || (isRallyanceWithDoctype88 && +product.ItemExtra5 < arizotInputValueAsNumber * ItemPack))) {
				this.StockAlert = true;
				YehidotInput.reset();
				ArizotInput.reset();
			} else {
				const overwrite = { TeudaPackQuantity: arizotInputValueAsNumber, ArizotPrice: normalizeNumberToMaxTwoDecimal(HelpPriceArizot * arizotInputValueAsNumber) };

				order = this.addProductToAllOrderAndReturnOrderObject(product, overwrite);

				if (dontTouchPriceOrDiscount) {
					order.dontTouchPriceOrDiscount = true;
				}

				this.handleTeudaNum();
				this.handleTeudaCreateDate();

				// localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		} else {
			const calculatedQuantities = arizotInputValueAsNumber * ItemPack + order.TeudaQuantity;

			if (BS === 1 && (Stock < calculatedQuantities || (isRallyanceWithDoctype88 && +product.ItemExtra5 < calculatedQuantities))) {
				this.StockAlert = true;
				YehidotInput.reset();
				ArizotInput.reset();
			} else {
				order.TeudaPackQuantity = arizotInputValueAsNumber;
				order.ArizotPrice = order.TeudaPackQuantity * ItemPack;
				order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.ArizotPrice * order.NetPrice);
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		if (ArizotInput.value === null || arizotInputValueAsNumber === 0) {
			order.TeudaPackQuantity = 0;
			order.ArizotPrice = order.TeudaPackQuantity * ItemPack;
			order.ArizotPrice = normalizeNumberToMaxTwoDecimal(order.ArizotPrice * order.NetPrice);
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}

		if (order.TeudaQuantity === 0 && order.TeudaPackQuantity === 0 && order.TeudaBonusQuantity === 0) {
			//
			let data: any = null;
			if (this._ss.allConcentratedProducts.length) {
				data = this.AllProductsSearch.find(ob => ob.ProductId === ProductId);
			} else {
				data = this.AllProducts.find(ob => ob.ProductId === ProductId);
			}
			let data2 = this.testpop.find(ob => ob.ProductId === ProductId);
			if (data) {
				data.TeudaTotalRow = 0;
				data.TeudaQuantity = 0;
				data.TeudaPackQuantity = 0;
				data.TeudaBonusQuantity = 0;
				data.ChangePrice = data.NetPrice;
				data.ChangeDiscount = data.discount;
				// if (BD === 0) {
				//   this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].NetPrice = this.AllProducts[this.AllProducts.indexOf(this.AllProducts.find(ob => ob.ProductId === ProductId))].Price
				// }
			}
			if (data2) {
				data2.TeudaTotalRow = 0;
				data2.TeudaQuantity = 0;
				data2.TeudaPackQuantity = 0;
				data2.TeudaBonusQuantity = 0;
				data2.ChangePrice = data2.NetPrice;
				data2.ChangeDiscount = data2.discount;
				// if (BD === 0) {
				//   data2.NetPrice = data2.Price
				// }
			}
			//

			this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
			this.toggleMarkGroupWithProductInOrder({ product: data, isInOrder: false });
		}

		this.GeneralFunction();
	}

	addProductToAllOrderAndReturnOrderObject(product: Product2, overwriteProperties: AllOrderOverwrites): AllOrderOverwrites {
		const { Stock, ProductId, Product_Name, Price, ItemPack, ItemBarcode, MinPrice, ItemPicName, BD, BP, BM, qt, BS, PKD, CPP } = product;

		// prettier-ignore
		const order:AllOrderOverwrites = {
			Stock, ProductId, Product_Name, Price, ItemPack, ItemBarcode, ItemPicName, BD, BP, BM, MinPrice, qt, BS, PKD, CPP,
			ChangePrice: 0,
			ChangeDiscount: 0,
			YehidotPrice: 0,
			ArizotPrice: 0,
			TeudaQuantity: 0,
			TeudaPackQuantity: 0,
			TeudaBonusQuantity: 0,
			IfChangePriceOrDisc: OrderState.noChangeToProduct,
			TeudaBonusPackQuantity: 0,
			TeudaRemark: this.duplicateOrderTeudaRemark || '',
			TeudaItemRemark: '',
			Duplicate: 'false',
			DuplicateOrder: String(this.isDuplicateOrder),
			PermPrice: Price,
			PriceInInput: Price,
			Ptve: this._ss.VersionNumber,
			StatusInsertItem: this.Mode ? 'ONLINE' : 'OFFLINE',
		};

		if (product.weightMode) {
			order.weightMode = product.weightMode;
			order.Weight = product.Weight;
		}

		if (product.packsWeightMode) {
			order.packsWeightMode = product.packsWeightMode;
			order.originalItemPack = product.originalItemPack;
		}

		if (product.Type != null) {
			order.Type = product.Type;
		}

		if (product.GroupSum) {
			order.GroupSum = product.GroupSum;
		}

		this.toggleMarkGroupWithProductInOrder({ product, isInOrder: true });

		const orderToPush: AllOrderOverwrites = { ...order, ...overwriteProperties };

		this.AllOrder.push(orderToPush);

		this.handleTeudaNum();
		this.handleTeudaCreateDate();

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		return orderToPush;
	}

	CommentsProductChoose: ProductCommentSelect[];
	NewHearotParitWithClientId;

	handleFinishSpecialConcentrated() {
		const { special, order, quantity } = this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.value[this.concentratedSpecialCurrentlyEditting$.value.special[0].SpecialID];

		// push to allorder

		const filteredOrder = order.filter(p => p.TeudaQuantity > 0 || p.TeudaPackQuantity > 0 || p.TeudaBonusQuantity > 0);

		// remove from allorder if already exists

		const indexOfSpecial = this.AllOrder.findIndex(ob => ob.SpecialID === special[0].SpecialID);

		if (indexOfSpecial > -1) {
			this.AllOrder.splice(indexOfSpecial, 1);
		}

		const firstSpecial = special[0];

		const allOrderObject = {
			order: filteredOrder,
			special,
			quantity,
			SpecialID: firstSpecial.SpecialID,
			TeudaItemNetPrice: normalizeNumberToMaxTwoDecimal(filteredOrder.reduce((acc, p) => acc + (p.TeudaQuantity + p.TeudaPackQuantity * p.ItemPack) * firstSpecial.TeudaItemNetPrice, 0)),
			StatusInsertItem: this.Mode ? 'ONLINE' : 'OFFLINE',
			Ptve: this._ss.VersionNumber,
			DuplicateOrder: this.isDuplicateOrder ? 'true' : 'false',
			TeudaRemark: this.duplicateOrderTeudaRemark || '',
			TeudaItemRemark: firstSpecial.Name,
			SpecialType: firstSpecial.SpecialType,
		};
		this.concentratedSpecialsCart$.next({
			...this.concentratedSpecialsCart$.value,
			[firstSpecial.SpecialID]: { ...allOrderObject },
		});

		this.AllOrder.push(allOrderObject);

		this.handleTeudaNum();
		this.handleTeudaCreateDate();

		this.GeneralFunction();

		this.isConcentratedProductPopupShowing$.next(false);
	}

	tabChange(event: MatTabChangeEvent) {
		this.concentratedSpecialTabsSelectedIndex$.next(event.index);
	}

	concentratedSpecialQuantityChange(product: Product2, quantity: 'packs' | 'singles' | 'bonus', mode: 'minus' | 'plus', amount: number) {
		const quantityToProperty: Record<typeof quantity, 'TeudaPackQuantity' | 'TeudaQuantity' | 'TeudaBonusQuantity'> = {
			packs: 'TeudaPackQuantity',
			singles: 'TeudaQuantity',
			bonus: 'TeudaBonusQuantity',
		};
		const quantityToPriceProperty: Record<Exclude<typeof quantity, 'bonus'>, 'ArizotPrice' | 'YehidotPrice'> = {
			packs: 'ArizotPrice',
			singles: 'YehidotPrice',
		};
		const special = this.concentratedSpecialCurrentlyEditting$.value.special[0];
		if (!special) return;

		const order = this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.value[special.SpecialID].order;

		const quantityProperty = quantityToProperty[quantity];

		let productInOrder = order.find(p => p.ProductId === product.ProductId);
		if (mode === 'minus') {
			if (!productInOrder) return;

			productInOrder[quantityProperty] = Math.max(productInOrder[quantityProperty] - amount, 0);

			if (productInOrder.TeudaQuantity === 0 && productInOrder.TeudaPackQuantity === 0 && productInOrder.TeudaBonusQuantity === 0) {
				order.splice(order.indexOf(productInOrder), 1);
			}
		}

		if (mode === 'plus') {
			// calculate stock

			const { Stock, qt, BS } = product;
			if (!this.shouldOverrideAgentProductQuantities) {
				if (quantity === 'packs' && (qt === 1 || qt === 3)) return;

				if (quantity === 'singles' && (qt === 1 || qt === 2)) return;
			}

			const incomingQuantity = quantity === 'packs' ? amount * product.ItemPack : amount;

			if (!productInOrder) {
				// replace itempack with relevant quantities
				if (!this.shouldOverrideAgentProductQuantities) {
					if (quantity === 'packs' && Stock < incomingQuantity) {
						if (BS === 2 || BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true)) {
							this.StockAlert = true;
						}

						if (BS === 1 || BS === 4) {
							this.StockAlert = true;
							return;
						}
					}

					if (quantity === 'singles' && Stock <= 0) {
						if (BS === 5 || (BS === 3 && this._ss.BlockMlayParam3 === true) || BS === 6) {
							this.StockAlert = true;
						}

						if (BS === 1 || BS === 2 || BS === 4) {
							this.StockAlert = true;
							return;
						}
					}
				}

				const newOrderItem: AllOrder = {
					Stock: product.Stock,
					ProductId: product.ProductId,
					Product_Name: product.Product_Name,
					Price: special.TeudaItemNetPrice,
					ItemPack: product.ItemPack,
					ItemBarcode: product.ItemBarcode,
					BD: product.BD,
					BP: product.BP,
					BM: product.BM,
					MinPrice: product.MinPrice,
					qt: product.qt,
					BS: product.BS,
					PKD: product.PKD,
					CPP: product.CPP,
					ChangePrice: 0,
					ChangeDiscount: 0,
					ArizotPrice: quantity === 'packs' ? special.TeudaItemNetPrice * amount * product.ItemPack : 0,
					YehidotPrice: quantity === 'singles' ? special.TeudaItemNetPrice * amount : 0,
					TeudaQuantity: quantity === 'singles' ? amount : 0,
					TeudaPackQuantity: quantity === 'packs' ? amount : 0,
					TeudaBonusQuantity: quantity === 'bonus' ? amount : 0,
					IfChangePriceOrDisc: OrderState.noChangeToProduct,
					TeudaBonusPackQuantity: 0,
					TeudaRemark: this.duplicateOrderTeudaRemark || '',
					TeudaItemRemark: 'מבצע ' + special.SpecialID,
					Duplicate: 'false',
					DuplicateOrder: String(this.isDuplicateOrder),
					PermPrice: special.TeudaItemNetPrice,
					PriceInInput: product.Price,
					Ptve: this._ss.VersionNumber,
					StatusInsertItem: this.Mode ? 'ONLINE' : 'OFFLINE',
					ItemPicName: product.ItemPicName,
					CPPTotalRow: (product.TeudaPackQuantity * product.ItemPack + product.TeudaQuantity) * product.CPP,
				};
				order.push(newOrderItem);
				productInOrder = newOrderItem;
			} else {
				const packs = productInOrder.TeudaPackQuantity * product.ItemPack;
				const singles = productInOrder.TeudaQuantity;
				const bonus = productInOrder.TeudaBonusQuantity;
				const check = packs + singles + bonus + incomingQuantity;

				if (BS === 1 && Stock < check && !this.shouldOverrideAgentProductQuantities) {
					this.StockAlert = true;
					return;
				}
				productInOrder[quantityProperty] += amount;

				// calculate yehidot/arizot price
				if (quantityToPriceProperty[quantity]) {
					productInOrder[quantityToPriceProperty[quantity]] = special.TeudaItemNetPrice * productInOrder[quantityProperty];
				}
			}

			productInOrder.TeudaTotalRow = normalizeNumberToMaxTwoDecimal(productInOrder.ArizotPrice + productInOrder.YehidotPrice);
		}

		this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.next({
			...this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.value,
		});
	}

	concentratedMoveToProductCard(product: Product2, number) {
		if (this.concentratedSpecialCurrentlyEditting$.value) return;
		this.MoveToProductCard(product, number);
	}

	MoveToProductCard(product: Product2, number) {
		const { ProductId } = product;
		if (this.isB2BLeasing || !ProductId) return;

		if (product.IsMainProduct) {
			this.openConcentratedProductPopup(product);
			return;
		}

		if (this.isCatalogAgent) number = null;

		// this._alert.handleAlertLogic(MsgType.productPopupOpen);

		if (this.SeeImages === 0) {
			this.picname = product.ItemPicName;
		}
		if (this._ss.CustomerInformation.ShowSpecificationsFileDownload === '1' && product.ItemPicName) {
			this.specificationsFile = 'S-' + product.ItemPicName;
		}

		this.showHideProductDetailPopup(true);

		this.MinAlertChangePrice = false;
		this.MinAlertChangeDiscount = false;

		this.showMoreDetails = false;

		this.FormReset();

		// TODO find out if can use same product passed as arguement. check against all filters/sorts
		this.testpop = [this.AllProducts.find(prd => prd.ProductId === ProductId)];

		if (this.isConcentratedProductPopupShowing$.value) {
			this.testpop = [product];
		}

		console.log(this.testpop);

		if (this._ss.CustNum === '10134' || this._ss.CustNum === '10264' || this._ss.CustomerInformation.CustChangeCss1 === '1') {
			setTimeout(() => {
				if (number === 0) {
					if (this.testpop[0].qt === 0 || this.testpop[0].qt === 2) {
						document.getElementById('Arizot' + ProductId).focus();
					} else {
						document.getElementById('Yehidot' + ProductId).focus();
					}
				}

				if (number === 1) {
					document.getElementById('Yehidot' + ProductId).focus();
				}

				if (number === 2) {
					document.getElementById('Arizot' + ProductId).focus();
				}

				this.GeneralFunction();
			}, 50);
		} else {
			setTimeout(() => {
				let el: HTMLElement | undefined;

				if (number === 0) {
					let el;
					if (this.testpop[0].qt === 0 || this.testpop[0].qt === 3) {
						el = document.getElementById('Yehidot' + ProductId);
					} else {
						el = document.getElementById('Arizot' + ProductId);
					}
					if (el) el.focus();
				}

				if (number === 1) {
					el = document.getElementById('Yehidot' + ProductId);
				}

				if (number === 2) {
					el = document.getElementById('Arizot' + ProductId);
				}

				if (el) el.focus();

				this.GeneralFunction();
			}, 50);
		}

		if (this._ss.CustomerInformation.IsPackDefault === '1') {
			setTimeout(() => {
				document.getElementById('Arizot' + ProductId).focus();
			}, 100);
		}

		this.handleCustChangeCssFocusInput(ProductId);

		//PRODUCT IS IN LOWER CASE !!!!!!!!!
		setTimeout(() => {
			this._ss.GetHearotPerProduct(ProductId).subscribe(res => {
				this.CommentsProduct = res;
				this.CommentsProduct = this.CommentsProduct.recordset;

				for (let x = 0; x < this.CommentsProduct.length; x++) {
					let str = this.CommentsProduct[x].HeadLineStr;
					this.CommentsProduct[x].HeadLineStr = str.toString().toLowerCase();
				}

				const profit = this.CommentsProduct.find(item => item.HeadLineStr === ' מחיר מכירה ');

				if (profit) {
					this.profitNumber = profit.DataLineStr;
				}

				if (this._ss.CustomerInformation.CustChangeCss2 === '1' || this._ss.CustomerInformation.CustChangeCss2 === '2') {
					this._ss.GetHearotPerProductClick(ProductId).subscribe(res => {
						if (typeof res === 'string') {
							return;
						}
						this.CommentsProductChoose = res.recordset;

						if (this._ss.CustomerInformation.CustChangeCss2 === '2' && this._ss.DocType === 11) {
							this.CommentsProductChoose = this.CommentsProductChoose.filter(comment => !comment.rem.includes('החזרה'));
						}
					});
				}
			});
		}, 100);
	}

	openConcentratedProductPopup(product: Product2 | string) {
		let mainID = '';

		if (typeof product === 'string') {
			mainID = product;
		} else {
			mainID = product.ProductId;
		}

		const productsRecord: Record<string, Product2> = this.AllProductsSearch.reduce((p, c: Product2) => {
			if (!c.ProductId) return p;

			p[c.ProductId.toLowerCase()] = c;
			return p;
		}, {} as Record<string, Product2>);

		this.concentratedProductsUnderMain$.next(
			this.concentratedProducts[mainID].map(concentrated => ({ product: productsRecord[concentrated.ProductID ? concentrated.ProductID.toLowerCase() : ''], concentrated })),
		);

		this.buildConcentratedProductsFilters();
		this.isConcentratedProductPopupShowing$.next(true);
	}

	handleCustChangeCssFocusInput(productid: string) {
		setTimeout(() => {
			const { CustChangeCss1 } = this._ss.CustomerInformation;
			if (CustChangeCss1 !== '2' && CustChangeCss1 !== '3') return;

			let element: HTMLElement | undefined;

			if (CustChangeCss1 === '2') {
				element = document.getElementById(`Yehidot${productid}`);
			}
			if (CustChangeCss1 === '3') {
				element = document.getElementById(`Arizot${productid}`);
			}

			if (element) {
				element.focus();
			}

			this.GeneralFunction();
		}, 500);
	}

	// BackToProductsAll() {
	// 	this.FormReset();
	// 	// this.OneProductPop = false;

	// 	var elmnt = document.getElementById('Product' + this.ProductIdForBack);
	// 	elmnt.scrollIntoView({ block: 'center' });

	// 	setTimeout(() => {
	// 		this.GeneralFunction();
	// 	}, 20);
	// }

	AreYouSureDeletePop = false;

	OpenAreYouSureDeletePop() {
		this.AreYouSureDeletePop = true;
	}

	CloseAreYouSureDeletePop() {
		this.AreYouSureDeletePop = false;
	}

	//Search start of word if <= 2 chars, otherwise search anywhere in the word
	SearchProducts() {
		this.virtualScroll.scrollToIndex(0);

		if (this.form.controls.ProductSearch.value === null) return;

		this.FormReset();

		this.handleSearchFunctionality();

		this.SearchErrorPop = !this.AllProducts.length;

		this.GeneralFunction();
	}

	handleSearchFunctionality() {
		const arrayToSearch = this.SearchProductInAllProducts === true ? this.SaveSectionSearch : this.AllProductsSearch;

		this.AllProducts = [];

		const productSearchValue: string = this.form.controls.ProductSearch.value?.toLowerCase?.();
		const productSearchValueLength = productSearchValue.length;

		const rankExactSearchResult = this.rankExactSearchResult;
		const prioritizeFirstLetters = this.prioritizeFirstLetters;

		if (!productSearchValue) {
			this.AllProducts = arrayToSearch;

			if (this._ss.CustomerInformation.SortByStock === '1') {
				this.handleSortByStock('visible');
			}

			return;
		}
		const prioritizeResultArray = [];

		let val: string, val2: string | undefined;

		switch (this.form.controls.SearchOptions.value) {
			case 'Name':
				val = 'Product_Name';
				val2 = 'Product_NameFilterd';
				break;
			case 'Code':
				val = 'ProductId';
				val2 = 'ProductIdFilterd';
				break;
			case 'Barcode':
				val = 'ItemBarcode';
				break;
			case 'ItemExtra1':
				val = 'ItemExtra1';
				break;

			default:
				break;
		}

		if (val) {
			if (productSearchValueLength <= 2) {
				for (let i = 0; i < arrayToSearch.length; i++) {
					if (arrayToSearch[i][val] && arrayToSearch[i][val].slice(0, productSearchValueLength).toLowerCase() === productSearchValue) {
						this.AllProducts.push(arrayToSearch[i]);
					}
				}
				if (this._ss.CustomerInformation.SortByStock === '1') {
					this.handleSortByStock('visible');
				}
			}

			if (productSearchValueLength > 2) {
				const splitValue = productSearchValue.split(' ');

				for (let i = 0; i < arrayToSearch.length; i++) {
					let counter = 0,
						otherVal = '';

					const prodWithoutSpaces = (arrayToSearch[i][val] || '').replace(/\s/g, '').toLowerCase();

					if (val2) {
						otherVal = (arrayToSearch[i][val2] || '').replace(/\s/g, '').toLowerCase();
					}

					// V8 optimizes this if statement, maybe
					if (typeof otherVal === 'string' && typeof prodWithoutSpaces === 'string') {
						for (let j = 0; j < splitValue.length; j++) {
							if ((prodWithoutSpaces && prodWithoutSpaces.indexOf(splitValue[j]) > -1) || (otherVal && otherVal.indexOf(splitValue[j]) > -1)) {
								counter++;
							}
						}
					}

					if (counter >= splitValue.length) {
						// rank exact matches higher in search
						if ((rankExactSearchResult || prioritizeFirstLetters) && arrayToSearch[i][val]) {
							if (rankExactSearchResult) {
								let counter2 = 0;
								for (const value of splitValue) {
									if (arrayToSearch[i][val].indexOf(value) > -1) {
										counter2++;
									}
								}

								if (counter2 >= splitValue.length) {
									prioritizeResultArray.push(arrayToSearch[i]);
									continue;
								}
							}
							const index = arrayToSearch[i][val].indexOf(productSearchValue);
							if (prioritizeFirstLetters && index > -1 && index === 0 && arrayToSearch[i].Stock) {
								prioritizeResultArray.push(arrayToSearch[i]);
								continue;
							}
						}
						this.AllProducts.push(arrayToSearch[i]);
					}
				}
			}
			this.AllProducts = [...prioritizeResultArray, ...this.AllProducts];

			if (this._ss.CustomerInformation.SearchLogic === '3') {
				this.filterNonExactMatches(productSearchValue, val, (val2 !== 'Product_NameFilterd' && val2) || null);
				this.rankByExactResult(productSearchValue, val, val2);
			}

			if (this._ss.CustomerInformation.SortByStock === '1') {
				this.handleSortByStock('visible');
			}

			return;
		}

		// search in 'all' option
		if (productSearchValueLength <= 2) {
			const len = productSearchValueLength;
			for (let x = 0; x < arrayToSearch.length; x++) {
				if (
					(arrayToSearch[x].Product_Name || '').toString().slice(0, len).toLowerCase() === productSearchValue ||
					(arrayToSearch[x].ProductId || '').toString().slice(0, len).toLowerCase() === productSearchValue ||
					(arrayToSearch[x].ItemExtra1 || '').toString().slice(0, len).toLowerCase() === productSearchValue
				) {
					this.AllProducts.push(arrayToSearch[x]);
				}
			}
			if (this._ss.CustomerInformation.SortByStock === '1') {
				this.handleSortByStock('visible');
			}
		}

		if (productSearchValueLength > 2) {
			const splitValue = productSearchValue.split(' ');

			for (let i = 0; i < arrayToSearch.length; i++) {
				let counter = 0;

				const { Product_Name, Product_NameFilterd, ProductId, ProductIdFilterd, ItemBarcode, ItemExtra1 } = arrayToSearch[i];

				const prodNameWithoutSpaces = (Product_Name || '').replace(/\s/g, '').toLowerCase(),
					filteredProdNameWithoutSpaces = (Product_NameFilterd || '').replace(/\s/g, '').toLowerCase(),
					productIdWithoutSpaces = (ProductId || '').replace(/\s/g, '').toLowerCase(),
					filteredProductIdWithoutSpaces = (ProductIdFilterd || '').replace(/\s/g, '').toLowerCase(),
					itemBarcodeWithoutSpaces = (ItemBarcode || '').replace(/\s/g, '').toLowerCase(),
					itemExtra1WithoutSpaces = (ItemExtra1 || '').replace(/\s/g, '').toLowerCase(),
					allStrings =
						typeof prodNameWithoutSpaces === 'string' &&
						typeof filteredProdNameWithoutSpaces === 'string' &&
						typeof filteredProductIdWithoutSpaces === 'string' &&
						typeof itemBarcodeWithoutSpaces === 'string' &&
						typeof itemExtra1WithoutSpaces === 'string' &&
						typeof productIdWithoutSpaces === 'string';

				// V8 optimizes this if statement, maybe
				if (allStrings) {
					for (let j = 0; j < splitValue.length; j++) {
						if (
							(prodNameWithoutSpaces && prodNameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(filteredProdNameWithoutSpaces && filteredProdNameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(productIdWithoutSpaces && productIdWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(filteredProductIdWithoutSpaces && filteredProductIdWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(itemBarcodeWithoutSpaces && itemBarcodeWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(itemExtra1WithoutSpaces && itemExtra1WithoutSpaces.indexOf(splitValue[j]) > -1)
						) {
							counter++;
						}
					}

					if (counter >= splitValue.length) {
						// rank exact matches higher in search
						if ((rankExactSearchResult || prioritizeFirstLetters) && Product_Name) {
							if (rankExactSearchResult) {
								let counter2 = 0;
								for (const value of splitValue) {
									if (Product_Name.indexOf(value) > -1) {
										counter2++;
									}
								}

								if (counter2 >= splitValue.length) {
									prioritizeResultArray.push(arrayToSearch[i]);
									continue;
								}
							}
							const index = Product_Name.indexOf(productSearchValue);
							if (prioritizeFirstLetters && index > -1 && index === 0 && arrayToSearch[i].Stock) {
								prioritizeResultArray.push(arrayToSearch[i]);
								continue;
							}
						}
						this.AllProducts.push(arrayToSearch[i]);
					}
				}
			}

			this.AllProducts = [...prioritizeResultArray, ...this.AllProducts];

			if (this._ss.CustomerInformation.SearchLogic === '3') {
				this.filterNonExactMatches(productSearchValue, val, 'Product_Name', 'Product_NameFilterd', 'ProductId', 'ProductIdFilterd', 'ItemBarcode', 'ItemExtra1');
			}

			if (this._ss.CustomerInformation.SortByStock === '1') {
				this.handleSortByStock('visible');
			}
		}
	}

	filterNonExactMatches(searchValue: string, value: string, ...args: string[]) {
		const split = searchValue.split(' ');

		if (args.length) {
			this.AllProducts = this.AllProducts.filter(prod => {
				let counter = 0;

				for (const substring of split) {
					const firstValueMatch = prod[value] && prod[value].toLowerCase().indexOf(substring) > -1;
					const anyMatch = args.some(property => prod[property] && prod[property].toLowerCase().indexOf(substring) > -1);
					if (anyMatch || firstValueMatch) {
						counter++;
					}
				}

				return counter >= split.length;
			});
			return;
		}

		this.AllProducts = this.AllProducts.filter(prod => {
			let counter = 0;

			for (const substring of split) {
				if (prod[value] && prod[value].toLowerCase().indexOf(substring) > -1) {
					counter++;
				}
			}

			return counter === split.length;
		});
	}

	rankByExactResult(searchValue: string, value: string, ...args: string[]) {
		if (args.length) {
			this.AllProducts.sort((a, b) => {
				if ((b[value] && b[value].indexOf(searchValue) > -1) || args.some(property => b[property] && b[property].indexOf(searchValue) > -1)) {
					return 1;
				}

				return -1;
			});
			return;
		}

		this.AllProducts.sort((a, b) => {
			if (b[value] && b[value].indexOf(searchValue) > -1) {
				return 1;
			}

			return -1;
		});
	}

	SectionSearchClear() {
		this.Groups = [...this.GroupsSearch];
		this.form.controls.SectionSearch.reset();
	}

	SearchSection() {
		window.scrollTo(0, 0);

		if (this.hasMGroups) {
			// is handled through .pipe
			return;
		}
		const sectionSearchValue = this.form.controls.SectionSearch.value?.toLowerCase?.();

		if (!sectionSearchValue) {
			this.Groups = [...this.GroupsSearch];
			return;
		}

		if (sectionSearchValue.length <= 2) {
			this.Groups = this.GroupsSearch.filter(
				grp =>
					(grp.GroupName || '').toLowerCase().slice(0, sectionSearchValue.length) === sectionSearchValue.slice(0, sectionSearchValue.length) ||
					(grp.GroupId || '').toLowerCase().slice(0, sectionSearchValue.length) === sectionSearchValue.slice(0, sectionSearchValue.length),
			);
			return;
		}

		this.Groups = this.GroupsSearch.filter(grp => (grp.GroupName || '').toLowerCase().indexOf(sectionSearchValue) > -1 || (grp.GroupId || '').toLowerCase().indexOf(sectionSearchValue) > -1);
	}

	handleGroupDiscountsClientAndCompanyFilter() {
		this.allGroupDiscounts = this.allGroupDiscounts.filter(grp => grp.cl == this._ss.OneClientInfo.ClientId && grp.cm == (this._ss.OneClientInfo.company || 1));
	}

	viewTypes = {
		Big: 'big',
		Small: 'small',
		Medium: 'medium',
		Inline: 'inline',
	};

	productType: string = this.viewTypes.Inline;
	filterSortActive = false;
	filterSortBlock = false;
	showDepartmentsPopup = false;
	showDepartmentsList = false;
	showProductDetailPopup = false;
	showSortPopup = false;
	addComment = false;
	productsPerRowMedium = 2;

	showAddPopup = false;

	offsetTop = null;

	setProductSmallView(): string {
		return this.windowWidth >= TABLET_RESOLUTION_CUTOFF ? this.viewTypes.Medium : this.viewTypes.Small;
	}

	setProductMediumView(): string {
		return this.windowWidth >= TABLET_RESOLUTION_CUTOFF ? this.viewTypes.Small : this.viewTypes.Medium;
	}

	chooseViewType(type: string): void {
		if (!this.isRallyanceReclamation) {
			for (let i = 0; i < this.AllProducts.length; i++) {
				const element = this.AllProducts[i];
				element.present.showContent = false;
			}
		}
		this.productType = type;
		window.scrollTo(0, 0);
		this.virtualScroll.scrollToIndex(0);

		localStorage.setItem('view', type);

		setTimeout(() => {
			this.handleAdjustVirtualViewportHeight();
			this.GeneralFunction();
		}, 50);
		this.FormReset();
	}

	showHideSortAndDepartments(bool: boolean) {
		this.showHideSortBlock(bool);
		this.showHideDepartmentsPopup(bool);
	}

	showHideSortBlock(value: boolean): void {
		this.filterSortActive = value;
		this.filterSortBlock = value;
	}

	showHideDepartmentsPopup(value: boolean): void {
		this.addRemoveBodyClass(value);

		this.showDepartmentsPopup = value;

		if (value && this.departmentsWrapperScrollY) {
			setTimeout(() => {
				const wrapper = this.getDepartmentsWrapperScrollerCurrentlyInDOM();

				if (!wrapper) return;
				const { nativeElement } = wrapper;
				if (nativeElement) {
					nativeElement.scrollTo({ top: this.departmentsWrapperScrollY });
				}
			}, 0);
		}

		if (this.HasMainGroup === '1') {
			// this.HasMahlakotMain = 1
			// this.Groups = this._ss.SaveMainGroups
			// this.GroupsSearch = this._ss.SaveMainGroups
		}
	}

	changeDepartmentSort(ev: { source: MatRadioButton; value: string }): void {
		if (ev.value === '1') {
			this.Groups.sort((a, b) => a.GroupId.localeCompare(b.GroupId));
		}

		if (ev.value === '2') {
			this.Groups.sort((a, b) => a.GroupName.localeCompare(b.GroupName));
		}
	}

	ResetGroups() {
		if (this.hasMGroups) {
			if (this.showDepartmentsList) {
				this.showDepartmentsList = !this.showDepartmentsList;
				this.selectedMainGroup = { GroupId: '', GroupName: '' };
				this.Groups = Object.values(this.MGroupsRecord).map(group => group.main);
				return;
			}
		}

		if (this.HasMainGroup === '1') {
			this.HasMahlakotMain = 1;
			this.Groups = this._ss.SaveMainGroups;
			this.GroupsSearch = this._ss.SaveMainGroups;
		}
	}

	showHideSortPopup(value: boolean, key: string): void {
		this.addRemoveBodyClass(value);

		this.showSortPopup = value;

		//TODO Needs to be rewritten as unclosured dependency to affect
		// ? What's the point of this part ? What was the intention ?
		if (key == 'forget') {
			document.querySelectorAll('ItemExtra1').forEach((el: HTMLDivElement) => {
				el.style.backgroundColor = 'white';
			});
		}
	}

	addRemoveBodyClass(value: boolean): void {
		if (value) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}
	}

	showPresentContent(index): void {
		if (this.alternativeProductCalculations || this.isRallyanceReclamation) {
			return;
		}
		this.AllProducts.forEach((product, i) => {
			product.present.showContent = index === i && !product.present.showContent;
		});

		setTimeout(() => {
			this.FormReset();
			this.GeneralFunction();
		}, 100);
	}
	picname = '';
	specificationsFile = '';

	showHideProductDetailPopup(value: boolean): void {
		this.profitNumber = 0;
		this.addRemoveBodyClass(value);

		this.showProductDetailPopup = value;

		if (this._ss.CustomerInformation.ShowFileDownload === '1' && value && this.picname) {
			this.doesFileExistInFolder(this.picname, 'image-download');
		}
		if (this._ss.CustomerInformation.ShowSpecificationsFileDownload === '1' && value && this.specificationsFile) {
			this.doesFileExistInFolder(this.specificationsFile, 'specifications-file');
		}

		if (!value) {
			this.doesImageExist = '';
			this.doesSpecificationFileExist = '';
		}

		this.GeneralFunction();
		this.HistoryaParitVar = null;
	}

	handleCheckSpecificationFile(product: Product2) {
		if (this._ss.CustomerInformation.ShowSpecificationsFileDownload !== '1') return;
	}

	checkFaultyProduct() {
		const isMissingNetPrice = this.AllOrder.filter(ord => 'NetPrice' in ord && ord.NetPrice === undefined);
		if (isMissingNetPrice.length) {
			for (const ord of isMissingNetPrice) {
				ord.Netprice = ord.Price || ord.DocNetPrice;
			}
		}
	}

	showHideMenu(value: boolean): void {
		if (value) {
			this._ss.openMenuAndAnimateButton();
			return;
		}

		this._ss.closeMenu();
	}

	scrollTo(id: string, position?): void {
		const item = document.getElementById(id);
		const itemOffsetTop = item.offsetTop;

		if (itemOffsetTop !== this.offsetTop) {
			item.scrollIntoView({ behavior: 'smooth', block: position ? position : 'start' });

			this.offsetTop = itemOffsetTop;
		}
	}

	// showHideLogoutPopup(value: boolean): void {
	// 	this.showLogoutPopup = value;
	// }

	showHideAddPopup(value: boolean): void {
		this.addRemoveBodyClass(value);
		this.showHideProductDetailPopup(!value);
		this.MinAlertChangePrice = false;
		this.MinAlertChangeDiscount = false;

		this.showAddPopup = value;
	}

	DohotParitPop = false;
	HistoryaParitPop = false;

	DohotParit(ProductId) {
		if (this.DohotParitPop === false) {
			if (this.HistoryaParitVar === null) {
				this._ss.GetAllHistoryaParitClientService(ProductId).subscribe({
					next: res => {
						this.HistoryaParitVar = res;
						this.HistoryaParitVar = this.HistoryaParitVar.recordset;

						this.loadingHistoryaParit = false;

						this.teudaLength = this.HistoryaParitVar?.[0]?.DocDocNum?.length;

						this.HistoryaParitVar.sort((a, b) => new Date(b.DocCreateDate).getTime() - new Date(a.DocCreateDate).getTime());
					},
					error: () => {
						this.loadingHistoryaParit = false;
					},
				});
			}
			this.loadingHistoryaParit = false;
			this.DohotParitPop = true;
		} else {
			this.loadingHistoryaParit = false;
			this.DohotParitPop = false;
		}
	}

	@ViewChild('historyaTableStart') rightSideOfTableHistoriyaOrDohot: ElementRef<HTMLTableCellElement>;
	@ViewChild('historyaTableStart1') rightSideOfTableHistoriyaOrDohotPopupTable: ElementRef<HTMLTableCellElement>;
	loadingHistoryaParit = false;
	teudaLength;
	HistoryaParitVar = null;
	HistoryaParit(ProductId) {
		this.loadingHistoryaParit = true;

		if (this.HistoryaParitPop !== false) {
			this.HistoryaParitPop = false;
			this.loadingHistoryaParit = false;
			if (this.isB2BAndShouldSeeHistoryButton) {
				this.HistoryaParitVar = null;
			}
			return;
		}

		if (this.HistoryaParitVar === null) {
			this._ss.GetAllHistoryaParitClientService(ProductId).subscribe({
				next: res => {
					this.HistoryaParitVar = res;
					this.HistoryaParitVar = this.HistoryaParitVar.recordset;
					this.loadingHistoryaParit = false;

					this.teudaLength = this.HistoryaParitVar?.[0]?.DocDocNum?.length;

					this.HistoryaParitVar.sort((a, b) => new Date(b.DocCreateDate).getTime() - new Date(a.DocCreateDate).getTime());

					if (this.HistoryaParitVar?.length) {
						setTimeout(() => {
							this.rightSideOfTableHistoriyaOrDohot.nativeElement.scrollIntoView();
						}, 10);
					}
				},
				error: () => {
					this.HistoryaParitVar = null;
					this.loadingHistoryaParit = false;
				},
			});
		} else {
			this.loadingHistoryaParit = false;

			if (this.HistoryaParitVar?.length) {
				setTimeout(() => {
					this.rightSideOfTableHistoriyaOrDohot.nativeElement.scrollIntoView();
				}, 10);
			}
		}

		this.HistoryaParitPop = true;
		this.loadingHistoryaParit = false;
	}

	handleTeudaNum() {
		//TODO refactor after 613.3
		const isTeudaNumAlreadyExisting = this.AllOrder.find(ord => ord?.TeudaNum);

		let TeudaNum = new Date().getTime() + '000' + this._ss.AgentIdConnected;

		// Reclamation can only have up to 16 digits
		if (this._ss.DocType === RECLAMATION_DOCTYPE) {
			TeudaNum = '' + new Date().getTime();
		}

		if (isTeudaNumAlreadyExisting) {
			this.AllOrder[this.AllOrder.length - 1].TeudaNum = isTeudaNumAlreadyExisting.TeudaNum;
		} else {
			this.AllOrder.forEach(ord => {
				ord.TeudaNum = TeudaNum;
			});
		}
	}

	handleTeudaCreateDate() {
		//TODO refactor after 613.3
		const isCreateDateAlreadyExisting = this.AllOrder.find(ord => ord?.TeudaCreateDate);

		if (isCreateDateAlreadyExisting) {
			this.AllOrder[this.AllOrder.length - 1].TeudaCreateDate = isCreateDateAlreadyExisting.TeudaCreateDate;
		} else {
			const TeudaCreateDate = new Date().toISOString();
			this.AllOrder.forEach(ord => {
				ord.TeudaCreateDate = TeudaCreateDate;
			});
		}
	}

	preventContextAndDownload(e: PointerEvent | MouseEvent) {
		// alert('triggers context event')
		if (!('share' in navigator)) {
			const a = document.createElement('a');
			a.href = (e.srcElement as HTMLImageElement).currentSrc;
			a.download = '';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

	handleGetDataURLForImageDownload(src: string, filename: string, e: Event) {
		if (e) e.preventDefault();

		const image = new Image();
		image.crossOrigin = 'Anonymous';

		image.onload = function () {
			const canvas = document.createElement('canvas'),
				context = canvas.getContext('2d');

			canvas.height = this['naturalHeight'];
			canvas.width = this['naturalWidth'];
			context.drawImage(this as HTMLImageElement, 0, 0);

			const dataURL = canvas.toDataURL('image/jpeg'),
				a = document.createElement('a');

			a.href = dataURL;
			a.download = filename + '.jpg';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		};

		image.src = src;
	}

	triggerDownloadWithContextMenu(e: Event) {
		console.log(e);
	}

	openExtraInfoModal(e: MouseEvent) {
		console.log(e.clientX, e.clientY);
	}

	async openDialog(product) {
		if (this.isB2BAndShouldSeeHistoryButton) {
			this.HistoryaParit(product.ProductId);
			return;
		}

		if (this.isCatalogAgent) return;
		this.MinAlertChangePrice = false;
		this.MinAlertChangeDiscount = false;

		this.showMoreDetails = false;

		this.FormReset();

		this.CommentsProduct = [];

		this.testpop = [product];

		console.log(this.testpop);

		if (!this.concentratedSpecialCurrentlyEditting$.value || !Object.keys(this.concentratedSpecialCurrentlyEditting$.value).length) {
			this.GeneralFunction();
		}

		//PRODUCT IS IN LOWER CASE !!!!!!!!!
		setTimeout(() => {
			this._ss.GetHearotPerProduct(product.ProductId).subscribe({
				next: res => {
					this.CommentsProduct = res;
					this.CommentsProduct = this.CommentsProduct.recordset;

					for (let x = 0; x < this.CommentsProduct.length; x++) {
						let str = this.CommentsProduct[x].HeadLineStr;
						this.CommentsProduct[x].HeadLineStr = str.toString().toLowerCase();
					}

					this.dialog.openDialogs[0].componentInstance.data.CommentsProduct = this.CommentsProduct;

					if (this._ss.CustomerInformation.CustChangeCss2 === '1' || this._ss.CustomerInformation.CustChangeCss2 === '2') {
						this._ss.GetHearotPerProductClick(product.ProductId).subscribe(res => {
							if (typeof res === 'string') {
								return;
							}
							this.CommentsProductChoose = res.recordset;

							if (this._ss.CustomerInformation.CustChangeCss2 === '2' && this._ss.DocType === 11) {
								this.CommentsProductChoose = this.CommentsProductChoose.filter(comment => !comment.rem.includes('החזרה'));
							}
						});
					}
				},

				complete: () => {
					this.dialog.openDialogs[0].componentInstance.data.loadingComments = false;
				},
			});
		}, 100);

		this.dialog.open(ProductInfoDialogComponent, {
			data: {
				ap: {
					...this.testpop[0],
					IGC: this.testpop[0].IGC + (this.testpop[0].IGC && this.groupNamesRecord[this.testpop[0].IGC] ? ' - ' + this.groupNamesRecord[this.testpop[0].IGC].GroupName : ''),
				},
				CommentsProduct: this.CommentsProduct,
				ShowProfit: this.ShowProfit,
				loadingComments: true,
			},
			maxHeight: '80vh',
		});

		if (!this.showProductDetailPopup) {
			this.dialog.afterAllClosed.pipe(takeUntil(this.destroy$)).subscribe(() => {
				this.testpop = null;
			});
		}
	}

	doesImageExist = '';
	doesSpecificationFileExist = '';

	doesFileExistInFolder(lookupString: string, mode: 'image-download' | 'specifications-file') {
		const callbackObject = {
			next: res => {},
			error: err => {
				console.log(err);
			},
		};
		if (mode === 'image-download') {
			callbackObject.next = (res: { file: string }) => {
				this.doesImageExist = res.file;
			};
		}

		if (mode === 'specifications-file') {
			callbackObject.next = res => {
				this.doesSpecificationFileExist = res.file;
			};
		}

		this._ss.checkIfImageExistInServer(lookupString).subscribe(callbackObject);
	}

	handleUnBlockQty() {
		if (this._ss.CustomerInformation.UnBlockQty && this._ss.CustomerInformation.UnBlockQty !== '0') {
			const { UnBlockQty } = this._ss.CustomerInformation,
				{ DocType } = this._ss;

			if (this.resolveIfDocIsOfReturnsType()) {
				if (UnBlockQty === '1' || UnBlockQty === '3') {
					this.shouldOverrideAgentProductQuantities = true;
				}
				return;
			}

			if (DocType === 7) {
				if (UnBlockQty === '2' || UnBlockQty === '3') {
					this.shouldOverrideAgentProductQuantities = true;
				}
				return;
			}
		}
	}

	resolveIfDocIsOfReturnsType(doc: string | number = this._ss.DocType) {
		if (!this._doctypes.doesHaveExtraDoctypeDefinitions()) return this._doctypes.isSpecialRefundDoctype(doc) || +doc === 74;
		return this._doctypes.isSpecialRefundDoctype(doc) || this._doctypes.typesOfDoctypes.return.has(+doc);
	}

	handleBlockInputs() {
		if (this._ss.CustomerInformation.BlockChangingPriceBonusDiscountOrQuantities && this._ss.CustomerInformation.BlockChangingPriceBonusDiscountOrQuantities !== '0') {
			const { BlockChangingPriceBonusDiscountOrQuantities: block } = this._ss.CustomerInformation;

			if (block === '1') {
				this.shouldDisableChangingInputs = true;
			}
			if (block === '2') {
				this.shouldDisablePriceAndDiscountInput = true;
			}
			if (block === '3') {
				this.shouldDisableChangingClickingFunctions = true;
			}
		}
	}

	async postOnInitFunctions() {
		this.handleAdjustVirtualViewportHeight();
		this.updateCookiesState();
		this.FilterProductName();
		this.handleSortProducts();
		this.handleSpecialDoctype();
		this.handleOnlyInPriceListParameter();
		this.handleSortWhenEnter();
		this.handleBuildConcentratedProductsList();
		this.setupConcentratedProductsFormInteractivity();
		// this.setImageNameForConcentratedProducts();
		this.handleRememberLastSearchOption();

		if (this._ss.CustomerInformation.ProductWeightType === '1') {
			this.handleGenerateItemPacksRecord();
		}
		this.toggleMarkGroupIfAnyProduct();

		this.handleSetCdkItemSize();

		if (this.hasSpecials) {
			await this.handleGetSpecials();
			this.markProductsAsPartOfSpecial();
		}

		if (this._ss.CustomerInformation.GetOriginalStock === '1') {
			const stockOriginal = localStorage.getItem('StockOriginal');

			if (stockOriginal) {
				this.handleSetupOriginalStock(stockOriginal);
			}
		}

		if (this._ss.CustomerInformation.ProductWeightType === '1') {
			this.handleWeightTypeMarking();
		}

		if (this._ss.CustomerInformation.SortProductGroups === '1') {
			this.groupSort.setValue('2');
			this.GroupsSearch.sort((a, b) => a.GroupName.localeCompare(b.GroupName));
			this.Groups.sort((a, b) => a.GroupName.localeCompare(b.GroupName));
		}

		if (this._ss.CustomerInformation.SortByStock === '1') {
			this.handleSortByStock('all');
		}

		if (this._b2b.isB2BAgentAndNoDiscountsAndDates$.value) {
			this.handleSortByNumOfTimes('all');
		}

		if (this.hasGroupSum) {
			this.buildGroupSumStore();
		}

		this.handleHideSinglesAndPacksIfParameterIsOn();

		// this._ss.checkConnection().subscribe({
		// 	next: () => {},
		// 	error: res => {
		// 		if (res?.status === 404) {
		// 			this.Mode = true;
		// 			return;
		// 		}

		// 		this.Mode = false;
		// 	},
		// });

		this.GeneralFunction();
	}

	handleHideSinglesAndPacksIfParameterIsOn() {
		if (this._ss.CustomerInformation.HideQuantityWhenNoStockWithDoctype === '0' || !this._ss.CustomerInformation.HideQuantityWhenNoStockWithDoctype) return;
		const split: number[] = this._ss.CustomerInformation.HideQuantityWhenNoStockWithDoctype.split('_').map(string => Number(string));

		if (!split.includes(this._ss.DocType)) return;

		for (const product of this.AllProductsSearch) {
			if (product.Stock > 0) continue;
			product.hideSingles = true;
			product.hidePacks = true;
		}

		// if (this.AllProducts?.length) {
		// 	for (const product of this.AllProducts) {
		// 		if (product.Stock > 0) continue;
		// 		product.hideSingles = true;
		// 		product.hidePacks = true;
		// 	}
		// }
	}

	buildGroupSumStore() {
		this.groupSumStore = this.AllProductsSearch.reduce((prev, curr) => {
			prev[curr.GroupSum] = { GroupSum: curr.GroupSum, Total: 0 };
			return prev;
		}, {});
	}

	refreshGroupSumStore() {
		for (const group in this.groupSumStore) {
			this.groupSumStore[group].Total = 0;
		}
	}

	handleUpdateGroupSumStore() {
		for (const product of this.AllOrder) {
			this.groupSumStore[product.GroupSum] ||= { GroupSum: product.GroupSum, Total: 0 };
			this.groupSumStore[product.GroupSum].Total += product.TeudaTotalRow;
		}
	}

	prepareGroupSumDisplay() {
		this.groupSumDisplay = Object.values(this.groupSumStore);
		if (this._ss.CustomerInformation.GroupSum === '2') {
			this.groupSumDisplay = this.groupSumDisplay.filter(grp => grp.Total);
		}
	}

	// resolveParameters() {}

	handleGenerateItemPacksRecord() {
		const itempacks = localStorage.getItem('ItemPacks');

		if (!itempacks) return;

		this.itempackRecord = (JSON.parse(decompress(itempacks)) as ItemPacks[]).reduce((prev, curr) => {
			const prodID = curr.ProductID.toLowerCase();
			prev[prodID] ||= [];
			prev[prodID].push(curr);
			return prev;
		}, {});

		let orderRecord: Record<string, Product2> = {};

		if (this.AllOrder?.length) {
			orderRecord = this.AllOrder.filter(product => product.weightMode || product.packsWeightMode).reduce((prev, curr) => {
				prev[curr.ProductId] = curr;
				return prev;
			}, {});
		}

		for (const product of this.AllProductsSearch) {
			const itempack = this.itempackRecord[product.ProductId];
			if (!itempack) continue;

			if (environment.development && product.ProductId === '30001') {
				product.Type = 5;
				product.ItemPack = 10;
			}

			product.originalItemPack = product.ItemPack;

			if (orderRecord[product.ProductId] && orderRecord[product.ProductId].packsWeightMode) {
				const { packsWeightMode } = orderRecord[product.ProductId];

				if (packsWeightMode === 'packs') {
					delete product.Unit;
					continue;
				}
			}

			this.originalItempackRecord[product.ProductId] = product.ItemPack;
			product.Unit = itempack[0].Unit;
			product.ItemPack = itempack[0].UnitPerPack;
		}

		console.log(this.originalItempackRecord, this.itempackRecord);
	}

	handleSortByStock(mode: 'all' | 'visible') {
		this._change.detach();

		this.AllProducts.sort((a, b) => {
			if (a.Stock && !b.Stock) {
				return -1;
			}
			if (!a.Stock && b.Stock) {
				return 1;
			}
			return 0;
		});

		this.AllProducts = [...this.AllProducts];

		if (mode === 'visible') {
			this._change.detectChanges();
			this._change.reattach();
			return;
		}

		const arrays = [this.AllProductsSearch, this.SaveSectionSearch, this.SaveSectionSearchOnlySpecPricessOrderByDate, this._ss.SpecPriceSs];

		for (const array of arrays) {
			if (!array || !Array.isArray(array)) continue;
			array.sort((a, b) => {
				if (a.Stock && !b.Stock) {
					return -1;
				}
				if (!a.Stock && b.Stock) {
					return 1;
				}
				return 0;
			});
		}
		this._change.detectChanges();
		this._change.reattach();
	}

	handleSortByNumOfTimes(mode: 'all' | 'visible') {
		const withNumOfTimes = this.AllProducts.filter(prd => prd.NumOfTimes);
		withNumOfTimes.sort((a, b) => b.NumOfTimes - a.NumOfTimes);
		// sort this.AllProducts by leading with products that have NumOfTimes
		this.AllProducts.sort((a, b) => {
			const order = withNumOfTimes.indexOf(b) - withNumOfTimes.indexOf(a);
			return order || 0;
		});

		if (mode === 'visible') {
			return;
		}

		const arrays = [this.AllProductsSearch, this.SaveSectionSearch, this.SaveSectionSearchOnlySpecPricessOrderByDate, this._ss.SpecPriceSs];

		for (const array of arrays) {
			if (!array || !Array.isArray(array)) continue;
			const withNumOfTimes = array.filter(prd => prd.NumOfTimes);
			withNumOfTimes.sort((a, b) => b.NumOfTimes - a.NumOfTimes);
			array.sort((a, b) => {
				const order = withNumOfTimes.indexOf(b) - withNumOfTimes.indexOf(a);
				if (order) return order;
				return 0;
			});
		}
	}

	updateCookiesState() {
		try {
			this.cookiesState = this._cookies.getAll();
		} catch (error) {
			console.log('cookies not supported');
		}
	}

	handleRememberLastSearchOption() {
		if (this.saveLastSearchOptionSelected) {
			this.form.controls.SearchOptions.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
				localStorage.setItem('lastSearch', value);
			});
		} else {
			localStorage.removeItem('lastSearch');
		}
	}

	handleSetupOriginalStock(stockOriginal: string) {
		const productsSet = new Set(this.AllProductsSearch.map(prod => prod.ProductId));
		const stock: StockOriginal[] = JSON.parse(decompress(stockOriginal));

		if (stock.length) {
			const filteredStock = stock.filter(stock => productsSet.has(stock.StockItemCode));

			if (filteredStock.length) {
				this.warehouseStock = filteredStock;
			}
		}
	}

	markProductsWithClearerProperties() {
		for (const product of this.AllProductsSearch) {
			product.basePrice = product.NetPrice;
		}
	}

	handleAdjustVirtualViewportHeight() {
		if (this.virtualScroll) {
			const element = this.virtualScroll.elementRef.nativeElement;
			const { y } = element.getBoundingClientRect();
			element.style.height = `calc( 100vh - ${y}px)`;
		}
	}
	handleSortWhenEnter() {
		if (this._ss.BackToProductsFromTeuda) {
			// Might be duplicate of  if (this._ss.SpecPriceSs !== null) in ngoninit
			this.ChoosenSection = this._ss.BackToProductsFromTeudaSectionName;
			return;
		}

		if (!this._ss.CustomerInformation.SortWhenEnter || this._ss.CustomerInformation.SortWhenEnter === '0') return;

		if (['1', '2', '3'].includes(this._ss.CustomerInformation.SortWhenEnter)) {
			this.Shchichim(+this._ss.CustomerInformation.SortWhenEnter);
			return;
		}

		const days: 30 | 60 = ((Number(this._ss.CustomerInformation.SortWhenEnter as '4' | '5') - 3) * 30) as 30 | 60;
		this.handleLastBoughtOnFilter(days);
	}

	handleSpecialDoctype() {
		const ls = localStorage.getItem('DocSpecialProducts');

		const specialDocProductIDs = ls ? JSON.parse(decompress(ls)) : ls;

		if (!specialDocProductIDs) return;

		const filteredWithDoctype = specialDocProductIDs.filter(special => special.DocType === this._ss.DocType);

		if (!filteredWithDoctype.length) return;

		// const set = new Set(filteredWithDoctype.map(spc => spc.ProductID));

		const filteredWithDoctypeHash = filteredWithDoctype.reduce((prev, curr) => {
			prev[curr.ProductID] = curr;
			return prev;
		}, {});

		this.AllProductsSearch = this.AllProductsSearch.filter(prd => filteredWithDoctypeHash[prd.ProductId]).map(prd => {
			const { price, discount, extra5, stock, Stock } = filteredWithDoctypeHash[prd.ProductId];

			if (extra5 === '1') {
				prd.PermPrice = prd.ShowPrice = prd.Price;
				prd.PriceInInput = prd.ChangePrice = price;

				prd.ChangeDiscount = prd.discount = discount;

				prd.BD = prd.BP = prd.BB = 1;

				// I dont remember if the name of the column is capitalized
				prd.Stock = stock ?? Stock;

				prd.Price = prd.NetPrice = price * (1 - discount / 100);
				prd.dontTouchPriceOrDiscount = true;
			}
			return prd;
		});
		this.AllProducts = this.AllProducts.filter(prd => filteredWithDoctypeHash[prd.ProductId]);
		// .map(prd => {
		// 	const {price,discount,extra5} = filteredWithDoctypeHash[prd.ProductId]

		// 	if (extra5 === '1'){
		// 		prd.PermPrice = prd.Price
		// 		prd.PriceInInput = prd.ChangePrice = price

		// 		prd.ChangeDiscount = prd.discount = discount

		// 		prd.Price = prd.NetPrice = price * (1 - (discount / 100))
		// 		prd.dontTouchPriceOrDiscount = true
		// 	}
		// 	return prd
		// })

		const byDate = this.SaveSectionSearchOnlySpecPricessOrderByDate;

		if (byDate && byDate.length) {
			this.SaveSectionSearchOnlySpecPricessOrderByDate = byDate
				.filter(prd => filteredWithDoctypeHash[prd.ProductId])
				.map(prd => {
					const { price, discount, extra5 } = filteredWithDoctypeHash[prd.ProductId];

					if (extra5 === '1') {
						prd.PermPrice = prd.Price;
						prd.PriceInInput = prd.ChangePrice = price;

						prd.ChangeDiscount = prd.discount = discount;

						prd.Price = prd.NetPrice = price * (1 - discount / 100);
						prd.dontTouchPriceOrDiscount = true;
					}
					return prd;
				});
		}
	}

	handleOnlyInPriceListParameter() {
		if (this._ss.CustomerInformation.OnlyInClientPriceList !== '1' || this.resolveIfDocIsOfReturnsType()) return;

		const priceListSet = new Set(this.FilterMehironim.map(mehiron => mehiron.it));

		this.AllProductsSearch = this.AllProductsSearch.filter(prd => priceListSet.has(prd.ProductId));
		this.AllProducts = this.AllProducts.filter(prd => priceListSet.has(prd.ProductId));

		const byDate = this.SaveSectionSearchOnlySpecPricessOrderByDate;

		if (byDate?.length) {
			this.SaveSectionSearchOnlySpecPricessOrderByDate = byDate.filter(prd => priceListSet.has(prd.ProductId));
		}
	}

	setupConcentratedProductsFormInteractivity() {
		if (!this._ss.allConcentratedProducts.length) return;

		if (this.hasSpecials) {
			this.isConcentratedProductPopupShowing$.pipe(takeUntil(this.destroy$)).subscribe(boolean => {
				if (!boolean) {
					this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.next({});
					this.concentratedSpecialCurrentlyEditting$.next(null);
					this.concentratedSpecialTabsSelectedIndex$.next(0);
				}
			});
		}

		const searchValue$ = this.concentratedProductsForm.controls.concentratedProductsSearch.valueChanges.pipe(startWith(''));
		const itemSizeValue$ = this.concentratedProductsForm.controls.concentratedProductsItemSize.valueChanges.pipe(startWith([]));
		const itemColorValue$ = this.concentratedProductsForm.controls.concentratedProductsItemColor.valueChanges.pipe(startWith([]));

		this.concentratedProductsShown$ = combineLatest([
			this.concentratedProductsUnderMain$,
			searchValue$,
			itemSizeValue$,
			itemColorValue$,
			this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$,
			this.concentratedSpecialCurrentlyEditting$,
		]).pipe(
			map(([allProducts, searchValue, itemSizeValues, itemColorValues, concentratedSpecialsCart, specialCurrentlyEditting]) => {
				let filteredProducts: ConcentratedProductsDisplay[] = [...allProducts];
				const stringSearchArray: ConcentratedProductsDisplay[] = [];

				if (searchValue) {
					const splitValue = searchValue.split(' ');

					for (let i = 0; i < filteredProducts.length; i++) {
						let counter = 0;

						const { Product_Name, ProductId, ItemBarcode, ItemExtra1 } = filteredProducts[i].product,
							{ ItemColor, ItemSize } = filteredProducts[i].concentrated;

						const prodNameWithoutSpaces = (Product_Name || '').replace(/\s/g, '').toLowerCase(),
							itemColorWithoutSpaces = (ItemColor || '').replace(/\s/g, '').toLowerCase(),
							productIdWithoutSpaces = (ProductId || '').replace(/\s/g, '').toLowerCase(),
							itemExtra1WithoutSpaces = (ItemExtra1 || '').replace(/\s/g, '').toLowerCase(),
							itemSizeWithoutSpaces = (ItemSize || '').replace(/\s/g, '').toLowerCase(),
							itemBarcodeWithoutSpaces = (ItemBarcode || '').replace(/\s/g, '').toLowerCase(),
							allStrings =
								typeof prodNameWithoutSpaces === 'string' &&
								typeof itemColorWithoutSpaces === 'string' &&
								typeof itemSizeWithoutSpaces === 'string' &&
								typeof itemBarcodeWithoutSpaces === 'string' &&
								typeof productIdWithoutSpaces === 'string';

						// V8 optimizes this if statement, maybe
						if (allStrings) {
							for (let j = 0; j < splitValue.length; j++) {
								if (
									(prodNameWithoutSpaces && prodNameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
									(itemColorWithoutSpaces && itemColorWithoutSpaces.indexOf(splitValue[j]) > -1) ||
									(productIdWithoutSpaces && productIdWithoutSpaces.indexOf(splitValue[j]) > -1) ||
									(itemExtra1WithoutSpaces && itemExtra1WithoutSpaces.indexOf(splitValue[j]) > -1) ||
									(itemBarcodeWithoutSpaces && itemBarcodeWithoutSpaces.indexOf(splitValue[j]) > -1) ||
									(itemSizeWithoutSpaces && itemSizeWithoutSpaces.indexOf(splitValue[j]) > -1)
								) {
									counter++;
								}
							}

							if (counter >= splitValue.length) {
								stringSearchArray.push(filteredProducts[i]);
							}
						}
					}

					filteredProducts = stringSearchArray;
				}

				const filterProductIds = (filterValues: string[], filterProperty: string) => {
					const array: string[] = [];
					for (const filter of filterValues) {
						array.push(...this.concentratedProductsFilters[filterProperty][filter]);
					}
					const productSet = new Set(array);
					return filteredProducts.filter(prod => productSet.has(prod.product.ProductId));
				};

				if (itemSizeValues.length) {
					filteredProducts = filterProductIds(itemSizeValues, 'ItemSize');
				}

				if (itemColorValues.length) {
					filteredProducts = filterProductIds(itemColorValues, 'ItemColor');
				}

				if (concentratedSpecialsCart && specialCurrentlyEditting) {
					const special = concentratedSpecialsCart[specialCurrentlyEditting.special[0].SpecialID];

					if (!special) return filteredProducts;

					if (!special.order?.length) return filteredProducts.map(prod => ({ ...prod, product: { ...prod.product, TeudaPackQuantity: 0, TeudaQuantity: 0, TeudaBonusQuantity: 0 } }));

					for (const item of special.order) {
						const product = filteredProducts.find(prod => prod.product.ProductId === item.ProductId);

						if (product) {
							product.product.TeudaPackQuantity = item.TeudaPackQuantity || 0;
							product.product.TeudaQuantity = item.TeudaQuantity || 0;
							product.product.TeudaBonusQuantity = item.TeudaBonusQuantity || 0;
						}
					}
				}

				return filteredProducts;
			}),
		);

		this.concentratedPoductsTotalQuantity$ = this.concentratedProductsUnderMain$.pipe(
			map(value => value.reduce((p, c) => p + (+c.product.TeudaPackQuantity || 0) + (+c.product.TeudaQuantity || 0), 0)),
		);

		this.isConcentratedProductPopupShowing$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.concentratedPoductsTotalQuantityPlaceholder$.next({ packs: 0, singles: 0 });
		});
	}

	resetConcentratedProductsFormFilterSelections() {
		this.concentratedProductsForm.controls.concentratedProductsItemSize.reset();
		this.concentratedProductsForm.controls.concentratedProductsItemColor.reset();
	}

	handleBuildConcentratedProductsList() {
		if (!this._ss.allConcentratedProducts.length) return;

		// for (let i = 0; i < this.AllProductsSearch.length; i++) {
		// 	const element = this.AllProductsSearch[i] as AllProducts;
		// 	this.allProductsMap.set(element.ProductId, element);
		// }

		// this.allProductsRecord = this.AllProductsSearch.reduce((p, c: AllProducts) => {
		// 	p[c.ProductId] = c;
		// 	return p;
		// }, {} as Record<string, AllProducts>);

		this.concentratedProducts = this._ss.allConcentratedProducts.reduce((p, c) => {
			if (!c.MainProductID) return p;
			p[c.MainProductID] ||= [];
			p[c.MainProductID].push(c);

			return p;
		}, {} as Record<string, ConcentratedProducts[]>);
	}

	buildConcentratedProductsFilters() {
		this.concentratedProductsForm.reset();

		this.concentratedProductsFilters.ItemColor = {};
		this.concentratedProductsFilters.ItemSize = {};
		this.concentratedProductsFilters.allItemColors = [];
		this.concentratedProductsFilters.allItemSizes = [];

		const colorSet: Record<string, Set<string>> = {};
		const sizeSet: Record<string, Set<string>> = {};

		for (const product of this.concentratedProductsUnderMain$.value) {
			colorSet[product.concentrated.ItemColor] ||= new Set();
			colorSet[product.concentrated.ItemColor].add(product.concentrated.ProductID);

			sizeSet[product.concentrated.ItemSize] ||= new Set();
			sizeSet[product.concentrated.ItemSize].add(product.concentrated.ProductID);
		}

		for (const key in colorSet) {
			this.concentratedProductsFilters.ItemColor[key] = [...colorSet[key]];
			this.concentratedProductsFilters.allItemColors.push(key);
		}
		for (const key in sizeSet) {
			this.concentratedProductsFilters.ItemSize[key] = [...sizeSet[key]];
			this.concentratedProductsFilters.allItemSizes.push(key);
		}
	}

	async handleGetSpecials() {
		this.updatingSpecials = true;

		await new Promise<void>(resolve => {
			this._ss.getAllSpecials().subscribe({
				next: res => {
					if (res === 'ConnectionError' || res === 'RequestError') {
						if (this._ss.allSpecials.length) {
							this.allSpecials = this._ss.allSpecials;

							return resolve();
						}

						const ls = localStorage.getItem('allSpecials');
						if (ls) {
							this._ss.allSpecials = this.allSpecials = JSON.parse(decompress(ls));
						}
						return resolve();
					}

					this._ss.allSpecials = this.allSpecials = res.recordset;

					localStorage.setItem('allSpecials', compress(JSON.stringify(this._ss.allSpecials)));

					resolve();
				},
				error: () => {
					if (this._ss.allSpecials.length) {
						this.allSpecials = this._ss.allSpecials;
						return resolve();
					}

					const hasAllSpecials = this._storage.getAndParseFromStorage('allSpecials', { onlyCheckIfExist: true });
					if (!hasAllSpecials) {
						this.allSpecials = [];
						return resolve();
					}

					this.allSpecials = this._ss.allSpecials = this._storage.getAndParseFromStorage('allSpecials');
					resolve();
				},
			});
		});

		if (this.allSpecials.length) {
			const today = new ChangedDate().getChangedTime().getTime();

			this._ss.allSpecials = this.allSpecials = this.allSpecials.filter(
				sp => sp.SpecialStatus !== 1 && ((new Date(sp.StartDate).getTime() <= today && new Date(sp.EndDate).getTime() >= today) || sp.SpecialStatus === 2),
			);

			if (this.hasConcentratedSpecials) {
				this.concentratedSpecialsCart$.next(
					this.AllOrder.filter(ord => typeof ord.SpecialID === 'number' && ord.SpecialType !== specialsTypesEnum.NORMAL).reduce((prev, curr) => {
						prev[curr.SpecialID] ||= { ...curr };
						return prev;
					}, {}),
				);
			}

			for (const ord of this.AllOrder) {
				if (typeof ord.SpecialID === 'number') {
					this.specialsInCart[ord.SpecialID] = { special: this.allSpecials.filter(sp => sp.SpecialID === ord.SpecialID), quantity: ord.quantity };
				}
			}
			this.isSpecialsPopupOpen = this.openSpecialsPopupOnEntry;
		}

		this.updatingSpecials = false;
	}

	markProductsAsPartOfSpecial() {
		const hash = this.allSpecials.reduce((prev, curr) => {
			prev[curr.ProductID] ||= [];
			prev[curr.ProductID].push(curr.SpecialID);

			return prev;
		}, {});

		this.AllProductsSearch = this.AllProductsSearch.map(prd => {
			if (hash[prd.ProductId]) {
				prd.inSpecial = [...hash[prd.ProductId]];
			}
			return prd;
		});
	}

	openSpecialsPopupFiltered(specialIDs: number[]) {
		this.showOnlyTheseSpecials = specialIDs;
		this.refreshSpecials();
		this.isSpecialsPopupOpen = true;
	}

	handleCloseSpecialsPopup() {
		this.showOnlyTheseSpecials = null;
		this.isSpecialsPopupOpen = false;
	}

	refreshSpecials() {
		const hash: SpecialObjectHash<Special> = this.allSpecials.reduce((acc: SpecialObjectHash<Special>, item) => {
			if (!acc[item.SpecialID]) {
				acc[item.SpecialID] = { ...item, length: 1 };

				return acc;
			}

			acc[item.SpecialID].length++;
			return acc;
		}, {});

		let specialsArr = Object.values(hash).map(spc => ({ ...spc, quantity: this.specialsInCart[spc.SpecialID]?.quantity || 0 }));

		if (this.showOnlyTheseSpecials?.length) {
			specialsArr = specialsArr.filter(spc => this.showOnlyTheseSpecials.includes(spc.SpecialID));
		}

		this.allSpecials$.next(specialsArr);

		// this.AllProducts = specialsArr.concat(this.AllProducts.filter(prd => typeof prd.SpecialID !== 'number'));
	}

	// showSpecialsInCart(){
	// 	this.AllProducts = this.AllOrder.filter(ord => ord.specialID).map(ord => ({...this.productsHash[ord.Duplicate],...ord,present:{icon: true,showContent:false}}))
	// }

	handleOpenConcentratedSpecialsPopup(event: Event, concentratedSpecial: SpecialInCart) {
		event.stopPropagation();
		const { special, quantity } = concentratedSpecial;
		this.concentratedProductPopupView$.next('inline');
		this.openConcentratedProductPopup(special[0].ProductID);
		const specialInCart = this.concentratedSpecialsCart$.value[special[0].SpecialID] || this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.value[special[0].SpecialID];
		if (specialInCart) {
			const dictionary = specialInCart.order.reduce((prev, curr) => {
				prev[curr.ProductId] = curr;
				return prev;
			}, {});
			const concentratedProductsUnderMain = this.concentratedProductsUnderMain$.value;
			const products = concentratedProductsUnderMain.map(product => {
				return this.productsHash[product.product.ProductId.toLowerCase()];
			});
			this.concentratedSpecialCurrentlyEditting$.next({ special, quantity, products });
			if (!this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.value[special[0].SpecialID]) {
				this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.next(this.concentratedSpecialsCart$.value);
			}

			for (const product of concentratedProductsUnderMain) {
				const productInCart = dictionary[product.product.ProductId];
				product.product = { ...product.product };
				product.product.TeudaPackQuantity = 0;
				product.product.TeudaQuantity = 0;
				product.product.TeudaBonusQuantity = 0;
				if (!productInCart) continue;
				product.product.TeudaPackQuantity = productInCart.TeudaPackQuantity;
				product.product.TeudaQuantity = productInCart.TeudaQuantity;
				product.product.TeudaBonusQuantity = productInCart.TeudaBonusQuantity;
			}
		}
		this.isSpecialsPopupOpen = false;
	}

	handlePopulateProductHash() {
		if (!Object.getOwnPropertyNames(this.productsHash).length) {
			this.productsHash = this.AllProductsSearch.reduce((prev, curr) => {
				prev[(curr.ProductId || '').toLowerCase()] = curr;
				return prev;
			}, {});
		}
	}

	handlePrepareSpecialForNonSetSpecialType(special: Special[], action: 'increment' | 'decrement', quantity: number) {
		const { SpecialID: specialID, MaxInCart } = special[0];

		const concentratedSpecialsInCartEditting = this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.value;
		const concentratedSpecialsInCart = this.concentratedSpecialsCart$.value;

		concentratedSpecialsInCartEditting[specialID] ||= concentratedSpecialsInCart[specialID] ? JSON.parse(JSON.stringify(concentratedSpecialsInCart[specialID])) : { special, quantity: 0, order: [] };

		if (action === 'increment') {
			if (MaxInCart && MaxInCart <= concentratedSpecialsInCartEditting[specialID].quantity) {
				alert('אין אפשרות להוסיף מבצע זה מכיוון שהוא הגיע למכסה');
			} else {
				concentratedSpecialsInCartEditting[specialID].quantity += quantity;
			}
		}

		if (action === 'decrement') {
			concentratedSpecialsInCartEditting[specialID].quantity -= quantity;
		}

		if (concentratedSpecialsInCartEditting[specialID].quantity <= 0) {
			delete concentratedSpecialsInCartEditting[specialID];
			delete concentratedSpecialsInCart[specialID];
			delete this.specialsInCart[specialID];
			this.concentratedSpecialsCart$.next(concentratedSpecialsInCart);
			this.refreshSpecials();
			this.AllOrder = this.AllOrder.filter(ord => ord.SpecialID !== specialID);
			this.GeneralFunction();
		}

		this.concentratedSpecialIntermediateCurrentlyEdittingQuantities$.next(concentratedSpecialsInCartEditting);
	}

	handleChangeSpecialInCart(specialObj: Special, action: 'increment' | 'decrement', quantity: number) {
		const { SpecialID: specialID } = specialObj;

		if (!quantity) return;

		this.handlePopulateProductHash();

		const special = this.allSpecials.filter(sp => sp.SpecialID === specialID);

		const specialInCart = this.specialsInCart[specialID];

		if ((specialObj.SpecialType === specialsTypesEnum.BUY_GET || specialObj.SpecialType === specialsTypesEnum.BUY_GET_MAIN_PRODUCT) && this.hasConcentratedSpecials) {
			this.handlePrepareSpecialForNonSetSpecialType(special, action, quantity);
			return;
		}

		if (action === 'increment') {
			this.handleIncrementSpecialInCart(special, specialInCart, quantity);
		}

		if (action === 'decrement') {
			this.handleDecrementSpecialInCart(special, specialInCart, quantity);
		}
	}

	handleIncrementSpecialInCart(special: Special[], specialInCart: SpecialInCart, quantity: number) {
		const { SpecialID: specialID, MaxInCart } = special[0];
		if (specialInCart && MaxInCart && MaxInCart <= specialInCart.quantity) {
			alert('אין אפשרות להוסיף מבצע זה מכיוון שהוא הגיע למכסה');

			// If somehow goes over maxincart
			specialInCart.quantity = MaxInCart;
			return;
		}

		if (!specialInCart) {
			const addToAllOrder = this.returnSpecialObject(special[0], quantity);

			for (const sp of special) {
				const { ProductID, TeudaItemNetPrice, TeudaPackQuantity, TeudaQuantity } = sp;

				const { ItemPack } = this.productsHash[ProductID.toLowerCase()];

				addToAllOrder.IDs.push(sp.ID);

				addToAllOrder.TeudaItemNetPrice += normalizeNumberToMaxTwoDecimal(TeudaQuantity + TeudaPackQuantity * ItemPack) * TeudaItemNetPrice;
			}
			this.AllOrder.push(addToAllOrder);

			this.specialsInCart[specialID] = specialInCart;

			this.handleTeudaNum();
			this.handleTeudaCreateDate();
			this.GeneralFunction();
			return;
		}

		const order = this.AllOrder.find(ord => ord.SpecialID === specialID);
		order.quantity += quantity;
		this.GeneralFunction();
	}

	handleDecrementSpecialInCart(special: Special[], specialInCart: SpecialInCart, quantity: number) {
		if (!specialInCart || !specialInCart.quantity) return;

		const { SpecialID: specialID } = special[0];

		const order = this.AllOrder.find(ord => ord.SpecialID === specialID);

		if (specialInCart.quantity < quantity) {
			console.log('illegal quantity for special');
			return;
		}

		order.quantity = specialInCart.quantity -= quantity;

		if (!order.quantity) {
			delete this.specialsInCart[specialID];
			this.AllOrder = this.AllOrder.filter(ord => ord.SpecialID !== specialID);
		}

		this.GeneralFunction();
	}

	handleOpenSpecialDialog(specialID: number) {
		this.handlePopulateProductHash();

		const special = this.allSpecials.filter(spc => spc.SpecialID === specialID).map(spc => ({ ...this.productsHash[spc.ProductID.toLowerCase()], ...spc }));

		this.dialog.open(SpecialInfoDialog, {
			data: { special },
			maxHeight: '80vh',
		});
	}

	handleSortProducts() {
		const { SortProductsBy } = this._ss.CustomerInformation,
			opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		if (SortProductsBy && SortProductsBy !== '0') {
			let sortValue: string | string[] = '';

			switch (SortProductsBy) {
				case '1':
					sortValue = 'ProductId';
					break;
				case '2':
					sortValue = 'Product_Name';
					break;
				case '3':
					sortValue = ['IGC', 'Product_Name'];
					break;
				case '4':
					sortValue = ['IGC', 'ProductId'];

					break;
				case '5':
					sortValue = 'DefaultOrderBY';
					break;
				case '6':
					sortValue = 'ItemExtra5';
					break;

				default:
					break;
			}

			const isArray = Array.isArray(sortValue);

			const sortCompare = (a, b) => {
				if (isArray) {
					const result = Intl.Collator('he-il', opt).compare(a[sortValue[0]], b[sortValue[0]]);
					// if not 0 - same sort, try to be more specific
					return result ? result : Intl.Collator('he-il', opt).compare(a[sortValue[0]] + a[sortValue[1]], b[sortValue[0]] + b[sortValue[1]]);
				}

				return Intl.Collator('he-il', opt).compare(a[sortValue as string], b[sortValue as string]);
			};

			if (this.AllProducts) {
				this.AllProducts.sort(sortCompare);
			}

			if (this.AllProductsSearch) {
				this.AllProductsSearch.sort(sortCompare);
			}

			if (this._ss.SpecPriceSs) {
				this._ss.SpecPriceSs.sort(sortCompare);
			}

			if (this.SaveSectionSearch) {
				this.SaveSectionSearch.sort(sortCompare);
			}

			if (this.SaveSectionSearchOnlySpecPricessOrderByDate) {
				this.SaveSectionSearchOnlySpecPricessOrderByDate.sort(sortCompare);
			}
		}
	}

	returnSpecialObject(special: Special, quantity: number) {
		return {
			IDs: [],
			SpecialID: special.SpecialID,
			TeudaItemNetPrice: 0,
			Color: special.Color,
			StatusInsertItem: this.Mode ? 'ONLINE' : 'OFFLINE',
			Ptve: this._ss.VersionNumber,
			DuplicateOrder: this.isDuplicateOrder ? 'true' : 'false',
			TeudaRemark: this.duplicateOrderTeudaRemark || '',
			TeudaItemRemark: special.Name,
			quantity: quantity || special.MaxInCart || 1,
		};
	}

	handleBarcodePopupOpen() {
		this.isBarcodePopupOpen = true;

		setTimeout(() => {
			try {
				if (this.videoElement && this.videoElement.nativeElement) {
					this.video = this.videoElement.nativeElement;
				} else {
					alert('no video element');
					throw new Error();
				}

				if (!window.BarcodeDetector) {
					alert('קורא ברקוד אינו נתמך במכשיר זה');
					throw new Error();
				}

				if (!window.navigator.mediaDevices || !('getUserMedia' in window.navigator.mediaDevices)) {
					alert('אין גישה למצלמה');
					throw new Error();
				}
			} catch (error) {
				this.handleBarcodePopupClose();
				return;
			}

			const constraints: MediaStreamConstraints = {
				video: { facingMode: { exact: 'environment' } },
				audio: false,
			};

			window.navigator.mediaDevices
				.getUserMedia(constraints)
				.then(async stream => {
					this.video.srcObject = stream;
					this.video.play();

					this.mediaStream = stream;
					const formats: SupportedFormats[] = ['code_39', 'codabar', 'ean_13', 'code_128'];

					const barcodeFormatsDetect = new window.BarcodeDetector({ formats });

					if (!barcodeFormatsDetect) {
						alert('קורא ברקוד אינו נתמך במכשיר זה');
						return;
					}

					const barcodeDetector = new window.BarcodeDetector({ formats });

					// Detect code function
					const detectCode = async () => {
						if (!('detect' in barcodeDetector) || !this.video || this.video.paused) {
							return;
						}

						if (this.detectedCode?.length) {
							return;
						}

						try {
							const codes = await barcodeDetector.detect(this.video);
							if (!codes?.length) return;

							for (const barcode of codes) {
								this.detectedCode = barcode.rawValue;

								const searchControl = this.isConcentratedProductPopupShowing$.value ? this.concentratedProductsForm.controls.concentratedProductsSearch : this.form.controls.ProductSearch;

								searchControl.setValue(barcode.rawValue);
							}
							this.SearchProducts();
						} catch (error) {
							console.error(error);
						}

						this.handleBarcodePopupClose();
					};

					this.intervalID = setInterval(detectCode, 200);
				})
				.catch(err => {
					this.supportsVideo = false;
					alert('יש לאפשר גישה למצלמה כדי להפעיל סורק ברקוד');
					this.handleBarcodePopupClose();
				});
		}, 0);
	}

	handleBarcodePopupClose() {
		if (this.mediaStream) {
			for (const track of this.mediaStream.getTracks()) {
				this.mediaStream.removeTrack(track);
			}
		}
		if (this.video) {
			this.video.srcObject = null;
			this.video.pause();
		}

		clearInterval(this.intervalID);

		this.detectedCode = '';

		this.isBarcodePopupOpen = false;
	}

	handleShirionDoctypeOnInitWhenOnline() {
		// doctype 888 for autolux
		const overrideClientForDoctype888 = this._ss.DocType === 888 ? 99999999 : undefined;

		this._ss.GetAllPricesShirion(overrideClientForDoctype888).subscribe({
			next: (res: any) => {
				console.log(res.recordset);

				this.AllProductsSearch = this._ss.SpecPriceSs = res.recordset;
				for (let z = 0; z < this.AllProductsSearch.length; z++) {
					this.AllProductsSearch[z].present = { icon: true, showContent: false };
					let strid = this.AllProductsSearch[z].ProductId;
					// this.AllProductsSearch[z].Product_Name = str.toString().toLowerCase()
					this.AllProductsSearch[z].ProductId = strid.toString().toLowerCase();
					this.AllProductsSearch[z].TeudaQuantity = 0;
					this.AllProductsSearch[z].TeudaPackQuantity = 0;
					this.AllProductsSearch[z].TeudaBonusQuantity = 0;
					this.AllProductsSearch[z].ChangePrice = this.AllProductsSearch[z].NetPrice;
					this.AllProductsSearch[z].ChangeDiscount = this.AllProductsSearch[z].discount;
					this.AllProductsSearch[z].TeudaTotalRow = 0;
					this.AllProductsSearch[z].ShowPrice = this.AllProductsSearch[z].Price;
					this.AllProductsSearch[z].Heara = '';
				}

				if (this._ss.OneClientInfo.HM === 1 && this.online === true) {
					this.OnMivza = true;
					this._ss.Mivza().subscribe({
						next: res => {
							this.OnMivza = false;

							this._ss.MivzaA = res;
							this._ss.MivzaA = this._ss.MivzaA.recordset;
							for (let z = 0; z < this._ss.MivzaA.length; z++) {
								let str = this._ss.MivzaA[z].ItemCode;
								this._ss.MivzaA[z].ItemCode = str.toString().toLowerCase();
							}
						},
						error: () => {
							this.OnMivza = false;

							alert('בעיה בקבלת מבצעים למוצרים');
						},
					});
				}

				this.AllProducts = this.AllProductsSearch;

				if (this.HasMahlakotMain !== 1) {
					this.filterOnlyRelevantGroups();
				}

				this.isLoadingPopupOpen = false;

				for (let x = 0; x < this.AllProducts.length; x++) {
					this.AllProducts[x].TeudaTotalRow = this.AllProducts[x].TeudaQuantity = this.AllProducts[x].TeudaPackQuantity = this.AllProducts[x].TeudaBonusQuantity = 0;
					this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
					this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
					this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
				}
				setTimeout(() => {
					this.postOnInitFunctions();
				}, 200);
			},
			error: () => {
				const shirionProducts = localStorage.getItem('ShirionProducts');
				if (shirionProducts !== null) {
					this._ss.SpecPriceSs = [];

					const shirionProductsArray = JSON.parse(decompress(shirionProducts));

					for (let x = 0; x < shirionProductsArray.length; x++) {
						const product = shirionProductsArray[x];
						if (product.ClientId === this._ss.OneClientInfo.ClientId && product.company === this._ss.OneClientInfo.company) {
							this._ss.SpecPriceSs.push(product);
						}
					}

					this.AllProductsSearch = this._ss.SpecPriceSs;
					for (let z = 0; z < this.AllProductsSearch.length; z++) {
						this.AllProductsSearch[z].present = { icon: true, showContent: false };
						// this.AllProductsSearch[z].ProductIdNoChange = this.AllProductsSearch[z].ProductId
						let strid = this.AllProductsSearch[z].ProductId;
						// this.AllProductsSearch[z].Product_Name = str.toString().toLowerCase()
						this.AllProductsSearch[z].ProductId = strid.toString().toLowerCase();
						this.AllProductsSearch[z].TeudaQuantity = this.AllProductsSearch[z].TeudaPackQuantity = this.AllProductsSearch[z].TeudaBonusQuantity = 0;
						this.AllProductsSearch[z].ChangePrice = this.AllProductsSearch[z].NetPrice;
						this.AllProductsSearch[z].ChangeDiscount = this.AllProductsSearch[z].discount;
						this.AllProductsSearch[z].TeudaTotalRow = 0;
						this.AllProductsSearch[z].ShowPrice = this.AllProductsSearch[z].Price;
					}

					this.AllProducts = this.AllProductsSearch;

					if (this.HasMahlakotMain !== 1) {
						this.filterOnlyRelevantGroups();
					}

					this.isLoadingPopupOpen = false;

					for (let x = 0; x < this.AllProducts.length; x++) {
						this.AllProducts[x].TeudaTotalRow = this.AllProducts[x].TeudaQuantity = this.AllProducts[x].TeudaPackQuantity = this.AllProducts[x].TeudaBonusQuantity = 0;
						this.AllProducts[x].ChangePrice = this.AllProducts[x].Price;
						this.AllProducts[x].ChangeDiscount = this.AllProducts[x].discount;
						this.AllProducts[x].NetPrice = this.AllProducts[x].NetPrice;
					}
					setTimeout(() => {
						this.postOnInitFunctions();
					}, 200);
				} else {
					alert('תקלה בקבלת שריון ');
					this.router.navigate(['clients']);
				}
			},
		});
	}

	incrementOrDecrementAllPopupProducts(mode: 'increment' | 'decrement', quantity: 'singles' | 'packs', concentratedProducts: ConcentratedProductsDisplay[]) {
		for (const concentrate of concentratedProducts) {
			if (mode === 'increment') {
				if (quantity === 'singles') {
					this.YehidotPlus(concentrate.product as Product2);
				} else {
					this.ArizotPlus(concentrate.product as Product2);
				}
			}
			if (mode === 'decrement') {
				if (quantity === 'singles') {
					this.YehidotMinus(concentrate.product as Product2);
				} else {
					this.ArizotMinus(concentrate.product as Product2);
				}
			}
		}

		this.updateConcentratedQuantitiesPlaceholder(mode, quantity);
	}

	inputQuantityAllPopupProducts(event: Event, quantity: 'singles' | 'packs', concentratedProducts: ConcentratedProductsDisplay[]) {
		const allOrderRecord: Record<string, { singles: boolean; packs: boolean }> = {};
		if (this.AllOrder.length) {
			for (const order of this.AllOrder) {
				if (order.TeudaPackQuantity || order.TeudaQuantity) {
					allOrderRecord[order.ProductId] = { packs: !!order.TeudaPackQuantity, singles: !!order.TeudaQuantity };
				}
			}
		}
		if (quantity === 'singles') {
			if (isNaN(+this.concentratedProductsForm.controls.concentratedSingles.value)) {
				this.concentratedProductsForm.controls.concentratedSingles.reset();
				return;
			}

			for (const concentrate of concentratedProducts) {
				if (!allOrderRecord[concentrate.product.ProductId]?.singles || this.concentratedProductsForm.controls.concentratedSingles.value === '0') {
					this.YehidotInput(event, concentrate.product as Product2, true);
				}
			}
			this.concentratedProductsForm.controls.concentratedSingles.reset();
		}

		if (quantity === 'packs') {
			if (isNaN(+this.concentratedProductsForm.controls.concentratedPacks.value)) {
				this.concentratedProductsForm.controls.concentratedPacks.reset();
				return;
			}

			for (const concentrate of concentratedProducts) {
				if (!allOrderRecord[concentrate.product.ProductId]?.singles || this.concentratedProductsForm.controls.concentratedPacks.value === '0') {
					this.ArizotInput(event, concentrate.product as Product2, true);
				}
			}
			this.concentratedProductsForm.controls.concentratedPacks.reset();
		}
	}

	updateConcentratedQuantitiesPlaceholder(mode: 'increment' | 'decrement', quantity: 'singles' | 'packs') {
		const newValue = this.concentratedPoductsTotalQuantityPlaceholder$.value;
		if (mode === 'increment') {
			if (quantity === 'singles') {
				newValue.singles++;
			} else {
				newValue.packs++;
			}
		}
		if (mode === 'decrement') {
			if (quantity === 'singles') {
				newValue.singles = Math.max(newValue.singles - 1, 0);
			} else {
				newValue.packs = Math.max(newValue.packs - 1, 0);
			}
		}
		this.concentratedPoductsTotalQuantityPlaceholder$.next(newValue);
	}

	updateDB() {
		localStorage.removeItem('lastupdate');
		localStorage.setItem('Updating', 'true');
		history.back();
	}

	shouldStockPopupShow = false;
	stockArrayToShow = [];
	mlayPopupProductName = '';

	openMlayPopup(product: Product) {
		if (this._ss.CustomerInformation.GetOriginalStock !== '1') return;
		this.mlayPopupProductName = product.Product_Name;

		this.stockArrayToShow = [...this.warehouseStock]
			.filter(stock => stock.StockItemCode.toLowerCase() === product.ProductId.toLowerCase())
			.sort((a, b) => (b.StockOpen || b.Stock) - (a.StockOpen || a.Stock));

		if (!this.stockArrayToShow.length) return;

		this.shouldStockPopupShow = true;
	}

	async updateOriginalStock() {
		return new Promise<StockOriginal[]>((resolve, reject) => {
			this._ss
				.getStockFromStockTable()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return reject();
						resolve(res.recordset);
					},
					error: () => {
						reject();
					},
				});
		});
	}

	handleSetCdkItemSize() {
		this.handleBigViewTypeItemSize();

		this.setCSSVariables();
	}

	handleBigViewTypeItemSize() {
		// const considerZoom = (num: number) => (this.windowWidth >= TABLET_RESOLUTION_CUTOFF ? num / 1.2 : num);
		if (this._ss.CustomerInformation.ProductBigViewOptions === '1') {
			const height: number = ['.order-header', '.order-gray', '.order-filters']
				.map(className => {
					const el = document.querySelector(className);
					if (el) return el.getBoundingClientRect().height;
					return 0;
				})
				.reduce((p, c) => p + c);

			console.log(height);
			this.viewtypeBigItemSize = +document.body.getBoundingClientRect().height - height;
			// this.viewtypeBigItemSize = considerZoom(+document.body.offsetHeight - height);

			return;
		}
		this.viewtypeBigItemSize = 644;

		if (this.windowWidth > 767) {
			this.viewtypeBigItemSize = this._ss.CustomerInformation.SeeProductName === '1' ? 604 : 632.4;
		}
	}

	setCSSVariables() {
		const root: HTMLElement = document.querySelector(':root');

		const considerZoom = (num: number) => (this.windowWidth >= TABLET_RESOLUTION_CUTOFF ? num * 1.2 : num);

		root.style.setProperty('--viewtype_big-itemsize', this.viewtypeBigItemSize - considerZoom(20) + 'px');
		if (this._ss.CustomerInformation.ProductBigViewOptions === '1') {
			root.style.setProperty('--viewtype_big-image', this.viewtypeBigItemSize + 'px');
		}
	}

	isBelowMinActive(product: Product2, mode: 'block' | 'warn' | 'block-discount' | 'block-price' | 'any'): boolean {
		const { BM } = product;
		const ignoreBM = !this.NoMinimum;

		const belowMinRecord: Record<typeof mode, boolean> = {
			block: BM === 1 || BM === 3,
			warn: BM === 2 || BM === 4,
			any: BM !== 0,
			'block-discount': BM === 3,
			'block-price': BM === 1,
		};

		return belowMinRecord[mode] && ignoreBM;
	}

	handleWeightTypeMarking() {
		let orderRecord: Record<string, Product2> = {};

		if (this.AllOrder?.length) {
			console.log(this.AllOrder);
			orderRecord = this.AllOrder.filter(product => product.weightMode || product.packsWeightMode).reduce((prev, curr) => {
				prev[curr.ProductId] = curr;
				return prev;
			}, {});
		}

		for (const product of this.AllProductsSearch) {
			if (product.Type == null) continue;

			if (product.Type === 1) {
				product.canSwitchWeightSinglesQuantities = true;
				product.weightMode = 'weight';
			}
			if (orderRecord[product.ProductId]) {
				const { weightMode, packsWeightMode } = orderRecord[product.ProductId];
				if (weightMode) product.weightMode = weightMode;

				if (packsWeightMode) {
					product.canToggleBoxesPacks = true;
					product.packsWeightMode = packsWeightMode;
					if (packsWeightMode === 'packs') {
						delete product.Unit;
					}
				}
			} else {
				if (product.Type === 5) {
					product.canToggleBoxesPacks = true;
					product.packsWeightMode = 'boxes';
				}
			}

			if (product.Type === 3 || product.Type === 4 || product.Type === 5) {
				product.hideSingles = true;
			}

			if (product.Type === 2) {
				product.hidePacks = true;
				product.weightMode = 'singles';
			}
		}
	}

	handleRallyanceReclamation() {
		if (!this.isRallyanceReclamation) return;

		this.hideSingles = this.hidePacks = true;

		this.DoctypeName = this._ss.DocTypeName;
		const products = localStorage.getItem('AllProducts');

		if (!products) {
			alert('יש לעדכן נתונים ');
			history.back();
		}

		this.AllProducts = this.AllProductsSearch = JSON.parse(decompress(products));

		// for (const product of this.AllProductsSearch) {

		// }

		this.Mode = this.online = window.navigator.onLine;

		this._ss.checkConnection().subscribe({
			next: res => {
				this.online = true;
				this.Mode = this.online;
			},
			error: err => {
				if (err.status === 404) {
					this.online = true;
					this.Mode = this.online;
				}
			},
		});
	}

	handleRallyanceReclamationSubmit(formValues: RallyanceReclamationNewReclamationFormValues) {
		const { RekID, SugTakala, AgentRemark } = formValues;

		if (!RekID || !SugTakala) {
			alert('יש למלא את כל השדות');
			return;
		}

		const TeudaNum = new Date().getTime() + '000' + RECLAMATION_RALLYANCE_DOCTYPE;

		const body: RallyanceReclamationNewReclamationBody = {
			AgentRemark,
			RekID,
			SugTakala,
			TeudaNum,
			ClientId: this._ss.OneClientInfo.ClientId,
			ProductId: this.testpop[0].ProductId,
			TeudaDate: translateDateForSQLServer().toISOString(),
		};

		this._ss
			.submitRallyanceReclamationAgent(body)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					if (res === 'ConnectionError' || res === 'RequestError') {
						alert('לא ניתן לשלוח תעודה זו כרגע');
						return;
					}

					this.router.navigate(['teuda'], { queryParams: { ReclamationSuccess: true, TeudaNum }, replaceUrl: true });
				},
				error: () => {
					alert('בעיה בהגשת התלונה');
				},
			});
	}

	toggleProductBetweenWeightAndSingles(product: Product2) {
		const { weightMode } = product;
		const allorderProduct = this.AllOrder.find(prod => prod.ProductId === product.ProductId);
		if (weightMode === 'weight') {
			product.weightMode = 'singles';
		}

		if (weightMode === 'singles') {
			product.weightMode = 'weight';
		}

		if (!allorderProduct) {
			this.addProductToAllOrderAndReturnOrderObject(product, {});
		} else {
			allorderProduct.weightMode = product.weightMode;
		}

		this.GeneralFunction();
	}

	toggleProductBetweenBoxesAndPacks(product: Product2) {
		const { packsWeightMode } = product;
		const allorderProduct = this.AllOrder.find(prod => prod.ProductId === product.ProductId);
		if (packsWeightMode === 'boxes') {
			product.packsWeightMode = 'packs';
			product.ItemPack = product.originalItemPack || 1;
			delete product.Unit;
			if (allorderProduct) {
				delete allorderProduct.Unit;
			}
		}

		if (packsWeightMode === 'packs') {
			product.packsWeightMode = 'boxes';
			const itemPacks = this.itempackRecord[product.ProductId];
			if (itemPacks?.length) {
				product.ItemPack = itemPacks[0].UnitPerPack || 1;
				product.Unit = itemPacks[0].Unit;
			}
		}

		if (!allorderProduct) {
			this.addProductToAllOrderAndReturnOrderObject(product, {});
		} else {
			allorderProduct.packsWeightMode = product.packsWeightMode;
			allorderProduct.ItemPack = product.ItemPack;
		}

		this.GeneralFunction();
	}

	getDepartmentsWrapperScrollerCurrentlyInDOM() {
		return [this.departmentsWrapperScroller1, this.departmentsWrapperScroller2, this.departmentsWrapperScroller3, this.departmentsWrapperScroller4].find(w => w);
	}

	toggleMarkGroupWithProductInOrder({ product, isInOrder }: { product: Product2; isInOrder: boolean }) {
		if (!this.Groups?.length) return;

		const group = this.Groups.find(grp => product.IGC === grp.gr || product.IGC === grp.GroupId);
		if (group) group.inOrder = isInOrder;
	}

	toggleMarkGroupIfAnyProduct(GroupId?: string) {
		if (!this.AllOrder?.length) return;

		let groupIDs = new Set(GroupId);

		const productIDs = new Set(this.AllOrder.map(order => order.ProductId));

		if (!GroupId) {
			groupIDs = new Set(this.AllProductsSearch.filter(product => productIDs.has(product.ProductId)).map(product => product.IGC));
		}

		for (const grp of this.GroupsSearch) {
			if (groupIDs.has(grp.GroupId)) {
				grp.inOrder = grp.visitedThisSession = true;
			}
		}
	}

	filterOnlyRelevantGroups() {
		const productIGCSet = new Set(this.AllProductsSearch.map(product => product.IGC));

		this.Groups = this.GroupsSearch = this.GroupsSearch.filter(group => productIGCSet.has(group.GroupId));
	}

	saveStateWhenNavigating(mode: 'full' | 'partial') {
		this._ss.BackToProductsFromTeuda = this.AllProducts;
		this._ss.BackToProductsFromTeudaSectionName = this.ChoosenSection;
		this._ss.BackToProductsOnlineStatus = this.Mode;

		if (mode === 'partial') return;

		this._ss.allProductsSearchForTeudaCheck = this.AllProductsSearch;
		this._ss.allProductsSearchByDateForSchichim = this.SaveSectionSearchOnlySpecPricessOrderByDate;
	}

	clearAllOrderLocalStorage(identifier: string) {
		const relevantLocalStorageKeys = ['AllOrder', 'HearaNewOrder', 'HearaNewOrder1', 'HearaNewOrder2', 'HearaNewOrder3', 'HearaNewOrder4', 'SupplyDateTeuda'];

		for (const key of relevantLocalStorageKeys) {
			localStorage.removeItem(key + identifier);
		}
	}

	handleDocTypeShrionIsShrion() {
		if (!this._ss.DocTypeShrion || ![2, 3, 7].includes(this._ss.DocTypeShrion)) return;

		const openPricesAndDiscounts = this._ss.DocTypeShrion === 2 || this._ss.DocTypeShrion === 3;
		const onlyWorkInSinglesAndBlockPacks = this._ss.DocTypeShrion === 7;

		if (this._ss.DocTypeShrion === 3) {
			this._ss.CheckMinPrice = 1;
		}

		// if (onlyWorkInSinglesAndBlockPacks) {
		// 	this.hideSingles = false;
		// 	this.hidePacks = true;
		// 	this._ss.SeeArizot = false;
		// }

		if (this._ss.DocTypeShrion === 2 || this._ss.DocTypeShrion === 3 || this._ss.DocTypeShrion === 7) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (openPricesAndDiscounts) {
					this.AllProductsSearch[x].BP = 0;
					this.AllProductsSearch[x].BD = 0;
				}
				// if (onlyWorkInSinglesAndBlockPacks) {
				// 	this.AllProductsSearch[x].hidePacks = true;
				// 	this.AllProductsSearch[x].hideSingles = false;
				// 	this.AllProductsSearch[x].qt = 3;
				// }
			}
		}
	}
}
