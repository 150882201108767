import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';
import { AllProducts } from '../Interfaces/SQL-morning-responses';

@Pipe({
	name: 'getImageNameFrom',
})
export class GetImageNameFromPipe implements PipeTransform {
	constructor(private _ss: StoreService) {}

	transform(product: AllProducts): string {
		switch (this._ss.CustomerInformation.ImageFilenameLookup) {
			case '0':
			case undefined:
			case null:
				return product.ItemPicName;
			case '1':
				return product.ProductId;
			case '2':
				return product.ItemBarcode;
			default:
				return '';
		}
	}
}
