import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterArray',
	pure: true
})
export class FilterArrayPipe implements PipeTransform {
	transform(arr: any[]): any[] {
		return arr.filter(a => a);
	}
}
