import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { TextService } from 'src/app/services/text.service';
import { EmailRadioValues } from '../teuda/teuda.component';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-clienthovoteach',
	templateUrl: './clienthovoteach.component.html',
	styleUrls: ['./clienthovoteach.component.scss'],
})
export class ClienthovoteachComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _location: Location, public _text: TextService, public _user: UserService) {}

	public form: FormGroup;
	public EachClientEachHov;

	OneClientInfo;

	addressToDisplay =
		this._ss.OneClientInfo?.Address && this._ss.OneClientInfo?.CustCity
			? `${this._ss.OneClientInfo.Address}, ${this._ss.OneClientInfo.CustCity}`
			: this._ss.OneClientInfo?.Address || this._ss.OneClientInfo?.CustCity || '';
	// If both undefined for some reason

	doesPDFExistInServer = false;
	pdfFolderLookup = this._ss.CustomerInformation.PDFDirectory;
	pdfFilepath;

	history = history;

	isEmailPickPopupOpen = false;

	clientHasEmail = false;

	windowWidth = window.innerWidth;

	emailRadios = new FormControl<EmailRadioValues>('client');
	emailInput = new FormControl('', [Validators.required, Validators.email]);

	showItemInPack = true;

	public Admin;

	ngOnInit(): void {
		this.EachClientEachHov = this._ss.EachClientEachHov;
		this.OneClientInfo = this._ss.OneClientInfo;
		this.determineIfClientHasEmail();

		if (!this.clientHasEmail){
			this.emailRadios.setValue('custom')
		}

		window.scrollTo(0, 0);

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		if (this._ss.CustNum === '10152.1') {
			this._ss.SeeArizot = false;
		}

		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
		}

		this.showItemInPack = this._ss.CustomerInformation.HideQuantityInPack !== '1';

		console.log({ OneClientInfoDebt: this._ss.OneClientInfoDebt });
		console.log({ EachClientEachHov: this.EachClientEachHov });

		if (this._ss.CustomerInformation.CanSendEmail && this._ss.CustomerInformation.CanSendEmail.includes('4')) {
			this._ss.checkIfPDFExistInServer(this.pdfFolderLookup, this.EachClientEachHov?.[0]?.DocPicName).subscribe({
				next: (value: any) => {
					this.doesPDFExistInServer = true;
					this.pdfFilepath = this.formatStringWithEncodedSlashes(value.pdf);
				},
			});
		}

		this.form = this._fb.group({
			Client_Name: ['', Validators.required],
			Product_Name: ['', Validators.required],
			Amount: ['', Validators.required],
			Arizot: ['', Validators.required],
			OrderBy: ['', Validators.required],
			OrderByProducts: ['', Validators.required],
			HearaForOrder: ['', Validators.required],
			Anaha: ['', Validators.required],
			Ahozim: ['', Validators.required],
			TotalPrecentAllOrderSum: ['', Validators.required],
			OrderType: ['', Validators.required],
			OrderTypeBack: ['', Validators.required],
			HearaForOrderProduct: ['', Validators.required],
			AspakaDateForOrder: ['', Validators.required],
			bonus: ['', Validators.required],
			MahlakotType: ['', Validators.required],
		});

		// console.log({hovot:this._ss.OneClientInfoDebt});
	}

	public BackToClientHovot() {
		// this.router.navigate(['clienthovot'])
		history.back();
	}

	formatStringWithEncodedSlashes(str: string) {
		return str.replace(/\//g, '%2F').replace(/\\/g, '%5C');
	}

	handleClosePopup() {
		this.isEmailPickPopupOpen = this.emailSentSuccessfully = false;
	}

	determineIfClientHasEmail() {
		this.clientHasEmail = Boolean(this.OneClientInfo.CustEmail || this.OneClientInfo.CustEMail);
	}

	loadingEmailSend = false;
	emailSentSuccessfully = false;

	handleSubmitEmail() {
		this.loadingEmailSend = true;
		let email = '';

		switch (this.emailRadios.value) {
			case 'client':
				email = this.OneClientInfo.CustEmail || this.OneClientInfo.CustEMail;
				break;
			case 'user':
				email = this._user.agentEmail$.value;
				break;
			case 'custom':
				email = this.emailInput.value;
				break;

			default:
				break;
		}

		this._ss.sendPDFThroughEmail(email, this.pdfFilepath, this.EachClientEachHov[0].DocDocNum).subscribe({
			next: res => {
				this.loadingEmailSend = false;
				this.emailSentSuccessfully = true;
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסה שנית או מאוחר יותר.');
				this.loadingEmailSend = false;
			},
		});
	}
}
