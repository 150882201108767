<div class="main-wrapper">
	<div class="main-content" [ngClass]="{ 'gray-background': false }">
		<!-- global markup for header -->
		<header class="order-header header">
			<div class="content">
				<div class="header__block row">
					<div class="header__box">
						<div class="header__basket"></div>
					</div>

					<div class="header__burger col image" (click)="handleSaveOrBackButton()">
						<img src="/assets/images/header/back-arrow.svg" alt="burger" /> <span class="back-arrow-text">{{ _text.store.goBack }}</span>
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- order cart gray section -->
		<ng-container *ngIf="!orderProcess; else likutSent">
			<section class="order-cart-gray">
				<div class="content">
					<div class="order-cart-gray__row row">
						<div class="order-cart-gray__title">רקלמציה - <span style="color: green">ONLINE</span></div>
						<div class="order-cart-gray__descrip">{{ rekID$ | async }}</div>
					</div>
				</div>
			</section>
			<!-- end order cart gray section -->
			<!-- order step section -->
			<section class="order-step">
				<div class="content">
					<div class="order-step__row row">
						<div class="order-step__item">
							<div class="order-step__item-title">{{ clientName$ | async }}</div>
							<div class="order-step__item-title">{{ productID$ | async }}</div>
						</div>
						<div class="order-step__block">
							<div class="order-step__block-row">
								<div class="order-step__block-row-title">שם פריט</div>
								<div class="order-step__block-row-text">{{ productName$ | async }}</div>
							</div>
							<div class="order-step__block-row">
								<div class="order-step__block-row-title">סוג תקלה</div>
								<div class="order-step__block-row-text">{{ sugTakala$ | async }}</div>
							</div>
							<div class="order-step__block-row">
								<div class="order-step__block-row-title">הערת סוכן</div>
								<div class="order-step__block-row-text">{{ agentRemark$ | async }}</div>
							</div>

							<div class="buttons-row">
								<button class="buttons-row-button buttons-row-button-green" (click)="openTaskPopup(statusesEnum.approved)">מאושר</button>
								<button class="buttons-row-button buttons-row-button-red" (click)="openTaskPopup(statusesEnum.notApproved)">לא מאושר</button>
								<button class="buttons-row-button buttons-row-button-blue" (click)="openTaskPopup(statusesEnum.sentForClaim)">נשלח לתביעה</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</ng-container>
		<!-- end order step section -->

		<!-- order process -->
		<ng-template #likutSent>
			<section class="order-process" *ngIf="orderProcess === true">
				<div class="container">
					<div class="order-process__row order-process__row_success">
						<div class="row">
							<h2 class="order-process__title">{{ _text.store.processSuccessful[0] }}</h2>
						</div>
						<div class="row">
							<div class="order-process__descrip">{{ _text.store.processSuccessful[1] }}</div>
						</div>
						<div class="row">
							<div class="order-process__image image">
								<img src="/assets/images/basket/process/save.gif" alt="photo" />
							</div>
						</div>
						<button class="order-step__block-button order-step__block-button_green" (click)="BackToProducts()">{{ _text.store.backToLikut }}</button>
					</div>
				</div>
			</section>
		</ng-template>
	</div>
</div>

<div class="popup" *ngIf="isTaskPopupOpen$ | async">
	<div class="overlay" (click)="handleClickOutsideTaskPopup($event)"></div>

	<div class="order-cart__popup popup__content">
		<div class="popup__close" (click)="cancelSendAndClosePopup()"></div>
		<div class="order-cart__popup popup__content-wrapper">
			<div class="order-cart__popup-image image">
				<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
			</div>
			<h1 class="order-cart__popup-title">רקלמציה - {{ rekID$ | async }}</h1>
			<h2 class="order-cart__popup-title">{{ taskPopupTitle$ | async }}</h2>
			<form [formGroup]="form" (ngSubmit)="handleFormSubmit()">
				<div class="wrapper" *ngIf="taskPopupForNotApproved$ | async">
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">הערה</div>
						<textarea class="order-step__block-row-input remark-input" appTap formControlName="RekRemark"></textarea>
					</div>
					<ng-container *ngTemplateOutlet="cancelSendButton; context: { $implicit: 'red' }"></ng-container>
				</div>
				<div class="wrapper" *ngIf="taskPopupForApproved$ | async">
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">אחוז פיצוי</div>
						<input class="order-step__block-row-input remark-input" appTap type="text" formControlName="AhuzPizui" />
					</div>
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">סיבת אישור</div>
						<select name="" id="" formControlName="ConfirmReason">
							<option [value]="item.Name" *ngFor="let item of reclamationReasons$ | async">{{ item.Name }}</option>
						</select>
						<span class="invalid-color" *ngIf="form.controls.ConfirmReason.touched && form.controls.ConfirmReason.invalid"> יש לבחור סיבת אישור כדי לשלוח </span>
					</div>
					<ng-container *ngTemplateOutlet="cancelSendButton; context: { $implicit: 'green' }"></ng-container>
				</div>
				<div class="wrapper" *ngIf="taskPopupForSentForClaim$ | async">
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">DOT</div>
						<input class="order-step__block-row-input remark-input" appTap type="text" placeholder="ERASED" formControlName="DOT" />
					</div>
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">SERIAL</div>
						<input class="order-step__block-row-input remark-input" appTap type="text" placeholder="ERASED" formControlName="SERIAL" />
					</div>
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">עומק סוליה</div>
						<input class="order-step__block-row-input remark-input" appTap type="text" formControlName="OmekSulia" />
					</div>
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">סוג תקלת יצרן</div>
						<select formControlName="SugTakalatYazran">
							<option [value]="item.Name" *ngFor="let item of reclamationReasons$ | async">{{ item.Name }}</option>
						</select>
						<span class="invalid-color" *ngIf="form.controls.SugTakalatYazran.touched && form.controls.SugTakalatYazran.invalid"> יש לבחור סוג תקלה כדי לשלוח תביעה </span>
					</div>
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">הערה</div>
						<textarea class="order-step__block-row-input remark-input" appTap formControlName="RekRemark"></textarea>
					</div>
					<div class="order-cart__popup-block row">
						<div class="order-step__block-row-title">הוסף תמונות</div>
						<input type="file" [ngClass]="{ success: images[i] }" *ngFor="let image of images; index as i" type="file" accept="image/*" capture="camera" (change)="setImage($event, i)" />
						<button type="button" class="buttons-row-button buttons-row-button_green" (click)="addImageControl()">הוסף תמונה</button>
					</div>
					<ng-container *ngTemplateOutlet="cancelSendButton; context: { $implicit: 'blue' }"></ng-container>
				</div>
			</form>
		</div>
	</div>
</div>

<ng-template #cancelSendButton let-color>
	<div class="buttons-row">
		<button class="buttons-row-button" type="submit" [ngClass]="getButtonClass(color)">שלח</button>
		<button class="buttons-row-button" type="button" (click)="cancelSendAndClosePopup()">{{ _text.store.declineReturn }}</button>
	</div>
</ng-template>
