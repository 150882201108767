<div class="page">
	<div class="page-background">
		<img [src]="logo" alt="mobility" class="logo" />

		<form [formGroup]="form" (ngSubmit)="Login()" *ngIf="showLoginForm" class="auth__form-base">
			<fieldset class="auth__form">
				<!-- <video autoplay loop muted playsinline id="video" class="auth__avatar image" >
					<source src="/assets/images/auth/man-read.webm" type="video/webm" />
					<source src="/assets/images/auth/man-read.mp4" type="video/mp4" />
				</video> -->
				<div class="auth__avatar image"></div>
				<ng-container *ngIf="isLocalhost">
					<h2 style="margin: 4px">Fast Login</h2>
					<mat-button-toggle-group color="primary" aria-label="Fast Login" (change)="loginLocalhost($event)">
						<mat-button-toggle style="background: var(--main-color); color: var(--secondary-color)" value="mekabel">Mekabel</mat-button-toggle>
						<mat-button-toggle style="background: var(--main-color); color: var(--secondary-color)" value="user">User</mat-button-toggle>
						<mat-button-toggle style="background: var(--main-color); color: var(--secondary-color)" value="admin">Admin</mat-button-toggle>
					</mat-button-toggle-group>
					<!-- <div class="row">
						<button mat-raised-button color="primary" type="button" (click)="Login(true)">Login .30000</button>
						<button mat-raised-button color="primary" type="button" (click)="Login(true)">Login .30000</button>
					</div> -->
				</ng-container>
				<div class="auth__form-controls">
					<div class="auth__form-block">
						<label class="form-control__label" for="username"> מספר חברה </label>
						<div class="form-control__input form-control__input--error">
							<input
								(keyup)="KeyUpCustomerNumber()"
								formControlName="CustomerNumber"
								id="customernumber"
								type="text"
								class="form-control form-control--error"
								autocomplete="off"
								inputmode="numeric"
							/>
							<div class="form-control__input--error-info" *ngIf="CompanyNotFilled === true"></div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="CompanyNotFilled === true">
								<span>יש להזין מספר חברה </span>
							</div>
						</div>
					</div>
					<div class="auth__form-block">
						<label class="form-control__label" for="username"> מספר סוכן </label>
						<div class="form-control__input form-control__input--error">
							<input (keyup)="KeyUpAgentId()" formControlName="AgentId" id="agentid" type="text" class="form-control form-control--error" autocomplete="off" />
							<div class="form-control__input--error-info" *ngIf="AgentIdNotFilled === true"></div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="AgentIdNotFilled === true">
								<span>יש להזין מספר סוכן </span>
							</div>
						</div>
					</div>
					<div class="auth__form-block">
						<label class="form-control__label" for="password"> סיסמה </label>
						<div class="form-control__input form-control__input--error">
							<input (keyup)="KeyUpPassword()" formControlName="Password" id="password" type="password" class="form-control form-control--error" autocomplete="off" />
							<div class="form-control__input--error-info" *ngIf="PasswordNotFilled === true"></div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="PasswordNotFilled === true">
								<span> יש להזין סיסמה </span>
							</div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="WrongPassOrName === true">
								<span> שם משתמש או סיסמא אינם נכונים </span>
							</div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="AlreadyLogged === true">
								<span>אופס..! יש כבר סוכן מחובר </span>
							</div>
							<div class="form-control__validation-message form-control__validation-message--error" *ngIf="ServerProblem === true">
								<span>מצטערים ישנה תקלה בשרת.</span>
							</div>
						</div>
					</div>
					<p class="auth__navigation">
						<a (click)="showHideBlock(blockType.CallBlock)"> שכחתי שם משתמש/סיסמה</a>
					</p>
				</div>
				<div *ngIf="Loading === true">טוען... אנא המתן</div>
				<div class="auth__actions" *ngIf="Loading === false">
					<button type="submit" class="submit-button">
						<img src="/assets/images/logout.svg" alt="''" class="submit-button__icon" />
						<span class="submit-button__text"> כניסה </span>
					</button>
					<span style="margin-top: 10px; font-size: 14px; font-weight: 400">
						<b>{{ this._ss.VersionNumber }}</b></span
					>
					<br />
					<span style="margin-top: 10px; font-size: 14px; font-weight: 400" (click)="MoveToPrivacy()">
						<b><u>Privacy Policy - תנאי שימוש</u></b></span
					>
				</div>
			</fieldset>
			<div class="additional-controls">
				<div class="call-control" (click)="router.navigate(['help'])">
					<span class="call-control__text"> קריאת שירות </span>
					<img width="auto" height="16px" src="/assets/images/bell.svg" alt="''" class="call-control__icon" />
				</div>
			</div>
		</form>

		<div class="auth__help" *ngIf="showSuccessBlock">
			<h2 class="auth__help-title">תודה שפנית אלינו, נחזור אליך בהקדם!</h2>
			<div class="auth__help-image image">
				<img src="/assets/images/auth/plane-icon.gif" alt="photo" />
			</div>
			<div class="auth__help-cancel" (click)="showHideBlock(blockType.LoginForm)">סגור</div>
		</div>
		<div class="auth__help" *ngIf="showCallBlock">
			<h2 class="auth__help-title">צור קשר עם חברת מוביליטי</h2>
			<div class="auth__help-descrip">050-3899773</div>
			<div class="auth__help-image auth__help-image_small image">
				<img src="/assets/images/auth/call-icon.gif" alt="photo" />
			</div>
			<div class="auth__help-image auth__help-image_small image">
				<a href="https://wa.me/{{ 0503899773 | unformatPhoneNumber }}">
					<img src="/assets/images/client-profile/whatsapp-icon.svg" alt="image" />
				</a>
			</div>

			<a href="tel:0503899773"> <button class="auth__help-button">חייג עכשיו</button></a>

			<div class="auth__help-cancel" (click)="showHideBlock(blockType.LoginForm)">סגור</div>
		</div>
	</div>
</div>

<!-- ========================= -->
<div class="popup" *ngIf="Privacy === true" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<!-- <div
                 class="popup__close"
                 (click)="Privacy = false"
               ></div> -->

		<div style="text-align: center; width: 99%; margin: auto; font-size: 15px; color: blue; font-weight: 500px">Privacy Policy - תנאי שימוש</div>
		<div style="text-align: center; width: 99%; margin: auto; font-size: 15px">office@mobility-ltd.com - 050-3899773</div>

		<div dir="rtl" class="div3" style="margin-top: 10px">
			תנאי שימוש באפליקציית מוביליטי, כדי לאבטח את המשתמש שלך, מוביליטי לעולם לא תבקש את פרטיך האישיים, פרטי ההתחברות מסופקים אך ורק על ידי חברת מוביליטי, אנא אל תחלוק את פרטי ההתחברות הניתנים לך,
			מוביליטי רשאית לעדכן את תנאי השימוש, אנא וודא שאתה בודק את תנאי השימוש מפעם לפעם, אם הנך חושב ששימוש באפליקציית מוביליטי פוגע באחד מתנאי השימוש אנא צור איתנו קשר בפרטים למעלה.
			<div class="product-detail-add__popup-row" style="width: 100px; margin: auto; margin-top: 10px">
				<button class="popup-button" (click)="Privacy = false" style="background-color: #38d88b; width: 100px">מאשר</button>
			</div>
		</div>

		<div class="div3">
			This privacy policy sets out how mobility uses and protects any information on this website. mobility is committed to ensuring that your privacy is protected. mobility does not required any
			information from our users,login information is provided only by mobility, mobility will never ask you to insert any personal information, then you can be assured that it will only be used in
			accordance with this privacy statement. mobility may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with
			any changes. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly
			correct any information found to be incorrect.

			<div class="product-detail-add__popup-row" style="width: 100px; margin: auto; margin-top: 10px">
				<button class="popup-button" (click)="Privacy = false" style="background-color: #38d88b; width: 100px">Confirm</button>
			</div>
		</div>
	</div>
</div>
<!-- ========================= -->
