import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
	name: 'getBonusNumber',
})
export class GetBonusNumberPipe implements PipeTransform {
	constructor(private _ss: StoreService) {}

	transform(bonus: number, teudaQty?: number, teudaPackQty?: number, unitsPerPack?: number): number {
		const custInfo = this._ss.CustomerInformation;

		if (custInfo.ShowProduct !== '7' && custInfo.ShowProduct !== '8' && custInfo.SpecificCompanyVersion !== '1' && custInfo.BonusDisplay !== '1') {
			return bonus;
		}

		unitsPerPack ||= 1;
		return bonus + teudaQty + teudaPackQty * unitsPerPack;
	}
}
