import { Pipe, PipeTransform } from '@angular/core';
import { Time } from '../services/util.service';

const TWENTYFOUR_HOURS = Time.day;

@Pipe({
	name: 'isAlertActive',
})
export class IsAlertActivePipe implements PipeTransform {
	transform(value: '0' | '1' | '2', start?: string, end?: string): boolean {
		switch (value) {
			case '0':
				return true;

			case '2':
				if (start == '0' || end == '0') return false
				if (isNaN(+new Date(start)) || isNaN(+new Date(end))) {
					console.log({ start: new Date(start), end: new Date(end), dates: { start, end }, value });
					throw Error('invalid date provided to pipe');
				}
				return new Date().getTime() >= new Date(start).getTime() && new Date().getTime() <= new Date(end).getTime() + TWENTYFOUR_HOURS;

			case '1':
			default:
				return false;
		}
	}
}
