import { Component, ElementRef, ViewChild, AfterViewInit, Input, EventEmitter, Output, Renderer2, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';

import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';
// import Hammer from 'hammerjs';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import SignaturePad from 'signature_pad';
import { PDFDocumentProxy, PDFSource, PdfViewerModule } from 'ng2-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient } from '@angular/common/http';
import { PDFDocument, rgb } from 'pdf-lib';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { PDFTextOptions } from 'src/app/Interfaces/truck-distribution';
import { MaterialModule } from 'src/app/modules/material/material.module';

type ZoomScale = 'page-height' | 'page-fit' | 'page-width';

interface PDFProgressData {
	loaded: number;
	total: number;
}

@Component({
	selector: 'app-canvas-popup',
	styleUrls: ['./canvas-popup.component.scss'],
	templateUrl: './canvas-popup.component.html',
	standalone: true,
	imports: [HammerModule, CommonModule, PdfViewerModule, MaterialModule, MatButtonModule],
})
export class CanvasPopupComponent implements AfterViewInit, OnDestroy, OnInit {
	constructor() {}
	@Input() pdfSRCInput$: Subject<string>;
	@Input() alreadyHaveSigned: boolean;
	@Input() useSignature: boolean = true;
	@Input() textToRender: PDFTextOptions[];
	@Output() private readonly closeEvent = new EventEmitter();
	@Output() private readonly signatureFinalizedEvent = new EventEmitter<string>();

	private readonly destroy$ = new Subject<void>();

	isSignaturePopupOpen = false;
	signatureOK = false;

	signatureFinalized = false;

	signaturePad: SignaturePad | undefined;

	error: any;
	page = 1;
	rotation = 0;
	zoom = 1.0;
	zoomScale: ZoomScale = 'page-width';
	originalSize = false;
	pdf: any;
	renderText = true;
	isLoaded = false;
	stickToPage = false;
	showAll = true;
	autoresize = true;
	fitToPage = false;
	outline!: any[];
	isOutlineShown = false;
	pdfQuery = '';
	mobile = false;

	finalPDFsrc$ = new BehaviorSubject<string>('');

	arimoFont = new FontFace('Arimo', 'https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700');

	async ngOnInit() {
		this.arimoFont
			.load()
			.then(() => {})
			.catch(() => {});

		//set pdf worker
		//gotten from https://cdn.jsdelivr.net/npm/pdfjs-dist@2.14.305/legacy/build/pdf.worker.min.js
		(window as any).pdfWorkerSrc = 'assets/pdf.worker.min.js';

		if (this.alreadyHaveSigned) {
			this.signatureOK = this.signatureFinalized = true;
		}

		this.pdfSRCInput$.pipe(takeUntil(this.destroy$)).subscribe(url => {
			this.finalPDFsrc$.next(url);
			this.zoom = 1;
			this.page = 1;
		});
	}

	async ngAfterViewInit() {
		this.setupListener();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	closePopup() {
		this.closeEvent.emit();
	}

	setupListener() {}

	dontPropogate(event: Event) {
		event.stopPropagation();
	}

	finalizeSignature() {
		this.signatureFinalized = true;
		this.signatureFinalizedEvent.emit(this.signaturePad.toDataURL());
		this.toggleSignaturePopupAndPrepareSignatureCanvas('close');
	}

	toggleSignaturePopupAndPrepareSignatureCanvas(mode: 'open' | 'close') {
		if (mode === 'close') {
			if (this.signatureFinalized) {
				this.isSignaturePopupOpen = false;
				this.closePopup();
				return;
			}
			this.isSignaturePopupOpen = this.signatureOK = false;
			return;
		}

		if (mode === 'open') {
			if (this.signatureFinalized) {
				return;
			}
			this.isSignaturePopupOpen = true;

			setTimeout(() => {
				const canvas = document.getElementById('signature') as HTMLCanvasElement | null;

				if (!canvas) {
					this.isSignaturePopupOpen = false;
					return;
				}
				this.signaturePad = new SignaturePad(canvas);

				const ctx = canvas.getContext('2d');
				if (!ctx) return;
				ctx.strokeStyle = '#BADA55';
				ctx.lineJoin = 'round';
				ctx.lineCap = 'round';
				ctx.fillStyle = '#ffffff';
				ctx.fillRect(0, 0, canvas.width, canvas.height);
			}, 0);
		}
	}

	incrementPage(amount: number) {
		this.page += amount;
	}

	incrementZoom(amount: number) {
		this.zoom = Math.max(this.zoom + amount, 1);
	}

	rotate(angle: number) {
		this.rotation += angle;
	}

	/**
	 * Get pdf information after it's loaded
	 * @param pdf pdf document proxy
	 */
	async afterLoadComplete(pdf: PDFDocumentProxy) {}

	/**
	 * Get outline
	 */
	loadOutline() {
		this.pdf.getOutline().then((outline: any[]) => {
			this.outline = outline;
		});
	}

	/**
	 * Handle error callback
	 *
	 * @param error error message
	 */
	onError(error: any) {
		this.error = error; // set error

		this.finalPDFsrc$.next('');

		if (error.name === 'PasswordException') {
			const password = prompt('This document is password protected. Enter the password:');

			if (password) {
				this.error = null;
				this.setPassword(password);
			}
		}
	}

	setPassword(password: string) {}

	/**
	 * Pdf loading progress callback
	 * @param progressData pdf progress data
	 */
	onProgress(progressData: PDFProgressData) {
		// console.log(progressData);
		// this.progressData = progressData;

		this.isLoaded = progressData.loaded >= progressData.total;
		this.error = null; // clear error
	}

	getInt(value: number): number {
		return Math.round(value);
	}

	/**
	 * Navigate to destination
	 * @param destination pdf navigate to
	 */
	navigateTo(destination: any) {
		// this.pdfComponent.pdfLinkService.goToDestination(destination);
	}

	/**
	 * Scroll view
	 */
	scrollToPage() {
		// this.pdfComponent.pdfViewer.scrollPageIntoView({
		// 	pageNumber: 3,
		// });
	}

	/**
	 * Page rendered callback, which is called when a page is rendered (called multiple times)
	 *
	 * @param e custom event
	 */
	pageRendered(e: CustomEvent) {
		this.handleAddCustomTextToPage();
	}

	/**
	 * Page initialized callback.
	 *
	 * @param {CustomEvent} e
	 */
	pageInitialized(e: CustomEvent) {
		// console.log('(page-initialized)', e);
	}

	/**
	 * Page change callback, which is called when a page is changed (called multiple times)
	 *
	 * @param e number
	 */
	pageChange(e: number) {
		// console.log('(page-change)', e);
	}

	searchQueryChanged(newQuery: string) {
		const type = newQuery !== this.pdfQuery ? '' : 'again';
		this.pdfQuery = newQuery;

		// this.pdfComponent.eventBus.dispatch('find', {
		// 	type,
		// 	query: this.pdfQuery,
		// 	highlightAll: true,
		// 	caseSensitive: false,
		// 	phraseSearch: true,
		// 	// findPrevious: undefined,
		// });
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.mobile = (event.target as Window).innerWidth <= 768;
	}

	handleAddCustomTextToPage() {
		const element = document.querySelector('.canvasWrapper') as HTMLElement | null;

		if (!element) return;

		if (this.textToRender?.length) {
			for (const text of this.textToRender) {
				let canvas = element;
				if (text.page === 1) {
					const canvases = Array.from(document.querySelectorAll('.canvasWrapper'));
					canvas = canvases[canvases.length - 1] as HTMLElement;
				}
				this.handleRemoveAndTextSpan({ canvas, ...text });
			}
		}
	}

	handleRemoveAndTextSpan(options: { canvas: HTMLElement } & PDFTextOptions) {
		let { id, text, top, canvas, left, right, size } = options;

		const previousSpan = document.getElementById(id);

		if (previousSpan) {
			previousSpan.remove();
		}

		const span = document.createElement('span');
		if (top) {
			top *= parseInt(canvas.style.height);
			if (text) {
				const split = text.split('<br/>');
				top -= split.length * size * this.zoom;
			}
			span.style.top = top + 'px';
		}

		if (right != null) {
			right *= parseInt(canvas.style.width);
			span.style.right = right + 'px';
			span.style.transformOrigin = 'right';
		}

		if (size) {
			span.style.fontSize = size + 'px';
			span.style.lineHeight = size + 'px';
		}

		span.innerHTML = text;
		span.setAttribute('role', 'presentation');
		span.dir = 'rtl';
		span.style.transform = `scale(${this.zoom})`;
		span.style.fontWeight = '700';
		span.style.color = 'black';
		span.style.position = 'absolute';
		span.id = id;

		if (left) {
			left *= parseInt(canvas.style.width);
			span.style.left = left + 'px';
			span.style.transform = `translate(-50%) scale(${this.zoom})`;
		}

		canvas.appendChild(span);
	}
}
