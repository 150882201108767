import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { compress, decompress } from 'lz-string';
import { BaseComponent } from '../base-component/base.component';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { icons } from 'src/app/Interfaces/assets';

type Icons = keyof typeof icons;

@Component({
	selector: 'app-dohot',
	templateUrl: './dohot.component.html',
	styleUrls: ['./dohot.component.scss'],
})
export class DohotComponent extends BaseComponent implements OnInit {
	constructor(public _router: Router) {
		super();
	}

	public Loading = false;
	public Status;
	public AllProducts;
	public Date = new Date();
	public Admin;
	history = history;

	isB2B = location.href.includes('b2b');

	isB2BL = false;
	isB2BLM = false;

	client = null;

	logo = MOBILITY_LOGO;

	readonly normalDohotItems: { text: string; icon: Icons; path: string }[] = [
		{ icon: 'products', text: 'מוצרים', path: 'dohot-motzarim' },
		{ icon: 'performence', text: 'ביצועים', path: 'dohot-bizoim' },
		{ icon: 'customers', text: 'לקוחות', path: 'dohot-lekohot' },
		{ icon: 'certificates', text: 'תעודות', path: 'dohot-teudot' },
	];

	readonly adminItem = { icon: 'performence', text: 'דף מנהל', path: 'admin' } as const;
	readonly agentSeeDashboardItem = { icon: 'performence', text: 'תמונת מצב', path: 'mobilitydashbord' } as const;
	readonly b2bItems: { text: string; icon: 'products'; path: string }[] = [{ text: 'חובות', path: 'dohot-b2b', icon: 'products' }];

	ngOnInit(): void {
		if (this.isB2B) {
			const ls = localStorage.getItem('AllClients');

			if (ls) {
				const allClients = JSON.parse(decompress(ls)) as AllClients[];

				const client = allClients.find(client => client.ClientId === this._ss.AgentIdConnected);

				this.client = client || allClients[0];
			}
		}

		if (this._ss.isMovingToDohot) this._ss.isMovingToDohot = false;

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		if (this.Admin && this.Admin.includes('b2b')) {
			if (!this.isB2B) {
				this._router.navigate(['dohot-b2b'], { replaceUrl: true });
			}

			this.isB2BL = this.Admin === 'b2bL';
			this.isB2BLM = this.Admin === 'b2bLM';
		}

		if (localStorage.getItem('AgentId') === null) {
			this._router.navigate(['clients']);
		}

		window.scrollTo(0, 0);

		if (localStorage.getItem('AllProducts') === null) {
			this.Loading = true;
			setTimeout(() => {
				this.Status = '...מוריד נתונים';

				this._ss.GetAllProducts().subscribe({
					next: res => {
						if (res === 'ConnectionError' || res === 'RequestError') {
							alert('ישנה תקלת תקשורת, אנא נסו שנית  מאוחר יותר');
							this._router.navigate(['home'], { replaceUrl: true });
						}

						this.AllProducts = res;
						this.AllProducts = this.AllProducts.recordset;

						localStorage.setItem('AllProducts', compress(JSON.stringify(this.AllProducts)));
						localStorage.setItem('lastupdate', JSON.stringify(this.Date.toISOString()));
						this.Status = '...מבצע שמירה מקומית';
						setTimeout(() => {
							this.Loading = false;
						}, 4000);
					},
					error: () => {
						alert('ישנה תקלת תקשורת, אנא נסו שנית  מאוחר יותר');
						this._router.navigate(['home'], { replaceUrl: true });
					},
				});
			}, 500);
		}
	}

	handleNavigation(route: 'debts' | 'branches' | 'dashboard') {
		if (route) {
			if (route === 'branches') {
				this._router.navigate(['branches-lookup']);
				return;
			}
			if (route === 'dashboard') {
				this._router.navigate(['mobilitydashbord-B2B-autolux']);
				return;
			}
		}
		if (!this.client) {
			alert('ישנה בעיה בקבלת נתוני לקוח, אנא נסה שנית מאוחר יותר');
			return;
		}

		this._ss.OneClientInfo = this.client;
		this._ss.AutoMoveHovot = 1;

		this._router.navigate(['client-card']);
	}

	public MoveToClients() {
		this._router.navigate(['clients']);
	}

	public BackToHome() {
		if (this._ss.FromProductsToDohot === true) {
			this._router.navigate(['products']);
		} else {
			this._router.navigate(['home']);
		}
	}

	public MoveToDohotDinamicAgent() {
		this._router.navigate(['dohot-dinamic-agent']);
	}

	public MoveToAdmin() {
		this._router.navigate(['admin']);
	}

	clearState() {
		this._ss.SaveTeudotDohot = null;
	}
}
