import { shouldDisplayDifferentLogo } from './functions';

export const monthsHeb = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];

let logo = 'logo';

const shanaTova = '_ShanaTova';

const hannukah = '-hannukah';

const isNewYear = shouldDisplayDifferentLogo('Rosh Hashana');

if (isNewYear) {
	logo += shanaTova;
}

logo += '.svg';

export const MOBILITY_LOGO_SHANATOVA = '/assets/images/logo_ShanaTova.svg';

export const MOBILITY_LOGO = `/assets/images/${logo}`;

if (isNewYear) {
	document.getElementById('favicon').setAttribute('href', MOBILITY_LOGO_SHANATOVA);
}

export const ccExpiryMonths = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
export const ccExpiryYears = Array.from({ length: 10 }, (_, i) => (new Date().getFullYear() + i).toString().slice(-2));
//@ts-ignore
window.logo = window.MOBILITY_LOGO = MOBILITY_LOGO;
