import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';

@Component({
	selector: 'app-admincake',
	template: '',
	// templateUrl: './admincake.component.html',
	styleUrls: ['./admincake.component.scss'],
})
export class AdmincakeComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {}

	public Agentsres;
	public Agents = [];
	public DateUpdate = new Date();
	public len = 0;
	public me = 0;
	public ah = 0;

	public pie1 = true;
	public pie2 = false;
	public pie3 = false;
	public pie4 = false;
	public pie5 = false;
	public pie6 = false;
	public pie7 = false;
	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this._ss.GetAgentsList().subscribe(res => {
			this.Agentsres = res;
			this.Agentsres = this.Agentsres.recordset;

			for (let x = 0; x < this.Agentsres.length; x++) {
				if (this.Agentsres[x].td.toString().slice(8, 10) === this.DateUpdate.toString().slice(8, 10)) {
					this.Agents.push(this.Agentsres[x]);
				}
			}

			this.Agents.sort(function (a, b) {
				return b.sum1 - a.sum1;
			});

			setTimeout(() => {
				for (let x = 0; x < this.Agents.length; x++) {
					this.len = x * 150;
					this.me = this.Agents[x].sum1 / 1000;

					document.getElementById('q' + this.Agents[x].agn).style.marginLeft = this.len + 'px';
					if (this.me < 5) {
						this.me = 5;
						document.getElementById('m' + this.Agents[x].agn).style.height = this.me + 'px';
					} else {
						if (this.me > 210 && this.me < 300) {
							this.me = 210;
						}
						if (this.me > 300 && this.me < 400) {
							this.me = 230;
						}
						if (this.me > 400 && this.me < 500) {
							this.me = 230;
						}
						if (this.me > 500 && this.me < 600) {
							this.me = 245;
						}
						if (this.me > 600 && this.me < 700) {
							this.me = 260;
						}
						if (this.me > 700 && this.me < 800) {
							this.me = 270;
						}
						if (this.me > 800 && this.me < 1000) {
							this.me = 290;
						}
						if (this.me > 1000 && this.me < 1500) {
							this.me = 310;
						}
						if (this.me > 1500 && this.me < 2000) {
							this.me = 330;
						}
						if (this.me > 2000) {
							this.me = 350;
						}
						document.getElementById('m' + this.Agents[x].agn).style.height = this.me + 'px';
					}

					document.getElementById('a' + this.Agents[x].agn).style.height = '2px';
					// document.getElementById('q-graph').style.marginLeft =  this.len+"px"
				}
			}, 200);

			setTimeout(() => {
				if (this.Agents.length === 0) {
				} else if (this.Agents.length === 1) {
					document.getElementById('color' + this.Agents[0].agn).style.color = '#38D88B';
					document.getElementById('pie').style.background = '#38D88B';
				} else if (this.Agents.length === 2) {
					this.pie1 = false;
					this.pie2 = true;

					setTimeout(() => {
						if (this.Agents[0].sum1 > this.Agents[1].sum1) {
							document.getElementById('color' + this.Agents[0].agn).style.color = '#38D88B';
							document.getElementById('color' + this.Agents[1].agn).style.color = 'red';
						} else {
							document.getElementById('color' + this.Agents[1].agn).style.color = '#38D88B';
							document.getElementById('color' + this.Agents[0].agn).style.color = 'red';
						}
					}, 100);
				} else if (this.Agents.length === 3) {
					this.pie1 = false;
					this.pie3 = true;

					this.Agents.sort(function (a, b) {
						return b.sum1 - a.sum1;
					});

					setTimeout(() => {
						document.getElementById('color' + this.Agents[0].agn).style.color = '#38D88B';
						document.getElementById('color' + this.Agents[1].agn).style.color = 'red';
						document.getElementById('color' + this.Agents[2].agn).style.color = 'black';
					}, 100);
				} else if (this.Agents.length === 4) {
					this.pie1 = false;
					this.pie4 = true;

					this.Agents.sort(function (a, b) {
						return b.sum1 - a.sum1;
					});

					setTimeout(() => {
						document.getElementById('color' + this.Agents[0].agn).style.color = '#38D88B';
						document.getElementById('color' + this.Agents[1].agn).style.color = 'red';
						document.getElementById('color' + this.Agents[2].agn).style.color = 'black';
						document.getElementById('color' + this.Agents[3].agn).style.color = 'blue';
					}, 100);
				} else if (this.Agents.length === 5) {
					this.pie1 = false;
					this.pie5 = true;

					this.Agents.sort(function (a, b) {
						return b.sum1 - a.sum1;
					});

					setTimeout(() => {
						document.getElementById('color' + this.Agents[0].agn).style.color = '#38D88B';
						document.getElementById('color' + this.Agents[1].agn).style.color = 'red';
						document.getElementById('color' + this.Agents[2].agn).style.color = 'black';
						document.getElementById('color' + this.Agents[3].agn).style.color = 'blue';
						document.getElementById('color' + this.Agents[4].agn).style.color = 'orange';
					}, 100);
				} else if (this.Agents.length === 6) {
					this.pie1 = false;
					this.pie6 = true;

					this.Agents.sort(function (a, b) {
						return b.sum1 - a.sum1;
					});

					setTimeout(() => {
						document.getElementById('color' + this.Agents[0].agn).style.color = '#38D88B';
						document.getElementById('color' + this.Agents[1].agn).style.color = 'red';
						document.getElementById('color' + this.Agents[2].agn).style.color = 'black';
						document.getElementById('color' + this.Agents[3].agn).style.color = 'blue';
						document.getElementById('color' + this.Agents[4].agn).style.color = 'orange';
						document.getElementById('color' + this.Agents[5].agn).style.color = 'brown';
					}, 100);
				} else if (this.Agents.length === 7) {
					this.pie1 = false;
					this.pie7 = true;

					this.Agents.sort(function (a, b) {
						return b.sum1 - a.sum1;
					});

					setTimeout(() => {
						document.getElementById('color' + this.Agents[0].agn).style.color = '#38D88B';
						document.getElementById('color' + this.Agents[1].agn).style.color = 'red';
						document.getElementById('color' + this.Agents[2].agn).style.color = 'black';
						document.getElementById('color' + this.Agents[3].agn).style.color = 'blue';
						document.getElementById('color' + this.Agents[4].agn).style.color = 'orange';
						document.getElementById('color' + this.Agents[5].agn).style.color = 'brown';
						document.getElementById('color' + this.Agents[6].agn).style.color = 'purple';
					}, 100);
				}
			}, 300);
		});
	}

	//==============================================================================================================================================>
	//==============================================================================================================================================>
	//==============================================================================================================================================>
	//==============================================================================================================================================>

	public BackToAdmin() {
		history.back();
	}
}

//==============================================================================================================================================>
//==============================================================================================================================================>
//==============================================================================================================================================>
//==============================================================================================================================================>
