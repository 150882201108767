import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject, Observable, Subscription, Subject, fromEvent, takeUntil } from 'rxjs';

import { ClientGoalsDisplay, ClientGoals } from 'src/app/Interfaces/goals';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { StoreService } from 'src/app/services/store.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
	selector: 'app-password-for-bypass',
	templateUrl: './password-for-bypass.component.html',
	styleUrls: ['./password-for-bypass.component.scss'],
})
export class PasswordForBypassComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _acr: ActivatedRoute, private _util: UtilService) {}

	allClients: AllClients[] = [];
	allClients$ = new BehaviorSubject<AllClients[]>(this.allClients);
	year = new Date().getFullYear();
	month = new Date().getMonth();
	shouldShowAgentSelectPopup = false;
	windowWidth = innerWidth;
	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription = new Subscription();

	form = new FormGroup({
		agents: new FormControl(''),
		action: new FormControl(0),
	});

	selectedToEdit;

	isSendingInfo = false;
	infoSendingMessage = 'מעדכן';

	agents = [];

	document = document;

	history = history;

	Loading = true;

	searchControl = new FormControl<string>('', { nonNullable: true });

	destroy$ = new Subject<void>();

	company;

	actions = [
		{ value: 1, text: 'פתח לקוח להזמנה' },
		// { value: 2, text: 'פתח אפשרות לשנות מחיר' },
	];

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	passwordObject: { [agentid: string]: string } = {};

	ngOnInit(): void {
		this.company = this._acr.snapshot.queryParamMap.get('company') || 1;

		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			} else {
				alert('קרתה שגיאה בקריאת נתונים');
				this.router.navigate(['admin']);
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		const clientsFromStorage = localStorage.getItem('AllClients');

		if (!clientsFromStorage) {
			this._ss.GetAllClients().subscribe({
				next: (res: any) => {
					this.allClients = res.recordset;
					if (this.company) {
						this.allClients = this.allClients.filter(ac => ac.company == this.company);
					}
					this.allClients$.next(this.allClients);
					this.Loading = false;
					console.log(this.allClients);
				},
			});
		} else {
			this.allClients = JSON.parse(decompress(clientsFromStorage));
			if (this.company) {
				this.allClients = this.allClients.filter(ac => ac.company == this.company);
			}
			this.allClients$.next(this.allClients);
			this.Loading = false;
			console.log(this.allClients);
		}

		this._ss.GetAllAgents().subscribe({
			next: (res: any) => {
				this.agents = res.recordset;

				for (const ag of this.agents) {
					const control = new FormControl('');

					control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async val => {
						if (val) {
							const pass = await this._util.getPasswordPIN({ AgentID: ag.AgentID, CompanyNumber: this.selectedToEdit.ClientId, company: this.company, action: +val });
							this.passwordObject[ag.AgentID] = pass;
						}
					});

					this.form.addControl(ag.AgentID, control);
				}
			},
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleEditButton(client) {
		this.shouldShowAgentSelectPopup = true;
		this.selectedToEdit = client;
	}

	search() {
		this.virtualScroll.scrollToIndex(0);

		const value = this.searchControl.value?.toLowerCase?.();

		if (!value) {
			this.allClients$.next(this.allClients);
		} else {
			const pattern = new RegExp(value.replace(/[\/*\+\-\?,]/g, ''), 'i');

			this.allClients$.next(this.allClients.filter(ac => pattern.test(ac.Client_Name) || pattern.test(ac.ClientId)));
		}
	}

	SearchClear() {
		this.searchControl.reset();
		this.search();
	}

	closeEditPopup() {
		this.shouldShowAgentSelectPopup = this.isSendingInfo = false;

		this.selectedToEdit = null;

		this.form.reset();

		this.passwordObject = {};

		// const arr = Object.keys(this.passwordObject);

		// for (const key of arr) {
		// 	delete this.passwordObject[key];
		// }
	}
}
