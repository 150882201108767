import { Pipe, PipeTransform } from '@angular/core';
import { StoredDistributionTask } from '../Interfaces/truck-distribution';

@Pipe({
	name: 'taskSendingStatus',
	pure: false,
})
export class TaskSendingStatusPipe implements PipeTransform {
	transform(task: StoredDistributionTask, ...args: unknown[]): string {
		if (task.main.isSending) {
			return 'שולח...';
		}

		if (task.main.sendingSuccess) {
			return 'נשלח';
		}

		// sendingSuccess false
		return 'לא נשלח';
	}
}
