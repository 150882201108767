import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base-component/base.component';

@Component({
	selector: 'app-menu-button',
	templateUrl: './menu-button.component.html',
	styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent extends BaseComponent implements OnInit {
	@Input() shouldChangeToPositionInitial: boolean;

	shouldFillMainColor = ['home', 'dohot', 'dohot-bizoim', 'dohot-lekohot', 'dohot-motzarim', 'admin-manage-company', 'admin-reports-company'];

	constructor(private _router: Router) {
		super();
	}

	shouldUseMainColorFill = this.shouldFillMainColor.includes((this._router.url || '').replace('/', ''));

	ngOnInit(): void {
		document.documentElement.style.setProperty('--menu-button-main-color', 'var(--' + (this.shouldUseMainColorFill ? 'main' : 'secondary') + '-color)');
	}
}
