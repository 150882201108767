export default class ChangedDate extends Date {
	constructor() {
		super();
	}

	getChangedTime() {
		const d = new Date();
		d.setHours(0);
		d.setHours(-5);
		return d;
	}
}
