import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router, Event as NavigationEvent, NavigationEnd, RouterEvent } from '@angular/router';
import { decompress } from 'lz-string';
import { BehaviorSubject, Subject, takeUntil, Subscription } from 'rxjs';
// import { ReturnMonthHebrewPipe } from 'src/app/Pipes/return-month-hebrew.pipe';
import { StoreService } from 'src/app/services/store.service';
import { UtilService, ExcelObject } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { utils, writeFile } from 'xlsx';
import mockResponse from './mockResponse.json';
import debtResult from './debt_result.json';

import { SQLResponse } from '../../Interfaces/SQL-interfaces';

import { AgentGviaService, colors, Gvia } from 'src/app/services/agent-gvia.service';

const FirstValues = ['DebtCustCode', 'CustOpenDebt', 'OwnerAgentID'] as const;
const SecondValues = ['DebtDocNum', 'DocTypeName', 'DebtDocCreateDate', 'DebtDocToPayDate', 'DebtDocTotal', 'DebtDocOpenToPay', 'DebtRemark', 'PayTermName'] as const;

enum DebtColor {
	red = 'red',
	purple = 'purple',
	green = 'green',
}

const valuesObject = {
	First: FirstValues,
	Second: SecondValues,
} as const;

@Component({
	selector: 'app-agent-gvia',
	templateUrl: './agent-gvia.component.html',
	styleUrls: ['./agent-gvia.component.scss'],
})
export class AgentGviaComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _util: UtilService, private _acr: ActivatedRoute, public _agentGvia: AgentGviaService) {}

	public AllClientsSearch;
	public AgentsSalesAll: Gvia[] = this._agentGvia.AgentsSalesAll;
	AgentsSalesAll$ = new BehaviorSubject<typeof this.AgentsSalesAll>(this.AgentsSalesAll);

	public AgentsSalesAllResponse: typeof this.AgentsSalesAll = this._agentGvia.AgentsSalesAllResponse;
	public AgentsSalesAllSecond: typeof this.AgentsSalesAll = this._agentGvia.AgentsSalesAllSecond;
	public AgentsSalesAllSecondObject: Record<string, typeof this.AgentsSalesAll> = this._agentGvia.AgentsSalesAllSecondObject;

	public HasMultipleCompanys;
	public ShowCompanys;

	public First = true;
	public Second = false;

	colors = colors;

	loading = true;

	agentResponseWithGoals = [];

	agentsToGet = [];

	agentsFromV_Users;

	destroy$ = new Subject<void>();

	displayTotal$ = new BehaviorSubject<number>(0);
	displayAverage$ = new BehaviorSubject<number>(0);

	isClosed = true;

	windowWidth = innerWidth;

	clientid;
	navigate$: Subscription;
	// disgregardNavigation = true;

	// @ViewChild('secondTable') secondTable: ElementRef<HTMLTableElement>;

	async ngOnInit() {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (this._acr.snapshot.queryParamMap.keys.length) {
			this.clientid = this._acr.snapshot.queryParamMap.get('clientid');
		}

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this._ss.CustomerInformation.HasMultipleCompanies !== '0') {
			this.HasMultipleCompanys = Number(this._ss.CustomerInformation.HasMultipleCompanies) + 1;
		}

		if (this.HasMultipleCompanys > 1) {
			await new Promise<void>(resolve => {
				this._ss.GetCompaniesWithoutAgent().subscribe({
					next: res => {
						if (typeof res !== 'string' && res.recordset && res.recordset.length) {
							this.ShowCompanys = res.recordset;
							localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
						} else {
							this.ShowCompanys = [{ Company: 1, CompanyName: this._ss.CustomerInformation.CustName }];
						}
						resolve();
					},
					error: err => {
						resolve();
					},
				});
			});

			if (!this.ShowCompanys) {
				if (localStorage.getItem('ShowCompanys') !== null) {
					this.ShowCompanys = localStorage.getItem('ShowCompanys');
					this.ShowCompanys = JSON.parse(this.ShowCompanys);
					this.ShowCompanys.sort((a, b) => a.Company - b.Company);
				} else {
					this._ss.GetComppanys().subscribe(res => {
						this.ShowCompanys = res;
						this.ShowCompanys = this.ShowCompanys.recordset;
						this.ShowCompanys.sort((a, b) => a.Company - b.Company);
						localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
					});
				}
			}
		} else {
			this.ShowCompanys = [{ Company: 1, CompanyName: this._ss.CustomerInformation.CustName }];
		}

		this._agentGvia.AgentsControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: val => {
				console.log(val);
				if (val.length) {
					this.AgentsSalesAll$.next(this.AgentsSalesAll.filter(ag => val.includes(ag.OwnerAgentID + ' - ' + ag.OwnerAgentName)));
				} else {
					this.AgentsSalesAll$.next(this.AgentsSalesAll);
				}

				this.SearchClient();
			},
		});

		this._agentGvia.colorsControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: val => {
				console.log(val);
				if (val.length) {
					this.AgentsSalesAll$.next(this.AgentsSalesAll.filter(ag => val.includes(ag.DebtColor)));
				} else {
					this.AgentsSalesAll$.next(this.AgentsSalesAll);
				}

				this.SearchClient();
			},
		});

		this._ss.EachClientEachHov = null;
		this._ss.OneClientInfo = null;

		if (this._ss.CustomerInformation.Submanagers == '1') {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res == 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							return;
						}

						this.agentsToGet = res.recordset.map(obj => obj.Agent);
						this.agentsToGet.push(this._ss.AgentIdConnected);

						this._ss.agentsOfSubmanager = this.agentsToGet;

						this.getFirstPage();
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				this.agentsToGet = this._ss.agentsOfSubmanager;
				this.getFirstPage();
			}
		} else {
			this.getFirstPage();
		}
	}

	ngOnDestroy(): void {
		this._ss.openDohotThirdOnAgent = this._ss.openDohotFourthOnAgent = undefined;

		this.destroy$.next();
		this.destroy$.complete();
	}
	//'agentsSalesAllThird' | 'agentsSalesAllFourth' | 'agentsSalesAllFifth'
	refreshSubjects(mode: 'agentsSalesAll' | 'agentsSalesAllSecond') {
		if (mode === 'agentsSalesAll') {
			const arr = this.AgentsSalesAll;
			this.displayTotal$.next(arr.reduce((p, c) => p + c.CustOpenDebt, 0));
			this.displayAverage$.next(arr.reduce((p, c) => p + c.CustOpenDebt, 0) / arr.length);
			return;
		}

		if (mode === 'agentsSalesAllSecond') {
			const arr = this.AgentsSalesAllSecond;
			if (!arr) return;
			this.displayTotal$.next(arr.reduce((p, c) => p + c.DebtDocOpenToPay, 0));
			this.displayAverage$.next(arr.reduce((p, c) => p + c.DebtDocOpenToPay, 0) / (arr.length || 1));
			return;
		}
	}

	public BackToAdmin() {
		history.back();
		// this.First = true;
		// this.Second = false;

		// this.refreshSubjects('agentsSalesAll');

		// if (this.Third === true) {
		// 	if (this._ss.openDohotThirdOnAgent) {
		// 		history.back();
		// 	}
		// 	this.Second = true;
		// 	this.Third = false;

		// 	this.refreshSubjects('agentsSalesAllSecond');
		// }
	}

	getFirstPage() {
		if (!this.agentsToGet.length) {
			this._ss.GetAllAgents().subscribe(res => {
				if (typeof res == 'string') {
					console.log("Couldn't get agents");
					return;
				}

				this.agentsFromV_Users = res.recordset;
			});
		}

		if (this.AgentsSalesAll.length && this.AgentsSalesAllResponse.length && this.AgentsSalesAllSecond.length && Object.keys(this.AgentsSalesAllSecondObject).length) {
			this.SearchClient();
			this.loading = false;
			this.refreshSubjects('agentsSalesAll');

			if (this.clientid) {
				this.SecondPageAdminAgentsSales(this.clientid);
			}
			return;
		}

		if (!environment.production) {
			this.handlePopulateFirstPage(mockResponse as SQLResponse<Gvia>);
			return;
		}

		this._ss.getAgentGvia<Gvia>().subscribe({
			next: res => {
				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלת שרת, אנא נסו שנית מאוחר יותר');
					return;
				}
				this.handlePopulateFirstPage(res);
			},
			error: err => {
				alert('ישנה תקלה בשרת');
				history.back();
				this.loading = false;
			},
		});
	}

	handlePopulateFirstPage(res: SQLResponse<Gvia>) {
		this._agentGvia.AgentsSalesAllResponse = this.AgentsSalesAllResponse = res.recordset;
		this._agentGvia.AgentsSalesAll = this.AgentsSalesAll = Object.values(
			this.AgentsSalesAllResponse.reduce((prev, curr) => {
				const clientid = curr.DebtCustCode;
				const existing = prev[clientid];

				this.AgentsSalesAllSecondObject[clientid] ??= [];
				this.AgentsSalesAllSecondObject[clientid].push(curr);

				if (existing) {
					if (curr.DebtColor === DebtColor.green && existing.DebtColor !== DebtColor.purple && existing.DebtColor !== DebtColor.red) {
						existing.DebtColor = DebtColor.green;
					}

					if (curr.DebtColor === DebtColor.purple && existing.DebtColor !== DebtColor.red) {
						existing.DebtColor = DebtColor.purple;
					}

					if (curr.DebtColor === DebtColor.red) {
						existing.DebtColor = DebtColor.red;
					}

					return prev;
				} else {
					prev[clientid] = { ...curr };
				}

				return prev;
			}, {}),
		);

		const obj = { red: 2, purple: 1, green: 0 };

		this.AgentsSalesAll.sort((a, b) => obj[b.DebtColor] - obj[a.DebtColor]);

		this._agentGvia.AgentsSalesAllSecondObject = this.AgentsSalesAllSecondObject;

		this.AgentsSalesAll$.next(this.AgentsSalesAll);

		for (const ag of this.AgentsSalesAll) {
			this._agentGvia.Agents.add(ag.OwnerAgentID + ' - ' + ag.OwnerAgentName);
		}

		this.loading = false;

		this.refreshSubjects('agentsSalesAll');

		console.log({ all: this.AgentsSalesAll, allsecond: this.AgentsSalesAllSecond, allsecondobj: this.AgentsSalesAllSecondObject, allres: this.AgentsSalesAllResponse });
	}

	public SecondPageAdminAgentsSales(clientid) {
		this.AgentsSalesAllSecond = this._agentGvia.AgentsSalesAllSecond = this.AgentsSalesAllSecondObject[clientid];
		this.clientName = this.AgentsSalesAllSecond[0].Client_Name;
		if (this.navigate$) {
			this.navigate$.unsubscribe();
		}
		if (!this.clientid) this.router.navigate(['agent-gvia'], { queryParams: { clientid } });
		this.First = false;
		this.Second = true;
		this.refreshSubjects('agentsSalesAllSecond');
		this.navigate$ = this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEvent) => {
			if (event instanceof NavigationStart) {
				if (this.Second) {
					this.First = true;
					this.Second = false;

					this.clientid = null;

					this.refreshSubjects('agentsSalesAll');
					if (this.navigate$) {
						this.navigate$.unsubscribe();
					}
				}
			}
		});
	}

	selectedAgent = null;
	agentName = '';

	public ThirdPageAdminAgentsSales(ag, client, docnum, doctype) {
		// if (!environment.production) {
		// 	this._ss.currentlyChosenDebt = debtResult[0];

		// 	this._ss.EachClientEachHov = debtResult;

		// 	this._ss.OneClientInfo = {};

		// 	this.router.navigate(['client-hovot-each']);
		// 	return;
		// }
		this._ss.SeeOneHovTeudaService(client, docnum, ag, doctype).subscribe(
			(res: any) => {
				// console.log(res);

				if (res === 'ConnectionError' || res === 'RequestError') {
					alert('ישנה תקלה בשרת, לא היה ניתן להביא פירוט חובות');
					return;
				}

				this._ss.currentlyChosenDebt = res.recordset[0];

				this._ss.EachClientEachHov = res.recordset;

				this._ss.OneClientInfo = {};

				this.router.navigate(['client-hovot-each']);
			},
			err => {
				alert('ישנה תקלה בשרת, לא היה ניתן להביא פירוט חובות');
			},
		);
	}

	resetAgentsAndColors() {
		this._agentGvia.AgentsControl.reset();
		this._agentGvia.colorsControl.reset();

		this.SearchClient();
	}

	Loading = false;
	clientName = '';

	SearchClear() {
		this.AgentsSalesAll$.next(this.AgentsSalesAll);
		this.filterResults();

		this.refreshSubjects('agentsSalesAll');

		this._agentGvia.searchControl.reset();
	}

	filterResults() {
		if (this._agentGvia.colorsControl.value.length) {
			this.AgentsSalesAll$.next(this.AgentsSalesAll$.value.filter(ag => this._agentGvia.colorsControl.value.includes(ag.DebtColor)));
		}
		if (this._agentGvia.AgentsControl.value.length) {
			this.AgentsSalesAll$.next(this.AgentsSalesAll$.value.filter(ag => this._agentGvia.AgentsControl.value.includes(ag.OwnerAgentID + ' - ' + ag.OwnerAgentName)));
		}
	}

	SearchClient() {
		if (!this._agentGvia.searchControl.value) {
			this.SearchClear();
			this.filterResults();
			return;
		}
		const pattern = new RegExp(this._agentGvia.searchControl.value.replace(/[\/*\+\-\?,]/g, ''), 'i');

		this.AgentsSalesAll$.next(this.AgentsSalesAll.filter(client => client.Client_Name.match(pattern)?.length || client.DebtCustCode.match(pattern)?.length));
		this.filterResults();

		this.refreshSubjects('agentsSalesAll');
	}

	openedCompanyIndex = null;

	translateForSort(value: typeof valuesObject[typeof screen][number], screen: keyof typeof valuesObject) {
		let i: number, arrayToSort: any[];

		if (screen === 'First') {
			arrayToSort = this.AgentsSalesAll;
		}

		if (screen === 'Second') {
			arrayToSort = this.AgentsSalesAllSecond;
		}

		i = valuesObject[screen].findIndex(arr => arr === value);

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}

	downloadCSV() {
		const dataObject: ExcelObject = { data: [], filename: '', extension: 'xlsx' };

		// const { transform } = new ReturnMonthHebrewPipe();

		const table = document.querySelector('table');
		const title = document.querySelector('.header__title');

		if (table) {
			if (title) {
				dataObject.filename = title.textContent;
			}

			const wb = utils.table_to_book(table, { sheet: dataObject.filename.slice(0, 31), cellStyles: true });

			console.log({ wb });

			if (!wb.Workbook) wb.Workbook = {};
			if (!wb.Workbook.Views) wb.Workbook.Views = [{}];
			wb.Workbook.Views[0].RTL = true;

			writeFile(wb, `${dataObject.filename}.${dataObject.extension}`, { bookType: dataObject.extension, compression: true, cellStyles: true });
			return;
		}
	}

	formatColumn(worksheet, col, fmt) {
		const range = utils.decode_range(worksheet['!ref']);
		// note: range.s.r + 1 skips the header row
		for (let row = range.s.r + 1; row <= range.e.r; ++row) {
			const ref = utils.encode_cell({ r: row, c: col });
			if (worksheet[ref] && worksheet[ref].t === 'n') {
				worksheet[ref].z = fmt;
			}
		}
	}
}
