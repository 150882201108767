import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { Subject, BehaviorSubject, fromEvent, takeUntil, debounceTime, distinctUntilChanged, catchError, throwError, map, filter, Observable, combineLatest, startWith } from 'rxjs';
import { LikutDisplayMain, LikutMainSQL, LikutStatus, LikutSubSQL, LikutUsers } from 'src/app/Interfaces/likut';
import {
	PDFTextOptions,
	TruckDistributionMainSQL,
	TruckDistributionNotFulfilled,
	TruckDistributionPODSupplied,
	TruckDistributionSubSQL,
	TruckDistributionTaskDisplay,
} from 'src/app/Interfaces/truck-distribution';
import { TruckLoadingAssignmentTable, TruckLoadingDisplay, TruckLoadingMainSQL, TruckLoadingUsers } from 'src/app/Interfaces/truck-loading';
import { LikutService } from 'src/app/services/likut.service';
import { LoadingService } from 'src/app/services/loading.service';
import { StoreService } from 'src/app/services/store.service';
import { TextService } from 'src/app/services/text.service';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { TruckLoadingService } from 'src/app/services/truck-loading.service';
import { Time, UtilService } from 'src/app/services/util.service';
import { genericSearchingFunction, genericSearchingFunctionWithoutNullChecks } from 'src/app/Utility/functions';
import { environment } from 'src/environments/environment';
import { TEUDA_TYPES } from '../clients/clients.component';
import truckDistributionMock from './truckDistributionSuppliedMock.json';
import truckDistributionSuppliedTaskMock from './truckDistributionSuppliedTaskMock.json';

type LikutDisplayNewRoute = {
	teudot: string[];
	TeudaNum: string;
	Client_Name: string;
	Client_Id: string;
	User_Name: string;
	LikutDate: string;
	LikutStatus: LikutStatus;
	TotalLines: number;
	areAllQuantitiesCollected: boolean;
	TeudaCreateDate: string;
	TeudaSupplyDate: string;
	TuedaErpDocNum?: string;
	AreaName?: string;
};

type NewStatusFromNull = 'activate' | 'cancel';

@Component({
	selector: 'app-likut-reports',
	templateUrl: './likut-reports.component.html',
	styleUrls: ['./likut-reports.component.scss'],
})
export class LikutReportsComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		private _router: Router,
		private _fb: FormBuilder,
		public _likut: LikutService,
		public _truckLoading: TruckLoadingService,
		private _loading: LoadingService,
		private _snackbar: MatSnackBar,
		public _text: TextService,
		public _util: UtilService,
		public _truckDistribution: TruckDistributionService,
	) {}

	minDate = new Date();

	form = this._fb.nonNullable.group({
		FromDate: ['', Validators.required],
		ToDate: ['', [Validators.required, Validators.min(this.minDate.getTime())]],
		Agent: [<string[]>[]],
		Status: [<string[]>[]],
		areas: [<string[]>[]],
		ClientSearch: [''],
	});

	agentsToFilter = [];
	statusesToFilter = [];
	areasToFilter = [];

	agentsToAssign: LikutUsers[] = [];

	Admin;

	windowWidth: number;
	history = history;

	showFilterPopup = false;

	destroy$: Subject<void> = new Subject();

	AllClients: Record<string, { Client_Name: string; Client_Id: string }> = {};

	isLikut = location.pathname.includes('likut');
	isTruckLoading = location.pathname.includes('truck-loading');
	isTruckDistribution = location.pathname.includes('truck-distribution');

	AllProducts;
	isManageSpecificStatuses = location.pathname.includes('-part');
	isAssignmentOfLikut = location.pathname.includes('-assignment');
	isNotFulfilled = location.pathname.includes('-not-fulfilled');
	isNoStatus = location.pathname.includes('-no-status');
	isPODSupplied = location.pathname.includes('-supplied');
	isOnlyManage = !this.isAssignmentOfLikut && !this.isNotFulfilled && !this.isNoStatus && !this.isManageSpecificStatuses && !this.isPODSupplied;

	isOnlyAgent$ = new BehaviorSubject(false);

	allTeudotNewRoutes: LikutMainSQL[] = [];
	allTeudotNewRoutesObject$: BehaviorSubject<Record<string, LikutMainSQL[]>> = new BehaviorSubject({});
	teudotDisplayNewRoute$: BehaviorSubject<LikutDisplayNewRoute[]> = new BehaviorSubject([]);

	truckLoadingAllAssignments$: BehaviorSubject<TruckLoadingAssignmentTable[]> = new BehaviorSubject([]);
	truckLoadingAllUsers$: BehaviorSubject<TruckLoadingUsers[]> = new BehaviorSubject([]);

	truckDistributionAllTasks$: BehaviorSubject<TruckDistributionNotFulfilled[]> = new BehaviorSubject([]);
	truckDistributionTasksShown$ = combineLatest([this.truckDistributionAllTasks$, this.form.controls.ClientSearch.valueChanges.pipe(startWith('')), this.isOnlyAgent$]).pipe(
		map(([tasks, inputValue, isOnlyAgent]) => {
			if (isOnlyAgent) {
				tasks = tasks.filter(task => task.AssignTo === this._ss.AgentIdConnected);
			}
			return genericSearchingFunctionWithoutNullChecks(inputValue, tasks, ['ClientName', 'DocNum', 'ClientID', 'AssignTo'], 400);
		}),
	);
	truckDistributionSuppliedTasks$: BehaviorSubject<TruckDistributionPODSupplied[]> = new BehaviorSubject([]);
	truckDistributionSuppliedTasksShown$ = combineLatest([this.truckDistributionSuppliedTasks$, this.form.controls.ClientSearch.valueChanges.pipe(startWith(''))]).pipe(
		map(([tasks, inputValue]: [(TruckDistributionPODSupplied & { CompanyName: string })[], string]) => {
			return genericSearchingFunction(inputValue, tasks, ['ClientName', 'DocNum', 'DocTypeName', 'AssignTo'], 400);
		}),
	);

	scrollY = 0;

	tableHeight: string | number = '';

	openedLikutNewRoute$ = new BehaviorSubject<{ main: LikutMainSQL; sub: LikutSubSQL[] }>(undefined);
	openedTruckLoading$: BehaviorSubject<TruckLoadingMainSQL[]> = new BehaviorSubject([]);
	openedTruckDistrbution$: BehaviorSubject<{ main: TruckDistributionPODSupplied; sub: TruckDistributionSubSQL[] }> = new BehaviorSubject(undefined);
	isLikutViewPopupOpenNewRoutes$ = this.openedLikutNewRoute$.pipe(map(value => value));
	isTruckLoadingPopupOpen$ = this.openedTruckLoading$.pipe(map(value => value.length));
	isTruckDistributionPopupOpen$ = this.openedTruckDistrbution$.pipe(map(value => !!value));

	isChangeStatusesPopupConfirmationOpen$ = new BehaviorSubject(false);
	popupMode$ = new BehaviorSubject<NewStatusFromNull>('activate');

	checkboxState: { [teudaNum: string]: boolean } = {};

	showQuantity = this._ss.CustomerInformation.LikutType === '0' || this._ss.CustomerInformation.LikutType === '1';
	showPackQuantity = this._ss.CustomerInformation.LikutType === '0' || this._ss.CustomerInformation.LikutType === '2';
	showLikutExtra1 = this._ss.CustomerInformation.LikutRemarkExtra1 === '1';

	teudaNumsToChangeStatus: string[] = [];

	PODSuppliedHasCompany = false;

	title = this._text.store.manageTaskReportTitle;

	shouldShowAreaColumnInNoStatus = false;
	@ViewChild('teudotTableNames') rightSideOfPopup: ElementRef<HTMLTableCellElement>;

	shouldShowPDFPopup$ = new BehaviorSubject(false);
	pdfSRC$ = new BehaviorSubject('');
	// PDFURL$ = new BehaviorSubject<string>('');
	currentPage$ = new BehaviorSubject(1);
	// moreThanOnePage$ = this.PDFURL$.pipe(map(array => array.length > 1));
	// textToRender$ = combineLatest([this.currentPage$, this.PDFURL$]).pipe(
	// 	map(([currentPage, pdfArray]) => {
	// 		const text: PDFTextOptions[] = [];
	// 		if (!pdfArray.length) return text;
	// 		const { sub, task } = this._truckDistribution.truckDistributionOpened;

	// 		const relevantTeuda = Object.values(task.teudot).find(teuda => teuda.OrgPDF === pdfArray);

	// 		console.log(pdfArray, relevantTeuda);

	// 		if (relevantTeuda.Extra5 && this._truckDistribution.parametersRecord.considerExtra5) {
	// 			text.push({ id: 'extra5-text', left: 0.5, top: 0.07, text: relevantTeuda.Extra5 });
	// 		}

	// 		const anyChanges = sub.filter(line => line.DocNum === relevantTeuda.DocNum && (line.PackQTY !== line.PODPackQTY || line.Quantity !== line.PODQuantity));

	// 		if (anyChanges.length) {
	// 			let finalText = '';

	// 			for (const change of anyChanges) {
	// 				finalText += `פריט ${change.ProductID} - סופקה כמות ${change.PODQuantity} (${change.PODPackQTY} אריזות)<br/>`;
	// 			}
	// 			text.push({ id: 'changes-text', top: 0.75, text: finalText, size: 8, right: 0.075 });
	// 		}

	// 		return text;
	// 	}),
	// );

	async ngOnInit() {
		this._ss.OneClientInfoDebt = null;

		if (location.pathname.includes('only-agent')) {
			this.isOnlyAgent$.next(true);
		}

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this._router.navigate(['login']);
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		this.windowWidth = window.innerWidth;

		if (this._ss.isLocalhost) {
			fromEvent(window, 'resize')
				.pipe(takeUntil(this.destroy$))
				.subscribe((evt: any) => {
					this.windowWidth = evt.currentTarget.innerWidth;
				});
		}

		this.handleTitle();
		this.overrideTableHeaders();
		window.scrollTo(0, 0);

		this.initiaiteSubscriptions();

		await this.updateTasks();

		this.form.controls.FromDate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.form.controls.ToDate.reset();

			this.minDate = new Date(val);
		});

		setTimeout(() => {
			const [header, filterRow] = document.querySelectorAll('header, .filter-row');

			let height = header.getBoundingClientRect().height;

			if (filterRow) {
				height += filterRow.getBoundingClientRect().height;
			}

			// zoom: 1.2
			if (this.windowWidth > 767 && this.windowWidth < 1099) {
				height *= 2.2;
			}
			this.tableHeight = height + 'px';
		}, 0);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		document.body.classList.remove('no-scroll');
		this._loading.changeLoadingStatuses({ isLoading: false });
	}

	shouldClientCardPopupShow = false;

	openClientCardPopup(teuda) {
		// this.scrollY = scrollY;
		// const client = this.allClients.find(ac => ac.Client_Name == teuda.Client_Name);
		// if (client) {
		// 	document.body.classList.add('no-scroll');
		// 	this.shouldClientCardPopupShow = true;
		// 	this._ss.OneClientInfo = client;
		// 	setTimeout(() => {
		// 		window.scrollTo({ top: this.scrollY });
		// 	}, 0);
		// }
	}

	handleChangeLikutPopup(stateToChange: 'open' | 'close') {
		if (stateToChange === 'open') {
			// this.openedLikut$.next(likut);
			document.body.classList.add('no-scroll');

			setTimeout(() => {
				this.rightSideOfPopup.nativeElement.scrollIntoView();
			}, 0);
		}
		if (stateToChange === 'close') {
			this.openedTruckLoading$.next([]);
			this.openedLikutNewRoute$.next(undefined);
			this.openedTruckDistrbution$.next(undefined);
			document.body.classList.remove('no-scroll');
		}
	}

	handleGetLikutDetails(task: LikutDisplayNewRoute) {
		const teudanumArray = [task.TeudaNum];
		const callbacks = {
			next: res => {
				if (typeof res === 'string') {
					alert(this._text.store.taskConnectionError);
					this._router.navigate(['home'], { replaceUrl: true });
					return;
				}

				this.openedLikutNewRoute$.next({ sub: res.recordset, main: this.allTeudotNewRoutesObject$.value[task.TeudaNum][0] });
				document.body.classList.add('no-scroll');

				setTimeout(() => {
					this.rightSideOfPopup.nativeElement.scrollIntoView();
				}, 0);
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		};
		if (this.isOnlyManage) {
			this.pipeADestroyer(this._ss.getLikutByTeudaNumSubHistory(teudanumArray)).subscribe(callbacks);
			return;
		}
		this.pipeADestroyer(this._ss.getLikutByTeudaNumSub(teudanumArray)).subscribe(callbacks);
	}

	handleGetTruckDistributionDetails(task: TruckDistributionPODSupplied) {
		// if (this._ss.isLocalhost) {
		// 	//@ts-ignore
		// 	this.openedTruckDistrbution$.next({ main: task, sub: truckDistributionSuppliedTaskMock });
		// 	document.body.classList.add('no-scroll');
		// 	setTimeout(() => {
		// 		this.rightSideOfPopup.nativeElement.scrollIntoView();
		// 	}, 0);
		// 	return;
		// }
		this.pipeADestroyer(this._truckDistribution.getTruckDistributionPODSuppliedTaskForReport(task.DocNum)).subscribe({
			next: res => {
				if (typeof res === 'string') {
					alert(this._text.store.taskConnectionError);
					this._router.navigate(['home'], { replaceUrl: true });
					return;
				}

				this.openedTruckDistrbution$.next({ main: task, sub: res.recordset });
				document.body.classList.add('no-scroll');
				setTimeout(() => {
					this.rightSideOfPopup.nativeElement.scrollIntoView();
				}, 0);
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
	}

	handleGetTruckLoadingWaveDetails(wave: string) {
		this.pipeADestroyer(this._truckLoading.getAllTruckLoadingTasksForMain('assignment', wave)).subscribe({
			next: res => {
				if (typeof res === 'string') {
					alert(this._text.store.taskConnectionError);
					this._router.navigate(['home'], { replaceUrl: true });
					return;
				}

				this.openedTruckLoading$.next(res.recordset);
				document.body.classList.add('no-scroll');

				setTimeout(() => {
					this.rightSideOfPopup.nativeElement.scrollIntoView();
				}, 0);
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
	}

	dateInvalid = false;

	handleDateChange(val) {
		if (val == 'from') {
			this.dateInvalid = this.form.controls.FromDate.invalid || this.form.controls.ToDate.invalid;
			console.log({ dateInvalid: this.dateInvalid, from: this.form.controls.FromDate.invalid, to: this.form.controls.ToDate.invalid });
		} else {
			if (!this.form.controls.FromDate.value || new Date(this.form.controls.FromDate.value).getTime() > new Date(this.form.controls.ToDate.value).getTime()) {
				const msg = !this.form.controls.FromDate.value ? "ערך 'מתאריך' אינו תקין" : "ערך 'עד תאריך' אינו יכול להיות קטן מערך 'מתאריך'";
				alert(msg);
				this.dateInvalid = true;
				this.form.controls.ToDate.reset();
			} else {
				this.dateInvalid = false;
			}
		}
	}

	handleAdvancedSearch() {
		this.refreshSubjectNewLikutRoutes('teudotDisplay');
		this.showFilterPopup = false;
	}

	Restart() {
		window.location.reload();
	}

	checkAllOrders() {
		const checkboxes = [...document.querySelectorAll('input[type=checkbox]')] as HTMLInputElement[];

		for (const chkbx of checkboxes) {
			chkbx.click();
		}
	}

	async updateTasks() {
		this._ss.closeMenu();
		await new Promise<void>((resolve, reject) => {
			this._loading.changeLoadingStatuses({ newMessage: `${this._text.store.updatingTasks}...` });

			if (this.isAssignmentOfLikut) {
				this.getAllUnassigned();
				this.overrideTableHeaders('assignment');

				if (this.isTruckLoading) {
					this.getTruckUsers();
					return resolve();
				}
				this.getMelaketUsers();
				return resolve();
			}
			if (this.isNotFulfilled) {
				this.getAllNotFulfilled();

				return resolve();
			}

			if (this.isNoStatus) {
				this.overrideTableHeaders('no-status');
				this.getAllNoStatus();
				return resolve();
			}

			if (this.isManageSpecificStatuses) {
				this.getAllAfterCheckerChecked();
				this.overrideTableHeaders('part');
				return resolve();
			}

			if (this.isPODSupplied) {
				this.getAllPODSupplied();
				return resolve();
			}

			this.pipeADestroyer(this._ss.getAllForLikutReport('all-history')).subscribe({
				next: res => {
					if (typeof res === 'string') return resolve();

					this.allTeudotNewRoutes = res.recordset;

					this.agentsToFilter = [...new Set(this.allTeudotNewRoutes.map(teuda => teuda.MelaketName))];
					this.statusesToFilter = [...new Set(this.allTeudotNewRoutes.map(teuda => teuda.LikutStatus))];

					console.log(res);

					return resolve();
				},
				error: () => {
					alert(this._text.store.taskConnectionError);
					this._router.navigate(['home'], { replaceUrl: true });
				},
			});
		});

		this.buildAllTeudotObject('likutNewRoutes');

		this.refreshSubjectNewLikutRoutes('teudotDisplay');

		this.refreshSubjectNewLikutRoutes('totalLikut');

		return;
	}

	getTruckUsers() {
		this.pipeADestroyer(this._truckLoading.getAllTruckLoadingUsers()).subscribe({
			next: res => {
				if (typeof res === 'string') return;
				this.truckLoadingAllUsers$.next(res.recordset);
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
	}

	getAllUnassigned() {
		if (this.isTruckLoading) {
			this.pipeADestroyer(this._truckLoading.getTruckAssignment()).subscribe({
				next: res => {
					if (typeof res === 'string') return;
					this.truckLoadingAllAssignments$.next(res.recordset);
				},
				error: () => {
					alert(this._text.store.taskConnectionError);
					this._router.navigate(['home'], { replaceUrl: true });
				},
			});

			return;
		}

		this.pipeADestroyer(this._ss.getAllForLikutReport('unassigned')).subscribe({
			next: res => {
				if (typeof res === 'string') return;
				this.allTeudotNewRoutes = res.recordset;

				this.buildAllTeudotObject('likutNewRoutes');

				this.refreshSubjectNewLikutRoutes('teudotDisplay');

				this.refreshSubjectNewLikutRoutes('totalLikut');
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
		return;
	}

	getAllNotFulfilled() {
		if (this.isTruckDistribution) {
			this.pipeADestroyer(this._truckDistribution.getTruckDistributionTasksNotFulfilled()).subscribe({
				next: res => {
					if (typeof res === 'string') return;
					this.truckDistributionAllTasks$.next(res.recordset.map(task => ({ ...task, DocTypeName: task.DocTypeName || '' })));
				},
				error: () => {
					alert(this._text.store.taskConnectionError);
					this._router.navigate(['home'], { replaceUrl: true });
				},
			});
			return;
		}
		this.pipeADestroyer(this._ss.getAllForLikutReport('not-fulfilled')).subscribe({
			next: res => {
				if (typeof res === 'string') return;
				this.allTeudotNewRoutes = res.recordset;

				this.buildAllTeudotObject('likutNewRoutes');

				this.refreshSubjectNewLikutRoutes('teudotDisplay');

				this.refreshSubjectNewLikutRoutes('totalLikut');
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
		return;
	}

	getAllNoStatus() {
		this.pipeADestroyer(this._ss.getAllForLikutReport('no-status')).subscribe({
			next: res => {
				if (typeof res === 'string') return;
				this.allTeudotNewRoutes = res.recordset;

				this.determineIfShouldShowAreaColumn();

				this.buildAllTeudotObject('likutNewRoutes');

				this.refreshSubjectNewLikutRoutes('teudotDisplay');

				this.refreshSubjectNewLikutRoutes('totalLikut');
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
		return;
	}

	getMelaketUsers() {
		this.pipeADestroyer(this._ss.getAllLikutUsers()).subscribe({
			next: res => {
				if (typeof res === 'string') return;

				this.agentsToAssign = res.recordset;
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
	}

	getAllAfterCheckerChecked() {
		this.pipeADestroyer(this._ss.getAllForLikutReport('all-after-checker')).subscribe({
			next: res => {
				if (typeof res === 'string') return;
				this.allTeudotNewRoutes = res.recordset;

				this.buildAllTeudotObject('likutNewRoutes');

				this.refreshSubjectNewLikutRoutes('teudotDisplay');

				this.refreshSubjectNewLikutRoutes('totalLikut');
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
	}

	getAllPODSupplied() {
		// if (this._ss.isLocalhost) {
		// 	this.PODSuppliedHasCompany = true;
		// 	this.truckDistributionSuppliedTasks$.next(truckDistributionMock);
		// 	console.log(truckDistributionMock);
		// 	return;
		// }
		this.pipeADestroyer(this._truckDistribution.getTruckDistributionPODSupplied()).subscribe({
			next: res => {
				if (typeof res === 'string') return;

				this.PODSuppliedHasCompany = res.recordset.length && 'CompanyName' in res.recordset[0];

				this.truckDistributionSuppliedTasks$.next(res.recordset.map(task => ({ ...task, CompanyName: task.CompanyName || '' })));
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
				this._router.navigate(['home'], { replaceUrl: true });
			},
		});
	}

	refreshSubjectNewLikutRoutes(type: 'teudotDisplay' | 'totalLikut') {
		const agentsSet = new Set<string>();
		const statusesSet = new Set<string>();
		const areasSet = new Set<string>();
		const day = Time.hour * 24;

		const values = Object.values(this.allTeudotNewRoutesObject$.value).map(teudot => {
			const innerValues = teudot[0];
			const {
				Client_Id,
				LikutStatus,
				Client_Name,
				LikutDate,
				MelaketName: User_Name,
				TeudaNum,
				TotalLines,
				TeudaCreateDate,
				TeudaSupplyDate,
				QuantitiesMatch,
				TuedaErpDocNum,
				AreaName,
			} = innerValues;

			agentsSet.add(User_Name);
			statusesSet.add(LikutStatus);

			if (AreaName) {
				areasSet.add(AreaName);
			}

			const finalValues: {
				Client_Id: string;
				LikutStatus: LikutStatus;
				Client_Name: string;
				LikutDate: string;
				User_Name: string;
				TeudaNum: string;

				TotalLines: number;
				teudot: string[];
				areAllQuantitiesCollected: boolean;
				TeudaCreateDate: string;
				TeudaSupplyDate: string;
				TuedaErpDocNum: string;
				AreaName?: string;
			} = {
				Client_Id,
				LikutStatus,
				Client_Name,
				LikutDate,
				User_Name,
				TeudaNum,
				TotalLines,
				teudot: [...new Set(teudot.map(t => t.TeudaNum))],
				areAllQuantitiesCollected: !!QuantitiesMatch,
				TeudaCreateDate,
				TeudaSupplyDate,
				TuedaErpDocNum: TuedaErpDocNum || '',
			};

			if (AreaName) {
				finalValues['AreaName'] = AreaName;
			}

			return finalValues;
		});

		this.agentsToFilter = [...agentsSet];
		this.statusesToFilter = [...statusesSet].sort((a, b) => +a - +b);
		this.areasToFilter = [...areasSet];

		if (type === 'teudotDisplay') {
			const clientSearchValue: string = this.form.controls['ClientSearch'].value?.toLowerCase?.();
			const len = clientSearchValue?.length;
			const { FromDate, ToDate, Agent, Status, areas } = this.form.value;

			const filteredValues = (
				!len
					? values
					: values.filter(teuda => {
							if (len && len <= 2) {
								if (
									(teuda.Client_Name ?? '').toLowerCase().slice(0, len) === clientSearchValue ||
									(teuda.Client_Id ?? '').toLowerCase().slice(0, len) === clientSearchValue ||
									(teuda.TuedaErpDocNum ?? '').toLowerCase().slice(0, len) === clientSearchValue ||
									(teuda.TeudaNum ?? '').toLowerCase().slice(0, len) === clientSearchValue
								) {
									return true;
								}
								return false;
							}
							if (len > 2) {
								const splitValue = clientSearchValue.split(' ');

								let counter = 0;

								const clientNameWithoutSpaces = (teuda.Client_Name || '').replace(/\s/g, '').toLowerCase(),
									clientidWitoutSpaces = (teuda.Client_Id || '').replace(/\s/g, '').toLowerCase(),
									tuedaErpDocNumWitoutSpaces = (teuda.TuedaErpDocNum || '').replace(/\s/g, '').toLowerCase(),
									teudaNum = teuda.TeudaNum.toLowerCase();

								if (typeof clientNameWithoutSpaces === 'string' && typeof tuedaErpDocNumWitoutSpaces === 'string' && typeof clientidWitoutSpaces === 'string' && typeof teudaNum === 'string') {
									for (let j = 0; j < splitValue.length; j++) {
										if (
											(clientNameWithoutSpaces && clientNameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
											(clientidWitoutSpaces && clientidWitoutSpaces.indexOf(splitValue[j]) > -1) ||
											(tuedaErpDocNumWitoutSpaces && tuedaErpDocNumWitoutSpaces.indexOf(splitValue[j]) > -1) ||
											(teudaNum && teudaNum.indexOf(splitValue[j]) > -1)
										) {
											counter++;
										}
									}
								}

								if (counter === splitValue.length) {
									return true;
								}

								return false;
							}

							return true;
					  })
			)
				.filter(value => {
					if (this.isNoStatus) {
						if (
							(FromDate &&
								ToDate &&
								!(
									(new Date(value.TeudaCreateDate).getTime() >= new Date(FromDate).getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds &&
										new Date(value.TeudaCreateDate).getTime() <= new Date(ToDate).getTime() + day - new Date().getTimezoneOffset() * Time.minuteInMilliseconds) ||
									(new Date(value.TeudaSupplyDate).getTime() >= new Date(FromDate).getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds &&
										new Date(value.TeudaSupplyDate).getTime() <= new Date(ToDate).getTime() + day - new Date().getTimezoneOffset() * Time.minuteInMilliseconds)
								)) ||
							(areas.length && !areas.includes(value.AreaName))
						)
							return false;
						return true;
					}
					if (
						(Agent.length && !Agent.includes(value.User_Name)) ||
						(Status.length && !Status.includes(value.LikutStatus)) ||
						(FromDate &&
							ToDate &&
							!(
								new Date(value.LikutDate).getTime() >= new Date(FromDate).getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds &&
								new Date(value.LikutDate).getTime() <= new Date(ToDate).getTime() + day - new Date().getTimezoneOffset() * Time.minuteInMilliseconds
							))
					)
						return false;
					return true;
				})
				.reduce((p, c) => {
					p[c.TeudaNum] = c;
					return p;
				}, {});

			this.teudotDisplayNewRoute$.next(
				this.isNoStatus
					? values
							.filter(cl => {
								return filteredValues[cl.TeudaNum];
							})
							.sort((a, b) => {
								// order by TeudaCreateDate, TeudaSupplyDate
								const num = +new Date(a.TeudaCreateDate) - +new Date(b.TeudaCreateDate);

								if (!num) {
									return +new Date(a.TeudaSupplyDate) - +new Date(b.TeudaSupplyDate);
								}

								return num;
							})
					: values
							.filter(cl => {
								return filteredValues[cl.TeudaNum];
							})
							.sort((a, b) => {
								// order by LikutStatus, LikutDate
								const num = +a.LikutStatus - +b.LikutStatus;

								if (!num) {
									return +new Date(a.LikutDate) - +new Date(b.LikutDate);
								}

								return num;
							}),
			);
		}

		if (type === 'totalLikut') {
			// const filtered = values.filter(cl => displayTheseStatusesSet.has(cl.LikutStatus));
			// // this.totalLikut$.next(filtered.length);
			// const completedStatusArray = new Set();
			// if (this._likut.isMelaketAgent) {
			// 	completedStatusArray.add('2');
			// }
			// if (this._likut.isCheckerAgent) {
			// 	completedStatusArray.add('3');
			// }
			// // this.totalLikutCompleted$.next(filtered.filter(likut => completedStatusArray.has(likut.status)).length);
		}
	}

	resetForm() {
		for (const control of Object.values(this.form.controls)) {
			control.reset();
		}
	}

	handleOpenStatusChangePopup(mode: NewStatusFromNull, teudanum?: string) {
		this.popupMode$.next(mode);
		// if (mode === 'cancel') {
		// 	if (!teudanum) throw 'needs a teudanum';
		// 	this.teudaNumsToChangeStatus = [teudanum];
		// 	this.isChangeStatusesPopupConfirmationOpen$.next(true);
		// 	return;
		// }
		const teudaNums = Object.entries(this.checkboxState).filter(entry => entry[1]);
		if (!teudaNums.length) {
			alert(this._text.store.noStatusChecked);
			return;
		}

		this.teudaNumsToChangeStatus = teudaNums.map(entry => entry[0]);

		this.isChangeStatusesPopupConfirmationOpen$.next(true);
	}

	changeStatusForTheseLikut() {
		this.isChangeStatusesPopupConfirmationOpen$.next(false);
		if (this.isNoStatus) {
			this.changeStatusForTheseLikutFromNoStatus();
			return;
		}

		this.pipeADestroyer(this._ss.patchMainAndTaskForLikut('confirm-checker', this.teudaNumsToChangeStatus)).subscribe({
			next: res => {
				if (typeof res === 'string') return alert(this._text.store.couldntPatch);

				this.teudaNumsToChangeStatus = [];
				this.checkboxState = {};

				this.getAllAfterCheckerChecked();

				this._snackbar.open(this._text.store.taskUpdatedSuccess, 'סגור', {
					direction: 'rtl',
					panelClass: ['snack-bar'],
				});
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
			},
		});
	}

	changeStatusForTheseLikutFromNoStatus() {
		const mode = this.popupMode$.value;
		let endpoint: 'assign-status-from-null-to-activate' | 'assign-status-from-null-to-cancelled';
		if (mode === 'activate') {
			endpoint = 'assign-status-from-null-to-activate';
		}
		if (mode === 'cancel') {
			endpoint = 'assign-status-from-null-to-cancelled';
		}

		this.pipeADestroyer(this._ss.patchMainAndTaskForLikut(endpoint, this.teudaNumsToChangeStatus)).subscribe({
			next: res => {
				if (typeof res === 'string') return alert(this._text.store.couldntPatch);

				this.teudaNumsToChangeStatus = [];
				this.checkboxState = {};

				this.getAllNoStatus();

				this._snackbar.open(this._text.store.taskUpdatedSuccess, 'סגור', {
					direction: 'rtl',
					panelClass: ['snack-bar'],
				});
			},
			error: () => {
				alert(this._text.store.taskConnectionError);
			},
		});
	}

	// assignThisLikutToSelectedUser(ev: MatSelectChange, teuda: LikutDisplay) {
	// 	const user = ev.source.value;
	// 	const teudanum = teuda.TeudaNum;

	// 	this.pipeADestroyer(this._ss.assignTeuda(teudanum, user)).subscribe({
	// 		next: res => {
	// 			if (typeof res === 'string') return alert(this._text.store.couldntPatch);

	// 			this.getAllUnassigned();

	// 			this._snackbar.open(this._text.store.taskUpdatedSuccess, 'סגור', {
	// 				direction: 'rtl',
	// 				duration: 3000,
	// 				panelClass: ['snack-bar'],
	// 			});
	// 		},
	// 		error: () => {
	// 			alert(this._text.store.couldntPatch);
	// 		},
	// 	});
	// }

	assignLikutNewRoute(ev: MatSelectChange, teudanum: string) {
		const user = ev.source.value;
		this.pipeADestroyer(this._ss.assignLikutToUserNewRoute(teudanum, user)).subscribe({
			next: res => {
				if (typeof res === 'string') return alert(this._text.store.couldntPatch);

				this.getAllUnassigned();

				this._snackbar.open(this._text.store.taskUpdatedSuccess, 'סגור', {
					direction: 'rtl',
					duration: 3000,
					panelClass: ['snack-bar'],
				});
			},
			error: () => {
				alert(this._text.store.couldntPatch);
			},
		});
	}

	assignNewTruckLoading(ev: MatSelectChange, Extra4: string) {
		const AssignTo = ev.source.value;
		this.pipeADestroyer(this._truckLoading.assignTaskToUser({ AssignTo, Extra4 })).subscribe({
			next: res => {
				if (typeof res === 'string') return alert(this._text.store.couldntPatch);

				this.getAllUnassigned();

				this._snackbar.open(this._text.store.taskUpdatedSuccess, 'סגור', {
					direction: 'rtl',
					duration: 3000,
					panelClass: ['snack-bar'],
				});
			},
			error: () => {
				alert(this._text.store.couldntPatch);
			},
		});
	}

	ClearClientSearch() {
		this.resetForm();
		if (this.isTruckDistribution) return;
		this.refreshSubjectNewLikutRoutes('teudotDisplay');
	}

	SearchClient() {
		if (this.isTruckDistribution) return;
		this.refreshSubjectNewLikutRoutes('teudotDisplay');
	}

	buildAllTeudotObject(mode: 'likutNewRoutes') {
		this.allTeudotNewRoutesObject$.next(
			this.allTeudotNewRoutes.reduce((p, c) => {
				p[c.TeudaNum] ||= [];
				p[c.TeudaNum].push(c);
				return p;
			}, {}),
		);
	}

	handleTitle() {
		if (this.isManageSpecificStatuses) {
			this.title = this._text.store.manageCertainStatusReportTitle;
			return;
		}

		if (this.isAssignmentOfLikut) {
			this.title = this._text.store.taskAssignmentReport;
			return;
		}

		if (this.isNotFulfilled) {
			if (this.isTruckDistribution) {
				if (this.isOnlyAgent$.value) {
					this.title = `דוח משימות`;
					return;
				}
				this.title = this._text.store.manageNotFulfilledTruckDistribution;
				return;
			}
			this.title = this._text.store.manageNotFulfilled;
			return;
		}
		if (this.isNoStatus) {
			this.title = this._text.store.manageNoStatus;
		}

		if (this.isPODSupplied) {
			this.title = this._text.store.manageSuppliedTruckDistribution;
		}
	}

	overrideTableHeaders(to: 'default' | 'no-status' | 'part' | 'assignment' = 'default') {
		const mapArrayToHeader = (arr: string[]): typeof this._text.store.manageReportTableHeaders1 => arr.map(text => ({ text, element: ['אשר', 'קובץ'].find(t => t === text) ? 'button' : 'th' }));
		if (to === 'default') {
			this._text.store.manageReportTableHeaders1 = mapArrayToHeader(['תעודה', 'שם לקוח', 'שם מלקט', 'תאריך', 'שעה', 'שורות', 'סטטוס']);
		}
		if (to === 'part') {
			this._text.store.manageReportTableHeaders1 = mapArrayToHeader(['תעודה', 'אסמכתא', 'שם לקוח', 'שם מלקט', 'תאריך', 'שעה', 'שורות', 'סטטוס', 'אשר']);
		}

		if (to === 'no-status') {
			this._text.store.manageReportTableHeaders1 = mapArrayToHeader(['תעודה', 'אסמכתא', 'שם לקוח', 'ת. הזמנה', 'ת. אספקה', 'שורות', 'אשר']);
		}

		if (to === 'assignment') {
			this._text.store.manageReportTableHeaders1 = mapArrayToHeader(['תעודה', 'שם לקוח', 'ת. אספקה', 'שורות', 'בחר מלקט']);
		}
	}

	determineIfShouldShowAreaColumn() {
		this.shouldShowAreaColumnInNoStatus = this.allTeudotNewRoutes.some(teuda => !!teuda.AreaId) || environment.development;
		if (this.shouldShowAreaColumnInNoStatus) {
			this._text.store.manageReportTableHeaders1.splice(2, 0, { text: 'אזור', element: 'th' });
		}
	}

	changePage(action: 'next' | 'previous') {
		// if (action === 'next') {
		// 	this.currentPage$.next(Math.min(this.currentPage$.value + 1, this.PDFArray$.value.length));
		// }
		// if (action === 'previous') {
		// 	this.currentPage$.next(Math.max(this.currentPage$.value - 1, 1));
		// }
	}

	initiaiteSubscriptions() {
		if (!this.isTruckDistribution) return;
		// this.pipeADestroyer(this.currentPage$).subscribe(number => {
		// 	this.pdfSRC$.next(this.PDFArray$.value[number - 1] || '');
		// 	console.log(this.pdfSRC$.value);
		// });
		this.pipeADestroyer(this.shouldShowPDFPopup$).subscribe(boolean => {
			if (boolean) {
				document.body.classList.add('no-scroll');
				return;
			}
			document.body.classList.remove('no-scroll');
		});
	}

	closePDFPopup() {
		this.shouldShowPDFPopup$.next(false);
		this.pdfSRC$.next('');
	}

	openPDFPopup(teuda: TruckDistributionNotFulfilled | TruckDistributionPODSupplied) {
		if (!teuda.OrgPDF) {
			alert('לא נמצא קובץ PDF');
			return;
		}

		this.shouldShowPDFPopup$.next(true);
		const isTeudaPODSupplied = (t): t is TruckDistributionPODSupplied => 'PDFfile' in t;
		if (this.isPODSupplied && isTeudaPODSupplied(teuda)) {
			if (!teuda.PDFfile) {
				this.shouldShowPDFPopup$.next(false);
				alert('לא נמצא קובץ PDF');
				return;
			}
			this.pdfSRC$.next(this._truckDistribution.resolvePDFLink({ filename: teuda.PDFfile, mode: 'PDFfile' }));
			return;
		}
		this.pdfSRC$.next(this._truckDistribution.resolvePDFLink({ filename: teuda.OrgPDF, mode: 'OrgPDF' }));
	}

	// setupTableData() {}

	pipeADestroyer<T>(storeServiceMethod: Observable<T>) {
		return this._util.pipeASubjectDestoryer(storeServiceMethod, this.destroy$);
	}
}
