import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { ReportDashboardItemInput } from '../report-dashboard-item/report-dashboard-item.component';

@Component({
	selector: 'app-dohotmotzarim',
	templateUrl: './dohotmotzarim.component.html',
	styleUrls: ['./dohotmotzarim.component.scss'],
})
export class DohotmotzarimComponent extends BaseComponent implements OnInit {
	history = history;

	logo = MOBILITY_LOGO;

	items: ReportDashboardItemInput[] = [
		{ text: 'ריכוז מלאי', icon: 'products', path: 'dohot-motzarim-mlay' },
		{ text: '(לקוח- מוצרים) דו"ח ', icon: 'products', path: 'doh-mechira' },
		{ text: '(מוצר - לקוחות) דו"ח ', icon: 'products', path: 'dohot-motzarim-reverse' },

	];

	constructor(public router: Router, public _fb: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		window.scrollTo(0, 0);
	}
	public MoveToClients() {
		this.router.navigate(['clients']);
	}

	public BackToHome() {
		this.router.navigate(['home']);
	}

	public BackToDohot() {
		this.router.navigate(['dohot']);
	}

	public MoveToDohotMotzarimmlay() {
		this.router.navigate(['dohot-motzarim-mlay']);
	}

	public MoveTodohmechiraReverse() {
		this.router.navigate(['dohot-motzarim-reverse']);
	}

	public MoveTodohmechira() {
		this.router.navigate(['doh-mechira']);
	}

	public MoveToohbakara() {
		this.router.navigate(['doh-bakara']);
	}

	handleNavigation(navPath: string) {
		if (this._ss.CustomerInformation.HideReport) {
		}
	}
	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;
	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}
}
