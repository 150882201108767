import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RALLYANCE_RECLAMATOR_ADMIN } from '../Interfaces/reclamations';
import { AdminTypes, LoginUser } from '../Interfaces/users';
import { LS_KEYS, StorageService } from './storage.service';
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	agentEmail$ = new BehaviorSubject('');
	isTestUser$ = new BehaviorSubject(false);
	isAdmin$ = new BehaviorSubject(false);
	isRallyanceReclamator$ = new BehaviorSubject(false);

	constructor(private _ss: StoreService, private _storage: StorageService) {
		this.handleInit();
	}

	updateEmail(email: string) {
		this.agentEmail$.next(email);
	}

	handleInit() {
		const emailFromStorage = localStorage.getItem('AgentEmail');
		if (emailFromStorage) {
			this.updateEmail(emailFromStorage);
		}

		let admin = this._ss.Admin;
		admin ||= localStorage.getItem('Admin') as AdminTypes | null;
		if (admin) {
			this.isAdmin$.next(admin === 'true');
			this.isRallyanceReclamator$.next(admin.toLowerCase().includes(RALLYANCE_RECLAMATOR_ADMIN));
		}
	}

	updateTestUserState(newValue: boolean) {
		this.isTestUser$.next(newValue);
	}

	updateUserParamsAndInfo(data: { verifiedUser: LoginUser }) {
		const { verifiedUser } = data;
		this._ss.UserNameConnected = verifiedUser.UserName;
		this._ss.AgentIdConnected = verifiedUser.AgentID;
	}

	refreshUserParams() {
		this.handleInit();
	}

	isLoggedIn() {
		return this._storage.doesKeyExistInStorage('UserName') && this._storage.doesKeyExistInStorage('AgentId');
	}
}
