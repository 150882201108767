import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject, from, Observable, of, Subject, Subscriber, Subscription, takeUntil } from 'rxjs';
import { monthsHeb } from 'src/app/Utility/constants';

@Component({
	selector: 'app-dohotbizoimlekohot.component',
	templateUrl: './dohotbizoimlekohot.component.html',
	styleUrls: ['./dohotbizoimlekohot.component.scss'],
})
export class DohotbizoimlekohotComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	public Bizoim = [];
	public BizoimSwitch;
	public BizoimSwitchMain;
	public DateToday = new Date();

	public toogleInputNew = false;

	public AllClientsSearch;

	public test;
	public test2;

	public FirstPage = true;

	public LastPage = false;

	public BizoimEach;

	public ShowCompanys;

	public HasMultipleCompanys;

	public CompanyChoosen = 1;

	public OneTeudaInfo;

	public Loading = false;

	reportSearch = null;

	history = history;

	destroy$ = new Subject<void>();

	bizoimTotal$ = new BehaviorSubject<number>(0);
	bizoimAverage$ = new BehaviorSubject<number>(0);

	bizoimEachTotal$ = new BehaviorSubject<number>(0);
	bizoimEachAverage$ = new BehaviorSubject<number>(0);

	readonly currentMonth = new Date().getMonth();
	readonly months = monthsHeb.map((month, index) => ({ month, value: (+index + 1).toString().padStart(2, '0') }));

	hasYaad = false;

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Month: ['', Validators.required],
			ClientSearch: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (this._ss.CustomerInformation.YaadClients === '1' || this._ss.CustomerInformation.YaadClients === '2') {
			this.hasYaad = true;
		}

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this.Loading = true;
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');

			this._ss.GetBizoimLekohot().subscribe(res => {
				this.AllClientsSearch = res;
				this.AllClientsSearch = this.AllClientsSearch.recordset;
				this.BizoimSwitch = this.AllClientsSearch;

				this.test = this.DateToday.toISOString().toString().slice(5, 7);
				this.test2 = this.DateToday.toISOString().toString().slice(0, 4);

				for (let x = 0; x < this.AllClientsSearch.length; x++) {
					const client = this.AllClientsSearch[x];

					if (!client.Tot && !client.tot && !client.Ltot && !client.ltot) {
						delete this.AllClientsSearch[x];
						continue;
					}

					client.Yy1 = JSON.stringify(client.Yy1);
					client.Client_Name = client.Cn;
					client.ClientId = client.Cl;

					if (client.Mm1 === this.test && client.Yy1 === this.test2 && client.cm === 1) {
						this.Bizoim.push(client);
					}

					if (this.hasYaad) {
						if (client.Tot && (client.Yaad1 || client.Yaad2)) {
							client.advancementPercentage = ((client.total ?? client.Tot) / (client.currentYearGoal ?? (client.Yaad2 || client.Yaad1))) * 100;
						}
					}
				}

				this.BizoimSwitch = this.AllClientsSearch;

				this.AllClientsSearch = this.Bizoim;

				this.Bizoim.sort(function (a, b) {
					return b.Ahuz - a.Ahuz;
				});

				this.refreshBizoimSubjects();

				this.Loading = false;
			});
		} else {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('AllProducts') !== null) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
			this.AllProductsSearch = this.AllProducts;
		} else {
			this.router.navigate(['clients']);
		}

		this.refreshBizoimSubjects();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public AH = false;
	public TYA = false;
	public lYA = false;

	public SortAhoz() {
		if (this.AH === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ahuz - a.Ahuz;
			});

			this.AH = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ahuz - b.Ahuz;
			});

			this.AH = false;
		}
	}

	public SortThisYear() {
		if (this.TYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Tot - a.Tot;
			});

			this.TYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Tot - b.Tot;
			});

			this.TYA = false;
		}
	}

	public SortLastYear() {
		if (this.lYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ltot - a.Ltot;
			});

			this.lYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ltot - b.Ltot;
			});

			this.lYA = false;
		}
	}

	public ChangeMonth() {
		this.Bizoim = this.BizoimSwitch.filter(bz => bz.Mm1 === this.form.controls['Month'].value && bz.Yy1 === this.test2 && bz.cm === this.CompanyChoosen);

		if (this.form.controls['ClientSearch'].value) {
			this.Bizoim = this.Bizoim.filter(bz => bz.Client_Name.includes(this.form.controls['ClientSearch'].value) || bz.ClientId.includes(this.form.controls['ClientSearch'].value));
		}

		this.AllClientsSearch = this.Bizoim;

		this.refreshBizoimSubjects();
	}

	refreshBizoimSubjects() {
		this.bizoimTotal$.next(this.Bizoim.reduce((p, c) => p + c.Tot, 0));
		this.bizoimAverage$.next(this.Bizoim.reduce((p, c) => p + c.Tot, 0) / this.Bizoim.length || 0);
	}

	refreshBizoimEachSubjects() {
		this.bizoimEachTotal$.next(this.BizoimEach.reduce((p, c) => p + c.tsum, 0));
		this.bizoimEachAverage$.next(this.BizoimEach.reduce((p, c) => p + c.tsum, 0) / this.BizoimEach.length || 0);
	}

	public ChangeCompany() {
		this.form.controls['Month'].reset();

		this.CompanyChoosen = JSON.parse(this.form.controls['Company'].value);

		this.AllClientsSearch = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].cm === JSON.parse(this.form.controls['Company'].value) && this.BizoimSwitch[x].Mm1 === this.test && this.BizoimSwitch[x].Yy1 === this.test2) {
				this.AllClientsSearch.push(this.BizoimSwitch[x]);
			}
		}

		console.log(this.AllClientsSearch);

		this.Bizoim = this.AllClientsSearch;

		this.refreshBizoimSubjects();
	}

	public BackToDohotBizoim() {
		if (this.LastPage === true) {
			this.LastPage = false;
			this.OneTeudaInfo = null;

			setTimeout(() => {
				this.refreshBizoimEachSubjects();
			}, 0);
		} else {
			if (this.FirstPage === true) {
				this.history.back();
			} else {
				this.BizoimEach = null;
				this.FirstPage = true;

				setTimeout(() => {
					this.refreshBizoimSubjects();
				}, 0);
			}
		}
	}
	public OneTeuda(number) {
		this.LastPage = true;

		this._ss.GetOneTeudaDohot(number).subscribe(res => {
			this.OneTeudaInfo = res;
			this.OneTeudaInfo = this.OneTeudaInfo.recordset;
		});
	}

	//THIS IS IN THE SAME PAGE NOT IN NEW PAGE
	public MoveToDohotBizoimLekohotEach(ClientId, Company, Month, Year) {
		this.Loading = true;
		this.FirstPage = false;
		this._ss.EachClientDohot(ClientId, Company, Month, Year).subscribe(res => {
			this.BizoimEach = res;
			this.BizoimEach = this.BizoimEach.recordset;

			this.Loading = false;

			setTimeout(() => {
				this.refreshBizoimEachSubjects();
			}, 0);
		});
	}

	public SearchClear() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].Mm1 === this.test && this.BizoimSwitch[x].Yy1 === this.test2 && this.BizoimSwitch[x].cm === this.CompanyChoosen) {
				this.Bizoim.push(this.BizoimSwitch[x]);
			}
		}

		this.form.reset();

		this.refreshBizoimSubjects();
	}

	public SearchClient() {
		this.Bizoim = [];

		if (this.form.controls['ClientSearch'].value === '' || this.form.controls['ClientSearch'].value.length === 0) {
			for (let x = 0; x < this.BizoimSwitch.length; x++) {
				if (this.BizoimSwitch[x].Mm1 === this.test && this.BizoimSwitch[x].Yy1 === this.test2 && this.BizoimSwitch[x].cm === this.CompanyChoosen) {
					this.Bizoim.push(this.BizoimSwitch[x]);
				}
			}
			if (this.form.controls['Month'].value) {
				this.Bizoim = this.BizoimSwitch.filter(bz => bz.Mm1 === this.form.controls['Month'].value && bz.Yy1 === this.test2 && bz.cm === this.CompanyChoosen);
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 1) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1)
				) {
					this.Bizoim.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2)
				) {
					this.Bizoim.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length > 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				for (let z = 0; z < 50; z++) {
					if (
						this.AllClientsSearch[x].Client_Name.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length) ||
						this.AllClientsSearch[x].ClientId.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length)
					) {
						let data = this.Bizoim.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
						if (data === undefined) {
							this.Bizoim.push(this.AllClientsSearch[x]);
						}
					}
				}
			}
		}

		this.refreshBizoimSubjects();
	}
}
