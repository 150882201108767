import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { catchError, from, map, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { translateDateForSQLServer } from 'src/app/Utility/functions';
import { AlertService } from 'src/app/services/alert.service';
import { MsgType } from 'src/app/Interfaces/alerts';

@Component({
	selector: 'app-clientactiv',
	templateUrl: './clientactiv.component.html',
	styleUrls: ['./clientactiv.component.scss'],
})
export class ClientactivComponent implements OnInit {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		public device: DeviceInfoService,
		private http: HttpClient,
		public _alert: AlertService,
		private _acr: ActivatedRoute,
	) {}

	form = this._fb.nonNullable.group({
		Report: ['', Validators.required],
		Reason: ['', Validators.required],
		Status: ['', Validators.required],
	});
	showActivityProducts = true;

	public ResAgentActivity;

	public ConfirmSentReport = false;

	public Reasons;

	public TodayDate = new Date();

	public ReasonRemark = '';

	public NewCartBikor;

	public Loading = false;

	readonly canAttachPicture = this._ss.CustomerInformation?.VisitsTakePicture === '1';
	readonly isNotWebview$ = this.device.isWebview$.pipe(map(value => !value));

	public RemCheck;

	public StatusRemark = '';

	VisitID = '';
	image: File | null = null;

	addToVisitAlertText = '';

	openOnExit = this._acr.snapshot.queryParams['exit'] === 'true';

	canSelectMultipleReasons = this._ss.CustomerInformation?.VisitMultipleReasons === '1';

	@ViewChild('imageInput') imageInput: ElementRef<HTMLInputElement>;

	ngOnInit(): void {
		if (!this._ss.OneClientInfo?.ClientId) {
			history.back();
			return;
		}

		this._ss.GetAllReportsAgent().subscribe({
			next: res => {
				this.ResAgentActivity = res;
				this.ResAgentActivity = this.ResAgentActivity.recordset;

				this._ss.GetAllReportsAgentReasons().subscribe(res => {
					this.Reasons = res;
					this.Reasons = this.Reasons.recordset;
				});
			},
			error: err => {
				console.log(err);
			},
		});
	}

	public BackToClientCard() {
		// this.router.navigate(['clientcard'])
		history.back();
	}

	public CloseSentReportPopOpen() {
		this.ConfirmSentReport = false;

		this.clearAllState();
		history.back();
	}

	public ConfirmBikor() {
		if (this._ss.CustomerInformation?.BikorMustIncludeReason === '1' && !this.form.controls.Reason.value?.length) {
			alert('חייב לבחור סיכום ביקור');
			return;
		}

		if (this.canAttachPicture && this.imageInput.nativeElement.classList.contains('uploading')) {
			alert('אנא המתינו לסיום העלאת תמונה');
			return;
		}

		this.Loading = true;

		const BikorString: any = {
			VisitID: this.NewCartBikor,
			ClientID: this._ss.OneClientInfo.ClientId,
			ResonID: this.form.controls.Reason.value,
			PicName: this.image?.name || '0',
			company: this._ss.OneClientInfo.company,
			ResDesc: this.ReasonRemark,
			AgentID: this._ss.AgentIdConnected,
			ClientName: this._ss.OneClientInfo.Client_Name,
		};

		const translatedDate = translateDateForSQLServer(new Date()).toISOString();
		const statusRemark = this.form.controls['Report'].value + ' ';

		if (this.form.controls['Status'].value === 'enter') {
			this.StatusRemark = statusRemark + 'כניסה';
			BikorString.Enter = translatedDate;
		} else if (this.form.controls['Status'].value === 'exit') {
			this.StatusRemark = statusRemark + 'יציאה';
			BikorString.Exit = translatedDate;
		}

		BikorString.Remark = this.StatusRemark;

		if (this.Reasons) {
			for (let x = 0; x < this.Reasons.length; x++) {
				if (this.form.controls['Reason'].value === this.Reasons[x].rid) {
					this.ReasonRemark = this.Reasons[x].rds;

					break;
				}
			}
		}

		BikorString.ResDesc = this.ReasonRemark;

		if (this.addToVisitAlertText) {
			BikorString.addToVisitAlertText = this.addToVisitAlertText;
		}

		if (this.form.controls['Reason'].value.length === 0) {
			alert('חייב לבחור סיבת אי מכירה');
			this.Loading = false;
		} else if (this.form.controls['Status'].value === '') {
			alert('חייב לבחור כניסה או יציאה');
			this.Loading = false;
		} else if (this.form.controls['Reason'].value === '5' && this.form.controls['Report'].value.length === 0) {
			alert('חייב להכניס פירוט אי מכירה');
			this.Loading = false;
		} else {
			setTimeout(() => {
				this._ss
					.BikorConfirm({
						VisitID: this.VisitID,
						ClientID: this._ss.OneClientInfo.ClientId,
						ResonID: this.form.controls['Reason'].value,
						Remark: this.StatusRemark,
						PicName: this.image?.name || '0',
						company: this._ss.OneClientInfo.company,
						Latitude: '0',
						Longitude: '0',
						ResDesc: this.ReasonRemark,
						AgentID: this._ss.AgentIdConnected,
						ClientName: this._ss.OneClientInfo.Client_Name,
						DATAString: JSON.stringify(BikorString),
					})
					.subscribe(
						res => {
							if (res !== 'RequestError') {
								this.Loading = false;
								this.ConfirmSentReport = true;
							} else {
								alert('ישנה תקלה,אנא נסה שנית');
								this.Loading = false;
							}
						},
						err => {
							alert('ישנה תקלה,אנא נסה שנית');
							this.Loading = false;
						},
					);
			}, 200);
		}
	}

	clearAllState() {
		this.form.reset();
		this.refreshVisitID();
		this.image = null;
		if (this.imageInput?.nativeElement) {
			this.imageInput.nativeElement.value = null;
		}
	}

	newReport() {
		this.refreshVisitID();
		this.showActivityProducts = false;
		this._alert.handleAlertLogic(MsgType.newVisit.msgType);
		const anyAlerts = this._alert.handleFilterRelevantAlerts(MsgType.newVisit.msgType);

		if (!anyAlerts.length) return;

		this.addToVisitAlertText = anyAlerts.map(alert => alert.Msg).join(', ');
	}

	setImage(event): void {
		const input = event.target as HTMLInputElement;
		input.classList.remove('error');
		input.classList.add('uploading');

		if (!event.target?.files?.length) {
			input.classList.remove('uploading', 'success');
			return;
		}

		const file = new File(event.target.files, `${this.VisitID}.jpg`, { type: event.target.files[0].type });

		this.handleFileUpload(file)
			.pipe(
				switchMap(bool => {
					if (bool) {
						this.image = file;
					} else {
						input.classList.add('error');
					}
					return from([bool]);
				}),
			)
			.subscribe({
				next: () => {
					input.classList.remove('uploading');
				},
				error: error => {
					console.log(error);
					input.classList.add('error');
					input.classList.remove('uploading');
				},
			});
	}

	handleFileUpload(file: File): Observable<boolean> {
		const fd = new FormData();
		fd.append(file.name, file);

		return this.http.post(`${this._ss.CustomerInformation.CustServer}/mobil/new-image/visits`, fd).pipe(
			map(() => true), // If the response is successful, return true
			catchError(error => {
				console.log(error);
				return from([false]);
			}),
		);
	}

	refreshVisitID() {
		this.VisitID = `${JSON.stringify(new Date().getTime())}000${this._ss.AgentIdConnected}`;
	}

	handleNavigationToVisitsReport() {
		this.router.navigate(['activ'], { queryParams: { clientid: this._ss.OneClientInfo.ClientId } });
	}

	navigateToAlerts(): void {
		this.router.navigate(['manage-alerts'], { queryParams: { newAlert: true, backTwice: this.openOnExit ? null : true }, replaceUrl: true });
	}
}
