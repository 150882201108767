import { Pipe, PipeTransform } from '@angular/core';
import { monthsHeb } from '../Utility/constants';

@Pipe({
	name: 'returnMonthHebrew',
})
export class ReturnMonthHebrewPipe implements PipeTransform {
	transform(value: number | string, decrementByOne?: boolean): string | null {
		const arr = monthsHeb;
		value = decrementByOne ? +value - 1 : value;

		if ((typeof value === 'number' || !isNaN(+value)) && +value >= 0 && +value <= 11) {
			return arr[+value];
		}

		return null;
	}
}
