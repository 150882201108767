import { Component, OnInit, HostListener, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject, catchError, combineLatest, interval, map, Subject, takeUntil, throwError } from 'rxjs';
import { Time, UtilService } from 'src/app/services/util.service';
import { MsgType } from 'src/app/Interfaces/alerts';
import { AlertService } from 'src/app/services/alert.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LikutMainSQL, LikutDisplayMain, LikutSubSQL, LikutPath, LikutKabalaMainSQL, LikutKabalaSubSQL, LikutMekabelDisplay, NewLikutMekabelWarehouseFormResult } from 'src/app/Interfaces/likut';
import { LikutService } from 'src/app/services/likut.service';
import { environment } from 'src/environments/environment';
import { TruckLoadingService } from 'src/app/services/truck-loading.service';
import { TruckLoadingDisplay, TruckLoadingMainSQL, TruckLoadingPath, TruckLoadingSubSQL, TruckModeStatuses } from 'src/app/Interfaces/truck-loading';
import { TextService } from 'src/app/services/text.service';
import {
	StoredDistributionTask,
	TruckDistributionTaskDisplayExtended,
	TruckDistributionMainSQL,
	TruckDistributionPath,
	TruckDistributionSubSQL,
	TruckDistributionExtra5Array,
	TruckDistributionFinishBody,
	ImageStored,
	StoredDistributionTaskIDB,
} from 'src/app/Interfaces/truck-distribution';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { getTaskIdentifier, TEUDA_GROUP_B } from './methods';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { translateDateForSQLServer } from 'src/app/Utility/functions';
import { IndexedDBService } from 'src/app/services/indexed-db.service';
import { AppStyleService } from 'src/app/services/app-style.service';
import { TaskTextVisibilityPipe } from 'src/app/Pipes/task-text-visibility.pipe';
import { LikutClientsWarehouseDialogComponent } from './likut-clients-warehouse-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AllDocTypes, DocTypesNumToName } from 'src/app/Interfaces/SQL-morning-responses';
import { RALLYANCE_RECLAMATION_APP_PATH } from 'src/app/services/reclamation.service';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { ClosePopupValues } from '../new-receipt/new-receipt.component';
// import { RallyanceReclamationPath } from 'src/app/Interfaces/reclamations';

type TruckMode = TruckLoadingPath | TruckDistributionPath;
type LikutMode = LikutPath | 'likut-mekabel';
export type SubAppModes = LikutMode | TruckMode | typeof RALLYANCE_RECLAMATION_APP_PATH;

type clientIDWithStatusAndAddressAndCity = string;
type DocNum = string;
type TeudaNum = string;

type clientId = string;

@Component({
	selector: 'app-likut-clients',
	templateUrl: './likut-clients.component.html',
	styleUrls: ['./likut-clients.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LikutClientsComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		public _fb: FormBuilder,
		private _alert: AlertService,
		private _loading: LoadingService,
		private _router: Router,
		public _likut: LikutService,
		public _truckLoading: TruckLoadingService,
		public _truckDistribution: TruckDistributionService,
		public _text: TextService,
		private _util: UtilService,
		private _indexedDB: IndexedDBService,
		private _style: AppStyleService,
		private dialog: MatDialog,
		public _receipts: ReceiptsService,
		private _cc: CreditCardService,
	) {}

	form = this._fb.nonNullable.group({
		ClientSearch: [''],
	});
	AllClients: Record<clientId, { clientName: string; clientId: string }> = {};

	logo = MOBILITY_LOGO;

	AllProducts;
	Loading = false;
	hasReports = false;

	allTeudotNewRoute: LikutMainSQL[] = [];
	allTeudotNewRouteObject$: BehaviorSubject<Record<clientIDWithStatusAndAddressAndCity, Record<TeudaNum, LikutMainSQL>>> = new BehaviorSubject({});
	allTeudotNewRouteTeudotDisplay$: BehaviorSubject<LikutDisplayMain[]> = new BehaviorSubject([]);

	allTruckLoadingTeudot: TruckLoadingMainSQL[] = [];
	allTruckLoadingTeudotObject$: BehaviorSubject<Record<clientIDWithStatusAndAddressAndCity, Record<DocNum, TruckLoadingMainSQL>>> = new BehaviorSubject({});
	allTruckLoadingTeudotDisplay$: BehaviorSubject<TruckLoadingDisplay[]> = new BehaviorSubject([]);

	allTruckDistributionTeudot: TruckDistributionMainSQL[] = [];
	allTruckDistributionTeudotObject$: BehaviorSubject<Record<clientIDWithStatusAndAddressAndCity, Record<DocNum, TruckDistributionMainSQL>>> = new BehaviorSubject({});
	allTruckDistributionTeudotDisplay$: BehaviorSubject<TruckDistributionTaskDisplayExtended[]> = new BehaviorSubject([]);

	allTeudotLikutMekabel: LikutKabalaMainSQL[] = [];
	allTeudotLikutMekabelObject$: BehaviorSubject<Record<TeudaNum, Record<TeudaNum, LikutKabalaMainSQL>>> = new BehaviorSubject({});
	allTeudotLikutMekabelTeudotDisplay$: BehaviorSubject<LikutMekabelDisplay[]> = new BehaviorSubject([]);

	dateString = Intl.DateTimeFormat('he-il', { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }).format(+new Date());

	Date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

	totalTasks$ = new BehaviorSubject(0);
	totalTasksCompleted$ = new BehaviorSubject(0);

	status$ = new BehaviorSubject(this._text.store.updatingGeneral);
	SearchErrorPop = false;
	OrderTypeList = false;
	shopFilterPop = false;

	HasMultipleCompanys;
	online = window.navigator.onLine;

	Datetwo;

	datedate = '';
	datetime = '';

	showSortPopupSanen = false;

	DateUpdate = new Date();

	Datee;

	Admin;

	NavigateToClientSection = null;

	ShowSapakim = false;

	LoadingClient = false;

	ManualUpdate = 'false';
	Updating = 'false';

	AllUpdatesArray = [];

	windowWidth = innerWidth;

	destroy$ = new Subject<void>();

	zoomLevel = this.windowWidth > 767 && this.windowWidth < 1099 ? 1.2 : 1;

	itemSize = this.windowWidth > 768 ? 80 : 72;

	isLocal = !environment.production;

	isLikut = location.pathname.includes('likut');
	isTruckLoading = location.pathname.includes('truck-loading');
	isTruckDistribution = location.pathname.includes('truck-distribution');
	isRallyanceReclamation = location.pathname.includes('rallyance-reclamation');

	pageMode$ = new BehaviorSubject<SubAppModes>('likut');

	isOnlyForPassword = location.pathname.includes('password');

	savedOrders$ = new BehaviorSubject<Record<string, StoredDistributionTask>>({});
	isAnySavedOrder$ = this.savedOrders$.pipe(map(savedOrdersRecord => !!Object.values(savedOrdersRecord).length));
	sendTheFollowingOrders$ = new BehaviorSubject<StoredDistributionTask[]>([]);
	isSendingAnyOrders$ = this.sendTheFollowingOrders$.pipe(map(orders => orders.some(order => order.main.isSending || order.main.sendingSuccess != null)));
	areAllOrdersSent$ = combineLatest([this.savedOrders$, this.sendTheFollowingOrders$]).pipe(
		map(([savedOrders, orders]) => {
			const savedOrdersIsEmpty = Object.keys(savedOrders).length === 0;
			const allSendingSuccess = orders.every(order => order.main.sendingSuccess === true);
			return savedOrdersIsEmpty || allSendingSuccess;
		}),
	);

	extra5State$: Subject<{ client: string; array: TruckDistributionExtra5Array[] }> = new BehaviorSubject({ array: [], client: '' });
	onlyDocnum$ = new BehaviorSubject(false);

	isNewReceiptPopupOpen$ = new BehaviorSubject(false);

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	async ngOnInit() {
		this.resolvePageMode();

		this.resetParameters();

		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}

		if (!localStorage.getItem('UserName') && !localStorage.getItem('AgentId')) this._router.navigate(['login']);

		console.log('before ls check', performance.now());

		if (localStorage.getItem('Updating') === null) {
			// if (this.isTruckDistribution) {

			// } else {
			const ls5 = localStorage.getItem('AllProducts');

			if (ls5 !== null) {
				this._ss.ProductCompany = JSON.parse(decompress(ls5));
			}
			// }
		}

		const updateTasks = interval(1000 * 60 * 5);

		if (this.isLikut) {
			this._likut.refreshAllAdminTypes();
			this.Admin = this._likut.Admin.toLowerCase();

			if (this._likut.isCheckerAgent) {
				this._text.store.mainTasks = 'משימות בדיקה';
			}
			this._likut.resetLikutState();

			updateTasks.pipe(takeUntil(this.destroy$)).subscribe(async () => {
				await this.updateLikut();
			});
		}

		if (this.isTruckLoading) {
			this._truckLoading.refreshAllAdminTypes();
			this._truckLoading.switchTaskAndTeudaNames('truck-loading');
			if (this._truckLoading.parametersRecord.groupOnlyByDocNum) {
				this.onlyDocnum$.next(true);
			}
			this.Admin = this._truckLoading.Admin.toLowerCase();
			updateTasks.pipe(takeUntil(this.destroy$)).subscribe(async () => {
				await this.updateTruckLoadingTasks();
			});
		}

		if (this.isTruckDistribution) {
			this._truckLoading.refreshAllAdminTypes();
			this._truckLoading.switchTaskAndTeudaNames('truck-distribution');
			if (this._truckDistribution.parametersRecord.groupOnlyByDocNum) {
				this.onlyDocnum$.next(true);
			}
			this.Admin = this._truckLoading.Admin.toLowerCase();
			// updateTasks.pipe(takeUntil(this.destroy$)).subscribe(async () => {
			// 	await this.updateTruckDistributionTasks();
			// });
		}

		if (localStorage.getItem('ManualUpdate') !== null) {
			this.ManualUpdate = localStorage.getItem('ManualUpdate');
		}

		if (localStorage.getItem('Updating') !== null) {
			this.Updating = localStorage.getItem('Updating');
		}

		if (localStorage.getItem('CustNum') !== null) {
			this._ss.CustNum = localStorage.getItem('CustNum');
		}

		this.online = window.navigator.onLine;

		if (localStorage.getItem('lastupdate') === null) this._router.navigate(['home']);

		this.Datetwo = JSON.parse(localStorage.getItem('lastupdate'));
		this.datedate = this.Date.toString().slice(0, 10);
		this.datetime = this.Date.toString().slice(11, 20);

		if (this.Datetwo?.toString().slice(0, 10) !== this.Date.toISOString().toString().slice(0, 10)) this._router.navigate(['home']);

		if (localStorage.getItem('Updating') === null && this.online && localStorage.getItem('refresh') !== null) {
			this.Datee = localStorage.getItem('refresh');
			this.Datee = JSON.parse(this.Datee);

			if (this.Datee.toString().slice(0, 13) !== this.DateUpdate.toISOString().toString().slice(0, 13)) {
				localStorage.setItem('refresh', JSON.stringify(this.Date.toISOString()));

				if (this._ss.CustomerInformation.CustChangeCss6 === '1') {
					localStorage.setItem('lastupdate', '0');
					localStorage.setItem('ManualUpdate', 'true');
				}
			}
		}

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.Updating !== 'false') {
			localStorage.removeItem('ManualUpdate');
			localStorage.removeItem('Updating');
			localStorage.removeItem('SelectHearot');

			this.Loading = true;

			if (this.ManualUpdate === 'false') {
				localStorage.removeItem('DailyOrder');
				localStorage.removeItem('FinishBikor');
				localStorage.removeItem('LocalSaveTrucksSent');
			}

			const updateNearFinish = this._text.store.updateNearFinish;
			const connectionError = this._text.store.taskConnectionError;

			try {
				this.clearSavedOrders();

				await new Promise<void>((resolve, reject) => {
					this._ss
						.getAllProductsWithoutAgent(this.pageMode$.value)
						.pipe(catchError(() => throwError(() => reject())))
						.subscribe({
							next: async res => {
								if (typeof res === 'string') {
									return reject();
								}

								if (this.isTruckDistribution) {
									try {
										await this._indexedDB.storeAllProductsDB(res.recordset);
									} catch (error) {
										console.log(error);
										return reject();
									}
								} else {
									localStorage.setItem('AllProducts', compress(JSON.stringify(res.recordset)));
								}
								localStorage.setItem('lastupdate', JSON.stringify(this.Date.toISOString()));
								localStorage.setItem('refresh', JSON.stringify(this.Date.toISOString()));

								this._loading.changeLoadingStatuses({ newMessage: 'מעדכן פרמטרים...' });

								resolve();
							},
						});
				});
			} catch (error) {
				alert(connectionError);

				if (this._ss.isMovingToDohot) {
					let route = 'dohot';

					this._router.navigate([route], { replaceUrl: true });
					return;
				}

				window.location.reload();
			}

			try {
				await new Promise<void>(resolve => {
					this._ss
						.GetCustInfo(this._ss.CustomerInformation.CustNum)
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: (res: any) => {
								if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset.length) return resolve();

								localStorage.setItem('CustomerInformation', compress(JSON.stringify(res.recordset[0])));

								this._loading.changeLoadingStatuses({ newMessage: 'מעדכן התראות...' });

								resolve();
							},
						});
				});

				await new Promise<void>(resolve => {
					this._ss
						.getAlerts()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									this._alert.Alerts = res.recordset.map(alert => ({ ...alert, JSON: typeof alert.JSON === 'string' ? JSON.parse(alert.JSON) : alert.JSON }));
									localStorage.setItem('Alerts', compress(JSON.stringify(this._alert.Alerts)));
								}

								resolve();
							},
						});
				});

				if (this.isTruckDistribution) {
					this._loading.changeLoadingStatuses({ newMessage: `${this._text.store.updatingTasks}...` });

					let PODMain: TruckDistributionMainSQL[] = [],
						PODSub: TruckDistributionSubSQL[] = [],
						allTruckDistributionDocNumsSet: Set<string>,
						pdfSRCArray = [];

					await new Promise<void>((resolve, reject) => {
						const options = { onlyAssigned: this._ss.AgentIdConnected };
						this._truckDistribution
							.getAllTruckDistributionTasksForMain('clientsTask', options)
							.pipe(
								catchError(() => throwError(() => resolve())),
								takeUntil(this.destroy$),
							)
							.subscribe({
								next: res => {
									if (typeof res === 'string' || !res.recordset.length) return resolve();

									PODMain = res.recordset;

									allTruckDistributionDocNumsSet = new Set(res.recordset.map(task => task.DocNum));

									if (!('OrgPDF' in res.recordset[0])) return resolve();
									pdfSRCArray = res.recordset.map(task => task.OrgPDF);

									resolve();
								},
								error: () => {
									alert(this._text.store.taskConnectionError);
									this._router.navigate(['home'], { replaceUrl: true });
								},
							});
					});
					await new Promise<void>((resolve, reject) => {
						if (!allTruckDistributionDocNumsSet.size) return resolve();
						this._truckDistribution
							.getOneOrMoreSubTruckDistributionTask({ docnums: [...allTruckDistributionDocNumsSet] })
							.pipe(
								catchError(() => throwError(() => resolve())),
								takeUntil(this.destroy$),
							)
							.subscribe({
								next: res => {
									if (typeof res === 'string') return resolve();

									PODSub = res.recordset;

									resolve();
								},
								error: () => {
									alert(this._text.store.taskConnectionError);
									this._router.navigate(['home'], { replaceUrl: true });
								},
							});
					});

					this._truckDistribution.updateTasks({ main: PODMain, sub: PODSub });
					await new Promise<void>(async (resolve, reject) => {
						if (!pdfSRCArray.length) return resolve();
						for (const pdf of pdfSRCArray) {
							if (!pdf) continue;
							try {
								await fetch(`${this._ss.CustomerInformation.CustServer}/docout/${pdf}`);
							} catch (error) {}
						}

						resolve();
					});
				}

				if (this._likut.isMekabel) {
					await new Promise<void>((resolve, reject) => {
						this._ss
							.getLikutMekabelDocTypes()
							.pipe(
								catchError(() => throwError(() => resolve())),
								takeUntil(this.destroy$),
							)
							.subscribe({
								next: res => {
									if (typeof res === 'string') return resolve();

									const doctypesReduced: DocTypesNumToName[] = Object.values(res.recordset.reduce((acc, curr) => ({ ...acc, [curr.DocType]: curr }), {}));

									this._likut.likutMekabelDoctypes$.next(doctypesReduced);

									localStorage.setItem('LikutMekabelDoctypes', compress(JSON.stringify(doctypesReduced)));

									resolve();
								},
							});
					});
					await new Promise<void>((resolve, reject) => {
						this._ss
							.getLikutMekabelWarehouses()
							.pipe(
								catchError(() => throwError(() => resolve())),
								takeUntil(this.destroy$),
							)
							.subscribe({
								next: res => {
									if (typeof res === 'string') return resolve();

									this._likut.likutMekabelWarehouses$.next(res.recordset);

									localStorage.setItem('LikutMekabelWarehouses', compress(JSON.stringify(res.recordset)));

									resolve();
								},
							});
					});

					// get warehouses after SQL table built
					// get suppliers after SQL table built
				}

				this.status$.next(updateNearFinish);
				this._loading.changeMessageBackToDefault();

				setTimeout(() => {
					window.location.reload();
				}, 700);
			} catch (error) {
				this.status$.next(updateNearFinish);
				setTimeout(() => {
					window.location.reload();
				}, 300);
			}
		} else {
			this._alert.handleAlertLogic(MsgType.afterUpdateLocal.msgType);

			// if (environment.development) {
			// 	const db = await this._indexedDB.getTruckDistributionDB();

			// 	if ('PODSub' in db) {
			// 		const canceled = db.PODSub.filter(sub => sub.PODStatus === '2');
			// 		console.log({ canceled });
			// 	}
			// }

			this._style.handleSetCSSVariablesBasedOnCustomerParameters();

			if (this.isLikut) {
				if (this._likut.isMekabel) {
					await this.updateLikutMekabel({ buildAllClients: true });
					return;
				}
				await this.updateLikut({ buildAllClients: true });
			}

			if (this.isTruckLoading) {
				await this.updateTruckLoadingTasks({ buildAllClients: true });
			}

			if (this.isTruckDistribution) {
				if (this._truckLoading.cantStartDistributionIfLoadingNotDone) {
					await this.updateTruckLoadingTasks();

					if (this.allTruckLoadingTeudot.filter(task => task.LoadingStatus !== '1').length) {
						alert('אין אפשרות להתחיל הפצה לפני סיום כל משימות העמסה. הנך מועבר כעת.');
						this._router.navigate(['truck-loading', 'clients'], { replaceUrl: true });
					}
				}
				await this.updateTruckDistributionTasks({ buildAllClients: true });
			}
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this._loading.changeLoadingStatuses({ isLoading: false });
	}

	resetParameters() {
		this._truckDistribution.canLeaveCurrentPage = true;
	}

	MoveToDailyReport() {
		this._router.navigate(['daily-report']);
	}

	MoveToDohot() {
		this._router.navigate(['dohot'], { replaceUrl: true });
	}

	BackToHome() {
		this._router.navigate(['home']);
	}

	handleNavigateToProductsPage() {
		this._alert.handleAlertLogic(MsgType.enterProductsFromHour.msgType);
		this._router.navigate(['products']);
	}

	handleUpdateTasks() {
		if (this.isTruckLoading) {
			this.updateTruckLoadingTasks();
			return;
		}

		if (this.isTruckDistribution) {
			this.updateTruckDistributionTasks();
			return;
		}

		if (this._likut.isMekabel) {
			this.updateLikutMekabel();
			return;
		}

		this.updateLikut();
	}

	async updateLikut(options: { buildAllClients?: boolean } = {}) {
		if (this._likut.isMekabel) return;
		this._ss.closeMenu();

		let buildAllClients = options.buildAllClients || false;
		await new Promise<void>((resolve, reject) => {
			this._loading.changeLoadingStatuses({ newMessage: `${this._text.store.updatingTasks}...` });
			let role: Parameters<typeof this._ss.getAllTeudotForLikutNewRoute>[0]['role'] = 'melaket';
			let onlyAssigned: boolean = true;

			if (this._likut.isCheckerAgent) {
				role = 'checker';
				onlyAssigned = false;
			}

			this._ss
				.getAllTeudotForLikutNewRoute({ role, assigned: onlyAssigned })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return resolve();

						this.allTeudotNewRoute = res.recordset;

						resolve();
					},
					error: () => {
						alert(this._text.store.taskConnectionError);
						this._router.navigate(['home'], { replaceUrl: true });
					},
				});
		});

		this.buildAllLikutTeudotObject(buildAllClients);

		this.refreshSubject('teudotNewRouteDisplay');

		this.refreshSubject('totalLikutNewRoute');
	}
	async updateLikutMekabel(options: { buildAllClients?: boolean } = {}) {
		this._ss.closeMenu();

		let buildAllClients = options.buildAllClients || false;
		await new Promise<void>((resolve, reject) => {
			this._loading.changeLoadingStatuses({ newMessage: `${this._text.store.updatingTasks}...` });
			let role: Parameters<typeof this._ss.getAllTeudotForLikutMekabel>[0]['role'] = 'mekabel';
			// let onlyAssigned: boolean = true;

			this._ss
				.getAllTeudotForLikutMekabel({ role })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return resolve();

						this.allTeudotLikutMekabel = res.recordset;

						resolve();
					},
					error: () => {
						alert(this._text.store.taskConnectionError);
						this._router.navigate(['home'], { replaceUrl: true });
					},
				});
		});

		this.buildAllLikutMekabelTeudotObject(buildAllClients);

		this.refreshSubject('teudotMekabelDisplay');
	}

	async updateTruckDistributionTasks(options: { buildAllClients?: boolean; onlyAssigned?: boolean } = {}) {
		this._ss.closeMenu();

		const buildAllClients = options.buildAllClients || false;
		const onlyAssigned = options.onlyAssigned || true;

		await new Promise<void>((resolve, reject) => {
			this._loading.changeLoadingStatuses({ newMessage: `${this._text.store.updatingTasks}...` });
			const options = { onlyAssigned: onlyAssigned ? this._ss.AgentIdConnected : null };
			this._truckDistribution
				.getAllTruckDistributionTasksForMain('clientsTask', options)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return resolve();

						this.allTruckDistributionTeudot = res.recordset;

						resolve();
					},
					error: () => {
						if (!this._truckDistribution.savedTasks$.value.length) {
							alert(this._text.store.taskConnectionError);
							this._router.navigate(['home'], { replaceUrl: true });
							return;
						}

						this.allTruckDistributionTeudot = this._truckDistribution.savedTasks$.value.map(savedTasks => savedTasks.main);
						resolve();
					},
				});
		});

		this.buildAllTruckTeudotObject('truck-distribution', buildAllClients);

		this.refreshSubject('truckDistributionDisplay');

		this.refreshSubject('truckDistributionTotals');

		this.buildAndMarkOpenTasks();
	}
	/**
	 * update tasks.
	 * @param {{}} options - provided options
	 * @default {buildAllClients:false,onlyAssigned:true}
	 */
	async updateTruckLoadingTasks(options: { buildAllClients?: boolean; onlyAssigned?: boolean } = {}) {
		this._ss.closeMenu();

		const buildAllClients = options.buildAllClients || false;
		const onlyAssigned = options.onlyAssigned || true;

		await new Promise<void>((resolve, reject) => {
			this._loading.changeLoadingStatuses({ newMessage: `${this._text.store.updatingTasks}...` });
			if (this.isOnlyForPassword) {
				this._truckLoading
					.getAllTruckLoadingTasksForMain('password')
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							if (typeof res === 'string') return resolve();

							this.allTruckLoadingTeudot = res.recordset;

							resolve();
						},
						error: () => {
							alert(this._text.store.taskConnectionError);
							this._router.navigate(['home'], { replaceUrl: true });
						},
					});
				return;
			}
			this._truckLoading
				.getAllTruckLoadingTasksForMain('clientsTask', onlyAssigned ? this._ss.AgentIdConnected : null)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') return resolve();

						this.allTruckLoadingTeudot = res.recordset;

						resolve();
					},
					error: () => {
						alert(this._text.store.taskConnectionError);
						this._router.navigate(['home'], { replaceUrl: true });
					},
				});
		});

		this.buildAllTruckTeudotObject('truck-loading', buildAllClients);

		this.refreshSubject('truckLoadingDisplay');

		this.refreshSubject('truckLoadingTotals');
	}

	buildAllTruckTeudotObject(mode: TruckMode, buildAllClients = false) {
		const onlyDocnumTruckDistribution = this._truckDistribution.parametersRecord.groupOnlyByDocNum;
		const onlyDocnumTruckLoading = this._truckLoading.parametersRecord.groupOnlyByDocNum;

		if (mode === 'truck-loading') {
			this.allTruckLoadingTeudotObject$.next(
				this.allTruckLoadingTeudot.reduce((p, c) => {
					if (buildAllClients) {
						this.buildClientNameAndID(c);
					}

					const identifier = getTaskIdentifier({ mode: 'truck-loading', task: c, onlyDocnum: onlyDocnumTruckLoading });

					p[identifier] ||= {};
					p[identifier][c.DocNum] = c;

					return p;
				}, {}),
			);
			return;
		}

		this.allTruckDistributionTeudotObject$.next(
			this.allTruckDistributionTeudot.reduce((p, c) => {
				if (buildAllClients) {
					this.buildClientNameAndID(c);
				}

				const identifier = getTaskIdentifier({ mode: 'truck-distribution', task: c, onlyDocnum: onlyDocnumTruckDistribution });

				p[identifier] ||= {};
				p[identifier][c.DocNum] = c;

				return p;
			}, {}),
		);
		return;
	}

	buildAllLikutTeudotObject(buildAllClients = false) {
		this.allTeudotNewRouteObject$.next(
			this.allTeudotNewRoute.reduce((p, c) => {
				if (buildAllClients) {
					this.buildClientNameAndID(c);
				}

				const displaySeparator = getTaskIdentifier({ mode: 'likut', task: c });

				p[displaySeparator] ||= {};
				p[displaySeparator][c.TeudaNum] = c;

				return p;
			}, {}),
		);
		console.log(this.allTeudotNewRouteObject$.value);
	}

	buildAllLikutMekabelTeudotObject(buildAllClients = false) {
		this.allTeudotLikutMekabelObject$.next(
			this.allTeudotLikutMekabel.reduce((p, c) => {
				if (buildAllClients) {
					this.buildClientNameAndID(c);
				}

				const displaySeparator = getTaskIdentifier({ mode: 'likut-mekabel', task: c });

				p[displaySeparator] ||= {};
				p[displaySeparator][c.TeudaNum] = c;

				return p;
			}, {}),
		);
	}

	buildClientNameAndID(task: TruckDistributionMainSQL | TruckLoadingMainSQL | LikutMainSQL | LikutKabalaMainSQL) {
		if (!('ClientID' in task)) {
			this.AllClients[task.Client_Id] = { clientName: task.Client_Name, clientId: task.Client_Id };
			return;
		}
		this.AllClients[task.ClientID] = { clientName: task.ClientName, clientId: task.ClientID };
	}

	async checkIfLikutNewRoutesCompleted(task: LikutDisplayMain) {
		const teudaNumArray = Object.keys(task.teudot);
		return await new Promise<false | string>((resolve, reject) => {
			this._ss
				.getLikutByTeudaNumMain(teudaNumArray, 'get')
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						console.log(res);
						if (typeof res === 'string') {
							return reject(res);
						}

						const isAlreadyOpen = res.recordset.find(task => task.OpenedBy && task.OpenedBy !== '0' && task.OpenedBy !== this._ss.AgentIdConnected);

						if (isAlreadyOpen && !this.isLocal) {
							return resolve(isAlreadyOpen.OpenedBy);
						}

						resolve(false);
					},
					error: err => {
						console.log(err);
						reject();
					},
				});
		});
	}

	async getOneMekabelTaskByTeudaNum(task: LikutMekabelDisplay) {
		const TeudaNum = task.refrenceNumber;
		const TeudaType = task.doctype.num;
		return await new Promise<LikutKabalaSubSQL[]>((resolve, reject) => {
			this._ss
				.getLikutMekabelByTeudaNumSub({ TeudaNum, TeudaType })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						console.log(res);
						if (typeof res === 'string') {
							return reject(res);
						}

						resolve(res.recordset);
					},
					error: err => {
						console.log(err);
						reject();
					},
				});
		});
	}

	async checkIfTruckTaskCompleted(task: TruckLoadingDisplay | TruckDistributionTaskDisplayExtended) {
		const docnumArray = Object.keys(task.teudot);

		if (this.isTruckDistribution) {
			return await new Promise<false | string>((resolve, reject) => {
				this._truckDistribution
					.getOneMainTruckDistributionTask({ docnums: docnumArray })
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							console.log(res);
							if (typeof res === 'string') {
								return reject(res);
							}

							const isAlreadyOpen = res.recordset.find(task => task.OpenedBy && task.OpenedBy !== '0' && task.OpenedBy !== this._ss.AgentIdConnected);

							if (isAlreadyOpen) {
								return resolve(isAlreadyOpen.OpenedBy);
							}

							resolve(false);
						},
						error: err => {
							console.log(err);
							reject();
						},
					});
			});
		}
		return await new Promise<false | string>((resolve, reject) => {
			this._truckLoading
				.getOneMainTruckLoadingTask({ docnums: docnumArray })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						console.log(res);
						if (typeof res === 'string') {
							return reject(res);
						}

						const isAlreadyOpen = res.recordset.find(task => task.OpenedBy && task.OpenedBy !== '0' && task.OpenedBy !== this._ss.AgentIdConnected);

						if (isAlreadyOpen) {
							return resolve(isAlreadyOpen.OpenedBy);
						}

						resolve(false);
					},
					error: err => {
						console.log(err);
						reject();
					},
				});
		});
	}

	async getOneTruckTaskFromLoadingSub(docnums: string[]) {
		return await new Promise<TruckLoadingSubSQL[]>((resolve, reject) => {
			this._truckLoading
				.getOneSubTruckLoadingTask({ docnums })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') {
							return reject();
						}

						resolve(res.recordset);
					},
					error: err => {
						console.log(err);
						reject();
					},
				});
		});
	}

	async getOneOrMoreTruckTaskFromDistributionSub(docnums: string[]) {
		return await new Promise<TruckDistributionSubSQL[]>((resolve, reject) => {
			this._truckDistribution
				.getOneOrMoreSubTruckDistributionTask({ docnums })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') {
							return reject();
						}

						resolve(res.recordset);
					},
					error: err => {
						console.log(err);
						reject();
					},
				});
		});
	}

	async getOneTaskFromLikutSub(docnums: string[]) {
		return await new Promise<LikutSubSQL[]>((resolve, reject) => {
			this._ss
				.getLikutByTeudaNumSub(docnums)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') {
							return reject();
						}

						resolve(res.recordset);
					},
					error: err => {
						console.log(err);
						reject();
					},
				});
		});
	}

	async markLikutAsOpened(likut: LikutDisplayMain): Promise<void> {
		return await new Promise((resolve, reject) => {
			const teudaNums = Object.keys(likut.teudot);
			this._ss
				.getLikutByTeudaNumMain(teudaNums, 'mark')
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') {
							return reject();
						}
						resolve();
					},
					error: () => {
						reject();
					},
				});
		});
	}

	async markTruckTaskAsOpenedOrFree(DocNum: string[], OpenedBy = this._ss.AgentIdConnected): Promise<void> {
		if (this.isTruckDistribution) {
			return await new Promise((resolve, reject) => {
				this._truckDistribution
					.markTaskAsOpenedByAgent({ DocNum, OpenedBy })
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							if (typeof res === 'string') {
								return reject();
							}
							resolve();
						},
						error: () => {
							reject();
						},
					});
			});
		}
		return await new Promise((resolve, reject) => {
			this._truckLoading
				.markTaskAsOpenedByAgent({ DocNum, OpenedBy })
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'string') {
							return reject();
						}
						resolve();
					},
					error: () => {
						reject();
					},
				});
		});
	}

	refreshSubject(
		type: 'truckLoadingDisplay' | 'truckLoadingTotals' | 'truckDistributionDisplay' | 'truckDistributionTotals' | 'teudotNewRouteDisplay' | 'totalLikutNewRoute' | 'teudotMekabelDisplay',
	) {
		if (type === 'teudotNewRouteDisplay' || type === 'totalLikutNewRoute') {
			this.handleRefreshLikutSubjectsWithNewRoutes(type);
			return;
		}

		if (type === 'truckLoadingDisplay' || type === 'truckLoadingTotals') {
			this.handleRefreshTruckLoadingSubjects(type);
			return;
		}

		if (type === 'truckDistributionDisplay' || type === 'truckDistributionTotals') {
			this.handleRefreshTruckDistributionSubjects(type);
		}

		if (type === 'teudotMekabelDisplay') {
			this.handleRefreshLikutMekabelSubjects('teudotMekabelDisplay');
		}
	}

	handleRefreshLikutSubjectsWithNewRoutes(type: 'teudotNewRouteDisplay' | 'totalLikutNewRoute') {
		const values: LikutDisplayMain[] = Object.entries(this.allTeudotNewRouteObject$.value).map(([clientidWithCommaAndAddress, teudot]) => {
			const innerValues = Object.values(teudot),
				clientid = clientidWithCommaAndAddress.split(',')[0],
				clientName = innerValues[0].Client_Name,
				address = innerValues[0].Address,
				city = innerValues[0].City,
				status = innerValues[0].LikutStatus,
				quantitiesMatch = innerValues[0].QuantitiesMatch;
			let totalRow = 0,
				totalTeudot = 0;

			for (const teuda of innerValues) {
				totalRow += +teuda.TotalLines;
				totalTeudot++;
			}

			const finalValues: LikutDisplayMain = { clientid, totalRow, totalTeudot, teudot, clientName, status, address, city, quantitiesMatch: !!quantitiesMatch };

			return finalValues;
		});

		if (type === 'teudotNewRouteDisplay') {
			const clientSearchValue: string = this.form.controls.ClientSearch.value?.toLowerCase?.();
			const len = clientSearchValue?.length;
			const clients = !len
				? this.AllClients
				: Object.values(this.AllClients)
						.filter(client => {
							if (len && len <= 2) {
								if ((client.clientName ?? '').toLowerCase().slice(0, len) === clientSearchValue || (client.clientId ?? '').toLowerCase().slice(0, len) === clientSearchValue) {
									return true;
								}
								return false;
							}
							if (len > 2) {
								const splitValue = clientSearchValue.split(' ');

								let counter = 0;

								const clientNameWithoutSpaces = client.clientName.replace(/\s/g, '').toLowerCase(),
									clientidWitoutSpaces = client.clientId.replace(/\s/g, '').toLowerCase();

								// V8 optimizes this if statement
								if (typeof clientNameWithoutSpaces === 'string' && typeof clientidWitoutSpaces === 'string') {
									for (let j = 0; j < splitValue.length; j++) {
										if ((clientNameWithoutSpaces && clientNameWithoutSpaces.indexOf(splitValue[j]) > -1) || (clientidWitoutSpaces && clientidWitoutSpaces.indexOf(splitValue[j]) > -1)) {
											counter++;
										}
									}
								}

								if (counter === splitValue.length) {
									return true;
								}

								return false;
							}
							return true;
						})
						.reduce((p, c) => {
							p[c.clientId] = c;
							return p;
						}, {});

			if (this._likut.isLikutAppActiveWithAlternateRoutes$.value) {
				console.log(values);
				this.allTeudotNewRouteTeudotDisplay$.next(
					values.filter(cl => {
						return cl.totalRow && clients[cl.clientid];
					}),
				);
				return;
			}
		}

		if (type === 'totalLikutNewRoute') {
			const filtered = values.filter(cl => cl.totalRow);
			this.totalTasks$.next(filtered.length);
			const completedStatusArray = new Set();
			if (this._likut.isMelaketAgent) {
				completedStatusArray.add('2');
			}
			if (this._likut.isCheckerAgent) {
				completedStatusArray.add('3');
			}
			this.totalTasksCompleted$.next(filtered.filter(likut => completedStatusArray.has(likut.status)).length);
			return;
		}
	}
	handleRefreshLikutMekabelSubjects(type: 'teudotMekabelDisplay') {
		const values: LikutMekabelDisplay[] = Object.entries(this.allTeudotLikutMekabelObject$.value).map(([teudanum, teudot]) => {
			const innerValues = Object.values(teudot),
				firstTeuda = innerValues[0],
				clientid = firstTeuda.Client_Id,
				clientName = firstTeuda.Client_Name,
				status = firstTeuda.TeudaStatus,
				doctype = { num: firstTeuda.TeudaType, name: '' },
				refrenceNumber = firstTeuda.TeudaNum,
				totalRow = firstTeuda.TotalLines;

			const finalValues: LikutMekabelDisplay = {
				clientid,
				totalRow,
				clientName,
				status,
				doctype,
				refrenceNumber,
				rows: [],
				supplier: { id: '', name: '' },
				warehouse: { id: '', name: '' },
			};

			return finalValues;
		});

		if (type === 'teudotMekabelDisplay') {
			const clientSearchValue: string = this.form.controls.ClientSearch.value?.toLowerCase?.();
			const len = clientSearchValue?.length;
			const clients = !len
				? this.AllClients
				: Object.values(this.AllClients)
						.filter(client => {
							if (len && len <= 2) {
								if ((client.clientName ?? '').toLowerCase().slice(0, len) === clientSearchValue || (client.clientId ?? '').toLowerCase().slice(0, len) === clientSearchValue) {
									return true;
								}
								return false;
							}
							if (len > 2) {
								const splitValue = clientSearchValue.split(' ');

								let counter = 0;

								const clientNameWithoutSpaces = client.clientName.replace(/\s/g, '').toLowerCase(),
									clientidWitoutSpaces = client.clientId.replace(/\s/g, '').toLowerCase();

								// V8 optimizes this if statement
								if (typeof clientNameWithoutSpaces === 'string' && typeof clientidWitoutSpaces === 'string') {
									for (let j = 0; j < splitValue.length; j++) {
										if ((clientNameWithoutSpaces && clientNameWithoutSpaces.indexOf(splitValue[j]) > -1) || (clientidWitoutSpaces && clientidWitoutSpaces.indexOf(splitValue[j]) > -1)) {
											counter++;
										}
									}
								}

								if (counter === splitValue.length) {
									return true;
								}

								return false;
							}
							return true;
						})
						.reduce((p, c) => {
							p[c.clientId] = c;
							return p;
						}, {});

			this.allTeudotLikutMekabelTeudotDisplay$.next(
				values.filter(cl => {
					return cl.totalRow && clients[cl.clientid];
				}),
			);
			return;
		}
	}

	handleRefreshTruckDistributionSubjects(type: 'truckDistributionDisplay' | 'truckDistributionTotals') {
		const values = Object.entries(this.allTruckDistributionTeudotObject$.value).map(([clientidWithComma, teudotObject]) => {
			// clientid is docnum if LoadingAndDistribution_GroupByDocNum === '1'
			const innerValues = Object.values(teudotObject),
				clientid = clientidWithComma.split(',')[0],
				totalTeudot = innerValues.length,
				clientName = innerValues[0].ClientName,
				status = innerValues[0].PODStatus,
				date = innerValues[0].PODDate.slice(0, 10),
				totalRow = innerValues.reduce((p, c) => p + +c.RowsNum, 0),
				phone = innerValues[0].Phone,
				address = (innerValues[0].Address && innerValues[0].Address && innerValues[0].Address + ', ' + innerValues[0].City) || innerValues[0].Address || innerValues[0].City || '',
				extra5Array: TruckDistributionExtra5Array[] = innerValues.filter(line => line.Extra5).map(line => ({ DocNum: line.DocNum, Extra5: line.Extra5 })),
				groupB = TEUDA_GROUP_B.includes(innerValues[0].TeudaType),
				colorForBackground = innerValues[0].Color,
				storageKey = `Task-${clientid},${status},${address}${date}${groupB || ''}`;

			if (!this._truckDistribution.parametersRecord.considerExtra5) {
				this.itemSize = 118;
				for (const line of extra5Array) {
					line.Extra5 = '';
				}
			}

			const finalValues: TruckDistributionTaskDisplayExtended = {
				clientid,
				totalTeudot,
				teudot: teudotObject,
				totalRow,
				clientName,
				status,
				address,
				phone,
				taskID: '',
				storageKey,
				date,
				groupB,
				colorForBackground,
				extra5Array,
			};
			if (innerValues[0].TypeofDoc) {
				finalValues.typeofDoc = innerValues[0].TypeofDoc;
			}

			return finalValues;
		});

		if (type === 'truckDistributionDisplay') {
			const clientSearchValue: string = this.form.controls.ClientSearch.value?.toLowerCase?.();
			const len = clientSearchValue?.length;
			const clients = !len
				? this.AllClients
				: Object.values(this.AllClients)
						.filter(client => {
							if (len < 3) {
								return (client.clientName ?? '').toLowerCase().slice(0, len) === clientSearchValue || (client.clientId ?? '').toLowerCase().slice(0, len) === clientSearchValue;
							}

							const splitValue = clientSearchValue.split(' ');

							let counter = 0;

							const clientNameWithoutSpaces = client.clientName.replace(/\s/g, '').toLowerCase(),
								clientidWitoutSpaces = client.clientId.replace(/\s/g, '').toLowerCase();

							// V8 optimizes this if statement
							if (typeof clientNameWithoutSpaces === 'string' && typeof clientidWitoutSpaces === 'string') {
								for (let i = 0; i < splitValue.length; i++) {
									if ((clientNameWithoutSpaces && clientNameWithoutSpaces.indexOf(splitValue[i]) > -1) || (clientidWitoutSpaces && clientidWitoutSpaces.indexOf(splitValue[i]) > -1)) {
										counter++;
									}
								}
							}

							if (counter === splitValue.length) {
								return true;
							}

							return false;
						})
						.reduce((p, c) => {
							p[c.clientId] = c;
							return p;
						}, {});

			this.allTruckDistributionTeudotDisplay$.next(len ? values.filter(v => clients[v.clientid]) : values);
			return;
		}

		if (type === 'truckDistributionTotals') {
			this.totalTasks$.next(values.length);
			const completedStatusArray = new Set('1');

			this.totalTasksCompleted$.next(values.filter(likut => completedStatusArray.has(likut.status)).length);
			return;
		}
	}
	handleRefreshTruckLoadingSubjects(type: 'truckLoadingDisplay' | 'truckLoadingTotals') {
		const values = Object.entries(this.allTruckLoadingTeudotObject$.value).map(([clientidWithComma, teudotObject]) => {
			// clientid is docnum if LoadingAndDistribution_GroupByDocNum === '1'
			const innerValues = Object.values(teudotObject),
				clientid = clientidWithComma.split(',')[0],
				totalTeudot = innerValues.length,
				clientName = innerValues[0].ClientName,
				status = innerValues[0].LoadingStatus,
				date = innerValues[0].LoadDate.slice(0, 10),
				totalRow = innerValues.reduce((p, c) => p + +c.RowsNum, 0),
				groupB = TEUDA_GROUP_B.includes(innerValues[0].TeudaType),
				colorForBackground = innerValues[0].Color,
				address = (innerValues[0].Address && innerValues[0].Address && innerValues[0].Address + ', ' + innerValues[0].City) || innerValues[0].Address || innerValues[0].City || '';

			const finalValues = { clientid, totalTeudot, teudot: teudotObject, totalRow, clientName, status, address, groupB, date, colorForBackground };

			return finalValues;
		});

		if (type === 'truckLoadingDisplay') {
			const clientSearchValue: string = this.form.controls.ClientSearch.value?.toLowerCase?.();
			const len = clientSearchValue?.length;
			const clients = !len
				? this.AllClients
				: Object.values(this.AllClients)
						.filter(client => {
							if (len < 3) {
								return (client.clientName ?? '').toLowerCase().slice(0, len) === clientSearchValue || (client.clientId ?? '').toLowerCase().slice(0, len) === clientSearchValue;
							}

							const splitValue = clientSearchValue.split(' ');

							let counter = 0;

							const clientNameWithoutSpaces = client.clientName.replace(/\s/g, '').toLowerCase(),
								clientidWitoutSpaces = client.clientId.replace(/\s/g, '').toLowerCase();

							// V8 optimizes this if statement
							if (typeof clientNameWithoutSpaces === 'string' && typeof clientidWitoutSpaces === 'string') {
								for (let i = 0; i < splitValue.length; i++) {
									if ((clientNameWithoutSpaces && clientNameWithoutSpaces.indexOf(splitValue[i]) > -1) || (clientidWitoutSpaces && clientidWitoutSpaces.indexOf(splitValue[i]) > -1)) {
										counter++;
									}
								}
							}

							if (counter === splitValue.length) {
								return true;
							}

							return false;
						})
						.reduce((p, c) => {
							p[c.clientId] = c;
							return p;
						}, {});

			this.allTruckLoadingTeudotDisplay$.next(len ? values.filter(v => clients[v.clientid]) : values);
			return;
		}

		if (type === 'truckLoadingTotals') {
			this.totalTasks$.next(values.length);
			const completedStatusArray = new Set('1');

			this.totalTasksCompleted$.next(values.filter(likut => completedStatusArray.has(likut.status)).length);
			return;
		}
	}

	chosenWarehouseAndClient = new BehaviorSubject<NewLikutMekabelWarehouseFormResult | null>(null);

	handleOpenWarehouseDialog() {
		const clients = localStorage.getItem(`AllClients`);

		if (!clients) {
			alert(this._text.store.noClientsForMekabelError);
			return;
		}

		const anyClientsSaved = JSON.parse(decompress(clients));

		if (!anyClientsSaved?.length) {
			alert(this._text.store.noClientsForMekabelError);
			return;
		}

		const dialogRef = this.dialog.open(LikutClientsWarehouseDialogComponent, { width: '80vw' });

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: NewLikutMekabelWarehouseFormResult | 'cancel' | undefined) => {
				this._likut.handleUpdateOpenMekabelTaskWithSupplierAndWarehouseAndResolveIfShouldRouteToLikutMekabel(result);
			});
	}

	async handleClicked(task: TruckLoadingDisplay | TruckDistributionTaskDisplayExtended | LikutDisplayMain | LikutMekabelDisplay, mode: SubAppModes) {
		if (mode === 'likut') {
			if (!this._likut.isLikutAppActiveWithAlternateRoutes$.value) throw 'New Routes likut app isnt active, did you mean to do this?';
			this.handleLikutNewRoutesClicked(task as LikutDisplayMain);
			return;
		}

		if (mode === 'likut-mekabel' || 'supplier' in task) {
			this.handleLikutMekabelClicked(task as LikutMekabelDisplay);
			return;
		}

		if (!this._truckLoading.isTruckLoadingOrTruckDistributionAppsActive$.value) throw 'TruckLoading and TruckDistribution app arent active, did you mean to do this?';
		if (this.isOnlyForPassword) {
			const { Address, City, ClientID } = Object.values(task.teudot as Record<DocNum, TruckLoadingMainSQL>)[0];

			const password = await this._util.getPasswordPIN({ AgentID: Address + City, CompanyNumber: ClientID });

			prompt('הסיסמה היא', password);
			return;
		}
		if (mode === 'truck-distribution') {
			this.handleTruckDistributionClicked(task as TruckDistributionTaskDisplayExtended);
			return;
		}

		this.handleTruckLoadingClicked(task as TruckLoadingDisplay);
	}

	async handleLikutMekabelClicked(task: LikutMekabelDisplay) {
		try {
			if (TaskTextVisibilityPipe.prototype.transform(task.status, this.Admin)) {
				return;
			}

			const freshTask = await this.getOneMekabelTaskByTeudaNum(task);

			task.rows = freshTask;

			this._likut.likutMekabelOpenedState = { main: task, extras: { image: '', name: '', remark: '', signature: '' } };

			this._router.navigate(['likut', 'tasks', 'mekabel']);
		} catch (error) {
			console.log(error);
			this._likut.resetLikutState();
			alert(this._text.store.taskConnectionError);
		}
	}

	async handleLikutNewRoutesClicked(task: LikutDisplayMain) {
		try {
			console.log(task);
			if ((task.status === '2' && this._likut.isMelaketAgent) || (task.status === '3' && this._likut.isCheckerAgent)) {
				return;
			}
			const likutRespose = await this.checkIfLikutNewRoutesCompleted(task);

			if (likutRespose) {
				this._alert.customAlert(`${this._text.store.alreadyOpenedByMsg} ${likutRespose}`);
				return;
			}

			const teudanums = Object.keys(task.teudot);

			const likutSub = await this.getOneTaskFromLikutSub(teudanums);

			this._likut.likutOpenedNewRoutesState = { task, sub: likutSub };
			if (!this.isLocal) {
				await this.markLikutAsOpened(task);
			}

			this._router.navigate(['likut', 'tasks']);
		} catch (error) {
			console.log(error);
			this._likut.resetLikutState();
			alert(this._text.store.taskConnectionError);
		}
	}

	async handleTruckDistributionClicked(task: TruckDistributionTaskDisplayExtended) {
		if (task.typeofDoc === '1') {
			//popup receipt
		}

		if (TaskTextVisibilityPipe.prototype.transform(task.status, this.Admin) || this.savedOrders$.value[task.clientid + task.status + task.address + task.date + (task.groupB || '')]) return;

		try {
			// const truckReponse = await this.checkIfTruckTaskCompleted(task);

			// if (truckReponse !== false) {
			// 	this._alert.customAlert(`${this._text.store.alreadyOpenedByMsg} ${truckReponse}`);
			// 	return;
			// }

			const docnums = Object.keys(task.teudot);
			let truckSub: TruckDistributionSubSQL[] = [];

			try {
				truckSub = await this.getOneOrMoreTruckTaskFromDistributionSub(docnums);
			} catch (error) {
				truckSub = this._truckDistribution.savedTasks$.value
					.filter(savedTask => task.teudot[savedTask.main.DocNum])
					.map(task => task.sub)
					.flat();
			}

			if (!truckSub.length) {
				await this.markTruckTaskAsOpenedOrFree(docnums, null);
				alert(this._text.store.taskConnectionErrorSpecific);
				return;
			}

			this._truckDistribution.truckDistributionOpened = { task, sub: truckSub, finishOptions: this._truckDistribution.truckDistributionOpened.finishOptions };

			try {
				await this.markTruckTaskAsOpenedOrFree(docnums);
			} catch (err) {}

			this._router.navigate(['truck-distribution', 'tasks']);
		} catch (error) {
			console.log(error);
			this._truckLoading.truckLoadOpened.sub = [];
			alert(this._text.store.taskConnectionError);
		}
	}

	async handleTruckLoadingClicked(task: TruckLoadingDisplay) {
		if (TaskTextVisibilityPipe.prototype.transform(task.status, this.Admin)) return;

		try {
			const truckReponse = await this.checkIfTruckTaskCompleted(task);

			if (truckReponse !== false) {
				this._alert.customAlert(`${this._text.store.alreadyOpenedByMsg} ${truckReponse}`);
				return;
			}

			const docnums = Object.keys(task.teudot);

			const truckSub = await this.getOneTruckTaskFromLoadingSub(docnums);

			if (!truckSub.length) {
				await this.markTruckTaskAsOpenedOrFree(docnums, null);
				alert(this._text.store.taskConnectionErrorSpecific);
				return;
			}

			this._truckLoading.truckLoadOpened = { task, sub: truckSub };
			await this.markTruckTaskAsOpenedOrFree(docnums);

			this._router.navigate(['truck-loading', 'tasks']);
		} catch (error) {
			console.log(error);
			this._truckLoading.truckLoadOpened.sub = [];
			alert(this._text.store.taskConnectionError);
		}
	}

	async clearSavedOrders(options?: { single: string }) {
		let keys = Object.keys(localStorage).filter(key => key.slice(0, 4) === 'Task');

		if (options?.single) {
			keys = [options.single];
		}

		for (const key of keys) {
			localStorage.removeItem(key);

			if (this.isTruckDistribution) {
				try {
					await this._indexedDB.deleteImage(key);
					await this._indexedDB.deleteTruckDistributionTask(key);
				} catch (err) {
					console.log(err);
				}
			}
		}
	}

	async buildAndMarkOpenTasks() {
		if (!this.isTruckDistribution) return;

		let savedTruckDistribution: StoredDistributionTaskIDB[];

		try {
			this._loading.changeMessageBackToDefault();
			this._loading.changeLoadingStatuses({ isLoading: true });
			savedTruckDistribution = await this._indexedDB.getAllTruckDistributionTasks();
		} catch (error) {
			this._loading.changeLoadingStatuses({ isLoading: false });
			alert('לא ניתן להביא משימות שמורות');
			throw '';
		}

		this._loading.changeLoadingStatuses({ isLoading: false });
		this.savedOrders$.next({});

		if (!savedTruckDistribution?.length) return;

		this.savedOrders$.next(
			savedTruckDistribution.reduce((p, c: StoredDistributionTaskIDB) => {
				// todo add poddate
				p[c.main.clientid + c.main.status + c.main.address + c.main.date + (c.main.groupB || '')] = c;
				return p;
			}, {}),
		);

		console.log(this.savedOrders$.value, this.allTruckDistributionTeudotDisplay$.value);
	}

	openSavedOrdersPopup() {
		this.sendTheFollowingOrders$.next(Object.values(this.savedOrders$.value));
	}

	async startSendingSavedOrders() {
		const array = this.sendTheFollowingOrders$.value;

		for (const task of array) {
			if (task.main.sendingSuccess) continue;
			task.main.isSending = true;
			this.sendTheFollowingOrders$.next(array);

			try {
				await this.sendTruckDistributionTask(task);
				task.main.sendingSuccess = true;

				this.clearSavedOrders({ single: task.main.storageKey });
			} catch (err) {
				task.main.sendingSuccess = false;
			}
			task.main.isSending = false;
			this.sendTheFollowingOrders$.next(array);
			this.buildAndMarkOpenTasks();
		}

		await this.updateTruckDistributionTasks();
	}

	async sendTruckDistributionTask(savedTask: StoredDistributionTask) {
		let body: TruckDistributionFinishBody[];
		const { sub, main } = savedTask;
		// TODO write proper types
		const teudotArray = Object.values(sub as unknown as TruckDistributionSubSQL[]);

		// const teudotArray = Object.values(this._truckDistribution.truckDistributionOpened.sub);

		body = teudotArray.map(task => ({
			table: 'sub',
			DocNum: task.DocNum,
			ProductID: task.ProductID,
			PODStatus: this._truckDistribution.resolvePODStatus(task),
			PODQuantity: task.PODQuantity,
			PODPackQTY: task.PODPackQTY,
		}));

		let storedImages: ImageStored[] = [];

		try {
			storedImages = await this._indexedDB.getImage(main.storageKey);
		} catch (error) {
			console.log(error);
		}

		if (storedImages.length) {
			for (const image of storedImages[0].imageFile) {
				try {
					await this._truckDistribution.handleFileUpload(image);
				} catch (error) {
					throw new Error();
				}
			}
		}

		const images = [1, 2, 3, 4]
			.map(n => ['ImageName' + n, n])
			.reduce((p, c) => {
				p[c[0]] = storedImages[(c[1] as number) - 1] ? storedImages[(c[1] as number) - 1].imageFile[0].name : '';
				return p;
			}, {} as Record<Images, string>);

		let Coordinates = {};

		if (savedTask.finishOptions?.Coordinates) {
			Coordinates = { Coordinates: savedTask.finishOptions.Coordinates };
		}

		const taskBody: TruckDistributionFinishBody[] = Object.values(main.teudot).map(task => ({
			table: 'main',
			DocNum: task.DocNum,
			DocID: task.DocID,
			Comment: savedTask.finishOptions?.remarks || '',
			SignatureString: savedTask.finishOptions?.signature || '',
			PODDate: translateDateForSQLServer().toISOString(),
			PODStatus: String(Number(savedTask.main.status || 0) + 1) as TruckModeStatuses,
			PODID: savedTask.main.taskID,
			// ...extras,
			...Coordinates,
			...images,
		}));

		return await new Promise<void>((resolve, reject) => {
			this._truckDistribution.finishTruckDistributionTask(body.concat(taskBody)).subscribe({
				next: res => {
					if (typeof res === 'string') {
						return reject();
					}
					return resolve();
				},
				error: () => {
					return reject();
				},
			});
		});
	}

	toggleReceiptPopup(mode: 'open' | 'close') {
		if (mode === 'open') {
			this.isNewReceiptPopupOpen$.next(true);
			return;
		}

		this.isNewReceiptPopupOpen$.next(false);
	}

	closeReceiptPopup() {
		if ((!this._receipts.receiptSent && this._receipts.chequesArray.length) || this._cc.hasAnyFormValues()) {
			const decision = confirm('האם אתה בטוח שתרצה לסגור את מסך הקבלות? הפרטים שהוכנסו לא יישמרו');
			if (!decision) return;
		}

		history.back();

		this.handleReceiptsReset();
	}

	handleReceiptsReset() {
		this._receipts.lastDocnumSent = null;
		this._receipts.emailForReceipt = null;
		this._receipts.receiptSent = false;
		this._receipts.sendingErroredWithTimeoutAndCredit = false;
		this._receipts.chequesArray = [];
		document.body.classList.remove('no-scroll');
		this._receipts.shouldOpenEmailPopup$.next(false);
		this._receipts.emailOkToContinue$.next(false);
		this._cc.resetFormValues();
	}

	handleReceiptPopupCloseFromChild(value: ClosePopupValues) {
		if (value === 'close') {
			this.closeReceiptPopup();
		}

		if (value === 'error') {
			this.receiptSendFailed();
		}

		this._receipts.emailForReceipt = null;
	}
	receiptSendFailed() {
		alert('ישנה תקלה בשרת או בחיבור שלכם, אנא נסו לשלוח קבלה זו ממסך הלקוחות במועד מאוחר יותר.');
		history.back();
		this.handleReceiptsReset();
	}
	ClearClientSearch() {
		this.form.controls.ClientSearch.reset();
		this.SearchClient();
	}

	SearchClient() {
		if (this.isTruckLoading) {
			this.refreshSubject('truckLoadingDisplay');
			return;
		}
		if (this.isTruckDistribution) {
			this.refreshSubject('truckDistributionDisplay');
			return;
		}
		this.refreshSubject('teudotNewRouteDisplay');
	}

	resolvePageMode() {
		let page: SubAppModes = 'likut';

		if (this.isTruckLoading) page = 'truck-loading';
		if (this.isTruckDistribution) page = 'truck-distribution';

		this.pageMode$.next(page);
	}

	showHideMenu(value: boolean): void {
		this._ss.burgerMenu = value;

		if (value) {
			this._ss.headerAbsolute = value;
			this._ss.burgerMenuNoScroll = value;
		} else {
			setTimeout(() => {
				this._ss.headerAbsolute = value;
				this._ss.burgerMenuNoScroll = value;
			}, 1000);
		}
	}

	showSortPopup = false;
	showSortList = false;
	headerAbsolute = false;

	SupplyDateTeuda = this.Date.toISOString().toString().slice(0, 10);

	stopPropogation(e: MouseEvent) {
		e.stopPropagation();
	}
}

type Images = 'ImageName1' | 'ImageName2' | 'ImageName3' | 'ImageName4';
