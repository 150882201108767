<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="header header_app-shop-return" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToHome()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /> <span style="color: var(--secondary-color); font-size: 14px">חזרה</span>
					</div>

					<div class="header__search">
						<form [formGroup]="form" (keyup)="SearchClient()">
							<input
								class="header__search-input"
								type="text"
								[placeholder]="_text.store.search"
								formControlName="ClientSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="clientsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
					</div>

					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- start shop/return markup -->
		<section class="shop">
			<div class="content">
				<div
					class="shop__filters row flex-column"
					[ngClass]="{
						'main-content_absolute': !_ss.headerAbsolute
					}"
				>
					<div class="header__block">
						<div class="filter-sort__row">
							<span class="filter-sort__row-name">{{ dateString }} </span>
						</div>
						<div class="filter-sort__row" *ngIf="this._ss.CompanyName !== null">
							<span class="filter-sort__row-name">{{ this._ss.CompanyName }}</span>
							<div class="filter-sort__row-img image"></div>
						</div>

						<!-- <div class="filter-sort__row" *ngIf="isTruckDistribution && isAnySavedOrder$ | async">
							<div class="filter-sort__row-name" (click)="openSavedOrdersPopup()">שלח הכל</div>
						</div> -->

						<div class="filter-sort__row filter-sort__row_double">
							<div class="filter-sort__row-block">
								<span class="filter-sort__row-name">
									{{ _text.store.mainTasks }}
									<span>{{ (totalTasksCompleted$ | async) || 0 }}</span> / <span>{{ (totalTasks$ | async) || 0 }}</span></span
								>
							</div>
						</div>
					</div>
				</div>

				<div class="shop__cards row">
					<cdk-virtual-scroll-viewport [itemSize]="itemSize" [minBufferPx]="itemSize * 5" [maxBufferPx]="itemSize * 5 * 2" class="example-viewport">
						<div
							class="shop__card shop__card-distribution"
							appSetTextColorToFitBackgroundContrast
							style="place-items: center"
							[ngStyle]="{ 'background-color': task.Color || null }"
							*cdkVirtualFor="let task of tasks$"
							(click)="handleClicked(task)"
						>
							<div class="bold text-overflow row" style="text-align: center; min-height: 1.6em; max-height: 2em">
								{{ task.Client_Name + (task.ClientId ? ' - ' + task.ClientId : '') }}
							</div>
							<div class="shop__card-info-block">
								<div class="shop__card-info-text shop__card-info-text_title">מספר זיהוי רקלמציה</div>
								<div class="shop__card-info-text">{{ task.RekID }}</div>
							</div>
							<div class="bold text-overflow row" style="text-align: center; min-height: 1.6em; max-height: 2em">
								{{ task.ProductId }}
							</div>
							<div class="bold text-overflow row" style="text-align: center; min-height: 1.6em; max-height: 2em">
								{{ task.PRIStatus }}
							</div>
						</div>
					</cdk-virtual-scroll-viewport>
				</div>
			</div>
		</section>
		<!-- end shop/return markup -->

		<!-- <section class="shop-delivery" *ngIf="Loading === true">
			<div class="container">
				<div class="shop-delivery__row row">
					<div class="shop-delivery__photo image">
						<img src="/assets/images/shop-return/delivery-icon.gif" alt="photo" />
					</div>
					<h2 class="shop-delivery__title shop-delivery__title_margin">{{ status$ | async }}</h2>
					<h2 class="shop-delivery__title">{{ this.Date }}</h2>
					<h2 class="shop-delivery__title"><span class="shop-delivery__title-bold">נא לא לסגור את הדפדפן</span> סגירת הדפדפן תצריך סנכרון חדש</h2>
				</div>
			</div>
		</section> -->

		<!-- markup for product popup -->
		<!-- <div class="popup" *ngIf="LoadingClient === true" style="z-index: 333">
			<div class="overlay"></div>
			<div class="product-popup popup__content">
				<div class="order-product__item">
					<div class="order-process__row order-process__row_success">
						<div class="row">
							<h2 class="order-process__title" style="text-align: center">טוען אנא המתן</h2>
						</div>
						<div class="row"></div>
						<div class="row">
							<div class="order-process__image image">
								<img *ngIf="_ss.isConnectionFastEnough()" src="/assets/images/basket/process/success.gif" alt="photo" />
							</div>
						</div>
						<div class="row"></div>
					</div>
				</div>
			</div>
		</div> -->
	</div>

	<!-- main menu markup -->
	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img src="{{ this._ss.CustomerInformation?.CustImageDomain }}/companylogo.jpg" crossorigin="anonymous" alt="mobility" onerror="this.onerror=null; this.src=MOBILITY_LOGO" />
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ this._ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ this._ss.AgentIdConnected }}</div>
				</div>
			</div>
			<!-- <ng-container *ngIf="hasReports">
				<div class="main-menu__border"></div>
				<div class="main-menu__row">
					<div class="main-menu__col" (click)="MoveToDohot()">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-statistic.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">{{ _text.store.reports }}</div>
					</div>
				</div>
			</ng-container> -->

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="handleUpdateTasks()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">{{ _text.store.updateTasks }}</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="BackToHome()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דף הבית</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__col">
				<div class="main-menu__col-title">{{ _ss.VersionNumber }}-{{ Admin }}-{{ _ss.CustomerInformation?.CustName }}</div>
			</div>
		</div>
	</div>
	<!-- end main menu markup -->
</div>

<!-- start markup for product detail add popup -->

<app-menu-button></app-menu-button>
