import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toNormalNumber',
	pure: true
})
export class ToNormalNumberPipe implements PipeTransform {
	transform(value: string | number, vat?: number, group?: boolean): string {
		const num = vat ? +value + +value * (vat / 100) : value;
		return Intl.NumberFormat('he-IL', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
			useGrouping: !!group,
		}).format(+num);
	}
}
