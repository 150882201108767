import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeviceInfoService } from 'src/app/services/device-info.service';

@Component({
	selector: 'app-mobilitydashbord-filter-box',
	templateUrl: './mobilitydashbord-filter-box.component.html',
	styleUrls: ['./mobilitydashbord-filter-box.component.scss'],
})
export class MobilitydashbordFilterBoxComponent implements OnInit {
	@Input() form: FormGroup;
	@Input() formControlNameFilter: string;
	@Input() controlValues: string[];
	@Input() title: string;


	constructor(public device:DeviceInfoService) {}

	ngOnInit(): void {}
}
