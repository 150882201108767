<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToAdmin()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>

			<div class="header__title col">{{ title$ | async }}</div>

			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="showSearch$ | async">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" style="margin-top: 50px">
	<div class="content">

		<div class="report-table__row report-table__row_between flex-column" style="margin-top: 50px">
			<h2 style="text-align: center;" *ngIf="Third || Fourth">סוכן מס' {{ selectedAgent + (agentName.length ? ', ' + agentName : '') }}</h2>
			<h2 style="text-align: center" *ngIf="Fifth || Sixth">{{ clientName }}</h2>
			<h2 style="text-align: center" *ngIf="Sixth">תעודה מספר {{ teudaNum }}</h2>
			<!-- <div *ngIf="HasMultipleCompanys > 1">
				<form [formGroup]="form">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div> -->

			<div class="flex-row" [ngClass]="{ hidden: openedCompanyIndex === null && First }" *ngIf="!loading">
				<div class="box" *ngIf="!Sixth">
					<b>סה"כ</b>
					<span>
						{{ displayTotal$ | async | number : '1.0-2' }}
					</span>
				</div>
				<div class="box" *ngIf="!Second && !Sixth">
					<b>ממוצע</b>
					<span>
						{{ displayAverage$ | async | number : '1.0-2' }}
					</span>
				</div>
				<div class="box">
					<app-excel-button *ngIf="!First" [filename]="excelFilename$ | async"></app-excel-button>
				</div>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<h1 *ngIf="loading">טוען...</h1>
			<h1
				*ngIf="
					!loading &&
					((First && AgentsSalesAll && !AgentsSalesAll.length) ||
						(Second && AgentsSalesAllSecond && !AgentsSalesAllSecond.length) ||
						(Third && AgentsSalesAllThird && !AgentsSalesAllThird.length) ||
						(Fourth && AgentsSalesAllFourth && !AgentsSalesAllFourth.length) ||
						(Fifth && AgentsSalesAllFifth && !AgentsSalesAllFifth.length) ||
						(Sixth && OneTeudaInfo && !OneTeudaInfo.length))
				"
			>
				לא נמצאו תוצאות
			</h1>

			<table class="report-table__table" cellspacing="0" *ngIf="!loading && First && AgentsSalesAll?.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">חברה</th>
						<th class="report-table__table-head-name" style="width: 150px">מכירות השנה</th>
						<th class="report-table__table-head-name" style="width: 150px" *ngIf="hasAgentsGoals">יעד שנתי</th>
						<th class="report-table__table-head-name" *ngIf="hasAgentsGoals" style="width: 150px">קצב התקדמות</th>
						<th class="report-table__table-head-name" style="width: 150px">מכירות אשתקד</th>
						<th class="report-table__table-head-name" style="width: 150px">גידול שנתי</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let a of AgentsSalesAll; index as i" class="clickable" (click)="SecondPageAdminAgentsSales(a.cm, a.month)">
						<td class="report-table__table-body-name">
							<div class="flex gap align-center">
								<span>{{ a.isMonth ? (a.month | returnMonthHebrew) : a.CompanyName }}</span>

								<button
									class="order-product__item-info-count-button"
									(click)="handleClickMonthsButton($event, i, a.cm)"
									*ngIf="!a.isMonth && (openedCompanyIndex === null || openedCompanyIndex === i)"
									[ngClass]="{ 'order-product__item-info-count-button_add': isClosed }"
								></button>
							</div>
						</td>
						<td class="report-table__table-body-name">{{ a.tot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" *ngIf="hasAgentsGoals">{{ a.currentYearGoal ? (a.currentYearGoal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name" *ngIf="hasAgentsGoals">{{ a.advancementPercentage ? (a.advancementPercentage | number : '1.0-2' : 'en-US') + '%' : 'אין נתון עדיין' }}</td>
						<td class="report-table__table-body-name">{{ a.ltot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ (a.ahuz | number : '1.0-2' : 'en-US') + '%' }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" dir="rtl" cellspacing="0" #secondTable *ngIf="!loading && Second && AgentsSalesAllSecond?.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('cm', 'Second')" *ngIf="ShowCompanys && ShowCompanys.length > 1">חברה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 250px" (click)="translateForSort(hasAgentsGoals ? 'AgentCode' : 'ag', 'Second')">סוכן</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort(hasAgentsGoals ? 'total' : 'tot', 'Second')">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" *ngIf="hasAgentsGoals" (click)="translateForSort('currentYearGoal', 'Second')">יעד שנתי</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" *ngIf="hasAgentsGoals" (click)="translateForSort('advancementPercentage', 'Second')">
							קצב התקדמות
						</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort(hasAgentsGoals ? 'lastYearTotal' : 'ltot', 'Second')">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort(hasAgentsGoals ? 'growthPercentage' : 'ahuz', 'Second')">גידול שנתי</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body" *ngIf="!hasAgentsGoals; else goalsTableBlockSecond">
					<tr *ngFor="let a of AgentsSalesAllSecond" (click)="ThirdPageAdminAgentsSales(a.ag, a.cm, a.agn)">
						<td class="report-table__table-body-name" *ngIf="ShowCompanys?.length > 1">{{ a.cm }}</td>
						<td class="report-table__table-body-name">{{ a.ag + (a.agn ? ' - ' + a.agn : '') }}</td>
						<td class="report-table__table-body-name">{{ a.tot | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name">{{ a.ltot | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name" data-t="n" [attr.data-v]="a.ahuz">{{ (a.ahuz | number : '1.0-2' : 'en-US') + '%' }}</td>
					</tr>
				</tbody>
				<ng-template #goalsTableBlockSecond>
					<tbody class="report-table__table-body">
						<tr *ngFor="let a of AgentsSalesAllSecondWithGoals$ | async" (click)="ThirdPageAdminAgentsSales(a.AgentCode, a.cm, a.AgentName)">
							<td class="report-table__table-body-name" *ngIf="ShowCompanys?.length > 1">{{ a.CompanyName }}</td>
							<td class="report-table__table-body-name">{{ a.AgentCode + (a.AgentName ? ' - ' + a.AgentName : '') }}</td>
							<td class="report-table__table-body-name">{{ a.total | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name">{{ a.currentYearGoal ? (a.currentYearGoal | number : '1.0-2') : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ a.advancementPercentage ? ((a.advancementPercentage | number : '1.0-2') || 0) + '%' : 'אין נתון עדיין' }}</td>
							<td class="report-table__table-body-name">{{ a.lastYearTotal | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name">{{ a.growthPercentage ? (a.growthPercentage | number : '1.0-2') + '%' : 'אין נתון עדיין' }}</td>
						</tr>
					</tbody>
				</ng-template>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="!loading && Third && AgentsSalesAllThird?.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort(hasAgentsGoals ? 'MM' : 'mm', 'Third')">חודש</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort(hasAgentsGoals ? 'Tot' : 'tot', 'Third')">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" *ngIf="hasAgentsGoals" (click)="translateForSort('currentYearGoal', 'Third')">יעד</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" *ngIf="hasAgentsGoals" (click)="translateForSort('growthPercentage', 'Third')">קצב התקדמות</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort(hasAgentsGoals ? 'Ltot' : 'ltot', 'Third')">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort(hasAgentsGoals ? 'Ahuz' : 'ahuz', 'Third')">גידול חודשי</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('cm', 'Third')" *ngIf="ShowCompanys && ShowCompanys.length > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body" *ngIf="!hasAgentsGoals; else goalsTableBlockThird">
					<tr *ngFor="let a of AgentsSalesAllThird" (click)="FourthPageAdminAgentsSales(a.mm, a.YY, a.ag, a.cm)">
						<td class="report-table__table-body-name" style="width: 150px">{{ a.mm }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ a.tot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ a.ltot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ (a.ahuz | number : '1.0-2' : 'en-US') + '%' }}</td>
						<td class="report-table__table-body-name" style="width: 150px" *ngIf="ShowCompanys && ShowCompanys.length > 1">{{ a.cm }}</td>
					</tr>
				</tbody>
				<ng-template #goalsTableBlockThird>
					<tbody class="report-table__table-body">
						<tr *ngFor="let a of AgentsSalesAllThird" (click)="FourthPageAdminAgentsSales(a.MM, a.YY, a.ag, a.cm)">
							<td class="report-table__table-body-name" style="width: 100px">{{ a.MM }}-{{ a.YY }}</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr">{{ a.Tot | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr">{{ a.currentYearGoal ? (a.currentYearGoal | number : '1.0-2') : 'אין נתונים עדיין' }}</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr">
								{{ a.growthPercentage ? (a.growthPercentage | number : '1.0-2' : 'en-US') + '%' : 'אין נתונים עדיין' }}
							</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr">{{ a.Ltot | number : '1.0-2' }}</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr">{{ (a.Ahuz | number : '1.0-2' : 'en-US') + '%' }}</td>
							<td class="report-table__table-body-name" style="width: 100px" dir="ltr" *ngIf="ShowCompanys && ShowCompanys.length > 1">{{ a.cm }}</td>
						</tr>
					</tbody>
				</ng-template>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="!loading && Fourth && AgentsSalesAllFourth?.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('Cl', 'Fourth')">קוד לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('cn', 'Fourth')">שם לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('tot', 'Fourth')">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('ltot', 'Fourth')">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('ahuz', 'Fourth')">קצב התקדמות באחוזים</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('cm', 'Fourth')" *ngIf="ShowCompanys && ShowCompanys.length > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let a of AgentsSalesAllFourth" (click)="MoveToDohotBizoimLekohotEach(a.Cl, a.cm, a.cn)">
						<td class="report-table__table-body-name" style="width: 150px">{{ a.Cl }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ a.cn }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ a.tot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ a.ltot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ (a.ahuz | number : '1.0-2' : 'en-US') + ' %' }}</td>
						<td class="report-table__table-body-name" style="width: 150px" *ngIf="ShowCompanys && ShowCompanys.length > 1">{{ a.CompanyName || a.cm }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="!loading && Fifth && AgentsSalesAllFifth?.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('dd', 'Fifth')">תאריך</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('dn', 'Fifth')">מס תעודה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('tsum', 'Fifth')">סכום</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of AgentsSalesAllFifth" (click)="OneTeuda(b.dn)">
						<td class="report-table__table-body-name" style="width: 150px">{{ b.dd }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ b.dn }}</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ b.tsum | number : '1.0-2' }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="!loading && Sixth && OneTeudaInfo?.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('pr', 'Sixth')">קוד פריט</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 250px" (click)="translateForSort('pn', 'Sixth')">שם פריט</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('prc', 'Sixth')">מחיר</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('qu', 'Sixth')">כמות</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('ds', 'Sixth')">הנחה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of OneTeudaInfo">
						<td class="report-table__table-body-name">{{ b.pr }}</td>
						<td class="report-table__table-body-name">{{ b.pn }}</td>
						<td class="report-table__table-body-name">{{ b.prc | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.qu }}</td>
						<td class="report-table__table-body-name">{{ b.ds ? (b.ds | number : '1.0-2' : 'en-US') + '%' : b.ds }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<div class="popup" *ngIf="Loading" style="z-index: 333">
	<div class="overlay"></div>
	<div class="product-popup popup__content">
		<div class="order-product__item">
			<div class="order-process__row order-process__row_success">
				<div class="row">
					<h2 class="order-process__title" style="text-align: center">טוען אנא המתן</h2>
				</div>
				<div class="row"></div>
				<div class="row">
					<div class="order-process__image image">
						<img src="/assets/images/basket/process/success.gif" alt="photo" />
					</div>
				</div>
				<div class="row"></div>
			</div>
		</div>
	</div>
</div>
