import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
	name: 'determineInputType',
})
export class DetermineInputTypePipe implements PipeTransform {
	constructor(private _ss: StoreService) {}
	transform(searchOpt: '' | 'Name' | 'Barcode' | 'Code' | 'ItemExtra1'): 'number' | 'text' {
		const inputNumeric: '0' | '1' | '2' | '3' | undefined = this._ss.CustomerInformation?.InputBarcodeNumeric;
		const specificVersion: '0' | '1' | undefined = this._ss.CustomerInformation?.SpecificCompanyVersion;
		if (specificVersion === '1') {
			return 'number';
		}

		return (searchOpt == 'Barcode' && (inputNumeric == '1' || inputNumeric == '3')) || (searchOpt == 'Code' && (inputNumeric == '2' || inputNumeric == '3')) ? 'number' : 'text';
	}
}
