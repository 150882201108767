<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="header header_white">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToDohot()"><img src="/assets/images/header/back-arrow-black.svg" alt="arrow" /><span style="font-size: 18px">חזרה</span></div>
					<div class="header__title col">מנהל - ניהול חברה</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger-red.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start content of report page -->
		<section class="report-dashboard">
			<div class="container">
				<div class="report-dashboard__row row" style="background: var(--main-color)">
					<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['admin-users'])">
						<div class="report-dashboard__item-photo image">
							<img src="/assets/images/report/performence-icon.svg" alt="photo" />
						</div>
						<span class="report-dashboard__item-text">ניהול סוכנים</span>
					</div>
					<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['specials'])" *ngIf="hasSpecials">
						<div class="report-dashboard__item-photo image">
							<img src="/assets/images/report/performence-icon.svg" alt="photo" />
						</div>
						<span class="report-dashboard__item-text">ניהול מבצעים</span>
					</div>
					<div class="report-dashboard__item report-dashboard__item_performence col" (click)="router.navigate(['dashboard-receipts'])" *ngIf="_receipts.hasReceipts">
						<div class="report-dashboard__item-photo image">
							<img src="/assets/images/report/performence-icon.svg" alt="photo" />
						</div>
						<span class="report-dashboard__item-text">ניהול קבלות</span>
					</div>

					<div class="report-dashboard__item report-dashboard__item_performence col" *ngIf="_ss.CustomerInformation.YaadAgents === '1'" (click)="navigateAdminGoals('agents')">
						<div class="report-dashboard__item-photo image">
							<img src="/assets/images/report/performence-icon.svg" alt="photo" />
						</div>
						<span class="report-dashboard__item-text">ניהול יעדי סוכנים</span>
					</div>

					<div
						class="report-dashboard__item report-dashboard__item_performence col"
						*ngIf="(_ss.CustomerInformation.YaadClients === '1' || _ss.CustomerInformation.YaadClients === '2') && windowWidth > 700"
						(click)="navigateAdminGoals('clients')"
					>
						<div class="report-dashboard__item-photo image">
							<img src="/assets/images/report/performence-icon.svg" alt="photo" />
						</div>
						<span class="report-dashboard__item-text">ניהול יעדי לקוחות</span>
					</div>
					<div
						class="report-dashboard__item report-dashboard__item_performence col"
						(click)="checkIfHasMultipleCompanys('temporary-password')"
						*ngIf="_ss.CustomerInformation.TemporaryPassword === '1'"
					>
						<div class="report-dashboard__item-photo image">
							<img src="/assets/images/report/performence-icon.svg" alt="photo" />
						</div>
						<span class="report-dashboard__item-text">סיסמה זמנית</span>
					</div>
				</div>
			</div>
		</section>
		<!-- end content of report page -->
	</div>

	<!-- markup for popup -->
	<div class="popup" *ngIf="HasMultiplleCompanysPop === true">
		<div class="overlay"></div>
		<div class="order-cart__popup popup__content">
			<div class="popup__close" (click)="NoChoose()"></div>
			<div class="order-cart__popup-image image">
				<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
			</div>
			<h2 class="order-cart__popup-title">בחר חברה</h2>
			<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoChoose()">חזור</button>
			<br />
			<div class="order-cart__popup-block row">
				<button class="order-cart__popup-button" (click)="CompanyChoosen(sc.Company)" *ngFor="let sc of ShowCompanys">
					{{ sc.CompanyName }}
				</button>
			</div>
		</div>
	</div>
	<!-- end markup for popup -->

	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img
						width="100%"
						height="auto"
						src="{{ this._ss.CustomerInformation.CustImageDomain }}/companylogo.jpg"
						crossorigin="anonymous"
						alt="mobility"
						class="company-logo__image"
						onerror="this.onerror=null; this.src=MOBILITY_LOGO"
					/>
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ this._ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ this._ss.AgentIdConnected }}</div>
				</div>
			</div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="MoveToClients()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">לקוחות</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="BackToHome()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דף הבית</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-menu-button></app-menu-button>
