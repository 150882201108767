import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject } from 'rxjs';
import { AllOrder, Product2 } from '../Interfaces/all-products';
import { MustIncludeProducts, MustIncludeProductsActions, MustIncludeProductsOperators, MustIncludeProductsParsed, MustIncludeProductsPOSTBody } from '../Interfaces/SQL-morning-responses';
import { StoreService } from './store.service';

/*

	mustIncludeForm: MustIncludeProductsForm = this._fb.nonNullable.group({
		clients: [<string[]>[]],
		agents: [<string[]>[]],
		doctypes: [<number[]>[]],
		operator: [<MustIncludeProductsOperators>'gt', Validators.required],
		productsA: [<string[]>[], [Validators.required]],
		productsB: [<string[]>[]],
		quantity: [<number>null, Validators.required],
		action: [<MustIncludeProductsActions>'', Validators.required],
		shouldAffectAllClients: [true],
		shouldAffectAllAgents: [true],
		shouldAffectAllDocTypes: [true],
		clientsSearch: [''],
		agentsSearch: [''],
		productsASearch: [''],
		productsBSearch: [''],
	});
*/

export type MustIncludeProductsForm = FormGroup<{
	clients: FormControl<string[]>;
	agents: AbstractControl<string[]>;
	doctypes: AbstractControl<number[]>;
	operator: AbstractControl<MustIncludeProductsOperators>;
	productsA: AbstractControl<string[]>;
	productsB: AbstractControl<string[]>;
	quantity: AbstractControl<number>;
	action: AbstractControl<MustIncludeProductsActions>;
	shouldAffectAllClients: AbstractControl<boolean>;
	shouldAffectAllAgents: AbstractControl<boolean>;
	shouldAffectAllDoctypes: AbstractControl<boolean>;
	clientsSearch: AbstractControl<string>;
	agentsSearch: AbstractControl<string>;
	productsASearch: AbstractControl<string>;
	productsBSearch: AbstractControl<string>;
}>;

@Injectable({
	providedIn: 'root',
})
export class MustIncludeProductsService {
	private readonly operators = {
		gt: (a: number, b: number) => a > b,
		lt: (a: number, b: number) => a < b,
		gtTotal: (a: number, b: number) => a > b,
		ltTotal: (a: number, b: number) => a < b,
	};
	constructor(private _ss: StoreService, private snackbar: MatSnackBar) {}

	showAgentSelectionInManagement = false;
	showDoctypeSelectionInManagement = false;

	relevantMustIncludeProductsForCurrentTeuda$ = new BehaviorSubject<MustIncludeProducts[]>([]);

	readonly path = 'mobil/products/must-include-products';

	private checkResult(result: 'ok' | MustIncludeProductsActions, productIds: string[]): { result: 'ok' | MustIncludeProductsActions; productIds: string[] } {
		return { result, productIds };
	}

	cleanup(){
		this.relevantMustIncludeProductsForCurrentTeuda$.next([]);
	}

	handleCheckMustIncludeProducts(allOrder: AllOrder[]): { result: 'ok' | MustIncludeProductsActions; productIds: string[] } {
		const productIds = [];
		if (this._ss.CustomerInformation.MustIncludeProducts !== '1') return this.checkResult('ok', productIds);

		const relevantLines: MustIncludeProductsParsed[] = this.getRelevantMustIncludeProductsForCurrentTeuda().map(line => (!line ? line : { ...line, JSON: JSON.parse(line.JSON) }));

		if (!relevantLines.length) return this.checkResult('ok', productIds);

		const activeProducts = new Set(allOrder.map(order => order.ProductId?.toLowerCase()));

		for (const mustIncludeLine of relevantLines) {
			if (!mustIncludeLine.JSON.productsA.some(product => activeProducts.has(product?.toLowerCase()))) continue;

			let quantitiesA = [...activeProducts]
				.map(product => {
					const order = allOrder.find(order => order.ProductId?.toLowerCase() === product?.toLowerCase());

					if (!order) return 0;

					return (order.TeudaPackQuantity || 0) * (order.ItemPack || 1) + (order.TeudaQuantity || 0);
				})
				.filter(quantity => !!quantity);

			if (mustIncludeLine.JSON.operator === 'gtTotal' || mustIncludeLine.JSON.operator === 'ltTotal') {
				quantitiesA = [quantitiesA.reduce((acc, curr) => acc + curr, 0)];
			}

			const areQuantitiesOk = quantitiesA.every(quantity => !this.operators[mustIncludeLine.JSON.operator](quantity, mustIncludeLine.JSON.quantity));

			if (areQuantitiesOk) continue;

			const productsB = mustIncludeLine.JSON.productsB.map(product => product?.toLowerCase());
			const quantitiesB = new Set(
				allOrder.filter(product => productsB.includes(product.ProductId?.toLowerCase()) && (product.TeudaPackQuantity || product.TeudaQuantity)).map(product => product.ProductId?.toLowerCase()),
			);

			const areProductsOk = productsB.every(product => activeProducts.has(product) && quantitiesB.has(product));

			if (areProductsOk) continue;

			return this.checkResult(mustIncludeLine.JSON.action, productsB);
		}

		return this.checkResult('ok', productIds);
	}

	replaceStoredMustIncludeProducts(mustIncludeProducts: MustIncludeProducts[]) {
		localStorage.setItem('MustIncludeProducts', compress(JSON.stringify(mustIncludeProducts)));
		this.relevantMustIncludeProductsForCurrentTeuda$.next(mustIncludeProducts);
	}

	fetchRelevantMustIncludeProductsForCurrentTeuda() {

		if (this.relevantMustIncludeProductsForCurrentTeuda$.value.length) return;

		const query = {
			doctype: this._ss.DocType,
			agent: this._ss.AgentIdConnected,
			clientid: this._ss.OneClientInfo.ClientId,
		};

		this._ss.get<MustIncludeProducts>(this.path, new HttpParams({ fromObject: query })).subscribe({
			next: relevantLines => {
				if (typeof relevantLines === 'string') {
					return;
				}

				this.relevantMustIncludeProductsForCurrentTeuda$.next(relevantLines.recordset);
			},
			error: error => {
				return;
			},
		});
	}

	private getRelevantMustIncludeProductsForCurrentTeuda() {
		const relevantInMemory = this.relevantMustIncludeProductsForCurrentTeuda$.value;

		if (relevantInMemory.length) return relevantInMemory;

		const storedMustIncludeProducts = localStorage.getItem('MustIncludeProducts');

		if (!storedMustIncludeProducts) return [];

		const parsed: MustIncludeProducts[] = JSON.parse(decompress(storedMustIncludeProducts));

		const relevantLines: MustIncludeProducts[] = parsed.filter(
			line =>
				(!line.doctype || line.doctype === this._ss.DocType) && (!line.agent || line.agent === this._ss.AgentIdConnected) && (!line.clientid || line.clientid === this._ss.OneClientInfo.ClientId),
		);

		this.relevantMustIncludeProductsForCurrentTeuda$.next(relevantLines);

		return relevantLines;
	}

	getMustIncludeProducts() {
		return this._ss.get<MustIncludeProducts>(this.path);
	}

	handlePOSTnewMustIncludeProducts(body: { lines: MustIncludeProductsPOSTBody[] }) {
		return this._ss.post(this.path, body);
	}

	handlePATCHMustIncludeProducts(id: string | number, body: Partial<MustIncludeProductsPOSTBody>) {
		return this._ss.patch(`${this.path}/${id}`, body);
	}

	handleDELETEMustIncludeProducts(id: string | number) {
		return this._ss.delete(`${this.path}/${id}`);
	}
}
