import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { HovotHeaders } from 'src/app/Interfaces/SQL-reports-responses';
// import { read, utils, writeFile } from 'xlsx';

@Component({
	selector: 'app-dohotlekohotbydate',
	templateUrl: './dohotlekohotbydate.component.html',
	styleUrls: ['./dohotlekohotbydate.component.scss'],
})
export class DohotlekohotbydateComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	toogleInput = false;
	searchActive = false;

	public form: FormGroup;
	public AllClients = [];
	public AllClientsSearch;

	public AllProducts;
	public ItemExtraOne = [];

	public toogleInputNew = false;

	public ShowCompanys;

	public HasMultipleCompanys;

	public MainAc;

	public OneBack;

	public First = true;
	public Second = false;
	public third = false;
	public Loading = false;

	reportSearch = null;

	longestName = 0;

	windowWidth = document.body.clientWidth;

	hovotMappedByClientID: { [key: string]: HovotHeaders[] } = {};

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		this._ss.ClientFromDohot = 0;

		this._ss.OneClientInfoDebt = null;

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			ClientSearch: ['', Validators.required],
			SectionSearch: ['', Validators.required],
			Yehidot: ['', Validators.required],
			Arizot: ['', Validators.required],
			ChangePrice: ['', Validators.required],
			ChangeDiscount: ['', Validators.required],
			HearaForProduct: ['', Validators.required],
			Bonus: ['', Validators.required],
			BonusArizot: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		const allClientsLS = localStorage.getItem('AllClients');
		const allHovotLS = localStorage.getItem('AllHovotHeaders');

		console.log({ allHovot: JSON.parse(decompress(allHovotLS)), allClients: JSON.parse(decompress(allClientsLS)) });

		if (!allClientsLS) {
			this.router.navigate(['login']);
			return;
		}

		this.AllClientsSearch = decompress(allClientsLS);
		this.AllClientsSearch = JSON.parse(this.AllClientsSearch);

		if (allHovotLS) {
			const hovot: HovotHeaders[] = JSON.parse(decompress(allHovotLS));
			for (const i in hovot) {
				const clientHov = this.hovotMappedByClientID[hovot[i].DebtCustCode];

				if (!clientHov) {
					this.hovotMappedByClientID[hovot[i].DebtCustCode] = [];
				}

				this.hovotMappedByClientID[hovot[i].DebtCustCode].push(hovot[i]);
			}

			console.log({ hovotMapped: this.hovotMappedByClientID });
		}

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].COD !== 0) {
				this.AllClients.push(this.AllClientsSearch[x]);
				this.longestName = Math.max(this.longestName, this.AllClientsSearch[x].Client_Name.length);
			}
		}

		setTimeout(() => {
			this.AllClientsSearch = this.MainAc = this.AllClients;
		}, 100);

		setTimeout(() => {
			if (
				this._ss.OneClientInfo !== null &&
				document.getElementById('Client' + this._ss.OneClientInfo.ClientId) !== null &&
				document.getElementById('Client' + this._ss.OneClientInfo.ClientId) !== undefined
			) {
				var elmnt = document.getElementById('Client' + this._ss.OneClientInfo.ClientId);
				elmnt.scrollIntoView({ block: 'center' });
			}
		}, 100);
	}

	displayByLastDateOrCreateDate = true;

	changeMode(makeActiveElement: EventTarget) {
		document.querySelectorAll('.report-table__button').forEach(div => div.classList.remove('active'));

		if (makeActiveElement instanceof HTMLDivElement) makeActiveElement.classList.add('active');

		this.displayByLastDateOrCreateDate = !this.displayByLastDateOrCreateDate;
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>
	public ChangeCompany() {
		window.scrollTo(0, 0);
		this.AllClients = [];
		this.AllClientsSearch = [];

		for (let x = 0; x < this.MainAc.length; x++) {
			if (this.MainAc[x].company === JSON.parse(this.form.controls['Company'].value)) {
				this.AllClients.push(this.MainAc[x]);
				this.AllClientsSearch.push(this.MainAc[x]);
			}
		}
	}

	public BackToDohot() {
		if (this.First === true) {
			history.back();
		}

		if (this.Second === true) {
			this.Second = false;
			this.First = true;
			this.OneClientHovot = [];
			this.clientName = '';
		}
	}

	public OneClientHovot;
	OneClientHovotLdt;
	clientName = '';

	public GetHovotClient(company, ClientId, Client_Name) {
		this.clientName = Client_Name;

		const relevantClient = this.hovotMappedByClientID[ClientId];

		const obj = relevantClient.reduce((prev, curr) => {
			const year = new Date(curr.DebtDocCreateDate).getFullYear();
			const month = new Date(curr.DebtDocCreateDate).getMonth() + 1;

			if (!prev[year + '-' + month]) {
				prev[year + '-' + month] = {};
				prev[year + '-' + month].tot = 0;
			}

			prev[year + '-' + month].tot += curr.DebtDocOpenToPay;

			return prev;
		}, {});

		console.log({ obj });

		this.OneClientHovotLdt = Object.entries(obj).map((entry: any) => ({ yymm: entry[0], tot: entry[1].tot }));

		this.sortNumber('yymm', 'OneClientHovotLdt');

		this.First = false;
		this.Second = true;

		this.Loading = true;

		this._ss.HovotByMonth(company, ClientId).subscribe(res => {
			this.Loading = false;

			this.OneClientHovot = res;
			this.OneClientHovot = this.OneClientHovot.recordset;
		});
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public MlayOne = false;
	public IdOne = false;
	public NameOne = false;

	public SortDebt() {
		this.IdOne = false;
		this.NameOne = false;

		if (this.MlayOne === false) {
			this.AllClients.sort(function (a, b) {
				return a.COD - b.COD;
			});
			this.MlayOne = true;
		} else {
			this.AllClients.sort(function (a, b) {
				return b.COD - a.COD;
			});
			this.MlayOne = false;
		}
	}

	sortArr = [true, false, false];

	sortString(valueToSortBy: string, arrayToSort: string) {
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };

		let bool: boolean, i: number;

		switch (valueToSortBy) {
			case 'Client_Name':
				i = 0;
				break;
			case 'ClientId':
				i = 1;
				break;
			case 'yymm':
				i = 2;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			this[arrayToSort].sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	sortArrNumber = [true, false, false];

	sortNumber(valueToSortBy: string, arrayToSort: string) {
		let bool;
		let i;

		switch (valueToSortBy) {
			case 'COD':
				i = 0;
				break;
			case 'yymm':
				i = 1;
				break;
			case 'tot':
				i = 2;
				break;

			default:
				console.log('I should never return');
				return;
		}
		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			this[arrayToSort].sort((a, b) => +String(b[valueToSortBy]).replace(/-/g, '') - +String(a[valueToSortBy]).replace(/-/g, ''));
		} else {
			this[arrayToSort].sort((a, b) => +String(a[valueToSortBy]).replace(/-/g, '') - +String(b[valueToSortBy]).replace(/-/g, ''));
		}

		this.sortArr[i] = !bool;
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	searchFocus(value): void {
		this.searchActive = value;
	}

	public SearchClear() {
		this.AllClients = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			this.AllClients.push(this.AllClientsSearch[x]);
		}
		this.toogleInputNew = false;
		this.form.reset();
	}
	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	public SearchClient() {
		window.scrollTo(0, 0);
		this.AllClients = [];

		if (this.form.controls['ClientSearch'].value === '' || this.form.controls['ClientSearch'].value.length === 0) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (this.AllClients.length < 100) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 1) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 1) === this.form.controls['ClientSearch'].value.toString().slice(0, 1)
				) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length === 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					this.AllClientsSearch[x].Client_Name.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2) ||
					this.AllClientsSearch[x].ClientId.toString().slice(0, 2) === this.form.controls['ClientSearch'].value.toString().slice(0, 2)
				) {
					this.AllClients.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (this.form.controls['ClientSearch'].value.length > 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				for (let z = 0; z < 50; z++) {
					if (
						this.AllClientsSearch[x].Client_Name.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length) ||
						this.AllClientsSearch[x].ClientId.toString().slice(z, this.form.controls['ClientSearch'].value.length + z) ===
							this.form.controls['ClientSearch'].value.toString().slice(0, this.form.controls['ClientSearch'].value.length)
					) {
						let data = this.AllClients.find(ob => ob.ClientId === this.AllClientsSearch[x].ClientId);
						if (data === undefined) {
							this.AllClients.push(this.AllClientsSearch[x]);
						}
					}
				}
			}
		}
	}
	//==========================================================================================================================================================>

	// getExcelReport() {
	//   const table = document.getElementById("one-client-hovot-table");
	//   const wb = utils.table_to_book(table);

	//   writeFile(wb, "SheetJSTable.xlsx");
	// }
}
