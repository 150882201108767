import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { StoreService } from '../services/store.service';

export type formatAsDateOptions = { isTrueISO?: boolean; onlyTime?: boolean };

export enum Time {
	minuteInMilliseconds = 60000, // 1000 * 60
	hour = minuteInMilliseconds * 60,
	day = hour * 24,
	week = day * 7,
}

export enum Password {
	bypassBlockedClient = 1,
	generateLoginPassword = 2,
}

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	constructor(private _ss: StoreService) {}

	sortArr = [true];

	// create new regex instance, since reusing the same one can lead to false negatives
	// https://stackoverflow.com/questions/1520800/why-does-a-regexp-with-global-flag-give-wrong-results
	problematicCharactersRegex = () => new RegExp(/[&%,'"\/\\<>=*]/g);

	sortWithIndex(valueToSortBy: string, i: number, arrayToSort: any[]) {
		// Morph array to get sorted by value provided
		const opt: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };
		let bool: boolean;

		bool = this.sortArr[i];
		this.sortArr = this.sortArr.map(_ => false);

		this.sortArr[0] = bool;

		if (bool) {
			arrayToSort.sort((a, b) => new Intl.Collator('he', opt).compare(b[valueToSortBy], a[valueToSortBy]));
		} else {
			arrayToSort.sort((a, b) => new Intl.Collator('he', opt).compare(a[valueToSortBy], b[valueToSortBy]));
		}

		this.sortArr[i] = !bool;
	}

	reduceArrayToNumber(arr: any[], propertyName: string): number {
		return arr.reduce((p, c) => p + (c[propertyName] || 0), 0);
	}

	testProblematicCharacters(str: string): boolean {
		return this.problematicCharactersRegex().test(str);
	}

	/**
	 * Remove problematic characters for SQL JSON extraction. Used to sanitize user input.
	 * @param str input string
	 * @returns sanitized string
	 */
	removeProblematicCharacters(str: string): string {
		return this.replaceNewLinesWithSpaces(str).replace(this.problematicCharactersRegex(), '');
	}

	/**
	 * Replace new line characters with spaces.
	 * @param str input string
	 * @returns sanitized string
	 */
	replaceNewLinesWithSpaces(str: string): string {
		return str.replaceAll(/\n/g, ' ');
	}

	/**
	 * Generate unique password PIN - with agent, company, custnum, action, and date as the seed.
	 * @param options AgentID, CompanyNumber, company, action, length
	 * @returns unique PIN
	 */
	async getPasswordPIN(options?: { AgentID?: string; CompanyNumber?: string; company?: number; action?: number; length?: number }): Promise<string> {
		const AgentID = options.AgentID || '';
		const CompanyNumber = options.CompanyNumber || '';
		const company = options.company || '';
		const action = options.action || '';
		const length = options.length || 4;
		const inputStr = AgentID + CompanyNumber + company + new Date().toISOString().slice(0, 10) + action;

		// Calculate a hash value using SHA-256 algorithm
		const msgUint8 = new TextEncoder().encode(inputStr);
		// only available in https.
		const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

		const pin = parseInt(hashHex.slice(0, length), 16);

		return pin.toString().padStart(6, '0');
	}

	formatAsDate(
		value: string | number | Date,
		options: formatAsDateOptions = {
			isTrueISO: false,
			onlyTime: false,
		},
	): string {
		const timeZone = options.isTrueISO ? 'Asia/Jerusalem' : 'UTC';

		const intlOptions: Intl.DateTimeFormatOptions = options.onlyTime
			? { timeZone, hour: 'numeric', minute: 'numeric', second: 'numeric' }
			: { timeZone, year: 'numeric', month: 'numeric', day: 'numeric' };

		return new Intl.DateTimeFormat('en-gb', intlOptions).format(new Date(value));
	}

	// yaron doesn't want to see shekel signs, so keep as is
	formatAsNormalNumber(input: string | number, group = true): string {
		return Intl.NumberFormat('he-IL', { minimumFractionDigits: 0, maximumFractionDigits: 2, useGrouping: group }).format(+input);
	}

	returnIDBObjectForWhereClause(): { ClientId: string; Company: number; DocType: number } {
		return { ClientId: this._ss.OneClientInfo.ClientId, Company: this._ss.OneClientInfo.company, DocType: this._ss.DocType };
	}

	pipeASubjectDestoryer<T>(observable: Observable<T>, destroyer: Subject<void>) {
		return observable.pipe(takeUntil(destroyer));
	}

	reloadPage() {
		location.reload();
	}

	navigateBack() {
		history.back();
	}

	stopEventPropogation(e: Event) {
		e.stopPropagation();
	}

	randomNumber(limit?: number) {
		return Math.random() * (limit || 1);
	}
}

export type ExcelObject = { data: any[]; headers?: string[]; filename: string; extension: 'csv' | 'xlsx' };

export class EmptyPromise extends Promise<void> {}

export class CustomMap<K extends string, V> {
	private data: Record<K, V> = {} as Record<K, V>;
	private size: number = 0;

	set(key: K, value: V): void {
		if (!this.has(key)) {
			this.size++;
		}
		this.data[key] = value;
	}

	get(key: K): V | undefined {
		return this.data[key];
	}

	delete(key: K): void {
		if (this.has(key)) {
			this.size--;
			delete this.data[key];
		}
	}

	has(key: K): boolean {
		return this.data.hasOwnProperty(key);
	}

	isEmpty(): boolean {
		return this.size === 0;
	}
}
