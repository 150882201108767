import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LikutService } from '../services/likut.service';
import { TruckDistributionService } from '../services/truck-distribution.service';
import { TruckLoadingService } from '../services/truck-loading.service';

@Injectable({
	providedIn: 'root',
})
export class EmptyOpenLikutGuard implements CanActivate {
	constructor(private _likut: LikutService, private _truckLoading: TruckLoadingService, private _truckDistribution: TruckDistributionService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		if (
			!this._likut.likutOpenedNewRoutesState.sub.length &&
			!this._truckLoading.truckLoadOpened.sub.length &&
			!this._truckDistribution.truckDistributionOpened.sub.length &&
			!this._likut.likutMekabelOpenedState.main?.rows?.length &&
			!this._likut.likutMekabelOpenedState.main?.refrenceNumber
		) {
			return false;
		}

		return true;
	}
}
