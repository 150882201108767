import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, mergeMap,map, timer } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	defaultMessage = 'טוען אנא המתן...';
	loadingMessage$ = new BehaviorSubject(this.defaultMessage);
	isLoading$ = new BehaviorSubject<boolean>(false);
	isLoaded$ = this.isLoading$.pipe(map(isLoading => !isLoading));
	maxWaitTime = 20000;

	constructor() {
		// if load takes more than maxWaitTime / is stuck
		this.isLoading$
			.pipe(
				filter(() => this.isLoading$.value),
				mergeMap(() => timer(this.maxWaitTime)),
			)
			.subscribe(() => {
				this.isLoading$.next(false);
			});
	}

	private changeLoadingStatus(value: boolean) {
		this.isLoading$.next(value);
	}

	private changeLoadingMessage(message: string) {
		this.loadingMessage$.next(message);
	}

	changeLoadingStatuses(values: { isLoading?: boolean; newMessage?: string }) {
		const { isLoading, newMessage } = values;

		if (newMessage) {
			this.changeLoadingMessage(newMessage);
		}

		if (isLoading !== undefined) {
			this.changeLoadingStatus(isLoading);
		}
	}

	changeMessageBackToDefault() {
		this.loadingMessage$.next(this.defaultMessage);
	}
}
