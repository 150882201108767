<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		[ngStyle]="{ 'z-index': (isConcentratedProductPopupShowing$ | async) && showProductDetailPopup ? 555 : 1 }"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="order-header header" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }">
			<div class="content">
				<div class="header__block row">
					<span class="header__basket-text header__basket-text-VAT" *ngIf="_ss.CustomerInformation.CustSeePrice !== '1' && _ss.CustomerInformation.DisplayWithVat === '3' && canSeePrices">
						עם מע"מ: {{ TotalSumOAllOrder | toNormalNumber : _ss.OneClientInfo.CustVat }}
					</span>
					<div class="header__box" *ngIf="_ss.OrderInProcess === false && ShowMainTeudaIcon === true">
						<div class="header__basket" (click)="MoveToTeuda()">
							<div class="expand-click-area" *ngIf="_ss.CustomerInformation.HeaderDisplay === '1'"></div>
							<div class="header__basket-image" [ngClass]="{ bigger: _ss.CustomerInformation.HeaderDisplay === '1' }">
								<div class="header__basket-image-number">
									{{ AllOrder?.length || 0 }}
								</div>
								<span
									class="header__basket-text"
									[ngClass]="{ bigger: _ss.CustomerInformation.HeaderDisplay === '1' }"
									*ngIf="_ss.CustomerInformation.CustSeePrice !== '1' && (_ss.OneClientInfo.DSP !== 2 || TotalOrderOverride === '1') && !isB2BLeasing && !isCatalogAgent && canSeePrices"
								>
									{{ TotalSumOAllOrder | number : '1.0-2' : 'en-US' }}
								</span>
							</div>
						</div>
						<!-- <div class="header__barcode"></div> -->

						<div style="text-decoration: underline" (click)="ShowTotal()" *ngIf="_ss.CustNum === '10259'">סה"כ</div>

						<div *ngIf="_ss.CustNum !== '10259' && _ss.CustomerInformation.HeaderDisplay !== '1'">
							<form [formGroup]="form" (change)="SearchProducts()">
								<select formControlName="SearchOptions" style="height: 20px; background: white">
									<option *ngFor="let option of searchOptions" [value]="option.value">{{ option.text }}</option>
								</select>
							</form>
						</div>
						<div></div>
					</div>

					<!-- Search line option keyup start  and main manu 0-->

					<div class="header__search header__search_small" *ngIf="_ss.SearchStyle === 1">
						<form [formGroup]="form" (keyup)="SearchProducts()">
							<input
								class="header__search-input"
								[type]="form.controls.SearchOptions.value | determineInputType"
								[placeholder]="ChoosenSection"
								formControlName="ProductSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="productsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon header__barcode-icon" (click)="handleBarcodePopupOpen()"></button>
						<button class="header__clear-icon" (click)="SearchClear()"></button>
						<span class="search-info"> מציג ({{ AllProducts?.length || 0 }}) מוצרים</span>
					</div>

					<!-- Search line option keyup ends and main manu-->

					<!-- Search line option click start and main manu 1-->

					<div class="header__search header__search_small" *ngIf="_ss.SearchStyle === 0">
						<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="SearchProducts()">
							<img src="/assets/images/header-search.svg" alt="search" />
						</button>
						<form [formGroup]="form" (keyup.enter)="SearchProducts()">
							<input
								class="header__search-input"
								[type]="form.controls.SearchOptions.value | determineInputType"
								[placeholder]="ChoosenSection"
								formControlName="ProductSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="productsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon" (click)="SearchClear()"></button>
						<span class="search-info"> מציג ({{ AllProducts?.length || 0 }}) מוצרים</span>
					</div>

					<!-- Search line option click ends  and main manu -->

					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- order gray section -->
		<section class="order-gray" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }">
			<div class="content">
				<div class="order-gray__row row">
					<div class="order-gray__title order-gray__title_bold">
						{{ _ss.DocTypeName }} - <span [ngStyle]="{ color: Mode ? 'green' : 'red' }">{{ Mode ? 'ONLINE' : 'OFFLINE' }}</span>
					</div>
					<div class="order-gray__title">{{ isCatalogAgent ? '' : _ss.OneClientInfo?.Client_Name }}</div>
					<div class="order-gray__title" *ngIf="canShowClientYaad">
						מכירות: {{ yaadObject.tot | number : '1.0-2' }} | יעד: {{ yaadObject.yaad | number : '1.0-2' }} | {{ yaadObject.avg | number : '1.0-2' }}%
					</div>
					<!-- <div class="order-gray__title"><span>סה"כ {{_text.packQuantityText}}: {{totalQuantities.packQuantity}}</span> |<span> סה"כ יחידות: {{totalQuantities.quantity}}</span></div> -->
				</div>
			</div>
		</section>
		<!-- end order gray section -->

		<!-- order filters section -->
		<section
			class="order-filters"
			[ngClass]="{
				'main-content_absolute': _ss.headerAbsolute
			}"
		>
			<div class="content">
				<div class="order-filters__row row" *ngIf="!isCatalogAgent">
					<div class="filter-sort__row filter-sort__row_view" *ngIf="windowWidth < 768">
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_big"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Big }"
							(click)="chooseViewType(viewTypes.Big)"
							*ngIf="SeeImagesGeneral === 0 && (_ss.CustomerInformation.SpecificCompanyVersion !== '1' || _ss.DocType !== 88)"
						></div>
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_small"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Small }"
							*ngIf="_ss.CustomerInformation.SpecificCompanyVersion !== '1' || _ss.DocType !== 88"
							(click)="chooseViewType(viewTypes.Small)"
						></div>
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_inline"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Inline }"
							(click)="chooseViewType(viewTypes.Inline)"
						></div>
					</div>
					<div class="filter-sort__row filter-sort__row_view" *ngIf="windowWidth >= 768 && windowWidth < 1100">
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_big"
							style="margin-left: 15px"
							*ngIf="_ss.CustomerInformation.SpecificCompanyVersion !== '1' || _ss.DocType !== 88"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Big }"
							(click)="chooseViewType(viewTypes.Big)"
						></div>
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_small"
							style="margin-left: 15px"
							*ngIf="_ss.CustomerInformation.SpecificCompanyVersion !== '1' || _ss.DocType !== 88"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Small }"
							(click)="chooseViewType(viewTypes.Small)"
						></div>
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_medium"
							style="margin-left: 15px"
							*ngIf="_ss.CustomerInformation.SpecificCompanyVersion !== '1' || _ss.DocType !== 88"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Medium }"
							(click)="chooseViewType(viewTypes.Medium)"
						></div>
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_inline"
							style="margin-left: 15px"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Inline }"
							(click)="chooseViewType(viewTypes.Inline)"
						></div>
					</div>
					<div class="filter-sort__row filter-sort__row_view" *ngIf="windowWidth >= 1100">
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_medium"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Small }"
							*ngIf="_ss.CustomerInformation.SpecificCompanyVersion !== '1' || _ss.DocType !== 88"
							(click)="chooseViewType(viewTypes.Small)"
						></div>
						<div
							class="filter-sort__row-view-type filter-sort__row-view-type_inline"
							[ngClass]="{ 'filter-sort__row-view-type_active': productType === viewTypes.Inline }"
							(click)="chooseViewType(viewTypes.Inline)"
						></div>
					</div>
					<ng-container *ngIf="!isCatalogAgent">
						<ng-container *ngIf="windowWidth < 768">
							<div class="filter-sort__row" [ngClass]="{ 'filter-sort__row_active': filterSortActive }" (click)="showHideSortBlock(true); showHideDepartmentsPopup(true)">
								<span class="filter-sort__row-name">מחלקות</span>
								<div class="filter-sort__row-img filter-sort__row-img_active filter-sort__row-img_layer image">
									<img src="/assets/images/filters/layers-icon.svg" alt="photo" />
								</div>
							</div>
							<div
								class="filter-sort__row"
								*ngIf="!ItemExtraOneSearch?.length || (isB2BAgent && (_ss.CustomerInformation.ProductsFilterRow === '1' || _ss.CustomerInformation.ProductsFilterRow === '2'))"
								(click)="handleFilterButton('popularOrAll')"
							>
								<span class="filter-sort__row-name">{{ LastBoughtOn ? (windowWidth < 900 ? 'מוצרים' : 'כל המוצרים') : 'פופולרי' }}</span>
								<div class="filter-sort__row-img filter-sort__row-img_active filter-sort__row-img_layer image">
									<img src="/assets/images/filters/layers-icon.svg" alt="photo" />
								</div>
							</div>
							<div class="filter-sort__row" *ngIf="isB2BAgent && _ss.CustomerInformation.ProductsFilterRow === '1' && !isCatalogAgent" (click)="SanenChecked('חדש')">
								<span class="filter-sort__row-name">חדש</span>
								<div class="filter-sort__row-img filter-sort__row-img_active filter-sort__row-img_layer image">
									<img src="/assets/images/filters/layers-icon.svg" alt="photo" />
								</div>
							</div>
							<div
								class="filter-sort__row"
								*ngIf="(windowWidth > 540 && !ItemExtraOneSearch?.length) || (isB2BAgent && _ss.CustomerInformation.ProductsFilterRow === '2')"
								(click)="handleFilterButton('basket')"
							>
								<span class="filter-sort__row-name">סל</span>
								<div class="filter-sort__row-img filter-sort__row-img_active filter-sort__row-img_layer image">
									<img src="/assets/images/filters/layers-icon.svg" alt="photo" />
								</div>
							</div>
							<div
								class="filter-sort__row filter-sort__row_double"
								*ngIf="!isB2BAgent || (_ss.CustomerInformation.ProductsFilterRow !== '1' && _ss.CustomerInformation.ProductsFilterRow !== '2')"
							>
								<div class="filter-sort__row-block" (click)="OpenSanen()" *ngIf="ItemExtraOneSearch?.length">
									<span class="filter-sort__row-name">סנן</span>
									<div class="filter-sort__row-img image">
										<img src="/assets/images/filters/filter.svg" alt="photo" />
									</div>
								</div>
								<div class="filter-sort__row-block filter-sort__row-block_active" [attr.data-show-before]="!!ItemExtraOneSearch?.length" (click)="showHideSortPopup(true, 'remember')">
									<span class="filter-sort__row-name">מיין</span>
									<div class="filter-sort__row-img image">
										<img src="/assets/images/filters/arrows.svg" alt="photo" />
									</div>
								</div>
							</div>
						</ng-container>
						<div class="filter-sort__box" *ngIf="windowWidth >= 768">
							<div class="filter-sort__row" [ngClass]="{ 'filter-sort__row_active': filterSortActive }" (click)="showHideSortBlock(true); showHideDepartmentsPopup(true)">
								<span class="filter-sort__row-name">מחלקות</span>
								<div class="filter-sort__row-img filter-sort__row-img_active filter-sort__row-img_layer image">
									<img src="/assets/images/filters/layers-icon.svg" alt="photo" />
								</div>
							</div>
							<div class="filter-sort__row" (click)="handleFilterButton('popularOrAll')">
								<span class="filter-sort__row-name">{{ LastBoughtOn ? 'כל המוצרים' : 'פופולרי' }}</span>
								<div class="filter-sort__row-img filter-sort__row-img_active filter-sort__row-img_layer image">
									<img src="/assets/images/filters/layers-icon.svg" alt="photo" />
								</div>
							</div>
							<div class="filter-sort__row" (click)="handleFilterButton('basket')">
								<span class="filter-sort__row-name">סל</span>
								<div class="filter-sort__row-img filter-sort__row-img_active filter-sort__row-img_layer image">
									<img src="/assets/images/filters/layers-icon.svg" alt="photo" />
								</div>
							</div>
							<div class="filter-sort__row filter-sort__row_double">
								<div class="filter-sort__row-block" (click)="OpenSanen()" *ngIf="ItemExtraOneSearch && ItemExtraOneSearch.length">
									<span class="filter-sort__row-name">סנן</span>
									<div class="filter-sort__row-img image">
										<img src="/assets/images/filters/filter.svg" alt="photo" />
									</div>
								</div>
								<div class="filter-sort__row-block filter-sort__row-block_active" [attr.data-show-before]="!!ItemExtraOneSearch?.length" (click)="showHideSortPopup(true, 'remember')">
									<span class="filter-sort__row-name">מיין</span>
									<div class="filter-sort__row-img image">
										<img src="/assets/images/filters/arrows.svg" alt="photo" />
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="order-sort__row row" *ngIf="filterSortBlock">
					<div class="sort__block">
						<span class="sort__block-name">{{ ChoosenSection }}</span>
						<div class="sort__block-img image" (click)="selectedGroup = { GroupId: '', GroupName: '' }; KolHamozarim()">
							<img src="/assets/images/filters/close-icon.svg" alt="photo" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- end order filters section -->

		<!-- products section -->
		<section class="order-products" [ngClass]="{ 'order-products_margin-top': filterSortBlock }">
			<div class="content">
				<section class="shop-filter-empty" *ngIf="SearchErrorPop === true">
					<div class="container" style="text-align: center">
						<div class="shop-filter-empty__row row">
							<div class="shop-filter-empty__photo image">
								<img src="/assets/images/shop-return/no-filters-result.svg" alt="photo" />
							</div>
							<h2 class="shop-filter-empty__title" style="margin-top: 1rem">אופס, לא מצאנו את מה שחיפשתם</h2>
							<h2 class="shop-filter-empty__title">כדאי לבדוק איות או לנסות לחפש לפי פרמטר אחר</h2>
						</div>
					</div>
				</section>

				<!-- markup for product detail popup -->
				<!-- <div class="popup" *ngIf="CalculaterPop === true" style="z-index: 555">
					<div class="overlay"></div>
					<div class="product-detail-popup popup__content">
						<div class="popup__close" (click)="CalculaterPopClose()"></div>
						<div class="product-detail__wrapper">
							<div class="product-detail__item">
								<div class="calculator">
									<input type="text" class="calculator-screen" [value]="currentNumber" [attr.disabled]="true" />

									<div class="calculator-keys">
										<button type="button" (click)="getOperation('+')" class="operator" value="">+</button>
										<button type="button" (click)="getOperation('-')" class="operator" value="-">-</button>
										<button type="button" (click)="getOperation('*')" class="operator" value="*">&times;</button>
										<button type="button" (click)="getOperation('/')" class="operator" value="/">&divide;</button>

										<button type="button" (click)="getNumber('7')" value="7" class="button">7</button>
										<button type="button" (click)="getNumber('8')" value="8" class="button">8</button>
										<button type="button" (click)="getNumber('9')" value="9" class="button">9</button>

										<button type="button" (click)="getNumber('4')" value="4" class="button">4</button>
										<button type="button" (click)="getNumber('5')" value="5" class="button">5</button>
										<button type="button" (click)="getNumber('6')" value="6" class="button">6</button>

										<button type="button" (click)="getNumber('1')" value="1" class="button">1</button>
										<button type="button" (click)="getNumber('2')" value="2" class="button">2</button>
										<button type="button" (click)="getNumber('3')" value="3" class="button">3</button>

										<button type="button" (click)="getNumber('0')" value="0" class="button">0</button>

										<button type="button" (click)="getDecimal()" class="decimal" value="." class="button">.</button>
										<button type="button" (click)="clear()" class="all-clear" value="all-clear" class="button">AC</button>

										<button type="button" (click)="getOperation('=')" class="equal-sign" value="=" class="button">=</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> -->
				<!-- end markup for product detail popup -->

				<!-- Product Popup -->

				<!-- markup for product detail popup -->
				<div class="popup" *ngIf="showProductDetailPopup && windowWidth < 768" style="z-index: 555">
					<div class="overlay"></div>
					<div class="product-detail-popup popup__content">
						<div class="popup__close" (click)="showHideProductDetailPopup(false)"></div>
						<div class="product-detail__wrapper" *ngFor="let ap of testpop; let i = index">
							<div class="product-detail__item" [ngClass]="{ 'main-background secondary-color': isAlternativeColors }">
								<div
									class="order-product__item-corner"
									[attr.id]="ap.TeudaPackQuantity > 0 || ap.TeudaQuantity > 0 || ap.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + ap.ProductId"
									appGetBorderColor
									[TeudaPackQuantity]="ap.TeudaPackQuantity || 0"
									[TeudaQuantity]="ap.TeudaQuantity || 0"
									[TeudaBonusQuantity]="ap.TeudaBonusQuantity || 0"
									[NumOfTimes]="ap.NumOfTimes || 0"
									[IC]="ap.IC || ''"
								></div>
								<button mat-icon-button class="expand-icon" (click)="openDialog(ap)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
									<mat-icon>unfold_more</mat-icon>
								</button>

								<div class="product-detail__item-wrapper row" *ngIf="AddProductTrueOrFalse === '1'">
									<button class="product-detail__button" (click)="showHideAddPopup(true)">הוספת פריט</button>
								</div>
								<div class="product-detail__item-wrapper row">
									<ng-container *ngIf="SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'">
										<div style="font-size: 17px; font-weight: 800" *ngIf="_ss.CustomerInformation.CustNotes1 !== '1'; else custNotes1Block">
											{{ ap.RE }}
										</div>
										<ng-template #custNotes1Block>
											<h2 style="text-align: center">{{ ap.RE }}</h2>
										</ng-template>
									</ng-container>
									<h2 class="product-detail__item-title" [ngClass]="{ 'bigger-font': isRallyanceReclamation }">
										<!-- Product_Name -->
										{{ ap | addItemExtraToName : ProductName_AddItemExtra }}
										<div
											class="product-detail__item-title-packaging"
											(click)="GetItemPackOptions(ap)"
											*ngIf="(ap.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') || (ap.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1')"
										>
											{{
												ap.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
													? ap.Unit
													: (ap | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
													? ap.ItemPack + ' קילו'
													: 'באריזה ' + ap.ItemPack
											}}
										</div>
										<div class="product-detail__item-title-packaging" *ngIf="ItemExtraTitle === '1'">{{ ap.ItemPack * ap.NetPrice | number : '1.0-2' }}</div>
										<div class="product-detail__item-title-packaging" *ngIf="ItemExtraTitle === '1'">
											{{ (100 - (ap.CPP / ap.NetPrice) * 100 | number : '1.0-2' : 'en-US') + '%' }}
										</div>
									</h2>
									<div class="product-detail__item-block" *ngIf="isRallyanceReclamation">
										<app-reclamation-popup-content
											[product]="ap"
											[CustImageDomain]="CustImageDomain"
											[DoctypeName]="DoctypeName"
											(submitted)="handleRallyanceReclamationSubmit($event)"
										></app-reclamation-popup-content>
									</div>
									<ng-container *ngIf="!isCatalogAgent && !isRallyanceReclamation">
										<div class="product-detail__item-block">
											<div class="product-detail__item-info">
												<div class="product-detail__item-info-wrapper" style="display: grid">
													<div class="product-detail__item-info-title">מק"ט:</div>
													<div class="product-detail__item-info-number">{{ ap.ProductId }}</div>
												</div>
												<div class="product-detail__item-info-wrapper" style="display: grid" *ngIf="SeeBarcode !== '5'">
													<div class="product-detail__item-info-title" [style.color]="SeeBarcode === '2' ? 'blue' : 'black'">
														{{ SeeBarcode === '2' ? ap.RE.split(':')[0] + ': ' : SeeBarcode !== '6' && !showItemExtra4InsteadOfBarcode ? 'ברקוד:' : '' }}
													</div>
													<div class="product-detail__item-info-number" [style.color]="SeeBarcode === '2' ? 'blue' : 'black'">
														{{ SeeBarcode === '2' ? ap.RE.split(':')[1] : showItemExtra4InsteadOfBarcode ? ap.ItemExtra4 || '' : ap.ItemBarcode }}
													</div>
												</div>
												<div class="order-product__item-info-count" style="width: 70px" *ngIf="shouldSeeBadatzLogo">
													<img [src]="(ap | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
												</div>
												<ng-container *ngIf="canSeeStock">
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														(click)="openSelectMlay(i)"
														*ngIf="ap.BS | shouldStockShow : 'StockToggle1' : ShowMlay[i] : Admin"
													>
														<div class="order-product__item-count">
															<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock">!</div>
														</div>
													</div>
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														(click)="openSelectMlay(i)"
														*ngIf="ap.BS | shouldStockShow : 'StockToggle2' : ShowMlay[i] : Admin"
													>
														<div class="order-product__item-count bigger-font">
															<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock">!</div>
															<!-- <div class="order-product__item-count-text">מלאי</div> -->
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': ap.Stock <= 0 }">
																{{ (showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</div>
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														(click)="openMlayPopup(ap)"
														*ngIf="ap.BS | shouldStockShow : 'productPopup' : false : Admin"
													>
														<div class="order-product__item-count bigger-font">
															<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock">!</div>
															<div class="order-product__item-count-text">
																{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : '' }}
															</div>
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': ap.Stock <= 0 }">
																{{ (showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</div>
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														*ngIf="(ap.BS | shouldStockShow : 'productPopup' : false : Admin) && _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88"
													>
														<div class="order-product__item-count bigger-font">
															<div class="order-product__item-count-info" appGetBackground [Stock]="ap.ItemExtra5">!</div>
															<div class="order-product__item-count-text">מלאי</div>
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': ap.ItemExtra5 <= 0 }">
																{{ ap.ItemExtra5 | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</div>
												</ng-container>
											</div>
											<div class="product-detail__item-count">
												<div class="product-detail__item-count-col" *ngIf="(_ss.SeeArizot || shouldOverrideAgentProductQuantities) && !ap.hidePacks">
													<div
														class="product-detail__item-count-title"
														*ngIf="!hasWeightTypeUnitsBehaviors || !ap.canToggleBoxesPacks; else productPopupBoxesToggleButton"
														style="font-size: 15px; font-weight: bold"
													>
														{{ hasWeightTypeUnitsBehaviors ? (ap | weightTypeText : 'pack') : _text.packQuantityText }}
														<span *ngIf="calculatePackagesTotalQuantity">({{ (ap.ItemPack || 1) * (ap.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
													</div>
													<ng-template #productPopupBoxesToggleButton>
														<div
															class="product-detail__item-count-title"
															style="font-size: 15px; font-weight: bold; background: #e8e8e8; text-align: center; padding: 0 5px; border-radius: 5px"
															(click)="toggleProductBetweenBoxesAndPacks(ap)"
														>
															{{ ap.packsWeightMode === 'boxes' ? 'קופסאות' : 'אריזות'
															}}<span *ngIf="calculatePackagesTotalQuantity">({{ (ap.ItemPack || 1) * (ap.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
														</div>
													</ng-template>
													<div class="product-detail__item-count-text">
														<form [formGroup]="form" (input)="stopKeyupEventIfKey($event)" (keyup)="ArizotInput($event, ap)">
															<input
																class="order-product__item-info-count-box"
																type="text"
																inputmode="numeric"
																min="0"
																[placeholder]="ap.TeudaPackQuantity"
																[attr.id]="'Arizot' + ap.ProductId"
																formControlName="Arizot"
																(keyup.enter)="showHideProductDetailPopup(false)"
																[attr.disabled]="shouldOverrideAgentProductQuantities ? null : ap.qt === 1 || ap.qt === 3 || null"
																style="font-size: 15px; font-weight: bold; border: #38d88b solid 1px; width: 50px"
															/>
														</form>
													</div>
												</div>
												<div class="product-detail__item-count-col" *ngIf="(!hideSingles || shouldOverrideAgentProductQuantities) && !ap.hideSingles">
													<div
														class="product-detail__item-count-title"
														*ngIf="!hasWeightTypeUnitsBehaviors || !ap.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
														style="font-size: 15px; font-weight: bold"
													>
														{{ hasWeightTypeUnitsBehaviors ? (ap | weightTypeText : 'single') : 'יחידות' }}
													</div>
													<ng-template #productPopupWeightToggleButton>
														<div
															class="product-detail__item-count-title"
															style="font-size: 15px; font-weight: bold; background: #e8e8e8; text-align: center; padding: 0 5px; border-radius: 5px"
															(click)="toggleProductBetweenWeightAndSingles(ap)"
														>
															{{ ap.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
														</div>
													</ng-template>
													<div class="product-detail__item-count-text">
														<form [formGroup]="form" (input)="stopKeyupEventIfKey($event)" (keyup)="YehidotInput($event, ap)">
															<input
																class="order-product__item-info-count-box"
																type="text"
																inputmode="numeric"
																min="0"
																[placeholder]="ap.TeudaQuantity"
																[attr.id]="'Yehidot' + ap.ProductId"
																formControlName="Yehidot"
																(keyup.enter)="showHideProductDetailPopup(false)"
																[attr.disabled]="shouldOverrideAgentProductQuantities ? null : ap.qt === 1 || ap.qt === 2 || null"
																style="font-size: 15px; font-weight: bold; border: #38d88b solid 1px; width: 50px"
															/>
														</form>
													</div>
												</div>
												<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.CustExtra2 === '1'" style="margin-top: 10px">
													<div class="order-product__item-present-block-input order-product__item-present-block-input_big" *ngIf="ap.qt === 0 || ap.qt === 2">
														<form [formGroup]="form" (keyup)="ChangeKamotProductInput(ap.ProductId)">
															באריזה:<input
																type="number"
																[placeholder]="ap.ItemPack"
																formControlName="ItemPackInput"
																(keyup.enter)="showHideProductDetailPopup(false)"
																style="font-size: 15px; width: 50px"
																maxlength="99"
															/>
														</form>
													</div>
												</div>
												<div
													class="order-product__item-present-block order-product__item-present-block_big"
													style="font-size: 16px; font-weight: 600; margin-top: 15px; margin-right: 15px"
													*ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices"
												>
													<ng-container *ngIf="!alternativeProductCalculations">
														<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
															{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}
															<span [ngStyle]="{ color: ap.NetPrice !== ap.ShowPrice ? '#38D88B' : '' }">{{ ap.NetPrice | number : '1.0-2' : 'en-US' }} </span>
															<span *ngIf="_ss.CustomerInformation.ProductExtraInfo === '2'">({{ 100 - (ap.NetPrice / profitNumber) * 100 | number : '1.0-2' : 'en-US' }}%)</span>
														</div>
														<div
															class="order-product__item-present-block-input order-product__item-present-block-input_big"
															*ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 && !isB2BAgent"
														>
															מחיר: <span>{{ ap.ItemExtra4 | number : '1.0-2' : 'en-US' }} </span>
														</div>
														<div class="order-product__item-present-block-input" *ngIf="!dontShowCalculatedDiscount && ap.NetPrice < ap.ShowPrice">
															הנחה מחושבת: {{ (ap.NetPrice / ap.ShowPrice - 1) * -100 | number : '1.0-2' }}%
														</div>
													</ng-container>
												</div>
												<div
													class="order-product__item-present-block order-product__item-present-block_big"
													style="font-size: 16px; font-weight: 600; margin-top: 15px; margin-right: 15px"
													*ngIf="MinAlertChangeDiscount === true && _ss.OneClientInfo.DSP !== 2 && canSeePrices"
												>
													<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
														מחיר מחושב:
														<span [ngStyle]="{ color: ap.NetPrice !== ap.ShowPrice ? 'red' : '' }"
															>{{ (ap.NetPrice !== ap.ShowPrice ? dis : ap.NetPrice) | number : '1.0-2' : 'en-US' }}
														</span>
													</div>
												</div>
												<div
													class="order-product__item-present-block order-product__item-present-block_big"
													style="font-size: 16px; font-weight: 600; margin-top: 15px; margin-right: 15px"
													*ngIf="MinAlertChangePrice === true"
												>
													<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
														הנחה מחושבת: <span style="color: red">{{ ShowDisAhuz | number : '1.0-2' : 'en-US' }} </span>
													</div>
												</div>
											</div>
										</div>
										<div class="product-detail__item-price-row row" *ngIf="_ss.OneClientInfo.DSP !== 1 && _ss.OneClientInfo.DSP !== 2 && canSeePrices">
											<div class="product-detail__item-price-block" (click)="Mehiron(ap)" *ngIf="!alternativeProductCalculations; else alternativeCalcBlock">
												<div class="product-detail__item-price-block-title" style="font-size: 15px; font-weight: bolder">מחירון</div>
												<div class="product-detail__item-price-blockr" style="font-size: 15px; font-weight: bolder">{{ ap.ShowPrice | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<ng-template #alternativeCalcBlock>
												<div class="order-product__item-present-block" *ngIf="!isB2BAgent" style="margin-left: 6px">
													<div class="order-product__item-present-block-title" style="font-size: 15px; font-weight: bold">מחיר</div>
													<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
														<div style="color: red; font-size: 14px; font-weight: 600" *ngIf="MinAlertChangeDiscount === true">חריגה ממחיר מינימום</div>
														<form [formGroup]="form" (keyup)="changeBasePrice(ap)">
															<input
																type="number"
																placeholder="{{ ap.basePriceInputted || ap.basePrice || ap.ShowPrice || 0 | toNormalNumber }}"
																formControlName="ChangeBasePrice"
																id="ChangeBasePrice{{ ap.ProductId }}"
																(keyup.enter)="showHideProductDetailPopup(false)"
																style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
																[attr.disabled]="
																	shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : ap.BP === 1 || null
																"
															/>
														</form>
													</div>
												</div>
											</ng-template>
											<div class="product-detail__item-price-block" (click)="ChangePriceClickLastPriceFunction(ap, 'price')" *ngIf="!isB2BAgent">
												<div class="product-detail__item-price-block-title" style="font-size: 15px; font-weight: bold">מ אחרון</div>
												<div class="product-detail__item-price-blockr" style="font-size: 15px; font-weight: bold">{{ ap.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<div
												class="product-detail__item-price-block"
												(click)="ChangePriceClickLastPriceFunction(ap, 'discount')"
												*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
											>
												<div class="product-detail__item-price-block-title" style="font-size: 15px; font-weight: bold">הנחה אחרונה</div>
												<div class="product-detail__item-price-blockr" style="font-size: 15px; font-weight: bold">{{ ap.DocDiscount | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<div
												class="product-detail__item-price-block"
												(click)="ChangePriceClickLastPriceFunctionMinPrice(ap)"
												*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
											>
												<div class="product-detail__item-price-block-title" style="font-size: 15px; font-weight: bold">מחיר מינימום</div>
												<div class="product-detail__item-price-blockr" style="font-size: 15px; font-weight: bold">{{ ap.MinPrice | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<div class="product-detail__item-price-block" (click)="LastAmount(ap)">
												<div class="product-detail__item-price-block-title" style="font-size: 15px; font-weight: bold">כמות אחרונה</div>
												<div class="product-detail__item--price-blockr" style="font-size: 15px; font-weight: bold">{{ ap.docquantity | number : '1.0-2' : 'en-US' }}</div>
											</div>
										</div>
										<div class="product-detail__item-price-row row" *ngIf="_ss.OneClientInfo.DSP !== 1 && _ss.OneClientInfo.DSP !== 2 && canSeePrices">
											<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
												<div class="order-product__item-present-block-title" style="font-size: 15px; font-weight: bold">{{ alternativeProductCalculations ? 'נטו' : 'מחיר' }}</div>
												<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
													<div style="color: red" *ngIf="MinAlertChangePrice === true">חריגה ממחיר מינימום</div>
													<form [formGroup]="form" (keyup)="ChangePriceFunction(ap)">
														<input
															type="number"
															placeholder="{{ (alternativeProductCalculations ? ap.NetPrice : ap.Price) | number : '1.0-2' : 'en-US' }}"
															formControlName="ChangePrice"
															id="ChangePrice{{ ap.ProductId }}"
															style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
															(keyup.enter)="showHideProductDetailPopup(false)"
															[attr.disabled]="
																shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : ap.BP === 1 || null
															"
														/>
													</form>
												</div>
											</div>
											<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
												<div class="order-product__item-present-block-title" style="font-size: 15px; font-weight: bold">הנחה</div>
												<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
													<div style="color: red; font-size: 14px; font-weight: 600" *ngIf="MinAlertChangeDiscount === true">חריגה ממחיר מינימום</div>
													<form [formGroup]="form" (keyup)="ChangeDiscountFunction(ap)">
														<input
															type="number"
															placeholder="{{ ap.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
															formControlName="ChangeDiscount"
															id="ChangeDiscount{{ ap.ProductId }}"
															(keyup.enter)="showHideProductDetailPopup(false)"
															style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
															[attr.disabled]="
																shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : ap.BD === 1 || null
															"
														/>
													</form>
												</div>
											</div>
											<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
												<div class="order-product__item-present-block-title" style="font-size: 15px; font-weight: bold">בונוס</div>
												<div [ngClass]="{ 'bonus-input': ap.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
													<form [formGroup]="form" (keyup)="Bonus(ap)">
														<input
															type="number"
															[placeholder]="ap.TeudaBonusQuantity"
															formControlName="Bonus"
															id="Bonusqty{{ ap.ProductId }}"
															style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
															(keyup.enter)="showHideProductDetailPopup(false)"
															[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : ap.BB === 1 || null"
														/>
													</form>
												</div>
											</div>
											<div class="product-detail__item-price-block text-align-center" *ngIf="_ss.SeeTotalPrice !== '1'">
												<div class="product-detail__item-price-block-title" style="font-size: 15px; font-weight: bold">סכום שורה</div>
												<div class="product-detail__item-price-blockr" id="RowPrice{{ ap.ProductId }}" style="font-size: 15px; font-weight: bold">
													{{ ap.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
										<div class="order-product__item-present-block order-product__item-present-block_big" style="font-size: 16px; font-weight: 600" *ngIf="ShowExtraHearot">
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big" *ngIf="CommentsProductChoose?.length">
												<span>בחר הערה: </span>
												<form [formGroup]="form" (change)="HearaProduct(ap)">
													<select formControlName="HearaForProduct" style="height: 20px">
														<option *ngFor="let cpc of CommentsProductChoose" value="{{ cpc.rem }}">{{ cpc.rem }}</option>
													</select>
												</form>
											</div>
										</div>
										<div
											class="order-product__item-present-block order-product__item-present-block_big"
											*ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263' || _ss.CustomerInformation.CustChangeCss3 === '1') && ap.NumOfTimes"
										>
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big" style="color: var(--main-color); font-size: 16px; font-weight: 550px">
												תאריך אחרון שנקנה: {{ ap.DocCreateDateDisplay }}
											</div>
										</div>
										<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
												<form [formGroup]="form" (keyup)="HearaProduct(ap)">
													<input
														type="text"
														placeholder="{{ ap.Heara || itemRemarkPlaceholder }}"
														formControlName="HearaForProduct"
														(keyup.enter)="showHideProductDetailPopup(false)"
														class="remark-input"
														style="font-size: 15px"
														maxlength="99"
													/>
												</form>
											</div>
										</div>
									</ng-container>

									<ng-container *ngIf="!isRallyanceReclamation">
										<div
											class="product-detail__item-image image"
											(click)="showHideProductDetailPopup(false)"
											style="height: 250px; max-width: 100%; margin: auto; display: flex; justify-content: center"
										>
											<!-- <div
											class="product-detail__item-image image"
											(click)="showHideProductDetailPopup(false)"
											style="height: 250px; max-width: 100%; margin: auto; display: flex; justify-content: center"
											*ngIf="!isTestEnvForPictureSnapScroll; else multipleImagesScrollBlock"
										> -->
											<img *ngIf="shouldSeeBadatzLogo && (ap | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
											<img
												src="{{ CustImageDomain }}/{{ ap | getImageNameFrom | encodeURI }}.jpg"
												class="ItemPic"
												appFallbackSrc
												alt="photo"
												crossorigin
												style="height: 100%; object-fit: contain; margin: auto"
											/>
										</div>
									</ng-container>
									<!-- <ng-template #multipleImagesScrollBlock>
										<div class="scrollable-image-container">
											<img src="{{ CustImageDomain }}/{{ ap | getImageNameFrom | encodeURI }}.jpg" class="scrollable-image-container__image" appFallbackSrc alt="photo" crossorigin />
											<img src="{{ CustImageDomain }}/{{ ap | getImageNameFrom | encodeURI }}.jpg" class="scrollable-image-container__image" appFallbackSrc alt="photo" crossorigin />
											<img src="{{ CustImageDomain }}/{{ ap | getImageNameFrom | encodeURI }}.jpg" class="scrollable-image-container__image" appFallbackSrc alt="photo" crossorigin />
											<img src="{{ CustImageDomain }}/{{ ap | getImageNameFrom | encodeURI }}.jpg" class="scrollable-image-container__image" appFallbackSrc alt="photo" crossorigin />
										</div>
									</ng-template> -->

									<ng-container *ngIf="!isCatalogAgent && !isRallyanceReclamation">
										<div class="product-detail__item-price-row" *ngIf="_ss.CustomerInformation.ProductDescription === '1' && ap.ItemExtra2">
											{{ ap.ItemExtra2 }}
										</div>
										<div class="product-detail__item-price-row" [ngClass]="{ 'justify-evenly': windowWidth < 1100 }" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
											<button class="product-detail__item-button" (click)="HistoryaParit(ap.ProductId)">היסטוריה</button>
											<ng-container *ngIf="!isB2BAndShouldSeeHistoryButton">
												<button class="product-detail__item-button" *ngIf="doesImageExist">
													<a [href]="_ss.CustomerInformation.CustServer + '/mobil/download-image/' + (doesImageExist | encodeURI)">הורד תמונה</a>
												</button>
												<button class="product-detail__item-button" *ngIf="doesSpecificationFileExist">
													<a [href]="_ss.CustomerInformation.CustServer + '/mobil/download-image/' + (doesSpecificationFileExist | encodeURI)">הורד מפרט</a>
												</button>
												<button class="product-detail__item-button" (click)="DohotParit(ap.ProductId)">דו"ח פריט</button>
											</ng-container>
										</div>
										<div class="product-detail__item-block row" [ngClass]="{ 'product-detail__item-block_center': windowWidth < 1100 }">
											<div class="product-detail__item-more-block" *ngIf="showMoreDetails">
												<ng-container *ngIf="reverseProductExtraInfoOrder">
													<div class="product-detail__item-more-block-row row" *ngFor="let cp of CommentsProduct">
														<div class="product-detail__item-more-block-title">{{ cp.HeadLineStr }}</div>
														<div class="product-detail__item-more-block-text">{{ cp.DataLineStr }}</div>
														<div class="product-detail__item-more-block-text">
															<a href="{{ cp.IfUrl }}" target="_blank" rel="noopener noreferrer">{{ cp.IfUrl }}</a>
														</div>
													</div>
												</ng-container>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.Unit">
													<div class="product-detail__item-more-block-title">{{ ap.ItemPack }} באריזה</div>
													<div class="product-detail__item-more-block-text">{{ ap.Unit }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ShowProfit === '1' || ShowProfit === '2'">
													<div class="product-detail__item-more-block-title">רווחיות שורה</div>
													<div class="product-detail__item-more-block-text">
														{{ ((ap.NetPrice - ap.CPP) / (ShowProfit === '1' ? ap.NetPrice : ap.CPP)) * 100 | number : '1.0-2' : 'en-US' }}
													</div>
												</div>

												<div class="product-detail__item-more-block-row row" *ngIf="ap.NumOfTimes">
													<div class="product-detail__item-more-block-title">תאריך אחרון שנקנה</div>
													<div class="product-detail__item-more-block-text">{{ ap.DocCreateDateDisplay }}</div>
												</div>
												<div class="product-detail__item-more-block-row row">
													<div class="product-detail__item-more-block-title">מספר פעמים שנקנה</div>
													<div class="product-detail__item-more-block-text">{{ ap.NumOfTimes }}</div>
												</div>
												<div
													class="product-detail__item-more-block-row row"
													*ngIf="ap.BM !== 3 && ap.BM !== 4 && _ss.CustomerInformation.HideMinPrice === '0' && _ss.OneClientInfo.DSP !== 2 && canSeePrices"
												>
													<div class="product-detail__item-more-block-title">מחיר מינימום</div>
													<div class="product-detail__item-more-block-text">{{ ap.MinPrice | number : '1.0-2' : 'en-US' }}</div>
												</div>
												<div class="product-detail__item-more-block-row row">
													<div class="product-detail__item-more-block-title">מחלקה</div>
													<div class="product-detail__item-more-block-text">
														{{ ap.IGC + (ap.IGC && groupNamesRecord[ap.IGC] ? ' - ' + groupNamesRecord[ap.IGC].GroupName : '') }}
													</div>
												</div>
												<div class="product-detail__item-more-block-row row">
													<div class="product-detail__item-more-block-title">שם סינון</div>
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra1 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra2">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra2 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra3">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra3 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra4">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra4 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra5">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra5 }}</div>
												</div>

												<ng-container *ngIf="!reverseProductExtraInfoOrder">
													<div class="product-detail__item-more-block-row row" *ngFor="let cp of CommentsProduct">
														<div class="product-detail__item-more-block-title">{{ cp.HeadLineStr }}</div>
														<div class="product-detail__item-more-block-text">{{ cp.DataLineStr }}</div>
														<div class="product-detail__item-more-block-text">
															<a href="{{ cp.IfUrl }}" target="_blank" rel="noopener noreferrer">{{ cp.IfUrl }}</a>
														</div>
													</div>
												</ng-container>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.NetPrice < ap.ShowPrice">
													<div class="product-detail__item-more-block-title">הנחה ממחירון</div>
													<div class="product-detail__item-more-block-text">{{ (1 - ap.NetPrice / ap.ShowPrice) * 100 | number : '1.0-2' }}%</div>
												</div>
											</div>
											<div
												class="product-detail__item-more-title"
												[ngClass]="{ 'product-detail__item-more-title_active': showMoreDetails }"
												(click)="showMoreDetails = !showMoreDetails"
												*ngIf="!isB2BAgent"
											>
												פרטים נוספים
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end markup for product detail popup -->

				<!-- markup for product detail popup -->
				<div class="popup" *ngIf="showProductDetailPopup && windowWidth >= 768" style="z-index: 555">
					<div class="overlay"></div>
					<div class="product-detail-popup popup__content">
						<div class="popup__close" (click)="showHideProductDetailPopup(false)" style="width: 30px; height: 30px; background-color: black"></div>
						<div class="product-detail__wrapper" *ngFor="let ap of testpop; let i = index" #mainap>
							<div class="product-detail__item" [ngClass]="{ 'main-background secondary-color': isAlternativeColors }">
								<div
									class="order-product__item-corner"
									[attr.id]="ap.TeudaPackQuantity > 0 || ap.TeudaQuantity > 0 || ap.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + ap.ProductId"
									appGetBorderColor
									[TeudaPackQuantity]="ap.TeudaPackQuantity || 0"
									[TeudaQuantity]="ap.TeudaQuantity || 0"
									[TeudaBonusQuantity]="ap.TeudaBonusQuantity || 0"
									[NumOfTimes]="ap.NumOfTimes || 0"
									[IC]="ap.IC || ''"
								></div>
								<button mat-icon-button class="expand-icon" (click)="openDialog(ap)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
									<mat-icon>unfold_more</mat-icon>
								</button>

								<div class="product-detail__item-wrapper row" *ngIf="AddProductTrueOrFalse === '1'">
									<button class="product-detail__button" (click)="showHideAddPopup(true)">הוספת פריט</button>
								</div>
								<div class="product-detail__item-wrapper row">
									<div style="font-size: 17px; font-weight: 800" *ngIf="_ss.CustomerInformation.CustNotes1 !== '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'">
										{{ ap.RE }}
									</div>
									<h2 *ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'" style="text-align: center">
										{{ ap.RE }}
									</h2>
									<h1 class="product-detail__item-title">
										<!-- Product_Name -->
										{{ ap | addItemExtraToName : ProductName_AddItemExtra }}
										<div
											class="product-detail__item-title-packaging"
											(click)="GetItemPackOptions(ap)"
											*ngIf="(ap.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') || (ap.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1')"
										>
											{{
												ap.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
													? ap.Unit
													: (ap | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
													? ap.ItemPack + ' קילו'
													: 'באריזה ' + ap.ItemPack
											}}
										</div>
										<div class="product-detail__item-title-packaging" *ngIf="ItemExtraTitle === '1'">{{ ap.ItemPack * ap.NetPrice | number : '1.0-2' }}</div>
										<div class="product-detail__item-title-packaging" *ngIf="ItemExtraTitle === '1'">
											{{ (100 - (ap.CPP / ap.NetPrice) * 100 | number : '1.0-2' : 'en-US') + '%' }}
										</div>
									</h1>
									<div class="product-detail__item-block" *ngIf="isRallyanceReclamation">
										<app-reclamation-popup-content
											[product]="ap"
											[CustImageDomain]="CustImageDomain"
											[DoctypeName]="DoctypeName"
											(submitted)="handleRallyanceReclamationSubmit($event)"
										></app-reclamation-popup-content>
									</div>
									<ng-container *ngIf="!isCatalogAgent && !isRallyanceReclamation">
										<div class="product-detail__item-block">
											<div class="product-detail__item-info" style="gap: 10px">
												<div class="product-detail__item-info-wrapper">
													<div class="product-detail__item-info-title">מק"ט:</div>
													<div class="product-detail__item-info-number">{{ ap.ProductId }}</div>
												</div>
												<div class="product-detail__item-info-wrapper" *ngIf="SeeBarcode !== '5'">
													<div class="product-detail__item-info-title" style="font-size: 15px; font-weight: bold" [style.color]="SeeBarcode === '2' ? 'blue' : 'black'">
														{{ SeeBarcode === '2' ? ap.RE.split(':')[0] + ': ' : SeeBarcode !== '6' && !showItemExtra4InsteadOfBarcode ? 'ברקוד:' : '' }}
													</div>
													<div class="product-detail__item-info-number" style="font-size: 15px; font-weight: bold" [style.color]="SeeBarcode === '2' ? 'blue' : 'black'">
														{{
															SeeBarcode === '2'
																? ap.RE.split(':')[1]
																: showItemExtra4InsteadOfBarcode
																? ap.ItemExtra4 || ''
																: ap.ItemBarcode?.length > 10 && !dontLimitBarcodeTo5DigitsInPopup
																? ap.ItemBarcode?.substring(5)
																: ap.ItemBarcode
														}}
													</div>
												</div>
												<div class="order-product__item-info-count" style="width: 90px" *ngIf="shouldSeeBadatzLogo">
													<img [src]="(ap | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
												</div>
												<ng-container *ngIf="canSeeStock">
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														(click)="openSelectMlay(i)"
														*ngIf="ap.BS | shouldStockShow : 'StockToggle1' : ShowMlay[i] : Admin"
													>
														<div class="order-product__item-count">
															<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock">!</div>
														</div>
													</div>
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														(click)="openSelectMlay(i)"
														*ngIf="ap.BS | shouldStockShow : 'StockToggle2' : ShowMlay[i] : Admin"
													>
														<div class="order-product__item-count bigger-font">
															<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock">!</div>
															<!-- <div class="order-product__item-count-text" style="font-size: 20px">מלאי</div> -->
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': ap.Stock <= 0 }">
																{{ (showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</div>
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														(click)="openMlayPopup(ap)"
														*ngIf="ap.BS | shouldStockShow : 'productPopup' : false : Admin"
													>
														<div class="order-product__item-count bigger-font">
															<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock">!</div>
															<div class="order-product__item-count-text">
																{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : '' }}
															</div>
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': ap.Stock <= 0 }">
																{{ (showTranslatedStock ? ap.Stock / ap.ItemPack : ap.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</div>
													<div
														class="order-product__item-count order-product__item-count_margin much-bigger-font"
														*ngIf="(ap.BS | shouldStockShow : 'productPopup' : false : Admin) && _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88"
													>
														<div class="order-product__item-count bigger-font">
															<div class="order-product__item-count-info" appGetBackground [Stock]="ap.ItemExtra5">!</div>
															<div class="order-product__item-count-text">מלאי</div>
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': ap.ItemExtra5 <= 0 }">
																{{ ap.ItemExtra5 | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</div>
												</ng-container>
											</div>
											<div class="product-detail__item-count">
												<div class="product-detail__item-count-col" *ngIf="(_ss.SeeArizot || shouldOverrideAgentProductQuantities) && !ap.hidePacks">
													<div
														class="product-detail__item-count-title"
														*ngIf="!hasWeightTypeUnitsBehaviors || !ap.canToggleBoxesPacks; else productPopupBoxesToggleButton"
														style="font-size: 15px; font-weight: bold"
													>
														{{ hasWeightTypeUnitsBehaviors ? (ap | weightTypeText : 'pack') : _text.packQuantityText }}
														<span *ngIf="calculatePackagesTotalQuantity">({{ (ap.ItemPack || 1) * (ap.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
													</div>
													<ng-template #productPopupBoxesToggleButton>
														<div
															class="product-detail__item-count-title"
															style="font-size: 15px; font-weight: bold; background: #e8e8e8; text-align: center; padding: 0 5px; border-radius: 5px"
															(click)="toggleProductBetweenBoxesAndPacks(ap)"
														>
															{{ ap.packsWeightMode === 'boxes' ? 'קופסאות' : 'אריזות' }}
															<span *ngIf="calculatePackagesTotalQuantity">({{ (ap.ItemPack || 1) * (ap.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
														</div>
													</ng-template>
													<div class="product-detail__item-count-text">
														<form [formGroup]="form" (input)="stopKeyupEventIfKey($event)" (keyup)="ArizotInput($event, ap)">
															<input
																class="order-product__item-info-count-box"
																type="text"
																inputmode="numeric"
																min="0"
																placeholder="{{ ap.TeudaPackQuantity }}"
																id="Arizot{{ ap.ProductId }}"
																(keyup.enter)="showHideProductDetailPopup(false)"
																formControlName="Arizot"
																[attr.disabled]="shouldOverrideAgentProductQuantities ? null : ap.qt === 1 || ap.qt === 3 || null"
																style="font-size: 23px; font-weight: bold; border: #38d88b solid 1px; width: 70px; height: 30px"
															/>
														</form>
													</div>
												</div>
												<div class="product-detail__item-count-col" *ngIf="(!hideSingles || shouldOverrideAgentProductQuantities) && !ap.hideSingles">
													<div
														class="product-detail__item-count-title"
														*ngIf="!hasWeightTypeUnitsBehaviors || !ap.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
														style="text-align: center; font-size: 15px; font-weight: bold"
													>
														{{ hasWeightTypeUnitsBehaviors ? (ap | weightTypeText : 'single') : 'יחידות' }}
													</div>
													<ng-template #productPopupWeightToggleButton>
														<div
															class="product-detail__item-count-title"
															style="font-size: 15px; font-weight: bold; background: #e8e8e8; text-align: center; padding: 0 5px; border-radius: 5px"
															(click)="toggleProductBetweenWeightAndSingles(ap)"
														>
															{{ ap.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
														</div>
													</ng-template>
													<div class="product-detail__item-count-text">
														<form [formGroup]="form" (input)="stopKeyupEventIfKey($event)" (keyup)="YehidotInput($event, ap)">
															<input
																class="order-product__item-info-count-box"
																type="text"
																inputmode="numeric"
																min="0"
																placeholder="{{ ap.TeudaQuantity }}"
																id="Yehidot{{ ap.ProductId }}"
																formControlName="Yehidot"
																(keyup.enter)="showHideProductDetailPopup(false)"
																style="font-size: 23px; font-weight: bold; border: #38d88b solid 1px; width: 70px; height: 30px"
																[attr.disabled]="shouldOverrideAgentProductQuantities ? null : ap.qt === 1 || ap.qt === 2 || null"
															/>
														</form>
													</div>
												</div>
												<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.CustExtra2 === '1'" style="margin-top: 10px">
													<div class="order-product__item-present-block-input order-product__item-present-block-input_big" *ngIf="ap.qt === 0 || ap.qt === 2">
														<form [formGroup]="form" (keyup)="ChangeKamotProductInput(ap.ProductId)" style="position: relative; right: 50px; font-size: 25px">
															באריזה:<input
																type="number"
																placeholder="{{ ap.ItemPack }}"
																formControlName="ItemPackInput"
																(keyup.enter)="showHideProductDetailPopup(false)"
																style="font-size: 25px; width: 80px"
																maxlength="99"
															/>
														</form>
													</div>
												</div>
												<div
													class="order-product__item-present-block order-product__item-present-block_big"
													style="font-size: 21px; font-weight: 600; padding-top: 15px; margin-right: 35px"
													*ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices"
												>
													<ng-container *ngIf="!alternativeProductCalculations">
														<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
															{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}
															<span *ngIf="ap.NetPrice !== ap.ShowPrice" style="color: #38d88b">{{ ap.NetPrice | number : '1.0-2' : 'en-US' }} </span>
															<span *ngIf="ap.NetPrice === ap.ShowPrice">{{ ap.NetPrice | number : '1.0-2' : 'en-US' }} </span>
														</div>
														<div
															class="order-product__item-present-block-input order-product__item-present-block-input_big"
															*ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 && !isB2BAgent"
														>
															מחיר: <span>{{ ap.ItemExtra4 | number : '1.0-2' : 'en-US' }} </span>
														</div>
														<div class="order-product__item-present-block-input" *ngIf="!dontShowCalculatedDiscount && ap.NetPrice < ap.ShowPrice">
															הנחה מחושבת: {{ (ap.NetPrice / ap.ShowPrice - 1) * -100 | number : '1.0-2' }}%
														</div>
													</ng-container>
												</div>
												<div
													class="order-product__item-present-block order-product__item-present-block_big"
													style="font-size: 21px; font-weight: 600; padding-top: 15px; margin-right: 35px"
													*ngIf="MinAlertChangeDiscount === true && _ss.OneClientInfo.DSP !== 2 && canSeePrices"
												>
													<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
														מחיר מחושב: <span *ngIf="ap.NetPrice !== ap.ShowPrice" style="color: red">{{ dis | number : '1.0-2' : 'en-US' }} </span>
														<span *ngIf="ap.NetPrice === ap.ShowPrice">{{ ap.NetPrice | number : '1.0-2' : 'en-US' }} </span>
													</div>
												</div>
												<div
													class="order-product__item-present-block order-product__item-present-block_big"
													style="font-size: 21px; font-weight: 600; padding-top: 15px; margin-right: 35px"
													*ngIf="MinAlertChangePrice === true"
												>
													<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
														הנחה מחושבת: <span style="color: red">{{ ShowDisAhuz | number : '1.0-2' : 'en-US' }} </span>
													</div>
												</div>
											</div>
										</div>
										<div
											class="product-detail__item-price-row row"
											*ngIf="_ss.OneClientInfo.DSP !== 1 && _ss.OneClientInfo.DSP !== 2 && canSeePrices"
											style="padding-top: 20px; padding-bottom: 20px"
										>
											<div class="product-detail__item-price-block" (click)="Mehiron(ap)" *ngIf="!alternativeProductCalculations; else alternativeCalcBlock">
												<div class="product-detail__item-price-block-title" style="font-weight: bolder">מחירון</div>
												<div class="product-detail__item-price-blockr" style="font-weight: bolder; font-size: 20px">{{ ap.ShowPrice | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<ng-template #alternativeCalcBlock>
												<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
													<div class="product-detail__item-price-block-title" style="font-weight: bold; font-size: 20px">מחיר</div>
													<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
														<div style="color: red" *ngIf="MinAlertChangePrice === true">חריגה ממחיר מינימום</div>
														<form [formGroup]="form" (keyup)="changeBasePrice(ap)">
															<input
																type="number"
																placeholder="{{ ap.basePriceInputted || ap.basePrice || ap.ShowPrice || 0 | toNormalNumber }}"
																formControlName="ChangeBasePrice"
																id="ChangeBasePrice{{ ap.ProductId }}"
																(keyup.enter)="showHideProductDetailPopup(false)"
																style="width: 100px !important; height: 40px; margin-left: 2px"
																[attr.disabled]="
																	shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : ap.BP === 1 || null
																"
															/>
														</form>
													</div>
												</div>
											</ng-template>
											<div class="product-detail__item-price-block" (click)="ChangePriceClickLastPriceFunction(ap, 'price')" *ngIf="!isB2BAgent">
												<div class="product-detail__item-price-block-title" style="font-weight: bold">מ אחרון</div>
												<div class="product-detail__item-price-blockr" style="font-weight: bold; font-size: 20px">{{ ap.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<div
												class="product-detail__item-price-block"
												(click)="ChangePriceClickLastPriceFunction(ap, 'discount')"
												*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
											>
												<div class="product-detail__item-price-block-title" style="font-weight: bold">הנחה אחרונה</div>
												<div class="product-detail__item-price-blockr" style="font-weight: bold; font-size: 20px">{{ ap.DocDiscount | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<div
												class="product-detail__item-price-block"
												(click)="ChangePriceClickLastPriceFunctionMinPrice(ap)"
												*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
											>
												<div class="product-detail__item-price-block-title" style="font-weight: bold">מחיר מינימום</div>
												<div class="product-detail__item-price-blockr" style="font-weight: bold; font-size: 20px">{{ ap.MinPrice | number : '1.0-2' : 'en-US' }}</div>
											</div>
											<div class="product-detail__item-price-block" (click)="LastAmount(ap)">
												<div class="product-detail__item-price-block-title" style="font-weight: bold">כמות אחרונה</div>
												<div class="product-detail__item--price-blockr" style="font-weight: bold; font-size: 20px">{{ ap.docquantity | number : '1.0-2' : 'en-US' }}</div>
											</div>
										</div>
										<div class="product-detail__item-price-row row" *ngIf="_ss.OneClientInfo.DSP !== 1 && _ss.OneClientInfo.DSP !== 2 && canSeePrices" style="padding-bottom: 20px">
											<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
												<div class="product-detail__item-price-block-title" style="font-weight: bold; font-size: 20px">{{ alternativeProductCalculations ? 'נטו' : 'מחיר' }}</div>
												<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
													<div style="color: red" *ngIf="MinAlertChangePrice === true">חריגה ממחיר מינימום</div>
													<form [formGroup]="form" (keyup)="ChangePriceFunction(ap)">
														<input
															type="number"
															placeholder="{{ (alternativeProductCalculations ? ap.NetPrice : ap.Price) | number : '1.0-2' : 'en-US' }}"
															(keyup.enter)="showHideProductDetailPopup(false)"
															formControlName="ChangePrice"
															id="ChangePrice{{ ap.ProductId }}"
															style="width: 100px !important; height: 40px; margin-left: 2px"
															[attr.disabled]="
																shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : ap.BP === 1 || null
															"
														/>
													</form>
												</div>
											</div>
											<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
												<div class="product-detail__item-price-block-title" style="font-weight: bold; font-size: 20px">הנחה</div>
												<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
													<div style="color: red; font-size: 14px; font-weight: 600" *ngIf="MinAlertChangeDiscount === true">חריגה ממחיר מינימום</div>
													<form [formGroup]="form" (keyup)="ChangeDiscountFunction(ap)">
														<input
															type="number"
															placeholder="{{ ap.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
															(keyup.enter)="showHideProductDetailPopup(false)"
															formControlName="ChangeDiscount"
															id="ChangeDiscount{{ ap.ProductId }}"
															style="width: 100px; height: 40px; margin-left: 2px"
															[attr.disabled]="
																shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : ap.BD === 1 || null
															"
														/>
													</form>
												</div>
											</div>
											<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
												<div class="product-detail__item-price-block-title" style="font-weight: bold; font-size: 20px">בונוס</div>
												<div [ngClass]="{ 'bonus-input': ap.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
													<form [formGroup]="form" (keyup)="Bonus(ap)">
														<input
															type="number"
															[placeholder]="ap.TeudaBonusQuantity"
															formControlName="Bonus"
															id="Bonusqty{{ ap.ProductId }}"
															(keyup.enter)="showHideProductDetailPopup(false)"
															style="width: 100px; height: 40px; margin-left: 2px"
															[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : ap.BB === 1 || null"
														/>
													</form>
												</div>
											</div>
											<div class="product-detail__item-price-block text-align-center" *ngIf="_ss.SeeTotalPrice !== '1'">
												<div class="product-detail__item-price-block-title" style="font-size: 20px; font-weight: bold">סכום שורה</div>
												<div class="product-detail__item-price-blockr" id="RowPrice{{ ap.ProductId }}" style="font-size: 20px; font-weight: bold">
													{{ ap.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
										<div class="order-product__item-present-block order-product__item-present-block_big" style="font-size: 16px; font-weight: 600" *ngIf="ShowExtraHearot">
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big" *ngIf="CommentsProductChoose?.length">
												<span>בחר הערה: </span>
												<form [formGroup]="form" (change)="HearaProduct(ap)">
													<select formControlName="HearaForProduct" style="height: 20px">
														<option *ngFor="let cpc of CommentsProductChoose" value="{{ cpc.rem }}">{{ cpc.rem }}</option>
													</select>
												</form>
											</div>
										</div>
										<div
											class="order-product__item-present-block order-product__item-present-block_big"
											*ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263' || _ss.CustomerInformation.CustChangeCss3 === '1') && ap.NumOfTimes"
										>
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big" style="color: var(--main-color); font-size: 16px; font-weight: 550px">
												תאריך אחרון שנקנה: {{ ap.DocCreateDateDisplay }}
											</div>
										</div>
										<div
											class="order-product__item-present-block order-product__item-present-block_big"
											style="padding-bottom: 15px"
											*ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent"
										>
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
												<form [formGroup]="form" (keyup)="HearaProduct(ap)">
													<input
														type="text"
														placeholder="{{ ap.Heara || itemRemarkPlaceholder }}"
														formControlName="HearaForProduct"
														(keyup.enter)="showHideProductDetailPopup(false)"
														style="font-size: 20px; height: 30px"
														class="remark-input"
														maxlength="99"
													/>
												</form>
											</div>
										</div>
									</ng-container>

									<div
										*ngIf="!isRallyanceReclamation"
										class="product-detail__item-image image"
										(click)="showHideProductDetailPopup(false)"
										style="height: 350px; max-width: 100%; margin: auto; display: flex; justify-content: center"
									>
										<img *ngIf="shouldSeeBadatzLogo && (ap | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
										<img
											src="{{ CustImageDomain }}/{{ ap | getImageNameFrom | encodeURI }}.jpg"
											class="ItemPic"
											appFallbackSrc
											alt="photo"
											crossorigin
											style="height: 100%; object-fit: contain; margin: auto"
										/>
									</div>

									<div class="product-detail__item-price-row" *ngIf="_ss.CustomerInformation.ProductDescription === '1' && ap.ItemExtra2">
										{{ ap.ItemExtra2 }}
									</div>
									<ng-container *ngIf="!isCatalogAgent && !isRallyanceReclamation">
										<div class="product-detail__item-price-row" [ngClass]="{ 'justify-evenly': windowWidth < 1100 }" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
											<button class="product-detail__item-button" (click)="HistoryaParit(ap.ProductId)">היסטוריה</button>
											<ng-container *ngIf="!isB2BAndShouldSeeHistoryButton">
												<button class="product-detail__item-button" *ngIf="doesImageExist">
													<a [href]="_ss.CustomerInformation.CustServer + '/mobil/download-image/' + (doesImageExist | encodeURI)">הורד תמונה</a>
												</button>
												<button class="product-detail__item-button" *ngIf="doesSpecificationFileExist">
													<a [href]="_ss.CustomerInformation.CustServer + '/mobil/download-image/' + (doesSpecificationFileExist | encodeURI)">הורד מפרט</a>
												</button>
												<button class="product-detail__item-button" (click)="DohotParit(ap.ProductId)">דו"ח פריט</button>
											</ng-container>
										</div>
										<div class="product-detail__item-block row" [ngClass]="{ 'product-detail__item-block_center': windowWidth < 1100 }">
											<div class="product-detail__item-more-block" *ngIf="showMoreDetails">
												<ng-container *ngIf="reverseProductExtraInfoOrder">
													<div class="product-detail__item-more-block-row row" *ngFor="let cp of CommentsProduct">
														<div class="product-detail__item-more-block-title" style="font-size: 20px">{{ cp.HeadLineStr }}</div>
														<div class="product-detail__item-more-block-text" style="font-size: 20px">{{ cp.DataLineStr }}</div>
														<div class="product-detail__item-more-block-text" style="font-size: 20px">
															<a href="{{ cp.IfUrl }}" target="_blank" rel="noopener noreferrer">{{ cp.IfUrl }}</a>
														</div>
													</div>
												</ng-container>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.Unit">
													<div class="product-detail__item-more-block-title">{{ ap.ItemPack }} באריזה</div>
													<div class="product-detail__item-more-block-text">{{ ap.Unit }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ShowProfit === '1' || ShowProfit === '2'">
													<div class="product-detail__item-more-block-title" style="font-size: 20px">רווחיות שורה</div>
													<div class="product-detail__item-more-block-text" style="font-size: 20px">
														{{ ((ap.NetPrice - ap.CPP) / (ShowProfit === '1' ? ap.NetPrice : ap.CPP)) * 100 | number : '1.0-2' : 'en-US' }}
													</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.NumOfTimes">
													<div class="product-detail__item-more-block-title" style="font-size: 20px">תאריך אחרון שנקנה</div>
													<div class="product-detail__item-more-block-text" style="font-size: 20px">
														{{ ap.DocCreateDateDisplay }}
													</div>
												</div>
												<div class="product-detail__item-more-block-row row">
													<div class="product-detail__item-more-block-title" style="font-size: 20px">מספר פעמים שנקנה</div>
													<div class="product-detail__item-more-block-text" style="font-size: 20px">{{ ap.NumOfTimes }}</div>
												</div>
												<div
													class="product-detail__item-more-block-row row"
													*ngIf="ap.BM !== 3 && ap.BM !== 4 && _ss.CustomerInformation.HideMinPrice === '0' && _ss.OneClientInfo.DSP !== 2 && canSeePrices"
												>
													<div class="product-detail__item-more-block-title" style="font-size: 20px">מחיר מינימום</div>
													<div class="product-detail__item-more-block-text" style="font-size: 20px">{{ ap.MinPrice | number : '1.0-2' : 'en-US' }}</div>
												</div>
												<div class="product-detail__item-more-block-row row">
													<div class="product-detail__item-more-block-title" style="font-size: 20px">מחלקה</div>
													<div class="product-detail__item-more-block-text" style="font-size: 20px">
														{{ ap.IGC + (ap.IGC && groupNamesRecord[ap.IGC] ? ' - ' + groupNamesRecord[ap.IGC].GroupName : '') }}
													</div>
												</div>
												<div class="product-detail__item-more-block-row row">
													<div class="product-detail__item-more-block-title" style="font-size: 20px">שם סינון</div>
													<div class="product-detail__item-more-block-text" style="font-size: 20px">{{ ap.ItemExtra1 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra2">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra2 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra3">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra3 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra4">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra4 }}</div>
												</div>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.ItemExtra5">
													<div class="product-detail__item-more-block-text">{{ ap.ItemExtra5 }}</div>
												</div>
												<ng-container *ngIf="!reverseProductExtraInfoOrder">
													<div class="product-detail__item-more-block-row row" *ngFor="let cp of CommentsProduct">
														<div class="product-detail__item-more-block-title" style="font-size: 20px">{{ cp.HeadLineStr }}</div>
														<div class="product-detail__item-more-block-text" style="font-size: 20px">{{ cp.DataLineStr }}</div>
														<div class="product-detail__item-more-block-text" style="font-size: 20px">
															<a href="{{ cp.IfUrl }}" target="_blank" rel="noopener noreferrer">{{ cp.IfUrl }}</a>
														</div>
													</div>
												</ng-container>
												<div class="product-detail__item-more-block-row row" *ngIf="ap.NetPrice < ap.ShowPrice">
													<div class="product-detail__item-more-block-title">הנחה ממחירון</div>
													<div class="product-detail__item-more-block-text">{{ (1 - ap.NetPrice / ap.ShowPrice) * 100 | number : '1.0-2' }}%</div>
												</div>
											</div>
											<div
												class="product-detail__item-more-title"
												[ngClass]="{ 'product-detail__item-more-title_active': showMoreDetails }"
												(click)="showMoreDetails = !showMoreDetails"
												*ngIf="!isB2BAgent"
											>
												פרטים נוספים
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end markup for product detail popup -->

				<!-- markup for product popup -->
				<div class="popup" *ngIf="OnMivza || updatingProducts || updatingSpecials" style="z-index: 333">
					<div class="overlay"></div>
					<div class="product-popup popup__content">
						<div class="order-product__item">
							<div class="order-process__row order-process__row_success">
								<div class="row">
									<h2 class="order-process__title" *ngIf="OnMivza" style="text-align: center">מעדכן מבצעים ומחירים</h2>
									<h2 class="order-process__title" *ngIf="updatingProducts" style="text-align: center">מעדכן מוצרים</h2>
									<h2 class="order-process__title" *ngIf="updatingSpecials" style="text-align: center">מעדכן מבצעים</h2>
								</div>
								<div class="row"></div>
								<div class="row">
									<div class="order-process__image image">
										<img src="/assets/images/basket/process/success.gif" alt="photo" />
									</div>
								</div>
								<div class="row"></div>
							</div>
						</div>
					</div>
				</div>

				<!-- end markup for product popup -->

				<!-- markup for product popup -->
				<div class="popup" *ngIf="isLoadingPopupOpen === true" style="z-index: 333">
					<div class="overlay"></div>
					<div class="product-popup popup__content">
						<div class="order-product__item">
							<div class="order-process__row order-process__row_success">
								<div class="row">
									<h2 class="order-process__title" style="text-align: center">מעדכן אנא המתן</h2>
								</div>
								<div class="row"></div>
								<div class="row">
									<div class="order-process__image image">
										<img src="/assets/images/basket/process/success.gif" alt="photo" />
									</div>
								</div>
								<div class="row"></div>
							</div>
						</div>
					</div>
				</div>

				<!-- end markup for product popup -->

				<!-- markup for product popup -->
				<div class="popup" *ngIf="ExtraMlayClient === true" style="z-index: 333">
					<div class="overlay"></div>
					<div class="product-popup popup__content">
						<div class="order-product__item">
							<div class="order-process__row order-process__row_success">
								<div class="row">
									<h2 class="order-process__title" style="text-align: center">מעדכן מלאי</h2>
								</div>
								<div class="row"></div>
								<div class="row">
									<div class="order-process__image image">
										<img src="/assets/images/basket/process/success.gif" alt="photo" />
									</div>
								</div>
								<div class="row"></div>
							</div>
						</div>
					</div>
				</div>

				<!-- end markup for product popup -->

				<!-- markup for product popup arizot-->
				<div class="popup" *ngIf="ProductPackOptionsPop === true" style="z-index: 999">
					<div class="overlay"></div>
					<div class="product-detail-add__popup popup__content">
						<div class="popup__close" (click)="CloseProductKmotPop()"></div>
						<div class="product-detail-add__popup-row">
							<h2 class="product-detail-add__popup-title">כמות באריזה לפריט {{ Kamot }}</h2>
						</div>

						<table style="text-align: center; width: 99%; margin: auto">
							<tr>
								<th>כמות ביחידת מידה ראשית</th>
								<th>תיאור יחידת מידה</th>
							</tr>
							<tr *ngFor="let ppo of ProductPackOptions" (click)="ChangeKamotProduct(ppo.ProductID, ppo.UnitPerPack, ppo.PriceChange, ppo.Unit)">
								<td *ngIf="ppo.PriceChange !== 1" style="color: red">{{ ppo.UnitPerPack }}</td>
								<td *ngIf="ppo.PriceChange === 1">{{ ppo.UnitPerPack }}</td>
								<td>{{ ppo.Unit }}</td>
							</tr>
						</table>

						<div class="product-detail-add__popup-row">
							<button class="product-detail-add__popup-button" (click)="CloseProductKmotPop()">חזור</button>
						</div>
					</div>
				</div>

				<div class="popup" *ngIf="DohotParitPop === true" style="z-index: 555">
					<div class="overlay"></div>
					<div class="product-detail__title">דו"ח פריט</div>
					<div class="product-detail-popup popup__content">
						<div class="popup__close" (click)="DohotParit(undefined)" style="width: 30px; height: 30px; background-color: black"></div>
						<div class="product-detail__wrapper2 custom-scrollbar">
							<table class="custom-scrollbar" *ngIf="HistoryaParitVar?.length">
								<thead>
									<tr>
										<th #historyaTableStart [ngStyle]="{ 'width.px': teudaLength && teudaLength > 6 ? teudaLength * 10 : 75 }">תעודה</th>
										<th style="width: 100px">תאריך</th>
										<th style="width: 60px">סוג תעודה</th>
										<th style="width: 50px" *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">סכום שורה</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let hp of HistoryaParitVar" (click)="HeshbonitClick(hp.DocDocNum, hp.DocDocType, hp.Company)">
										<td>{{ hp.DocDocNum }}</td>
										<td>{{ hp.DocCreateDate | date : 'dd-MM-yyyy' }}</td>
										<td>{{ hp.DocTypeName }}</td>
										<td *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">{{ hp.DocTotalRow | number : '1.0-2' }}</td>
									</tr>
								</tbody>
							</table>
							<div class="product-detail__wrapper2__loading" *ngIf="!HistoryaParitVar?.length">
								{{ loadingHistoryaParit ? 'טוען דו"ח פריט, אנא המתינו...' : 'אין נתונים להציג' }}
								<mat-spinner diameter="50" color="primary" *ngIf="loadingHistoryaParit"></mat-spinner>
							</div>
						</div>
					</div>
				</div>

				<div class="popup" *ngIf="HistoryaParitPop === true" style="z-index: 555">
					<div class="overlay"></div>
					<div class="product-detail__title">היסטוריה לפריט</div>
					<div class="product-detail-popup popup__content">
						<div class="popup__close" (click)="HistoryaParit(undefined)" style="width: 30px; height: 30px; background-color: black"></div>
						<div class="product-detail__wrapper2 custom-scrollbar">
							<table class="custom-scrollbar" *ngIf="HistoryaParitVar?.length">
								<tr>
									<th #historyaTableStart [ngStyle]="{ 'width.px': teudaLength && teudaLength > 6 ? teudaLength * 10 : 75 }">תעודה</th>
									<th style="width: 100px">תאריך</th>
									<th style="width: 60px">יחידות</th>
									<th style="width: 60px">{{ _text.packQuantityText }}</th>
									<th style="width: 50px" *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">מחיר</th>
									<th style="width: 50px">הנחה</th>
								</tr>
								<tr *ngFor="let hp of HistoryaParitVar" (click)="HeshbonitClick(hp.DocDocNum, hp.DocDocType, hp.Company)">
									<td>{{ hp.DocDocNum }}</td>
									<td>{{ hp.DocCreateDate | date : 'dd-MM-yyyy' }}</td>
									<td>{{ hp.DocQuantity }}</td>
									<td>{{ hp.DocPackQuantity }}</td>
									<td *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">{{ hp.DocPrice | number : '1.0-2' }}</td>
									<td>{{ hp.DocDiscount }}%</td>
								</tr>
							</table>
							<div class="product-detail__wrapper2__loading" *ngIf="!HistoryaParitVar?.length">
								{{ loadingHistoryaParit ? 'טוען היסטוריה, אנא המתינו...' : 'אין נתונים להציג' }}
								<mat-spinner diameter="50" color="primary" *ngIf="loadingHistoryaParit"></mat-spinner>
							</div>
						</div>
					</div>
				</div>

				<!-- start markup for product detail add popup -->
				<div class="popup" *ngIf="showAddPopup" style="z-index: 999">
					<div class="overlay"></div>
					<div class="product-detail-add__popup popup__content" *ngFor="let ap of testpop">
						<form [formGroup]="form" (ngSubmit)="AddNewProduct(ap)">
							<div class="popup__close" (click)="showHideAddPopup(false)"></div>
							<div class="product-detail-add__popup-row">
								<h4 class="product-detail-add__popup-title">שכפול פריט</h4>
							</div>
							<div class="product-detail-add__popup-row">
								<div class="product-detail-add__popup-label">
									<h3 class="product-detail-add__popup-label-title">שם הפריט החדש</h3>
									<input
										type="text"
										class="product-detail-add__popup-label-input"
										formControlName="NewProductAdd"
										placeholder="{{ ap.Product_Name }}"
										value="{{ ap.Product_Name }}"
										[attr.disabled]="_ss.CustomerInformation.EditProductName !== '1' || null"
									/>
								</div>
							</div>
							<div class="product-detail-add__popup-row product-detail-add__popup-row_bouble">
								<div class="product-detail-add__popup-label">
									<h3 class="product-detail-add__popup-label-title">{{ _text.packQuantityText }}</h3>
									<input type="number" class="product-detail-add__popup-label-input" formControlName="NewProductAddArizot" placeholder="0" />
								</div>
								<div class="product-detail-add__popup-label">
									<h3 class="product-detail-add__popup-label-title">יחידות</h3>
									<input type="number" class="product-detail-add__popup-label-input" formControlName="NewProductAddKamot" placeholder="0" />
								</div>
							</div>
							<div class="product-detail-add__popup-row product-detail-add__popup-row_bouble">
								<div class="product-detail-add__popup-label">
									<h3 class="product-detail-add__popup-label-title">מחיר</h3>
									<input type="number" class="product-detail-add__popup-label-input" formControlName="NewProductAddPrice" placeholder="{{ ap.Price }}" />
								</div>
								<div class="product-detail-add__popup-label">
									<h3 class="product-detail-add__popup-label-title">הנחה</h3>
									<input type="number" class="product-detail-add__popup-label-input" formControlName="NewProductAddPriceAhuz" placeholder="0" />
								</div>
							</div>
							<div class="product-detail-add__popup-row">
								<div class="product-detail-add__popup-label">
									<h3 class="product-detail-add__popup-label-title">הוסף הערה</h3>
									<input type="text" class="product-detail-add__popup-label-input" formControlName="NewProductAddHeara" maxlength="99" placeholder="הערת פריט" />
								</div>
							</div>
							<div class="product-detail-add__popup-row">
								<button class="product-detail-add__popup-button" type="submit">הוסף מוצר</button>
							</div>
						</form>
					</div>
				</div>
				<!-- end markup for product detail add popup -->

				<!-- markup for product detail popup -->
				<div class="popup" *ngIf="HeshbonitClickPop === true" style="z-index: 10999">
					<div class="overlay"></div>
					<div class="product-detail__title">{{Heshbonit?.[0]?.DocTypeName || 'תעודה'}}</div>

					<div class="product-detail-popup popup__content">
						<div class="popup__close" (click)="CloseHeshbonitPop()" style="width: 30px; height: 30px; background-color: black"></div>
						<div class="product-detail__wrapper2 custom-scrollbar">
							<table *ngIf="Heshbonit?.length" style="white-space: initial">
								<tr>
									<th style="width: 180px" #historyaTableStart1>שם מוצר</th>
									<th style="width: 60px">יחידות</th>
									<th style="width: 60px">{{ _text.packQuantityText }}</th>
									<th style="width: 50px" *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">מחיר</th>
									<th style="width: 50px">הנחה</th>
									<th style="width: 60px" *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">שורה</th>
								</tr>
								<tr *ngFor="let hp of Heshbonit">
									<td>{{ hp.DocItemName }}</td>
									<td>{{ hp.DocQuantity }}</td>
									<td>{{ hp.DocPackQuantity }}</td>
									<td *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">{{ hp.DocPrice }}</td>
									<td>{{ hp.DocDiscount }}</td>
									<td *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">{{ hp.DocTotalRow }}</td>
									<!-- <td [ngClass]="{ 'invalid-color': hp.DocItemCode === ProductIdForBack }">{{ hp.DocItemName }}</td>
									<td [ngClass]="{ 'invalid-color': hp.DocItemCode === ProductIdForBack }">{{ hp.DocQuantity }}</td>
									<td [ngClass]="{ 'invalid-color': hp.DocItemCode === ProductIdForBack }">{{ hp.DocPackQuantity }}</td>
									<td *ngIf="_ss.OneClientInfo.DSP !== 2  && canSeePrices" [ngClass]="{ 'invalid-color': hp.DocItemCode === ProductIdForBack }">{{ hp.DocPrice }}</td>
									<td [ngClass]="{ 'invalid-color': hp.DocItemCode === ProductIdForBack }">{{ hp.DocDiscount }}</td>
									<td *ngIf="_ss.OneClientInfo.DSP !== 2  && canSeePrices" [ngClass]="{ 'invalid-color': hp.DocItemCode === ProductIdForBack }">{{ hp.DocTotalRow }}</td> -->
								</tr>
							</table>

							<div class="product-detail__wrapper2__loading" *ngIf="!Heshbonit?.length">
								{{ loadingHeshbonit ? 'טוען היסטוריה, אנא המתינו...' : 'אין נתונים להציג' }}
								<mat-spinner diameter="50" color="primary" *ngIf="loadingHeshbonit"></mat-spinner>
							</div>

							<!-- <div class="product-detail-add__popup-row">
								<button class="product-detail-add__popup-button"
									(click)="CloseHeshbonitPop()">חזור</button>
							</div> -->
						</div>
					</div>
				</div>
				<!-- end markup for product detail popup -->
				<!-- <cdk-virtual-scroll-viewport
					[itemSize]="viewtypeBigItemSize"
					[maxBufferPx]="viewtypeBigItemSize * 10"
					[minBufferPx]="viewtypeBigItemSize * 2"
					class="example-viewport"
					*ngIf="productType === viewTypes.Big && windowWidth < 768"
				> -->
				<cdk-virtual-scroll-viewport
					[style]="filterSortActive ? 'top:253px' : ''"
					itemSize="664"
					minBufferPx="1328"
					maxBufferPx="6640"
					class="example-viewport"
					*ngIf="productType === viewTypes.Big && windowWidth < 768"
				>
					<div class="order-product order-product_big">
						<!-- <div
							class="order-product__item"
							*cdkVirtualFor="let product of AllProducts; let i = index"
							[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
							style="touch-action: manipulation"
							[attr.id]="'Product' + product.ProductId"
						> -->
						<div
							class="order-product__item"
							*cdkVirtualFor="let product of AllProducts; let i = index"
							[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
							style="touch-action: manipulation; height: 644px"
							[attr.id]="'Product' + product.ProductId"
						>
							<div class="order-product__item-present" [ngClass]="{ active: product.TeudaBonusQuantity }" *ngIf="product.present?.icon && !isB2BAgent" style="font-size: larger">
								+<span [attr.id]="'Bonus' + product.ProductId">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }}</span>
							</div>

							<img
								src="../../../assets/images/ribbon.svg"
								*ngIf="product.inSpecial && !useSpecialsIcons"
								(click)="openSpecialsPopupFiltered(product.inSpecial)"
								style="width: 230px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
							/>

							<div class="mivza-icon mivza-icon-to-left">
								<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
								<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
							</div>

							<div
								class="order-product__item-corner"
								[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
								appGetBorderColor
								[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
								[TeudaQuantity]="product.TeudaQuantity || 0"
								[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
								[NumOfTimes]="product.NumOfTimes || 0"
								[IC]="product.IC || ''"
							></div>
							<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
								<mat-icon>unfold_more</mat-icon>
							</button>

							<div
								class="order-product__remarks"
								*ngIf="product.RE && _ss.CustomerInformation.CustNotes1 !== '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
								#tooltip="matTooltip"
								[matTooltip]="product.RE"
								(click)="tooltip.toggle()"
								dir="rtl"
							>
								{{ product.RE }}
							</div>

							<div
								*ngIf="SeeImages === 0"
								class="order-product__item-info-count-image image"
								(click)="MoveToProductCard(product, 0)"
								style="max-width: 95vw; margin: auto; display: flex; justify-content: center"
								[ngClass]="{ IsMainProduct: product.IsMainProduct === 1 }"
							>
								<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
								<img src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg" class="ItemPic" appFallbackSrc alt="photo" crossorigin style="margin: auto" />
							</div>

							<div
								*ngIf="SeeImages === 1"
								class="order-product__item-info-count-image image"
								(click)="MoveToProductCard(product, 0)"
								[ngClass]="{ IsMainProduct: product.IsMainProduct === 1 }"
								style="max-width: 245px; margin: auto; display: flex; justify-content: center"
							>
								<img [src]="logo" class="ItemPic" appFallbackSrc crossorigin alt="photo" style="margin: auto" />
							</div>

							<div
								*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
								style="max-width: 95vw; margin: auto; text-align: center"
							>
								{{ product.RE }}
							</div>
							<h2 class="order-product__item-title" style="height: 40px" *ngIf="_ss.CustomerInformation.SeeProductName !== '1' && SeeBarcode !== '2'">
								<!-- Product_Name -->
								{{ product | addItemExtraToName : ProductName_AddItemExtra }}
								<div
									class="order-product__item-title-packaging"
									(click)="GetItemPackOptions(product)"
									*ngIf="
										(product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') ||
										(product.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1') ||
										product.IsMainProduct === 1
									"
									[ngSwitch]="product.IsMainProduct === 1"
								>
									<ng-container *ngSwitchCase="true">{{ concentratedProducts[product.ProductId]?.length || 0 }}</ng-container>
									<ng-container *ngSwitchCase="false">
										{{
											product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
												? product.Unit
												: (product | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
												? product.ItemPack + ' קילו'
												: 'באריזה ' + product.ItemPack
										}}
									</ng-container>
								</div>
							</h2>
							<div class="order-product__item-block" *ngIf="product.IsMainProduct !== 1">
								<div class="order-product__item-info">
									<div
										class="order-product__item-info-wrapper"
										*ngIf="_ss.CustNum !== '10391' && _ss.CustNum !== '10263' && SeeBarcode !== '1' && SeeBarcode !== '4' && SeeBarcode !== '5' && !isB2BLeasing"
									>
										<ng-container *ngIf="SeeBarcode !== '7'">
											<div
												class="order-product__item-info-title"
												*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
											>
												ברקוד:
											</div>
											<div class="order-product__item-info-number" [ngClass]="{ 'color-green bold': showLastDatePriceAndQuantityInsteadOfBarcodes }" *ngIf="SeeBarcode !== '2'">
												{{
													showItemExtra4InsteadOfBarcode
														? product.ItemExtra4 || ''
														: showLastDatePriceAndQuantityInsteadOfBarcodes
														? product.NumOfTimes
															? product.DocCreateDateDisplay + ' ' + (product.DocNetPrice | shekels) + ' (' + product.docquantity + ')'
															: ''
														: product.ItemBarcode
												}}
											</div>
										</ng-container>
										<div class="order-product__item-info-title" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[0] + ': ' }}</div>
										<div class="order-product__item-info-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
									</div>
									<div class="order-product__item-info-wrapper" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-info-title">ת אחרון:</div>
										<div class="order-product__item-info-number">{{ product.DocCreateDateDisplay }}</div>
									</div>
									<div class="order-product__item-info-wrapper" *ngIf="SeeProductId !== '1' && SeeProductId !== '3'">
										<div class="order-product__item-info-title">קוד פריט:</div>
										<div class="order-product__item-info-number">{{ product.ProductId }}</div>
									</div>
								</div>
								<div class="order-product__item-price" (click)="showPresentContent(i)" *ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1">
									<div class="order-product__item-price-title">מחיר</div>
									<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
										{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
									</div>
								</div>
							</div>
							<div class="order-product__item-block">
								<div class="order-product__item-info-count order-product__item-info-count_big" *ngIf="_ss.SeeArizot && !hidePacks && !product.hidePacks">
									<h3 class="order-product__item-info-count-title" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canToggleBoxesPacks; else productPopupWeightToggleButton">
										{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
										<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
									</h3>
									<ng-template #productPopupWeightToggleButton>
										<h3 class="product-detail__item-count-title" (click)="toggleProductBetweenBoxesAndPacks(product)">
											<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
												{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
												<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
											</span>
										</h3>
									</ng-template>
									<div class="order-product__item-info-count-box">
										<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="ArizotPlus(product)"></button>

										<div
											class="order-product__item-info-count-input"
											(click)="MoveToProductCard(product, 2)"
											*ngIf="product.qt === 0 || product.qt === 2"
											[ngClass]="{ active: product.TeudaPackQuantity }"
											style="font-weight: 700; font-size: 20px"
										>
											{{ product.TeudaPackQuantity }}
										</div>
										<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 700; font-size: 20px; color: red">
											{{ product.TeudaPackQuantity }}
										</div>

										<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)"></button>
									</div>
								</div>
								<div class="order-product__item-info-count" *ngIf="shouldSeeBadatzLogo">
									<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
								</div>
								<ng-container *ngIf="product.IsMainProduct !== 1 && canSeeStock">
									<div
										class="order-product__item-info-count order-product__item-info-count_divider bigger-font"
										(click)="openSelectMlay(i)"
										*ngIf="product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[i] : Admin"
									>
										<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
									</div>
									<div
										class="order-product__item-info-count order-product__item-info-count_divider bigger-font"
										[ngClass]="{ 'justify-align-center': ('' + product.Stock)?.length < 5 }"
										[style.padding]="('' + product.Stock)?.length < 5 ? '0px' : null"
										(click)="openSelectMlay(i)"
										*ngIf="product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[i] : Admin"
									>
										<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
										<!-- <div class="order-product__item-count-text">מלאי</div> -->
										<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
											{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<div
										class="order-product__item-info-count order-product__item-info-count_divider bigger-font"
										(click)="openMlayPopup(product)"
										[ngClass]="{ 'justify-align-center': ('' + product.Stock)?.length < 5 }"
										[style.padding]="('' + product.Stock)?.length < 5 ? '0px' : null"
										*ngIf="product.BS | shouldStockShow : 'productBrowser' : false : Admin"
									>
										<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
										<!-- <div class="order-product__item-count-text">מלאי</div> -->
										<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
											{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
								</ng-container>

								<div class="order-product__item-info-count order-product__item-info-count_big" dir="ltr" *ngIf="!hideSingles && !product.hideSingles">
									<h3 class="product-detail__item-count-title" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton">
										{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
									</h3>
									<ng-template #productPopupWeightToggleButton>
										<h3 class="product-detail__item-count-title" (click)="toggleProductBetweenWeightAndSingles(product)">
											<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
												{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
											</span>
										</h3>
									</ng-template>
									<div class="order-product__item-info-count-box">
										<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="YehidotPlus(product)"></button>

										<div
											class="order-product__item-info-count-input"
											(click)="MoveToProductCard(product, 1)"
											*ngIf="product.qt === 0 || product.qt === 3"
											[ngClass]="{ active: product.TeudaQuantity }"
											style="font-weight: 700; font-size: 20px"
										>
											{{ product.TeudaQuantity }}
										</div>
										<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 700; font-size: 20px; color: red">
											{{ product.TeudaQuantity }}
										</div>

										<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)"></button>
									</div>
								</div>
							</div>
							<div
								class="order-product__item-block order-product__item-block_present"
								[attr.id]="'product-present-' + i"
								*ngIf="product.present?.showContent && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices"
							>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" (click)="Mehiron(product)">
										<div class="order-product__item-present-block-title">מחירון</div>
										<div class="order-product__item-present-block-price">{{ product.ShowPrice }}</div>
									</div>
									<div class="order-product__item-present-block" (click)="ChangePriceClickLastPriceFunction(product, 'price')" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מח אחרון</div>
										<div class="order-product__item-present-block-price">{{ product.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunction(product, 'discount')"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
									>
										<div class="order-product__item-present-block-title">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? 'מחיר בדיקה' : 'הנחה אחרונה' }}
										</div>
										<div class="order-product__item-present-block-percent" [ngClass]="{ 'no-percentage': _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' }">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? product.CPP : (product.DocDiscount | number : '1.0-2' : 'en-US') }}
										</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunctionMinPrice(product)"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
									>
										<div class="order-product__item-present-block-title">מחיר מינימום</div>
										<div class="order-product__item-present-block-price">{{ product.MinPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="Admin === 'true' && _ss.CustomerInformation.ProductExtraInfo === '3'; else remarkElseBlock">
										<div class="order-product__item-present-block-title">רווחיות שורה</div>
										<div class="order-product__item-present-block-price no-price">
											{{ ((product.NetPrice - product.CPP) / product.NetPrice) * 100 | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<ng-template #remarkElseBlock>
										<div class="order-product__item-present-block order-product__item-present-block_icon" [ngClass]="{ 'order-product__item-present-block_active': addComment }">
											<div class="order-product__item-present-block-title">הערה</div>
											<div class="order-product__item-present-block-icon image" (click)="addComment = true">
												<img src="/assets/images/order/comment-active-icon.svg" alt="icon" />
											</div>
										</div>
									</ng-template>
								</div>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מחיר</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangePriceFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.Price | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangePrice"
													[attr.id]="'ChangePrice' + product.ProductId"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">הנחה</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangeDiscountFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangeDiscount"
													id="ChangeDiscount{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BD === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">בונוס</div>
										<div [ngClass]="{ 'bonus-input': product.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="Bonus(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.TeudaBonusQuantity }}"
													formControlName="Bonus"
													id="Bonusqty{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : product.BB === 1 || null"
												/>
											</form>
										</div>
									</div>
									<div class="product-detail__item-price-block" *ngIf="_ss.SeeTotalPrice !== '1'">
										<div class="product-detail__item-price-block-title">סכום שורה</div>
										<div class="product-detail__item-price-block-number" id="RowPrice{{ product.ProductId }}">
											{{ product.TeudaTotalRow }}
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<form [formGroup]="form" (keyup)="HearaProduct(product)" (keyup.enter)="CloseKeybord(i)">
												<input type="text" class="remark-input" placeholder="{{ product.Heara || itemRemarkPlaceholder }}" formControlName="HearaForProduct" maxlength="99" />
											</form>
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<button class="order-product__item-present-block-button" (click)="addComment = false">סגור</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<cdk-virtual-scroll-viewport
					[itemSize]="viewtypeBigItemSize"
					[maxBufferPx]="viewtypeBigItemSize * 10"
					[minBufferPx]="viewtypeBigItemSize * 2"
					class="example-viewport"
					*ngIf="productType === viewTypes.Big && windowWidth >= 768"
					[style]="filterSortActive ? 'top:301px' : ''"
				>
					<div class="order-product order-product_big">
						<div
							class="order-product__item"
							*cdkVirtualFor="let product of AllProducts; let i = index"
							[ngClass]="{ 'main-background secondary-color': isAlternativeColors, opened: product.present?.showContent }"
							id="Product{{ product.ProductId }}"
							style="touch-action: manipulation"
						>
							<div
								class="order-product__item-present"
								[ngClass]="{ active: product.TeudaBonusQuantity }"
								*ngIf="product.present?.icon && !isB2BAgent && !isRallyanceReclamation"
								style="font-size: larger"
							>
								+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }}</span>
							</div>

							<img
								src="../../../assets/images/ribbon.svg"
								*ngIf="product.inSpecial && !useSpecialsIcons"
								(click)="openSpecialsPopupFiltered(product.inSpecial)"
								style="width: 230px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
							/>

							<div class="mivza-icon mivza-icon-to-left">
								<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
								<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
							</div>

							<div
								class="order-product__item-corner"
								[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
								appGetBorderColor
								[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
								[TeudaQuantity]="product.TeudaQuantity || 0"
								[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
								[NumOfTimes]="product.NumOfTimes || 0"
								[IC]="product.IC || ''"
							></div>
							<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
								<mat-icon>unfold_more</mat-icon>
							</button>

							<div
								class="order-product__remarks"
								*ngIf="product.RE && _ss.CustomerInformation.CustNotes1 !== '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
								#tooltip="matTooltip"
								[matTooltip]="product.RE"
								(click)="tooltip.toggle()"
								dir="rtl"
							>
								{{ product.RE }}
							</div>

							<div
								*ngIf="SeeImages === 0"
								class="order-product__item-info-count-image image"
								(click)="MoveToProductCard(product, 0)"
								style="max-width: 80vw; margin: auto; display: flex; justify-content: center"
								[ngClass]="{ IsMainProduct: product.IsMainProduct === 1 }"
							>
								<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
								<img src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="margin: auto" />
							</div>

							<div
								*ngIf="SeeImages === 1"
								class="order-product__item-info-count-image image"
								(click)="MoveToProductCard(product, 0)"
								style="height: 300px; max-width: 245px; margin: auto; display: flex; justify-content: center"
								[ngClass]="{ IsMainProduct: product.IsMainProduct === 1 }"
							>
								<img [src]="logo" class="ItemPic" appFallbackSrc crossorigin alt="photo" style="margin: auto" />
							</div>
							<div
								*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
								style="width: 99%; margin: auto; text-align: center"
							>
								{{ product.RE }}
							</div>
							<h2 class="order-product__item-title" style="height: 40px" *ngIf="_ss.CustomerInformation.SeeProductName !== '1'">
								<!-- Product_Name -->
								{{ product | addItemExtraToName : ProductName_AddItemExtra }}
								<div
									class="order-product__item-title-packaging"
									(click)="GetItemPackOptions(product)"
									*ngIf="
										(product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') ||
										(product.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1') ||
										product.IsMainProduct === 1
									"
									[ngSwitch]="product.IsMainProduct === 1"
								>
									<ng-container *ngSwitchCase="true">{{ concentratedProducts[product.ProductId]?.length || 0 }}</ng-container>
									<ng-container *ngSwitchCase="false">
										{{
											product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
												? product.Unit
												: (product | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
												? product.ItemPack + ' קילו'
												: 'באריזה ' + product.ItemPack
										}}
									</ng-container>
								</div>
							</h2>
							<div class="order-product__item-block" *ngIf="product.IsMainProduct !== 1">
								<div class="order-product__item-info">
									<div
										class="order-product__item-info-wrapper"
										*ngIf="_ss.CustNum !== '10391' && _ss.CustNum !== '10263' && SeeBarcode !== '1' && SeeBarcode !== '4' && SeeBarcode !== '5' && !isB2BLeasing"
									>
										<ng-container *ngIf="SeeBarcode !== '7'">
											<div
												class="order-product__item-info-title"
												*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
											>
												ברקוד:
											</div>
											<div class="order-product__item-info-number" [ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }" *ngIf="SeeBarcode !== '2'">
												{{
													showItemExtra4InsteadOfBarcode
														? product.ItemExtra4 || ''
														: showLastDatePriceAndQuantityInsteadOfBarcodes
														? product.NumOfTimes
															? product.DocCreateDateDisplay + ' ' + (product.DocNetPrice | shekels) + ' (' + product.docquantity + ')'
															: ''
														: product.ItemBarcode
												}}
											</div>
										</ng-container>
										<div class="order-product__item-info-title" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[0] + ': ' }}</div>
										<div class="order-product__item-info-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
									</div>
									<div class="order-product__item-info-wrapper" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-info-title">ת אחרון:</div>
										<div class="order-product__item-info-number">{{ product.DocCreateDateDisplay }}</div>
									</div>
									<div class="order-product__item-info-wrapper" *ngIf="SeeProductId !== '1' && SeeProductId !== '3'">
										<div class="order-product__item-info-title">קוד פריט:</div>
										<div class="order-product__item-info-number">{{ product.ProductId }}</div>
									</div>
								</div>
								<div class="order-product__item-price" (click)="showPresentContent(i)" *ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1">
									<div class="order-product__item-price-title">מחיר</div>
									<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
										{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
									</div>
								</div>
							</div>
							<div class="order-product__item-block">
								<div class="order-product__item-info-count order-product__item-info-count_big" *ngIf="_ss.SeeArizot && !hidePacks && !product.hidePacks">
									<h3 class="order-product__item-info-count-title" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canToggleBoxesPacks; else productPopupWeightToggleButton">
										{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
										<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
									</h3>
									<ng-template #productPopupWeightToggleButton>
										<h3 class="product-detail__item-count-title" (click)="toggleProductBetweenBoxesAndPacks(product)">
											<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
												{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
												<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span></span
											>
										</h3>
									</ng-template>
									<div class="order-product__item-info-count-box">
										<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="ArizotPlus(product)"></button>

										<div
											class="order-product__item-info-count-input"
											(click)="MoveToProductCard(product, 2)"
											*ngIf="product.qt === 0 || product.qt === 2"
											[ngClass]="{ active: product.TeudaPackQuantity }"
											style="font-weight: 700; font-size: 20px"
										>
											{{ product.TeudaPackQuantity }}
										</div>
										<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 700; font-size: 20px; color: red">
											{{ product.TeudaPackQuantity }}
										</div>

										<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)"></button>
									</div>
								</div>
								<div class="order-product__item-info-count" style="width: 70px" *ngIf="shouldSeeBadatzLogo">
									<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
								</div>
								<ng-container *ngIf="product.IsMainProduct !== 1 && canSeeStock">
									<div
										class="order-product__item-info-count order-product__item-info-count_divider bigger-font"
										(click)="openSelectMlay(i)"
										*ngIf="product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[i] : Admin"
									>
										<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
									</div>
									<div
										class="order-product__item-info-count order-product__item-info-count_divider bigger-font"
										[ngClass]="{ 'justify-align-center': ('' + product.Stock)?.length < 5 }"
										[style.padding]="('' + product.Stock)?.length < 5 ? '0px' : null"
										(click)="openSelectMlay(i)"
										*ngIf="product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[i] : Admin"
									>
										<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
										<!-- <div class="order-product__item-count-text">מלאי</div> -->
										<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
											{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<div
										class="order-product__item-info-count order-product__item-info-count_divider bigger-font"
										(click)="openMlayPopup(product)"
										[ngClass]="{ 'justify-align-center': ('' + product.Stock)?.length < 5 }"
										[style.padding]="('' + product.Stock)?.length < 5 ? '0px' : null"
										*ngIf="product.BS | shouldStockShow : 'productBrowser' : false : Admin"
									>
										<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
										<!-- <div class="order-product__item-count-text">מלאי</div> -->
										<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
											{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
								</ng-container>

								<div class="order-product__item-info-count order-product__item-info-count_big" dir="ltr" *ngIf="!hideSingles && !product.hideSingles">
									<h3
										class="order-product__item-info-count-title"
										[ngClass]="{ 'text-align-center': !_ss.SeeArizot }"
										*ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
									>
										{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
									</h3>
									<ng-template #productPopupWeightToggleButton>
										<h3 class="order-product__item-info-count-title" [ngClass]="{ 'text-align-center': !_ss.SeeArizot }" (click)="toggleProductBetweenWeightAndSingles(product)">
											<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
												{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
											</span>
										</h3>
									</ng-template>
									<div class="order-product__item-info-count-box" [ngClass]="{ 'justify-center': !_ss.SeeArizot }">
										<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="YehidotPlus(product)"></button>

										<div
											class="order-product__item-info-count-input"
											(click)="MoveToProductCard(product, 1)"
											*ngIf="product.qt === 0 || product.qt === 3"
											[ngClass]="{ active: product.TeudaQuantity }"
											style="font-weight: 700; font-size: 20px"
										>
											{{ product.TeudaQuantity }}
										</div>
										<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 700; font-size: 20px; color: red">
											{{ product.TeudaQuantity }}
										</div>

										<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)"></button>
									</div>
								</div>
							</div>
							<div
								class="order-product__item-block order-product__item-block_present"
								id="{{ 'product-present-' + i }}"
								*ngIf="product.present?.showContent && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices"
							>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" (click)="Mehiron(product)">
										<div class="order-product__item-present-block-title">מחירון</div>
										<div class="order-product__item-present-block-price">{{ product.ShowPrice }}</div>
									</div>
									<div class="order-product__item-present-block" (click)="ChangePriceClickLastPriceFunction(product, 'price')" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מח אחרון</div>
										<div class="order-product__item-present-block-price">{{ product.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunction(product, 'discount')"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
									>
										<div class="order-product__item-present-block-title">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? 'מחיר בדיקה' : 'הנחה אחרונה' }}
										</div>
										<div class="order-product__item-present-block-percent" [ngClass]="{ 'no-percentage': _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' }">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? product.CPP : (product.DocDiscount | number : '1.0-2' : 'en-US') }}
										</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunctionMinPrice(product)"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
									>
										<div class="order-product__item-present-block-title">מחיר מינימום</div>
										<div class="order-product__item-present-block-price">{{ product.MinPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="Admin === 'true' && _ss.CustomerInformation.ProductExtraInfo === '3'; else remarkElseBlock">
										<div class="order-product__item-present-block-title">רווחיות שורה</div>
										<div class="order-product__item-present-block-price no-price">
											{{ ((product.NetPrice - product.CPP) / product.NetPrice) * 100 | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<ng-template #remarkElseBlock>
										<div class="order-product__item-present-block order-product__item-present-block_icon" [ngClass]="{ 'order-product__item-present-block_active': addComment }">
											<div class="order-product__item-present-block-title">הערה</div>
											<div class="order-product__item-present-block-icon image" (click)="addComment = true">
												<img src="/assets/images/order/comment-active-icon.svg" alt="icon" />
											</div>
										</div>
									</ng-template>
								</div>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מחיר</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangePriceFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.Price | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangePrice"
													id="ChangePrice{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">הנחה</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangeDiscountFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangeDiscount"
													id="ChangeDiscount{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BD === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">בונוס</div>
										<div [ngClass]="{ 'bonus-input': product.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="Bonus(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.TeudaBonusQuantity }}"
													formControlName="Bonus"
													id="Bonusqty{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : product.BB === 1 || null"
												/>
											</form>
										</div>
									</div>
									<div class="product-detail__item-price-block" *ngIf="_ss.SeeTotalPrice !== '1'">
										<div class="product-detail__item-price-block-title">סכום שורה</div>
										<div class="product-detail__item-price-block-number" id="RowPrice{{ product.ProductId }}">
											{{ product.TeudaTotalRow }}
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<form [formGroup]="form" (keyup)="HearaProduct(product)" (keyup.enter)="CloseKeybord(i)">
												<input type="text" class="remark-input" placeholder="{{ product.Heara || itemRemarkPlaceholder }}" formControlName="HearaForProduct" maxlength="99" />
											</form>
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<button class="order-product__item-present-block-button" (click)="addComment = false">סגור</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<cdk-virtual-scroll-viewport
					itemSize="{{ mediumItemSize }}"
					class="example-viewport"
					[ngClass]="{ 'example-viewport-catalog': isCatalogAgent }"
					*ngIf="productType === setProductMediumView()"
					[style]="filterSortActive ? 'top:301px' : ''"
				>
					<div class="order-product order-product_medium order-product_medium_over2500" *ngIf="productType === setProductMediumView()">
						<div class="product-row" *cdkVirtualFor="let row of AllProducts | toMatrix : productsPerRowMedium; let idx = index">
							<div
								class="order-product__item"
								*ngFor="let product of row; let i = index"
								[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
								style="touch-action: manipulation; min-height: 470px; flex: 1"
								id="Product{{ product.ProductId }}"
							>
								<div
									class="order-product__item-present"
									[ngClass]="{ active: product.TeudaBonusQuantity }"
									*ngIf="product.present?.icon && !isB2BAgent && !isRallyanceReclamation"
									style="font-size: larger"
								>
									+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} </span>
								</div>

								<img
									src="../../../assets/images/ribbon.svg"
									*ngIf="product.inSpecial && !useSpecialsIcons"
									(click)="openSpecialsPopupFiltered(product.inSpecial)"
									style="width: 200px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
								/>

								<div class="mivza-icon mivza-icon-to-left">
									<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
									<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
								</div>

								<div
									class="order-product__item-corner"
									[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
									appGetBorderColor
									[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
									[TeudaQuantity]="product.TeudaQuantity || 0"
									[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
									[NumOfTimes]="product.NumOfTimes || 0"
									[IC]="product.IC || ''"
								></div>
								<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
									<mat-icon>unfold_more</mat-icon>
								</button>

								<div
									class="order-product__remarks"
									*ngIf="product.RE && _ss.CustomerInformation.CustNotes1 !== '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
									#tooltip="matTooltip"
									[matTooltip]="product.RE"
									(click)="tooltip.toggle()"
									dir="rtl"
								>
									{{ product.RE }}
								</div>
								<div
									*ngIf="SeeImages === 0"
									class="order-product__item-info-count-image image"
									(click)="MoveToProductCard(product, 0)"
									appSetCardStyle
									[ngClass]="{ IsMainProduct: product.IsMainProduct === 1 }"
									size="med"
								>
									<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
									<img src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="margin: auto" />
								</div>
								<div *ngIf="SeeImages === 1" class="order-product__item-info-count-image image" (click)="MoveToProductCard(product, 0)" appSetCardStyle size="med">
									<img [src]="logo" class="ItemPic" appFallbackSrc crossorigin alt="photo" style="margin: auto" />
								</div>
								<div
									*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
									style="width: 99%; margin: auto; text-align: center"
								>
									{{ product.RE }}
								</div>
								<h2 class="order-product__item-title" style="height: 50px" *ngIf="_ss.CustomerInformation.SeeProductName !== '1'">
									<!-- Product_Name -->
									{{ (product | addItemExtraToName : ProductName_AddItemExtra).toString().slice(0, maxProductNameMobile) }}
									<div
										class="order-product__item-title-packaging"
										(click)="GetItemPackOptions(product)"
										*ngIf="
											(product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') ||
											(product.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1') ||
											product.IsMainProduct === 1
										"
										[ngSwitch]="product.IsMainProduct === 1"
									>
										<ng-container *ngSwitchCase="true">{{ concentratedProducts[product.ProductId]?.length || 0 }}</ng-container>
										<ng-container *ngSwitchCase="false">
											{{
												product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
													? product.Unit
													: (product | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
													? product.ItemPack + ' קילו'
													: 'באריזה ' + product.ItemPack
											}}
										</ng-container>
									</div>
								</h2>
								<div class="order-product__item-block" *ngIf="SeeProductId !== '1' && ShowProduct !== '5' && product.IsMainProduct !== 1 && SeeProductId !== '3'">
									<div class="order-product__item-count">
										<div class="order-product__item-count-text">קוד:</div>
										<div class="order-product__item-count-number">{{ product.ProductId }}</div>
									</div>
								</div>
								<div
									class="order-product__item-block"
									*ngIf="
										SeeBarcode !== '1' &&
										SeeBarcode !== '4' &&
										_ss.CustomerInformation?.ShowProduct !== '2' &&
										_ss.CustomerInformation?.ShowProduct !== '3' &&
										SeeBarcode !== '5' &&
										!isB2BLeasing &&
										product.IsMainProduct !== 1
									"
								>
									<div class="order-product__item-count" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-count-text">ת אחרון:</div>
										<div class="order-product__item-count-number">{{ product.DocCreateDateDisplay }}</div>
									</div>
									<!-- TODO Might be redundant, check if needs refactor -->
									<div class="order-product__item-count" *ngIf="product.IsMainProduct !== 1 && SeeBarcode !== '1' && SeeBarcode !== '4' && SeeBarcode !== '5' && SeeBarcode !== '7'">
										<div
											class="order-product__item-info-title"
											*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
										>
											ברקוד:
										</div>
										<div class="order-product__item-info-number color-green" style="font-size: 18px" *ngIf="showLastDatePriceAndQuantityInsteadOfBarcodes && product.NumOfTimes">
											<span>ת' אחרון: {{ product.DocCreateDateDisplay }}</span> <span> | </span> <span>כ' אחרונה: {{ product.docquantity }}</span>
										</div>
										<div class="order-product__item-info-number" *ngIf="SeeBarcode !== '2' && !showLastDatePriceAndQuantityInsteadOfBarcodes">
											{{ showItemExtra4InsteadOfBarcode ? product.ItemExtra4 || '' : product.ItemBarcode }}
										</div>
										<div class="order-product__item-info-title" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[0] + ': ' }}</div>
										<div class="order-product__item-info-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
									</div>
								</div>
								<div class="order-product__item-block" *ngIf="!isCatalogAgent">
									<div class="order-product__item-info-count" *ngIf="shouldSeeBadatzLogo">
										<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
									</div>
									<div
										class="order-product__item-count order-product__item-count_margin flex-column"
										(click)="openSelectMlay(productsPerRowMedium * idx + +i)"
										*ngIf="product.IsMainProduct !== 1 && canSeeStock && (product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[productsPerRowMedium * idx + +i] : Admin)"
									>
										<div class="order-product__item-count margin-bottom">
											<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
										</div>
										<div
											class="order-product__item-count"
											*ngIf="
												product.IsMainProduct !== 1 &&
												SeeBarcode !== '1' &&
												SeeBarcode !== '4' &&
												SeeBarcode !== '5' &&
												(_ss.CustomerInformation?.ShowProduct === '2' || _ss.CustomerInformation?.ShowProduct === '3') &&
												!isB2BLeasing
											"
										>
											<ng-container *ngIf="SeeBarcode !== '7'">
												<div
													class="order-product__item-info-title"
													*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
												>
													ברקוד:
												</div>
												<div class="order-product__item-info-number" [ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }" *ngIf="SeeBarcode !== '2'">
													{{
														showItemExtra4InsteadOfBarcode
															? product.ItemExtra4 || ''
															: showLastDatePriceAndQuantityInsteadOfBarcodes
															? product.NumOfTimes
																? product.DocCreateDateDisplay + ' | (' + product.docquantity + ')'
																: ''
															: product.ItemBarcode
													}}
												</div>
											</ng-container>
											<div class="order-product__item-info-title" style="color: blue" *ngIf="SeeBarcode === '2'">
												{{ product.RE.split(':')[0] + ': ' }}
											</div>
											<div class="order-product__item-info-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
										</div>
									</div>
									<div
										class="order-product__item-count order-product__item-count_margin flex-column justify-align-center bigger-font"
										(click)="openSelectMlay(productsPerRowMedium * idx + +i)"
										*ngIf="product.IsMainProduct !== 1 && canSeeStock && (product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[productsPerRowMedium * idx + +i] : Admin)"
									>
										<div class="order-product__item-count margin-bottom">
											<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
											<!-- <div class="order-product__item-count-text">מלאי</div> -->
											<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
												{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
										<div
											class="order-product__item-count"
											*ngIf="
												SeeBarcode !== '1' &&
												SeeBarcode !== '4' &&
												SeeBarcode !== '5' &&
												(_ss.CustomerInformation?.ShowProduct === '2' || _ss.CustomerInformation?.ShowProduct === '3') &&
												!isB2BLeasing
											"
										>
											<ng-container *ngIf="product.ItemBarcode && SeeBarcode !== '7'">
												<div
													class="order-product__item-info-title"
													*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
												>
													ברקוד:
												</div>
												<div class="order-product__item-info-number" [ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }" *ngIf="SeeBarcode !== '2'">
													{{
														showItemExtra4InsteadOfBarcode
															? product.ItemExtra4 || ''
															: showLastDatePriceAndQuantityInsteadOfBarcodes
															? product.NumOfTimes
																? product.DocCreateDateDisplay + ' | (' + product.docquantity + ')'
																: ''
															: product.ItemBarcode
													}}
												</div>
											</ng-container>
											<div class="order-product__item-info-title" style="color: blue" *ngIf="SeeBarcode === '2'">
												{{ product.RE.split(':')[0] + ': ' }}
											</div>
											<div class="order-product__item-info-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
										</div>
									</div>
									<div
										class="order-product__item-count order-product__item-count_margin flex-column justify-align-center bigger-font"
										(click)="openMlayPopup(product)"
										*ngIf="product.IsMainProduct !== 1 && canSeeStock && (product.BS | shouldStockShow : 'productBrowser' : false : Admin)"
									>
										<div class="order-product__item-count margin-bottom">
											<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
											<!-- <div class="order-product__item-count-text">מלאי</div> -->
											<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
												{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
										<div
											class="order-product__item-count"
											*ngIf="
												product.IsMainProduct !== 1 &&
												SeeBarcode !== '1' &&
												SeeBarcode !== '4' &&
												SeeBarcode !== '5' &&
												(_ss.CustomerInformation?.ShowProduct === '2' || _ss.CustomerInformation?.ShowProduct === '3') &&
												!isB2BLeasing
											"
										>
											<ng-container *ngIf="SeeBarcode !== '7'">
												<div
													class="order-product__item-info-title"
													*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
												>
													ברקוד:
												</div>
												<div class="order-product__item-info-number" [ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }" *ngIf="SeeBarcode !== '2'">
													{{
														showItemExtra4InsteadOfBarcode
															? product.ItemExtra4 || ''
															: showLastDatePriceAndQuantityInsteadOfBarcodes
															? product.NumOfTimes
																? product.DocCreateDateDisplay + ' | ' + ' (' + product.docquantity + ')'
																: ''
															: product.ItemBarcode
													}}
												</div>
											</ng-container>
											<div class="order-product__item-info-title" style="color: blue" *ngIf="SeeBarcode === '2'">
												{{ product.RE.split(':')[0] + ': ' }}
											</div>
											<div class="order-product__item-info-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
										</div>
									</div>
									<ng-container *ngIf="ShowProduct !== '5' && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1">
										<div class="order-product__item-price" (click)="showPresentContent(productsPerRowMedium * idx + +i)">
											<div class="order-product__item-price-title">מחיר</div>
											<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
												{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
										<div
											class="order-product__item-price flex-column order-product__item-price-red"
											(click)="showPresentContent(productsPerRowMedium * idx + +i)"
											*ngIf="showLastDatePriceAndQuantityInsteadOfBarcodes && product.NumOfTimes"
										>
											<div class="order-product__item-price-title order-product__item-price-red" style="width: auto">מחיר אחרון</div>
											<div class="order-product__item-count-number">
												{{ product.DocNetPrice | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
									</ng-container>
									<div class="order-product__item-count" *ngIf="ShowProduct === '5' && product.IsMainProduct !== 1 && SeeProductId !== '3'">
										<div class="order-product__item-count-text">קוד:</div>
										<div class="order-product__item-count-number">{{ product.ProductId }}</div>
									</div>
								</div>
								<div class="order-product__item-block" *ngIf="ShowProduct !== '4'">
									<div class="order-product__item-info-count order-product__item-info-count_big" *ngIf="_ss.SeeArizot && !hidePacks && !product.hidePacks">
										<h3 class="order-product__item-info-count-title" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canToggleBoxesPacks; else productPopupWeightToggleButton">
											{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
											<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
										</h3>
										<ng-template #productPopupWeightToggleButton>
											<h3 class="order-product__item-info-count-title" (click)="toggleProductBetweenBoxesAndPacks(product)">
												<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px"> {{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}</span>
												<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
											</h3>
										</ng-template>

										<div class="order-product__item-info-count-box">
											<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="ArizotPlus(product)"></button>
											<div
												class="order-product__item-info-count-input"
												(click)="MoveToProductCard(product, 2)"
												*ngIf="product.qt === 0 || product.qt === 2"
												[ngClass]="{ active: product.TeudaPackQuantity }"
												style="font-weight: 700; font-size: 20px"
											>
												{{ product.TeudaPackQuantity }}
											</div>
											<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 700; font-size: 20px; color: red">
												{{ product.TeudaPackQuantity }}
											</div>
											<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)"></button>
										</div>
									</div>
									<div class="order-product__item-info-count order-product__item-info-count_big" dir="ltr" *ngIf="(!hideSingles || isCatalogAgent) && !product.hideSingles">
										<h3
											class="order-product__item-info-count-title"
											*ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
											[ngStyle]="{ textAlign: useSpecialsIcons && (product.mobmivza === '1' || product.wizmivza === '1') ? 'end' : null }"
										>
											{{ isCatalogAgent ? 'כמות' : hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
										</h3>
										<ng-template #productPopupWeightToggleButton>
											<h3 class="order-product__item-info-count-title" (click)="toggleProductBetweenWeightAndSingles(product)">
												<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
													{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
												</span>
											</h3>
										</ng-template>
										<div
											class="order-product__item-info-count-box"
											[ngStyle]="{ justifyContent: useSpecialsIcons && (product.mobmivza === '1' || product.wizmivza === '1') ? 'flex-end' : null }"
										>
											<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="YehidotPlus(product)"></button>
											<div
												class="order-product__item-info-count-input"
												(click)="MoveToProductCard(product, 1)"
												*ngIf="product.qt === 0 || product.qt === 3"
												[ngClass]="{ active: product.TeudaQuantity }"
												style="font-weight: 700; font-size: 20px"
											>
												{{ product.TeudaQuantity }}
											</div>
											<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 700; font-size: 20px; color: red">
												{{ product.TeudaQuantity }}
											</div>
											<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)"></button>
										</div>
									</div>
								</div>
								<div
									class="order-product__item-block order-product__item-block_present"
									id="{{ 'product-present-' + i }}"
									*ngIf="product.present?.showContent && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices"
								>
									<div class="order-product__item-present-row row">
										<div class="order-product__item-present-block">
											<div class="order-product__item-present-block-title" (click)="Mehiron(product)">מחירון</div>
											<div class="order-product__item-present-block-price">{{ product.ShowPrice }}</div>
										</div>
										<div class="order-product__item-present-block" (click)="ChangePriceClickLastPriceFunction(product, 'price')" *ngIf="!isB2BAgent">
											<div class="order-product__item-present-block-title">מח אחרון</div>
											<div class="order-product__item-present-block-price">{{ product.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
										</div>
										<div
											class="order-product__item-present-block"
											(click)="ChangePriceClickLastPriceFunction(product, 'discount')"
											*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
										>
											<div class="order-product__item-present-block-title">{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? 'מחיר בדיקה' : 'הנחה אחרונה' }}</div>
											<div class="order-product__item-present-block-percent" [ngClass]="{ 'no-percentage': _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' }">
												{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? product.CPP : (product.DocDiscount | number : '1.0-2' : 'en-US') }}
											</div>
										</div>
										<div
											class="order-product__item-present-block"
											(click)="ChangePriceClickLastPriceFunctionMinPrice(product)"
											*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
										>
											<div class="order-product__item-present-block-title">מחיר מינימום</div>
											<div class="order-product__item-present-block-price">{{ product.MinPrice | number : '1.0-2' : 'en-US' }}</div>
										</div>
										<div class="order-product__item-present-block" *ngIf="Admin === 'true' && _ss.CustomerInformation.ProductExtraInfo === '3'; else remarkElseBlock">
											<div class="order-product__item-present-block-title">רווחיות שורה</div>
											<div class="order-product__item-present-block-price no-price">
												{{ ((product.NetPrice - product.CPP) / product.NetPrice) * 100 | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
										<ng-template #remarkElseBlock>
											<div class="order-product__item-present-block order-product__item-present-block_icon" [ngClass]="{ 'order-product__item-present-block_active': addComment }">
												<div class="order-product__item-present-block-title">הערה</div>
												<div class="order-product__item-present-block-icon image" (click)="addComment = true">
													<img src="/assets/images/order/comment-active-icon.svg" alt="icon" />
												</div>
											</div>
										</ng-template>
									</div>
									<div class="order-product__item-present-row row">
										<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
											<div class="order-product__item-present-block-title">מחיר</div>
											<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
												<form [formGroup]="form" (keyup)="ChangePriceFunction(product)" (keyup.enter)="CloseKeybord(i)">
													<input
														type="number"
														placeholder="{{ product.Price | number : '1.0-2' : 'en-US' }}"
														formControlName="ChangePrice"
														id="ChangePrice{{ product.ProductId }}"
														style="width: 55px; height: 30px; font-size: 15px"
														[attr.disabled]="
															shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
														"
													/>
												</form>
											</div>
										</div>
										<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
											<div class="order-product__item-present-block-title">הנחה</div>
											<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
												<form [formGroup]="form" (keyup)="ChangeDiscountFunction(product)" (keyup.enter)="CloseKeybord(i)">
													<input
														type="number"
														placeholder="{{ product.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
														formControlName="ChangeDiscount"
														id="ChangeDiscount{{ product.ProductId }}"
														style="width: 55px; height: 30px; font-size: 15px"
														[attr.disabled]="
															shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BD === 1 || null
														"
													/>
												</form>
											</div>
										</div>
										<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
											<div class="order-product__item-present-block-title">בונוס</div>
											<div [ngClass]="{ 'bonus-input': product.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
												<form [formGroup]="form" (keyup)="Bonus(product)" (keyup.enter)="CloseKeybord(productsPerRowMedium * idx + +i)">
													<input
														type="number"
														placeholder="{{ product.TeudaBonusQuantity }}"
														formControlName="Bonus"
														id="Bonusqty{{ product.ProductId }}"
														style="width: 55px; height: 30px; font-size: 15px"
														[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : product.BB === 1 || null"
													/>
												</form>
											</div>
										</div>
										<div class="product-detail__item-price-block" *ngIf="_ss.SeeTotalPrice !== '1'">
											<div class="product-detail__item-price-block-title">סכום שורה</div>
											<div class="product-detail__item-price-block-number" id="RowPrice{{ product.ProductId }}">
												{{ product.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
									</div>
									<div class="order-product__item-present-row row" *ngIf="addComment">
										<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
												<form [formGroup]="form" (keyup)="HearaProduct(product)" (keyup.enter)="CloseKeybord(productsPerRowMedium * idx + +i)">
													<input type="text" class="remark-input" placeholder="{{ product.Heara || itemRemarkPlaceholder }}" formControlName="HearaForProduct" maxlength="99" />
												</form>
											</div>
										</div>
									</div>
									<div class="order-product__item-present-row row" *ngIf="addComment">
										<div class="order-product__item-present-block order-product__item-present-block_big">
											<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
												<button class="order-product__item-present-block-button" (click)="addComment = false">סגור</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<cdk-virtual-scroll-viewport
					itemSize="{{ smallViewItemSize }}"
					class="example-viewport"
					[ngClass]="{ 'example-viewport-catalog': isCatalogAgent }"
					*ngIf="productType === setProductSmallView() && windowWidth < 768 && SeeImagesGeneral === 0"
					minBufferPx="{{ smallViewItemSize * 2 * 2 }}"
					maxBufferPx="{{ smallViewItemSize * 2 * 2 * 2 * 2 }}"
					[style]="filterSortActive ? 'top:253px' : ''"
				>
					<div class="order-product order-product_small">
						<div class="product-row product-row__small" *cdkVirtualFor="let row of AllProducts | toMatrix : 2; let idx = index">
							<div
								class="order-product__item small-under768-general0"
								*ngFor="let product of row; let i = index"
								[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
								style="touch-action: manipulation; flex: 1"
								id="Product{{ product.ProductId }}"
								[ngStyle]="{
									padding: ShowProduct === '1' ? '0' : null,
									'min-height': !isAlternativeShowProduct ? '' : 'unset',
									'height.px': !isAlternativeShowProduct ? 310 : 180
								}"
							>
								<div
									class="order-product__item-present"
									[ngClass]="{ active: product.TeudaBonusQuantity }"
									*ngIf="product.present?.icon && !isB2BAgent && !product.inSpecial && !isRallyanceReclamation"
									style="font-size: larger"
								>
									+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} </span>
								</div>

								<img
									src="../../../assets/images/ribbon.svg"
									*ngIf="product.inSpecial && !useSpecialsIcons"
									(click)="openSpecialsPopupFiltered(product.inSpecial)"
									style="width: 120px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
								/>

								<div class="mivza-icon mivza-icon-to-left">
									<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
									<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
								</div>

								<div
									class="order-product__item-corner"
									[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
									appGetBorderColor
									[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
									[TeudaQuantity]="product.TeudaQuantity || 0"
									[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
									[NumOfTimes]="product.NumOfTimes || 0"
									[IC]="product.IC || ''"
								></div>
								<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
									<mat-icon>unfold_more</mat-icon>
								</button>

								<div
									class="order-product__remarks"
									*ngIf="
										product.RE &&
										_ss.CustomerInformation.CustNotes1 !== '1' &&
										SeeBarcode !== '2' &&
										_ss.CustomerInformation.DisplayRemark !== '1' &&
										_ss.CustomerInformation.DisplayRemark !== '2'
									"
									#tooltip="matTooltip"
									[matTooltip]="product.RE"
									(click)="tooltip.toggle()"
									dir="rtl"
								>
									{{ product.RE }}
								</div>
								<div
									*ngIf="SeeImages === 0"
									class="order-product__item-info-count-image image"
									(click)="MoveToProductCard(product, 0)"
									[ngClass]="{ IsMainProduct: product.IsMainProduct === 1 }"
									appSetCardStyle
									size="small"
									type="image"
								>
									<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
									<img src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="margin: auto" />
								</div>
								<div *ngIf="SeeImages === 1" class="order-product__item-info-count-image image" (click)="MoveToProductCard(product, 0)" appSetCardStyle size="small" type="image">
									<img [src]="logo" class="ItemPic" appFallbackSrc crossorigin alt="photo" style="margin: auto" />
								</div>
								<div
									*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1' && _ss.CustomerInformation.DisplayRemark !== '2'"
									style="width: 99%; margin: auto; text-align: center"
								>
									{{ product.RE }}
								</div>
								<h2 class="order-product__item-title" style="height: 50px; font-size: 17px" *ngIf="_ss.CustomerInformation.SeeProductName !== '1'">
									<!-- Product_Name -->
									{{ (product | addItemExtraToName : ProductName_AddItemExtra).toString().slice(0, maxProductNameMobile) }}
									<div class="order-product__item-title-packaging" *ngIf="product.IsMainProduct === 1">
										{{ concentratedProducts[product.ProductId]?.length || 0 }}
									</div>
								</h2>

								<div
									appSetCardStyle
									size="small"
									[ngClass]="{
										'small-under768-general0-info': shouldSeeBadatzLogo
									}"
									type="info"
									*ngIf="ShowProduct !== '1' && !isAlternativeShowProduct && product.IsMainProduct !== 1"
								>
									<div class="order-product__item-info-count small-under768-general0-info-badatz" *ngIf="shouldSeeBadatzLogo">
										<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
									</div>
									<div class="order-product__item-count" *ngIf="SeeProductId !== '1' && SeeProductId !== '2' && SeeProductId !== '3'">
										<div class="order-product__item-count-text">קוד:</div>
										<div class="order-product__item-count-number">{{ product.ProductId }}</div>
									</div>
									<div
										class="order-product__item-count"
										*ngIf="
											_ss.CustNum !== '10259' &&
											_ss.CustNum !== '10391' &&
											_ss.CustNum !== '10265' &&
											_ss.CustNum !== '10263' &&
											SeeBarcode !== '1' &&
											SeeBarcode !== '4' &&
											SeeBarcode !== '5' &&
											!isB2BLeasing
										"
									>
										<ng-container *ngIf="product.ItemBarcode && SeeBarcode !== '7'">
											<div
												class="order-product__item-info-title"
												*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
											>
												ברקוד:
											</div>
											<div class="order-product__item-info-number" [ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }" *ngIf="SeeBarcode !== '2'">
												{{
													showItemExtra4InsteadOfBarcode
														? product.ItemExtra4 || ''
														: showLastDatePriceAndQuantityInsteadOfBarcodes
														? product.NumOfTimes
															? product.DocCreateDateDisplay + ' ' + (product.DocNetPrice | shekels) + ' (' + product.docquantity + ')'
															: ''
														: product.ItemBarcode
												}}
											</div>
										</ng-container>
										<div class="order-product__item-info-title" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[0] + ': ' }}</div>
										<div class="order-product__item-info-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
									</div>
									<div class="order-product__item-count" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-count-text">ת אחרון:</div>
										<div class="order-product__item-count-number">{{ product.DocCreateDateDisplay }}</div>
									</div>
									<ng-container *ngIf="!isCatalogAgent; else catalogQuantityBlock">
										<div class="order-product__item-count" *ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1">
											<div class="order-product__item-count-text">מחיר:</div>
											<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
												{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
										<div
											class="order-product__item-count"
											[ngClass]="{ 'flex justify-between': hidePacks || hideSingles }"
											*ngIf="_ss.CustomerInformation.SpecificCompanyVersion !== '1' && ShowProduct !== '11' && _ss.CustomerInformation.BonusDisplay !== '1'"
										>
											<div class="order-product__item-count-number color-green" *ngIf="!hidePacks && !hideSingles; else quantityBlock">
												אר :{{ product.TeudaPackQuantity }} \ יח :{{ product.TeudaQuantity }}
											</div>
											<ng-template #quantityBlock>
												<ng-container *ngIf="!hidePacks && !product.hidePacks">
													<h3 class="order-product__item-count-text" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canToggleBoxesPacks; else productPopupWeightToggleButton">
														{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
														<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
													</h3>
													<ng-template #productPopupWeightToggleButton>
														<h3 class="order-product__item-count-text" (click)="toggleProductBetweenBoxesAndPacks(product)">
															<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
																{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
																<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
															</span>
														</h3>
													</ng-template>
													<div class="order-product__item-info-count-box" style="position: relative">
														<button
															class="order-product__item-info-count-button order-product__item-info-count-button_add"
															(click)="ArizotPlus(product)"
															style="position: relative"
														></button>
														<div
															class="order-product__item-info-count-input"
															(click)="MoveToProductCard(product, 2)"
															*ngIf="product.qt === 0 || product.qt === 2"
															[ngClass]="{ active: product.TeudaPackQuantity }"
															style="font-weight: 600; font-size: 18px"
														>
															{{ product.TeudaPackQuantity }}
														</div>
														<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 600; font-size: 18px; color: red">
															{{ product.TeudaPackQuantity }}
														</div>
														<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)" style="position: relative"></button>
													</div>
												</ng-container>
												<ng-container *ngIf="!hideSingles && !product.hideSingles">
													<h3
														class="order-product__item-count-text text-center"
														*ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
													>
														{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
													</h3>
													<ng-template #productPopupWeightToggleButton>
														<h3 class="order-product__item-count-text text-center" (click)="toggleProductBetweenWeightAndSingles(product)">
															<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
																{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
															</span>
														</h3>
													</ng-template>

													<div class="order-product__item-info-count-box" style="position: relative">
														<button
															class="order-product__item-info-count-button order-product__item-info-count-button_add"
															(click)="YehidotPlus(product)"
															style="position: relative"
														></button>

														<div
															class="order-product__item-info-count-input"
															(click)="MoveToProductCard(product, 1)"
															*ngIf="product.qt === 0 || product.qt === 3"
															[ngClass]="{ active: product.TeudaQuantity }"
															style="font-weight: 600; font-size: 18px"
														>
															{{ product.TeudaQuantity }}
														</div>
														<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 600; font-size: 18px; color: red">
															{{ product.TeudaQuantity }}
														</div>

														<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)" style="position: relative"></button>
													</div>
												</ng-container>
											</ng-template>
										</div>
										<ng-container *ngIf="product.IsMainProduct !== 1 && canSeeStock">
											<div
												class="order-product__item-count order-product__item-count_margin bigger-font"
												(click)="openSelectMlay(2 * idx + +i)"
												*ngIf="product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[2 * idx + +i] : Admin"
											>
												<div class="order-product__item-count">
													<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
												</div>
											</div>
											<div
												class="order-product__item-count order-product__item-count_margin bigger-font"
												(click)="openSelectMlay(2 * idx + +i)"
												*ngIf="product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[2 * idx + +i] : Admin"
											>
												<div class="order-product__item-count">
													<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
													<!-- <div class="order-product__item-count-text">מלאי</div> -->
													<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
														{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
													</div>
												</div>
											</div>
											<div
												class="order-product__item-count order-product__item-count_margin bigger-font"
												(click)="openMlayPopup(product)"
												*ngIf="product.BS | shouldStockShow : 'productBrowser' : false : Admin"
											>
												<div class="order-product__item-count">
													<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
													<!-- <div class="order-product__item-count-text">מלאי</div> -->
													<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
														{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
													</div>
												</div>
											</div>
										</ng-container>
									</ng-container>
									<ng-template #catalogQuantityBlock>
										<div class="order-product__item-info-count order-product__item-info-count_big" dir="ltr" style="width: 100%; margin: 14px auto; padding: 0">
											<h3 class="order-product__item-info-count-title" style="font-size: 16px; text-align: center">כמות</h3>
											<div class="order-product__item-info-count-box" style="justify-content: center">
												<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="YehidotPlus(product)"></button>
												<div
													class="order-product__item-info-count-input"
													(click)="MoveToProductCard(product, 1)"
													*ngIf="product.qt === 0 || product.qt === 3"
													[ngClass]="{ active: product.TeudaQuantity }"
													style="font-weight: 700; font-size: 20px"
												>
													{{ product.TeudaQuantity }}
												</div>
												<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 700; font-size: 20px; color: red">
													{{ product.TeudaQuantity }}
												</div>
												<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)"></button>
											</div>
										</div>
									</ng-template>
								</div>
								<div appSetCardStyle size="small" type="info" *ngIf="ShowProduct === '8' || ShowProduct === '9' || ShowProduct === '10'">
									<div class="order-product__item-count" *ngIf="ShowProduct === '9' && SeeProductId !== '3'">
										<div class="order-product__item-count-text">קוד:</div>
										<div class="order-product__item-count-number">{{ product.ProductId }}</div>
									</div>
									<div class="order-product__item-count" *ngIf="ShowProduct === '10' && product.IsMainProduct !== 1">
										<div class="order-product__item-count-text">מחיר:</div>
										<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
											{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<ng-container *ngIf="product.IsMainProduct !== 1 && canSeeStock">
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openSelectMlay(2 * idx + +i)"
											*ngIf="product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[2 * idx + +i] : Admin"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
											</div>
										</div>
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openSelectMlay(2 * idx + +i)"
											*ngIf="product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[2 * idx + +i] : Admin"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
												<!-- <div class="order-product__item-count-text">מלאי</div> -->
												<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
													{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openMlayPopup(product)"
											*ngIf="product.BS | shouldStockShow : 'productBrowser' : false : Admin"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
												<!-- <div class="order-product__item-count-text">מלאי</div> -->
												<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
													{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<!-- 402 is the right number for device emulator scroll on pc -->
				<cdk-virtual-scroll-viewport
					itemSize="{{ 402 }}"
					class="example-viewport"
					*ngIf="productType === setProductSmallView() && windowWidth >= 768"
					[style]="filterSortActive ? 'top:301px' : ''"
				>
					<div class="order-product order-product_small">
						<div class="product-row product-row__small" *cdkVirtualFor="let row of AllProducts | toMatrix : 3; let idx = index">
							<div
								class="order-product__item small-above768"
								*ngFor="let product of row; let i = index"
								[ngClass]="{ 'main-background secondary-color': isAlternativeColors, grid: product.IsMainProduct === 1 }"
								style="touch-action: manipulation; height: 310px; flex: 1"
								id="Product{{ product.ProductId }}"
								[ngStyle]="{ padding: ShowProduct === '1' ? '0' : null }"
							>
								<div
									class="order-product__item-present"
									[ngClass]="{ active: product.TeudaBonusQuantity }"
									*ngIf="product.present?.icon && !isB2BAgent && !product.inSpecial && !isRallyanceReclamation"
									style="font-size: larger"
								>
									+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} </span>
								</div>

								<img
									src="../../../assets/images/ribbon.svg"
									*ngIf="product.inSpecial && !useSpecialsIcons"
									(click)="openSpecialsPopupFiltered(product.inSpecial)"
									style="width: 174px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
								/>

								<div class="mivza-icon mivza-icon-to-left">
									<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
									<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
								</div>

								<div
									class="order-product__item-corner"
									[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
									appGetBorderColor
									[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
									[TeudaQuantity]="product.TeudaQuantity || 0"
									[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
									[NumOfTimes]="product.NumOfTimes || 0"
									[IC]="product.IC || ''"
								></div>
								<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
									<mat-icon>unfold_more</mat-icon>
								</button>

								<div
									class="order-product__remarks"
									*ngIf="
										product.RE &&
										_ss.CustomerInformation.CustNotes1 !== '1' &&
										SeeBarcode !== '2' &&
										_ss.CustomerInformation.DisplayRemark !== '1' &&
										_ss.CustomerInformation.DisplayRemark !== '2'
									"
									#tooltip="matTooltip"
									[matTooltip]="product.RE"
									(click)="tooltip.toggle()"
									dir="rtl"
								>
									{{ product.RE }}
								</div>
								<div
									*ngIf="SeeImages === 0"
									class="order-product__item-info-count-image image"
									(click)="MoveToProductCard(product, 0)"
									[ngClass]="{ IsMainProduct: product.IsMainProduct === 1 }"
									appSetCardStyle
									size="small"
									type="image"
									over768="true"
								>
									<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
									<img src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="margin: auto" />
								</div>
								<div *ngIf="SeeImages === 1" class="order-product__item-info-count-image image" (click)="MoveToProductCard(product, 0)" appSetCardStyle size="small" type="image">
									<img [src]="logo" class="ItemPic" crossorigin alt="photo" style="height: 100%; object-fit: contain; margin: auto" />
								</div>
								<div
									*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1' && _ss.CustomerInformation.DisplayRemark !== '2'"
									style="width: 99%; margin: auto; text-align: center"
								>
									{{ product.RE }}
								</div>
								<h2 class="order-product__item-title" style="height: 50px; font-size: 17px; margin: 0" *ngIf="_ss.CustomerInformation.SeeProductName !== '1'">
									<!-- Product_Name -->
									{{ (product | addItemExtraToName : ProductName_AddItemExtra).toString().slice(0, maxProductNameMobile) }}
									<div class="order-product__item-title-packaging" *ngIf="product.IsMainProduct === 1">
										{{ concentratedProducts[product.ProductId]?.length || 0 }}
									</div>
								</h2>
								<div
									appSetCardStyle
									size="small"
									type="info"
									[ngClass]="{
										'small-above768-info': shouldSeeBadatzLogo
									}"
									*ngIf="ShowProduct !== '1' && product.IsMainProduct !== 1"
								>
									<div class="order-product__item-info-count small-above768-info-badatz" *ngIf="shouldSeeBadatzLogo">
										<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
									</div>
									<div class="order-product__item-count" *ngIf="SeeProductId !== '1' && SeeProductId !== '3'">
										<div class="order-product__item-count-text">קוד:</div>
										<div class="order-product__item-count-number">{{ product.ProductId }}</div>
									</div>
									<div
										class="order-product__item-count"
										*ngIf="_ss.CustNum !== '10259' && _ss.CustNum !== '10391' && _ss.CustNum !== '10263' && SeeBarcode !== '1' && SeeBarcode !== '4' && SeeBarcode !== '5' && !isB2BLeasing"
									>
										<ng-container *ngIf="product.ItemBarcode && SeeBarcode !== '7'">
											<div
												class="order-product__item-count-text"
												*ngIf="SeeBarcode !== '2' && SeeBarcode !== '6' && !showLastDatePriceAndQuantityInsteadOfBarcodes && !showItemExtra4InsteadOfBarcode"
											>
												ברקוד:
											</div>
											<div class="order-product__item-count-number" [ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }" *ngIf="SeeBarcode !== '2'">
												{{
													showItemExtra4InsteadOfBarcode
														? product.ItemExtra4 || ''
														: showLastDatePriceAndQuantityInsteadOfBarcodes
														? product.NumOfTimes
															? product.DocCreateDateDisplay + ' ' + (product.DocNetPrice | shekels) + ' (' + product.docquantity + ')'
															: ''
														: product.ItemBarcode
												}}
											</div>
										</ng-container>
										<div class="order-product__item-count-text" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[0] + ': ' }}</div>
										<div class="order-product__item-count-number" style="color: blue" *ngIf="SeeBarcode === '2'">{{ product.RE.split(':')[1] }}</div>
									</div>
									<div class="order-product__item-count" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-count-text">ת אחרון:</div>
										<div class="order-product__item-count-number">{{ product.DocCreateDateDisplay }}</div>
									</div>
									<div
										class="order-product__item-count"
										*ngIf="_ss.CustomerInformation?.ShowProduct !== '1' && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1"
									>
										<div class="order-product__item-count-text">מחיר:</div>
										<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
											{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<div
										class="order-product__item-count"
										[ngClass]="{ 'flex justify-between': hidePacks || hideSingles }"
										*ngIf="_ss.CustomerInformation?.ShowProduct !== '1' && _ss.CustomerInformation.BonusDisplay !== '1'"
									>
										<div class="order-product__item-count-number color-green" *ngIf="!hidePacks && !hideSingles; else quantityBlock">
											אר :{{ product.TeudaPackQuantity }} \ יח :{{ product.TeudaQuantity }}
										</div>
										<ng-template #quantityBlock>
											<ng-container *ngIf="!hidePacks && !product.hidePacks">
												<h3 class="order-product__item-count-text" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canToggleBoxesPacks; else productPopupWeightToggleButton">
													{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
													<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
												</h3>
												<ng-template #productPopupWeightToggleButton>
													<h3 class="order-product__item-count-text" (click)="toggleProductBetweenBoxesAndPacks(product)">
														<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
															{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
															<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
														</span>
													</h3>
												</ng-template>
												<div class="order-product__item-info-count-box" style="position: relative">
													<button
														class="order-product__item-info-count-button order-product__item-info-count-button_add"
														(click)="ArizotPlus(product)"
														style="position: relative"
													></button>
													<div
														class="order-product__item-info-count-input"
														(click)="MoveToProductCard(product, 2)"
														*ngIf="product.qt === 0 || product.qt === 2"
														[ngClass]="{ active: product.TeudaPackQuantity }"
														style="font-weight: 600; font-size: 18px"
													>
														{{ product.TeudaPackQuantity }}
													</div>
													<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 600; font-size: 18px; color: red">
														{{ product.TeudaPackQuantity }}
													</div>
													<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)" style="position: relative"></button>
												</div>
											</ng-container>
											<ng-container *ngIf="!hideSingles && !product.hideSingles">
												<h3
													class="order-product__item-count-text text-center"
													*ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
												>
													{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
												</h3>
												<ng-template #productPopupWeightToggleButton>
													<h3 class="order-product__item-count-text text-center" (click)="toggleProductBetweenWeightAndSingles(product)">
														<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
															{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
														</span>
													</h3>
												</ng-template>

												<div class="order-product__item-info-count-box" style="position: relative">
													<button
														class="order-product__item-info-count-button order-product__item-info-count-button_add"
														(click)="YehidotPlus(product)"
														style="position: relative"
													></button>

													<div
														class="order-product__item-info-count-input"
														(click)="MoveToProductCard(product, 1)"
														*ngIf="product.qt === 0 || product.qt === 3"
														[ngClass]="{ active: product.TeudaQuantity }"
														style="font-weight: 600; font-size: 18px"
													>
														{{ product.TeudaQuantity }}
													</div>
													<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 600; font-size: 18px; color: red">
														{{ product.TeudaQuantity }}
													</div>

													<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)" style="position: relative"></button>
												</div>
											</ng-container>
										</ng-template>
									</div>

									<ng-container *ngIf="product.IsMainProduct !== 1 && canSeeStock">
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openSelectMlay(3 * idx + +i)"
											*ngIf="product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[3 * idx + +i] : Admin"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
											</div>
										</div>
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openSelectMlay(3 * idx + +i)"
											*ngIf="product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[3 * idx + +i] : Admin"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
												<!-- <div class="order-product__item-count-text">מלאי</div> -->
												<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
													{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openMlayPopup(product)"
											*ngIf="product.BS | shouldStockShow : 'productBrowser' : false : Admin"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
												<!-- <div class="order-product__item-count-text">מלאי</div> -->
												<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
													{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<cdk-virtual-scroll-viewport
					itemSize="270"
					class="example-viewport"
					*ngIf="productType === setProductSmallView() && windowWidth < 768 && SeeImagesGeneral === 1"
					minBufferPx="{{ 270 * 2 * 2 }}"
					maxBufferPx="{{ 270 * 2 * 2 * 2 }}"
					[style]="filterSortActive ? 'top:253px' : ''"
				>
					<div class="order-product order-product_small" *ngIf="productType === setProductSmallView()">
						<div class="product-row" *cdkVirtualFor="let row of AllProducts | toMatrix : 2; index as idx">
							<div
								class="order-product__item small-under768-general1"
								*ngFor="let product of row; let i = index"
								[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
								style="touch-action: manipulation; height: 250px; flex: 1"
								id="Product{{ product.ProductId }}"
							>
								<div
									class="order-product__item-present"
									[ngClass]="{ active: product.TeudaBonusQuantity }"
									*ngIf="product.present?.icon && !isB2BAgent && !product.inSpecial && !isRallyanceReclamation"
									style="font-size: larger"
								>
									+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} </span>
								</div>

								<img
									src="../../../assets/images/ribbon.svg"
									*ngIf="product.inSpecial && !useSpecialsIcons"
									(click)="openSpecialsPopupFiltered(product.inSpecial)"
									style="width: 174px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
								/>

								<div class="mivza-icon mivza-icon-to-left">
									<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
									<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
								</div>

								<div
									class="order-product__item-corner"
									[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
									appGetBorderColor
									[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
									[TeudaQuantity]="product.TeudaQuantity || 0"
									[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
									[NumOfTimes]="product.NumOfTimes || 0"
									[IC]="product.IC || ''"
								></div>
								<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
									<mat-icon>unfold_more</mat-icon>
								</button>

								<div
									class="order-product__remarks"
									*ngIf="
										product.RE &&
										_ss.CustomerInformation.CustNotes1 !== '1' &&
										SeeBarcode !== '2' &&
										_ss.CustomerInformation.DisplayRemark !== '1' &&
										_ss.CustomerInformation.DisplayRemark !== '2'
									"
									#tooltip="matTooltip"
									[matTooltip]="product.RE"
									(click)="tooltip.toggle()"
									dir="rtl"
								>
									{{ product.RE }}
								</div>
								<div
									*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1' && _ss.CustomerInformation.DisplayRemark !== '2'"
									style="width: 99%; margin: auto; text-align: center"
								>
									{{ product.RE }}
								</div>
								<h2 class="order-product__item-title" style="height: 50px; font-size: 17px" (click)="MoveToProductCard(product, 0)">
									<!-- Product_Name -->
									{{ product | addItemExtraToName : ProductName_AddItemExtra }}
									<div class="order-product__item-title-packaging" *ngIf="product.IsMainProduct === 1">
										{{ concentratedProducts[product.ProductId]?.length || 0 }}
									</div>
								</h2>
								<div style="position: relative; top: 10px; width: 99%; margin: auto">
									<div class="order-product__item-count" *ngIf="SeeProductId !== '1' && product.IsMainProduct !== 1 && SeeProductId !== '3'">
										<div class="order-product__item-count-text">קוד:</div>
										<div class="order-product__item-count-number">{{ product.ProductId }}</div>
									</div>
									<div
										class="order-product__item-count"
										*ngIf="
											product.IsMainProduct !== 1 &&
											_ss.CustNum !== '10259' &&
											_ss.CustNum !== '10391' &&
											_ss.CustNum !== '10265' &&
											_ss.CustNum !== '10263' &&
											!isB2BLeasing &&
											SeeBarcode !== '7'
										"
									>
										<div
											class="order-product__item-count-text"
											[ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }"
											[style.color]="SeeBarcode === '2' ? 'blue' : null"
										>
											{{
												showItemExtra4InsteadOfBarcode
													? product.ItemExtra4 || ''
													: showLastDatePriceAndQuantityInsteadOfBarcodes
													? product.NumOfTimes
														? product.DocCreateDateDisplay + ' ' + (product.DocNetPrice | shekels) + ' (' + product.docquantity + ')'
														: ''
													: SeeBarcode === '2'
													? product.RE.split(':')[0] + ': '
													: 'ברקוד:'
											}}
										</div>
										<div class="order-product__item-count-number" [style.color]="SeeBarcode === '2' ? 'blue' : null">
											{{
												showItemExtra4InsteadOfBarcode
													? product.ItemExtra4 || ''
													: showLastDatePriceAndQuantityInsteadOfBarcodes
													? ''
													: SeeBarcode === '2'
													? product.RE.split(':')[1]
													: product.ItemBarcode
											}}
										</div>
									</div>
									<div class="order-product__item-count" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-count-text">ת אחרון:</div>
										<div class="order-product__item-count-number">{{ product.DocCreateDateDisplay }}</div>
									</div>
									<div class="order-product__item-count" *ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1">
										<div class="order-product__item-count-text">מחיר:</div>
										<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
											{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<div class="order-product__item-count" *ngIf="_ss.CustomerInformation.BonusDisplay !== '1'">
										<div class="order-product__item-count-number color-green">אר :{{ product.TeudaPackQuantity }} \ יח :{{ product.TeudaQuantity }}</div>
									</div>
									<ng-container *ngIf="canSeeStock">
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openSelectMlay(2 * idx + +i)"
											*ngIf="product.IsMainProduct !== 1 && (product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[2 * idx + +i] : Admin)"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
											</div>
										</div>
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openSelectMlay(2 * idx + +i)"
											*ngIf="product.IsMainProduct !== 1 && (product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[2 * idx + +i] : Admin)"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
												<!-- <div class="order-product__item-count-text">מלאי</div> -->
												<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
													{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
										<div
											class="order-product__item-count order-product__item-count_margin bigger-font"
											(click)="openMlayPopup(product)"
											*ngIf="product.IsMainProduct !== 1 && (product.BS | shouldStockShow : 'productBrowser' : false : Admin)"
										>
											<div class="order-product__item-count">
												<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
												<!-- <div class="order-product__item-count-text">מלאי</div> -->
												<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
													{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<!-- INLINE VIEW -->

				<cdk-virtual-scroll-viewport
					[itemSize]="inlineUnder768Size"
					class="example-viewport"
					*ngIf="productType === viewTypes.Inline && windowWidth < 768 && SeeImagesGeneral === 0"
					[style]="filterSortBlock ? 'top:253px' : ''"
				>
					<div class="order-product order-product_inline">
						<div
							class="order-product__item inline-under768-general0"
							*cdkVirtualFor="let product of AllProducts; let i = index"
							[ngClass]="{
								'main-background secondary-color': isAlternativeColors,
								'without-quantities inline-under768-general0-smaller-height': alternativeInlineView,
								'inline-under768-general0-opened': product.present?.showContent,
								'with-remark': addComment
							}"
							style="touch-action: manipulation"
							id="Product{{ product.ProductId }}"
						>
							<div
								class="order-product__item-present"
								[ngClass]="{ active: product.TeudaBonusQuantity }"
								*ngIf="product.present?.icon && !isB2BAgent && !isRallyanceReclamation"
								style="font-size: larger"
							>
								+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} </span>
							</div>

							<img
								src="../../../assets/images/ribbon.svg"
								*ngIf="product.inSpecial && !useSpecialsIcons"
								(click)="openSpecialsPopupFiltered(product.inSpecial)"
								style="width: 200px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
							/>

							<div class="mivza-icon">
								<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
								<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
							</div>

							<div
								class="order-product__item-corner"
								[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
								appGetBorderColor
								[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
								[TeudaQuantity]="product.TeudaQuantity || 0"
								[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
								[NumOfTimes]="product.NumOfTimes || 0"
								[IC]="product.IC || ''"
							></div>
							<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="(!isB2BAgent && !product.SpecialID) || isB2BAndShouldSeeHistoryButton">
								<mat-icon>unfold_more</mat-icon>
							</button>

							<div
								class="order-product__remarks"
								*ngIf="product.RE && _ss.CustomerInformation.CustNotes1 !== '1' && SeeBarcode !== '2' && !product.SpecialID"
								#tooltip="matTooltip"
								[matTooltip]="product.RE"
								(click)="tooltip.toggle()"
								dir="rtl"
							>
								{{ product.RE }}
							</div>

							<div
								*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1' && !product.SpecialID"
								style="width: 99%; margin: auto; text-align: center"
							>
								{{ product.RE }}
							</div>
							<h2
								class="order-product__item-title"
								style="height: 50px; font-size: 17px"
								[ngStyle]="{ margin: hasSpecials ? '28px 0 0 0' : null }"
								*ngIf="_ss.CustomerInformation.ShowInlineProduct !== '2'; else alternativeInlineBlock"
								(click)="MoveToProductCard(product, 0)"
							>
								<!-- Product_Name -->
								{{
									!product.SpecialID
										? (product | addItemExtraToName : ProductName_AddItemExtra)
												.toString()
												.slice(0, (product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') || product.ItemPack ? maxProductName : 90)
										: ''
								}}
								<div
									class="order-product__item-title-packaging"
									(click)="GetItemPackOptions(product)"
									*ngIf="
										(product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') ||
										(product.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1') ||
										product.IsMainProduct === 1
									"
									[ngSwitch]="product.IsMainProduct === 1"
								>
									<ng-container *ngSwitchCase="true">{{ concentratedProducts[product.ProductId]?.length || 0 }}</ng-container>
									<ng-container *ngSwitchCase="false">
										{{
											product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
												? product.Unit
												: (product | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
												? product.ItemPack + ' קילו'
												: 'באריזה ' + product.ItemPack
										}}
									</ng-container>
								</div>
							</h2>
							<ng-template #alternativeInlineBlock>
								<div class="order-product__item-title-alternative">
									<div class="order-product__item-title-alternative-right order-product__item-title">
										<!-- Product_Name -->
										{{
											(product | addItemExtraToName : ProductName_AddItemExtra)
												.toString()
												.slice(0, (product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') || product.ItemPack ? 60 : 90)
										}}
										<div
											class="order-product__item-title-packaging"
											(click)="GetItemPackOptions(product)"
											*ngIf="
												(product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') ||
												(product.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1') ||
												product.IsMainProduct === 1
											"
											[ngSwitch]="product.IsMainProduct === 1"
										>
											<ng-container *ngSwitchCase="true">{{ concentratedProducts[product.ProductId]?.length || 0 }}</ng-container>
											<ng-container *ngSwitchCase="false">
												{{
													product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
														? product.Unit
														: (product | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
														? product.ItemPack + ' קילו'
														: 'באריזה ' + product.ItemPack
												}}
											</ng-container>
										</div>
									</div>
									<div class="order-product__item-title-alternative-left">
										<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
										<img
											src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg"
											class="ItemPic"
											(click)="MoveToProductCard(product, 0)"
											appFallbackSrc
											alt="photo"
											crossorigin
											style="width: 100%; height: 70px; object-fit: contain; margin: auto"
										/>

										<div *ngIf="SeeImages === 1" class="order-product__item-info-count-image image" style="height: 100px; margin: auto; display: flex; justify-content: center">
											<img [src]="logo" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="height: 100%; object-fit: contain; margin: auto" />
										</div>
									</div>
								</div>
							</ng-template>
							<div class="order-product__item-block">
								<div class="order-product__item-info" [ngClass]="{ 'without-quantities': alternativeInlineView }">
									<div
										class="order-product__item-info-wrapper"
										*ngIf="product.IsMainProduct !== 1 && _ss.CustNum !== '10391' && _ss.CustNum !== '10263' && SeeBarcode !== '5' && !isB2BLeasing && SeeBarcode !== '7'"
									>
										<div
											class="order-product__item-info-title"
											[ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }"
											style="font-size: 16px; font-weight: 550"
											[style.color]="SeeBarcode === '2' ? 'blue' : null"
										>
											{{
												showItemExtra4InsteadOfBarcode
													? ''
													: showLastDatePriceAndQuantityInsteadOfBarcodes
													? product.NumOfTimes
														? product.DocCreateDateDisplay + ' ' + (product.DocNetPrice | shekels) + ' (' + product.docquantity + ')'
														: ''
													: SeeBarcode === '2'
													? product.RE.split(':')[0] + ': '
													: SeeBarcode !== '6'
													? 'ברקוד:'
													: ''
											}}
										</div>
										<div class="order-product__item-info-number" style="font-size: 16px; font-weight: 550" [style.color]="SeeBarcode === '2' ? 'blue' : null">
											{{
												showItemExtra4InsteadOfBarcode
													? product.ItemExtra4 || ''
													: showLastDatePriceAndQuantityInsteadOfBarcodes
													? ''
													: SeeBarcode === '2'
													? product.RE.split(':')[1]
													: product.ItemBarcode
											}}
										</div>
									</div>

									<div class="order-product__item-info-wrapper" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-info-title" style="font-size: 16px; font-weight: 550">ת אחרון:</div>
										<div class="order-product__item-info-number" style="font-size: 16px; font-weight: 550">
											{{ product.DocCreateDateDisplay }}
										</div>
									</div>

									<div class="order-product__item-info-wrapper" *ngIf="SeeProductId !== '3'">
										<div class="order-product__item-info-title" style="font-size: 16px; font-weight: 550">קוד:</div>
										<div class="order-product__item-info-number" style="font-size: 16px; font-weight: 550">
											{{ product.ProductId }}
										</div>
									</div>
								</div>
								<div
									class="order-product__item-price"
									[ngClass]="{
										'flex justify-evenly pad0': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88,
										'without-quantities': alternativeInlineView,
										'justify-around pad0': isMoveStockInline
									}"
									(click)="showPresentContent(i)"
									*ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1"
								>
									<div>
										<div class="order-product__item-price-title">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}</div>
										<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
											{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<div *ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 && !isB2BAgent">
										<div class="order-product__item-price-title">מחיר</div>
										<div class="order-product__item-count-number">{{ product.ItemExtra4 | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<app-stock-flag
										[product]="product"
										[Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock"
										[Admin]="Admin"
										class="order-product__item-count order-product__item-count_margin bigger-font"
										(click)="openSelectMlay(i)"
										[ShowMlay]="ShowMlay[i]"
										[isMoveStockInline]="isMoveStockInline"
										*ngIf="
											(product.IsMainProduct !== 1 &&
												canSeeStock &&
												(_ss.CustomerInformation.ShowInlineProduct === '1' || _ss.CustomerInformation.ShowInlineProduct === '2') &&
												!isB2BLeasing) ||
											isMoveStockInline
										"
									></app-stock-flag>
								</div>
							</div>
							<div class="order-product__item-block" *ngIf="_ss.CustomerInformation.ShowInlineProduct !== '1' && _ss.CustomerInformation.ShowInlineProduct !== '2'">
								<div class="order-product__item-info-count order-product__item-info-count_big">
									<ng-container *ngIf="_ss.SeeArizot && !hidePacks && !product.hidePacks">
										<h3 class="order-product__item-info-count-title text-center" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canToggleBoxesPacks; else productPopupWeightToggleButton">
											{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
											<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
										</h3>
										<ng-template #productPopupWeightToggleButton>
											<h3 class="order-product__item-info-count-title text-center" (click)="toggleProductBetweenBoxesAndPacks(product)">
												<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
													{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
													<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
												</span>
											</h3>
										</ng-template>
										<div class="order-product__item-info-count-box" style="position: relative; right: 20px">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												(click)="ArizotPlus(product)"
												style="position: relative; left: 10px"
											></button>
											<div
												class="order-product__item-info-count-input"
												(click)="MoveToProductCard(product, 2)"
												*ngIf="product.qt === 0 || product.qt === 2"
												[ngClass]="{ active: product.TeudaPackQuantity }"
												style="font-weight: 600; font-size: 18px"
											>
												{{ product.TeudaPackQuantity }}
											</div>
											<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 600; font-size: 18px; color: red">
												{{ product.TeudaPackQuantity }}
											</div>
											<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)" style="position: relative; right: 10px"></button>
										</div>
										<div class="order-product__item-info-count badatz-inline-under768-general0-logo" *ngIf="shouldSeeBadatzLogo">
											<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
										</div>
									</ng-container>
									<app-stock-flag
										[product]="product"
										[Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock"
										[Admin]="Admin"
										class="order-product__item-count order-product__item-count_margin bigger-font"
										(click)="openSelectMlay(i)"
										[ShowMlay]="ShowMlay[i]"
										*ngIf="product.IsMainProduct !== 1 && canSeeStock && !hideSingles && !isB2BLeasing && !isMoveStockInline"
									></app-stock-flag>
								</div>

								<div class="order-product__item-info-count" (click)="MoveToProductCard(product, 0)" style="display: flex; justify-content: center">
									<div *ngIf="SeeImages === 0" class="order-product__item-info-count-image image" style="height: 100px; margin: auto; display: flex; justify-content: center">
										<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
										<img
											src="{{ CustImageDomain }}/{{ product | getImageNameFrom | encodeURI }}.jpg"
											class="ItemPic"
											appFallbackSrc
											alt="photo"
											crossorigin
											[ngClass]="{ 'ItemPic-with-badatz-inline-under768-general0-logo': shouldSeeBadatzLogo }"
											style="height: 100%; object-fit: contain; margin: auto"
										/>
									</div>

									<div *ngIf="SeeImages === 1" class="order-product__item-info-count-image image" style="height: 100px; margin: auto; display: flex; justify-content: center">
										<img [src]="logo" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="height: 100%; object-fit: contain; margin: auto" />
									</div>
								</div>

								<div class="order-product__item-info-count order-product__item-info-count_big" dir="ltr" *ngIf="!shouldSeeBadatzLogo">
									<!-- //TODO see if ngif can be on main div or ruins layout -->
									<ng-container *ngIf="!hideSingles && !product.hideSingles">
										<h3
											class="order-product__item-info-count-title text-center"
											*ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
										>
											{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
										</h3>
										<ng-template #productPopupWeightToggleButton>
											<h3 class="order-product__item-info-count-title text-center" (click)="toggleProductBetweenWeightAndSingles(product)">
												<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
													{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
												</span>
											</h3>
										</ng-template>

										<div class="order-product__item-info-count-box" style="position: relative; left: 20px">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												(click)="YehidotPlus(product)"
												style="position: relative; right: 10px"
											></button>

											<div
												class="order-product__item-info-count-input"
												(click)="MoveToProductCard(product, 1)"
												*ngIf="product.qt === 0 || product.qt === 3"
												[ngClass]="{ active: product.TeudaQuantity }"
												style="font-weight: 600; font-size: 18px"
											>
												{{ product.TeudaQuantity }}
											</div>
											<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 600; font-size: 18px; color: red">
												{{ product.TeudaQuantity }}
											</div>

											<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)" style="position: relative; left: 10px"></button>
										</div>
									</ng-container>
									<app-stock-flag
										[product]="product"
										[Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock"
										[Admin]="Admin"
										class="order-product__item-count order-product__item-count_margin bigger-font"
										(click)="openSelectMlay(i)"
										[ShowMlay]="ShowMlay[i]"
										*ngIf="product.IsMainProduct !== 1 && canSeeStock && hideSingles && !isB2BLeasing"
									></app-stock-flag>
								</div>
							</div>
							<div
								class="order-product__item-block order-product__item-block_present"
								id="{{ 'product-present-' + i }}"
								*ngIf="product.present?.showContent && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices"
							>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" (click)="Mehiron(product)">
										<div class="order-product__item-present-block-title">מחירון</div>
										<div class="order-product__item-present-block-price">{{ product.ShowPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" (click)="ChangePriceClickLastPriceFunction(product, 'price')" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מח אחרון</div>
										<div class="order-product__item-present-block-price">{{ product.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunction(product, 'discount')"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
									>
										<div class="order-product__item-present-block-title">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? 'מחיר בדיקה' : 'הנחה אחרונה' }}
										</div>
										<div class="order-product__item-present-block-percent" [ngClass]="{ 'no-percentage': _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' }">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? product.CPP : (product.DocDiscount | number : '1.0-2' : 'en-US') }}
										</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunctionMinPrice(product)"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
									>
										<div class="order-product__item-present-block-title">מחיר מינימום</div>
										<div class="order-product__item-present-block-price">{{ product.MinPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="Admin === 'true' && _ss.CustomerInformation.ProductExtraInfo === '3'; else remarkElseBlock">
										<div class="order-product__item-present-block-title">רווחיות שורה</div>
										<div class="order-product__item-present-block-price no-price">
											{{ ((product.NetPrice - product.CPP) / product.NetPrice) * 100 | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<ng-template #remarkElseBlock>
										<div class="order-product__item-present-block order-product__item-present-block_icon" [ngClass]="{ 'order-product__item-present-block_active': addComment }">
											<div class="order-product__item-present-block-title">הערה</div>
											<div class="order-product__item-present-block-icon image" (click)="addComment = true">
												<img src="/assets/images/order/comment-active-icon.svg" alt="icon" />
											</div>
										</div>
									</ng-template>
								</div>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מחיר</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangePriceFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.Price | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangePrice"
													id="ChangePrice{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">הנחה</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangeDiscountFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangeDiscount"
													id="ChangeDiscount{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BD === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">בונוס</div>
										<div [ngClass]="{ 'bonus-input': product.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="Bonus(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.TeudaBonusQuantity }}"
													formControlName="Bonus"
													id="Bonusqty{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : product.BB === 1 || null"
												/>
											</form>
										</div>
									</div>
									<div class="product-detail__item-price-block" *ngIf="_ss.SeeTotalPrice !== '1'">
										<div class="product-detail__item-price-block-title">סכום שורה</div>
										<div class="product-detail__item-price-block-number" id="RowPrice{{ product.ProductId }}">
											{{ product.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<form [formGroup]="form" (keyup)="HearaProduct(product)" (keyup.enter)="CloseKeybord(i)">
												<input type="text" class="remark-input" placeholder="{{ product.Heara || itemRemarkPlaceholder }}" formControlName="HearaForProduct" maxlength="99" />
											</form>
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<button class="order-product__item-present-block-button" (click)="addComment = false">סגור</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<cdk-virtual-scroll-viewport
					itemSize="221"
					class="example-viewport"
					*ngIf="productType === viewTypes.Inline && windowWidth < 768 && SeeImagesGeneral === 1"
					[style]="filterSortBlock ? 'top:253px' : ''"
				>
					<div class="order-product order-product_inline">
						<div
							class="order-product__item inline-under768-general1"
							*cdkVirtualFor="let product of AllProducts; let i = index"
							[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
							style="touch-action: manipulation"
							id="Product{{ product.ProductId }}"
						>
							<div
								class="order-product__item-present"
								[ngClass]="{ active: product.TeudaBonusQuantity }"
								*ngIf="product.present?.icon && !isB2BAgent && !isRallyanceReclamation"
								style="font-size: larger"
							>
								+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} </span>
							</div>

							<img
								src="../../../assets/images/ribbon.svg"
								*ngIf="product.inSpecial && !useSpecialsIcons"
								(click)="openSpecialsPopupFiltered(product.inSpecial)"
								style="width: 200px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
							/>

							<div class="mivza-icon">
								<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
								<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
							</div>

							<div
								class="order-product__item-corner"
								[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
								appGetBorderColor
								[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
								[TeudaQuantity]="product.TeudaQuantity || 0"
								[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
								[NumOfTimes]="product.NumOfTimes || 0"
								[IC]="product.IC || ''"
							></div>
							<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
								<mat-icon>unfold_more</mat-icon>
							</button>

							<div
								class="order-product__remarks"
								*ngIf="product.RE && _ss.CustomerInformation.CustNotes1 !== '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
								#tooltip="matTooltip"
								[matTooltip]="product.RE"
								(click)="tooltip.toggle()"
								dir="rtl"
							>
								{{ product.RE }}
							</div>

							<div
								*ngIf="_ss.CustomerInformation.CustNotes1 === '1' && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'"
								style="width: 99%; margin: auto; text-align: center"
							>
								{{ product.RE }}
							</div>
							<h2 class="order-product__item-title" style="height: 50px; font-size: 17px" [ngStyle]="{ margin: hasSpecials ? '28px 0 0 0' : null }" (click)="MoveToProductCard(product, 0)">
								<!-- Product_Name -->
								{{ product | addItemExtraToName : ProductName_AddItemExtra }}
								<div
									class="order-product__item-title-packaging"
									(click)="GetItemPackOptions(product)"
									*ngIf="
										(product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') ||
										(product.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1') ||
										product.IsMainProduct === 1
									"
									[ngSwitch]="product.IsMainProduct === 1"
								>
									<ng-container *ngSwitchCase="true">{{ concentratedProducts[product.ProductId]?.length || 0 }}</ng-container>
									<ng-container *ngSwitchCase="false">
										{{
											product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
												? product.Unit
												: (product | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
												? product.ItemPack + ' קילו'
												: 'באריזה ' + product.ItemPack
										}}
									</ng-container>
								</div>
							</h2>
							<div class="order-product__item-block">
								<div class="order-product__item-info">
									<div
										class="order-product__item-info-wrapper"
										*ngIf="product.IsMainProduct !== 1 && _ss.CustNum !== '10391' && _ss.CustNum !== '10263' && SeeBarcode !== '5' && !isB2BLeasing && SeeBarcode !== '7'"
									>
										<div
											class="order-product__item-info-title"
											[ngClass]="{ 'color-green': showLastDatePriceAndQuantityInsteadOfBarcodes }"
											style="font-size: 16px; font-weight: 550"
											[style.color]="SeeBarcode === '2' ? 'blue' : null"
										>
											{{
												showItemExtra4InsteadOfBarcode
													? ''
													: showLastDatePriceAndQuantityInsteadOfBarcodes
													? product.NumOfTimes
														? product.DocCreateDateDisplay + ' ' + (product.DocNetPrice | shekels) + ' (' + product.docquantity + ')'
														: ''
													: SeeBarcode === '2'
													? product.RE.split(':')[0] + ': '
													: SeeBarcode !== '6'
													? 'ברקוד:'
													: ''
											}}
										</div>
										<div class="order-product__item-info-number" style="font-size: 16px; font-weight: 550" [style.color]="SeeBarcode === '2' ? 'blue' : null">
											{{
												showItemExtra4InsteadOfBarcode
													? product.ItemExtra4 || ''
													: showLastDatePriceAndQuantityInsteadOfBarcodes
													? ''
													: SeeBarcode === '2'
													? product.RE.split(':')[1]
													: product.ItemBarcode
											}}
										</div>
									</div>
									<div class="order-product__item-info-wrapper" *ngIf="(_ss.CustNum === '10391' || _ss.CustNum === '10263') && product.NumOfTimes">
										<div class="order-product__item-info-title" style="font-size: 16px; font-weight: 550">ת אחרון:</div>
										<div class="order-product__item-info-number" style="font-size: 16px; font-weight: 550">
											{{ product.DocCreateDateDisplay }}
										</div>
									</div>

									<div class="order-product__item-info-wrapper" *ngIf="SeeProductId !== '3'">
										<div class="order-product__item-info-title" style="font-size: 16px; font-weight: 550">קוד:</div>
										<div class="order-product__item-info-number" style="font-size: 16px; font-weight: 550">
											{{ product.ProductId }}
										</div>
									</div>
								</div>
								<div
									class="order-product__item-price"
									[ngClass]="{ 'flex justify-evenly pad0': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 }"
									(click)="showPresentContent(i)"
									*ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1"
								>
									<div>
										<div class="order-product__item-price-title">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}</div>
										<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
											{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<div *ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 && !isB2BAgent">
										<div class="order-product__item-price-title">מחיר</div>
										<div class="order-product__item-count-number">{{ product.ItemExtra4 | number : '1.0-2' : 'en-US' }}</div>
									</div>
								</div>
							</div>
							<div class="order-product__item-block justify-evenly">
								<div class="order-product__item-info-count order-product__item-info-count_big">
									<ng-container *ngIf="_ss.SeeArizot && !hidePacks && !product.hidePacks">
										<h3 class="order-product__item-info-count-title" *ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton">
											{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
											<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
										</h3>
										<ng-template #productPopupWeightToggleButton>
											<h3 class="order-product__item-info-count-title" (click)="toggleProductBetweenBoxesAndPacks(product)">
												<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
													{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
													<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
												</span>
											</h3>
										</ng-template>
										<div class="order-product__item-info-count-box" style="position: relative; right: 20px">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												(click)="ArizotPlus(product)"
												style="position: relative; left: 10px"
											></button>
											<div
												class="order-product__item-info-count-input"
												(click)="MoveToProductCard(product, 2)"
												*ngIf="product.qt === 0 || product.qt === 2"
												[ngClass]="{ active: product.TeudaPackQuantity }"
												style="font-weight: 600; font-size: 18px"
											>
												{{ product.TeudaPackQuantity }}
											</div>
											<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 600; font-size: 18px; color: red">
												{{ product.TeudaPackQuantity }}
											</div>
											<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)" style="position: relative; right: 10px"></button>
										</div>
									</ng-container>
								</div>
								<div class="order-product__item-info-count" *ngIf="shouldSeeBadatzLogo">
									<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
								</div>
								<app-stock-flag
									[product]="product"
									[Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock"
									[Admin]="Admin"
									class="order-product__item-count order-product__item-count_margin bigger-font"
									(click)="openSelectMlay(i)"
									[ShowMlay]="ShowMlay[i]"
									*ngIf="product.IsMainProduct !== 1 && canSeeStock && !hideSingles && !isB2BLeasing"
								></app-stock-flag>

								<div class="order-product__item-info-count order-product__item-info-count_big" dir="ltr">
									<ng-container *ngIf="!hideSingles && !product.hideSingles">
										<h3
											class="order-product__item-info-count-title text-center"
											*ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
										>
											{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
										</h3>
										<ng-template #productPopupWeightToggleButton>
											<h3 class="order-product__item-info-count-title text-center" (click)="toggleProductBetweenWeightAndSingles(product)">
												<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
													{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
												</span>
											</h3>
										</ng-template>
										<div class="order-product__item-info-count-box" style="position: relative; left: 20px">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												(click)="YehidotPlus(product)"
												style="position: relative; right: 10px"
											></button>

											<div
												class="order-product__item-info-count-input"
												(click)="MoveToProductCard(product, 1)"
												*ngIf="product.qt === 0 || product.qt === 3"
												[ngClass]="{ active: product.TeudaQuantity }"
												style="font-weight: 600; font-size: 18px"
											>
												{{ product.TeudaQuantity }}
											</div>
											<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 600; font-size: 18px; color: red">
												{{ product.TeudaQuantity }}
											</div>

											<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)" style="position: relative; left: 10px"></button>
										</div>
									</ng-container>
									<div class="order-product__item-info-count" *ngIf="shouldSeeBadatzLogo">
										<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
									</div>
									<app-stock-flag
										[product]="product"
										[Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock"
										[Admin]="Admin"
										class="order-product__item-count order-product__item-count_margin bigger-font"
										(click)="openSelectMlay(i)"
										[ShowMlay]="ShowMlay[i]"
										*ngIf="product.IsMainProduct !== 1 && canSeeStock && hideSingles && !isB2BLeasing"
									></app-stock-flag>
								</div>
							</div>
							<div
								class="order-product__item-block order-product__item-block_present"
								id="{{ 'product-present-' + i }}"
								*ngIf="product.present?.showContent && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices"
							>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" (click)="Mehiron(product)">
										<div class="order-product__item-present-block-title">מחירון</div>
										<div class="order-product__item-present-block-price">{{ product.ShowPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" (click)="ChangePriceClickLastPriceFunction(product, 'price')" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מח אחרון</div>
										<div class="order-product__item-present-block-price">{{ product.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunction(product, 'discount')"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
									>
										<div class="order-product__item-present-block-title">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? 'מחיר בדיקה' : 'הנחה אחרונה' }}
										</div>
										<div class="order-product__item-present-block-percent" [ngClass]="{ 'no-percentage': _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' }">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? product.CPP : (product.DocDiscount | number : '1.0-2' : 'en-US') }}
										</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunctionMinPrice(product)"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
									>
										<div class="order-product__item-present-block-title">מחיר מינימום</div>
										<div class="order-product__item-present-block-price">{{ product.MinPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="Admin === 'true' && _ss.CustomerInformation.ProductExtraInfo === '3'; else remarkElseBlock">
										<div class="order-product__item-present-block-title">רווחיות שורה</div>
										<div class="order-product__item-present-block-price no-price">
											{{ ((product.NetPrice - product.CPP) / product.NetPrice) * 100 | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<ng-template #remarkElseBlock>
										<div class="order-product__item-present-block order-product__item-present-block_icon" [ngClass]="{ 'order-product__item-present-block_active': addComment }">
											<div class="order-product__item-present-block-title">הערה</div>
											<div class="order-product__item-present-block-icon image" (click)="addComment = true">
												<img src="/assets/images/order/comment-active-icon.svg" alt="icon" />
											</div>
										</div>
									</ng-template>
								</div>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מחיר</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangePriceFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.Price | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangePrice"
													id="ChangePrice{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">הנחה</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangeDiscountFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangeDiscount"
													id="ChangeDiscount{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BD === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">בונוס</div>
										<div [ngClass]="{ 'bonus-input': product.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="Bonus(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.TeudaBonusQuantity }}"
													formControlName="Bonus"
													id="Bonusqty{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : product.BB === 1 || null"
												/>
											</form>
										</div>
									</div>
									<div class="product-detail__item-price-block" *ngIf="_ss.SeeTotalPrice !== '1'">
										<div class="product-detail__item-price-block-title">סכום שורה</div>
										<div class="product-detail__item-price-block-number" id="RowPrice{{ product.ProductId }}">
											{{ product.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<form [formGroup]="form" (keyup)="HearaProduct(product)" (keyup.enter)="CloseKeybord(i)">
												<input type="text" class="remark-input" placeholder="{{ product.Heara || itemRemarkPlaceholder }}" formControlName="HearaForProduct" maxlength="99" />
											</form>
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<button class="order-product__item-present-block-button" (click)="addComment = false">סגור</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>

				<cdk-virtual-scroll-viewport
					[itemSize]="inlineItemSize"
					class="example-viewport"
					*ngIf="productType === viewTypes.Inline && windowWidth >= 768"
					[style]="filterSortBlock ? 'top:301px' : ''"
				>
					<div class="order-product order-product_inline">
						<div
							class="order-product__item inline-over768"
							id="{{ 'product-present-' + i }}"
							*cdkVirtualFor="let product of AllProducts; let i = index"
							style="touch-action: manipulation"
							[ngClass]="{ 'main-background secondary-color': isAlternativeColors, 'inline-over768_opened': product.present?.showContent, 'with-remark': addComment }"
							id="Product{{ product.ProductId }}"
						>
							<div
								class="order-product__item-present"
								[ngClass]="{ active: product.TeudaBonusQuantity }"
								*ngIf="product.present?.icon && !isB2BAgent && !isRallyanceReclamation"
								style="font-size: larger"
							>
								+<span id="Bonus{{ product.ProductId }}">{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} </span>
							</div>

							<img
								src="../../../assets/images/ribbon.svg"
								*ngIf="product.inSpecial && !useSpecialsIcons"
								(click)="openSpecialsPopupFiltered(product.inSpecial)"
								style="width: 174px; position: absolute; top: 0; left: 50%; transform: translateX(-50%)"
							/>

							<div class="mivza-icon">
								<img class="mivza-icon-item" *ngIf="product.mobmivza === '1'" (click)="openSpecialsPopupFiltered(product.inSpecial)" src="../../../assets/images/mobmivza.png" />
								<img class="mivza-icon-item" *ngIf="product.wizmivza === '1'" src="../../../assets/images/wizmivza.png" />
							</div>

							<div
								class="order-product__item-corner"
								[attr.id]="product.TeudaPackQuantity > 0 || product.TeudaQuantity > 0 || product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + product.ProductId"
								appGetBorderColor
								[TeudaPackQuantity]="product.TeudaPackQuantity || 0"
								[TeudaQuantity]="product.TeudaQuantity || 0"
								[TeudaBonusQuantity]="product.TeudaBonusQuantity || 0"
								[NumOfTimes]="product.NumOfTimes || 0"
								[IC]="product.IC || ''"
							></div>
							<button mat-icon-button class="expand-icon" (click)="openDialog(product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
								<mat-icon>unfold_more</mat-icon>
							</button>

							<ng-container *ngIf="product.RE && SeeBarcode !== '2' && _ss.CustomerInformation.DisplayRemark !== '1'">
								<div
									class="order-product__remarks"
									*ngIf="_ss.CustomerInformation.CustNotes1 !== '1'; else custNotes1Block"
									#tooltip="matTooltip"
									[matTooltip]="product.RE"
									(click)="tooltip.toggle()"
									dir="rtl"
								>
									{{ product.RE }}
								</div>
								<ng-template #custNotes1Block>
									<div style="width: 99%; margin: auto; text-align: center">
										{{ product.RE }}
									</div>
								</ng-template>
							</ng-container>
							<h2 class="order-product__item-title" [ngStyle]="{ margin: hasSpecials ? '28px 0 0 0' : null }">
								<!-- Product_Name -->
								{{
									(product | addItemExtraToName : ProductName_AddItemExtra)
										.toString()
										.slice(0, (product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') || product.ItemPack ? maxProductName : 90)
								}}
								<div
									class="order-product__item-title-packaging"
									(click)="GetItemPackOptions(product)"
									*ngIf="
										(product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1') ||
										(product.ItemPack && _ss.CustomerInformation.HideQuantityInPack !== '1') ||
										product.IsMainProduct === 1
									"
									[ngSwitch]="product.IsMainProduct === 1"
								>
									<ng-container *ngSwitchCase="true">{{ concentratedProducts[product.ProductId]?.length || 0 }}</ng-container>
									<ng-container *ngSwitchCase="false">
										{{
											product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1'
												? product.Unit
												: (product | isB2BSpecialProduct) === 'inKilograms' && shouldSeeBadatzLogo
												? product.ItemPack + ' קילו'
												: 'באריזה ' + product.ItemPack
										}}
									</ng-container>
								</div>
							</h2>

							<div class="order-product__item-block" *ngIf="product.IsMainProduct !== 1">
								<div class="order-product__item-info" *ngIf="!showLastDatePriceAndQuantityInsteadOfBarcodes || product.NumOfTimes">
									<div
										class="order-product__item-info-wrapper"
										*ngIf="_ss.CustNum !== '10391' && _ss.CustNum !== '10263' && SeeBarcode !== '5' && !isB2BLeasing && SeeBarcode !== '7' && !showLastDatePriceAndQuantityInsteadOfBarcodes"
									>
										<div class="order-product__item-info-title" style="font-size: 18px; font-weight: 550; white-space: nowrap" [style.color]="SeeBarcode === '2' ? 'blue' : null">
											{{ SeeBarcode === '2' ? product.RE.split(':')[0] + ': ' : SeeBarcode !== '6' && !showItemExtra4InsteadOfBarcode ? 'ברקוד:' : '' }}
										</div>
										<div class="order-product__item-info-number" style="font-size: 18px; font-weight: 550" [style.color]="SeeBarcode === '2' ? 'blue' : null">
											{{ showItemExtra4InsteadOfBarcode ? product.ItemExtra4 || '' : SeeBarcode === '2' ? product.RE.split(':')[1] : product.ItemBarcode }}
										</div>
									</div>
									<div
										class="order-product__item-info-wrapper order-product__item-info-wrapper-blue"
										*ngIf="_ss.CustNum === '10391' || _ss.CustNum === '10263' || (showLastDatePriceAndQuantityInsteadOfBarcodes && product.NumOfTimes)"
									>
										<div class="order-product__item-info-title" style="font-size: 18px; font-weight: 550">ת' אחרון:</div>
										<div class="order-product__item-info-number" style="font-size: 18px; font-weight: 550">
											{{ product.DocCreateDateDisplay }}
										</div>
									</div>

									<div class="order-product__item-info-wrapper" *ngIf="SeeProductId !== '3' && !showLastDatePriceAndQuantityInsteadOfBarcodes">
										<div class="order-product__item-info-title" style="font-size: 18px; font-weight: 550">קוד:</div>
										<div class="order-product__item-info-number" style="font-size: 18px; font-weight: 550">
											{{ product.ProductId }}
										</div>
									</div>
									<div class="order-product__item-info-wrapper order-product__item-info-wrapper-blue" *ngIf="showLastDatePriceAndQuantityInsteadOfBarcodes && product.NumOfTimes">
										<div class="order-product__item-info-title" style="font-size: 18px; font-weight: 550">כ' אחרונה:</div>
										<div class="order-product__item-info-number" style="font-size: 18px; font-weight: 550">
											{{ product.docquantity }}
										</div>
									</div>
								</div>
								<div class="order-product__item-info-count" style="width: 60px" *ngIf="shouldSeeBadatzLogo">
									<img [src]="(product | isB2BSpecialProduct) === 'hatam' ? hatamLogo : badatzLogo" class="b2bei-img" />
								</div>
								<ng-container *ngIf="canSeeStock">
									<div
										class="order-product__item-info-count order-product__item-info-count_center bigger-font"
										(click)="openSelectMlay(i)"
										*ngIf="product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[i] : Admin"
									>
										<div class="order-product__item-count">
											<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
										</div>
									</div>
									<div
										class="order-product__item-info-count order-product__item-info-count_center bigger-font"
										(click)="openSelectMlay(i)"
										*ngIf="product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[i] : Admin"
									>
										<div class="order-product__item-count">
											<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
											<!-- <div class="order-product__item-count-text">מלאי</div> -->
											<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
												{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
									</div>
									<div
										class="order-product__item-info-count order-product__item-info-count_center bigger-font"
										(click)="openMlayPopup(product)"
										*ngIf="product.BS | shouldStockShow : 'productBrowser' : false : Admin"
									>
										<div class="order-product__item-count">
											<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? product.Stock / product.ItemPack : product.Stock">!</div>
											<div class="order-product__item-count-text">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : '' }}</div>
											<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.Stock <= 0 }">
												{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
										<div class="order-product__item-count" *ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88">
											<div class="order-product__item-count-info" appGetBackground [Stock]="product.ItemExtra5">!</div>
											<div class="order-product__item-count-text">מלאי</div>
											<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': product.ItemExtra5 <= 0 }">
												{{ product.ItemExtra5 | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
									</div>
								</ng-container>
								<div class="order-product__item-count date" *ngIf="_ss.CustomerInformation.ProductExtraInfo === '1' && product.ItemExtra5 && !isB2BLeasing">
									<div class="order-product__item-count-text">תאריך הגעה</div>
									<div class="order-product__item-count-number" dir="ltr">{{ product.ItemExtra5 }}</div>
								</div>
								<div class="order-product__item-price" (click)="showPresentContent(i)" *ngIf="_ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices && product.IsMainProduct !== 1">
									<div class="order-product__item-price-title">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}</div>
									<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': product.NetPrice !== product.ShowPrice }">
										{{ product.NetPrice | number : '1.0-2' : 'en-US' }}
									</div>
								</div>
								<div class="order-product__item-price" (click)="showPresentContent(i)" *ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 && !isB2BAgent">
									<div class="order-product__item-price-title">מחיר</div>
									<div class="order-product__item-count-number">{{ product.ItemExtra4 | number : '1.0-2' : 'en-US' }}</div>
								</div>
								<div
									class="order-product__item-price order-product__item-price-red"
									(click)="showPresentContent(i)"
									*ngIf="showLastDatePriceAndQuantityInsteadOfBarcodes && product.NumOfTimes"
								>
									<div class="order-product__item-price-title order-product__item-price-red">מחיר אחרון</div>
									<div class="order-product__item-count-number">{{ product.DocNetPrice }}</div>
								</div>
							</div>
							<div
								class="order-product__item-block order-product__item-block_center"
								[ngClass]="{ 'justify-evenly': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88, height100: product.IsMainProduct === 1 }"
							>
								<div class="order-product__item-info-count order-product__item-info-count_big" *ngIf="_ss.SeeArizot && !hidePacks && !product.hidePacks">
									<h3
										class="order-product__item-info-count-title"
										style="font-size: 16px; font-weight: 550"
										*ngIf="!hasWeightTypeUnitsBehaviors || !product.canToggleBoxesPacks; else productPopupWeightToggleButton"
									>
										{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
										<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
									</h3>
									<ng-template #productPopupWeightToggleButton>
										<h3 class="order-product__item-info-count-title" (click)="toggleProductBetweenBoxesAndPacks(product)">
											<span style="font-size: 16px; font-weight: 550; background: #e8e8e8; padding: 0 5px; border-radius: 5px">
												{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
												<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
											</span>
										</h3>
									</ng-template>
									<div class="order-product__item-info-count-box" style="position: relative; left: 30px">
										<button
											class="order-product__item-info-count-button order-product__item-info-count-button_add"
											(click)="ArizotPlus(product)"
											style="position: relative; left: 20px"
										></button>

										<div
											class="order-product__item-info-count-input"
											(click)="MoveToProductCard(product, 2)"
											*ngIf="product.qt === 0 || product.qt === 2"
											[ngClass]="{ active: product.TeudaPackQuantity }"
											style="font-weight: 700; font-size: 20px"
										>
											{{ product.TeudaPackQuantity }}
										</div>
										<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 3" style="font-weight: 700; font-size: 20px; color: red">
											{{ product.TeudaPackQuantity }}
										</div>
										<button class="order-product__item-info-count-button" (click)="ArizotMinus(product)" style="position: relative; right: 20px"></button>
									</div>
								</div>

								<div
									class="order-product__item-info-count order-product__item-info-count_image"
									[ngStyle]="{ width: product.IsMainProduct !== 1 ? null : '33%' }"
									(click)="MoveToProductCard(product, 0)"
								>
									<div
										class="order-product__item-info-count-image image"
										style="margin: auto; display: flex; justify-content: center"
										[ngStyle]="{
											'height.px': product.IsMainProduct !== 1 ? 100 : null,
											'width.px': product.IsMainProduct !== 1 ? 100 : null,
											'max-width': product.IsMainProduct !== 1 ? null : 'unset'
										}"
									>
										<img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" />
										<img
											[src]="SeeImages === 1 ? logo : CustImageDomain + '/' + (product | getImageNameFrom | encodeURI) + '.jpg'"
											crossorigin
											class="ItemPic"
											appFallbackSrc
											alt="photo"
											style="margin: auto"
											[ngStyle]="{ margin: product.IsMainProduct !== 1 ? 'auto' : 0, 'max-height': product.IsMainProduct !== 1 ? null : '170px' }"
										/>
									</div>
								</div>

								<div class="order-product__item-info-count order-product__item-info-count_big" dir="ltr">
									<ng-container *ngIf="!hideSingles && !product.hideSingles">
										<h3
											class="order-product__item-info-count-title"
											style="font-size: 16px; font-weight: 550"
											*ngIf="!hasWeightTypeUnitsBehaviors || !product.canSwitchWeightSinglesQuantities; else productPopupWeightToggleButton"
										>
											{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
										</h3>
										<ng-template #productPopupWeightToggleButton>
											<h3 class="order-product__item-info-count-title" (click)="toggleProductBetweenWeightAndSingles(product)">
												<span style="font-size: 16px; font-weight: 550; background: #e8e8e8; padding: 0 5px; border-radius: 5px">
													{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
												</span>
											</h3>
										</ng-template>
										<div class="order-product__item-info-count-box" style="position: relative; right: 30px">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												(click)="YehidotPlus(product)"
												style="position: relative; right: 20px"
											></button>

											<div
												class="order-product__item-info-count-input"
												(click)="MoveToProductCard(product, 1)"
												*ngIf="product.qt === 0 || product.qt === 3"
												[ngClass]="{ active: product.TeudaQuantity }"
												style="font-weight: 700; font-size: 20px"
											>
												{{ product.TeudaQuantity }}
											</div>
											<div class="order-product__item-info-count-input" *ngIf="product.qt === 1 || product.qt === 2" style="font-weight: 700; font-size: 20px; color: red">
												{{ product.TeudaQuantity }}
											</div>
											<button class="order-product__item-info-count-button" (click)="YehidotMinus(product)" style="position: relative; left: 20px"></button>
										</div>
									</ng-container>
								</div>
							</div>

							<div class="order-product__item-block order-product__item-block_present" *ngIf="product.present?.showContent && _ss.OneClientInfo.DSP !== 2 && !isB2BLeasing && canSeePrices">
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" (click)="Mehiron(product)">
										<div class="order-product__item-present-block-title">מחירון</div>
										<div class="order-product__item-present-block-price">{{ product.ShowPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" (click)="ChangePriceClickLastPriceFunction(product, 'price')" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מח אחרון</div>
										<div class="order-product__item-present-block-price">{{ product.DocNetPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunction(product, 'discount')"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '0'"
									>
										<div class="order-product__item-present-block-title">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? 'מחיר בדיקה' : 'הנחה אחרונה' }}
										</div>
										<div class="order-product__item-present-block-percent" [ngClass]="{ 'no-percentage': _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' }">
											{{ _ss.CustomerInformation.ProductExtraInfo === '3' && Admin === 'true' ? product.CPP : (product.DocDiscount | number : '1.0-2' : 'en-US') }}
										</div>
									</div>
									<div
										class="order-product__item-present-block"
										(click)="ChangePriceClickLastPriceFunctionMinPrice(product)"
										*ngIf="!isB2BAgent && _ss.CustomerInformation.CustChangeCss === '1'"
									>
										<div class="order-product__item-present-block-title">מחיר מינימום</div>
										<div class="order-product__item-present-block-price">{{ product.MinPrice | number : '1.0-2' : 'en-US' }}</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="Admin === 'true' && _ss.CustomerInformation.ProductExtraInfo === '3'; else remarkElseBlock">
										<div class="order-product__item-present-block-title">רווחיות שורה</div>
										<div class="order-product__item-present-block-price no-price">
											{{ ((product.NetPrice - product.CPP) / product.NetPrice) * 100 | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
									<ng-template #remarkElseBlock>
										<div class="order-product__item-present-block order-product__item-present-block_icon" [ngClass]="{ 'order-product__item-present-block_active': addComment }">
											<div class="order-product__item-present-block-title">הערה</div>
											<div class="order-product__item-present-block-icon image" (click)="addComment = true">
												<img src="/assets/images/order/comment-active-icon.svg" alt="icon" />
											</div>
										</div>
									</ng-template>
								</div>
								<div class="order-product__item-present-row row">
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">מחיר</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangePriceFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.Price | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangePrice"
													id="ChangePrice{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">הנחה</div>
										<div class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="ChangeDiscountFunction(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
													formControlName="ChangeDiscount"
													id="ChangeDiscount{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="
														shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BD === 1 || null
													"
												/>
											</form>
										</div>
									</div>
									<div class="order-product__item-present-block" *ngIf="!isB2BAgent">
										<div class="order-product__item-present-block-title">בונוס</div>
										<div [ngClass]="{ 'bonus-input': product.TeudaBonusQuantity }" class="order-product__item-present-block-input order-product__item-present-block-input_active">
											<form [formGroup]="form" (keyup)="Bonus(product)" (keyup.enter)="CloseKeybord(i)">
												<input
													type="number"
													placeholder="{{ product.TeudaBonusQuantity }}"
													formControlName="Bonus"
													id="Bonusqty{{ product.ProductId }}"
													style="width: 70px; height: 30px; font-size: 15px; margin-left: 2px"
													[attr.disabled]="shouldOverrideAgentProductQuantities ? null : shouldDisableChangingInputs ? true : product.BB === 1 || null"
												/>
											</form>
										</div>
									</div>
									<div class="product-detail__item-price-block" *ngIf="_ss.SeeTotalPrice !== '1'">
										<div class="product-detail__item-price-block-title">סכום שורה</div>
										<div class="product-detail__item-price-block-number" id="RowPrice{{ product.ProductId }}">
											{{ product.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<form [formGroup]="form" (keyup)="HearaProduct(product)" (keyup.enter)="CloseKeybord(i)">
												<input type="text" class="remark-input" placeholder="{{ product.Heara || itemRemarkPlaceholder }}" formControlName="HearaForProduct" maxlength="99" />
											</form>
										</div>
									</div>
								</div>
								<div class="order-product__item-present-row row" *ngIf="addComment">
									<div class="order-product__item-present-block order-product__item-present-block_big">
										<div class="order-product__item-present-block-input order-product__item-present-block-input_big">
											<button class="order-product__item-present-block-button" (click)="addComment = false">סגור</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>
			</div>

			<!-- <div class="order-process__row order-process__row_success" *ngIf="NoConnection === true">
				<div class="row">
					<h2 class="order-process__title" style="color: red">אופס... בעיה בתקשורת</h2>
					<h2 class="order-process__title" style="color: red">אנא צא למסך הלקוחות ונסה שנית.</h2>
				</div>
				<div class="row"></div>
				<div class="row">
					<div class="order-process__image image">
						<img src="/assets/images/basket/process/error.gif" alt="photo" />
					</div>
				</div>
				<div class="row"></div>
			</div> -->
		</section>
		<!-- end products section -->
	</div>

	<!-- main menu markup -->
	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img src="{{ _ss.CustomerInformation?.CustImageDomain }}/companylogo.jpg" crossorigin alt="mobility" crossorigin appFallbackSrc />
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ _ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ _ss.AgentIdConnected }}</div>
				</div>
			</div>

			<div class="main-menu__row" *ngIf="!isB2BAgent && !isCatalogAgent; else b2bBlock">
				<div class="main-menu__col" (click)="MoveToClientCard()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">כרטיס לקוח - {{ _ss.OneClientInfo.Client_Name.toString().slice(0, 25) }}</div>
				</div>
				<div class="main-menu__border"></div>
				<div class="main-menu__col" (click)="BackToClients()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">לקוחות</div>
				</div>
				<div class="main-menu__border" *ngIf="Admin !== 'simple'"></div>
				<div class="main-menu__col" *ngIf="Admin !== 'simple'" (click)="MoveToDohot()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-statistic.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דוחות</div>
				</div>
			</div>

			<ng-template #b2bBlock>
				<div class="main-menu__row" *ngIf="!isCatalogAgent">
					<!-- <div class="main-menu__col" (click)="BackToClients()">
						<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">חזרה</div>
				</div> -->
					<div class="main-menu__col" *ngIf="Admin !== 'b2bL' && _ss.CustomerInformation.B2BOptions !== '3' && !isB2BAgentNoPricesAndNoParameters" (click)="MoveToHovot()">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-person.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">חובות</div>
					</div>
				</div>
				<!-- <div class="main-menu__border"></div> -->
			</ng-template>

			<ng-container *ngIf="!isCatalogAgent">
				<div class="main-menu__border"></div>
				<div class="main-menu__row">
					<div class="main-menu__col" (click)="MoveToDailyReport()">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-calendar.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">דו"ח יומי</div>
					</div>
				</div>

				<div class="main-menu__border"></div>
				<div class="main-menu__row">
					<div class="main-menu__col" (click)="RefreshMlay()">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-settings.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">עדכן מלאי</div>
					</div>
				</div>
			</ng-container>

			<ng-container>
				<div class="main-menu__border"></div>
				<div class="main-menu__row">
					<div class="main-menu__col" (click)="toggleSearchOptionsPopup('open')">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-settings.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">העדפות חיפוש</div>
					</div>
				</div>
			</ng-container>

			<!-- <div class="main-menu__border"></div>
      <div class="main-menu__col" *ngIf="_ss.SearchStyle === 1" (click)="SearchStyleChange()">
        <div class="main-menu__col-icon image">
          <img src="/assets/images/burger/icon-settings.svg" alt="icon">
        </div>
        <div class="main-menu__col-title"> הגדרת חיפוש רגיל</div>
      </div>

      <div class="main-menu__col" *ngIf="_ss.SearchStyle === 0" (click)="SearchStyleChange()">
        <div class="main-menu__col-icon image">
          <img src="/assets/images/burger/icon-settings.svg" alt="icon">
        </div>
        <div class="main-menu__col-title"> הגדרת חיפוש חכם</div>
      </div> -->

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="BackToHome()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דף הבית</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__col">
				<div class="main-menu__col-title">{{ _ss.VersionNumber + '-' + (isB2BAgent ? Admin + '-' : '') + _ss.CustomerInformation.CustName }}</div>
			</div>
		</div>
	</div>
	<!-- end main menu markup -->
</div>

<!-- markup for sort popup sanen-->
<div class="popup" *ngIf="showSortPopupSanen === true">
	<div class="overlay"></div>
	<div class="order-popup order-popup_sort popup__content">
		<div class="popup__close" (click)="CloseSanen()"></div>
		<div class="order-popup__header">
			<div class="order-popup__header-search">
				<form [formGroup]="form" (keyup)="SearchItemExtra()" class="sanen-search-input">
					<img
						src="/assets/images/header/clear-icon.svg"
						alt="search"
						class="sanen-search-clear-button"
						*ngIf="ItemRxtraSanenArray?.length || form.controls.ExtraSearch.value?.length"
						(click)="clearSanen()"
					/>
					<input class="header__search-input" type="text" placeholder="חפש" formControlName="ExtraSearch" style="text-align: center" dir="rtl" />
				</form>
			</div>
			<span class="filter-sort__row-name">סנן</span>
			<!-- <div class="order-popup__header-description">נקה הכל</div> -->
		</div>
		<!-- <div class="order-popup__content" *ngIf="_ss.CustomerInformation.ItemExtrasFilter !== '1';else itemExtrasBlock"> -->
		<div class="order-popup__content">
			<div class="order-popup__row" *ngFor="let ix of ItemExtraOne" style="margin-top: 25px">
				<div class="order-popup__row-first">
					<div class="order-popup__content-title">{{ ix }}</div>
					<div class="sanen-search-checkbox" (click)="SanenChecked(ix)" id="{{ ix }}"></div>

					<!-- <input type="checkbox" (change)="SanenChecked(ix.ItemExtra1)" style="text-align: left;"> -->
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>
		</div>
		<!-- <ng-template #itemExtrasBlock>
				<div class="order-popup__content" *ngIf="!itemExtraChosen">
					<div class="order-popup__row" *ngIf="_ss.itemExtraObject.itemExtra1" (click)="itemExtraChosen = 1">
						<div class="order-popup__row-first" [ngClass]="{active:itemExtraObject.itemExtra1.length}">itemExtra1</div>
					</div>
					<div class="order-popup__row" *ngIf="_ss.itemExtraObject.itemExtra2" (click)="itemExtraChosen = 2">
						<div class="order-popup__row-first" [ngClass]="{active:itemExtraObject.itemExtra2.length}">itemExtra2</div>
					</div>
					<div class="order-popup__row" *ngIf="_ss.itemExtraObject.itemExtra3" (click)="itemExtraChosen = 3">
						<div class="order-popup__row-first" [ngClass]="{active:itemExtraObject.itemExtra3.length}">itemExtra3</div>
					</div>
					<div class="order-popup__row" *ngIf="_ss.itemExtraObject.itemExtra4" (click)="itemExtraChosen = 4">
						<div class="order-popup__row-first" [ngClass]="{active:itemExtraObject.itemExtra4.length}">itemExtra4</div>
					</div>
					<div class="order-popup__row" *ngIf="_ss.itemExtraObject.itemExtra5" (click)="itemExtraChosen = 5">
						<div class="order-popup__row-first" [ngClass]="{active:itemExtraObject.itemExtra5.length}">itemExtra5</div>
					</div>
				</div>

			</ng-template> -->
	</div>
</div>
<!-- end markup for sort popup sanen-->

<div class="popup" *ngIf="isSearchOptionsPopupOpen$ | async" (click)="closePopupOnlyIfTargetIsOutside($event)">
	<div class="overlay"></div>
	<app-search-preference (closeEvent)="toggleSearchOptionsPopup('close')" (preferenceSelectChanged)="acknowledgeSearchPreferenceChanges($event)"></app-search-preference>
</div>

<!-- markup for departments popup -->
<div class="popup" *ngIf="showDepartmentsPopup && HasMainGroup === '1'">
	<div class="overlay"></div>
	<div class="order-popup popup__content">
		<div class="popup__close" (click)="showHideDepartmentsPopup(false)"></div>
		<div class="order-popup__header">
			<div class="order-popup__header-search">
				<form [formGroup]="form" (keyup)="SearchSection()">
					<input type="text" class="order-popup__header-input" placeholder="חפש שם או קוד מחלקה" formControlName="SectionSearch" />
				</form>
			</div>

			<div class="order-popup__header-title" (click)="ResetGroups()">
				<div class="main-menu__col-icon image">
					<img src="/assets/images/arrow-back.svg" alt="icon" />
				</div>
			</div>
		</div>
		<div class="order-popup__content" *ngIf="hasMGroups; else noMGroupsNoDepartments" style="padding-bottom: 100px">
			<div class="order-popup__row" (click)="ShowChosenMahlaka('ALL_DEPARTMENTS', 'ALL_DEPARTMENTS')" (click)="showHideDepartmentsPopup(false)">
				<div class="order-popup__content-image image">
					<img src="{{ CustImageDomain }}/mahlaka/logo.jpg" alt="photo" crossorigin appFallbackSrc />
				</div>
				<div class="order-popup__content-title">כל הפריטים</div>
				<!-- <div class="order-popup__content-number" *ngIf="_ss.CustNum !== '10326'">{{ gn.GroupId }}</div> -->
			</div>
			<ng-container *ngIf="!showDepartmentsList">
				<div class="order-popup__row" *ngFor="let main of MGroupsMainDisplay$ | async" (click)="ShowChosenMahlaka(main.mg, main.mgn, true)" style="justify-content: space-between">
					<div class="right flex justify-align-center">
						<div class="order-popup__content-image image">
							<!-- <img src="{{ CustImageDomain }}/mahlaka/{{ main.GroupId | encodeURI }}.jpg" alt="photo" crossorigin appFallbackSrc /> -->
						</div>
						<div class="order-popup__content-title">{{ main.mgn }}</div>
						<div class="order-popup__content-number" *ngIf="_ss.CustNum !== '10326'">{{ main.mg }}</div>
					</div>
					<div class="left">
						<img *ngIf="main.visitedThisSession && main.inOrder" src="/assets/images/check-green.svg" />
						<img *ngIf="main.visitedThisSession && !main.inOrder" src="/assets/images/popup-close-red.svg" />
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="showDepartmentsList">
				<div class="order-popup__row order-popup__row_list" *ngFor="let gn of Groups" (click)="ShowChosenMahlaka(gn.gr, gn.gn, true)" (click)="showHideDepartmentsPopup(false)">
					<div class="order-popup__content-title">{{ gn.gn }}</div>
					<div class="order-popup__content-number">{{ gn.gr }}</div>
				</div>
			</ng-container>
		</div>
		<ng-template #noMGroupsNoDepartments>
			<div class="order-popup__content" #departmentsWrapperScroller1 *ngIf="!showDepartmentsList" style="padding-bottom: 100px">
				<div class="order-popup__row" *ngFor="let gn of Groups" (click)="ShowChosenMahlaka(gn.GroupId, gn.GroupName)" (click)="showHideDepartmentsPopup(false)">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/mahlaka/{{ gn.GroupId | encodeURI }}.jpg" alt="photo" crossorigin appFallbackSrc />
					</div>
					<div class="order-popup__content-title">{{ gn.GroupName }}</div>
					<div class="order-popup__content-number" *ngIf="_ss.CustNum !== '10326'">{{ gn.GroupId }}</div>
				</div>
			</div>
			<div class="order-popup__content" #departmentsWrapperScroller2 *ngIf="showDepartmentsList">
				<div class="order-popup__row order-popup__row_arrow order-popup__row_main">
					<div class="order-popup__content-title">חזרה</div>
					<div class="order-popup__content-number"></div>
					<div class="order-popup__content-arrow-back" (click)="showDepartmentsList = false"></div>
				</div>

				<div class="order-popup__row order-popup__row_list" *ngFor="let gn of Groups" (click)="ShowChosenMahlaka(gn.GroupId, gn.GroupName)" (click)="showHideDepartmentsPopup(false)">
					<div class="order-popup__content-title">{{ gn.GroupName }}</div>
					<div class="order-popup__content-number">{{ gn.GroupId }}</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>

<!-- end markup for departments popup -->

<!-- markup for departments popup -->
<div class="popup" *ngIf="showDepartmentsPopup && HasMainGroup !== '1'">
	<div class="overlay"></div>
	<div class="order-popup popup__content">
		<div class="popup__close" (click)="showHideDepartmentsPopup(false)"></div>
		<div class="order-popup__header">
			<div class="order-popup__header-search">
				<form [formGroup]="form" (keyup)="SearchSection()" style="position: relative">
					<button class="header__clear-icon" style="right: 8px" (click)="SectionSearchClear()"></button>
					<input type="text" class="order-popup__header-input" [placeholder]="form.controls.SectionSearch.value || 'חפש שם או קוד'" formControlName="SectionSearch" />
				</form>
			</div>

			<div class="order-popup__header-title">מחלקות</div>
		</div>
		<div class="row flex flex-column" style="text-align: right; direction: rtl">
			<b>מיון: </b>
			<mat-radio-group color="primary" dir="rtl" (change)="changeDepartmentSort($event)" [formControl]="groupSort" style="zoom: 1">
				<mat-radio-button #main value="1">קוד</mat-radio-button>
				<mat-radio-button value="2">א'-ב'</mat-radio-button>
			</mat-radio-group>
		</div>

		<div class="order-popup__content" *ngIf="hasMGroups; else noMGroupsDepartments" style="padding-bottom: 100px">
			<div class="order-popup__row" *ngFor="let main of MGroupsMainDisplay$ | async" (click)="showHideDepartmentsPopup(false)" style="justify-content: space-between">
				<div class="flex justify-align-center">
					<div class="order-popup__content-image image">
						<!-- <img src="{{ CustImageDomain }}/mahlaka/{{ main.GroupId | encodeURI }}.jpg" alt="photo" crossorigin appFallbackSrc /> -->
					</div>
					<div class="order-popup__content-title">{{ gn.GroupName }}</div>
					<div class="order-popup__content-number" *ngIf="_ss.CustNum !== '10326'">{{ gn.GroupId }}</div>
				</div>
				<div class="order-popup__row__left">
					<img *ngIf="main.visitedThisSession && main.inOrder" src="/assets/images/check-green.svg" />
					<img *ngIf="main.visitedThisSession && !main.inOrder" src="/assets/images/popup-close-red.svg" />
				</div>
			</div>
		</div>
		<ng-template #noMGroupsDepartments>
			<div class="order-popup__content" #departmentsWrapperScroller3 *ngIf="!showDepartmentsList" style="padding-bottom: 100px">
				<div class="order-popup__row" *ngFor="let gn of Groups" (click)="ShowChosenMahlaka(gn.GroupId, gn.GroupName); showHideDepartmentsPopup(false)" style="justify-content: space-between">
					<div class="right flex justify-align-center">
						<div class="order-popup__content-image image">
							<img src="{{ CustImageDomain }}/mahlaka/{{ gn.GroupId | encodeURI }}.jpg" alt="photo" crossorigin appFallbackSrc />
						</div>
						<div class="order-popup__content-title">{{ gn.GroupName }}</div>
						<div class="order-popup__content-number" *ngIf="_ss.CustNum !== '10326'">{{ gn.GroupId }}</div>
					</div>
					<div class="order-popup__row__left">
						<img width="19px" *ngIf="gn.visitedThisSession" [src]="gn.inOrder ? '/assets/images/check-green.svg' : '/assets/images/popup-close-red.svg'" />
					</div>
				</div>
			</div>
			<div class="order-popup__content" #departmentsWrapperScroller4 *ngIf="showDepartmentsList">
				<div class="order-popup__row order-popup__row_arrow order-popup__row_main">
					<div class="order-popup__content-title">חזרה</div>
					<div class="order-popup__content-number"></div>
					<div class="order-popup__content-arrow-back" (click)="showDepartmentsList = false"></div>
				</div>
				<div class="order-popup__row order-popup__row_list" *ngFor="let gn of Groups" (click)="ShowChosenMahlaka(gn.GroupId, gn.GroupName)" (click)="showHideDepartmentsPopup(false)">
					<div class="order-popup__content-title">{{ gn.GroupName }}</div>
					<div class="order-popup__content-number">{{ gn.GroupId }}</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
<!-- end markup for departments popup -->

<!-- markup for sort popup -->
<div class="popup" *ngIf="showSortPopup">
	<div class="overlay" (click)="showHideSortPopup(false, 'remember')"></div>
	<div class="order-popup order-popup_sort popup__content" (click)="preventPropagation($event)">
		<div class="popup__close" (click)="showHideSortPopup(false, 'remember')"></div>
		<div class="order-popup__header">
			<!-- <div class="order-popup__header-search"></div> -->
			<div class="order-popup__header-title">בחר</div>
		</div>
		<div class="order-popup__content">
			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="selectedGroup = { GroupId: '', GroupName: '' }; KolHamozarim()">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">כל המוצרים - {{ AllProductsSearch?.length || 0 }}</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>
			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="selectedGroup = { GroupId: '', GroupName: '' }; KolHamozarim({ sortAlphabetically: true })">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">כל המוצרים - א'-ב'</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>
			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="Shchichim(0)">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">פופולרי</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>
			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')" *ngIf="canSeeDates">
				<div class="order-popup__row-first" (click)="Shchichim(1)">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">נקנו לאחרונה - תאריך</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>
			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="Shchichim(2)">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">נקנו לאחרונה - א'-ב'</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>
			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="Shchichim(3)">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">נקנו לאחרונה - מק"ט</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>
			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="InOrderProducts()">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">מוצרים בסל - {{ AllOrder?.length || 0 }}</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="InMivzaProducts()">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">מוצרים במבצע</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="handleLastBoughtOnFilter(30)">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">לא נקנו במשך 30 יום</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<div class="order-popup__row" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="handleLastBoughtOnFilter(60)">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">לא נקנו במשך 60 יום</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<div class="order-popup__row" *ngIf="hasSpecials" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="isSpecialsPopupOpen = true">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">מבצעים</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<!-- <div class="order-popup__row" *ngIf="_ss.CustomerInformation.HasSpecials === '1'" (click)="showHideSortPopup(false, 'forget')">
				<div class="order-popup__row-first" (click)="showSpecialsInCart()">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">מבצעים בסל</div>

					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div> -->

			<div class="order-popup__row" (click)="showHideSortPopup(false, 'remember')">
				<div class="order-popup__row-first" (click)="AreYouSureDeletePop = true">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">מחק תעודה</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<div class="order-popup__row" (click)="MlayMinusPlus()">
				<div class="order-popup__row-first">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo" appFallbackSrc />
					</div>
					<div class="order-popup__content-title">מלאי</div>
					<div class="filter-sort__row-img image">
						<img src="/assets/images/filters/arrows.svg" alt="photo" />
					</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<div class="order-popup__row" *ngIf="_ss.CustomerInformation.HeaderDisplay === '1'">
				<div class="order-popup__row-first">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" alt="photo" crossorigin appFallbackSrc />
					</div>
					<div class="order-popup__content-title">חפש לפי:</div>
					<div class="filter-sort__row-img image">
						<!-- <img src="/assets/images/filters/arrows.svg" alt="photo" /> -->

						<form [formGroup]="form" (change)="SearchProducts()">
							<select formControlName="SearchOptions" style="height: 20px; background: white">
								<option value="" selected>הכל</option>
								<option value="Name">שם</option>
								<option value="Code">קוד</option>
								<option value="Barcode">ברקוד</option>
							</select>
						</form>
					</div>
					<!-- <div class="order-popup__content-number"></div> -->
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div>

			<!-- <div class="order-popup__row" (click)="showHideSortPopup(false, 'remember')">
				<div class="order-popup__row-first" (click)="CalculaterPopOpen()">
					<div class="order-popup__content-image image">
						<img src="{{ CustImageDomain }}/companylogo.jpg" crossorigin alt="photo"
							appFallbackSrc />
					</div>
					<div class="order-popup__content-title">מחשבון</div>
					<div class="order-popup__content-number"></div>
					<div class="order-popup__content-arrow order-popup__content-arrow-hide"></div>
				</div>
			</div> -->
		</div>
	</div>
</div>
<!-- end markup for sort popup -->

<!-- markup for logout popup -->
<!-- <div class="popup" *ngIf="showLogoutPopup">
	<div class="overlay"></div>
	<div class="popup__content popup__content_logout">
		<div class="popup__close" (click)="showHideLogoutPopup(false)"></div>
		<div class="popup__content-image image">
			<img src="/assets/images/logout.gif" alt="photo" />
		</div>
		<h2 class="popup__content-title">ברצונך להתנתק?</h2>
		<div class="popup__content-description">כל התעודות הפתוחות יימחקו</div>
		<div class="popup__content-block popup__content-block_center">
			<button class="popup__content-button" (click)="showHideLogoutPopup(false)">כן, התנתק</button>
			<button class="popup__content-button popup__content-button_red" (click)="showHideLogoutPopup(false)">לא, חזור</button>
		</div>
	</div>
</div> -->
<!-- end markup for logout popup -->

<!-- markup for popup -->
<div class="popup" *ngIf="StockAlert === true" [ngStyle]="{ 'z-index': (isConcentratedProductPopupShowing$ | async) === true ? 9999 : null }">
	<div class="overlay"></div>
	<div class="order-cart__popup popup__content">
		<div class="popup__close" (click)="CloseStockAlert()"></div>
		<div class="order-cart__popup-image image">
			<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
		</div>
		<h2 class="order-cart__popup-title">חסר במלאי!</h2>

		<br />
		<div class="order-cart__popup-block row" style="justify-content: center">
			<button class="order-cart__popup-button invalid" (click)="CloseStockAlert()">קיבלתי</button>
		</div>
	</div>
</div>
<!-- end markup for popup -->

<!-- markup for popup -->
<div class="popup" *ngIf="AreYouSureDeletePop">
	<div class="overlay"></div>
	<div class="order-cart__popup popup__content">
		<div class="popup__close" (click)="AreYouSureDeletePop = false"></div>
		<div class="order-cart__popup-image image">
			<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
		</div>
		<h2 class="order-cart__popup-title">האם אתה בטוח שברצונך למחוק את התעודה?</h2>

		<br />
		<div class="order-cart__popup-block row">
			<button class="order-cart__popup-button" (click)="AreYouSureDeletePop = false" style="background-color: red">ביטול</button>

			<button class="order-cart__popup-button" (click)="DeleteTeudaProducts()">אישור</button>
		</div>
	</div>
</div>
<!-- end markup for popup -->
<!-- markup for popup -->
<div class="popup" *ngIf="AddedSuccess === true" style="z-index: 9999">
	<div class="overlay"></div>
	<div class="order-cart__popup popup__content">
		<div class="popup__close" (click)="CloseAddedSuccess()"></div>
		<div class="order-cart__popup-image image">
			<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
		</div>
		<h2 class="order-cart__popup-title">מוצר התווסף בהצלחה</h2>

		<br />
		<div class="order-cart__popup-block row">
			<button class="order-cart__popup-button" (click)="CloseAddedSuccess()">קיבלתי</button>
		</div>
	</div>
</div>
<!-- end markup for popup -->

<div class="popup" *ngIf="isSpecialsPopupOpen" style="z-index: 555">
	<div class="overlay"></div>
	<div class="product-detail__title">מבצעים</div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="handleCloseSpecialsPopup()" style="width: 30px; height: 30px; background-color: black"></div>

		<div class="product-detail__wrapper2 custom-scrollbar">
			<table class="report-table__table" cellspacing="0" *ngIf="allSpecials?.length; else noSpecialsBlock" style="display: table">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 200px">שם</th>
						<th class="report-table__table-head-name" style="width: 100px">הוסף לסל</th>
					</tr>
				</thead>
				<ng-container *ngIf="concentratedSpecialsCartWithIntermediateQuantities$ | async as concentratedSpecialsCartWithIntermediateQuantities">
					<tbody class="report-table__table-body">
						<tr *ngFor="let ata of allSpecials$ | async; index as i" class="relative">
							<td class="report-table__table-body-name clickable" style="white-space: break-spaces" (click)="handleOpenSpecialDialog(ata.SpecialID)">
								{{ ata.Name }}
								<span *ngIf="concentratedSpecialsCartWithIntermediateQuantities[ata.SpecialID]?.TeudaItemNetPrice">
									({{ concentratedSpecialsCartWithIntermediateQuantities[ata.SpecialID].TeudaItemNetPrice | number : '1.0-2' : 'en-US' }})
								</span>
								<button
									mat-raised-button
									color="primary"
									style="position: absolute; right: -33%; top: 0"
									(click)="handleOpenConcentratedSpecialsPopup($event, concentratedSpecialsCartWithIntermediateQuantities[ata.SpecialID])"
									*ngIf="concentratedSpecialsCartWithIntermediateQuantities[ata.SpecialID]"
								>
									בחר
								</button>
							</td>
							<td class="report-table__table-body-name">
								<div class="order-product__item-info-count-box" style="justify-content: center">
									<button class="order-product__item-info-count-button order-product__item-info-count-button_add" (click)="handleChangeSpecialInCart(ata, 'increment', 1)"></button>
									<div class="order-product__item-info-count-input" style="font-weight: 700; font-size: 20px">
										{{ concentratedSpecialsCartWithIntermediateQuantities[ata.SpecialID]?.quantity || ata.quantity }}
									</div>
									<button class="order-product__item-info-count-button" (click)="handleChangeSpecialInCart(ata, 'decrement', 1)"></button>
								</div>
							</td>
						</tr>
					</tbody>
				</ng-container>
			</table>
		</div>
		<ng-template #noSpecialsBlock>
			<h1 style="text-align: center">אין מבצעים להציג</h1>
		</ng-template>
	</div>
</div>
<div class="popup" *ngIf="isBarcodePopupOpen" style="z-index: 555">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="handleBarcodePopupClose()" style="width: 30px; height: 30px; background-color: black"></div>

		<div class="product-detail__wrapper2 custom-scrollbar">
			<video #videoElement id="video" width="100%" height="80%"></video>
		</div>
	</div>
</div>

<div class="popup" *ngIf="shouldStockPopupShow" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="popup__close" (click)="shouldStockPopupShow = false" style="margin-top: 50px; background-color: black"></div>
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<div class="product-detail-add__popup-row">
					<h2 class="product-detail-add__popup-title">{{ mlayPopupProductName }}</h2>
				</div>

				<table style="text-align: center; width: 99%; margin: auto">
					<tr>
						<th>מחסן</th>
						<th>מלאי</th>
						<!-- <th>זמין</th>
						<th>אצווה</th> -->
					</tr>
					<tr *ngFor="let stock of stockArrayToShow">
						<td>{{ stock.StockWareHouse }}</td>
						<td>{{ stock.Stock | number : '1.0-2' : 'en-US' }}</td>
						<!-- <td>{{ stock.StockOpen | number : '1.0-2' : 'en-US' }}</td>
						<td>{{ stock.StockBatch }}</td> -->
					</tr>
				</table>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="shouldStockPopupShow = false">חזור</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="popup" *ngIf="isGroupSumPopupOpen" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="popup__close" (click)="isGroupSumPopupOpen = false" style="margin-top: 50px; background-color: black"></div>
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<div class="product-detail-add__popup-row">
					<h4 class="product-detail-add__popup-title">סיכום {{ _ss.DocName }} לפי מחלקות</h4>
				</div>
				<div class="product-detail-add__popup-row flex-column" style="gap: 10px">
					<div class="product-detail-add__popup-label" style="margin: 0; border-bottom: 1px solid black" *ngFor="let product of groupSumDisplay">
						<h3 class="product-detail-add__popup-label-title">{{ product.GroupSum }}: {{ product.Total | number : '1.0-2' }}</h3>
					</div>
				</div>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="MoveToTeuda()">המשך</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="popup" *ngIf="isConcentratedProductPopupShowing$ | async" style="z-index: 553">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content normal-zoom" style="height: 94vh; width: 95vw; padding: 0">
		<div class="popup__close popup__close_red" (click)="isConcentratedProductPopupShowing$.next(false)"></div>
		<div class="product-detail__wrapper product-detail__wrapper-concentrated" style="max-height: initial; height: 100%">
			<div class="product-detail__item" style="padding: 0; height: 100%">
				<div class="product-detail-add__popup-row justify-center" style="background: var(--main-color); padding: 1rem 3rem; height: 78px">
					<div class="header__search header__search_small">
						<form [formGroup]="concentratedProductsForm" style="height: 100%">
							<input
								class="header__search-input"
								type="text"
								[placeholder]="'מוצרים'"
								formControlName="concentratedProductsSearch"
								style="text-align: center; font-size: 17px; font-weight: 600; height: 100%"
								dir="rtl"
								id="productsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon header__barcode-icon" (click)="handleBarcodePopupOpen()"></button>
						<button class="header__clear-icon" (click)="concentratedProductsForm.reset()"></button>
						<!-- <span class="search-info"> מציג ({{ AllProducts.length }}) מוצרים</span> -->
					</div>
				</div>

				<div
					class="order-filters__row row order-filters__row-popup relative"
					[ngClass]="{ 'with-quantity-text': _ss.SeeArizot || !hideSingles, 'with-concentrated-special': isConcentratedSpecialViewOpen$ | async }"
				>
					<div class="flex justify-between">
						<div class="filter-sort__box gap">
							<form [formGroup]="concentratedProductsForm">
								<mat-form-field color="primary" appearance="fill" dir="rtl" style="text-align: right">
									<mat-label>צבע</mat-label>
									<mat-select multiple formControlName="concentratedProductsItemColor">
										<mat-option *ngFor="let color of this.concentratedProductsFilters.allItemColors" [value]="color">{{ color }}</mat-option>
									</mat-select>
								</mat-form-field>
							</form>
							<form [formGroup]="concentratedProductsForm">
								<mat-form-field appearance="fill" dir="rtl" style="text-align: right">
									<mat-label>גודל</mat-label>
									<mat-select multiple formControlName="concentratedProductsItemSize">
										<mat-option *ngFor="let size of this.concentratedProductsFilters.allItemSizes" [value]="size">{{ size }}</mat-option>
									</mat-select>
								</mat-form-field>
							</form>
						</div>
						<div
							*ngIf="(isConcentratedSpecialViewOpen$ | async) === false"
							class="filter-sort__row filter-sort__row_view"
							[ngStyle]="{ scale: windowWidth > 768 ? 1.3 : 1.5 }"
							style="padding: 11px 11px 0; min-width: 90px"
						>
							<div
								class="filter-sort__row-view-type filter-sort__row-view-type_medium"
								[ngClass]="{ 'filter-sort__row-view-type_active': (concentratedProductPopupView$ | async) === 'medium' }"
								(click)="concentratedProductPopupView$.next('medium')"
							></div>
							<div
								class="filter-sort__row-view-type filter-sort__row-view-type_inline"
								[ngClass]="{ 'filter-sort__row-view-type_active': (concentratedProductPopupView$ | async) === 'inline' }"
								(click)="concentratedProductPopupView$.next('inline')"
							></div>
						</div>
					</div>
					<div class="flex justify-evenly">
						<ng-container *ngIf="isConcentratedSpecialViewOpen$ | async; else normalConcentratedTotalQuantityBlock">
							<ng-container *ngIf="concentratedSpecialCurrentlyEdittingQuantities$ | async as quantities">
								<div class="text-2xl" [ngClass]="{ 'quantities-ok': quantities.current === quantities.needed }">
									{{ concentratedSpecialTabsText$ | async }} (<span>{{ quantities.current | number : '1.0-2' : 'en-US' }}</span> מתוך
									<span>{{ quantities.needed | number : '1.0-2' : 'en-US' }}</span
									>)
								</div>
								<button class="concentrated-special-button-submit text-xl" [disabled]="!quantities.canFinish" (click)="handleFinishSpecialConcentrated()">סיים</button>
							</ng-container>
						</ng-container>

						<ng-template #normalConcentratedTotalQuantityBlock>
							<div>
								<span>כמות סה"כ: </span> <span>({{ concentratedPoductsTotalQuantity$ | async }})</span>
							</div>
						</ng-template>
						<ng-container *ngIf="(isConcentratedSpecialViewOpen$ | async) === false">
							<div
								class="order-product__item-block order-product__item-block_center gap"
								style="transform: none; min-width: 13vw"
								*ngIf="concentratedProductsShown$ | async as products"
								[ngClass]="{ 'justify-evenly': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 }"
							>
								<div class="order-product__item-info-count order-product__item-info-count_big" style="padding: 0; width: 100%" *ngIf="_ss.SeeArizot">
									<h3 class="order-product__item-info-count-title" style="font-size: 16px; font-weight: 550" *ngIf="!hideSingles">
										{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
									</h3>
									<div class="order-product__item-info-count-box" style="position: relative">
										<button
											class="order-product__item-info-count-button order-product__item-info-count-button_add"
											(click)="incrementOrDecrementAllPopupProducts('increment', 'packs', products)"
											style="position: relative"
										></button>
										<form [formGroup]="concentratedProductsForm" class="flex" (input)="stopKeyupEventIfKey($event)">
											<input
												class="order-product__item-info-count-box"
												type="text"
												inputmode="numeric"
												min="0"
												[placeholder]="(concentratedPoductsTotalQuantityPlaceholder$ | async).packs"
												formControlName="concentratedPacks"
												#concentratedPacks
												autocomplete="off"
												(keyup.enter)="inputQuantityAllPopupProducts($event, 'packs', products)"
												style="font-size: 15px; font-weight: bold; border: #38d88b solid 1px; width: 50px; text-align: center"
											/>
										</form>
										<button class="order-product__item-info-count-button" (click)="incrementOrDecrementAllPopupProducts('decrement', 'packs', products)" style="position: relative"></button>
									</div>
								</div>
								<div class="order-product__item-info-count order-product__item-info-count_big" *ngIf="!hideSingles" style="padding: 0; width: 100%" dir="ltr">
									<h3 class="order-product__item-info-count-title" *ngIf="_ss.SeeArizot" style="font-size: 16px; font-weight: 550">
										{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
									</h3>
									<div class="order-product__item-info-count-box justify-between" style="position: relative">
										<button
											class="order-product__item-info-count-button order-product__item-info-count-button_add"
											style="position: relative"
											(click)="incrementOrDecrementAllPopupProducts('increment', 'singles', products)"
										></button>
										<form [formGroup]="concentratedProductsForm" class="flex" (input)="stopKeyupEventIfKey($event)">
											<input
												class="order-product__item-info-count-box"
												type="text"
												inputmode="numeric"
												autocomplete="off"
												min="0"
												[placeholder]="(concentratedPoductsTotalQuantityPlaceholder$ | async).singles"
												formControlName="concentratedSingles"
												#concentratedSingles
												(keyup.enter)="inputQuantityAllPopupProducts($event, 'singles', products)"
												style="font-size: 15px; font-weight: bold; border: #38d88b solid 1px; width: 50px; text-align: center"
											/>
										</form>
										<button
											class="order-product__item-info-count-button"
											style="position: relative"
											(click)="incrementOrDecrementAllPopupProducts('decrement', 'singles', products)"
										></button>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<ng-container *ngIf="isConcentratedSpecialViewOpen$ | async; else nonSpecialConcentratedCDKVirtualScrollBlock">
					<ng-container [ngSwitch]="concentratedProductPopupView$ | async">
						<div class="flex row" style="height: 40px">
							<mat-tab-group
								class="row text-2xl bold"
								animationDuration="0ms"
								[selectedIndex]="concentratedSpecialTabsSelectedIndex$ | async"
								(selectedTabChange)="tabChange($event)"
								*ngIf="concentratedSpecialCurrentlyEdittingQuantities$ | async as quantities"
							>
								<mat-tab labelClass="flex-1 text-2xl bold" #matTabElement0 [label]="'קנה ' + (quantities.mustPurchaseQuantitiesLeft !== 0 ? '🅾' : '✅')"></mat-tab>
								<mat-tab
									labelClass="flex-1 text-2xl bold"
									#matTabElement1
									[disabled]="!!quantities.mustPurchaseQuantitiesLeft"
									[label]="'קבל ' + (quantities.receiveBonusQuantitiesLeft !== 0 ? (matTabElement1.isActive ? '🅾' : '🟦') : '✅')"
								></mat-tab>
							</mat-tab-group>
						</div>
						<cdk-virtual-scroll-viewport
							*ngSwitchCase="'inline'"
							[itemSize]="inlineConcentratedPopupSize"
							[ngClass]="{ 'with-quantity-text': _ss.SeeArizot || !hideSingles }"
							class="example-viewport example-viewport-popup with-concentrated-special"
						>
							<div class="order-product order-product_inline" style="margin-inline: 10px">
								<div
									class="flex justify-between order-product__item-concentrated"
									id="{{ 'product-present-' + i }}"
									*cdkVirtualFor="let prod of concentratedProductsShown$ | async; let i = index"
									style="touch-action: manipulation; width: 100%; height: 100px"
									[ngClass]="{ 'main-background secondary-color': isAlternativeColors, 'inline-over768_opened': prod.product.present?.showContent, 'with-remark': addComment }"
									id="Product{{ prod.product.ProductId }}"
								>
									<div
										class="order-product__item-corner"
										[attr.id]="prod.product.TeudaPackQuantity > 0 || prod.product.TeudaQuantity > 0 || prod.product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + prod.product.ProductId"
										appGetBorderColor
										[TeudaPackQuantity]="prod.product.TeudaPackQuantity"
										[TeudaQuantity]="prod.product.TeudaQuantity"
										[TeudaBonusQuantity]="prod.product.TeudaBonusQuantity"
										[NumOfTimes]="prod.product.NumOfTimes"
										[IC]="prod.product.IC"
									></div>
									<button mat-icon-button class="expand-icon" style="z-index: 1" (click)="openDialog(prod.product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
										<mat-icon>unfold_more</mat-icon>
									</button>
									<div class="order-product__item-info-count order-product__item-info-count_image" style="margin: 0; padding: 0; flex: 1">
										<!-- <div
										class="order-product__item-info-count order-product__item-info-count_image"
										style="margin: 0; padding: 0; flex: 1"
										(click)="concentratedMoveToProductCard(prod.product, 0)"
									> -->
										<div *ngIf="SeeImages === 0" class="order-product__item-info-count-image image" style="height: 100%">
											<img
												src="{{ CustImageDomain }}/{{ prod.product | getImageNameFrom | encodeURI }}.jpg"
												crossorigin
												class="ItemPic"
												appFallbackSrc
												alt="photo"
												style="height: 100%; object-fit: contain"
											/>
										</div>
										<div *ngIf="SeeImages === 1" class="order-product__item-info-count-image image" style="height: 100px; width: 100px; margin: auto; display: flex; justify-content: center">
											<img [src]="logo" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="height: 100%; object-fit: contain; margin: auto" />
										</div>
									</div>
									<div class="grid" style="flex: 6">
										<h2 class="order-product__item-title" style="overflow: hidden; white-space: nowrap; text-wrap: balance; margin: 0">
											<!-- Product_Name -->
											{{ prod.product | addItemExtraToName : ProductName_AddItemExtra }}
										</h2>
										<div class="flex justify-align-center">
											<ng-container #stockBlock>
												<div
													class="order-product__item-info-count order-product__item-info-count_center bigger-font"
													(click)="openSelectMlay(i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[i] : Admin"
													style="padding: 0; width: 100%"
												>
													<ng-container *ngIf="showConcentratedStock">
														<div class="order-product__item-count justify-align-center">
															<div
																class="order-product__item-count-info"
																appGetBackground
																[Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock"
															>
																!
															</div>
														</div>
													</ng-container>
													<ng-container *ngTemplateOutlet="priceInlineTemplate"></ng-container>
												</div>
												<div
													class="order-product__item-info-count order-product__item-info-count_center bigger-font"
													(click)="openSelectMlay(i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[i] : Admin"
													style="padding: 0; width: 100%"
												>
													<div class="order-product__item-count justify-align-center">
														<ng-container *ngIf="showConcentratedStock">
															<div
																class="order-product__item-count-info"
																appGetBackground
																[Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock"
															>
																!
															</div>
															<!-- <div class="order-product__item-count-text">מלאי</div> -->
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
																{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</ng-container>
													</div>
													<ng-container *ngTemplateOutlet="priceInlineTemplate"></ng-container>
												</div>
												<div
													class="order-product__item-info-count order-product__item-info-count_center bigger-font"
													style="padding: 0; transform: none; width: 100%"
													(click)="openMlayPopup(prod.product)"
													*ngIf="prod.product.BS | shouldStockShow : 'productBrowser' : false : Admin"
												>
													<div class="order-product__item-count justify-align-center">
														<ng-container *ngIf="showConcentratedStock">
															<div
																class="order-product__item-count-info"
																appGetBackground
																[Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock"
															>
																!
															</div>
															<div class="order-product__item-count-text">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : '' }}</div>
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
																{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</ng-container>
														<ng-container *ngTemplateOutlet="priceInlineTemplate"></ng-container>
													</div>
													<div class="order-product__item-count justify-align-center" *ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88">
														<div class="order-product__item-count-info" appGetBackground [Stock]="prod.product.ItemExtra5">!</div>
														<div class="order-product__item-count-text">מלאי</div>
														<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.ItemExtra5 <= 0 }">
															{{ prod.product.ItemExtra5 | number : '1.0-2' : 'en-US' }}
														</div>
													</div>
												</div>
												<ng-template #priceInlineTemplate>
													<ng-container *ngIf="showConcentratedPrice">
														<div class="divider" style="width: 20px; height: 1px"></div>
														<div class="flex" style="gap: 6px">
															<div>{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}:</div>
															<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': prod.product.NetPrice !== prod.product.ShowPrice }">
																{{ prod.product.NetPrice | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</ng-container>
												</ng-template>
											</ng-container>
										</div>
									</div>
									<div class="grid" style="place-items: center; flex: 2">
										<div class="flex">
											<div
												class="order-product__item-block order-product__item-block_center gap"
												style="transform: none"
												[ngClass]="{ 'justify-evenly': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 }"
											>
												<ng-container [ngSwitch]="concentratedSpecialTabsSelectedIndex$ | async">
													<ng-container *ngIf="concentratedSpecialCurrentlyEdittingQuantities$ | async as quantities">
														<ng-container *ngSwitchCase="0">
															<div
																class="order-product__item-info-count order-product__item-info-count_big"
																style="padding: 0; width: 100%"
																*ngIf="_ss.SeeArizot && !prod.product.hidePacks"
															>
																<h3 class="order-product__item-info-count-title" style="font-size: 16px; font-weight: 550">
																	{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
																</h3>
																<div class="order-product__item-info-count-box" style="position: relative">
																	<button
																		class="order-product__item-info-count-button order-product__item-info-count-button_add"
																		(click)="!quantities.mustPurchaseQuantitiesLeft ? null : concentratedSpecialQuantityChange(prod.product, 'packs', 'plus', 1)"
																		style="position: relative"
																	></button>
																	<div
																		class="order-product__item-info-count-input"
																		*ngIf="prod.product.qt === 0 || prod.product.qt === 2"
																		[ngClass]="{ active: prod.product.TeudaPackQuantity }"
																		style="font-weight: 700; font-size: 20px"
																	>
																		{{ prod.product.TeudaPackQuantity || 0 }}
																	</div>
																	<div
																		class="order-product__item-info-count-input"
																		*ngIf="prod.product.qt === 1 || prod.product.qt === 3"
																		style="font-weight: 700; font-size: 20px; color: red"
																	>
																		{{ prod.product.TeudaPackQuantity || 0 }}
																	</div>
																	<button
																		class="order-product__item-info-count-button"
																		(click)="concentratedSpecialQuantityChange(prod.product, 'packs', 'minus', 1)"
																		style="position: relative"
																	></button>
																</div>
															</div>
															<div
																class="order-product__item-info-count order-product__item-info-count_big"
																*ngIf="!hideSingles && !prod.product.hideSingles"
																style="padding: 0; width: 100%"
																dir="ltr"
															>
																<h3 class="order-product__item-info-count-title" *ngIf="_ss.SeeArizot" style="font-size: 16px; font-weight: 550">יחידות</h3>
																<div class="order-product__item-info-count-box" style="position: relative">
																	<button
																		class="order-product__item-info-count-button order-product__item-info-count-button_add"
																		(click)="!quantities.mustPurchaseQuantitiesLeft ? null : concentratedSpecialQuantityChange(prod.product, 'singles', 'plus', 1)"
																		style="position: relative"
																	></button>
																	<div
																		class="order-product__item-info-count-input"
																		*ngIf="prod.product.qt === 0 || prod.product.qt === 3"
																		[ngClass]="{ active: prod.product.TeudaQuantity }"
																		style="font-weight: 700; font-size: 20px"
																	>
																		{{ prod.product.TeudaQuantity || 0 }}
																	</div>
																	<div
																		class="order-product__item-info-count-input"
																		*ngIf="prod.product.qt === 1 || prod.product.qt === 2"
																		style="font-weight: 700; font-size: 20px; color: red"
																	>
																		{{ prod.product.TeudaQuantity || 0 }}
																	</div>
																	<button
																		class="order-product__item-info-count-button"
																		(click)="concentratedSpecialQuantityChange(prod.product, 'singles', 'minus', 1)"
																		style="position: relative"
																	></button>
																</div>
															</div>
														</ng-container>
														<div class="order-product__item-info-count order-product__item-info-count_big" *ngSwitchCase="1" style="padding: 0; width: 100%" dir="ltr">
															<h3 class="order-product__item-info-count-title" *ngIf="!hideSingles && !prod.product.hideSingles" style="font-size: 16px; font-weight: 550">יחידות</h3>
															<h3 class="order-product__item-info-count-title" *ngIf="_ss.SeeArizot && !prod.product.hidePacks" style="font-size: 16px; font-weight: 550">
																{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
															</h3>
															<div class="order-product__item-info-count-box" style="position: relative">
																<button
																	class="order-product__item-info-count-button order-product__item-info-count-button_add"
																	(click)="!quantities.receiveBonusQuantitiesLeft ? null : concentratedSpecialQuantityChange(prod.product, 'bonus', 'plus', 1)"
																	style="position: relative"
																></button>
																<div class="order-product__item-info-count-input" [ngClass]="{ active: prod.product.TeudaBonusQuantity }" style="font-weight: 700; font-size: 20px">
																	{{ prod.product.TeudaBonusQuantity || 0 }}
																</div>

																<button
																	class="order-product__item-info-count-button"
																	(click)="concentratedSpecialQuantityChange(prod.product, 'bonus', 'minus', 1)"
																	style="position: relative"
																></button>
															</div>
														</div>
													</ng-container>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</div>
						</cdk-virtual-scroll-viewport>
						<cdk-virtual-scroll-viewport *ngSwitchCase="'medium'" [itemSize]="mediumConcentratedPopupSize" class="example-viewport example-viewport-popup">
							<div class="order-product order-product_small">
								<div class="product-row product-row__small" *cdkVirtualFor="let row of concentratedProductsShown$ | async | toMatrix : 3; let idx = index">
									<div
										class="order-product__item small-above768 flex flex-column"
										*ngFor="let prod of row; let i = index"
										[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
										style="touch-action: manipulation; height: 310px; flex: 1; padding: 25px 15px"
										id="Product{{ prod.product.ProductId }}"
										[ngStyle]="{ padding: ShowProduct === '1' ? '0' : null }"
									>
										<div
											class="order-product__item-present"
											[ngClass]="{ active: prod.product.TeudaBonusQuantity }"
											*ngIf="prod.product.present?.icon && !isB2BAgent && !prod.product.inSpecial && !isRallyanceReclamation"
											style="font-size: larger"
										>
											+<span id="Bonus{{ prod.product.ProductId }}"
												>{{ prod.product.TeudaBonusQuantity | getBonusNumber : prod.product.TeudaQuantity : prod.product.TeudaPackQuantity : prod.product.ItemPack }}
											</span>
										</div>
										<div
											class="order-product__item-corner"
											[attr.id]="
												prod.product.TeudaPackQuantity > 0 || prod.product.TeudaQuantity > 0 || prod.product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + prod.product.ProductId
											"
											appGetBorderColor
											[TeudaPackQuantity]="prod.product.TeudaPackQuantity"
											[TeudaQuantity]="prod.product.TeudaQuantity"
											[TeudaBonusQuantity]="prod.product.TeudaBonusQuantity"
											[NumOfTimes]="prod.product.NumOfTimes"
											[IC]="prod.product.IC"
										></div>
										<button mat-icon-button class="expand-icon" (click)="openDialog(prod.product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
											<mat-icon>unfold_more</mat-icon>
										</button>
										<div
											*ngIf="SeeImages === 0"
											class="order-product__item-info-count-image image"
											style="flex: 1"
											(click)="concentratedMoveToProductCard(prod.product, 0)"
											appSetCardStyle
											size="small"
											type="image"
										>
											<!-- <img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" /> -->
											<img
												src="{{ CustImageDomain }}/{{ prod.product | getImageNameFrom | encodeURI }}.jpg"
												class="ItemPic"
												crossorigin
												appFallbackSrc
												alt="photo"
												style="height: 100%; object-fit: contain"
											/>
										</div>
										<div
											*ngIf="SeeImages === 1"
											class="order-product__item-info-count-image image"
											(click)="concentratedMoveToProductCard(prod.product, 0)"
											appSetCardStyle
											size="small"
											type="image"
										>
											<img [src]="logo" class="ItemPic" crossorigin alt="photo" style="height: 100%; object-fit: contain; margin: auto" />
										</div>
										<div class="flex justify-align-center" *ngIf="ShowProduct !== '1'">
											<ng-container *ngIf="showConcentratedStock">
												<div
													class="order-product__item-count order-product__item-count_margin bigger-font"
													(click)="openSelectMlay(3 * idx + +i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[3 * idx + +i] : Admin"
												>
													<div class="order-product__item-count">
														<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock">
															!
														</div>
													</div>
												</div>
												<div
													class="order-product__item-count order-product__item-count_margin bigger-font"
													(click)="openSelectMlay(3 * idx + +i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[3 * idx + +i] : Admin"
												>
													<div class="order-product__item-count">
														<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock">
															!
														</div>
														<!-- <div class="order-product__item-count-text">מלאי</div> -->
														<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
															{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
														</div>
													</div>
												</div>
												<div
													class="order-product__item-count order-product__item-count_margin bigger-font"
													(click)="openMlayPopup(prod.product)"
													*ngIf="prod.product.BS | shouldStockShow : 'productBrowser' : false : Admin"
												>
													<div class="order-product__item-count">
														<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock">
															!
														</div>
														<!-- <div class="order-product__item-count-text">מלאי</div> -->
														<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
															{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
														</div>
													</div>
												</div>
											</ng-container>
											<ng-container *ngIf="showConcentratedPrice">
												<div class="divider" style="width: 20px; height: 1px"></div>
												<div class="flex" style="gap: 6px">
													<div style="font-size: 20px">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}:</div>
													<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': prod.product.NetPrice !== prod.product.ShowPrice }">
														{{ prod.product.NetPrice | number : '1.0-2' : 'en-US' }}
													</div>
												</div>
											</ng-container>
										</div>
										<div
											class="order-product__item-block order-product__item-block_center"
											[ngClass]="{ 'justify-evenly': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 }"
										>
											<div class="order-product__item-info-count order-product__item-info-count_big" style="width: 100%" *ngIf="_ss.SeeArizot && !prod.product.hidePacks">
												<h3 class="order-product__item-info-count-title" style="font-size: 16px; font-weight: 550" *ngIf="!hideSingles">
													{{ _text.packQuantityText }}
												</h3>
												<div class="order-product__item-info-count-box" style="position: relative">
													<button
														class="order-product__item-info-count-button order-product__item-info-count-button_add"
														(click)="ArizotPlus(prod.product)"
														style="position: relative"
													></button>
													<div
														class="order-product__item-info-count-input"
														(click)="concentratedMoveToProductCard(prod.product, 2)"
														*ngIf="prod.product.qt === 0 || prod.product.qt === 2"
														[ngClass]="{ active: prod.product.TeudaPackQuantity }"
														style="font-weight: 700; font-size: 20px"
													>
														{{ prod.product.TeudaPackQuantity }}
													</div>
													<div class="order-product__item-info-count-input" *ngIf="prod.product.qt === 1 || prod.product.qt === 3" style="font-weight: 700; font-size: 20px; color: red">
														{{ prod.product.TeudaPackQuantity }}
													</div>
													<button class="order-product__item-info-count-button" (click)="ArizotMinus(prod.product)" style="position: relative"></button>
												</div>
											</div>
											<div class="order-product__item-info-count order-product__item-info-count_big" style="width: 100%" dir="ltr" *ngIf="!hideSingles && !prod.product.hideSingles">
												<h3 class="order-product__item-info-count-title" style="font-size: 16px; font-weight: 550" *ngIf="_ss.SeeArizot">יחידות</h3>
												<div class="order-product__item-info-count-box" [ngClass]="{ 'justify-center': !_ss.SeeArizot }" style="position: relative">
													<button
														class="order-product__item-info-count-button order-product__item-info-count-button_add"
														(click)="YehidotPlus(prod.product)"
														style="position: relative"
													></button>
													<div
														class="order-product__item-info-count-input"
														(click)="concentratedMoveToProductCard(prod.product, 1)"
														*ngIf="prod.product.qt === 0 || prod.product.qt === 3"
														[ngClass]="{ active: prod.product.TeudaQuantity }"
														style="font-weight: 700; font-size: 20px"
													>
														{{ prod.product.TeudaQuantity }}
													</div>
													<div class="order-product__item-info-count-input" *ngIf="prod.product.qt === 1 || prod.product.qt === 2" style="font-weight: 700; font-size: 20px; color: red">
														{{ prod.product.TeudaQuantity }}
													</div>
													<button class="order-product__item-info-count-button" (click)="YehidotMinus(prod.product)" style="position: relative"></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</cdk-virtual-scroll-viewport>
					</ng-container>
				</ng-container>
				<ng-template #nonSpecialConcentratedCDKVirtualScrollBlock>
					<ng-container [ngSwitch]="concentratedProductPopupView$ | async">
						<div class="flex row" style="height: 40px" *ngIf="isConcentratedSpecialViewOpen$ | async">
							<mat-tab-group
								class="row text-2xl bold"
								animationDuration="0ms"
								(selectedTabChange)="tabChange($event)"
								*ngIf="concentratedSpecialCurrentlyEdittingQuantities$ | async as quantities"
							>
								<mat-tab labelClass="flex-1 text-2xl bold" label="קנה"></mat-tab>
								<mat-tab labelClass="flex-1 text-2xl bold" [disabled]="quantities.needed !== quantities.current" label="קבל"></mat-tab>
							</mat-tab-group>
						</div>
						<cdk-virtual-scroll-viewport
							*ngSwitchCase="'inline'"
							[itemSize]="inlineConcentratedPopupSize"
							[ngClass]="{ 'with-quantity-text': _ss.SeeArizot || !hideSingles, 'with-concentrated-special': isConcentratedSpecialViewOpen$ | async }"
							class="example-viewport example-viewport-popup"
						>
							<div class="order-product order-product_inline" style="margin-inline: 10px">
								<div
									class="flex justify-between order-product__item-concentrated"
									id="{{ 'product-present-' + i }}"
									*cdkVirtualFor="let prod of concentratedProductsShown$ | async; let i = index"
									style="touch-action: manipulation; width: 100%; height: 100px"
									[ngClass]="{ 'main-background secondary-color': isAlternativeColors, 'inline-over768_opened': prod.product.present?.showContent, 'with-remark': addComment }"
									id="Product{{ prod.product.ProductId }}"
								>
									<div
										class="order-product__item-corner"
										[attr.id]="prod.product.TeudaPackQuantity > 0 || prod.product.TeudaQuantity > 0 || prod.product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + prod.product.ProductId"
										appGetBorderColor
										[TeudaPackQuantity]="prod.product.TeudaPackQuantity"
										[TeudaQuantity]="prod.product.TeudaQuantity"
										[TeudaBonusQuantity]="prod.product.TeudaBonusQuantity"
										[NumOfTimes]="prod.product.NumOfTimes"
										[IC]="prod.product.IC"
									></div>
									<button mat-icon-button class="expand-icon" style="z-index: 1" (click)="openDialog(prod.product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
										<mat-icon>unfold_more</mat-icon>
									</button>
									<div
										class="order-product__item-info-count order-product__item-info-count_image"
										style="margin: 0; padding: 0; flex: 1"
										(click)="concentratedMoveToProductCard(prod.product, 0)"
									>
										<div *ngIf="SeeImages === 0" class="order-product__item-info-count-image image" style="height: 100%">
											<img
												src="{{ CustImageDomain }}/{{ prod.product | getImageNameFrom | encodeURI }}.jpg"
												crossorigin
												class="ItemPic"
												appFallbackSrc
												alt="photo"
												style="height: 100%; object-fit: contain"
											/>
										</div>
										<div *ngIf="SeeImages === 1" class="order-product__item-info-count-image image" style="height: 100px; width: 100px; margin: auto; display: flex; justify-content: center">
											<img [src]="logo" class="ItemPic" crossorigin appFallbackSrc alt="photo" style="height: 100%; object-fit: contain; margin: auto" />
										</div>
									</div>
									<div class="grid" style="flex: 6">
										<h2 class="order-product__item-title" style="overflow: hidden; white-space: nowrap; text-wrap: balance; margin: 0">
											<!-- Product_Name -->
											{{ prod.product | addItemExtraToName : ProductName_AddItemExtra }}
										</h2>
										<div class="flex justify-align-center">
											<ng-container #stockBlock>
												<div
													class="order-product__item-info-count order-product__item-info-count_center bigger-font"
													(click)="openSelectMlay(i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[i] : Admin"
													style="padding: 0; width: 100%"
												>
													<ng-container *ngIf="showConcentratedStock">
														<div class="order-product__item-count justify-align-center">
															<div
																class="order-product__item-count-info"
																appGetBackground
																[Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock"
															>
																!
															</div>
														</div>
													</ng-container>
													<ng-container *ngTemplateOutlet="priceInlineTemplate"></ng-container>
												</div>
												<div
													class="order-product__item-info-count order-product__item-info-count_center bigger-font"
													(click)="openSelectMlay(i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[i] : Admin"
													style="padding: 0; width: 100%"
												>
													<div class="order-product__item-count justify-align-center">
														<ng-container *ngIf="showConcentratedStock">
															<div
																class="order-product__item-count-info"
																appGetBackground
																[Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock"
															>
																!
															</div>
															<!-- <div class="order-product__item-count-text">מלאי</div> -->
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
																{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</ng-container>
													</div>
													<ng-container *ngTemplateOutlet="priceInlineTemplate"></ng-container>
												</div>
												<div
													class="order-product__item-info-count order-product__item-info-count_center bigger-font"
													style="padding: 0; transform: none; width: 100%"
													(click)="openMlayPopup(prod.product)"
													*ngIf="prod.product.BS | shouldStockShow : 'productBrowser' : false : Admin"
												>
													<div class="order-product__item-count justify-align-center">
														<ng-container *ngIf="showConcentratedStock">
															<div
																class="order-product__item-count-info"
																appGetBackground
																[Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock"
															>
																!
															</div>
															<div class="order-product__item-count-text">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : '' }}</div>
															<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
																{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
															</div>
														</ng-container>
														<ng-container *ngTemplateOutlet="priceInlineTemplate"></ng-container>
													</div>
													<div class="order-product__item-count justify-align-center" *ngIf="_ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88">
														<div class="order-product__item-count-info" appGetBackground [Stock]="prod.product.ItemExtra5">!</div>
														<div class="order-product__item-count-text">מלאי</div>
														<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.ItemExtra5 <= 0 }">
															{{ prod.product.ItemExtra5 | number : '1.0-2' : 'en-US' }}
														</div>
													</div>
												</div>
												<ng-template #priceInlineTemplate>
													<ng-container *ngIf="showConcentratedPrice">
														<div class="divider" style="width: 20px; height: 1px"></div>
														<div class="flex" style="gap: 6px">
															<div>{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}:</div>
															<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': prod.product.NetPrice !== prod.product.ShowPrice }">
																{{ prod.product.NetPrice | number : '1.0-2' : 'en-US' }}
															</div>
														</div>
													</ng-container>
												</ng-template>
											</ng-container>
										</div>
									</div>
									<div class="grid" style="place-items: center; flex: 2">
										<div class="flex">
											<div
												class="order-product__item-block order-product__item-block_center gap"
												style="transform: none"
												[ngClass]="{ 'justify-evenly': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 }"
											>
												<div class="order-product__item-info-count order-product__item-info-count_big" style="padding: 0; width: 100%" *ngIf="_ss.SeeArizot && !prod.product.hidePacks">
													<h3 class="order-product__item-info-count-title" style="font-size: 16px; font-weight: 550">
														{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}
													</h3>
													<div class="order-product__item-info-count-box" style="position: relative">
														<button
															class="order-product__item-info-count-button order-product__item-info-count-button_add"
															(click)="ArizotPlus(prod.product)"
															style="position: relative"
														></button>
														<div
															class="order-product__item-info-count-input"
															(click)="concentratedMoveToProductCard(prod.product, 2)"
															*ngIf="prod.product.qt === 0 || prod.product.qt === 2"
															[ngClass]="{ active: prod.product.TeudaPackQuantity }"
															style="font-weight: 700; font-size: 20px"
														>
															{{ prod.product.TeudaPackQuantity }}
														</div>
														<div
															class="order-product__item-info-count-input"
															*ngIf="prod.product.qt === 1 || prod.product.qt === 3"
															style="font-weight: 700; font-size: 20px; color: red"
														>
															{{ prod.product.TeudaPackQuantity }}
														</div>
														<button class="order-product__item-info-count-button" (click)="ArizotMinus(prod.product)" style="position: relative"></button>
													</div>
												</div>
												<div
													class="order-product__item-info-count order-product__item-info-count_big"
													*ngIf="!hideSingles && !prod.product.hideSingles"
													style="padding: 0; width: 100%"
													dir="ltr"
												>
													<h3 class="order-product__item-info-count-title" *ngIf="_ss.SeeArizot" style="font-size: 16px; font-weight: 550">יחידות</h3>
													<div class="order-product__item-info-count-box" style="position: relative">
														<button
															class="order-product__item-info-count-button order-product__item-info-count-button_add"
															(click)="YehidotPlus(prod.product)"
															style="position: relative"
														></button>
														<div
															class="order-product__item-info-count-input"
															(click)="concentratedMoveToProductCard(prod.product, 1)"
															*ngIf="prod.product.qt === 0 || prod.product.qt === 3"
															[ngClass]="{ active: prod.product.TeudaQuantity }"
															style="font-weight: 700; font-size: 20px"
														>
															{{ prod.product.TeudaQuantity }}
														</div>
														<div
															class="order-product__item-info-count-input"
															*ngIf="prod.product.qt === 1 || prod.product.qt === 2"
															style="font-weight: 700; font-size: 20px; color: red"
														>
															{{ prod.product.TeudaQuantity }}
														</div>
														<button class="order-product__item-info-count-button" (click)="YehidotMinus(prod.product)" style="position: relative"></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</cdk-virtual-scroll-viewport>
						<cdk-virtual-scroll-viewport *ngSwitchCase="'medium'" [itemSize]="mediumConcentratedPopupSize" class="example-viewport example-viewport-popup">
							<div class="order-product order-product_small">
								<div class="product-row product-row__small" *cdkVirtualFor="let row of concentratedProductsShown$ | async | toMatrix : 3; let idx = index">
									<div
										class="order-product__item small-above768 flex flex-column"
										*ngFor="let prod of row; let i = index"
										[ngClass]="{ 'main-background secondary-color': isAlternativeColors }"
										style="touch-action: manipulation; height: 310px; flex: 1; padding: 25px 15px"
										id="Product{{ prod.product.ProductId }}"
										[ngStyle]="{ padding: ShowProduct === '1' ? '0' : null }"
									>
										<div
											class="order-product__item-present"
											[ngClass]="{ active: prod.product.TeudaBonusQuantity }"
											*ngIf="prod.product.present?.icon && !isB2BAgent && !prod.product.inSpecial && !isRallyanceReclamation"
											style="font-size: larger"
										>
											+<span id="Bonus{{ prod.product.ProductId }}"
												>{{ prod.product.TeudaBonusQuantity | getBonusNumber : prod.product.TeudaQuantity : prod.product.TeudaPackQuantity : prod.product.ItemPack }}
											</span>
										</div>
										<div
											class="order-product__item-corner"
											[attr.id]="
												prod.product.TeudaPackQuantity > 0 || prod.product.TeudaQuantity > 0 || prod.product.TeudaBonusQuantity > 0 ? undefined : 'InOrder' + prod.product.ProductId
											"
											appGetBorderColor
											[TeudaPackQuantity]="prod.product.TeudaPackQuantity"
											[TeudaQuantity]="prod.product.TeudaQuantity"
											[TeudaBonusQuantity]="prod.product.TeudaBonusQuantity"
											[NumOfTimes]="prod.product.NumOfTimes"
											[IC]="prod.product.IC"
										></div>
										<button mat-icon-button class="expand-icon" (click)="openDialog(prod.product)" *ngIf="!isB2BAgent || isB2BAndShouldSeeHistoryButton">
											<mat-icon>unfold_more</mat-icon>
										</button>
										<div
											*ngIf="SeeImages === 0"
											class="order-product__item-info-count-image image"
											style="flex: 1"
											(click)="concentratedMoveToProductCard(prod.product, 0)"
											appSetCardStyle
											size="small"
											type="image"
										>
											<!-- <img *ngIf="shouldSeeBadatzLogo && (product | isB2BSpecialProduct) === 'hatam'" class="b2b-hatam-text-PNG" [src]="hatamTextPNG" /> -->
											<img
												src="{{ CustImageDomain }}/{{ prod.product | getImageNameFrom | encodeURI }}.jpg"
												class="ItemPic"
												crossorigin
												appFallbackSrc
												alt="photo"
												style="height: 100%; object-fit: contain"
											/>
										</div>
										<div
											*ngIf="SeeImages === 1"
											class="order-product__item-info-count-image image"
											(click)="concentratedMoveToProductCard(prod.product, 0)"
											appSetCardStyle
											size="small"
											type="image"
										>
											<img [src]="logo" class="ItemPic" crossorigin alt="photo" style="height: 100%; object-fit: contain; margin: auto" />
										</div>
										<div class="flex justify-align-center" *ngIf="ShowProduct !== '1'">
											<ng-container *ngIf="showConcentratedStock">
												<div
													class="order-product__item-count order-product__item-count_margin bigger-font"
													(click)="openSelectMlay(3 * idx + +i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle1' : ShowMlay[3 * idx + +i] : Admin"
												>
													<div class="order-product__item-count">
														<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock">
															!
														</div>
													</div>
												</div>
												<div
													class="order-product__item-count order-product__item-count_margin bigger-font"
													(click)="openSelectMlay(3 * idx + +i)"
													*ngIf="prod.product.BS | shouldStockShow : 'StockToggle2' : ShowMlay[3 * idx + +i] : Admin"
												>
													<div class="order-product__item-count">
														<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock">
															!
														</div>
														<!-- <div class="order-product__item-count-text">מלאי</div> -->
														<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
															{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
														</div>
													</div>
												</div>
												<div
													class="order-product__item-count order-product__item-count_margin bigger-font"
													(click)="openMlayPopup(prod.product)"
													*ngIf="prod.product.BS | shouldStockShow : 'productBrowser' : false : Admin"
												>
													<div class="order-product__item-count">
														<div class="order-product__item-count-info" appGetBackground [Stock]="showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock">
															!
														</div>
														<!-- <div class="order-product__item-count-text">מלאי</div> -->
														<div class="order-product__item-count-number" dir="ltr" [ngClass]="{ 'order-product__item-count-number_red': prod.product.Stock <= 0 }">
															{{ (showTranslatedStock ? prod.product.Stock / prod.product.ItemPack : prod.product.Stock) | number : '1.0-2' : 'en-US' }}
														</div>
													</div>
												</div>
											</ng-container>
											<ng-container *ngIf="showConcentratedPrice">
												<div class="divider" style="width: 20px; height: 1px"></div>
												<div class="flex" style="gap: 6px">
													<div style="font-size: 20px">{{ _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 ? 'משוריין' : 'מחיר' }}:</div>
													<div class="order-product__item-count-number" [ngClass]="{ 'valid-color': prod.product.NetPrice !== prod.product.ShowPrice }">
														{{ prod.product.NetPrice | number : '1.0-2' : 'en-US' }}
													</div>
												</div>
											</ng-container>
										</div>
										<div
											class="order-product__item-block order-product__item-block_center"
											[ngClass]="{ 'justify-evenly': _ss.CustomerInformation.SpecificCompanyVersion === '1' && _ss.DocType === 88 }"
										>
											<div class="order-product__item-info-count order-product__item-info-count_big" style="width: 100%" *ngIf="_ss.SeeArizot && !prod.product.hidePacks">
												<h3 class="order-product__item-info-count-title" style="font-size: 16px; font-weight: 550" *ngIf="!hideSingles">
													{{ _text.packQuantityText }}
												</h3>
												<div class="order-product__item-info-count-box" style="position: relative">
													<button
														class="order-product__item-info-count-button order-product__item-info-count-button_add"
														(click)="ArizotPlus(prod.product)"
														style="position: relative"
													></button>
													<div
														class="order-product__item-info-count-input"
														(click)="concentratedMoveToProductCard(prod.product, 2)"
														*ngIf="prod.product.qt === 0 || prod.product.qt === 2"
														[ngClass]="{ active: prod.product.TeudaPackQuantity }"
														style="font-weight: 700; font-size: 20px"
													>
														{{ prod.product.TeudaPackQuantity }}
													</div>
													<div class="order-product__item-info-count-input" *ngIf="prod.product.qt === 1 || prod.product.qt === 3" style="font-weight: 700; font-size: 20px; color: red">
														{{ prod.product.TeudaPackQuantity }}
													</div>
													<button class="order-product__item-info-count-button" (click)="ArizotMinus(prod.product)" style="position: relative"></button>
												</div>
											</div>
											<div class="order-product__item-info-count order-product__item-info-count_big" style="width: 100%" dir="ltr" *ngIf="!hideSingles && !prod.product.hideSingles">
												<h3 class="order-product__item-info-count-title" style="font-size: 16px; font-weight: 550" *ngIf="_ss.SeeArizot">יחידות</h3>
												<div class="order-product__item-info-count-box" [ngClass]="{ 'justify-center': !_ss.SeeArizot }" style="position: relative">
													<button
														class="order-product__item-info-count-button order-product__item-info-count-button_add"
														(click)="YehidotPlus(prod.product)"
														style="position: relative"
													></button>
													<div
														class="order-product__item-info-count-input"
														(click)="concentratedMoveToProductCard(prod.product, 1)"
														*ngIf="prod.product.qt === 0 || prod.product.qt === 3"
														[ngClass]="{ active: prod.product.TeudaQuantity }"
														style="font-weight: 700; font-size: 20px"
													>
														{{ prod.product.TeudaQuantity }}
													</div>
													<div class="order-product__item-info-count-input" *ngIf="prod.product.qt === 1 || prod.product.qt === 2" style="font-weight: 700; font-size: 20px; color: red">
														{{ prod.product.TeudaQuantity }}
													</div>
													<button class="order-product__item-info-count-button" (click)="YehidotMinus(prod.product)" style="position: relative"></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</cdk-virtual-scroll-viewport>
					</ng-container>
				</ng-template>

				<!-- <div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="isConcentratedProductPopupShowing$.next(false)">חזור</button>
				</div> -->
			</div>
		</div>
	</div>
</div>

<app-menu-button
	[shouldChangeToPositionInitial]="showSortPopupSanen || showSortPopup || showDepartmentsPopup || OnMivza || ExtraInfoClient || ExtraMlayClient || showProductDetailPopup"
></app-menu-button>
