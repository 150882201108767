import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import { StoreService } from '../services/store.service';

@Directive({
	selector: '[appGetBorderColor]',
})
export class GetBorderColorDirective implements OnChanges {
	@Input() TeudaPackQuantity;
	@Input() TeudaQuantity;
	@Input() TeudaBonusQuantity;
	@Input() NumOfTimes;
	@Input() IC;
	@Input() CC;

	constructor(private element: ElementRef, private _ss: StoreService) {}

	ngOnChanges() {
		const el: HTMLDivElement = this.element.nativeElement;

		if (this.CC != null) {
			let color = this.CC || 'grey';

			if (typeof this.CC === 'string') this.CC = this.CC.toLowerCase();

			// ? orange == magenta according to original code ?
			if (this.CC === 'orange') {
				color = 'magenta';
			}

			el.style.background = color;
		}

		if (this.TeudaPackQuantity != null && this.TeudaQuantity != null && this.TeudaBonusQuantity != null && this.NumOfTimes != null && this.IC != null) {
			let color;

			if (this.TeudaPackQuantity > 0 || this.TeudaQuantity > 0 || this.TeudaBonusQuantity > 0) {
				color = 'var(--valid-color)';
			} else {
				color = this.NumOfTimes ? '#5e5eff' : this.IC || null;
				// Override color if sql says. Blue meaning any color other than red
				// Editted for zanlacolyahin - want purple
				if (this._ss.CustomerInformation.BlueOrRed === '1' && (this.IC == 'red' || this.IC == 'purple')) color = this.IC;
				if (this._ss.CustomerInformation.BlueOrRed === '2' && (this.IC == 'pink' || this.IC == 'orange')) color = this.IC;
			}

			if (el && el instanceof HTMLDivElement) {
				el.style.borderColor = color;
			}
		} else {
			console.log('I should never show');
		}
	}
}
