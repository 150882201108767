import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { visistsPlannedVsUnplannedStatuses, VisitsPlannedVsUnplanned } from '../Interfaces/SQL-reports-responses';

export type PlannedVsUnplannedStatistics = {
	agent: string;
	status: typeof visistsPlannedVsUnplannedStatuses[number];
	totalOf: number;
	totalSum: number;
};

@Injectable({
	providedIn: 'root',
})
export class VisitsService {
	chosenLineToView$ = new BehaviorSubject<{ agent: string; status: typeof visistsPlannedVsUnplannedStatuses[number]; from: Date; to: Date } | null>(null);
	scrollYPosition$ = new BehaviorSubject(0);

	isViewingSpecificAgentAndStatus$ = this.chosenLineToView$.pipe(map(value => !!value));

	allVisits: VisitsPlannedVsUnplanned[] = [];
	visits$ = new BehaviorSubject<VisitsPlannedVsUnplanned[]>([]);
	visitsPerAgentStatistics$ = new BehaviorSubject<PlannedVsUnplannedStatistics[]>([]);

	namesRecord: Record<string, string> = {};

	plannedVsUnplannedForm = this._fb.nonNullable.group({
		agentSearch: [''],
		from: [<string | Date>new Date(), Validators.required],
		to: [<string | Date>new Date(), Validators.required],
		agents: [<string[]>[]],
	});

	constructor(private _fb: FormBuilder) {}

	resetChosenLineState() {
		this.chosenLineToView$.next(null);
	}

	resetForm() {
		this.plannedVsUnplannedForm.reset({ agents: [], agentSearch: '', from: new Date(), to: new Date() });
	}

	resetAll() {
		this.resetChosenLineState();
		this.resetForm();
		this.allVisits = [];
		this.visits$.next([]);
		this.visitsPerAgentStatistics$.next([]);
	}
}
