import { Component } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.component.html',
	styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	public form: FormGroup;

	public Privacy = true;

	public MoveToLogin() {
		this.router.navigate(['login']);
	}
}
