<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="header header_app-shop-return" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToHome()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /> <span style="color: var(--secondary-color); font-size: 14px">חזרה</span>
					</div>

					<div class="header__search">
						<form [formGroup]="form" (keyup)="SearchClient()">
							<input
								class="header__search-input"
								type="text"
								[placeholder]="_text.store.search"
								formControlName="ClientSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="clientsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
					</div>

					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- start shop/return markup -->
		<section class="shop" *ngIf="Loading === false">
			<div class="content">
				<div
					class="shop__filters row flex-column"
					[ngClass]="{
						'main-content_absolute': !_ss.headerAbsolute
					}"
				>
					<div class="header__block">
						<div class="filter-sort__row">
							<span class="filter-sort__row-name">{{ dateString }} </span>
						</div>
						<div class="filter-sort__row" *ngIf="this._ss.CompanyName !== null">
							<span class="filter-sort__row-name">{{ this._ss.CompanyName }}</span>
							<div class="filter-sort__row-img image"></div>
						</div>

						<div class="filter-sort__row" *ngIf="isTruckDistribution && isAnySavedOrder$ | async">
							<div class="filter-sort__row-name" (click)="openSavedOrdersPopup()">שלח הכל</div>
						</div>

						<div class="filter-sort__row filter-sort__row_double" [ngClass]="{ 'valid-background secondary-color': _likut.isMekabel }">
							<div class="filter-sort__row-block" *ngIf="!_likut.isMekabel; else mekabelOrderToWarehouseBlock">
								<span class="filter-sort__row-name">
									{{ _text.store.mainTasks }}
									<span>{{ (totalTasksCompleted$ | async) || 0 }}</span> / <span>{{ (totalTasks$ | async) || 0 }}</span></span
								>
							</div>
							<ng-template #mekabelOrderToWarehouseBlock>
								<div class="filter-sort__row-block" style="margin: 0" (click)="handleOpenWarehouseDialog()">
									<span class="filter-sort__row-name" style="font-size: 18px">קבלה למחסן</span>
								</div>
							</ng-template>
						</div>
					</div>
				</div>

				<div class="shop__cards row">
					<cdk-virtual-scroll-viewport [itemSize]="itemSize" [minBufferPx]="itemSize * 5" [maxBufferPx]="itemSize * 5 * 2" class="example-viewport" [ngSwitch]="pageMode$ | async">
						<ng-container *ngSwitchCase="'likut'">
							<ng-container *ngIf="_likut.isMekabel; else allLikutTeudotBlock">
								<div
									class="shop__card"
									*cdkVirtualFor="let ac of allTeudotLikutMekabelTeudotDisplay$ | async; index as i"
									[ngStyle]="{ '--color': ac.CC }"
									(click)="handleClicked(ac, 'likut-mekabel')"
								>
									<div class="shop__card-block" [ngClass]="{ 'has-status': ac.status | taskTextVisibility : Admin }">
										<div class="shop__card-title">
											<div class="shop__card-title-text">{{ ac.clientName + (ac.clientid ? ' - ' + ac.clientid : '') }}</div>
											<!-- <div class="shop__card-title-text">{{ (ac.address ? ac.address : '') + (ac.city ? ', ' + ac.city : '') }}</div> -->
										</div>
										<div class="shop__card-info">
											<!-- <div class="shop__card-info-block">
												<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalTeudot }}:</div>
												<div class="shop__card-info-text">{{ ac.totalTeudot }}</div>
											</div> -->
											<div class="shop__card-info-block">
												<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalRow }}:</div>
												<div class="shop__card-info-text">{{ ac.totalRow }}</div>
											</div>
										</div>
									</div>
									<span class="text-over" *ngIf="ac.status | taskTextVisibility : Admin">{{ ac.status | taskText : 'likut' }}</span>
								</div>
							</ng-container>

							<ng-template #allLikutTeudotBlock>
								<div
									class="shop__card"
									*cdkVirtualFor="let ac of allTeudotNewRouteTeudotDisplay$ | async; index as i"
									[ngClass]="{ 'shop__card__with-color': _ss.CustomerInformation.ColorClientName === '1' && ac.CC, 'unmatched-quantities': !ac.quantitiesMatch && _likut.isCheckerAgent }"
									[ngStyle]="{ '--color': ac.CC }"
									(click)="handleClicked(ac, 'likut')"
								>
									<div class="shop__card-block" [ngClass]="{ 'has-status': ac.status | taskTextVisibility : Admin }">
										<div class="shop__card-title">
											<div class="shop__card-title-text">{{ ac.clientName + (ac.clientid ? ' - ' + ac.clientid : '') }}</div>
											<!-- <div class="shop__card-title-text">{{ (ac.address ? ac.address : '') + (ac.city ? ', ' + ac.city : '') }}</div> -->
										</div>
										<div class="shop__card-info">
											<div class="shop__card-info-block">
												<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalTeudot }}:</div>
												<div class="shop__card-info-text">{{ ac.totalTeudot }}</div>
											</div>
											<div class="shop__card-info-block">
												<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalRow }}:</div>
												<div class="shop__card-info-text">{{ ac.totalRow }}</div>
											</div>
										</div>
									</div>
									<span class="text-over" *ngIf="ac.status | taskTextVisibility : Admin">{{ ac.status | taskText : 'likut' }}</span>
								</div>
							</ng-template>
						</ng-container>

						<ng-container #truckLoadingBlock *ngSwitchCase="'truck-loading'">
							<div
								class="shop__card"
								*cdkVirtualFor="let ac of allTruckLoadingTeudotDisplay$ | async; index as i"
								[ngClass]="{ 'shop__card__with-color': _ss.CustomerInformation.ColorClientName === '1' && ac.CC }"
								[ngStyle]="{ '--color': ac.CC, 'background-color': ac.colorForBackground || null }"
								(click)="handleClicked(ac, 'truck-loading')"
							>
								<div class="shop__card-block" [ngClass]="{ 'has-status': ac.status | taskTextVisibility : Admin }">
									<div class="shop__card-title">
										<div class="shop__card-title-text">{{ ac.clientName + (ac.clientid ? ' - ' + ac.clientid : '') }}</div>
										<div class="shop__card-title-text">{{ ac.address }}</div>
									</div>
									<div class="shop__card-info">
										<div class="shop__card-info-block" *ngIf="(onlyDocnum$ | async) === false">
											<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalTeudot }}:</div>
											<div class="shop__card-info-text">{{ ac.totalTeudot }}</div>
										</div>
										<div class="shop__card-info-block">
											<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalRow }}:</div>
											<div class="shop__card-info-text">{{ ac.totalRow }}</div>
										</div>
									</div>
								</div>
								<span class="text-over" *ngIf="ac.status | taskTextVisibility : Admin">{{ ac.status | taskText : 'truck-loading' }}</span>
							</div>
						</ng-container>

						<ng-container *ngIf="savedOrders$ | async as savedOrders">
							<ng-container #truckDistributionBlock *ngSwitchCase="'truck-distribution'">
								<div
									class="shop__card shop__card-distribution"
									*cdkVirtualFor="let ac of allTruckDistributionTeudotDisplay$ | async; index as i"
									[ngClass]="{
										'shop__card__with-color': _ss.CustomerInformation.ColorClientName === '1' && ac.CC,
										'with-extra5 flex': ac.extra5Array.length
									}"
									[ngStyle]="{ '--color': ac.CC, 'background-color': ac.colorForBackground || null }"
									(click)="handleClicked(ac, 'truck-distribution')"
								>
									<div
										class="grid width100"
										[ngClass]="{ 'has-status': (ac.status | taskTextVisibility : Admin) || savedOrders[ac.clientid + ac.status + ac.address + ac.date + (ac.groupB || '')] }"
									>
										<div class="bold text-overflow" style="text-align: center; min-height: 1.6em; max-height: 2em">
											{{ ac.clientName + (ac.clientid ? ' - ' + ac.clientid : '') }}
										</div>
										<div class="flex justify-between rtl">
											<div class="shop__card-info justify-align-center">
												<div class="shop__card-info-block" *ngIf="(onlyDocnum$ | async) === false">
													<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalTeudot }}:</div>
													<div class="shop__card-info-text">{{ ac.totalTeudot }}</div>
												</div>
												<div class="shop__card-info-block">
													<div class="shop__card-info-text shop__card-info-text_title">{{ _text.store.totalRow }}:</div>
													<div class="shop__card-info-text">{{ ac.totalRow }}</div>
												</div>
											</div>
											<div class="shop__card-info flex flex-column justify-align-center text-balance text-align-center" style="flex: 1">
												<div class="shop__card-title-text">{{ ac.address }}</div>
												<ng-container *ngIf="ac.extra5Array.length > 1; else extra5ArrayDistributionWithoutButton">
													<div class="row">
														<button
															mat-flat-button
															style="color: var(--valid-color)"
															(click)="stopPropogation($event); extra5State$.next({ client: ac.clientName + (ac.clientid ? ' - ' + ac.clientid : ''), array: ac.extra5Array })"
														>
															<mat-icon>add</mat-icon>
															הצג אישורים
														</button>
													</div>
												</ng-container>
												<ng-template #extra5ArrayDistributionWithoutButton>
													<div class="shop__card-title-text" *ngIf="ac.extra5Array.length">{{ ac.extra5Array[0].Extra5 }}</div>
												</ng-template>
											</div>
											<div class="shop__card-info justify-align-center">
												<div class="shop__card-info-block justify-align-center" (click)="stopPropogation($event)">
													<a href="https://waze.com/ul?q={{ ac.address }}" class="flex" target="_blank" rel="noopener noreferrer"
														><img src="/assets/images/client-profile/simple-icons_waze.svg" alt="image"
													/></a>
												</div>
												<div class="shop__card-info-block justify-align-center" (click)="stopPropogation($event)">
													<a class="flex" [href]="'tel:' + ac.phone"><img src="/assets/images/client-profile/whatsapp-icon.svg" alt="image" /></a>
												</div>
											</div>
										</div>
									</div>
									<span class="text-over" *ngIf="(ac.status | taskTextVisibility : Admin) || savedOrders[ac.clientid + ac.status + ac.address + ac.date + (ac.groupB || '')]">{{
										ac.status | taskText : 'truck-distribution' : !!savedOrders[ac.clientid + ac.status + ac.address + ac.date + (ac.groupB || '')]
									}}</span>
								</div>
							</ng-container>
						</ng-container>
					</cdk-virtual-scroll-viewport>
				</div>
			</div>
		</section>
		<!-- end shop/return markup -->

		<section class="shop-delivery" *ngIf="Loading === true">
			<div class="container">
				<div class="shop-delivery__row row">
					<div class="shop-delivery__photo image">
						<img src="/assets/images/shop-return/delivery-icon.gif" alt="photo" />
					</div>
					<h2 class="shop-delivery__title shop-delivery__title_margin">{{ status$ | async }}</h2>
					<h2 class="shop-delivery__title">{{ this.Date }}</h2>
					<h2 class="shop-delivery__title"><span class="shop-delivery__title-bold">נא לא לסגור את הדפדפן</span> סגירת הדפדפן תצריך סנכרון חדש</h2>
				</div>
			</div>
		</section>

		<!-- markup for product popup -->
		<div class="popup" *ngIf="LoadingClient === true" style="z-index: 333">
			<div class="overlay"></div>
			<div class="product-popup popup__content">
				<div class="order-product__item">
					<div class="order-process__row order-process__row_success">
						<div class="row">
							<h2 class="order-process__title" style="text-align: center">טוען אנא המתן</h2>
						</div>
						<div class="row"></div>
						<div class="row">
							<div class="order-process__image image">
								<img *ngIf="_ss.isConnectionFastEnough()" src="/assets/images/basket/process/success.gif" alt="photo" />
							</div>
						</div>
						<div class="row"></div>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="extra5State$ | async as extra5">
			<div class="popup" *ngIf="extra5.array.length" style="z-index: 999">
				<div class="overlay"></div>
				<div class="product-detail-add__popup popup__content">
					<div class="product-detail__wrapper">
						<div class="product-detail__item">
							<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="extra5State$.next({ client: '', array: [] })"></div>
							<div class="product-detail-add__popup-row flex-column">
								<h2 class="product-detail-add__popup-title" style="margin: 0">אישורים</h2>
								<h2 class="product-detail-add__popup-title" style="margin: 0; padding-top: 0">{{ extra5.client }}</h2>
							</div>
							<table style="text-align: center; width: 99%; margin: auto">
								<tr>
									<th>תעודה</th>
									<th>אישור</th>
								</tr>
								<tr *ngFor="let extra of extra5.array">
									<td>{{ extra.DocNum }}</td>
									<td>{{ extra.Extra5 }}</td>
								</tr>
							</table>
							<div class="product-detail-add__popup-row">
								<button class="product-detail-add__popup-button" (click)="extra5State$.next({ client: '', array: [] })">חזור</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="sendTheFollowingOrders$ | async as orders">
			<div class="popup" *ngIf="orders.length">
				<div class="overlay"></div>
				<div class="product-detail-add__popup popup__content">
					<div class="product-detail__wrapper">
						<div class="product-detail__item">
							<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="sendTheFollowingOrders$.next([])"></div>
							<div class="product-detail-add__popup-row flex-column">
								<h2 class="product-detail-add__popup-title" style="margin: 0">שלח כל המשימות</h2>
							</div>
							<table style="text-align: center; width: 99%; margin: auto">
								<tr>
									<th>לקוח</th>
									<th *ngIf="isSendingAnyOrders$ | async">סטטוס</th>
								</tr>
								<tr *ngFor="let order of orders">
									<td>{{ order.main.clientName + (order.main.clientid ? ' - ' + order.main.clientid : '') }}</td>
									<td *ngIf="isSendingAnyOrders$ | async">{{ order | taskSendingStatus }}</td>
								</tr>
							</table>
							<div class="row text-align-center" *ngIf="areAllOrdersSent$ | async">
								<p class="valid-color">כל המשימות נשלחו בהצלחה.</p>
								<p class="valid-color">ניתן לסגור את החלון</p>
							</div>
							<div class="product-detail-add__popup-row">
								<button class="product-detail-add__popup-button" (click)="sendTheFollowingOrders$.next([])">חזור</button>
								<button class="product-detail-add__popup-button product-detail-add__popup-button_green" *ngIf="(areAllOrdersSent$ | async) === false" (click)="startSendingSavedOrders()">
									שלח
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<!-- end markup for product popup -->
		<!-- start if didn't heve filters result -->

		<!-- <ng-container *ngIf="teudotDisplay$ | async as teudot">
			<section class="shop-filter-empty" *ngIf="!teudot.length && !Loading && form.controls.ClientSearch.value">
				<div class="container">
					<div class="shop-filter-empty__row row">
						<div class="shop-filter-empty__photo image">
							<img src="/assets/images/shop-return/no-filters-result.svg" alt="photo" />
						</div>
						<h2 class="shop-filter-empty__title" *ngFor="let line of _text.store.notFound">{{ line }}</h2>
					</div>
				</div>
			</section>
		</ng-container> -->
		<!-- end if didn't heve filters result -->
	</div>

	<!-- main menu markup -->
	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img src="{{ this._ss.CustomerInformation?.CustImageDomain }}/companylogo.jpg" crossorigin="anonymous" alt="mobility" onerror="this.onerror=null; this.src=MOBILITY_LOGO" />
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ this._ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ this._ss.AgentIdConnected }}</div>
				</div>
			</div>
			<ng-container *ngIf="hasReports">
				<div class="main-menu__border"></div>
				<div class="main-menu__row">
					<div class="main-menu__col" (click)="MoveToDohot()">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-statistic.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">{{ _text.store.reports }}</div>
					</div>
				</div>
			</ng-container>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="handleUpdateTasks()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-person.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">{{ _text.store.updateTasks }}</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="BackToHome()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">דף הבית</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__col">
				<div class="main-menu__col-title">{{ _ss.VersionNumber }}-{{ Admin }}-{{ _ss.CustomerInformation?.CustName }}</div>
			</div>
		</div>
	</div>
	<!-- end main menu markup -->
</div>

<!-- start markup for product detail add popup -->

<app-menu-button [shouldChangeToPositionInitial]="showSortPopupSanen || showSortPopup || LoadingClient"></app-menu-button>

<div class="popup" *ngIf="isNewReceiptPopupOpen$ | async" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed wrapper">
		<div class="product-detail__item">
			<div class="popup__close" (click)="toggleReceiptPopup('close')" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>

			<!-- <div *ngIf="true"> -->
			<div *ngIf="!_receipts.receiptSent && _receipts.sendingErroredWithTimeoutAndCredit; else receiptNormalBlock">
				<h2 class="product-detail-add__popup-title">קרתה תקלה בעת שידור עסקת אשראי</h2>

				<h3 style="text-align: center">הזמנתך נשמרה אך לא ניתנת לשליחה שוב</h3>
				<h3 style="text-align: center">נא לפנות למשרד לבדיקה</h3>
				<h4 style="text-align: center">מזהה עסקה: {{ _receipts.creditFailureIdentifiers.transactionID }}</h4>
				<h4 style="text-align: center">מספר הזמנה: {{ _receipts.creditFailureIdentifiers.teudaNum }}</h4>

				<div class="auth__help-image image">
					<img src="/assets/images/basket/process/error.gif" alt="photo" />
				</div>
				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="toggleReceiptPopup('close')">קיבלתי</button>
				</div>
				<div class="product-detail-add__popup-row"></div>
			</div>

			<ng-template #receiptNormalBlock>
				<app-new-receipt *ngIf="!_receipts.receiptSent" (closePopup)="handleReceiptPopupCloseFromChild($event)"></app-new-receipt>

				<div *ngIf="_receipts.receiptSent">
					<h2 class="product-detail-add__popup-title">קבלה נשלחה בהצלחה</h2>
					<h3 style="text-align: center">{{ this._ss.OneClientInfo.Client_Name }}</h3>
					<h4 style="text-align: center">מזהה עסקה: {{ _receipts.lastDocnumSent }}</h4>
					<ng-container *ngIf="_receipts.canPayWithCreditCard">
						<h4 style="text-align: center">מספר מנפיק: {{ _receipts.lastAuthManpikNo }}</h4>
						<h4 style="text-align: center">מספר שובר: {{ _receipts.lastShovarNum }}</h4>
						<h4 style="text-align: center">מספר מפעיל חיצוני: {{ _receipts.lastTransactionID }}</h4>
					</ng-container>
					<div class="auth__help-image image">
						<img src="/assets/images/auth/plane-icon.gif" alt="photo" />
					</div>
					<div class="product-detail-add__popup-row">
						<button class="product-detail-add__popup-button valid-background" (click)="closeReceiptPopup()">קיבלתי</button>
					</div>
					<div class="product-detail-add__popup-row"></div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
