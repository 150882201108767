<!-- <h2 mat-dialog-title align="end">{{ data.special[0].Name }}</h2>
<h2 mat-dialog-title align="end">{{ total }}</h2> -->

<div class="flex justify-evenly rtl main-menu__logo-col-title" style="color: black">
	<span>{{ data.special[0].Name }}</span>
	<span style="color: var(--valid-color)">{{ total | number : '1.0-2' }}</span>
</div>
<mat-dialog-content class="mat-typography" dir="rtl">
	<div class="product-detail__wrapper2 custom-scrollbar">
		<table class="report-table__table no-width" cellspacing="0" style="display: table">
			<thead class="report-table__table-head">
				<tr>
					<th class="report-table__table-head-name">פריט</th>
					<th class="report-table__table-head-name">כמות</th>
					<th class="report-table__table-head-name">מחיר</th>
					<th class="report-table__table-head-name">בונוס</th>
				</tr>
			</thead>
			<tbody class="report-table__table-body">
				<tr *ngFor="let special of data.special">
					<td class="report-table__table-body-name">{{ special.Product_Name }}</td>
					<td class="report-table__table-body-name">{{ special.TeudaQuantity + special.TeudaPackQuantity * special.ItemPack | number : '1.0-2' }}</td>
					<td class="report-table__table-body-name">{{ special.TeudaItemNetPrice * (special.TeudaQuantity + special.TeudaPackQuantity * special.ItemPack) | number : '1.0-2' }}</td>
					<td class="report-table__table-body-name">{{ special.TeudaBonusQuantity }}</td>
				</tr>
			</tbody>
		</table>

		{{ data.special[0].SpecialDescription }}
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="true" cdkFocusInitial>סגור</button>
</mat-dialog-actions>
