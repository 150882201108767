<!-- ========================= -->
<div class="popup" *ngIf="Privacy === true" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<!-- <div
                 class="popup__close"
                 (click)="Privacy = false"
               ></div> -->

		<div style="text-align: center; width: 99%; margin: auto; font-size: 15px; color: blue; font-weight: 500px">Privacy Policy - תנאי שימוש</div>
		<div style="text-align: center; width: 99%; margin: auto; font-size: 15px">office@mobility-ltd.com - 050-3899773</div>

		<div dir="rtl" class="div3" style="margin-top: 10px">
			תנאי שימוש באפליקציית מוביליטי, כדי לאבטח את המשתמש שלך, מוביליטי לעולם לא תבקש את פרטיך האישיים, פרטי ההתחברות מסופקים אך ורק על ידי חברת מוביליטי, אנא אל תחלוק את פרטי ההתחברות הניתנים לך,
			מוביליטי רשאית לעדכן את תנאי השימוש, אנא וודא שאתה בודק את תנאי השימוש מפעם לפעם, אם הנך חושב ששימוש באפליקציית מוביליטי פוגע באחד מתנאי השימוש אנא צור איתנו קשר בפרטים למעלה.
			<div class="product-detail-add__popup-row" style="width: 100px; margin: auto; margin-top: 10px">
				<button class="popup-button" (click)="MoveToLogin()" style="background-color: #38d88b; width: 100px">מאשר</button>
			</div>
		</div>

		<div class="div3">
			This privacy policy sets out how mobility uses and protects any information on this website. mobility is committed to ensuring that your privacy is protected. mobility does not required any
			information from our users,login information is provided only by mobility, mobility will never ask you to insert any personal information, then you can be assured that it will only be used in
			accordance with this privacy statement. mobility may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with
			any changes. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly
			correct any information found to be incorrect.

			<div class="product-detail-add__popup-row" style="width: 100px; margin: auto; margin-top: 10px">
				<button class="popup-button" (click)="MoveToLogin()" style="background-color: #38d88b; width: 100px">Confirm</button>
			</div>
		</div>
	</div>
</div>
<!-- ========================= -->
