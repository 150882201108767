import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { compress } from 'lz-string';
import { BehaviorSubject } from 'rxjs';
import SignaturePad from 'signature_pad';
import { LikutProductDisplayNewRoute } from '../components/likut-task/likut-task.component';
import {
	LikutAssignmentUpdateLineNum,
	LikutDisplayMain,
	LikutFinishBodyNewRoutes,
	LikutKabalaStatus,
	LikutMainSQL,
	LikutMekabelDisplay,
	LikutMekabelFinishBody,
	LikutMekabelFinishBodyForUnexistingTask,
	LikutMekabelTaskExtra,
	LikutStatus,
	LikutSubSQL,
	LikutTaskDisplayWithAssignment,
	LikutUsers,
	LStatuses,
	MekabelWarehouse,
	NewLikutMekabelWarehouseFormResult,
} from '../Interfaces/likut';
import { AllProducts, DocTypesNumToName } from '../Interfaces/SQL-morning-responses';
import { AdminTypes, Users } from '../Interfaces/users';
import { translateDateForSQLServer } from '../Utility/functions';
import { StoreService } from './store.service';
import { TextService } from './text.service';
import { Time } from './util.service';

export const LIKUT_ADMIN_TYPES = ['melaket', 'melaketm', 'melaketa', 'checker', 'checkerm', 'likutmanager', 'mekabel'] as const;

export const userAdminToValue = {
	isMelaketAgent: 'melaket',
	isMelaketAgentAndCanGoOverQuantities: 'melaketm',
	isMelaketAgentAndCanAddNewItems: 'melaketa',
	isCheckerAgent: 'checker',
	isCheckerAndCanEdit: 'checkerm',
	isLikutManager: 'likutmanager',
	isMekabel: 'mekabel',
} as const;

export const DEFAULT_DOCTYPE_MEKABEL = { num: 11, name: 'קבלה למחסן' } as const;

type LikutMekabelOpened = {
	main: LikutMekabelDisplay;
	extras: LikutMekabelTaskExtra;
};

@Injectable({
	providedIn: 'root',
})
export class LikutService {
	constructor(private _ss: StoreService, private _text: TextService, private router: Router) {
		this.handleLikutInit();
	}
	likutOpenedNewRoutesState: { sub: LikutSubSQL[]; task: LikutDisplayMain } = {
		sub: [],
		task: { clientid: '', clientName: '', status: '0', teudot: {}, totalRow: 0, totalTeudot: 0, address: '', city: '', quantitiesMatch: true },
	};
	likutMekabelOpenedState: LikutMekabelOpened = {
		main: {
			clientid: '',
			clientName: '',
			status: '0',
			rows: [],
			totalRow: 0,
			supplier: {
				name: '',
				id: '',
			},
			warehouse: {
				name: '',
				id: '',
			},
			doctype: { num: null, name: '' },
			refrenceNumber: '',
		},
		extras: { signature: '', image: '', name: '', remark: '' },
	};

	Admin: AdminTypes | null = localStorage.getItem('Admin') as AdminTypes | null;

	likutAdminTypes: Record<typeof LIKUT_ADMIN_TYPES[number], boolean> = LIKUT_ADMIN_TYPES.reduce((p, c) => {
		p[c] = false;
		return p;
	}, {} as Record<typeof LIKUT_ADMIN_TYPES[number], boolean>);

	canSeeManageOrder = false;

	canSeeManageCertainStatuses = false;

	canSeeManageAndAssignLikut = false;

	canSeeManageNotFulfilled = false;

	canSeeDashboard = false;

	canSeeNoStatus = false;

	canSeeAssignUsersKanban = false;

	canSeeTeudaPrice = false;
	showLikutExtra1InParenthesis = false;
	dontShowParenthesisAtAll = false;
	refreshStockOnTaskEntry = false;
	getBarcodeFromSubTable = false;

	private readonly pathEntry = 'mobil/likut';

	isLikutAppActive$ = new BehaviorSubject(false);
	isLikutAppActiveWithAlternateRoutes$ = new BehaviorSubject(false);

	usersForAssignment$ = new BehaviorSubject<Users[]>([]);

	likutMekabelDoctypes$ = new BehaviorSubject<DocTypesNumToName[]>([]);
	likutMekabelWarehouses$ = new BehaviorSubject<MekabelWarehouse[]>([]);

	taskAssigmentPopupDateChange = new EventEmitter<{ task: LikutTaskDisplayWithAssignment; newDate: string }>();
	taskAssigmentPopupAgentChange = new EventEmitter<{ task: LikutTaskDisplayWithAssignment; newAgent: string }>();

	uniqueText: Partial<typeof this._text.store> = {
		homeScreenAction: 'התחל ליקוט',
		mainTasks: `משימות ליקוט`,
		teudaName: `תעודת ליקוט`,
		collected: `לוקט`,
		quantityCollected: { '0': `יחידות שלוקטו`, '0-2': 'אריזות שלוקטו', '0-3': 'מחיר מעודכן', '0-4': '', '1': 'כמות שלוקטה', '2': 'כמות שלוקטה' },
		name: {
			'1': 'שם מלקט',
			'2': 'שם בודק',
			'3': 'שם מטפל',
		},
		returnTask: 'החזרה לליקוט',
		returnTaskConfirmation: 'האם להחזיר לליקוט משימה זו?',
		manageTaskReportTitle: 'היסטוריה הזמנות ליקוט',
		manageCertainStatusReportTitle: 'הזמנות לטיפול',
		manageNotFulfilled: 'לא לוקט',
		manageReportTableHeaders1: ['תעודה', 'שם לקוח', 'שם מלקט', 'תאריך', 'שעה', 'שורות', 'סטטוס', 'אשר', 'בחר מלקט'].map(text => ({ text, element: 'th' })),
		taskUpdatedSuccess: 'משימות ליקוט עודכנו בהצלחה',
		taskAssignmentReport: 'שיוך ליקוטים',
		manageNoStatus: 'הזמנות לליקוט',
	};

	get canGoOverQuantities() {
		return (
			this.likutAdminState(userAdminToValue.isMelaketAgentAndCanGoOverQuantities) ||
			this.likutAdminState(userAdminToValue.isMelaketAgentAndCanAddNewItems) ||
			this.likutAdminState(userAdminToValue.isCheckerAndCanEdit)
		);
	}

	get isMelaketAgent() {
		return this.likutAdminState(userAdminToValue.isMelaketAgent);
	}
	get isMelaketAgentAndCanGoOverQuantities() {
		return this.likutAdminState(userAdminToValue.isMelaketAgentAndCanGoOverQuantities);
	}
	get isMelaketAgentAndCanAddNewItems() {
		return this.likutAdminState(userAdminToValue.isMelaketAgentAndCanAddNewItems);
	}

	get isCheckerAgent() {
		return this.likutAdminState(userAdminToValue.isCheckerAgent);
	}

	get isCheckerAgentAndCanEdit() {
		return this.likutAdminState(userAdminToValue.isCheckerAndCanEdit);
	}

	get isLikutManager() {
		return this.likutAdminState(userAdminToValue.isLikutManager);
	}

	get isMekabel() {
		return this.likutAdminState(userAdminToValue.isMekabel);
	}

	private updateAdminType() {
		this.Admin ??= localStorage.getItem('Admin') as AdminTypes;

		if (!this.Admin) {
			return;
		}

		for (const admin of LIKUT_ADMIN_TYPES) {
			const isAdmin = this.isAdminLowerCase(admin);
			this.likutAdminTypes[admin] = isAdmin;
		}

		this.isLikutAppActive$.next(Object.values(this.likutAdminTypes).some(admin => admin));

		if (this.isLikutAppActive$.value) {
			this.isLikutAppActiveWithAlternateRoutes$.next(true);
		}
	}

	private isAdminLowerCase(value: typeof LIKUT_ADMIN_TYPES[number]) {
		// 'melaketM' includes both melaket AND melaketm
		if (!this.Admin) return false;
		return this.Admin.toLowerCase().includes(value);
	}

	private likutAdminState(propertyToLookup: typeof LIKUT_ADMIN_TYPES[number]) {
		const val = this.likutAdminTypes[propertyToLookup];
		if (!this.isLikutAppActive$.value) {
			this.isLikutAppActive$.next(val);
		}
		return val;
	}

	refreshAllAdminTypes() {
		this.updateAdminType();
	}

	handleSetupCanSeeReportsBasedOnCustomerInformation() {
		if (!this._ss.CustomerInformation?.LikutReportsCanSee) return;

		const reportsSplit: string[] = this._ss.CustomerInformation.LikutReportsCanSee.split(',');

		this.canSeeManageOrder = reportsSplit.includes('0');
		this.canSeeManageCertainStatuses = reportsSplit.includes('1');
		this.canSeeManageAndAssignLikut = reportsSplit.includes('2');
		this.canSeeManageNotFulfilled = reportsSplit.includes('3');
		this.canSeeDashboard = reportsSplit.includes('4');
		this.canSeeNoStatus = reportsSplit.includes('5');
		this.canSeeAssignUsersKanban = reportsSplit.includes('6');
	}

	handleLikutInit() {
		this.refreshAllAdminTypes();
		this.handleSetupCanSeeReportsBasedOnCustomerInformation();
		this.populateLikutExtraParameters();
		if (this.isLikutAppActive$.value) {
			this.replaceTextForLikut();
		}
	}

	populateLikutExtraParameters() {
		if (this._ss.CustomerInformation?.LikutPrice) {
			this.canSeeTeudaPrice = this._ss.CustomerInformation.LikutPrice === '1';
		}
		if (this._ss.CustomerInformation?.LikutRemarkExtra1) {
			this.showLikutExtra1InParenthesis = this._ss.CustomerInformation.LikutRemarkExtra1 === '1';
			this.dontShowParenthesisAtAll = this._ss.CustomerInformation.LikutRemarkExtra1 === '2';
		}

		if (this._ss.CustomerInformation?.LikutRefreshStock) {
			const split = this._ss.CustomerInformation.LikutRefreshStock.split('_');
			this.refreshStockOnTaskEntry = split.includes('1');
		}

		if (this._ss.CustomerInformation?.LikutBarcode) {
			this.getBarcodeFromSubTable = this._ss.CustomerInformation.LikutBarcode === '1';
		}
	}

	private replaceTextForLikut() {
		this.handlePopulateMekabelText();
		this._text.updateTextStore(this.uniqueText);
	}

	buildLikutFinishPayloadBody(formValues: { name: string; remarks: string }, signaturePad: SignaturePad, taskStatus: LikutStatus) {
		const { name, remarks } = formValues;

		let body: LikutFinishBodyNewRoutes[];

		const teudotArray = Object.values(this.likutOpenedNewRoutesState.sub);

		body = teudotArray.map(likut => ({
			table: 'sub',
			TeudaNum: likut.TeudaNum,
			TeudaType: likut.TeudaType,
			ProductId: likut.ProductId,
			LStatus: likut.LStatus === LStatuses.isDuplicated ? LStatuses.isDuplicated : String(Number(taskStatus) + 1),
			LikutPackQty: likut.LikutPackQty || 0,
			LikutQty: likut.LikutQty || 0,
			LikutPrice: likut.LikutPrice || 0,
			TeudaLineNum: likut.TeudaLineNum,
		}));

		const QuantitiesMatch = !teudotArray.some(teuda => teuda.TeudaQuantity !== teuda.LikutQty || teuda.TeudaPackQuantity !== teuda.LikutPackQty);

		const taskBody: LikutFinishBodyNewRoutes[] = Object.values(this.likutOpenedNewRoutesState.task.teudot).map(task => ({
			table: 'main',
			TeudaNum: task.TeudaNum,
			TeudaType: task.TeudaType,
			MelaketName: this.isMelaketAgent ? name || this._ss.UserNameConnected : task.MelaketName,
			CheckerName: this.isCheckerAgent ? name || this._ss.UserNameConnected : '',
			LikutSignature: this.isMelaketAgent ? signaturePad.toDataURL() : task.LikutSignature || '',
			CheckerSignature: this.isCheckerAgent ? signaturePad.toDataURL() : '',
			LikutCheckDate: this.isCheckerAgent ? translateDateForSQLServer().toISOString() : '',
			LikutDate: this.isMelaketAgent ? translateDateForSQLServer().toISOString() : task.LikutDate || translateDateForSQLServer().toISOString(),
			MelaketRemark: this.isMelaketAgent ? remarks : task.MelaketRemark,
			CheckerRemark: this.isCheckerAgent ? remarks : '',
			LikutStatus: String(Number(taskStatus) + 1) as LikutStatus,
			QuantitiesMatch,
		}));

		return body.concat(taskBody);
	}
	buildLikutMekabelFinishPayloadBody(formValues: { name: string; remarks: string }, signaturePad: SignaturePad, taskStatus: LikutKabalaStatus) {
		const { name, remarks } = formValues;

		let body: LikutMekabelFinishBody[];

		const teudotArray = Object.values(this.likutMekabelOpenedState.main.rows);

		body = teudotArray.map(likut => ({
			table: 'sub',
			TeudaNum: likut.TeudaNum,
			TeudaType: likut.TeudaType,
			DiscountOut: likut.DiscountOut || 0,
			Eitur: likut.Eitur || '',
			ProductId: likut.ProductId,
			ItemRemark: likut.ItemRemark || '',
			ItemStatus: String(Number(taskStatus) + 1),
			PriceOut: likut.PriceOut || 0,
			TeudaPackQtyKabala: likut.TeudaPackQtyKabala || 0,
			TeudaQtyKabala: likut.TeudaQtyKabala || 0,
		}));

		const { clientid, doctype, refrenceNumber, status } = this.likutMekabelOpenedState.main;

		const taskBody: LikutMekabelFinishBody = {
			table: 'main',
			TeudaNum: refrenceNumber,
			TeudaType: doctype.num,
			DATAString: JSON.stringify(this.likutMekabelOpenedState),
			ImageFileName: this.likutMekabelOpenedState.extras.image,
			MekabelId: this._ss.AgentIdConnected,
			MekabelName: name || this._ss.UserNameConnected,
			Remark: remarks,
			SignatureString: signaturePad.toDataURL(),
			TeudaKabalaDate: translateDateForSQLServer(new Date()).toISOString(),
			TeudaStatus: String(Number(taskStatus) + 1) as LikutKabalaStatus,
		};

		return body.concat(taskBody);
	}

	buildLikutMekabelFinishPayloadBodyForNewTask(formValues: { name: string; remarks: string; teudaReference: string }, signaturePad: SignaturePad, taskStatus: LikutKabalaStatus) {
		const { name, remarks, teudaReference } = formValues;

		let body: LikutMekabelFinishBodyForUnexistingTask[];

		const teudotArray = Object.values(this.likutMekabelOpenedState.main.rows);

		body = teudotArray.map(likut => ({
			table: 'sub',
			...likut,
			ItemStatus: String(Number(taskStatus) + 1),
		}));

		const { clientid, doctype, refrenceNumber, clientName, warehouse } = this.likutMekabelOpenedState.main;

		const taskBody: LikutMekabelFinishBodyForUnexistingTask = {
			table: 'main',
			TeudaNum: refrenceNumber,
			TeudaType: doctype.num,
			DATAString: JSON.stringify(this.likutMekabelOpenedState),
			ImageFileName: this.likutMekabelOpenedState.extras.image,
			MekabelId: this._ss.AgentIdConnected,
			MekabelName: name || this._ss.UserNameConnected,
			Remark: remarks,
			SignatureString: signaturePad.toDataURL(),
			TeudaKabalaDate: translateDateForSQLServer(new Date()).toISOString(),
			TeudaStatus: String(Number(taskStatus) + 1) as LikutKabalaStatus,
			Client_Id: clientid,
			Client_Name: clientName,
			Currency: '',
			Extra1: '',
			Extra2: '',
			Extra3: '',
			Extra4: '',
			Extra5: '',
			PrintStatus: 0,
			Rate: 0,
			StringNum: '',
			TeudaAgent: this._ss.AgentIdConnected,
			TeudaCompany: 1,
			TeudaDate: translateDateForSQLServer(new Date()).toISOString(),
			TeudaErpErr: '',
			TeudaErpNum: '',
			TotalLines: teudotArray.length,
			Warehouse: warehouse.id,
			Warehouse2: warehouse.id,
			TeudaReference: teudaReference,
		};

		return body.concat(taskBody);
	}

	resetLikutState() {
		this.likutOpenedNewRoutesState = {
			sub: [],
			task: { clientid: '', clientName: '', status: '0', teudot: {}, totalRow: 0, totalTeudot: 0, address: '', city: '', quantitiesMatch: true },
		};

		this.likutMekabelOpenedState = {
			main: {
				clientid: '',
				clientName: '',
				status: '0',
				rows: [],
				totalRow: 0,
				supplier: {
					name: '',
					id: '',
				},
				warehouse: {
					name: '',
					id: '',
				},
				doctype: null,
				refrenceNumber: '',
			},
			extras: { signature: '', image: '', name: '', remark: '' },
		};
	}

	private getLikutUsersForKanban() {
		let path = '';

		path = 'get-all-users-for-assignment-kanban';

		return this._ss.get<Users>(`${this.pathEntry}/${path}`);
	}

	updateLikutUsersForAssignment() {
		this.getLikutUsersForKanban().subscribe({
			next: res => {
				if (typeof res === 'string') {
					return;
				}

				this.usersForAssignment$.next(res.recordset);
			},
		});
	}

	handlePopulateMekabelText() {
		if (!this.isMekabel) return;

		const textOverride: Partial<typeof this.uniqueText> = {
			teudaName: 'תעודת קבלה למחסן',
			quantityCollected: { '0': 'יחידות שהתקבלו', '0-2': 'אריזות שהתקבלו', '0-3': 'הנחה', '0-4': 'מחיר', '1': 'כמות שהתקבלה', '2': 'כמות שהתקבלה' },
			name: {
				'1': 'שם מקבל',
				'2': 'שם בודק',
				'3': 'שם מטפל',
			},
			collected: 'התקבל',
		};

		this.uniqueText = { ...this.uniqueText, ...textOverride };
	}

	async handleFileUploadLikutMekabel(file: File) {
		const fd = new FormData();

		fd.append(file.name, file);

		try {
			const res = await fetch(`${this._ss.CustomerInformation.CustServer}/${this.pathEntry}/upload-camera-picture-mekabel`, {
				method: 'POST',
				body: fd,
			});

			if (!res.ok) {
				const data = await res.json();
				console.log(data);
				return false;
			}

			this.likutMekabelOpenedState.extras.image = file.name;

			console.log('finished fetch');
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	handleUpdateOpenMekabelTaskWithSupplierAndWarehouseAndResolveIfShouldRouteToLikutMekabel(result: NewLikutMekabelWarehouseFormResult | 'cancel' | undefined) {
		if (result === 'cancel' || !result) return;

		const boolean = this.updateOpenMekabelTaskWithSupplierAndWarehouse(result);

		if (boolean) {
			this.router.navigate(['likut', 'tasks', 'mekabel'], { queryParams: { new: true } });
		}
	}

	private updateOpenMekabelTaskWithSupplierAndWarehouse(result: NewLikutMekabelWarehouseFormResult) {
		const { warehouse, supplier } = result;

		this.likutMekabelOpenedState = {
			main: {
				...this.likutMekabelOpenedState.main,
				warehouse: { id: warehouse.WarehouseId, name: warehouse.WarehouseName },
				supplier: { id: supplier.ClientId, name: supplier.Client_Name },
				clientid: supplier.ClientId,
				clientName: supplier.Client_Name,
				doctype: { ...DEFAULT_DOCTYPE_MEKABEL },
				refrenceNumber: `${new Date().getTime().toString()}000${this._ss.AgentIdConnected}`,
			},
			extras: this.likutMekabelOpenedState.extras,
		};

		return true;
	}

	async setupTestingLikutMekabel() {
		this.likutMekabelOpenedState = {
			main: {
				...this.likutMekabelOpenedState.main,
				warehouse: { id: '1', name: 'מחסן 1' },
				supplier: { id: '9007', name: 'סופר אגמים נתניה (שותפות אגמים לקמעונאות גרנות)' },
				clientid: '9007',
				clientName: 'סופר אגמים נתניה (שותפות אגמים לקמעונאות גרנות)',
				doctype: { ...DEFAULT_DOCTYPE_MEKABEL },
				refrenceNumber: `${new Date().getTime().toString()}000${this._ss.AgentIdConnected}`,
			},
			extras: this.likutMekabelOpenedState.extras,
		};

		const products = localStorage.getItem('AllProducts');

		if (!products) {
			try {
				await new Promise<void>((resolve, reject) => {
					this._ss.GetAllProducts().subscribe({
						next: res => {
							if (typeof res === 'string') {
								return reject();
							}

							localStorage.setItem('AllProducts', compress(JSON.stringify(res.recordset)));

							resolve();
						},
					});
				});
			} catch (error) {
				alert('תקלה בשרת, לא עודכנו מוצרים חדשים');
			}
		}

		this.Admin = 'Mekabel';

		this.router.navigate(['likut', 'tasks', 'mekabel'], { queryParams: { new: true } });
	}

	handleGenerateProductDisplayFromAllProducts(input: { product: AllProducts; isDuplicated: boolean }): LikutProductDisplayNewRoute {
		const { TeudaNum, TeudaType } = Object.values(this.likutOpenedNewRoutesState.task.teudot)[0];
		const { product, isDuplicated } = input;
		return {
			opened: true,
			likut: {
				TeudaNum,
				TeudaType,
				TeudaItemRemark: '',
				PicName: product.ItemPicName,
				ProductId: product.ProductId,
				ProductName: product.Product_Name,
				Stock: product.Stock,
				TeudaBonusPackQuantity: 0,
				TeudaBonusQuantity: 0,
				TeudaLineNum: this.likutOpenedNewRoutesState.sub.length + 1,
				TeudaPackQuantity: 0,
				TeudaQuantity: 0,
				TeudaUnitPerPack: product.ItemPack,
				Amount: 0,
				Arizot: 0,
				ItemPack: product.ItemPack,
				LikutExtra1: '',
				LikutExtra2: '',
				LikutPackQty: 0,
				LikutQty: 0,
				LStatus: isDuplicated ? LStatuses.isDuplicated : LStatuses.regular,
				LikutPrice: 0,
			} as LikutSubSQL,
			product,
		};
	}

	getAllKanbanTasks() {
		return this._ss.get<LikutMainSQL>(`${this.pathEntry}/get-all-for-assignment-kanban`);
	}

	assignKanbanTaskToAgent(body: { TeudaNum: string[]; AssignTo: string; LikutStatus?: string }) {
		const LikutStatus = body.AssignTo ? '1' : '0';
		body.LikutStatus = LikutStatus;
		return this._ss.patch(`${this.pathEntry}/set-assignment-agent-kanban`, body);
	}

	assignKanbanTaskLineNum(lines: LikutAssignmentUpdateLineNum[]) {
		const body = { lines };
		return this._ss.patch(`${this.pathEntry}/set-assignment-linenum-kanban`, body);
	}
}
