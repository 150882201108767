<div class="main-wrapper">
	<div class="main-content" [ngClass]="{ 'gray-background': false }">
		<!-- global markup for header -->
		<header class="order-header header">
			<div class="content">
				<div class="header__block row">
					<div class="header__box">
						<div class="header__basket"></div>
					</div>

					<div class="header__burger col image" (click)="handleSaveOrBackButton()">
						<img src="/assets/images/header/back-arrow.svg" alt="burger" /> <span class="back-arrow-text">{{ _text.store.goBack }}</span>
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- order cart gray section -->
		<ng-container *ngIf="!orderProcess; else likutSent">
			<ng-container *ngIf="shouldShowPDFPopup$ | async; else nonPDFBlock">
				<div class="pdf-wrapper">
					<!-- <canvas style="border: 1px black solid; background-color: #fff" *ngIf="shouldShow" id="signature"></canvas> -->
					<app-canvas-popup
						(signatureFinalizedEvent)="updateSignatureState({ signed: true, dataURL: $event })"
						[alreadyHaveSigned]="(signatureState$ | async).signed"
						[pdfSRCInput$]="pdfSRC$"
						[textToRender]="textToRender$ | async"
						(closeEvent)="togglePDFPopup(false)"
					></app-canvas-popup>
					<div class="pdf-viewer-controls-line" *ngIf="moreThanOnePage$ | async">
						<ng-container *ngIf="currentPage$ | async as page">
							<button mat-flat-button (click)="changePage('previous')" [disabled]="page < 2">
								<mat-icon>navigate_before</mat-icon>
							</button>
							<div class="pdf-viewer-controls-line__page-number">{{ page }}</div>
							<button mat-flat-button (click)="changePage('next')" [disabled]="page === (PDFArray$ | async).length">
								<mat-icon>navigate_next</mat-icon>
							</button>
						</ng-container>
					</div>
				</div>
			</ng-container>
			<ng-template #nonPDFBlock>
				<section class="order-cart-gray">
					<div class="content">
						<div class="order-cart-gray__row row">
							<div class="order-cart-gray__title">{{ _text.store.teudaName }} - <span style="color: green">ONLINE</span></div>
							<div class="order-cart-gray__descrip">{{ clientName }}</div>
							<div class="order-cart-gray__descrip" *ngIf="isLikutMekabel">{{ _likut.likutMekabelOpenedState.main.refrenceNumber }}</div>
						</div>
						<div class="order-cart-gray__row row">
							<div class="order-cart-gray__block order-cart-gray__block_double">
								<div class="order-cart-gray__block-title">{{ _text.store.totalProducts }}</div>
								<div class="order-cart-gray__block-number">{{ totalLinesProducts | number : '1.0-2' : 'en-US' }}</div>
							</div>
							<div class="order-cart-gray__block order-cart-gray__block_double">
								<div class="order-cart-gray__block-title">{{ _text.store.totalQuantity }}</div>
								<div class="order-cart-gray__block-number">{{ totalQuantities | number : '1.0-2' : 'en-US' }}</div>
							</div>
						</div>
					</div>
				</section>
				<!-- end order cart gray section -->
				<!-- order step section -->
				<section class="order-step">
					<div class="content">
						<div class="order-step__row row">
							<div class="order-step__item"></div>
							<div class="order-step__block">
								<form [formGroup]="form">
									<div class="order-step__block-row">
										<ng-container *ngIf="isTruckLoading; else inputNameBlock">
											<div class="order-step__block-row-title">{{ _ss.UserNameConnected }}</div>
										</ng-container>
										<ng-template #inputNameBlock>
											<div class="order-step__block-row-title">{{ _text.store.name[taskStatus] }}</div>
											<input class="order-step__block-row-input" [placeholder]="_ss.UserNameConnected" formControlName="name" type="text" />
										</ng-template>
									</div>
									<div class="order-step__block-row" *ngIf="isTruckLoading">
										<ng-container *ngIf="_truckLoading.isBothMaamisAndNahag; else deliveryLineSelectBlock">
											<div class="order-step__block-row-title">{{ _truckLoading.deliveryLineChosen.Name }}</div>
										</ng-container>
										<ng-template #deliveryLineSelectBlock>
											<div class="order-step__block-row-title">{{ _text.store.deliveryLine }}</div>
											<form [formGroup]="form">
												<mat-select dir="rtl" formControlName="deliveryLine">
													<mat-option *ngFor="let line of _truckLoading.truckDeliveryLines$ | async as list" [value]="line.UserID">{{ line.UserID }} - {{ line.Name }}</mat-option>
												</mat-select>
											</form>
										</ng-template>
									</div>

									<ng-container *ngIf="isLikutMekabel">
										<div class="order-step__block-row">
											<div class="order-step__block-row-title">הוסף תמונה</div>
											<input
												class="order-step__block-row-input"
												[ngClass]="{ success: likutMekabelImageUploaded }"
												id="likut-mekabel-image"
												type="file"
												accept="image/*"
												capture="camera"
												(change)="setImage($event, 0)"
											/>
										</div>
										<div class="order-step__block-row">
											<div class="order-step__block-row-title">אסמכתא</div>
											<input class="order-step__block-row-input" formControlName="teudaReference" type="text" />
											<span class="invalid-color" *ngIf="form.controls.teudaReference?.invalid && form.controls.teudaReference?.touched">שדה חובה</span>
										</div>
										<ng-container *ngIf="_likut.likutMekabelDoctypes$ | async as list">
											<div class="order-step__block-row" *ngIf="list.length > 1; else onlyOnceDoctypeBlock">
												<div class="order-step__block-row-title">בחירת תעודה</div>
												<mat-select dir="rtl" formControlName="doctype">
													<mat-option *ngFor="let doctype of list" [value]="doctype.DocType">{{ doctype.DocTypeName }}</mat-option>
												</mat-select>
												<mat-error *ngIf="form.controls.doctype?.invalid && form.controls.doctype?.touched">שדה חובה</mat-error>
											</div>
											<ng-template #onlyOnceDoctypeBlock>
												<div class="order-step__block-row">
													<div class="order-step__block-row-title">{{ list[0].DocTypeName }}</div>
												</div>
											</ng-template>
										</ng-container>
									</ng-container>
									<div class="order-step__block-row" *ngIf="(isTruckLoading || isTruckDistribution) && _truckLoading.howManyPicturesCanAttach">
										<div class="order-step__block-row-title">בחר תמונות לצרף</div>
										<input
											*ngFor="let number of imageArray"
											class="order-step__block-row-input"
											[ngClass]="{ success: images['ImageName' + number] }"
											[attr.id]="'ImageName' + number"
											type="file"
											accept="image/*"
											capture="camera"
											(change)="setImage($event, number)"
										/>
									</div>
									<ng-container *ngIf="isTruckDistribution" formArrayName="extras">
										<div class="order-step__block-row" *ngFor="let extra of _truckDistribution.extrasDisplay$ | async; index as i">
											<div class="order-step__block-row-title">{{ extra.HebName }}<ng-container *ngIf="extra.MustFill">*</ng-container></div>
											<input
												class="order-step__block-row-input"
												[ngClass]="{ error: form.controls.extras.controls[i]?.invalid && form.controls.extras.controls[i]?.touched }"
												[formControlName]="i"
												type="text"
											/>
											<span class="invalid-color" *ngIf="form.controls.extras.controls[i]?.invalid && form.controls.extras.controls[i]?.touched">שדה חובה</span>
										</div>
									</ng-container>
									<div class="order-step__block-row">
										<div class="order-step__block-row-title">{{ _text.store.remarks }}</div>
										<input class="order-step__block-row-input" formControlName="remarks" type="text" />
									</div>
									<div class="text-align-center position-relative" *ngIf="isLikut">
										<div class="order-step__block-row-title zoom-on-tablet">{{ _text.store.signature }}</div>
										<canvas style="border: 1px black solid; background-color: #fff" [width]="windowWidth / 2" id="signature"></canvas>
										<span class="order-step__block-row-delete absolute zoom-on-tablet" (click)="signaturePad.clear()"></span>
									</div>
									<div class="flex gap justify-between pb-6" *ngIf="!isLikut">
										<!-- <button class="order-step__block-button eye-icon" *ngIf="(pdfSRC$ | async) && _truckDistribution.parametersRecord.showPDF" (click)="togglePDFPopup()">הצג מסמך</button> -->
										<div class="order-step__block-button no-before justify-align-center" (click)="togglePDFPopup(true)" style="display: flex">
											<div class="signature-status justify-align-center">
												<ng-container *ngIf="signatureState$ | async as signatureState">
													<span class="justify-align-center gap" *ngIf="!signatureState.signed; else signatureOKBlock">
														חתימה
														<mat-icon color="primary">error</mat-icon>
													</span>
													<ng-template #signatureOKBlock>
														<span class="justify-align-center gap">
															חתימה
															<mat-icon class="force-valid-color">done</mat-icon>
														</span>
													</ng-template>
												</ng-container>
											</div>
										</div>
									</div>
									<div class="flex gap justify-align-center">
										<button class="order-step__block-button" (click)="handleSaveButton()">{{ _text.store.save }}</button>
										<button class="order-step__block-button order-step__block-button_green" (click)="decideWhichMethodToUseForSend()">{{ _text.store.send }}</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</ng-template>
		</ng-container>
		<!-- end order step section -->

		<!-- order process -->
		<ng-template #likutSent>
			<section class="order-process" *ngIf="orderProcess === true">
				<div class="container">
					<div class="order-process__row order-process__row_success">
						<div class="row">
							<h2 class="order-process__title">{{ _text.store.processSuccessful[0] }}</h2>
						</div>
						<div class="row">
							<div class="order-process__descrip">{{ _text.store.processSuccessful[1] }}</div>
						</div>
						<div class="row">
							<div class="order-process__image image">
								<img src="/assets/images/basket/process/save.gif" alt="photo" />
							</div>
						</div>
						<button class="order-step__block-button order-step__block-button_green" (click)="backToLikutClients()">{{ _text.store.backToLikut }}</button>
					</div>
				</div>
			</section>
		</ng-template>
	</div>
</div>

<div class="popup" *ngIf="isMismatchedQuantityPopupOpen$ | async">
	<div class="overlay"></div>
	<div class="order-cart__popup popup__content">
		<div class="popup__close" (click)="isMismatchedQuantityPopupOpen$.next(false)"></div>
		<div class="order-cart__popup-image image">
			<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
		</div>
		<h2 class="order-cart__popup-title">{{ _text.store.mismatchedQuantityErrorMessage }}</h2>
		<div class="order-cart__popup-block row">
			<button class="order-cart__popup-button" style="background: var(--invalid-color); color: var(--secondary-color)" (click)="isMismatchedQuantityPopupOpen$.next(false)">
				{{ _text.store.declineReturn }}
			</button>
			<button class="order-cart__popup-button" (click)="decideWhichMethodToUseForSend()" style="background-color: #38d88b">{{ _text.store.confirmContinue }}</button>
		</div>
	</div>
</div>
