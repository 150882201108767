<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="handleBackArrow()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ריכוז חובות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="report-table__row report-table__row_between">
			<div class="fixed-header">
				<div class="box flex-column justify-align-center" style="border: none">
					<div class="report-table-popup__label-title">סה"כ חובות</div>
					<strong dir="ltr">{{ _debtByDate.allDebtsSum$ | async | number : '1.0-2' : 'en-US' }}</strong>
				</div>
				<mat-divider vertical="true"></mat-divider>
				<div class="box" style="border: none">
					<mat-button-toggle-group name="byDate" [formControl]="_debtByDate.debtDateControl">
						<mat-button-toggle value="by-pay" [ngClass]="{ active: _debtByDate.debtDateControl.value === 'by-pay' }">פרעון</mat-button-toggle>
						<mat-button-toggle value="by-create" [ngClass]="{ active: _debtByDate.debtDateControl.value === 'by-create' }">יצירה</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
				<div class="box gap-xs" *ngIf="_debtByDate.hasMoreThanOneCompany">
					<mat-form-field dir="rtl" style="text-align: right">
						<mat-label>חברה</mat-label>
						<mat-select [formControl]="_debtByDate.companyControl">
							<mat-option *ngFor="let company of _debtByDate.companies" [value]="company.Company">{{ company.CompanyName }} </mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="box gap-xs" *ngIf="Second">
					<span>תקופה:</span> <span dir="ltr">{{ _debtByDate.selectedDate.mm }}-{{ _debtByDate.selectedDate.yy }}</span>
				</div>
			</div>
		</div>

		<ng-container *ngIf="(_loading.isLoading$ | async) === false; else loadingBlock">
			<table class="report-table__table" cellspacing="0" *ngIf="First">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 60px" (click)="sort('Client_Name', 'AllClients')">תקופה</th>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="sort('COD', 'AllClients')">חוב</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let date of _debtByDate.allDebtsByDate$ | async" class="clickable" #tableRow (click)="handleClickOnDate(date)">
						<td class="report-table__table-body-quantity date" dir="ltr">{{ date.date }}</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ date.tothov | number : '1.0-2' }}</td>
					</tr>
				</tbody>
			</table>
			<table class="report-table__table" cellspacing="0" *ngIf="Second">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 120px" (click)="sort('Client_Name', 'AllClients')">לקוח</th>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="sort('COD', 'AllClients')">חוב</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ac of _debtByDate.allClientsByDebt$ | async" class="clickable" #tableRow (click)="handleClickOnClient(ac)">
						<td class="report-table__table-body-quantity date">{{ ac.name }}</td>
						<td class="report-table__table-body-quantity" dir="ltr">{{ ac.tothov | number : '1.0-2' }}</td>
					</tr>
				</tbody>
			</table>
		</ng-container>
		<ng-template #loadingBlock>
			<h2>טוען...</h2>
		</ng-template>
	</div>
</section>
