import { Component, OnInit, OnDestroy, Input, Output, LOCALE_ID, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { ToNormalNumberPipe } from '../../Pipes/to-normal-number.pipe';
import { BehaviorSubject, combineLatest, filter, map, mergeMap, Observable, of, reduce, startWith, Subject, take, takeUntil } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { NewReceiptBody, NewReceiptBodyWithClientName, ReceiptArray } from 'src/app/Interfaces/new-receipt-body';
import { compress, decompress } from 'lz-string';
import { Time, UtilService } from 'src/app/services/util.service';
import { BanksList, HovotHeaders } from 'src/app/Interfaces/SQL-reports-responses';
import { normalizeNumber, normalizeNumberToFormattedString, translateDateForSQLServer } from 'src/app/Utility/functions';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { CreditFormValues } from '../credit-card-payment/credit-card-payment.component';
import { BackendCreditTerms, CreditParameters, RecieiptSuccessResponse } from 'src/app/Interfaces/credit-payment';

export type ClosePopupValues = 'close' | 'error';
export type EmailPopupState = 'open' | 'close';

type DebtArray = {
	DebtDocNum: number;
	DebtDocOpenToPay: number;
	debtObject: HovotHeaders;
};

type Branches = { Branch_Name: string; Branch_Code: number };

type Banks = { Bank_Name: string; Bank_Code: number; branches: Branches[] };

type BanksRecord = Record<string, Banks>;

@Component({
	selector: 'app-new-receipt',
	templateUrl: './new-receipt.component.html',
	styleUrls: ['./new-receipt.component.scss'],
	providers: [{ provide: LOCALE_ID, useValue: 'en-IL' }],
})
export class NewReceiptComponent implements OnInit, OnDestroy {
	constructor(
		public _cc: CreditCardService,
		public _ss: StoreService,
		private _fb: FormBuilder,
		private _date: DateAdapter<any, 'he-IL'>,
		public _receipts: ReceiptsService,
		private _util: UtilService,
	) {}

	@Input() TotalSumDebtChecked: number;
	@Input() DebtArray: DebtArray[];
	@Output() closePopup = new EventEmitter<ClosePopupValues>();

	refrenceNums;

	totalLeftToPay: number;

	receiptDocNum = new Date().getTime() + '00032' + this._ss.AgentIdConnected;

	form = this._fb.group({
		remarks: '',
		cash: ['', [Validators.min(0), Validators.max(1_000_000)]],
	});

	OneClientInfo = this._ss.OneClientInfo;

	windowWidth = innerWidth;

	allBanks$ = new BehaviorSubject<BanksList[]>([]);
	allBanksRecord$ = new BehaviorSubject<BanksRecord>({});
	branchesDisplay$ = new BehaviorSubject<Branches[]>([]);
	filteredBanks$: Observable<BanksRecord[string][]>;
	filteredBranches$: Observable<Branches[]>;

	banksDisplay$ = new BehaviorSubject<BanksRecord[string][]>([]);
	totalPaid = 0;
	totalChequesPaid = 0;

	mainScreen = true;
	newCredit = false;

	isEdittingCheque = false;

	year = 31_104_000_000;

	dates = { min: new Date(new Date().getTime() - Time.day * 2 - this.year / 2), max: new Date(new Date().getTime() + this.year * 10) }; // 10 years max

	farthestDebtDate: Date;

	numReqControl = <TControl = number>(...validators: ValidatorFn[]) => new FormControl<TControl>(null, { validators: [Validators.required, Validators.min(1), ...validators] });

	chequeForm = new FormGroup({
		bank: this.numReqControl(this.validateBank.bind(this)),
		branch: this.numReqControl(this.validateBranch.bind(this)),
		accountNumber: this.numReqControl(),
		chequeNumber: this.numReqControl(),
		amount: this.numReqControl(Validators.max(1_000_000)),
		remark: new FormControl<string>('', { nonNullable: true }),
		dueDate: new FormControl<Date>(new Date(), {
			nonNullable: true,
			validators: [Validators.min(this.dates.min.getTime()), Validators.max(this.dates.max.getTime())],
		}),
	});

	destroy$ = new Subject<void>();

	isSendingReceipt = false;
	isSpecialTimeoutError = false;

	canPayWithCreditCard = this._receipts.canPayWithCreditCard;
	isEdittingExistingCard = false;
	canProduceReceiptWithoutFullPaymentWithCreditCard = false;

	ngOnInit(): void {
		this._date.setLocale('he-IL');

		if (this._receipts.useBanksList) {
			const ls = localStorage.getItem('Banks');

			if (ls) {
				this.allBanks$.next(JSON.parse(decompress(ls)));
				// this.chequeForm.controls.bank.setValidators([]);
				// this.chequeForm.controls.bankSelect.setValidators(Validators.required);
			} else {
				this._receipts.getBanksList().subscribe({
					next: res => {
						if (typeof res === 'string') {
							return;
						}

						this.allBanks$.next(res.recordset);
						// this.chequeForm.controls.bank.setValidators([]);
						// this.chequeForm.controls.bankSelect.setValidators(Validators.required);
					},
					error: () => {},
				});
			}
		}

		if (this.canPayWithCreditCard) {
			const parameters = this._cc.parameters$.value;
			if (this._cc.hasParameters(parameters)) {
				this.canProduceReceiptWithoutFullPaymentWithCreditCard = parameters.canProduceReceiptWithoutFullPayment;
			} else {
				this.canPayWithCreditCard = false;

				this._cc.refreshParameters$.subscribe({
					next: newParams => {
						this.canPayWithCreditCard = true;
						this.canProduceReceiptWithoutFullPaymentWithCreditCard = newParams.canProduceReceiptWithoutFullPayment;
					},
					error: () => {
						alert('לא ניתן להשתמש בכרטיס אשראי בשל תקלת תקשורת, אנא נסה שוב מאוחר יותר');
						this.canPayWithCreditCard = false;
					},
				});
			}
		}

		this.totalLeftToPay = this.DebtArray.length ? this.TotalSumDebtChecked : 0;

		this.setupFormInteractivity();

		this._receipts.refreshReceiptsParameters();

		if (this.DebtArray.length) {
			this.refrenceNums = this.DebtArray.map(deb => deb.debtObject.DebtDocNum).join(', ') + '.';
		}

		if (this.OneClientInfo.CustAccountNum) {
			this.chequeForm.controls.accountNumber.setValue(this.OneClientInfo.CustAccountNum);
		}

		if (this.OneClientInfo.CustBank && !this.allBanks$.value.length) {
			this.chequeForm.controls.bank.setValue(this.OneClientInfo.CustBank);
		}

		if (this.OneClientInfo.CustBranch) {
			this.chequeForm.controls.branch.setValue(this.OneClientInfo.CustBranch);
		}

		if (this._receipts.alertIfChequeIsFartherThanClosestDebt) {
			this.farthestDebtDate = new Date(Math.max(...this.DebtArray.map(debt => new Date(debt.debtObject.DebtDocToPayDate).getTime())));
		}

		console.log(this.TotalSumDebtChecked);
	}

	ngOnDestroy(): void {
		document.body.classList.remove('no-scroll');

		this.destroy$.next();
		this.destroy$.complete();
	}

	setupFormInteractivity() {
		this.pipeADestroyer(this.form.controls.cash.valueChanges).subscribe(() => {
			this.renewTotals();
		});

		this.pipeADestroyer(this.allBanks$).subscribe(allBanks => {
			this.allBanksRecord$.next(
				allBanks.reduce((p: BanksRecord, c: BanksList) => {
					p[c.Bank_Code] ||= { Bank_Code: c.Bank_Code, Bank_Name: c.Bank_Name, branches: [] };
					if (c.Branch_Code !== 0) {
						p[c.Bank_Code].branches.push({ Branch_Code: c.Branch_Code, Branch_Name: c.Branch_Name });
					}
					return p;
				}, {}),
			);
		});

		this.pipeADestroyer(this.chequeForm.controls.bank.valueChanges).subscribe(bankCode => {
			if (!this._receipts.useBanksList) return;
			console.log(bankCode);
			this.chequeForm.controls.branch.reset();
			if (!bankCode) {
				this.branchesDisplay$.next([]);
				return;
			}
			const branches = this.allBanksRecord$.value[bankCode]?.branches || [];
			this.branchesDisplay$.next(branches.sort((a, b) => +a.Branch_Code - +b.Branch_Code));
		});

		this.pipeADestroyer(this.allBanksRecord$).subscribe(record => {
			if (record) {
				this.banksDisplay$.next(Object.values(record).sort((a, b) => +a.Bank_Code - +b.Bank_Code));
			}
		});

		this.filteredBanks$ = this.chequeForm.controls.bank.valueChanges.pipe(
			startWith(''),
			map(value => this._filter<BanksRecord[string]>(value || '', this.banksDisplay$.value, 'Bank_Code')),
		);

		this.filteredBranches$ = this.chequeForm.controls.branch.valueChanges.pipe(
			startWith(''),
			map(value => this._filter<Branches>(value || '', this.branchesDisplay$.value, 'Branch_Code')),
		);
	}

	private _filter<T>(value: string | number, array: T[], key: keyof T extends string ? keyof T : never): T[] {
		const filterValue = String(value).toLowerCase();

		return array.filter(item => String(item[key]).toLowerCase().includes(filterValue));
	}

	newCheque(val: boolean) {
		this.banksDisplay$.pipe(take(1)).subscribe(val => console.log(val));
		this.mainScreen = val;
		if (this._receipts.chequesArray.length) {
			const lastCheque = this._receipts.chequesArray[this._receipts.chequesArray.length - 1];

			this.chequeForm.setValue({
				bank: lastCheque.bank,
				branch: lastCheque.branch,
				accountNumber: lastCheque.accountNumber,
				chequeNumber: Number(lastCheque.chequeNumber) + 1,
				remark: '',
				dueDate: new Date(),
				amount: null,
			});
		}
		if (this.isEdittingCheque) {
			this.chequeForm.reset();
			this.chequeIndex = -1;
			this.isEdittingCheque = false;
		}
	}

	newCreditCard(moveTo: 'main' | 'form') {
		this.renewTotals();
		if (moveTo === 'main') {
			this.mainScreen = true;
			this.newCredit = false;
			return;
		}

		if (this._cc.hasAnyFormValues()) {
			this.isEdittingExistingCard = true;
		}

		this.mainScreen = false;
		this.newCredit = true;
	}

	handleCreditCardFormReset() {
		this._cc.resetFormValues();
		this.renewTotals();
		this.isEdittingExistingCard = false;
	}

	fillInputWithRest(val: 'cash' | 'amount') {
		if (val === 'cash') {
			const { cash } = this.form.controls;
			cash.setValue((+cash.value || 0) + +this.totalLeftToPay + '');
		}
		if (val === 'amount') {
			const { amount } = this.chequeForm.controls;
			amount.setValue(this.totalLeftToPay);
		}
	}

	handleBack(val: 'main' | 'cheque') {
		if (val === 'main') {
			this.closePopup.emit('close');
			return;
		}
		if (val === 'cheque') {
			this.newCheque(true);
			return;
		}
	}

	formatNumber(val: 'cash' | 'amount') {
		const { cash } = this.form.controls;
		const { amount } = this.chequeForm.controls;

		if (val === 'cash' && cash.valid) {
			cash.setValue(new ToNormalNumberPipe().transform(cash.value));
		}

		if (val === 'amount' && amount.valid) {
			amount.setValue(+new ToNormalNumberPipe().transform(amount.value));
		}
	}

	handleChequeSubmit() {
		if (this.chequeForm.invalid) {
			this.chequeForm.markAllAsTouched();
			return;
		}
		if (this.isEdittingCheque) {
			this.handleExistingChequeEdit();
			return;
		}

		const { value } = this.chequeForm;
		if (
			this._receipts.chequesArray.length &&
			!!this._receipts.chequesArray.find(chq => value.accountNumber === chq.accountNumber && value.bank === chq.bank && value.branch === chq.branch && value.chequeNumber === chq.chequeNumber)
		) {
			alert('המחאה זו כבר קיימת ברשימה');
			return;
		}

		this._receipts.chequesArray.push({ ...value } as Required<typeof value>);

		this.renewTotals();

		this.chequeForm.reset();

		this.mainScreen = !this.mainScreen;
	}

	deleteCheque(i: number) {
		const decision = confirm('האם אתה בטוח שתרצה למחוק המחאה זו? הפעולה אינה הפיכה');
		if (!decision) return;
		this._receipts.chequesArray.splice(i, 1);

		this.renewTotals();

		this.handleBack('cheque');
	}

	handleExistingChequeEdit() {
		// after form is valid
		const { value } = this.chequeForm;
		this._receipts.chequesArray[this.chequeIndex] = value as Required<typeof value>;

		this.renewTotals();
		this.newCheque(true);
	}

	renewTotals() {
		const chequesTotal = this._util.reduceArrayToNumber(this._receipts.chequesArray, 'amount');

		this.totalLeftToPay = this.totalLeftToPay === 0 ? 0 : this.totalLeftToPay - chequesTotal;

		this.totalPaid = chequesTotal;
		this.totalChequesPaid = chequesTotal;

		const { cash } = this.form.controls;

		if (cash.valid) {
			this.totalPaid = +cash.value + chequesTotal;
			this.totalLeftToPay = this.DebtArray.length ? this.TotalSumDebtChecked - this.totalPaid : 0;
		}

		if (this._cc.hasAnyFormValues()) {
			this.totalPaid += +this._cc.creditCardFormValues$.value.amount;
			this.totalLeftToPay = this.DebtArray.length ? this.TotalSumDebtChecked - this.totalPaid : 0;
		}
	}

	chequeIndex = -1;

	chipClicked(index: number) {
		this.chequeIndex = index;
		const cheque = this._receipts.chequesArray[this.chequeIndex];
		this.chequeForm.setValue({ ...cheque });

		this.isEdittingCheque = true;

		this.mainScreen = !this.mainScreen;
	}

	handleReceiptSubmitProcess() {
		// TODO refactor this implementation.
		// Email popup should be self containing, independant component, that emits email to relevant listeners
		// de-tangle email popup from client-hovot
		if (!this.checkIfLeftToPay()) return;

		if (!this.checkIfDebtIsCloserThanAllCheques()) {
			alert(`תאריך הצ'קים רחוק יותר מתאריכי החוב`);
			if (this._receipts.blockIfChequeIsFartherThanClosestDebt) return;
		}

		if (!this._receipts.handleEmailCheckAndEmitPopupOpenIfNeedBe()) {
			this._receipts.emailOkToContinue$
				.pipe(
					filter(val => val),
					take(1),
				)
				.subscribe(val => {
					console.log('object');
					this.handlePostNewReceipt();
				});
			return;
		}

		this.handlePostNewReceipt();
	}

	checkIfLeftToPay(): boolean {
		if (this.totalLeftToPay === 0) return true;

		if (this._receipts.totalDebtMustBePaid) {
			alert('סכום התשלום חייב להיות זהה לסכום החובות שסומנו');
			return false;
		}

		const confirm = window.confirm('הוקש סכום שונה מסך כל החובות. האם להמשיך בהפקת הקבלה?');
		return confirm;
	}

	checkIfDebtIsCloserThanAllCheques(): boolean {
		if (!this._receipts.alertIfChequeIsFartherThanClosestDebt || !this._receipts.chequesArray.length || !this.farthestDebtDate) return true;

		if (this.farthestDebtDate.getTime() < Math.min(...this._receipts.chequesArray.map(cheque => new Date(cheque.dueDate).getTime()))) return false;

		return true;
	}

	handleGetEmail(): boolean {
		const hasAgentProvidedOrChoseEmail = !this._receipts.canProvideEmail || typeof this._receipts.emailForReceipt === 'string';
		return hasAgentProvidedOrChoseEmail;
	}

	async handlePostNewReceipt() {
		if (this.isSendingReceipt) return;

		this.isSendingReceipt = true;
		const { cash } = this.form.controls;

		let totalCash = 0;

		if (cash.valid) {
			totalCash += +cash.value;
		}

		const chequesTotal = this._util.reduceArrayToNumber(this._receipts.chequesArray, 'amount');

		const creditTotal = +this._cc.creditCardFormValues$.value.amount || 0;

		const DocTotal = totalCash + chequesTotal + creditTotal;

		let DocRemark = this.form.controls.remarks.value || '';

		if (!this.checkIfDebtIsCloserThanAllCheques()) {
			DocRemark += '**';
		}

		const today = new Date(new Date().getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

		const receiptArray: ReceiptArray[] = [];

		const body: NewReceiptBody = {
			DocNum: this.receiptDocNum,
			CreateDate: today,
			DocTotal,
			ClientID: this.OneClientInfo.ClientId,
			AgentID: this._ss.AgentIdConnected,
			Company: this._ss.CompanyNum || 1,
			ClientEmail: this._receipts.emailForReceipt || '',
			TeudaStatus: this._receipts.adminCanDeleteAndEditReceipt ? 2 : 0,
			receiptArray,
		};

		body.receiptArray.push({
			Sug: 1,
			PayRemark: '',
			DocRemark,
			CheckNum: '0',
			Bank: '0',
			Snif: '0',
			AccountNum: '0',
			PayDate: today,
			PayTotal: totalCash,
			HeshNum: '0',
			HeshType: '0',
			HexhSUm: 0,
		});

		for (const chq of this._receipts.chequesArray) {
			body.receiptArray.push({
				Sug: 2,
				PayRemark: chq.remark,
				DocRemark,
				CheckNum: chq.chequeNumber + '',
				Bank: chq.bank + '',
				Snif: chq.branch + '',
				AccountNum: chq.accountNumber + '',
				PayDate: translateDateForSQLServer(chq.dueDate),
				PayTotal: chq.amount,
				HeshNum: '0',
				HeshType: '0',
				HexhSUm: 0,
			});
		}

		for (const deb of this.DebtArray) {
			body.receiptArray.push({
				Sug: 3,
				PayRemark: '',
				DocRemark,
				CheckNum: '0',
				Bank: '0',
				Snif: '0',
				AccountNum: '0',
				PayDate: today,
				PayTotal: 0,
				HeshNum: deb.debtObject.DebtDocNum + '',
				HeshType: deb.debtObject.DebtDocType + '',
				HexhSUm: deb.debtObject.DebtDocOpenToPay,
			});
		}

		if (this._cc.hasAnyFormValues()) {
			const PayTotal = +normalizeNumberToFormattedString(creditTotal, { maximumFractionDigits: 3, minimumFractionDigits: 0, useGrouping: false });

			body.receiptArray.push({
				Sug: 4,
				PayRemark: '',
				DocRemark,
				CheckNum: '0',
				Bank: '0',
				Snif: '0',
				AccountNum: '0',
				PayDate: today,
				PayTotal,
				HeshNum: '0',
				HeshType: '0',
				HexhSUm: 0,
			});
		}

		if (this._cc.hasAnyFormValues()) {
			const { CVV, amount, creditCardID, expiry, number, payType, payments } = this._cc.creditCardFormValues$.value;
			const creditBody = {
				...body,
				CreditTerms: BackendCreditTerms[payType.toUpperCase()],
				Expiry: { MM: expiry.month, YY: expiry.year },
				PAN: number,
				Payments: payments,
				TransSum: amount,
				CVV2: CVV,
				ID: creditCardID,
			};
			try {
				const response = await this._cc.handlePostNewReceiptWithCredit(creditBody);

				const shouldCountError = response.msg !== 'Receipt already exists' && response.msg !== 'Receipt already paid with different amount';

				if (response.msg === 'request timed out') {
					this.isSendingReceipt = false;
					this._receipts.handleFailedCreditCardPaymentAfterTimeout(response.payload);
					return;
				}

				if (response.err && shouldCountError) {
					this.isSendingReceipt = false;
					alert(response.msg);
					console.error(creditBody);
					return;
				}

				const payload = response.payload as RecieiptSuccessResponse['extraResponse'];

				this._receipts.lastAuthManpikNo = payload.authManpikNo;
				this._receipts.lastShovarNum = payload.shovarNum;

				this.handleReceiptSubmitSuccess();
			} catch (error) {
				this.isSendingReceipt = false;
				alert('לא ניתן לשלוח קבלה זו כרגע, אנא נסה שוב מאוחר יותר');
				console.error(error);
			}
			return;
		}
		let arr = [];

		const previousReceipts = localStorage['receiptsToSend'];

		if (previousReceipts) {
			const parsed = JSON.parse(decompress(previousReceipts));
			arr.push(...parsed);
		}

		this._receipts.postNewReceipt(body).subscribe({
			next: () => {
				this.handleReceiptSubmitSuccess();
			},
			error: err => {
				this.isSendingReceipt = false;
				if (err.msg === 'duplicate docnum detected') {
					this._receipts.chequesArray = [];
					this._receipts.lastDocnumSent = this.receiptDocNum;
					this._receipts.receiptSent = true;
					return;
				}

				const latestPreviousReceipts = localStorage['receiptsToSend'];

				if (latestPreviousReceipts) {
					const parsed: NewReceiptBodyWithClientName[] = JSON.parse(decompress(latestPreviousReceipts));

					arr = [...parsed.filter(parsd => parsd.DocNum !== this.receiptDocNum)];
				}

				console.log([...arr, body]);

				if (!this._cc.hasAnyFormValues()) {
					localStorage['receiptsToSend'] = compress(JSON.stringify([...arr, { ...body, Client_Name: this.OneClientInfo.Client_Name }]));
				}

				this.closePopup.emit('error');
			},
		});
	}

	handleReceiptSubmitSuccess() {
		this._receipts.receiptSent = true;
		this._receipts.chequesArray = [];
		this._receipts.lastDocnumSent = this.receiptDocNum;
		this.isSendingReceipt = false;
		this._cc.resetFormValues();
	}

	validateBank(control: FormControl) {
		if (this._receipts.useBanksList && !this.allBanksRecord$.value[control.value]) {
			return { noBank: true };
		}
		return null;
	}

	validateBranch(control: FormControl) {
		if (this._receipts.useBanksList && this.branchesDisplay$.value.length && !this.branchesDisplay$.value.find(branch => branch.Branch_Code === +control.value)) {
			return { noBranch: true };
		}
		return null;
	}

	handleCreditCardSubmit(payload: { status: 'cancel' } | { status: 'ok'; value: any }) {
		if (payload.status === 'cancel') {
			this.newCreditCard('main');
			return;
		}

		if (payload.status === 'ok') {
			console.log(payload.value);
			this._cc.creditCardFormValues$.next(payload.value);
			this.newCreditCard('main');
		}
	}

	pipeADestroyer<T>(observableToPipe: Observable<T>) {
		return this._util.pipeASubjectDestoryer(observableToPipe, this.destroy$);
	}
}
