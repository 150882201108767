<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToAdmin()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">{{ title$ | async }}</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="showSearch$ | async">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" [ngClass]="{ 'margin-top': (pageMode$ | async) === 'daily-sales', 'margin-top-extra': (pageMode$ | async) === 'planned-vs-unplanned' }">
	<div class="content">
		<form [formGroup]="form" dir="rtl">
			<div class="row flex gap flex-wrap">
				<ng-container *ngIf="(_visits.isViewingSpecificAgentAndStatus$ | async) === false">
					<mat-form-field color="primary" style="max-width: 30%">
						<mat-label>לפי תאריך</mat-label>
						<input readonly="true" formControlName="Date" (dateChange)="ChooseDate()" matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilterFunction" (click)="picker.open()" />
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button *ngIf="!isVisitsPlannedVsUnplanned" (click)="ResetDate()" style="height: 50%; align-self: center" color="primary">כל המכירות</button>
				</ng-container>
				<app-excel-button></app-excel-button>
				<div style="margin-top: 10px; font-size: 15.5px; font-weight: 425" *ngFor="let number of [1, 2, 3]">
					<ng-container [ngSwitch]="number">
						<ng-container *ngSwitchCase="1"> סה"כ: {{ totalSum$ | async | number : '1.0-2' : 'en-US' }} </ng-container>
						<ng-container *ngSwitchCase="2">{{ ((Second || Third) && currentlyViewedAgent && '/ ' + currentlyViewedAgent) || '' }}</ng-container>
						<ng-container *ngSwitchCase="3">{{ (Third && currentlyViewedClientTeuda && '/ ' + currentlyViewedClientTeuda) || '' }}</ng-container>
					</ng-container>
				</div>
			</div>
			<!-- <div class="row flex gap">
        <div class="box" style="max-height:60px">
					<form [formGroup]="form" style="width: 150px">
						<ng-multiselect-dropdown [placeholder]="'סנן סוכנים'" [settings]="dropdownSettings"
							[data]="dropdownList" formControlName="agents" [(ngModel)]="form.controls.agents"
							(onSelect)="handleAdvancedSearch('single')" (onDeSelect)="handleAdvancedSearch('single')"
							(onSelectAll)="handleAdvancedSearch('all')" (onDeSelectAll)="handleAdvancedSearch('clear')">
						</ng-multiselect-dropdown>
					</form>
				</div>
      </div> -->
		</form>

		<!-- <form [formGroup]="form" (change)="ChooseDate()" style="margin-top: 10px;">
      <input type="date" > <button (click)="ResetDate()" style="margin-right: 10px;">כל
        המכירות</button>
      <div style="margin-top: 10px;font-size: 15.5px;font-weight: 425;">סה"כ: {{this.Total | number:'1.0-2':'en-US'}}
      </div>
    </form> -->

		<div class="report-table__row report-table__row_between"></div>
		<div class="report-table__row"></div>

		<div class="report-table__row report-table__row_full-width" *ngIf="First === true" [ngStyle]="{ 'max-height': isVisitsPlannedVsUnplanned ? '74vh' : null }">
			<table class="report-table__table" cellspacing="0">
				<ng-container *ngIf="!isVisitsPlannedVsUnplanned; else visitsBlock">
					<thead class="report-table__table-head">
						<tr>
							<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('agn', 'First')">שם סוכן</th>
							<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('ag', 'First')">קוד סוכן</th>
							<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('sum1', 'First')">סה"כ יומי</th>
							<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('td', 'First')">תאריך</th>
							<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('cm', 'First')" *ngIf="shouldShowCompanies > 1">חברה</th>
						</tr>
					</thead>
					<tbody class="report-table__table-body">
						<tr *ngFor="let asa of AdminSeeAgents" (click)="GetSpecificAgentAndDay(asa.ag, asa.agn, asa.td, asa.cm)">
							<td class="report-table__table-body-quantity">{{ asa.agn.toString().slice(0, 10) }}</td>
							<td class="report-table__table-body-quantity">{{ asa.ag }}</td>
							<td class="report-table__table-body-quantity">{{ asa.sum1 | number : '1.0-3' : 'en-US' }}</td>
							<td class="report-table__table-body-quantity">{{ asa.td.toString().slice(8, 10) }}.{{ asa.td.toString().slice(5, 7) }}.{{ asa.td.toString().slice(0, 4) }}</td>
							<td class="report-table__table-body-quantity" *ngIf="shouldShowCompanies > 1">{{ asa.cm }}</td>
						</tr>
					</tbody>
				</ng-container>
				<ng-template #visitsBlock>
					<thead class="report-table__table-head">
						<tr>
							<th class="report-table__table-head-quantity">תאריך</th>
							<th class="report-table__table-head-quantity">סוכן</th>
							<th class="report-table__table-head-quantity">שעה</th>
							<th class="report-table__table-head-quantity">לקוח</th>
							<th class="report-table__table-head-quantity">סטטוס</th>
							<th class="report-table__table-head-quantity">סכום</th>
							<th class="report-table__table-head-quantity">סיבת אי מכירה</th>
							<th class="report-table__table-head-quantity">הערות</th>
							<th class="report-table__table-head-quantity" *ngIf="shouldShowCompanies > 1">חברה</th>
						</tr>
					</thead>
					<tbody class="report-table__table-body">
						<tr *ngFor="let visit of visitsDisplay$ | async">
							<td class="report-table__table-body-quantity">{{ visit.docdate | date : 'dd/MM/yy' : 'GMT' }}</td>
							<td class="report-table__table-body-quantity">{{ visit.OwnerAgentID + ' - ' + visit.AgentName }}</td>
							<td class="report-table__table-body-quantity">{{ visit.docdate | date : 'HH:mm' : 'GMT' }}</td>
							<td class="report-table__table-body-quantity">{{ visit.ClientID + ' - ' + visit.clientname }}</td>
							<td class="report-table__table-body-quantity">{{ visit.IfNotPlaned }}</td>
							<td class="report-table__table-body-quantity">{{ visit.TeudaTotalSumBeforeVat | number : '1.0-3' : 'en-US' }}</td>
							<td class="report-table__table-body-quantity">{{ visit.ResDesc }}</td>
							<td class="report-table__table-body-quantity">{{ visit.Remark }}</td>
							<td class="report-table__table-body-quantity" *ngIf="shouldShowCompanies > 1">{{ visit.company }}</td>
						</tr>
					</tbody>
				</ng-template>
			</table>
		</div>

		<div class="report-table__row report-table__row_full-width" *ngIf="Second === true">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('cn', 'Second')">שם לקוח</th>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('cl', 'Second')">קוד לקוח</th>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('sum1', 'Second')">סה"כ</th>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('tn', 'Second')">מספר תעודה</th>
						<th class="report-table__table-head-quantity sortable-clickable" (click)="translateForSort('dn', 'Second')">סוג תעודה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let asa of AgentPerDate" (click)="GetOneAzmanaAgentAdmonDate(asa.tn, asa.cn)">
						<td class="report-table__table-body-quantity">{{ asa.cn }}</td>
						<td class="report-table__table-body-quantity">{{ asa.cl }}</td>
						<td class="report-table__table-body-quantity">{{ asa.sum1 | number : '1.0-3' : 'en-US' }}</td>
						<td class="report-table__table-body-quantity">{{ asa.tn }}</td>
						<td class="report-table__table-body-quantity">{{ asa.dn }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="report-table__row report-table__row_full-width" *ngIf="Third === true">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('itn', 'Third')">שם פריט</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('it', 'Third')">קוד פריט</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('qt', 'Third')">יח</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('pk', 'Third')">אר</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('unt', 'Third')">כ. אר</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('prc', 'Third')">מחיר</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('dsc', 'Third')">הנחה</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('net', 'Third')">נטו</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('bns', 'Third')">בונוס</th>
						<th class="report-table__table-head-code sortable-clickable" (click)="translateForSort('rem', 'Third')">הערה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let asa of AgentPerDateOne">
						<td class="report-table__table-body-code">{{ asa.itn }}</td>
						<td class="report-table__table-body-code">{{ asa.it }}</td>
						<td class="report-table__table-body-code">{{ asa.qt }}</td>
						<td class="report-table__table-body-code">{{ asa.pk }}</td>
						<td class="report-table__table-body-code">{{ asa.unt }}</td>
						<td class="report-table__table-body-code">{{ asa.prc }}</td>
						<td class="report-table__table-body-code">{{ asa.dsc }}</td>
						<td class="report-table__table-body-code">{{ asa.net | number : '1.0-3' : 'en-US' }}</td>
						<td class="report-table__table-body-code">{{ asa.bns }}</td>
						<td class="report-table__table-body-code">{{ asa.rem }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
