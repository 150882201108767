export type TotalAgentPerDay = {
	ag: string;
	agn: string;
	sum1: number;
	td: string;
	cm: number;
};

export type TeudaFromAllTeudot = {
	TeudaNum: string;
	TeudaType: number;
	Client_Id: string;
	TeudaCreateDate: string;
	ProductId: string;
	TeudaLineNum: number;
	ProductName: string;
	TeudaQuantity: number;
	TeudaPackQuantity: number;
	TeudaBonusQuantity: number;
	TeudaBonusPackQuantity: number;
	TeudaPrice: number;
	TeudaItemNetPrice: number;
	TeudaItemProfit: number;
	TeudaItemDiscount: number;
	TeudaItemRemark: string;
	TeudaRemark: string;
	TeudaTotalRow: number;
	TeudaTotalSumBrouto: number;
	TeudaTotalSumBeforeVat: number;
	TeudaTotalSumVat: number;
	TeudaTotalSum: number;
	TeudaProfit: number;
	TeudaSupplyDate: string;
	TeudaVat: number;
	TeudaDiscount: number;
	TeudaAgent: string;
	CartId: number;
	Client_Name: string;
	Amount: number;
	Arizot: number;
	User_Name: string;
	TeudaStatus: number;
	PrintStatus: number;
	EmailStatus: number;
	CustEmail: string;
	RecordId: number;
	TeudaNumDupFrom: string;
	TuedaErpDocNum: string;
	TeuddaErpErrMessage: string;
	TeudaRemark1: string;
	TeudaRemark2: string;
	TeudaRemark3: string;
	TeudaRemark4: string;
	TeudaRemark5: string;
	TeudaDeliveryAddr: string;
	TeudaDeliveryCity: string;
	TeudaRef: string;
	TeudaCompany: number;
	TeudaUnitPerPack: number;
	IfChangePriceOrDisc: number;
	TotalLines: number;
	PicName: string;
	Stock: number;
	Latitude: string;
	Longitude: string;
	ItemPack: number;
};

export type TDataOneStringTeuda = {
	DATAString: string;
	TeudaNum: string;
	TeudaType: number;
	AgentID: string;
	TeudaDate: string;
	Status: number;
	Client_Name: string;
	TeudaTotalSum: number;
	TotalLines: number;
	TeudaRemark: string;
	Company: number;
	TeudaNewNum: string;
	TruckNum: string;
};

export type SeeOneHov = {
	AgentId: string;
	DocCustCode: string;
	DocDocNum: string;
	DocDocType: number;
	DocCreateDate: string;
	DocItemCode: string;
	DocLineNum: number;
	DocItemName: string;
	DocQuantity: number;
	DocPackQuantity: number;
	DocPrice: number;
	DocDiscount: number;
	DocItemRemark: string;
	DocRemark: string;
	DocTotalRow: number;
	DocTotalSum: number;
	DocTotalSumVat: number;
	DocSupplyDate: string;
	DocOrderBy: number;
	DocPicName: string;
	Company: number;
	DocTypeName: string;
	Client_Name: string;
};

export type GetAllPrices = {
	IC: string;
	qt: number;
	ClientId: string;
	NumOfTimes: number;
	DocPrice: number;
	DocDiscount: number;
	docquantity: number;
	discount: number;
	company: number;
	Stock: number;
	Product_Name: string;
	ProductId: string;
	BP: number;
	BD: number;
	BS: number;
	SpecByLevel: number;
	IDGR: string;
	BM: number;
	ItemBarcode: string;
	IGC: string;
	ItemPack: number;
	ItemPicName: string;
	MinPrice: number;
	Price: number;
	RE: string;
	BB: number;
	ItemExtra1: string;
	PKD: number;
	CPP: number;
	NetPrice: number;
	DocNetPrice: number;
	DocCreateDateDisplay: string;
};

export type AgentGoals = {
	AgentCode: string;
	AgentName: string;
	Year: string;
	Month: string;
	Yaad: null | number;
	Yaad2: null | number;
	Tot: number;
	Ltot: number;
};

export type AdminAgentsSalesSecond = {
	ag: string;
	YY: number;
	tot: number;
	ltot: number;
	ahuz: number;
	cm: number;
};

export interface AdminAgentsSalesThird extends AdminAgentsSalesSecond {
	mm: number;
}

export interface BIReports extends Record<string, string | number> {}

export type VisitsPlannedVsUnplanned = {
	ActivityID: string;
	OwnerAgentID: string;
	AgentName: string;
	ClientID: string;
	docdate: string;
	company: number;
	clientname: string;
	TeudaTotalSumBeforeVat: number;
	Remark: string;
	ResDesc: string;
	ResonID: string;
	IfNotPlaned: typeof visistsPlannedVsUnplannedStatuses[number];
};

export const visistsPlannedVsUnplannedStatuses = ['0', '1', '2', '3'] as const;

export type BanksList = {
	Bank_Name: string;
	Bank_Code: number;
	Branch_Name: string;
	Branch_Code: number;
};

export type HovotHeaders = {
	AgentId: string;
	DebtCustCode: string;
	DebtDocNum: string;
	DebtDocType: number;
	DebtDocCreateDate: Date;
	DebtDocToPayDate: Date;
	DebtDocTotal: number;
	DebtDocOpenToPay: number;
	DebtRemark: string;
	DebtColor: string;
	Company: number;
	DocTypeName?: any;
};

export type ProductCommentSelect = {
	itm: string;
	rem: string;
	cm: number;
	ag: string;
};

export interface Reasons {
	ag: string;
	cm: string;
	rid: string;
	rds: string;
	pic: number;
	rem: number;
}

export type YaadGvia = {
	cm: string;
	agentid: string;
	yy: number;
	mm: number;
	tothov: number;
	gviathismonth: number;
	ahuzgvia: number;
	agentname: string;
};
