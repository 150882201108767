import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { StoreService } from '../services/store.service';
import { Properties } from 'csstype';

@Directive({
	selector: '[appSetCardStyle]',
})
export class SetCardStyleDirective implements OnInit {
	@Input() type;
	@Input() size;
	@Input() over768;

	constructor(private element: ElementRef<HTMLDivElement>, private _ss: StoreService) {}

	ngOnInit() {
		// Customer 10123.1
		const areAllDetailsHidden =
			this._ss.CustomerInformation.SeeProductName === '1' &&
			(this._ss.CustomerInformation.SeeBarcode === '1' || this._ss.CustomerInformation.SeeBarcode === '4') &&
			this._ss.CustomerInformation.SeeProductId === '1';

		const el: HTMLDivElement = this.element.nativeElement;

		const stylesobject: Properties = {};
		const isMainProduct = this.element.nativeElement.classList.contains('IsMainProduct');

		if (this.size === 'small') {
			if (this.type === 'image') {
				stylesobject.maxHeight = areAllDetailsHidden ? '200px' : null;
				stylesobject.maxHeight = areAllDetailsHidden ? '200px' : null;
				stylesobject.height = areAllDetailsHidden ? null : '100px';
				stylesobject.margin = 'auto';
				stylesobject.display = 'flex';
				stylesobject.justifyContent = 'center';

				if (this._ss.CustomerInformation.ShowProduct === '1') {
					stylesobject.height = '80%';
				}

				if (this._ss.CustomerInformation.ShowProduct === '8') {
					stylesobject.height = '65px';
				}

				if (this._ss.CustomerInformation.ShowProduct === '9' || this._ss.CustomerInformation.ShowProduct === '10') {
					stylesobject.height = '45px';
				}

				if (this._ss.CustomerInformation.ShowProduct === '11') {
					stylesobject.height = '140px';
				}

				if (this._ss.CustomerInformation.SeeProductName !== '1' && this._ss.CustomerInformation.SeeBarcode === '7' && this._ss.CustomerInformation.SeeProductId === '3') {
					stylesobject.height = '172px';
				}

				if (isMainProduct) {
					stylesobject.height = '224px';
					if (this.over768) {
						stylesobject.height = '190px';
					}
					// stylesobject.height = null;
					// stylesobject.minHeight = '170px'
				}
			}

			if (this.type === 'info') {
				stylesobject.top = areAllDetailsHidden ? '30px' : '10px';
				stylesobject.display = areAllDetailsHidden ? 'flex' : null;
				stylesobject.flexDirection = areAllDetailsHidden ? 'column' : null;
				stylesobject.width = areAllDetailsHidden ? null : '99%';
				stylesobject.alignItems = stylesobject.justifyContent = areAllDetailsHidden ? 'center' : null;
				stylesobject.margin = areAllDetailsHidden ? null : 'auto';
				stylesobject.position = 'relative';
			}
		}

		if (!this.type && this.size === 'med') {
			stylesobject.maxWidth = areAllDetailsHidden ? '300px' : null;
			stylesobject.maxHeight = areAllDetailsHidden ? '300px' : null;
			stylesobject.height = areAllDetailsHidden ? null : '150px';
			stylesobject.margin = 'auto';
			stylesobject.display = 'flex';
			stylesobject.justifyContent = 'center';

			if (this._ss.CustomerInformation.ShowProduct === '2' || this._ss.CustomerInformation.ShowProduct === '3') {
				stylesobject.height = '200px';
			}

			if (this._ss.CustomerInformation.ShowProduct === '5') {
				stylesobject.height = '215px';
			}

			if (this._ss.CustomerInformation.SeeProductName !== '1') {
				if (this._ss.CustomerInformation.SeeBarcode === '7' && this._ss.CustomerInformation.SeeProductId === '3') {
					stylesobject.height = '212px';
				}

				if (this._ss.CustomerInformation.SeeBarcode === '1' && this._ss.CustomerInformation.SeeProductId === '1') {
					stylesobject.height = '250px';

					if (this._ss.CustomerInformation.ShowProduct === '4') {
						stylesobject.height = '300px';
					}
				}
			}

			if (isMainProduct) {
				stylesobject.height = '280px';
			}
		}

		if (el && el instanceof HTMLDivElement) {
			for (const key in stylesobject) {
				if (Object.prototype.hasOwnProperty.call(stylesobject, key)) {
					el.style[key] = stylesobject[key];
				}
			}
		}
	}
}
