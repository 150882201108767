import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MapDistributionService } from 'src/app/services/map-distribution.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
	selector: 'app-map-distribution-popup',
	templateUrl: './map-distribution-popup.component.html',
	styleUrls: ['./map-distribution-popup.component.scss'],
})
export class MapDistributionPopupComponent implements OnInit {
	constructor(private _fb: FormBuilder, private _date: DateAdapter<any, 'he-IL'>, public _mapDistribution: MapDistributionService) {}

	@Output() datePicked = new EventEmitter();

	ngOnInit(): void {
		this._date.setLocale('he-IL');
	}

	form = this._fb.group({
		date: [new Date(), Validators.required],
	});

	handleFormSubmit() {
		this.datePicked.emit(this.form.controls.date.value);
	}

	handleError() {
		alert('ישנה תקלת תקשורת, אנא נסה שנית מאוחר יותר');
		history.back();
		return;
	}
}
