import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { OnlineStatusService } from 'src/app/services/online-status.service';
import { MatDialog } from '@angular/material/dialog';
import { Time, UtilService } from 'src/app/services/util.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { LikutService } from 'src/app/services/likut.service';
import SignaturePad from 'signature_pad';
import { LikutFinishBodyNewRoutes, LikutKabalaStatus, LikutMekabelFinishBody, LikutStatus, LStatuses } from 'src/app/Interfaces/likut';
import { TextService } from 'src/app/services/text.service';
import { TruckLoadingService } from 'src/app/services/truck-loading.service';
import { TruckLoadingFinishBody, TruckModeStatuses } from 'src/app/Interfaces/truck-loading';
import { POD_DEFAULT_PDF_TEXT_OPTIONS, TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { PDFTextOptions, StoredDistributionTaskIDB, TruckDistributionFinishBody, TruckDistributionSubSQL, TruckDistributionTaskDisplayExtended } from 'src/app/Interfaces/truck-distribution';
import { compress, decompress } from 'lz-string';
import { LoadingService } from 'src/app/services/loading.service';
import { SubAppModes } from '../likut-clients/likut-clients.component';
import { translateDateForSQLServer } from 'src/app/Utility/functions';

// import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, version } from 'pdfjs-dist';
import { ProductDisplay } from '../likut-task/likut-task.component';
import { IndexedDBService } from 'src/app/services/indexed-db.service';
import { environment } from 'src/environments/environment';

type TruckDistributionOpened = { sub: TruckDistributionSubSQL[]; main: TruckDistributionTaskDisplayExtended; finishOptions?: { signature: string } };

type Form = FormGroup<{
	name: FormControl<string>;
	remarks: FormControl<string>;
	deliveryLine: FormControl<string>;
	extras: FormArray<FormControl<unknown>>;
	doctype?: FormControl<number>;
	teudaReference?: FormControl<string>;
}>;

@Component({
	selector: 'app-likut-finish',
	templateUrl: './likut-finish.component.html',
	styleUrls: ['./likut-finish.component.scss'],
})
export class LikutFinishComponent implements OnInit, OnDestroy, AfterViewInit {
	constructor(
		private _router: Router,
		private _fb: FormBuilder,
		public _ss: StoreService,
		public _online: OnlineStatusService,
		public _likut: LikutService,
		public _text: TextService,
		public _truckLoading: TruckLoadingService,
		public _truckDistribution: TruckDistributionService,
		private _util: UtilService,
		private _indexedDB: IndexedDBService,
		private _acr: ActivatedRoute,
	) {}

	clientName = '';

	totalQuantities = 0;
	totalLinesProducts = 0;

	windowWidth = innerWidth;

	taskStatus = '0';

	orderProcess = false;

	online = false;

	isSendingOrder = false;

	//! Changed date is necessary for SQL. doesn't know how to deal with datetime. check .ReadmeMD file note
	Date = new Date(new Date().getTime() + new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

	form: Form = this._fb.nonNullable.group({
		name: [''],
		remarks: [''],
		deliveryLine: [''],
		extras: this._fb.array([]),
	});

	pageMode$ = new BehaviorSubject<SubAppModes>(location.pathname.split('/')[1] as SubAppModes);

	long = '0';

	alt = '0';

	destroy$ = new Subject<void>();

	location:
		| string
		| {
				latitude: number;
				longitude: number;
		  } = '';
	position: GeolocationPosition | null = null;
	toggle = false;
	currentPositionUrl: SafeResourceUrl | null = null;
	watchSubscription: Subscription | null = null;
	error: GeolocationPositionError | null = null;

	signaturePad: SignaturePad | undefined;

	worksInSinglesQuantity = this._ss.CustomerInformation.LikutType === '1' || this._ss.CustomerInformation.LikutType === '0';
	worksInPackQuantity = this._ss.CustomerInformation.LikutType === '2' || this._ss.CustomerInformation.LikutType === '0';

	isLikut = location.pathname.includes('likut');
	isLikutMekabel = location.pathname.includes('mekabel');
	isTruckLoading = location.pathname.includes('truck-loading');
	isTruckDistribution = location.pathname.includes('truck-distribution');
	isNewTask = this._acr.snapshot.queryParams['new'] === 'true';

	signatureState$ = new BehaviorSubject({
		signed: false,
		dataURL: '',
	});
	storedTask: StoredDistributionTaskIDB & { id?: number };

	generatedTaskID = new Date().getTime() + '000' + this._ss.AgentIdConnected;
	imageArray = new Array(this._truckLoading.howManyPicturesCanAttach).fill(1).map((_, i) => i + 1);

	isMismatchedQuantityPopupOpen$ = new BehaviorSubject(false);

	controlsObject: Record<string, FormControl> = {};

	@ViewChild('imageInput') imageInput: ElementRef<HTMLInputElement>;

	readonly defaultTextOptions = POD_DEFAULT_PDF_TEXT_OPTIONS;

	useImage = false;
	usePDF = false;
	pdfSRC$ = new BehaviorSubject('');
	currentPage$ = new BehaviorSubject(1);
	PDFArray$ = new BehaviorSubject<string[]>([]);
	moreThanOnePage$ = this.PDFArray$.pipe(map(array => array.length > 1));
	textToRender$ = combineLatest([this.currentPage$, this.PDFArray$]).pipe(
		map(([currentPage, pdfArray]) => {
			if (!pdfArray.length) return [];
			const text: PDFTextOptions[] = [];
			const { sub, task } = this._truckDistribution.truckDistributionOpened;

			const relevantPDF = pdfArray[currentPage - 1].split('/').at(-1);
			const relevantTeuda = Object.values(task.teudot).find(teuda => teuda.OrgPDF === relevantPDF);

			console.log(relevantPDF, relevantTeuda);

			if (relevantTeuda.Extra5 && this._truckDistribution.parametersRecord.considerExtra5) {
				text.push({ id: 'extra5-text', left: 0.5, top: 0.07, text: relevantTeuda.Extra5 });
			}

			const anyChanges = sub.filter(line => line.DocNum === relevantTeuda.DocNum && (line.PackQTY !== line.PODPackQTY || line.Quantity !== line.PODQuantity));

			const PDFTextOptions = this._truckDistribution.getPDFTextOptions();

			if (anyChanges.length) {
				let finalText = '';

				for (const change of anyChanges) {
					finalText += `פריט ${change.ProductID} - סופקה כמות ${change.PODQuantity} (${change.PODPackQTY} אריזות)<br/>`;
				}
				text.push({ ...PDFTextOptions, id: 'changes-text', text: finalText });
			}

			return text;
		}),
	);

	shouldShowPDFPopup$ = new BehaviorSubject(false);

	images: { [K in Images]?: File } = {};
	likutMekabelImageUploaded = false;

	ngOnInit() {
		this._truckLoading.refreshAllAdminTypes();
		this._likut.refreshAllAdminTypes();

		this.online = window.navigator.onLine;

		if (this.isTruckDistribution) {
			this.usePDF = true;

			const teudot = Object.values(this._truckDistribution.truckDistributionOpened.task.teudot);

			const PDFs = teudot.filter(teuda => teuda.OrgPDF).map(teuda => `${this._ss.CustomerInformation.CustServer}/docout/${teuda.OrgPDF}`);

			if (PDFs.length) {
				this.PDFArray$.next(PDFs);
				this.pdfSRC$.next(PDFs[0]);
			}
		}

		this.initiateSubscriptions();

		this.handleTaskStorage('get');

		this.handlePopulateInfo();

		window.scrollTo(0, 0);

		if (this.isTruckDistribution) {
			try {
				navigator.geolocation.getCurrentPosition(
					pos => {
						this.location = {
							latitude: pos.coords.latitude,
							longitude: pos.coords.longitude,
						};
						console.log(pos);
					},
					err => {
						this.location = err.message;
						console.log(err);
					},
					{ enableHighAccuracy: true, timeout: 10000 },
				);
			} catch (error) {
				console.log('getting coordinates failed');
			}
		}

		if (this.isLikutMekabel) {
			this.pageMode$.next('likut-mekabel');
			this.form.addControl('doctype', new FormControl<number>(null, Validators.required));
			this.form.addControl('teudaReference', new FormControl<string>(null, Validators.required));

			// if (environment.development) {
			// 	this._likut.likutMekabelDoctypes$.next([{ name: 'קבלה למחסן', num: 11 }]);
			// }

			if (!this._likut.likutMekabelDoctypes$.value.length) {
				const doctypes = localStorage.getItem('LikutMekabelDoctypes');
				if (!doctypes) {
					alert('יש לעדכן נתונים כדי להציג סוגי מסמכים');
					this._router.navigate(['home']);
					return;
				}
				this._likut.likutMekabelDoctypes$.next(JSON.parse(decompress(doctypes)));
			}

			if (this._likut.likutMekabelDoctypes$.value.length === 1) {
				this.form.controls.doctype.setValue(this._likut.likutMekabelDoctypes$.value[0].DocType);
			}
		}
	}

	ngAfterViewInit(): void {
		const canvas = document.querySelector('canvas');

		if (!canvas) return;

		this.signaturePad = new SignaturePad(canvas);

		const ctx = canvas.getContext('2d');
		if (!ctx) return;
		ctx.strokeStyle = '#BADA55';
		ctx.lineJoin = 'round';
		ctx.lineCap = 'round';
		// ctx.fillStyle = '#ffffff';
		// ctx.fillRect(0, 0, canvas.width, canvas.height);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		this.shouldShowPDFPopup$.next(false);

		this._truckDistribution.canLeaveCurrentPage = true;
	}

	async handlePopulateInfo() {
		if (this.isTruckLoading) {
			this.totalQuantities = this._truckLoading.truckLoadOpened.sub.reduce((p, c) => p + +(c.Quantity || 0) + +(c.PackQTY || 0), 0);
			this.totalLinesProducts = this._truckLoading.truckLoadOpened.sub.length;
			this.clientName = this._truckLoading.truckLoadOpened.task.clientName;
			this.taskStatus = this._truckLoading.truckLoadOpened.task.status;

			try {
				if (!this._truckLoading.deliveryLineChosen.UserID) {
					return await new Promise<void>((resolve, reject) => {
						this.pipeADestroyer(this._truckLoading.getDeliveryLinesList()).subscribe({
							next: async res => {
								if (typeof res === 'string' || !res.recordset.length) {
									return reject();
								}

								if (this._truckLoading.isBothMaamisAndNahag) {
									const haluka = res.recordset.find(line => line.UserID === this._ss.AgentIdConnected);
									if (!haluka) {
										return reject();
									}
									this._truckLoading.deliveryLineChosen = haluka;
									return;
								}

								this._truckLoading.truckDeliveryLines$.next(res.recordset);
								resolve();
							},
							error: () => {
								reject();
							},
						});
					});
				}
			} catch (err) {
				alert(this._text.store.taskConnectionError);
				history.back();
			}
			return;
		}

		if (this.isTruckDistribution) {
			const { sub, task } = this._truckDistribution.truckDistributionOpened;
			this.totalQuantities = sub.reduce((p, c) => p + +(c.PODPackQTY || 0) + +(c.PODQuantity || 0), 0);
			this.totalLinesProducts = sub.length;
			this.clientName = task.clientName;
			this.taskStatus = task.status;

			if (!this.storedTask) {
				this.storedTask = { sub, main: task as TruckDistributionTaskDisplayExtended, storageKey: this._truckDistribution.truckDistributionOpened.task.storageKey };
			}

			if (!this._truckDistribution.extras$.value.length) {
				this._truckDistribution.getTruckDistributionExtras().subscribe({
					next: res => {
						if (typeof res === 'string') {
							return;
						}

						this._truckDistribution.extras$.next(res.recordset);

						for (const extra of res.recordset) {
							const requiredArray = [];
							if (extra.MustFill) {
								requiredArray.push(Validators.required);
							}
							const control = new FormControl('', requiredArray);
							this.controlsObject[extra.EName] = control;
							this.form.controls.extras.push(control);
						}
					},
					error: () => {},
				});
			} else {
				for (const extra of this._truckDistribution.extras$.value) {
					const requiredArray = [];
					if (extra.MustFill) {
						requiredArray.push(Validators.required);
					}
					const control = new FormControl('', requiredArray);
					this.controlsObject[extra.EName] = control;
					this.form.controls.extras.push(control);
				}
			}

			return;
		}

		if (this.isLikutMekabel) {
			this.totalQuantities = this._likut.likutMekabelOpenedState.main.rows.reduce((p, c) => p + +(c.TeudaQtyKabala || 0) + +(c.TeudaPackQtyKabala * c.ItemPack || 0), 0);
			this.totalLinesProducts = this._likut.likutMekabelOpenedState.main.totalRow;
			if (this.isNewTask) {
				this.totalLinesProducts = this._likut.likutMekabelOpenedState.main.rows.length;
			}
			this.clientName = this._likut.likutMekabelOpenedState.main.clientName;
			this.taskStatus = this._likut.likutMekabelOpenedState.main.status;
			return;
		}

		this.totalQuantities = this._likut.likutOpenedNewRoutesState.sub.reduce((p, c) => p + +(c.LikutQty || 0) + +(c.LikutPackQty || 0), 0);
		this.totalLinesProducts = this._likut.likutOpenedNewRoutesState.sub.length;
		this.clientName = this._likut.likutOpenedNewRoutesState.task.clientName;
		this.taskStatus = this._likut.likutOpenedNewRoutesState.task.status;
	}

	BackToProducts() {
		if (this.shouldShowPDFPopup$.value) {
			this.togglePDFPopup(false);
			return;
		}
		history.back();
	}

	handleSaveOrBackButton() {
		if (!this.isTruckDistribution || !this.signatureState$.value.signed || this.shouldShowPDFPopup$.value) {
			this.BackToProducts();
			return;
		}

		this.handleSaveButton();
	}

	handleSaveButton() {
		if (!this.isTruckDistribution || this.shouldShowPDFPopup$.value) {
			this.BackToProducts();
			return;
		}

		if (!this.signatureState$.value.signed) {
			alert('אין אפשרות לשמור משימה בלי חתימה');
			return;
		}

		this._truckDistribution.resetTruckDistributionState();
		this._truckDistribution.canLeaveCurrentPage = true;
		this._router.navigate(['truck-distribution', 'clients']);
	}

	decideWhichMethodToUseForSend() {
		if (this.isSendingOrder) return;

		if (this.isLikutMekabel) {
			this.sendLikutMekabel();
			return;
		}

		if (this.isLikut) {
			this.sendLikutNewRoutes();
			return;
		}

		if (this.isTruckDistribution) {
			if (this.form.invalid) {
				this.form.markAllAsTouched();
				return;
			}

			if (!this.signatureState$.value.signed) {
				alert('אין אפשרות לסיים בלי חתימה');
				return;
			}

			this.sendTruckDistributionTask();
			return;
		}

		this.sendTruckLoadingTask();
	}

	sendLikutNewRoutes() {
		if (!this.checkIfAnyQuantityIsMismatched('likut') && !this._likut.isCheckerAgent && !this._likut.isCheckerAgentAndCanEdit ) {
			this.isMismatchedQuantityPopupOpen$.next(true);
			return;
		}

		this.isMismatchedQuantityPopupOpen$.next(false);

		if (!this.signaturePad) return;

		this.isSendingOrder = true;
		let { name, remarks } = this.form.value;

		name ||= '';
		remarks ||= '';

		const body: LikutFinishBodyNewRoutes[] = this._likut.buildLikutFinishPayloadBody({ name, remarks }, this.signaturePad, this.taskStatus as LikutStatus);

		this._ss.finishAndSendLikutNewRoute(body).subscribe({
			next: res => {
				this.isSendingOrder = false;
				if (typeof res === 'string') {
					alert(this._text.store.couldntPatch);
					return;
				}
				this._likut.resetLikutState();
				this.orderProcess = true;
			},
			error: () => {
				alert(this._text.store.couldntPatch);
				this.isSendingOrder = false;
			},
		});
	}

	sendLikutMekabel() {
		if (!this.signaturePad) return;

		this.isSendingOrder = true;
		let { name, remarks } = this.form.value;

		if (this.isNewTask) {
			if (this.form.invalid) {
				this.form.markAllAsTouched();
				this.isSendingOrder = false;
				return;
			}
			this.sendLikutMekabelNewTask();
			return;
		}

		const body: LikutMekabelFinishBody[] = this._likut.buildLikutMekabelFinishPayloadBody({ name, remarks }, this.signaturePad, this.taskStatus as LikutKabalaStatus);

		this._ss.finishAndSendLikutMekabel(body).subscribe({
			next: res => {
				this.isSendingOrder = false;
				if (typeof res === 'string') {
					alert(this._text.store.couldntPatch);
					return;
				}
				this._likut.resetLikutState();
				this.orderProcess = true;
			},
			error: () => {
				alert(this._text.store.couldntPatch);
				this.isSendingOrder = false;
			},
		});
	}

	sendLikutMekabelNewTask() {
		const { remarks, teudaReference, name } = this.form.value;
		const body = this._likut.buildLikutMekabelFinishPayloadBodyForNewTask({ name, remarks, teudaReference }, this.signaturePad, this.taskStatus as LikutKabalaStatus);
		this._ss.finishAndSendLikutMekabelNewTask(body).subscribe({
			next: res => {
				this.isSendingOrder = false;
				if (typeof res === 'string') {
					alert(this._text.store.couldntPatch);
					return;
				}
				this._likut.resetLikutState();
				this.orderProcess = true;
			},
			error: () => {
				alert(this._text.store.couldntPatch);
				this.isSendingOrder = false;
			},
		});
	}

	checkIfAnyQuantityIsMismatched(mode: 'likut' | 'truck-loading'): boolean {
		if (this.isMismatchedQuantityPopupOpen$.value) return true;
		if (mode === 'likut') {
			if (this.worksInSinglesQuantity) {
				const isOneSubQuantityNotEqual = this._likut.likutOpenedNewRoutesState.sub.some(sub => (sub.LikutQty || 0) !== sub.TeudaQuantity && sub.LStatus !== LStatuses.isDuplicated);
				if (isOneSubQuantityNotEqual) return false;
			}

			if (this.worksInPackQuantity) {
				const isOneSubQuantityNotEqual = this._likut.likutOpenedNewRoutesState.sub.some(sub => (sub.LikutPackQty || 0) !== sub.TeudaPackQuantity && sub.LStatus !== LStatuses.isDuplicated);
				if (isOneSubQuantityNotEqual) return false;
			}
			return true;
		}

		// TODO need to implement truck-loading
		return true;
	}

	sendTruckLoadingTask() {
		const { remarks } = this.form.value;

		if (!this.signaturePad) return;

		if (!this.form.controls.deliveryLine.value && !this._truckLoading.deliveryLineChosen.UserID) {
			alert('אנא בחר קו חלוקה');
			return;
		}

		this.isSendingOrder = true;
		let body: TruckLoadingFinishBody[];

		const teudotArray = Object.values(this._truckLoading.truckLoadOpened.sub);

		body = teudotArray.map(task => ({
			table: 'sub',
			DocNum: task.DocNum,
			ProductID: task.ProductID,
			LoadingStatus: String(Number(this.taskStatus) + 1),
		}));

		const haluka = this._truckLoading.deliveryLineChosen.UserID || this._truckLoading.truckDeliveryLines$.value.find(line => line.UserID === this.form.controls.deliveryLine.value).UserID;

		const images: Record<Images, string> = Object.entries(this.images).reduce((p, c) => {
			const num = c[0][c[0].length - 1];
			p[c[0] as Images] = `${this.generatedTaskID}-${num}.jpg`;
			return p;
		}, {} as Record<Images, string>);

		const taskBody: TruckLoadingFinishBody[] = Object.values(this._truckLoading.truckLoadOpened.task.teudot).map(task => ({
			table: 'main',
			DocNum: task.DocNum,
			DocID: task.DocID,
			Comment: remarks,
			Haluka: haluka,
			SignatureString: this.signaturePad.toDataURL(),
			LoadDate: translateDateForSQLServer().toISOString(),
			LoadingStatus: String(Number(this.taskStatus) + 1) as LikutStatus,
			LoadID: this.generatedTaskID,
			...images,
		}));

		this._truckLoading.finishTruckLoadingTask(body.concat(taskBody)).subscribe({
			next: res => {
				this.isSendingOrder = false;
				if (typeof res === 'string') {
					alert(this._text.store.couldntPatch);
					return;
				}
				this._truckLoading.resetTruckLoadingState();
				this.orderProcess = true;
			},
			error: () => {
				alert(this._text.store.couldntPatch);
				this.isSendingOrder = false;
			},
		});
	}
	sendTruckDistributionTask() {
		const { remarks } = this.form.value;

		// if (!this.signaturePad) return;

		this.isSendingOrder = true;
		let body: TruckDistributionFinishBody[];

		const teudotArray = Object.values(this._truckDistribution.truckDistributionOpened.sub);

		body = teudotArray.map(task => ({
			table: 'sub',
			DocNum: task.DocNum,
			ProductID: task.ProductID,
			PODStatus: this._truckDistribution.resolvePODStatus(task),
			PODQuantity: task.PODQuantity,
			PODPackQTY: task.PODPackQTY,
		}));

		// const haluka = this._truckLoading.deliveryLineChosen.UserID || this._truckLoading.truckDeliveryLines$.value.find(line => line.UserID === this.form.controls.deliveryLine.value).UserID;

		const extras = Object.fromEntries(
			Object.entries(this.controlsObject).map(entry => {
				entry[1] = entry[1].value;
				return entry;
			}),
		);

		let Coordinates = {};

		if (typeof this.location !== 'string') {
			Coordinates = { Coordinates: JSON.stringify(this.location) };
		}

		const images: Record<Images, string> = Object.entries(this.images).reduce((p, c) => {
			const num = c[0][c[0].length - 1];
			p[c[0] as Images] = `${this.generatedTaskID}-${num}.jpg`;
			return p;
		}, {} as Record<Images, string>);

		const taskBody: TruckDistributionFinishBody[] = Object.values(this._truckDistribution.truckDistributionOpened.task.teudot).map(task => ({
			table: 'main',
			DocNum: task.DocNum,
			DocID: task.DocID,
			Comment: remarks,
			SignatureString: this.signatureState$.value.dataURL,
			PODDate: translateDateForSQLServer().toISOString(),
			PODStatus: String(Number(this.taskStatus) + 1) as TruckModeStatuses,
			PODID: this.generatedTaskID,
			...extras,
			...Coordinates,
			...images,
		}));

		this._truckDistribution.finishTruckDistributionTask(body.concat(taskBody)).subscribe({
			next: res => {
				this.isSendingOrder = false;
				if (typeof res === 'string') {
					alert(this._text.store.couldntPatch);
					return;
				}
				this._truckDistribution.resetTruckDistributionState({ clearStored: true });
				this.orderProcess = true;
			},
			error: () => {
				alert(this._text.store.couldntPatch);
				this.isSendingOrder = false;
			},
		});
	}

	async setImage(event: Event, number) {
		const input = event.target as HTMLInputElement;
		input.classList.remove('error');
		input.classList.add('uploading');
		const selectedFile = input.files[0];
		if (!selectedFile) {
			input.classList.add('error');
			return;
		}
		let file = new File([selectedFile], `${this.generatedTaskID}-${number}.jpg`, { type: selectedFile.type });
		if (this.isLikutMekabel) {
			file = new File([selectedFile], `${this._likut.likutMekabelOpenedState.main.rows[0].TeudaNum}.jpg`, { type: selectedFile.type });
		}
		let bool;

		try {
			bool = await this.resolveFileUploadHandler(file);
		} catch (err) {
			console.error(err);
			bool = false;
		}
		if (bool) {
			if (this.isLikutMekabel) {
				this.likutMekabelImageUploaded = true;
				input.classList.remove('uploading');
				return;
			}
			this.images['ImageName' + number] = file;
		} else {
			input.classList.add('error');

			if (this.isTruckDistribution) {
				try {
					await this._indexedDB.storeImage({ taskIdentifier: this._truckDistribution.truckDistributionOpened.task.storageKey, imageFile: file, uploaded: false });
					// this.images['ImageName' + number] = file;
					input.classList.remove('error');
				} catch (err) {
					alert('לא ניתן להעלות או לשמור תמונה');
				}
			}
		}

		input.classList.remove('uploading');
	}

	async resolveFileUploadHandler(file: File): Promise<boolean> {
		switch (this.pageMode$.value) {
			case 'truck-distribution':
				return await this._truckDistribution.handleFileUpload(file);
			case 'truck-loading':
				return await this._truckLoading.handleFileUpload(file);
			case 'likut-mekabel':
				return await this._likut.handleFileUploadLikutMekabel(file);

			default:
				return false;
		}
	}

	togglePDFPopup(mode = false) {
		if (mode && this.signatureState$.value.signed) return;
		this.shouldShowPDFPopup$.next(mode || !this.shouldShowPDFPopup$.value);
	}

	// closePDFPopup() {
	// 	this.shouldShowPDFPopup$.next(false);
	// }

	initiateSubscriptions() {
		this.pipeADestroyer(this.currentPage$).subscribe(number => {
			this.pdfSRC$.next(this.PDFArray$.value[number - 1] || '');
		});
		this.pipeADestroyer(this.shouldShowPDFPopup$).subscribe(boolean => {
			if (boolean) {
				document.body.classList.add('no-scroll');
				return;
			}
			document.body.classList.remove('no-scroll');
		});

		this.pipeADestroyer(this.signatureState$).subscribe(state => {
			if (state.signed) {
				this.handleTaskStorage('set', state.dataURL);
				this._truckDistribution.canLeaveCurrentPage = false;
			}
		});
	}

	async handleTaskStorage(mode: 'get');
	async handleTaskStorage(mode: 'set', signature: string);
	async handleTaskStorage(mode: 'get' | 'set', signature = '') {
		// const tasks = this._indexedDB.getTasks();
		// console.log(tasks);
		try {
			let key = '',
				clientid = '',
				address = '',
				status = '';
			if (this._truckDistribution.truckDistributionOpened.task) {
				// clientid is docnum if LoadingAndDistribution_GroupByDocNum
				({ clientid, address, status } = this._truckDistribution.truckDistributionOpened.task);
				key = `Task-${clientid},${status},${address}`;
			}
			if (mode === 'get') {
				if (!key || !clientid || !address || !status) return;
				// let stored = localStorage.getItem(key);

				const stored = await this._indexedDB.getTruckDistributionTask(key);

				if (stored?.length) {
					const task = stored[0];

					if (task?.sub?.length && this.pageMode$.value.includes('truck')) {
						if (this.isTruckDistribution) {
							this.storedTask = task;
							this.generatedTaskID = task.main.taskID;
							const signature = task.finishOptions?.signature;
							if (signature) {
								this.updateSignatureState({ dataURL: signature, signed: true });
							}
						}
					}
				}
			}

			if (mode === 'set' && this.isTruckDistribution) {
				const { main, sub } = this.storedTask;

				main.taskID = this.generatedTaskID;

				const { remarks } = this.form.value;

				let Coordinates = {};

				if (typeof this.location !== 'string') {
					Coordinates = { Coordinates: JSON.stringify(this.location) };
				}

				const obj = {
					storageKey: this.storedTask.main.storageKey,
					sub,
					main,
					finishOptions: { signature, remarks, ...Coordinates },
				};

				await this._indexedDB.storeTruckDistributionTask(obj);
			}
		} catch (err) {
			console.error(err);
		}
	}

	updateSignatureState(options: typeof this.signatureState$.value) {
		this.signatureState$.next(options);
	}

	changePage(action: 'next' | 'previous') {
		if (action === 'next') {
			this.currentPage$.next(Math.min(this.currentPage$.value + 1, this.PDFArray$.value.length));
		}
		if (action === 'previous') {
			this.currentPage$.next(Math.max(this.currentPage$.value - 1, 1));
		}
	}

	backToLikutClients() {
		const pathname = this.pageMode$.value;

		this._truckDistribution.canLeaveCurrentPage = true;

		if (this.isLikutMekabel) {
			this._router.navigate([pathname, 'clients', 'mekabel']);
			return;
		}

		this._router.navigate([pathname, 'clients']);
	}

	pipeADestroyer<T>(storeServiceMethod: Observable<T>) {
		return this._util.pipeASubjectDestoryer(storeServiceMethod, this.destroy$);
	}

	// base64ToBlob(base64: string, type: string = 'image/jpeg') {
	// 	const byteCharacters = window.atob(base64.split(',')[1]);
	// 	const byteArrays = [];

	// 	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
	// 		const slice = byteCharacters.slice(offset, offset + 512);

	// 		const byteNumbers = new Array(slice.length);
	// 		for (let i = 0; i < slice.length; i++) {
	// 			byteNumbers[i] = slice.charCodeAt(i);
	// 		}

	// 		const byteArray = new Uint8Array(byteNumbers);
	// 		byteArrays.push(byteArray);
	// 	}

	// 	return new Blob(byteArrays, { type });
	// }
}

type Images = 'ImageName1' | 'ImageName2' | 'ImageName3' | 'ImageName4';
