import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import {
	TruckDistributionAgentSpread,
	TruckDistributionDeliveryLinesResponse,
	TruckDistributionTaskDisplayExtended,
	TruckDistributionExtrasResponse,
	TruckDistributionFinishBody,
	TruckDistributionMainSQL,
	TruckDistributionSubSQL,
	TruckDistributionUsers,
	TruckDistributionAssignmentUpdateLineNum,
	TruckDistributionAssignmentUpdateDate,
	TruckDistributionTaskDisplayWithAssignment,
	TruckDistributionSavedTask,
	TruckDistributionNotFulfilled,
	PDFTextOptions,
	TruckDistributionPODSupplied,
} from '../Interfaces/truck-distribution';
import { TruckLoadingMainSQL, TruckModeStatuses } from '../Interfaces/truck-loading';
import { AdminTypes } from '../Interfaces/users';
import { IndexedDBService } from './indexed-db.service';
import { StoreService } from './store.service';
import { TextService } from './text.service';
import { TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES } from './truck-loading.service';
import { environment } from 'src/environments/environment';
import { AllClients } from '../Interfaces/SQL-morning-responses';

export const POD_DEFAULT_PDF_TEXT_OPTIONS: PDFTextOptions = {
	id: '',
	text: '',
	top: 1,
	page: 0,
	right: 0,
	size: 8,
};

type PDFLinkOptions = { mode: 'PDFfile' | 'OrgPDF'; filename: string };

@Injectable({
	providedIn: 'root',
})
export class TruckDistributionService {
	constructor(private _ss: StoreService, private _text: TextService, private _idb: IndexedDBService) {
		this.init();
	}

	readonly taskLineStatus = {
		isOK: '1',
		cancelled: '2',
		mismatchedQuantity: '3',
	} as const;

	uniqueText: Partial<typeof this._text.store> = {
		mainTasks: `משימות הפצה`,
		teudaName: `תעודת הפצה`,
		teudaNameGroupB: 'תעודת החזרה',
		name: {
			'1': 'שם הנהג',
			'2': 'שם מנהל',
			'3': 'לא בשימוש',
		},
		ordered: 'כמות',
		quantityCollected: { '0': `יחידות בפועל`, '0-2': 'אריזות בפועל', '0-3': '', '0-4': '', '1': 'כמות בפועל', '2': 'כמות בפועל' },
	};

	truckDistributionOpened: { sub: TruckDistributionSubSQL[]; task: TruckDistributionTaskDisplayExtended; finishOptions?: { signature: string; remarks: string } } = {
		sub: [],
		task: { clientid: '', clientName: '', status: '0', teudot: {}, totalRow: 0, totalTeudot: 0, address: '', phone: '', taskID: '', storageKey: '', date: '', extra5Array: [] },
		finishOptions: { signature: '', remarks: '' },
	};

	deliveryLineChosen: TruckDistributionDeliveryLinesResponse = { Extra1: null, Extra2: null, Name: '', UserID: '' };

	Admin: AdminTypes | null = localStorage.getItem('Admin') as AdminTypes | null;

	worksIn: 'singles' | 'packs' | 'both' = 'singles';
	drivers$ = new BehaviorSubject<TruckDistributionUsers[]>([]);
	usersForAssignment$ = new BehaviorSubject<TruckDistributionUsers[]>([]);

	extras$ = new BehaviorSubject<TruckDistributionExtrasResponse[]>([]);
	extrasDisplay$ = this.extras$.pipe(map(extras => extras));

	private readonly pathEntry = 'mobil/truck-distribution';

	relevantUsers: Partial<typeof TRUCK_LOADING_AND_DISTRIBUTION_ADMIN_TYPES>[number][] = ['nahag', 'nahagmaamis'];

	reportAgentDisplay: TruckDistributionAgentSpread = 'wrap';
	reportShowExtras = [];

	reportsRecord = {
		truckLoadingAssignment: false,
		truckDistributionAssignment: false,
		truckLoadingPassword: false,
		truckLoadingExtra4AssignmentManagement: false,
		truckDistributionMap: false,
		mobilityDashboard: false,
		truckDistributionNotSupplied: false,
		truckDistributionWasSupplied: false,
		truckDistributionCanGenerateNewTask: false,
	};

	parametersRecord = {
		considerExtra5: false,
		showPDF: false,
		groupOnlyByDocNum: false,
		// showPDFWithoutPDFButton: false,
	};

	// ./Guards/can-leave-page-custom.guard
	canLeaveCurrentPage = true;

	taskAssigmentPopupDateChange = new EventEmitter<{ task: TruckDistributionTaskDisplayWithAssignment; newDate: string }>();
	taskAssigmentPopupAgentChange = new EventEmitter<{ task: TruckDistributionTaskDisplayWithAssignment; newAgent: string }>();

	savedTasks$ = new BehaviorSubject<TruckDistributionSavedTask[]>([]);

	init() {
		this.getTasksFromStorage();

		if (!this._ss.CustomerInformation) return;

		if (this._ss.CustomerInformation.LoadingAndDistribution_TaskAssignmentDisplay === '1') {
			this.reportAgentDisplay = 'multiselect';
		}

		if (this._ss.CustomerInformation.LoadingAndDistribution_TaskAssignmentExtra && this._ss.CustomerInformation.LoadingAndDistribution_TaskAssignmentExtra !== '0') {
			this.reportShowExtras = this._ss.CustomerInformation.LoadingAndDistribution_TaskAssignmentExtra.split('_').filter(num => +num < 6);
		}

		if (this._ss.CustomerInformation.LoadingAndDistribution_PODExtra5 === '1') {
			this.parametersRecord.considerExtra5 = true;
		}

		if (this._ss.CustomerInformation.LoadingAndDistribution_GroupByDocNum) {
			const split = this._ss.CustomerInformation.LoadingAndDistribution_GroupByDocNum.split('_');
			this.parametersRecord.groupOnlyByDocNum = split.includes('1');
		}
		if (this._ss.CustomerInformation.LoadingAndDistribution_ShowPDF) {
			const split = this._ss.CustomerInformation.LoadingAndDistribution_ShowPDF.split('_');
			this.parametersRecord.showPDF = split.includes('1');
		}

		if (!this._ss.CustomerInformation.LoadingAndDistribution_Manager_POD) return;
		const splitReportsParameters: string[] = this._ss.CustomerInformation.LoadingAndDistribution_Manager_POD.split('_');
		this.reportsRecord.truckLoadingPassword = splitReportsParameters.includes('1');
		this.reportsRecord.truckLoadingAssignment = splitReportsParameters.includes('2');
		this.reportsRecord.truckDistributionAssignment = splitReportsParameters.includes('3');
		this.reportsRecord.truckLoadingExtra4AssignmentManagement = splitReportsParameters.includes('4');
		this.reportsRecord.truckDistributionMap = splitReportsParameters.includes('5');
		this.reportsRecord.mobilityDashboard = splitReportsParameters.includes('6');
		this.reportsRecord.truckDistributionNotSupplied = splitReportsParameters.includes('7');
		this.reportsRecord.truckDistributionWasSupplied = splitReportsParameters.includes('8');

		if (environment.development) {
			this.reportsRecord.truckDistributionCanGenerateNewTask = true;
		}
	}

	getAllTruckDistributionTasksForMain(mode: 'assignment' | 'clientsTask' | 'password' | 'map' | 'not-fulfilled', options?: { onlyAssigned?: string; date?: string }) {
		let endpoint = 'get-all-main-as-driver';
		let params = new HttpParams();
		const onlyAssigned = options?.onlyAssigned || '';
		const date = options?.date || new Date().toISOString();

		if (mode === 'assignment') {
			if (!onlyAssigned) {
				endpoint = 'get-all-for-assignment-kanban';
			} else {
				endpoint = 'get-not-assigned/' + onlyAssigned;
			}
		}

		if (mode === 'clientsTask') {
			if (onlyAssigned) {
				endpoint += '/' + onlyAssigned;
			}
		}

		if (mode === 'password') {
			endpoint = 'get-all-main-as-manager-for-password';
		}

		if (mode === 'map') {
			endpoint += '-for-map';
			params = params.set('date', date);
		}

		return this._ss.get<TruckDistributionMainSQL>(`${this.pathEntry}/${endpoint}`, params);
	}

	getTruckDistributionTasksNotFulfilled() {
		return this._ss.get<TruckDistributionNotFulfilled>(`${this.pathEntry}/get-all-main-as-manager-not-fulfilled`);
	}

	getTruckDistributionPODSupplied() {
		return this._ss.get<TruckDistributionPODSupplied>(`${this.pathEntry}/get-all-main-as-manager-supplied`);
	}

	getTruckDistributionPODSuppliedTaskForReport(docnum: string) {
		let params = new HttpParams();

		params = params.appendAll({ docnum });

		return this._ss.get<TruckDistributionSubSQL>(`${this.pathEntry}/get-supplied-task-for-supplied-report`, params);
	}

	overrideText() {
		this._text.updateTextStore(this.uniqueText);
	}

	resetTruckDistributionState(options?: { clearStored?: boolean }) {
		const clearStored = options?.clearStored || false;
		if (clearStored) {
			this.deleteStoredTask();
		}

		this.truckDistributionOpened = {
			sub: [],
			task: { clientid: '', clientName: '', status: '0', teudot: {}, totalRow: 0, totalTeudot: 0, address: '', phone: '', taskID: '', storageKey: '', date: '', extra5Array: [] },
			finishOptions: { signature: '', remarks: '' },
		};
		this.deliveryLineChosen = { Extra1: null, Extra2: null, Name: '', UserID: '' };
	}

	async deleteStoredTask() {
		const { clientid, address, status } = this.truckDistributionOpened.task;
		const key = `Task-${clientid},${status},${address}`;

		localStorage.removeItem(key);
		try {
			await this._idb.deleteImage(key);
			await this._idb.deleteTruckDistributionTask(key);
		} catch (error) {}
	}

	updateDistributionUsers() {
		this.getAllTruckDistributionUsers('all').subscribe({
			next: res => {
				if (typeof res === 'string') {
					return;
				}

				this.drivers$.next(res.recordset);
			},
		});
	}

	updateDistributionUsersForAssignment() {
		this.getAllTruckDistributionUsers('assignment').subscribe({
			next: res => {
				if (typeof res === 'string') {
					return;
				}

				this.usersForAssignment$.next(res.recordset);
			},
		});
	}

	private getAllTruckDistributionUsers(mode: 'all' | 'assignment') {
		let path = '';
		if (mode === 'all') {
			path = 'get-truck-distribution-users';
		}

		if (mode === 'assignment') {
			path = 'get-all-users-for-assignment-kanban';
		}
		return this._ss.get<TruckDistributionUsers>(`${this.pathEntry}/${path}`);
	}

	finishTruckDistributionTask(body: TruckDistributionFinishBody[]) {
		return this._ss.patch(`${this.pathEntry}/finish-distribution-task-as-driver`, { lines: body });
	}

	getOneMainTruckDistributionTask(options: { docnums: string[] }) {
		let params = new HttpParams();

		params = params.appendAll({ DocNum: options.docnums });

		return this._ss.get<TruckDistributionMainSQL>(`${this.pathEntry}/get-one-main-as-driver`, params);
	}

	getTruckDistributionExtras() {
		return this._ss.get<TruckDistributionExtrasResponse>(`${this.pathEntry}/get-all-extras`);
	}

	getOneOrMoreSubTruckDistributionTask(options: { docnums: string[] }) {
		let params = new HttpParams();

		params = params.appendAll({ DocNum: options.docnums });

		return this._ss.get<TruckDistributionSubSQL>(`${this.pathEntry}/get-sub-task-as-driver`, params);
	}

	markTaskAsOpenedByAgent(body: { DocNum: string[]; OpenedBy: string | null }) {
		let base = `${this.pathEntry}/mark-sub-task-as`;

		switch (body.OpenedBy) {
			case null:
			case '':
				return this._ss.patch(`${base}-free-driver`, body);

			default:
				return this._ss.patch(`${base}-opened-by-driver`, body);
		}
	}

	assignTaskToUser(body: { Extra4: string; AssignTo: string }) {
		return this._ss.patch(`${this.pathEntry}/set-not-assigned`, body);
	}

	assignKanbanTaskToAgent(body: { DocNum: string[]; AssignTo: string }) {
		return this._ss.patch(`${this.pathEntry}/set-assignment-agent-kanban`, body);
	}

	assignKanbanTaskLineNum(lines: TruckDistributionAssignmentUpdateLineNum[]) {
		const body = { lines };
		return this._ss.patch(`${this.pathEntry}/set-assignment-linenum-kanban`, body);
	}

	assignKanbanTaskDate(lines: TruckDistributionAssignmentUpdateDate[]) {
		const body = { lines };
		return this._ss.patch(`${this.pathEntry}/set-assignment-date-kanban`, body);
	}

	getDeliveryLinesList() {
		return this._ss.get<TruckDistributionDeliveryLinesResponse>(`${this.pathEntry}/get-all-delivery-lines`);
	}

	async handleFileUpload(file: File) {
		const fd = new FormData();

		fd.append(file.name, file);

		try {
			const res = await fetch(`${this._ss.CustomerInformation.CustServer}/${this.pathEntry}/upload-camera-picture`, {
				method: 'POST',
				body: fd,
			});

			if (!res.ok) {
				const data = await res.json();
				console.log(data);
				return false;
			}

			console.log('finished fetch');
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	async getTasksFromStorage() {
		let PODMain, PODSub;
		try {
			const stored = await this._idb.getTruckDistributionDB();
			if (stored && 'lastUpdated' in stored) {
				PODMain = stored.PODMain;
				PODSub = stored.PODSub;
			}
		} catch (error) {
			console.log(error);
		}

		if (PODMain && PODSub) {
			this.updateTasks({ main: PODMain, sub: PODSub });
		}
	}

	updateTasks(tasksSplit: { main: TruckDistributionMainSQL[]; sub: TruckDistributionSubSQL[] }) {
		const { main, sub } = tasksSplit;
		try {
			this._idb.storeTruckDistributionDB({ PODMain: main, PODSub: sub, lastUpdated: new Date().toISOString() });
		} catch (error) {
			console.log(error);
		}

		const subs = sub.reduce((prev, curr) => {
			prev[curr.DocNum] ||= [];
			prev[curr.DocNum].push(curr);
			return prev;
		}, {});

		const tasks: TruckDistributionSavedTask[] = main.map(main => ({ main, sub: subs[main.DocNum] }));

		this.savedTasks$.next(tasks);
	}

	getPDFTextOptions(): PDFTextOptions {
		const options = { ...POD_DEFAULT_PDF_TEXT_OPTIONS };
		if (!this._ss.CustomerInformation || !this._ss.CustomerInformation.LoadingAndDistribution_PODPDFOptions) return options;

		try {
			const parsed = JSON.parse(this._ss.CustomerInformation.LoadingAndDistribution_PODPDFOptions);
			return { ...options, ...parsed };
		} catch (error) {
			return options;
		}
	}

	resolvePODStatus(task: TruckDistributionSubSQL): TruckModeStatuses {
		return task.PODPackQTY === task.PackQTY && task.PODQuantity === task.Quantity ? this.taskLineStatus.isOK : this.taskLineStatus.mismatchedQuantity;
	}

	resolvePDFLink({ mode, filename }: PDFLinkOptions): string {
		let path = '';

		if (mode === 'PDFfile') {
			path = `podpdf`;
		} else {
			path = `docout`;
		}
		return `${this._ss.CustomerInformation.CustServer}/${path}/${filename}`;
	}

	handleCreateNewTask(body: { client: AllClients; agent: TruckDistributionUsers; date: string; remarks: string; typeofTask: string }) {
		const newDocNum = new Date().getTime().toString() + '000' + body.typeofTask;
		const translatedBody: Partial<TruckDistributionMainSQL> = {
			DocID: newDocNum,
			DocNum: newDocNum,
			DocType: body.typeofTask,
			Address: body.client.Address,
			City: body.client.CustCity,
			ClientID: body.client.ClientId,
			ClientName: body.client.Client_Name,
			AssignTo: body.agent.AgentID,
			PODDate: body.date,
			TeudaType: +body.typeofTask,
			PODStatus: '0',
		};
		return this._ss.post(`${this.pathEntry}/create-new-task`, translatedBody);
	}
}
