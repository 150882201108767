import { Pipe, PipeTransform } from '@angular/core';
import { Product2 } from '../Interfaces/all-products';

@Pipe({
	name: 'addItemExtraToName',
	pure: false,
})
export class AddItemExtraToNamePipe implements PipeTransform {
	constructor() {}
	valueToItemExtra = {
		'1': 'ItemExtra3',
	};
	transform(product: Product2, hasProductName_AddItemExtra?: string): string {
		if (!hasProductName_AddItemExtra || hasProductName_AddItemExtra === '0') return product.Product_Name || '';
		let string = product[this.valueToItemExtra[hasProductName_AddItemExtra]] || '';
		return `${product.Product_Name || ''} ${string}`;
	}
}
