import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/Interfaces/product';
import { AdminTypes } from 'src/app/Interfaces/users';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-stock-flag',
	templateUrl: './stock-flag.component.html',
	styleUrls: ['./stock-flag.component.scss'],
})

// implements OnInit
export class StockFlagComponent {
	@Input() product: Partial<Product>;
	@Input() Stock: number;
	@Input() ShowMlay: boolean = false;
	@Input() Admin: AdminTypes | null;
	@Input() showArrivalDate: boolean = true;
	@Input() isB2BLeasing = false;
	@Input() isMoveStockInline = false;

	windowWidth = window.innerWidth;
	// showTranslatedStock: boolean;

	constructor(public _ss: StoreService) {
		// this.showTranslatedStock = this._ss.CustomerInformation.StockWithItemPack === '1' && Boolean(this.ItemPack);
	}

	// ngOnInit(): void {}
}
