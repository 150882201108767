import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
	selector: 'app-report-table',
	templateUrl: './report-table.component.html',
	styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent implements OnInit, OnDestroy {
	constructor(private _util: UtilService, private _ss: StoreService) {}

	allocations = [];

	history = history;

	loading = true;

	destroy$ = new Subject<void>();

	ngOnInit(): void {
		this._ss
			.getAllotcationReports()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: any) => {
					this.allocations = res.recordset;

					this.loading = false;
				},
				error: err => {
					alert('ישנה תקלה בטעינת נתונים, אנא נסה שנית מאוחר יותר');
					this.history.back();
				},
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	valuesObject = {
		main: ['agent', 'agentname', 'productid', 'productname', 'fromdate', 'todate', 'muktze', 'nutzal', 'yetra', 'status'],
	} as const;

	translateForSort<T extends keyof typeof this.valuesObject = keyof typeof this.valuesObject>(value: typeof this.valuesObject[T][number], screen: T = Object.keys(this.valuesObject)[0] as T): void {
		let i: number,
			arrayToSort = this.allocations;

		i = this.valuesObject[screen].findIndex(arr => arr === value);

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}
}
