<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToClientTeudot()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">פירוט תעודה</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<section class="report-table" style="margin-top: 70px">
			<div class="content">
				<div class="report-table__row report-table__row_between"></div>

				<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
					<table class="report-table__table" cellspacing="0">
						<thead class="report-table__table-head">
							<tr>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('DocItemCode')" style="width: 150px">קוד פריט</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('DocItemName')" style="width: 250px">שם פריט</th>

								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('DocQuantity')" style="width: 150px">יחידות</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('DocPackQuantity')" style="width: 150px">{{ _text.packQuantityText }}</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('DocPrice')" style="width: 150px">מחיר יחידה</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('DocNet')" style="width: 150px">מחיר נטו</th>
								<th class="report-table__table-head-name sortable-clickable" (click)="translateForSort('DocTotalRow')" style="width: 150px">סכום</th>
							</tr>
						</thead>
						<tbody class="report-table__table-body">
							<tr *ngFor="let eceo of TeudotDetailsEach">
								<td class="report-table__table-body-name" style="width: 150px">{{ eceo.DocItemCode }}</td>
								<td class="report-table__table-body-name" style="width: 250px">{{ eceo.DocItemName }}</td>

								<td class="report-table__table-body-name" style="width: 150px">{{ eceo.DocQuantity }}</td>
								<td class="report-table__table-body-name" style="width: 150px">{{ eceo.DocPackQuantity }}</td>
								<td
									class="report-table__table-body-name"
									style="width: 150px"
									[style.fontWeight]="shouldColorRedAndBolden ? '600' : null"
									[style.color]="shouldColorRedAndBolden ? 'red' : null"
								>
									{{ eceo.DocPrice | number : '1.0-2' }}
								</td>
								<td
									class="report-table__table-body-name"
									style="width: 150px"
									[style.fontWeight]="shouldColorRedAndBolden ? '600' : null"
									[style.color]="shouldColorRedAndBolden ? 'red' : null"
								>
									{{ eceo.DocNet | number : '1.0-2' }}
								</td>
								<td
									class="report-table__table-body-name"
									style="width: 150px"
									[style.fontWeight]="shouldColorRedAndBolden ? '600' : null"
									[style.color]="shouldColorRedAndBolden ? 'red' : null"
									dir="ltr"
								>
									{{ eceo.DocTotalRow | number : '1.0-2' }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</section>

		<!-- markup for popup -->
		<div class="popup" *ngIf="PopDuplicate === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="NoDuplicate()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">לשכפל את התעודה ?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoDuplicate()">לא, חזור</button>
					<button class="order-cart__popup-button" (click)="DuplicateOrder()">כן, שכפל</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->

		<!-- markup for popup -->
		<div class="popup" *ngIf="ConfirmPop === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="Exit()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">תעודה שוכפלה בהצלחה</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="Exit()">חזור</button>

					<button class="order-cart__popup-button" (click)="WatchOrderDuplicate()">צפה בתעודה</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->
	</div>
</div>
