import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreService } from '../services/store.service';

@Injectable({
	providedIn: 'root',
})
export class CanEnterPageGuard implements CanActivate {
	constructor(private _ss: StoreService, private _title: Title, private _router: Router) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (
			this._ss.DocType === null ||
			this._ss.DocTypeName === null ||
			this._ss.DocType === undefined ||
			this._ss.DocTypeName === undefined ||
			localStorage.getItem('Updating') !== null ||
			this._ss.OneClientInfo === null
		) {
			if (window.location.href.includes('test.')) {
				this._title.setTitle('Test Env');
			} else {
				this._title.setTitle(environment.production ? 'Mobility' : 'Localhost');
			}
			const tree: UrlTree = this._router.parseUrl('/clients');
			return tree;
		}
		return true;
	}
}
