<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohotBizoim()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביצועים לקוחות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" *ngIf="FirstPage === true && LastPage === false">
	<div class="content">
		<h1 *ngIf="Loading === true" style="text-align: right; margin-top: 100px">טוען אנא המתן...</h1>

		<div class="flex-row flex-column" style="margin-top: 100px">
			<div class="flex-row">
				<form [formGroup]="form" (change)="ChangeMonth()">
					<select formControlName="Month" style="width: 150px; height: 35px">
						<option *ngFor="let m of months" [value]="m.value">{{ m.month }}</option>
					</select>
				</form>
				<div *ngIf="HasMultipleCompanys === '2'" style="margin-left: 25px">
					<form [formGroup]="form" (change)="ChangeCompany()">
						<select formControlName="Company" style="width: 150px; height: 35px">
							<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
						</select>
					</form>
				</div>
			</div>

			<div class="flex-row">
				<div class="box">
					<b>סה"כ</b>
					<span>
						{{ this.bizoimTotal$ | async | number : '1.0-2' }}
					</span>
				</div>
				<div class="box">
					<b>ממוצע</b>
					<span>
						{{ this.bizoimAverage$ | async | number : '1.0-2' }}
					</span>
				</div>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 100px">חודש</th>
						<th class="report-table__table-head-name" style="width: 130px">קוד לקוח</th>
						<th class="report-table__table-head-name" style="width: 250px">שם לקוח</th>
						<th class="report-table__table-head-name" style="width: 130px" (click)="SortThisYear()">מכירות השנה</th>
						<th class="report-table__table-head-name" *ngIf="hasYaad" style="width: 130px">יעד</th>
						<th class="report-table__table-head-name" *ngIf="hasYaad" style="width: 130px">אחוז התקדמות</th>
						<th class="report-table__table-head-name" style="width: 130px" (click)="SortLastYear()">מכירות אשתקד</th>
						<th class="report-table__table-head-name" style="width: 175px" (click)="SortAhoz()">גידול שנתי</th>
						<th class="report-table__table-head-name" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Bizoim" (click)="MoveToDohotBizoimLekohotEach(b.Cl, b.cm, b.Mm1, b.Yy1)">
						<td class="report-table__table-body-name" style="width: 150px">{{ b.Mm1 }}-{{ b.Yy1 }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ b.Cl }}</td>
						<td class="report-table__table-body-name" style="width: 250px">{{ b.Cn }}</td>
						<td class="report-table__table-body-name" style="width: 150px; position: relative" dir="ltr">
							{{ b.Tot | number : '1.0-2' }}
							<img
								*ngIf="+b.Ahuz !== 0 && b.Mm1 <= currentMonth + 1"
								[src]="b.Ahuz < 100 ? '../../../assets/images/arrow-down-red.svg' : '../../../assets/images/arrow-up-green.svg'"
								class="arrow"
							/>
						</td>
						<td class="report-table__table-body-name" style="width: 150px" *ngIf="hasYaad" dir="ltr">{{ b.Yaad2 || b.Yaad1 ? (b.Yaad2 || b.Yaad1 | number : '1.0-2') : 'אין נתון' }}</td>
						<td class="report-table__table-body-name" style="width: 150px" *ngIf="hasYaad" dir="ltr">
							{{ b.advancementPercentage ? (b.advancementPercentage | number : '1.0-2') : 'אין נתון' }}
						</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ b.Ltot | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ b.Ahuz | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name" style="width: 150px" *ngIf="HasMultipleCompanys > 1">{{ b.cm }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table" *ngIf="FirstPage === false && LastPage === false">
	<div class="content">
		<div class="flex-row" style="margin-top: 100px">
			<div class="box">
				<b>סה"כ</b>
				<span>
					{{ bizoimEachTotal$ | async | number : '1.0-2' }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ bizoimEachAverage$ | async | number : '1.0-2' }}
				</span>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">תאריך</th>
						<th class="report-table__table-head-name" style="width: 150px">מס תעודה</th>
						<th class="report-table__table-head-name" style="width: 150px">סכום</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of BizoimEach" (click)="OneTeuda(b.dn)">
						<td class="report-table__table-body-name" style="width: 150px">{{ b.dd }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ b.dn }}</td>
						<td class="report-table__table-body-name" style="width: 150px" dir="ltr">{{ b.tsum | number : '1.0-2' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table" *ngIf="LastPage === true">
	<div class="content">
		<!-- <div class="flex-row" style="margin-top:100px;">
      <div class="box">
        <b>סה"כ</b>
        <span>
          {{(oneTeudaInfoTotal$ | async ) | number : '1.0-2'}}
        </span>
      </div>
      <div class="box">
        <b>ממוצע</b>
        <span>
          {{oneTeudaInfoAverage$ | async | number : '1.0-2'}}
        </span>
      </div>
    </div> -->

		<div class="report-table__row report-table__row_full-width" style="margin-top: 98px; margin-right: 0">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 150px">קוד פריט</th>
						<th class="report-table__table-head-name" style="width: 250px">שם פריט</th>
						<th class="report-table__table-head-name" style="width: 150px">מחיר</th>
						<th class="report-table__table-head-name" style="width: 150px">כמות</th>
						<th class="report-table__table-head-name" style="width: 150px">הנחה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of OneTeudaInfo">
						<td class="report-table__table-body-name" style="width: 150px">{{ b.pr }}</td>
						<td class="report-table__table-body-name" style="width: 250px">{{ b.pn }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ b.prc | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ b.qu }}</td>
						<td class="report-table__table-body-name" style="width: 150px">{{ b.ds }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
