import { Pipe, PipeTransform } from '@angular/core';
import { visistsPlannedVsUnplannedStatuses } from '../Interfaces/SQL-reports-responses';

@Pipe({
	name: 'visitStatusText',
})
export class VisitStatusTextPipe implements PipeTransform {
	transform(value: typeof visistsPlannedVsUnplannedStatuses[number], ...args: unknown[]): string {
		switch (value) {
			case '0':
				return 'לא בוצע';
			case '1':
				return 'הזמנה ללא תכנון';
			case '2':
				return 'בוצע';
			case '3':
				return 'אי מכירה';

			default:
				return '';
		}
	}
}
