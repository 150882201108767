import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import { StoreService } from '../services/store.service';

@Directive({
	selector: '[appGetBackground]',
})
export class GetBackgroundDirective implements OnChanges {
	@Input() CC?: string;
	@Input() Azmana?: boolean | string;
	@Input() Ahzara?: boolean | string;
	@Input() Azaha?: boolean | string;
	@Input() Stock?: number | string;

	constructor(private element: ElementRef, private _ss: StoreService) {}

	ngOnChanges() {
		const el: HTMLDivElement = this.element.nativeElement;

		if (this.CC != null) {
			let color = this.CC;

			if (typeof this.CC === 'string') this.CC = this.CC.toLowerCase();

			if (!this.CC) {
				color = 'grey';
			}

			// ? orange == magenta according to original code ?
			if (this.CC === 'orange') {
				color = 'magenta';
			}

			el.style.background = color;
		}

		if ((this.Azmana || this.Ahzara || this.Azaha) != null) {
			let color;

			if (this.Azmana && !this.Ahzara) {
				color = 'var(--valid-color)';
			}

			if (this.Azmana && this.Ahzara) {
				color = 'orange';
			}

			if (!this.Azmana && this.Ahzara) {
				color = 'var(--invalid-color)';
			}

			if (!this.Azmana && !this.Ahzara && this.Azaha) {
				color = 'blue';
			}

			el.style.background = color;
		}

		if (this.Stock != null) {
			if (isNaN(+this.Stock)) {
				throw new Error('Stock is not a number');
			}

			let color = '#fbbdb4';
			let num = 100;

			if (this._ss.CustomerInformation.StockQuantity && +this._ss.CustomerInformation.StockQuantity >= 0) {
				num = +this._ss.CustomerInformation.StockQuantity;
			}

			if (+this.Stock >= num) {
				color = 'var(--valid-color)';
			}

			if (+this.Stock < num && +this.Stock > 0) {
				color = 'yellow';
			}

			el.style.background = color;
		}
	}
}
