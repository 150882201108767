import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { compress, decompress } from 'lz-string';

@Component({
	selector: 'app-dohotdinamicagent',
	templateUrl: './dohotdinamicagent.component.html',
	styleUrls: ['./dohotdinamicagent.component.scss'],
})
export class DohotdinamicagentComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public HeaderForDinamicDohot;
	public First = true;
	public Second = false;
	public HeaderForDinamicDohotSecond;

	reportSearch = null;

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		}

		this._ss.DinamicDohotFirst().subscribe(res => {
			this.HeaderForDinamicDohot = res;
			this.HeaderForDinamicDohot = this.HeaderForDinamicDohot.recordset;
		});
	}

	public BackToDohot() {
		if (this.First === true) {
			this.router.navigate(['dohot']);
		} else {
			this.Second = false;
			this.First = true;
		}
	}

	public SecondStep(repn) {
		this.First = false;
		this.Second = true;

		this._ss.DinamicDohotSecond(repn).subscribe(res => {
			this.HeaderForDinamicDohotSecond = res;
			this.HeaderForDinamicDohotSecond = this.HeaderForDinamicDohotSecond.recordset;
		});
	}

	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}
}
