import { FormGroup, ValidationErrors, FormControl, ValidatorFn } from '@angular/forms';

export const cardsExpectedProperties = {
	mastercard: {
		length: 16,
		startsWith: '5',
	},
	visa: {
		length: 16,
		startsWith: '4',
	},
	americanExpress: {
		length: 15,
		startsWith: '3',
	},
	diners: {
		length: 14,
		startsWith: '3',
	},
	isracardDebit: {
		length: 9,
	},
	isracardDirect: {
		length: 9,
	},
} as const;

// Credit Card Validators
export function validateCreditExpiryMonth(group: FormGroup): ValidationErrors | null {
	const month = group.get('month')?.value;
	const year = group.get('year')?.value;

	if (month < new Date().getMonth() + 1 && year === new Date().getFullYear().toString().slice(-2)) {
		return { month: true };
	}
	return null;
}

export function validateCreditCardNumber(group: FormGroup): ValidationErrors | null {
	const cardType = group.get('cardType')?.value;
	const number = group.get('number')?.value;

	const expectedLength = cardsExpectedProperties[cardType].length;
	const startsWith = cardsExpectedProperties[cardType]?.startsWith;

	if (number?.length !== expectedLength) {
		return { cardNumber: true };
	}

	if (!validateShouldStartWith(cardType, number, startsWith)) {
		return { cardNumber: true };
	}

	if (!checkCreditCardLuhn(cardType, number)) {
		return { cardNumber: true };
	}

	return null;
}

function validateShouldStartWith(cardType: string, number: string, startsWith: string) {
	return cardType === 'isracardDebit' || cardType === 'isracardDirect' || number.startsWith(startsWith);
}

function checkCreditCardLuhn(cardType: string, cardNumber: string) {
	if (cardType === 'isracardDebit' || cardType === 'isracardDirect') {
		return isValidIsracardNumber(cardNumber);
	}
	let sum = 0;
	let shouldDouble = false;
	for (let i = cardNumber.length - 1; i >= 0; i--) {
		let digit = +cardNumber[i];

		if (shouldDouble) {
			digit *= 2;
			if (digit > 9) digit -= 9;
		}

		sum += digit;
		shouldDouble = !shouldDouble;
	}

	return sum % 10 === 0;
}

function isValidIsracardNumber(possibleIsracardNumber: number | string | null | undefined) {
	const strNum = String(possibleIsracardNumber);
	if (typeof possibleIsracardNumber !== 'string' && typeof possibleIsracardNumber !== 'number') {
		return false;
	}

	let sum = 0;
	for (let i = 0; i < strNum.length; i++) {
		sum += +strNum[8 - i] * (i + 1);
	}

	return sum % 11 === 0;
}

export function validateIsraeliIDControlValidator(control: FormControl): ValidationErrors | null {
	if (isIsraeliIdValid(control.value)) {
		return null;
	}

	return { israeliID: true };
}

// General Validators
export function ValidateArrayLength(control: FormControl): ValidationErrors {
	if (!control.value?.length) {
		return { invalidArray: true };
	}
	return null;
}

export function validateThatIsNumber(control: FormControl): ValidationErrors | null {
	if (!isFinite(+control.value)) {
		return { notANumber: true };
	}
	return null;
}

function isIsraeliIdValid(id: string | number) {
	let strId = String(id).trim();
	if (strId.length > 9) {
		return false;
	}
	strId = strId.padStart(9, '0');
	let counter = 0;
	let rawVal: number;
	let actualVal: number;
	for (let i = 0; i < strId.length; i++) {
		rawVal = Number(strId[i]) * ((i % 2) + 1);
		actualVal = rawVal > 9 ? rawVal - 9 : rawVal;
		counter += actualVal;
	}
	return counter % 10 === 0;
}

export function validateMinDateForDateControl(minDate: Date | string | number): ValidatorFn {
	return (control: FormControl): ValidationErrors | null => {
		if (new Date(control.value) < new Date(minDate)) {
			return { minDate: true };
		}
		return null;
	};
}
