import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { catchError, from, map, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { MsgType } from 'src/app/Interfaces/alerts';

@Component({
	selector: 'app-clientactiv3',
	templateUrl: './clientactiv3.component.html',
	styleUrls: ['./clientactiv3.component.scss'],
})
export class Clientactiv3Component implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		private _acr: ActivatedRoute,
		private device: DeviceInfoService,
		private http: HttpClient,
		public _alert: AlertService,
	) {}

	form = this._fb.group({
		Report: ['', Validators.required],
		Reason: ['', Validators.required],
		Status: ['', Validators.required],
		reasonVisit: [<string | null>'', Validators.required],
	});
	showActivityProducts = true;
	showLogoutPopup = false;

	public ResAgentActivity;

	public ConfirmSentReport = false;

	public Reasons;

	reasonsForVisit = [];

	public TodayDate;

	public ReasonRemark = '';

	public NewCartBikor = new Date().getTime() + '000' + this._ss.AgentIdConnected;

	public Loading = false;

	public ShowExit = false;

	public BikorString;

	public KnisaTime;
	knisaTimeParsed;

	public PendingBikor;
	public FinishBikor;

	VisitID = '';
	image: File | null = null;

	readonly canAttachPicture = this._ss.CustomerInformation?.VisitsTakePicture === '1';
	readonly isNotWebview$ = this.device.isWebview$.pipe(map(value => !value));

	addToVisitAlertText = '';

	openOnExit = !!this._acr.snapshot.queryParams['exit'];

	canSelectMultipleReasons = this._ss.CustomerInformation?.VisitMultipleReasons === '1';

	@ViewChild('imageInput') imageInput: ElementRef<HTMLInputElement>;

	ngOnInit(): void {
		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
			return;
		}

		if (this.openOnExit) {
			this.showActivityProducts = false;
		}

		if (localStorage.getItem('knisa' + this._ss.OneClientInfo.ClientId) !== null) {
			this.ShowExit = true;

			this.KnisaTime = localStorage.getItem('knisa' + this._ss.OneClientInfo.ClientId);

			this.KnisaTime = JSON.parse(this.KnisaTime);
			this.knisaTimeParsed = new Date(this.KnisaTime);
		}

		this.PendingBikor = [];
		if (localStorage.getItem('PendingBikor') !== null) {
			this.PendingBikor = localStorage.getItem('PendingBikor');
			this.PendingBikor = JSON.parse(this.PendingBikor);
		}

		this.FinishBikor = [];
		if (localStorage.getItem('FinishBikor') !== null) {
			this.FinishBikor = localStorage.getItem('FinishBikor');
			this.FinishBikor = JSON.parse(this.FinishBikor);
		}

		if (localStorage.getItem('SavedExit' + this._ss.OneClientInfo.ClientId) !== null) {
			var save = localStorage.getItem('SavedExit' + this._ss.OneClientInfo.ClientId);

			const parsed = JSON.parse(save);

			this.Loading = true;
			setTimeout(() => {
				this._ss.BikorConfirmnew(save).subscribe(
					res => {
						if (res !== 'RequestError') {
							this.Loading = false;
							this.ConfirmSentReport = true;
							localStorage.removeItem('knisa' + this._ss.OneClientInfo.ClientId);
							localStorage.removeItem('SavedExit' + this._ss.OneClientInfo.ClientId);

							this.FinishBikor.push({ Exit: this._ss.OneClientInfo.ClientId });
							localStorage.setItem('FinishBikor', JSON.stringify(this.FinishBikor));

							this.PendingBikor.splice(this.PendingBikor.indexOf(this.PendingBikor.find(ob => ob.Knisa === this._ss.OneClientInfo.ClientId)), 1);
							localStorage.setItem('PendingBikor', JSON.stringify(this.PendingBikor));
						} else {
							alert('ישנה תקלה,אנא נסה שנית מאוחר יותר - ביקור נשמר');
							this.Loading = false;

							const ResonID = Array.isArray(this.form.controls['Reason'].value) ? this.form.controls['Reason'].value[0] : this.form.controls['Reason'].value;

							var newSave = {
								VisitID: this.NewCartBikor,
								ClientID: this._ss.OneClientInfo.ClientId,
								ResonID,
								Remark: this.StatusRemark,
								PicName: parsed.PicName || '0',
								company: this._ss.OneClientInfo.company,
								Latitude: '0',
								Longitude: '0',
								ResDesc: this.ReasonRemark,
								AgentID: this._ss.AgentIdConnected,
								ClientName: this._ss.OneClientInfo.Client_Name,
								DATAString: JSON.stringify(this.BikorString),
							};

							localStorage.setItem('SavedExit' + this._ss.OneClientInfo.ClientId, JSON.stringify(newSave));
						}
					},
					err => {
						alert('ישנה תקלה,אנא נסה שנית מאוחר יותר - ביקור נשמר');
						this.Loading = false;
						const ResonID = Array.isArray(this.form.controls['Reason'].value) ? this.form.controls['Reason'].value[0] : this.form.controls['Reason'].value;
						var newSave = {
							VisitID: this.NewCartBikor,
							ClientID: this._ss.OneClientInfo.ClientId,
							ResonID,
							ResonVisitID: this.form.controls.reasonVisit.value,
							Remark: this.StatusRemark,
							PicName: parsed.PicName || '0',
							company: this._ss.OneClientInfo.company,
							Latitude: '0',
							Longitude: '0',
							ResDesc: this.ReasonRemark,
							AgentID: this._ss.AgentIdConnected,
							ClientName: this._ss.OneClientInfo.Client_Name,
							DATAString: JSON.stringify(this.BikorString),
						};

						localStorage.setItem('SavedExit' + this._ss.OneClientInfo.ClientId, JSON.stringify(newSave));
					},
				);
			}, 200);
		} else {
			if (localStorage.getItem('reasonsbikor') !== null) {
				this.Reasons = localStorage.getItem('reasonsbikor');
				this.Reasons = JSON.parse(this.Reasons);
				console.log(this.Reasons);
			}
			this._ss.GetAllReportsAgentnew().subscribe(res => {
				this.ResAgentActivity = res;
				this.ResAgentActivity = this.ResAgentActivity.recordset;

				this._ss.GetAllReportsAgentReasons().subscribe(res => {
					if (typeof res === 'string') {
						return;
					}
					this.Reasons = res;
					this.Reasons = this.Reasons.recordset;

					if (this.Reasons.some(r => [2, 3].includes(r.rem))) {
						this.Reasons = res.recordset.filter(r => [0, 1].includes(r.rem));
						this.reasonsForVisit = res.recordset.filter(r => [2, 3].includes(r.rem));

						for (const key in this.reasonsForVisit) {
							const element = this.reasonsForVisit[key];

							const visits = this.ResAgentActivity.filter(r => r.ResonVisitID);

							for (const visit of visits) {
								visit.ResonVisitIDText = element.rds;
							}
						}
					}
					localStorage.setItem('reasonsbikor', JSON.stringify(this.Reasons));
				});
			});
		}
	}

	ngOnDestroy(): void {
		if (this._acr.snapshot.queryParams['exit']) {
			const clientid = this._ss.OneClientInfo?.ClientId;
			localStorage.removeItem('knisa' + clientid);
			localStorage.removeItem('PendingBikor');
		}
	}

	public Knisa() {
		this.TodayDate = new Date();
		localStorage.setItem('knisa' + this._ss.OneClientInfo.ClientId, JSON.stringify(this.TodayDate));

		alert(' כניסה ללקוח ' + this._ss.OneClientInfo.Client_Name + ' בשעה ' + this.TodayDate.toString().slice(15, 24));

		this.PendingBikor.push({ Knisa: this._ss.OneClientInfo.ClientId });

		localStorage.setItem('PendingBikor', JSON.stringify(this.PendingBikor));

		// this.router.navigate(['client-card']);
		history.back();
	}

	public CloseSentReportPopOpen() {
		this.ConfirmSentReport = false;

		this.form.reset();

		this.NewCartBikor;

		// this.router.navigate(['client-card']);
		history.back();
	}

	public RemCheck;

	public StatusRemark = '';

	public ExitTime;
	public ConfirmBikor() {
		if (this._ss.CustomerInformation?.BikorMustIncludeReason === '1' && !this.form.controls.Reason.value?.length) {
			alert('חייב לבחור סיכום ביקור');
			return;
		}

		if (this.canAttachPicture && this.imageInput.nativeElement.classList.contains('uploading')) {
			alert('אנא המתינו לסיום העלאת תמונה');
			return;
		}

		this.ExitTime = JSON.stringify(new Date());

		this.Loading = true;

		this.StatusRemark = this.form.controls['Report'].value;

		if (this.canSelectMultipleReasons && this.form.controls['Reason'].value?.length) {
			const statusRemarkReasonsText = 'סיבות שנבחרו: ' + (this.form.controls['Reason'].value as unknown as string[]).map(id => this.Reasons.find(r => r.rid === id)?.rds).join(', ') + '.';
			const id = this.form.controls['Reason'].value[0];
			this.ReasonRemark = this.Reasons.find(r => r.rid === id)?.rds ?? '';
			if (this.ReasonRemark) {
				this.StatusRemark = statusRemarkReasonsText + ' ' + this.StatusRemark;
			}
		} else {
			for (let x = 0; x < this.Reasons.length; x++) {
				if (this.form.controls['Reason'].value === this.Reasons[x].rid) {
					this.ReasonRemark = this.Reasons[x].rds;
					break;
				}
			}
		}

		if (this.form.controls['Reason'].value === '5' && this.form.controls['Report'].value.length === 0) {
			alert('חייב להכניס פירוט אי מכירה');
			this.Loading = false;
			return;
		}

		let intDate = +new Date();

		this.NewCartBikor = JSON.stringify(intDate);

		this.NewCartBikor = this.NewCartBikor + '000' + this._ss.AgentIdConnected;

		const ResonID = Array.isArray(this.form.controls['Reason'].value) ? this.form.controls['Reason'].value[0] : this.form.controls['Reason'].value;

		this.BikorString = {
			Enter: this.KnisaTime,
			Exit: this.ExitTime,
			VisitID: this.NewCartBikor,
			ClientID: this._ss.OneClientInfo.ClientId,
			ResonID,
			Remark: this.StatusRemark,
			PicName: this.image?.name || '0',
			company: this._ss.OneClientInfo.company,
			Latitude: '0',
			Longitude: '0',
			ResDesc: this.ReasonRemark,
			AgentID: this._ss.AgentIdConnected,
			ClientName: this._ss.OneClientInfo.Client_Name,
		};

		if (this.addToVisitAlertText) {
			this.BikorString.addToVisitAlertText = this.addToVisitAlertText;
		}

		setTimeout(() => {
			this._ss
				.BikorConfirmnew({
					VisitID: this.NewCartBikor,
					ClientID: this._ss.OneClientInfo.ClientId,
					ResonID,
					Remark: this.StatusRemark,
					PicName: this.image?.name || '0',
					company: this._ss.OneClientInfo.company,
					Latitude: '0',
					Longitude: '0',
					ResDesc: this.ReasonRemark,
					AgentID: this._ss.AgentIdConnected,
					ClientName: this._ss.OneClientInfo.Client_Name,
					DATAString: JSON.stringify(this.BikorString),
				})
				.subscribe(
					res => {
						if (res !== 'RequestError') {
							this.Loading = false;
							this.ConfirmSentReport = true;
							localStorage.removeItem('knisa' + this._ss.OneClientInfo.ClientId);

							this.FinishBikor.push({ Exit: this._ss.OneClientInfo.ClientId });
							localStorage.setItem('FinishBikor', JSON.stringify(this.FinishBikor));

							this.PendingBikor.splice(this.PendingBikor.indexOf(this.PendingBikor.find(ob => ob.Knisa === this._ss.OneClientInfo.ClientId)), 1);
							localStorage.setItem('PendingBikor', JSON.stringify(this.PendingBikor));
						} else {
							alert('ישנה תקלה,אנא נסה שנית');
							this.Loading = false;

							const ResonID = Array.isArray(this.form.controls['Reason'].value) ? this.form.controls['Reason'].value[0] : this.form.controls['Reason'].value;

							var save = {
								VisitID: this.NewCartBikor,
								ClientID: this._ss.OneClientInfo.ClientId,
								ResonID,
								Remark: this.StatusRemark,
								PicName: this.image?.name || '0',
								company: this._ss.OneClientInfo.company,
								Latitude: '0',
								Longitude: '0',
								ResDesc: this.ReasonRemark,
								AgentID: this._ss.AgentIdConnected,
								ClientName: this._ss.OneClientInfo.Client_Name,
								DATAString: JSON.stringify(this.BikorString),
							};

							localStorage.setItem('SavedExit' + this._ss.OneClientInfo.ClientId, JSON.stringify(save));
						}
					},
					err => {
						alert('ישנה תקלה,אנא נסה שנית');
						this.Loading = false;
						const ResonID = Array.isArray(this.form.controls['Reason'].value) ? this.form.controls['Reason'].value[0] : this.form.controls['Reason'].value;
						var save = {
							VisitID: this.NewCartBikor,
							ClientID: this._ss.OneClientInfo.ClientId,
							ResonID,
							Remark: this.StatusRemark,
							PicName: '0',
							company: this._ss.OneClientInfo.company,
							Latitude: '0',
							Longitude: '0',
							ResDesc: this.ReasonRemark,
							AgentID: this._ss.AgentIdConnected,
							ClientName: this._ss.OneClientInfo.Client_Name,
							DATAString: JSON.stringify(this.BikorString),
						};
						localStorage.setItem('SavedExit' + this._ss.OneClientInfo.ClientId, JSON.stringify(save));
					},
				);
		}, 200);
	}

	setImage(event): void {
		const input = event.target as HTMLInputElement;
		input.classList.remove('error');
		input.classList.add('uploading');

		if (!event.target?.files?.length) {
			input.classList.remove('uploading', 'success');
			return;
		}

		const file = new File(event.target.files, `${this.NewCartBikor}.jpg`, { type: event.target.files[0].type });

		this.handleFileUpload(file)
			.pipe(
				switchMap(bool => {
					if (bool) {
						this.image = file;
					} else {
						input.classList.add('error');
					}
					return from([bool]);
				}),
			)
			.subscribe({
				next: () => {
					input.classList.remove('uploading');
				},
				error: error => {
					console.log(error);
					input.classList.add('error');
					input.classList.remove('uploading');
				},
			});
	}

	handleFileUpload(file: File): Observable<boolean> {
		const fd = new FormData();
		fd.append(file.name, file);

		return this.http.post(`${this._ss.CustomerInformation.CustServer}/mobil/new-image/visits`, fd).pipe(
			map(() => true), // If the response is successful, return true
			catchError(error => {
				console.log(error);
				return from([false]);
			}),
		);
	}

	refreshVisitID() {
		this.VisitID = `${JSON.stringify(new Date().getTime())}000${this._ss.AgentIdConnected}`;
	}

	showHideLogoutPopup(value: boolean): void {
		this.showLogoutPopup = value;
	}

	newExitReprot() {
		this.showActivityProducts = false;
		this._alert.handleAlertLogic(MsgType.newVisit.msgType);
		const anyAlerts = this._alert.handleFilterRelevantAlerts(MsgType.newVisit.msgType);

		if (!anyAlerts.length) return;

		this.addToVisitAlertText = anyAlerts.map(alert => alert.Msg).join(', ');
	}

	public BackToClientCard() {
		history.back();
	}

	navigateToAlerts(): void {
		this.router.navigate(['manage-alerts'], { queryParams: { newAlert: true, backTwice: this.openOnExit ? null : true }, replaceUrl: true });
	}
}
