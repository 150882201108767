import { Directive, ElementRef, OnChanges, OnInit } from '@angular/core';

@Directive({
	selector: '[appSetTextColorToFitBackgroundContrast]',
})
export class SetTextColorToFitBackgroundContrastDirective implements OnChanges, OnInit {
	constructor(private element: ElementRef) {}

  ngOnInit() {
    this.ngOnChanges();
  }

	ngOnChanges() {
		const el: HTMLDivElement = this.element.nativeElement;

		el.style.color = 'black';

		const bgColor = getComputedStyle(el).backgroundColor;

		if (!bgColor || !bgColor.includes('rgb')) return;

		const red = parseInt(bgColor.split(',')[0].split('(')[1]);
		const green = parseInt(bgColor.split(',')[1]);
		const blue = parseInt(bgColor.split(',')[2].split(')')[0]);

		const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

		const color = brightness > 125 ? 'black' : 'white';

		el.style.color = color;
	}
}
