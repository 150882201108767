<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__title col">
						{{ mainScreen | getNewReceiptScreenTitle : OneClientInfo.Client_Name : isEdittingCheque }}
					</div>
				</div>
			</div>
		</header>

		<!-- shop return activity markup -->
		<section class="shop-activity">
			<div class="content">
				<!-- First Screen -->
				<div class="shop-activity__product-summ" *ngIf="mainScreen">
					<ng-container *ngIf="_cc.creditCardFormValues$ | async as formValues">
						<div class="shop-activity__item">
							<div class="shop-activity__item-row shop-activity__item-row_gray row">
								<div class="shop-activity__item-title">לתשלום:</div>
								<div class="shop-activity__item-text shop-activity__item-text_bold" [ngClass]="{ 'invalid-color': totalLeftToPay > 0, 'valid-color': totalLeftToPay < 0 }">
									{{ totalLeftToPay | number : '1.0-2' }}
								</div>
							</div>
							<div class="shop-activity__item-row shop-activity__item-row_gray row">
								<div class="shop-activity__item-title">מס קבלה:</div>
								<div class="shop-activity__item-text shop-activity__item-text_bold">{{ receiptDocNum }}</div>
							</div>
							<div class="shop-activity__item-row shop-activity__item-row_double row" *ngIf="DebtArray.length">
								<div class="shop-activity__item-title_double shop-activity__item-text_bold underline fit-content text-align-center">אסמכתאות:</div>
								<div class="shop-activity__item-text_spread">{{ refrenceNums }}</div>
							</div>
							<div class="shop-activity__item-row row flex">
								<form [formGroup]="form" dir="rtl" autocomplete="off" class="flex flex-column">
									<mat-form-field color="primary">
										<mat-label>הערות לקבלה</mat-label>
										<input matInput formControlName="remarks" />
									</mat-form-field>
									<mat-form-field color="primary">
										<mat-label>מזומן</mat-label>
										<input matInput placeholder="מזומן" type="number" formControlName="cash" (blur)="formatNumber('cash')" />
										<button type="button" matSuffix mat-icon-button [disabled]="totalLeftToPay < 1" (click)="fillInputWithRest('cash')">
											<mat-icon>keyboard_double_arrow_right</mat-icon>
										</button>
										<mat-error *ngIf="form.controls.cash.invalid">סכום אינו תקין</mat-error>
									</mat-form-field>
								</form>
							</div>
							<div class="shop-activity__item-row row">
								<div class="flex flex-wrap pad-inside">
									<!-- <div class="shop-activity__item-title justify-align-center">המחאות:</div> -->
									<mat-chip-list dir="rtl" *ngIf="_receipts.chequesArray?.length">
										<mat-chip *ngFor="let chq of _receipts.chequesArray; let i = index" style="padding: 0 12px" (click)="chipClicked(i)">
											{{ chq.chequeNumber }}
											<mat-icon matChipAvatar>edit_note</mat-icon>
										</mat-chip>
									</mat-chip-list>
								</div>
								<div class="shop-activity__item-row row">
									<div class="shop-activity__item-title">
										סה"כ המחאות: <b>{{ totalChequesPaid | number : '1.0-2' }}</b>
									</div>
								</div>
								<div class="flex justify-align-center">
									<button class="product-detail__button flex justify-align-center" (click)="newCheque(false)">המחאה חדשה</button>
								</div>
							</div>
							<ng-container *ngIf="canPayWithCreditCard && DebtArray.length && TotalSumDebtChecked > 0">
								<div class="shop-activity__item-row row">
									<div class="flex flex-wrap pad-inside">
										<!-- <div class="shop-activity__item-title justify-align-center">אשראי:</div> -->
										<mat-chip-list dir="rtl" *ngIf="formValues.number">
											<mat-chip style="padding: 0 12px" (click)="newCreditCard('form')">
												<span class="chip-credit-card">
													{{ formValues.number.slice(-4) + '*'.repeat(formValues.number.length - 4) }}
												</span>
												<mat-icon matChipAvatar>edit_note</mat-icon>
											</mat-chip>
										</mat-chip-list>
									</div>
									<div class="shop-activity__item-row row">
										<div class="shop-activity__item-title">
											סה"כ אשראי: <b>{{ formValues.amount ?? 0 | number : '1.0-2' }}</b>
										</div>
									</div>
									<div class="flex justify-align-center">
										<button class="product-detail__button product-detail__button-no-after flex justify-align-center" (click)="newCreditCard('form')">פרטי אשראי</button>
									</div>
								</div>
							</ng-container>
							<div class="shop-activity__item-row row">
								<div class="shop-activity__item-title">
									סה"כ שולם: <b>{{ totalPaid | number : '1.0-2' }}</b>
								</div>
							</div>
						</div>
						<div class="shop-activity__action">
							<button
								*ngIf="!isSendingReceipt; else sendingReceiptLoadingBlock"
								class="shop-activity__action-button shop-activity__action-button_gray"
								[ngClass]="{
									'valid-background': (totalPaid && (!formValues?.amount || canProduceReceiptWithoutFullPaymentWithCreditCard)) || (formValues?.amount && totalLeftToPay === 0)
								}"
								[attr.disabled]="totalPaid < 1 || (formValues?.amount && totalPaid !== 0 && !canProduceReceiptWithoutFullPaymentWithCreditCard) || null"
								(click)="handleReceiptSubmitProcess()"
							>
								הפק קבלה
							</button>
							<ng-template #sendingReceiptLoadingBlock>
								<div class="shop-activity__action-sending">
									<p>שולח...</p>
									<mat-progress-bar mode="indeterminate"></mat-progress-bar>
								</div>
							</ng-template>
							<span class="floating-receipt-sum-error" [ngClass]="{ show: formValues?.amount && totalLeftToPay !== 0 && !canProduceReceiptWithoutFullPaymentWithCreditCard }"
								>לא ניתן לחרוג מסה"כ לתשלום בקבלת אשראי</span
							>
							<div class="shop-activity__action-text-button" (click)="handleBack('main')">בטל</div>
						</div>
					</ng-container>
				</div>

				<!-- Second Screen -->
				<div class="shop-activity__product-summ" *ngIf="!mainScreen">
					<app-credit-card-payment
						[maxAmount]="TotalSumDebtChecked - totalPaid"
						[isEdittingExistingCard]="isEdittingExistingCard"
						(submitted)="handleCreditCardSubmit($event)"
						(resetted)="handleCreditCardFormReset()"
						[needsToSpecifyAmount]="true"
						*ngIf="newCredit; else chequeFormBlock"
					></app-credit-card-payment>
					<ng-template #chequeFormBlock>
						<div class="shop-activity__item">
							<form [formGroup]="chequeForm" dir="rtl" autocomplete="off" class="flex flex-column chequeForm" (submit)="handleChequeSubmit()">
								<mat-form-field color="primary" *ngIf="!(allBanks$ | async).length; else bankSelectBlock">
									<mat-label>בנק</mat-label>
									<input matInput placeholder="מס בנק" type="number" formControlName="bank" [readonly]="isEdittingCheque" />
									<mat-error *ngIf="chequeForm.controls.bank.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<ng-template #bankSelectBlock>
									<mat-form-field color="primary">
										<mat-label>בנק</mat-label>
										<input type="text" placeholder="בנק" aria-label="bank" matInput formControlName="bank" [matAutocomplete]="auto" [readonly]="isEdittingCheque" />
										<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
											<mat-option *ngFor="let bank of filteredBanks$ | async" [value]="bank.Bank_Code"> {{ bank.Bank_Code }} - {{ bank.Bank_Name }} </mat-option>
										</mat-autocomplete>
										<mat-error *ngIf="chequeForm.controls.bank.invalid && chequeForm.controls.bank.touched && !chequeForm.controls.bank.hasError('noBank')">שדה חסר או אינו תקין</mat-error>
										<mat-error *ngIf="chequeForm.controls.bank.invalid && chequeForm.controls.bank.touched && chequeForm.controls.bank.hasError('noBank')"
											>יש לבחור בנק מרשימת הבנקים</mat-error
										>
									</mat-form-field>
								</ng-template>
								<ng-container *ngIf="branchesDisplay$ | async as branches; else branchInputBlock">
									<mat-form-field color="primary" *ngIf="branches.length; else branchInputBlock">
										<mat-label>סניף</mat-label>
										<input type="text" placeholder="סניף" aria-label="branch" matInput formControlName="branch" [matAutocomplete]="auto" [readonly]="isEdittingCheque" />
										<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
											<mat-option *ngFor="let branch of filteredBranches$ | async" [value]="branch.Branch_Code"> {{ branch.Branch_Code }} - {{ branch.Branch_Name }} </mat-option>
										</mat-autocomplete>
										<mat-error *ngIf="chequeForm.controls.branch.invalid && !chequeForm.controls.branch.hasError('noBranch')">שדה חסר או אינו תקין</mat-error>
										<mat-error *ngIf="chequeForm.controls.branch.touched && chequeForm.controls.branch.hasError('noBranch')">יש לבחור סניף מרשימת הסניפים</mat-error>
									</mat-form-field>
								</ng-container>
								<ng-template #branchInputBlock>
									<mat-form-field color="primary">
										<mat-label>סניף</mat-label>
										<input matInput placeholder="סניף" type="number" formControlName="branch" [readonly]="isEdittingCheque" />
										<mat-error *ngIf="chequeForm.controls.branch.invalid">שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
								</ng-template>
								<mat-form-field color="primary">
									<mat-label>חשבון</mat-label>
									<input matInput placeholder="חשבון" type="number" formControlName="accountNumber" [readonly]="isEdittingCheque" />
									<mat-error *ngIf="chequeForm.controls.accountNumber.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>מספר צ'ק</mat-label>
									<input matInput placeholder="מספר צ'ק" type="number" formControlName="chequeNumber" [readonly]="isEdittingCheque" />
									<mat-error *ngIf="chequeForm.controls.chequeNumber.invalid">שדה חסר או אינו תקין</mat-error>
									<mat-error *ngIf="chequeForm.hasError('repeating')">צ'ק זה כבר קיים</mat-error>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>הערת המחאה</mat-label>
									<input matInput placeholder="הערות" type="string" formControlName="remark" />
									<mat-error *ngIf="chequeForm.controls.remark.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>תאריך פרעון</mat-label>
									<input readonly="true" [min]="dates.min" [max]="dates.max" matInput [matDatepicker]="picker" formControlName="dueDate" (click)="picker.open()" />
									<mat-error *ngIf="chequeForm.controls.dueDate.invalid">תאריך אינו תקין</mat-error>
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>סכום</mat-label>
									<input matInput placeholder="סכום" type="number" formControlName="amount" (blur)="formatNumber('amount')" />
									<button type="button" matSuffix mat-icon-button [disabled]="totalLeftToPay < 1 || isEdittingCheque" (click)="fillInputWithRest('amount')">
										<mat-icon>keyboard_double_arrow_right</mat-icon>
									</button>
									<mat-hint
										>נשאר לתשלום:
										{{(totalLeftToPay |
											displayAmountLeftToPay:chequeForm.controls.amount.value:isEdittingCheque:(_receipts.chequesArray?.[chequeIndex]?.amount
											|| 0)) | number : '1.0-2'}}
									</mat-hint>
									<mat-error *ngIf="chequeForm.controls.amount.getError('required')">שדה חסר או אינו תקין</mat-error>
									<mat-error *ngIf="chequeForm.controls.amount.getError('min')">סכום חייב להיות מעל 0</mat-error>
								</mat-form-field>
								<div class="shop-activity__action" *ngIf="!isEdittingCheque; else elseBlock">
									<button class="shop-activity__action-button shop-activity__action-button_gray" [ngClass]="{ 'valid-background': chequeForm.valid }">אשר המחאה</button>
									<div class="shop-activity__action-text-button" (click)="handleBack('cheque')">בטל</div>
								</div>
								<ng-template #elseBlock>
									<div class="shop-activity__action">
										<button class="shop-activity__action-button shop-activity__action-button_gray" style="width: 33%" [ngClass]="{ 'valid-background': chequeForm.valid }">אשר</button>
										<div class="shop-activity__action-text-button" (click)="handleBack('cheque')">שכח שינויים</div>
										<button type="button" class="shop-activity__action-button" style="width: 33%" (click)="deleteCheque(chequeIndex)">מחק המחאה</button>
									</div>
								</ng-template>
							</form>
						</div>
					</ng-template>
				</div>
			</div>
		</section>
		<!-- end shop return activity markup -->
	</div>
</div>
