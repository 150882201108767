export const customerInformationMockTest = {
	CustNum: '.30000',
	CustName: 'בדיקה',
	CustServer: 'http://localhost:1000',
	CustERP: '',
	CustNotes: '',
	CustNotes1: '',
	CustDomain: '',
	CustImageDomain: 'http://localhost:1000/assets',
	CustDashbord: '',
	CustVersion: '0.0',
	CustAhuzAnaha: '0',
	CustSeeImages: '0',
	CustSeeImageGeneral: '0',
	CustShowExtraHarot: '0',
	CustBlockParam3: '0',
	CustSeeArizot: '0',
	CustSeePrice: '0',
	CustChangeCss: '0',
	CustChangeCss1: '0',
	CustChangeCss2: '0',
	CustChangeCss3: '0',
	CustChangeCss4: '0',
	CustChangeCss5: '0',
	CustExtra: '',
	CustExtra1: '',
	CustExtra2: '',
	CustExtra3: '',
	CustExtra4: '',
	CustExtra5: '',
	CustIP: '0.0.0.0',
	CustChange2: '0',
	CustChange3: '0',
	CustChange4: '0',
	CustChange5: '0',
	CustChange6: '0',
	CustChange7: '0',
	CustChange8: '0',
	CustChange9: '0',
	CustChange1: '1',
	SeeDeliveryOptions: 0,
	EditProductName: '0',
	EditTeuda: '0',
	SeeProductName: '0',
	SeeBarcode: '0',
	SeeProductId: '0',
	BlueOrRed: '0',
	AnyDesk: '',
	HasMobilityService: 0,
	EditDate: 0,
	ShowProduct: '0',
	InputBarcodeNumeric: '0',
	SkipRequest: '0',
	OrderByInDashboard: '0',
	CheckStockBeforeSend: '0',
	DisplayWithVat: '0',
	Submanagers: null,
	CopyRemark: '0',
	SendPic: '',
	RekActive: '0',
	Graph: null,
	PrintOrder: '0',
	PDFDirectory: '',
	ItemExtraTitle: '0',
	AgentSeeDashboard: '0',
	IsPackDefault: '0',
	HideMinPrice: '0',
	ShowFileDownload: '0',
	LoadDebtOnUpdate: '0',
	BlockCopyCustPrice: '0',
	ShowObligoInstead: '0',
	PasswordFromMobility: '0',
	CustomLogoutCode: '0',
	AddToSupplyDate: '0',
	HideStock: '0',
	EnforceDefaultOrderBy: '0',
	RecvVproductsWhenEnter: '0',
	AllProductsHistoryFirst: '0',
	SpecificCompanyVersion: '0',
	CanProduceReceipt: '0',
	RefreshClients: '0',
	DisplayLogo: '0',
	UpdateLogic: '0',
	ProductExtraInfo: '0',
	CanSendEmail: '0',
	B2BOptions: '0',
	OrderDepartments: '0',
	PopularDefault: '0',
	ReportsOptions: '0',
	UnBlockQty: '0',
	SortProductsBy: '0',
	BonusDisplay: '0',
	DiscountLogic: '0',
	BlockChangingPriceBonusDiscountOrQuantities: '0',
	DisplayRemark: null,
	HasMultipleCompanies: '0',
	ItemPackDisplay: '0',
	ShowInlineProduct: '0',
	SearchLogic: '0',
	HeaderDisplay: '0',
	ItemExtrasFilter: '0',
	OfflineLogic: '0',
	MainColor: '#f65944',
	MinimumTotalOrderB2B: '0',
	ClientsPageExtraInfo: '0',
	SortByLastDateTeuda: '0',
	AlertHearotWhenEnter: '0',
	OnlyInClientPriceList: '0',
	DisplayDohotTeudot: null,
	HasSpecials: '0',
	SortWhenEnter: '0',
	YaadAgents: '0',
	YaadClients: '0',
	ProductsFilterRow: '0',
	TemporaryPassword: '1',
	SendDiscount: '0',
	TeudaItemRemark: '0',
	CheckPassword: '0',
	ColorClientName: '0',
	AlternativeAdminReports: '0',
	StockQuantity: '',
	ProductDescription: '0',
	HideReport: '',
	HideQuantityInPack: '0',
	BlockIncreasePrice: '0',
	BikorMustIncludeReason: '0',
	CanChangePermPrice: '0',
	GetByMainCustID: '0',
	ShowDebtTerm: '0',
	NeedsPasswordFromMobility: '0',
	ProductExtraInfoOrder: '0',
	WhatsappB2BL: '0',
	SendCoordinates: '0',
	MainColorCompany2: '0',
	LikutType: '0',
	B2BCanSeePrices: '0',
	FilterMehironimOnBackFromTeuda: '0',
	LikutReportsCanSee: '0',
	BlockYehidotPlusIfBelowStockOne: '0',
	SearchExtraTeudaTypeFromSave: '',
	SearchExtraProductFilter: '',
	LikutSearch: '0',
	LikutRemarkExtra1: '0',
	GetOriginalStock: '0',
	ImageFilenameLookup: '0',
	PackQuantityText: '0',
	BlockClientTeudaSend: '0',
	StockWithItemPack: '0',
	MaxProductNameLength: '0',
	MaslulDefaultClients: '0',
	VisitsPlannedVsUnplanned: '0',
	LoadingAndDistribution: '0',
	LoadingAndDistribution_Quantities: '0',
	ConcentratedProducts: '0',
	ConcentratedProducts_Display: '0',
	ShowVisitInClientsPage: '1',
	VisitsTakePicture: '1',
	VisitsHasNoSaleOption: '1',
	SpecialOnlyIfRemarkContains: '1',
	LikutPrice: '0',
	LoadingAndDistribution_Manager_Loading: '0',
	LoadingAndDistribution_TaskAssignmentExtra: '0',
	LoadingAndDistribution_TaskAssignmentDisplay: '0',
	LoadingAndDistribution_CanAttachPictures: '0',
	LoadingAndDistribution_Manager_POD: null,
	ShowSpecificationsFileDownload: '0',
	CalculatedDiscount: '0',
	DashboardSeeTrucks: '0',
};

export const userResponseMock = {
	UserId: 60,
	UserName: 'נדב',
	Password: '0',
	Admin: 'true',
	Logged: '1',
	AgentID: '17',
	AgentPassword: '0',
	AgentEmail: '0',
	CustNum: '10356',
	AgentPhoneMobile: '10356',
	AgentExtra1: '',
	AgentExtra2: '0',
	AgentWarehouse: '',
	HasMainGroup: 0,
	AddProduct: 0,
	IsHistFirst: 0,
	SuperUser: 0,
	ordon: 1,
	hazaon: 0,
	reton: 1,
	ShowProfit: 0,
	IgnorMinPrice: 0,
};
export const customerInformationMockIdan88 = {
	CustNum: '20840',
	CustName: 'עידן 88',
	CustServer: 'https://idan88.mobilityltd.net:1000',
	CustERP: '',
	CustNotes: '',
	CustNotes1: '',
	CustDomain: '',
	CustImageDomain: 'https://idan88.mobilityltd.net:1000/assets',
	CustDashbord: '',
	CustVersion: '0.0',
	CustAhuzAnaha: '0',
	CustSeeImages: '0',
	CustSeeImageGeneral: '0',
	CustShowExtraHarot: '0',
	CustBlockParam3: '0',
	CustSeeArizot: '2',
	CustSeePrice: '0',
	CustChangeCss: '0',
	CustChangeCss1: '0',
	CustChangeCss2: '0',
	CustChangeCss3: '0',
	CustChangeCss4: '0',
	CustChangeCss5: '0',
	CustExtra: '',
	CustExtra1: '',
	CustExtra2: '',
	CustExtra3: '',
	CustExtra4: '',
	CustExtra5: '',
	CustIP: '0.0.0.0',
	CustChange2: '0',
	CustChange3: '0',
	CustChange4: '0',
	CustChange5: '1',
	CustChange6: '0',
	CustChange7: '0',
	CustChange8: '0',
	CustChange9: '0',
	CustChange1: '0',
	SeeDeliveryOptions: 0,
	EditProductName: '0',
	EditTeuda: '0',
	SeeProductName: '0',
	SeeBarcode: '0',
	SeeProductId: '0',
	BlueOrRed: '0',
	AnyDesk: '',
	HasMobilityService: 0,
	EditDate: 0,
	ShowProduct: '0',
	InputBarcodeNumeric: '0',
	SkipRequest: '0',
	OrderByInDashboard: '0',
	CheckStockBeforeSend: '0',
	DisplayWithVat: '0',
	Submanagers: null,
	CopyRemark: '0',
	SendPic: '',
	RekActive: '0',
	Graph: null,
	PrintOrder: '0',
	PDFDirectory: '',
	ItemExtraTitle: '0',
	AgentSeeDashboard: '0',
	IsPackDefault: '0',
	HideMinPrice: '0',
	ShowFileDownload: '0',
	LoadDebtOnUpdate: '0',
	BlockCopyCustPrice: '0',
	ShowObligoInstead: '0',
	PasswordFromMobility: '0',
	CustomLogoutCode: '0',
	AddToSupplyDate: '0',
	HideStock: '0',
	EnforceDefaultOrderBy: '0',
	RecvVproductsWhenEnter: '0',
	AllProductsHistoryFirst: '0',
	SpecificCompanyVersion: '0',
	CanProduceReceipt: '0',
	RefreshClients: '0',
	DisplayLogo: '1',
	UpdateLogic: '0',
	ProductExtraInfo: '0',
	CanSendEmail: '3',
	B2BOptions: '0',
	OrderDepartments: '0',
	PopularDefault: '0',
	ReportsOptions: '0',
	UnBlockQty: '0',
	SortProductsBy: '0',
	BonusDisplay: '0',
	DiscountLogic: '0',
	BlockChangingPriceBonusDiscountOrQuantities: '0',
	DisplayRemark: null,
	HasMultipleCompanies: '0',
	ItemPackDisplay: '0',
	ShowInlineProduct: '0',
	SearchLogic: '3',
	HeaderDisplay: '0',
	ItemExtrasFilter: '0',
	OfflineLogic: '0',
	MainColor: '#f65944',
	MinimumTotalOrderB2B: '0',
	ClientsPageExtraInfo: '0',
	SortByLastDateTeuda: '0',
	AlertHearotWhenEnter: '0',
	OnlyInClientPriceList: '0',
	DisplayDohotTeudot: null,
	HasSpecials: '0',
	SortWhenEnter: '0',
	YaadAgents: '0',
	YaadClients: '0',
	ProductsFilterRow: '0',
	TemporaryPassword: '0',
	SendDiscount: '0',
	TeudaItemRemark: '0',
	CheckPassword: '0',
	ColorClientName: '0',
	AlternativeAdminReports: '0',
	StockQuantity: '',
	ProductDescription: '0',
	HideReport: '',
	HideQuantityInPack: '0',
	BlockIncreasePrice: '0',
	BikorMustIncludeReason: '0',
	CanChangePermPrice: '0',
	GetByMainCustID: '0',
	ShowDebtTerm: '0',
	NeedsPasswordFromMobility: '0',
	ProductExtraInfoOrder: '0',
	WhatsappB2BL: '0',
	SendCoordinates: '1',
	MainColorCompany2: '0',
	LikutType: '0',
	B2BCanSeePrices: '0',
	FilterMehironimOnBackFromTeuda: '0',
	LikutReportsCanSee: '0',
	BlockYehidotPlusIfBelowStockOne: '0',
	SearchExtraTeudaTypeFromSave: '',
	SearchExtraProductFilter: '',
	LikutSearch: '0',
	LikutRemarkExtra1: '0',
	GetOriginalStock: '0',
	ImageFilenameLookup: '0',
	PackQuantityText: '1',
	BlockClientTeudaSend: '0',
	StockWithItemPack: '0',
	MaxProductNameLength: '0',
	MaslulDefaultClients: '0',
	VisitsPlannedVsUnplanned: '0',
	LoadingAndDistribution: '0',
	LoadingAndDistribution_Quantities: '0',
	ConcentratedProducts: '0',
	ConcentratedProducts_Display: '0',
	ShowVisitInClientsPage: '0',
	VisitsTakePicture: '0',
	VisitsHasNoSaleOption: '0',
	SpecialOnlyIfRemarkContains: '0',
	LikutPrice: '0',
	LoadingAndDistribution_Manager_Loading: '0',
	LoadingAndDistribution_TaskAssignmentExtra: '0',
	LoadingAndDistribution_TaskAssignmentDisplay: '0',
	LoadingAndDistribution_CanAttachPictures: '0',
	LoadingAndDistribution_Manager_POD: '0',
	ShowSpecificationsFileDownload: '0',
	CalculatedDiscount: '0',
	DashboardSeeTrucks: '0',
};
