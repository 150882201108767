import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { decompress } from 'lz-string';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-dohot-bizoim-agent-division',
	templateUrl: './dohot-bizoim-agent-division.component.html',
	styleUrls: ['./dohot-bizoim-agent-division.component.scss'],
})
export class DohotBizoimAgentDivisionComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	public Bizoim = [];

	public BizoimCompany;

	public toogleInputNew = false;
	public ShowCompanys = null;
	public HasMultipleCompanys;
	reportSearch = null;
	history = history;

	destroy$ = new Subject<void>();

	bizoimTotal$: BehaviorSubject<number>;
	bizoimAverage$: BehaviorSubject<number>;

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Company: ['', Validators.required],
		});

		if (!localStorage.getItem('UserName') && !localStorage.getItem('AgentId')) {
			this.router.navigate(['login']);
		}

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		this._ss.getDohotAgentDivisionSales().subscribe(res => {
			this.BizoimCompany = res;
			this.BizoimCompany = this.BizoimCompany.recordset;

			for (let x = 0; x < this.BizoimCompany.length; x++) {
				if (this.BizoimCompany[x].COMPANY === 1) {
					this.Bizoim.push(this.BizoimCompany[x]);
				}
			}

			const biz = this.Bizoim;
			this.bizoimTotal$ = new BehaviorSubject(biz.reduce((p, c) => p + c.TotMoney, 0));
			this.bizoimAverage$ = new BehaviorSubject(biz.reduce((p, c) => p + c.TotMoney, 0) / this.Bizoim.length);
		});

		if (localStorage.getItem('AllProducts') !== null) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
			this.AllProductsSearch = this.AllProducts;
		} else {
			this.router.navigate(['clients']);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public AH = false;
	public TYA = false;
	public lYA = false;

	public SortAhoz() {
		if (this.AH === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ahuz - a.Ahuz;
			});

			this.AH = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ahuz - b.Ahuz;
			});

			this.AH = false;
		}
	}

	public SortThisYear() {
		if (this.TYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Tot - a.Tot;
			});

			this.TYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Tot - b.Tot;
			});

			this.TYA = false;
		}
	}

	public SortLastYear() {
		if (this.lYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ltot - a.Ltot;
			});

			this.lYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ltot - b.Ltot;
			});

			this.lYA = false;
		}
	}

	public ChangeCompany() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimCompany.length; x++) {
			if (this.BizoimCompany[x].COMPANY === JSON.parse(this.form.controls['Company'].value)) {
				this.Bizoim.push(this.BizoimCompany[x]);
			}
		}
	}

	refreshSubjects() {
		const biz = this.Bizoim;

		this.bizoimTotal$.next(biz.reduce((p, c) => p + c.TotMoney, 0));
		this.bizoimAverage$.next(biz.reduce((p, c) => p + c.TotMoney, 0) / this.Bizoim.length);
	}
}
