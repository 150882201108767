<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="MoveToDohotMotzarim()">
				<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
			</div>
			<div class="header__title col">דו"ח מכירות לקוח - מוצרים</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="First === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchClient()">
				<input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
		</div>
	</div>

	<div class="report-table__row" *ngIf="Second === true">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="SearchProducts()">
				<input class="header__search-input" type="text" placeholder=" חפש מוצר" formControlName="ProductSearch" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="ClearProductsSearch()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" style="margin-top: 100px" *ngIf="First === true">
	<div class="content">
		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" [style.width.px]="longestClientId * 17" (click)="sortString('ClientId', 'AllClients')">קוד לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 250px" (click)="sortString('Client_Name', 'AllClients')">שם לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 120px" (click)="sortNumber('numOfProductsPurchased', 'AllClients')" *ngIf="hasTDashKabalaQueries">
							מספר פריטים שנקנו
						</th>
						<th class="report-table__table-head-name" style="width: 150px" *ngIf="HasMultipleCompanys > 2">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ac of AllClients; trackBy: trackByFnClient" (click)="GetClientsPricess(ac.ClientId, ac.company, ac.Client_Name)">
						<td class="report-table__table-body-name">{{ ac.ClientId }}</td>
						<td class="report-table__table-body-name">{{ ac.Client_Name }}</td>
						<td class="report-table__table-body-name" *ngIf="hasTDashKabalaQueries">{{ ac.numOfProductsPurchased }}</td>
						<td class="report-table__table-body-name" *ngIf="HasMultipleCompanys > 2">{{ ac.company }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<!-- start report table markup -->
<section class="report-table" *ngIf="Second === true" style="margin-top: 100px">
	<div class="content">
		<h2 style="text-align: center">{{ this.ChoosenClient }}</h2>
		<div class="report-table__row report-table__row_between">
			<h3 *ngIf="loading">טוען...</h3>
			<h3 *ngIf="!loading && !AllProducts.length">אין נתונים להציג</h3>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px" *ngIf="!loading && AllProducts.length">
			<table class="report-table__table" cellspacing="0">
				<!-- <table class="report-table__table" cellspacing="0" *ngIf="!hasTDashKabalaQueries"> -->
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('ProductId', 'AllProducts')" [style.width.px]="longestProductId * 11">קוד מוצר</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortString('Product_Name', 'AllProducts')" style="width: 200px">שם מוצר</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortNumber('sumqty', 'AllProducts')" *ngIf="hasUpdatedChart" style="width: 100px">כמות שנקנתה השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sortNumber('sumtotalrows', 'AllProducts')" *ngIf="hasUpdatedChart" style="width: 150px">סכום מכירה</th>
						<th class="report-table__table-head-name sortable-clickable" *ngIf="!hasUpdatedChart" style="width: 150px" (click)="SortNumOfTimes()">מס פעמים שנקנה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let ap of AllProducts; trackBy: trackByFnProduct" (click)="hasUpdatedQueryWithDocNumAndDocType ? TeudaClicked(ap.docnum, ap.doctype, 1) : null">
						<td class="report-table__table-body-name">{{ ap.ProductId }}</td>
						<td class="report-table__table-body-name">{{ ap.Product_Name }}</td>
						<td class="report-table__table-body-name" *ngIf="hasUpdatedChart" dir="ltr">{{ ap.sumqty }}</td>
						<td class="report-table__table-body-name" *ngIf="hasUpdatedChart" dir="ltr">{{ ap.sumtotalrows | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" *ngIf="!hasUpdatedChart" dir="ltr">{{ ap.NumOfTimes }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<div class="popup" *ngIf="LoadingSeeTeuda || SeeTeudaLoaded" style="z-index: 555">
	<div class="overlay"></div>
	<div class="product-detail__title">
		{{ ChoosenClient }}
	</div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black"></div>
		<div class="product-detail__wrapper">
			<table *ngIf="SeeTeudaLoaded">
				<tr>
					<th #teudotTableNames>שם פריט</th>
					<th>קוד פריט</th>
					<th>יחידות</th>
					<th>{{ _text.packQuantityText }}</th>
					<th>מחיר יחידה</th>
					<th>סכום</th>
				</tr>
				<tr *ngFor="let pte of TeudotEach">
					<td>{{ pte.DocItemName }}</td>
					<td>{{ pte.DocItemCode }}</td>
					<td>{{ pte.DocQuantity }}</td>
					<td>{{ pte.DocPackQuantity }}</td>
					<td [style.fontWeight]="shouldColorRedAndBolden ? '600' : null" [style.color]="shouldColorRedAndBolden ? 'red' : null">
						{{ pte.DocPrice | number : '1.0-2' }}
					</td>
					<td [style.fontWeight]="shouldColorRedAndBolden ? '600' : null" [style.color]="shouldColorRedAndBolden ? 'red' : null">
						{{ pte.DocTotalRow | number : '1.0-2' }}
					</td>
				</tr>
			</table>
			<div class="product-detail__wrapper__already-sent" *ngIf="LoadingSeeTeuda || errorLoadingTeuda">
				<div class="product-detail__wrapper__already-sent__text" *ngIf="!errorLoadingTeuda; else errorBlock">
					טוען תעודה, אנא המתינו...
					<mat-spinner diameter="50" color="primary"></mat-spinner>
				</div>
				<ng-template #errorBlock>
					<div class="product-detail__wrapper__already-sent__text error">טעינת תעודה נכשלה, אנא נסה שנית מאוחר יותר</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
