import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { decompress } from 'lz-string';
import { Subject, BehaviorSubject } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';

type filterType = {
	name: string;
	value: string;
};
@Component({
	selector: 'app-branches-lookup',
	templateUrl: './branches-lookup.component.html',
	styleUrls: ['./branches-lookup.component.scss'],
})
export class BranchesLookupComponent implements OnInit, OnChanges, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	@Input() searchString: string | null;

	@Output() mikumCode = new EventEmitter<string>();

	// searchString$ = new BehaviorSubject(this.searchString)

	AllClients = [];
	AllClients$ = new BehaviorSubject(this.AllClients);
	AllClientsSearch;

	mikumSelected = '';

	Loading = true;
	Date = new Date();
	Status;

	history = history;

	SearchStyleHelp;

	HasMultipleCompanys;
	online;

	Admin;

	LoadingClient = false;

	windowWidth;

	destroy$ = new Subject<void>();

	carTypes: filterType[] = [
		{ name: 'פרטי', value: 'privet' },
		{ name: '4x4', value: 'suv' },
		{ name: 'משאית', value: 'truck' },
		{ name: 'מצ"ה', value: 'traktor' },
	];

	checkboxes: filterType[] = [
		{ name: 'חילוץ', value: 'recue' },
		{ name: 'שינוע', value: 'shinua' },
		{ name: 'פרונט', value: 'front' },
		{ name: 'TPMS', value: 'tpms' },
		{ name: 'שבת', value: 'shabat' },
	];

	isPartOfOtherPage = !location.pathname.includes('branches');

	searchBranchControl = new FormControl<string>('', { nonNullable: true });
	carTypeControl = new FormControl<string[]>([], { nonNullable: true });
	checkboxesFormGroup = this._fb.group({
		...this.checkboxes.reduce((prev, curr) => {
			prev[curr.value] = new FormControl<boolean>(false, { nonNullable: true });
			return prev;
		}, {}),
	});

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	async ngOnInit() {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.online = window.navigator.onLine;
		this.windowWidth = window.innerWidth;

		if (!localStorage.getItem('UserName') && !localStorage.getItem('AgentId')) this.router.navigate(['login']);

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		this.online = window.navigator.onLine;

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		console.log(this._ss.AgentIdConnected);

		this._ss.getBranches().subscribe({
			next: (res: any) => {
				this.AllClients = res.recordset.filter(cl => cl.clientid === (this._ss.AgentIdConnected || '').replaceAll(/\D/g, ''));
				this.AllClients$.next(this.AllClients);
				this.Loading = false;

				// console.log('total branches: ', this.AllClients.length);
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסו שנית מאוחר יותר');
				this.history.back();
			},
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isPartOfOtherPage) {
			this.searchBranches(changes['searchString'].currentValue);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	AllClientsHelpSort = [];

	ScrollUp() {
		window.scrollTo(0, 0);
		this.virtualScroll.scrollToIndex(0);
	}

	AllClientsHelp;

	searchBranches(value = this.searchBranchControl.value) {
		this.AllClients$.next(!value ? this.AllClients : this.AllClients.filter(ac => (ac.mikumname && ac.mikumname.includes(value)) || (ac.city && ac.city.includes(value))));

		if (this.carTypeControl.value.length) {
			console.log('object');
			this.filterByCartypes();
		}

		if (Object.entries(this.checkboxesFormGroup.value).filter(v => v[1]).length) {
			console.log('object1');
			this.filterByCheckboxes();
		}

		console.log(this.carTypeControl.value, this.checkboxesFormGroup.value);
	}

	filterByCartypes() {
		const val = this.AllClients$.value;

		this.AllClients$.next(
			val.filter(ac => {
				for (const typ of this.carTypeControl.value) {
					if (this.validator(ac, typ)) {
						return true;
					}
				}
				return false;
			}),
		);
	}

	filterByCheckboxes() {
		const val = this.AllClients$.value;

		console.log(Object.entries(this.checkboxesFormGroup.value).filter(v => v[1]));

		this.AllClients$.next(
			val.filter(ac => {
				for (const chk of Object.entries(this.checkboxesFormGroup.value).filter(v => v[1])) {
					console.log(chk);
					if (this.validator(ac, chk[0])) {
						return true;
					}
				}
				return false;
			}),
		);
	}

	validator = (obj: { [key: string]: string }, prop: string): boolean => prop in obj && obj[prop] === 'Y';

	clearSearch() {
		this.searchBranchControl.reset();
		this.carTypeControl.reset();
		this.checkboxesFormGroup.reset();
		this.AllClients$.next(this.AllClients);
	}

	handleCardClicked(value: string) {
		this.mikumSelected = value;
		this.mikumCode.emit(value);
	}

	preventInteraction(e: MatCheckboxChange) {
		e.source.checked = !e.checked;
	}
}
