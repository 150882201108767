import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReturnMonthHebrewPipe } from 'src/app/Pipes/return-month-hebrew.pipe';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MapComponent } from 'src/app/components/map/map.component';

@NgModule({
	declarations: [ReturnMonthHebrewPipe, MapComponent],
	imports: [MaterialModule, CommonModule],
	exports: [ReturnMonthHebrewPipe, MaterialModule, ReactiveFormsModule, MapComponent],
})
export class SharedModule {}
