<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToDohot()" *ngIf="LoadingBack === false">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">כל התעודות</div>
					<div class="header__burger col image">
						<span class="underline bold" (click)="handlePressRefresh()">רענן</span>
					</div>
				</div>
			</div>

			<div class="report-table__row">
				<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
					<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="SearchClient()">
						<img src="/assets/images/header-search.svg" alt="search" />
					</button>
					<form [formGroup]="form" (ngSubmit)="SearchClient()">
						<input class="header__search-input" type="text" placeholder="חפש לקוח" formControlName="ClientSearch" style="text-align: center" (input)="SearchClient()" />
					</form>
					<button class="header__clear-icon" (click)="SearchClear()"></button>
				</div>
			</div>

			<h2 style="text-align: center" *ngIf="LoadingBack === true">טוען אנא המתן</h2>
		</header>

		<!-- start markup for client profile page -->
		<!-- client info for all client profile page -->
		<section class="client-info">
			<div class="content">
				<div class="row">
					<div class="client-info__title col">
						{{ this._ss.UserNameConnected }}
					</div>
				</div>
				<div class="client-info__block row">
					<div class="client-info__number col">
						{{ this._ss.AgentIdConnected }}
					</div>
				</div>
			</div>
		</section>

		<section class="report-table" *ngIf="Loading === false && LoadingBack === false" style="margin-top: 40px">
			<div class="content">
				<div class="row">
					<div class="report-table__button" [ngClass]="{ 'report-table__button_active': searchActive }" (click)="showHideFilterPopup(true)">חיפוש מתקדם</div>

					<div class="pages-div">
						<span class="left-arrow" (click)="changePage('+')" *ngIf="currentPage < Math.ceil(AllClients.length / (+numOfItemsPerPage || 50))"
							><img src="assets/images/dashboard/scroll-arrow.svg" alt="" /></span
						>עמוד {{ (!AllClients.length && '0') || currentPage }} מתוך {{ Math.ceil(AllClients.length / numOfItemsPerPage)
						}}<span class="right-arrow" (click)="changePage('-')" *ngIf="currentPage > 1"><img src="assets/images/dashboard/scroll-arrow.svg" alt="" /></span>
					</div>
					<div class="pagination-title">
						<form [formGroup]="form">
							<label for="pagination">מספר שורות לדף</label>
							<select name="pagination" id="pagination" formControlName="Page">
								<option [value]="50">50</option>
								<option [value]="100">100</option>
								<option [value]="200">200</option>
								<option [value]="300">300</option>
								<option [value]="500">500</option>
							</select>
						</form>
					</div>

					<div *ngIf="FilterLoading === true">טוען {{ this.form.controls['DocType'].value }} אנא המתן</div>
				</div>

				<div class="report-table__row report-table__row_between"></div>

				<div class="report-table__row report-table__row_full-width" style="margin-right: 0px" [ngClass]="{ 'report-table__row__alternate': isAlternativeView }">
					<!-- <div class="report-table__row report-table__row_full-width" style="margin-right: 0px;justify-content: center;"> -->
					<table class="report-table__table" [ngClass]="{ 'report-table__table-alternate': isAlternativeView }" cellspacing="0" *ngIf="FilterLoading === false">
						<ng-container *ngIf="!isAlternativeView; else alternativeViewBlock">
							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name" style="width: 120px">קוד לקוח</th>
									<th class="report-table__table-head-name" [style.width.px]="widthForClientName">שם לקוח</th>
									<th class="report-table__table-head-name" style="width: 120px">סכום{{ _ss.CustomerInformation.DisplayWithVat === '2' ? ' כולל מע"מ ' : '' }}</th>
									<th class="report-table__table-head-name" style="width: 120px">תאריך</th>
									<th class="report-table__table-head-name" *ngIf="!isUndelivered" style="width: 120px">סוג תעודה</th>
									<th class="report-table__table-head-name" style="width: 120px">הערה</th>
									<th class="report-table__table-head-name" style="width: 120px">מס תעודה</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr
									*ngFor="let ata of AllClients.slice(numOfItemsPerPage * currentPage - numOfItemsPerPage, numOfItemsPerPage * currentPage); trackBy: trackByFnClient"
									(click)="TeudaClicked(ata.DocDocNum, ata.DocDocType, ata.Company); document.body.classList.add('no-scroll')"
								>
									<!-- <tr
									*ngFor="let ata of AllClients.slice(numOfItemsPerPage * currentPage - numOfItemsPerPage, numOfItemsPerPage * currentPage); trackBy: trackByFnClient"
									(click)="TeudaClicked(ata.DocDocNum, ata.Company); document.body.classList.add('no-scroll')"
									class='body-tr'> -->
									<td class="report-table__table-body-name">{{ ata.DocCustCode }}</td>
									<td class="report-table__table-body-name">{{ ata.Client_Name }}</td>
									<!-- <td class="report-table__table-body-name">{{ ata.DocTypeName }}</td> -->
									<!-- <td class="report-table__table-body-name">{{ ata.DocCreateDate }}</td> -->
									<td
										class="report-table__table-body-name"
										[style.color]="ata.DocTypeName === 'החזרה' || ata.DocTypeName === 'חשבונית מס זיכוי' ? 'red' : null"
										[style.fontWeight]="ata.DocTypeName === 'החזרה' || ata.DocTypeName === 'חשבונית מס זיכוי' ? '900' : null"
										dir="ltr"
									>
										{{ (_ss.CustomerInformation.DisplayWithVat === '2' ? ata.DocTotalSumVat : ata.DocTotalSum) | number : '1.0-2' }}
									</td>
									<td class="report-table__table-body-name">{{ ata.DocCreateDate }}</td>
									<td class="report-table__table-body-name" *ngIf="!isUndelivered">{{ ata.DocTypeName }}</td>
									<td class="report-table__table-body-name" dir="ltr">{{ ata.DocRemark }}</td>
									<td class="report-table__table-body-name">{{ ata.DocDocNum }}</td>
									<!-- <span class="absolute name">{{ata.Client_Name}}</span> -->
									<!-- <span class="absolute remark">{{ata.DocRemark}}</span> -->
								</tr>
							</tbody>
						</ng-container>
						<ng-template #alternativeViewBlock>
							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name" style="width: 30vw">סכום כולל מע"מ</th>
									<th class="report-table__table-head-name" style="width: 30vw">תאריך</th>
									<th class="report-table__table-head-name" style="width: 30vw">סוג תעודה</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr
									*ngFor="let ata of AllClients.slice(numOfItemsPerPage * currentPage - numOfItemsPerPage, numOfItemsPerPage * currentPage); trackBy: trackByFnClient"
									(click)="TeudaClicked(ata.DocDocNum, ata.DocDocType, ata.Company); document.body.classList.add('no-scroll')"
									class="body-tr"
								>
									<td
										class="report-table__table-body-name"
										[style.color]="ata.DocTypeName === 'החזרה' || ata.DocTypeName === 'חשבונית מס זיכוי' ? 'red' : null"
										[style.fontWeight]="ata.DocTypeName === 'החזרה' || ata.DocTypeName === 'חשבונית מס זיכוי' ? '900' : null"
										dir="ltr"
									>
										{{ (_ss.CustomerInformation.DisplayWithVat === '2' ? ata.DocTotalSumVat : ata.DocTotalSum) | number : '1.0-2' }}
									</td>
									<td class="report-table__table-body-name">{{ ata.DocCreateDate }}</td>
									<td class="report-table__table-body-name">{{ ata.DocTypeName }}</td>
									<span class="absolute name">{{ ata.Client_Name }}</span>
									<span class="absolute remark"
										>מס' <strong>{{ ata.DocDocNum }}</strong></span
									>
								</tr>
							</tbody>
						</ng-template>
					</table>
				</div>
			</div>
		</section>
		<!-- end report table markup -->
		<!-- end markup for client profile order page -->
		<div class="order-process__row order-process__row_success" *ngIf="Loading === true" style="margin-top: 70px">
			<div class="row">
				<h2 class="order-process__title">מעדכן</h2>
			</div>
			<div class="row"></div>
			<div class="row">
				<div class="order-process__image image">
					<img src="/assets/images/basket/process/success.gif" alt="photo" />
				</div>
			</div>
			<div class="row"></div>
		</div>
	</div>
</div>

<!-- markup for sort popup -->
<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="report-table-popup report-table-popup_sort popup__content">
		<div class="popup__close" (click)="showHideFilterPopup(false)"></div>
		<div class="report-table-popup__header">
			<div class="report-table-popup__header-search"></div>
			<div class="report-table-popup__header-title">חיפוש מתקדם</div>
		</div>
		<div class="report-table-popup__wrapper">
			<div class="report-table-popup__content">
				<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding">
					<span class="report-table-popup__content-title">בחירת תקופה</span>
				</div>
				<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
					<div class="report-table-popup__box">
						<div class="report-table-popup__row">
							<label class="report-table-popup__label custom-checkbox" (click)="AllTeudot()" (click)="showHideFilterPopup(false)">
								<div class="report-table-popup__label-title">הצג הכל</div>
							</label>
						</div>
						<div class="report-table-popup__row" (click)="TopTwenty()" (click)="showHideFilterPopup(false)">
							<label class="report-table-popup__label custom-checkbox">
								<div class="report-table-popup__label-title">20 אחרונים</div>
							</label>
						</div>
						<div class="report-table-popup__row" (click)="ThisMonth()" (click)="showHideFilterPopup(false)">
							<label class="report-table-popup__label custom-checkbox">
								<div class="report-table-popup__label-title">מתחילת החודש</div>
							</label>
						</div>
						<div class="report-table-popup__row" (click)="LastMonth()" (click)="showHideFilterPopup(false)">
							<label class="report-table-popup__label custom-checkbox">
								<div class="report-table-popup__label-title">חודש קודם</div>
							</label>
						</div>
						<div class="report-table-popup__row" (click)="ThirtyDays()" (click)="showHideFilterPopup(false)">
							<label class="report-table-popup__label custom-checkbox">
								<div class="report-table-popup__label-title">30 יום</div>
							</label>
						</div>
					</div>
					<div class="report-table-popup__box">
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label" [ngClass]="{ 'report-table-popup__label_disabled': toogleInputDate }">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">מתאריך</div>
								<form [formGroup]="form" (change)="FromDateFunction()">
									<input type="date" formControlName="FromDate" />
								</form>
							</label>
						</div>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label" [ngClass]="{ 'report-table-popup__label_disabled': toogleInputDate }">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">עד תאריך</div>
								<form [formGroup]="form" (change)="ToDateFunction()">
									<input type="date" formControlName="ToDate" />
								</form>
							</label>
						</div>
					</div>
				</div>
				<ng-container *ngIf="!isUndelivered">
					<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
						<span class="report-table-popup__content-title">סוג תעודה</span>
					</div>
					<div style="float: right">
						<form [formGroup]="form" (change)="DocTypeSelect()" style="width: 150px; margin-bottom: 30px">
							<select formControlName="DocType" style="width: 120px; height: 30px">
								<option *ngFor="let d of Doc" value="{{ d.DocTypeName }}">{{ d.DocTypeName }}</option>
							</select>
						</form>
					</div>
				</ng-container>
			</div>
			<div class="report-table-popup__button-wrapper" (click)="showHideFilterPopup(false)">
				<button class="report-table-popup__button">הצג תוצאות</button>
			</div>
		</div>
	</div>
</div>
<!-- end markup for sort popup -->

<div class="popup" *ngIf="LoadingSeeTeuda || SeeTeudaLoaded" style="z-index: 555">
	<div class="overlay"></div>
	<!-- <div class="product-detail__title" *ngIf="selectedTeudaToOpenPopup?.Client_Name">
		{{ selectedTeudaToOpenPopup?.Client_Name}}
		<br/>
		קוד: {{selectedTeudaToOpenPopup.DocCustCode}}
		<br/>
		תעודה: {{selectedTeudaToOpenPopup.DocDocNum}}
	</div> -->
	<div class="product-detail__title" *ngIf="selectedTeudaToOpenPopup.Client_Name">
		{{ selectedTeudaToOpenPopup.Client_Name }}
	</div>
	<div class="product-detail__title remarks" *ngIf="selectedTeudaToOpenPopup.DocRemark"><span style="font-weight: 700">הערת תעודה:</span> {{ selectedTeudaToOpenPopup.DocRemark }}</div>
	<div class="product-detail-popup popup__content">
		<div
			class="popup__close"
			(click)="SeeTeudaLoaded = false; LoadingSeeTeuda = false; TeudotEach = []; document.body.classList.remove('no-scroll'); selectedTeudaToOpenPopup = {}; shouldColorRedAndBolden = false"
			style="width: 30px; height: 30px; background-color: black"
			*ngIf="!LoadingSeeTeuda"
		></div>
		<div class="product-detail__wrapper">
			<table *ngIf="SeeTeudaLoaded">
				<tr>
					<th #teudotTableNames>שם פריט</th>
					<th>קוד פריט</th>
					<th>יחידות</th>
					<th>{{ _text.packQuantityText }}</th>
					<th>מחיר יחידה</th>
					<!-- <th> הנחה</th> -->
					<!-- <th> מחיר נטו</th> -->
					<th>סכום</th>
					<!-- <th> הערה</th> -->
				</tr>
				<tr *ngFor="let pte of TeudotEach">
					<td>{{ pte.DocItemName }}</td>
					<td>{{ pte.DocItemCode }}</td>
					<td>{{ pte.DocQuantity }}</td>
					<td>{{ pte.DocPackQuantity }}</td>
					<td [style.fontWeight]="shouldColorRedAndBolden ? '600' : null" [style.color]="shouldColorRedAndBolden ? 'red' : null">
						{{ pte.DocPrice | number : '1.0-2' }}
					</td>
					<!-- <td>{{pte.DocDiscount}}</td> -->
					<!-- <td>{{pte.TeudaItemNetPrice | number: '1.0-2'}}</td> -->
					<td [style.fontWeight]="shouldColorRedAndBolden ? '600' : null" [style.color]="shouldColorRedAndBolden ? 'red' : null">
						{{ pte.DocTotalRow | number : '1.0-2' }}
					</td>
					<!-- <td>{{pte.DocItemRemark}}</td> -->
				</tr>
			</table>
			<div class="product-detail__wrapper__already-sent" *ngIf="LoadingSeeTeuda">
				טוען תעודה, אנא המתינו...
				<mat-spinner diameter="50" color="primary"></mat-spinner>
			</div>
		</div>
	</div>
</div>
