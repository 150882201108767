import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'encodeURI',
	pure: true
})
export class EncodeURIPipe implements PipeTransform {
	transform(picName: string): string {
		return encodeURIComponent(picName);
	}
}
