import { AllProducts } from './SQL-morning-responses';

export interface Product extends AllProducts {
	ChangeDiscount?: number;
	ChangePrice?: number;
	ShowPrice?: number;
	DocNetPrice: number | string;
	passedCustGroup?: boolean;
	SpecialID?: number;
	dontTouchPriceOrDiscount?: boolean;
	PermPrice?: number;
	DocDiscount?: number;
}

// new product interface
export interface Product2 extends Product {
	basePrice?: number;
	basePriceInputted?: number;
	netPrice?: number;
	netPriceInputted?: number;
	baseDiscount?: number;
	baseDiscountInputted?: number;
	state?: Set<Base> | Set<Stateful>;
	TeudaPackQuantity?: number;
	TeudaQuantity?: number;
	TeudaBonusQuantity?: number;
	Weight?: number;
	Type?: number;
	weightMode?: 'weight' | 'singles';
	packsWeightMode?: 'boxes' | 'packs';
	originalItemPack?: number;
	Unit?: string;
	GroupSum?: string;
}

type Base = 'base';
type Stateful = 'price' | 'net' | 'discount' | 'bonus';

export interface AllOrder {
	Stock: number;
	ProductId: string;
	Product_Name: string;
	Price: number;
	ItemBarcode: string;
	ItemPack: number;
	ItemPicName: string;
	BD: number;
	BP: number;
	BM: number;
	MinPrice: number;
	qt: number;
	BS: number;
	PKD: number;
	CPP: number;
	TeudaQuantity: number;
	TeudaPackQuantity: number;
	TeudaBonusQuantity: number;
	TeudaBonusPackQuantity: number;
	YehidotPrice: number;
	ChangePrice: number;
	ChangeDiscount: number;
	IfChangePriceOrDisc: number;
	ArizotPrice: number;
	TeudaItemRemark: string;
	TeudaRemark: string;
	Ptve: string;
	Duplicate: string;
	DuplicateOrder: string;
	PermPrice: number;
	PriceInInput: number;
	StatusInsertItem: string;
	TeudaNum?: string;
	TeudaCreateDate?: string;
	TeudaTotalRow?: number;
	CPPTotalRow?: number;
	dontTouchPriceOrDiscount?: boolean;
	basePrice?: number;
	basePriceInputted?: number;
	weightMode?: 'weight' | 'singles';
	Weight?: number;
	Type?: number;
	originalItemPack?: number;
	packsWeightMode?: 'boxes' | 'packs';
	GroupSum?: string;
}
export interface AllOrderOverwrites extends Partial<AllOrder> {}

// Product State In All Order - Translations
// Not fully implemented
export enum OrderState {
	noChangeToProduct,
	changedPrice,
	changedDiscount,
	changedBothDiscountAndPrice,
}
