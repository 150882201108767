import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { compress, decompress } from 'lz-string';
import { UtilService } from 'src/app/services/util.service';
import { TextService } from 'src/app/services/text.service';

@Component({
	selector: 'app-dohotteudoteach',
	templateUrl: './dohotteudoteach.component.html',
	styleUrls: ['./dohotteudoteach.component.scss'],
})
export class DohotteudoteachComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, public _util: UtilService, public _text: TextService) {}

	public TeudotDetailsEach = [];

	public AllOrder = [];

	public PopDuplicate = false;

	public ConfirmPop = false;

	custVAT = this._ss.valueAddedTax;

	shouldColorRedAndBolden;

	ngOnInit(): void {
		if (this._ss.TeudotDetailsEach === undefined) {
			// this.router.navigate(['dohot-teudot']);
			history.back();
		} else {
			this.TeudotDetailsEach = this._ss.TeudotDetailsEach = this._ss.TeudotDetailsEach.map(td => ({ ...td, DocNet: td.DocPrice * (1 - td.DocDiscount / 100) }));

			if (this._ss.CustomerInformation.DisplayWithVat === '1' || this._ss.CustomerInformation.DisplayWithVat === '2') {
				if (!this._ss.valueAddedTax) {
					this._ss.valueAddedTax = JSON.parse(decompress(localStorage.getItem('AllClients')))[0].CustVat;
				}

				this.TeudotDetailsEach = this.TeudotDetailsEach.map(td => ({
					...td,
					DocPrice: td.DocPrice * (1 + this.custVAT / 100),
					DocTotalRow: td.DocTotalRow * (1 + this.custVAT / 100),
				}));
			}

			this.shouldColorRedAndBolden = this.TeudotDetailsEach[0].DocDocType == '3' || this.TeudotDetailsEach[0].DocDocType == '5';

			console.log(this.TeudotDetailsEach);
		}

		window.scrollTo(0, 0);
	}

	public BackToClientTeudot() {
		history.back();
	}

	public AskDuplicate() {
		this.PopDuplicate = true;
	}

	public NoDuplicate() {
		this.PopDuplicate = false;
	}

	public DuplicateOrder() {
		this.AllOrder = [];

		for (let x = 0; x < this._ss.TeudotDetailsEach.length; x++) {
			this.AllOrder.push({
				Stock: this._ss.TeudotDetailsEach[x].Stock,
				ProductId: this._ss.TeudotDetailsEach[x].ProductId,
				Product_Name: this._ss.TeudotDetailsEach[x].ProductName,
				Price: this._ss.TeudotDetailsEach[x].TeudaPrice,
				PermPrice: this._ss.TeudotDetailsEach[x].TeudaPrice,
				ItemPack: this._ss.TeudotDetailsEach[x].ItemPack,
				TeudaQuantity: this._ss.TeudotDetailsEach[x].TeudaQuantity,
				TeudaPackQuantity: this._ss.TeudotDetailsEach[x].TeudaPackQuantity,
				TeudaBonusQuantity: this._ss.TeudotDetailsEach[x].TeudaBonusQuantity,
				TeudaBonusPackQuantity: 0,
				YehidotPrice: 0,
				ArizotPrice: 0,
				ItemBarcode: this._ss.TeudotDetailsEach[x].ItemBarcode,
				TeudaItemRemark: this._ss.TeudotDetailsEach[x].TeudaItemRemark || '',
				TeudaRemark: this._ss.TeudotDetailsEach[x].TeudaRemark || '',
				ChangePrice: this._ss.TeudotDetailsEach[x].TeudaItemNetPrice,
				ChangeDiscount: this._ss.TeudotDetailsEach[x].TeudaItemDiscount,
				ItemPicName: this._ss.TeudotDetailsEach[x].ItemPicName,
				BD: 0,
				BP: 0,
				BM: 0,
				MinPrice: 0,
			});
		}

		localStorage.setItem('AllOrder' + this.TeudotDetailsEach[0].Client_Id + this._ss.TeudotDetailsEach[0].TeudaType + this._ss.CompanyNumForDuplicate, JSON.stringify(this.AllOrder));

		this.ConfirmPop = true;
		this.PopDuplicate = false;
	}

	//==========================================================================================================================================================>
	//==========================================================================================================================================================>

	public Exit() {
		this.ConfirmPop = false;
	}

	public WatchOrderDuplicate() {
		this.router.navigate(['clients']);
	}

	valuesObject = {
		main: ['DocItemCode', 'DocItemName', 'DocQuantity', 'DocPackQuantity', 'DocPrice', 'DocNet', 'DocTotalRow'],
	} as const;

	translateForSort<T extends keyof typeof this.valuesObject = keyof typeof this.valuesObject>(value: typeof this.valuesObject[T][number], screen: T = Object.keys(this.valuesObject)[0] as T): void {
		let i: number,
			arrayToSort = this.TeudotDetailsEach;

		i = this.valuesObject[screen].findIndex(arr => arr === value);

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}
}
