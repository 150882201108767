<div class="popup" *ngIf="_loading.isLoading$ | async" style="z-index: 333;direction:rtl">
	<div class="overlay"></div>
	<div class="product-popup popup__content">
		<div class="order-product__item">
			<div class="order-process__row order-process__row_success">
				<div class="row">
					<h2 class="order-process__title" style="text-align: center">{{ _loading.loadingMessage$ | async }}</h2>
				</div>
				<div class="row"></div>
				<div class="row">
					<div class="order-process__image image">
						<img src="/assets/images/basket/process/success.gif" alt="photo" />
					</div>
				</div>
				<div class="row"></div>
			</div>
		</div>
	</div>
</div>
