<!-- <video [srcObject]="videoSrc" autoplay="true"></video>
<pre>{{scanData}}</pre>
<p>is barcode supported: {{hasBarcodeDetector}}</p>
<div *ngIf="errors">{{errors}}</div> -->

<!-- <button (click)="pdfToImageDataURLAsync(pdfCanvas)">Get PDF</button> -->
<!-- <input type="text" #input> -->
<!-- <canvas #pdfCanvas></canvas> -->

<section class="report-graph" style="padding: 0">
	<div class="content" style="padding-top: 20px">
		<h1 *ngIf="!loadedGraph" dir="rtl">טוען...</h1>
		<div class="wrapper">
			<div class="wrapper-chart">
				<form [formGroup]="dailyForm" (change)="handleDayChange($event)" *ngIf="loadedGraph">
					<input type="date" formControlName="date" placeholder="בחרו יום" class="input-styled" />
					<span><strong>תאריך:</strong> {{ (dailyForm.controls.date.value && (dailyForm.controls.date.value | date : 'dd/MM/yy')) || 'לא נבחר תאריך' }}</span>
					<span><strong>סה"כ:</strong> {{ Total | number : '1.0-2' }}</span>
				</form>
				<div id="pieChart" *ngIf="loadedGraph"></div>
				<div class="amounts-legend">
					<span *ngFor="let item of columnsForLegend['first']">
						<strong>{{ item[0] }}</strong> - {{ item[1] | number : '1.0-2' }}
					</span>
				</div>
			</div>
			<div class="wrapper-chart">
				<form [formGroup]="monthForm" (change)="handleMonthChange($event)" *ngIf="loadedGraph">
					<div style="position: relative">
						<span class="expand-icon" #expand>
							<mat-icon>expand_more</mat-icon>
						</span>
						<select name="month" id="month" formControlName="month" class="input-styled select" #select (click)="rotate($event, !isRotated)">
							<option *ngFor="let i of monthArr" [value]="i">{{ i | returnMonthHebrew }}</option>
						</select>
					</div>
					<span><strong>סה"כ:</strong> {{ monthTotal | number : '1.0-2' }}</span>
				</form>
				<div id="pieChart2" *ngIf="loadedGraph"></div>
				<div class="amounts-legend">
					<span *ngFor="let item of columnsForLegend['second']">
						<strong>{{ item[0] }}</strong> - {{ item[1] | number : '1.0-2' }}
					</span>
				</div>
			</div>
			<div class="wrapper-chart">
				<form *ngIf="loadedGraph">
					<span class="span-title"><strong>סה"כ:</strong> {{ yearTotal | number : '1.0-2' }}</span>
				</form>
				<div id="pieChart3" *ngIf="loadedGraph"></div>
				<div class="amounts-legend">
					<span *ngFor="let item of columnsForLegend['third']">
						<strong>{{ item[0] }}</strong> - {{ item[1] | number : '1.0-2' }}
					</span>
				</div>
			</div>
		</div>
	</div>
</section>
