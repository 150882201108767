import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';
import { CompaniesResponse } from 'src/app/Interfaces/companies';

@Component({
	selector: 'app-dailyreport',
	templateUrl: './dailyreport.component.html',
	styleUrls: ['./dailyreport.component.scss'],
})
export class DailyreportComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder) {}

	public form: FormGroup;
	public Teudot;
	public Loading = true;
	public TeudotDetailsEach = [];
	public UpdateSystem = false;
	public DateUpdate = new Date();
	public Today;

	allDoctypeNames = this._ss.allDoctypeNames;
	doctypeNamesShrinked = [];

	public TotalDay = 0;

	public Loading1 = false;
	Intl = Intl;
	date = Date;

	Admin = localStorage.getItem('Admin');

	isMultiCompany: boolean = false;

	docTypes = [];
	companies: CompaniesResponse[] = [];

	docTypeControl = this._fb.control('');
	companyControl = this._fb.control('');

	canSeePrice = true;

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		if (this.Admin && this.Admin.includes('b2b') && this._ss.CustomerInformation.B2BCanSeePrices === '1') {
			this.canSeePrice = false;
		}

		this._ss.CompanyNumForDuplicate = null;

		this.form = this._fb.group({
			Date: ['', Validators.required],
		});

		window.scrollTo(0, 0);

		this._ss.Betipul = 0;
		this._ss.TeudotDetailsEach = [];
		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			this._ss.UserNameConnected = localStorage.getItem('UserName');
			this._ss.AgentIdConnected = localStorage.getItem('AgentId');
		} else {
			this.router.navigate(['login']);
		}

		const doctypesFromLocalStorage = localStorage.getItem('AllDocTypesSave');

		if (!this.allDoctypeNames && doctypesFromLocalStorage) {
			this.allDoctypeNames = this._ss.allDoctypeNames = JSON.parse(decompress(doctypesFromLocalStorage));
		}

		const teudaNames = new Set<string>();

		let len = 0;

		const doctypesAvailable = !!this.allDoctypeNames;

		if (doctypesAvailable) {
			for (const name of this.allDoctypeNames) {
				teudaNames.add(name.DocName);

				if (teudaNames.size > len) {
					this.doctypeNamesShrinked.push({ DocName: name.DocName, DocType: name.DocType });
					len++;
				}
			}
		}

		if (this._ss.Teudot === null) {
			this._ss.GetAllOrdersOneAgent().subscribe(async (res: any) => {
				this._ss.AllTeudotAgent = this.Today = res.recordset.filter(res => res.Status !== 9)

				this._ss.Teudot = this.Teudot = [];

				const doctypes = this.doctypeNamesShrinked.map(dc => dc.DocType);

				const docTypes = new Set<string>();

				for (let x = 0; x < this.Today.length; x++) {
					if (!this.isMultiCompany) {
						this.isMultiCompany = this.Today[x].Company !== 1;
					}

					if (doctypesAvailable && doctypes.includes(this.Today[x].TeudaType)) {
						this.Today[x].Docname = this.doctypeNamesShrinked.find(nm => this.Today[x].TeudaType === nm.DocType).DocName;
					}
					if (this.Today[x].TeudaDate.toString().slice(0, 10) === this.DateUpdate.toISOString().toString().slice(0, 10)) {
						this.Teudot.push(this.Today[x]);
						docTypes.add(this.Today[x].TeudaType);
					}
				}

				await new Promise<void>(resolve => {
					if (!this.isMultiCompany) {
						resolve();
					}
					if (localStorage.getItem('ShowCompanys') !== null) {
						this.companies = JSON.parse(localStorage.getItem('ShowCompanys'));
						resolve();
					} else {
						this._ss.GetComppanys().subscribe((res: any) => {
							if (res.recordset && res.recordset.length > 0) {
								localStorage.setItem('ShowCompanys', JSON.stringify(res.recordset));
								resolve();
							}
						});
					}
				});

				console.log(this.companies);

				if (this.companies.length) {
					for (let i = 0; i < this._ss.AllTeudotAgent.length; i++) {
						const element = this._ss.AllTeudotAgent[i];
						const company = this.companies.find(cm => cm.Company === element.Company);
						if (company) {
							element.CompanyName = company.CompanyName;
						}
					}
				}

				for (let x = 0; x < this.Teudot.length; x++) {
					this.TotalDay += this.Teudot[x].TeudaTotalSum;
				}

				this.Loading = false;
			});
		} else {
			this.Today = this._ss.AllTeudotAgent;
			this.Teudot = this._ss.Teudot;
			this.Loading = false;
			for (let x = 0; x < this.Teudot.length; x++) {
				this.TotalDay += this.Teudot[x].TeudaTotalSum;
			}
		}
	}
	public Changedatatest;
	public MoveToTeudaEach(TeudaNum, Status, Company) {
		this.Loading = true;

		this._ss.CompanyNumForDuplicate = Company;

		if (Status === 0) {
			this._ss.Betipul = 1;
		} else {
			this._ss.Betipul = 0;
		}

		this._ss.GetTeudotEach(TeudaNum).subscribe(
			(res: any) => {
				this.Changedatatest = res;
				this.Changedatatest = this.Changedatatest.recordset;

				this.Changedatatest = JSON.parse(this.Changedatatest[0].DATAString);
				this._ss.TeudotDetailsEach = this.Changedatatest.map(cds => ({ ...cds, TeudaDate: res.recordset[0].TeudaDate }));

				console.log(this.Changedatatest);

				setTimeout(() => {
					this.router.navigate(['daily-report-each']);
				}, 100);
			},
			err => {
				alert('שגיאת שרת');
				this.Loading = false;
			},
		);
	}

	filterByDocType(doctype) {
		this.form.controls['Date'].setValue('');
		this.companyControl.reset();

		this.Teudot = this.Today.filter(td => td.TeudaType === +doctype.target.value);

		this.TotalDay = this.Teudot.reduce((prev, curr) => prev + +curr.TeudaTotalSum, 0);
	}

	filterByCompany(company) {
		this.form.controls['Date'].setValue('');
		this.docTypeControl.reset();

		this.Teudot = this.Today.filter(td => td.Company === +company.target.value);

		this.TotalDay = this.Teudot.reduce((prev, curr) => prev + +curr.TeudaTotalSum, 0);
	}

	public BackToHome() {
		this.router.navigate(['home']);
	}

	public Refresh() {
		this._ss.TeudotDetails = null;
		this._ss.Teudot = null;
		window.location.reload();
	}

	public AskRefresh() {
		this.UpdateSystem = true;
	}

	public NoUpdate() {
		this.UpdateSystem = false;
	}

	public DateChange() {
		this.Teudot = [];
		this.TotalDay = 0;

		for (let x = 0; x < this.Today.length; x++) {
			if (this.Today[x].TeudaDate.toString().slice(0, 10) === this.form.controls['Date'].value) {
				this.Teudot.push(this.Today[x]);
			}
			this._ss.Teudot = this.Teudot;
		}

		for (let x = 0; x < this.Teudot.length; x++) {
			this.TotalDay += this.Teudot[x].TeudaTotalSum;
		}

		this.docTypeControl.reset();
		this.companyControl.reset();
	}
}
