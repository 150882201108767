import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';
import { Users } from 'src/app/Interfaces/users';
import { Subject, takeUntil, catchError, of, from, mergeMap } from 'rxjs';
import { ExcelObject, UtilService } from 'src/app/services/util.service';
import { read, utils, writeFile, writeFileXLSX } from 'xlsx';
import { ReturnMonthHebrewPipe } from 'src/app/Pipes/return-month-hebrew.pipe';

const screens = ['Bizoim', 'BizoimEach', 'BizoimEach2', 'OneTeudaInfo'] as const;

const BizoimValues = ['cl', 'cn', 'Tot', 'Ltot', 'ahuz', 'cm', 'Cl', 'Cn', 'Yaad1', 'advancementPercentage', 'Ahuz'] as const;
const BizoimEachValues = ['Mm1', 'Tot', 'Ltot', 'ahuz', 'cm', 'Yaad1', 'advancementPercentage'] as const;
const BizoimEach2Values = ['dd', 'dn', 'tsum'] as const;
const OneTeudaInfoValues = ['pr', 'pn', 'prc', 'qu', 'ds'] as const;

const valuesObject = {
	Bizoim: BizoimValues,
	BizoimEach: BizoimEachValues,
	BizoimEach2: BizoimEach2Values,
	OneTeudaInfo: OneTeudaInfoValues,
} as const;

@Component({
	selector: 'app-bizoimlekohotyear',
	templateUrl: './bizoimlekohotyear.component.html',
	styleUrls: ['./bizoimlekohotyear.component.scss'],
})
export class BizoimlekohotyearComponent implements OnInit, OnDestroy {
	constructor(public _ss: StoreService, public router: Router, private _fb: FormBuilder, private _util: UtilService) {}

	public form: FormGroup;
	public AllProducts;
	public AllProductsSearch;
	public Bizoim = [];
	public BizoimSwitch;
	public BizoimSwitchMain;
	public DateToday = new Date();

	public toogleInputNew = false;

	public AllClientsSearch;

	public test;
	public test2;

	public FirstPage = true;
	public SecondPage = false;
	public ThirdPage = false;
	public FourthPage = false;

	public LastPage = false;

	public BizoimEach = [];
	public BizoimEach2 = [];

	public ShowCompanys;

	public HasMultipleCompanys;

	public CompanyChoosen = 1;

	public OneTeudaInfo;

	public Loading = false;

	public LastPage2 = false;

	currentClientNameAndID;

	admin = localStorage.getItem('Admin');

	isInAdminPage = location.href.includes('admin');

	agentsForAdmin = [];

	destroy$ = new Subject<void>();

	bizoimTotal$ = new Subject<number>();
	bizoimAverage$ = new Subject<number>();

	bizoimEachTotal$ = new Subject<number>();
	bizoimEachAverage$ = new Subject<number>();

	bizoimEach2Total$ = new Subject<number>();
	bizoimEach2Average$ = new Subject<number>();

	agents: any[] = [];
	agentsControl = new FormControl<string[]>(['']);

	month = new Date().getMonth();

	hasYaad = false;

	windowWidth = innerWidth;

	clickedMonth;
	teudaNum;

	agentsToGet = [];

	async ngOnInit() {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		if (this.HasMultipleCompanys === '2') {
			if (localStorage.getItem('ShowCompanys') !== null) {
				this.ShowCompanys = localStorage.getItem('ShowCompanys');
				this.ShowCompanys = JSON.parse(this.ShowCompanys);
			} else {
				this._ss.GetComppanys().subscribe(res => {
					this.ShowCompanys = res;
					this.ShowCompanys = this.ShowCompanys.recordset;
					localStorage.setItem('ShowCompanys', JSON.stringify(this.ShowCompanys));
				});
			}
		}

		window.scrollTo(0, 0);

		this.form = this._fb.group({
			Month: ['', Validators.required],
			ClientSearch: ['', Validators.required],
			Company: ['', Validators.required],
		});

		if (localStorage.getItem('UserName') !== null && localStorage.getItem('AgentId') !== null) {
			if (this.admin) this.admin = JSON.parse(this.admin);

			console.log(this.admin);

			this.Loading = true;

			if (this._ss.CustomerInformation.YaadClients === '1' || this._ss.CustomerInformation.YaadClients === '2') {
				this.hasYaad = true;
			}

			if (this.admin !== 'false' && this.isInAdminPage) {
				await this.handleGetAgents();
				if (!this.hasYaad) {
					await this.handleGetAdminStats();
				} else {
					await this.handleGetAdminStatsWithYaad();
				}
			} else {
				this._ss.UserNameConnected = localStorage.getItem('UserName');
				this._ss.AgentIdConnected = localStorage.getItem('AgentId');

				if (this.hasYaad) {
					this.getBizoimLekohotWithYaad();
				} else {
					this.getBizoimLekohotWithoutYaad();
				}
			}
		} else {
			this.router.navigate(['login']);
		}

		if (localStorage.getItem('AllProducts') !== null) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
			this.AllProductsSearch = this.AllProducts;
		} else {
			this.router.navigate(['clients']);
		}
	}

	ngOnDestroy(): void {
		this._ss.ParamBitzoimClientCard = null;

		this.destroy$.next();
		this.destroy$.complete();
	}

	async getSubmanagerAgentsFirst(): Promise<string[]> {
		return await new Promise(resolve => {
			if (!this._ss.agentsOfSubmanager.length) {
				this._ss.getAdminAgents().subscribe({
					next: res => {
						if (typeof res === 'string') {
							alert('חלה תקלה בהבאת סוכנים עבור מנהל זה. נסו שנית');
							resolve([]);
							history.back();
							return;
						}

						const agents = res.recordset.map(obj => obj.Agent);
						agents.push(this._ss.AgentIdConnected);
						this._ss.agentsOfSubmanager = agents;

						return resolve(agents);
					},
					error: err => {
						alert('שגיאת שרת');
					},
				});
			} else {
				resolve(this._ss.agentsOfSubmanager);
			}
		});
	}

	async getAgentsList() {
		return await new Promise<Users[]>((resolve, rej) => {
			this._ss
				.GetAllAgents(this.agentsToGet)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						if (typeof res === 'object' && res.recordset) {
							return resolve(res.recordset.filter(ag => ag.Admin !== 'b2b'));
						}

						rej();
					},
					error: err => {
						console.log({ err });
						rej();
					},
				});
		});
	}

	async handleGetAgents() {
		try {
			if (this._ss.CustomerInformation.Submanagers === '1') {
				this.agentsToGet = await this.getSubmanagerAgentsFirst();
			}
			this.agentsForAdmin = await this.getAgentsList();
		} catch (error) {}
	}

	agentsSet = new Set<string>();

	async handleGetAdminStats() {
		const agentsArr$ = this.agentsForAdmin.map(ag => this._ss.GetBizoimLekohotYearCustomAgent(ag.AgentID).pipe(catchError(err => of({}))));

		this.agents = this.agentsForAdmin.map(agent => ({ id: agent.AgentID, name: agent.UserName }));

		const MAX_PARALLEL_QUERIES = 1;
		const observables = agentsArr$;
		from(observables)
			.pipe(
				mergeMap(observable => observable, MAX_PARALLEL_QUERIES),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: partialResults => {
					this.Bizoim = this.Bizoim.concat(partialResults);
				},
				complete: () => {
					console.log({ result: this.Bizoim });

					this.Bizoim = Object.values(
						this.Bizoim.reduce((p, c) => {
							for (const client of c.recordset) {
								if (!client.Tot && !client.Ltot) continue;
								this.agentsSet.add(client.ag);
								if (!p[client.cl]) {
									p[client.cl] = client;
									p[client.cl].Client_Name = client.cn;
									p[client.cl].ClientId = client.cl;
									p[client.cl].agent = client.ag;
								}
							}

							return p;
						}, {}),
					);

					this.BizoimSwitch = this.AllClientsSearch = [...this.Bizoim];

					this.Bizoim.sort(function (a, b) {
						return b.ahuz - a.ahuz;
					});

					// console.log({ bizSorted: this.Bizoim.map(bz => bz.cl).sort() });

					this.Loading = false;

					this.agents = this.agents.filter(ag => this.agentsSet.has(ag.id));

					// setTimeout(() => {
					this.refreshSubjects('bizoim');
					// }, 0);
				},
			});
	}

	async handleGetAdminStatsWithYaad() {
		this.agents = this.agentsForAdmin.map(agent => ({ id: agent.AgentID, name: agent.UserName }));

		this._ss.GetBizoimLekohotYearWithYaadAdmin().subscribe({
			next: (res: any) => {
				if (res === 'RequestError' || res === 'ConnectionError') {
					alert('ישנה תקלת שרת, אנא נסו שנית מאוחר יותר');
					return;
				}

				this.Bizoim = Object.values(
					res.recordset.reduce((prev, curr) => {
						const { ag, Cl } = curr;

						this.agentsSet.add(ag);

						if (!prev[Cl]) {
							prev[Cl] = curr;
							prev[Cl].agent = curr.ag;
						}

						return prev;
					}, {}),
				);

				this.BizoimSwitch = this.AllClientsSearch = [...this.Bizoim];

				this.Bizoim.sort(function (a, b) {
					return b.ahuz - a.ahuz;
				});

				console.log({ agents: this.agentsSet });

				for (const b of this.BizoimSwitch) {
					if (b.Tot && b.Yaad1) {
						b.advancementPercentage = (b.Tot / b.Yaad1) * 100;
					}
				}

				this.Loading = false;

				this.agents = this.agents.filter(ag => this.agentsSet.has(ag.id));

				this.refreshSubjects('bizoim');
			},
		});
	}

	handleMatSelectChange(agentsArr) {
		if (agentsArr.length) {
			this.Bizoim = this.BizoimSwitch.filter(b => agentsArr.includes(b.agent));
		} else {
			this.Bizoim = this.BizoimSwitch;
		}

		this.refreshSubjects('bizoim');
	}

	async handleGetAdminStatsMonths({ ClientId, Company, Year }) {
		ClientId = encodeURIComponent(ClientId);

		this.BizoimEach = [];

		for (const agent of this.agentsForAdmin) {
			const res = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/user/bizoimeachclientyear/${ClientId}/${agent.AgentID}/${Company}/${Year}`);

			const body = await res.json();

			if (body.recordset?.length) {
				this.BizoimEach = body.recordset;

				console.log(this.BizoimEach);

				this.refreshSubjects('bizoimEach');
				return;
			}
		}
	}
	async handleGetAdminStatsMonthsSingle({ ClientId, Company, Year, Month }) {
		ClientId = encodeURIComponent(ClientId);

		this.BizoimEach2 = [];

		for (const agent of this.agentsForAdmin) {
			const res = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/user/bizoimeachclient/${ClientId}/${agent.AgentID}/${Company}/${Month}/${Year}`);

			const body = await res.json();

			if (body.recordset?.length) {
				this.BizoimEach2 = body.recordset;

				this.refreshSubjects('bizoimEach2');

				console.log(this.BizoimEach2);

				// this.agents = this.BizoimEach2.map(bz=>bz.ag).filter((v,i,a)=>a.indexOf(v)===i)
				// console.log(this.agents);

				this.BizoimEach2.sort((a, b) => new Date(a.dd.split('-').reverse().join('-')).getTime() - new Date(b.dd.split('-').reverse().join('-')).getTime());
				return;
			}
		}
	}

	refreshSubjects(mode: 'bizoim' | 'bizoimEach' | 'bizoimEach2') {
		setTimeout(() => {
			if (mode === 'bizoim') {
				this.bizoimTotal$.next(this.Bizoim.reduce((p, c) => p + c.Tot, 0));
				this.bizoimAverage$.next(this.Bizoim.reduce((p, c) => p + c.Tot, 0) / this.Bizoim.length);
				return;
			}

			if (mode === 'bizoimEach') {
				this.bizoimEachTotal$.next(this.BizoimEach.reduce((p, c) => p + c.Tot, 0));
				this.bizoimEachAverage$.next(this.BizoimEach.reduce((p, c) => p + c.Tot, 0) / this.BizoimEach.length || 0);
				return;
			}

			if (mode === 'bizoimEach2') {
				this.bizoimEach2Total$.next(this.BizoimEach2.reduce((p, c) => p + c.tsum, 0));
				this.bizoimEach2Average$.next(this.BizoimEach2.reduce((p, c) => p + c.tsum, 0) / this.BizoimEach2.length || 0);
				return;
			}

			// if (mode === 'oneTeudaInfo') {
			//   this.oneTeudaInfoTotal$.next(this.OneTeudaInfo.reduce((p, c) => p + c.prc, 0))
			//   this.oneTeudaInfoAverage$.next((this.OneTeudaInfo.reduce((p, c) => p + c.prc, 0) / this.OneTeudaInfo.length) || 0)
			//   return
			// }
		}, 0);
	}

	changeMode(makeActiveElm: HTMLDivElement, disableActive: HTMLDivElement) {
		disableActive.classList.remove('active');
		makeActiveElm.classList.add('active');
	}

	public AH = false;
	public TYA = false;
	public lYA = false;

	public SortAhoz() {
		if (this.AH === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ahuz - a.Ahuz;
			});

			this.AH = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ahuz - b.Ahuz;
			});

			this.AH = false;
		}
	}

	public SortThisYear() {
		if (this.TYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Tot - a.Tot;
			});

			this.TYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Tot - b.Tot;
			});

			this.TYA = false;
		}
	}

	public SortLastYear() {
		if (this.lYA === false) {
			this.Bizoim.sort(function (a, b) {
				return b.Ltot - a.Ltot;
			});

			this.lYA = true;
		} else {
			this.Bizoim.sort(function (a, b) {
				return a.Ltot - b.Ltot;
			});

			this.lYA = false;
		}
	}

	public ChangeMonth() {
		this.Bizoim = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].Mm1 === this.form.controls['Month'].value && this.BizoimSwitch[x].Yy1 === this.test2 && this.BizoimSwitch[x].cm === this.CompanyChoosen) {
				this.Bizoim.push(this.BizoimSwitch[x]);
			}
		}

		this.AllClientsSearch = this.Bizoim;
	}

	public ChangeCompany() {
		this.form.controls['Month'].reset();

		this.CompanyChoosen = JSON.parse(this.form.controls['Company'].value);

		this.AllClientsSearch = [];

		for (let x = 0; x < this.BizoimSwitch.length; x++) {
			if (this.BizoimSwitch[x].cm === JSON.parse(this.form.controls['Company'].value)) {
				this.AllClientsSearch.push(this.BizoimSwitch[x]);
			}
		}

		this.Bizoim = this.AllClientsSearch;

		this.refreshSubjects('bizoim');
	}

	public BackToDohotBizoim() {
		if (this.FirstPage === true) {
			// this.router.navigate(['dohotbizoim'])
			window.history.back();
		}

		if (this.SecondPage === true) {
			this.Loading = false;
			this.SecondPage = false;
			this.FirstPage = true;
			this.BizoimEach = null;
			this.refreshSubjects('bizoim');
		}

		if (this.ThirdPage === true) {
			this.Loading = false;
			this.ThirdPage = false;
			this.SecondPage = true;
			this.BizoimEach2 = null;
			this.refreshSubjects('bizoimEach');
		}

		if (this.FourthPage === true) {
			this.Loading = false;
			this.FourthPage = false;
			this.ThirdPage = true;
			this.OneTeudaInfo = null;
			this.refreshSubjects('bizoimEach2');
		}
	}

	public OneTeuda(number) {
		this.ThirdPage = false;
		this.FourthPage = true;

		this.teudaNum = number;

		this._ss.GetOneTeudaDohot(number).subscribe(res => {
			this.OneTeudaInfo = res;
			this.OneTeudaInfo = this.OneTeudaInfo.recordset;

			// this.refreshSubjects('oneTeudaInfo')
		});
	}

	//THIS IS IN THE SAME PAGE NOT IN NEW PAGE
	async MoveToDohotBizoimLekohotEachYear({ ClientId, AgentId, Company, Year, Client_Name }) {
		//cl,b.ag,b.cm,b.Yy1

		this.currentClientNameAndID = `${ClientId}${Client_Name ? ' - ' + Client_Name : ''}`;

		this.Loading = true;
		this.FirstPage = false;
		this.SecondPage = true;

		if (this.admin && this.isInAdminPage && !this.hasYaad) {
			await this.handleGetAdminStatsMonths({ ClientId, Company, Year });
			return;
		}

		if (this._ss.CustomerInformation.YaadClients === '1' || this._ss.CustomerInformation.YaadClients === '2') {
			const sendAgent = this.hasYaad ? AgentId : undefined;
			this._ss.EachClientDohotYearWithYaad(ClientId, Company, Year, sendAgent).subscribe({
				next: (res: any) => {
					this.BizoimEach = res.recordset;

					this.BizoimEach = this.BizoimEach.map(gl => {
						if (gl.Tot && (gl.Yaad2 || gl.Yaad1)) {
							gl.advancementPercentage = (gl.Tot / (gl.Yaad2 || gl.Yaad1)) * 100;
						}
						return gl;
					});

					console.log(this.BizoimEach);

					this.Loading = false;

					this.refreshSubjects('bizoimEach');
				},
			});
			return;
		}

		this._ss.EachClientDohotYear(ClientId, Company, Year, AgentId).subscribe((res: any) => {
			// this.BizoimEach = res
			this.BizoimEach = res.recordset;

			this.Loading = false;

			this.refreshSubjects('bizoimEach');
		});
	}

	async MoveToDohotBizoimLekohotEach(ClientId, Company, Month, Year) {
		this.Loading = true;
		this.SecondPage = false;
		this.ThirdPage = true;

		this.clickedMonth = Month;

		if (this.admin && this.isInAdminPage) {
			await this.handleGetAdminStatsMonthsSingle({ ClientId, Company, Year, Month });
			return;
		}

		this._ss.EachClientDohot(ClientId, Company, Month, Year).subscribe((res: any) => {
			this.BizoimEach2 = res.recordset;

			this.Loading = false;

			this.refreshSubjects('bizoimEach2');
		});
	}

	public SearchClear() {
		this.Bizoim = this.BizoimSwitch;

		this.form.reset();

		this.refreshSubjects('bizoim');
	}

	public SearchClient() {
		const searchInput = this.form.controls['ClientSearch'].value?.toLowerCase?.();
		const searchInputLength = searchInput?.length;

		this.Bizoim = [];

		this.refreshSubjects('bizoim');

		if (!searchInput) {
			this.Bizoim = this.BizoimSwitch;
			return;
		}

		if (searchInputLength <= 2) {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					(this.AllClientsSearch[x].Client_Name || this.AllClientsSearch[x].Cn || '').slice(0, searchInputLength).toLowerCase() === searchInput ||
					(this.AllClientsSearch[x].ClientId || this.AllClientsSearch[x].Cl || '').slice(0, searchInputLength).toLowerCase() === searchInput
				) {
					this.Bizoim.push(this.AllClientsSearch[x]);
				}
			}
			return;
		}

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			for (let z = 0; z < 50; z++) {
				const len = searchInputLength + z;

				if ((this.AllClientsSearch[x].Client_Name || this.AllClientsSearch[x].Cn || '').slice(z, len) === searchInput) {
					this.Bizoim.push(this.AllClientsSearch[x]);
					break;
				}

				if ((this.AllClientsSearch[x].ClientId || this.AllClientsSearch[x].Cl || '').slice(z, len) === searchInput) {
					this.Bizoim.push(this.AllClientsSearch[x]);
					break;
				}
			}
		}
	}

	getBizoimLekohotWithoutYaad() {
		this._ss.GetBizoimLekohotYear().subscribe(res => {
			this.AllClientsSearch = res;
			this.AllClientsSearch = this.AllClientsSearch.recordset;

			if (Array.isArray(this.AllClientsSearch)) {
				this.AllClientsSearch = this.AllClientsSearch.filter(client => client.Tot || client.Ltot);
			}

			this.BizoimSwitch = this.AllClientsSearch;

			this.test = this.DateToday.toISOString().toString().slice(5, 7);
			this.test2 = this.DateToday.toISOString().toString().slice(0, 4);

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				this.AllClientsSearch[x].Yy1 = JSON.stringify(this.AllClientsSearch[x].Yy1);
				this.AllClientsSearch[x].Client_Name = this.AllClientsSearch[x].cn;
				this.AllClientsSearch[x].ClientId = this.AllClientsSearch[x].cl;

				this.Bizoim.push(this.AllClientsSearch[x]);
			}

			this.BizoimSwitch = this.AllClientsSearch;

			this.AllClientsSearch = this.Bizoim;

			this.Bizoim.sort(function (a, b) {
				return b.ahuz - a.ahuz;
			});

			this.Loading = false;

			setTimeout(() => {
				this.refreshSubjects('bizoim');
			}, 0);

			if (this._ss.ParamBitzoimClientCard !== null) {
				console.log(this._ss.ParamBitzoimClientCard);

				let data = this.Bizoim.find(ob => ob.cl === this._ss.ParamBitzoimClientCard);
				if (data !== undefined) {
					console.log(data);

					this.MoveToDohotBizoimLekohotEachYear({ ClientId: data.cl, AgentId: data.ag, Company: data.cm, Year: data.Yy1, Client_Name: data.Client_Name });
				}
			}
		});
	}

	getBizoimLekohotWithYaad() {
		this._ss.GetBizoimLekohotYearWithYaad().subscribe(res => {
			this.AllClientsSearch = res;
			this.AllClientsSearch = this.AllClientsSearch.recordset;
			this.BizoimSwitch = this.AllClientsSearch;

			this.test = this.DateToday.toISOString().toString().slice(5, 7);
			this.test2 = this.DateToday.toISOString().toString().slice(0, 4);

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				const client = this.AllClientsSearch[x];

				if (!client.Tot && !client.tot && !client.Ltot && !client.ltot) {
					delete this.AllClientsSearch[x];
					continue;
				}
				client.Yy1 = JSON.stringify(client.Yy1);
				client.Client_Name = client.cn;
				client.ClientId = client.cl;

				if (client.Tot && client.Yaad1) {
					client.advancementPercentage = (client.Tot / client.Yaad1) * 100;
				}

				this.Bizoim.push(this.AllClientsSearch[x]);
			}

			this.AllClientsSearch = this.Bizoim;

			this.Bizoim.sort(function (a, b) {
				return b.Ahuz - a.Ahuz;
			});

			this.Loading = false;

			setTimeout(() => {
				this.refreshSubjects('bizoim');
			}, 0);

			if (this._ss.ParamBitzoimClientCard !== null) {
				console.log(this._ss.ParamBitzoimClientCard, this.Bizoim);

				// Yaad comes with different names

				let data = this.Bizoim.find(ob => (ob.cl || ob.Cl) === this._ss.ParamBitzoimClientCard);
				if (data !== undefined) {
					this.MoveToDohotBizoimLekohotEachYear({
						ClientId: data.cl || data.Cl,
						AgentId: data.ag || this._ss.UserIdConnected,
						Company: data.cm,
						Year: data.Yy1,
						Client_Name: data.Client_Name || data.Cn,
					});
				}
			}
		});
	}

	translateForSort(value: typeof valuesObject[typeof screens[number]][number], screen: typeof screens[number]): void {
		let i: number,
			arrayToSort: any[] = [];

		if (screen === 'Bizoim') {
			arrayToSort = this.Bizoim;
		}

		if (screen === 'BizoimEach') {
			arrayToSort = this.BizoimEach;
		}

		if (screen === 'BizoimEach2') {
			arrayToSort = this.BizoimEach2;
		}

		if (screen === 'OneTeudaInfo') {
			arrayToSort = this.OneTeudaInfo;
		}

		i = valuesObject[screen].findIndex(arr => arr === value);

		if (i === undefined || i === -1) {
			console.log('i should never show');
			return;
		}

		this._util.sortWithIndex(value, i, arrayToSort);
	}

	downloadExcel() {
		const dataObject: ExcelObject = { data: [], filename: '', extension: 'xlsx' };

		const { transform } = new ReturnMonthHebrewPipe();

		const table = document.querySelector('table');
		const title = document.querySelector('.header__title');

		if (table) {
			if (title) {
				dataObject.filename = title.textContent;
			}

			if (this.SecondPage) dataObject.filename = 'ביצועים לקוח  ' + this.currentClientNameAndID;
			if (this.ThirdPage) dataObject.filename = 'תעודות לקוח  ' + this.currentClientNameAndID + ' ' + transform(+this.clickedMonth - 1);
			if (this.FourthPage) dataObject.filename = 'תעודה ' + this.teudaNum;

			const wb = utils.table_to_book(table, { sheet: dataObject.filename.slice(0, 31) });

			if (!wb.Workbook) wb.Workbook = {};
			if (!wb.Workbook.Views) wb.Workbook.Views = [{}];
			wb.Workbook.Views[0].RTL = true;

			writeFile(wb, `${dataObject.filename}.${dataObject.extension}`, { bookType: dataObject.extension, compression: true, cellStyles: true });
			return;
		}

		console.log('no table to download!');
	}
}
