<div class="main-wrapper">
	<div class="main-content" [ngClass]="{ 'gray-background': isCatalogAgent }">
		<!-- global markup for header -->
		<header class="order-header header">
			<div class="content">
				<div class="header__block row">
					<div class="header__box">
						<div class="header__basket">
							<div class="header__basket-image" [ngClass]="{ bigger: _ss.CustomerInformation.HeaderDisplay === '1' }">
								<div class="header__basket-image-number">
									{{ this.AllOrder.length }}
								</div>
							</div>
							<span
								class="header__basket-text"
								[ngClass]="{ bigger: _ss.CustomerInformation.HeaderDisplay === '1' }"
								*ngIf="this._ss.SeeTotalPrice !== '1' && (_ss.OneClientInfo.DSP !== 2 || TotalOrderOverride === '1') && canSeePrices && !isLeasingOrder && !isCatalogAgent"
							>
								{{ this.TotalSumOAllOrder | number : '1.0-2' : 'en-US' }}
							</span>
						</div>
						<!-- <div class="header__barcode"></div> -->
					</div>

					<!-- option search start keyup 0-->
					<div class="header__search" *ngIf="this._ss.SearchStyle === 1">
						<form [formGroup]="form" (keyup)="SearchProducts()">
							<input
								class="header__search-input"
								type="text"
								placeholder="חיפוש "
								formControlName="ProductSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="productsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon" (click)="SearchClear()"></button>
					</div>
					<!-- option search ends keyup -->

					<!-- option search ends click 1-->
					<div class="header__search" *ngIf="this._ss.SearchStyle === 0">
						<button class="header__search-icon image" style="position: absolute; top: 50%; left: 5%" (click)="SearchProducts()">
							<img src="/assets/images/header-search.svg" alt="search" />
						</button>
						<form [formGroup]="form" (keyup.enter)="SearchProducts()">
							<input
								class="header__search-input"
								type="text"
								placeholder="חיפוש"
								formControlName="ProductSearch"
								style="text-align: center; font-size: 17px; font-weight: 600"
								dir="rtl"
								id="productsearch"
								autocomplete="off"
							/>
						</form>
						<button class="header__clear-icon" (click)="SearchClear()"></button>
					</div>
					<!-- option search ends click -->

					<div class="header__burger col image" (click)="BackToProducts()" *ngIf="BackArrowPop === true">
						<img src="/assets/images/header/back-arrow.svg" alt="burger" /> <span class="back-arrow-text">חזרה</span>
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- order cart gray section -->
		<section class="order-cart-gray" *ngIf="!orderProcess">
			<div class="content">
				<ng-container *ngIf="!isCatalogAgent; else catalogTitleBlock">
					<div class="order-cart-gray__row row">
						<div class="order-cart-gray__title">
							{{ this._ss.DocTypeName }} - <span [ngStyle]="{ color: Mode ? 'green' : 'red' }">{{ Mode ? 'ONLINE' : 'OFFLINE' }}</span>
							<!-- {{ this._ss.DocTypeName }} -
							<span *ngIf="_online.networkStatus$ | async;else offline" style="color:green">ONLINE</span>
							<ng-template #offline>
								<span style="color:red">OFFLINE</span>
							</ng-template> -->
						</div>
						<div class="order-cart-gray__descrip">{{ this._ss.OneClientInfo.Client_Name }}</div>
					</div>
					<div class="order-cart-gray__row row">
						<div class="order-cart-gray__block order-cart-gray__block_double">
							<div class="order-cart-gray__block-title">סה"כ פריטים</div>
							<div class="order-cart-gray__block-number">{{ this.AllOrder.length }}</div>
						</div>
						<div
							class="order-cart-gray__block order-cart-gray__block_double"
							*ngIf="_ss.SeeTotalPrice !== '1' && (_ss.OneClientInfo.DSP !== 2 || TotalOrderOverride === '1') && canSeePrices && !isLeasingOrder"
						>
							<div class="order-cart-gray__block-title">סכום</div>
							<div class="order-cart-gray__block-number order-cart-gray__block-number_price">
								{{ (isCreditCardOrder ? CustDiscountShowVat || TotalSumOAllOrderPlusVat : TotalSumOAllOrder) | number : '1.0-2' : 'en-US' }}
							</div>
						</div>
						<div class="order-cart-gray__block order-cart-gray__block_double" *ngIf="this.TotalArizot !== 0">
							<div class="order-cart-gray__block-title">סה"כ {{ _text.packQuantityText }}</div>
							<div class="order-cart-gray__block-number">{{ TotalArizot | number : '1.0-2' : 'en-US' }}</div>
						</div>
						<div class="order-cart-gray__block order-cart-gray__block_double" *ngIf="showDates">
							<div class="order-cart-gray__block-title">תאריך אספקה</div>
							<form [formGroup]="form">
								<input
									type="date"
									class="order-cart-gray__block-number"
									style="background: unset; border: unset; user-select: none"
									formControlName="SupplyDate"
									onkeydown="return false"
									(change)="changeSupplyTeuda($event)"
								/>
							</form>
							<!-- <div class="order-cart-gray__block-number">{{this.SupplyDateTeuda | date : 'd/M/yy' : 'gmt+3'}}</div> -->
						</div>
						<div class="order-cart-gray__block order-cart-gray__block_double" *ngIf="teudaRemark5ShowPrice && _ss.DocType === 170">
							<div class="order-cart-gray__block-title">סכום נדרש</div>
							<div class="order-cart-gray__block-number red-underlined" [ngClass]="{ 'red-underlined': +TotalSumOAllOrder < +_ss.HearaNewOrder5 }">
								{{ _ss.HearaNewOrder5 | number : '1.0-2' }}
							</div>
						</div>
					</div>
					<div class="order-cart-gray__row order-cart-gray__row_button row">
						<button class="order-cart-gray__block-button order-cart-gray__block-button_green" (click)="SendTeudaFunction()" *ngIf="Mode && !isReclamationOrder">
							{{ isCreditCardOrder ? sendOrderText : sendOrderTextGeneric }}
						</button>
						<button class="order-cart-gray__block-button order-cart-gray__block-button_gray" (click)="SaveTeudaFunction()" *ngIf="!isReclamationOrder">שמור</button>
						<div class="order-cart-gray__block-void" (click)="CancelOrderFunction()">מחק</div>
					</div>
					<div class="order-cart-gray__row row" style="flex-direction: column; justify-content: center; margin: 1rem 0" *ngIf="canOpenEmailPopup" (click)="isEmailPickPopupOpen = true">
						<button class="order-cart-gray__block-button order-cart-gray__block-button_gray" style="max-width: fit-content; padding-inline: 1em">דוא"ל לשליחה</button>
						<form [formGroup]="form">
							<ng-container [ngSwitch]="form.controls.emailRadio.value">
								<ng-container *ngSwitchCase="'custom'">
									<input type="text" class="order-cart-gray__block-number" formControlName="Email" readonly style="background: unset; border: unset; user-select: none" />
								</ng-container>
								<ng-container *ngSwitchCase="'client'">
									<h3>{{ clientHasEmail }}</h3>
								</ng-container>
								<ng-container *ngSwitchCase="'user'">
									<h3>{{ _user.agentEmail$ | async }}</h3>
								</ng-container>
								<ng-container *ngSwitchCase="'none'">
									<h3>לא יישלח דוא"ל</h3>
								</ng-container>
							</ng-container>
						</form>
					</div>
				</ng-container>
				<ng-template #catalogTitleBlock>
					<div class="order-cart-gray__row row">
						<div class="order-cart-gray__title">
							{{ this._ss.DocTypeName }} - <span [ngStyle]="{ color: Mode ? 'green' : 'red' }">{{ Mode ? 'ONLINE' : 'OFFLINE' }}</span>
						</div>
					</div>
				</ng-template>
			</div>
		</section>
		<!-- end order cart gray section -->

		<!-- order step section -->
		<section class="order-step" [ngClass]="{ 'keep-page-to-right': isLeasingOrder }" *ngIf="orderProcess === false">
			<div class="content" *ngIf="!isLeasingOrder; else leasingBlock">
				<ng-container *ngIf="!isReclamationOrder && !isCatalogAgent">
					<div class="order-step__row row">
						<div class="order-step__item" (click)="showHideFirstStepFunction()">
							<div class="order-step__item-number">1</div>
							<div class="order-step__item-title">סיכום {{ this._ss.DocTypeName }}</div>
							<div class="order-step__item-edit"></div>
						</div>
						<div class="order-step__block" *ngIf="showHideFirstStep === true">
							<div class="order-step__product" *ngFor="let product of AllProducts; index as i" style="touch-action: manipulation">
								<!-- <div class="order-step__product-row order-step__product-row_gray">
									<div class="order-step__product-row-image image">
									  <img src="/assets/images/basket/product-company-photo.png" alt="photo">
									</div>
									<div class="order-step__product-row-title">מחלקות</div>
									<div class="order-step__product-row-number">256524</div>
								 </div> -->
								<div class="order-step__product-block" *ngIf="!product.SpecialID; else specialBlock">
									<div class="order-step__product-close" (click)="DeleteProductFromTeuda(product.ProductId, product.Product_Name)"></div>
									<h2 class="order-step__product-title">
										{{ product.Product_Name }}<br />
										<span class="order-step__product-title-brackets" *ngIf="product.qt === 0 || product.qt === 2">
											{{ product.Unit && _ss.CustomerInformation.ItemPackDisplay === '1' ? product.Unit : 'באריזה ' + product.ItemPack }}
										</span>
									</h2>
									<div class="order-step__product-block-row">
										<div class="order-step__product-block-col order-step__product-block-col_image image">
											<img
												src="{{ this._ss.CustomerInformation.CustImageDomain }}/{{ product.ItemPicName | encodeURI }}.jpg"
												class="ItemPic"
												onerror="this.onerror=null; this.src=MOBILITY_LOGO"
												alt="photo"
											/>
										</div>
										<div class="order-step__product-block-col order-step__product-block-col_text">
											<div class="row">
												<ng-container *ngIf="showItemExtra4InsteadOfBarcode; else itemExtra4InsteadOfBarcodeBlock">
													<div class="order-step__product-block-text order-step__product-block-text_gray">ברקוד:</div>
													<div class="order-step__product-block-text">{{ product.ItemBarcode }}</div>
												</ng-container>
												<ng-template #itemExtra4InsteadOfBarcodeBlock>
													<div class="order-step__product-block-text">{{ product.ItemExtra4 || '' }}</div>
												</ng-template>
											</div>
											<div class="row">
												<div class="order-step__product-block-text order-step__product-block-text_gray">קוד פריט:</div>
												<div class="order-step__product-block-text">{{ product.ProductId }}</div>
											</div>
											<div class="row" *ngIf="product.BS | shouldStockShow : 'productPopup' : false : Admin">
												<div class="order-step__product-block-text order-step__product-block-text_gray">מלאי</div>
												<div class="order-step__product-block-text order-step__product-block-text_bold" dir="ltr">
													{{ (showTranslatedStock ? product.Stock / product.ItemPack : product.Stock) | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
										<div class="order-step__product-block-col order-step__product-block-col_input">
											<div class="row" [ngClass]="{ expandable: product.TeudaQuantity > 9999 }" *ngIf="(this._ss.SeeArizot && !hidePacks) || shouldOverrideAgentProductQuantities">
												<h3 class="order-product__product-block-count-title" *ngIf="!hasWeightTypeUnitsBehaviors || !product.packsWeightMode; else productPopupWeightToggleButton">
													{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'pack') : _text.packQuantityText }}

													<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
												</h3>
												<ng-template #productPopupWeightToggleButton>
													<h3 class="order-product__product-block-count-title" (click)="toggleProductBetweenBoxesAndPacks(product)">
														<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
															{{ product.packsWeightMode === 'packs' ? _text.packQuantityText : 'קופסאות' }}
															<span *ngIf="calculatePackagesTotalQuantity">({{ (product.ItemPack || 1) * (product.TeudaPackQuantity || 0) | number : '1.0-2' }})</span>
														</span>
													</h3>
												</ng-template>
												<div class="order-product__product-block-count-row">
													<button class="order-product__product-block-count-button order-product__product-block-count-button_add" (click)="ArizotPlus(product)"></button>
													<form [formGroup]="form" (keyup)="ArizotInput(product)">
														<input
															class="order-product__product-block-count-input"
															type="number"
															min="0"
															placeholder="{{ product.TeudaPackQuantity }}"
															id="{{ product.TeudaPackQuantity }}"
															formControlName="Arizot"
															[attr.disabled]="((product.qt === 1 || product.qt === 3) && !shouldOverrideAgentProductQuantities) || null"
														/>
													</form>
													<button class="order-product__product-block-count-button" (click)="ArizotMinus(product)"></button>
												</div>
											</div>
											<div class="row" [ngClass]="{ expandable: product.TeudaQuantity > 9999 }" *ngIf="!hideSingles">
												<h3 class="order-product__product-block-count-title" *ngIf="!hasWeightTypeUnitsBehaviors || !product.weightMode; else productPopupWeightToggleButton">
													{{ hasWeightTypeUnitsBehaviors ? (product | weightTypeText : 'single') : 'יחידות' }}
												</h3>
												<ng-template #productPopupWeightToggleButton>
													<h3 class="order-product__product-block-count-title" (click)="toggleProductBetweenWeightAndSingles(product)">
														<span style="background: #e8e8e8; padding: 0 5px; border-radius: 5px">
															{{ product.weightMode === 'singles' ? 'יחידות' : 'משקל' }}
														</span>
													</h3>
												</ng-template>
												<div class="order-product__product-block-count-row">
													<button class="order-product__product-block-count-button order-product__product-block-count-button_add" (click)="YehidotPlus(product)"></button>
													<form [formGroup]="form" (keyup)="YehidotInput(product)">
														<input
															class="order-product__product-block-count-input"
															type="number"
															min="0"
															placeholder="{{ product.TeudaQuantity }}"
															id="Yehidot{{ product.ProductId }}"
															formControlName="Yehidot"
															[attr.disabled]="((product.qt === 1 || product.qt === 2) && !shouldOverrideAgentProductQuantities) || null"
														/>
													</form>
													<button class="order-product__product-block-count-button" (click)="YehidotMinus(product)"></button>
												</div>
											</div>
										</div>
									</div>
									<div class="order-step__product-block-row row" *ngIf="_ss.OneClientInfo.DSP !== 2 && canSeePrices">
										<ng-container *ngIf="!alternativeProductCalculations; else alternativePriceCalc">
											<div class="order-step__product-block-box">
												<div class="order-step__product-block-box-title">מחיר יחידה</div>
												<div class="order-step__product-block-box-price order-step__product-block-box-price_small">
													{{ product.PermPrice | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
											<div class="order-step__product-block-box" *ngIf="!isB2BAgent">
												<div class="order-step__product-block-box-title">עדכן מחיר</div>
												<div class="order-step__product-block-box-input order-step__product-block-box-input_active">
													<form [formGroup]="form" (keyup)="ChangePriceFunction(product)">
														<input
															type="number"
															placeholder="{{ product.PriceInInput | number : '1.0-2' : 'en-US' }}"
															formControlName="ChangePrice"
															[attr.disabled]="
																shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
															"
															style="width: 70px; height: 30px; font-size: 15px"
														/>
													</form>
												</div>
											</div>
										</ng-container>
										<ng-template #alternativePriceCalc>
											<div class="order-step__product-block-box" *ngIf="!isB2BAgent">
												<div class="order-step__product-block-box-title">עדכן מחיר</div>
												<div class="order-step__product-block-box-input order-step__product-block-box-input_active">
													<form [formGroup]="form" (keyup)="changeBasePrice(product)">
														<input
															type="number"
															placeholder="{{ product.basePriceInputted || product.basePrice || 0 | number : '1.0-2' : 'en-US' }}"
															formControlName="ChangeBasePrice"
															[attr.disabled]="
																shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
															"
															style="width: 70px; height: 30px; font-size: 15px"
														/>
													</form>
												</div>
											</div>
											<div class="order-step__product-block-box" *ngIf="!isB2BAgent">
												<div class="order-step__product-block-box-title">עדכן נטו</div>
												<div class="order-step__product-block-box-input order-step__product-block-box-input_active">
													<form [formGroup]="form" (keyup)="ChangePriceFunctionAlternative(product)">
														<input
															type="number"
															placeholder="{{ product.NetPrice | number : '1.0-2' : 'en-US' }}"
															formControlName="ChangePrice"
															[attr.disabled]="
																shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BP === 1 || null
															"
															style="width: 70px; height: 30px; font-size: 15px"
														/>
													</form>
												</div>
											</div>
										</ng-template>
										<div class="order-step__product-block-box" *ngIf="!isB2BAgent">
											<div class="order-step__product-block-box-title">עדכן הנחה</div>
											<div class="order-step__product-block-box-input">
												<form [formGroup]="form" (keyup)="ChangeDiscountFunction(product)">
													<input
														type="number"
														placeholder="{{ product.ChangeDiscount | number : '1.0-2' : 'en-US' }}"
														formControlName="ChangeDiscount"
														[attr.disabled]="
															shouldOverrideAgentProductQuantities ? null : shouldDisablePriceAndDiscountInput || shouldDisableChangingInputs ? true : product.BD === 1 || null
														"
														style="width: 70px; height: 30px; font-size: 15px"
													/>
												</form>
											</div>
										</div>
										<div class="order-step__product-block-box" *ngIf="this._ss.SeeTotalPrice !== '1'">
											<div class="order-step__product-block-box-title">סה"כ</div>
											<div class="order-step__product-block-box-price" [ngClass]="{ 'order-step__product-block-box-price_green': product.Price !== product.PermPrice }">
												{{ product.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
											</div>
										</div>
									</div>
									<div class="order-step__product-block-row" *ngIf="!isB2BAgent">
										<div class="order-step__product-block-icon image bonus" [ngClass]="{ active: product.TeudaBonusQuantity }">
											<img src="/assets/images/order/present-icon.svg" alt="photo" />
										</div>
										<div class="order-step__product-block-title">
											{{ product.TeudaBonusQuantity | getBonusNumber : product.TeudaQuantity : product.TeudaPackQuantity : product.ItemPack }} +
										</div>
									</div>
									<div class="order-step__product-block-row" *ngIf="_ss.CustomerInformation.TeudaItemRemark !== '1' || !isB2BAgent">
										<div class="order-step__product-block-icon image" (click)="remarkInput.focus()">
											<img src="/assets/images/basket/chat-icon.svg" alt="photo" />
										</div>
										<div class="order-step__product-block-box-input">
											<input
												type="text"
												[placeholder]="product.TeudaItemRemark"
												style="width: auto; height: 30px; font-size: 15px"
												class="remark-input"
												#remarkInput
												(input)="handleChangeRemark(product, $event)"
											/>
										</div>
										<div class="order-step__product-block-edit" (click)="remarkInput.focus()"></div>
									</div>
								</div>
								<ng-template #specialBlock>
									<div class="order-step__product-block">
										<!-- <div class="order-step__product-close" ></div> -->
										<h2 class="order-step__product-title">{{ product.SpecialName }}<br /></h2>
										<div class="order-step__product-block-row justify-evenly">
											<div class="order-step__product-block-col order-step__product-block-col_image image" (click)="handleOpenSpecialDialog(product.SpecialID)">
												<img
													src="{{ this._ss.CustomerInformation.CustImageDomain }}/SPECIAL{{ product.SpecialID | encodeURI }}.jpg"
													class="ItemPic"
													onerror="this.onerror=null; this.src=MOBILITY_LOGO"
													alt="photo"
												/>
											</div>
											<div *ngIf="!hasConcentratedSpecials" class="order-step__product-block-col order-step__product-block-col_input">
												<div class="row" [ngClass]="{ expandable: product.TeudaQuantity > 9999 }">
													<h3 class="order-product__product-block-count-title">כמות</h3>
													<div class="order-product__product-block-count-row">
														<button
															class="order-product__product-block-count-button order-product__product-block-count-button_add"
															(click)="changeSpecialQuantity(product.SpecialID, 'increment', 1)"
														></button>
														<input
															class="order-product__product-block-count-input"
															type="number"
															placeholder="{{ product.quantity }}"
															id="Special{{ product.SpecialID }}"
															[attr.disabled]="true"
														/>
														<button class="order-product__product-block-count-button" (click)="changeSpecialQuantity(product.SpecialID, 'decrement', 1)"></button>
													</div>
												</div>
											</div>
										</div>
										<div class="order-step__product-block-row row justify-evenly">
											<div class="order-step__product-block-box">
												<div class="order-step__product-block-box-title">מחיר מבצע</div>
												<div class="order-step__product-block-box-price order-step__product-block-box-price_small">
													{{ product.TeudaItemNetPrice | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
											<div class="order-step__product-block-box" *ngIf="this._ss.SeeTotalPrice !== '1'">
												<div class="order-step__product-block-box-title">סה"כ</div>
												<div class="order-step__product-block-box-price order-step__product-block-box-price_green">
													{{ product.TeudaTotalRow | number : '1.0-2' : 'en-US' }}
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</div>
							<button class="order-step__block-button" (click)="showHideFirstStepFunctionNext()">המשך</button>
						</div>
					</div>
					<div class="order-step__row row">
						<div class="order-step__item" (click)="showHideSecondStepFunction()">
							<div class="order-step__item-number">2</div>
							<div class="order-step__item-title">פרטים נוספים</div>
							<div class="order-step__item-edit"></div>
						</div>
						<div class="order-step__block" *ngIf="showHideSecondStep === true">
							<div class="order-step__block-row">
								<div class="order-step__block-row-title">לקוח</div>
								<div class="order-step__block-row-text">{{ this._ss.OneClientInfo.Client_Name }}</div>
							</div>
							<div class="order-step__block-row">
								<div class="order-step__block-row-title">קוד לקוח</div>
								<div class="order-step__block-row-text">{{ this._ss.OneClientInfo.ClientId }}</div>
							</div>
							<div class="order-step__block-row">
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">סוג</div>
									<div class="order-step__block-row-text">הזמנה</div>
								</div>
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">פיקדון</div>
									<div class="order-step__block-row-text">{{ this.Pikadon | number : '1.0-2' : 'en-US' }}</div>
								</div>
							</div>
							<div class="order-step__block-row">
								<div class="order-step__block-row-double" *ngIf="!isB2BAgent && this._ss.CustomerInformation.CustExtra3 === '1'">
									<div class="order-step__block-row-title">הנחה כללית</div>
									<form [formGroup]="form" (keyup)="GeneraldiscountClient()">
										<input class="order-step__block-row-input order-step__block-row-input_small" type="number" formControlName="Cd" placeholder="{{ this._ss.OneClientInfo.CustDiscount }}" />
									</form>
								</div>
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">שורות</div>
									<div class="order-step__block-row-text order-step__block-row-text_padding-top">{{ this.AllOrder.length }}</div>
								</div>
							</div>
							<div class="order-step__block-row" *ngIf="(_ss.OneClientInfo.DSP !== 2 || TotalOrderOverride === '1') && canSeePrices">
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">סה"כ</div>
									<div class="order-step__block-row-text">{{ this.TotalSumOAllOrder | number : '1.0-2' : 'en-US' }}</div>
								</div>
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">+מע"מ</div>
									<div class="order-step__block-row-text">{{ this.TotalSumOAllOrderPlusVat | number : '1.0-2' : 'en-US' }}</div>
								</div>
							</div>
							<div class="order-step__block-row" *ngIf="!isB2BAgent">
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">הנחת לקוח</div>
									<div class="order-step__block-row-text">%{{ this._ss.OneClientInfo.CustDiscount | number : '1.0-2' : 'en-US' }}</div>
								</div>
								<div class="order-step__block-row-double" *ngIf="ShowProfit === '1' || ShowProfit === '2'">
									<div class="order-step__block-row-title">רווחיות תעודה</div>
									<div class="order-step__block-row-text">%{{ this.TotalSumOAllOrderProfit | number : '1.0-2' : 'en-US' }}</div>
								</div>
							</div>
							<div class="order-step__block-row" *ngIf="!isB2BAgent && (_ss.OneClientInfo.DSP !== 2 || TotalOrderOverride === '1')">
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">סה"כ לאחר הנחת לקוח</div>
									<div class="order-step__block-row-text">{{ this.CustDiscountShow | number : '1.0-2' : 'en-US' }}</div>
								</div>
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">+מע"מ לאחר הנחת לקוח</div>
									<div class="order-step__block-row-text">{{ this.CustDiscountShowVat | number : '1.0-2' : 'en-US' }}</div>
								</div>
							</div>
							<div class="order-step__block-row" *ngIf="showDates">
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">תאריך תשלום</div>
									<input class="order-step__block-row-input" type="date" />
								</div>
							</div>
							<div class="order-step__block-row" *ngIf="showDates">
								<div class="order-step__block-row-double">
									<div class="order-step__block-row-title">
										תאריך אספקה - {{ this.SupplyDateTeuda.toString().slice(8, 10) }}-{{ this.SupplyDateTeuda.toString().slice(5, 7) }}-{{ this.SupplyDateTeuda.toString().slice(0, 4) }}
									</div>
									<form [formGroup]="form" (change)="SupplyDateFunction()">
										<input class="order-step__block-row-input" type="date" formControlName="SupplyDate" />
									</form>
								</div>
							</div>
							<ng-container *ngIf="_ss.CustomerInformation.SeeDeliveryOptions !== 2">
								<div class="order-step__block-row" *ngIf="this._ss.CustomerInformation.SeeDeliveryOptions === 1">
									<div class="order-step__block-row-title">כתובת לאספקה</div>
									<form [formGroup]="form" (change)="SupplyAdressSelect()">
										<select formControlName="SupplyAdressSelect">
											<option *ngFor="let sh of SelectHearotRessupply" value="{{ sh.ADDR }}">{{ sh.ADDR }}-{{ sh.ADDR1 }}</option>
										</select>
									</form>
								</div>
								<div class="order-step__block-row">
									<div class="order-step__block-row-title">כתובת לאספקה</div>
									<form [formGroup]="form" (change)="SupplyAdressFunction()">
										<input class="order-step__block-row-input" type="text" formControlName="SupplyAdress" placeholder="{{ this.SupplyAdressTeuda }}" />
									</form>
								</div>
								<div class="order-step__block-row">
									<div class="order-step__block-row-double">
										<div class="order-step__block-row-title">עיר</div>
										<input class="order-step__block-row-input" type="text" />
									</div>
								</div>
							</ng-container>

							<button class="order-step__block-button" (click)="showHideSecondStepFunctionNext()">המשך</button>
						</div>
					</div>
					<div class="order-step__row row">
						<div class="order-step__item" (click)="showHideThreeStepFunction()">
							<div class="order-step__item-number">3</div>
							<div class="order-step__item-title">{{ stepThreeTitle }}</div>
							<div class="order-step__item-edit"></div>
						</div>
						<div class="order-step__block" *ngIf="showHideThreeStep === true">
							<div *ngIf="this._ss.CustNum === '10159'">
								<div class="order-step__block-row" *ngFor="let heara of hearotArray">
									<div>{{ heara.text }}</div>
									<div style="width: 20px; height: 20px; border: 1px solid green; margin-right: 15px" [id]="'heara' + heara.id" (click)="CheckHeara(heara)"></div>
								</div>
							</div>
							<form [formGroup]="form" (keyup)="HearaForOrder()">
								<div class="order-step__block-row">
									<div class="order-step__block-row-title" *ngIf="!showOnlyMainRemark">הערה ראשית</div>
									<textarea
										class="order-step__block-row-textatea remark-input"
										formControlName="HearaForOrder"
										appTap
										(tappedTwice)="populateRemarkWithText('HearaForOrder')"
										placeholder="{{ this.HearaNewOrder }}"
										[maxlength]="maxRemarkLength"
									></textarea>
								</div>
							</form>

							<ng-container *ngIf="!showOnlyMainRemark">
								<div *ngIf="this._ss.CustomerInformation.CustChangeCss5 === '1'">
									<div class="order-step__block-row">
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda !== null">
											{{ this._ss.HearotHeadForTeuda[0]?.NewHeadLine }}
										</div>
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda === null">הערה 1</div>
										<form [formGroup]="form" (change)="SelectHearaTeuda1()">
											<select formControlName="SelectHeara1" class="remark-input">
												<option *ngFor="let sh of SelectHearotRes1" value="{{ sh.decc }}">{{ sh.decc }}</option>
											</select>
										</form>
									</div>
								</div>
								<form [formGroup]="form" (keyup)="HearaForOrder(1)" *ngIf="this._ss.CustomerInformation.CustChangeCss5 !== '1'">
									<div class="order-step__block-row">
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda !== null">
											{{ this._ss.HearotHeadForTeuda[0]?.NewHeadLine }}
										</div>
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda === null">הערה 1</div>
										<input
											class="order-step__block-row-input remark-input"
											type="text"
											formControlName="HearaForOrder1"
											appTap
											(tappedTwice)="populateRemarkWithText('HearaForOrder1')"
											placeholder="{{ this._ss.HearaNewOrder1 }}"
											maxlength="99"
										/>
									</div>
								</form>
								<form [formGroup]="form" *ngIf="this._ss.CustomerInformation.CustChangeCss5 === '1' && SelectHearotRes2.length">
									<div class="order-step__block-row">
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda !== null">
											{{ this._ss.HearotHeadForTeuda[1]?.NewHeadLine }}
										</div>
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda === null">הערה 2</div>
										<form [formGroup]="form" (change)="SelectHearaTeuda2()">
											<select formControlName="SelectHeara2" class="remark-input">
												<option *ngFor="let sh of SelectHearotRes2" value="{{ sh.decc }}">{{ sh.decc }}</option>
											</select>
										</form>
									</div>
								</form>
								<form [formGroup]="form" (keyup)="HearaForOrder(2)" *ngIf="this._ss.CustomerInformation.CustChangeCss5 !== '1' || !SelectHearotRes2.length">
									<div class="order-step__block-row">
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda !== null">
											{{ this._ss.HearotHeadForTeuda[1]?.NewHeadLine }}
										</div>
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda === null">הערה 2</div>
										<input
											class="order-step__block-row-input remark-input"
											appTap
											(tappedTwice)="populateRemarkWithText('HearaForOrder2')"
											type="text"
											formControlName="HearaForOrder2"
											placeholder="{{ this._ss.HearaNewOrder2 }}"
											maxlength="99"
										/>
									</div>
								</form>
								<form [formGroup]="form" (keyup)="HearaForOrder(3)">
									<div class="order-step__block-row">
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda !== null">
											{{ this._ss.HearotHeadForTeuda[2]?.NewHeadLine }}
										</div>
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda === null">הערה 3</div>
										<input
											class="order-step__block-row-input remark-input"
											appTap
											(tappedTwice)="populateRemarkWithText('HearaForOrder3')"
											type="text"
											formControlName="HearaForOrder3"
											placeholder="{{ this._ss.HearaNewOrder3 }}"
											maxlength="99"
										/>
									</div>
								</form>
								<form [formGroup]="form" (keyup)="HearaForOrder(4)">
									<div class="order-step__block-row">
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda !== null">
											{{ this._ss.HearotHeadForTeuda[3]?.NewHeadLine }}
										</div>
										<div class="order-step__block-row-title" *ngIf="this._ss.HearotHeadForTeuda === null">הערה 4</div>
										<input
											class="order-step__block-row-input remark-input"
											appTap
											(tappedTwice)="populateRemarkWithText('HearaForOrder4')"
											type="text"
											formControlName="HearaForOrder4"
											placeholder="{{ this._ss.HearaNewOrder4 }}"
											maxlength="99"
										/>
									</div>
								</form>
								<form [formGroup]="form" (keyup)="HearaForOrder(5)" *ngIf="_ss.CustomerInformation.CopyRemark === '1'">
									<div class="order-step__block-row">
										<div class="order-step__block-row-title">
											<!-- TODO reuse this logic instead of if === null ... -->
											{{_ss.HearotHeadForTeuda?.[4]?.NewHeadLine || 'הערה 5' }}
										</div>
										<input
											class="order-step__block-row-input remark-input"
											appTap
											(tappedTwice)="populateRemarkWithText('HearaForOrder5')"
											type="text"
											formControlName="HearaForOrder5"
											placeholder="{{ this._ss.HearaNewOrder5 }}"
											maxlength="99"
										/>
									</div>
								</form>
								<button class="order-step__block-button" (click)="showHideThreeStepFunctionNext()">המשך</button>
							</ng-container>
						</div>
					</div>
				</ng-container>
				<div class="order-step__row row" *ngIf="showSignatureStep">
					<div class="order-step__item" (click)="showHideFourStepFunction()">
						<div class="order-step__item-number" *ngIf="!isReclamationOrder && !isCatalogAgent">4</div>
						<div class="order-step__item-title">{{ isReclamationOrder || isCatalogAgent ? 'פרטים' : 'חתימה' }}</div>
						<div class="order-step__item-edit"></div>
					</div>
					<div class="order-step__block" *ngIf="showHideFourStep === true">
						<ng-container *ngIf="!isReclamationOrder && !isCatalogAgent; else notRegularOrderBlock">
							<div class="order-step__block-row">
								<div class="order-step__block-row-title">חתימה</div>
								<textarea class="order-step__block-row-textatea order-step__block-row-textatea_delete" class="remark-input"></textarea>
								<div class="order-step__block-row-delete"></div>
							</div>
							<div class="order-step__block-row order-step__block-row_border-none">
								<div class="order-step__block-row-title">מאשר</div>
								<input class="order-step__block-row-input remark-input" type="text" />
							</div>
						</ng-container>

						<ng-template #notRegularOrderBlock>
							<ng-container *ngIf="isReclamationOrder">
								<form [formGroup]="reclamationForm" dir="rtl" autocomplete="off" class="reclamation-form">
									<mat-form-field appearance="fill">
										<mat-label>BODY</mat-label>
										<select matNativeControl formControlName="BODY">
											<option *ngFor="let reason of reclamationLists.BODY" [value]="reason.ResonCode">
												{{ reason.Reason }}
											</option>
										</select>
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>LOCATION</mat-label>
										<select matNativeControl formControlName="LOCATION">
											<option *ngFor="let reason of LOCATION$ | async" [value]="reason.ResonCode">
												{{ reason.Reason }}
											</option>
										</select>
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TAKALA</mat-label>
										<select matNativeControl formControlName="TAKALA">
											<option *ngFor="let reason of reclamationLists.TAKALA" [value]="reason.ResonCode">
												{{ reason.Reason }}
											</option>
										</select>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>ANS</mat-label>
										<select matNativeControl formControlName="ANS">
											<option *ngFor="let reason of reclamationLists.ANS" [value]="reason.ResonCode">
												{{ reason.Reason }}
											</option>
										</select>
										<mat-error *ngIf="ANS.invalid">שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>CarNum</mat-label>
										<input type="number" #carNum matInput formControlName="CarNum" placeholder="CarNum" />
										<mat-error *ngIf="reclamationForm.controls.CarNum.invalid">שדה חסר או אינו תקין</mat-error>
										<!-- <mat-hint align="end">{{carNum.value?.length || 0}}/20</mat-hint> -->
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>RDR</mat-label>
										<input type="text" matInput formControlName="RDR" placeholder="RDR" />
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>RDM</mat-label>
										<input type="text" matInput formControlName="RDM" placeholder="RDM" />
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>RML</mat-label>
										<input type="text" matInput formControlName="RDL" placeholder="RML" />
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>DOT</mat-label>
										<input type="text" matInput formControlName="DOT" placeholder="DOT" />
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>SN</mat-label>
										<input type="text" matInput formControlName="SN" placeholder="SN" />
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>הערה</mat-label>
										<input type="text" matInput formControlName="RekRemark" maxlength="100" placeholder="הערה" />
										<mat-error>שדה חסר או אינו תקין</mat-error>
									</mat-form-field>
								</form>
								<div class="order-step__block-row">
									<div class="order-step__block-row-title">תמונות רקלמציה</div>
									<input class="order-step__block-row-input" [ngClass]="{ success: images.image1 }" type="file" accept="image/*" capture="camera" (change)="setImage($event, 1)" />
									<input class="order-step__block-row-input" [ngClass]="{ success: images.image2 }" type="file" accept="image/*" capture="camera" (change)="setImage($event, 2)" />
									<input class="order-step__block-row-input" [ngClass]="{ success: images.image3 }" type="file" accept="image/*" capture="camera" (change)="setImage($event, 3)" />
									<input class="order-step__block-row-input" [ngClass]="{ success: images.image4 }" type="file" accept="image/*" capture="camera" (change)="setImage($event, 4)" />
								</div>
							</ng-container>

							<ng-container *ngIf="isCatalogAgent">
								<form [formGroup]="catalogForm" dir="rtl" autocomplete="off" class="reclamation-form">
									<mat-form-field appearance="fill" *ngFor="let r of catalogRemarks; index as i">
										<mat-label>{{ r.text }}</mat-label>
										<input
											type="text"
											[maxlength]="r.maxLength"
											matInput
											[attr.inputmode]="r.type === 'number' ? 'tel' : 'text'"
											[pattern]="r.type === 'number' ? '\\d{9}|\\d{10}' : null"
											[formControlName]="'teudaRemark' + (+i + 1)"
											[placeholder]="r.text"
										/>
										<mat-error *ngIf="catalogForm.controls['teudaRemark' + (+i + 1)].hasError('required')">שדה חובה</mat-error>
										<mat-error *ngIf="catalogForm.controls['teudaRemark' + (+i + 1)].hasError('pattern')">ערך לא תקין</mat-error>
										<mat-error *ngIf="catalogForm.controls['teudaRemark' + (+i + 1)].hasError('maxlength')">ערך גדול מהמותר</mat-error>
									</mat-form-field>
								</form>
							</ng-container>
						</ng-template>
						<div class="full-width-and-center">
							<button class="order-cart-gray__block-button order-cart-gray__block-button_gray" (click)="SaveTeudaFunction()" *ngIf="!Mode && !isReclamationOrder">שמור</button>
						</div>
						<button
							[ngClass]="{ 'order-step__block-button_green': !isReclamationOrder || ((images.image1 || images.image2 || images.image3 || images.image4) && reclamationForm.valid) }"
							class="order-step__block-button order-step__block-button_green"
							*ngIf="Mode"
							(click)="SendTeudaFunction()"
						>
							{{ sendOrderText }}
						</button>
					</div>
				</div>
			</div>
			<ng-template #leasingBlock>
				<app-branches-lookup [searchString]="searchString" (mikumCode)="mikumSelected($event)"></app-branches-lookup>
			</ng-template>
		</section>
		<!-- end order step section -->

		<!-- order process -->
		<section class="order-process" *ngIf="orderProcess === true">
			<app-error-message-with-truck-gif (closeEvent)="ClientsPage()" *ngIf="_receipts.sendingErroredWithTimeoutAndCredit; else normalOrderBlock"></app-error-message-with-truck-gif>
			<ng-template #normalOrderBlock>
				<div class="container">
					<div class="order-process__row order-process__row_save" *ngIf="orderSave">
						<div class="row">
							<h2 class="order-process__title">{{ this._ss.DocTypeName }} נשמרה</h2>
						</div>
						<div class="row">
							<div class="order-process__descrip">לשליחת {{ this._ss.DocTypeName }} יש לחזור {{ this._ss.DocTypeName }} מתוך מסך הלקוחות או מסך ההזמנות.</div>
						</div>
						<div class="row">
							<div class="order-process__image image">
								<img src="/assets/images/basket/process/save.gif" alt="photo" />
							</div>
						</div>
						<div class="row">
							<button class="order-process__button" (click)="showHideProcess()">המשך ללקוח הבא</button>
						</div>
					</div>
					<div class="order-process__row order-process__row_success" *ngIf="orderError !== true">
						<ng-container *ngIf="OrderGood === true">
							<div class="row">
								<h2 class="order-process__title">!בוצע בהצלחה</h2>
							</div>
							<div class="row">
								<div class="order-process__descrip">{{ this._ss.DocTypeName }} התקבלה בהצלחה</div>
							</div>
							<div class="row">
								<div class="order-process__descrip">{{ isReclamationOrder ? reclamationID : this.NewCart }} מספר אישור</div>
							</div>

							<ng-container *ngIf="isCreditCardOrder">
								<div class="row">
									<div class="order-process__descrip" *ngIf="authManpikNo">{{ authManpikNo }} מספר אישור מנפיק</div>
								</div>
								<div class="row">
									<div class="order-process__descrip" *ngIf="shovarNum">{{ shovarNum }} מספר שובר</div>
								</div>
							</ng-container>
						</ng-container>
						<div class="row">
							<div class="order-process__descrip" *ngIf="OrderGood !== true">{{ this.OrderInProgressLine }}</div>
						</div>
						<div class="row">
							<div class="order-process__descrip" *ngIf="OrderGood !== true">{{ this.timeLeft }}</div>
						</div>
						<div class="row" *ngIf="DrivingTruckPop === true && Vorder === false">
							<div class="order-process__image image">
								<img src="/assets/images/basket/process/success.gif" alt="photo" />
							</div>
						</div>
						<div class="row" *ngIf="Vorder === true">
							<div class="order-process__image image">
								<img src="/assets/images/basket/process/save.gif" alt="photo" />
							</div>
						</div>
						<div class="row">
							<button class="order-process__button" (click)="ClientsPage()" *ngIf="OrderGood === true && !isB2BAgent && !isCatalogAgent">המשך ללקוח הבא</button>
							<button class="order-process__button" (click)="ClientsPage()" *ngIf="OrderGood === true && (isB2BAgent || isCatalogAgent)">חזרה</button>
						</div>
						<div class="row">
							<button class="order-process__button" (click)="MoveToDailyReport()" *ngIf="OrderGood === true && !isB2BAgent && !isCatalogAgent && !isRallyanceReclamation">צפה בתעודה</button>
						</div>
						<!-- <div class="row">
						  <div class="order-process__text">הצג את {{this._ss.DocTypeName}}</div>
						</div> -->
					</div>
					<div class="order-process__row order-process__row_error" *ngIf="orderError === true">
						<div class="row">
							<h2 class="order-process__title">...אופס</h2>
						</div>
						<div class="row">
							<div class="order-process__descrip">{{ requestErrorAndAllOrderLength }}</div>
						</div>
						<div class="row">
							<div class="order-process__image image">
								<img src="/assets/images/basket/process/error.gif" alt="photo" />
							</div>
						</div>
						<div class="row">
							<button class="order-process__button" (click)="ClientsPageError()">{{ isCatalogAgent ? 'חזרה' : 'המשך ללקוח הבא' }}</button>
						</div>
					</div>
				</div>
			</ng-template>
		</section>
		<!-- end order process -->

		<!-- markup for popup -->
		<div class="popup" *ngIf="CancelOrder === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="NoCancelOrder()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">למחוק את {{ this._ss.DocTypeName }}?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoCancelOrder()">לא, חזור</button>
					<button class="order-cart__popup-button" (click)="YesCancelOrder()" style="background-color: #38d88b">כן, מחק</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->

		<!-- markup for popup -->
		<div class="popup" *ngIf="SendOrder === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="NoSendTeuda()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">לשלוח את {{ this._ss.DocTypeName }}?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoSendTeuda()">לא, חזור</button>
					<button class="order-cart__popup-button" (click)="SendTeuda()" style="background-color: #38d88b">כן, שלח</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->

		<!-- markup for popup -->
		<div class="popup" *ngIf="SaveTeudaPop === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="NoSaveTeuda()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">לשמור {{ this._ss.DocTypeName }}?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoSaveTeuda()">לא, חזור</button>
					<button class="order-cart__popup-button" (click)="SaveTeuda()" style="background-color: #38d88b">כן, שמור וחזור</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->

		<!-- markup for popup -->
		<div class="popup" *ngIf="DeleteProductPop === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="NoDeleteProduct()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">למחוק את {{ this.ChoosenDeleteProduct }} ?</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoDeleteProduct()">לא, חזור</button>
					<button class="order-cart__popup-button" (click)="DeleteProduct()">כן, מחק מוצר</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->

		<!-- markup for rotate device popup -->
		<div class="popup" *ngIf="showRotatePopup">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="showHideRotatePopup(false)"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/rotate-phone.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title order-cart__popup-title_padding">האפליקציה עובדת לרוחב, נא לסובב את המכשיר שברשותך</h2>
				<div class="order-cart__popup-block order-cart__popup-block_center row">
					<button class="order-cart__popup-button order-cart__popup-button_green" (click)="showHideRotatePopup(false)">כן, שלח</button>
				</div>
			</div>
		</div>
		<!-- end markup for rotate device popup -->
		<!-- markup for popup -->
		<div class="popup" *ngIf="StockAlert === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="CloseStockAlert()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">חסר במלאי!</h2>

				<br />
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button" (click)="CloseStockAlert()">קיבלתי</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->
	</div>
</div>

<div class="popup" *ngIf="isEmailPickPopupOpen" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed wrapper">
		<div class="product-detail__item">
			<div class="popup__close" (click)="isEmailPickPopupOpen = false" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>

			<div>
				<h2 class="product-detail-add__popup-title">בחר כתובת דוא"ל לשליחה</h2>

				<div style="padding: 1rem">
					<form [formGroup]="form" (ngSubmit)="isEmailPickPopupOpen = false">
						<mat-radio-group color="primary" [ngClass]="{ 'radio-group': windowWidth > 768 }" dir="rtl" formControlName="emailRadio">
							<mat-radio-button value="client" [disabled]="!clientHasEmail">{{ clientHasEmail || 'לא נמצאה כתובת לקוח' }}</mat-radio-button>
							<mat-radio-button value="user" *ngIf="_user.agentEmail$ | async as email">אימייל סוכן - {{ email }}</mat-radio-button>
							<mat-radio-button value="custom">
								<mat-form-field>
									<mat-label>כתובת דוא"ל</mat-label>
									<input matInput inputmode="email" #input (input)="form.controls['Email'].setValue(input.value)" formControlName="Email" dir="rtl" placeholder='כתובת דוא"ל' />
								</mat-form-field>
							</mat-radio-button>
							<mat-radio-button value="none">ללא שליחה</mat-radio-button>
						</mat-radio-group>
						<div style="text-align: center">
							<button mat-raised-button color="primary" [disabled]="form.controls['Email'].invalid && !form.controls['emailRadio'].value">אשר</button>
						</div>
					</form>
				</div>

				<!-- <h3 style="text-align: center">{{ this._ss.OneClientInfo.Client_Name }}</h3>
				<h4 style="text-align: center">מספר אישור: {{ _receipt.lastDocnumSent }}</h4> -->

				<div class="product-detail-add__popup-row"></div>
			</div>
		</div>
	</div>
</div>

<div class="popup" *ngIf="isCreditCardPopupOpen" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed wrapper">
		<div class="product-detail__item">
			<div class="popup__close" (click)="toggleCreditCardPopup('close')" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>

			<div>
				<app-credit-card-payment (submitted)="handleCreditCardSubmit($event)" (resetted)="handleCreditCardFormReset()" [customText]="'אשר ושלח'"></app-credit-card-payment>
			</div>
		</div>
	</div>
</div>
