import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, Observable, of, startWith, Subject, takeUntil } from 'rxjs';
import { AllCards, BackendCreditTerms, CardProperties, CreditParameters, PaymentLevel } from 'src/app/Interfaces/credit-payment';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { UtilService } from 'src/app/services/util.service';
import { ccExpiryMonths, ccExpiryYears } from 'src/app/Utility/constants';
import { cardsExpectedProperties, validateCreditCardNumber, validateCreditExpiryMonth, validateIsraeliIDControlValidator, validateThatIsNumber } from 'src/app/Utility/FormValidators';

const payTypeToBackendValue = {
	credit: BackendCreditTerms.CREDIT,
	payments: BackendCreditTerms.PAYMENTS,
} as const;

export type CreditFormValues = Partial<{
	cardType: typeof AllCards[number];
	number: string;
	expiry: {
		month: string;
		year: string;
	};
	amount: number;
	CVV: string;
	creditCardID: string;
	payType: keyof typeof payTypeToBackendValue;
	payments: number;
}>;

@Component({
	selector: 'app-credit-card-payment',
	templateUrl: './credit-card-payment.component.html',
	styleUrls: ['./credit-card-payment.component.scss'],
})
export class CreditCardPaymentComponent implements OnInit, OnDestroy {
	constructor(public _CC: CreditCardService, private _fb: FormBuilder, private _util: UtilService) {}

	@Output() submitted = new EventEmitter();
	@Output() resetted = new EventEmitter();
	@Input() isEdittingExistingCard: boolean;
	@Input() needsToSpecifyAmount: boolean;
	@Input() maxAmount?: number;
	@Input() customText?: string;

	readonly years = [...ccExpiryYears];
	readonly months = [...ccExpiryMonths];

	formValues$ = this._CC.creditCardFormValues$;

	maskedCardNumber = '';

	submitText = 'אשר פרטי אשראי';

	form = this._fb.nonNullable.group(
		{
			cardType: new FormControl<typeof AllCards[number] | null>(this.formValues$.value.cardType || 'mastercard', { validators: [Validators.required], nonNullable: true }),
			number: new FormControl('', { validators: [Validators.required], nonNullable: true }),
			expiry: new FormGroup(
				{
					month: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
					year: new FormControl('', { validators: [Validators.required], nonNullable: true }),
				},
				{ validators: [validateCreditExpiryMonth] },
			),
			amount: new FormControl(this.formValues$.value.amount || '', { nonNullable: true }),
			CVV: new FormControl('', { nonNullable: true }),
			creditCardID: new FormControl(this.formValues$.value.creditCardID || '', { nonNullable: true }),
			payType: new FormControl<keyof typeof payTypeToBackendValue>(this.formValues$.value.payType || 'credit', { nonNullable: true }),
			payments: new FormControl(this.formValues$.value.payments || 1, { nonNullable: true }),
		},
		{ validators: [validateCreditCardNumber] },
	);

	months$ = combineLatest([this.form.controls.expiry.controls.year.valueChanges.pipe(startWith(new Date().getFullYear().toString().slice(-2))), of(this.months)]).pipe(
		map(([year, months]) => {
			if (year === new Date().getFullYear().toString().slice(-2)) {
				return months.filter(month => +month >= new Date().getMonth() + 1);
			}
			return months;
		}),
	);

	needsToProvideCVVAndIsraeliID = false;
	hasPayments = false;
	canProduceReceiptWithoutFullPayment = false;

	cards: { type: typeof AllCards[number]; hebrewName: string }[] = [];
	cardLevels: Record<typeof AllCards[number], PaymentLevel[]> = {} as Record<typeof AllCards[number], PaymentLevel[]>;
	availablePayments$ = this.form.controls.cardType.valueChanges.pipe(
		startWith(this.form.controls.cardType.value),
		map(cardType => {
			return this.cardLevels[cardType].filter(({ minCredit }) => minCredit <= this.form.controls.amount.value);
		}),
	);

	destroy$ = new Subject<void>();

	ngOnInit() {
		const parameters = this._CC.parameters$.value;
		if (!this._CC.hasParameters(parameters)) {
			alert('יש צורך לעדכן נתונים לצורך תשלום באשראי');
			this.handleCancel();
			return;
		}

		if (this.customText) {
			this.submitText = this.customText;
		}

		this.needsToProvideCVVAndIsraeliID = parameters.needsToProvideCVVAndIsraeliID;
		this.hasPayments = parameters.hasPayments;
		this.canProduceReceiptWithoutFullPayment = parameters.canProduceReceiptWithoutFullPayment;
		this.cards = Object.entries(parameters.cardTypes)
			.filter(([, c]) => c.allowed)
			.map(([card, { hebrewName }]) => ({ type: card as typeof AllCards[number], hebrewName }));
		this.cardLevels = this.cards
			.map(c => c.type)
			.reduce((acc, card) => {
				acc[card] = (parameters.cardTypes[card] as CardProperties & { allowed: true }).customPaymentLevels ?? parameters.defaultPaymentLevels;
				return acc;
			}, {} as Record<typeof AllCards[number], PaymentLevel[]>);
		if (!this.cardLevels['mastercard']) {
			this.form.controls.cardType.setValue(this.formValues$.value.cardType || this.cards[0].type);
		}

		this.handleFormInteractivityAndValidation();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleCancel() {
		this.submitted.emit({ status: 'cancel' });
	}

	handleSubmit() {
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}
		this.submitted.emit({ status: 'ok', value: this.form.value });
	}

	getErrorMessage(controlName: keyof typeof this.form.value) {
		const control = this.form.controls[controlName];
		if (!control.errors) return '';
		switch (controlName) {
			case 'amount':
				if (control.errors['required']) return 'שדה חובה';
				if (control.errors['pattern']) return 'ערך לא תקין';
				if (control.errors['max']) return 'לא ניתן להזין סכום גבוה מסך כל החוב';

				break;

			case 'number':
				if (control.errors['required']) return 'שדה חובה';
				if (control.errors['minlength']) return 'מספר כרטיס אשראי לא תקין';
				if (control.errors['cardNumber']) return 'כרטיס אשראי לא תקין';
				break;

			default:
				if (control.errors) return 'שדה חסר או לא תקין';
				break;
		}
		return '';
	}

	handleFormInteractivityAndValidation() {
		if (this.isEdittingExistingCard) {
			const cardNumber = this.formValues$.value.number;
			this.maskedCardNumber = '*'.repeat(cardNumber.length - 4) + cardNumber.slice(-4);
			this.form.disable();
		}

		if (this.needsToSpecifyAmount) {
			const validators = [Validators.required, Validators.pattern(/^[1-9]\d*\.?\d{0,3}$/), Validators.max(this.maxAmount ?? Infinity), validateThatIsNumber];
			if (this.canProduceReceiptWithoutFullPayment) {
				validators.splice(2, 1);
			}
			this.form.get('amount')?.setValidators(validators);
		}

		if (this.needsToProvideCVVAndIsraeliID) {
			this.form.controls.CVV.setValidators([Validators.required, Validators.minLength(3), validateThatIsNumber]);
			this.form.controls.creditCardID.setValidators([Validators.required, validateIsraeliIDControlValidator, validateThatIsNumber]);
		}

		this.pipeADestroyer(this.form.valueChanges).subscribe(() => {
			if (this.form.hasError('cardNumber')) {
				this.form.controls.number.setErrors({ cardNumber: true });
			} else {
				this.form.controls.number.setErrors(null);
			}
		});

		this.pipeADestroyer(this.form.controls.cardType.valueChanges).subscribe(cardType => {
			const { length } = cardsExpectedProperties[cardType];
			this.form.controls.number.setValidators([Validators.required, Validators.minLength(length)]);

			if (cardType === 'isracardDirect') {
				this.form.controls.payments.setValue(1);
			}
		});

		this.pipeADestroyer(combineLatest([this.form.controls.payType.valueChanges, this.availablePayments$])).subscribe(([payType, availablePayments]) => {
			if (payType === 'credit') {
				this.form.controls.payments.setValue(1);
				this.form.controls.payments.clearValidators();
				return;
			}
			if (payType === 'payments' && availablePayments.length) {
				this.form.controls.payments.setValue(availablePayments[0].payments ?? 2);
				this.form.controls.payments.setValidators([Validators.required]);
			}
		});

		this.pipeADestroyer(this.form.controls.amount.valueChanges).subscribe(amount => {
			if (!this.hasPayments || this.form.controls.payType.value !== 'payments') return;

			const cardType = this.form.controls.cardType.value;

			// change payments to the max possible for the current card and input amount
			const paymentLevels = this.cardLevels[cardType];
			const maxPayments = [...paymentLevels].reverse().find(({ minCredit }) => minCredit < amount)?.payments ?? 1;
			if (this.form.controls.payments.value > maxPayments) {
				this.form.controls.payments.setValue(maxPayments);
			}
		});

		this.pipeADestroyer(combineLatest([this.months$, this.form.get('expiry')?.get('month')?.valueChanges])).subscribe(([months, month]) => {
			if (!months.includes(month)) {
				this.form.get('expiry')?.get('month')?.setValue(months[0]);
			}
		});
	}

	resetForm() {
		this.resetted.emit();
		this.form.enable();
		this.form.reset();
		// this.isEdittingExistingCard = false;
	}
	fillAmount() {
		this.form.controls.amount.setValue(this.maxAmount ?? 0);
	}

	trimWhitespaces(controlName: 'number' | 'CVV' | 'creditCardID') {
		const control = this.form.controls[controlName];
		if (control.value) {
			control.setValue(control.value.trim());
		}
	}

	pipeADestroyer<T>(observableToPipe: Observable<T>) {
		return this._util.pipeASubjectDestoryer(observableToPipe, this.destroy$);
	}
}
