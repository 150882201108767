import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ClientcardComponent } from './components/clientcard/clientcard.component';
import { ClientsComponent } from './components/clients/clients.component';
import { ClienthovotComponent } from './components/clienthovot/clienthovot.component';
import { ClientteudotComponent } from './components/clientteudot/clientteudot.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductInfoDialogComponent } from './components/products/product-info-dialog.component';
import { TeudaComponent } from './components/teuda/teuda.component';
import { ClientteudoteachComponent } from './components/clientteudoteach/clientteudoteach.component';
import { ClienthovoteachComponent } from './components/clienthovoteach/clienthovoteach.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DohotComponent } from './components/dohot/dohot.component';
import { MobilitydashbordComponent } from './components/mobilitydashbord/mobilitydashbord.component';
import { DailyreportComponent } from './components/dailyreport/dailyreport.component';
import { DailyReportEachComponent } from './components/daily-report-each/daily-report-each.component';
import { DohotteudotComponent } from './components/dohotteudot/dohotteudot.component';
import { DohotteudoteachComponent } from './components/dohotteudoteach/dohotteudoteach.component';
import { DohotmotzarimComponent } from './components/dohotmotzarim/dohotmotzarim.component';
import { DohotmotzarimmlayComponent } from './components/dohotmotzarimmlay/dohotmotzarimmlay.component';
import { DohotlekohotComponent } from './components/dohotlekohot/dohotlekohot.component';
import { DohotlekohothovotComponent } from './components/dohotlekohothovot/dohotlekohothovot.component';
import { GraphComponent } from './components/graph/graph.component';
import { DohotbizoimComponent } from './components/dohotbizoim/dohotbizoim.component';
import { DohotBizoimAgentComponent } from './components/dohot-bizoim-agent/dohot-bizoim-agent.component';
import { DohotbizoimlekohotComponent } from './components/dohotbizoimlekohot/dohotbizoimlekohot.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminbizoateudotComponent } from './components/adminbizoateudot/adminbizoateudot.component';
import { AdminBizoaHevraComponent } from './components/admin-bizoa-hevra/admin-bizoa-hevra.component';
import { DohbakaraComponent } from './components/dohbakara/dohbakara.component';
import { DohmechiraComponent } from './components/dohmechira/dohmechira.component';
import { DohotbizoimgviaComponent } from './components/dohotbizoimgvia/dohotbizoimgvia.component';
import { DohotbizoimazmanotComponent } from './components/dohotbizoimazmanot/dohotbizoimazmanot.component';
import { AdminbizoaazmanotComponent } from './components/adminbizoaazmanot/adminbizoaazmanot.component';
import { AdmindohotproductsComponent } from './components/admindohotproducts/admindohotproducts.component';
// import { AdmincakeComponent } from './components/admincake/admincake.component';
import { ClientactivComponent } from './components/clientactiv/clientactiv.component';
import { AdminactivComponent } from './components/adminactiv/adminactiv.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DohotlekohotbydateComponent } from './components/dohotlekohotbydate/dohotlekohotbydate.component';
import { BizoimlekohotyearComponent } from './components/bizoimlekohotyear/bizoimlekohotyear.component';
import { MismachimComponent } from './components/mismachim/mismachim.component';
import { MismachimeachComponent } from './components/mismachimeach/mismachimeach.component';
import { DohotdinamicagentComponent } from './components/dohotdinamicagent/dohotdinamicagent.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { Clientactiv2Component } from './components/clientactiv2/clientactiv2.component';
import { DohotmotzarimreverseComponent } from './components/dohotmotzarimreverse/dohotmotzarimreverse.component';
import { Clientactiv3Component } from './components/clientactiv3/clientactiv3.component';
import { UnformatPhoneNumberPipe } from './Pipes/unformat-phone-number.pipe';
import { FallbacksrcDirective } from './Directives/img-fallback.directive';
import { AdminGoalsComponent } from './components/admin-goals/admin-goals.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import {MatCheckboxModule} from '@angular/material/checkbox';
import { BarcodeReaderComponent } from './components/barcode-reader/barcode-reader.component';
// import PdfJsViewerModule from 'ng2-pdfjs-viewer';

import { DohotbizoimyaadimComponent } from './components/dohotbizoimyaadim/dohotbizoimyaadim.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { GetBorderColorDirective } from './Directives/get-border-color.directive';
import { GetRemarkHeaderPipe } from './Pipes/get-remark-header.pipe';
import { DohotAgentCategoriesPerformanceComponent } from './components/dohot-agent-categories-performance/dohot-agent-categories-performance.component';
import { SetCardStyleDirective } from './Directives/set-card-style.directive';
import { ToMatrixPipe } from './Pipes/to-matrix.pipe';
import { DetermineInputTypePipe } from './Pipes/determine-input-type.pipe';
import { ReturnTimeFormattedPipe } from './Pipes/return-time-formatted.pipe';
import { GetBackgroundDirective } from './Directives/get-background.directive';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { NewReceiptComponent } from './components/new-receipt/new-receipt.component';
import { ShouldStockShowPipe } from './Pipes/should-stock-show.pipe';
import { SetViewPipe } from './Pipes/set-view.pipe';
import { ShekelsPipe } from './Pipes/shekels.pipe';

import { ToNormalNumberPipe } from './Pipes/to-normal-number.pipe';
import { HelpFormComponent } from './components/help-form/help-form.component';
import { GetNewReceiptScreenTitlePipe } from './Pipes/get-new-receipt-screen-title.pipe';
import { DisplayAmountLeftToPayPipe } from './Pipes/display-amount-left-to-pay.pipe';
import { StockFlagComponent } from './components/stock-flag/stock-flag.component';
import { SpecialsComponent } from './components/specials/specials.component';
import { GetBonusNumberPipe } from './Pipes/get-bonus-number.pipe';
import { EncodeURIPipe } from './Pipes/encode-uri.pipe';
import { ShouldCheckboxBeMarkedPipe } from './Pipes/should-checkbox-be-marked.pipe';
import { DashboardReceiptsComponent } from './components/dashboard-receipts/dashboard-receipts.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GetTeudaStatusPipe } from './Pipes/get-teuda-status.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ReceiptTypePipe } from './Pipes/receipt-type.pipe';
import { DohotBizoimAgentDivisionComponent } from './components/dohot-bizoim-agent-division/dohot-bizoim-agent-division.component';
import { AgentSalesProductsQuantitiesDatesComponent } from './components/agent-sales-products-quantities-dates/agent-sales-products-quantities-dates.component';
import { MatNativeDateModule } from '@angular/material/core';
import { BrandsTableComponent } from './components/brands-table/brands-table.component';
import { GroupSalesAllComponent } from './components/group-sales-all/group-sales-all.component';
import { LoadingInterceptor } from './Interceptors/http-interceptor.interceptor';
import { ShowProductPipe } from './Pipes/show-product.pipe';
import { DailyInvoicesComponent } from './components/daily-invoices/daily-invoices.component';
import { SalesVsReturnsComponent } from './components/sales-vs-returns/sales-vs-returns.component';
import { BranchesLookupComponent } from './components/branches-lookup/branches-lookup.component';
import { OrderWithoutSpecialPipe } from './Pipes/order-without-special.pipe';
import { PasswordForBypassComponent } from './components/password-for-bypass/password-for-bypass.component';
import { SpecialInfoDialog } from './components/specials/special-dialog.component';
import { AdminManageCompanyComponent } from './components/admin-manage-company/admin-manage-company.component';
import { AdminReportsCompanyComponent } from './components/admin-reports-company/admin-reports-company.component';
import { FilterArrayPipe } from './Pipes/filter-array.pipe';
import { BaseComponent } from './components/base-component/base.component';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { AlertManagerComponent } from './components/alert-manager/alert-manager.component';
import { AgentGviaComponent } from './components/agent-gvia/agent-gvia.component';
import { DebtParameterPipe } from './Pipes/debt-parameter.pipe';
import { SharedModule } from './modules/shared/shared.module';
import { DohotLekohotHovotByDateComponent } from './components/dohot-lekohot-hovot-by-date/dohot-lekohot-hovot-by-date.component';
import { LoadingComponent } from './components/loading/loading.component';
import { IsAlertActivePipe } from './Pipes/is-alert-active.pipe';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { LikutClientsComponent } from './components/likut-clients/likut-clients.component';
import { TaskTextPipe } from './Pipes/task-text.pipe';
import { LikutTaskComponent } from './components/likut-task/likut-task.component';
import { LikutFinishComponent } from './components/likut-finish/likut-finish.component';
import { LikutReportsComponent } from './components/likut-reports/likut-reports.component';
import { GetImageNameFromPipe } from './Pipes/get-image-name-from.pipe';
import { DynamicReportComponent } from './components/dynamic-report/dynamic-report.component';
import { CookieModule } from 'ngx-cookie';
import { ExcelButtonComponent } from './components/excel-button/excel-button.component';
import { PlannedVsUnplannedComponent } from './components/planned-vs-unplanned/planned-vs-unplanned.component';
import { VisitStatusTextPipe } from './Pipes/visit-status-text.pipe';
import { AreQuantitiesDifferentPipe } from './Pipes/are-quantities-different.pipe';
import { GetTextColorDirective } from './Directives/get-text-color.directive';
import { DatePipe } from './Pipes/date.pipe';
import { TapDirective } from './Directives/tap.directive';
import { SearchPreferenceComponent } from './components/search-preference/search-preference.component';
import { KanbanAssignmentComponent } from './components/kanban-assignment/kanban-assignment.component';
import { KanbanAssignmentPopupComponent } from './components/kanban-assignment/kanban-assignment-popup/kanban-assignment-popup.component';
import { TaskAssignmentExtraDisplayPipe } from './Pipes/task-assignment-extra-display.pipe';
import { MobilitydashbordFilterBoxComponent } from './components/mobilitydashbord/mobilitydashbord-filter-box/mobilitydashbord-filter-box.component';
import { MobilitydashbordTableHeaderComponent } from './components/mobilitydashbord/mobilitydashbord-table-header/mobilitydashbord-table-header.component';
import { MapDistributionComponent } from './components/map-distribution/map-distribution.component';
import { MapDistributionPopupComponent } from './components/map-distribution/map-distribution-popup/map-distribution-popup.component';
import { CanvasPopupComponent } from './components/canvas-popup/canvas-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TaskSendingStatusPipe } from './Pipes/task-sending-status.pipe';
import { AddItemExtraToNamePipe } from './Pipes/add-item-extra-to-name.pipe';
import { WeightTypeTextPipe } from './Pipes/weight-type-text.pipe';
import { WeightTypeDisplayPipe } from './Pipes/weight-type-display.pipe';
import { TaskTextVisibilityPipe } from './Pipes/task-text-visibility.pipe';
import { FitHeightToContentDirective } from './Directives/fit-height-to-content.directive';
import { LikutClientsWarehouseDialogComponent } from './components/likut-clients/likut-clients-warehouse-dialog.component';
import { CreditCardPaymentComponent } from './components/credit-card-payment/credit-card-payment.component';
import { MaskedInputComponent } from './components/masked-input/masked-input.component';
import { DecideBarcodePipe } from './Pipes/decide-barcode.pipe';
import { ErrorMessageWithTruckGifComponent } from './components/error-message-with-truck-gif/error-message-with-truck-gif.component';
import { ReclamationPopupContentComponent } from './components/reclamation-popup-content/reclamation-popup-content.component';
import { PodProductStatusPipe } from './Pipes/pod-product-status.pipe';
import { ReclamationTasksComponent } from './components/reclamation-tasks/reclamation-tasks.component';
import { ReclamationFinishComponent } from './components/reclamation-finish/reclamation-finish.component';
import { YaadGviaReportComponent } from './components/yaad-gvia-report/yaad-gvia-report.component';
import { IsB2BSpecialProductPipe } from './Pipes/is-hatam.pipe';
import { CustomSvgComponent } from './components/svg/custom-svg.component';
import { ReportDashboardItemComponent } from './components/report-dashboard-item/report-dashboard-item.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SetTextColorToFitBackgroundContrastDirective } from './Directives/set-text-color-to-fit-background-contrast.directive';
import { SliderToggleComponent } from './components/slider-toggle/slider-toggle.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HomeComponent,
		ClientcardComponent,
		ClientsComponent,
		ClienthovotComponent,
		ClientteudotComponent,
		ProductsComponent,
		TeudaComponent,
		ClientteudoteachComponent,
		ClienthovoteachComponent,
		DohotComponent,
		MobilitydashbordComponent,
		DailyreportComponent,
		DailyReportEachComponent,
		DohotteudotComponent,
		DohotteudoteachComponent,
		DohotmotzarimComponent,
		DohotmotzarimmlayComponent,
		DohotlekohotComponent,
		DohotlekohothovotComponent,
		DohotbizoimComponent,
		DohotBizoimAgentComponent,
		DohotbizoimlekohotComponent,
		AdminComponent,
		AdminbizoateudotComponent,
		AdminBizoaHevraComponent,
		DohbakaraComponent,
		DohmechiraComponent,
		DohotbizoimgviaComponent,
		DohotbizoimazmanotComponent,
		AdminbizoaazmanotComponent,
		AdmindohotproductsComponent,
		// AdmincakeComponent,
		ClientactivComponent,
		AdminactivComponent,
		DohotlekohotbydateComponent,
		BizoimlekohotyearComponent,
		MismachimComponent,
		MismachimeachComponent,
		DohotdinamicagentComponent,
		PrivacyComponent,
		Clientactiv2Component,
		DohotmotzarimreverseComponent,
		Clientactiv3Component,
		UnformatPhoneNumberPipe,
		FallbacksrcDirective,
		AdminGoalsComponent,
		BarcodeReaderComponent,
		DohotbizoimyaadimComponent,
		AdminUsersComponent,
		GetBorderColorDirective,
		GetRemarkHeaderPipe,
		DohotAgentCategoriesPerformanceComponent,
		SetCardStyleDirective,
		ToMatrixPipe,
		DetermineInputTypePipe,
		ReturnTimeFormattedPipe,
		GetBackgroundDirective,
		MenuButtonComponent,
		NewReceiptComponent,
		ShouldStockShowPipe,
		SetViewPipe,
		ShekelsPipe,
		ToNormalNumberPipe,
		HelpFormComponent,
		GetNewReceiptScreenTitlePipe,
		DisplayAmountLeftToPayPipe,
		StockFlagComponent,
		SpecialsComponent,
		GetBonusNumberPipe,
		EncodeURIPipe,
		ShouldCheckboxBeMarkedPipe,
		DashboardReceiptsComponent,
		GetTeudaStatusPipe,
		ProductInfoDialogComponent,
		ReceiptTypePipe,
		DohotBizoimAgentDivisionComponent,
		AgentSalesProductsQuantitiesDatesComponent,
		BrandsTableComponent,
		GroupSalesAllComponent,
		ShowProductPipe,
		DailyInvoicesComponent,
		SalesVsReturnsComponent,
		BranchesLookupComponent,
		OrderWithoutSpecialPipe,
		SpecialInfoDialog,
		PasswordForBypassComponent,
		AdminManageCompanyComponent,
		AdminReportsCompanyComponent,
		FilterArrayPipe,
		BaseComponent,
		ReportTableComponent,
		AlertManagerComponent,
		AgentGviaComponent,
		DebtParameterPipe,
		DohotLekohotHovotByDateComponent,
		LoadingComponent,
		IsAlertActivePipe,
		AlertDialogComponent,
		LikutClientsComponent,
		TaskTextPipe,
		LikutTaskComponent,
		LikutFinishComponent,
		LikutReportsComponent,
		GetImageNameFromPipe,
		DynamicReportComponent,
		ExcelButtonComponent,
		PlannedVsUnplannedComponent,
		VisitStatusTextPipe,
		AreQuantitiesDifferentPipe,
		GetTextColorDirective,
		DatePipe,
		TapDirective,
		SearchPreferenceComponent,
		KanbanAssignmentComponent,
		KanbanAssignmentPopupComponent,
		TaskAssignmentExtraDisplayPipe,
		MobilitydashbordFilterBoxComponent,
		MobilitydashbordTableHeaderComponent,
		MapDistributionComponent,
		MapDistributionPopupComponent,
		TaskSendingStatusPipe,
		AddItemExtraToNamePipe,
		WeightTypeTextPipe,
		WeightTypeDisplayPipe,
		TaskTextVisibilityPipe,
		FitHeightToContentDirective,
		LikutClientsWarehouseDialogComponent,
		CreditCardPaymentComponent,
		MaskedInputComponent,
		DecideBarcodePipe,
		ErrorMessageWithTruckGifComponent,
		ReclamationPopupContentComponent,
		PodProductStatusPipe,
		ReclamationTasksComponent,
		ReclamationFinishComponent,
		YaadGviaReportComponent,
		IsB2BSpecialProductPipe,
		CustomSvgComponent,
		ReportDashboardItemComponent,
		SetTextColorToFitBackgroundContrastDirective,
		SliderToggleComponent,
	],
	imports: [
		BrowserModule,
		HammerModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		BrowserAnimationsModule,
		ScrollingModule,
		NgxSkeletonLoaderModule,
		// MatCheckboxModule,
		// PdfJsViewerModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		NgMultiSelectDropDownModule,
		MatNativeDateModule,
		SharedModule,
		CookieModule.withOptions(),
		CanvasPopupComponent,
		PdfViewerModule,
		NgxMaterialTimepickerModule.setOpts('he-IL'),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadingInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
