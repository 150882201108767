<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToDohotBizoim()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
					</div>
					<div class="header__title col">{{ title$ | async }}</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
			<div class="report-table__row">
				<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
					<!-- <button class="header__search-icon image" style="position: absolute;top: 50%;left: 5%;">
            <img src="/assets/images/header-search.svg" alt="search">
          </button> -->
					<form [formGroup]="form">
						<input class="header__search-input" type="text" placeholder="חפש" formControlName="Search" id="search-Input" style="text-align: center; direction: rtl" />
					</form>
					<button class="header__clear-icon" (click)="SearchClear()"></button>
				</div>
			</div>
		</header>

		<!--  dashboard-tables markup  -->

		<section class="dashboard-tables" *ngIf="Main === true" style="margin-top: 64px">
			<div class="content">
				<div style="display: flex; justify-content: center" *ngIf="isNotReclamation$ | async">
					<div class="fixed-header">
						<div class="report-table__button" (click)="showHideFilterPopup(true)">סינון</div>
						<ng-container *ngIf="canSeePrices">
							<div class="box">
								<div class="report-table-popup__label-title">סה"כ</div>
								<strong>{{ this.TotalArray | number : '1.0-2' : 'en-US' }}</strong>
							</div>
							<div class="box">
								<div class="report-table-popup__label-title">סה"כ סומנו</div>
								<strong>{{ this.TotalArrayChecked | number : '1.0-2' : 'en-US' }}</strong>
							</div>
						</ng-container>
					</div>
				</div>
				<!-- markup for logout popup -->

				<!-- end markup for logout popup -->

				<div class="dashboard-tables__wrapper">
					<div class="dashboard-tables__row dashboard-tables__row_scroll" style="width: 100%">
						<b>
							<table class="report-table__table" cellspacing="0" style="margin-top: 13vmax" *ngIf="isNotReclamation$ | async; else reclamationsTable">
								<thead class="report-table__table-head">
									<tr>
										<th class="report-table__table-head-name">תעודה</th>
										<th class="report-table__table-head-code">שם לקוח</th>

										<ng-container *ngIf="canSeePrices">
											<th class="report-table__table-head-quantity">סכום</th>
										</ng-container>

										<th class="report-table__table-head-quantity" style="min-width: 100px">תאריך</th>
										<th class="report-table__table-head-quantity">שעה</th>
										<th class="report-table__table-head-quantity">שורות</th>
										<th class="report-table__table-head-quantity">סטטוס</th>
										<th class="report-table__table-head-quantity">חברה</th>
										<th class="report-table__table-head-quantity">סוג תעודה</th>
										<th class="report-table__table-head-quantity">ERP סטטוס</th>
										<th class="report-table__table-head-quantity">סמן</th>
									</tr>
								</thead>
								<tbody class="report-table__table-body">
									<tr *ngFor="let t of AllOrdersBitzoimPerAgent; let i = index">
										<td class="report-table__table-body-name clickable" *ngIf="t.TeudaNewNum.length < 1" style="color: red" (click)="seeTeuda(t.TeudaNum, i)">
											{{ t.TeudaNum.toString().slice(7, 13) }}
										</td>
										<td class="report-table__table-body-name clickable" *ngIf="t.TeudaNewNum.length > 1" style="color: #38d88b" (click)="seeTeuda(t.TeudaNum, i)">
											{{ t.TeudaNewNum }}
										</td>
										<td class="report-table__table-body-name">{{ t.Client_Name }}</td>

										<td class="report-table__table-body-quantity" dir="ltr" *ngIf="canSeePrices">{{ t.TeudaTotalSum | number : '1.0-2' : 'en-US' }}</td>
										<td class="report-table__table-body-quantity">{{ t.TeudaShowDate }}</td>
										<td class="report-table__table-body-quantity">{{ t.TeudaShowTime }}</td>
										<td class="report-table__table-body-quantity">{{ t.TotalLines }}</td>
										<td class="report-table__table-body-quantity">{{ t.Status }}</td>
										<td class="report-table__table-body-quantity">{{ t.Company }}</td>
										<td class="report-table__table-body-quantity">{{ docTypeHash[t.TeudaType] || 'אין נתון' }}</td>
										<td class="report-table__table-body-quantity">{{ t.TuedaErpDocNum }}</td>
										<td class="report-table__table-body-quantity">
											<mat-checkbox color="primary" [checked]="checkedObject[t.TeudaNum]" (change)="OrderChecked(t.TeudaNum, t.TeudaTotalSum)"></mat-checkbox>
											<!-- <input type="checkbox" style="height: 20px; width: 20px" (change)="OrderChecked(t.TeudaNum, t.TeudaTotalSum)" /> -->
										</td>
									</tr>
								</tbody>
							</table>

							<ng-template #reclamationsTable>
								<table class="report-table__table" cellspacing="0" style="margin-top: 13vmax">
									<thead class="report-table__table-head">
										<tr>
											<th class="report-table__table-head-name">תעודה</th>
											<th class="report-table__table-head-code">שם לקוח</th>
											<th class="report-table__table-head-quantity">תאריך</th>
											<th class="report-table__table-head-quantity">שעה</th>
											<th class="report-table__table-head-quantity">סטטוס</th>
										</tr>
									</thead>
									<tbody class="report-table__table-body">
										<tr *ngFor="let t of AllOrdersBitzoimPerAgent">
											<td class="report-table__table-body-name" style="color: #38d88b">{{ t.RekID }}</td>
											<td class="report-table__table-body-name">{{ t.Client_Name || 'אין נתון' }}</td>
											<td class="report-table__table-body-quantity">{{ t.CreateDate | date : 'dd.MM.yyyy' }}</td>
											<td class="report-table__table-body-quantity">{{ t.CreateDate | returnTimeFormatted : true }}</td>
											<td class="report-table__table-body-quantity">{{ t.Status }}</td>
										</tr>
									</tbody>
								</table>
							</ng-template>
						</b>
					</div>
				</div>
			</div>
		</section>

		<div *ngIf="Last === true" style="margin-top: 100px; margin-right: 20px" dir="rtl">
			<div class="dashboard-tables__row dashboard-tables__row_scroll" style="width: 100%">
				<table class="dashboard-tables__table-big table" cellspacing="0" style="height: 800px">
					<thead class="table__head">
						<tr>
							<th class="report-table__table-head-quantity">שם מוצר</th>
							<th class="report-table__table-head-quantity">יחידות</th>
							<th class="report-table__table-head-quantity">{{ _text.packQuantityText }}</th>
							<th class="report-table__table-head-quantity">בונוס</th>
							<ng-container *ngIf="canSeePrices">
								<th class="report-table__table-head-quantity">מחיר</th>
								<th class="report-table__table-head-quantity">מחיר נטו</th>
								<th class="report-table__table-head-quantity">הנחה</th>
								<th class="report-table__table-head-quantity">סכום שורה</th>
							</ng-container>
						</tr>
					</thead>
					<tbody id="second-table" class="table__body" style="height: 800px">
						<tr class="dashboard-tables__table-big-row dashboard-tables__table-big-row_active" *ngFor="let t of TeudotTableEach">
							<td class="report-table__table-head-quantity">
								<b>{{ t.ProductName }}</b>
							</td>
							<td class="report-table__table-head-quantity">
								<b>{{ t.TeudaQuantity }}</b>
							</td>
							<td class="report-table__table-head-quantity">
								<b>{{ t.TeudaPackQuantity }}</b>
							</td>
							<td class="report-table__table-head-quantity">
								<b>{{ t.TeudaBonusQuantity }}</b>
							</td>
							<ng-container *ngIf="canSeePrices">
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaPrice | number : '1.0-2' : 'en-US' }}</b>
								</td>
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaItemNetPrice | number : '1.0-2' : 'en-US' }}</b>
								</td>
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaItemDiscount | number : '1.0-2' : 'en-US' }}</b>
								</td>
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaTotalRow | number : '1.0-2' : 'en-US' }}</b>
								</td>
							</ng-container>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div *ngIf="Each === true" style="margin-top: 100px; margin-right: 20px" dir="rtl">
			<div class="dashboard-tables__row dashboard-tables__row_scroll" style="height: 500px">
				<table class="dashboard-tables__table-big table" cellspacing="0" style="height: 800px">
					<thead class="table__head">
						<tr>
							<th class="report-table__table-head-quantity">שם מוצר</th>
							<th class="report-table__table-head-quantity">יחידות</th>
							<th class="report-table__table-head-quantity">{{ _text.packQuantityText }}</th>
							<th class="report-table__table-head-quantity">בונוס</th>
							<ng-container *ngIf="canSeePrices">
								<th class="report-table__table-head-quantity">מחיר</th>
								<th class="report-table__table-head-quantity">מחיר נטו</th>
								<th class="report-table__table-head-quantity">הנחה</th>
								<th class="report-table__table-head-quantity">סכום שורה</th>
							</ng-container>
						</tr>
					</thead>
					<tbody id="second-table" class="table__body" style="height: 800px">
						<tr class="dashboard-tables__table-big-row dashboard-tables__table-big-row_active" *ngFor="let t of TeudotEach">
							<td class="report-table__table-head-quantity">
								<b>{{ t.ProductName }}</b>
							</td>
							<td class="report-table__table-head-quantity">
								<b>{{ t.TeudaQuantity }}</b>
							</td>
							<td class="report-table__table-head-quantity">
								<b>{{ t.TeudaPackQuantity }}</b>
							</td>
							<td class="report-table__table-head-quantity">
								<b>{{ t.TeudaBonusQuantity }}</b>
							</td>
							<ng-container *ngIf="canSeePrices">
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaPrice | number : '1.0-2' : 'en-US' }}</b>
								</td>
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaItemNetPrice | number : '1.0-2' : 'en-US' }}</b>
								</td>
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaItemDiscount | number : '1.0-2' : 'en-US' }}</b>
								</td>
								<td class="report-table__table-head-quantity">
									<b>{{ t.TeudaTotalRow | number : '1.0-2' : 'en-US' }}</b>
								</td>
							</ng-container>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<!--  end dashboard-tables markup  -->
	</div>

	<div class="popup" *ngIf="showFilterPopup">
		<div class="overlay"></div>
		<div class="report-table-popup report-table-popup_sort popup__content">
			<div class="popup__close" (click)="showHideFilterPopup(false)"></div>
			<div class="report-table-popup__header">
				<div class="report-table-popup__header-search"></div>
			</div>
			<div class="report-table-popup__wrapper">
				<div class="report-table-popup__content">
					<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding"></div>
					<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
						<div class="report-table-popup__box" style="width: 100%">
							<form [formGroup]="form" dir="rtl">
								<div class="report-table-popup__box with-border-bottom">
									<div class="popup-select-title">לפי תקופה</div>
									<mat-form-field color="primary" appearance="fill" style="scale: 0.8; max-width: 75%">
										<mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilterFunction" (click)="picker.open()">
											<input matStartDate readonly="true" formControlName="from" />
											<input matEndDate readonly="true" formControlName="to" (dateChange)="ChooseDate()" />
										</mat-date-range-input>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>
							</form>
							<div class="report-table-popup__box with-border-bottom">
								<div class="popup-select-title">סוג תעודה</div>
								<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: scale(0.8) translateY(10px)">
									<mat-select [formControl]="docTypesControl" multiple (valueChange)="handleChangeDoctype($event)">
										<mat-option *ngFor="let d of docTypes; let i = index" [value]="d">{{ d.DocName }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="report-table-popup__box button-row">
								<button mat-raised-button color="primary" (click)="resetSort()">אפס</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="popup" *ngIf="LoadingSeeTeuda || SeeTeudaLoaded" style="z-index: 555">
		<div class="overlay" (click)="handleClosePopup()"></div>
		<div class="product-detail__title" *ngIf="selectedTeudaToOpenPopup?.Client_Name">
			{{ selectedTeudaToOpenPopup?.Client_Name }}
		</div>
		<div class="product-detail__title remarks" *ngIf="(remarks.length || selectedTeudaToOpenPopup.remark) && !isRemarkPopupOpen">
			<span *ngIf="selectedTeudaToOpenPopup.remark" style="font-weight: 700">הערת תעודה ראשית: {{ selectedTeudaToOpenPopup.remark }}</span
			><button *ngIf="remarks.length" mat-raised-button color="primary" (click)="isRemarkPopupOpen = true">הצג הערות</button>
		</div>
		<div class="product-detail-popup popup__content" *ngIf="!isRemarkPopupOpen; else elseBlock">
			<div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black" *ngIf="!LoadingSeeTeuda"></div>
			<div class="product-detail__wrapper">
				<table *ngIf="SeeTeudaLoaded">
					<tr>
						<th #teudotTableNames>שם פריט</th>
						<th>קוד פריט</th>
						<th>יחידות</th>
						<th>{{ _text.packQuantityText }}</th>
						<th>בונוס</th>
						<ng-container *ngIf="canSeePrices">
							<th>מחיר</th>
							<th>הנחה</th>
							<th>מחיר נטו</th>
							<th>מחיר סופי</th>
						</ng-container>
						<th>הערה</th>
					</tr>
					<tr *ngFor="let pte of TeudotTableEach.length ? TeudotTableEach : TeudotEach">
						<td>{{ pte.ProductName }}</td>
						<td>{{ pte.ProductId }}</td>
						<td>{{ pte.TeudaQuantity }}</td>
						<td>{{ pte.TeudaPackQuantity }}</td>
						<td>{{ pte.TeudaBonusQuantity }}</td>
						<ng-container *ngIf="canSeePrices">
							<td>{{ pte.TeudaPrice }}</td>
							<td>{{ pte.TeudaItemDiscount }}</td>
							<td>{{ pte.TeudaItemNetPrice | number : '1.0-2' }}</td>
							<td>{{ pte.TeudaTotalRow | number : '1.0-2' }}</td>
						</ng-container>
						<td>{{ pte.TeudaItemRemark }}</td>
					</tr>
				</table>
				<div class="product-detail__wrapper__already-sent" *ngIf="LoadingSeeTeuda">
					טוען תעודה, אנא המתינו...
					<mat-spinner diameter="50" color="primary"></mat-spinner>
				</div>
			</div>
		</div>
		<ng-template #elseBlock>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="isRemarkPopupOpen = false"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">הערות</h2>
				<div class="order-cart__popup-block-wrapper">
					<div class="order-cart__popup-block column" *ngFor="let remark of remarks; let i = index">
						<h3 class="remark_title" *ngIf="remark">
							{{ i | getRemarkHeader }}
						</h3>
						<div class="client-details__item-name margin-bottom" *ngIf="remark">
							{{ remark }}
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>

<div class="popup" *ngIf="isRemarkPopupOpen">
	<div class="overlay"></div>
</div>
