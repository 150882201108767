<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="history.back()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">ביקורים - תיכנון מול ביצוע</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>
	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="_visits.plannedVsUnplannedForm">
				<input
					class="header__search-input"
					type="text"
					[placeholder]="(_device.isTabletAndUpResolution$ | async) ? 'חפש סוכן' : 'חפש'"
					formControlName="agentSearch"
					id="search-Input"
					style="text-align: center; direction: rtl"
				/>
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<div class="flex-row" style="margin-top: 100px; flex-wrap: wrap">
			<!-- <div *ngIf="ShowCompanys !==null">

		  <form [formGroup]="form" (change)="ChangeCompany()">
			<select formControlName="Company" style="width: 150px;height: 35px;">
			  <option *ngFor="let c of ShowCompanys" value="{{c.Company}}">{{c.CompanyName}}</option>
			</select>
		  </form>

		</div> -->
			<div class="box">
				<b>סה"כ</b>
				<span>
					{{ totalSum$ | async | toNormalNumber : 0 : true }}
				</span>
			</div>
			<div class="box">
				<b>ממוצע</b>
				<span>
					{{ totalAverage$ | async | toNormalNumber : 0 : true }}
				</span>
			</div>
			<div class="box">
				<button mat-raised-button color="primary" (click)="mustPickDatePopup = true">אפס</button>
			</div>
			<!-- <div class="box"> -->
			<app-excel-button></app-excel-button>
			<!-- </div> -->
		</div>

		<!-- <div class="flex-row">סינון סוכנים</div> -->

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px; display: block">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-quantity">סוכן</th>
						<th class="report-table__table-head-quantity">סטטוס</th>
						<th class="report-table__table-head-quantity" style="width: 80px">סה"כ תנועות</th>
						<th class="report-table__table-head-quantity" style="width: 100px">סכום</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let visit of visitsDisplay$ | async" (click)="handleTableRowClick(visit)">
						<td class="report-table__table-body-quantity">{{ visit.agent + ' - ' + _visits.namesRecord[visit.agent] }}</td>
						<td class="report-table__table-body-quantity">{{ visit.status | visitStatusText }}</td>
						<td class="report-table__table-body-quantity">{{ visit.totalOf }}</td>
						<td class="report-table__table-body-quantity">{{ visit.totalSum | number : '1.0-2' }}</td>
						<!-- <td class="report-table__table-body-quantity" *ngIf="shouldShowCompanies > 1">{{ visit.company }}</td> -->
					</tr>
				</tbody>
			</table>

			<div
				class="report-table__row report-table__row_full-width"
				style="margin-right: 0px"
				*ngIf="(visitsDisplay$ | async).length === 0 && !mustPickDatePopup && (_loading.isLoading$ | async) === false"
			>
				<h2>אין נתונים להציג</h2>
			</div>
		</div>
	</div>
</section>
<!-- end report table markup -->

<div class="popup" *ngIf="mustPickDatePopup" style="z-index: 555">
	<div class="overlay"></div>
	<!-- <div class="product-detail__title" *ngIf="selectedTeudaToOpenPopup?.Client_Name">
		{{ selectedTeudaToOpenPopup?.Client_Name }}
	</div> -->
	<!-- <div class="product-detail__title remarks" *ngIf="remarks.length && !isRemarkPopupOpen"><button mat-raised-button color="primary" (click)="isRemarkPopupOpen=true;">הצג הערות</button></div> -->
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black"></div>
		<div class="product-detail__wrapper" *ngIf="!loadingMainQuery; else elseBlock">
			<h3 style="text-align: center">אנא בחר תקופה להציג</h3>

			<form [formGroup]="_visits.plannedVsUnplannedForm" dir="rtl" (ngSubmit)="handleFormSubmit()" class="flex flex-column gap">
				<mat-form-field color="primary">
					<mat-label>תקופה</mat-label>
					<mat-error *ngIf="_visits.plannedVsUnplannedForm.controls.from.invalid">תאריך אינו תקין</mat-error>
					<mat-date-range-input [rangePicker]="picker" [min]="dates.min" [max]="dates.max" (click)="picker.open()">
						<input matStartDate readonly="true" formControlName="from" />
						<input matEndDate readonly="true" formControlName="to" />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>

				<div class="flex justify-evenly">
					<button mat-raised-button type="button" (click)="changeDateValue('month')">חודש</button>
					<button mat-raised-button type="button" (click)="changeDateValue('year')">שנה</button>
				</div>

				<div class="report-table-popup__box" *ngIf="isAdmin">
					<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border" *ngIf="!loadingAgents; else loadingAgentsBlock">
						<form [formGroup]="_visits.plannedVsUnplannedForm" style="width: 100%">
							<mat-form-field color="primary" appearance="fill" dir="rtl" style="width: 100%">
								<mat-label>סוכנים</mat-label>
								<mat-select formControlName="agents" multiple>
									<mat-option *ngFor="let a of AgentArray" [value]="a.AgentID">{{ a.AgentID + ' - ' + a.UserName }}</mat-option>
								</mat-select>
							</mat-form-field>
						</form>
					</div>
					<ng-template #loadingAgentsBlock>
						<ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
					</ng-template>
				</div>

				<button mat-raised-button color="primary" [disabled]="_visits.plannedVsUnplannedForm.controls.to.invalid || (loadingAgents && isAdmin)">בחר</button>
			</form>
		</div>
		<ng-template #elseBlock>
			<div class="product-detail__wrapper flex flex-column justify-align-center rtl">
				<h2>טוען, אנא המתינו...</h2>
				<mat-spinner diameter="50" color="primary"></mat-spinner>
			</div>
		</ng-template>
	</div>
</div>
