import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[appFitHeightToContent]',
})
export class FitHeightToContentDirective implements AfterViewInit {
	constructor(private element: ElementRef<HTMLTextAreaElement>) {}

	ngAfterViewInit() {
		const el: HTMLTextAreaElement = this.element.nativeElement;

		['keydown', 'input'].forEach(event => {
			el.addEventListener(event, () => {
				el.style.removeProperty('height');
				el.style.height = el.scrollHeight + 2 + 'px';
			});
		});
	}
}
