<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToAdmin()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>

			<div class="header__title col">לקוחות לגבייה</div>

			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row" *ngIf="First">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<input class="header__search-input" (keyup)="SearchClient()" type="text" placeholder=" חפש לקוח" [formControl]="_agentGvia.searchControl" style="text-align: center" />
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table" style="margin-top: 50px">
	<div class="content">
		<!-- <h3 style="text-align: center; font-size: x-large; font-weight: 600" *ngIf="Third">סוכן מס' {{ selectedAgent + (agentName.length ? ', ' + agentName : '') }}</h3> -->
		<h2 style="text-align: center" *ngIf="Second">{{ clientName }}</h2>
		<!-- <h2 style="text-align: center" *ngIf="Sixth">תעודה מספר {{ teudaNum }}</h2> -->

		<div class="report-table__row report-table__row_between flex-column" style="margin-top: 50px">
			<!-- <div *ngIf="HasMultipleCompanys > 1">
				<form [formGroup]="form">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div> -->

			<div class="flex-row" *ngIf="!loading" style="position: relative">
				<div class="box" style="position: absolute; left: 0" *ngIf="windowWidth > 768">
					<button mat-raised-button (click)="downloadCSV()">
						<img src="../../../assets/images/export-excel-48.png" />
						הורד דוח
					</button>
				</div>
				<div class="box">
					<b>סה"כ</b>
					<span>
						{{ displayTotal$ | async | number : '1.0-2' }}
					</span>
				</div>
				<div class="box">
					<b>ממוצע</b>
					<span>
						{{ displayAverage$ | async | number : '1.0-2' }}
					</span>
				</div>
			</div>
			<div class="flex-row changes-to-column" *ngIf="!loading && !Second" style="position: relative">
				<div class="box">
					<mat-form-field color="primary" appearance="fill" dir="rtl" style="text-align: right">
						<mat-label>סנן לפי סוכן</mat-label>
						<!-- <mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px"> -->
						<mat-select [formControl]="_agentGvia.AgentsControl" multiple>
							<mat-option *ngFor="let a of _agentGvia.Agents" [value]="a">{{ a }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="box">
					<mat-form-field color="primary" appearance="fill" dir="rtl" style="text-align: right">
						<mat-label>סנן לפי חוב</mat-label>
						<mat-select [formControl]="_agentGvia.colorsControl" multiple>
							<mat-option *ngFor="let a of colors" [style.color]="a.value" [value]="a.value">{{ a.text }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="box">
					<button mat-raised-button color="primary" (click)="resetAgentsAndColors()">אפס</button>
				</div>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width">
			<!-- <div class="report-table__row report-table__row_full-width" style="margin-right: 0px"> -->
			<h1 *ngIf="loading">טוען...</h1>
			<h1 *ngIf="!loading && ((First && AgentsSalesAll && !AgentsSalesAll.length) || (Second && AgentsSalesAllSecond && !AgentsSalesAllSecond.length))">לא נמצאו תוצאות</h1>

			<table class="report-table__table" cellspacing="0" *ngIf="!loading && First && AgentsSalesAll && AgentsSalesAll.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 250px" (click)="translateForSort('DebtCustCode', 'First')">לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 120px" (click)="translateForSort('CustOpenDebt', 'First')">חוב</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 150px" (click)="translateForSort('OwnerAgentID', 'First')">סוכן משוייך</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let a of AgentsSalesAll$ | async; index as i" class="clickable" [ngStyle]="{ color: a.DebtColor }" (click)="SecondPageAdminAgentsSales(a.DebtCustCode)">
						<td class="report-table__table-body-name">{{ a.DebtCustCode + ' - ' + a.Client_Name }}</td>
						<td class="report-table__table-body-name">{{ a.CustOpenDebt | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name">{{ a.OwnerAgentID + ' - ' + a.OwnerAgentName }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" dir="rtl" cellspacing="0" #secondTable *ngIf="!loading && Second && AgentsSalesAllSecond && AgentsSalesAllSecond.length">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" style="width: 130px" (click)="translateForSort('DebtDocNum', 'Second')">מס' תעודה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 130px" (click)="translateForSort('DocTypeName', 'Second')">סוג תעודה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 100px" (click)="translateForSort('DebtDocCreateDate', 'Second')">יצירה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 100px" (click)="translateForSort('DebtDocToPayDate', 'Second')">פרעון</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 120px" (click)="translateForSort('DebtDocTotal', 'Second')">סכום תעודה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 120px" (click)="translateForSort('DebtDocOpenToPay', 'Second')">לתשלום</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 100px" (click)="translateForSort('DebtRemark', 'Second')">הערה</th>
						<th class="report-table__table-head-name sortable-clickable" style="width: 100px" (click)="translateForSort('PayTermName', 'Second')">תנאי תשלום</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let a of AgentsSalesAllSecond" [ngStyle]="{ color: a.DebtColor }" (click)="ThirdPageAdminAgentsSales(a.OwnerAgentID, a.DebtCustCode, a.DebtDocNum, a.DebtDocType)">
						<td class="report-table__table-body-name">{{ a.DebtDocNum }}</td>
						<td class="report-table__table-body-name">{{ a.DocTypeName }}</td>
						<td class="report-table__table-body-name">{{ a.DebtDocCreateDate | date : 'dd/MM/yy' : 'GMT' }}</td>
						<td class="report-table__table-body-name">{{ a.DebtDocToPayDate | date : 'dd/MM/yy' : 'GMT' }}</td>
						<td class="report-table__table-body-name">{{ a.DebtDocTotal }}</td>
						<td class="report-table__table-body-name">{{ a.DebtDocOpenToPay }}</td>
						<td class="report-table__table-body-name">{{ a.DebtRemark }}</td>
						<td class="report-table__table-body-name">{{ a.PayTermName }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->

<div class="popup" *ngIf="Loading" style="z-index: 333">
	<div class="overlay"></div>
	<div class="product-popup popup__content">
		<div class="order-product__item">
			<div class="order-process__row order-process__row_success">
				<div class="row">
					<h2 class="order-process__title" style="text-align: center">טוען אנא המתן</h2>
				</div>
				<div class="row"></div>
				<div class="row">
					<div class="order-process__image image">
						<img src="/assets/images/basket/process/success.gif" alt="photo" />
					</div>
				</div>
				<div class="row"></div>
			</div>
		</div>
	</div>
</div>
