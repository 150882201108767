<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToDohot()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
					<div class="header__title col">ניהול התראות</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>

			<!-- <h2 style="text-align: center" *ngIf="LoadingBack === true">טוען אנא המתן</h2> -->
		</header>

		<section class="report-table" style="margin-top: 40px">
			<div class="content">
				<div style="display: flex; justify-content: space-between; align-items: center">
					<div class="product-detail__button flex justify-align-center" (click)="openNewAlertDrawer()">התראה חדשה</div>
				</div>

				<div class="report-table__row report-table__row_between"></div>

				<div class="report-table__row report-table__row_full-width">
					<div class="loading" *ngIf="loading">
						<mat-spinner color="primary"></mat-spinner>
					</div>
					<mat-progress-bar *ngIf="isLoadingProgressBarShowing" style="position: absolute" mode="indeterminate"></mat-progress-bar>
					<ng-container *ngIf="allAlerts$ | async as allRelevantAlerts">
						<table class="report-table__table" cellspacing="0" *ngIf="allRelevantAlerts.length && !loading; else noAlertsBlock" dir="rtl">
							<!-- <table class="report-table__table" cellspacing="0" *ngIf="FilterLoading === false && allSpecials.length"> -->
							<thead class="report-table__table-head">
								<tr>
									<th class="report-table__table-head-name">שם</th>
									<th class="report-table__table-head-name" style="min-width: 140px">סוג</th>
									<th class="report-table__table-head-name">מלל</th>
									<th class="report-table__table-head-name">יצירה</th>
									<th class="report-table__table-head-name">סטטוס</th>
									<th class="report-table__table-head-name">שינויים</th>
								</tr>
							</thead>
							<tbody class="report-table__table-body">
								<tr *ngFor="let ata of allRelevantAlerts; index as i">
									<td class="report-table__table-body-name">{{ ata.Name }}</td>
									<td class="report-table__table-body-name">{{ MsgTypeText[ata.MsgType] }}</td>
									<td class="report-table__table-body-name">
										<form (ngSubmit)="msgInput.value ? handleChangeAlert($event, ata.ID, 'patch-msg', { input: msgInput, i }) : null">
											<mat-form-field dir="rtl">
												<!-- <mat-label>Enter your password</mat-label> -->
												<input #msgInput="ngModel" [(ngModel)]="inputControls[i]" matInput type="text" [placeholder]="ata.Msg" [ngModelOptions]="{ standalone: true }" />
												<button
													mat-icon-button
													matSuffix
													[attr.aria-label]="'Hide password'"
													[ngStyle]="{ opacity: msgInput.value && msgInput.value !== ata.Msg ? 1 : 0 }"
													style="color: var(--valid-color)"
													(click)="msgInput.value ? handleChangeAlert($event, ata.ID, 'patch-msg', { input: msgInput, i }) : null"
												>
													<mat-icon>done</mat-icon>
												</button>
												<button
													mat-icon-button
													matSuffix
													[attr.aria-label]="'Hide password'"
													[ngStyle]="{ opacity: msgInput.value && msgInput.value !== ata.Msg ? 1 : 0 }"
													style="color: var(--invalid-color)"
													(click)="msgInput.reset()"
												>
													<mat-icon>close</mat-icon>
												</button>
											</mat-form-field>
										</form>
									</td>
									<!-- <td class="report-table__table-body-name">{{ ata.Msg }}</td> -->
									<td class="report-table__table-body-name">{{ ata.CreateDate | date : 'dd/MM/yy' }}</td>
									<td
										class="report-table__table-body-name-active"
										[ngClass]="{ 'report-table__table-body-name-active-valid': ata.Active | isAlertActive : ata.JSON.date?.start : ata.JSON.date?.end }"
									>
										{{ (ata.Active | isAlertActive : ata.JSON.date?.start : ata.JSON.date?.end) ? 'פעיל' : 'לא פעיל' }}
									</td>
									<td class="report-table__table-body-name bold text-align-center">
										<div class="flex gap">
											<button mat-raised-button (click)="handleEditAlert(ata)">ערוך</button>
											<button mat-raised-button color="primary" (click)="handleChangeAlert($event, ata.ID, 'delete')">מחק</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</ng-container>

					<ng-template #noAlertsBlock>
						<h1 >אין נתונים להציג</h1>
					</ng-template>
					<!-- <h1 *ngIf="FilterLoading === false && !allSpecials.length">אין נתונים להציג</h1> -->
				</div>
			</div>
		</section>
	</div>
</div>

<!-- markup for sort popup -->
<div class="popup" *ngIf="isAlertDrawerOpen">
	<div class="overlay"></div>
	<div class="report-table-popup report-table-popup_sort popup__content">
		<div class="popup__close" (click)="closeDrawer()"></div>
		<div class="report-table-popup__header">
			<div class="report-table-popup__header-search"></div>
			<div class="report-table-popup__header-title">{{ isEdittingAlert ? 'עריכת התראה' : 'התראה חדשה' }}</div>
		</div>
		<div class="report-table-popup__wrapper">
			<div class="report-table-popup__content">
				<div class="loading" *ngIf="loading; else formBlock">
					<mat-spinner color="primary"></mat-spinner>
					<h3 style="text-align: center; direction: rtl">טוען...</h3>
				</div>
				<ng-template #formBlock>
					<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
						<div *ngIf="alertSuccess; else alertChangeBlock" style="width: 100%; display: grid; place-items: center">
							<h3 style="text-align: center; color: var(--valid-color)">התראה {{ isEdittingAlert ? 'עודכנה' : 'נוספה' }} בהצלחה!</h3>
							<button mat-raised-button color="primary" (click)="closeDrawer()">סגור</button>
						</div>

						<ng-template #alertChangeBlock>
							<form [formGroup]="form" dir="rtl" autocomplete="off" class="flex flex-column gap" (ngSubmit)="handleFormSubmit()">
								<mat-form-field color="primary">
									<mat-label>שם</mat-label>
									<input matInput placeholder="שם" type="text" formControlName="Name" required />
									<mat-error *ngIf="form.controls.Name.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<mat-form-field color="primary">
									<mat-label>התראה</mat-label>
									<input matInput placeholder="התראה" type="text" formControlName="Msg" required />
									<mat-error *ngIf="form.controls.Msg.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<mat-form-field color="primary" *ngIf="!isOnlyAgentAlerts">
									<mat-label>סוג התראה</mat-label>
									<mat-select formControlName="MsgType">
										<mat-option *ngFor="let type of msgTypes" [value]="type.msgType">
											{{ type.msgText }}
										</mat-option>
									</mat-select>
									<mat-error *ngIf="form.controls.MsgType.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<mat-form-field color="primary" *ngIf="shouldAllowTimeSelection$ | async">
									<mat-label>בחר שעה</mat-label>
									<input matInput placeholder="התראה" type="time" formControlName="time" required />
									<mat-error *ngIf="form.controls.time.invalid">שדה חסר או אינו תקין</mat-error>
								</mat-form-field>
								<ng-container *ngIf="shouldAllowClientSelection$ | async">
									<mat-checkbox formControlName="shouldAffectAllClients" color="primary">כל הלקוחות</mat-checkbox>

									<ng-container *ngIf="shouldShowClientAutocomplete$ | async">
										<mat-form-field color="primary">
											<mat-label>בחר לקוח</mat-label>
											<input type="text" matInput [matAutocomplete]="auto" formControlName="clientsSearch" />
											<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionSelected)="handleAutocompleteSelect($event, 'clients')">
												<mat-option *ngFor="let client of allClients$ | async" [value]="client" style="direction: rtl">
													<span style="unicode-bidi: isolate">{{ client.ClientId }}</span> -
													<span style="unicode-bidi: isolate">{{ client.Client_Name }}</span>
												</mat-option>
											</mat-autocomplete>
										</mat-form-field>
										<mat-chip-list dir="rtl">
											<mat-chip *ngFor="let client of form.controls.clients.value; let i = index" style="padding: 0 12px">
												{{ client.Client_Name }}
												<button matChipRemove (click)="chipClicked(i, 'clients')">
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<mat-error *ngIf="form.controls.clients.getError('invalidArray') && form.controls.clients.touched">יש לבחור לפחות לקוח אחד, או לסמן מחדש 'כל הלקוחות'</mat-error>
										</mat-chip-list>
									</ng-container>
								</ng-container>
								<ng-container *ngIf="(shouldAllowAgentSelection$ | async) && !isOnlyAgentAlerts">
									<mat-checkbox formControlName="shouldAffectAllAgents" color="primary">כל הסוכנים</mat-checkbox>

									<ng-container *ngIf="shouldShowAgentAutocomplete$ | async">
										<mat-form-field color="primary">
											<mat-label>בחר סוכן</mat-label>
											<input type="text" matInput [matAutocomplete]="auto" formControlName="agentsSearch" />
											<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnAgents" (optionSelected)="handleAutocompleteSelect($event, 'agents')">
												<mat-option *ngFor="let agent of allAgents$ | async" [value]="agent" style="direction: rtl">
													<span style="unicode-bidi: isolate">{{ agent.AgentID }}</span> -
													<span style="unicode-bidi: isolate">{{ agent.UserName }}</span>
												</mat-option>
											</mat-autocomplete>
										</mat-form-field>
										<mat-chip-list dir="rtl">
											<mat-chip *ngFor="let agent of form.controls.agents.value; let i = index" style="padding: 0 12px">
												{{ agent.UserName }}
												<button matChipRemove (click)="chipClicked(i, 'agents')">
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<mat-error *ngIf="form.controls.agents.getError('invalidArray') && form.controls.agents.touched">יש לבחור לפחות סוכן אחד, או לסמן מחדש 'כל הסוכנים'</mat-error>
										</mat-chip-list>
									</ng-container>
								</ng-container>
								<!-- <mat-checkbox formControlName="shouldBlockAgent" color="primary">עצירת סוכן</mat-checkbox> -->

								<div *ngIf="shouldShowStatusSelection" class="flex radio-row">
									<label>סטטוס: </label>
									<mat-radio-group formControlName="Active" color="primary">
										<mat-radio-button value="0">פעיל</mat-radio-button>
										<mat-radio-button value="1">לא פעיל</mat-radio-button>
										<mat-radio-button value="2">תקופה</mat-radio-button>
									</mat-radio-group>
								</div>

								<ng-container *ngIf="shouldShowDateSelection$ | async">
									<mat-form-field color="primary">
										<mat-label>משך התראה</mat-label>
										<mat-error *ngIf="form.controls.StartDate.invalid || form.controls.EndDate.invalid">תאריך אינו תקין</mat-error>
										<mat-date-range-input [rangePicker]="picker" [min]="dates.min" [max]="dates.max" (click)="picker.open()">
											<input matStartDate readonly="true" formControlName="StartDate" />
											<input matEndDate readonly="true" formControlName="EndDate" />
										</mat-date-range-input>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
									<div class="flex justify-evenly">
										<button mat-stroked-button color="primary" type="button" (click)="handleDateChange('month')">חודש</button>
										<button mat-stroked-button color="primary" type="button" (click)="handleDateChange('year')">שנה</button>
									</div>
								</ng-container>
								<div class="report-table-popup__button-wrapper" style="position: relative">
									<button class="report-table-popup__button" [ngClass]="{ darken: form.invalid }">{{ isEdittingAlert ? 'ערוך' : 'אשר' }}</button>
								</div>
							</form>
						</ng-template>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
