import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, combineLatest, from, map, Observable, startWith, Subject, switchMap } from 'rxjs';
import { RallyanceReclamationReclamatorPatchBody, RallyanceReclamationRekStatuses } from 'src/app/Interfaces/reclamations';
import { ReclamationService } from 'src/app/services/reclamation.service';
import { StoreService } from 'src/app/services/store.service';
import { TextService } from 'src/app/services/text.service';
import { UtilService } from 'src/app/services/util.service';
@Component({
	selector: 'app-reclamation-finish',
	templateUrl: './reclamation-finish.component.html',
	styleUrls: ['./reclamation-finish.component.scss'],
})
export class ReclamationFinishComponent implements OnInit, OnDestroy, AfterViewInit {
	constructor(private _fb: FormBuilder, public _ss: StoreService, public _text: TextService, private _util: UtilService, public _reclamation: ReclamationService, private http: HttpClient) {}

	windowWidth = innerWidth;

	orderProcess = false;

	isSendingOrder = false;

	form = this._fb.nonNullable.group({
		RekRemark: [''],
		AhuzPizui: [''],
		ConfirmReason: [''],
		DOT: [''],
		SERIAL: [''],
		OmekSulia: [''],
		SugTakalatYazran: [''],
	});

	destroy$ = new Subject<void>();

	@ViewChild('imageInput') imageInput: ElementRef<HTMLInputElement>;

	selectedTask$ = this._reclamation.selectedTask$;
	rekID$ = this.selectedTask$.pipe(map(task => task?.RekID));
	clientName$ = this.selectedTask$.pipe(map(task => task?.Client_Name));
	productID$ = this.selectedTask$.pipe(map(task => task?.ProductId));
	// productName$ = this.selectedTask$.pipe(map(() => 'product_name'));
	productName$ = this.selectedTask$.pipe(map(task => task?.Product_Name));
	sugTakala$ = this.selectedTask$.pipe(map(task => task?.SugTakala));
	agentRemark$ = this.selectedTask$.pipe(map(task => task?.AgentRemark));

	statusesEnum = this._reclamation.rallyanceReclamationRekStatusesDictionary;

	taskRekStatus$ = new BehaviorSubject<typeof RallyanceReclamationRekStatuses[number]>(this.statusesEnum.noStatus);
	isTaskPopupOpen$ = this.taskRekStatus$.pipe(map(status => status !== this.statusesEnum.noStatus));
	taskPopupTitle$ = this.taskRekStatus$.pipe(
		map(status => {
			switch (status) {
				case '1':
					return 'לא מאושר';
				case '2':
					return 'מאושר';
				case '3':
					return 'נשלח לתביעה';
				default:
					return '';
			}
		}),
	);

	reclamationReasons$ = combineLatest([this._reclamation.sugRecReasons$.pipe(startWith(this._reclamation.sugRecReasons$.value)), this.taskRekStatus$]).pipe(
		map(([reasons, status]) => {
			return reasons.filter(reason => reason.Sug === status);
		}),
	);

	taskPopupForApproved$ = this.taskRekStatus$.pipe(map(status => status === this.statusesEnum.approved));
	taskPopupForNotApproved$ = this.taskRekStatus$.pipe(map(status => status === this.statusesEnum.notApproved));
	taskPopupForSentForClaim$ = this.taskRekStatus$.pipe(map(status => status === this.statusesEnum.sentForClaim));

	images = [];

	ngOnInit() {
		window.scrollTo(0, 0);

		if (!this._reclamation.selectedTask$.value) {
			alert('לא נבחרה תביעה');
			history.back();
			return;
		}

		this._reclamation.getReclamationReasons();
		this._reclamation.sugRecReasons$.subscribe(console.log);
		this.reclamationReasons$.subscribe(console.log);
	}

	ngAfterViewInit(): void {
		console.log('view init');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	async handlePopulateInfo() {}

	BackToProducts() {
		history.back();
	}

	handleSaveOrBackButton() {
		this.BackToProducts();
	}

	handleSaveButton() {
		this.BackToProducts();
	}

	handleFormSubmit() {
		if (this.isSendingOrder) return;

		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}

		this.sendTask();
	}

	sendTask() {
		this.isSendingOrder = true;

		const body: RallyanceReclamationReclamatorPatchBody = {
			RekStatus: this.taskRekStatus$.value,
		};
		switch (this.taskRekStatus$.value) {
			case this.statusesEnum.notApproved:
				if (this.form.controls.RekRemark.value) {
					body.RekRemark = this.form.controls.RekRemark.value;
				}
				break;

			case this.statusesEnum.approved:
				if (this.form.controls.AhuzPizui.value) {
					body.AhuzPizui = this.form.controls.AhuzPizui.value;
				}
				body.ConfirmReason = this.form.controls.ConfirmReason.value;

				break;
			case this.statusesEnum.sentForClaim:
				body.DOT = this.form.controls.DOT.value || 'ERASED';
				body.SERIAL = this.form.controls.SERIAL.value || 'ERASED';
				body.SugTakalatYazran = this.form.controls.SugTakalatYazran.value;

				if (this.form.controls.OmekSulia.value) {
					body.OmekSulia = this.form.controls.OmekSulia.value;
				}
				for (const index in this.images) {
					const control = this.images[index];
					if (control) {
						body['PIC' + (Number(index) + 1)] = this.selectedTask$.value.RekID + '-' + (Number(index) + 1) + '.jpg';
					}
				}
				break;
			default:
				break;
		}

		this._reclamation.sendReclamationTaskAsReclamator(body, this.selectedTask$.value.TeudaNum).subscribe({
			next: res => {
				this.isSendingOrder = false;
				if (typeof res === 'string') {
					alert(this._text.store.couldntPatch);
					return;
				}
				this._reclamation.resetState();
				this.taskRekStatus$.next(this.statusesEnum.noStatus);
				this.orderProcess = true;
			},
			error: () => {
				alert(this._text.store.couldntPatch);
				this.isSendingOrder = false;
			},
		});
	}

	async setImage(event: Event, number: number) {
		const input = event.target as HTMLInputElement;
		input.classList.remove('error');
		input.classList.add('uploading');

		if (!input?.files?.length) {
			input.classList.remove('uploading', 'success');
			this.images[number] = '';
			return;
		}

		const fileReader = new FileReader();
		fileReader.readAsArrayBuffer(input.files[0]);

		fileReader.addEventListener('load', () => {
			const arrayBuffer = fileReader.result as ArrayBuffer;
			const blob = new Blob([arrayBuffer], { type: input.files[0].type });
			const file = new File([blob], `${this._reclamation.selectedTask$.value.RekID} - ${+number + 1}.jpg`, { type: input.files[0].type });

			this.handleFileUpload(file)
				.pipe(
					switchMap(bool => {
						if (bool) {
							this.images[number] = file;
						} else {
							input.classList.add('error');
						}
						return from([bool]);
					}),
				)
				.subscribe({
					next: () => {
						console.log(input);
						input.classList.remove('uploading');
						input.classList.add('success');
						// input.files
					},
					error: error => {
						console.log(error);
						input.classList.add('error');
						input.classList.remove('uploading');
					},
				});
		});
	}

	handleFileUpload(file: File): Observable<boolean> {
		const fd = new FormData();
		fd.append(file.name, file);

		return this.http.post(`${this._ss.CustomerInformation.CustServer}/${this._reclamation.path}/upload-camera-picture`, fd).pipe(
			map(() => true), // If the response is successful, return true
			catchError(error => {
				console.log(error);
				return from([false]);
			}),
		);
	}

	// async setImage(event: Event, number) {
	// 	const input = event.target as HTMLInputElement;
	// 	input.classList.remove('error');
	// 	input.classList.add('uploading');
	// 	const selectedFile = input.files[0];
	// 	if (!selectedFile) {
	// 		input.classList.add('error');
	// 		return;
	// 	}
	// 	// let file = new File([selectedFile], `${this.generatedTaskID}-${number}.jpg`, { type: selectedFile.type });
	// 	// if (this.isLikutMekabel) {
	// 	let file = new File([selectedFile], `${this._likut.likutMekabelOpenedState.main.rows[0].TeudaNum}.jpg`, { type: selectedFile.type });
	// 	// }
	// 	let bool;

	// 	try {
	// 		bool = await this.resolveFileUploadHandler(file);
	// 	} catch (err) {
	// 		console.error(err);
	// 		bool = false;
	// 	}
	// 	// if (bool) {
	// 	// 	if (this.isLikutMekabel) {
	// 	// 		this.likutMekabelImageUploaded = true;
	// 	// 		input.classList.remove('uploading');
	// 	// 		return;
	// 	// 	}
	// 	// 	this.images['ImageName' + number] = file;
	// 	// } else {
	// 	// 	input.classList.add('error');

	// 	// 	if (this.isTruckDistribution) {
	// 	// 		try {
	// 	// 			await this._indexedDB.storeImage({ taskIdentifier: this._truckDistribution.truckDistributionOpened.task.storageKey, imageFile: file, uploaded: false });
	// 	// 			// this.images['ImageName' + number] = file;
	// 	// 			input.classList.remove('error');
	// 	// 		} catch (err) {
	// 	// 			alert('לא ניתן להעלות או לשמור תמונה');
	// 	// 		}
	// 	// 	}
	// 	// }

	// 	input.classList.remove('uploading');
	// }

	async resolveFileUploadHandler(file: File): Promise<boolean> {
		// switch (this.pageMode$.value) {
		// 	case 'truck-distribution':
		// 		return await this._truckDistribution.handleFileUpload(file);
		// 	case 'truck-loading':
		// 		return await this._truckLoading.handleFileUpload(file);
		// 	case 'likut-mekabel':
		// 		return await this._likut.handleFileUploadLikutMekabel(file);

		// 	default:
		// 		return false;
		// }
		return Promise.resolve(false);
	}

	initiateSubscriptions() {}

	pipeADestroyer<T>(storeServiceMethod: Observable<T>) {
		return this._util.pipeASubjectDestoryer(storeServiceMethod, this.destroy$);
	}

	openTaskPopup(type: typeof RallyanceReclamationRekStatuses[number]) {
		this.taskRekStatus$.next(type);

		this.handleFormInit(type);
	}

	handleFormInit(type: typeof RallyanceReclamationRekStatuses[number]) {
		this.stripAllFormValidators();
		switch (type) {
			case this.statusesEnum.approved:
				this.form.controls.ConfirmReason.setValidators(Validators.required);
				break;
			case this.statusesEnum.sentForClaim:
				this.form.controls.SugTakalatYazran.setValidators(Validators.required);
				break;

			default:
				break;
		}
	}

	stripAllFormValidators() {
		for (const key in this.form.controls) {
			this.form.controls[key].setValidators(null);
		}
	}

	handleClickOutsideTaskPopup(e: Event) {
		if ((e.target as HTMLElement).classList.contains('overlay')) {
			this.cancelSendAndClosePopup();
		}
	}

	cancelSendAndClosePopup() {
		this.taskRekStatus$.next(this.statusesEnum.noStatus);
		this.form.reset();
		this.images = [];
	}

	getButtonClass(color: string) {
		return { ['buttons-row-button-' + color]: true };
	}

	addImageControl() {
		this.images.push('');
	}
}
