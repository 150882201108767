<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohotBizoim()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">מכירות מול חזרות</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<!-- <div class="report-table__row">
    <div class="header__search header__search_small" style="width: 99%;margin: auto;margin-top: 10px;">
      <form [formGroup]="form" (keyup)="SearchClient()">
        <input class="header__search-input" type="text" placeholder=" חפש לקוח" formControlName="ClientSearch"
          style="text-align: center;">
      </form>
      <button class="header__clear-icon" (click)="SearchClear()"></button>
    </div>
  </div> -->
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<h1 *ngIf="Loading === true" style="text-align: right; margin-top: 100px">טוען אנא המתן...</h1>

		<div class="report-table__row report-table__row_between" style="display: block; margin-top: 100px">
			<h3 *ngIf="(Second || Third || Fourth) && selectedYear && !Loading" style="text-align: right">{{ selectedYear }}</h3>
			<h3 *ngIf="(Second || Third || Fourth) && selectedAgent && !Loading" style="text-align: right">{{ selectedAgent }}</h3>
			<h1 *ngIf="((Second && !BizoimEach.length) || (Third && !BizoimEach2.length) || (Fourth && !BizoimEach3.length)) && !Loading" style="text-align: right">אין נתונים להציג</h1>
		</div>

		<!-- <div class="flex-row">
      <div class="box">
        <b>סה"כ</b>
        <span>
          {{(bizoimTotal$ | async ) | number : '1.0-2'}}
        </span>
      </div>
      <div class="box">
        <b>ממוצע</b>
        <span>
          {{bizoimAverage$ | async | number : '1.0-2'}}
        </span>
      </div>
      <div class="box">
        <mat-form-field color="primary" appearance="fill" dir="rtl" style="text-align: right;" *ngIf="isInAdminPage">
          <mat-label  dir="rtl">סוכנים</mat-label>
          <mat-select dir="rtl" [formControl]="agentsControl" multiple (valueChange)="handleMatSelectChange($event)">
            <mat-option *ngFor="let a of agents" value="{{ a.id }}">{{ a.id + ' - ' + a.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0" *ngIf="First && !Loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('agentcode', 'Bizoim')" style="width: 170px">סוכן</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totsale', 'Bizoim')" style="width: 120px">מכירות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('ltotsale', 'Bizoim')" style="width: 120px">מכירות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totreturn', 'Bizoim')" style="width: 120px">החזרות השנה</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('ltotreturn', 'Bizoim')" style="width: 120px">החזרות אשתקד</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('ahuz', 'Bizoim')" style="width: 90px">אחוז</th>
						<th class="report-table__table-head-name" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Bizoim">
						<td class="report-table__table-body-name">{{ b.agentcode + ' - ' + b.agentname }}</td>
						<td class="report-table__table-body-name" (click)="getSalesVsReturnsPerAgent(b.agentcode, year)">{{ b.totsale | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" (click)="getSalesVsReturnsPerAgent(b.agentcode, year - 1)">{{ b.ltotsale | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" (click)="getSalesVsReturnsPerAgent(b.agentcode, year)">{{ b.totreturn | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" (click)="getSalesVsReturnsPerAgent(b.agentcode, year - 1)">{{ b.ltotreturn | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name" (click)="getSalesVsReturnsPerAgent(b.agentcode, year)">{{ b.ahuz | number : '1.0-2' : 'en-US' }}</td>

						<td class="report-table__table-body-name" *ngIf="HasMultipleCompanys > 1">{{ b.company }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="Second && BizoimEach.length && !Loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('clientname', 'BizoimEach')" style="width: 250px">לקוח</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totsale', 'BizoimEach')" style="width: 150px">מכירות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totreturn', 'BizoimEach')" style="width: 150px">החזרות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('ahuz', 'BizoimEach')" style="width: 150px">אחוז</th>
						<th class="report-table__table-head-name" style="width: 150px" *ngIf="HasMultipleCompanys > 1">חברה</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of BizoimEach" (click)="getSalesVsReturnsPerAgentPerClient(b.agentcode, b.yy, b.clientid)">
						<td class="report-table__table-body-name">{{ b.clientname }}</td>
						<td class="report-table__table-body-name">{{ b.totsale | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.totreturn | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.ahuz | number : '1.0-2' : 'en-US' }}</td>
						<td class="report-table__table-body-name" *ngIf="HasMultipleCompanys > 1">{{ b.company }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="Third && BizoimEach2.length && !Loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('mm', 'BizoimEach2')" style="width: 110px">חודש</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totsale', 'BizoimEach2')" style="width: 110px">מכירות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totreturn', 'BizoimEach2')" style="width: 110px">החזרות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('ahuz', 'BizoimEach2')" style="width: 110px">אחוז</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of BizoimEach2" (click)="getSalesVsReturnsPerAgentPerClientPerMonth(b.agentcode, b.yy, b.clientid, b.mm)">
						<td class="report-table__table-body-name">{{ b.mm - 1 | returnMonthHebrew }}</td>
						<td class="report-table__table-body-name">{{ b.totsale | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.totreturn | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.ahuz | number : '1.0-2' : 'en-US' }}</td>
					</tr>
				</tbody>
			</table>

			<table class="report-table__table" cellspacing="0" *ngIf="Fourth && BizoimEach3.length && !Loading">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('productname', 'BizoimEach3')" style="width: 250px">פריט</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totsale', 'BizoimEach3')" style="width: 110px">מכירות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('totreturn', 'BizoimEach3')" style="width: 110px">החזרות</th>
						<th class="report-table__table-head-name sortable-clickable" (click)="sort('ahuz', 'BizoimEach3')" style="width: 90px">אחוז</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of BizoimEach3">
						<td class="report-table__table-body-name">{{ b.productname }}</td>
						<td class="report-table__table-body-name">{{ b.totsale | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.totreturn | number : '1.0-2' }}</td>
						<td class="report-table__table-body-name">{{ b.ahuz | number : '1.0-2' : 'en-US' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
<!-- end report table markup -->
