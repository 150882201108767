import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'returnTimeFormatted',
})
export class ReturnTimeFormattedPipe implements PipeTransform {
	transform(value: string | number | Date, isTrueISO = false): string {
		try {
			// ISO Date comes with added hours because backend can't turn hours properly

			const timeZone = isTrueISO ? 'Asia/Jerusalem' : 'UTC';

			return new Intl.DateTimeFormat('en-gb', { timeZone, hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(value));
		} catch (error) {
			return '';
		}
	}
}
