import { Directive, EventEmitter, Output, OnInit, HostListener, Input, Host, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
	selector: '[appTap]',
})
export class TapDirective implements OnInit {
	@Output() tap = new EventEmitter();
	// @Output() doubleTap = new EventEmitter();
	@Output() tappedTwice = new EventEmitter();
	@Input() mobileOnly: 'true' | 'false' | boolean = false;
	lastTap = 0;
	tapCount = 0;
	tapTimeout = null;
	tapGesture = {
		enabled: false,
		interval: 250,
	};
	doubleTapGesture = {
		enabled: true,
		interval: 300,
	};

	ngOnInit() {
		this.tapGesture.enabled = true;
		this.doubleTapGesture.enabled = true;

		// fromEvent(this.el.nativeElement,'touchstart').subscribe(e => console.log(e));
	}

	@HostListener('click', ['$event'])
	handleTaps(e: Event) {
		const tapTimestamp = Math.floor(e.timeStamp);
		const isDoubleTap = this.lastTap + this.tapGesture.interval > tapTimestamp;
		if (!this.tapGesture.enabled && !this.doubleTapGesture.enabled) {
			return this.resetTaps();
		}
		this.tapCount++;
		if (isDoubleTap && this.doubleTapGesture.enabled) {
			this.emitTaps();
		} else if (!isDoubleTap) {
			this.tapTimeout = setTimeout(() => this.emitTaps(), this.tapGesture.interval);
		}
		this.lastTap = tapTimestamp;
	}

	private emitTaps() {
		if (this.tapCount === 1 && this.tapGesture.enabled) {
			this.tap.emit();
		} else if (this.tapCount === 2 && this.doubleTapGesture.enabled) {
			this.tappedTwice.emit();
		}
		this.resetTaps();
	}

	private resetTaps() {
		clearTimeout(this.tapTimeout); // clear the old timeout
		this.tapCount = 0;
		this.tapTimeout = null;
		this.lastTap = 0;
	}
}
