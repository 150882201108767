<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<!-- global markup for header -->
		<header class="order-header header" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }">
			<div class="content">
				<div class="header__block row">
					<div class="header__box" *ngIf="_ss.OrderInProcess === false">
						<img
							class="finish-likut"
							[src]="isTruckLoading || isTruckDistribution ? '/assets/images/delivery23.svg' : '/assets/images/edit-document.png'"
							alt="finish likut"
							(click)="moveToFinishTask()"
						/>
					</div>

					<!-- Search line option keyup start  and main manu 0-->

					<div class="header__search header__search_small">
						<input
							class="header__search-input"
							type="text"
							[placeholder]="_text.store.search"
							[formControl]="productSearch"
							(keydown)="handlePressEnterOnSearch($event)"
							style="text-align: center; font-size: 17px; font-weight: 600"
							dir="rtl"
							id="productsearch"
							autocomplete="off"
						/>
						<button class="header__clear-icon header__barcode-icon" (click)="handleBarcodePopupOpen()"></button>
						<button class="header__clear-icon" (click)="SearchClear()"></button>
						<span class="search-info" *ngIf="!isLikut"> {{ _text.store.showingNumberOfProducts[0] }} ({{ totalShown$ | async }}) {{ _text.store.showingNumberOfProducts[1] }}</span>
						<span class="search-info total-active" *ngIf="isLikut"> פריטים {{ totalShown$ | async }} / {{ totalHandled$ | async }}</span>
					</div>

					<!-- Search line option keyup ends and main manu-->

					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>
		<!-- end global markup for header -->

		<!-- order gray section -->
		<section class="order-gray" [ngClass]="{ 'main-content_absolute': _ss.headerAbsolute }">
			<div class="content">
				<!-- <button mat-raised-button class="content-button" *ngIf="_likut.isCheckerAgent" (click)="isCheckerReturnPopupOpen$.next(true)">{{ _likut.text.returnLikutTask }}</button> -->
				<!-- <button mat-raised-button class="content-button" *ngIf="_likut.isMelaketAgent || isTruckLoading || isTruckDistribution" (click)="handleMelaketGoBack()">{{ _text.store.goBack }}</button> -->
				<div
					class="filter-sort__row filter-sort__row_double content-button valid-background secondary-color"
					style="right: unset; left: 6px"
					*ngIf="isNewTask || _likut.isMelaketAgentAndCanAddNewItems"
				>
					<div class="filter-sort__row-block" style="margin: 0" (click)="openAddNewProductForMekabelPopup()">
						<span class="filter-sort__row-name">הוסף פריט</span>
					</div>
				</div>
				<!-- <ng-template #totalShownHandledBlock>
					<div class="filter-sort__row filter-sort__row_double content-button" style="right: unset; left: 6px" *ngIf="isLikut">
						<div class="filter-sort__row-block" style="margin: 0">
							<span class="filter-sort__row-name">פריטים {{ totalShown$ | async }} / {{ totalHandled$ | async }}</span>
						</div>
					</div>
				</ng-template> -->
				<div class="filter-sort__row filter-sort__row_double content-button" *ngIf="_likut.isMelaketAgent || isTruckLoading || isTruckDistribution || isLikutMekabel">
					<div class="filter-sort__row-block" (click)="handleMelaketGoBack()">
						<span class="filter-sort__row-name">{{ _text.store.goBack }}</span>
						<div class="filter-sort__row-img image">
							<img src="/assets/images/arrow-back.svg" style="transform: rotate(180deg)" alt="photo" />
						</div>
					</div>
				</div>
				<div class="order-gray__row row">
					<div class="order-gray__title order-gray__title_bold">{{ teudaName }} - <span style="color: green">ONLINE</span></div>
					<div class="order-gray__title">{{ clientName }}</div>
					<div class="order-gray__title" *ngIf="isLikutMekabel">{{ _likut.likutMekabelOpenedState.main.refrenceNumber }}</div>
				</div>
			</div>
		</section>
		<!-- end order gray section -->

		<section class="main-title">
			<h2 class="order-gray__title order-gray__title_bold main-title-text" *ngIf="isTruckDistribution">פריטי אספקה ללקוח</h2>
		</section>

		<!-- products section -->
		<section class="order-products">
			<div class="content">
				<!-- <section class="shop-filter-empty" *ngIf="!(productDisplay$ | async).length">
					<div class="container" style="text-align: center">
						<div class="shop-filter-empty__row row">
							<div class="shop-filter-empty__photo image">
								<img src="/assets/images/shop-return/no-filters-result.svg" alt="photo" />
							</div>
							<h2 class="shop-filter-empty__title" *ngFor="let text of _text.store.notFound" style="margin-top: 1rem">{{ text }}</h2>
						</div>
					</div>
				</section> -->

				<cdk-virtual-scroll-viewport [itemSize]="inlineItemSize" class="example-viewport">
					<div class="order-product order-product_inline">
						<ng-container *ngIf="isTrucks$ | async; else likutNewRoutesBlock">
							<div
								class="order-product__item"
								[ngClass]="{ 'order-product__item-opened': line.truckTask.opened, 'order-product__item-matches-search': line.matchesSearch }"
								*cdkVirtualFor="let line of productDisplay$ | async; let i = index"
							>
								<img
									src="{{ CustImageDomain }}/{{ line.product?.ItemPicName || '' | encodeURI }}.jpg"
									crossorigin
									class="ItemPic product-line-right"
									appFallbackSrc
									alt="photo"
									(click)="handlePopupOpen(line)"
								/>

								<div class="product-line product-line-middle flex-column justify-align-center">
									<p>{{ _text.store.teudaNum }}: {{ line.truckTask.DocNum }}</p>

									<p class="product-name">
										{{ line.product?.Product_Name ? (line.product.Product_Name || '').slice(0, isMobile ? 70 : 120) : line.truckTask.ProductName || line.truckTask.ProductID }}
									</p>
									<p style="margin: 0">{{ line.truckTask.Barcode }}</p>
								</div>
								<div class="product-line product-line-left flex gap justify-between">
									<div class="product-line-left-column flex-column justify-align-center gap">
										<div class="product-line-left-column-up">
											<div class="product-line-left-column-up-title">
												{{ _text.store.ordered }}
											</div>
											<div class="product-line-left-column-up-number">
												<span>יח': </span>
												<ng-container [ngSwitch]="_truckLoading.worksIn">
													<span *ngSwitchCase="'singles'" [ngClass]="{ 'valid-color': line | areQuantitiesDifferent : 'truck' : 'single' }">{{ line.truckTask.PODQuantity }}</span>
													<span *ngSwitchCase="'packs'" [ngClass]="{ 'valid-color': line | areQuantitiesDifferent : 'truck' : 'pack' }">{{ line.truckTask.PODPackQTY }}</span>
													<span *ngSwitchCase="'both'" [ngClass]="{ 'valid-color': line | areQuantitiesDifferent : 'truck' : 'single' }">{{ line.truckTask.PODQuantity }}</span>
												</ng-container>
												<span
													*ngIf="line | areQuantitiesDifferent : 'truck' : 'both'"
													class="product-line-left-column-up-number-itempack"
													style="bottom: 0"
													[ngStyle]="{
														left: isMobile ? '-40px' : '-54px'
													}"
													>(<ng-container *ngIf="(worksInSinglesQuantity || worksInBothSinglesAndPackQuantity) && line | areQuantitiesDifferent : 'truck' : 'single'">
														{{ line.truckTask.Quantity }}
													</ng-container>
													<ng-container *ngIf="worksInPackQuantity && (line | areQuantitiesDifferent : 'truck' : 'pack')"> {{ line.truckTask.PackQTY }} </ng-container>)
												</span>
											</div>
											<div class="product-line-left-column-up-number">
												<ng-container *ngIf="_truckLoading.worksIn === 'both'">
													<span>אר': </span><span [ngClass]="{ 'valid-color': line | areQuantitiesDifferent : 'truck' : 'pack' }">{{ line.truckTask.PODPackQTY }}</span>
													<span
														*ngIf="line | areQuantitiesDifferent : 'truck' : 'pack'"
														class="product-line-left-column-up-number-itempack"
														style="bottom: 0"
														[ngStyle]="{
															left: isMobile ? '-40px' : '-54px'
														}"
														>( {{ line.truckTask.PackQTY }})
													</span>
												</ng-container>
											</div>
										</div>
										<div class="product-line-left-column-down" *ngIf="isTruckLoading">
											<mat-checkbox color="primary" [(ngModel)]="checkboxState[line.truckTask.Barcode]" disabled (click)="handleCheckboxPress(line)"></mat-checkbox>
										</div>
										<!-- <div class="product-line-left-column-down" *ngIf="isTruckDistribution">
											<mat-checkbox color="primary" [(ngModel)]="checkboxState[line.truckTask.Barcode]" (click)="handleCheckboxPress(line)"></mat-checkbox>
										</div> -->
									</div>
								</div>
							</div>
						</ng-container>
						<ng-template #likutNewRoutesBlock>
							<ng-container *ngIf="!isLikutMekabel; else likutMekabelBlock">
								<div
									class="order-product__item"
									[ngStyle]="{ 'background-color': line.likut.Color || null }"
									[ngClass]="{
										'order-product__item-opened': line.opened,
										'order-product__item-added': line.likut.LStatus === LStatuses.isDuplicated,
										'order-product__item-matches-search': line.matchesSearch,
										'order-product__item-doesnt-exist': !line.product,
										'order-product__item-mismatched-quantities': line.mismatchedQuantitiesToShowChecker
									}"
									*cdkVirtualFor="let line of likutProductDisplayNewRoutes$; let i = index"
								>
									<app-stock-flag class="line-extras stock" *ngIf="refreshStockOnEntryAndDisplay && line?.product?.Stock" [Stock]="line.product.Stock" [product]="{ BS: 0 }"></app-stock-flag>
									<div class="line-extras eitur" *ngIf="showEitur && line?.likut?.Eitur">
										{{ line.likut.Eitur }}
									</div>

									<img
										src="{{ CustImageDomain }}/{{ line.product?.ItemPicName | encodeURI }}.jpg"
										crossorigin
										class="ItemPic product-line-right"
										appFallbackSrc
										alt="photo"
										(click)="handlePopupOpen(line)"
									/>
									<div class="product-line product-line-middle flex-column justify-align-center">
										<p>{{ _text.store.teudaNum }}: {{ line.likut.TeudaNum }}</p>
										<ng-container *ngIf="line.product; else productInactiveBlock">
											<p class="product-name">{{ line.product.Product_Name.slice(0, isMobile ? 70 : 100) }}</p>
											<div class="flex gap">
												<span *ngIf="showBarcodeFromLikut"> {{ line.likut.Barcode }}</span>
												<span>{{ line.product.ItemBarcode }}</span>
												<ng-container *ngIf="canSeeTeudaPrice">
													<span> - </span>
													<span>
														<b>
															{{ line.likut.TeudaPrice }}
															<span class="valid-color" *ngIf="line.likut.LikutPrice !== line.likut.TeudaPrice">({{ line.likut.LikutPrice }})</span>
														</b>
													</span>
												</ng-container>
											</div>
										</ng-container>
										<ng-template #productInactiveBlock>
											<h1 class="invalid-color" style="margin: 0">לא פעיל</h1>
											<p class="product-name" style="margin: 0">{{ (line.likut.ProductName || '').slice(0, isMobile ? 70 : 100) }}</p>
										</ng-template>
									</div>
									<div class="product-line product-line-left flex gap justify-between position-relative">
										<span *ngIf="(worksInBothSinglesAndPackQuantity || showLikutExtra1InParenthesis) && !dontShowParenthesisAtAll" class="product-line-left-column-up-number-itempack"
											>({{
												showLikutExtra1InParenthesis
													? line.likut.LikutExtra1
													: ((line.likut.TeudaQuantity + line.likut.TeudaPackQuantity * line.likut.ItemPack) / line.likut.ItemPack | number : '1.0-2')
											}})</span
										>
										<div class="product-line-left-column flex-column justify-align-center gap" *ngIf="_likut.isCheckerAgent || line.likut.LStatus !== LStatuses.isDuplicated">
											<div class="product-line-left-column-up">
												<div class="product-line-left-column-up-title">
													{{ _text.store.ordered }}
												</div>
												<div class="product-line-left-column-up-number">
													<span *ngIf="worksInBothSinglesAndPackQuantity">יח': </span>
													<ng-container [ngSwitch]="LikutType">
														<span *ngSwitchCase="'0'">{{ line.likut.TeudaQuantity || 0 }}</span>
														<span *ngSwitchCase="'1'">{{ line.likut.TeudaQuantity || 0 }}</span>
														<span *ngSwitchCase="'2'">{{ line.likut.TeudaPackQuantity || 0 }}</span>
													</ng-container>
												</div>
												<div class="product-line-left-column-up-number">
													<ng-container *ngIf="worksInBothSinglesAndPackQuantity"> <span>אר': </span>{{ line.likut.TeudaPackQuantity || 0 }}</ng-container>
												</div>
											</div>
											<div class="product-line-left-column-down">
												<mat-checkbox
													color="primary"
													[(ngModel)]="checkboxState[line.likut.ProductId + ' - ' + line.likut.TeudaNum]"
													[disabled]="!line.product"
													(click)="handleCheckboxPress(line)"
												></mat-checkbox>
											</div>
										</div>
										<div class="product-line-left-column flex-column justify-align-center gap">
											<div class="product-line-left-column-up">
												<div class="product-line-left-column-up-title">
													{{ _text.store.collected }}
												</div>
												<div class="product-line-left-column-up-number">
													<ng-container [ngSwitch]="LikutType">
														<span *ngSwitchCase="'0'">{{ line.likut.LikutQty || 0 }}</span>
														<span *ngSwitchCase="'1'">{{ line.likut.LikutQty || 0 }}</span>
														<span *ngSwitchCase="'2'">{{ line.likut.LikutPackQty || 0 }}</span>
													</ng-container>
												</div>
												<div class="product-line-left-column-up-number">
													<ng-container *ngIf="worksInBothSinglesAndPackQuantity">
														<span> {{ line.likut.LikutPackQty || 0 }}</span>
													</ng-container>
												</div>
											</div>
											<div class="product-line-left-column-down" *ngIf="_likut.isMelaketAgent || _likut.isCheckerAgentAndCanEdit">
												<button mat-icon-button aria-label="edit" class="product-line-left-column-down-button" [disabled]="!line.product" (click)="handlePopupOpen(line)">
													<mat-icon>edit</mat-icon>
												</button>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-template #likutMekabelBlock>
								<div
									class="order-product__item"
									[ngStyle]="{ 'background-color': line.likut.Color || null }"
									[ngClass]="{
										'order-product__item-opened': line.likut.opened,
										'order-product__item-matches-search': line.matchesSearch
									}"
									*cdkVirtualFor="let line of likutMekabelDisplay$; let i = index"
								>
									<img
										src="{{ CustImageDomain }}/{{ line.likut.ItemPicName | encodeURI }}.jpg"
										crossorigin
										class="ItemPic product-line-right"
										appFallbackSrc
										alt="photo"
										(click)="handlePopupOpen(line)"
									/>
									<div class="product-line product-line-middle flex-column justify-align-center">
										<p class="product-name">{{ line.likut.ProductName.slice(0, isMobile ? 70 : 100) }}</p>
										<div class="flex gap">
											<span>{{ line.likut.Barcode }}</span>
											<ng-container *ngIf="canSeeTeudaPrice">
												<span> - </span>
												<span>
													<b> {{ line.likut.TeudaPrice }} </b>
												</span>
											</ng-container>
										</div>
									</div>
									<div class="product-line product-line-left flex gap justify-between position-relative">
										<span
											*ngIf="(worksInBothSinglesAndPackQuantity || showLikutExtra1InParenthesis) && !dontShowParenthesisAtAll"
											class="product-line-left-column-up-number-itempack"
											[ngClass]="{ 'both-quantities': worksInBothSinglesAndPackQuantity }"
										>
											({{
												showLikutExtra1InParenthesis
													? line.likut.LikutExtra1
													: ((line.likut.TeudaQty + line.likut.TeudaPackQty * line.likut.ItemPack) / line.likut.ItemPack | number : '1.0-2')
											}})
										</span>
										<div class="product-line-left-column flex-column justify-align-center gap" *ngIf="!isNewTask">
											<div class="product-line-left-column-up">
												<div class="product-line-left-column-up-title">
													{{ _text.store.ordered }}
												</div>
												<div class="product-line-left-column-up-number">
													<span *ngIf="worksInBothSinglesAndPackQuantity">יח': </span>
													<ng-container [ngSwitch]="LikutType">
														<span *ngSwitchCase="'0'">{{ line.likut.TeudaQty }}</span>
														<span *ngSwitchCase="'1'">{{ line.likut.TeudaQty }}</span>
														<span *ngSwitchCase="'2'">{{ line.likut.TeudaPackQty }}</span>
													</ng-container>
												</div>
												<div class="product-line-left-column-up-number">
													<ng-container *ngIf="worksInBothSinglesAndPackQuantity"> <span>אר': </span>{{ line.likut.TeudaPackQty }}</ng-container>
												</div>
											</div>
											<div class="product-line-left-column-down">
												<mat-checkbox color="primary" [checked]="checkboxState[line.likut.ProductId]" (change)="handleCheckboxPressLikutMekabel(line, $event)"></mat-checkbox>
											</div>
										</div>
										<div class="product-line-left-column flex-column justify-align-center gap">
											<div class="product-line-left-column-up">
												<div class="product-line-left-column-up-title">
													{{ _text.store.collected }}
												</div>
												<div class="product-line-left-column-up-number">
													<ng-container [ngSwitch]="LikutType">
														<span *ngSwitchCase="'0'"><span *ngIf="isNewTask">יח: </span>{{ line.likut.TeudaQtyKabala || 0 }}</span>
														<span *ngSwitchCase="'1'"><span *ngIf="isNewTask">יח: </span>{{ line.likut.TeudaQtyKabala || 0 }}</span>
														<span *ngSwitchCase="'2'"><span *ngIf="isNewTask">אר: </span>{{ line.likut.TeudaPackQtyKabala || 0 }}</span>
													</ng-container>
												</div>
												<div class="product-line-left-column-up-number">
													<ng-container *ngIf="worksInBothSinglesAndPackQuantity">
														<span><span *ngIf="isNewTask">אר: </span>{{ line.likut.TeudaPackQtyKabala || 0 }}</span>
													</ng-container>
												</div>
											</div>
											<div class="product-line-left-column-down">
												<button mat-icon-button aria-label="edit" class="product-line-left-column-down-button" (click)="handlePopupOpen(line)">
													<mat-icon>edit</mat-icon>
												</button>
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</ng-template>
					</div>
				</cdk-virtual-scroll-viewport>
			</div>
		</section>
		<!-- end products section -->
	</div>

	<div class="popup" *ngIf="isNormalPopupShown$ | async" style="z-index: 555">
		<div class="overlay" (click)="handlePopupClose()"></div>
		<div class="product-detail-popup popup__content">
			<div class="popup__close" (click)="handlePopupClose()"></div>
			<ng-conatiner [ngSwitch]="LikutType" *ngIf="isProductWithLikut(currentlyEdittedTask)">
				<div class="product-detail__wrapper">
					<div class="product-detail__item">
						<h1 class="order-gray__title_bold text-align-center position-relative">
							<div
								class="filter-sort__row filter-sort__row_double content-button"
								style="background: var(--invalid-color); color: var(--secondary-color)"
								*ngIf="currentlyEdittedTask.likut.LStatus === LStatuses.isDuplicated"
							>
								<div class="filter-sort__row-block" style="margin: 0" (click)="melaketProductToBeDeleted$.next(currentlyEdittedTask)">
									<span class="filter-sort__row-name">מחק פריט</span>
								</div>
							</div>
							{{ _text.store.quantityUpdateTitle }}
						</h1>
						<h1 class="order-gray__title_bold text-align-center">{{ currentlyEdittedTask.product.Product_Name }}</h1>
						<h1 class="order-gray__title_bold text-align-center" *ngIf="currentlyEdittedTask.likut.TeudaItemRemark">{{ currentlyEdittedTask.likut.TeudaItemRemark }}</h1>
						<!-- <h1 class="order-gray__title_bold text-align-center" *ngIf="currentlyEdittedTask.likut.LikutExtra1 && currentlyEdittedTask.likut.LikutExtra1 !== '0'">
						{{ currentlyEdittedTask.likut.LikutExtra1 }}
					</h1> -->
						<div style="display: flex; margin-bottom: 16px" [ngStyle]="{ justifyContent: currentlyEdittedTask.likut.LStatus === LStatuses.isDuplicated ? 'space-evenly' : 'space-between' }">
							<div class="product-detail-popup-column" *ngIf="currentlyEdittedTask.likut.LStatus !== LStatuses.isDuplicated">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityOrdered[LikutType] }}
								</div>
								<h3 *ngSwitchCase="'0'">
									{{ currentlyEdittedTask.likut.TeudaQuantity }}
								</h3>
								<h3 *ngSwitchCase="'1'">
									{{ currentlyEdittedTask.likut.TeudaQuantity }}
								</h3>
								<h3 *ngSwitchCase="'2'">
									{{ currentlyEdittedTask.likut.TeudaPackQuantity }}
								</h3>
							</div>

							<div class="product-detail-popup-column">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityCollected[LikutType] }}
								</div>
								<div class="flex gap justify-align-center align-center">
									<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
										<button
											class="order-product__item-info-count-button order-product__item-info-count-button_add"
											type="button"
											(click)="handleQuantityButtonPress('increment', LikutType !== '2' ? 'single' : 'pack')"
										></button>
										<input
											formControlName="collected"
											class="product-detail-popup-column-quantity"
											inputmode="numeric"
											(keyup)="handleIfBackspaceOnEmptyInput($event)"
											#collected
											*ngIf="LikutType !== '2'; else collectedPacksInput"
											[placeholder]="currentlyEdittedTask.likut.LikutQty || 0"
											[ngClass]="{ 'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTask.likut.ProductId + ' - ' + currentlyEdittedTask.likut.TeudaNum] }"
										/>
										<ng-template #collectedPacksInput>
											<input
												formControlName="collectedPacks"
												class="product-detail-popup-column-quantity"
												inputmode="numeric"
												(keyup)="handleIfBackspaceOnEmptyInput($event)"
												#collectedPacks
												[placeholder]="currentlyEdittedTask.likut.LikutPackQty || 0"
												[ngClass]="{
													'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTask.likut.ProductId + ' - ' + currentlyEdittedTask.likut.TeudaNum]
												}"
											/>
										</ng-template>
										<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement', LikutType !== '2' ? 'single' : 'pack')"></button>
									</form>
								</div>
							</div>
						</div>
						<div
							style="display: flex"
							[ngStyle]="{ justifyContent: currentlyEdittedTask.likut.LStatus === LStatuses.isDuplicated ? 'space-evenly' : 'space-between' }"
							*ngIf="worksInBothSinglesAndPackQuantity"
						>
							<div class="product-detail-popup-column text-align-center" *ngIf="currentlyEdittedTask.likut.LStatus !== LStatuses.isDuplicated">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityOrdered['0-2'] }}
								</div>

								<h3>{{ currentlyEdittedTask.likut.TeudaPackQuantity }}</h3>
							</div>

							<div class="product-detail-popup-column">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityCollected['0-2'] }}
								</div>
								<div class="flex gap justify-align-center align-center">
									<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
										<button
											class="order-product__item-info-count-button order-product__item-info-count-button_add"
											type="button"
											(click)="handleQuantityButtonPress('increment', 'pack')"
										></button>
										<input
											formControlName="collectedPacks"
											class="product-detail-popup-column-quantity"
											inputmode="numeric"
											(keyup)="handleIfBackspaceOnEmptyInput($event)"
											#collectedPacks
											[placeholder]="currentlyEdittedTask.likut.LikutPackQty || 0"
											[ngClass]="{ 'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTask.likut.ProductId + ' - ' + currentlyEdittedTask.likut.TeudaNum] }"
										/>
										<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement', 'pack')"></button>
									</form>
								</div>
							</div>
						</div>
						<div style="display: flex; justify-content: space-between" *ngIf="_likut.isCheckerAgentAndCanEdit && canSeeTeudaPrice">
							<div class="product-detail-popup-column text-align-center">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityOrdered['0-3'] }}
								</div>

								<h3>{{ currentlyEdittedTask.likut.TeudaPrice }}</h3>
							</div>

							<div class="product-detail-popup-column">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityCollected['0-3'] }}
								</div>
								<div class="flex gap justify-align-center align-center">
									<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
										<input
											formControlName="price"
											class="product-detail-popup-column-quantity"
											inputmode="numeric"
											(keyup)="handleIfBackspaceOnEmptyInput($event)"
											#price
											[placeholder]="currentlyEdittedTask.likut.LikutPrice || 0"
										/>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-conatiner>
			<ng-conatiner [ngSwitch]="LikutType" *ngIf="isProductWithTruckDistribution(currentlyEdittedTask)">
				<div class="product-detail__wrapper">
					<div class="product-detail__item">
						<h1 class="order-gray__title_bold text-align-center">{{ _text.store.quantityUpdateTitle }}</h1>
						<h1 class="order-gray__title_bold text-align-center">{{ currentlyEdittedTask.product.Product_Name }}</h1>
						<!-- <h1 class="order-gray__title_bold text-align-center" *ngIf="currentlyEdittedTask.likut.LikutExtra1 && currentlyEdittedTask.likut.LikutExtra1 !== '0'">
						{{ currentlyEdittedTask.likut.LikutExtra1 }}
					</h1> -->
						<div style="display: flex; justify-content: space-between">
							<div class="product-detail-popup-column">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityOrdered[LikutType] }}
								</div>
								<h3 *ngSwitchCase="'0'">
									{{ currentlyEdittedTask.truckTask.Quantity }}
								</h3>
								<h3 *ngSwitchCase="'1'">
									{{ currentlyEdittedTask.truckTask.Quantity }}
								</h3>
								<h3 *ngSwitchCase="'2'">
									{{ currentlyEdittedTask.truckTask.PackQTY }}
								</h3>
							</div>

							<div class="product-detail-popup-column">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityCollected[LikutType] }}
								</div>
								<div class="flex gap justify-align-center align-center">
									<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
										<button
											class="order-product__item-info-count-button order-product__item-info-count-button_add"
											type="button"
											(click)="handleQuantityButtonPress('increment', LikutType !== '2' ? 'single' : 'pack')"
										></button>
										<input
											formControlName="collected"
											class="product-detail-popup-column-quantity"
											inputmode="numeric"
											(keyup)="handleIfBackspaceOnEmptyInput($event)"
											#collected
											*ngIf="LikutType !== '2'; else truckCollectedPacksBlock"
											[placeholder]="currentlyEdittedTask.truckTask.PODQuantity || 0"
											[ngClass]="{
												'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTask.truckTask.ProductID + ' - ' + currentlyEdittedTask.truckTask.DocNum]
											}"
										/>
										<ng-template #truckCollectedPacksBlock>
											<input
												formControlName="collectedPacks"
												class="product-detail-popup-column-quantity"
												inputmode="numeric"
												(keyup)="handleIfBackspaceOnEmptyInput($event)"
												#collectedPacks
												[placeholder]="currentlyEdittedTask.truckTask.PODPackQTY"
												[ngClass]="{
													'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTask.truckTask.ProductID + ' - ' + currentlyEdittedTask.truckTask.DocNum]
												}"
											/>
										</ng-template>
										<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement', LikutType !== '2' ? 'single' : 'pack')"></button>
									</form>
								</div>
							</div>
						</div>
						<div style="display: flex; justify-content: space-between" *ngIf="worksInBothSinglesAndPackQuantity">
							<div class="product-detail-popup-column text-align-center">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityOrdered['0-2'] }}
								</div>

								{{ currentlyEdittedTask.truckTask.PackQTY }}
							</div>

							<div class="product-detail-popup-column">
								<div class="filter-sort__row justify-align-center">
									{{ _text.store.quantityCollected['0-2'] }}
								</div>
								<div class="flex gap justify-align-center align-center">
									<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
										<button
											class="order-product__item-info-count-button order-product__item-info-count-button_add"
											type="button"
											(click)="handleQuantityButtonPress('increment', 'pack')"
										></button>
										<input
											formControlName="collectedPacks"
											class="product-detail-popup-column-quantity"
											inputmode="numeric"
											(keyup)="handleIfBackspaceOnEmptyInput($event)"
											#collectedPacks
											[placeholder]="currentlyEdittedTask.truckTask.PODPackQTY"
											[ngClass]="{
												'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTask.truckTask.ProductID + ' - ' + currentlyEdittedTask.truckTask.DocNum]
											}"
										/>
										<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement', 'pack')"></button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-conatiner>
		</div>
	</div>
	<div class="popup" *ngIf="isLikutMekabelPopupShown$ | async" style="z-index: 555">
		<div class="overlay" (click)="handlePopupClose()"></div>
		<div class="product-detail-popup popup__content">
			<div class="popup__close" (click)="handlePopupClose()"></div>
			<ng-conatiner [ngSwitch]="LikutType" *ngIf="isProductWithLikutMekabel(currentlyEdittedTaskLikutMekabel)">
				<div class="product-detail__wrapper">
					<div class="product-detail__item">
						<h1 class="order-gray__title_bold text-align-center position-relative">
							<div class="filter-sort__row filter-sort__row_double content-button" style="background: var(--invalid-color); color: var(--secondary-color)" *ngIf="isNewTask">
								<div class="filter-sort__row-block" style="margin: 0" (click)="productToBeDeleted$.next(currentlyEdittedTaskLikutMekabel)">
									<span class="filter-sort__row-name">מחק פריט</span>
								</div>
							</div>
							{{ _text.store.quantityUpdateTitle }}
						</h1>
						<h1 class="order-gray__title_bold text-align-center">{{ currentlyEdittedTaskLikutMekabel.likut.ProductName }}</h1>

						<ng-container *ngIf="!isNewTask; else newTaskQuantitiesBlock">
							<div style="display: flex; justify-content: space-between">
								<div class="product-detail-popup-column">
									<div class="filter-sort__row justify-align-center">
										{{ _text.store.quantityOrdered[LikutType] }}
									</div>
									<h3 *ngSwitchCase="'0'">
										{{ currentlyEdittedTaskLikutMekabel.likut.TeudaQty }}
									</h3>
									<h3 *ngSwitchCase="'1'">
										{{ currentlyEdittedTaskLikutMekabel.likut.TeudaQty }}
									</h3>
									<h3 *ngSwitchCase="'2'">
										{{ currentlyEdittedTaskLikutMekabel.likut.TeudaPackQty }}
									</h3>
								</div>
								<div class="product-detail-popup-column">
									<div class="filter-sort__row justify-align-center">
										{{ _text.store.quantityCollected[LikutType] }}
									</div>
									<div class="flex gap justify-align-center align-center">
										<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												type="button"
												(click)="handleQuantityButtonPress('increment')"
											></button>
											<input
												formControlName="collected"
												class="product-detail-popup-column-quantity"
												inputmode="numeric"
												(keyup)="handleIfBackspaceOnEmptyInput($event)"
												#collected
												[placeholder]="
													worksInBothSinglesAndPackQuantity
														? currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala || 0
														: currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala || currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala || 0
												"
												[ngClass]="{ 'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTaskLikutMekabel.likut.ProductId] }"
											/>
											<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement')"></button>
										</form>
									</div>
								</div>
							</div>
							<div style="display: flex; justify-content: space-between" *ngIf="worksInBothSinglesAndPackQuantity">
								<div class="product-detail-popup-column text-align-center">
									<div class="filter-sort__row justify-align-center">
										{{ _text.store.quantityOrdered['0-2'] }}
									</div>

									<h3>{{ currentlyEdittedTaskLikutMekabel.likut.TeudaPackQty }}</h3>
								</div>

								<div class="product-detail-popup-column">
									<div class="filter-sort__row justify-align-center">
										{{ _text.store.quantityCollected['0-2'] }}
									</div>
									<div class="flex gap justify-align-center align-center">
										<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												type="button"
												(click)="handleQuantityButtonPress('increment', 'pack')"
											></button>
											<input
												formControlName="collectedPacks"
												class="product-detail-popup-column-quantity"
												inputmode="numeric"
												(keyup)="handleIfBackspaceOnEmptyInput($event)"
												#collectedPacks
												[placeholder]="currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala"
												[ngClass]="{ 'product-detail-popup-column-quantity-equal': validQuantitiesState[currentlyEdittedTaskLikutMekabel.likut.ProductId] }"
											/>
											<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement', 'pack')"></button>
										</form>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-template #newTaskQuantitiesBlock>
							<div style="display: flex; justify-content: space-between">
								<div class="product-detail-popup-column">
									<div class="filter-sort__row justify-align-center">
										{{ _text.store.quantityCollected[LikutType] }}
									</div>
									<div class="flex gap justify-align-center align-center">
										<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												type="button"
												(click)="handleQuantityButtonPress('increment')"
											></button>
											<input
												formControlName="collected"
												class="product-detail-popup-column-quantity"
												inputmode="numeric"
												(keyup)="handleIfBackspaceOnEmptyInput($event)"
												#collected
												[placeholder]="
													worksInBothSinglesAndPackQuantity
														? currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala || 0
														: currentlyEdittedTaskLikutMekabel.likut.TeudaQtyKabala || currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala || 0
												"
											/>
											<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement')"></button>
										</form>
									</div>
								</div>
								<div class="product-detail-popup-column" *ngIf="worksInBothSinglesAndPackQuantity">
									<div class="filter-sort__row justify-align-center">
										{{ _text.store.quantityCollected['0-2'] }}
									</div>
									<div class="flex gap justify-align-center align-center">
										<form class="flex justify-between align-center gap" [formGroup]="form" (ngSubmit)="handlePopupClose()">
											<button
												class="order-product__item-info-count-button order-product__item-info-count-button_add"
												type="button"
												(click)="handleQuantityButtonPress('increment', 'pack')"
											></button>
											<input
												formControlName="collectedPacks"
												class="product-detail-popup-column-quantity"
												inputmode="numeric"
												(keyup)="handleIfBackspaceOnEmptyInput($event)"
												#collectedPacks
												[placeholder]="currentlyEdittedTaskLikutMekabel.likut.TeudaPackQtyKabala"
											/>
											<button class="order-product__item-info-count-button" type="button" (click)="handleQuantityButtonPress('decrement', 'pack')"></button>
										</form>
									</div>
								</div>
							</div>
						</ng-template>
						<div style="display: flex; justify-content: space-between">
							<div class="product-detail-popup-column">
								<div class="flex gap justify-align-center align-center">
									<form class="flex justify-between align-center flex-column" [formGroup]="form" (ngSubmit)="handlePopupClose()">
										<label for="discount">{{ _text.store.quantityCollected['0-3'] }}</label>
										<input
											formControlName="discount"
											id="discount"
											class="product-detail-popup-column-quantity"
											inputmode="numeric"
											(keyup)="handleIfBackspaceOnEmptyInput($event)"
											#discount
											style="width: 100%"
											[placeholder]="currentlyEdittedTaskLikutMekabel.likut.DiscountOut ?? currentlyEdittedTaskLikutMekabel.likut.Discount"
										/>
									</form>
								</div>
							</div>

							<div class="product-detail-popup-column">
								<div class="flex gap justify-align-center align-center">
									<form class="flex justify-between align-center flex-column" [formGroup]="form" (ngSubmit)="handlePopupClose()">
										<label for="price">{{ _text.store.quantityCollected['0-4'] }}</label>
										<input
											formControlName="price"
											id="price"
											class="product-detail-popup-column-quantity"
											inputmode="numeric"
											(keyup)="handleIfBackspaceOnEmptyInput($event)"
											#price
											style="width: 100%"
											[placeholder]="currentlyEdittedTaskLikutMekabel.likut.PriceOut ?? currentlyEdittedTaskLikutMekabel.likut.Price"
										/>
									</form>
								</div>
							</div>
						</div>
						<div style="display: flex; justify-content: space-between">
							<form class="flex justify-between align-center flex-column" style="width: 100%" [formGroup]="form" (ngSubmit)="handlePopupClose()">
								<h1 class="order-gray__title_bold text-align-center">איתור</h1>
								<textarea
									formControlName="eitur"
									id="eitur"
									class="product-detail-popup-column-quantity"
									(keydown)="enforceMaxTextLength($event, 'eitur')"
									appFitHeightToContent
									#eitur
									style="width: 100%"
									[placeholder]="currentlyEdittedTaskLikutMekabel.likut.Eitur || ''"
								></textarea>
							</form>
						</div>
						<div style="display: flex; justify-content: space-between">
							<form class="flex justify-between align-center flex-column" style="width: 100%" [formGroup]="form" (ngSubmit)="handlePopupClose()">
								<h1 class="order-gray__title_bold text-align-center">הערה</h1>
								<textarea
									data-expandable
									formControlName="remark"
									id="remark"
									class="product-detail-popup-column-quantity"
									(keydown)="enforceMaxTextLength($event, 'remark')"
									#remark
									appFitHeightToContent
									style="width: 100%"
									[placeholder]="currentlyEdittedTaskLikutMekabel.likut.ItemRemark || ''"
								></textarea>
							</form>
						</div>
					</div>
				</div>
			</ng-conatiner>
		</div>
	</div>

	<!-- main menu markup -->
	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img src="{{ _ss.CustomerInformation?.CustImageDomain }}/companylogo.jpg" crossorigin alt="mobility" crossorigin appFallbackSrc />
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ _ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ _ss.AgentIdConnected }}</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="handleMelaketGoBack()">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/burger/icon-statistic.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">{{ _text.store.mainTasks }}</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__row">
				<div class="main-menu__col" (click)="handleMelaketGoBack('home')">
					<div class="main-menu__col-icon image">
						<img src="/assets/images/arrow-back.svg" alt="icon" />
					</div>
					<div class="main-menu__col-title">{{ _text.store.homepage }}</div>
				</div>
			</div>

			<div class="main-menu__border"></div>
			<div class="main-menu__col">
				<div class="main-menu__col-title">{{ _ss.VersionNumber }} - {{ Admin }} - {{ _ss.CustomerInformation.CustName }}</div>
			</div>
		</div>
	</div>
	<!-- end main menu markup -->
</div>

<!-- end markup for popup -->
<div class="popup" *ngIf="isAddNewProductForMekabelPopupOpen$ | async" style="z-index: 555">
	<div class="overlay" (click)="isAddNewProductForMekabelPopupOpen$.next(false)"></div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="isAddNewProductForMekabelPopupOpen$.next(false)" style="width: 30px; height: 30px; background-color: black"></div>

		<div class="product-detail__wrapper2 flex flex-column" dir="rtl" style="padding: 1rem 0.2rem; gap: 2rem">
			<h2>בחר פריט</h2>
			<mat-form-field class="example-full-width override-bidi" appearance="fill" style="width: 100%">
				<mat-label>פריט</mat-label>
				<input type="text" matInput [formControl]="addProductFormControl" [matAutocomplete]="auto" />
				<button mat-icon-button matSuffix (click)="openScannerForAddNewProductPopup($event)" [attr.aria-label]="'Open Scanner'">
					<img src="./assets/images/header/barcode-icon.svg" matSuffix />
				</button>
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnProduct" (optionSelected)="handleRouteSelectNewProductForMekabelOrMelaket($event)">
					<mat-option *ngFor="let product of addProductSearch$ | async" [value]="product" style="direction: rtl">
						<span style="unicode-bidi: isolate">{{ product.Product_Name }}</span>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<div class="order-cart__popup-block row text-align-center" style="margin: 0">
				<div class="order-cart__popup-button invalid" (click)="isAddNewProductForMekabelPopupOpen$.next(false)">סגור</div>
			</div>
		</div>
	</div>
</div>
<div class="popup" *ngIf="isBarcodePopupOpen$ | async" style="z-index: 555">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="handleBarcodePopupClose()" style="width: 30px; height: 30px; background-color: black"></div>

		<div class="product-detail__wrapper2 custom-scrollbar">
			<video #videoElement id="video" width="100%" height="80%"></video>
		</div>
	</div>
</div>
<div class="popup" *ngIf="isCheckerReturnPopupOpen$ | async" style="z-index: 555">
	<div class="overlay" (click)="isCheckerReturnPopupOpen$.next(false)"></div>
	<div class="product-detail-popup popup__content">
		<div class="popup__close" (click)="isCheckerReturnPopupOpen$.next(false)" style="width: 30px; height: 30px; background-color: black"></div>

		<div class="product-detail__wrapper2 flex flex-column" style="padding: 3rem 3rem 0; gap: 3rem">
			<div class="order-gray__title order-gray__title_bold">{{ _text.store.returnTaskConfirmation }}</div>
			<div class="order-cart__popup-block row text-align-center">
				<div class="order-cart__popup-button invalid" (click)="isCheckerReturnPopupOpen$.next(false)">{{ _text.store.declineReturn }}</div>
				<div class="order-cart__popup-button" (click)="handleSendLikutBackToMelaket()">{{ _text.store.confirmReturn }}</div>
			</div>
		</div>
	</div>
</div>

<div class="popup" *ngIf="isDeleteProductPopupShown$ | async" style="z-index: 555">
	<ng-container *ngIf="productToBeDeleted$ | async as productForDeletion">
		<div class="overlay" (click)="productToBeDeleted$.next(null)"></div>
		<div class="product-detail-popup popup__content">
			<div class="popup__close" (click)="productToBeDeleted$.next(null)" style="width: 30px; height: 30px; background-color: black"></div>

			<div class="product-detail__wrapper2 flex flex-column" style="padding: 3rem 3rem 0; gap: 3rem">
				<div class="order-gray__title order-gray__title_bold">האם אתה בטוח שתרצה למחוק פריט {{ productForDeletion.likut.ProductName }} ?</div>
				<div class="order-cart__popup-block row text-align-center">
					<div class="order-cart__popup-button" style="background: lightgray" (click)="productToBeDeleted$.next(null)">{{ _text.store.declineReturn }}</div>
					<div class="order-cart__popup-button invalid" (click)="handleDeleteLikutMekabelProduct(productForDeletion)">מחק פריט</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="melaketProductToBeDeleted$ | async as productForDeletion">
		<div class="overlay" (click)="productToBeDeleted$.next(null)"></div>
		<div class="product-detail-popup popup__content">
			<div class="popup__close" (click)="productToBeDeleted$.next(null)" style="width: 30px; height: 30px; background-color: black"></div>

			<div class="product-detail__wrapper2 flex flex-column" style="padding: 3rem 3rem 0; gap: 3rem">
				<div class="order-gray__title order-gray__title_bold">האם אתה בטוח שתרצה למחוק פריט {{ productForDeletion.likut.ProductName }} ?</div>
				<div class="order-cart__popup-block row text-align-center">
					<div class="order-cart__popup-button" style="background: lightgray" (click)="productToBeDeleted$.next(null)">{{ _text.store.declineReturn }}</div>
					<div class="order-cart__popup-button invalid" (click)="handleDeleteLikutMelaketProduct(productForDeletion)">מחק פריט</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<app-menu-button [shouldChangeToPositionInitial]="isEditQuantityPopupShown$ | async"></app-menu-button>
