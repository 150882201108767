import { Component, OnInit, HostListener, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { compress, decompress } from 'lz-string';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, of, Subject, Subscription, takeUntil, throwError } from 'rxjs';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { ToNormalNumberPipe } from 'src/app/Pipes/to-normal-number.pipe';
import { Time, UtilService } from 'src/app/services/util.service';
import { MsgType } from 'src/app/Interfaces/alerts';
import { AlertService } from 'src/app/services/alert.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TextService } from 'src/app/services/text.service';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { AllClientsLocal } from 'src/app/Interfaces/clients';
import { B2bService } from 'src/app/services/b2b.service';
import { environment } from 'src/environments/environment';
import { normalizeNumber, normalizeNumberToMaxThreeDecimal } from 'src/app/Utility/functions';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppStyleService } from 'src/app/services/app-style.service';
import { Special, SpecialInCartObj } from 'src/app/Interfaces/special';
import { OrderState, Product } from 'src/app/Interfaces/all-products';
import ChangedDate from 'src/app/Helpers/changedDate';
import { CreditOrderDoctypes } from 'src/app/Interfaces/credit-payment';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { DocMinTeudaService, MinDocTeudaBaseWithActionAndDoctype } from 'src/app/services/doc-min-teuda.service';
import { DoctypeService } from 'src/app/services/doctype.service';
import {
	ALL_CLIENTSIDE_ORDER_DOCTYPES,
	DEFAULT_DOCTYPE_BY_TYPE,
	DEFAULT_ORDER_DOCTYPE,
	DEFAULT_RETURN_DOCTYPE,
	DEFAULT_VIRTUAL_DOCTYPE,
	DOCTYPE_DEFAULT_NAME,
	RECLAMATION_RALLYANCE_DOCTYPE,
} from 'src/app/Utility/constants-doctype';
import { DeviceInfoService, TABLET_RESOLUTION_CUTOFF } from 'src/app/services/device-info.service';
import { ReclamationService } from 'src/app/services/reclamation.service';
import { MustIncludeProductsService } from 'src/app/services/must-include-products.service';
import { MatDialog } from '@angular/material/dialog';

export const TEUDA_TYPES = [
	DEFAULT_VIRTUAL_DOCTYPE.toString(),
	DEFAULT_RETURN_DOCTYPE.toString(),
	...Array.from(ALL_CLIENTSIDE_ORDER_DOCTYPES).map(d => d.toString()),
	'89',
	'90',
	'91',
	'92',
	'777',
	...new Array(CreditOrderDoctypes.max - CreditOrderDoctypes.min + 1).fill(0).map((_, i) => (i + CreditOrderDoctypes.min).toString()),
];

type TeudotStat = { all: number; orders: number; returns: number };

@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit, OnDestroy {
	constructor(
		private _docMin: DocMinTeudaService,
		public _ss: StoreService,
		public router: Router,
		public _device: DeviceInfoService,
		public _fb: FormBuilder,
		public _receipts: ReceiptsService,
		private _util: UtilService,
		private _alert: AlertService,
		private _text: TextService,
		public _b2b: B2bService,
		private _user: UserService,
		private _storage: StorageService,
		private _style: AppStyleService,
		private _cc: CreditCardService,
		public _doctype: DoctypeService,
		private _reclamation: ReclamationService,
		private _mustIncludeProducts: MustIncludeProductsService,
		private dialog: MatDialog,
	) {}

	readonly form = this._fb.group({
		ClientSearch: ['', Validators.required],
		CitySearch: ['', Validators.required],
		MaslulDate: ['', Validators.required],
	});
	AllClients: AllClientsLocal[] = [];
	AllClientsSearch;

	logo = MOBILITY_LOGO;

	AllProducts;
	Loading = false;

	readonly Date = new Date();

	Status;
	SearchErrorPop = false;
	OrderTypeList = false;
	shopFilterPop = false;

	HasMultipleCompanys;
	online;

	CustCity = [];
	CustCitySearch;

	Datetwo;

	datedate = '';
	datetime = '';

	showSortPopupSanen = false;

	CityCheckedArray = [];

	AllOpenTeudaArray = [];

	AAABBB = [];

	allSpecials = this._ss.allSpecials;
	allSpecials$ = new BehaviorSubject<Special[]>(this.allSpecials);
	specialsInCart: SpecialInCartObj = {};

	hasSpecials = ['1', '2', '3'].includes(this._ss.CustomerInformation?.HasSpecials);

	productsHash: { [key: string]: Product } = {};

	DateUpdate = new Date();

	Datee;

	Admin = localStorage.getItem('Admin') || undefined;

	isCatalogAgent = false;

	NavigateToClientSection = null;

	ShowSapakim = false;

	AllDocTypesSave;

	ShirionProducts;

	AllProductsPricess;

	SpecPriceSs;

	LoadingClient = false;

	ManualUpdate = localStorage.getItem('ManualUpdate') ?? 'false';
	Updating = localStorage.getItem('Updating') ?? 'false';

	readonly windowWidth = innerWidth;

	WorkOnLineShow = localStorage.getItem('work') !== null;
	WorkOfflineShow = !this.WorkOnLineShow;
	ConfirmOffline = false;

	PendingBikor;
	FinishBikor;
	ShowKnisa = false;
	ShowExit = false;

	shouldClearCitySortButtonAppear = false;

	bizoimOfMonth = '';
	bizoimReflect = '';

	isDuplicateOrderPopupShowing = false;

	readonly destroy$ = new Subject<void>();

	readonly totalOpenTeudot: { count: TeudotStat; sum: TeudotStat } = {
		count: { all: 0, orders: 0, returns: 0 },
		sum: { all: 0, orders: 0, returns: 0 },
	};

	isOpenTeudotMenuOpen = false;
	today = new Date();

	readonly itemSize = this.windowWidth > TABLET_RESOLUTION_CUTOFF ? 228.6 : 149;

	readonly failedTeudotArray = [];

	readonly alternativeProductCalculations = this._ss.CustomerInformation?.CanChangePermPrice === '1';

	readonly SearchExtraTeudaTypeFromSave = this._ss.CustomerInformation?.SearchExtraTeudaTypeFromSave;
	readonly hasSendAllTeudot = this._ss.CustomerInformation?.HideSendAll !== '1';

	location:
		| string
		| {
				latitude: number;
				longitude: number;
		  } = '';
	position: GeolocationPosition | null = null;
	toggle = false;
	currentPositionUrl: SafeResourceUrl | null = null;
	watchSubscription: Subscription | null = null;
	error: GeolocationPositionError | null = null;

	canSeePrices = true;
	hasMinDocTeuda = false;

	readonly blockBeforeSendTeudaIfObligoWarning = this._ss.CustomerInformation?.BlockClientTeudaSend === '1';

	showingOnlyMaslul = false;

	readonly hasVisitsNoSale = this._ss.CustomerInformation?.VisitsHasNoSaleOption === '1' && (this._ss.CustomerInformation.CustChange1 === '1' || this._ss.CustomerInformation.CustChange1 === '2');

	readonly isSuppliersPage$ = new BehaviorSubject(location.pathname.includes('suppliers'));

	//template
	readonly hasBikurIcon = this._ss.CustomerInformation?.ShowVisitInClientsPage === '1';
	readonly TotalOrderOverride: string = this._ss.CustomerInformation?.TotalOrderOverride;

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	async ngOnInit() {
		if (!this._storage.getAndParseFromStorage('AllClients', { onlyCheckIfExist: true })) {
			alert('אנא עדכן נתונים');
			this.router.navigate(['home']);
			return;
		}

		if (this._ss.CompanyNum === null) {
			this._ss.CompanyNum = 1;
		}

		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (!this._ss.CustomerInformation && this._storage.getAndParseFromStorage('CustomerInformation', { onlyCheckIfExist: true })) {
			this._ss.CustomerInformation = this._storage.getAndParseFromStorage('CustomerInformation');
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////

		this._receipts.refreshReceiptsParameters();
		this._docMin.refresh();
		this.hasMinDocTeuda = this._docMin.hasMinDocTeuda;

		this.online = window.navigator.onLine;

		if (!localStorage.getItem('UserName') && !localStorage.getItem('AgentId')) {
			this.router.navigate(['login']);
			return;
		}

		if (localStorage.getItem('Updating') === null) {
			const ls = localStorage.getItem('SpecPriceSs');
			const ls2 = localStorage.getItem('AllProductsPricess');

			if (ls !== null && ls2 !== null && this._ss.AllProductsPricess === null && this._ss.AllProductsInfo === null) {
				this._ss.AllProductsInfo = JSON.parse(decompress(ls));
				this._ss.AllProductsPricess = JSON.parse(decompress(ls2));
			}

			if (!this._ss.FilterMigvan?.length) {
				this._ss.FilterMigvan = [];

				const ls3 = localStorage.getItem('_ss.FilterMigvan');

				if (ls3 !== null) {
					this._ss.FilterMigvan = JSON.parse(decompress(ls3));
				}
			}

			this._ss.Mehironim = [];

			const ls4 = localStorage.getItem('_ss.Mehironim');

			if (ls4 !== null) {
				this._ss.Mehironim = JSON.parse(decompress(ls4));
			}

			const ls5 = localStorage.getItem('AllProducts');

			if (ls5 !== null) {
				this._ss.ProductCompany = JSON.parse(decompress(ls5));
			}
		}

		if ('AllDocTypesSave' in localStorage) {
			this.AllDocTypesSave = JSON.parse(decompress(localStorage.getItem('AllDocTypesSave')));
			this._doctype.buildDoctypeNamesRecord(this.AllDocTypesSave);
		}

		if (!this._doctype.doctypesDefinitions.length && 'DocTypesDefinitions' in localStorage) {
			const doctypesDefinitions = localStorage.getItem('DocTypesDefinitions');

			if (doctypesDefinitions) {
				this._doctype.doctypesDefinitions = JSON.parse(decompress(doctypesDefinitions));
				this._doctype.buildDoctypesDefinitions();
			}
		}

		if (this.Admin && (this.Admin as string).toLowerCase().includes('catalog')) {
			this.isCatalogAgent = true;
		}

		if (this._b2b.isB2BAgent$.value) {
			this._ss.AutoMoveHovot = 1;
			this.canSeePrices = this._ss.CustomerInformation.B2BCanSeePrices !== '1';
			if (this._b2b.isB2BAgentNoPricesAndNoParameters$.value) {
				this.canSeePrices = false;
			}
		}

		this._ss.CustNum = localStorage.getItem('CustNum') ?? this._ss.CustNum;
		if (this._ss.CustNum === '11111') {
			this.ShowSapakim = true;
		}

		this.PendingBikor = [];
		if (localStorage.getItem('PendingBikor') !== null) {
			this.PendingBikor = localStorage.getItem('PendingBikor');
			this.PendingBikor = JSON.parse(this.PendingBikor);

			if (this.PendingBikor.length > 0) {
				this.ShowKnisa = true;
			}
		}

		this.FinishBikor = [];
		if (localStorage.getItem('FinishBikor') !== null) {
			this.FinishBikor = localStorage.getItem('FinishBikor');
			this.FinishBikor = JSON.parse(this.FinishBikor);

			if (this.FinishBikor.length > 0) {
				this.ShowExit = true;
			}
		}

		this.online = window.navigator.onLine;

		const { AddToSupplyDate } = this._ss.CustomerInformation;

		if (AddToSupplyDate && !/\D/.test(AddToSupplyDate)) {
			const time = Intl.DateTimeFormat('en-IL', { hour: '2-digit', minute: 'numeric', second: 'numeric' }).format(new Date()).slice(0, 2);
			let toSubtract = 0;

			if (+time >= 0 && +time < 6) {
				toSubtract = 1;
			}

			this.SupplyDateTeuda = new Date(this.Date.getTime() + 1000 * 60 * 60 * 24 * (AddToSupplyDate - toSubtract)).toISOString().slice(0, 10);
		}

		if (localStorage.getItem('lastupdate') === null) {
			this.router.navigate(['home']);
			return;
		}

		console.log('after ls check', performance.now());

		this.Datetwo = JSON.parse(localStorage.getItem('lastupdate'));
		this.datedate = this.Date.toString().slice(0, 10);
		this.datetime = this.Date.toString().slice(11, 20);

		if (this.Datetwo?.toString().slice(0, 10) !== this.Date.toISOString().toString().slice(0, 10)) {
			this.router.navigate(['home']);
			return;
		}

		if (localStorage.getItem('Updating') === null && this.online && localStorage.getItem('refresh') !== null) {
			this.Datee = localStorage.getItem('refresh');
			this.Datee = JSON.parse(this.Datee);

			if (this.Datee.toString().slice(0, 13) !== this.DateUpdate.toISOString().toString().slice(0, 13)) {
				localStorage.setItem('refresh', JSON.stringify(this.Date.toISOString()));

				if (this._ss.CustomerInformation.CustChangeCss6 === '1') {
					localStorage.setItem('lastupdate', '0');
					localStorage.setItem('ManualUpdate', 'true');
				}

				// window.location.reload()
			}
		}

		this.cleanupForOtherPages();

		this._ss.UserNameConnected = localStorage.getItem('UserName');
		this._ss.AgentIdConnected = localStorage.getItem('AgentId');

		this.HasMultipleCompanys = localStorage.getItem('HasMultipleCompanys');

		this.AllClientsSearch = decompress(localStorage.getItem('AllClients'));
		this.AllClientsSearch = JSON.parse(this.AllClientsSearch);

		if (this.isSuppliersPage$.value) {
			this.AllClientsSearch = this.AllClientsSearch.filter(client => client.CE4 && client.CE4.toLowerCase().includes('supplier'));
		} else {
			if (this._ss.CustomerInformation.Suppliers) {
				const { Suppliers } = this._ss.CustomerInformation;

				if (['1', '2'].includes(Suppliers)) {
					this.AllClientsSearch = this.AllClientsSearch.filter(ac => !ac.CE4 || !ac.CE4.toLowerCase().includes('supplier'));
				}
			}
		}

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			const client = this.AllClientsSearch[x];
			client.Azmana = false;
			client.Ahzara = false;
			client.Azaha = false;
			client.Knisa = 'false';
			client.Exit = 'false';
		}

		if (this.HasMultipleCompanys === '2') {
			if (this._ss.AllClientsTwoCompanys.length === 0) {
				this.router.navigate(['home']);
				return;
			}

			this.AllClientsSearch = this._ss.AllClientsTwoCompanys;

			if (this.isSuppliersPage$.value) {
				this.AllClientsSearch = this.AllClientsSearch.filter(client => client.CE4 && client.CE4.toLowerCase().includes('supplier'));
			} else {
				if (this._ss.CustomerInformation.Suppliers) {
					const { Suppliers } = this._ss.CustomerInformation;

					if (['1', '2'].includes(Suppliers)) {
						this.AllClientsSearch = this.AllClientsSearch.filter(ac => !ac.CE4 || !ac.CE4.toLowerCase().includes('supplier'));
					}
				}
			}

			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				this.AllClientsSearch[x].Azmana = false;
				this.AllClientsSearch[x].Ahzara = false;
				this.AllClientsSearch[x].Azaha = false;
				this.AllClientsSearch[x].Knisa = 'false';
				this.AllClientsSearch[x].Exit = 'false';
			}
		}

		if (this.AllClientsSearch === undefined || this.AllClientsSearch === 'undefined' || this.AllClientsSearch === null) {
			this.router.navigate(['home']);
			return;
		}

		if (this._ss.AllClientsHold !== null && !this.isSuppliersPage$.value) {
			for (let x = 0; x < this._ss.AllClientsHold.length; x++) {
				this._ss.AllClientsHold[x].Azmana = false;
				this._ss.AllClientsHold[x].Ahzara = false;
				this._ss.AllClientsHold[x].Azaha = false;
				this._ss.AllClientsHold[x].Knisa = 'false';
				this._ss.AllClientsHold[x].Exit = 'false';
			}

			for (let x = 0; x < this._ss.AllClientsHold.length; x++) {
				for (let y = 0; y < this.PendingBikor.length; y++) {
					if (this.PendingBikor[y].Knisa === this._ss.AllClientsHold[x].ClientId) {
						this._ss.AllClientsHold[x].Knisa = 'true';
						this.ShowKnisa = true;
					}
				}
				for (let y = 0; y < this.FinishBikor.length; y++) {
					if (this.FinishBikor[y].Exit === this._ss.AllClientsHold[x].ClientId) {
						this._ss.AllClientsHold[x].Exit = 'true';
					}
				}

				this.AllClients.push(this._ss.AllClientsHold[x]);
			}
		} else {
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				for (let y = 0; y < this.PendingBikor.length; y++) {
					if (this.PendingBikor[y].Knisa === this.AllClientsSearch[x].ClientId) {
						this.AllClientsSearch[x].Knisa = 'true';
						this.ShowKnisa = true;
					}
				}
				for (let y = 0; y < this.FinishBikor.length; y++) {
					if (this.FinishBikor[y].Exit === this.AllClientsSearch[x].ClientId) {
						this.AllClientsSearch[x].Exit = 'true';
					}
				}

				this.AllClients.push(this.AllClientsSearch[x]);
			}
		}

		setTimeout(() => {
			const selectedIndex = this.AllClients.findIndex(elem => elem.ClientId === this._ss.ClientForRETURN);
			if (this.virtualScroll && selectedIndex > -1) {
				this.virtualScroll.scrollToIndex(selectedIndex);
			}
			this.GeneralFunction();
		}, 101);

		const citySet = new Set();

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].CustCity) {
				citySet.add(this.AllClientsSearch[x].CustCity);
			}
		}

		console.log(this.AllClientsSearch);

		this.CustCitySearch = this.CustCity = [...citySet];

		if (this._ss.CustomerInformation.SortCities === '1') {
			const collator = new Intl.Collator('he', { sensitivity: 'base' });
			this.CustCitySearch.sort(collator.compare);
		}
		this.form.controls.CitySearch.valueChanges.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(val => {
			this.CustCity = val
				? this.CustCitySearch.filter(city => city.toLowerCase().includes(val.toLowerCase()))
				: [...this.CustCitySearch].sort(a => (this.CityCheckedArray.map(c => c.City).includes(a) ? -1 : 1));

			setTimeout(() => {
				for (let x = 0; x < this.CityCheckedArray.length; x++) {
					if (document.getElementById(this.CityCheckedArray[x].City) !== null) {
						document.getElementById(this.CityCheckedArray[x].City).classList.add('city-check-checked');
					}
				}
			}, 150);
		});

		if (this.Updating !== 'false') {
			await this.updateAllLocalStorage();
			return;
		}
		this._alert.handleAlertLogic(MsgType.afterUpdateLocal.msgType);

		if (this._ss.CustomerInformation.ClientsPageExtraInfo !== '1') {
			this._ss.GetBizoim().subscribe({
				next: (res: any) => {
					if (res.recordset) {
						const bizoim = res.recordset.find(b => b.MM === new Date().getMonth() + 1);
						if (bizoim) {
							this.bizoimOfMonth = bizoim.Tot;
							this.bizoimReflect = bizoim.Tot > bizoim.Ltot ? 'up' : 'down';
						}
					}
				},
			});
		}

		this._style.handleSetCSSVariablesBasedOnCustomerParameters();

		// TODO think of a way to utilize this much much faster algorithm. need to change localstorage allorder keys to use separators ({clientid}-{doc}-{company})
		// const allOrdersInLocalStorage = Object.keys(localStorage).filter(key => key.includes('AllOrder'))

		// for (const order of allOrdersInLocalStorage) {
		// 	const ls = localStorage.getItem(order)
		// }

		let temporaryArrayForExtraTeudaTypesFromCustomerInformation = [];

		if (this.SearchExtraTeudaTypeFromSave?.length) {
			temporaryArrayForExtraTeudaTypesFromCustomerInformation = (this.SearchExtraTeudaTypeFromSave as string).split(',');
		}

		if (this._doctype.doctypesSet.size) {
			const modifiedToString = [...this._doctype.doctypesSet].map(d => d.toString());
			temporaryArrayForExtraTeudaTypesFromCustomerInformation = [...new Set([...temporaryArrayForExtraTeudaTypesFromCustomerInformation, ...modifiedToString])];
		}

		this.totalOpenTeudot.sum.all = 0;

		const allDoctypesToGoOver = [...new Set([...TEUDA_TYPES, ...temporaryArrayForExtraTeudaTypesFromCustomerInformation])];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			const { Client_Name, ClientId, company } = this.AllClientsSearch[x];

			for (const doc of allDoctypesToGoOver) {
				const ls = localStorage.getItem('AllOrder' + ClientId + doc + company);

				if (!ls) continue;

				const parsed = JSON.parse(ls);

				if (this.hasMinDocTeuda) {
					let docMinArray: MinDocTeudaBaseWithActionAndDoctype[] = [];
					docMinArray = this._docMin.getRelevantDocMinTeudaWithoutDoctype(company, ClientId);

					const docMin = docMinArray.find(docMin => docMin.doctype === +doc);

					if (docMin?.minteuda > parsed.reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
						continue;
					}
				}

				this.totalOpenTeudot.count.all += 1;
				let Type = 'הזמנה';

				switch (doc) {
					case '74':
						Type = 'ת. החזרה';
						break;

					case '7':
						Type = 'הצעת מחיר';
						break;

					case '88':
						Type = 'הזמנה משריון';
						break;

					default:
						break;
				}

				if (this._doctype.doesHaveExtraDoctypeDefinitions() && this._doctype.doctypesDefinitions.length) {
					if (this._doctype.typesOfDoctypes.order.has(+doc)) {
						Type = 'הזמנה';
						this.totalOpenTeudot.count.orders++;
						this.totalOpenTeudot.sum.orders += parsed.reduce((a, b) => a + +b.TeudaTotalRow, 0);
					} else if (this._doctype.typesOfDoctypes.return.has(+doc)) {
						Type = 'ת. החזרה';
						this.totalOpenTeudot.count.returns++;
						this.totalOpenTeudot.sum.returns += parsed.reduce((a, b) => a + +b.TeudaTotalRow, 0);
					} else if (this._doctype.typesOfDoctypes.virtual.has(+doc)) {
						Type = 'הצעת מחיר';
						this.totalOpenTeudot.count.orders++;
						this.totalOpenTeudot.sum.orders += parsed.reduce((a, b) => a + +b.TeudaTotalRow, 0);
					}
					if (this._cc.isDoctypeOfCreditTypeOrder(+doc)) continue;
					this.AllOpenTeudaArray.push({
						Client_Name,
						Type,
						ClientId,
						company,
						Status: 'בשליחה..',
						TypeNum: +doc,
					});
					continue;
				}

				if (doc === '74') {
					this.totalOpenTeudot.count.returns++;
					this.totalOpenTeudot.sum.returns += parsed.reduce((a, b) => a + +b.TeudaTotalRow, 0);
				} else {
					this.totalOpenTeudot.count.orders++;
					this.totalOpenTeudot.sum.orders += parsed.reduce((a, b) => a + +b.TeudaTotalRow, 0);
				}

				if (this._cc.isDoctypeOfCreditTypeOrder(+doc)) continue;
				const typeFromDB = this._doctype.getDoctypeName(+doc);
				Type = typeFromDB || Type;
				this.AllOpenTeudaArray.push({
					Client_Name,
					Type,
					ClientId,
					company,
					Status: 'בשליחה..',
					TypeNum: +doc,
				});
			}
		}

		this.totalOpenTeudot.sum.all = this.totalOpenTeudot.sum.orders + -this.totalOpenTeudot.sum.returns;

		if ((this._b2b.isB2BAgent$.value || this.isCatalogAgent) && this.AllClients.length === 1) {
			this.MoveToProductsAzmana(this.AllClients[0].ClientId, this.AllClients[0].company, this.AllClients[0].DocType, this.AllClients[0].DocTypeName);
			return;
		}

		if (this._ss.CustomerInformation.MaslulDefaultClients === '1') {
			this.maslol();
		}
	}

	ngOnDestroy(): void {
		if (this._ss.CustomerInformation?.SpecificCompanyVersion === '1' && this._ss.DocType === 88) {
			// changes for specificcompany aren't ready for other views
			localStorage.setItem('view', 'inline');
		}

		this.destroy$.next();
		this.destroy$.complete();
	}

	cleanupForOtherPages() {
		// Cleanup from products, teuda, clientcard,client-hovot components
		this._ss.cleanupForProductsPage();

		this._mustIncludeProducts.cleanup();

		this._receipts.resetAllState();
	}

	Sapakim() {
		// this.resetOtherFilters();
		this.showHideMenu(false);
		this.AllClients = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].DocType === 17) {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		}

		setTimeout(() => {
			this.GeneralFunction();
		}, 1000);
	}

	KnisaToday() {
		// this.resetOtherFilters();
		this.showHideMenu(false);
		this.AllClients = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].Knisa === 'true') {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		}

		setTimeout(() => {
			this.GeneralFunction();
		}, 1000);
	}

	ExitToday() {
		// this.resetOtherFilters();
		this.showHideMenu(false);
		this.AllClients = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].Exit === 'true') {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		}

		setTimeout(() => {
			this.GeneralFunction();
		}, 1000);
	}

	SentTodayVar;
	SentTodayPop = false;
	SentTodayPopSecond = false;
	SecondOrderSentToday = [];
	SecondOrderSentTodayTwo = [];

	SentToday() {
		this.SentTodayPop = true;

		if (localStorage.getItem('DailyOrder') !== null) {
			this.SentTodayVar = localStorage.getItem('DailyOrder');
			this.SentTodayVar = JSON.parse(this.SentTodayVar);
		}
	}

	SentTodayOne(TeudaNum) {
		this.SecondOrderSentTodayTwo = [];
		this.SecondOrderSentToday = [];
		this.SentTodayPopSecond = true;
		for (let x = 0; x < this.SentTodayVar.length; x++) {
			if (this.SentTodayVar[x].Order[0].TeudaNum === TeudaNum) {
				this.SecondOrderSentToday.push(this.SentTodayVar[x].Order);
				for (let y = 0; y < this.SentTodayVar[x].Order.length; y++) {
					this.SecondOrderSentTodayTwo.push(this.SentTodayVar[x].Order[y]);
				}

				break;
			}
		}
	}

	CloseSentTodayPop() {
		this.SentTodayPop = false;
	}

	CloseSentTodayPopSecondPop() {
		this.SentTodayPopSecond = false;
	}

	MoveToDailyReport(options: { source: 'menu' } | { source: 'card'; client: AllClients }) {
		if (options.source === 'menu') {
			this.router.navigate(['daily-report']);
			return;
		}

		this._ss.AutoMoveAgentOrders = 1;
		this._ss.AutoMoveHovot = 0;
		this._ss.OneClientInfo = options.client;
		this._ss.ClientForRETURN = options.client.ClientId;

		this._ss.AllClientsHold = this.AllClients;
		this.router.navigate(['client-card']);
	}

	MoveToDohot() {
		this.router.navigate(['dohot'], { replaceUrl: true });
	}

	BackToHome() {
		this.router.navigate(['home']);
	}

	async handleCheckForDuplicateCreditOrder(): Promise<'none' | 'exists' | 'error'> {
		if (!this._cc.isDoctypeOfCreditTypeOrder(+this._ss.DocType)) return 'none';

		const isOpenOrder = localStorage.getItem('AllOrder' + this._ss.ClientForRETURN + this._ss.DocType + this._ss.CompanyNum);

		if (!isOpenOrder) return 'none';

		const parsedOrder = JSON.parse(isOpenOrder)[0];

		const doesExistAlready = await new Promise<'none' | 'exists' | 'error'>(resolve => {
			this._ss.CheckConfirmedOrder(parsedOrder.TeudaNum).subscribe({
				next: res => {
					return resolve(typeof res === 'string' || res.recordset.length > 0 ? 'exists' : 'none');
				},
				error: err => {
					resolve('error');
				},
			});
		});

		return doesExistAlready;
	}

	async handleNavigateToProductsPage() {
		const result = await this.handleCheckForDuplicateCreditOrder();

		switch (result) {
			case 'exists':
				localStorage.removeItem('AllOrder' + this._ss.ClientForRETURN + this._ss.DocType + this._ss.CompanyNum);
				const clientID = this._ss.ClientForRETURN;
				this._ss.cleanupForDoctype();
				this._ss.ClientForRETURN = clientID;
				this.LoadingClient = false;
				this.isDuplicateOrderPopupShowing = true;
				break;

			case 'error':
				alert('ישנה תקלת תקשורת, אנא נסה שנית');
				return;
			default:
				break;
		}

		this._alert.handleAlertLogic(MsgType.enterProductsFromHour.msgType);
		this.router.navigate(['products']);
	}

	closeDuplicateOrderPopupAndOpenDoctypeSelect() {
		this.isDuplicateOrderPopupShowing = false;
		this.MoveToProductsAzmana(this._ss.ClientForRETURN, this._ss.CompanyNum, 0, '');
	}

	OpenOrder;
	GeneralFunction() {
		const datetime = Intl.DateTimeFormat('en-IL', {
			dateStyle: 'short',
			timeStyle: 'medium',
			timeZone: 'Israel',
		}).format;

		setTimeout(() => {
			let temporaryArrayForExtraTeudaTypesFromCustomerInformation = [];

			if (this.SearchExtraTeudaTypeFromSave?.length) {
				temporaryArrayForExtraTeudaTypesFromCustomerInformation = (this.SearchExtraTeudaTypeFromSave as string).split(',');
			}
			if (this._doctype.doctypesSet.size) {
				temporaryArrayForExtraTeudaTypesFromCustomerInformation = [...new Set([...temporaryArrayForExtraTeudaTypesFromCustomerInformation, ...this._doctype.doctypesSet])];
			}

			const allDoctypesToGoOver = [...new Set([...TEUDA_TYPES, ...temporaryArrayForExtraTeudaTypesFromCustomerInformation])];
			for (let x = 0; x < this.AllClients.length; x++) {
				const { ClientId, company } = this.AllClients[x];
				const string = 'AllOrder' + ClientId;

				for (const teudaType of allDoctypesToGoOver) {
					const ls = localStorage.getItem(string + teudaType + company);

					if (!ls) continue;
					const parsed: any[] = JSON.parse(ls);

					if (parsed[0].failed) {
						this.AllClients[x].Failed = true;
						this.failedTeudotArray.push(parsed);
					}

					if (this._doctype.doesHaveExtraDoctypeDefinitions() && this._doctype.doctypesDefinitions.length) {
						if (this._doctype.typesOfDoctypes.order.has(+teudaType)) {
							const total = parsed.reduce((p, c) => +p + c.TeudaTotalRow, 0);
							this.AllClients[x].Azmana = datetime(new Date(parsed[0].TeudaCreateDate)) + ' - ' + new ToNormalNumberPipe().transform(total, 0, true);
						} else if (this._doctype.typesOfDoctypes.return.has(+teudaType)) {
							this.AllClients[x].Ahzara = datetime(new Date(parsed[0].TeudaCreateDate));
						} else if (this._doctype.typesOfDoctypes.virtual.has(+teudaType)) {
							this.AllClients[x].Azaha = datetime(new Date(parsed[0].TeudaCreateDate));
						}
						continue;
					}

					if (teudaType === '7') {
						this.AllClients[x].Azaha = datetime(new Date(parsed[0].TeudaCreateDate));
					}

					if (teudaType === '74') {
						this.AllClients[x].Ahzara = datetime(new Date(parsed[0].TeudaCreateDate));
					}

					if (allDoctypesToGoOver.slice(2).includes(teudaType)) {
						const total = parsed.reduce((p, c) => +p + c.TeudaTotalRow, 0);
						this.AllClients[x].Azmana = datetime(new Date(parsed[0].TeudaCreateDate)) + ' - ' + new ToNormalNumberPipe().transform(total, 0, true);
						continue;
					}
				}
			}
		}, 200);
	}

	show: { [key: number]: boolean } = {};

	indexClose = null;

	openSelect(index: number) {
		this.show[index] = !this.show[index];
	}

	openSelectClose(index: number) {
		this.show[index] = false;
	}

	Sanen() {
		this.showSortPopupSanen = true;
		setTimeout(() => {
			document.querySelector('.order-popup.order-popup_sort.popup__content').classList.add('open');
		}, 100);
		const checkedCities = new Set(this.CityCheckedArray.map(c => c.City));
		this.CustCity = [...this.CustCitySearch].sort(city => (checkedCities.has(city) ? -1 : 1));
		setTimeout(() => {
			for (let x = 0; x < this.CityCheckedArray.length; x++) {
				if (document.getElementById(this.CityCheckedArray[x].City) !== null) {
					document.getElementById(this.CityCheckedArray[x].City).classList.add('city-check-checked');
				}
			}
		}, 150);
	}

	CloseSanen() {
		document.querySelector('.order-popup.order-popup_sort.popup__content').classList.remove('open');
		setTimeout(() => {
			this.showSortPopupSanen = false;
		}, 380);

		this.form.controls['CitySearch'].reset();
	}

	filterByLastDateTeuda(range: 30 | 60) {
		// this.resetOtherFilters();
		const dateRange = new Date().getTime() - 1000 * 60 * 60 * 24 * range;
		this.AllClients = this.AllClientsSearch.filter(ac => ac.Ldt && new Date(ac.Ldt.split('-').reverse().join('-')).getTime() < dateRange);

		this.AllClients.sort((a, b) => new Date(b.Ldt.split('-').reverse().join('-')).getTime() - new Date(a.Ldt.split('-').reverse().join('-')).getTime());

		this._ss.AllClientsHoldSearch = range + ' יום';
	}

	CityChecked(City) {
		let data = this.CityCheckedArray.find(ob => ob.City === City);

		if (data === undefined) {
			this.CityCheckedArray.push({ City: City });

			document.getElementById(City).classList.add('city-check-checked');
		} else {
			this.CityCheckedArray.splice(this.CityCheckedArray.indexOf(this.CityCheckedArray.find(ob => ob.City === City)), 1);
			document.getElementById(City).classList.remove('city-check-checked');
		}

		setTimeout(() => {
			this.shouldClearCitySortButtonAppear = !!this.CityCheckedArray.length;
			const clientsArray = this.HovotOn ? this.AllClientsHelpSort : this.AllClientsSearch;
			if (!this.CityCheckedArray.length) {
				this.AllClients = clientsArray;
			} else {
				this.AllClients = [];
				const checkedCities = new Set(this.CityCheckedArray.map(c => c.City));

				for (let y = 0; y < clientsArray.length; y++) {
					const client = clientsArray[y];
					if (checkedCities.has(client.CustCity)) {
						this.AllClients.push(client);
					}
				}
			}
		}, 100);

		setTimeout(() => {
			this.GeneralFunction();
		}, 100);
	}

	Maslul;

	MaslulPickPop = false;

	maslol() {
		this.MaslulPickPop = false;

		this.resetOtherFilters();

		const company = this._ss.CompanyNum;
		const today = this.Date.toISOString().slice(0, 10);
		if (this.form.controls['MaslulDate'].value === '' || this.form.controls['MaslulDate'].value === null) {
			if (localStorage.getItem('Maslul') !== null) {
				this.AllClients = [];
				this.Maslul = decompress(localStorage.getItem('Maslul'));
				this.Maslul = JSON.parse(this.Maslul);

				if (!Array.isArray(this.Maslul)) {
					setTimeout(() => {
						this.GeneralFunction();
					}, 1000);
					return;
				}

				this.Maslul = this.Maslul.filter(m => m.cm === company && m.dt.slice(0, 10) === today);

				const clients = this.AllClientsSearch.reduce((acc, client) => {
					if (this.Maslul.some(m => m.cl === client.ClientId)) {
						acc.push(client);
					}
					return acc;
				}, []);

				this.AllClients = clients;

				// this.showingOnlyMaslul = true;
				setTimeout(() => {
					this.GeneralFunction();
				}, 1000);
			} else {
				this._ss.GetMaslol().subscribe({
					next: res => {
						this.AllClients = [];
						this.Maslul = res;
						this.Maslul = this.Maslul.recordset;

						localStorage.setItem('Maslul', compress(JSON.stringify(this.Maslul)));

						if (!Array.isArray(this.Maslul)) {
							setTimeout(() => {
								this.GeneralFunction();
							}, 1000);
							return;
						}

						this.Maslul = this.Maslul.filter(m => m.cm === company && m.dt.slice(0, 10) === today);

						const clients = this.AllClientsSearch.reduce((acc, client) => {
							if (this.Maslul.some(m => m.cl === client.ClientId)) {
								acc.push(client);
							}
							return acc;
						}, []);

						this.AllClients = clients;

						setTimeout(() => {
							this.GeneralFunction();
						}, 1000);
					},
					error: () => {
						alert('ישנה תקלה בשרת, לא ניתן להציג מסלול');
					},
				});
			}
		} else {
			if (localStorage.getItem('Maslul') !== null) {
				this.AllClients = [];
				this.Maslul = decompress(localStorage.getItem('Maslul'));
				this.Maslul = JSON.parse(this.Maslul);

				const dateValue = this.form.controls['MaslulDate'].value;

				if (!Array.isArray(this.Maslul)) {
					setTimeout(() => {
						this.GeneralFunction();
					}, 1000);
					return;
				}

				this.Maslul = this.Maslul.filter(m => m.cm === company && m.dt.slice(0, 10) === dateValue);

				const clients = this.AllClientsSearch.reduce((acc, client) => {
					if (this.Maslul.some(m => m.cl === client.ClientId)) {
						acc.push(client);
					}
					return acc;
				}, []);

				this.AllClients = clients;

				// this.showingOnlyMaslul = true;
				setTimeout(() => {
					this.form.controls['MaslulDate'].reset();
					this.GeneralFunction();
				}, 1000);
			} else {
				this._ss.GetMaslol().subscribe(res => {
					this.AllClients = [];
					this.Maslul = res;
					this.Maslul = this.Maslul.recordset;

					localStorage.setItem('Maslul', compress(JSON.stringify(this.Maslul)));

					for (let x = 0; x < this.Maslul.length; x++) {
						for (let y = 0; y < this.AllClientsSearch.length; y++) {
							if (
								this.Maslul[x].cm === this._ss.CompanyNum &&
								this.Maslul[x].cl === this.AllClientsSearch[y].ClientId &&
								this.Maslul[x].dt.toString().slice(0, 10) === this.form.controls['MaslulDate'].value
							) {
								this.AllClients.push(this.AllClientsSearch[y]);
							}
						}
					}
					// this.showingOnlyMaslul = true;

					setTimeout(() => {
						this.form.controls['MaslulDate'].reset();
						this.GeneralFunction();
					}, 1000);
				});
			}
		}
	}

	AskOfflineWork() {
		this.ConfirmOffline = true;
	}

	CancelPopWorkOffline() {
		this.ConfirmOffline = false;
		this.showHideMenu(false);
	}

	WorkOffline() {
		this.WorkOnLineShow = false;
		this.WorkOfflineShow = true;
		this.ConfirmOffline = false;
		this.showHideMenu(false);
		localStorage.setItem('work', '1');
	}

	WorkOnLine() {
		this.WorkOnLineShow = true;
		this.WorkOfflineShow = false;
		this.showHideMenu(false);
		localStorage.removeItem('work');
	}

	CustCityClicked(CustCity) {
		window.scrollTo(0, 0);

		this.AllClients = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].CustCity === CustCity) {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		}

		setTimeout(() => {
			this.GeneralFunction();
		}, 1000);
	}

	AllClientsHelpSort = [];

	HovotOn = false;
	ForHovotAll;
	HovotClients() {
		// this.resetOtherFilters();
		this._ss.AllClientsHoldSearch = 'חיפוש';

		window.scrollTo(0, 0);

		// localStorage.setItem('NavigateToClientSection',JSON.stringify(1))

		this.AllClients = [];
		this.AllClientsHelpSort = [];

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].COD > 0) {
				this.AllClientsHelpSort.push(this.AllClientsSearch[x]);
			}
		}

		this.AllClientsHelpSort.sort(function (a, b) {
			return b.COD - a.COD;
		});

		for (let x = 0; x < this.AllClientsHelpSort.length; x++) {
			this.AllClients.push(this.AllClientsHelpSort[x]);
		}

		this.HovotOn = true;

		setTimeout(() => {
			this.GeneralFunction();
		}, 1000);
	}

	paintTeudaTypesPopup(ClientId, company) {
		this.OpenOrder = {};
		let temporaryArrayForExtraTeudaTypesFromCustomerInformation = [];

		if (this.SearchExtraTeudaTypeFromSave?.length) {
			temporaryArrayForExtraTeudaTypesFromCustomerInformation = (this.SearchExtraTeudaTypeFromSave as string).split(',');
		}
		if (this._doctype.doctypesSet.size) {
			temporaryArrayForExtraTeudaTypesFromCustomerInformation = [...new Set([...temporaryArrayForExtraTeudaTypesFromCustomerInformation, ...this._doctype.doctypesSet])];
		}

		const allDoctypesToGoOver = [...new Set([...TEUDA_TYPES, ...temporaryArrayForExtraTeudaTypesFromCustomerInformation])];
		setTimeout(() => {
			for (const TeudaNum of allDoctypesToGoOver) {
				const doesOrderExistInLocalStorage = localStorage.getItem('AllOrder' + ClientId + TeudaNum + company);

				if (!doesOrderExistInLocalStorage) continue;

				const order = JSON.parse(doesOrderExistInLocalStorage);

				this.OpenOrder[TeudaNum] = { order, total: order.reduce((p, c) => +p + c.TeudaTotalRow, 0) };

				let color = 'var(--valid-color)';

				if (TeudaNum === '74') {
					color = 'red';
				}

				if (TeudaNum === '7') {
					color = 'blue';
				}

				if (this._doctype.doesHaveExtraDoctypeDefinitions() && this._doctype.doctypesDefinitions.length) {
					if (this._doctype.typesOfDoctypes.order.has(+TeudaNum)) {
						color = 'var(--valid-color)';
					} else if (this._doctype.typesOfDoctypes.return.has(+TeudaNum)) {
						color = 'red';
					} else if (this._doctype.typesOfDoctypes.virtual.has(+TeudaNum)) {
						color = 'blue';
					}
				}

				const doc = 'doc' + TeudaNum;
				const numofitems = 'numofitems' + TeudaNum;

				const docnameElement = document.getElementById(doc);
				const docLengthElement = document.getElementById(numofitems);

				if (TeudaNum !== '88' || (this._doctype.doesHaveExtraDoctypeDefinitions() && this._doctype.doctypesDefinitions.length && !this._doctype.typesOfDoctypes.virtual.has(+TeudaNum))) {
					if (docLengthElement) {
						docLengthElement.innerHTML = '[' + order.length + ']';
					}
				}

				if (docnameElement) {
					docnameElement.style.color = color;
				}
			}
		}, 0);
	}
	OpenTeudaType(ClientId) {
		this.shopFilterPop = true;

		// הזמנה תמיד ראשונה ברשימת התעודות
		this.DocTypePerClient.sort((a, b) => (a.DocType === 11 ? -1 : 1));

		this.handleSettingUpItemExtraArrays();

		for (let x = 0; x < this.AllClients.length; x++) {
			if (this.AllClients[x].ClientId === ClientId) {
				this._ss.OneClientInfo = this.AllClients[x];
				break;
			}
		}
		this._ss.ClientForRETURN = ClientId;

		console.log(this.DocTypePerClient);

		if (this.DocTypePerClient.length === 1) {
			this.MoveToProductsOrderType(
				this.DocTypePerClient[0].DocType,
				this.DocTypePerClient[0].DocName,
				this.DocTypePerClient[0].IsShrion,
				this.DocTypePerClient[0].NoObligoCheck,
				this.DocTypePerClient[0].CheckMinPrice,
				this.DocTypePerClient[0].MustCustRem1,
				this.DocTypePerClient[0].MustCustRem2,
				this.DocTypePerClient[0].MustCustRem3,
				this.DocTypePerClient[0].MustCustRem4,
			);
		}
	}

	CloseTeudaType() {
		this.shopFilterPop = false;
		if (this._b2b.isB2BAgent$.value || this.isCatalogAgent) {
			this.router.navigate(['home'], { replaceUrl: true });
		}
	}

	MoveToProductsOrderType(DocType, DocName, DocTypeShrion, NoObligoCheck, CheckMinPrice, MustCustRem1, MustCustRem2, MustCustRem3, MustCustRem4) {
		this.shopFilterPop = false;
		this.LoadingClient = true;

		this._ss.Heara1 = MustCustRem1;
		this._ss.Heara2 = MustCustRem2;
		this._ss.Heara3 = MustCustRem3;
		this._ss.Heara4 = MustCustRem4;
		this._ss.NoObligoCheck = NoObligoCheck;
		this._ss.DocTypeName = DocName;
		this._ss.DocType = DocType;
		this._ss.DocTypeShrion = DocTypeShrion;
		if (environment.development) {
			this._ss.DocTypeShrion = 7;
		}
		this._ss.CheckMinPrice = CheckMinPrice;

		this._ss.AllClientsHold = this.AllClients;

		this._ss.performance.push({ name: 'MoveToProductsOrderType', time: performance.now() });

		setTimeout(() => {
			this.handleNavigateToProductsPage();
		}, 100);
	}

	DocTypePerClient;

	Hazaonvar;
	Ordonvar;
	Retonvar;

	MoveToProductsAzmana(ClientId, company, DocType, DocTypeName) {
		for (let x = 0; x < this.AllClients.length; x++) {
			if (this.AllClients[x].ClientId === ClientId) {
				this._ss.OneClientInfo = this.AllClients[x];
				break;
			}
		}

		// if (this._ss.OneClientInfo.Failed) {
		// 	alert('יש להתחבר לרשת בכדי לבדוק סטטוס תעודה')
		// 	return;
		// }

		this._ss.ClientForRETURN = ClientId;
		if (this._ss.SpecPriceSs !== null) {
			alert('מצטערים ארעה שגיאה,אנא נסה שנית');
			window.location.reload();
		} else {
			if (this._ss.OneClientInfo.AO === 1 || this._ss.OneClientInfo.AM === 1) {
				//  this.LoadingClient = true

				setTimeout(() => {
					this.handleSettingUpItemExtraArrays();

					setTimeout(() => {
						for (let x = 0; x < this.AllClients.length; x++) {
							if (this.AllClients[x].ClientId === ClientId) {
								this._ss.OneClientInfo = this.AllClients[x];
								break;
							}
						}
						this._ss.ClientForRETURN = ClientId;

						if (this._ss.OneClientInfo.AO === 1) {
							this._ss.DocTypeName = 'הזמנה';
							this._ss.DocType = 11;
							this._ss.DocTypeShrion = 0;
							if (environment.development) {
								this._ss.DocTypeShrion = 7;
							}
						}

						if (this._ss.OneClientInfo.AM === 1) {
							this._ss.DocTypeName = 'הצעת מחיר';
							this._ss.DocType = 7;
							this._ss.DocTypeShrion = 0;
							if (environment.development) {
								this._ss.DocTypeShrion = 7;
							}
						}
						this._ss.AllClientsHold = this.AllClients;
						this.LoadingClient = true;

						if (this._ss.CustomerInformation.LookupDoctypeBeforeEntry === '1') {
							this.handleGetDoctypeParametersBeforeNavigateToProducts();
						}
						setTimeout(() => {
							this.handleNavigateToProductsPage();
						}, 100);
					}, 100);
				}, 10);
			} else {
				this.online = window.navigator.onLine;

				for (let x = 0; x < this.AllClients.length; x++) {
					if (this.AllClients[x].ClientId === ClientId) {
						this._ss.OneClientInfo = this.AllClients[x];
						break;
					}
				}
				this._ss.ClientForRETURN = ClientId;

				if (DocType === 0) {
					this.shopFilterPop = true;
					this.DocTypePerClient = [];

					if (localStorage.getItem('AllDocTypesSave') !== null) {
						this.DocTypePerClient = JSON.parse(decompress(localStorage.getItem('AllDocTypesSave'))).filter(d => d.Company === company && d.ClientID === ClientId);

						if (this._ss.isLocalhost) {
							// this.DocTypePerClient.push({
							// 	CheckMinPrice: 1,
							// 	NoObligoCheck: 0,
							// 	OpenBodd: 0,
							// 	MustCustRem1: 0,
							// 	MustCustRem2: 0,
							// 	MustCustRem3: 0,
							// 	MustCustRem4: 0,
							// 	AgentID: '10',
							// 	ClientID: '6404',
							// 	DocType: RECLAMATION_RALLYANCE_DOCTYPE,
							// 	DocName: 'רקלמציה',
							// 	IsShrion: 0,
							// 	Company: 1,
							// 	Show: 1,
							// });
							// this.DocTypePerClient.push({
							// 	CheckMinPrice: 1,
							// 	NoObligoCheck: 0,
							// 	OpenBodd: 0,
							// 	MustCustRem1: 0,
							// 	MustCustRem2: 0,
							// 	MustCustRem3: 0,
							// 	MustCustRem4: 0,
							// 	AgentID: '16',
							// 	ClientID: 'אורן משי נתיבות',
							// 	DocType: 310,
							// 	DocName: 'אשראי',
							// 	IsShrion: 0,
							// 	Company: 1,
							// 	Show: 1,
							// });
						}

						this.OpenTeudaType(ClientId);

						this.paintTeudaTypesPopup(ClientId, company);
					} else {
						this._ss.GetDocTypePerClientAll().subscribe(
							res => {
								this.DocTypePerClient = [];
								this.AllDocTypesSave = res;
								this.AllDocTypesSave = this.AllDocTypesSave.recordset;

								for (let x = 0; x < this.AllDocTypesSave.length; x++) {
									this.AllDocTypesSave[x].Show = 1;
								}

								localStorage.setItem('AllDocTypesSave', compress(JSON.stringify(this.AllDocTypesSave)));

								for (let x = 0; x < this.AllDocTypesSave.length; x++) {
									if (this.AllDocTypesSave[x].Company === company && this.AllDocTypesSave[x].ClientID === ClientId) {
										this.DocTypePerClient.push(this.AllDocTypesSave[x]);
									}
								}

								this.OpenTeudaType(ClientId);

								this.paintTeudaTypesPopup(ClientId, company);
							},
							err => {
								this._ss.GetDoTypePerClient(ClientId, company).subscribe(
									res => {
										this.DocTypePerClient = [];
										this.DocTypePerClient = res;
										this.DocTypePerClient = this.DocTypePerClient.recordset;
										for (let x = 0; x < this.DocTypePerClient.length; x++) {
											this.DocTypePerClient[x].Show = 1;
										}

										this.OpenTeudaType(ClientId);

										this.paintTeudaTypesPopup(ClientId, company);
									},
									err => {
										this.DocTypePerClient = [];

										if (localStorage.getItem('hazaon') !== null) {
											this.Hazaonvar = localStorage.getItem('hazaon');
											this.Hazaonvar = JSON.parse(this.Hazaonvar);
										} else {
											this.Hazaonvar = 1;
										}

										if (localStorage.getItem('ordon') !== null) {
											this.Ordonvar = localStorage.getItem('ordon');
											this.Ordonvar = JSON.parse(this.Ordonvar);
										} else {
											this.Ordonvar = 1;
										}

										if (localStorage.getItem('reton') !== null) {
											this.Retonvar = localStorage.getItem('reton');
											this.Retonvar = JSON.parse(this.Retonvar);
										} else {
											this.Retonvar = 1;
										}

										if (this.Ordonvar === 1) {
											this.DocTypePerClient.push({
												DocType: 11,
												DocName: 'הזמנה',
												NoObligoCheck: 0,
												Show: this.Ordonvar,
												CheckMinPrice: 0,
											});
										}

										if (this.Retonvar === 1) {
											this.DocTypePerClient.push({
												DocType: 74,
												DocName: 'החזרה על תנאי',
												NoObligoCheck: 0,
												Show: this.Retonvar,
												CheckMinPrice: 0,
											});
										}

										if (this.Hazaonvar === 1) {
											this.DocTypePerClient.push({
												DocType: 7,
												DocName: 'הצעת מחיר',
												NoObligoCheck: 0,
												Show: this.Hazaonvar,
												CheckMinPrice: 0,
											});
										}

										this.OpenTeudaType(ClientId);

										this.paintTeudaTypesPopup(ClientId, company);
									},
								);
							},
						);
					}
					// }
				} else {
					if (DocType === 0) {
						this.DocTypePerClient = [];

						if (localStorage.getItem('hazaon') !== null) {
							this.Hazaonvar = localStorage.getItem('hazaon');
							this.Hazaonvar = JSON.parse(this.Hazaonvar);
						} else {
							this.Hazaonvar = 1;
						}

						if (localStorage.getItem('ordon') !== null) {
							this.Ordonvar = localStorage.getItem('ordon');
							this.Ordonvar = JSON.parse(this.Ordonvar);
						} else {
							this.Ordonvar = 1;
						}

						if (localStorage.getItem('reton') !== null) {
							this.Retonvar = localStorage.getItem('reton');
							this.Retonvar = JSON.parse(this.Retonvar);
						} else {
							this.Retonvar = 1;
						}

						if (this.Ordonvar === 1) {
							this.DocTypePerClient.push({
								DocType: 11,
								DocName: 'הזמנה',
								NoObligoCheck: 0,
								Show: this.Ordonvar,
								CheckMinPrice: 0,
							});
						}

						if (this.Retonvar === 1) {
							this.DocTypePerClient.push({
								DocType: 74,
								DocName: 'החזרה על תנאי',
								NoObligoCheck: 0,
								Show: this.Retonvar,
								CheckMinPrice: 0,
							});
						}

						if (this.Hazaonvar === 1) {
							this.DocTypePerClient.push({
								DocType: 7,
								DocName: 'הצעת מחיר',
								NoObligoCheck: 0,
								Show: this.Hazaonvar,
								CheckMinPrice: 0,
							});
						}

						this.OpenTeudaType(ClientId);

						this.paintTeudaTypesPopup(ClientId, company);
					} else {
						//  this.LoadingClient = true

						this.handleSettingUpItemExtraArrays();

						setTimeout(() => {
							for (let x = 0; x < this.AllClients.length; x++) {
								if (this.AllClients[x].ClientId === ClientId) {
									this._ss.OneClientInfo = this.AllClients[x];
									break;
								}
							}
							this._ss.ClientForRETURN = ClientId;

							this._ss.DocTypeName = DocTypeName;
							this._ss.DocType = DocType;
							this._ss.DocTypeShrion = 0;
							if (environment.development) {
								this._ss.DocTypeShrion = 7;
							}
							this._ss.AllClientsHold = this.AllClients;
							this.LoadingClient = true;
							setTimeout(() => {
								this.handleNavigateToProductsPage();
							}, 100);
						}, 100);
					}
				}
			}
		}
	}

	MoveToProductsAhzara(client) {
		const { ClientId, DocType, AO, AM } = client;

		if (DocType === 0 || AO === 1 || AM === 1) return;

		if (this._ss.SpecPriceSs !== null) {
			alert('מצטערים ארעה שגיאה,אנא נסה שנית');
			window.location.reload();
		} else {
			this.handleSettingUpItemExtraArrays();

			for (let x = 0; x < this.AllClients.length; x++) {
				if (this.AllClients[x].ClientId === ClientId) {
					this._ss.OneClientInfo = this.AllClients[x];
					break;
				}
			}
			this._ss.ClientForRETURN = ClientId;

			this._ss.DocTypeName = 'ת.החזרה';
			this._ss.DocType = 74;
			this._ss.DocTypeShrion = 0;
			if (environment.development) {
				this._ss.DocTypeShrion = 7;
			}
			this._ss.AllClientsHold = this.AllClients;
			this.LoadingClient = true;
			setTimeout(() => {
				this.handleNavigateToProductsPage();
			}, 100);
		}
	}

	MoveToClientCard(ClientId) {
		if (this._b2b.isB2BAgent$.value) return;
		if (this._ss.SpecPriceSs !== null) {
			alert('מצטערים ארעה שגיאה,אנא נסה שנית');
			window.location.reload();
		} else {
			this.handleSettingUpItemExtraArrays();

			for (let x = 0; x < this.AllClients.length; x++) {
				if (this.AllClients[x].ClientId === ClientId) {
					this._ss.OneClientInfo = this.AllClients[x];
					break;
				}
			}
			this._ss.ClientForRETURN = ClientId;

			this._ss.AllClientsHold = this.AllClients;
			this.router.navigate(['client-card']);
		}
	}

	MoveToClientHovot(ClientId) {
		this._ss.AutoMoveHovot = 1;

		if (this._ss.SpecPriceSs !== null) {
			alert('מצטערים ארעה שגיאה,אנא נסה שנית');
			window.location.reload();
		} else {
			this.handleSettingUpItemExtraArrays();

			for (let x = 0; x < this.AllClients.length; x++) {
				if (this.AllClients[x].ClientId === ClientId) {
					this._ss.OneClientInfo = this.AllClients[x];
					break;
				}
			}
			this._ss.ClientForRETURN = ClientId;

			this._ss.AllClientsHold = this.AllClients;
			this.router.navigate(['client-card']);
		}
	}

	resetOtherFilters() {
		this.showingOnlyMaslul = false;
		this.CityCheckedArray = [];
	}

	ClearClientSearch() {
		// this.resetOtherFilters();
		this._ss.AllClientsHoldSearch = 'חיפוש';

		localStorage.removeItem('NavigateToClientSection');

		this.showSortPopupSanen &&
			this.CityCheckedArray.forEach(ct => {
				const element = document.getElementById(ct.City);

				if (element !== null) {
					element.classList.remove('city-check-checked');
				}
			});

		this.shouldClearCitySortButtonAppear = false;

		this.CustCity = this.CustCitySearch;

		this.HovotOn = false;
		this.AllClients = [];
		this.CityCheckedArray = [];
		this.SearchErrorPop = false;
		this.form.reset();
		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			this.AllClients.push(this.AllClientsSearch[x]);
		}

		const wasSortPopupOpenBeforeTimeout = this.showSortPopup;

		setTimeout(() => {
			if (!wasSortPopupOpenBeforeTimeout) {
				document.getElementById('clientsearch').focus();
			}
			this.GeneralFunction();
		}, 1000);
	}

	SearchClient() {
		this._ss.AllClientsHoldSearch = this.form.controls['ClientSearch'].value;

		const clientSearchValue: string = this.form.controls['ClientSearch'].value?.toLowerCase?.();
		const clientSearchValueLength = clientSearchValue?.length;

		const prioritizeFirstLetters = this._ss.CustomerInformation.SearchLogic === '2';
		const prioritizeResultArray = [];

		this.HovotOn = false;

		window.scrollTo(0, 0);
		this.virtualScroll.scrollToIndex(0);

		this.SearchErrorPop = false;

		let resultsArray = [];

		if (!clientSearchValue) {
			this._ss.AllClientsHoldSearch = 'חיפוש';
			resultsArray = this.AllClientsSearch;
		}

		if (clientSearchValue && clientSearchValueLength <= 2) {
			const len = clientSearchValueLength;
			for (let x = 0; x < this.AllClientsSearch.length; x++) {
				if (
					(this.AllClientsSearch[x].Client_Name ?? '').toLowerCase().slice(0, len) === clientSearchValue ||
					(this.AllClientsSearch[x].ClientId ?? '').toLowerCase().slice(0, len) === clientSearchValue
				) {
					resultsArray.push(this.AllClientsSearch[x]);
				}
			}
		}
		if (clientSearchValueLength > 2) {
			const splitValue = clientSearchValue.split(' ');

			for (let i = 0; i < this.AllClientsSearch.length; i++) {
				let counter = 0;

				const { Client_Name, ClientId, CustCity } = this.AllClientsSearch[i];

				const Client_NameWithoutSpaces = (Client_Name || '').replace(/\s/g, '').toLowerCase(),
					ClientIdWithoutSpaces = (ClientId || '').replace(/\s/g, '').toLowerCase(),
					CustCityWithoutSpaces = (CustCity || '').replace(/\s/g, '').toLowerCase(),
					allStrings = typeof Client_NameWithoutSpaces === 'string' && typeof ClientIdWithoutSpaces === 'string' && typeof CustCityWithoutSpaces === 'string';

				// V8 optimizes this if statement, maybe
				if (allStrings) {
					for (let j = 0; j < splitValue.length; j++) {
						if (
							(Client_NameWithoutSpaces && Client_NameWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(ClientIdWithoutSpaces && ClientIdWithoutSpaces.indexOf(splitValue[j]) > -1) ||
							(CustCityWithoutSpaces && CustCityWithoutSpaces.indexOf(splitValue[j]) > -1)
						) {
							counter++;
						}
					}

					if (counter >= splitValue.length) {
						// rank exact matches higher in search
						if (prioritizeFirstLetters && Client_Name) {
							const index = Client_Name.indexOf(clientSearchValue);
							if (index > -1 && index === 0) {
								prioritizeResultArray.push(this.AllClientsSearch[i]);
								continue;
							}
						}
						resultsArray.push(this.AllClientsSearch[i]);
					}
				}
			}
		}

		this.AllClients = [...prioritizeResultArray, ...resultsArray];

		if (this.AllClients.length === 0) {
			this.SearchErrorPop = true;
		}

		this.GeneralFunction();
	}

	//ALL SEARCH CLIENT FUNCTIONS// BUTTOM

	OpenOrdersClients(val = 'all') {
		this._ss.AllClientsHoldSearch = 'חיפוש';

		let temporaryArrayForExtraTeudaTypesFromCustomerInformation = [];

		if (this.SearchExtraTeudaTypeFromSave?.length) {
			temporaryArrayForExtraTeudaTypesFromCustomerInformation = (this.SearchExtraTeudaTypeFromSave as string).split(',');
		}
		if (this._doctype.doctypesSet.size) {
			temporaryArrayForExtraTeudaTypesFromCustomerInformation = [...new Set([...temporaryArrayForExtraTeudaTypesFromCustomerInformation, ...this._doctype.doctypesSet])];
		}

		window.scrollTo(0, 0);

		this.AllClients = [];

		const allDoctypesToGoOver = [...new Set([...TEUDA_TYPES, ...temporaryArrayForExtraTeudaTypesFromCustomerInformation])];
		const hasExtraDoctypeDefinitions = this._doctype.doesHaveExtraDoctypeDefinitions() && this._doctype.doctypesDefinitions.length;

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			const { ClientId, company } = this.AllClientsSearch[x];

			let lsString = allDoctypesToGoOver;

			if (val === 'returns') {
				lsString = ['74'];
				if (hasExtraDoctypeDefinitions) {
					lsString = [...this._doctype.typesOfDoctypes.return];
				}
			}

			if (val === 'all') {
				lsString.push('74');
				if (hasExtraDoctypeDefinitions) {
					lsString = [...this._doctype.typesOfDoctypes.order, ...this._doctype.typesOfDoctypes.return, ...this._doctype.typesOfDoctypes.virtual];
				}
			}

			if (lsString.some(ls => localStorage.getItem('AllOrder' + ClientId + ls + company) !== null)) {
				this.AllClients.push(this.AllClientsSearch[x]);
			}
		}

		this.showHideSortPopup(false);
		setTimeout(() => {
			this.GeneralFunction();
		}, 200);
	}

	showHideMenu(value: boolean): void {
		if (value) {
			this._ss.burgerMenu = value;
			this._ss.headerAbsolute = value;
			this._ss.burgerMenuNoScroll = value;
		} else {
			this._ss.closeMenu();
			// setTimeout(() => {
			// 	this._ss.headerAbsolute = value;
			// 	this._ss.burgerMenuNoScroll = value;
			// }, 1000);
		}
	}

	//ADDED BY DESIGN // BUTTOM

	filterSortActive = false;
	filterSortBlock = false;
	presentBlock = false;
	showDepartmentsPopup = false;
	showDepartmentsList = false;
	showProductPopup = false;
	showSortPopup = false;
	showSortList = false;
	headerAbsolute = false;

	showLogoutPopup = false;

	commentBlock = false;
	commentText = '0';

	popupType = {
		range: 'range',
		filter: 'filter',
	};

	commentCharacterLimit = 140;

	showHideSortBlock(value: boolean): void {
		this.filterSortActive = value;
		this.filterSortBlock = value;
	}

	showHideDepartmentsPopup(value: boolean): void {
		this.showDepartmentsPopup = value;
	}

	showHideSortPopup(value: boolean): void {
		if (!this.canSeePrices) {
			return;
		}
		if (value) {
			setTimeout(() => {
				document.querySelector('.order-popup.order-popup_sort.popup__content').classList.add('open');
			}, 10);
			this.showSortPopup = value;

			const ls = localStorage['receiptsToSend'];
			if (ls) {
				this._receipts.receiptsWaitingToBeSent = JSON.parse(decompress(ls));
				console.log(this._receipts.receiptsWaitingToBeSent);
			}
		} else {
			setTimeout(() => {
				document.querySelector('.order-popup.order-popup_sort.popup__content').classList.remove('open');
			}, 10);
			setTimeout(() => {
				this.showSortPopup = value;
			}, 370);
		}
	}

	showHideSortList(): void {
		this.showSortList = !this.showSortList;
	}

	showHideLogoutPopup(value: boolean): void {
		this.showLogoutPopup = value;
	}

	SendAllTeudotPop = false;

	ButtonsShowSentAllTeudot = true;

	SentArray = [];

	Finish = false;

	SendOpenTeudot() {
		// for (const key in localStorage) {
		// 	if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
		// 		const element = localStorage[key];

		// 		if (key.includes('AllOrder')) console.log({key});

		// 	}
		// }

		this.SendAllTeudotPop = true;
		this.Finish = false;
	}

	CancelSendOpenTeudot() {
		this.SendAllTeudotPop = false;
		window.location.reload();
	}

	WichToSend = 0;
	AllOrder;

	resolveSendOpenTeudotConfirm(teudaType: keyof typeof DEFAULT_DOCTYPE_BY_TYPE) {
		const NumberTeuda = DEFAULT_DOCTYPE_BY_TYPE[teudaType];
		const sendAll = 0;
		this.SendOpenTeudotConfirm(NumberTeuda || sendAll);
	}
	SendOpenTeudotConfirm(NumberTeuda, multiCompany = false) {
		const defaultNames = DOCTYPE_DEFAULT_NAME;

		this.WichToSend = NumberTeuda;

		this.ButtonsShowSentAllTeudot = false;
		this.AllOrder = null;

		if (this.hasMinDocTeuda) {
			this.AllOpenTeudaArray = [];
			this.Finish = true;
			return;
		}

		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			const { ClientId, company, Client_Name } = this.AllClientsSearch[x];

			let docMinArray: MinDocTeudaBaseWithActionAndDoctype[] = [];

			if (this.hasMinDocTeuda) {
				docMinArray = this._docMin.getRelevantDocMinTeudaWithoutDoctype(company, ClientId);

				// const docMin = docMinArray.find(docMin => docMin.doctype === +doc);

				// if (docMin?.minteuda > parsed.reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
				// 	continue;
				// }
			}
			if (this.WichToSend === DEFAULT_ORDER_DOCTYPE) {
				let arrayOfDocs = [...ALL_CLIENTSIDE_ORDER_DOCTYPES];

				if (this._doctype.doesHaveExtraDoctypeDefinitions()) {
					arrayOfDocs = [...this._doctype.typesOfDoctypes.order];
				}

				let doesHaveSavedDocAndIsSending = false;

				for (const doc of arrayOfDocs) {
					const uniqueIdentifier = ClientId + doc + company;
					const localstorageCheck = localStorage.getItem('AllOrder' + uniqueIdentifier);
					if (!localstorageCheck) continue;

					const data = this.SentArray.find(ob => ob.id === 'id' + uniqueIdentifier);
					if (data) continue;

					this.AllOrder = JSON.parse(localstorageCheck);

					this._ss.DocType = doc;
					let docname = this._doctype.getDoctypeName(+doc) || defaultNames[doc];
					this._ss.DocTypeName = docname;
					this._ss.OneClientInfo = this.AllClientsSearch[x];
					this.SentArray.push({
						Client_Name: Client_Name,
						Status: 'בשליחה..',
						Type: docname,
						id: 'id' + uniqueIdentifier,
					});
					this.SendingTeuda();
					doesHaveSavedDocAndIsSending = true;
					break;
				}

				if (doesHaveSavedDocAndIsSending) {
					break;
				}
			}

			if (this.WichToSend === DEFAULT_RETURN_DOCTYPE) {
				let arrayOfDocs = [DEFAULT_RETURN_DOCTYPE];
				if (this._doctype.doesHaveExtraDoctypeDefinitions()) {
					arrayOfDocs = [...this._doctype.typesOfDoctypes.return];
				}
				for (const doc of arrayOfDocs) {
					const uniqueIdentifier = ClientId + doc + company;
					const localstorageCheck = localStorage.getItem('AllOrder' + uniqueIdentifier);
					if (!localstorageCheck) continue;

					const data = this.SentArray.find(ob => ob.id === 'id' + uniqueIdentifier);
					if (data) continue;

					this.AllOrder = JSON.parse(localstorageCheck);

					this._ss.DocType = doc;
					let docname = this._doctype.getDoctypeName(+doc) || defaultNames[doc];
					this._ss.DocTypeName = docname;
					this._ss.OneClientInfo = this.AllClientsSearch[x];
					this.SentArray.push({
						Client_Name: Client_Name,
						Status: 'בשליחה..',
						Type: docname,
						id: 'id' + uniqueIdentifier,
					});
					this.SendingTeuda();
					return;
				}
			}

			if (this.WichToSend === 7) {
				let arrayOfDocs = [7];
				if (this._doctype.doesHaveExtraDoctypeDefinitions()) {
					arrayOfDocs = [...this._doctype.typesOfDoctypes.virtual];
				}
				for (const doc of arrayOfDocs) {
					const uniqueIdentifier = ClientId + doc + company;
					const localstorageCheck = localStorage.getItem('AllOrder' + uniqueIdentifier);
					if (!localstorageCheck) continue;

					const data = this.SentArray.find(ob => ob.id === 'id' + uniqueIdentifier);
					if (data) continue;

					this.AllOrder = JSON.parse(localstorageCheck);

					this._ss.DocType = doc;
					let docname = this._doctype.getDoctypeName(+doc) || defaultNames[doc];
					this._ss.DocTypeName = docname;
					this._ss.OneClientInfo = this.AllClientsSearch[x];
					this.SentArray.push({
						Client_Name: Client_Name,
						Status: 'בשליחה..',
						Type: docname,
						id: 'id' + uniqueIdentifier,
					});
					this.SendingTeuda();
					return;
				}
			}

			if (this.WichToSend === 0) {
				let arrayOfDocs = this._doctype.getAllDoctypes();

				if (this._doctype.doesHaveExtraDoctypeDefinitions()) {
					arrayOfDocs = [...this._doctype.typesOfDoctypes.order, ...this._doctype.typesOfDoctypes.return, ...this._doctype.typesOfDoctypes.virtual];
				}

				for (const doc of arrayOfDocs) {
					const uniqueIdentifier = ClientId + doc + company;
					const localstorageCheck = localStorage.getItem('AllOrder' + uniqueIdentifier);
					if (!localstorageCheck) continue;

					const data = this.SentArray.find(ob => ob.id === 'id' + uniqueIdentifier);
					if (data) continue;

					this.AllOrder = JSON.parse(localstorageCheck);

					this._ss.DocType = doc;
					let docname = this._doctype.getDoctypeName(+doc) || defaultNames[doc] || 'הזמנה';
					this._ss.DocTypeName = docname;
					this._ss.OneClientInfo = this.AllClientsSearch[x];
					this.SentArray.push({
						Client_Name: Client_Name,
						Status: 'בשליחה..',
						Type: docname,
						id: 'id' + uniqueIdentifier,
					});
					this.SendingTeuda();
					return;
				}
			}
		}

		if (this.AllOrder === null) {
			this.Finish = true;
		}
	}

	AllOrderSwitch;
	TotalSumOAllOrder = 0;
	TotalSumOAllOrderPlusVat = 0;

	showHideFirstStep = false;
	showHideSecondStep = false;
	showHideThreeStep = false;
	showHideFourStep = false;
	orderProcess = false;
	orderSuccess = false;
	CancelOrder = false;
	orderError = false;
	SendOrder = false;
	SaveTeudaPop = false;
	DeleteProductPop = false;
	AllProductsSearch;

	HelpPriceArizot = 0;

	ChoosenDeleteProduct;
	ChoosenDeleteProductId;
	NewCart;
	CustDiscountShow = 0;
	CustDiscountShowVat = 0;

	StatusOrder = 0;

	Vorder = false;

	HearaNewOrder = '';

	OrderStatusNumberSend = '0';
	OrderStatusNumber = '0';

	SupplyDateTeuda = this.Date.toISOString().toString().slice(0, 10);

	AllClientsHelp;

	DailyOrder = [];
	DailyOrderAllorder;

	OrderGood = false;
	DrivingTruckPop = true;
	BackArrowPop = true;
	OrderInProgressLine;

	ResForConfirmedOrder;
	readonly hasConcentratedSpecials = this._ss.CustomerInformation?.HasSpecialsType === '1';

	async SendingTeuda() {
		const isEmptyProduct = this.AllOrder.find(order => !order.TeudaPackQuantity && !order.TeudaQuantity && !order.TeudaBonusQuantity && order.SpecialID == null);

		if (isEmptyProduct) {
			alert(`שליחת תעודה ${isEmptyProduct.TeudaNum} בוטלה,\nפריט ${isEmptyProduct.Product_Name} ללא כמות.`);
			this.ButtonsShowSentAllTeudotOne = false;
			this.CancelSendOpenTeudotOne();
			return;
		}

		// check must-include-products
		const mustIncludeCheck = this._mustIncludeProducts.handleCheckMustIncludeProducts(this.AllOrder);

		switch (mustIncludeCheck.result) {
			case 'ok':
				break;
			case 'stop':
				this._alert.customAlert([`לא ניתן לסיים ללא מוצרי חובה.`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);
				this.ButtonsShowSentAllTeudotOne = false;
				this.dialog.afterAllClosed.subscribe(() => {
					this.CancelSendOpenTeudotOne();
				});
				return;
			case 'alert':
				const promptUser = await this._alert.customConfirmAlert([`שים לב, ישנם מוצרי חובה לתעודה זו. האם להמשיך?`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);

				if (!promptUser) {
					this.ButtonsShowSentAllTeudotOne = false;
					this.CancelSendOpenTeudotOne();
				}
				break;
			default:
				break;
		}

		this.TotalSumOAllOrder = 0;

		for (let x = 0; x < this.AllOrder.length; x++) {
			this.AllOrder[x].ArizotPrice ||= 0;
			this.AllOrder[x].YehidotPrice ||= 0;
			this.AllOrder[x].TeudaTotalRow = this.AllOrder[x].ArizotPrice + this.AllOrder[x].YehidotPrice;
			this.TotalSumOAllOrder += this.AllOrder[x].TeudaTotalRow;
		}

		this.TotalSumOAllOrderPlusVat = this.TotalSumOAllOrder;
		let y = (this.TotalSumOAllOrder * this._ss.OneClientInfo.CustVat) / 100;
		this.TotalSumOAllOrderPlusVat += y;

		this.CustDiscountShow = this.TotalSumOAllOrder;
		const aa = (this._ss.OneClientInfo.CustDiscount * this.TotalSumOAllOrder) / 100;
		this.CustDiscountShow -= aa;

		this.CustDiscountShowVat = this.CustDiscountShow;
		const bb = (this.CustDiscountShow * this._ss.OneClientInfo.CustVat) / 100;
		// TODO What's going on here?
		this.CustDiscountShowVat += y;

		//2
		this.TODOS();

		if (this._ss.DocType === 74 || this._ss.DocType === 7) {
			this.Teuda();
		} else {
			if (
				this._ss.OneClientInfo.COD + this._ss.OneClientInfo.COC + this.TotalSumOAllOrder + (+this._ss.OneClientInfo.AddObligo || 0) < this._ss.OneClientInfo.CMC ||
				this._ss.OneClientInfo.BCO === 0
			) {
				this.Teuda();
			} else {
				if (this._ss.OneClientInfo.BCO === 1 && !this.blockBeforeSendTeudaIfObligoWarning) {
					alert('התראה: חריגת אובליגו ');
					this.Teuda();
				}

				if (this._ss.OneClientInfo.BCO === 2 || (this._ss.OneClientInfo.BCO === 1 && this.blockBeforeSendTeudaIfObligoWarning)) {
					this.SentArray[this.SentArray.indexOf(this.SentArray.find(ob => ob.id === 'id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company))].Status =
						'חסימת אובליגו';
					setTimeout(() => {
						document.getElementById('id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company).style.color = 'red';
						this.SendOpenTeudotConfirm(this.WichToSend);
					}, 200);
				}
			}
		}

		this.HearaNewOrder = localStorage.getItem('HearaNewOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
	}

	Teuda() {
		setTimeout(() => {
			this.orderProcess = true;
			this.orderSuccess = true;
			this._ss.OrderInProcess = true;

			this.OrderInProgressLine = 'מכין נתונים';

			if (this._ss.CustomerInformation.SendCoordinates === '1' && navigator?.geolocation?.getCurrentPosition && typeof this.location === 'string') {
				try {
					navigator.geolocation.getCurrentPosition(
						pos => {
							this.location = {
								latitude: pos.coords.latitude,
								longitude: pos.coords.longitude,
							};
							console.log(pos);
						},
						err => {
							this.location = err.message;
							console.log(err);
						},
						{ enableHighAccuracy: true, timeout: 10000 },
					);
				} catch (error) {
					console.log('getting coordinates failed');
				}
			}

			this.BackArrowPop = false;
			setTimeout(() => {
				if (this.orderError === false && this.OrderGood === false) {
					this.orderError = true;
					this.OrderGood = false;
					this.DrivingTruckPop = false;
					this.BackArrowPop = true;
					this._ss.OrderInProcess = false;
				}
			}, 1000 * 60 * 3);

			this.HearaNewOrder = localStorage.getItem('HearaNewOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder1 = localStorage.getItem('HearaNewOrder1' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder2 = localStorage.getItem('HearaNewOrder2' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder3 = localStorage.getItem('HearaNewOrder3' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder4 = localStorage.getItem('HearaNewOrder4' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';

			this.TODOS();
			// todo1
			if (localStorage.getItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) !== null) {
				this.SupplyDateTeuda = localStorage.getItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
			}

			// handle specials

			if (this.hasSpecials) {
				this.tryToBuildSpecials();
				this.populateSpecials();
			}

			if (Object.getOwnPropertyNames(this.specialsInCart).length) {
				const orderWithoutSpecials = this.AllOrder.filter(ord => typeof ord.SpecialID !== 'number');

				this.handlePopulateProductHash();

				const specialTranslatedToProduct = Object.values(this.specialsInCart).reduce((prev, { special, quantity }) => {
					const specialInAllOrder = this.AllOrder.filter(ord => typeof ord.SpecialID === 'number').reduce((prev, curr) => {
						prev[curr.SpecialID] = curr;
						return prev;
					}, {});

					if (this.hasConcentratedSpecials && special[0].SpecialType !== 0) {
						const bonusType = special[0].BonusType;
						const allOrder = specialInAllOrder[special[0].SpecialID];
						if (!allOrder) return prev;

						for (const order of allOrder.order) {
							if (bonusType === 'packs') {
								order.TeudaBonusQuantity = normalizeNumberToMaxThreeDecimal(order.TeudaBonusQuantity * order.ItemPack);
							}
							prev.push(order);
						}
						return prev;
					}

					for (const spc of special) {
						const { TeudaQuantity, TeudaPackQuantity, TeudaBonusQuantity, TeudaItemNetPrice, ProductID, SpecialID, ID } = spc;

						const product = this.productsHash[ProductID.toLowerCase()];

						const specialInOrder = specialInAllOrder[SpecialID] || {};

						const { Product_Name, Price } = product;
						const CPP = product.CPP || 0;
						const ItemBarcode = product.ItemBarcode || '';
						const ItemPack = product.ItemPack || 0;
						const ItemPicName = product.ItemPicName || '';
						const Stock = product.Stock || 0;
						const BD = product.BD || 0;
						const BP = product.BP || 0;
						const BM = product.BM || 0;
						const MinPrice = product.MinPrice || 0;
						const qt = product.qt || 0;
						const BS = product.BS || 0;
						const PKD = product.PKD || 0;
						const ChangePrice = product.ChangePrice || 0;
						const ChangeDiscount = product.ChangeDiscount || 0;
						const IfChangePriceOrDisc = OrderState.noChangeToProduct;
						const Duplicate = specialInOrder.Duplicate || 'false';
						const DuplicateOrder = specialInOrder.DuplicateOrder || '';
						const PriceInInput = 0;
						const Ptve = specialInOrder.Ptve || '';
						const StatusInsertItem = specialInOrder.StatusInsertItem || '';
						const CPPTotalRow = (TeudaPackQuantity * ItemPack + TeudaQuantity) * CPP;

						const YehidotPrice = TeudaQuantity * TeudaItemNetPrice;
						const ArizotPrice = TeudaPackQuantity * ItemPack * TeudaItemNetPrice;
						const TeudaTotalRow = YehidotPrice + ArizotPrice;

						this.TotalSumOAllOrder += TeudaTotalRow;

						// weird logic block:
						this.TotalSumOAllOrderPlusVat = this.TotalSumOAllOrder;
						let y = (this.TotalSumOAllOrder * this._ss.OneClientInfo.CustVat) / 100;
						this.TotalSumOAllOrderPlusVat += y;

						this.CustDiscountShow = this.TotalSumOAllOrder;
						const aa = (this._ss.OneClientInfo.CustDiscount * this.TotalSumOAllOrder) / 100;
						this.CustDiscountShow -= aa;

						this.CustDiscountShowVat = this.CustDiscountShow + y;

						prev.push({
							Stock,
							ProductId: ProductID,
							Product_Name,
							Price: TeudaItemNetPrice,
							ItemPack,
							ItemBarcode,
							ItemPicName,
							BD,
							BP,
							BM,
							MinPrice,
							qt,
							BS,
							PKD,
							CPP,
							ChangePrice,
							ChangeDiscount,
							YehidotPrice: YehidotPrice * quantity,
							ArizotPrice: ArizotPrice * quantity,
							TeudaQuantity: TeudaQuantity * quantity,
							TeudaPackQuantity: TeudaPackQuantity * quantity,
							TeudaBonusQuantity: TeudaBonusQuantity * quantity,
							IfChangePriceOrDisc,
							TeudaBonusPackQuantity: 0,
							TeudaRemark: '',
							TeudaItemRemark: 'מבצע ' + SpecialID,
							Duplicate,
							DuplicateOrder,
							PermPrice: Price,
							PriceInInput,
							Ptve,
							StatusInsertItem,
							TeudaTotalRow: TeudaTotalRow * quantity,
							CPPTotalRow,
							ProductName: Product_Name,
						});
					}

					return prev;
				}, []);

				this.AllOrder = [...orderWithoutSpecials, ...specialTranslatedToProduct];
			}

			//--------------------------------------------------->
			for (let x = 0; x < this.AllOrder.length; x++) {
				const order = this.AllOrder[x];
				if (order.Duplicate !== 'false') {
					order.ProductId = order.Duplicate;
				}

				order.TeudaType = this._ss.DocType;
				order.Client_Id = this._ss.OneClientInfo.ClientId;
				order.TeudaCreateDate = this.Date;
				// order.TeudaCreateDate = order.TeudaCreateDate ?? this.Date
				// order.ProductId =  order.ProductIdNoChange
				order.TeudaLineNum = x + 1;
				order.ProductName = this._util.removeProblematicCharacters(order.Product_Name || '');
				order.TeudaQuantity = order.TeudaQuantity;
				order.TeudaPackQuantity = order.TeudaPackQuantity;
				order.TeudaBonusQuantity = order.TeudaBonusQuantity;
				order.TeudaBonusPackQuantity = 0;
				order.TeudaPrice = order.PermPrice;
				order.TeudaItemNetPrice = order.Price;

				order.TeudaItemProfit = ((order.Price - order.CPP) / order.Price) * 100;
				order.TeudaItemProfit = Math.max(Math.min(+order.TeudaItemProfit, 1000), -99.99);

				order.TeudaItemDiscount = 0;
				order.TeudaItemRemark = this._util.removeProblematicCharacters(order.TeudaItemRemark || '');
				order.TeudaTotalRow = order.YehidotPrice + order.ArizotPrice;
				order.TeudaTotalSumBrouto = 0;
				order.TeudaTotalSumBeforeVat = 0;
				order.TeudaTotalSumVat = this.TotalSumOAllOrderPlusVat - this.TotalSumOAllOrder;
				order.TeudaTotalSum = 0;
				order.TeudaProfit = 0;
				order.TeudaSupplyDate = this.SupplyDateTeuda;
				order.TeudaVat = this._ss.OneClientInfo.CustVat;
				order.TeudaDiscount = order.TeudaDiscount ?? 0;
				order.TeudaAgent = this._ss.AgentIdConnected;
				order.CartId = 0;
				order.Client_Name = this._util.removeProblematicCharacters(this._ss.OneClientInfo.Client_Name || '');
				order.Arizot = order.TeudaPackQuantity;
				order.Amount = order.TeudaQuantity;
				order.User_Name = this._ss.UserNameConnected;
				order.TeudaStatus = '';
				order.PrintStatus = '';
				order.EmailStatus = '';
				order.CustEmail = '';
				order.TeudaNumDupFrom = '';
				order.TuedaErpDocNum = '';
				order.TeuddaErpErrMessage = '';
				order.TeudaRemark = this._util.removeProblematicCharacters(order.TeudaRemark || this.HearaNewOrder || '');
				order.TeudaRemark1 = this._util.removeProblematicCharacters(order.TeudaRemark1 || this._ss.HearaNewOrder1 || '');
				order.TeudaRemark2 = this._util.removeProblematicCharacters(order.TeudaRemark2 || this._ss.HearaNewOrder2 || '');
				order.TeudaRemark3 = this._util.removeProblematicCharacters(order.TeudaRemark3 || this._ss.HearaNewOrder3 || '');
				order.TeudaRemark4 = this._util.removeProblematicCharacters(order.TeudaRemark4 || this._ss.HearaNewOrder4 || '');
				order.TeudaRemark5 = this._util.removeProblematicCharacters(order.TeudaRemark5 || '');
				order.TeudaDeliveryAddr = this._util.removeProblematicCharacters(this._ss.OneClientInfo.Address || '');
				order.TeudaDeliveryCity = this._util.removeProblematicCharacters(this._ss.OneClientInfo.CustCity || '');
				order.TeudaRef = '';
				order.TeudaCompany = '';
				order.TeudaUnitPerPack = order.ItemPack;
				// order.IfChangePriceOrDisc = 0
				order.TotalLines = this.AllOrder.length;
				order.PicName = '';
				order.Stock = order.Stock;
				order.Longitude = typeof this.location === 'string' ? '0' : this.location.longitude;
				order.Latitude = typeof this.location === 'string' ? '0' : this.location.latitude;
				order.TeudaUnitPerPack = order.ItemPack;
				order.ItemBarcode = order.ItemBarcode;
				order.TeudaTypeName = this._ss.DocTypeName;
				order.TeudaCompany = this._ss.OneClientInfo.company;
				order.Tve = this._ss.VersionNumber;
				order.Sent = 'Auto';
			}

			const doesHaveDiscountButMissingInSomeItems = this.AllOrder.find(ord => ord.TeudaDiscount);

			for (let x = 0; x < this.AllOrder.length; x++) {
				const order = this.AllOrder[x];
				order.TeudaTotalSumBrouto = this.TotalSumOAllOrder;

				if (doesHaveDiscountButMissingInSomeItems) {
					order.TeudaDiscount = doesHaveDiscountButMissingInSomeItems.TeudaDiscount;
				} else {
					order.TeudaDiscount = this._ss.OneClientInfo.CustDiscount || 0;
				}

				let y = 100 - this._ss.OneClientInfo.CustDiscount;
				y *= this.TotalSumOAllOrder;
				y /= 100;
				order.TeudaTotalSumBeforeVat = y;

				let z = this._ss.OneClientInfo.CustVat / 100;
				z *= order.TeudaTotalSumBeforeVat;
				order.TeudaTotalSumVat = z;
				order.TeudaTotalSum = order.TeudaTotalSumBeforeVat + order.TeudaTotalSumVat;
			}

			for (let x = 0; x < this.AllOrder.length; x++) {
				const order = this.AllOrder[x];
				if (order.DuplicateOrder === 'true') {
					this.OrderStatusNumberSend = '5';
				}

				if (order.Price !== order.PermPrice) {
					let y = order.PermPrice - order.Price;
					y /= order.PermPrice;
					y *= 100;

					order.TeudaItemDiscount = normalizeNumber(y);
					// this.AllOrder[x].IfChangePriceOrDisc = 1
					if (this.OrderStatusNumber === '2') {
						this.OrderStatusNumberSend = '2';
					}
				}

				if (this._ss.CustomerInformation.SendDiscount === '1') {
					if (order.ChangeDiscount) {
						order.TeudaItemDiscount = order.ChangeDiscount;
					}

					if (order.PriceInInput) {
						order.TeudaPrice = order.PriceInInput;
					}

					if (order.IfChangePriceOrDisc === 1) {
						order.TeudaItemDiscount = 0;
					}
				}

				if (order.Type != null) {
					if (order.weightMode === 'weight') {
						order.Type = 1;
					}
					if (order.weightMode === 'singles') {
						order.Type = 2;
					}
				}

				if (this.alternativeProductCalculations) {
					order.Price = order.ChangePrice = order.TeudaItemNetPrice = order.NetPrice || 0;
					order.TeudaPrice = order.basePriceInputted || order.basePrice || order.ShowPrice || order.PermPrice || 0;
				}
			}

			//--------------------------------------------------->

			const isTeudaNumAlreadyExisting = this.AllOrder.filter(ord => ord.TeudaNum);

			//TODO refactor this after V613.2

			// let intDate = +new Date();

			this.NewCart = new Date().getTime();

			this.NewCart = this.NewCart + '000' + this._ss.AgentIdConnected;

			this.OrderInProgressLine = 'מספר תעודה' + this.NewCart + 'אנא המתן';

			console.log(isTeudaNumAlreadyExisting);

			if (isTeudaNumAlreadyExisting.length) {
				const { TeudaNum } = isTeudaNumAlreadyExisting[0];

				this.AllOrder = this.AllOrder.map(ord => ({ ...ord, TeudaNum }));

				this.NewCart = TeudaNum;
			} else {
				for (let x = 0; x < this.AllOrder.length; x++) {
					this.AllOrder[x].TeudaNum = this.NewCart;
				}
			}

			this._ss
				.AllOrderString({
					DATAString: JSON.stringify(this.AllOrder),
					TeudaType: this._ss.DocType,
					TeudaNum: this.NewCart,
					AgentID: this._ss.AgentIdConnected,
					Client_Name: this._ss.OneClientInfo.Client_Name,
					TeudaTotalSum: this.TotalSumOAllOrder,
					TotalLines: this.AllOrder.length,
					TeudaRemark: this._util.removeProblematicCharacters(this.HearaNewOrder || ''),
					Company: this._ss.OneClientInfo.company,
					Status: this.OrderStatusNumberSend,
				})
				.subscribe({
					next: res => {
						this.OrderInProgressLine = 'שולח תעודה';

						setTimeout(() => {
							this._ss.CheckConfirmedOrder(this.NewCart).subscribe(res => {
								this.ResForConfirmedOrder = res;
								this.ResForConfirmedOrder = this.ResForConfirmedOrder.recordset;

								setTimeout(() => {
									if (this.ResForConfirmedOrder.length === 0 || this.ResForConfirmedOrder === null || this.ResForConfirmedOrder === undefined) {
										this.SentArray[
											this.SentArray.indexOf(this.SentArray.find(ob => ob.id === 'id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company))
										].Status = 'נכשל';

										setTimeout(() => {
											document.getElementById('id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company).style.color = 'red';
										}, 200);

										this.orderError = true;
										this.OrderGood = false;
										this.DrivingTruckPop = false;
										this.BackArrowPop = true;
										this._ss.OrderInProcess = false;
										setTimeout(() => {
											this.SendOpenTeudotConfirm(this.WichToSend);
										}, 2000);
									} else {
										this.SentArray[
											this.SentArray.indexOf(this.SentArray.find(ob => ob.id === 'id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company))
										].Status = 'נשלח';
										setTimeout(() => {
											document.getElementById('id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company).style.color = 'green';
										}, 200);

										if (localStorage.getItem('DailyOrder') !== null) {
											this.DailyOrderAllorder = localStorage.getItem('DailyOrder');
											this.DailyOrderAllorder = JSON.parse(this.DailyOrderAllorder);
											this.DailyOrder = this.DailyOrderAllorder;
											this.AAABBB = [];
											for (let x = 0; x < this.AllOrder.length; x++) {
												this.AAABBB.push({
													Product_Name: this.AllOrder[x].Product_Name,
													TeudaQuantity: this.AllOrder[x].TeudaQuantity,
													TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
													Price: this.AllOrder[x].Price,
													TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
													TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
													TeudaNum: this.AllOrder[x].TeudaNum,
													Client_Name: this.AllOrder[x].Client_Name,
													TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
												});
											}
											this.DailyOrder.push({ Order: this.AAABBB });
											localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
										} else {
											this.AAABBB = [];
											for (let x = 0; x < this.AllOrder.length; x++) {
												this.AAABBB.push({
													Product_Name: this.AllOrder[x].Product_Name,
													TeudaQuantity: this.AllOrder[x].TeudaQuantity,
													TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
													Price: this.AllOrder[x].Price,
													TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
													TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
													TeudaNum: this.AllOrder[x].TeudaNum,
													Client_Name: this.AllOrder[x].Client_Name,
													TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
												});
											}
											this.DailyOrder.push({ Order: this.AAABBB });
											localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
										}

										localStorage.removeItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder1' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder2' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder3' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder4' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);

										this._ss.HearaNewOrder1 = this._ss.HearaNewOrder2 = this._ss.HearaNewOrder3 = this._ss.HearaNewOrder4 = '';

										this.BackArrowPop = true;
										this.OrderGood = true;
										this._ss.OrderInProcess = false;
										this.Vorder = true;
										this._ss.TeudotDetails = null;
										this._ss.Teudot = null;
										this._ss.TeudotAll = null;
										this.AllOrder = [];
										this.TotalSumOAllOrder = 0;

										for (let x = 0; x < this.AllClients.length; x++) {
											if (this._ss.OneClientInfo.ClientId === this.AllClients[x].ClientId) {
												this._ss.OneClientInfo.CustDiscount = this.AllClients[x].CustDiscount;
											}
										}

										setTimeout(() => {
											this.SendOpenTeudotConfirm(this.WichToSend);
										}, 2000);
									}
								}, 500);
							});
						}, 1500);
					},
					error: () => {
						this.SentArray[this.SentArray.indexOf(this.SentArray.find(ob => ob.id === 'id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company))].Status =
							'נכשל';

						setTimeout(() => {
							document.getElementById('id' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company).style.color = 'red';
						}, 200);

						this.orderError = true;
						this.OrderGood = false;
						this.DrivingTruckPop = false;
						this.BackArrowPop = true;
						this._ss.OrderInProcess = false;
						setTimeout(() => {
							this.SendOpenTeudotConfirm(this.WichToSend);
						}, 2000);
					},
				});
		}, 200);
	}

	shouldReceiptPopupShow = false;
	sendingReceipts = false;

	allreceiptsSent = false;

	async sendReceipts() {
		this.sendingReceipts = true;

		for (const rcpt of this._receipts.receiptsWaitingToBeSent) {
			const rcptWithoutName = { ...rcpt };

			delete rcptWithoutName.Client_Name;

			try {
				await new Promise<void>((resolve, reject) => {
					this._receipts.postNewReceipt(rcptWithoutName).subscribe({
						next: res => {
							resolve();
						},
						error: http => {
							if (http.error.msg === 'duplicate docnum detected') {
								return resolve();
							}

							reject();
						},
					});
				});

				rcpt.failed = false;

				this._receipts.receiptsWaitingToBeSent = this._receipts.receiptsWaitingToBeSent.filter(rcp => rcp.DocNum !== rcpt.DocNum);

				if (this._receipts.receiptsWaitingToBeSent.length) {
					const ls = compress(JSON.stringify(this._receipts.receiptsWaitingToBeSent));

					localStorage['receiptsToSend'] = ls;
				} else {
					localStorage.removeItem('receiptsToSend');
					this._receipts.chequesArray = [];
				}
			} catch (err) {
				rcpt.failed = true;
			}
		}

		this.sendingReceipts = false;
		this.allreceiptsSent = true;
	}

	ButtonsShowSentAllTeudotOne = false;
	SendOpenTeudotOnePop = false;
	SendOpenTeudotOne() {
		this.SendOpenTeudotOnePop = true;
	}

	CancelSendOpenTeudotOne() {
		this.SendOpenTeudotOnePop = false;
		window.location.reload();
	}

	async SendOpenTeudotOneConfirm(ClientId, TypeNum, company, Type) {
		if (TypeNum === null) {
			alert('ישנה שגיאה בשליחת התעודה');
			return;
		}

		// TODO Find out if ok to replace this with simple .find(), or might need the last client found (.filter()[length - 1])
		for (let x = 0; x < this.AllClientsSearch.length; x++) {
			if (this.AllClientsSearch[x].ClientId === ClientId && this.AllClientsSearch[x].company === company) {
				this._ss.OneClientInfo = this.AllClientsSearch[x];
			}
		}

		this._ss.DocType = TypeNum;
		this._ss.DocTypeName = Type;

		this.AllOrder = localStorage.getItem('AllOrder' + ClientId + TypeNum + company);
		this.AllOrder = JSON.parse(this.AllOrder);

		const isEmptyProduct = this.AllOrder.find(order => !order.TeudaPackQuantity && !order.TeudaQuantity && !order.TeudaBonusQuantity && order.SpecialID == null);

		if (isEmptyProduct) {
			alert(`שליחת תעודה ${isEmptyProduct.TeudaNum} בוטלה,\nפריט ${isEmptyProduct.Product_Name} ללא כמות.`);
			this.ButtonsShowSentAllTeudotOne = false;
			this.CancelSendOpenTeudotOne();
			return;
		}

		// check must-include-products
		const mustIncludeCheck = this._mustIncludeProducts.handleCheckMustIncludeProducts(this.AllOrder);

		switch (mustIncludeCheck.result) {
			case 'ok':
				break;
			case 'stop':
				this._alert.customAlert([`לא ניתן לסיים ללא מוצרי חובה.`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);
				this.ButtonsShowSentAllTeudotOne = false;
				this.dialog.afterAllClosed.subscribe(() => {
					this.CancelSendOpenTeudotOne();
				});
				return;
			case 'alert':
				const promptUser = await this._alert.customConfirmAlert([`שים לב, ישנם מוצרי חובה לתעודה זו. האם להמשיך?`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);

				if (!promptUser) {
					this.ButtonsShowSentAllTeudotOne = false;
					this.CancelSendOpenTeudotOne();
				}
				break;
			default:
				break;
		}

		this.ButtonsShowSentAllTeudotOne = true;

		this.TotalSumOAllOrder = 0;
		for (let x = 0; x < this.AllOrder.length; x++) {
			this.AllOrder[x].TeudaTotalRow = this.AllOrder[x].ArizotPrice + this.AllOrder[x].YehidotPrice;
			this.TotalSumOAllOrder += this.AllOrder[x].TeudaTotalRow;
		}

		this.TotalSumOAllOrderPlusVat = this.TotalSumOAllOrder;
		let y = 0;
		y = this.TotalSumOAllOrder * this._ss.OneClientInfo.CustVat;
		y /= 100;
		this.TotalSumOAllOrderPlusVat += y;

		this.CustDiscountShow = this.TotalSumOAllOrder;
		let aa = this._ss.OneClientInfo.CustDiscount * this.TotalSumOAllOrder;
		aa /= 100;
		this.CustDiscountShow -= aa;

		this.CustDiscountShowVat = this.CustDiscountShow;
		let bb = 0;
		bb = this.CustDiscountShow * this._ss.OneClientInfo.CustVat;
		bb /= 100;
		this.CustDiscountShowVat += y;

		if (this._ss.DocType === 74 || this._ss.DocType === 7) {
			this.TeudaOne();
		} else {
			if (
				this._ss.OneClientInfo.COD + this._ss.OneClientInfo.COC + this.TotalSumOAllOrder + (+this._ss.OneClientInfo.AddObligo || 0) < this._ss.OneClientInfo.CMC ||
				this._ss.OneClientInfo.BCO === 0
			) {
				this.TeudaOne();
			} else {
				if (this._ss.OneClientInfo.BCO === 1) {
					if (this.blockBeforeSendTeudaIfObligoWarning) {
						alert(' חסימה עקב חריגת אובליגו ');
						this.CancelSendOpenTeudotOne();

						return;
						// location.reload()
					}
					alert('התראה: חריגת אובליגו ');
					this.TeudaOne();
				}

				if (this._ss.OneClientInfo.BCO === 2) {
					this._ss.DocTypeName = 'הזמנה';
					this._ss.DocType = 11;
					alert(' חסימה עקב חריגת אובליגו ');
					this._ss.AllClientsHold = this.AllClients;
					this.LoadingClient = true;
					setTimeout(() => {
						this.handleNavigateToProductsPage();
					}, 100);
				}
			}
		}
	}

	SendOpenTeudotOnePopConfirm = false;

	CancelSendOpenTeudotOnePopConfirm() {
		this.SendOpenTeudotOnePopConfirm = false;
	}

	resetSpecialsInCart() {
		this.specialsInCart = {};
	}

	TeudaOne() {
		setTimeout(() => {
			this.orderProcess = true;
			this.orderSuccess = true;
			this._ss.OrderInProcess = true;
			this.OrderInProgressLine = 'מכין נתונים';

			this.BackArrowPop = false;
			setTimeout(() => {
				if (this.orderError === false && this.OrderGood === false) {
					this.orderError = true;
					this.OrderGood = false;
					this.DrivingTruckPop = false;
					this.BackArrowPop = true;
					this._ss.OrderInProcess = false;
				}
			}, 1000 * 60 * 3);

			this.HearaNewOrder = localStorage.getItem('HearaNewOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder1 = localStorage.getItem('HearaNewOrder1' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder2 = localStorage.getItem('HearaNewOrder2' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder3 = localStorage.getItem('HearaNewOrder3' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';
			this._ss.HearaNewOrder4 = localStorage.getItem('HearaNewOrder4' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) || '';

			this.TODOS();
			// todo1
			if (localStorage.getItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company) !== null) {
				this.SupplyDateTeuda = localStorage.getItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
			}

			if (this.hasSpecials) {
				this.tryToBuildSpecials();
				this.populateSpecials();
			}

			if (Object.getOwnPropertyNames(this.specialsInCart).length) {
				const orderWithoutSpecials = this.AllOrder.filter(ord => typeof ord.SpecialID !== 'number');

				this.handlePopulateProductHash();

				const specialTranslatedToProduct = Object.values(this.specialsInCart).reduce((prev, { special, quantity }) => {
					const specialInAllOrder = this.AllOrder.filter(ord => typeof ord.SpecialID === 'number').reduce((prev, curr) => {
						prev[curr.SpecialID] = curr;
						return prev;
					}, {});

					for (const spc of special) {
						const { TeudaQuantity, TeudaPackQuantity, TeudaBonusQuantity, TeudaItemNetPrice, ProductID, SpecialID, ID } = spc;

						const product = this.productsHash[ProductID.toLowerCase()];

						const specialInOrder = specialInAllOrder[SpecialID] || {};

						const { Product_Name, Price } = product;
						const CPP = product.CPP || 0;
						const ItemBarcode = product.ItemBarcode || '';
						const ItemPack = product.ItemPack || 0;
						const ItemPicName = product.ItemPicName || '';
						const Stock = product.Stock || 0;
						const BD = product.BD || 0;
						const BP = product.BP || 0;
						const BM = product.BM || 0;
						const MinPrice = product.MinPrice || 0;
						const qt = product.qt || 0;
						const BS = product.BS || 0;
						const PKD = product.PKD || 0;
						const ChangePrice = product.ChangePrice || 0;
						const ChangeDiscount = product.ChangeDiscount || 0;
						const IfChangePriceOrDisc = OrderState.noChangeToProduct;
						const Duplicate = specialInOrder.Duplicate || 'false';
						const DuplicateOrder = specialInOrder.DuplicateOrder || '';
						const PriceInInput = 0;
						const Ptve = specialInOrder.Ptve || '';
						const StatusInsertItem = specialInOrder.StatusInsertItem || '';
						const CPPTotalRow = 0;

						const YehidotPrice = TeudaQuantity * TeudaItemNetPrice;
						const ArizotPrice = TeudaPackQuantity * ItemPack * TeudaItemNetPrice;
						const TeudaTotalRow = YehidotPrice + ArizotPrice;

						prev.push({
							Stock,
							ProductId: ProductID,
							Product_Name,
							Price: TeudaItemNetPrice,
							ItemPack,
							ItemBarcode,
							ItemPicName,
							BD,
							BP,
							BM,
							MinPrice,
							qt,
							BS,
							PKD,
							CPP,
							ChangePrice,
							ChangeDiscount,
							YehidotPrice: YehidotPrice * quantity,
							ArizotPrice: ArizotPrice * quantity,
							TeudaQuantity: TeudaQuantity * quantity,
							TeudaPackQuantity: TeudaPackQuantity * quantity,
							TeudaBonusQuantity: TeudaBonusQuantity * quantity,
							IfChangePriceOrDisc,
							TeudaBonusPackQuantity: 0,
							TeudaRemark: '',
							TeudaItemRemark: 'מבצע ' + SpecialID,
							Duplicate,
							DuplicateOrder,
							PermPrice: Price,
							PriceInInput,
							Ptve,
							StatusInsertItem,
							TeudaTotalRow: TeudaTotalRow * quantity,
							CPPTotalRow,
							ProductName: Product_Name,
						});
					}

					return prev;
				}, []);

				this.AllOrder = [...orderWithoutSpecials, ...specialTranslatedToProduct];
			}

			//--------------------------------------------------->
			for (let x = 0; x < this.AllOrder.length; x++) {
				if (this.AllOrder[x].Duplicate !== 'false') {
					this.AllOrder[x].ProductId = this.AllOrder[x].Duplicate;
				}

				this.AllOrder[x].TeudaType = this._ss.DocType;
				this.AllOrder[x].Client_Id = this._ss.OneClientInfo.ClientId;
				this.AllOrder[x].TeudaCreateDate = this.Date;
				// this.AllOrder[x].TeudaCreateDate = this.AllOrder[x].TeudaCreateDate ?? this.Date
				// this.AllOrder[x].ProductId =  this.AllOrder[x].ProductIdNoChange

				// * Line is important, _ separation intended
				this.AllOrder[x].ProductName = this.AllOrder[x].Product_Name = this._util.removeProblematicCharacters(this.AllOrder[x].Product_Name || '');

				this.AllOrder[x].TeudaLineNum = x + 1;
				this.AllOrder[x].TeudaBonusPackQuantity = 0;
				this.AllOrder[x].TeudaPrice = this.AllOrder[x].PermPrice;
				this.AllOrder[x].TeudaItemNetPrice = this.AllOrder[x].Price;

				this.AllOrder[x].TeudaItemProfit = this.AllOrder[x].CPP ? ((this.AllOrder[x].Price - this.AllOrder[x].CPP) / this.AllOrder[x].Price) * 100 : 0;
				this.AllOrder[x].TeudaItemProfit = Math.max(Math.min(+this.AllOrder[x].TeudaItemProfit, 1000), -99.99);

				this.AllOrder[x].TeudaItemDiscount = 0;
				this.AllOrder[x].TeudaItemRemark = this._util.removeProblematicCharacters(this.AllOrder[x].TeudaItemRemark || '');
				this.AllOrder[x].TeudaTotalRow = this.AllOrder[x].YehidotPrice + this.AllOrder[x].ArizotPrice;
				this.AllOrder[x].TeudaTotalSumBrouto = 0;
				this.AllOrder[x].TeudaTotalSumBeforeVat = 0;
				this.AllOrder[x].TeudaTotalSumVat = this.TotalSumOAllOrderPlusVat - this.TotalSumOAllOrder;
				this.AllOrder[x].TeudaTotalSum = 0;
				this.AllOrder[x].TeudaProfit = 0;
				this.AllOrder[x].TeudaSupplyDate = this.SupplyDateTeuda;
				this.AllOrder[x].TeudaVat = this._ss.OneClientInfo.CustVat;
				this.AllOrder[x].TeudaDiscount = this.AllOrder[x].TeudaDiscount ?? 0;
				this.AllOrder[x].TeudaAgent = this._ss.AgentIdConnected;
				this.AllOrder[x].CartId = 0;
				this.AllOrder[x].Client_Name = this._util.removeProblematicCharacters(this._ss.OneClientInfo.Client_Name || '');
				this.AllOrder[x].Arizot = this.AllOrder[x].TeudaPackQuantity;
				this.AllOrder[x].Amount = this.AllOrder[x].TeudaQuantity;
				this.AllOrder[x].User_Name = this._ss.UserNameConnected;
				this.AllOrder[x].TeudaStatus = '';
				this.AllOrder[x].PrintStatus = '';
				this.AllOrder[x].EmailStatus = '';
				this.AllOrder[x].CustEmail = '';
				this.AllOrder[x].TeudaNumDupFrom = '';
				this.AllOrder[x].TuedaErpDocNum = '';
				this.AllOrder[x].TeuddaErpErrMessage = '';
				this.AllOrder[x].TeudaRemark = this._util.removeProblematicCharacters(this.AllOrder[x].TeudaRemark || this.HearaNewOrder || '');
				this.AllOrder[x].TeudaRemark1 = this._util.removeProblematicCharacters(this.AllOrder[x].TeudaRemark1 || this._ss.HearaNewOrder1 || '');
				this.AllOrder[x].TeudaRemark2 = this._util.removeProblematicCharacters(this.AllOrder[x].TeudaRemark2 || this._ss.HearaNewOrder2 || '');
				this.AllOrder[x].TeudaRemark3 = this._util.removeProblematicCharacters(this.AllOrder[x].TeudaRemark3 || this._ss.HearaNewOrder3 || '');
				this.AllOrder[x].TeudaRemark4 = this._util.removeProblematicCharacters(this.AllOrder[x].TeudaRemark4 || this._ss.HearaNewOrder4 || '');
				this.AllOrder[x].TeudaRemark5 = this._util.removeProblematicCharacters(this.AllOrder[x].TeudaRemark5 || '');
				this.AllOrder[x].TeudaDeliveryAddr = this._util.removeProblematicCharacters(this._ss.OneClientInfo.Address || '');
				this.AllOrder[x].TeudaDeliveryCity = this._util.removeProblematicCharacters(this._ss.OneClientInfo.CustCity || '');
				this.AllOrder[x].TeudaRef = '';
				this.AllOrder[x].TeudaCompany = '';
				this.AllOrder[x].TeudaUnitPerPack = this.AllOrder[x].ItemPack;
				// this.AllOrder[x].IfChangePriceOrDisc = 0
				this.AllOrder[x].TotalLines = this.AllOrder.length;
				this.AllOrder[x].PicName = '';
				this.AllOrder[x].Longitude = '';
				this.AllOrder[x].Latitude = '';
				this.AllOrder[x].TeudaUnitPerPack = this.AllOrder[x].ItemPack;
				this.AllOrder[x].ItemBarcode = this.AllOrder[x].ItemBarcode;
				this.AllOrder[x].TeudaTypeName = this._ss.DocTypeName;
				this.AllOrder[x].TeudaCompany = this._ss.OneClientInfo.company;
				this.AllOrder[x].Tve = this._ss.VersionNumber;
				this.AllOrder[x].Sent = 'Auto-Single';

				if (this.AllOrder[x].Type != null) {
					if (this.AllOrder[x].weightMode === 'weight') {
						this.AllOrder[x].Type = 1;
					}
					if (this.AllOrder[x].weightMode === 'singles') {
						this.AllOrder[x].Type = 2;
					}
				}
			}

			const doesHaveDiscountButMissingInSomeItems = this.AllOrder.find(ord => ord.TeudaDiscount);

			for (let x = 0; x < this.AllOrder.length; x++) {
				this.AllOrder[x].TeudaTotalSumBrouto = this.TotalSumOAllOrder;

				if (doesHaveDiscountButMissingInSomeItems) {
					this.AllOrder[x].TeudaDiscount = doesHaveDiscountButMissingInSomeItems.TeudaDiscount;
				} else {
					this.AllOrder[x].TeudaDiscount = this._ss.OneClientInfo.CustDiscount || 0;
				}

				let y = 100 - this._ss.OneClientInfo.CustDiscount;
				y *= this.TotalSumOAllOrder;
				y /= 100;
				this.AllOrder[x].TeudaTotalSumBeforeVat = y;

				let z = this._ss.OneClientInfo.CustVat / 100;
				z *= this.AllOrder[x].TeudaTotalSumBeforeVat;
				this.AllOrder[x].TeudaTotalSumVat = z;
				this.AllOrder[x].TeudaTotalSum = this.AllOrder[x].TeudaTotalSumBeforeVat + this.AllOrder[x].TeudaTotalSumVat;
			}

			for (let x = 0; x < this.AllOrder.length; x++) {
				if (this.AllOrder[x].DuplicateOrder === 'true') {
					this.OrderStatusNumberSend = '5';
				}

				if (this.AllOrder[x].Price !== this.AllOrder[x].PermPrice) {
					let y = this.AllOrder[x].PermPrice - this.AllOrder[x].Price;
					y /= this.AllOrder[x].PermPrice;
					y *= 100;

					this.AllOrder[x].TeudaItemDiscount = normalizeNumber(y);
					// this.AllOrder[x].IfChangePriceOrDisc = 1
					if (this.OrderStatusNumber === '2') {
						this.OrderStatusNumberSend = '2';
					}
				}
			}

			//--------------------------------------------------->

			const isTeudaNumAlreadyExisting = this.AllOrder.filter(ord => ord.TeudaNum);

			//TODO refactor this after V613.2

			// let intDate = +new Date();

			this.NewCart = new Date().getTime() + '000' + this._ss.AgentIdConnected;

			// this.NewCart = this.NewCart + "000" + this._ss.AgentIdConnected

			this.OrderInProgressLine = 'מספר תעודה' + this.NewCart + 'אנא המתן';

			console.log(isTeudaNumAlreadyExisting);

			if (isTeudaNumAlreadyExisting.length) {
				const { TeudaNum } = isTeudaNumAlreadyExisting[0];

				this.AllOrder = this.AllOrder.map(ord => ({ ...ord, TeudaNum }));

				this.NewCart = TeudaNum;
			} else {
				for (let x = 0; x < this.AllOrder.length; x++) {
					this.AllOrder[x].TeudaNum = this.NewCart;
				}
			}
			this._ss
				.AllOrderString({
					DATAString: JSON.stringify(this.AllOrder),
					TeudaType: this._ss.DocType,
					TeudaNum: this.NewCart,
					AgentID: this._ss.AgentIdConnected,
					Client_Name: this._ss.OneClientInfo.Client_Name,
					TeudaTotalSum: this.TotalSumOAllOrder,
					TotalLines: this.AllOrder.length,
					TeudaRemark: this._util.removeProblematicCharacters(this.HearaNewOrder || ''),
					Company: this._ss.OneClientInfo.company,
					Status: this.OrderStatusNumberSend,
				})
				.subscribe({
					next: res => {
						this.OrderInProgressLine = 'שולח תעודה';

						setTimeout(() => {
							this._ss.CheckConfirmedOrder(this.NewCart).subscribe(res => {
								this.ResForConfirmedOrder = res;
								this.ResForConfirmedOrder = this.ResForConfirmedOrder.recordset;

								setTimeout(() => {
									if (this.ResForConfirmedOrder.length === 0 || this.ResForConfirmedOrder === null || this.ResForConfirmedOrder === undefined) {
										this.orderError = true;
										this.OrderGood = false;
										this.DrivingTruckPop = false;
										this.BackArrowPop = true;
										this._ss.OrderInProcess = false;
										setTimeout(() => {
											alert('ישנה תקלה אנא נסה שנית');
										}, 2000);
									} else {
										if (localStorage.getItem('DailyOrder') !== null) {
											this.DailyOrderAllorder = localStorage.getItem('DailyOrder');
											this.DailyOrderAllorder = JSON.parse(this.DailyOrderAllorder);
											this.DailyOrder = this.DailyOrderAllorder;
											this.AAABBB = [];
											for (let x = 0; x < this.AllOrder.length; x++) {
												this.AAABBB.push({
													Product_Name: this.AllOrder[x].Product_Name,
													TeudaQuantity: this.AllOrder[x].TeudaQuantity,
													TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
													Price: this.AllOrder[x].Price,
													TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
													TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
													TeudaNum: this.AllOrder[x].TeudaNum,
													Client_Name: this.AllOrder[x].Client_Name,
													TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
												});
											}
											this.DailyOrder.push({ Order: this.AAABBB });
											localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
										} else {
											this.AAABBB = [];
											for (let x = 0; x < this.AllOrder.length; x++) {
												this.AAABBB.push({
													Product_Name: this.AllOrder[x].Product_Name,
													TeudaQuantity: this.AllOrder[x].TeudaQuantity,
													TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
													Price: this.AllOrder[x].Price,
													TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
													TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
													TeudaNum: this.AllOrder[x].TeudaNum,
													Client_Name: this.AllOrder[x].Client_Name,
													TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
												});
											}
											this.DailyOrder.push({ Order: this.AAABBB });
											localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
										}

										localStorage.removeItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder1' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder2' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder3' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('HearaNewOrder4' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);
										localStorage.removeItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company);

										this._ss.HearaNewOrder1 = this._ss.HearaNewOrder2 = this._ss.HearaNewOrder3 = this._ss.HearaNewOrder4 = '';

										this.BackArrowPop = true;
										this.OrderGood = true;
										this._ss.OrderInProcess = false;
										this.Vorder = true;
										this._ss.TeudotDetails = null;
										this._ss.Teudot = null;
										this._ss.TeudotAll = null;
										this.AllOrder = [];
										this.TotalSumOAllOrder = 0;

										for (let x = 0; x < this.AllClients.length; x++) {
											if (this._ss.OneClientInfo.ClientId === this.AllClients[x].ClientId) {
												this._ss.OneClientInfo.CustDiscount = this.AllClients[x].CustDiscount;
											}
										}

										setTimeout(() => {
											this.AllOpenTeudaArray = [];
											for (let x = 0; x < this.AllClientsSearch.length; x++) {
												const { ClientId, company, Client_Name } = this.AllClientsSearch[x];
												let docMinArray: MinDocTeudaBaseWithActionAndDoctype[] = [];
												if (this.hasMinDocTeuda) {
													docMinArray = this._docMin.getRelevantDocMinTeudaWithoutDoctype(company, ClientId);
												}
												if (localStorage.getItem('AllOrder' + ClientId + '11' + company) !== null) {
													if (this.hasMinDocTeuda) {
														const docMin = docMinArray.find(doc => doc.doctype === 11);

														if (docMin?.minteuda > JSON.parse(localStorage.getItem('AllOrder' + ClientId + '11' + company)).reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
															continue;
														}
													}
													this._ss.OneClientInfo = this.AllClientsSearch[x];
													this.AllOpenTeudaArray.push({
														Client_Name: Client_Name,
														Status: 'בשליחה..',
														Type: 'הזמנה',
														TypeNum: 11,
														ClientId: ClientId,
														company: company,
													});
												}

												if (localStorage.getItem('AllOrder' + ClientId + '170' + company) !== null) {
													if (this.hasMinDocTeuda) {
														const docMin = docMinArray.find(doc => doc.doctype === 170);

														if (docMin?.minteuda > JSON.parse(localStorage.getItem('AllOrder' + ClientId + '170' + company)).reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
															continue;
														}
													}
													this._ss.OneClientInfo = this.AllClientsSearch[x];
													this.AllOpenTeudaArray.push({
														Client_Name: Client_Name,
														Status: 'בשליחה..',
														Type: 'הזמנה',
														TypeNum: 170,
														ClientId: ClientId,
														company: company,
													});
												}
												if (localStorage.getItem('AllOrder' + ClientId + '171' + company) !== null) {
													if (this.hasMinDocTeuda) {
														const docMin = docMinArray.find(doc => doc.doctype === 171);

														if (docMin?.minteuda > JSON.parse(localStorage.getItem('AllOrder' + ClientId + '171' + company)).reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
															continue;
														}
													}
													this._ss.OneClientInfo = this.AllClientsSearch[x];
													this.AllOpenTeudaArray.push({
														Client_Name: Client_Name,
														Status: 'בשליחה..',
														Type: 'הזמנה',
														TypeNum: 171,
														ClientId: ClientId,
														company: company,
													});
												}
												if (localStorage.getItem('AllOrder' + ClientId + '172' + company) !== null) {
													if (this.hasMinDocTeuda) {
														const docMin = docMinArray.find(doc => doc.doctype === 172);

														if (docMin?.minteuda > JSON.parse(localStorage.getItem('AllOrder' + ClientId + '172' + company)).reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
															continue;
														}
													}
													this._ss.OneClientInfo = this.AllClientsSearch[x];
													this.AllOpenTeudaArray.push({
														Client_Name: Client_Name,
														Status: 'בשליחה..',
														Type: 'הזמנה',
														TypeNum: 172,
														ClientId: ClientId,
														company: company,
													});
												}
												if (localStorage.getItem('AllOrder' + ClientId + '7' + company) !== null) {
													if (this.hasMinDocTeuda) {
														const docMin = docMinArray.find(doc => doc.doctype === 7);

														if (docMin?.minteuda > JSON.parse(localStorage.getItem('AllOrder' + ClientId + '7' + company)).reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
															continue;
														}
													}
													this._ss.OneClientInfo = this.AllClientsSearch[x];
													this.AllOpenTeudaArray.push({
														Client_Name: Client_Name,
														Status: 'בשליחה..',
														Type: 'הצעת מחיר',
														TypeNum: 7,
														ClientId: ClientId,
														company: company,
													});
												}
												if (localStorage.getItem('AllOrder' + ClientId + '74' + company) !== null) {
													if (this.hasMinDocTeuda) {
														const docMin = docMinArray.find(doc => doc.doctype === 74);

														if (docMin?.minteuda > JSON.parse(localStorage.getItem('AllOrder' + ClientId + '74' + company)).reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
															continue;
														}
													}
													this._ss.OneClientInfo = this.AllClientsSearch[x];
													this.AllOpenTeudaArray.push({
														Client_Name: Client_Name,
														Status: 'בשליחה..',
														Type: 'ת. החזרה',
														TypeNum: 74,
														ClientId: ClientId,
														company: company,
													});
												}

												if (localStorage.getItem('AllOrder' + ClientId + '88' + company) !== null) {
													if (this.hasMinDocTeuda) {
														const docMin = docMinArray.find(doc => doc.doctype === 88);

														if (docMin?.minteuda > JSON.parse(localStorage.getItem('AllOrder' + ClientId + '88' + company)).reduce((p, c) => p + +c.TeudaTotalRow, 0)) {
															continue;
														}
													}
													this._ss.OneClientInfo = this.AllClientsSearch[x];
													this.AllOpenTeudaArray.push({
														Client_Name: Client_Name,
														Status: 'בשליחה..',
														Type: 'הזמנה משריון',
														TypeNum: 88,
														ClientId: ClientId,
														company: company,
													});
												}
											}
											this.ButtonsShowSentAllTeudotOne = false;
											this.SendOpenTeudotOnePopConfirm = true;
										}, 2000);
									}
								}, 500);
							});
						}, 1500);
					},
					error: () => {
						this.orderError = true;
						this.OrderGood = false;
						this.DrivingTruckPop = false;
						this.BackArrowPop = true;
						this._ss.OrderInProcess = false;
						setTimeout(() => {
							alert('ישנה תקלה אנא נסה שנית');
							this.CancelSendOpenTeudotOne();
						}, 1000);
					},
				});
		}, 200);
	}

	handleFillDoctypeParametersAndNavigateToProducts() {
		if (!Array.isArray(this.AllDocTypesSave)) {
			alert('ישנה תקלה בעת כניסה ללקוח, אנא עדכנו נתונים או נסו שנית מאוחר יותר');
			history.back();
			return;
		}
		let doctypeNum = 0;
		const { company, ClientId } = this._ss.OneClientInfo;

		if (this._ss.OneClientInfo.AO === 1) {
			doctypeNum = 11;
		}
		if (this._ss.OneClientInfo.AM === 1) {
			doctypeNum = 7;
		}

		const doc = this.AllDocTypesSave.find(doc => doc.Company === company && doc.ClientID === ClientId && doc.DocType === doctypeNum);

		if (doc) {
			this.MoveToProductsOrderType(doc.DocType, doc.DocName, doc.IsShrion, doc.NoObligoCheck, doc.CheckMinPrice, doc.MustCustRem1, doc.MustCustRem2, doc.MustCustRem3, doc.MustCustRem4);
		}
	}

	handleGetDoctypeParametersBeforeNavigateToProducts() {
		const { company, ClientId } = this._ss.OneClientInfo;

		if (localStorage.getItem('AllDocTypesSave') !== null) {
			this.DocTypePerClient = [];
			this.AllDocTypesSave = decompress(localStorage.getItem('AllDocTypesSave'));
			this.AllDocTypesSave = JSON.parse(this.AllDocTypesSave);

			this.handleFillDoctypeParametersAndNavigateToProducts();
		} else {
			this._ss.GetDocTypePerClientAll().subscribe(
				res => {
					this.DocTypePerClient = [];
					this.AllDocTypesSave = res;
					this.AllDocTypesSave = this.AllDocTypesSave.recordset;

					for (let x = 0; x < this.AllDocTypesSave.length; x++) {
						this.AllDocTypesSave[x].Show = 1;
					}

					localStorage.setItem('AllDocTypesSave', compress(JSON.stringify(this.AllDocTypesSave)));

					this.handleFillDoctypeParametersAndNavigateToProducts();
				},
				err => {
					this._ss.GetDoTypePerClient(ClientId, company).subscribe(
						res => {
							this.DocTypePerClient = [];
							this.DocTypePerClient = res;
							this.DocTypePerClient = this.DocTypePerClient.recordset;
							for (let x = 0; x < this.DocTypePerClient.length; x++) {
								this.DocTypePerClient[x].Show = 1;
							}
						},
						err => {
							alert('ישנה תקלה בעת כניסה ללקוח, אנא עדכנו נתונים או נסו שנית מאוחר יותר');
							history.back();
							return;
						},
					);
				},
			);
		}
	}

	handleSettingUpItemExtraArrays() {
		if (!this.AllProducts || !this.AllProducts.length) {
			this.AllProducts = decompress(localStorage.getItem('AllProducts'));
			this.AllProducts = JSON.parse(this.AllProducts);
		}

		const itemExtraObject: { [key: string]: Set<string> } = {};
		const numArr = ['1', '2', '3', '4', '5'];

		// ItemExtra1, ItemExtra2...
		for (const num of numArr) {
			itemExtraObject[`itemExtra${num}Set`] = new Set();
		}

		for (let x = 0; x < this.AllProducts.length; x++) {
			for (const num of numArr) {
				const itemExtra = this.AllProducts[x][`ItemExtra${num}`];

				if (itemExtra && itemExtra.replaceAll(' ', '')) {
					itemExtraObject[`itemExtra${num}Set`].add(this.AllProducts[x][`ItemExtra${num}`]);
				}
			}
		}

		for (const num of numArr) {
			this._ss.itemExtraObject[`itemExtra${num}`] = [...itemExtraObject[`itemExtra${num}Set`]];

			const currentItemExtra = this._ss.itemExtraObject[`itemExtra${num}`];

			if (!currentItemExtra.length || !currentItemExtra[0]) {
				delete this._ss.itemExtraObject[`itemExtra${num}`];
			}
		}

		this._ss.ItemExtra = [...itemExtraObject['itemExtra1Set']];
		console.log(this._ss.itemExtraObject);
	}

	handleBikurShortcutNavigate(client: AllClients, openOnExit = false) {
		this._ss.OneClientInfo = client;

		if (this._ss.CustomerInformation.CustChange1 === '1' || this._ss.CustomerInformation.CustChange1 === '2') {
			if (!openOnExit && client.Knisa !== 'true') {
				const date = new Date();
				localStorage.setItem('knisa' + this._ss.OneClientInfo.ClientId, JSON.stringify(date));

				alert(' כניסה ללקוח ' + this._ss.OneClientInfo.Client_Name + ' בשעה ' + date.toString().slice(15, 24));

				this.PendingBikor.push({ Knisa: this._ss.OneClientInfo.ClientId });

				localStorage.setItem('PendingBikor', JSON.stringify(this.PendingBikor));
				client.Knisa = 'true';
				return;
			}
		}

		const clientActivMap = {
			'0': 'client-activ',
			'1': 'client-activ2',
			'2': 'client-activ3',
		};

		const goTo = clientActivMap[this._ss.CustomerInformation.CustChange1 || '0'] || 'client-activ';

		this.router.navigate([goTo], { queryParams: { exit: openOnExit ? true : null } });
	}

	handleCheckingTeudotAlreadySentButFailed() {
		if (!this.failedTeudotArray.length) return;

		const observableArray = this.failedTeudotArray.map(order => ({ observable: this._ss.CheckConfirmedOrder(order[0].TeudaNum), order }));

		console.log(observableArray);

		for (const obs of observableArray) {
			obs.observable.pipe(takeUntil(this.destroy$)).subscribe({
				next: (res: any) => {
					if (res.recordset) {
						if (res.recordset.length) {
							console.log(this.failedTeudotArray);

							const { TeudaNum } = res.recordset;
							const order = this.failedTeudotArray.find(t => t[0].TeudaNum === TeudaNum);

							if (order) {
								console.log('found duplicate order:', order);
							}
							return;
						}

						if (obs.order) {
							for (const line of obs.order) {
								delete line.failed;
							}

							const { Client_Id } = obs.order[0];

							console.log(obs.order);

							const client = this.AllClients.find(ac => ac.ClientId === Client_Id);

							console.log({ client });

							delete client.Failed;

							const { TeudaType, TeudaCompany } = obs.order[0];
							localStorage.setItem('AllOrder' + Client_Id + TeudaType + TeudaCompany, JSON.stringify(obs.order));
						}
					}
				},
				error: err => {
					console.log('error, probably no internet', err);
				},
			});
		}
	}

	async updateAllLocalStorage() {
		localStorage.removeItem('ManualUpdate');
		localStorage.removeItem('Updating');
		localStorage.removeItem('SelectHearot');

		this.Loading = true;

		this.Status = 'מעדכן מוצרים...';

		let getAllMGroups = false;

		if (this.ManualUpdate === 'false') {
			localStorage.removeItem('DailyOrder');
			localStorage.removeItem('FinishBikor');
			localStorage.removeItem('LocalSaveTrucksSent');
		}

		//TODO Block can be refactored into loop with promise factory...

		try {
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetAllProducts()
					.pipe(catchError(() => throwError(() => reject())))
					.subscribe({
						next: res => {
							if (typeof res === 'string') {
								return reject();
							}

							const keys = Object.keys(res.recordset[0]);

							getAllMGroups = keys.includes('MGroup');

							localStorage.setItem('AllProducts', compress(JSON.stringify(res.recordset)));
							localStorage.setItem('lastupdate', JSON.stringify(this.Date.toISOString()));
							localStorage.setItem('refresh', JSON.stringify(this.Date.toISOString()));

							resolve();
						},
					});
			});
		} catch (error) {
			alert('תקלה בשרת, לא עודכנו מוצרים חדשים');

			if (this._ss.isMovingToDohot) {
				let route = 'dohot';

				if (this._b2b.isB2BAgent$.value) {
					route += '-b2b';
				}

				this.router.navigate([route], { replaceUrl: true });
				return;
			}

			window.location.reload();
		}

		this.Status = 'מעדכן סוגי תעודות...';
		try {
			localStorage.setItem('alerted', 'false');
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetDocTypePerClientAll()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (!res.recordset) return resolve();

							this.DocTypePerClient = [];

							this.AllDocTypesSave = this._ss.allDoctypeNames = res.recordset;

							for (let x = 0; x < this.AllDocTypesSave.length; x++) {
								this.AllDocTypesSave[x].Show = 1;
							}

							localStorage.setItem('AllDocTypesSave', compress(JSON.stringify(this.AllDocTypesSave)));

							resolve();
						},
					});
			});
			await new Promise<void>(resolve => {
				this._ss
					.GetAllDoctypesDefinitions()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: res => {
							if (typeof res === 'string' || !res.recordset) return resolve();

							this._doctype.doctypesDefinitions = res.recordset;

							localStorage.setItem('DocTypesDefinitions', compress(JSON.stringify(res.recordset)));

							resolve();
						},
					});
			});

			if (getAllMGroups) {
				this.Status = 'מעדכן תת-מחלקות וקבוצות';
				await new Promise<void>(resolve => {
					this._ss
						.getAllMGroups()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									localStorage.setItem('MGroups', compress(JSON.stringify(res.recordset)));
								}

								resolve();
							},
						});
				});
			}

			this.Status = 'מעדכן הערות...';
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetHearotForTeuda()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: res => {
							if (typeof res === 'string') return resolve();

							if (!res.recordset.length) {
								this._ss.HearotHeadForTeuda = null;
								return resolve();
							}

							this._ss.HearotHeadForTeuda = res.recordset;

							localStorage.setItem('HearotHeadForTeuda', compress(JSON.stringify(res.recordset)));

							resolve();
						},
					});
			});

			this.Status = 'מעדכן מחירים...';
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetAllProductsandpricesnew()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (!res.recordset) return resolve();

							this.AllProductsPricess = res.recordset;

							// allproductspricessnew
							// v_allproducts2comp
							localStorage.setItem('AllProductsPricess', compress(JSON.stringify(this.AllProductsPricess)));

							resolve();
						},
					});
			});

			this.Status = 'מעדכן שריונים...';
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetAllPricesShirionAll()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (!res.recordset) return resolve();

							this.ShirionProducts = res.recordset;

							localStorage.setItem('ShirionProducts', compress(JSON.stringify(this.ShirionProducts)));

							resolve();
						},
					});
			});

			this.Status = 'מעדכן חובות לקוחות...';
			await new Promise<void>((resolve, reject) => {
				this._ss

					.GetAllClientsInfoDebt()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (!res.recordset) return resolve();

							try {
								localStorage.setItem('AllHovotHeaders', compress(JSON.stringify(res.recordset)));
							} catch (error) {
								console.log(error);
							}

							resolve();
						},
					});
			});

			this.Status = 'מעדכן מוצרי לקוחות...';
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetAllPricesAll()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (!res.recordset) return resolve();
							this.SpecPriceSs = res.recordset;

							try {
								// V_AllProducts2Comp
								localStorage.setItem('SpecPriceSs', compress(JSON.stringify(this.SpecPriceSs)));
							} catch (error) {
								console.log(error);
							}

							resolve();
						},
					});
			});

			this.Status = 'מעדכן מחירונים...';
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetPricesNew()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (!res.recordset) return resolve();

							try {
								localStorage.setItem('_ss.Mehironim', compress(JSON.stringify(res.recordset)));
							} catch (error) {
								console.log(error);
							}
							resolve();
						},
					});
			});

			this.Status = 'מעדכן מגוונים...';
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetMigvanFilterNew()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset) return resolve();

							try {
								localStorage.setItem('_ss.FilterMigvan', compress(JSON.stringify(res.recordset)));
							} catch (error) {
								console.log(error);
							}

							return resolve();
						},
					});
			});

			this.Status = 'מעדכן מסלולים...';
			await new Promise<void>((resolve, reject) => {
				this._ss
					.GetMaslol()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset) return resolve();

							localStorage.setItem('Maslul', compress(JSON.stringify(res.recordset)));
							resolve();
						},
					});
			});

			this.Status = 'מעדכן נתונים...';
			await new Promise<void>(resolve => {
				this._ss
					.GetCustInfo(this._ss.CustomerInformation.CustNum)
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: res => {
							if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset) return resolve();

							this._ss.CustomerInformation = res.recordset[0];

							localStorage.setItem('CustomerInformation', compress(JSON.stringify(this._ss.CustomerInformation)));

							this._receipts.refreshReceiptsParameters();
							this._text.refreshCustomerParameters();

							resolve();
						},
					});
			});

			await new Promise<void>(resolve => {
				const agentId = localStorage.getItem('AgentId');
				const password = localStorage.getItem('Password');

				if (!agentId || !password) return resolve();

				this._ss
					.loginService(agentId, password)
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe(async res => {
						if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset.length) {
							return resolve();
						}

						const verifiedUser = res.recordset[0];

						this._ss.UserNameConnected = verifiedUser.UserName;
						this._ss.AgentIdConnected = verifiedUser.AgentID;

						localStorage.setItem('HasMultipleCompanys', verifiedUser.AgentExtra2);
						localStorage.setItem('UserName', verifiedUser.UserName);
						localStorage.setItem('SeeTotalPrice', verifiedUser.AgentExtra1);
						localStorage.setItem('OrderStatus', verifiedUser.AgentPhoneMobile);
						localStorage.setItem('Admin', verifiedUser.Admin);
						localStorage.setItem('HasMainGroup', String(verifiedUser.HasMainGroup));
						localStorage.setItem('IsHistFirst', String(verifiedUser.IsHistFirst));
						localStorage.setItem('hazaon', String(verifiedUser.hazaon));
						localStorage.setItem('ordon', String(verifiedUser.ordon));
						localStorage.setItem('reton', String(verifiedUser.reton));
						localStorage.setItem('ShowProfit', String(verifiedUser.ShowProfit));
						localStorage.setItem('AddProduct', String(verifiedUser.AddProduct));
						localStorage.setItem('CustNum', String(verifiedUser.CustNum));
						localStorage.setItem('IgnorMinPrice', String(verifiedUser.IgnorMinPrice));

						if (verifiedUser.Email) {
							localStorage.setItem('AgentEmail', verifiedUser.Email);
							this._user.updateEmail(verifiedUser.Email);
						}

						resolve();
					});
			});

			this.Status = 'מעדכן מחירונים...';
			await new Promise<void>(resolve => {
				this._ss
					.GetGroupDiscounts()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: res => {
							if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset) return resolve();

							localStorage.setItem('allGroupDiscounts', compress(JSON.stringify(res.recordset)));

							resolve();
						},
					});
			});

			this.Status = 'מעדכן הערות...';
			await new Promise<void>(resolve => {
				this._ss
					.SelectHearot(this._ss.CompanyNum)
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset) return resolve();

							this._ss.selectHearotForTeuda = res.recordset;

							localStorage.setItem('SelectHearot', JSON.stringify(this._ss.selectHearotForTeuda));

							resolve();
						},
					});
			});

			this.Status = 'מעדכן מבצעים...';
			await new Promise<void>(resolve => {
				this._ss
					.getAllSpecials()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: res => {
							if (res === 'ConnectionError' || res === 'RequestError' || !res.recordset) return resolve();

							this._ss.allSpecials = res.recordset;

							localStorage.setItem('allSpecials', JSON.stringify(this._ss.allSpecials));

							resolve();
						},
					});
			});

			this.Status = 'מעדכן תעודות מיוחדות...';
			await new Promise<void>(resolve => {
				this._ss
					.getDocSpecialProducts()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: (res: any) => {
							if (res !== 'ConnectionError' && res !== 'RequestError') {
								localStorage.setItem('DocSpecialProducts', compress(JSON.stringify(res.recordset)));
							}

							resolve();
						},
					});
			});

			this.Status = 'מעדכן התראות...';
			await new Promise<void>(resolve => {
				this._ss
					.getAlerts()
					.pipe(catchError(() => throwError(() => resolve())))
					.subscribe({
						next: res => {
							if (res !== 'ConnectionError' && res !== 'RequestError') {
								this._alert.Alerts = res.recordset.map(alert => ({ ...alert, JSON: typeof alert.JSON === 'string' ? JSON.parse(alert.JSON) : alert.JSON }));
								localStorage.setItem('Alerts', compress(JSON.stringify(this._alert.Alerts)));
							}

							resolve();
						},
					});
			});

			if (this._ss.CustomerInformation.GetOriginalStock === '1') {
				this.Status = 'מעדכן מלאי';
				await new Promise<void>(resolve => {
					this._ss
						.getStockFromStockTable()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									localStorage.setItem('StockOriginal', compress(JSON.stringify(res.recordset)));
								}

								resolve();
							},
						});
				});
			}
			if (this._ss.CustomerInformation.ConcentratedProducts === '1') {
				this.Status = 'מעדכן מוצרים מרכזים';
				await new Promise<void>(resolve => {
					this._ss
						.getConcentrateProducts()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									localStorage.setItem('Concentrate', compress(JSON.stringify(res.recordset)));
								}

								resolve();
							},
						});
				});
			}
			if (this._ss.CustomerInformation.HasDocMinTeuda === '1') {
				this.Status = 'מעדכן מינימום תעודה';
				await new Promise<void>(resolve => {
					this._ss
						.getAllDocMinTeuda()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									this._docMin.storeMinDocTeuda(res.recordset);
								}

								resolve();
							},
						});
				});
			}

			if (this._ss.CustomerInformation.ProductWeightType === '1') {
				this.Status = 'מעדכן כמויות לאריזה/קופסה';
				await new Promise<void>(resolve => {
					this._ss
						.getItemPacks()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									localStorage.setItem('ItemPacks', compress(JSON.stringify(res.recordset)));
								}

								resolve();
							},
						});
				});
			}

			if (this._ss.CustomerInformation.HasRallyanceReclamation === '1') {
				this.Status = 'מעדכן סיבות רקלמציה';
				await new Promise<void>(resolve => {
					this._reclamation
						.getAllReclamationReasons()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									localStorage.setItem(this._reclamation.reasonsStorageName, compress(JSON.stringify(res.recordset)));
								}

								resolve();
							},
						});
				});
			}

			if (this._receipts.sendAllOpenReceiptsOnMorningDBUpdate) {
				this.Status = 'שולח קבלות פתוחות';

				const ls = localStorage['receiptsToSend'];
				if (ls) {
					this._receipts.receiptsWaitingToBeSent = JSON.parse(decompress(ls));

					for (const rcpt of this._receipts.receiptsWaitingToBeSent) {
						const rcptWithoutName = { ...rcpt };

						delete rcptWithoutName.Client_Name;

						try {
							await new Promise<void>((resolve, reject) => {
								this._receipts.postNewReceipt(rcptWithoutName).subscribe({
									next: res => {
										resolve();
									},
									error: http => {
										if (http.error.msg === 'duplicate docnum detected') {
											return resolve();
										}

										reject();
									},
								});
							});

							rcpt.failed = false;

							this._receipts.receiptsWaitingToBeSent = this._receipts.receiptsWaitingToBeSent.filter(rcp => rcp.DocNum !== rcpt.DocNum);

							if (this._receipts.receiptsWaitingToBeSent.length) {
								const ls = compress(JSON.stringify(this._receipts.receiptsWaitingToBeSent));

								localStorage['receiptsToSend'] = ls;
							} else {
								localStorage.removeItem('receiptsToSend');
								this._receipts.chequesArray = [];
							}
						} catch (err) {
							rcpt.failed = true;
						}
					}
				}
			}
			if (this._ss.CustomerInformation.MustIncludeProducts === '1') {
				this.Status = 'מעדכן מוצרי חובה';
				await new Promise<void>(resolve => {
					this._mustIncludeProducts
						.getMustIncludeProducts()
						.pipe(catchError(() => of('')))
						.subscribe({
							next: res => {
								if (typeof res !== 'string' && res.recordset.length) {
									this._mustIncludeProducts.replaceStoredMustIncludeProducts(res.recordset);
								}

								resolve();
							},
						});
				});
			}
			if (this._receipts.useBanksList) {
				this.Status = 'מוריד רשימת בנקים';
				await new Promise<void>(resolve => {
					this._receipts
						.getBanksList()
						.pipe(catchError(() => throwError(() => resolve())))
						.subscribe({
							next: res => {
								if (res !== 'ConnectionError' && res !== 'RequestError') {
									localStorage.setItem('Banks', compress(JSON.stringify(res.recordset)));
								}

								resolve();
							},
						});
				});
			}
			this.Status = 'מייד מסיימים...';
			setTimeout(() => {
				if (this._ss.isMovingToDohot) {
					let route = 'dohot';

					if (this._b2b.isB2BAgent$.value) {
						route += '-b2b';
					}

					this.router.navigate([route], { replaceUrl: true });
					return;
				}
				window.location.reload();
			}, 700);
		} catch (error) {
			this.Status = 'מייד מסיימים...';

			setTimeout(() => {
				if (this._ss.isMovingToDohot) {
					let route = 'dohot';

					if (this._b2b.isB2BAgent$.value) {
						route += '-b2b';
					}

					this.router.navigate([route], { replaceUrl: true });
					return;
				}
				window.location.reload();
			}, 300);
		}
	}

	handlePopulateProductHash() {
		if (!Object.getOwnPropertyNames(this.productsHash).length) {
			const products = localStorage.getItem('AllProducts');

			if (!products) {
				localStorage.removeItem('lastupdate');
				alert('ישנה תקלה, אנא עדכנו נתונים ונסו שנית');
				this.router.navigate(['home']);
			}

			this.productsHash = JSON.parse(decompress(products)).reduce((prev, curr) => {
				prev[(curr.ProductId || '').toLowerCase()] = curr;
				return prev;
			}, {});
		}
	}

	populateSpecials() {
		this.resetSpecialsInCart();
		for (const ord of this.AllOrder) {
			if (typeof ord.SpecialID !== 'number') {
				continue;
			}

			this.specialsInCart[ord.SpecialID] = { special: this.allSpecials.filter(sp => sp.SpecialID === ord.SpecialID), quantity: ord.quantity };
			const special = this.specialsInCart[ord.SpecialID];
			ord.SpecialName = special.special[0].Name;
		}
	}

	tryToBuildSpecials() {
		if (this._ss.allSpecials.length) {
			this.allSpecials = this._ss.allSpecials;
			return;
		}

		const hasAllSpecials = this._storage.getAndParseFromStorage('allSpecials', { onlyCheckIfExist: true });
		if (!hasAllSpecials) {
			this.allSpecials = [];
			return;
		}

		this.allSpecials = this._ss.allSpecials = this._storage.getAndParseFromStorage('allSpecials');

		if (this._ss.allSpecials.length) {
			const today = new ChangedDate().getChangedTime().getTime();

			this._ss.allSpecials = this.allSpecials = this.allSpecials.filter(
				sp => sp.SpecialStatus !== 1 && ((new Date(sp.StartDate).getTime() <= today && new Date(sp.EndDate).getTime() >= today) || sp.SpecialStatus === 2),
			);

			for (const ord of this.AllOrder) {
				if (typeof ord.SpecialID === 'number') {
					this.specialsInCart[ord.SpecialID] = { special: this.allSpecials.filter(sp => sp.SpecialID === ord.SpecialID), quantity: ord.quantity };
				}
			}
		}
	}

	createNoSaleReportAndNavigateToClientActiv() {
		const date = new Date();
		const clientid = this._ss.OneClientInfo.ClientId;
		localStorage.setItem('knisa' + clientid, JSON.stringify(date));
		localStorage.setItem('PendingBikor', JSON.stringify({ Knisa: clientid }));

		this.handleBikurShortcutNavigate(this._ss.OneClientInfo, true);
	}

	TODOS() {
		// 1
		// TODO check if supposed to stay unassigned
		//2
		// This is a recursive loop which keeps calling Teuda() and SendOpenTeudotConfirm() which then calls SendingTeuda() back, until all teudot are sent.
		// It should be clearer, and easier to work with, in case of changes/breakpoints insertion in the future.
		// TODO Refactor process.
	}
}
