import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, map, startWith, Subject, takeUntil } from 'rxjs';
import { TruckDistributionMainSQL, TruckDistributionUsers } from 'src/app/Interfaces/truck-distribution';
import { MapDistributionService } from 'src/app/services/map-distribution.service';
import { MarkerInfo, MarkerService } from 'src/app/services/marker.service';
import { TextService } from 'src/app/services/text.service';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { Time, UtilService } from 'src/app/services/util.service';
import { translateDateForSQLServer } from 'src/app/Utility/functions';
import { MapDistributionPopupComponent } from './map-distribution-popup/map-distribution-popup.component';

@Component({
	selector: 'app-map-distribution',
	templateUrl: './map-distribution.component.html',
	styleUrls: ['./map-distribution.component.scss'],
})
export class MapDistributionComponent implements OnInit {
	constructor(
		public _util: UtilService,
		public _text: TextService,
		public _truckDistribution: TruckDistributionService,
		private _dialog: MatDialog,
		public _mapDistribution: MapDistributionService,
		private _marker: MarkerService,
	) {}

	allTruckDistributionTeudot: TruckDistributionMainSQL[] = [];

	destroy$ = new Subject<void>();

	coordinates$ = new BehaviorSubject<MarkerInfo<TruckDistributionMainSQL>[]>([]);
	relevantTasks$ = new BehaviorSubject<MarkerInfo<TruckDistributionMainSQL>[]>([]);
	shouldMapShow$ = this.coordinates$.pipe(map(array => !!array.length));
	recalculateMap$ = new BehaviorSubject(false);

	agentsControl = new FormControl(this._truckDistribution.usersForAssignment$.value);
	shouldShowPDFPopup$ = new BehaviorSubject(false);
	pdfSRC$ = new BehaviorSubject('');

	async ngOnInit() {
		// this.getAllInitialTasks();

		this.openDialog();

		try {
			await this.getExtras();
		} catch (err) {
			console.error(err);
		}

		this._truckDistribution.usersForAssignment$.pipe(takeUntil(this.destroy$)).subscribe(users => {
			this.agentsControl.setValue(users);
		});

		this.setupInteractivity();

		this._truckDistribution.updateDistributionUsersForAssignment();

		this._dialog.afterAllClosed.subscribe(() => {
			this.coordinates$.next([]);
			this.getAllInitialTasks();
		});
	}

	getExtras() {
		return new Promise<void>((resolve, reject) => {
			if (this._truckDistribution.extras$.value.length) {
				return resolve();
			}

			this._truckDistribution.getTruckDistributionExtras().subscribe({
				next: res => {
					if (typeof res === 'string') {
						return;
					}

					this._truckDistribution.extras$.next(res.recordset);
					resolve();
				},
				error: () => {
					reject();
				},
			});
		});
	}

	getAllInitialTasks() {
		this._truckDistribution
			.getAllTruckDistributionTasksForMain('map', {
				date: translateDateForSQLServer(this._mapDistribution.showDateControl.value).toISOString().slice(0, 10),
			})
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					if (typeof res === 'string') return;

					this.allTruckDistributionTeudot = res.recordset.filter(task => task.Coordinates);

					const coordinates = this.allTruckDistributionTeudot.map(task => ({ data: task, latitude: JSON.parse(task.Coordinates).latitude, longitude: JSON.parse(task.Coordinates).longitude }));
					this.coordinates$.next(coordinates);
				},
				error: err => {
					console.error(err);
				},
			});
	}

	setupInteractivity() {
		const tasks$ = this.coordinates$.pipe(startWith([] as MarkerInfo<TruckDistributionMainSQL>[]));
		const users$ = this.agentsControl.valueChanges.pipe(startWith([] as TruckDistributionUsers[]));

		combineLatest([tasks$, users$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([tasks, users]) => {
				let filtered = [...tasks];

				if (!users.length) {
					filtered = [];
				}

				const usersSet = new Set(users.map(user => user.AgentID));
				filtered = filtered.filter(task => usersSet.has(task.data.AssignTo));

				this.relevantTasks$.next(filtered);
				this.recalculateMap$.next(!this.recalculateMap$.value);
			});

		this._marker.openPDF.pipe(takeUntil(this.destroy$)).subscribe(filename => {
			const task = this.allTruckDistributionTeudot.find(task => task.PDFfile === filename);
			if (task) {
				this.openPDFPopup(task);
			}
		});
	}

	openDialog() {
		this._dialog.open(MapDistributionPopupComponent);
	}

	// initiaiteSubscriptions() {
	// 	this.pipeADestroyer(this.shouldShowPDFPopup$).subscribe(boolean => {
	// 		if (boolean) {
	// 			document.body.classList.add('no-scroll');
	// 			return;
	// 		}
	// 		document.body.classList.remove('no-scroll');
	// 	});
	// }

	closePDFPopup() {
		this.shouldShowPDFPopup$.next(false);
		this.pdfSRC$.next('');
	}

	openPDFPopup(teuda: TruckDistributionMainSQL) {
		if (!teuda.PDFfile) {
			alert('לא נמצא קובץ PDF');
			return;
		}

		this.shouldShowPDFPopup$.next(true);
		this.pdfSRC$.next(this._truckDistribution.resolvePDFLink({ filename: teuda.PDFfile, mode: 'PDFfile' }));
	}
}
