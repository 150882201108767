import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alert, MsgType, MsgTypeText } from 'src/app/Interfaces/alerts';

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnDestroy {
	constructor(private dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { alerts: Alert[]; confirmAction?: boolean; confirmActionWithoutCancel?: boolean }) {}

	ngOnDestroy(): void {
		if (this.data.alerts.some(alert => alert.JSON.block)) {
			history.back();
		}
	}

	messages = this.data.alerts.map(alert => alert.Msg);

	onNoClick(): void {
		this.dialogRef.close(false);
	}
	onYesClick(): void {
		this.dialogRef.close(true);
	}
}
