<div class="product-detail-add__popup popup__content">
	<div class="product-detail__wrapper">
		<div class="product-detail__item">
			<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="closeEvent.emit()"></div>
			<h3 style="text-align: center">בחר העדפה</h3>
			<ng-container *ngIf="savedValue$ | async"></ng-container>
			<div class="shop-filter__row" *ngFor="let choice of preferences" style="position: relative">
				<label class="shop-filter__radio custom-radio" style="font-weight: bold">
					{{ choice.name }}
					<input type="radio" name="shop-filter" [formControl]="preferenceRadioControl" [value]="choice.value" />
					<span class="custom-radio__checkmark"></span>
				</label>
				<ng-container *ngIf="choice.value === 'startFrom' && preferenceRadioControl.value === 'startFrom'">
					<select [formControl]="preferenceSelectControl">
						<option *ngFor="let option of searchOptions" [value]="option.value">{{ option.text }}</option>
					</select>
				</ng-container>
			</div>

			<div class="product-detail-add__popup-row">
				<button class="product-detail-add__popup-button" (click)="closeEvent.emit()">חזור</button>
			</div>
		</div>
	</div>
</div>
