import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { decompress } from 'lz-string';

@Component({
	selector: 'app-dohbakara',
	templateUrl: './dohbakara.component.html',
	styleUrls: ['./dohbakara.component.scss'],
})
export class DohbakaraComponent implements OnInit {
	constructor(public _ss: StoreService, public router: Router, public _fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {}

	ngOnInit(): void {
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
		if (this._ss.CustomerInformation === null) {
			if (localStorage.getItem('CustomerInformation') !== null) {
				this._ss.CustomerInformation = decompress(localStorage.getItem('CustomerInformation'));
				this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
			}
		}
		//////////////////// ---------- CLIENT INFORMATION ---------- ////////////////////
	}

	public MoveToDohotMotzarim() {
		this.router.navigate(['dohot-motzarim']);
	}

	//=====================================================================================================================================================================================================================================================================>
	//=====================================================================================================================================================================================================================================================================>

	toogleInput = false;
	showFilterPopup = false;
	searchActive = false;
	showHideFilterPopup(value): void {
		this.showFilterPopup = value;
	}

	searchFocus(value): void {
		this.searchActive = value;
	}
}
