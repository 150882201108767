import { Component, OnInit, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { decompress } from 'lz-string';
import { map, Observable, startWith } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { AlertService } from 'src/app/services/alert.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LikutService } from 'src/app/services/likut.service';
import { TruckLoadingService } from 'src/app/services/truck-loading.service';
import { TextService } from 'src/app/services/text.service';
import { TruckDistributionService } from 'src/app/services/truck-distribution.service';
import { IndexedDBService } from 'src/app/services/indexed-db.service';
import { AppStyleService } from 'src/app/services/app-style.service';
import { AllClients } from 'src/app/Interfaces/SQL-morning-responses';
import { MekabelWarehouse } from 'src/app/Interfaces/likut';
import { genericSearchingFunction } from 'src/app/Utility/functions';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-likut-clients-warehouse-dialog.component',
	template: `
		<form [formGroup]="form" (ngSubmit)="(true)" dir="rtl">
			<div mat-dialog-content style="overflow:initial">
				<h2>בחר ספק</h2>
				<mat-form-field class="example-full-width override-bidi" appearance="fill" style="width: 100%">
					<mat-label>ספק</mat-label>
					<input type="text" matInput formControlName="supplier" [matAutocomplete]="auto" />
					<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClient">
						<mat-option *ngFor="let supplier of suppliersSearch$ | async" [value]="supplier" style="direction: rtl">
							<span style="unicode-bidi: isolate">{{ supplier.ClientId }}</span> - <span style="unicode-bidi: isolate">{{ supplier.Client_Name }}</span>
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<ng-container *ngIf="warehouses.length > 1">
					<h2>בחר מחסן</h2>
					<mat-form-field class="example-full-width override-bidi" appearance="fill" style="width: 100%">
						<mat-label>מחסן</mat-label>
						<input type="text" matInput formControlName="warehouse" [matAutocomplete]="auto" />
						<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnWarehouse">
							<mat-option *ngFor="let warehouse of warehouseSearch$ | async" [value]="warehouse" style="direction: rtl">
								<span style="unicode-bidi: isolate">{{ warehouse.WarehouseId }}</span> - <span style="unicode-bidi: isolate">{{ warehouse.WarehouseName }}</span>
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</ng-container>
				<div mat-dialog-actions>
					<button mat-button matDialogClose="cancel">ביטול</button>
					<button mat-button [mat-dialog-close]="form.value" [disabled]="form.invalid" type="submit">אישור</button>
				</div>
			</div>
		</form>
	`,
	// styleUrls: ['./likut-clients.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LikutClientsWarehouseDialogComponent implements OnInit {
	constructor(
		public _ss: StoreService,
		public _fb: FormBuilder,
		private router: Router,
		public _likut: LikutService,
		public _truckLoading: TruckLoadingService,
		public _truckDistribution: TruckDistributionService,
		public _text: TextService,
	) {}

	warehousesExample: MekabelWarehouse[] = [
		{ WarehouseId: '1', WarehouseName: 'מחסן 1' },
		{ WarehouseId: '2', WarehouseName: 'מחסן 2' },
	];
	warehouses: MekabelWarehouse[] = this._likut.likutMekabelWarehouses$.value;
	suppliers: AllClients[] = [];

	suppliersSearch$ = new Observable<AllClients[]>();
	warehouseSearch$ = new Observable<MekabelWarehouse[]>();

	form = this._fb.group({
		warehouse: [<MekabelWarehouse>null, Validators.required],
		supplier: [<AllClients>null, Validators.required],
	});

	dialogRef: any;

	ngOnInit() {
		const clients = localStorage.getItem('AllClients');

		if (!clients) {
			alert('יש לעדכן נתונים כדי להציג לקוחות');
			this.router.navigate(['home']);
		}

		if (!this.warehouses.length) {
			const warehouses = localStorage.getItem('LikutMekabelWarehouses');
			if (!warehouses) {
				if (environment.development) {
					this.warehouses = this.warehousesExample;
				} else {
					alert('לא נמצאו מחסנים. אנא עדכנו נתונים ונסו שנית');
					this.router.navigate(['home']);
				}
			} else {
				this.warehouses = JSON.parse(decompress(warehouses));
				this._likut.likutMekabelWarehouses$.next(this.warehouses);
			}
		}

		if (this.warehouses.length === 1) {
			this.form.controls.warehouse.setValue(this.warehouses[0]);
		}

		this.suppliers = JSON.parse(decompress(clients));

		this.suppliersSearch$ = this.form.controls.supplier.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = value;
				return name ? this._filterClient(name as string) : this.suppliers.slice(0, 50);
			}),
		);

		this.warehouseSearch$ = this.form.controls.warehouse.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = value;
				return name ? this._filterWarehouse(name as string) : this.warehouses.slice(0, 50);
			}),
		);
	}

	private _filterClient(name: string | AllClients): AllClients[] {
		const filterValue = typeof name === 'string' ? name.toLowerCase() : '';

		return genericSearchingFunction(filterValue, this.suppliers, ['Client_Name', 'ClientId']);
	}
	private _filterWarehouse(name: string | MekabelWarehouse): MekabelWarehouse[] {
		const filterValue = typeof name === 'string' ? name.toLowerCase() : '';

		return genericSearchingFunction(filterValue, this.warehouses, ['WarehouseName', 'WarehouseId']);
	}

	displayFnClient(input: AllClients): string {
		return input?.ClientId && input?.Client_Name ? `${input.ClientId} - ${input.Client_Name}` : '';
	}

	displayFnWarehouse(input: MekabelWarehouse): string {
		return input?.WarehouseId && input?.WarehouseName ? `${input.WarehouseId} - ${input.WarehouseName}` : '';
	}
}
