<ng-container *ngIf="(_loading.isLoading$ | async) === false">
	<h1 mat-dialog-title>פרטי משימת הפצה</h1>

	<div mat-dialog-content style="font-size: 16px; font-weight: 600">
		<h4>{{ data.clientid }} - {{ data.clientName }}</h4>
		<h4>{{ data.address }}</h4>
		<h4 *ngIf="determineDistributionTask(data)">תעודות: {{ data.totalTeudot }} / שורות: {{ data.totalLines }}</h4>
		<h4 *ngIf="determineLoadingTask(data)">תעודות: {{ data.totalTeudot }} / שורות: {{ data.totalRow }}</h4>
		<form dir="rtl" class="grid" [formGroup]="form">
			<mat-form-field color="primary" *ngIf="shouldShowDate">
				<mat-label>שנה תאריך</mat-label>
				<input readonly="true" formControlName="date" matInput [matDatepicker]="picker" (click)="picker.open()" />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
			<mat-form-field color="primary" *ngIf="determineDistributionTask(data)">
				<mat-label>שנה סוכן</mat-label>
				<mat-select formControlName="agent">
					<mat-option [value]="''">ללא שיוך</mat-option>
					<mat-option *ngFor="let driver of _truckDistribution.usersForAssignment$ | async" [value]="driver.AgentID">{{ driver.AgentID }} - {{ driver.UserName }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field color="primary" *ngIf="determineLoadingTask(data)">
				<mat-label>שנה סוכן</mat-label>
				<mat-select formControlName="agent">
					<mat-option [value]="''">ללא שיוך</mat-option>
					<mat-option *ngFor="let driver of _truckLoading.usersForAssignment$ | async" [value]="driver.AgentID">{{ driver.AgentID }} - {{ driver.UserName }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field color="primary" *ngIf="determineLikutTask(data)">
				<mat-label>שנה סוכן</mat-label>
				<mat-select formControlName="agent">
					<mat-option [value]="''">ללא שיוך</mat-option>
					<mat-option *ngFor="let agent of _likut.usersForAssignment$ | async" [value]="agent.AgentID">{{ agent.AgentID }} - {{ agent.UserName }}</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</div>
	<div mat-dialog-actions>
		<button mat-button mat-dialog-close cdkFocusInitial>סגור</button>
	</div>
</ng-container>
