<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="history.back()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
					</div>
					<div class="header__title col">{{ title }}</div>

					<div class="header__burger col image" (click)="Restart()" style="text-decoration: underline">
						<b> איפוס </b>
					</div>
				</div>
			</div>
			<div class="report-table__row">
				<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
					<form [formGroup]="form">
						<input
							class="header__search-input"
							type="text"
							placeholder="חפש"
							formControlName="ClientSearch"
							(keyup)="SearchClient()"
							id="search-Input"
							style="text-align: center; direction: rtl"
						/>
					</form>
					<button class="header__clear-icon" (click)="ClearClientSearch()"></button>
				</div>
			</div>
		</header>

		<div class="popup" *ngIf="shouldShowPDFPopup$ | async" style="z-index: 1999">
			<div class="overlay" style="z-index: 0" (click)="closePDFPopup()"></div>

			<div class="popup__close" (click)="closePDFPopup()" style="width: 30px; height: 30px; background-color: black; top: 1rem; left: 1rem"></div>

			<div class="pdf-wrapper">
				<app-canvas-popup [pdfSRCInput$]="pdfSRC$" [useSignature]="false"></app-canvas-popup>
			</div>
		</div>

		<!-- start markup for delete teuda confirmation popup -->

		<!-- markup for see teuda after teuda num press-->

		<div class="popup" *ngIf="(isLikutViewPopupOpenNewRoutes$ | async) && openedLikutNewRoute$ | async as openedLikut" style="z-index: 555">
			<div class="overlay" (click)="handleChangeLikutPopup('close')"></div>
			<div class="product-detail__title">
				{{ openedLikut.main.Client_Name }}
			</div>
			<div class="product-detail-popup popup__content">
				<div class="popup__close" (click)="handleChangeLikutPopup('close')" style="width: 30px; height: 30px; background-color: black"></div>
				<div class="product-detail__wrapper custom-scrollbar">
					<table>
						<tr>
							<th #teudotTableNames>שם פריט</th>
							<th>קוד פריט</th>
							<th *ngIf="showQuantity">יח' הוזמן</th>
							<th *ngIf="showQuantity">יח' לוקט</th>
							<th *ngIf="showPackQuantity">אר' הוזמן</th>
							<th *ngIf="showPackQuantity">אר' לוקט</th>
							<th>הערה</th>
						</tr>
						<tr
							*ngFor="let pte of openedLikut.sub"
							[ngStyle]="{
								background: !isNoStatus && ((showQuantity && pte.TeudaQuantity !== pte.LikutQty) || (showPackQuantity && pte.TeudaPackQuantity !== pte.LikutPackQty)) ? '#ffb6b6' : null
							}"
						>
							<td>{{ pte.ProductName }}</td>
							<td>{{ pte.ProductId }}</td>
							<td *ngIf="showQuantity">{{ pte.TeudaQuantity }}</td>
							<td *ngIf="showQuantity" [ngStyle]="{ color: !isNoStatus && showQuantity && pte.TeudaQuantity !== pte.LikutQty ? 'red' : null }">{{ pte.LikutQty }}</td>
							<td *ngIf="showPackQuantity">{{ pte.TeudaPackQuantity }}</td>
							<td *ngIf="showPackQuantity" [ngStyle]="{ color: !isNoStatus && showPackQuantity && pte.TeudaPackQuantity !== pte.LikutPackQty ? 'red' : null }">{{ pte.LikutPackQty }}</td>
							<td>{{ openedLikut.main.LikutExtra1 || pte.TeudaItemRemark }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="popup" *ngIf="(isTruckLoadingPopupOpen$ | async) && openedTruckLoading$ | async as openedLoadingTasks" style="z-index: 555">
			<div class="overlay" (click)="handleChangeLikutPopup('close')"></div>
			<div class="product-detail__title">
				{{ openedLoadingTasks[0].Extra4 }}
			</div>
			<div class="product-detail-popup popup__content">
				<div class="popup__close" (click)="handleChangeLikutPopup('close')" style="width: 30px; height: 30px; background-color: black"></div>
				<div class="product-detail__wrapper custom-scrollbar">
					<table>
						<tr>
							<th #teudotTableNames>לקוח</th>
							<th>מס תעודה</th>
							<th>שורות</th>
						</tr>
						<tr *ngFor="let pte of openedLoadingTasks">
							<td>{{ pte.ClientID + ' - ' + pte.ClientName }}</td>
							<td>{{ pte.DocNum }}</td>
							<td>{{ pte.RowsNum }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<div class="popup" *ngIf="(isTruckDistributionPopupOpen$ | async) && openedTruckDistrbution$ | async as openedTask" style="z-index: 555">
			<div class="overlay" (click)="handleChangeLikutPopup('close')"></div>
			<div class="product-detail__title">
				{{ openedTask.main.ClientName }}
				<p style="direction: rtl; font-size: 16px" *ngIf="openedTask.main.Comment"><span style="font-weight: 700">הערת תעודה:</span> {{ openedTask.main.Comment }}</p>
			</div>
			<!-- <div class="product-detail__title remarks" *ngIf="openedTask.main.Comment">
				<p><span style="font-weight: 700">הערת תעודה:</span> {{ openedTask.main.Comment }}</p>
			</div> -->
			<div class="product-detail-popup popup__content">
				<div class="popup__close" (click)="handleChangeLikutPopup('close')" style="width: 30px; height: 30px; background-color: black"></div>
				<div class="product-detail__wrapper custom-scrollbar">
					<table>
						<tr>
							<th #teudotTableNames>קוד פריט</th>
							<th>שם פריט</th>
							<th>כמות</th>
							<th>סופק</th>
							<th>אריזות</th>
							<th>סופק</th>
							<th>סטטוס</th>
						</tr>
						<tr *ngFor="let line of openedTask.sub">
							<td>{{ line.ProductID }}</td>
							<td>{{ line.ProductName }}</td>
							<td>{{ line.Quantity }}</td>
							<td>{{ line.PODQuantity }}</td>
							<td>{{ line.PackQTY }}</td>
							<td>{{ line.PODPackQTY }}</td>
							<td>{{ line.PODStatus | podProductStatus }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<!--  dashboard-tables markup  -->
		<section class="dashboard-tables" style="margin-top: 30px">
			<div class="content">
				<div class="dashboard-tables__wrapper">
					<table
						style="font-size: 17px; position: sticky; top: 130px; z-index: 999"
						class="filter-row"
						[ngStyle]="{ 'z-index': (isLikutViewPopupOpenNewRoutes$ | async) || (isTruckLoadingPopupOpen$ | async) ? '99' : '999' }"
						*ngIf="!isTruckDistribution"
					>
						<tr>
							<th style="background-color: white; width: 150px"><b>סינון מתקדם</b></th>
						</tr>

						<tr>
							<td>
								<div class="justify-align-center">
									<div class="report-table__button" (click)="showFilterPopup = true"></div>
								</div>
							</td>
						</tr>
					</table>

					<div class="dashboard-tables__row dashboard-tables__row_scroll margin-top">
						<b>
							<section class="sticky-headers">
								<table
									class="report-table__table"
									*ngIf="isLikut; else truckLoadingTable"
									[ngStyle]="{ height: 'calc(99vh - ' + (tableHeight || 0) + ')', 'min-width': isAssignmentOfLikut ? 'initial' : null }"
									cellspacing="0"
								>
									<thead class="report-table__table-head" #thead>
										<tr style="height: 54px">
											<th class="report-table__table-head-name width-60">תעודה</th>
											<th class="report-table__table-head-code">{{ _text.store.manageReportTableHeaders1[1].text }}</th>
											<th
												class="report-table__table-head-quantity"
												[ngClass]="{ 'width-60': (isAssignmentOfLikut && i === 2) || header.text === 'שורות' }"
												*ngFor="let header of _text.store.manageReportTableHeaders1.slice(2); index as i"
											>
												<ng-container *ngIf="header.element === 'button'; else normalReportHeader">
													<!-- <button mat-raised-button color="primary" *ngIf="isNoStatus" (click)="handleOpenStatusChangePopup('activate')">
														{{ header.text }}
													</button> -->

													<mat-button-toggle-group *ngIf="isNoStatus">
														<mat-button-toggle style="background-color: var(--valid-color); color: var(--secondary-color)" (click)="handleOpenStatusChangePopup('activate')"
															>אשר</mat-button-toggle
														>
														<mat-button-toggle style="background-color: var(--invalid-color); color: var(--secondary-color)" (click)="handleOpenStatusChangePopup('cancel')"
															>מחק</mat-button-toggle
														>
													</mat-button-toggle-group>

													<button mat-raised-button color="primary" *ngIf="isManageSpecificStatuses" (click)="handleOpenStatusChangePopup('activate')">
														{{ header.text }}
													</button>
												</ng-container>
												<ng-template #normalReportHeader>
													{{ header.text }}
												</ng-template>
											</th>

											<!-- <ng-container *ngIf="isManageSpecificStatuses">
												<th class="report-table__table-head-quantity">{{ _text.store.manageReportTableHeaders1[7] }}</th>
												<th class="report-table__table-head-quantity">
													<button mat-raised-button color="primary" (click)="handleOpenStatusChangePopup('activate')">{{ _text.store.manageReportTableHeaders1[8] }}</button>
												</th>
											</ng-container> -->
										</tr>
									</thead>
									<tbody class="report-table__table-body" [ngStyle]="{ height: 'calc(99vh - ' + thead.offsetHeight + 'px - ' + (tableHeight || 1) + ')' }">
										<tr
											*ngFor="let t of teudotDisplayNewRoute$ | async; let index = index"
											[ngStyle]="{ 'box-shadow': !t.areAllQuantitiesCollected && !isAssignmentOfLikut && !isNoStatus ? 'rgb(233 115 115) 0px 0px 9px 3px inset' : '' }"
										>
											<td class="report-table__table-body-name-clickable width-60" style="color: #38d88b" (click)="handleGetLikutDetails(t)">
												{{ t.TeudaNum }}
											</td>
											<td class="report-table__table-body-name" *ngIf="isNoStatus || isManageSpecificStatuses">{{ t.TuedaErpDocNum }}</td>
											<td class="report-table__table-body-name" *ngIf="shouldShowAreaColumnInNoStatus">{{ t.AreaName || 'ללא אזור' }}</td>
											<td class="report-table__table-body-name clickable client-name" (click)="openClientCardPopup(t)">{{ t.Client_Name }}</td>
											<ng-container *ngIf="isNoStatus">
												<td class="report-table__table-body-quantity">{{ t.TeudaCreateDate | date : 'dd/MM/yy' : 'GMT' }}</td>
												<td class="report-table__table-body-quantity">{{ t.TeudaSupplyDate | date : 'dd/MM/yy' : 'GMT' }}</td>
											</ng-container>
											<ng-container *ngIf="!isAssignmentOfLikut && !isNoStatus">
												<td class="report-table__table-body-quantity">{{ t.User_Name }}</td>
												<td class="report-table__table-body-quantity" dir="ltr">{{ t.LikutDate | date : 'dd/MM/yy' : 'GMT' }}</td>
												<td class="report-table__table-body-quantity" dir="ltr">{{ t.LikutDate | date : 'HH:mm' : 'GMT' }}</td>
											</ng-container>
											<td class="report-table__table-body-quantity" *ngIf="isAssignmentOfLikut">{{ t.TeudaSupplyDate | date : 'dd/MM/yy' : 'GMT' }}</td>
											<td class="report-table__table-body-quantity" style="width: 60px">{{ t.TotalLines }}</td>
											<td class="report-table__table-body-quantity" *ngIf="!isAssignmentOfLikut && !isNoStatus">{{ t.LikutStatus | taskText : 'likut' }}</td>

											<td class="report-table__table-body-quantity" *ngIf="isManageSpecificStatuses || isNoStatus">
												<div class="flex gap justify-align-center">
													<mat-checkbox color="primary" [(ngModel)]="checkboxState[t.TeudaNum]"></mat-checkbox>
													<!-- <ng-container *ngIf="isNoStatus">
														<button mat-raised-button color="warn" (click)="handleOpenStatusChangePopup('cancel', t.TeudaNum)">בטל</button>
													</ng-container> -->
												</div>
											</td>
											<td class="report-table__table-body-quantity" *ngIf="isAssignmentOfLikut" style="width: 60px">
												<mat-select (selectionChange)="assignLikutNewRoute($event, t.TeudaNum)">
													<mat-option *ngFor="let user of agentsToAssign" [value]="user.melaketid">{{ user.melaketid }}-{{ user.melaketname }}</mat-option>
												</mat-select>
											</td>
										</tr>
									</tbody>
								</table>
								<ng-template #truckLoadingTable>
									<ng-container *ngIf="isTruckLoading; else truckDistributionTable">
										<table class="report-table__table" [ngStyle]="{ height: 'calc(99vh - ' + (tableHeight || 0) + ')', 'min-width': isAssignmentOfLikut ? 'initial' : null }" cellspacing="0">
											<thead class="report-table__table-head" #thead>
												<tr style="min-height: 53px">
													<th class="report-table__table-head-name">גל</th>
													<th class="report-table__table-head-code" style="width: 60px">מספר</th>
													<th class="report-table__table-head-quantity" style="width: 60px">שיוך</th>
												</tr>
											</thead>
											<tbody class="report-table__table-body" [ngStyle]="{ height: 'calc(99vh - ' + thead.offsetHeight + 'px - ' + (tableHeight || 0) + ')' }">
												<tr *ngFor="let t of truckLoadingAllAssignments$ | async; let index = index">
													<td class="report-table__table-body-name-clickable" style="color: #38d88b" (click)="handleGetTruckLoadingWaveDetails(t.Extra4)">
														{{ t.Extra4 }}
													</td>
													<td class="report-table__table-body-name clickable" style="width: 60px">{{ t.TotalLines }}</td>
													<td class="report-table__table-body-quantity" *ngIf="isAssignmentOfLikut" style="width: 60px">
														<mat-select (selectionChange)="assignNewTruckLoading($event, t.Extra4)" dir="rtl">
															<mat-option *ngFor="let user of truckLoadingAllUsers$ | async" [value]="user.AgentID">{{ user.AgentID }}-{{ user.UserName }}</mat-option>
														</mat-select>
													</td>
												</tr>
											</tbody>
										</table>
									</ng-container>
									<ng-template #truckDistributionTable>
										<table class="report-table__table" *ngIf="!isPODSupplied; else PODSuppleidBlock" [ngStyle]="{ height: 'calc(99vh - ' + (tableHeight || 0) + ')' }" cellspacing="0">
											<thead class="report-table__table-head" #thead>
												<tr style="min-height: 53px">
													<th class="report-table__table-head-name">תעודה</th>
													<th class="report-table__table-head-code">סוג מסמך</th>
													<th class="report-table__table-head-quantity">לקוח</th>
													<th class="report-table__table-head-quantity" style="width: 60px">משתמש</th>
													<th class="report-table__table-head-quantity">תאריך</th>
												</tr>
											</thead>
											<tbody class="report-table__table-body" [ngStyle]="{ height: 'calc(99vh - ' + thead.offsetHeight + 'px - ' + (tableHeight || 0) + ')' }">
												<tr *ngFor="let t of truckDistributionTasksShown$ | async; let index = index">
													<td class="report-table__table-body-name-clickable" style="color: #38d88b" (click)="openPDFPopup(t)">
														{{ t.DocNum }}
													</td>
													<td class="report-table__table-body-code">{{ t.DocTypeName }}</td>
													<td class="report-table__table-body-quantity">{{ t.ClientName }}</td>
													<td class="report-table__table-body-quantity" style="width: 60px">{{ t.AssignTo }}</td>
													<td class="report-table__table-body-quantity">{{ t.LoadDate | date : 'dd/MM/yy' : 'GMT' }}</td>
												</tr>
											</tbody>
										</table>
										<ng-template #PODSuppleidBlock>
											<table class="report-table__table" [ngStyle]="{ height: 'calc(99vh - ' + (tableHeight || 0) + ')' }" cellspacing="0">
												<thead class="report-table__table-head" #thead>
													<tr style="min-height: 53px">
														<th class="report-table__table-head-name">סוג מסמך</th>
														<th class="report-table__table-head-code">אסמכתא</th>
														<th class="report-table__table-head-quantity">לקוח</th>
														<th class="report-table__table-head-quantity" style="width: 60px">משתמש</th>
														<th class="report-table__table-head-quantity">תאריך</th>
														<th class="report-table__table-head-quantity">שעה</th>
														<th class="report-table__table-head-quantity">שורות</th>
														<th class="report-table__table-head-quantity">קובץ</th>
														<th class="report-table__table-head-quantity" *ngIf="PODSuppliedHasCompany">חברה</th>
													</tr>
												</thead>
												<tbody class="report-table__table-body" [ngStyle]="{ height: 'calc(99vh - ' + thead.offsetHeight + 'px - ' + (tableHeight || 0) + ')' }">
													<tr
														*ngFor="let t of truckDistributionSuppliedTasksShown$ | async; let index = index"
														[ngStyle]="{ color: t.Color || null }"
														class="clickable"
														(click)="handleGetTruckDistributionDetails(t)"
													>
														<td class="report-table__table-body-code">{{ t.DocTypeName }}</td>
														<td class="report-table__table-body-name-clickable" style="color: #38d88b">{{ t.DocNum }}</td>
														<td class="report-table__table-body-quantity">{{ t.ClientName }}</td>
														<td class="report-table__table-body-quantity" style="width: 60px">{{ t.AssignTo }}</td>
														<td class="report-table__table-body-quantity">{{ t.PODDate | date : 'dd/MM/yy' : 'GMT' }}</td>
														<td class="report-table__table-body-quantity">{{ t.PODDate | date : 'HH:mm' : 'GMT' }}</td>
														<td class="report-table__table-body-quantity">{{ t.RowsNum }}</td>
														<td class="report-table__table-body-quantity">
															<button mat-raised-button color="primary" *ngIf="t.PDFfile" (click)="_util.stopEventPropogation($event); openPDFPopup(t)">פתח קובץ</button>
														</td>
														<td class="report-table__table-body-quantity" *ngIf="PODSuppliedHasCompany">{{ t.CompanyName }}</td>
													</tr>
												</tbody>
											</table>
										</ng-template>
									</ng-template>
								</ng-template>
							</section>
						</b>
					</div>
				</div>
			</div>
		</section>
	</div>

	<!-- end main menu markup -->
</div>

<!-- start sort popup -->
<div class="popup" *ngIf="showFilterPopup">
	<div class="overlay"></div>
	<div class="popup__close" (click)="showFilterPopup = false" style="top: 2em; z-index: 1002; box-shadow: inset 0px 0px 2px, 0 0 3px 2px" [style.left]="windowWidth > 768 ? '43%' : '2em'"></div>
	<div class="report-table-popup report-table-popup_sort popup__content" style="overflow-y: scroll">
		<div class="report-table-popup__header">
			<div class="report-table-popup__header-title">סינון מתקדם</div>
		</div>
		<div class="report-table-popup__wrapper">
			<div class="report-table-popup__content">
				<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border report-table-popup__row_padding"></div>
				<div class="report-table-popup__row report-table-popup__row_no-border report-table-popup__row_double">
					<div class="report-table-popup__box">
						<span class="report-table-popup__content-title">בחירת תקופה</span>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">מתאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="FromDate" (change)="handleDateChange('from')" />
								</form>
							</label>
						</div>
						<div class="report-table-popup__row report-table-popup__row_padding-bottom">
							<label class="report-table-popup__label">
								<div class="report-table-popup__label-title report-table-popup__label-title_gray">עד תאריך</div>
								<form [formGroup]="form">
									<input type="date" formControlName="ToDate" (change)="handleDateChange('to')" />
								</form>
							</label>
						</div>
					</div>

					<ng-container *ngIf="!isNoStatus">
						<div class="report-table-popup__box">
							<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
								<span class="report-table-popup__content-title">מלקטים</span>
							</div>
							<div style="float: right; height: 75px">
								<form [formGroup]="form" style="width: 150px">
									<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px">
										<mat-select formControlName="Agent" multiple>
											<mat-option *ngFor="let a of agentsToFilter" [value]="a">{{ a }}</mat-option>
										</mat-select>
									</mat-form-field>
								</form>
							</div>
						</div>
						<div class="report-table-popup__box">
							<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
								<span class="report-table-popup__content-title">סטטוס</span>
							</div>
							<div style="float: right; height: 75px">
								<form [formGroup]="form" style="width: 150px">
									<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px">
										<mat-select formControlName="Status" multiple>
											<mat-option *ngFor="let s of statusesToFilter" [value]="s">{{ s | taskText : 'likut' }}</mat-option>
										</mat-select>
									</mat-form-field>
								</form>
							</div>
						</div>
					</ng-container>
					<div class="report-table-popup__box" *ngIf="shouldShowAreaColumnInNoStatus">
						<div class="report-table-popup__row report-table-popup__row_space-between report-table-popup__row_no-border">
							<span class="report-table-popup__content-title">אזור</span>
						</div>
						<div style="float: right; height: 75px">
							<form [formGroup]="form" style="width: 150px">
								<mat-form-field color="primary" appearance="fill" dir="rtl" style="transform: translateX(10%) scale(0.6); font-size: 22px">
									<mat-select formControlName="areas" multiple>
										<mat-option *ngFor="let area of areasToFilter" [value]="area">{{ area }}</mat-option>
									</mat-select>
								</mat-form-field>
							</form>
						</div>
					</div>
				</div>
				<div class="report-table-popup__button-wrapper">
					<button class="report-table-popup__button" [attr.disabled]="dateInvalid ? true : null" [ngClass]="{ darken: dateInvalid }" (click)="handleAdvancedSearch()">הצג תוצאות</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end sort popup -->

<!-- start clientcard popup -->

<!-- <div class="popup" *ngIf="shouldClientCardPopupShow" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed">
		<div class="product-detail__wrapper wrapper2">
			<div class="product-detail__item overflow" style="padding: 0">
				<div
					class="popup__close"
					(click)="_ss.OneClientInfoDebt = null; shouldClientCardPopupShow = false; document.body.classList.remove('no-scroll')"
					style="width: 30px; height: 30px; background-color: black; position: fixed"
				></div>
				<app-clientcard></app-clientcard>
			</div>
		</div>
	</div>
</div> -->

<!-- end clientcard popup -->

<div class="popup" *ngIf="isChangeStatusesPopupConfirmationOpen$ | async" style="z-index: 1999">
	<div class="overlay" (click)="isChangeStatusesPopupConfirmationOpen$.next(false)"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="popup__close" (click)="isChangeStatusesPopupConfirmationOpen$.next(false)"></div>

		<h2 style="text-align: center">
			{{ _text.store.changeStatusConfirmation[0] }}
			<ng-container [ngSwitch]="popupMode$ | async">
				<span *ngSwitchCase="'activate'" class="valid-color">לאשר</span>
				<span *ngSwitchCase="'cancel'" class="invalid-color">למחוק</span>
			</ng-container>
			{{ _text.store.changeStatusConfirmation[1] }}
		</h2>
		<h4 style="text-align: center">{{ teudaNumsToChangeStatus }}</h4>

		<div class="flex gap justify-align-center">
			<button class="order-step__block-button order-step__block-button_green" (click)="changeStatusForTheseLikut()">{{ _text.store.send }}</button>
			<button class="order-step__block-button" (click)="isChangeStatusesPopupConfirmationOpen$.next(false)">{{ _text.store.declineReturn }}</button>
		</div>
	</div>
</div>
