import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OneClientInfoDebt } from 'src/app/Interfaces/one-client-info-debt';
import { filter, fromEvent, Subject, take, takeUntil } from 'rxjs';
import { decompress } from 'lz-string';
import { Location } from '@angular/common';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilService } from 'src/app/services/util.service';
import { ClosePopupValues } from '../new-receipt/new-receipt.component';
import { EmailRadioValues } from '../teuda/teuda.component';
import { UserService } from 'src/app/services/user.service';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { monthsHeb } from 'src/app/Utility/constants';

interface OCIDWithRowNum extends OneClientInfoDebt {
	rownum?: number;
}

@Component({
	selector: 'app-clienthovot',
	templateUrl: './clienthovot.component.html',
	styleUrls: ['./clienthovot.component.scss'],
})
export class ClienthovotComponent implements OnInit, OnDestroy {
	constructor(
		public _ss: StoreService,
		public router: Router,
		public _fb: FormBuilder,
		public _receipts: ReceiptsService,
		private _snackBar: MatSnackBar,
		private _util: UtilService,
		public _user: UserService,
		private _cc: CreditCardService,
	) {}

	form = this._fb.group({
		CreateMonth: [null],
		DueMonth: [null],
	});

	showPopup = false;
	OneClientInfo = this._ss.OneClientInfo;
	OneClientInfoDebt: OCIDWithRowNum[] = this._ss.OneClientInfoDebt;
	DebtArray = [];
	TotalSumDebtChecked = 0;
	Loading1 = false;
	windowWidth;

	clientHasEmail = false;

	LoadingHov = false;

	longestTeudaNum = 0;

	Months = monthsHeb;

	destroy$ = new Subject<void>();

	document = document;

	history = history;

	AllHovotHeaders;

	isNewReceiptPopupOpen = false;

	emailRadios = new FormControl<EmailRadioValues>('none');
	emailInput = new FormControl('', [Validators.required, Validators.email]);

	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.windowWidth = window.innerWidth;

		if (!this._ss.CustomerInformation) {
			const ls = localStorage.getItem('CustomerInformation');

			if (!ls) {
				this.router.navigate(['login']);
			}

			this._ss.CustomerInformation = decompress(ls);
			this._ss.CustomerInformation = JSON.parse(this._ss.CustomerInformation);
		}

		if (this._ss.OneClientInfo === null) {
			this.router.navigate(['clients']);
		}

		this._receipts.refreshReceiptsParameters();

		if (this._receipts.canPayWithCreditCard) {
			this._cc.refreshParameters();
		}

		this.clientHasEmail = !!this.OneClientInfo.CustEMail || !!this.OneClientInfo.CustEmail || false;

		if (this.clientHasEmail) {
			// this.emailInput.setValue(this.OneClientInfo.CustEMail || this.OneClientInfo.CustEmail)
			this.emailRadios.setValue('client');
		}

		console.log({ oneClientInfo: this._ss.OneClientInfo, OneClientInfoDebt: this._ss.OneClientInfoDebt });

		if (!this._ss.OneClientInfoDebt?.length) {
			const hovot = localStorage.getItem('AllHovotHeaders');

			if (hovot) {
				console.log('getting from localstorage');
				this.AllHovotHeaders = decompress(localStorage.getItem('AllHovotHeaders'));
				this.AllHovotHeaders = JSON.parse(this.AllHovotHeaders);

				this._ss.OneClientInfoDebt = [];

				for (let x = 0; x < this.AllHovotHeaders.length; x++) {
					if (this.AllHovotHeaders[x].DebtCustCode === this.OneClientInfo.ClientId && this.AllHovotHeaders[x].Company === this.OneClientInfo.company) {
						this._ss.OneClientInfoDebt.push(this.AllHovotHeaders[x]);
					}
				}
			}

			this.OneClientInfoDebt = this._ss.OneClientInfoDebt;
		}

		this._ss.AutoMoveHovot = 0;

		for (let x = 0; x < this._ss.OneClientInfoDebt.length; x++) {
			this.TotalSumDebtChecked += this._ss.OneClientInfoDebt[x].DebtDocOpenToPay;
			this._ss.OneClientInfoDebt[x].rownum = x + 1;
		}

		// Adjust column width by longest num
		this.longestTeudaNum = Math.max(...this.OneClientInfoDebt.map(debt => debt.DebtDocNum.length));

		this.form.controls['DueMonth'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: string | null) => {
			if (val) {
				this.form.controls['CreateMonth'].reset();

				document.querySelectorAll('input[type="checkbox"]').forEach((chkbx: HTMLInputElement) => {
					chkbx.checked = false;
					chkbx.indeterminate = true;
				});

				this.DebtArray = [];

				this.OneClientInfoDebt = this._ss.OneClientInfoDebt.filter(debt => new Date(debt.DebtDocToPayDate).getMonth() <= +val);

				this.TotalSumDebtChecked = this._util.reduceArrayToNumber(this.OneClientInfoDebt, 'DebtDocOpenToPay');
			}
		});

		this.form.controls['CreateMonth'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: string | null) => {
			if (val) {
				this.form.controls['DueMonth'].reset();

				// for (const debt of this.DebtArray){
				//   document.getElementById('hov' + debt.DebtDocNum).style.color = "black"
				// }

				document.querySelectorAll('input[type="checkbox"]').forEach((chkbx: HTMLInputElement) => {
					chkbx.checked = false;
					chkbx.indeterminate = true;
				});

				this.DebtArray = [];

				this.OneClientInfoDebt = this._ss.OneClientInfoDebt.filter(debt => new Date(debt.DebtDocCreateDate).getMonth() === +val);

				this.TotalSumDebtChecked = this._util.reduceArrayToNumber(this.OneClientInfoDebt, 'DebtDocOpenToPay');
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		this._snackBar.dismiss();

		this.handleReceiptsReset();
	}

	BackToClientCard() {
		this.router.navigate(['client-card']);
	}

	handleReceiptPopupOpen() {
		if (this._receipts.needsAtLeastOneHovCheckedToProduceReceipt && (!this.DebtArray.length || this.TotalSumDebtChecked < 1)) {
			alert('כדי להפיק קבלה יש לסמן לפחות חוב אחד');
			return;
		}

		document.body.classList.add('no-scroll');
		this.isNewReceiptPopupOpen = true;

		history.pushState({}, '');

		fromEvent(window, 'popstate')
			.pipe(
				filter(evt => evt instanceof PopStateEvent),
				take(1),
			)
			.subscribe(() => {
				this.isNewReceiptPopupOpen = false;
				this.handleReceiptsReset();
			});
	}

	closeReceiptPopup() {
		if ((!this._receipts.receiptSent && this._receipts.chequesArray.length) || this._cc.hasAnyFormValues()) {
			const decision = confirm('האם אתה בטוח שתרצה לסגור את מסך הקבלות? הפרטים שהוכנסו לא יישמרו');
			if (!decision) return;
		}

		history.back();

		this.handleReceiptsReset();
	}

	handleCloseEmailPopup() {
		this._receipts.shouldOpenEmailPopup$.next(false);
		this.isEmailPickPopupOpen = false;
	}

	handleReceiptsReset() {
		this._receipts.lastDocnumSent = null;
		this._receipts.emailForReceipt = null;
		this._receipts.receiptSent = false;
		this._receipts.sendingErroredWithTimeoutAndCredit = false;
		this._receipts.chequesArray = [];
		document.body.classList.remove('no-scroll');
		this._receipts.shouldOpenEmailPopup$.next(false);
		this._receipts.emailOkToContinue$.next(false);
		this._cc.resetFormValues();
	}

	checkAll() {
		document.querySelectorAll('input[type="checkbox"]').forEach((chkbx: HTMLInputElement) => {
			chkbx.indeterminate = false;
			chkbx.checked = true;
		});

		this.TotalSumDebtChecked = this._util.reduceArrayToNumber(this.OneClientInfoDebt, 'DebtDocOpenToPay');

		this.DebtArray = this.OneClientInfoDebt.map((debt, i) => ({
			DebtDocNum: +i + 1,
			DebtDocOpenToPay: debt.DebtDocOpenToPay,
			debtObject: debt,
		}));
	}

	receiptSendFailed() {
		alert('ישנה תקלה בשרת או בחיבור שלכם, אנא נסו לשלוח קבלה זו ממסך הלקוחות במועד מאוחר יותר.');
		history.back();
		this.handleReceiptsReset();
	}

	handleCloseReceiptPopupEvent(value: ClosePopupValues) {
		if (value === 'close') {
			this.closeReceiptPopup();
		}

		if (value === 'error') {
			this.receiptSendFailed();
		}

		this._receipts.emailForReceipt = null;
	}

	ChosenTeuda = null;

	MoveToHovEach(DocNum, i) {
		this.LoadingHov = this.Loading1 = true;

		this._ss.SeeOneHovTeudaService(this.OneClientInfo.ClientId, DocNum).subscribe(
			res => {
				this._ss.EachClientEachHov = res;
				this._ss.EachClientEachHov = this._ss.EachClientEachHov.recordset;

				this._ss.currentlyChosenDebt = this._ss.OneClientInfoDebt[i];

				window.scrollTo(0, 0);
				this.ChosenTeuda = DocNum;

				this.router.navigate(['client-hovot-each']);
			},
			err => {
				alert('ישנה תקלה בשרת, לא היה ניתן להביא פירוט חובות');
				this.LoadingHov = this.Loading1 = false;
				// this.router.navigate(['client-card']);
				// history.back()
			},
		);
	}
	//==========================================================================================================================================================>

	AllDebt() {
		this.OneClientInfoDebt = this._ss.OneClientInfoDebt;

		this.TotalSumDebtChecked = this._util.reduceArrayToNumber(this.OneClientInfoDebt, 'DebtDocOpenToPay');

		this.DebtArray = [];

		this.form.reset();

		document.querySelectorAll('input[type="checkbox"]').forEach((chkbx: HTMLInputElement) => {
			chkbx.checked = false;
			chkbx.indeterminate = true;
		});
	}
	//==========================================================================================================================================================>

	DebtChecked(DebtDocNum, DebtDocOpenToPay, debt) {
		document.querySelectorAll('input[type="checkbox"]:indeterminate').forEach((chkbx: HTMLInputElement) => (chkbx.indeterminate = false));

		const debtIndex = this.DebtArray.findIndex(ob => ob.DebtDocNum === DebtDocNum);

		debtIndex > -1 ? this.DebtArray.splice(debtIndex, 1) : this.DebtArray.push({ DebtDocNum, DebtDocOpenToPay, debtObject: debt });

		this.TotalSumDebtChecked = this._util.reduceArrayToNumber(this.DebtArray, 'DebtDocOpenToPay');
	}

	wasDebtPDFCreatedAndSent = false;

	shouldDisableSendButton = false;

	isEmailPickPopupOpen = false;

	directToSendThroughEmail() {
		if (this._ss.CustomerInformation.CanSendEmail && this._ss.CustomerInformation.CanSendEmail.includes('2')) {
			this.isEmailPickPopupOpen = true;
			return;
		}

		this.createPdfInServer();
	}

	createPdfInServer() {
		if (this.shouldDisableSendButton) return;

		this.isEmailPickPopupOpen = false;

		this.shouldDisableSendButton = true;
		const returnWithCurrency = number =>
			Intl.NumberFormat('he-IL', {
				style: 'currency',
				currency: 'ILS',
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
			}).format(number);

		const { AgentId } = this.OneClientInfoDebt[0],
			{ PTN, Address, ClientId, Client_Name, CustCity, Phone, CustEMail, CustEmail } = this.OneClientInfo,
			{ CustName } = this._ss.CustomerInformation;

		// const to = CustEMail || CustEmail;

		let to: string = '';

		switch (this.emailRadios.value) {
			case 'client':
				to = CustEMail || CustEmail;
				break;
			case 'user':
				to = this._user.agentEmail$.value;
				break;
			case 'custom':
				if (this._ss.CustomerInformation.CanSendEmail && this._ss.CustomerInformation.CanSendEmail.includes('2') && this.emailInput.valid) {
					to = this.emailInput.value;
				}
				break;

			default:
				break;
		}

		const replyTo = ' ';

		const arrToMap = this.DebtArray.length ? this.DebtArray.map(debt => debt.debtObject) : this.OneClientInfoDebt;

		const body = {
			PTN,
			Client_Name,
			ClientId,
			AgentId,
			TotalSumDebtChecked: returnWithCurrency(this.TotalSumDebtChecked),
			Address,
			CustCity,
			Phone,
			CustName,
			replyTo,
			to,
			UserName: this._ss.UserNameConnected,
			company: this._ss.CompanyNum,
			debtArray: arrToMap.map(debt => ({
				DebtDocNum: debt.DebtDocNum,
				DebtDocOpenToPay: returnWithCurrency(debt.DebtDocOpenToPay),
				DebtDocCreateDate: debt.DebtDocCreateDate,
				DebtDocToPayDate: debt.DebtDocToPayDate,
				DebtRemark: debt.DebtRemark,
			})),
		};

		this._ss.createPDFInIndex(body, 'hovot').subscribe({
			next: (res: any) => {
				if (res.err) {
					alert(returnError());
					return;
				}

				this._snackBar.open(`חובות ללקוח ${Client_Name} נשלחו בהצלחה`, 'סגור', {
					direction: 'rtl',
					panelClass: ['snack-bar'],
				});

				this.shouldDisableSendButton = false;
			},
			error: err => {
				this.shouldDisableSendButton = false;
				alert(returnError());
			},
		});
	}

	handleSubmitEmail() {
		if (this._receipts.shouldOpenEmailPopup$.value) {
			let value = '';

			switch (this.emailRadios.value) {
				case 'client':
					value = this.OneClientInfo.CustEMail || this.OneClientInfo.CustEmail;
					break;
				case 'user':
					value = this._user.agentEmail$.value;
					break;
				case 'custom':
					value = this.emailInput.value;
					break;

				default:
					break;
			}

			this._receipts.emailForReceipt = value;
			this._receipts.shouldOpenEmailPopup$.next(false);
			this._receipts.emailOkToContinue$.next(true);
			return;
		}
		this.createPdfInServer();
	}
}

function returnError(): string {
	return 'ישנה תקלת שרת, אנא נסה שנית מאוחר יותר...';
}
