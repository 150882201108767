import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';
import { AllProducts, AllProductsLikut } from '../Interfaces/SQL-morning-responses';

import { ImageStored, StoredDistributionTask, StoredDistributionTaskIDB, TruckDistributionStored, TruckDistributionTaskDisplayExtended } from '../Interfaces/truck-distribution';

// type columnType =

export const columnNamesArray = ['product'] as const;

// interface Data<T> extends T{
// 	idbID?: number;
// }

type Data<T> = T & {
	id?: number;
};

export interface OrderData<T1, T2> {
	idbID: number;
	allorder: T1;
	teudaInfo: T2;
	DocType: number;
	Company: number;
	ClientId: string;
}

const TABLES = ['allProducts', 'orders'] as const;
type TTables = typeof TABLES[number];

const getImageNameAndNumber = (file: File) => ({ number: file.name.split('-')[1][0] });

@Injectable({ providedIn: 'root' })
export class IndexedDBService extends Dexie {
	private images: Table<Data<ImageStored>, number>;
	private truckDistribution: Table<Data<TruckDistributionStored>, number>;
	private allProducts: Table<Data<AllProductsLikut[]>, number>;
	private truckDistributionTasks: Table<Data<StoredDistributionTaskIDB>, number>;

	fetchingFromStorage = false;

	constructor() {
		super('Mobility');

		// Define the database schema
		this.version(6).stores({
			// allProducts:
			// 	'++idbID, IC, qt, discount, company, Stock, Product_Name, ProductId, BP, BD, BS, BM, ItemBarcode, IGC, IDGR, ItemPack, ItemPicName, MinPrice, Price, RE, BB, ItemExtra1, ItemExtra2, ItemExtra3, ItemExtra4, ItemExtra5, PKD, CPP, NetPrice',
			// orders: '++idbID, allorder,teudaInfo,ClientId,Company,DocType',
			truckDistributionTasks: '++id,storageKey',
			images: '++id, taskIdentifier',
			truckDistribution: '++id',
			allProducts: '++id',
		});
	}

	// Image
	async storeImage(details: { taskIdentifier: string; imageFile: File; uploaded: boolean }) {
		const { imageFile, taskIdentifier, uploaded } = details;

		const doesExist = await this.getImage(taskIdentifier);

		if (doesExist.length) {
			const imageArray = [];

			for (const image of doesExist[0].imageFile) {
				const { number } = getImageNameAndNumber(image);
				imageArray[+number - 1] = image;
			}
			const { number } = getImageNameAndNumber(imageFile);

			imageArray[+number - 1] = imageFile;
			return await this.images.update(doesExist[0], { imageFile: imageArray });
		}

		return await this.images.add({ imageFile: [imageFile], taskIdentifier, uploaded });
	}
	async getImage(taskIdentifier: string) {
		return await this.images.where('taskIdentifier').equals(taskIdentifier).toArray();
	}

	async deleteImage(taskIdentifier: string) {
		const task = await this.getImage(taskIdentifier);
		if (task.length) {
			return await this.images.delete(task[0].id);
		}
	}

	async deleteAllImages() {
		if (!this.images) return;

		await this.images.clear();
	}

	// TruckDistribution

	async storeTruckDistributionDB(storage: TruckDistributionStored) {
		try {
			const isExisting = await this.truckDistribution.toArray();

			if (isExisting && isExisting.length) {
				return await this.truckDistribution.toCollection().modify(storage);
			}
		} catch (error) {
			throw error;
		}

		return await this.truckDistribution.add(storage);
	}

	async getTruckDistributionDB(table?: 'PODMain' | 'PODSub') {
		const db = await this.truckDistribution.toArray();

		if (table) {
			return db[0][table];
		}

		return db[0];
	}

	async deleteTruckDistributionDB() {
		if (!this.truckDistribution) return;

		return await this.truckDistribution.clear();
	}

	//AllProducts

	async storeAllProductsDB(storage: AllProductsLikut[]) {
		try {
			const isExisting = await this.allProducts.toArray();

			if (isExisting && isExisting.length) {
				return await this.allProducts.toCollection().modify(storage);
			}
		} catch (error) {
			throw error;
		}

		return await this.allProducts.add(storage);
	}

	async getAllProductsDB() {
		const db = await this.allProducts.toArray();
		return db;
	}

	async deleteAllProductsDB() {
		if (!this.allProducts) return;
		return await this.allProducts.clear();
	}

	// TruckTask
	async storeTruckDistributionTask(details: StoredDistributionTaskIDB) {
		const { storageKey } = details;

		let doesExist;

		try {
			doesExist = await this.getTruckDistributionTask(storageKey);
		} catch (err) {}

		if (doesExist?.length) {
			return await this.truckDistributionTasks.update(doesExist[0], details);
		}

		return await this.truckDistributionTasks.add(details);
	}
	async getTruckDistributionTask(storageKey: string) {
		if (!this.truckDistributionTasks) return [];
		return await this.truckDistributionTasks.where('storageKey').equals(storageKey).toArray();
	}
	async getAllTruckDistributionTasks() {
		if (!this.truckDistributionTasks) return [];
		return await this.truckDistributionTasks.toArray();
	}

	async deleteTruckDistributionTask(storageKey: string) {
		let task;

		try {
			task = await this.getTruckDistributionTask(storageKey);
		} catch (err) {}

		if (task) {
			return await this.truckDistributionTasks.delete(task[0].id);
		}
	}

	async deleteAllTruckDistributionTasks() {
		if (!this.truckDistributionTasks) return;

		await this.truckDistributionTasks.clear();
	}
}
