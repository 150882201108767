import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { compress, decompress } from 'lz-string';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-help-form',
	templateUrl: './help-form.component.html',
	styleUrls: ['./help-form.component.scss'],
})
export class HelpFormComponent implements OnInit {
	constructor(public _fb: FormBuilder, public _ss: StoreService, public router: Router, public _user: UserService) {}

	customerInfo = this._ss.CustomerInformation;

	agentID = this._ss.AgentIdConnected;

	UserNameConnected = this._ss.UserNameConnected;

	isBottomReached = false;

	agentEmail = this._user.agentEmail$.value;

	logo = MOBILITY_LOGO;

	// localStorage.setItem('AgentId', this._ss.AgentIdConnected)

	main = true;

	form = this._fb.nonNullable.group({
		name: ['', Validators.required],
		phone: ['', Validators.required],
		email: '',
		company: '',
		subject: [0, Validators.required],
		text: ['', Validators.required],
	});

	ngOnInit(): void {
		const span = document.getElementById('intersection');

		if (span) {
			const observer = new IntersectionObserver(entry => {
				if (entry[0].isIntersecting) {
					this.isBottomReached = true;
				}
			});

			observer.observe(span);
		}

		this.customerInfo = this.customerInfo ?? JSON.parse(decompress(localStorage.getItem('CustomerInformation') || '') || 'false');

		this.agentID = this.agentID ?? localStorage.getItem('AgentId');

		this.UserNameConnected = this.UserNameConnected ?? localStorage.getItem('UserName');

		this.agentEmail ||= localStorage.getItem('AgentEmail');

		if (this.agentID) {
			this.form.controls.text.setValue('מספר סוכן: ' + this.agentID + '\n\n');
		}
		if (this.UserNameConnected) {
			this.form.controls.name.setValue(this.UserNameConnected);
		}
		if (this.agentEmail) {
			this.form.controls.email.setValue(this.agentEmail);
		}

		if (this.customerInfo) {
			this.form.controls.company.setValue(`${this.customerInfo.CustNum} - ${this.customerInfo.CustName}`);
		}
	}

	subjects = ['בעיית תקשורת', 'נתונים לא נכונים', 'חסר לי משהו'];

	sending = false;

	async handleSubmit() {
		this.sending = true;

		const sub = this.subjects[this.form.controls.subject.value];

		const obj = { ...this.form.value };

		if (this._ss.AgentIdConnected) {
			obj.company = `${obj.company} | נשלח מסוכן: ${this._ss.AgentIdConnected}`;
		}

		this._ss.sendEmail({ ...obj, reason: sub }).subscribe({
			next: () => {
				this.main = this.sending = false;
			},
			error: err => {
				alert('ישנה תקלת שרת, אנא נסו שוב מאוחר יותר');
				this.sending = false;
			},
		});
	}
}
