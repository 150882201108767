import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

export const TABLET_RESOLUTION_CUTOFF = 768;
export const DESKTOP_RESOLUTION_CUTOFF = 1100;

@Injectable({
	providedIn: 'root',
})
export class DeviceInfoService {
	constructor(private _platform: Platform) {}
	isWebview$ = new BehaviorSubject(navigator.userAgent && navigator.userAgent.includes('wv'));
	isSamsungBrowser$ = new BehaviorSubject(navigator.userAgent && navigator.userAgent.includes('SamsungBrowser'));
	shouldShowNativeControls$ = new BehaviorSubject(this._platform.ANDROID || this._platform.IOS || innerWidth < 500);
	shouldntShowNativeControls$ = this.shouldShowNativeControls$.pipe(map(v => !v));

	windowWidth$ = new BehaviorSubject(innerWidth);
	isDesktopResolution$ = this.windowWidth$.pipe(map(width => width >= DESKTOP_RESOLUTION_CUTOFF));
	isTabletAndUpResolution$ = this.windowWidth$.pipe(map(width => width >= TABLET_RESOLUTION_CUTOFF));
	isNotTabletResolution$ = this.isTabletAndUpResolution$.pipe(map(bool => !bool));
	isNotDesktopResolution$ = this.isDesktopResolution$.pipe(map(bool => !bool));
}
