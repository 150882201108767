<div class="main-wrapper">
	<div
		class="main-content"
		[ngClass]="{
			'main-content_active': _ss.burgerMenu,
			'main-content_no-scroll': _ss.burgerMenuNoScroll
		}"
		(click)="_ss.closeMenu()"
	>
		<div class="page">
			<div class="header header-home">
				<!-- <span style="position:absolute;bottom:-1rem;left:50%;translate:-50%">{{this.online ? 'ONLINE' : 'OFFLINE'}}</span> -->
				<div class="nav-button">
					<!-- <div class="nav-button__link-arrow image">
            <img src="/assets/images/arrow-back.svg" alt="התנתק">
          </div>
          <a class="nav-button__link" (click)="DisconnectAndMoveToLogin()"> התנתק </a> -->
					<div class="call-control" (click)="router.navigate(['help'])" *ngIf="Admin && !isB2BAgent && Admin !== 'Catalog'">
						<span class="call-control__text"> קריאת שירות </span>
						<img width="auto" height="16px" src="/assets/images/bell.svg" alt="''" class="call-control__icon" />
					</div>
				</div>
				<div class="logo" #clickToLogoutElement>
					<img [src]="logo" alt="mobility" />
				</div>
				<div class="header-burger image"></div>
				<img src="../../../assets/images/wartime-logo.png" alt="wartime-logo" class="under-logo-image" />
			</div>

			<ng-container *ngIf="_alert.hasAgentsAlerts && !willNeedToUpdate">
				<button
					class="alerts-wrapper"
					*ngIf="_alert.agentAlerts$ | async as agentAlertsNumber; else noActiveAgentAlerts"
					(click)="router.navigate(['manage-alerts'])"
					role="button"
					[matBadge]="agentAlertsNumber"
					matBadgePosition="before"
					matBadgeSize="small"
					matBadgeColor="primary"
				>
					<i class="alerts-bell"></i>
				</button>
			</ng-container>
			<ng-template #noActiveAgentAlerts>
				<button class="alerts-wrapper" *ngIf="_alert.hasAgentsAlerts" (click)="router.navigate(['manage-alerts'])" role="button">
					<i class="alerts-bell"></i>
				</button>
			</ng-template>
			<marquee direction="right" style="font-size: 1.7em; padding-bottom: 10px" *ngIf="_ss.CustomerInformation"> {{ _ss.CustomerInformation.CustNotes }}</marquee>
			<div class="content">
				<div class="content__header">
					<div
						class="company-logo"
						[ngClass]="{
							'company-logo-empahsis-image': _ss.CustomerInformation?.SpecificCompanyVersion === '1'
						}"
					>
						<img
							width="100%"
							src="{{ _ss.CustomerInformation?.CustImageDomain }}/companylogo.jpg"
							crossorigin="anonymous"
							alt="mobility"
							class="company-logo__image"
							onerror="this.onerror=null; this.src=MOBILITY_LOGO"
						/>
					</div>
					<div class="content__header__date" dir="rtl">
						{{ (datedate && this.datedate.toString().slice(8, 10) + '-' + this.datedate.toString().slice(5, 7) + '-' + this.datedate.toString().slice(0, 4)) || _ss.VersionNumber }}
						<div class="content__header__date-time">{{ this.datetime }}</div>
						<div class="content__header__date-time">עדכון אחרון</div>
					</div>
				</div>

				<div class="content__body">
					<div class="greeting">
						<span class="greeting__caption">
							<div>,שלום</div>
							<div style="direction: rtl">
								<b>{{ _ss.UserNameConnected }}</b>
							</div>
						</span>
						<span class="greeting__info">
							<b>{{ _ss.AgentIdConnected }} :</b>מספר סוכן
						</span>
					</div>
					<div class="title" *ngIf="ShowOptions === false">מוריד נתונים...</div>
					<div class="title" *ngIf="Loading">אנא המתן בזמן טעינה...</div>
					<ng-container *ngIf="_update.checkingNewVersion$ | async">
						<ng-container *ngIf="_update.swVersionState$ | async as updateState">
							<div class="title" [ngSwitch]="updateState">
								<span *ngSwitchCase="'updating'">אנא המתן בעת הורדת גרסה חדשה...</span>
								<span *ngSwitchCase="'updateSuccessful'">גרסה חדשה הותקנה! אנא המתן בעת טעינת נתונים...</span>
								<span *ngSwitchCase="'updateFailed'">קרתה תקלה בעת עדכון נתונים. נסו למחוק את האפליקציה ולנסות שנית. אם התקלה ממשיכה, אנא צרו קשר עם חברת מוביליטי.</span>
							</div>
						</ng-container>
					</ng-container>

					<div class="menu" *ngIf="ShowOptions && Loading === false && !isB2BAgent && !isBIUser && !isLikutAppActive && !isTruckModeActive && noNewVersion">
						<div class="menu__item" *ngIf="Admin !== 'simple' && Admin !== 'Catalog'" (keydown.enter)="MoveToDohot()" (click)="MoveToDohot()" tabindex="0" role="button">
							<img src="/assets/images/reports.svg" alt="mobility" />
							<div class="menu__item-text">דוחות</div>
						</div>
						<div class="menu__item" (keydown.enter)="MoveToClients()" (click)="MoveToClients()" *ngIf="showClientsButton" tabindex="0" role="button">
							<img [src]="Admin !== 'Catalog' ? '/assets/images/customers.svg' : '/assets/images/icons8-brochure-91.png'" alt="mobility" />
							<div class="menu__item-text">{{ Admin !== 'Catalog' ? 'לקוחות' : 'קטלוג' }}</div>
						</div>
						<div class="menu__item" (click)="MoveToClients('suppliers')" (keydown.enter)="MoveToClients('suppliers')" *ngIf="showSuppliersButton" tabindex="0" role="button">
							<img src="/assets/images/customers.svg" alt="mobility" />
							<div class="menu__item-text">ספקים</div>
						</div>
						<div
							class="menu__item"
							(click)="handleSubAppNavigation({ mode: rallyanceReclamationPath, route: 'tasks' })"
							(keydown.enter)="handleSubAppNavigation({ mode: rallyanceReclamationPath, route: 'tasks' })"
							*ngIf="hasRallyanceReclamation && !willNeedToUpdate"
							tabindex="0"
							role="button"
						>
							<img src="/assets/images/customers.svg" alt="mobility" />
							<div class="menu__item-text">רקלמטור</div>
						</div>
					</div>

					<div class="menu" *ngIf="ShowOptions && Loading === false && isB2BAgent && !isBIUser && !isLikutAppActive && !isTruckModeActive && noNewVersion">
						<div
							class="menu__item"
							tabindex="0"
							*ngIf="_ss.CustomerInformation.B2BOptions === '2' && !isB2BAgentNoPricesAndNoParameters"
							(click)="MoveToDohot('b2b')"
							(keydown.enter)="MoveToDohot('b2b')"
						>
							<img src="/assets/images/reports.svg" alt="mobility" />
							<div class="menu__item-text">דוחות</div>
						</div>
						<div
							class="menu__item"
							*ngIf="_ss.CustomerInformation.B2BOptions === '3' || isB2BAgentNoPricesAndNoParameters"
							(click)="router.navigate(['dohot-bizoim-azmanot-b2b-to-agent'])"
							(keydown.enter)="router.navigate(['dohot-bizoim-azmanot-b2b-to-agent'])"
							tabindex="0"
						>
							<img src="/assets/images/reports.svg" alt="mobility" />
							<div class="menu__item-text">מעקב הזמנות</div>
						</div>
						<div class="menu__item" (click)="MoveToClients()" (keydown.enter)="MoveToClients()" tabindex="0">
							<img src="/assets/images/customers.svg" alt="mobility" />
							<div class="menu__item-text">הזמנה</div>
						</div>
					</div>

					<div class="menu justify-align-center gap" *ngIf="ShowOptions && Loading === false && isLikutAppActive && !isTruckModeActive && noNewVersion" style="flex-wrap: wrap">
						<ng-container *ngIf="_likut.isLikutManager">
							<div class="menu__item no-margin" tabindex="0" *ngIf="_likut.canSeeDashboard" (click)="router.navigate(['mobilitydashbord-likut'])">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.dashboard }}</div>
							</div>
							<div class="menu__item no-margin" tabindex="0" *ngIf="_likut.canSeeManageOrder" (click)="handleSubAppNavigation({ mode: 'likut', route: 'manage' })">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.manageTaskReportTitle }}</div>
							</div>

							<div class="menu__item no-margin" tabindex="0" *ngIf="_likut.canSeeManageCertainStatuses" (click)="handleSubAppNavigation({ mode: 'likut', route: 'manage-part' })">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.manageCertainStatusReportTitle }}</div>
							</div>
						</ng-container>
						<div
							class="menu__item no-margin"
							tabindex="0"
							*ngIf="(_likut.isLikutManager || _likut.isCheckerAgentAndCanEdit) && _likut.canSeeManageAndAssignLikut"
							(click)="handleSubAppNavigation({ mode: 'likut', route: 'manage-assignment' })"
						>
							<img src="/assets/images/reports.svg" alt="mobility" />
							<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReport }}</div>
						</div>
						<ng-container *ngIf="_likut.isLikutManager">
							<div class="menu__item no-margin" tabindex="0" *ngIf="_likut.canSeeManageNotFulfilled" (click)="handleSubAppNavigation({ mode: 'likut', route: 'manage-not-fulfilled' })">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.manageNotFulfilled }}</div>
							</div>
							<div class="menu__item no-margin" tabindex="0" *ngIf="_likut.canSeeNoStatus" (click)="handleSubAppNavigation({ mode: 'likut', route: 'manage-no-status' })">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.manageNoStatus }}</div>
							</div>
							<div class="menu__item no-margin" tabindex="0" *ngIf="_likut.canSeeAssignUsersKanban" (click)="handleSubAppNavigation({ mode: 'likut', route: 'assign-users-tasks' })">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReportLikut }}</div>
							</div>
						</ng-container>
						<ng-container *ngIf="!_likut.isLikutManager">
							<div class="menu__item no-margin" tabindex="0" *ngIf="!_likut.isMekabel; else likutMekabelBlock" (click)="handleSubAppNavigation({ mode: 'likut', route: 'clients' })">
								<img src="/assets/images/customers.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.homeScreenAction }}</div>
							</div>
							<ng-template #likutMekabelBlock>
								<div class="menu__item no-margin" tabindex="0" (click)="handleSubAppNavigation({ mode: 'likut', route: 'clients/mekabel' })">
									<img src="/assets/images/customers.svg" alt="mobility" />
									<div class="menu__item-text menu__item-text-higher-line-height">קבלת סחורה</div>
								</div>
							</ng-template>
						</ng-container>
					</div>
					<div class="menu justify-align-center gap" *ngIf="ShowOptions && Loading === false && isTruckModeActive && !isLikutAppActive && noNewVersion" style="flex-wrap: wrap">
						<ng-container *ngIf="_truckLoading.isTruckLoadingManager">
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckLoading.reportsRecord.truckLoadingExtra4AssignmentManagement"
								(click)="handleSubAppNavigation({ mode: 'truck-loading', route: 'manage-assignment' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReport }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckLoading.reportsRecord.truckDistributionAssignment"
								(click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'assign-users-tasks' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReport2 }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckLoading.reportsRecord.truckLoadingAssignment"
								(click)="handleSubAppNavigation({ mode: 'truck-loading', route: 'assign-users-tasks' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReport3 }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckLoading.reportsRecord.truckLoadingPassword"
								(click)="handleSubAppNavigation({ mode: 'truck-loading', route: 'password' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.generatePassword }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckLoading.reportsRecord.truckDistributionMap"
								(click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'distribution-map' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReportMapDistribution }}</div>
							</div>
							<div class="menu__item no-margin" tabindex="0" *ngIf="_truckLoading.reportsRecord.mobilityDashboard" (click)="router.navigate(['mobilitydashbord-truck-distribution-or-loading'])">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.dashboard }}</div>
							</div>
						</ng-container>
						<ng-container *ngIf="_truckLoading.isTruckDistributionManager">
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.truckLoadingExtra4AssignmentManagement"
								(click)="handleSubAppNavigation({ mode: 'truck-loading', route: 'manage-assignment' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReport }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.truckDistributionAssignment"
								(click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'assign-users-tasks' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReport2 }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.truckLoadingAssignment"
								(click)="handleSubAppNavigation({ mode: 'truck-loading', route: 'assign-users-tasks' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReport3 }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.truckLoadingPassword"
								(click)="handleSubAppNavigation({ mode: 'truck-loading', route: 'password' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.generatePassword }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.truckDistributionMap"
								(click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'distribution-map' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.taskAssignmentReportMapDistribution }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.truckDistributionNotSupplied"
								(click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'manage-not-fulfilled' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.manageNotFulfilledTruckDistribution }}</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.truckDistributionWasSupplied"
								(click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'manage-supplied' })"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.manageSuppliedTruckDistribution }}</div>
							</div>
							<div class="menu__item no-margin" tabindex="0" *ngIf="_truckDistribution.reportsRecord.truckDistributionCanGenerateNewTask" (click)="togglePopupTruckDistributionNewTask('open')">
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">הפקת משימות הפצה</div>
							</div>
							<div
								class="menu__item no-margin"
								tabindex="0"
								*ngIf="_truckDistribution.reportsRecord.mobilityDashboard"
								(click)="router.navigate(['mobilitydashbord-truck-distribution-or-loading'])"
							>
								<img src="/assets/images/reports.svg" alt="mobility" />
								<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.dashboard }}</div>
							</div>
						</ng-container>
						<div
							class="menu__item no-margin"
							tabindex="0"
							*ngIf="_truckLoading.canStartDistribution"
							(click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'manage-not-fulfilled-only-agent' })"
						>
							<img src="/assets/images/reports.svg" alt="mobility" />
							<div class="menu__item-text menu__item-text-higher-line-height">דוח משימות</div>
						</div>
						<div class="menu__item no-margin" tabindex="0" *ngIf="_truckLoading.canStartLoading" (click)="handleSubAppNavigation({ mode: 'truck-loading', route: 'clients' })">
							<img src="/assets/images/customers.svg" alt="mobility" />
							<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.homeScreenAction }}</div>
						</div>

						<div class="menu__item no-margin" tabindex="0" *ngIf="_truckLoading.canStartDistribution" (click)="handleSubAppNavigation({ mode: 'truck-distribution', route: 'clients' })">
							<img src="/assets/images/customers.svg" alt="mobility" />
							<div class="menu__item-text menu__item-text-higher-line-height">{{ _text.store.homeScreenAction2 }}</div>
						</div>
					</div>

					<div class="menu justify-align-center gap" *ngIf="ShowOptions && Loading === false && isBIUser && noNewVersion" style="flex-wrap: wrap">
						<div class="menu__item no-margin" tabindex="0" *ngFor="let report of _ss.biUserAvailableReports; index as i" (click)="router.navigate(['dynamic-report', 'report' + report.ID])">
							<img src="/assets/images/reports.svg" alt="mobility" />
							<div class="menu__item-text menu__item-text-higher-line-height">{{ report.Name }}</div>
						</div>
					</div>

					<!-- <div class="developer-buttons" *ngIf=" (_user.isTestUser$ | async)"> -->
					<div class="developer-buttons" *ngIf="document.location.origin.match('localhost') || document.location.origin.match('test.mobilityltd') || !isProduction || (_user.isTestUser$ | async)">
						<button mat-flat-button (click)="openCustomerInformationOverrideModal()">Edit Local Customer Information</button>
						<button mat-flat-button (click)="changeAdminStatus(Admin === 'true' ? 'false' : 'true')">
							{{ (Admin === 'true' ? 'Remove' : 'Make') + ' Admin' }}
						</button>
						<mat-form-field appearance="fill">
							<mat-label>Change Admin In Localstorage</mat-label>
							<mat-select (selectionChange)="changeAdminAndReload($event)">
								<mat-option *ngFor="let likut of adminTypes" [value]="likut">{{ likut }}</mat-option>
							</mat-select>
						</mat-form-field>
						<button mat-flat-button (click)="deleteAndReload()">Delete localStorage and refresh</button>
						<button mat-flat-button (click)="router.navigate(['developer-info'])">Developer info page</button>
						<button mat-flat-button (click)="replaceSavedCustomerInformation()">replace custinfo</button>
						<button mat-flat-button (click)="clearCache()">Clear Image Cache</button>
						<button mat-flat-button (click)="setCookies()">Skip Password Check</button>
						<button mat-flat-button (click)="_likut.setupTestingLikutMekabel()">Begin Testing Likut Mekabel New Task</button>

						<!-- <button (click)="router.navigate(['barcode-reader'])">Go To Pdf View</button> -->
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- markup for popup -->
	<!-- <div class="popup" *ngIf="DisconnectPop && LoggoutPop === false">
		<div class="overlay"></div>
		<div class="order-cart__popup popup__content">
			<div class="popup__close" (click)="NoDisconnect()"></div>
			<div class="order-cart__popup-image image">
				<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
			</div>
			<h2 class="order-cart__popup-title">ברצונך להתנתק ?</h2>
			<h2 class="order-cart__popup-title">כל התעודות הפתוחות יימחקו</h2>
			<div class="order-cart__popup-block row">
				<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoDisconnect()">לא, חזור</button>
				<button class="order-cart__popup-button" (click)="YesDisconnect()">כן, התנתק</button>
			</div>
		</div>
	</div> -->

	<div class="popup" *ngIf="LoggoutPop">
		<div class="overlay"></div>
		<div class="order-cart__popup popup__content">
			<div class="order-cart__popup-image image">
				<img src="assets/images/logout.gif" alt="photo" />
			</div>
		</div>
	</div>

	<!-- <div class="popup" *ngIf="isNewTruckDistributionTaskPopupOpen$ | async">
		<div class="overlay"></div>
		<div class="order-cart__popup popup__content">
			<div class="popup__close" (click)="togglePopupTruckDistributionNewTask('close')"></div>
			<h2 class="order-cart__popup-title">הפק משימה</h2>
			<div class="order-cart__popup-block row" [ngSwitch]="truckDistributionNewTaskSubmitStatus$ | async">
				<form class="grid row" *ngSwitchCase="'idle'" style="place-items: center" [formGroup]="truckDistributionNewTaskForm" dir="rtl" (ngSubmit)="handleTruckDistributionNewTaskFormSubmit()">
					<mat-form-field color="primary">
						<mat-label>בחר לקוח</mat-label>
						<input type="text" placeholder="לקוח" matInput [matAutocomplete]="autoClient" formControlName="client" />
						<mat-autocomplete autoActiveFirstOption #autoClient="matAutocomplete">
							<mat-option *ngFor="let client of allClientsDisplay$ | async" [value]="client.ClientId">
								<span style="unicode-bidi: isolate">{{ client.ClientId }}</span> -
								<span style="unicode-bidi: isolate">{{ client.Client_Name }}</span>
							</mat-option>
						</mat-autocomplete>
						<mat-error>{{ handleErrorText('client') }}</mat-error>
					</mat-form-field>
					<mat-form-field color="primary" style="max-width: 180px">
						<mat-label>בחר תאריך יעד</mat-label>
						<input [min]="today" readonly="true" formControlName="date" matInput [matDatepicker]="picker" (click)="picker.open()" />
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
					<mat-form-field color="primary" dir="rtl">
						<mat-label dir="rtl">שיוך</mat-label>
						<input type="text" placeholder="נהג" matInput [matAutocomplete]="autoAgent" formControlName="agent" />
						<mat-autocomplete autoActiveFirstOption #autoAgent="matAutocomplete">
							<mat-option *ngFor="let agent of allAgentsDisplay$ | async" [value]="agent.AgentID">
								<span style="unicode-bidi: isolate">{{ agent.AgentID }}</span> -
								<span style="unicode-bidi: isolate">{{ agent.UserName }}</span>
							</mat-option>
						</mat-autocomplete>
						<mat-error>{{ handleErrorText('agent') }}</mat-error>
					</mat-form-field>
					<mat-form-field color="primary">
						<mat-label>סוג משימה</mat-label>
						<mat-select formControlName="typeofTask">
							<mat-option *ngFor="let task of typeofTasks$ | async" [value]="task.type" style="direction: rtl">
								{{ task.name }}
							</mat-option>
						</mat-select>
						<mat-error>יש לבחור אחד</mat-error>
					</mat-form-field>
					<mat-form-field color="primary">
						<mat-label>הערות למשימה</mat-label>
						<textarea style="min-height: 100px" formControlName="remarks" matInput placeholder="הערות"></textarea>
					</mat-form-field>
					<div class="row gap justify-align-center">
						<button mat-raised-button type="submit" color="primary">אישור</button>
						<button mat-raised-button type="button" (click)="togglePopupTruckDistributionNewTask('close')">ביטול</button>
					</div>
				</form>
				<div class="grid justify-align-center row">
					<mat-spinner *ngSwitchCase="'loading'"></mat-spinner>
					<div *ngSwitchCase="'success'" class="row grid gap">
						<div class="valid-color order-cart__popup-title">המשימה נוצרה בהצלחה!</div>
						<div class="order-process__image image" style="width: min(300px, 75vw)">
							<img src="/assets/images/basket/process/save.gif" alt="photo" />
						</div>
						<button mat-raised-button color="primary" (click)="truckDistributionNewTaskSubmitStatus$.next('idle')">אישור</button>
					</div>
					<div *ngSwitchCase="'error'" class="row grid gap">
						<div class="invalid-color">אירעה שגיאה בעת יצירת המשימה, נסו שנית מאוחר יותר</div>
						<button mat-raised-button (click)="truckDistributionNewTaskSubmitStatus$.next('idle')">הבנתי</button>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<!-- end markup for popup -->

	<!-- markup for popup -->
	<div class="popup" *ngIf="HasMultiplleCompanysPop">
		<div class="overlay"></div>
		<div class="order-cart__popup popup__content">
			<div class="popup__close" (click)="NoChoose()"></div>
			<div class="order-cart__popup-image image">
				<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
			</div>
			<h2 class="order-cart__popup-title">בחר חברה</h2>
			<button class="order-cart__popup-button order-cart__popup-button_send" (click)="NoChoose()">חזור</button>
			<br />
			<ngx-skeleton-loader *ngIf="!ShowCompanys?.length; else companiesBlock"></ngx-skeleton-loader>

			<ng-template #companiesBlock>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button" (click)="CompanyChoosen(sc.Company, sc.CompanyName)" *ngFor="let sc of ShowCompanys">
						{{ sc.CompanyName }}
					</button>
				</div>
			</ng-template>
		</div>
	</div>
	<!-- end markup for popup -->

	<!-- main menu markup -->
	<div class="main-menu" [ngClass]="{ 'main-menu_active': _ss.burgerMenu }">
		<div class="empty-line"></div>
		<div class="main-menu__content">
			<div class="main-menu__row main-menu__logo">
				<div class="main-menu__logo-image image">
					<img src="{{ _ss.CustomerInformation?.CustImageDomain }}/companylogo.jpg" crossorigin="anonymous" alt="mobility" onerror="this.onerror=null; this.src=MOBILITY_LOGO" />
				</div>
				<div class="main-menu__logo-col">
					<div class="main-menu__logo-col-title">{{ _ss.UserNameConnected }}</div>
					<div class="main-menu__logo-col-descrip">{{ _ss.AgentIdConnected }}</div>
				</div>
			</div>
			<!-- TODO combine these menus into 1 under ng-containers -->
			<ng-container *ngIf="Admin === 'Catalog' || isLikutAppActive || isTruckModeActive; else menuBlock">
				<div class="main-menu__border"></div>
				<div class="main-menu__row">
					<div class="main-menu__col" (click)="UpdateDb()">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-settings.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">עדכן נתונים</div>
					</div>
					<div class="main-menu__border"></div>
					<div class="main-menu__col" (click)="handleDisconnectionPopup('open')" style="margin-top: 25px">
						<div class="main-menu__col-title">התנתקות</div>
					</div>
					<div class="main-menu__border"></div>
					<div class="main-menu__col" (click)="refreshCustomerInformation()" style="margin-top: 25px">
						<div class="main-menu__col-title">רענון</div>
					</div>
					<div class="main-menu__border"></div>
					<div class="main-menu__col" (click)="Privacy = true" style="margin-top: 25px">
						<div class="main-menu__col-title">Privacy Policy - תנאי שימוש</div>
					</div>
					<div class="main-menu__border"></div>
					<div class="main-menu__col">
						<div class="main-menu__col-title" *ngIf="_ss.CustomerInformation">{{ _ss.VersionNumber }}-{{ Admin }}-{{ _ss.CustomerInformation.CustName }}</div>
					</div>
				</div>
			</ng-container>

			<ng-template #menuBlock>
				<div class="main-menu__border"></div>
				<div class="main-menu__row">
					<div class="main-menu__col" (click)="UpdateDb()">
						<div class="main-menu__col-icon image">
							<img src="/assets/images/burger/icon-settings.svg" alt="icon" />
						</div>
						<div class="main-menu__col-title">עדכן נתונים</div>
					</div>
					<div class="main-menu__border"></div>
					<div class="main-menu__row">
						<div class="main-menu__col" (click)="MoveToClients()">
							<div class="main-menu__col-icon image">
								<img src="/assets/images/burger/icon-person.svg" alt="icon" />
							</div>
							<div class="main-menu__col-title">{{ isB2BAgent ? 'הזמנה' : 'לקוחות' }}</div>
						</div>
						<ng-container *ngIf="!isB2BAgent && Admin !== 'simple'">
							<div class="main-menu__border"></div>
							<div class="main-menu__col" (click)="MoveToDohot()">
								<div class="main-menu__col-icon image">
									<img src="/assets/images/burger/icon-statistic.svg" alt="icon" />
								</div>
								<div class="main-menu__col-title">דוחות</div>
							</div>
						</ng-container>
					</div>
					<div class="main-menu__border"></div>
					<div class="main-menu__row">
						<div class="main-menu__col" (click)="MoveToDailyReport()">
							<div class="main-menu__col-icon image">
								<img src="/assets/images/burger/icon-calendar.svg" alt="icon" />
							</div>
							<div class="main-menu__col-title">דו"ח יומי</div>
						</div>
					</div>
					<ng-container *ngIf="_receipts.hasReceipts && !_ss.blockPaths['dashboard-receipts-agent']">
						<div class="main-menu__border"></div>
						<div class="main-menu__row">
							<div class="main-menu__col" (click)="MoveToDailyReportReceipts()">
								<div class="main-menu__col-icon image">
									<img src="/assets/images/burger/icon-calendar.svg" alt="icon" />
								</div>
								<div class="main-menu__col-title">דו"ח יומי - קבלות</div>
							</div>
						</div>
					</ng-container>

					<div class="main-menu__border" *ngIf="Admin === 'true'"></div>
					<div class="main-menu__row" *ngIf="Admin === 'true'">
						<div class="main-menu__col" (click)="MoveAdmin()">
							<div class="main-menu__col-icon image">
								<img src="/assets/images/burger/icon-calendar.svg" alt="icon" />
							</div>
							<div class="main-menu__col-title">דף מנהל</div>
						</div>
					</div>

					<div class="main-menu__border"></div>
					<div class="main-menu__col">
						<div class="main-menu__col-title" *ngIf="_ss.CustomerInformation">{{ _ss.VersionNumber }}-{{ isB2BAgent ? Admin + '-' : '' }}{{ _ss.CustomerInformation.CustName }}</div>
					</div>

					<div class="main-menu__border"></div>
					<div class="main-menu__col" (click)="handleDisconnectionPopup('open')" style="margin-top: 25px">
						<div class="main-menu__col-title">התנתקות</div>
					</div>
					<div class="main-menu__border"></div>
					<div class="main-menu__col" (click)="refreshCustomerInformation()" style="margin-top: 25px">
						<div class="main-menu__col-title">רענון</div>
					</div>

					<div class="main-menu__border"></div>
					<div class="main-menu__col" (click)="Privacy = true" style="margin-top: 25px">
						<div class="main-menu__col-title">Privacy Policy - תנאי שימוש</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
	<!-- end main menu markup -->
</div>

<!-- markup for logout popup -->
<div class="popup" *ngIf="showLogoutPopup">
	<div class="overlay"></div>
	<div class="popup__content popup__content_logout">
		<div class="popup__close" (click)="showHideLogoutPopup(false)"></div>
		<div class="popup__content-image image">
			<img src="/assets/images/logout.gif" alt="photo" />
		</div>
		<h2 class="popup__content-title">ברצונך להתנתק?</h2>
		<div class="popup__content-description">כל התעודות הפתוחות יימחקו</div>
		<div class="popup__content-block popup__content-block_center">
			<button class="popup__content-button" (click)="showHideLogoutPopup(false)">כן, התנתק</button>
			<button class="popup__content-button popup__content-button_red" (click)="showHideLogoutPopup(false)">לא, חזור</button>
		</div>
	</div>
</div>
<!-- end markup for logout popup -->

<!-- markup for logout popup -->
<div class="popup" *ngIf="isDisconnectionInputPopupOpen">
	<div class="overlay"></div>
	<div class="popup__content popup__content_logout">
		<div class="popup__close" (click)="handleDisconnectionPopup('close')"></div>
		<div class="popup__content-image image">
			<img src="/assets/images/logout.gif" alt="photo" />
		</div>
		<h2 class="popup__content-title">הכנס קוד התנתקות</h2>
		<div class="popup__content-description">
			<form [formGroup]="form"><input type="text" formControlName="disconnectInput" /></form>
		</div>
		<div class="popup__content-block popup__content-block_center">
			<button class="popup__content-button" (click)="handleDisconnection()">כן, התנתק</button>
			<button class="popup__content-button popup__content-button_red" (click)="handleDisconnectionPopup('close')">לא, חזור</button>
		</div>
	</div>
</div>
<!-- end markup for logout popup -->

<!-- markup for logout popup -->
<div class="popup" *ngIf="Doh">
	<div class="overlay"></div>
	<div class="popup__content popup__content_logout">
		<div class="popup__close" (click)="Doh = false"></div>

		<h3 class="popup__content-title">{{ this.resa }}</h3>
		<h3 class="popup__content-title" style="color: red">{{ this.erra }}</h3>

		<button class="popup__content-button popup__content-button_red" (click)="Doh = false">חזור</button>
	</div>
</div>
<!-- end markup for logout popup -->

<!-- ========================= -->
<div class="popup" *ngIf="Privacy" style="z-index: 1999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<!-- <div
                 class="popup__close"
                 (click)="Privacy = false"
               ></div> -->

		<div style="text-align: center; width: 99%; margin: auto; font-size: 15px; color: blue; font-weight: 500px">Privacy Policy - תנאי שימוש</div>
		<div style="text-align: center; width: 99%; margin: auto; font-size: 15px">office@mobility-ltd.com - 050-3899773</div>

		<div dir="rtl" class="div3" style="margin-top: 10px">
			תנאי שימוש באפליקציית מוביליטי, כדי לאבטח את המשתמש שלך, מוביליטי לעולם לא תבקש את פרטיך האישיים, פרטי ההתחברות מסופקים אך ורק על ידי חברת מובילטי, אנא אל תחלוק את פרטי ההתחברות הניתנים לך,
			מוביליטי רשאית לעדכן את תנאי השימוש, אנא וודא שאתה בודק את תנאי השימוש מפעם לפעם, אם הנך חושב ששימוש באפליקציית מוביליטי פוגע באחד מתנאי השימוש אנא צור איתנו קשר בפרטים למעלה.
			<div class="product-detail-add__popup-row" style="width: 100px; margin: auto; margin-top: 10px">
				<button class="popup-button" (click)="Privacy = false" style="background-color: #38d88b; width: 100px">מאשר</button>
			</div>
		</div>

		<div class="div3">
			This privacy policy sets out how mobility uses and protects any information on this website. mobility is committed to ensuring that your privacy is protected. mobility does not required any
			information from our users,login information is provided only by mobility, mobility will never ask you to insert any personal information, then you can be assured that it will only be used in
			accordance with this privacy statement. mobility may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with
			any changes. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly
			correct any information found to be incorrect.

			<div class="product-detail-add__popup-row" style="width: 100px; margin: auto; margin-top: 10px">
				<button class="popup-button" (click)="Privacy = false" style="background-color: #38d88b; width: 100px">Confirm</button>
			</div>
		</div>
	</div>
</div>
<!-- ========================= -->

<app-menu-button></app-menu-button>
