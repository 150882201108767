import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shekels',
	pure:true
})
export class ShekelsPipe implements PipeTransform {
	transform(value: string | number, vat?: number): string {
		if (typeof value === 'string' && isNaN(+value)) return value;

		const num = vat ? +value + +value * (vat / 100) : value;
		return Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(+num);
	}
}
