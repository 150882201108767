import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { decompress } from 'lz-string';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { ReclamationReasonsResponse } from 'src/app/Interfaces/reclamations';
import { Product } from 'src/app/Interfaces/product';
import { OnlineStatusService } from 'src/app/services/online-status.service';
import { Special, SpecialInCart, SpecialInCartObj, SpecialObjectHash, specialsTypesEnum } from 'src/app/Interfaces/special';
import { MatDialog } from '@angular/material/dialog';
import { SpecialInfoDialog } from '../specials/special-dialog.component';
import { Time, UtilService } from 'src/app/services/util.service';
import { OrderState, Product2 } from 'src/app/Interfaces/all-products';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TextService } from 'src/app/services/text.service';
import { normalizeNumber, normalizeNumberToMaxThreeDecimal } from 'src/app/Utility/functions';
import { MOBILITY_LOGO } from 'src/app/Utility/constants';
import { UserService } from 'src/app/services/user.service';
import { ItemPacks } from 'src/app/Interfaces/SQL-morning-responses';
import { DocMinTeudaService, MinDocTeuda, MinDocTeudaBaseWithAction, MinDocTeudaRecord, whataction } from 'src/app/services/doc-min-teuda.service';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { BackendCreditTerms } from 'src/app/Interfaces/credit-payment';
import { B2bService } from 'src/app/services/b2b.service';
import { ReceiptsService } from 'src/app/services/receipts.service';
import { RECLAMATION_DOCTYPE, RECLAMATION_RALLYANCE_DOCTYPE } from 'src/app/Utility/constants-doctype';
import { DoctypeService } from 'src/app/services/doctype.service';
import { AlertService } from 'src/app/services/alert.service';
import { MustIncludeProductsService } from 'src/app/services/must-include-products.service';

type Heara = { text: string; id: number };

type CatalogRemark = {
	text: string;
	type: 'number' | 'text';
	maxLength: number;
};

export type EmailRadioValues = 'none' | 'client' | 'user' | 'custom';
@Component({
	selector: 'app-teuda',
	templateUrl: './teuda.component.html',
	styleUrls: ['./teuda.component.scss'],
})
export class TeudaComponent implements OnInit, OnDestroy {
	constructor(
		private _docMin: DocMinTeudaService,
		public _b2b: B2bService,
		public _cc: CreditCardService,
		private router: Router,
		private _fb: FormBuilder,
		public _ss: StoreService,
		public _online: OnlineStatusService,
		private dialog: MatDialog,
		private _util: UtilService,
		public _text: TextService,
		public _user: UserService,
		public _receipts: ReceiptsService,
		private _doctypes: DoctypeService,
		private _alert: AlertService,
		private _mustIncludeProducts: MustIncludeProductsService,
	) {}

	AllOrder = [];
	AllOrderSwitch;
	TotalSumOAllOrder = 0;
	TotalSumOAllOrderProfit = 0;
	TotalSumOAllOrderPlusVat = 0;

	showHideFirstStep = false;
	showHideSecondStep = false;
	showHideThreeStep = false;
	showHideFourStep = false;
	orderProcess = false;
	orderSuccess = false;
	CancelOrder = false;
	orderError = false;
	SendOrder = false;
	SaveTeudaPop = false;
	DeleteProductPop = false;
	AllProductsSearch;

	HelpPriceArizot = 0;

	ChoosenDeleteProduct;
	ChoosenDeleteProductId;
	NewCart;
	CustDiscountShow = 0;
	CustDiscountShowVat = 0;

	authManpikNo = '';
	shovarNum = '';

	StatusOrder = 0;

	online;

	Vorder = false;

	HearaNewOrder = '';

	//! Changed date is necessary for SQL. doesn't know how to deal with datetime. check .ReadmeMD file note
	Date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * Time.minuteInMilliseconds);

	OrderStatusNumberSend = '0';
	OrderStatusNumber = '0';

	SupplyDateTeuda = this.Date.toISOString().toString().slice(0, 10);

	SupplyAdressTeuda = '';

	AllClientsHelp;
	AllClients = [];
	DailyOrder = [];
	DailyOrderAllorder;

	AAABBB = [];

	Admin;
	isB2BAgent = false;
	isLeasingOrder = false;
	isCatalogAgent = false;
	isB2BAgentNoPricesAndNoParameters = false;
	isRallyanceReclamation = false;

	searchString: string | null = '';
	form = this._fb.group({
		Yehidot: ['', Validators.required],
		Arizot: ['', Validators.required],
		OrderBy: ['', Validators.required],
		OrderByProducts: ['', Validators.required],
		HearaForOrder: [<string | null>'', Validators.required],
		HearaForOrder1: [<string | null>'', Validators.required],
		HearaForOrder2: [<string | null>'', Validators.required],
		HearaForOrder3: [<string | null>'', Validators.required],
		HearaForOrder4: [<string | null>'', Validators.required],
		HearaForOrder5: [<string | null>'', Validators.required],
		ChangePrice: [<number | null>null, Validators.required],
		ChangeBasePrice: [<number | null>null, Validators.required],
		ChangeDiscount: [<number | null>null, Validators.required],
		Bonus: ['', Validators.required],
		ProductSearch: ['', Validators.required],
		Cd: ['', Validators.required],
		SupplyDate: [this.SupplyDateTeuda, Validators.required],
		SupplyAdress: ['', Validators.required],
		SelectHeara1: ['', Validators.required],
		SelectHeara2: ['', Validators.required],
		Email: [''],
		SupplyAdressSelect: ['', Validators.required],
		emailRadio: [<EmailRadioValues>'none'],
	});

	ShowProfit;

	StockAlert = false;

	Pikadon = 0;

	AllOrderProfit = 0;

	SelectHearotRes;

	SelectHearotRes1 = [];

	SelectHearotRes2 = [];

	TotalArizot = 0;

	long = '0';

	alt = '0';

	SelectHearotRessupply;

	destroy$ = new Subject<void>();

	Mode = navigator.onLine;

	logo = MOBILITY_LOGO;

	teudanumToDisplay = '';

	clientHasEmail = '';
	isEmailPickPopupOpen = false;

	windowWidth = innerWidth;

	hasSpecials = ['1', '2', '3'].includes(this._ss.CustomerInformation.HasSpecials);

	// logic comes from CustomerInformation.UnBlockQty
	// this.handleUnBlockQty()
	shouldOverrideAgentProductQuantities = false;

	// logic comes from CustomerInformation.BlockChangingPriceBonusDiscountOrQuantities
	// this.handleBlockInputs()
	shouldDisablePriceAndDiscountInput = false;
	shouldDisableChangingInputs = false;

	isReclamationOrder = false;
	// location.href.includes('test') || (location.href.includes('localhost') && this._ss.CustomerInformation.CustNum === '10093');

	BODY = new FormControl<string>(null, Validators.required);
	LOCATION = new FormControl<string>(null, Validators.required);
	TAKALA = new FormControl<string>(null, Validators.required);
	ANS = new FormControl<string>(null, Validators.required);

	reclamationForm = this._fb.group({
		CarNum: [null, [Validators.required, Validators.max(99999999999999999999), Validators.min(0)]],
		BODY: this.BODY,
		LOCATION: this.LOCATION,
		TAKALA: this.TAKALA,
		ANS: this.ANS,
		RDR: [null, Validators.required],
		RDM: [null, Validators.required],
		RDL: [null, Validators.required],
		DOT: [null, Validators.required],
		SN: [null, Validators.required],
		RekRemark: [''],
	});

	catalogRemarks: CatalogRemark[] = [
		{ text: 'שם לקוח', maxLength: 30, type: 'text' },
		{ text: 'כתובת', type: 'text', maxLength: 50 },
		{ text: 'טלפון', type: 'number', maxLength: 10 },
		{ text: 'איש קשר', maxLength: 30, type: 'text' },
		{ text: "מס' ח.פ / ת.ז", maxLength: 9, type: 'number' },
	];

	readonly catalogForm = this._fb.group({
		teudaRemark1: ['', [Validators.required, Validators.maxLength(30)]],
		teudaRemark2: ['', [Validators.required, Validators.maxLength(50)]],
		teudaRemark3: ['', [Validators.required, Validators.maxLength(10)]],
		teudaRemark4: ['', [Validators.required, Validators.maxLength(30)]],
		teudaRemark5: ['', [Validators.required, Validators.maxLength(9)]],
	});

	readonly reclamationLists: {
		BODY?: ReclamationReasonsResponse[];
		LOCATION?: ReclamationReasonsResponse[];
		TAKALA?: ReclamationReasonsResponse[];
		ANS?: ReclamationReasonsResponse[];
	} = {};

	readonly LOCATION$ = new Subject<ReclamationReasonsResponse[]>();

	readonly requestErrorAndAllOrderLength = 'קיימת בעיה בשליחת ' + this._ss.DocTypeName + '. תעודתך נשמרה ותוכל לנסות לשלוח אותה שוב במועד מאוחר יותר.';
	// requestDelayedButMaybePassed = this._ss.DocTypeName + ' מספר ' + this.teudanumToDisplay + ' מתעכבת בשליחה אך ייתכן שעברה בהצלחה. מומלץ לברר במערכת לגבי מצבה.';

	teudaRemark5ShowPrice;

	reclamationID;

	readonly allSpecials = this._ss.allSpecials;
	readonly allSpecials$ = new BehaviorSubject<Special[]>(this.allSpecials);
	readonly specialsInCart: SpecialInCartObj = {};

	productsHash: { [key: string]: Product } = {};

	readonly alternativeProductCalculations = this._ss.CustomerInformation.CanChangePermPrice === '1';

	location:
		| string
		| {
				latitude: number;
				longitude: number;
		  } = '';
	position: GeolocationPosition | null = null;
	toggle = false;
	currentPositionUrl: SafeResourceUrl | null = null;
	watchSubscription: Subscription | null = null;
	error: GeolocationPositionError | null = null;

	hideSingles = this._ss.CustomerInformation.CustSeeArizot === '2' || (this._ss.CustomerInformation.CustSeeArizot === '3' && !this.resolveIfDocIsOfReturnsType());
	hidePacks = this._ss.CustomerInformation.CustSeeArizot === '1' || (this._ss.CustomerInformation.CustSeeArizot === '4' && !this.resolveIfDocIsOfReturnsType());

	readonly calculatePackagesTotalQuantity = this._ss.CustomerInformation.ShowTranslatedQuantity === '1';

	canSeePrices = true;

	itempackRecord: Record<string, ItemPacks[]> = {};

	readonly canOpenEmailPopup = this._ss.CustomerInformation.CanSendEmail && (this._ss.CustomerInformation.CanSendEmail.includes('3') || this._ss.CustomerInformation.CanSendEmail.includes('5'));

	readonly showTranslatedStock = this._ss.CustomerInformation.StockWithItemPack === '1';
	readonly hasWeightTypeUnitsBehaviors = this._ss.CustomerInformation.ProductWeightType === '1';

	readonly isCreditCardOrder = this._cc.isDoctypeOfCreditTypeOrder(this._ss.DocType);
	isCreditCardPopupOpen = false;

	readonly sendOrderText = this.isCreditCardOrder ? 'מעבר לתשלום' : 'שלח ' + this._ss.DocTypeName;
	readonly sendOrderTextGeneric = 'שלח תעודה';

	hasDocMinTeuda = false;
	allDocMinTeuda: MinDocTeudaBaseWithAction[] = [];

	// StoreService Locals
	readonly TotalOrderOverride: string = this._ss.CustomerInformation.TotalOrderOverride;

	orderBodyForCreditCard: any = {};

	readonly showDates = !this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	readonly showItemExtra4InsteadOfBarcode = this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;

	showSignatureStep = !this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	readonly replaceStepThreeTitle = this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	readonly showOnlyMainRemark = this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;
	needsToConfirmBeforeSending = this._b2b.isB2BAgentAndNoDiscountsAndDates$.value;

	readonly stepThreeTitle = this.replaceStepThreeTitle ? 'הערה - כאן ניתן לכתוב בקשות מיוחדות ונשתדל להתייחס אליהן' : 'הערות';

	readonly maxRemarkLength = (this._ss.CustomerInformation?.MaxRemarkLength as number) || 99;

	readonly hasConcentratedSpecials = this._ss.CustomerInformation?.HasSpecialsType === '1';

	async ngOnInit() {
		//  if (navigator.geolocation) {
		//       navigator.geolocation.getCurrentPosition((position)=>{
		//         this.long =  "" + position.coords.longitude + "";
		//         this.alt ="" + position.coords.latitude + "";
		//         console.log( position.coords.longitude);
		//         console.log( position.coords.latitude);

		//       });
		//   } else {
		//      console.log("No support for geolocation")
		//   }

		if (this._b2b.isB2BAgentAndNoDiscountsAndDates$.value) {
			this._alert.customAlertConfirmWithoutCancel(this._b2b.entryTeudaText);
		}

		if (this._ss.DocType === RECLAMATION_RALLYANCE_DOCTYPE) {
			const query = new URLSearchParams(location.search);

			if (!query.has('ReclamationSuccess')) {
				alert('ישנה תקלה, אנא נסה שנית מאוחר יותר');
				history.back();
				return;
			}

			this.isRallyanceReclamation = true;

			this.NewCart = query.get('TeudaNum');

			this.OrderGood = true;
			this.orderProcess = true;
			this.orderError = false;
			this.Vorder = true;
			this._ss.TeudotDetails = null;
			this._ss.Teudot = null;
			this._ss.TeudotAll = null;
			this.AllOrder = [];
			this.TotalSumOAllOrder = 0;

			return;
		}

		if (this._ss.DocType === RECLAMATION_DOCTYPE || this.isReclamationOrder) {
			this.isReclamationOrder = true;

			this.showHideFourStep = true;

			this._ss.handleGetReasonsOfSelectForReclamation().subscribe({
				next: res => {
					if (res.recordset && res.recordset.length > 0) {
						res.recordset.reduce((acc, curr) => {
							if (acc[curr.FieldId] === undefined) {
								acc[curr.FieldId] = [];
							}
							acc[curr.FieldId].push(curr);
							return acc;
						}, this.reclamationLists);

						for (const key in this.reclamationLists) {
							if (Object.prototype.hasOwnProperty.call(this.reclamationLists, key)) {
								const element = this.reclamationLists[key];
								element.sort((a, b) => Intl.Collator('he-il', { sensitivity: 'base', numeric: true }).compare(a.Reason, b.Reason));
							}
						}

						this.BODY.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
							next: val => {
								console.log(this.LOCATION.value);
								if (val) {
									this.LOCATION$.next(this.reclamationLists.LOCATION.filter(item => item.ResonBody === val));
								}
								this.LOCATION.reset(this.LOCATION$[0]);
							},
						});
					} else {
						alert('ישנה בעיה בטעינת סיבות רקלמציה. אנא נסה שוב מאוחר יותר.');
						this.router.navigate(['products'], { replaceUrl: true });
					}
				},
				error: err => {
					alert('ישנה בעיה בטעינת סיבות רקלמציה. אנא נסה שוב מאוחר יותר.');
					this.router.navigate(['products'], { replaceUrl: true });
				},
			});

			this._ss.handleGetLatestReclamationID().subscribe({
				next: res => {
					if (!res.recordset) {
						alert('ישנה בעיה בטעינת נתונים. אנא נסה שוב מאוחר יותר.');
						this.router.navigate(['products'], { replaceUrl: true });
						return;
					}

					const { RekID } = res.recordset[0];

					if (!RekID || RekID.slice(0, 2) !== 'MO') {
						this.reclamationID = 'MO000000';
						return;
					}

					this.reclamationID = 'MO' + String(parseInt(RekID.slice(2)) + 1).padStart(6, '0');
				},
				error: err => {
					alert('ישנה בעיה בטעינת נתונים. אנא נסה שוב מאוחר יותר.');
					this.router.navigate(['products'], { replaceUrl: true });
				},
			});
		}

		if (this.isCreditCardOrder) {
			const parameters = this._cc.parameters$.value;
			if (!this._cc.hasParameters(parameters)) {
				this._cc.refreshParameters$.subscribe({
					next: () => {
						console.log('refreshed parameters');
					},
					error: () => {
						alert('לא ניתן להשתמש בכרטיס אשראי בשל תקלת תקשורת, אנא נסה שוב מאוחר יותר');
						history.back();
					},
				});
			}
		}

		if (this._ss.CustNum === '10348') {
			this._ss.Heara1 = 1;
		}

		if (localStorage.getItem('Admin') !== null) {
			this.Admin = localStorage.getItem('Admin');
		}

		if (this.Admin) {
			this.isB2BAgent = this.Admin.includes('b2b');
			this.isLeasingOrder = ['b2bL', 'b2bLM'].includes(this.Admin);

			this.isCatalogAgent = this.Admin.includes('Catalog');

			// TODO Move this to b2b service
			if (this.isB2BAgent) {
				this.canSeePrices = this._ss.CustomerInformation.B2BCanSeePrices !== '1';
				if ((this.Admin as string).toLowerCase().includes('b2bp')) {
					this.canSeePrices = false;
					this.isB2BAgentNoPricesAndNoParameters = true;
				}
			}
		}

		if (this.isCatalogAgent) {
			this.showHideFourStep = true;
		}

		if (localStorage.getItem('ShowProfit') !== null) {
			this.ShowProfit = localStorage.getItem('ShowProfit');
		}

		const { AddToSupplyDate } = this._ss.CustomerInformation;

		if (AddToSupplyDate && !/\D/.test(AddToSupplyDate)) {
			const time = Intl.DateTimeFormat('en-IL', { hour: '2-digit', minute: 'numeric', second: 'numeric' }).format(new Date()).slice(0, 2);

			let toSubtract = 0;

			if (+time >= 0 && +time < 6) {
				toSubtract = 1;
			}

			this.SupplyDateTeuda = new Date(this.Date.getTime() + 1000 * 60 * 60 * 24 * (+AddToSupplyDate - toSubtract)).toISOString().slice(0, 10);

			this.form.controls.SupplyDate.setValue(this.SupplyDateTeuda);
		}

		this.online = window.navigator.onLine;

		window.scrollTo(0, 0);

		this.handleFormInteractivity();

		this._docMin.refresh();

		this.hasDocMinTeuda = this._docMin.hasMinDocTeuda;

		if (this.hasDocMinTeuda) {
			this.allDocMinTeuda = this._docMin.getRelevantDocMinTeuda(this._ss.OneClientInfo.company, this._ss.DocType, this._ss.OneClientInfo.ClientId);
		}

		if (this.canOpenEmailPopup) {
			const clientEmail: string | false = this._ss.OneClientInfo.CustEMail || this._ss.OneClientInfo.CustEmail || false;

			if (!!clientEmail) {
				this.clientHasEmail = clientEmail;

				if (this._ss.CustomerInformation.CanSendEmail.includes('5')) {
					this.form.controls.emailRadio.setValue('client');
				}
			}
		}

		this.AllClientsHelp = decompress(localStorage.getItem('AllClients'));
		this.AllClientsHelp = JSON.parse(this.AllClientsHelp);

		for (let x = 0; x < this.AllClientsHelp.length; x++) {
			if (this._ss.OneClientInfo.company === this.AllClientsHelp[x].company) {
				this.AllClients.push(this.AllClientsHelp[x]);
			}
		}

		if (localStorage.getItem('OrderStatus') !== null) {
			this.OrderStatusNumber = localStorage.getItem('OrderStatus');
			if (this._ss.CustNum === '10141') {
				this.OrderStatusNumber = '2';
			}
		}

		const localstorageKeysArray = ['HearaNewOrder', 'HearaNewOrder1', 'HearaNewOrder2', 'HearaNewOrder3', 'HearaNewOrder4', 'SupplyDateTeuda', 'AllOrder', 'Email'] as const,
			identifier = '' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company;

		for (const key of localstorageKeysArray) {
			const ls = localStorage.getItem(key + identifier);

			if (key === 'AllOrder') {
				if (!ls) {
					this.router.navigate(['products'], { replaceUrl: true });
					return;
				}
				this.AllOrder = this.AllOrderSwitch = this.AllProductsSearch = this.AllProducts = JSON.parse(ls);
				continue;
			}

			if (ls) {
				if (/\d/.test(key)) {
					// HearaNewOrder1, 2, 3...
					if (this._util.testProblematicCharacters(ls)) {
						this._ss[key] = this._util.removeProblematicCharacters(ls);
						continue;
					}
					this._ss[key] = ls;
					continue;
				}

				this[key] = this._util.testProblematicCharacters(ls) ? this._util.removeProblematicCharacters(ls) : ls;

				if (key === 'SupplyDateTeuda') {
					this.form.controls.SupplyDate.setValue(this.SupplyDateTeuda);
				}
			}
		}

		if (this._ss.CustomerInformation.SendCoordinates === '1' && navigator?.geolocation?.getCurrentPosition) {
			try {
				navigator.geolocation.getCurrentPosition(
					pos => {
						this.location = {
							latitude: pos.coords.latitude,
							longitude: pos.coords.longitude,
						};
						console.log(pos);
					},
					err => {
						this.location = err.message;
						console.log(err);
					},
					{ enableHighAccuracy: true, timeout: 10000 },
				);
			} catch (error) {
				console.log('getting coordinates failed');
			}
		}

		// TODO test the teuda check before production
		if (this._ss.CustomerInformation.CheckStockBeforeSend === '1') {
			// For replication of teudot through teudoteach.
			if (this._ss.allProductsSearchForTeudaCheck) {
				// object instead of array for faster checking. always choose object if main goal is to find something.
				const productsObj = this._ss.allProductsSearchForTeudaCheck.reduce((prev, curr) => {
					prev[curr.ProductId] = curr;
					return prev;
				}, {});

				for (const prd of this.AllProducts) {
					const prdCheck = productsObj[prd.ProductId];
					if (!prdCheck) {
						alert('פריט ' + prd.ProductId + ' אינו זמין יותר, אנא תקן את ההזמנה ונסה לשלוח שוב.');
						this.router.navigate(['products']);
						return;
					}
					// 0 if falsy. 0 also falsy, but returns 0
					const itemTotalAmount = (prdCheck.TeudaPackQuantity || 0 + prdCheck.TeudaBonusPackQuantity || 0) * prdCheck.ItemPack + prdCheck.TeudaQuantity + prdCheck.TeudaBonusQuantity;

					if (prdCheck.Stock < itemTotalAmount) {
						alert('פריט ' + prd.ProductId + ' חורג מהמלאי הזמין, אנא תקנו פריט זה לפני שליחת התעודה.');
						this.router.navigate(['products']);
						return;
					}
				}
			} else {
				alert('לא ניתן לוודא מלאי או תקינות הזמנה, אנא נסו שנית');
				this.router.navigate(['products']);
				return;
			}
		}

		for (let x = 0; x < this.AllProducts.length; x++) {
			this.TotalArizot += this.AllProducts[x].TeudaPackQuantity;
		}

		if (this.hasWeightTypeUnitsBehaviors) {
			this.buildItempackRecord();
		}

		this.handleObligoCheck({ alertObligoWarning: true });
		this.GeneralFunction();

		console.log(this.AllOrder);

		if (this.hasSpecials) {
			this.populateSpecials();
		}

		this.form.controls.ProductSearch.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: val => {
				this.searchString = val;
			},
		});

		if (localStorage.getItem('SelectHearot') === null) {
			this._ss.SelectHearot().subscribe({
				next: res => {
					this.SelectHearotRes = res;
					this.SelectHearotRes = this.SelectHearotRes.recordset;

					for (let x = 0; x < this.SelectHearotRes.length; x++) {
						if (this.SelectHearotRes[x].num === 1) {
							this.SelectHearotRes1.push(this.SelectHearotRes[x]);
						}
						if (this.SelectHearotRes[x].num === 2) {
							this.SelectHearotRes2.push(this.SelectHearotRes[x]);
						}
					}

					localStorage.setItem('SelectHearot', JSON.stringify(this.SelectHearotRes));

					this.SupplyAddressSelect();
				},
				error: err => {
					this.SupplyAddressSelect();
				},
			});
		} else {
			this.SupplyAddressSelect();
			this.SelectHearotRes = localStorage.getItem('SelectHearot');
			this.SelectHearotRes = JSON.parse(this.SelectHearotRes);

			for (let x = 0; x < this.SelectHearotRes.length; x++) {
				if (this.SelectHearotRes[x].num === 1) {
					this.SelectHearotRes1.push(this.SelectHearotRes[x]);
				}
				if (this.SelectHearotRes[x].num === 2) {
					this.SelectHearotRes2.push(this.SelectHearotRes[x]);
				}
			}
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleFormInteractivity() {
		const controls = ['Email', 'HearaForOrder', 'HearaForOrder1', 'HearaForOrder2', 'HearaForOrder3', 'HearaForOrder4', 'HearaForOrder5', 'SupplyAdress'];

		for (const control of controls) {
			this.form.controls[control].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
				if (val && this._util.testProblematicCharacters(val)) {
					this.form.controls[control].setValue(this._util.removeProblematicCharacters(val));
				}
			});
		}
	}

	buildItempackRecord() {
		const itempacks = localStorage.getItem('ItemPacks');

		if (!itempacks) return;

		this.itempackRecord = (JSON.parse(decompress(itempacks)) as ItemPacks[]).reduce((prev, curr) => {
			const prodID = curr.ProductID.toLowerCase();
			prev[prodID] ||= [];
			prev[prodID].push(curr);
			return prev;
		}, {});
	}

	populateSpecials() {
		for (const ord of this.AllOrder) {
			if (typeof ord.SpecialID !== 'number') {
				continue;
			}

			this.specialsInCart[ord.SpecialID] = { special: this.allSpecials.filter(sp => sp.SpecialID === ord.SpecialID), quantity: ord.quantity };
			const special = this.specialsInCart[ord.SpecialID];
			ord.SpecialName = special.special[0].Name;
		}
	}

	changeSpecialQuantity(specialID: number, action: 'increment' | 'decrement', quantity: number) {
		const specialInCart = this.specialsInCart[specialID];

		const special = this.allSpecials.filter(sp => sp.SpecialID === specialID);

		if (action === 'increment') {
			this.handleIncrementSpecialInCart(special, specialInCart, quantity);
		}

		if (action === 'decrement') {
			this.handleDecrementSpecialInCart(special, specialInCart, quantity);
		}
	}

	handleIncrementSpecialInCart(special: Special[], specialInCart: SpecialInCart, quantity: number) {
		const { SpecialID: specialID, MaxInCart } = special[0];
		if (specialInCart && MaxInCart && MaxInCart <= specialInCart.quantity) {
			alert('אין אפשרות להוסיף מבצע זה מכיוון שהוא הגיע למכסה');

			// If somehow goes over maxincart
			specialInCart.quantity = MaxInCart;
			return;
		}

		const order = this.AllOrder.find(ord => ord.SpecialID === specialID);
		order.quantity = specialInCart.quantity += quantity;
		this.GeneralFunction();
	}

	handleDecrementSpecialInCart(special: Special[], specialInCart: SpecialInCart, quantity: number) {
		if (!specialInCart || !specialInCart.quantity) return;

		const { SpecialID: specialID } = special[0];

		const order = this.AllOrder.find(ord => ord.SpecialID === specialID);

		if (specialInCart.quantity < quantity) {
			console.log('illegal quantity for special');
			return;
		}

		order.quantity = specialInCart.quantity -= quantity;

		if (!order.quantity) {
			delete this.specialsInCart[specialID];
			this.AllOrder = this.AllOrder.filter(ord => ord.SpecialID !== specialID);
		}

		this.GeneralFunction();
	}

	handleOpenSpecialDialog(specialID: number) {
		this.handlePopulateProductHash();

		const special = this.allSpecials.filter(spc => spc.SpecialID === specialID).map(spc => ({ ...this.productsHash[spc.ProductID.toLowerCase()], ...spc }));

		this.dialog.open(SpecialInfoDialog, {
			data: { special },
			maxHeight: '80vh',
		});
	}

	handlePopulateProductHash() {
		if (!Object.getOwnPropertyNames(this.productsHash).length) {
			const products = localStorage.getItem('AllProducts');

			if (!products) {
				localStorage.removeItem('lastupdate');
				alert('ישנה תקלה, אנא עדכנו נתונים ונסו שנית');
				this.router.navigate(['home']);
			}

			this.productsHash = JSON.parse(decompress(products)).reduce((prev, curr) => {
				prev[(curr.ProductId || '').toLowerCase()] = curr;
				return prev;
			}, {});
		}
	}

	changeSupplyTeuda(e: Event) {
		if (e.target instanceof HTMLInputElement) {
			this.SupplyDateTeuda = e.target.value;
		}

		localStorage.setItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, this.form.controls.SupplyDate.value);
	}

	CloseStockAlert() {
		this.StockAlert = false;
	}

	BackToProducts() {
		this.router.navigate(['products'], { replaceUrl: true });
	}

	MoveToDailyReport() {
		this.router.navigate(['daily-report']);
	}

	SupplyAddressSelect() {
		if (this._ss.CustomerInformation.SeeDeliveryOptions === 1) {
			this._ss.SelectHearotsupply().subscribe(
				res => {
					console.log(res);

					this.SelectHearotRessupply = res;
					this.SelectHearotRessupply = this.SelectHearotRessupply.recordset;
				},
				err => {},
			);
		}
	}

	GeneralFunction() {
		if (!this.AllOrder.length) {
			this.clearLocalStorageOfOrder();

			this.BackToProducts();
		}

		this.Pikadon = 0;
		this.TotalSumOAllOrder = 0;
		for (let x = 0; x < this.AllOrder.length; x++) {
			const order = this.AllOrder[x];

			if (this.alternativeProductCalculations) {
				const price = order.NetPrice || order.Price || order.PermPrice;
				order.YehidotPrice = order.TeudaQuantity * price;
				order.ArizotPrice = order.TeudaPackQuantity * order.ItemPack * price;
			}

			if (typeof order.SpecialID !== 'number') {
				order.TeudaTotalRow = order.ArizotPrice + order.YehidotPrice;
			} else {
				order.TeudaTotalRow = order.TeudaItemNetPrice * order.quantity;
				if (this.hasConcentratedSpecials) {
					order.TeudaTotalRow = order.TeudaItemNetPrice;
				}
			}
			this.TotalSumOAllOrder += order.TeudaTotalRow;
			this.Pikadon += (order.TeudaQuantity + order.TeudaPackQuantity * order.ItemPack) * (order.PKD || 0);
		}

		// this.TotalSumOAllOrder = this.TotalSumOAllOrder+this.Pikadon
		this.TotalSumOAllOrderPlusVat = this.TotalSumOAllOrder;
		const y = (this.TotalSumOAllOrder * this._ss.OneClientInfo.CustVat) / 100;
		this.TotalSumOAllOrderPlusVat += y;

		this.CustDiscountShow = this.TotalSumOAllOrder;
		const aa = (this._ss.OneClientInfo.CustDiscount * this.TotalSumOAllOrder) / 100;
		this.CustDiscountShow -= aa;

		this.CustDiscountShowVat = this.CustDiscountShow;
		const bb = (this.CustDiscountShow * this._ss.OneClientInfo.CustVat) / 100;
		this.CustDiscountShowVat += bb;
		this.CustDiscountShowVat += this.Pikadon;

		this.handleObligoCheck();

		this.TotalSumOAllOrderProfit = this.TotalSumOAllOrder;

		this.AllOrderProfit = 0;

		for (let x = 0; x < this.AllOrder.length; x++) {
			const order = this.AllOrder[x];
			if (typeof order.SpecialID !== 'number') {
				this.AllOrderProfit += order.CPPTotalRow = (order.TeudaPackQuantity * order.ItemPack + order.TeudaQuantity) * order.CPP;
			}
			if (this._ss.CustomerInformation.CopyRemark === '1') {
				this.HearaNewOrder = order.TeudaRemark;
				this._ss.HearaNewOrder1 = order.TeudaRemark1;
				this._ss.HearaNewOrder2 = order.TeudaRemark2;
				this._ss.HearaNewOrder3 = order.TeudaRemark3;
				this._ss.HearaNewOrder4 = order.TeudaRemark4;
				this._ss.HearaNewOrder5 = order.TeudaRemark5;
			}
		}

		if (this._ss.OneClientInfo.CustDiscount) {
			this.AllOrderProfit = this.AllOrderProfit - (this.AllOrderProfit * this._ss.OneClientInfo.CustDiscount) / 100;

			this.TotalSumOAllOrderProfit ||= 1;
		}

		this.teudaRemark5ShowPrice = this.AllOrder?.[0]?.TeudaRemark5 || '';

		// if 0
		this.TotalSumOAllOrderProfit ||= 1;

		this.TotalSumOAllOrderProfit = ((this.TotalSumOAllOrderProfit - this.AllOrderProfit) / this.TotalSumOAllOrderProfit) * 100;

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
	}

	SupplyDateFunction() {
		this.SupplyDateTeuda = this.form.controls.SupplyDate.value;

		this.form.controls.SupplyDate.setValue(this.SupplyDateTeuda);

		localStorage.setItem('SupplyDateTeuda' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, this.form.controls.SupplyDate.value);
	}

	SupplyAdressFunction() {
		this.SupplyAdressTeuda = this.form.controls['SupplyAdress'].value;
	}

	SupplyAdressSelect() {
		this.SupplyAdressTeuda = this.form.controls['SupplyAdressSelect'].value;
	}

	CancelOrderFunction() {
		this.orderProcess = true;
		this.CancelOrder = true;
	}

	NoCancelOrder() {
		this.orderProcess = false;
		this.CancelOrder = false;
	}

	YesCancelOrder() {
		this.clearLocalStorageOfOrder();

		this.router.navigate(['products'], { replaceUrl: true });
	}

	clearLocalStorageOfOrder() {
		const localstorageKeysArray = ['HearaNewOrder', 'HearaNewOrder1', 'HearaNewOrder2', 'HearaNewOrder3', 'HearaNewOrder4', 'SupplyDateTeuda', 'AllOrder'],
			identifier = '' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company;

		for (const key of localstorageKeysArray) {
			localStorage.removeItem(key + identifier);
		}
	}

	async SendTeudaFunction() {
		// check must-include-products
		const mustIncludeCheck = this._mustIncludeProducts.handleCheckMustIncludeProducts(this.AllOrder);

		switch (mustIncludeCheck.result) {
			case 'ok':
				break;
			case 'stop':
				this._alert.customAlert([`לא ניתן לסיים ללא מוצרי חובה.`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);
				return;
			case 'alert':
				const promptUser = await this._alert.customConfirmAlert([`שים לב, ישנם מוצרי חובה לתעודה זו. האם להמשיך?`, ...mustIncludeCheck.productIds.map(id => ` ${id}`)]);
				if (!promptUser) return;
				break;
			default:
				break;
		}

		if (this.needsToConfirmBeforeSending) {
			this._alert.customConfirmAlert(this._b2b.confirmBeforeSendText).then(res => {
				if (!res) return;

				this.needsToConfirmBeforeSending = false;
				this.SendTeudaFunction();
			});
			return;
		}

		if (this.isCreditCardOrder) {
			this.toggleCreditCardPopup('open');
			return;
		}
		if (this.isReclamationOrder) {
			this.handleReclamationOrder();
			return;
		}
		if (this._ss.DocType === RECLAMATION_DOCTYPE) return;

		if (this.isLeasingOrder && !this.mikum) {
			alert('חייב לבחור מיקום לפני שליחת תעודה');
			return;
		}

		if (this.isCatalogAgent && this.catalogForm.invalid) {
			alert('אחד או יותר מהשדות  אינו תקין');
			this.catalogForm.markAllAsTouched();
			console.log({ form: this.catalogForm, tel: this.catalogForm.controls.teudaRemark3 });
			return;
		}

		if (this._ss.DocType == 170 && +this.TotalSumOAllOrder < +this.AllOrder[0].TeudaRemark5) {
			const decision = confirm('סכום התעודה אינו תואם את הסכום הנדרש. האם להמשיך בכל זאת?');
			if (!decision) return;
		}

		this.orderProcess = true;
		this.SendOrder = true;
	}

	NoSendTeuda() {
		this.orderProcess = false;
		this.SendOrder = false;
	}

	SaveTeudaFunction() {
		if (this._ss.DocType == 170 && +this.TotalSumOAllOrder < +this.AllOrder[0].TeudaRemark5) {
			const decision = confirm('סכום התעודה אינו תואם את הסכום הנדרש. האם להמשיך בכל זאת?');
			if (!decision) return;
		}

		this.SaveTeudaPop = true;
	}

	SaveTeuda() {
		this.router.navigate(['clients']);
	}

	NoSaveTeuda() {
		this.SaveTeudaPop = false;
	}

	prevent(e) {
		e.preventDefault();
	}

	DeleteProductFromTeuda(id, name) {
		this.ChoosenDeleteProductId = id;
		this.ChoosenDeleteProduct = name;
		this.DeleteProductPop = true;
	}

	DeleteProduct() {
		this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === this.ChoosenDeleteProductId)), 1);

		this.AllProductsSearch = this.AllProducts = this.AllOrder;

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		this.GeneralFunction();

		this.DeleteProductPop = false;
	}

	NoDeleteProduct() {
		this.DeleteProductPop = false;
	}

	SelectHearaTeuda1() {
		this._ss.HearaNewOrder1 = this.form.controls['SelectHeara1'].value;
	}

	SelectHearaTeuda2() {
		this._ss.HearaNewOrder2 = this.form.controls['SelectHeara2'].value;
	}

	HearaForOrder(hearaID: '' | 1 | 2 | 3 | 4 | 5 = '') {
		const remark = this.form.controls['HearaForOrder' + hearaID].value;
		const { ClientId, company } = this._ss.OneClientInfo;
		const { DocType } = this._ss;

		if (!hearaID) {
			this.HearaNewOrder = remark;
		} else {
			this._ss['HearaNewOrder' + hearaID] = remark;
		}

		localStorage.setItem(`HearaNewOrder${hearaID}${ClientId}${DocType}${company}`, remark);
	}

	HearaExtra = [];

	hearotArray: Heara[] = [
		{ text: 'הנחה 2 אחוז מזומן', id: 1 },
		{ text: 'יש לאסוף תשלום', id: 2 },
		{ text: 'טעימות לשים מדבקות', id: 3 },
		{ text: 'החלפה ללא מדבקות', id: 4 },
		{ text: 'אין להוריד סחורה ללא קבלת תשלום', id: 5 },
		{ text: 'יש להתקשר לסוכן מהלקוח', id: 6 },
		{ text: 'לאסוף חזרות בבקשה.', id: 7 },
	];

	CheckHeara(heara: Heara) {
		const { text, id } = heara;
		let data = this.HearaExtra.find(ob => ob.Heara === id);

		if (data !== undefined) {
			this.HearaExtra.splice(heara.id - 1, 1);
			document.getElementById('heara' + id).style.backgroundColor = null;
		} else {
			this.HearaExtra.push({ Heara: id, HearaCommet: text });
			document.getElementById('heara' + id).style.backgroundColor = 'green';
		}
	}

	handleObligoCheck(options?: { alertObligoWarning: boolean }) {
		const alertObligoWarning = options?.alertObligoWarning || false;

		if (this._ss.DocType === 74 || this._ss.DocType === 7) {
			//מה קורה בתוך ההתניה
		} else {
			// CustOpenDebt + CustOpenChecks + sumallorder < CustMaxCredit || blockcustobligo ?
			if (
				this._ss.OneClientInfo.COD + this._ss.OneClientInfo.COC + this.TotalSumOAllOrder + (+this._ss.OneClientInfo.AddObligo || 0) < this._ss.OneClientInfo.CMC ||
				this._ss.OneClientInfo.BCO === 0
			) {
			} else {
				if (this._ss.OneClientInfo.BCO === 1 && this._ss.NoObligoCheck !== 1 && alertObligoWarning) {
					alert('התראה: חריגת אובליגו ');
				}

				if (this._ss.OneClientInfo.BCO === 2 && this._ss.NoObligoCheck !== 1) {
					alert(' חסימה עקב חריגת אובליגו ');
					this.router.navigate(['products']);
				}
			}
		}
	}

	ClientsPage() {
		this.clearLocalStorageOfOrder();

		this.router.navigate(['clients']);
	}

	ClientsPageError() {
		this.router.navigate(['clients']);
	}

	YehidotPlus(product) {
		const { Stock, ItemPack, qt, BS, TeudaPackQuantity, TeudaQuantity, Price } = product;

		if ((qt === 1 || qt === 2) && !this.shouldOverrideAgentProductQuantities) return;

		this.form.controls['Arizot'].reset();
		this.form.controls['Yehidot'].reset();

		if (BS === 1 && Stock === TeudaPackQuantity * ItemPack + TeudaQuantity) {
			this.StockAlert = true;
		} else {
			product.TeudaQuantity++;
			product.YehidotPrice = product.TeudaQuantity * Price;
		}

		this.GeneralFunction();
	}

	YehidotMinus(product) {
		const { ProductId, Price, qt, TeudaPackQuantity, TeudaBonusQuantity } = product;

		if ((qt === 1 || qt === 2) && !this.shouldOverrideAgentProductQuantities) return;

		this.form.controls['Arizot'].reset();
		this.form.controls['Yehidot'].reset();

		product.TeudaQuantity = Math.max(product.TeudaQuantity - 1, 0);

		product.YehidotPrice = product.TeudaQuantity * Price;

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		if (product.TeudaQuantity <= 0 && TeudaPackQuantity <= 0 && TeudaBonusQuantity <= 0) {
			this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
			this.AllProducts = this.AllOrder;
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}

		this.GeneralFunction();
	}

	ArizotPlus(product) {
		const { Stock, ItemPack, qt, BS, Price, TeudaPackQuantity, TeudaQuantity } = product;
		if ((qt === 1 || qt === 3) && !this.shouldOverrideAgentProductQuantities) {
			return;
		}
		this.form.controls['Arizot'].reset();
		this.form.controls['Yehidot'].reset();

		const check = TeudaPackQuantity * ItemPack + TeudaQuantity + ItemPack;

		if (BS === 1 && Stock < check) {
			this.form.controls['Arizot'].reset();
			this.form.controls['Yehidot'].reset();
			this.StockAlert = true;
		} else {
			product.TeudaPackQuantity++;
			product.ArizotPrice = product.TeudaPackQuantity * ItemPack * Price;
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}
		this.GeneralFunction();
	}

	ArizotMinus(product) {
		const { ProductId, Price, ItemPack, qt, TeudaPackQuantity } = product;
		if ((qt === 1 || qt === 3) && !this.shouldOverrideAgentProductQuantities) {
			return;
		}
		this.form.controls['Arizot'].reset();
		this.form.controls['Yehidot'].reset();

		product.TeudaPackQuantity = Math.max(product.TeudaPackQuantity - 1, 0);

		// if (product.TeudaPackQuantity > 0) {
		product.ArizotPrice = product.TeudaPackQuantity * ItemPack * Price;
		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		if (product.TeudaQuantity === 0 && product.TeudaPackQuantity === 0 && product.TeudaBonusQuantity === 0) {
			this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
			this.AllProducts = this.AllOrder;

			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}
		// }
		this.GeneralFunction();
	}

	YehidotInput(product) {
		const { Stock, ProductId, ItemPack, qt, BS, TeudaQuantity, TeudaPackQuantity, TeudaBonusQuantity } = product;
		if ((qt === 1 || qt === 2) && !this.shouldOverrideAgentProductQuantities) {
			return;
		}
		const yehidotInput = this.form.controls['Yehidot'];
		const order = this.AllOrder.find(ob => ob.ProductId === ProductId);
		if (BS === 1 && Stock < yehidotInput.value + order.TeudaPackQuantity * ItemPack) {
			this.StockAlert = true;
			this.form.controls['Arizot'].reset();
			yehidotInput.reset();
			this.GeneralFunction();
			return;
		}

		order.TeudaQuantity = +yehidotInput.value || 0;
		order.YehidotPrice = order.TeudaQuantity * (order.NetPrice || order.Price);
		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		if (product.TeudaQuantity <= 0 && product.TeudaPackQuantity <= 0 && product.TeudaBonusQuantity <= 0) {
			this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
			this.AllProducts = this.AllOrder;

			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}

		this.GeneralFunction();
	}

	ArizotInput(product) {
		const { Stock, ProductId, ItemPack, qt, BS } = product;
		if ((qt === 1 || qt === 3) && !this.shouldOverrideAgentProductQuantities) {
			return;
		}
		const arizotInput = this.form.controls['Arizot'];
		const order = this.AllOrder.find(ob => ob.ProductId === ProductId);
		if (BS === 1 && Stock < +arizotInput.value * ItemPack + order.TeudaQuantity) {
			this.StockAlert = true;
			arizotInput.reset();
			this.form.controls['Yehidot'].reset();
			this.GeneralFunction();
			return;
		}
		order.TeudaPackQuantity = arizotInput.value || 0;
		order.ArizotPrice = order.TeudaPackQuantity * ItemPack * (order.NetPrice || order.Price);

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		if (product.TeudaQuantity <= 0 && product.TeudaPackQuantity <= 0 && product.TeudaBonusQuantity <= 0) {
			this.AllOrder.splice(this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId)), 1);
			this.AllProducts = this.AllOrder;

			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}

		this.GeneralFunction();
	}

	GeneraldiscountClient() {
		this._ss.OneClientInfo.CustDiscount = this.form.controls['Cd'].value || 0;

		for (const item of this.AllOrder) {
			item.TeudaDiscount = this._ss.OneClientInfo.CustDiscount;
		}

		localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));

		this.GeneralFunction();
	}

	OrderGood = false;
	DrivingTruckPop = true;
	BackArrowPop = true;
	OrderInProgressLine;

	timeLeft: number = 30;
	interval;

	ResForConfirmedOrder;

	SendTeuda() {
		if (this._ss.DocType === null || this._ss.DocTypeName === null || this._ss.DocType === undefined || this._ss.DocTypeName === undefined) {
			this.router.navigate(['clients']);
			return;
		}

		const docminHandle = this._docMin.handleDocMinTeudaActions(this.allDocMinTeuda, this.TotalSumOAllOrder);

		if (!docminHandle) {
			this.NoSendTeuda();
			return;
		}

		if (this._ss.CustomerInformation.CustChangeCss5 === '1' && this._ss.CustomerInformation.CustNum === '20001' && this._ss.HearaNewOrder2 === '') {
			alert('אנא בחר הערה 2');
		} else {
			if (this._ss.CustNum === '10159') {
				this.HearaNewOrder += '-' + this.HearaExtra.map(heara => heara.HearaCommet).join('-');
			}

			const hasCustomRemarks = !!this._ss.HearotHeadForTeuda;

			if (this._ss.Heara1 === 1 && this._ss.HearaNewOrder1 === '') {
				alert('חייב להזין ' + (hasCustomRemarks ? 'הערה 1' : this._ss.HearotHeadForTeuda?.[0]?.NewHeadLine));
			} else if (this._ss.Heara2 === 1 && this._ss.HearaNewOrder2 === '') {
				alert('חייב להזין ' + (hasCustomRemarks ? 'הערה 2' : this._ss.HearotHeadForTeuda?.[1]?.NewHeadLine));
			} else if (this._ss.Heara3 === 1 && this._ss.HearaNewOrder3 === '') {
				alert('חייב להזין ' + (hasCustomRemarks ? 'הערה 3' : this._ss.HearotHeadForTeuda?.[2]?.NewHeadLine));
			} else if (this._ss.Heara4 === 1 && this._ss.HearaNewOrder4 === '') {
				alert('חייב להזין ' + hasCustomRemarks ? 'הערה 4' : this._ss.HearotHeadForTeuda?.[3]?.NewHeadLine);
			} else {
				this.showHideFourStep = false;
				this.SendOrder = false;
				this.orderProcess = true;
				this.orderSuccess = true;

				this._ss.OrderInProcess = true;

				this.OrderInProgressLine = 'מכין נתונים';

				this.interval = setInterval(() => {
					if (this.timeLeft > 0) {
						this.timeLeft--;
					} else {
						this.timeLeft = 30;
					}
				}, 1000);

				this.BackArrowPop = false;
				setTimeout(() => {
					if (this.orderError === false && this.OrderGood === false) {
						this.orderError = true;
						this.OrderGood = false;
						this.DrivingTruckPop = false;
						this.BackArrowPop = true;
						this._ss.OrderInProcess = false;
					}
				}, 45000);

				if (Object.getOwnPropertyNames(this.specialsInCart).length) {
					const orderWithoutSpecials = this.AllOrder.filter(ord => typeof ord.SpecialID !== 'number');

					this.handlePopulateProductHash();

					const specialTranslatedToProduct = Object.values(this.specialsInCart).reduce((prev, { special, quantity }) => {
						const specialInAllOrder = this.AllOrder.filter(ord => typeof ord.SpecialID === 'number').reduce((prev, curr) => {
							prev[curr.SpecialID] = curr;
							return prev;
						}, {});

						if (this.hasConcentratedSpecials && special[0].SpecialType !== specialsTypesEnum.NORMAL) {
							const bonusType = special[0].BonusType;
							const allOrder = specialInAllOrder[special[0].SpecialID];
							if (!allOrder) return prev;

							for (const order of allOrder.order) {
								if (bonusType === 'packs') {
									order.TeudaBonusQuantity = normalizeNumberToMaxThreeDecimal(order.TeudaBonusQuantity * order.ItemPack);
								}
								prev.push(order);
							}
							return prev;
						}

						for (const spc of special) {
							const { TeudaQuantity, TeudaPackQuantity, TeudaBonusQuantity, TeudaItemNetPrice, ProductID, SpecialID } = spc;

							const product = this.productsHash[ProductID.toLowerCase()];

							const specialInOrder = specialInAllOrder[SpecialID] || {};

							const { Product_Name, Price } = product;
							const CPP = product.CPP || 0;
							const ItemBarcode = product.ItemBarcode || '';
							const ItemPack = product.ItemPack || 0;
							const ItemPicName = product.ItemPicName || '';
							const Stock = product.Stock || 0;
							const BD = product.BD || 0;
							const BP = product.BP || 0;
							const BM = product.BM || 0;
							const MinPrice = product.MinPrice || 0;
							const qt = product.qt || 0;
							const BS = product.BS || 0;
							const PKD = product.PKD || 0;
							const ChangePrice = product.ChangePrice || 0;
							const ChangeDiscount = product.ChangeDiscount || 0;
							const IfChangePriceOrDisc = OrderState.noChangeToProduct;
							const Duplicate = specialInOrder.Duplicate || 'false';
							const DuplicateOrder = specialInOrder.DuplicateOrder || '';
							const PriceInInput = 0;
							const Ptve = specialInOrder.Ptve || '';
							const StatusInsertItem = specialInOrder.StatusInsertItem || '';
							const CPPTotalRow = (TeudaPackQuantity * ItemPack + TeudaQuantity) * CPP;

							const YehidotPrice = TeudaQuantity * TeudaItemNetPrice;
							const ArizotPrice = TeudaPackQuantity * ItemPack * TeudaItemNetPrice;
							const TeudaTotalRow = YehidotPrice + ArizotPrice;

							prev.push({
								Stock,
								ProductId: ProductID,
								Product_Name,
								Price: TeudaItemNetPrice,
								ItemPack,
								ItemBarcode,
								ItemPicName,
								BD,
								BP,
								BM,
								MinPrice,
								qt,
								BS,
								PKD,
								CPP,
								ChangePrice,
								ChangeDiscount,
								YehidotPrice: YehidotPrice * quantity,
								ArizotPrice: ArizotPrice * quantity,
								TeudaQuantity: TeudaQuantity * quantity,
								TeudaPackQuantity: TeudaPackQuantity * quantity,
								TeudaBonusQuantity: TeudaBonusQuantity * quantity,
								IfChangePriceOrDisc,
								TeudaBonusPackQuantity: 0,
								TeudaRemark: '',
								TeudaItemRemark: 'מבצע ' + SpecialID,
								Duplicate,
								DuplicateOrder,
								PermPrice: Price,
								PriceInInput,
								Ptve,
								StatusInsertItem,
								TeudaTotalRow: TeudaTotalRow * quantity,
								CPPTotalRow,
								ProductName: Product_Name,
								SpecialID,
							});
						}

						return prev;
					}, []);

					this.AllOrder = [...orderWithoutSpecials, ...specialTranslatedToProduct];
				}

				for (let x = 0; x < this.AllOrder.length; x++) {
					const order = this.AllOrder[x];
					if (order.Duplicate !== 'false') {
						order.ProductId = order.Duplicate;
					}

					order.TeudaType = this._ss.DocType;
					order.Client_Id = this._ss.OneClientInfo.ClientId;

					order.TeudaCreateDate = this.Date;
					// order.TeudaCreateDate = order.TeudaCreateDate ?? this.Date

					// order.ProductId =  order.ProductIdNoChange
					order.TeudaLineNum = x + 1;
					order.ProductName = order.Product_Name = this._util.removeProblematicCharacters(order.Product_Name || '');
					order.TeudaQuantity = order.TeudaQuantity;
					order.TeudaPackQuantity = order.TeudaPackQuantity;
					order.TeudaBonusQuantity = order.TeudaBonusQuantity;
					order.TeudaBonusPackQuantity = 0;
					order.TeudaPrice = order.PermPrice;
					order.TeudaItemNetPrice = order.Price;

					// mor yam - teudaprice = teudaitemnetprice

					order.TeudaItemProfit = order.CPP ? ((order.Price - order.CPP) / order.Price) * 100 : 0;
					order.TeudaItemProfit = Math.max(Math.min(+order.TeudaItemProfit, 1000), -99.99);

					order.TeudaItemDiscount = 0;
					order.TeudaItemRemark = this._util.removeProblematicCharacters(order.TeudaItemRemark || '');
					order.TeudaRemark = this._util.removeProblematicCharacters(this.HearaNewOrder || '');
					order.TeudaTotalRow = order.YehidotPrice + order.ArizotPrice || order.TeudaTotalRow;
					order.TeudaTotalSumBrouto = 0;
					order.TeudaTotalSumBeforeVat = 0;
					order.TeudaTotalSumVat = this.TotalSumOAllOrderPlusVat - this.TotalSumOAllOrder;
					order.TeudaTotalSum = 0;
					order.TeudaProfit = this.TotalSumOAllOrderProfit;
					order.TeudaSupplyDate = this.SupplyDateTeuda;
					order.TeudaVat = this._ss.OneClientInfo?.CustVat || 0;
					order.TeudaDiscount = 0;
					order.TeudaAgent = this._ss.AgentIdConnected;
					order.CartId = 0;
					order.Client_Name = this._util.removeProblematicCharacters(this._ss.OneClientInfo?.Client_Name || '');
					order.Arizot = order.TeudaPackQuantity;
					order.Amount = order.TeudaQuantity;
					order.User_Name = this._util.removeProblematicCharacters(this._ss.UserNameConnected);
					order.TeudaStatus = '';
					order.PrintStatus = '';
					order.EmailStatus = '';
					order.CustEmail = '';
					order.TeudaNumDupFrom = '';
					order.TuedaErpDocNum = '';
					order.TeuddaErpErrMessage = '';
					order.TeudaRemark1 = this._util.removeProblematicCharacters(this._ss.HearaNewOrder1 || '');
					order.TeudaRemark2 = this._util.removeProblematicCharacters(this._ss.HearaNewOrder2 || '');
					order.TeudaRemark3 = this._util.removeProblematicCharacters(this._ss.HearaNewOrder3 || '');
					order.TeudaRemark4 = this._util.removeProblematicCharacters(this._ss.HearaNewOrder4 || '');
					order.TeudaRemark5 = this._util.removeProblematicCharacters(order.TeudaRemark5 || ''); // For CopyRemark == 1
					order.TeudaDeliveryAddr = this._util.removeProblematicCharacters(this._ss.OneClientInfo?.Address || '');
					order.TeudaDeliveryCity = this._util.removeProblematicCharacters(this._ss.OneClientInfo?.CustCity || '');
					order.TeudaRef = '';
					order.TeudaCompany = '';
					order.TeudaUnitPerPack = order.ItemPack;
					order.TotalLines = this.AllOrder.length;
					order.PicName = this._util.removeProblematicCharacters(order.ItemPicName || '');
					order.Stock = order.Stock;
					order.Longitude = typeof this.location === 'string' ? '0' : this.location.longitude;
					order.Latitude = typeof this.location === 'string' ? '0' : this.location.latitude;
					order.TeudaTypeName = this._ss.DocTypeName;
					order.TeudaCompany = this._ss.OneClientInfo.company;
					order.Tve = this._ss.VersionNumber;
					order.Sent = 'Regular';

					switch (this.form.controls.emailRadio.value) {
						case 'client':
							order.CustEmail = this._ss.OneClientInfo.CustEMail || this._ss.OneClientInfo.CustEmail;
							break;
						case 'user':
							order.CustEmail = this._user.agentEmail$.value;
							break;
						case 'custom':
							order.CustEmail = this.form.controls.Email.value || '';
							break;

						default:
							break;
					}

					if (this._ss.CustomerInformation.SeeDeliveryOptions === 1) {
						order.TeudaDeliveryAddr = this.SupplyAdressTeuda;
					}

					if (order.Unit) {
						delete order.Unit;
					}

					if (this.isLeasingOrder) {
						order.TeudaRemark3 = this.mikum;
					}

					if (this._ss.CustomerInformation.SendDiscount === '1') {
						if (order.ChangeDiscount) {
							order.TeudaItemDiscount = order.ChangeDiscount;
						}

						if (order.PriceInInput) {
							order.TeudaPrice = order.PriceInInput;
						}

						if (order.IfChangePriceOrDisc === OrderState.changedPrice) {
							order.TeudaItemDiscount = 0;
						}
					}

					if (this.isCatalogAgent) {
						for (const num of [1, 2, 3, 4, 5]) {
							// teudaremark5 for CopyRemark == 1
							order[`TeudaRemark${num}`] = this._util.removeProblematicCharacters(this.catalogForm.controls[`teudaRemark${num}`].value);
						}
					}
				}

				for (let x = 0; x < this.AllOrder.length; x++) {
					const order = this.AllOrder[x];

					order.TeudaTotalSumBrouto = this.TotalSumOAllOrder;
					order.TeudaDiscount = this._ss.OneClientInfo?.CustDiscount || 0;
					let y = 100 - this._ss.OneClientInfo?.CustDiscount || 0;
					y *= this.TotalSumOAllOrder;
					y /= 100;
					order.TeudaTotalSumBeforeVat = y;

					let z = (this._ss.OneClientInfo?.CustVat || 0) / 100;
					z *= order.TeudaTotalSumBeforeVat;
					order.TeudaTotalSumVat = z;
					order.TeudaTotalSum = order.TeudaTotalSumBeforeVat + order.TeudaTotalSumVat;
				}

				for (let x = 0; x < this.AllOrder.length; x++) {
					const order = this.AllOrder[x];
					if (order.DuplicateOrder === 'true') {
						this.OrderStatusNumberSend = '5';
					}

					if (order.Price !== order.PermPrice) {
						let y = order.PermPrice - order.Price;
						y /= order.PermPrice;
						y *= 100;

						order.TeudaItemDiscount = normalizeNumber(y);

						if (this.OrderStatusNumber === '2') {
							this.OrderStatusNumberSend = '2';
						}
					}

					// if (this._ss.DocType === '17') {
					// 	this.OrderStatusNumberSend = '0';
					// }

					if (this._ss.CustomerInformation.SendDiscount === '1') {
						if (order.ChangeDiscount) {
							order.TeudaItemDiscount = order.ChangeDiscount;
						}

						if (order.PriceInInput) {
							order.TeudaPrice = order.PriceInInput;
						}

						if (order.IfChangePriceOrDisc === OrderState.changedPrice) {
							order.TeudaItemDiscount = 0;
						}
					} else {
						order.hasSendDiscount1 = false;
					}

					if (order.Type != null) {
						if (order.weightMode === 'weight') {
							order.Type = 1;
						}
						if (order.weightMode === 'singles') {
							order.Type = 2;
						}
						if (order.packsWeightMode === 'boxes') {
							order.Type = 3;
						}
						if (order.packsWeightMode === 'packs') {
							order.Type = 4;
						}
					}

					if (this.alternativeProductCalculations) {
						order.Price = order.ChangePrice = order.TeudaItemNetPrice = order.NetPrice || order.Price || order.PriceInInput || order.ChangePrice || order.PermPrice || 0;
						order.TeudaPrice = order.basePriceInputted || order.basePrice || order.ShowPrice || order.PermPrice || 0;
					}
				}

				const isTeudaNumAlreadyExisting = this.AllOrder.filter(ord => ord.TeudaNum);
				//TODO refactor this after V613.2

				this.NewCart = +new Date() + '000' + this._ss.AgentIdConnected;

				this.OrderInProgressLine = 'מספר תעודה' + this.NewCart + 'אנא המתן';

				if (isTeudaNumAlreadyExisting.length) {
					const { TeudaNum } = isTeudaNumAlreadyExisting[0];

					this.AllOrder = this.AllOrder.map(ord => ({ ...ord, TeudaNum }));

					this.NewCart = TeudaNum;
				} else {
					for (let x = 0; x < this.AllOrder.length; x++) {
						this.AllOrder[x].TeudaNum = this.NewCart;
					}
				}

				this.teudanumToDisplay = this.NewCart;

				if (this.isCreditCardOrder) {
					this.orderBodyForCreditCard = {
						DATAString: JSON.stringify(this.AllOrder),
						TeudaType: this._ss.DocType,
						TeudaNum: this.NewCart,
						AgentID: this._ss.AgentIdConnected,
						Client_Name: this._ss.OneClientInfo.Client_Name,
						TeudaTotalSum: this.CustDiscountShowVat || this.TotalSumOAllOrderPlusVat,
						TotalLines: this.AllOrder.length,
						TeudaRemark: this._util.removeProblematicCharacters(this.HearaNewOrder || ''),
						Company: this._ss.OneClientInfo.company,
						Status: this.OrderStatusNumberSend,
					};

					if (this._ss.CustomerInformation.CustChange2 === '1') {
						this.orderBodyForCreditCard.TeudaDeliveryCity = this._ss.OneClientInfo.CustCity;
						this.orderBodyForCreditCard.TeudaSupplyDate = this.SupplyDateTeuda;
						this.orderBodyForCreditCard.ClientId = this._ss.OneClientInfo.ClientId;
					}

					this.handleCreditCardSendOrder();
					return;
				}

				if (this._ss.CustomerInformation.CustChange2 === '1') {
					this._ss
						.AllOrderStringExpended({
							DATAString: JSON.stringify(this.AllOrder),
							TeudaType: this._ss.DocType,
							TeudaNum: this.NewCart,
							AgentID: this._ss.AgentIdConnected,
							Client_Name: this._ss.OneClientInfo.Client_Name,
							TeudaTotalSum: this.CustDiscountShow,
							TotalLines: this.AllOrder.length,
							TeudaRemark: this._util.removeProblematicCharacters(this.HearaNewOrder || ''),
							Company: this._ss.OneClientInfo.company,
							Status: this.OrderStatusNumberSend,
							ClientId: this._ss.OneClientInfo.ClientId,
							TeudaDeliveryCity: this._ss.OneClientInfo.CustCity,
							TeudaSupplyDate: this.SupplyDateTeuda,
						})
						.subscribe(
							res => {
								console.log(res);

								localStorage.setItem('res', JSON.stringify(res));

								if (res === 'RequestError' || res === 'ConnectionError') {
									this.orderError = true;
									this.OrderGood = false;
									this.DrivingTruckPop = false;
									this.BackArrowPop = true;
									this._ss.OrderInProcess = false;
								} else {
									if (localStorage.getItem('DailyOrder') !== null) {
										this.DailyOrderAllorder = localStorage.getItem('DailyOrder');
										this.DailyOrderAllorder = JSON.parse(this.DailyOrderAllorder);
										this.DailyOrder = this.DailyOrderAllorder;
										this.AAABBB = [];
										for (let x = 0; x < this.AllOrder.length; x++) {
											this.AAABBB.push({
												Product_Name: this.AllOrder[x].Product_Name,
												TeudaQuantity: this.AllOrder[x].TeudaQuantity,
												TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
												Price: this.AllOrder[x].Price,
												TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
												TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
												TeudaNum: this.AllOrder[x].TeudaNum,
												Client_Name: this.AllOrder[x].Client_Name,
												TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
											});
										}

										this.DailyOrder.push({ Order: this.AAABBB });
										localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
									} else {
										this.AAABBB = [];
										for (let x = 0; x < this.AllOrder.length; x++) {
											this.AAABBB.push({
												Product_Name: this.AllOrder[x].Product_Name,
												TeudaQuantity: this.AllOrder[x].TeudaQuantity,
												TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
												Price: this.AllOrder[x].Price,
												TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
												TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
												TeudaNum: this.AllOrder[x].TeudaNum,
												Client_Name: this.AllOrder[x].Client_Name,
												TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
											});
										}
										this.DailyOrder.push({ Order: this.AAABBB });
										localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
									}

									this.clearLocalStorageOfOrder();

									// if (this.form.controls['Email'].value.length > 5) {
									// 	this._ss
									// 		.Email({
									// 			Email: this.form.controls['Email'].value,
									// 			TeudaNum: this.NewCart,
									// 			Total: this.TotalSumOAllOrder,
									// 			Teuda: JSON.stringify(this.AllOrder),
									// 			CompanyName: this._ss.CustomerInformation.CustName,
									// 		})
									// 		.subscribe(res => {});
									// }

									this.BackArrowPop = true;
									this.OrderGood = true;
									this._ss.OrderInProcess = false;
									this.Vorder = true;
									this._ss.TeudotDetails = null;
									this._ss.Teudot = null;
									this._ss.TeudotAll = null;
									this.AllOrder = [];
									this.TotalSumOAllOrder = 0;

									for (let x = 0; x < this.AllClients.length; x++) {
										if (this._ss.OneClientInfo.ClientId === this.AllClients[x].ClientId) {
											this._ss.OneClientInfo.CustDiscount = this.AllClients[x].CustDiscount;
										}
									}
								}
							},
							err => {
								console.log(err);

								localStorage.setItem('err', JSON.stringify(err));

								this.orderError = true;
								this.OrderGood = false;
								this.DrivingTruckPop = false;
								this.BackArrowPop = true;
								this._ss.OrderInProcess = false;

								// localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder.map(ord => ({...ord, failed:true}))));
							},
						);
				} else {
					this._ss
						.AllOrderString({
							DATAString: JSON.stringify(this.AllOrder),
							TeudaType: this._ss.DocType,
							TeudaNum: this.NewCart,
							AgentID: this._ss.AgentIdConnected,
							Client_Name: this._ss.OneClientInfo.Client_Name,
							TeudaTotalSum: this.CustDiscountShow,
							TotalLines: this.AllOrder.length,
							TeudaRemark: this._util.removeProblematicCharacters(this.HearaNewOrder || ''),
							Company: this._ss.OneClientInfo.company,
							Status: this.OrderStatusNumberSend,
						})
						.subscribe(
							res => {
								console.log(res);

								localStorage.setItem('res', JSON.stringify(res));

								if (res === 'RequestError' || res === 'ConnectionError') {
									this.orderError = true;
									this.OrderGood = false;
									this.DrivingTruckPop = false;
									this.BackArrowPop = true;
									this._ss.OrderInProcess = false;
								} else {
									if (localStorage.getItem('DailyOrder') !== null) {
										this.DailyOrderAllorder = localStorage.getItem('DailyOrder');
										this.DailyOrderAllorder = JSON.parse(this.DailyOrderAllorder);
										this.DailyOrder = this.DailyOrderAllorder;
										this.AAABBB = [];
										for (let x = 0; x < this.AllOrder.length; x++) {
											this.AAABBB.push({
												Product_Name: this.AllOrder[x].Product_Name,
												TeudaQuantity: this.AllOrder[x].TeudaQuantity,
												TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
												Price: this.AllOrder[x].Price,
												TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
												TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
												TeudaNum: this.AllOrder[x].TeudaNum,
												Client_Name: this.AllOrder[x].Client_Name,
												TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
											});
										}

										this.DailyOrder.push({ Order: this.AAABBB });
										localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
									} else {
										this.AAABBB = [];
										for (let x = 0; x < this.AllOrder.length; x++) {
											this.AAABBB.push({
												Product_Name: this.AllOrder[x].Product_Name,
												TeudaQuantity: this.AllOrder[x].TeudaQuantity,
												TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
												Price: this.AllOrder[x].Price,
												TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
												TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
												TeudaNum: this.AllOrder[x].TeudaNum,
												Client_Name: this.AllOrder[x].Client_Name,
												TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
											});
										}
										this.DailyOrder.push({ Order: this.AAABBB });
										localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
									}

									this.clearLocalStorageOfOrder();

									this.BackArrowPop = true;
									this.OrderGood = true;
									this._ss.OrderInProcess = false;
									this.Vorder = true;
									this._ss.TeudotDetails = null;
									this._ss.Teudot = null;
									this._ss.TeudotAll = null;
									this.AllOrder = [];
									this.TotalSumOAllOrder = 0;

									for (let x = 0; x < this.AllClients.length; x++) {
										if (this._ss.OneClientInfo.ClientId === this.AllClients[x].ClientId) {
											this._ss.OneClientInfo.CustDiscount = this.AllClients[x].CustDiscount;
										}
									}
								}
							},
							err => {
								console.log(err);

								localStorage.setItem('err', JSON.stringify(err));

								this.orderError = true;
								this.OrderGood = false;
								this.DrivingTruckPop = false;
								this.BackArrowPop = true;
								this._ss.OrderInProcess = false;

								// localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder.map(ord => ({...ord, failed:true}))));
							},
						);
				}
			}
		}
	}

	dis = 0;
	ChangePriceFunction(product) {
		const { ProductId, PermPrice: Price, ItemPack, MinPrice, BM } = product;

		this.form.controls.ChangeDiscount.reset();

		if (Price !== 0) {
			this.dis = 1 - +this.form.controls.ChangePrice.value / Price;
			this.dis = this.dis * 100;
		} else {
			this.dis = 0;
		}

		if (MinPrice > this.form.controls.ChangePrice.value && BM !== 0 && +this.form.controls.ChangePrice.value > 0) {
		} else {
			if (this.form.controls.ChangePrice.value !== null) {
				const order = this.AllOrder.find(ob => ob.ProductId === ProductId);
				if (!+this.form.controls.ChangePrice.value) {
					order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
					order.ChangePrice = Price;
					order.ChangeDiscount = 0;

					order.Price = order.PriceInInput = order.PermPrice;
				} else {
					order.IfChangePriceOrDisc = OrderState.changedPrice;

					if (this._ss.CustomerInformation.CustChange1 === '1' || this._ss.CustomerInformation.DiscountLogic === '2' || this._ss.CustomerInformation.DiscountLogic === '3') {
						order.ChangeDiscount = this.dis;
					} else {
						order.ChangeDiscount = 0;
					}

					order.ChangePrice = order.NetPrice = order.Price = order.PriceInInput = this.form.controls.ChangePrice.value;
				}
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}

		setTimeout(() => {
			if (this.form.controls.ChangePrice.value === null) {
				const order = this.AllOrder.find(ob => ob.ProductId === ProductId);
				order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
				order.ChangePrice = order.ChangeDiscount = 0;
				order.Price = Price;

				order.Price = order.PriceInInput = order.PermPrice;

				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			}
		}, 100);

		setTimeout(() => {
			this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ArizotPrice =
				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].TeudaPackQuantity * ItemPack;
			this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ArizotPrice =
				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ArizotPrice *
				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].Price;

			this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].YehidotPrice =
				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].TeudaQuantity *
				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].Price;
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
			this.GeneralFunction();
		}, 200);
	}

	ChangePriceFunctionAlternative(product) {
		const { PermPrice, ShowPrice, basePriceInputted, basePrice } = product;

		this.form.controls.ChangeDiscount.reset();
		this.form.controls.ChangeBasePrice.reset();

		const changePriceInput = this.form.controls.ChangePrice;

		if (+changePriceInput.value < 0) {
			changePriceInput.setValue(0);
		}

		if (!+changePriceInput.value) {
			product.IfChangePriceOrDisc = OrderState.noChangeToProduct;

			product.ChangeDiscount = 0;

			product.NetPrice = basePriceInputted || basePrice || ShowPrice || PermPrice;

			if (!ShowPrice) {
				product.ShowPrice = PermPrice;
			}
		} else {
			product.IfChangePriceOrDisc = OrderState.changedPrice;
			product.NetPrice = changePriceInput.value;

			const base = basePriceInputted || basePrice || ShowPrice || PermPrice;

			if (!ShowPrice) {
				product.ShowPrice = PermPrice;
			}

			product.ChangeDiscount = (1 - +changePriceInput.value / base) * 100;

			product.ChangeDiscount = Math.max(product.ChangeDiscount, 0);
		}

		this.GeneralFunction();
	}

	changeBasePrice(product) {
		const { PermPrice, ShowPrice } = product;

		this.form.controls.ChangeDiscount.reset();
		this.form.controls.ChangePrice.reset();

		const changeBasePriceInput = this.form.controls.ChangeBasePrice;

		if (+changeBasePriceInput.value < 0) {
			changeBasePriceInput.setValue(0);
		}

		if (!+changeBasePriceInput.value) {
			product.IfChangePriceOrDisc = OrderState.noChangeToProduct;

			product.ChangePrice = product.ChangeDiscount = 0;

			product.NetPrice = product.basePrice = product.basePriceInputted = ShowPrice || PermPrice;
			if (!ShowPrice) {
				product.ShowPrice = PermPrice;
			}
			product.PriceInInput = product.Price = PermPrice;
		} else {
			product.IfChangePriceOrDisc = OrderState.changedPrice;
			product.NetPrice = product.basePriceInputted = changeBasePriceInput.value;

			if (product.ChangeDiscount) {
				product.NetPrice = product.NetPrice - (product.NetPrice * product.ChangeDiscount) / 100;
				product.IfChangePriceOrDisc = OrderState.changedBothDiscountAndPrice;
			}
		}

		this.GeneralFunction();
	}

	PriceAhoz;
	HelpCantChange;

	ChangeDiscountFunction(product) {
		if (this.alternativeProductCalculations) {
			this.ChangeDiscountFunctionAlternative(product);
			return;
		}

		const { Stock, ProductId, ItemPack, MinPrice, BM, PriceInInput: Price, BS } = product;

		this.form.controls.ChangePrice.reset();
		if (this.form.controls.ChangeDiscount.value > 100) {
			this.form.controls.ChangeDiscount.setValue(100);
		}
		this.PriceAhoz = Price * +this.form.controls.ChangeDiscount.value;
		this.PriceAhoz /= 100;
		this.HelpCantChange = Price - this.PriceAhoz;

		if (MinPrice > this.HelpCantChange && BM === 1 && this._ss.CheckMinPrice !== 1 && !this.shouldOverrideAgentProductQuantities) {
		} else if (MinPrice > this.HelpCantChange && BM === 3 && this._ss.CheckMinPrice !== 1 && !this.shouldOverrideAgentProductQuantities) {
		} else {
			if (MinPrice > this.HelpCantChange && BM === 2) {
			}

			if (MinPrice > this.HelpCantChange && BM === 4) {
			}

			const order = this.AllOrder.find(ob => ob.ProductId === ProductId);

			// TODO How can this be undefined if it has to be in the array? Needs refactoring
			if (order === undefined) {
			} else {
				if (this.form.controls.ChangeDiscount.value !== null) {
					if (!this.form.controls.ChangeDiscount.value) {
						if (order.IfChangePriceOrDisc === OrderState.changedBothDiscountAndPrice) {
							order.IfChangePriceOrDisc = OrderState.changedPrice;
						} else {
							order.IfChangePriceOrDisc = OrderState.noChangeToProduct;
						}
						order.ChangePrice = Price;
						order.ChangeDiscount = 0;
						order.Price = Price;
						localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
					} else {
						if (order.IfChangePriceOrDisc === OrderState.changedPrice) {
							order.IfChangePriceOrDisc = OrderState.changedBothDiscountAndPrice;
						} else {
							if (order.IfChangePriceOrDisc === OrderState.noChangeToProduct) {
								order.IfChangePriceOrDisc = OrderState.changedDiscount;
							}
						}



						order.ChangeDiscount = this.form.controls.ChangeDiscount.value;
						order.Price = order.NetPrice = order.ChangePrice = Price - this.PriceAhoz;
						localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
					}
				}
			}

			setTimeout(() => {
				if (!this.form.controls.ChangeDiscount) {
					if (this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].IfChangePriceOrDisc === OrderState.changedBothDiscountAndPrice) {
						this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].IfChangePriceOrDisc = OrderState.changedPrice;
					} else {
						this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].IfChangePriceOrDisc = OrderState.noChangeToProduct;
					}
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ChangePrice = 0;
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ChangeDiscount = 0;
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].Price = Price;
					localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
				}
			}, 100);

			setTimeout(() => {
				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ArizotPrice =
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].TeudaPackQuantity * ItemPack;
				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ArizotPrice =
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].ArizotPrice *
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].Price;

				this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].YehidotPrice =
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].TeudaQuantity *
					this.AllOrder[this.AllOrder.indexOf(this.AllOrder.find(ob => ob.ProductId === ProductId))].Price;
				localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
				this.GeneralFunction();
			}, 200);
		}
	}

	ChangeDiscountFunctionAlternative(product) {
		const { PermPrice, ShowPrice, basePriceInputted, basePrice } = product;

		this.form.controls.ChangeBasePrice.reset();
		this.form.controls.ChangePrice.reset();

		const changeDiscountInput = this.form.controls.ChangeDiscount;

		if (+changeDiscountInput.value < 0) {
			changeDiscountInput.setValue(0);
		}

		if (+changeDiscountInput.value > 100) {
			changeDiscountInput.setValue(100);
		}

		if (!+changeDiscountInput.value) {
			product.IfChangePriceOrDisc = OrderState.noChangeToProduct;

			product.ChangeDiscount = 0;

			product.NetPrice = basePriceInputted || basePrice || ShowPrice || PermPrice;
			if (!ShowPrice) {
				product.ShowPrice = PermPrice;
			}

			if (basePriceInputted !== ShowPrice) {
				product.IfChangePriceOrDisc = OrderState.changedPrice;
			}
		} else {
			const price = basePriceInputted || basePrice || ShowPrice || PermPrice;

			if (!ShowPrice) {
				product.ShowPrice = PermPrice;
			}

			product.IfChangePriceOrDisc = OrderState.changedDiscount;
			product.ChangeDiscount = changeDiscountInput.value;
			product.NetPrice = price - (price * changeDiscountInput.value) / 100;

			if (basePriceInputted && basePriceInputted !== ShowPrice) {
				product.IfChangePriceOrDisc = OrderState.changedBothDiscountAndPrice;
			}
		}

		this.GeneralFunction();
	}

	handleChangeRemark(product, event: Event) {
		if (event.target instanceof HTMLInputElement) {
			const { value } = event.target;

			let inputted = value;

			if (value && this._util.testProblematicCharacters(value)) {
				inputted = this._util.removeProblematicCharacters(value);
			}

			product.TeudaItemRemark = inputted ? inputted : 'אין הערה';
			localStorage.setItem('AllOrder' + this._ss.OneClientInfo.ClientId + this._ss.DocType + this._ss.OneClientInfo.company, JSON.stringify(this.AllOrder));
		}
	}

	handleChangeBonus(i: number, event) {}

	showHideFirstStepFunction() {
		this.form.controls['Arizot'].reset();
		this.form.controls['Yehidot'].reset();

		window.scrollTo(0, 0);
		if (this.showHideFirstStep === false) {
			this.showHideFirstStep = true;
			this.showHideSecondStep = false;
			this.showHideThreeStep = false;
			this.showHideFourStep = false;
			this.GeneralFunction();
		} else {
			this.showHideFirstStep = false;
		}
	}

	showHideSecondStepFunction() {
		window.scrollTo(0, 0);
		if (this.showHideSecondStep === false) {
			this.showHideSecondStep = true;
			this.showHideFirstStep = false;
			this.showHideThreeStep = false;
			this.showHideFourStep = false;
		} else {
			this.showHideSecondStep = false;
		}
	}

	showHideThreeStepFunction() {
		window.scrollTo(0, 0);

		if (this.showHideThreeStep !== false) {
			this.showHideThreeStep = false;
			return;
		}

		if (this._ss.HearotHeadForTeuda === null) {
			const hearotFromStorage = localStorage.getItem('HearotHeadForTeuda');

			if (hearotFromStorage) {
				this._ss.HearotHeadForTeuda = JSON.parse(decompress(hearotFromStorage));
			} else {
				this._ss.GetHearotForTeuda().subscribe(res => {
					console.log(res);

					this._ss.HearotHeadForTeuda = res;
					this._ss.HearotHeadForTeuda = this._ss.HearotHeadForTeuda.recordset;
					if (this._ss.HearotHeadForTeuda.length === 0) {
						this._ss.HearotHeadForTeuda = null;
					}
				});
			}
		}

		this.showHideThreeStep = true;
		this.showHideFirstStep = false;
		this.showHideSecondStep = false;
		this.showHideFourStep = false;
	}

	showHideFourStepFunction() {
		if (this.isReclamationOrder || this.isCatalogAgent) return;

		window.scrollTo(0, 0);
		if (this.showHideFourStep === false) {
			this.showHideFourStep = true;
			this.showHideFirstStep = false;
			this.showHideSecondStep = false;
			this.showHideThreeStep = false;
		} else {
			this.showHideFourStep = false;
		}
	}

	showHideFirstStepFunctionNext() {
		window.scrollTo(0, 0);

		if (this.isReclamationOrder) {
			this.showHideFirstStep = false;
			this.showHideFourStep = true;
			return;
		}

		this.showHideFirstStep = false;
		this.showHideSecondStep = true;
	}

	showHideSecondStepFunctionNext() {
		window.scrollTo(0, 0);
		this.showHideSecondStep = false;
		this.showHideThreeStep = true;

		if (this._ss.HearotHeadForTeuda === null) {
			const hearotFromStorage = localStorage.getItem('HearotHeadForTeuda');

			if (hearotFromStorage) {
				this._ss.HearotHeadForTeuda = JSON.parse(decompress(hearotFromStorage));
				return;
			}
			this._ss.GetHearotForTeuda().subscribe(res => {
				this._ss.HearotHeadForTeuda = res;
				this._ss.HearotHeadForTeuda = this._ss.HearotHeadForTeuda.recordset;
			});
		}
	}

	showHideThreeStepFunctionNext() {
		window.scrollTo(0, 0);
		this.showHideThreeStep = false;
		this.showHideFourStep = true;
	}

	orderSave = false;
	processType = {
		Save: 'save',
		Success: 'success',
		Error: 'error',
	};
	showPopup = false;
	showConfirmPopup = false;
	showRotatePopup = false;

	showHideProcess(type?: string): void {
		this.orderProcess = type ? true : false;
		this.orderSave = false;
		this.orderSuccess = false;
		this.orderError = false;

		switch (type) {
			case this.processType.Save:
				this.orderSave = true;
				break;

			case this.processType.Success:
				this.orderSuccess = true;
				break;

			case this.processType.Error:
				this.orderError = true;
				break;
		}
	}

	showHidePopup(value: boolean): void {
		this.showPopup = value;
	}

	showHideConfirmPopup(value: boolean): void {
		this.showConfirmPopup = value;
	}

	showHideRotatePopup(value: boolean): void {
		this.showRotatePopup = value;
	}

	SearchClear() {
		this.AllProducts = this.AllOrder;
		this.form.reset();

		setTimeout(() => {
			document.getElementById('productsearch').focus();
		}, 50);
	}

	FormReset() {
		this.form.controls['Yehidot'].reset();
		this.form.controls['Arizot'].reset();
		this.form.controls.ChangePrice.reset();
		this.form.controls.ChangeDiscount.reset();
	}

	AllProducts;
	SearchErrorPop;
	SearchProducts() {
		if (this.isLeasingOrder) return;
		this.FormReset();

		window.scrollTo(0, 0);

		this.AllProducts = [];

		this.SearchErrorPop = false;

		if (this.form.controls.ProductSearch.value === '' || this.form.controls.ProductSearch.value.length === 0) {
			for (let x = 0; x < this.AllOrder.length; x++) {
				this.AllProducts.push(this.AllProductsSearch[x]);
			}
		}
		if (this.form.controls.ProductSearch.value.length === 1) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (
					this.AllProductsSearch[x].Product_Name.toString().slice(0, 1) === this.form.controls.ProductSearch.value.toString().slice(0, 1) ||
					this.AllProductsSearch[x].ProductId.toString().slice(0, 1) === this.form.controls.ProductSearch.value.toString().slice(0, 1)
				) {
					this.AllProducts.push(this.AllProductsSearch[x]);
				}
			}
		}
		if (this.form.controls.ProductSearch.value.length === 2) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				if (
					this.AllProductsSearch[x].Product_Name.toString().slice(0, 2) === this.form.controls.ProductSearch.value.toString().slice(0, 2) ||
					this.AllProductsSearch[x].ProductId.toString().slice(0, 2) === this.form.controls.ProductSearch.value.toString().slice(0, 2)
				) {
					this.AllProducts.push(this.AllProductsSearch[x]);
				}
			}
		}
		if (this.form.controls.ProductSearch.value.length > 2) {
			for (let x = 0; x < this.AllProductsSearch.length; x++) {
				for (let z = 0; z < 50; z++) {
					if (
						this.AllProductsSearch[x].Product_Name.toString().slice(z, this.form.controls.ProductSearch.value.length + z) ===
							this.form.controls.ProductSearch.value.toString().slice(0, this.form.controls.ProductSearch.value.length) ||
						this.AllProductsSearch[x].ProductId.toString().slice(z, this.form.controls.ProductSearch.value.length + z) ===
							this.form.controls.ProductSearch.value.toString().slice(0, this.form.controls.ProductSearch.value.length) ||
						this.AllProductsSearch[x].ItemBarcode.toString().slice(z, this.form.controls.ProductSearch.value.length + z) ===
							this.form.controls.ProductSearch.value.toString().slice(0, this.form.controls.ProductSearch.value.length)
					) {
						let data = this.AllProducts.find(ob => ob.ProductId === this.AllProductsSearch[x].ProductId);
						if (data === undefined) {
							this.AllProducts.push(this.AllProductsSearch[x]);
						}
					}
				}
			}
		}
		if (this.AllProducts.length === 0) {
		}
	}

	refreshSpecials() {
		const hash: SpecialObjectHash<Special> = this.allSpecials.reduce((acc: SpecialObjectHash<Special>, item) => {
			if (!acc[item.SpecialID]) {
				acc[item.SpecialID] = { ...item, length: 1 };

				return acc;
			}

			acc[item.SpecialID].length++;
			return acc;
		}, {});

		const specialsArr = Object.values(hash).map(spc => ({ ...spc, Product_Name: '', quantity: this.specialsInCart[spc.SpecialID]?.quantity || 0 }));

		this.allSpecials$.next(specialsArr);
	}

	images: { [K in Images]?: File } = {};

	async setImage(event, image) {
		const input = event.target as HTMLInputElement;
		input.classList.remove('error');
		input.classList.add('uploading');
		const file = new File(event.target.files, `${this.reclamationID}-${image}.jpg`, { type: event.target.files[0].type });
		const bool = await this.handleFileUpload(file);
		if (bool) {
			this.images['image' + image] = file;
		} else {
			input.classList.add('error');
		}

		input.classList.remove('uploading');
	}

	async handleFileUpload(file: File) {
		const fd = new FormData();

		fd.append(file.name, file);

		try {
			const res = await fetch(`${this._ss.CustomerInformation.CustServer}/mobil/images-reclamation`, {
				method: 'POST',
				body: fd,
			});

			const data = await res.json();
			console.log('finished fetch');
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	handleBlockInputs() {
		if (this._ss.CustomerInformation.BlockChangingPriceBonusDiscountOrQuantities && this._ss.CustomerInformation.BlockChangingPriceBonusDiscountOrQuantities !== '0') {
			const { BlockChangingPriceBonusDiscountOrQuantities: block } = this._ss.CustomerInformation;

			if (block === '1') {
				this.shouldDisableChangingInputs = true;
			}
			if (block === '2') {
				this.shouldDisablePriceAndDiscountInput = true;
			}
		}
	}

	handleUnBlockQty() {
		if (this._ss.CustomerInformation.UnBlockQty && this._ss.CustomerInformation.UnBlockQty !== '0') {
			const { UnBlockQty } = this._ss.CustomerInformation,
				{ DocType } = this._ss;

			if (DocType === 74) {
				if (UnBlockQty === '1' || UnBlockQty === '3') {
					this.shouldOverrideAgentProductQuantities = true;
				}
				return;
			}

			if (DocType === 7) {
				if (UnBlockQty === '2' || UnBlockQty === '3') {
					this.shouldOverrideAgentProductQuantities = true;
				}
				return;
			}
		}
	}

	populateRemarkWithText(source: 'HearaForOrder' | 'HearaForOrder1' | 'HearaForOrder2' | 'HearaForOrder3' | 'HearaForOrder4' | 'HearaForOrder5') {
		const control = this.form.controls[source];
		if (control && !control.value) {
			if (source === 'HearaForOrder') {
				control.setValue(this.HearaNewOrder);
				return;
			}

			const id = source[source.length - 1];
			control.setValue(this._ss['HearaNewOrder' + id]);
		}
	}

	mikum = '';

	mikumSelected(val: string) {
		this.mikum = val;
	}

	returnNewReclamationFormControl() {
		return new FormControl<ReclamationReasonsResponse | null>(null, Validators.required);
	}

	handleReclamationOrder() {
		if (this.reclamationForm.invalid) {
			this.reclamationForm.markAllAsTouched();
			return;
		}

		if (!this.images.image1 && !this.images.image2 && !this.images.image3 && !this.images.image4) {
			alert('יש להעלות תמונה אחת לפחות');
			return;
		}

		this.showHideFourStep = false;
		this.SendOrder = false;
		this.orderProcess = true;
		this.orderSuccess = true;

		this._ss.OrderInProcess = true;

		const body = {
			...this.reclamationForm.value,
			BODY: this.reclamationForm.controls.BODY.value,
			ANS: this.reclamationForm.controls.ANS.value,
			LOCATION: this.reclamationForm.controls.LOCATION.value,
			TAKALA: this.reclamationForm.controls.TAKALA.value,
			CarNum: this.reclamationForm.value.CarNum.toString(),
			RekRemark: this.reclamationForm.value.RekRemark || '',
			RekID: this.reclamationID,
			PIC1: this.reclamationID + '-1.jpg',
			PIC2: this.reclamationID + '-2.jpg',
			PIC3: this.reclamationID + '-3.jpg',
			PIC4: this.reclamationID + '-4.jpg',
			ProductId: this.AllOrder[0].ProductId,
			ProductQty: this.AllOrder[0].TeudaQuantity,
			ClientId: this._ss.OneClientInfo.ClientId,
			CreateDate: this.AllOrder[0].TeudaCreateDate,
			AgentId: this._ss.AgentIdConnected,
		};

		this._ss.handleReclamationOrder(body).subscribe({
			next: res => {
				if (res === 'RequestError' || res === 'ConnectionError') {
					this.orderError = true;
					this.OrderGood = false;
					this.DrivingTruckPop = false;
					this.BackArrowPop = true;
					this._ss.OrderInProcess = false;

					return;
				}

				this.clearLocalStorageOfOrder();

				this.BackArrowPop = true;
				this.OrderGood = true;
				this._ss.OrderInProcess = false;
				this.Vorder = true;
				this._ss.TeudotDetails = null;
				this._ss.Teudot = null;
				this._ss.TeudotAll = null;
				this.AllOrder = [];
				this.TotalSumOAllOrder = 0;

				for (let x = 0; x < this.AllClients.length; x++) {
					if (this._ss.OneClientInfo.ClientId === this.AllClients[x].ClientId) {
						this._ss.OneClientInfo.CustDiscount = this.AllClients[x].CustDiscount;
					}
				}
			},
			error: err => {
				alert('ישנה תקלת תקשורת, אנא נסה שנית');
				this.orderError = true;
				this.OrderGood = false;
				this.DrivingTruckPop = false;
				this.BackArrowPop = true;
				this._ss.OrderInProcess = false;

				return;
			},
		});
	}

	toggleProductBetweenWeightAndSingles(product: Product2) {
		const { weightMode } = product;
		const allorderProduct = this.AllOrder.find(prod => prod.ProductId === product.ProductId);
		allorderProduct.YehidotPrice = allorderProduct.Price * allorderProduct.TeudaQuantity;
		if (weightMode === 'weight') {
			product.weightMode = 'singles';
			allorderProduct.YehidotPrice *= allorderProduct.Weight || 1;
		}

		if (weightMode === 'singles') {
			product.weightMode = 'weight';
		}

		allorderProduct.weightMode = product.weightMode;

		this.GeneralFunction();
	}

	toggleProductBetweenBoxesAndPacks(product: Product2) {
		const { packsWeightMode } = product;
		const allorderProduct = this.AllOrder.find(prod => prod.ProductId === product.ProductId);

		const itemPacks = this.itempackRecord[product.ProductId];

		if (packsWeightMode === 'boxes') {
			product.packsWeightMode = 'packs';
			product.ItemPack = product.originalItemPack || 1;
			delete product.Unit;
			if (allorderProduct) {
				delete allorderProduct.Unit;
			}
		}

		if (packsWeightMode === 'packs') {
			product.packsWeightMode = 'boxes';
			if (itemPacks?.length) {
				product.ItemPack = itemPacks[0].UnitPerPack || 1;
				product.Unit = itemPacks[0].Unit;
			}
		}
		allorderProduct.ArizotPrice = allorderProduct.Price * allorderProduct.TeudaPackQuantity * allorderProduct.ItemPack;

		this.GeneralFunction();
	}

	handleCreditCardFormReset() {
		this._cc.resetFormValues();
	}

	toggleCreditCardPopup(mode: 'open' | 'close') {
		this.isCreditCardPopupOpen = mode === 'open';
		this._cc.creditCardFormValues$.next({ ...this._cc.creditCardFormValues$.value, amount: this.TotalSumOAllOrderPlusVat });
	}

	async handleCreditCardSubmit(payload: { status: 'cancel' } | { status: 'ok'; value: any }) {
		if (payload.status === 'cancel') {
			this.toggleCreditCardPopup('close');
			return;
		}

		if (payload.status === 'ok') {
			this._cc.creditCardFormValues$.next(payload.value);

			this.toggleCreditCardPopup('close');
			this.SendTeuda();
		}
	}

	async handleCreditCardSendOrder() {
		const body = this.orderBodyForCreditCard;

		if (this._cc.hasAnyFormValues()) {
			const { CVV, amount, creditCardID, expiry, number, payType, payments } = this._cc.creditCardFormValues$.value;
			const creditBody = {
				...body,
				CreditTerms: BackendCreditTerms[payType.toUpperCase()],
				Expiry: { MM: expiry.month, YY: expiry.year },
				PAN: number,
				Payments: payments,
				TransSum: amount || +this.TotalSumOAllOrder,
				CVV2: CVV,
				ID: creditCardID,
			};
			try {
				const response = await this._cc.handlePostNewOrderWithCredit(creditBody);

				const shouldCountError =
					response.msg !== 'Receipt already exists' && response.msg !== 'Receipt already paid with different amount' && response.msg !== 'Order already paid with different amount';

				if (response.msg === 'request timed out') {
					this._receipts.sendingErroredWithTimeoutAndCredit = false;
					this._ss.OrderInProcess = false;
					this._receipts.handleFailedCreditCardPaymentAfterTimeout(response.payload);
					return;
				}

				if (response.err && shouldCountError) {
					alert(response.msg);
					console.error(response);
					this.orderError = true;
					this.OrderGood = false;
					this.DrivingTruckPop = false;
					this.BackArrowPop = true;
					this._ss.OrderInProcess = false;

					return;
				}

				if (response.authManpikNo) {
					this.authManpikNo = response.authManpikNo;
				}

				if (response.shovarNum) {
					this.shovarNum = response.shovarNum;
				}

				localStorage.setItem('res', JSON.stringify(response));

				if (response === 'RequestError' || response === 'ConnectionError') {
					throw response;
				}

				if (localStorage.getItem('DailyOrder') !== null) {
					this.DailyOrderAllorder = localStorage.getItem('DailyOrder');
					this.DailyOrderAllorder = JSON.parse(this.DailyOrderAllorder);
					this.DailyOrder = this.DailyOrderAllorder;
					this.AAABBB = [];
					for (let x = 0; x < this.AllOrder.length; x++) {
						this.AAABBB.push({
							Product_Name: this.AllOrder[x].Product_Name,
							TeudaQuantity: this.AllOrder[x].TeudaQuantity,
							TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
							Price: this.AllOrder[x].Price,
							TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
							TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
							TeudaNum: this.AllOrder[x].TeudaNum,
							Client_Name: this.AllOrder[x].Client_Name,
							TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
						});
					}

					this.DailyOrder.push({ Order: this.AAABBB });
					localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
				} else {
					this.AAABBB = [];
					for (let x = 0; x < this.AllOrder.length; x++) {
						this.AAABBB.push({
							Product_Name: this.AllOrder[x].Product_Name,
							TeudaQuantity: this.AllOrder[x].TeudaQuantity,
							TeudaPackQuantity: this.AllOrder[x].TeudaPackQuantity,
							Price: this.AllOrder[x].Price,
							TeudaItemDiscount: this.AllOrder[x].TeudaItemDiscount,
							TeudaTotalRow: this.AllOrder[x].TeudaTotalRow,
							TeudaNum: this.AllOrder[x].TeudaNum,
							Client_Name: this.AllOrder[x].Client_Name,
							TeudaTotalSum: this.AllOrder[x].TeudaTotalSum,
						});
					}
					this.DailyOrder.push({ Order: this.AAABBB });
					localStorage.setItem('DailyOrder', JSON.stringify(this.DailyOrder));
				}

				this.clearLocalStorageOfOrder();

				// if (this.form.controls['Email'].value.length > 5) {
				// 	this._ss
				// 		.Email({
				// 			Email: this.form.controls['Email'].value,
				// 			TeudaNum: this.NewCart,
				// 			Total: this.TotalSumOAllOrder,
				// 			Teuda: JSON.stringify(this.AllOrder),
				// 			CompanyName: this._ss.CustomerInformation.CustName,
				// 		})
				// 		.subscribe(res => {});
				// }

				this.BackArrowPop = true;
				this.OrderGood = true;
				this._ss.OrderInProcess = false;
				this.Vorder = true;
				this._ss.TeudotDetails = null;
				this._ss.Teudot = null;
				this._ss.TeudotAll = null;
				this.AllOrder = [];
				this.TotalSumOAllOrder = 0;

				for (let x = 0; x < this.AllClients.length; x++) {
					if (this._ss.OneClientInfo.ClientId === this.AllClients[x].ClientId) {
						this._ss.OneClientInfo.CustDiscount = this.AllClients[x].CustDiscount;
					}
				}
			} catch (error) {
				localStorage.setItem('err', JSON.stringify(error));

				this.orderError = true;
				this.OrderGood = false;
				this.DrivingTruckPop = false;
				this.BackArrowPop = true;
				this._ss.OrderInProcess = false;

				console.error(error);
			}
			return;
		}
	}

	resolveIfDocIsOfReturnsType(doc: string | number = this._ss.DocType) {
		const isTypeReturn = !this._doctypes.doesHaveExtraDoctypeDefinitions() ? +doc === 74 : this._doctypes.typesOfDoctypes.return.has(+doc);

		return this._doctypes.isSpecialRefundDoctype(doc) || isTypeReturn;
	}

	// handleDocTypeShrionIsShrion() {
	// 	if (!this._ss.DocTypeShrion || ![2, 3, 7].includes(this._ss.DocTypeShrion)) return;

	// 	const openPricesAndDiscounts = this._ss.DocTypeShrion === 2 || this._ss.DocTypeShrion === 3;
	// 	const onlyWorkInSinglesAndBlockPacks = this._ss.DocTypeShrion === 7;

	// 	if (this._ss.DocTypeShrion === 3) {
	// 		this._ss.CheckMinPrice = 1;
	// 	}

	// 	if (onlyWorkInSinglesAndBlockPacks) {
	// 		this.hideSingles = false;
	// 		this.hidePacks = true;
	// 		this._ss.SeeArizot = false;
	// 	}
	// }
}

type Images = 'image1' | 'image2' | 'image3' | 'image4';
