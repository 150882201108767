import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType, HttpResponse } from '@angular/common/http';
import { catchError, map, Observable, tap } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { RALLYANCE_RECLAMATION_APP_PATH } from '../services/reclamation.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	constructor(private _loading: LoadingService) {}
	
	private readonly openPopupInThesePaths = ['alert', 'likut', 'truck-loading', 'truck-distribution', 'activ', 'planned-vs-unplanned', RALLYANCE_RECLAMATION_APP_PATH];

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		for (const path of this.openPopupInThesePaths) {
			if (location.pathname.includes(path)) {
				return this.handleWithLoadingPopup(request, next);
			}
		}

		return next.handle(request);
	}

	private handleWithLoadingPopup(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this._loading.changeLoadingStatuses({ isLoading: true });
		return next
			.handle(request)
			.pipe(
				catchError(err => {
					this._loading.changeLoadingStatuses({ isLoading: false });
					return err;
				}),
			)
			.pipe(
				map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
					if (evt instanceof HttpResponse) {
						this._loading.changeLoadingStatuses({ isLoading: false });
						this._loading.changeMessageBackToDefault();
					}
					return evt;
				}),
			);
	}
}
