<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToClientCard()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">ביקורים</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- shop return activity markup -->
		<section class="shop-activity" style="margin-top: 50px">
			<div class="content">
				<div class="shop-activity__products" *ngIf="showActivityProducts">
					<div class="shop-activity__action">
						<button class="shop-activity__action-button" (click)="Knisa()" *ngIf="ShowExit === false">דווח כניסה</button>

						<button class="shop-activity__action-button" (click)="newExitReprot()" *ngIf="ShowExit === true && this.Loading === false">דווח יציאה</button>
					</div>

					<div class="shop-activity__item" *ngFor="let raa of ResAgentActivity">
						<div class="shop-activity__item-row shop-activity__item-row_gray row">
							<div class="shop-activity__item-title">לקוח:</div>
							<div class="shop-activity__item-text shop-activity__item-text_bold">{{ this._ss.OneClientInfo.Client_Name }}</div>
						</div>
						<div class="shop-activity__item-row shop-activity__item-row_double row">
							<div class="shop-activity__item-col">
								<div class="shop-activity__item-title">קוד לקוח:</div>
								<div class="shop-activity__item-text">{{ raa.ClientID }}</div>
							</div>
							<div class="shop-activity__item-col">
								<div class="shop-activity__item-title">שעת ביקור:</div>
								<div class="shop-activity__item-text">{{ raa.CreateDate | date : 'HH:MM:ss' : 'GMT' }}</div>
							</div>

							<div class="shop-activity__item-col">
								<div class="shop-activity__item-title">תאריך ביקור:</div>
								<div class="shop-activity__item-text">{{ raa.CreateDate.toString().slice(8, 10) }}.{{ raa.CreateDate.toString().slice(5, 7) }}.{{ raa.CreateDate.toString().slice(0, 4) }}</div>
							</div>
						</div>
						<!-- <div class="shop-activity__item-row row">
                <div class="shop-activity__item-title">נושא:</div>
                <div class="shop-activity__item-text">אי מכירה</div>
              </div> -->
						<div class="shop-activity__item-row row">
							<div class="shop-activity__item-title">סיכום ביקור:</div>
							<div class="shop-activity__item-text">{{ raa.ResDesc }}</div>
						</div>
						<div class="shop-activity__item-row row">
							<div class="shop-activity__item-title">פירוט:</div>
							<div class="shop-activity__item-text">{{ raa.Remark }}</div>
						</div>
					</div>
				</div>
				<form [formGroup]="form" (ngSubmit)="ConfirmBikor()">
					<div class="shop-activity__product-summ" *ngIf="!showActivityProducts">
						<div class="shop-activity__item">
							<div class="shop-activity__item-row shop-activity__item-row_gray row">
								<div class="shop-activity__item-title">לקוח:</div>
								<div class="shop-activity__item-text shop-activity__item-text_bold">
									{{ this._ss.OneClientInfo.Client_Name }}
								</div>
							</div>
							<div class="shop-activity__item-row shop-activity__item-row_double row">
								<div class="shop-activity__item-col">
									<div class="shop-activity__item-title">קוד לקוח:</div>
									<div class="shop-activity__item-text">{{ this._ss.OneClientInfo.ClientId }}</div>
								</div>
								<div class="shop-activity__item-col">
									<div class="shop-activity__item-title">שעת כניסה:</div>
									<div class="shop-activity__item-text">{{ this.knisaTimeParsed | appDate : 'mediumTimeFixed' }}</div>
								</div>
							</div>
							<!-- <div class="shop-activity__item-row row">
                <div class="shop-activity__item-title">סטטוס:</div>
                <select class="shop-activity__item-select" formControlName="Status" >
                  <option value="enter">כניסה</option>
                  <option value="exit"> יציאה</option>
                </select>
              </div> -->
							<div class="shop-activity__item-row row" *ngIf="canAttachPicture">
								<div class="shop-activity__item-title">צרף תמונה:</div>
								<input class="order-step__block-row-input" #imageInput [ngClass]="{ success: image }" type="file" accept="image/*" capture="camera" (change)="setImage($event)" />
							</div>
							<div class="shop-activity__item-row row">
								<div class="shop-activity__item-title">פירוט:</div>
								<textarea class="shop-activity__item-textarea" placeholder="ספרו לנו עוד" formControlName="Report" maxlength="100"></textarea>
							</div>
							<div class="shop-activity__item-row row">
								<div class="shop-activity__item-title">סיכום ביקור:</div>
								<select class="shop-activity__item-select" formControlName="Reason" [multiple]="canSelectMultipleReasons || null">
									<option value="{{ r.rid }}" *ngFor="let r of Reasons">{{ r.rds }}</option>
								</select>
							</div>
							<div class="shop-activity__item-row row" *ngIf="addToVisitAlertText">
								<div class="shop-activity__item-title">{{ addToVisitAlertText }}</div>
							</div>
						</div>
						<div class="shop-activity__action">
							<button class="shop-activity__action-button shop-activity__action-button_gray" *ngIf="Loading === false">דיווח חדש</button>
							<div *ngIf="Loading === true">שולח אנא המתן</div>
							<div class="shop-activity__action-text-button" (click)="showActivityProducts = !showActivityProducts">בטל</div>
						</div>
					</div>
				</form>
			</div>
		</section>
		<!-- end shop return activity markup -->
	</div>
</div>

<!-- start markup for product detail add popup -->
<div class="popup" *ngIf="ConfirmSentReport === true" style="z-index: 999">
	<div class="overlay"></div>
	<div class="product-detail-add__popup popup__content">
		<div class="product-detail__wrapper">
			<div class="product-detail__item">
				<div class="popup__close" style="position: absolute; top: 0px; background-color: black" (click)="CloseSentReportPopOpen()"></div>

				<h2 class="product-detail-add__popup-title">אישור ביקור</h2>

				<h3 class="product-detail-add__popup-title">ביקור נשלח בהצלחה</h3>

				<h3 class="product-detail-add__popup-title">מספר ביקור:{{ this.NewCartBikor }}</h3>

				<div class="auth__help-image image">
					<img src="/assets/images/auth/plane-icon.gif" alt="photo" />
				</div>

				<div class="product-detail-add__popup-row">
					<button class="product-detail-add__popup-button" (click)="CloseSentReportPopOpen()">חזור</button>
				</div>
				<div class="product-detail-add__popup-row" *ngIf="_alert.hasAgentsAlerts">
					<button class="product-detail-add__popup-button" (click)="navigateToAlerts()">הוסף התראה</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end markup for product detail add popup -->
