<!-- global markup for header -->
<header class="header">
	<div class="content">
		<div class="header__block row">
			<div class="header__arrow col image" (click)="BackToDohotBizoim()"><img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span></div>
			<div class="header__title col">הקצאות לסוכן</div>
			<div class="header__burger col image">
				<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
			</div>
		</div>
	</div>

	<div class="report-table__row">
		<div class="header__search header__search_small" style="width: 99%; margin: auto; margin-top: 10px">
			<form [formGroup]="form" (keyup)="Search()">
				<input class="header__search-input" type="text" placeholder="חפש רשומה" formControlName="Search" style="text-align: center" />
			</form>
			<button class="header__clear-icon" (click)="SearchClear()"></button>
		</div>
	</div>
</header>

<!-- start report table markup -->
<section class="report-table">
	<div class="content">
		<h1 *ngIf="Loading === true" style="text-align: right; margin-top: 100px">טוען אנא המתן...</h1>

		<div class="report-table__row report-table__row_between" style="margin-top: 100px">
			<div *ngIf="HasMultipleCompanys === '2'" style="margin-left: 25px">
				<form [formGroup]="form" (change)="ChangeCompany()">
					<select formControlName="Company" style="width: 150px; height: 35px">
						<option *ngFor="let c of ShowCompanys" value="{{ c.Company }}">{{ c.CompanyName }}</option>
					</select>
				</form>
			</div>
		</div>

		<div class="report-table__row report-table__row_full-width" style="margin-right: 0px">
			<table class="report-table__table" cellspacing="0">
				<thead class="report-table__table-head">
					<tr>
						<th class="report-table__table-head-name" style="width: 140px">שם קבוצת פריט</th>
						<th class="report-table__table-head-name" style="width: 80px">שנת בציר</th>
						<th class="report-table__table-head-name" style="width: 90px">נפח</th>
						<th class="report-table__table-head-name" style="width: 100px">הקצאה</th>
						<th class="report-table__table-head-name" style="width: 150px">מכירות מצטברות</th>
						<th class="report-table__table-head-name" style="width: 150px">הזמנות פתוחות</th>
						<th class="report-table__table-head-name" style="width: 150px">יתרה שנתית</th>
					</tr>
				</thead>
				<tbody class="report-table__table-body">
					<tr *ngFor="let b of Bizoim">
						<td class="report-table__table-body-name">{{ b.groupname }}</td>
						<td class="report-table__table-body-name">{{ b.bazir }}</td>
						<td class="report-table__table-body-name">{{ b.volume }}</td>
						<td class="report-table__table-body-name">{{ b.yaadqty }}</td>
						<td class="report-table__table-body-name">{{ b.totalsale }}</td>
						<td class="report-table__table-body-name">{{ b.openorder }}</td>
						<td class="report-table__table-body-name">{{ b.yitra }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</section>
