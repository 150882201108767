import { NewReceiptBody } from './new-receipt-body';

export const CreditOrderDoctypes = {
	min: 300,
	max: 310,
} as const;

export const BackendCreditTerms = {
	CREDIT: 1,
	DIRECT: 3,
	PAYMENTS: 8,
	1: 'CREDIT',
	3: 'DIRECT',
	8: 'PAYMENTS',
} as const;

export type CreditParameters = {
	methodName: string;
	needsToProvideCVVAndIsraeliID: boolean;
	canProduceReceiptWithoutFullPayment: boolean;
	hasPayments: boolean;
	defaultPaymentLevels: PaymentLevel[];
	cardTypes: CardTypes;
	apiPayloadDetails: ApiPayloadDetails;
	apiEndpoint: string;
};

export const AllCards = ['mastercard', 'visa', 'americanExpress', 'diners', 'isracardDebit', 'isracardDirect'] as const;

export type CardProperties = { hebrewName: string } & ({ allowed: true; customPaymentLevels: PaymentLevel[] } | { allowed: false });
export type CardTypes = Record<typeof AllCards[number], CardProperties>;

export interface ApiPayloadDetails {
	staticParametersWithoutIDs: StaticParametersWithoutIds;
	dynamicParameters: DynamicParameters;
	emptyFields: string[];
}

export interface StaticParametersWithoutIds {
	PrmJ: number;
	EntryMode: string;
	Currency: number;
	TransType: string;
	PosNum: string;
	CashBackSum: number;
	Payment1: number;
	PaymentN: number;
}

export interface DynamicParameters {
	Payments: number;
	CreditTerms: number;
	Addendum1: string;
	CVV2: string;
	ID: string;
}

export interface PaymentLevel {
	payments: number;
	minCredit: number;
}

export interface ReceiptBodyWithPayment extends NewReceiptBody {
	PAN: string;
	Expiry: {
		MM: string;
		YY: string;
	};
	TransSum: number;
	Payments: number;
	CreditTerms: typeof BackendCreditTerms[keyof typeof BackendCreditTerms];
	DocNum: string;
	CVV2?: string;
	ID?: string;
}

export type RecieiptSuccessResponse = {
	recordsets: any[];
	output: any;
	rowsAffected: number[];
	finalQuery: string;
	extraResponse: {
		uid: string;
		pan: string;
		rrn: string;
		addendum1: string;
		authManpikNo: string;
		cardType: string;
		amount: number;
		MerchantNote: string;
		shovarNum: string;
		TransactionID: string;
	};
};
