<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToClientHovot()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">פירוט חוב</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- start markup for client profile page -->
		<!-- client info for all client profile page -->
		<section class="client-info" style="margin-top: 100px">
			<div class="content">
				<div class="row-title">
					<div class="row-title-right">
						<div class="client-info__title col">
							{{ this._ss.OneClientInfo.Client_Name }}
						</div>
						<div class="client-info__number col">
							{{ this._ss.OneClientInfo.ClientId }}
						</div>
					</div>
					<div class="row-title-left justify-align-center clickable" (click)="isEmailPickPopupOpen = true" *ngIf="doesPDFExistInServer">
						<div>
							<button style="margin-left: 1em" color="primary" mat-mini-fab><mat-icon>picture_as_pdf</mat-icon></button>
							שליחה כדוא"ל
						</div>
						<!-- <a [href]="_ss.CustomerInformation.CustServer + '/pdf-download-link/' + pdfFilepath" [download]="EachClientEachOrder?.[0]?.DocDocNum + '.pdf'">
							<button style="margin-left: 1em" color="primary" mat-mini-fab><mat-icon>picture_as_pdf</mat-icon></button>לחץ כדי להוריד</a
						> -->
					</div>
				</div>
			</div>
		</section>
		<!-- end of client info for all client profile page -->

		<!-- start markup for client profile order page -->
		<section class="client-details" *ngIf="EachClientEachHov.length; else elseBlock">
			<div class="content">
				<div class="row">
					<div class="client-details__item row">
						<!-- <div class="client-details__item-status client-details__item-status_blue">סופק</div> -->
						<div class="client-details__item-row client-details__item-row_margin row">
							<span class="client-details__item-name client-details__item-name_bold" style="font-size: 17px; font-weight: 550">אסמכתא</span>
							<span class="client-details__item-name" style="font-size: 17px; font-weight: 550">{{ this.EachClientEachHov[0].DocDocNum }}#</span>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title" style="font-size: 17px; font-weight: 550">תאריך אספקה:</span>
							<span class="client-details__item-text client-details__item-text_bold" dir="rtl" style="font-size: 17px; font-weight: 550"
								>{{ this.EachClientEachHov[0].DocSupplyDate.toString().slice(8, 10) }}-{{ this.EachClientEachHov[0].DocSupplyDate.toString().slice(5, 7) }}-{{
									this.EachClientEachHov[0].DocSupplyDate.toString().slice(0, 4)
								}}</span
							>
							<span class="client-details__item-text" style="font-size: 17px; font-weight: 550">7:00-19:00</span>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title" style="font-size: 17px; font-weight: 550">תאריך הזמנה:</span>
							<span class="client-details__item-text client-details__item-text_bold" dir="rtl" style="font-size: 17px; font-weight: 550"
								>{{ this.EachClientEachHov[0].DocCreateDate.toString().slice(8, 10) }}-{{ this.EachClientEachHov[0].DocCreateDate.toString().slice(5, 7) }}-{{
									this.EachClientEachHov[0].DocCreateDate.toString().slice(0, 4)
								}}</span
							>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title" style="font-size: 17px; font-weight: 550">כתובת:</span>
							<span class="client-details__item-text client-details__item-text_bold" style="font-size: 17px; font-weight: 550"
								>{{ this._ss.OneClientInfo.Address }},{{ this._ss.OneClientInfo.CustCity }}</span
							>
						</div>
						<div class="client-details__item-row client-details__item-row_margin row">
							<span class="client-details__item-title" style="font-size: 17px; font-weight: 550">הערה:</span>
							<span class="client-details__item-text client-details__item-text_bold" style="font-size: 17px; font-weight: 550">{{ this._ss.currentlyChosenDebt.DebtRemark }}</span>
						</div>
						<div class="client-details__item-row client-details__item-row_summ row">
							<div class="client-details__item-col client-details__item-col_border col">
								<span class="client-details__item-title" style="font-size: 17px; font-weight: 550">סכום:</span>
								<span class="client-details__item-text client-details__item-text_big client-details__item-text_price" style="font-size: 19px; font-weight: 600">{{
									this.EachClientEachHov[0].DocTotalSumVat | number : '1.0-2' : 'en-US'
								}}</span>
							</div>
							<div class="client-details__item-col col">
								<span class="client-details__item-title" style="font-size: 17px; font-weight: 550">מספר שורות:</span>
								<span class="client-details__item-text client-details__item-text_big" style="font-size: 17px; font-weight: 550">{{ this.EachClientEachHov.length }}</span>
							</div>
						</div>
					</div>
					<div class="client-details__block" *ngFor="let eceo of EachClientEachHov">
						<div class="row">
							<div class="client__product-title" style="font-size: 17px; font-weight: 550">
								{{ eceo.DocItemName }}
								<span class="client__product-title-brackets" style="font-size: 14px; font-weight: 550" *ngIf="this._ss.SeeArizot === true && showItemInPack">{{ eceo.DocPackQuantity }} באריזה</span>
							</div>
						</div>
						<div class="client__product-block-row client__product-block-row_border row">
							<div class="client__product-block-col client__product-block-col_image image">
								<img
									src="{{ this._ss.CustomerInformation.CustImageDomain }}/{{ eceo.DocItemCode }}.jpg"
									class="ItemPic"
									onerror="this.onerror=null; this.src='/assets/images/no-product-icon.svg'"
									alt="photo"
								/>
							</div>
							<div class="client__product-block-col client__product-block-col_text">
								<div class="row">
									<div class="client__product-block-text client__product-block-text_gray" style="font-size: 17px; font-weight: 550">ברקוד:</div>
									<div class="client__product-block-text">לא קיים</div>
								</div>
								<div class="row">
									<div class="client__product-block-text client__product-block-text_gray" style="font-size: 17px; font-weight: 550">קוד פריט:</div>
									<div class="client__product-block-text" style="font-size: 17px; font-weight: 550">{{ eceo.DocItemCode }}</div>
								</div>
							</div>
							<div class="client__product-block-col client__product-block-col_number client__product-block-col_border">
								<div class="row">
									<h3 class="client__product-block-text client__product-block-text_gray" style="font-size: 17px; font-weight: 550">יחידות</h3>
									<div class="client__product-block-text" style="font-size: 17px; font-weight: 550">{{ eceo.DocQuantity }}</div>
								</div>
							</div>
							<div class="client__product-block-col client__product-block-col_number" *ngIf="this._ss.SeeArizot === true">
								<div class="row">
									<h3 class="client__product-block-text client__product-block-text_gray" style="font-size: 17px; font-weight: 550">{{ _text.packQuantityText }}</h3>
									<div class="client__product-block-text" style="font-size: 17px; font-weight: 550">
										{{ eceo.DocPackQuantity }}
									</div>
								</div>
							</div>
						</div>
						<div class="client__product-block-row row">
							<div class="client__product-block-box">
								<div class="client__product-block-box-title" style="font-size: 17px; font-weight: 550">מחיר יחידה</div>
								<div class="client__product-block-box-price client__product-block-box-price_small" style="font-size: 17px; font-weight: 550">
									{{ eceo.DocPrice | number : '1.0-2' : 'en-US' }}
								</div>
							</div>
							<div class="client__product-block-box">
								<div class="client__product-block-box-title" style="font-size: 17px; font-weight: 550">מחיר מעודכן</div>
								<div class="client__product-block-box-price client__product-block-box-price_green client__product-block-box-price_small" style="font-size: 17px; font-weight: 550">
									{{ eceo.DocPrice | number : '1.0-2' : 'en-US' }}
								</div>
							</div>
							<div class="client__product-block-box">
								<div class="client__product-block-box-title" style="font-size: 17px; font-weight: 550">הנחה %</div>
								<div class="client__product-block-box-text" style="font-size: 17px; font-weight: 550">{{ eceo.DocDiscount | number : '1.0-2' : 'en-US' }}</div>
							</div>
							<div class="client__product-block-box">
								<div class="client__product-block-box-title" style="font-size: 17px; font-weight: 550">סה"כ</div>
								<div class="client__product-block-box-price client__product-block-box-price_green" style="font-size: 17px; font-weight: 550" style="font-size: 17px; font-weight: 550">
									{{ eceo.DocTotalRow | number : '1.0-2' : 'en-US' }}
								</div>
							</div>
						</div>
						<div class="client__product-block-row">
							<div class="client__product-block-icon image">
								<img src="/assets/images/basket/chat-icon.svg" alt="photo" />
							</div>
							<div class="client__product-block-title">{{ eceo.DocRemark }}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- end markup for client profile order page -->

		<ng-template #elseBlock>
			<span style="margin: 2em; display: block; font-size: xx-large; text-align: center">אין נתונים להציג</span>
		</ng-template>
	</div>
</div>

<div class="popup" *ngIf="isEmailPickPopupOpen" style="z-index: 556">
	<div class="overlay"></div>
	<div class="product-detail-popup popup__content fixed wrapper">
		<div class="product-detail__item">
			<div class="popup__close" (click)="handleClosePopup()" style="width: 30px; height: 30px; background-color: black; position: fixed"></div>

			<div *ngIf="!loadingEmailSend; else loadingBlock">
				<ng-container *ngIf="!emailSentSuccessfully; else sent">
					<h2 class="product-detail-add__popup-title" style="text-align: center">בחר כתובת דוא"ל לשליחה</h2>

					<div style="padding: 1rem">
						<form (ngSubmit)="handleSubmitEmail()" style="display: grid">
							<mat-radio-group color="primary" [ngClass]="{ 'radio-group': windowWidth > 768 }" dir="rtl" [formControl]="emailRadios">
								<mat-radio-button value="user" *ngIf="_user.agentEmail$ | async as email">כתובת סוכן - {{ email }}</mat-radio-button>
								<mat-radio-button value="client" [disabled]="!clientHasEmail || null"
									>כתובת לקוח - {{ OneClientInfo.CustEmail || OneClientInfo.CustEMail || 'לא נמצאה כתובת לקוח' }}</mat-radio-button
								>
								<mat-radio-button value="custom">
									<mat-form-field>
										<mat-label>כתובת דוא"ל</mat-label>
										<input matInput (focus)="emailRadios.setValue('custom')" [formControl]="emailInput" dir="rtl" placeholder='כתובת דוא"ל' />
									</mat-form-field>
								</mat-radio-button>
							</mat-radio-group>
							<div style="text-align: center">
								<button mat-raised-button color="primary" [disabled]="emailInput.invalid&& emailRadios.value === 'custom'">שלח</button>
							</div>
						</form>
					</div>
				</ng-container>
				<ng-template #sent>
					<h2 style="text-align: center; direction: rtl">דוא"ל נשלח בהצלחה!</h2>
				</ng-template>

				<!-- <h3 style="text-align: center">{{ this._ss.OneClientInfo.Client_Name }}</h3>
				<h4 style="text-align: center">מספר אישור: {{ _receipt.lastDocnumSent }}</h4> -->

				<div class="product-detail-add__popup-row"></div>
			</div>
			<ng-template #loadingBlock>
				<div class="flex justify-align-center">
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
		</div>
	</div>
</div>
