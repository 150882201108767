<!-- markup taken from mobility-dashbord -->

<div class="main-wrapper" *ngIf="pageMode$ | async as page">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="navigateBack()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" />
					</div>
					<div class="header__title col">{{ reportTitle$ | async }}</div>
					<div class="header__burger col image" style="text-decoration: underline" (click)="_util.reloadPage()">
						<b> איפוס </b>
					</div>
				</div>
			</div>
			<div class="report-table__row" *ngIf="page !== 'likut'">
				<div class="flex justify-align-center" style="margin: 25px 25px 0">
					<form [formGroup]="form" dir="rtl" style="background: white; border-radius: 10px">
						<mat-form-field appearance="fill" color="primary">
							<mat-label>לפי תאריך</mat-label>
							<input readonly="true" formControlName="date" matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilterFunction" (click)="picker.open()" />
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
					</form>
				</div>
			</div>
		</header>

		<!--  dashboard-tables markup  -->
		<section class="dashboard-tables">
			<div class="content">
				<div class="dashboard-tables__wrapper">
					<div class="flex flex-wrap" style="margin-inline: 25px" [ngSwitch]="agentsCheckboxContainerMode$ | async">
						<form [formGroup]="form" dir="rtl" class="justify-align-center gap flex-wrap" *ngSwitchCase="'wrap'">
							<ng-container [ngSwitch]="page">
								<ng-container *ngSwitchCase="'truck-distribution'">
									<mat-checkbox *ngFor="let agent of agentsDistribution" color="primary" (change)="recalculateScrollEdges()" [formControlName]="agent.agentID"> {{ agent.name }}</mat-checkbox>
								</ng-container>
								<ng-container *ngSwitchCase="'truck-loading'">
									<mat-checkbox *ngFor="let agent of agentsLoading" color="primary" (change)="recalculateScrollEdges()" [formControlName]="agent.agentID"> {{ agent.name }}</mat-checkbox>
								</ng-container>
								<ng-container *ngSwitchCase="'likut'">
									<mat-checkbox *ngFor="let agent of agentsLikut" color="primary" (change)="recalculateScrollEdges()" [formControlName]="agent.agentID"> {{ agent.name }}</mat-checkbox>
								</ng-container>
							</ng-container>
							<button mat-raised-button color="primary" (click)="handleToggleAllAgents()">בחר הכל</button>
						</form>

						<ng-container *ngSwitchCase="'multiselect'">
							<form [formGroup]="form" dir="rtl" class="justify-align-center gap flex-wrap">
								<ng-container [ngSwitch]="page">
									<ng-container *ngSwitchCase="'truck-distribution'">
										<mat-form-field color="primary" appearance="fill" dir="rtl">
											<mat-select multiple formControlName="agentsArray">
												<mat-option *ngFor="let agent of agentsDistribution" [value]="agent.agentID">{{ agent.name }}</mat-option>
											</mat-select>
										</mat-form-field>
									</ng-container>
									<ng-container *ngSwitchCase="'truck-loading'">
										<mat-form-field color="primary" appearance="fill" dir="rtl">
											<mat-select multiple formControlName="agentsArray">
												<mat-option *ngFor="let agent of agentsLoading" [value]="agent.agentID">{{ agent.name }}</mat-option>
											</mat-select>
										</mat-form-field>
									</ng-container>
								</ng-container>
							</form>
						</ng-container>
					</div>

					<ng-container [ngSwitch]="page">
						<div
							class="dashboard-tables__row dashboard-tables__row_scroll margin-top custom-scrollbar"
							[ngClass]="{ 'with-select': (agentsCheckboxContainerMode$ | async) === 'multiselect' }"
							#scrollable
							*ngSwitchCase="'truck-distribution'"
							dir="rtl"
						>
							<div cdkDropListGroup class="flex scroll-me">
								<ng-container *ngIf="notAssignedDistribution$ | async as notAssignedDistributionArray">
									<div class="example-container" *ngIf="notAssignedDistributionArray?.length">
										<h2>לא משוייך</h2>
										<div cdkDropList [cdkDropListData]="notAssignedDistributionArray" class="example-list" (cdkDropListDropped)="dropDistribution($event, null)">
											<div
												class="rtl example-box"
												[ngStyle]="{ 'background-color': item.colorForBackground || null }"
												data-draggable="true"
												*ngFor="let item of notAssignedDistributionArray"
												(click)="toggleOpenTaskPopup(item)"
												cdkDrag
												[ngClass]="{ inaccessible: (item.status | taskTextVisibility : _truckLoading.Admin) }"
												[cdkDragDisabled]="item.status | taskTextVisibility : _truckLoading.Admin"
											>
												<span data-draggable="true">
													<span data-draggable="true">{{ item.clientid }}</span> - <span data-draggable="true">{{ item.clientName }}</span>
												</span>
												<span data-draggable="true">{{ item.address }}</span>
												<span data-draggable="true">
													<span data-draggable="true">סה"כ תעודות: {{ item.totalTeudot }}</span>
													<span data-draggable="true">{{ item | taskAssignmentExtraDisplay }}</span>
												</span>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngFor="let agent of agentsDistribution$ | async">
									<ng-container [ngSwitch]="agentsCheckboxContainerMode$ | async">
										<ng-container *ngSwitchCase="'wrap'">
											<div class="example-container" *ngIf="form.controls[agent.agentID].value">
												<ng-container [ngTemplateOutlet]="boxBlock"></ng-container>
											</div>
										</ng-container>
										<ng-container *ngSwitchCase="'multiselect'">
											<div class="example-container" *ngIf="form.controls.agentsArray.value.includes(agent.agentID)">
												<ng-container [ngTemplateOutlet]="boxBlock"></ng-container>
											</div>
										</ng-container>
										<ng-template #boxBlock>
											<h2>{{ agent.agentID }} - {{ agent.name }}</h2>
											<div cdkDropList [cdkDropListData]="agent.tasks" class="example-list" (cdkDropListDropped)="dropDistribution($event, agent.agentID)">
												<div
													class="rtl example-box"
													[ngClass]="{ inaccessible: item.OpenedBy || (item.status | taskTextVisibility : _truckLoading.Admin) }"
													[ngStyle]="{ 'background-color': item.colorForBackground || null }"
													data-draggable="true"
													*ngFor="let item of agent.tasks"
													(click)="toggleOpenTaskPopup(item)"
													cdkDrag
													[cdkDragDisabled]="item.OpenedBy || (item.status | taskTextVisibility : _truckLoading.Admin)"
												>
													<span data-draggable="true">
														<span data-draggable="true">{{ item.clientid }}</span> - <span data-draggable="true">{{ item.clientName }}</span>
													</span>
													<span data-draggable="true">{{ item.address }}</span>
													<div class="row" data-draggable="true">
														<span data-draggable="true">סה"כ תעודות: {{ item.totalTeudot }}</span>
														<span data-draggable="true">{{ item | taskAssignmentExtraDisplay }}</span>
													</div>
													<span class="text-over" *ngIf="item.status | taskTextVisibility : _truckLoading.Admin">{{ item.status | taskText : 'truck-distribution' }}</span>
												</div>
											</div>
										</ng-template>
									</ng-container>
								</ng-container>
							</div>
						</div>
						<div
							class="dashboard-tables__row dashboard-tables__row_scroll margin-top custom-scrollbar"
							[ngClass]="{ 'with-select': (agentsCheckboxContainerMode$ | async) === 'multiselect' }"
							#scrollable
							*ngSwitchCase="'truck-loading'"
							dir="rtl"
						>
							<div cdkDropListGroup class="flex scroll-me">
								<ng-container *ngIf="notAssignedLoading$ | async as notAssignedLoadingArray">
									<div class="example-container" *ngIf="notAssignedLoadingArray.length">
										<h2>לא משוייך</h2>
										<div cdkDropList [cdkDropListData]="notAssignedLoadingArray" class="example-list" (cdkDropListDropped)="dropLoading($event, null)">
											<div
												class="rtl example-box"
												[ngStyle]="{ 'background-color': item.colorForBackground || null }"
												data-draggable="true"
												*ngFor="let item of notAssignedLoadingArray"
												(click)="toggleOpenTaskPopup(item)"
												cdkDrag
												[ngClass]="{ inaccessible: (item.status | taskTextVisibility : _truckLoading.Admin) }"
												[cdkDragDisabled]="item.status | taskTextVisibility : _truckLoading.Admin"
											>
												<span data-draggable="true">
													<span data-draggable="true">{{ item.clientid }}</span> - <span data-draggable="true">{{ item.clientName }}</span>
												</span>
												<span data-draggable="true">{{ item.address }}</span>
												<span data-draggable="true">
													<span data-draggable="true">סה"כ תעודות: {{ item.totalTeudot }}</span>
												</span>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngFor="let agent of agentsLoading$ | async">
									<ng-container [ngSwitch]="agentsCheckboxContainerMode$ | async">
										<ng-container *ngSwitchCase="'wrap'">
											<div class="example-container" *ngIf="form.controls[agent.agentID].value">
												<ng-container [ngTemplateOutlet]="boxBlock"></ng-container>
											</div>
										</ng-container>
										<ng-container *ngSwitchCase="'multiselect'">
											<div class="example-container" *ngIf="form.controls.agentsArray.value.includes(agent.agentID)">
												<ng-container [ngTemplateOutlet]="boxBlock"></ng-container>
											</div>
										</ng-container>
										<ng-template #boxBlock>
											<h2>{{ agent.agentID }} - {{ agent.name }}</h2>
											<div cdkDropList [cdkDropListData]="agent.tasks" class="example-list" (cdkDropListDropped)="dropLoading($event, agent.agentID)">
												<div
													class="rtl example-box"
													[ngClass]="{ inaccessible: item.OpenedBy || (item.status | taskTextVisibility : _truckLoading.Admin) }"
													[ngStyle]="{ 'background-color': item.colorForBackground || null }"
													data-draggable="true"
													*ngFor="let item of agent.tasks"
													(click)="toggleOpenTaskPopup(item)"
													cdkDrag
													[cdkDragDisabled]="item.OpenedBy || (item.status | taskTextVisibility : _truckLoading.Admin)"
												>
													<span data-draggable="true">
														<span data-draggable="true">{{ item.clientid }}</span> - <span data-draggable="true">{{ item.clientName }}</span>
													</span>
													<span data-draggable="true">{{ item.address }}</span>
													<span data-draggable="true">סה"כ תעודות: {{ item.totalTeudot }}</span>
													<span class="text-over" *ngIf="item.status | taskTextVisibility : _truckLoading.Admin">{{ item.status | taskText : 'truck-loading' }}</span>
												</div>
											</div>
										</ng-template>
									</ng-container>
								</ng-container>
							</div>
						</div>
						<div
							class="dashboard-tables__row dashboard-tables__row_scroll margin-top custom-scrollbar"
							[ngClass]="{ 'with-select': (agentsCheckboxContainerMode$ | async) === 'multiselect' }"
							#scrollable
							*ngSwitchCase="'likut'"
							dir="rtl"
						>
							<div cdkDropListGroup class="flex scroll-me">
								<ng-container *ngIf="notAssignedLikut$ | async as notAssignedLikutArray">
									<div class="example-container" *ngIf="notAssignedLikutArray.length">
										<h2>לא משוייך</h2>
										<div cdkDropList [cdkDropListData]="notAssignedLikutArray" class="example-list" (cdkDropListDropped)="dropLikut($event, null)">
											<div
												class="rtl example-box"
												[ngStyle]="{ 'background-color': item.colorForBackground || null }"
												data-draggable="true"
												*ngFor="let item of notAssignedLikutArray"
												(click)="toggleOpenTaskPopup(item)"
												cdkDrag
												[ngClass]="{ inaccessible: (item.status | taskTextVisibility : _likut.Admin) }"
												[cdkDragDisabled]="item.status | taskTextVisibility : _likut.Admin"
											>
												<span data-draggable="true">
													<span data-draggable="true">{{ item.clientid }}</span> - <span data-draggable="true">{{ item.clientName }}</span>
												</span>
												<span data-draggable="true">{{ item.address }}</span>
												<span data-draggable="true">
													<span data-draggable="true">סה"כ תעודות: {{ item.totalTeudot }}</span>
												</span>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngFor="let agent of agentsLikut$ | async">
									<ng-container [ngSwitch]="agentsCheckboxContainerMode$ | async">
										<ng-container *ngSwitchCase="'wrap'">
											<div class="example-container" *ngIf="form.controls[agent.agentID].value">
												<ng-container [ngTemplateOutlet]="boxBlock"></ng-container>
											</div>
										</ng-container>
										<ng-container *ngSwitchCase="'multiselect'">
											<div class="example-container" *ngIf="form.controls.agentsArray.value.includes(agent.agentID)">
												<ng-container [ngTemplateOutlet]="boxBlock"></ng-container>
											</div>
										</ng-container>
										<ng-template #boxBlock>
											<h2>{{ agent.agentID }} - {{ agent.name }}</h2>
											<div cdkDropList [cdkDropListData]="agent.tasks" class="example-list" (cdkDropListDropped)="dropLikut($event, agent.agentID)">
												<div
													class="rtl example-box"
													[ngClass]="{ inaccessible: item.OpenedBy || (item.status | taskTextVisibility : _likut.Admin) }"
													[ngStyle]="{ 'background-color': item.colorForBackground || null }"
													data-draggable="true"
													*ngFor="let item of agent.tasks"
													(click)="toggleOpenTaskPopup(item)"
													cdkDrag
													[cdkDragDisabled]="item.OpenedBy || (item.status | taskTextVisibility : _likut.Admin)"
												>
													<span data-draggable="true">
														<span data-draggable="true">{{ item.clientid }}</span> - <span data-draggable="true">{{ item.clientName }}</span>
													</span>
													<span data-draggable="true">{{ item.address }}</span>
													<span data-draggable="true">סה"כ תעודות: {{ item.totalTeudot }}</span>
													<span class="text-over" *ngIf="item.status | taskTextVisibility : _likut.Admin">{{ item.status | taskText : 'likut' }}</span>
												</div>
											</div>
										</ng-template>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</section>
	</div>
</div>
<!-- <div class="proxy"></div> -->
