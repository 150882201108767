<div class="main-wrapper">
	<div class="main-content">
		<!-- global markup for header -->
		<header class="header">
			<div class="content">
				<div class="header__block row">
					<div class="header__arrow col image" (click)="BackToClientTeudot()">
						<img src="/assets/images/header/back-arrow.svg" alt="arrow" /><span style="color: white; font-size: 18px">חזרה</span>
					</div>
					<div class="header__title col">פירוט תעודה</div>
					<div class="header__burger col image">
						<!-- <img src="/assets/images/header/burger.svg" alt="burger"> -->
					</div>
				</div>
			</div>
		</header>

		<!-- client info for all client profile page -->
		<section class="client-info" style="margin-top: 100px">
			<div class="content">
				<div class="client-details__item-status client-details__item-status_green" *ngIf="this._ss.Betipul === 0">טופל</div>
				<div class="client-details__item-status client-details__item-status_blue" *ngIf="this._ss.Betipul === 1">בטיפול</div>
				<div class="row">
					<div class="client-info__title client-info__title_margin col">
						{{ this.TeudotDetailsEach[0].Client_Name }}
					</div>
				</div>
				<div class="row">
					<div class="client-info__number col" style="margin-bottom: 0.5rem">
						{{ this.TeudotDetailsEach[0].Client_Id }}
					</div>
				</div>
				<div class="row" style="text-align: right" *ngIf="_ss.CustomerInformation.CustChange4 === '1'">
					<button style="direction: rtl" (click)="createPdfInServer()">צור PDF בשרת</button>
				</div>
			</div>
		</section>
		<!-- end of client info for all client profile page -->

		<!-- start markup for client profile order page -->
		<section class="client-details">
			<div class="content">
				<div class="row">
					<div class="client-details__item row">
						<div class="client-details__item-row client-details__item-row_margin row">
							<span class="client-details__item-name client-details__item-name_bold">אסמכתא</span>
							<span class="client-details__item-name">{{ this.TeudotDetailsEach[0].TeudaNum }}#</span>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title">תאריך אספקה:</span>
							<span class="client-details__item-text client-details__item-text_bold">{{ this.TeudotDetailsEach[0].TeudaSupplyDate | date : 'dd-MM-yyyy' }}</span>
							<span class="client-details__item-text">7:00-19:00</span>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title">תאריך הזמנה:</span>
							<span class="client-details__item-text client-details__item-text_bold"> {{ this.TeudotDetailsEach[0].TeudaCreateDate | date : 'dd-MM-yyyy' }}</span>
							<span class="client-details__item-text">{{ TeudotDetailsEach[0].TeudaDate | returnTimeFormatted }}</span>
						</div>
						<div class="client-details__item-row row">
							<span class="client-details__item-title">סוג תעודה:</span>
							<span class="client-details__item-text client-details__item-text_bold" style="font-size: larger">{{ this.TeudotDetailsEach[0].TeudaTypeName }}</span>
						</div>

						<div class="client-details__item-row client-details__item-row_summ row">
							<div class="client-details__item-col client-details__item-col_border col" style="position: relative" *ngIf="canSeePrice">
								<span class="client-details__item-title">סכום:</span>
								<span class="client-details__item-text client-details__item-text_big client-details__item-text_price">{{
									this.TeudotDetailsEach[0].TeudaTotalSum | number : '1.0-2' : 'en-US'
								}}</span>
							</div>
							<div class="client-details__item-col col">
								<span class="client-details__item-title">מספר שורות:</span>
								<span class="client-details__item-text client-details__item-text_big">{{ this.TeudotDetailsEach[0].TotalLines }}</span>
							</div>
							<div class="client-details__item-col col" *ngIf="anyRemarksAtAll">
								<span class="client-details__item-title"> הערה ראשית:</span>
								<span class="client-details__item-text client-details__item-text_big">{{ this.TeudotDetailsEach[0].TeudaRemark }}</span>
								<button class="client-details__item-col-button client-order__item-button" (click)="handleRemarksPopupChangeDisplay(true)" *ngIf="anyRemarksAtAll">צפייה בכל ההערות</button>
							</div>
						</div>
					</div>
					<div class="client-details__block" *ngFor="let eceo of TeudotDetailsEach">
						<div class="row">
							<h2 class="client__product-title">
								{{ eceo.ProductName }}
								<span *ngIf="eceo.ItemPack && showItemInPack" class="client__product-title-brackets">{{ eceo.ItemPack }} באריזה</span>
							</h2>
						</div>
						<div class="client__product-block-row client__product-block-row_border row">
							<div class="client__product-block-col client__product-block-col_image image">
								<img
									src="{{ this._ss.CustomerInformation.CustImageDomain }}/{{ eceo.ItemPicName | encodeURI }}.jpg"
									class="ItemPic"
									onerror="this.onerror=null; this.src='/assets/images/no-product-icon.svg'"
									alt="photo"
								/>
							</div>
							<div class="client__product-block-col client__product-block-col_text">
								<div class="row">
									<div class="client__product-block-text client__product-block-text_gray">ברקוד:</div>
									<div class="client__product-block-text">{{ eceo.ItemBarcode }}</div>
								</div>
								<div class="row">
									<div class="client__product-block-text client__product-block-text_gray">קוד פריט:</div>
									<div class="client__product-block-text">{{ eceo.ProductId }}</div>
								</div>
							</div>
							<div class="client__product-block-col client__product-block-col_number client__product-block-col_border">
								<div class="row">
									<h3 class="client__product-block-text client__product-block-text_gray">יחידות</h3>
									<div class="client__product-block-text">{{ eceo.TeudaQuantity }}</div>
								</div>
							</div>
							<div class="client__product-block-col client__product-block-col_number">
								<div class="row">
									<h3 class="client__product-block-text client__product-block-text_gray">{{ _text.packQuantityText }}</h3>
									<div class="client__product-block-text">{{ eceo.TeudaPackQuantity }}</div>
								</div>
							</div>
						</div>
						<div class="client__product-block-row row" *ngIf="canSeePrice">
							<div class="client__product-block-box">
								<div class="client__product-block-box-title">מחיר יחידה</div>
								<div class="client__product-block-box-price client__product-block-box-price_small">{{ eceo.TeudaPrice | number : '1.0-2' : 'en-US' }}</div>
							</div>
							<div class="client__product-block-box">
								<div class="client__product-block-box-title">מחיר מעודכן</div>
								<div class="client__product-block-box-price client__product-block-box-price_green client__product-block-box-price_small">
									{{ eceo.TeudaItemNetPrice | number : '1.0-2' : 'en-US' }}
								</div>
							</div>
							<div class="client__product-block-box">
								<div class="client__product-block-box-title">הנחה %</div>
								<div class="client__product-block-box-text">{{ eceo.TeudaItemDiscount | number : '1.0-2' : 'en-US' }}</div>
							</div>
							<div class="client__product-block-box">
								<div class="client__product-block-box-title">סה"כ</div>
								<div class="client__product-block-box-price client__product-block-box-price_green">{{ eceo.TeudaTotalRow | number : '1.0-2' : 'en-US' }}</div>
							</div>
						</div>
						<div class="client__product-block-row client__product-block-row_margin">
							<div class="client__product-block-icon image">
								<img src="/assets/images/basket/bonus-icon.svg" alt="photo" />
							</div>
							<div class="client__product-block-title">בונוס יחידות {{ eceo.TeudaBonusQuantity }}</div>
						</div>
						<div class="client__product-block-row">
							<div class="client__product-block-icon image">
								<img src="/assets/images/basket/chat-icon.svg" alt="photo" />
							</div>
							<div class="client__product-block-title">{{ eceo.TeudaItemRemark }}</div>
						</div>
					</div>
					<div class="client-details__action row" (click)="PopDuplicate = true">
						<button class="client-details__block-button" style="color: white; background-color: #38d88b">שכפל תעודה</button>
					</div>
				</div>
			</div>
		</section>
		<!-- end markup for client profile order page -->

		<!-- start Hearot popup -->

		<div class="popup" *ngIf="isRemarkPopupOpen">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="handleRemarksPopupChangeDisplay(false)"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">הערות</h2>
				<div class="order-cart__popup-block-wrapper">
					<div class="order-cart__popup-block column" *ngFor="let remark of remarksArr; let i = index">
						<h3 class="remark_title" *ngIf="remark">
							{{ i | getRemarkHeader }}
						</h3>
						<div class="client-details__item-name margin-bottom" *ngIf="remark">
							{{ remark }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- end Hearot popup -->

		<!-- markup for popup -->
		<div class="popup" *ngIf="PopDuplicate">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="PopDuplicate = false"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">לשכפל את התעודה ?</h2>

				<div class="grid row" *ngIf="DocTypePerClient?.length; else defaultDoctypes">
					<div class="shop-filter__row" style="position: relative" *ngFor="let dtpc of DocTypePerClient" (click)="DuplicateOrder(dtpc.DocType)">
						<label class="shop-filter__radio custom-radio" style="font-weight: bold" id="doc{{ dtpc.DocType }}">
							{{ dtpc.DocName }} <span id="numofitems{{ dtpc.DocType }}"></span>
							<input type="radio" name="shop-filter" />
							<span class="custom-radio__checkmark"></span>
						</label>
					</div>
				</div>
				<ng-template #defaultDoctypes>
					<div class="order-cart__popup-block row">
						<button class="order-cart__popup-button" (click)="DuplicateOrder(11)" *ngIf="agentInfo.ordon">הזמנה</button>
						<button class="order-cart__popup-button" style="background-color: red" (click)="DuplicateOrder(74)" *ngIf="agentInfo.reton">החזרה</button>
						<button class="order-cart__popup-button" style="background-color: blue" (click)="DuplicateOrder(7)" *ngIf="agentInfo.hazaon">
							{{ _ss.CustomerInformation.CustNum === '61' ? ' ה.מחיר' : 'ה.מחיר ראשוני"' }}
						</button>
						<button class="order-cart__popup-button" (click)="DuplicateOrder(170)" *ngIf="_ss.CustomerInformation.CustNum === '61'">ה.מחיר סופית</button>
					</div>
				</ng-template>
			</div>
		</div>
		<!-- end markup for popup -->

		<!-- markup for popup -->
		<div class="popup" *ngIf="ConfirmPop === true">
			<div class="overlay"></div>
			<div class="order-cart__popup popup__content">
				<div class="popup__close" (click)="Exit()"></div>
				<div class="order-cart__popup-image image">
					<img src="/assets/images/basket/cancel-icon.gif" alt="photo" />
				</div>
				<h2 class="order-cart__popup-title">תעודה שוכפלה בהצלחה</h2>
				<div class="order-cart__popup-block row">
					<button class="order-cart__popup-button order-cart__popup-button_send" (click)="Exit()">חזור</button>

					<button class="order-cart__popup-button" (click)="WatchOrderDuplicate()">צפה בתעודה</button>
				</div>
			</div>
		</div>
		<!-- end markup for popup -->
	</div>
</div>
