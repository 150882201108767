import { Pipe, PipeTransform } from '@angular/core';
import { TruckModeStatuses } from '../Interfaces/truck-loading';

@Pipe({
	name: 'podProductStatus',
})
export class PodProductStatusPipe implements PipeTransform {
	transform(value: TruckModeStatuses): string {
		switch (value) {
			case '1':
				return 'סופק';
			case '2':
				return 'בוטל';
			case '3':
				return 'סופק חלקית';

			default:
				return '';
		}
	}
}
